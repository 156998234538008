import { ROUTER } from 'src/constants';

import { iFrameDetect } from 'utils';

function generateLinkProps(routeKey: string): { to: string; isActive: AnyFunction } {
  function isActive(_match: any, location: Location) {
    return location.pathname.match(ROUTER[routeKey]);
  }

  let to = ROUTER[routeKey];

  if (iFrameDetect().isWithinIFrame) {
    to = ROUTER[`IFRAME_${routeKey}`];
  }

  return { isActive, to };
}

export { generateLinkProps };
