import { BLOOD_OPTIONS } from '../constants';

class BloodList {
  static valuesForDropdown(): Array<{ key: number; label: string; value: string }> {
    return BLOOD_OPTIONS.map((blood) => ({
      key: blood.id,
      label: blood.title,
      value: blood.value,
    }));
  }
}

export { BloodList };
