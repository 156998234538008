import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const SickIllPath = (
  <path
    id='Subtract'
    fillRule='evenodd'
    clipRule='evenodd'
    d='M22.3509 16.9004C22.9642 15.6698 23.3538 14.3081 23.4662 12.8685L18.501 16.4151V16.9004H22.3509ZM18.501 18.9004V19.9004C18.501 20.4527 18.0533 20.9004 17.501 20.9004H7.501C6.94871 20.9004 6.501 20.4527 6.501 19.9004V18.9004C6.50067 18.9004 6.50035 18.9004 6.50002 18.9004H3.93306C5.94944 21.4006 9.03795 23 12.5 23C15.9621 23 19.0506 21.4006 21.067 18.9004H18.501ZM6.501 16.9004C6.50067 16.9004 6.50035 16.9004 6.50002 16.9004H2.64916C2.03559 15.6694 1.64598 14.3071 1.53369 12.8671L6.501 16.4151V16.9004ZM1.60676 10.4615L6.501 13.9573V13.9004H18.501V13.9573L23.3935 10.4627C22.6457 5.11534 18.0534 1 12.5 1C6.94703 1 2.35508 5.11469 1.60676 10.4615ZM17.501 6.90039C17.501 8.00496 16.6056 8.90039 15.501 8.90039C14.3964 8.90039 13.501 8.00496 13.501 6.90039C13.501 5.79582 15.501 2.90039 15.501 2.90039C15.501 2.90039 17.501 5.79582 17.501 6.90039ZM9.001 9.90039C8.17257 9.90039 7.501 10.572 7.501 11.4004C7.501 12.2288 8.17257 12.9004 9.001 12.9004C9.82942 12.9004 10.501 12.2288 10.501 11.4004C10.501 10.572 9.82942 9.90039 9.001 9.90039ZM14.501 11.4004C14.501 10.572 15.1726 9.90039 16.001 9.90039C16.8294 9.90039 17.501 10.572 17.501 11.4004C17.501 12.2288 16.8294 12.9004 16.001 12.9004C15.1726 12.9004 14.501 12.2288 14.501 11.4004Z'
    fill='currentColor'
  />
);

export const SickIll = (props: IconProps) => <SvgIcon path={SickIllPath} {...props} />;
