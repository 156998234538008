import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { toggleCheckpoint } from 'modules/results/actions';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { MobileAdditionalInfo } from 'src/modules/racersTable/components';
import { TrackingSource } from 'src/modules/segments/components/TrackingSource';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { calcProgress, formatAltitude, formatDistance, mobileItemHeight } from '../../utils';

import { activeSplitStore, classesStore, distancesStore, goalsStore, squadsStore, wavesStore } from '../../stores';

import { resultsPresentation } from '../../derivations';
import { LResult } from '../../types';
import { CheckpointsTable } from '../Checkpoint';
import { PresentResult } from '../PresentResult';
import { RacerName } from '../RacerName';
import { getGradientByPlace } from './ItemWrapper';

type Props = {
  value: LResult;
  distanceId: number;
  checkpointId: number;
};

export const ItemWrapper = styled.div<{ place?: number | nil }>`
  background-color: ${(props) => props.theme.main.colors.clay7};
  padding: 20px 0;
  overflow: hidden;
  transition: height 300ms ease-in-out;
  position: relative;

  ${getGradientByPlace}

  &:nth-of-type(odd) {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }
`;

const TrackingSourceWrap = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
  z-index: 1;

  display: flex;
  align-items: center;
`;

const Wrapper = styled(ItemWrapper)<{ height: number }>`
  padding: 20px 0;
  background-color: ${(props) => props.theme.main.colors.clay7};

  ${TrackingSourceWrap} {
    height: ${(props) => props.height}px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 24px;
  gap: 8px;

  align-items: center;
  padding: 0 20px;
  margin-bottom: 8px;
`;

const TimeInfo = styled.div`
  display: flex;
  gap: 8px;

  padding-left: 63px;
`;

const StyledSvg = styled(Svg)<{ active?: boolean }>`
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'initial')};
  transition: All ease-out 0.3s;
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
  white-space: nowrap;
`;

const CollapsibleInfo = styled.div`
  padding: 0 20px;
  transition: All 0.3s;
  &.active {
    height: auto;
    padding: 20px 20px 0;
  }
`;

const StyledMobileAdditionalInfo = styled(MobileAdditionalInfo)`
  margin-top: 16px;
`;

export const MobileSingleItem: React.FC<Props> = (props) => {
  const { value, distanceId, checkpointId } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const racer = value.racer;
  const distanceModel = distancesStore.find(+distanceId!);

  if (!racer || !distanceModel) return null;

  const classModel = classesStore.find(+racer.class_id!);
  const waveModel = wavesStore.find(+racer.wave_id!);
  const squad = squadsStore.values.find((item) => item.id === racer.squad_id!);
  const place = resultsPresentation.generatePlace(value, distanceId!, checkpointId);

  const height = mobileItemHeight(value);
  const expandCondition = !isEmpty(distanceModel.checkpoints);
  const showPercentage = distanceModel.isMode('cumulative');
  const goalModel = goalsStore.findByDistance(+distanceId!);
  const progressText = calcProgress(value.distance_logged, goalModel?.value?.goal_max_length);

  const distanceLogged = value.distance_logged as any;
  const distanceLength = goalModel?.isGoalType('distance') ? formatDistance(distanceLogged) : formatAltitude(distanceLogged);
  const isAltitude = Boolean(goalsStore.findByDistance(+distanceId!)?.isGoalType('altitude'));
  const distanceKey = isAltitude ? 'results.m' : 'results.km';

  return (
    <Observer>
      {() => {
        const isActive = value.racer_id === activeSplitStore.value;
        return (
          <Wrapper place={place} height={height}>
            <Show if={!distanceModel.isMode('classic')}>
              <TrackingSourceWrap>
                <TrackingSource link={value.activity_link} segmentId={value.racer_id} />
              </TrackingSourceWrap>
            </Show>

            <Header>
              <Utility variant='u1' weight='bold'>
                {place}
              </Utility>
              <RacerName value={racer} />
              <Show if={expandCondition}>
                <StyledSvg name='CircleDown' size={24} active={isActive} onClick={toggleCheckpoint(isActive ? null : value.racer_id)} />
              </Show>
            </Header>
            <TimeInfo>
              <StyledUtility variant='u2' weight='medium'>
                {racer.bib_number}
              </StyledUtility>
              <StyledUtility variant='u2' weight='bold'>
                <PresentResult.FinishResult result={value} checkpointId={checkpointId} distance={distanceModel} />
              </StyledUtility>
              <StyledUtility variant='u2' weight='medium'>
                <PresentResult.LeaderTime result={value} checkpointId={checkpointId} distanceId={Number(distanceId)} />
              </StyledUtility>
              <Show if={distanceModel.isMode('cumulative')}>
                <StyledUtility variant='u2' weight='medium'>
                  {distanceLength} {t.staticAsString(distanceKey)}
                </StyledUtility>
              </Show>
              <Show if={showPercentage}>
                <StyledUtility variant='u2' weight='medium'>
                  {progressText}
                </StyledUtility>
              </Show>
            </TimeInfo>
            <Show if={height === 140}>
              <StyledMobileAdditionalInfo
                classTitle={classModel?.value.title}
                union={racer.union}
                wave={waveModel?.value.name}
                squad={squad?.name}
                isPlace
              />
            </Show>
            <CollapsibleInfo className={`${isActive ? 'active' : ''}`}>
              <CheckpointsTable ref={ref} active={isActive} value={value} checkpointId={checkpointId} distanceId={distanceId} />
            </CollapsibleInfo>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
