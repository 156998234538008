import { openModal } from 'modules/race/actions';
import * as React from 'react';
import styled from 'styled-components';

import { t } from 'utils';

const MoreInfoLink = styled.span`
  cursor: pointer;
  margin-top: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: ${(props) => props.theme.main.colors.clay3};
  border-bottom: 2px solid ${(props) => props.theme.main.colors.clay3};
`;

type Props = {
  distanceInfo: AnyObject;
};

export const DistanceInfoLink: React.FC<Props> = ({ distanceInfo }) => {
  return <MoreInfoLink onClick={() => openModal(distanceInfo)}>{t.staticAsString('race.moreInfo')}</MoreInfoLink>;
};
