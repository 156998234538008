import { distanceIdMapper } from './distanceIdMapper';
import { filterCFByType } from './filterCFByType';
import { filterBERacerEmptyData } from './filterEmptyData';
import { filterFormRacerNonExistingCoupon } from './filterFormRacerNonExistingCoupon';
import { onlyPriceFields } from './filterPriceFields';
import { mapBECFToFormCF } from './mapBECFToFormCF';
import { mapBERacerToFormRacer } from './mapBERacerToFormRacer';
import { mapCustomFieldOptionsToSelectOptions } from './mapCustomFieldOptionsToSelectOptions';
import { mapForSquads } from './mapForSquads';
import { mapFormCFToBECF, mapPriceFormCFToBECF } from './mapFormCFToBECF';
import { mapFormRacerToBERacer } from './mapFormRacerToBERacer';
import { omitWave, omitClass, omitDiscipline, omitCoupon, omitRegistrationFieldsExcept } from './omitFields';
import { prependPrefixToObject } from './prependPrefixToObject';
import { rejectCustomFields } from './rejectCustomFields';
import { selectFormRacerByRegistrationFields, selectBERacerByRegistrationFields } from './selectDataByRegistrationFields';
import { selectRacerFormAdditionalFields, selectRacerBEAdditionalFields } from './selectRacerFormAdditionalFields';

export const mapperUtils = {
  /**
   * @description
   * map distance id to select option and vice verca
   */
  distanceIdMapper,

  /**
   * @description
   * Map user data to format that ca be inserted to the form
   */
  mapBERacerToFormRacer,

  /**
   * @description
   * Map user data to format that can be inserted to the form
   */
  mapFormRacerToBERacer,

  /**
   * @description
   * Filter team registrant, group registrant (racer from the form)
   * to the object which contains only the selected fields
   */
  selectFormRacerByRegistrationFields,

  /**
   * @description
   * Filter UserType or just object which is an object of {[registrationFields]: any}
   * to the object which contains only the selected fields
   */
  selectBERacerByRegistrationFields,

  /**
   * @description
   * Filter UserType or just object which is an object of {[registrationFields]: any}
   * filter null and undefined values
   */
  filterBERacerEmptyData,

  /**
   * @description
   * Select only provided additional fields,
   * possible return value: { class_id: number, discipline_id: number }
   */
  selectRacerFormAdditionalFields,

  /**
   * @description
   * Select only provided additional fields,
   * possible return value: { class_id: number, discipline_id: number }
   */
  selectRacerBEAdditionalFields,
  /**
   * @description
   * Map custom fields being fetched from the form to the backend format of an array of custom field values
   * map custom fields from form to BE format
   */
  mapFormCFToBECF,
  mapPriceFormCFToBECF,

  /**
   * @description
   * Map array of custom field values returned from the Backend to an custom fields object stored in the form
   * map custom fields from BE format to form format
   */
  mapBECFToFormCF,

  /**
   * @description
   * Map arrat of custom field values to select options, and provide title in format - 'option title (price race.currency)'
   * example - 'T-SHIRT (10.0 SEK)
   */
  mapCustomFieldOptionsToSelectOptions,

  /**
   * @description
   * reject array of custom fields by array of provided fieldId`s
   * select all custom fields that are not included in the fieldIds array
   *
   */
  rejectCustomFields,

  /**
   * @description
   * Filter object of form CustomFields by provided types
   * so for example only custom fields with type time is left
   */
  filterCFByType,

  /**
   * @description
   * Process object as follows:
   *
   * From:
   * { [someKey]: value }
   * To:
   * { [prefix.someKey]: value }
   */
  prependPrefixToObject,

  /**
   * @description
   * Filter form racer field coupon_code if there are no valid coupon loaded
   */
  filterFormRacerNonExistingCoupon,

  onlyPriceFields,

  omitClass,
  omitWave,
  omitDiscipline,
  omitCoupon,
  omitRegistrationFieldsExcept,
  mapForSquads,
};
