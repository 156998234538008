import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

export const ButtonBlock = styled.div`
  display: flex;
  align-self: end;
  padding: 32px 40px 32px 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    background: ${(props) => props.theme.main.colors.clay5};
    border-radius: 50%;

    svg path {
      stroke: ${(props) => props.theme.main.colors.clay3};
    }

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.main.colors.clay3};
      border-color: ${(props) => props.theme.main.colors.clay3};

      svg path {
        stroke: ${(props) => props.theme.main.colors.white};
      }
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    & > div {
      padding: 8px;

      svg path {
        stroke: ${(props) => props.theme.main.colors.clay1};
      }
    }
  }
`;
