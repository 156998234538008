import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Utility1 } from 'src/stories/Core/Typorgaphy/Utilities.stories';
import { TagsFilter } from 'src/styledComponents/TagsFilter';

import { withProgressSpinner } from 'hocs';

import { sessionStore, windowSize } from 'stores';

import { INITIALIZE_XTRI } from '../../constants';

import { mountXtri, unmountXtri } from '../../actions';

import { xtriStore } from '../../stores';

import { getFilteredTickets } from '../../selections';
import { XtriMessage } from './XtriMessage';
import { Wrapper as MessageWrapper } from './XtriMessage';
import { XtriUnion } from './XtriUnion';
import { XtriUnionMobile } from './XtriUnionMobile';

const Wrapper = styled.div`
  .xtri-filter {
    margin: 32px 0 24px 0;
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .xtri-filter {
      padding: 0 20px;
    }
  }
`;

const XtriContainer = () => {
  useEffect(() => {
    mountXtri();
    return () => {
      unmountXtri();
    };
  }, []);

  const options = (xtriStore.value || [])
    .filter((union) => {
      const tickets = getFilteredTickets(union.id);
      return union.is_visible && tickets.length;
    })
    .map((union) => ({
      id: union.id,
      label: union.name,
      value: union.id,
    }));

  const [tab, setTab] = useState(options.length ? options[0].value : null);

  const renderTabs = () => {
    if (!xtriStore.value || !options) {
      return null;
    }

    return <TagsFilter className='xtri-filter' name='filter' value={tab} onChange={(e) => setTab(e.value)} tagsList={options} />;
  };

  const renderUnion = (): React.ReactNode => {
    if (!tab) return null;
    const currentUnion = xtriStore.value?.find((union) => union.id === tab);
    if (!currentUnion) return null;

    if (windowSize.isLessThan('medium')) {
      return (
        <XtriUnionMobile
          key={`union:${currentUnion.id}`}
          unionId={currentUnion.id}
          title={currentUnion.name}
          isVisible={currentUnion.is_visible}
        />
      );
    }

    return (
      <XtriUnion key={`union:${currentUnion.id}`} unionId={currentUnion.id} title={currentUnion.name} isVisible={currentUnion.is_visible} />
    );
  };

  const ATNMyResults = { ...testAnchors.useField('xtri', TEST_ANCHORS.fieldStructure.container) };

  const renderXpoints = () => {
    const points = sessionStore.userModel?.value.xtri_x_points_balance;
    if (points === null) return null;
    return (
      <MessageWrapper>
        <Utility1 variant='u1' weight='bold'>
          <b>MY X-POINTS:</b> {points}
        </Utility1>
      </MessageWrapper>
    );
  };

  return (
    <Wrapper {...ATNMyResults}>
      {renderXpoints()}
      {renderTabs()}
      <XtriMessage />
      {renderUnion()}
    </Wrapper>
  );
};

export const Xtri = withProgressSpinner(INITIALIZE_XTRI)(XtriContainer);
