function generatePaginate(perPage: number) {
  function paginate<T>(values: T[], page: number): T[] {
    const total = values.length;
    const lastItemIndex = page * perPage;

    if (lastItemIndex > total) {
      return values;
    }

    return values.slice(0, lastItemIndex);
  }

  return paginate;
}

export { generatePaginate };
