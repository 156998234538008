import { VIEWPORT_OBSERVABLE } from '../constants';

import { loadMoreSegments } from '../actions/loadSegments';

let spinnerObserver: any;

const observeInfiniteScroll = (): void => {
  if (!!spinnerObserver) {
    return;
  }

  const scrollView = document.querySelector('.scroll-view');
  const observableElement = document.querySelector(`#${VIEWPORT_OBSERVABLE}`);

  if (!observableElement) {
    return;
  }

  spinnerObserver = new IntersectionObserver(observer, { root: scrollView });
  spinnerObserver.observe(observableElement);
};

const observer = (entries: IntersectionObserverEntry[]) => {
  if (entries[0].isIntersecting === true) {
    loadMoreSegments();
  }
};

const unmountInfiniteScroll = () => {
  if (spinnerObserver) {
    spinnerObserver.disconnect();
    spinnerObserver = null;
  }
};

export { observeInfiniteScroll, unmountInfiniteScroll };
