import { scrollManagerStore, windowSize } from 'stores';

const isHeaderHidden = () => {
  const isMobile: boolean = windowSize.isLessThan('large');
  const scrollTop: number = scrollManagerStore.scrollTop;

  return isMobile && scrollTop > 1;
};

export const headerVisibility = {
  isHeaderHidden,
};
