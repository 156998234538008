import { Observer } from 'mobx-react';
import * as React from 'react';

import { Form } from 'src/components/form';
import { Filters } from 'src/modules/racersTable/components/Filters';

import { FORM_FILTERS_ID } from '../../constants';

import { classesSelect, distanceSelect, gendersSelect, wavesSelect } from '../../utils';

import { isAllFiltersAreShowed, isGenderFilterAvailable } from '../../derivations';
import { CheckpointStepper } from './CheckpointStepper';

export const FiltersWrapper = () => {
  return (
    <Observer>
      {() => {
        const filterSettings = {
          distanceOptions: distanceSelect.options.get(),
          classOptions: classesSelect.options.get(),
          waveOptions: wavesSelect.options.get(),
          isGenderAvailable: isGenderFilterAvailable.get(),
          genderOptions: gendersSelect.options(),
          show: isAllFiltersAreShowed.get(),
        };

        return (
          <Form id={FORM_FILTERS_ID} onSubmit={() => {}} disableAutofill enableManualClean>
            <Filters filterSettings={filterSettings} />
            <CheckpointStepper />
          </Form>
        );
      }}
    </Observer>
  );
};
