import * as React from 'react';

export const Map = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.552 2.06356C10.3401 1.97881 10.1037 1.97881 9.89182 2.06356L6.10753 3.57763C5.89564 3.66237 5.65927 3.66237 5.44738 3.57763L1.94279 2.17556C1.87517 2.14846 1.80193 2.13839 1.7295 2.14625C1.65708 2.15411 1.5877 2.17965 1.52747 2.22062C1.46724 2.2616 1.418 2.31675 1.38409 2.38123C1.35018 2.4457 1.33264 2.51752 1.33301 2.59037V11.1077C1.33298 11.2854 1.38621 11.459 1.48582 11.6061C1.58542 11.7533 1.72685 11.8672 1.89183 11.9332L5.44738 13.3554C5.65927 13.4401 5.89564 13.4401 6.10753 13.3554L9.89182 11.8413C10.1037 11.7566 10.3401 11.7566 10.552 11.8413L14.0566 13.241C14.1242 13.2681 14.1974 13.2782 14.2698 13.2703C14.3423 13.2625 14.4116 13.2369 14.4719 13.196C14.5321 13.155 14.5814 13.0998 14.6153 13.0354C14.6492 12.9709 14.6667 12.8991 14.6663 12.8262V4.31126C14.6664 4.13357 14.6131 3.95995 14.5135 3.81281C14.4139 3.66567 14.2725 3.55176 14.1075 3.48578L10.552 2.06356Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5.77734 3.64111V13.4189' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.2217 2.00024V11.778' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
