import { Accordion, Display, Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import { t } from '../../../../utils';

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
  padding: 16px 0 32px;
  max-width: 630px;
  .title {
    color: ${(props) => props.theme.miles.colors.dark.alpha['900']};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FAQSection = () => {
  return (
    <BlockWrapper id='FAQ'>
      <Display className='title' weight='regular' size='xs' style={{ margin: '0 0 16px' }}>
        {t('miles.landing.faq.title')}
      </Display>
      <Content>
        <Accordion headingText={t.staticAsString('miles.landing.faq.questions.what-is-miles.question')}>
          <Text weight='regular' size='md'>
            {t.staticAsString('miles.landing.faq.questions.what-is-miles.answer')}
          </Text>
        </Accordion>
        <Accordion headingText={t.staticAsString('miles.landing.faq.questions.what-is-adaptive-plan.question')}>
          <Text weight='regular' size='md'>
            {t.staticAsString('miles.landing.faq.questions.what-is-adaptive-plan.answer')}
          </Text>
        </Accordion>
        <Accordion headingText={t.staticAsString('miles.landing.faq.questions.what-equipment.question')}>
          <Text weight='regular' size='md'>
            {t.staticAsString('miles.landing.faq.questions.what-equipment.answer')}
          </Text>
        </Accordion>
        <Accordion headingText={t.staticAsString('miles.landing.faq.questions.how-trial-work.question')}>
          <Text weight='regular' size='md'>
            {t.staticAsString('miles.landing.faq.questions.how-trial-work.answer')}
          </Text>
        </Accordion>
        <Accordion headingText={t.staticAsString('miles.landing.faq.questions.do-i-need-train.question')}>
          <Text weight='regular' size='md'>
            {t.staticAsString('miles.landing.faq.questions.do-i-need-train.answer')}
          </Text>
        </Accordion>
        <Accordion headingText={t.staticAsString('miles.landing.faq.questions.difference.question')}>
          <Text weight='regular' size='md'>
            {t.staticAsString('miles.landing.faq.questions.difference.answer')}
          </Text>
        </Accordion>
      </Content>
    </BlockWrapper>
  );
};
