import { observer } from 'mobx-react';
import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

import { Show } from 'components/condition';
import { GoIcon } from 'components/icons';
import { Spinner } from 'components/spinner';

import { t } from 'utils';

import { windowSize } from 'stores';

import { MobileList, TableView } from '../components/Virtual';

import { VIEWPORT_OBSERVABLE } from '../constants';

import { mountVirtual, unmountVirtual, generateLinkToProfile } from '../actions';

import { virtualStore } from '../stores';

import { FormProvider } from './';

type Props = {};

@observer
export class VirtualResults extends React.Component<Props> {
  componentDidMount(): void {
    mountVirtual();
  }

  componentWillUnmount(): void {
    unmountVirtual();
  }

  render() {
    return (
      <div className='my-results'>
        <FormProvider />
        <div className='container'>
          <div className='title'>
            <Link className='go-back' to={generateLinkToProfile()}>
              {GoIcon}
            </Link>
            {t('profile.results.virtualRaces')}
          </div>
          <Show if={windowSize.isLessThan('large')}>
            <MobileList />
          </Show>
          <Show if={!windowSize.isLessThan('large')}>
            <TableView />
          </Show>
          <Show if={virtualStore.hasMore}>
            <div id={VIEWPORT_OBSERVABLE}>
              <Spinner type='cubesSpinner' />
            </div>
          </Show>
        </div>
      </div>
    );
  }
}
