import { footerStore } from 'modules/footer/stores';

import { scrollManagerStore, windowSize } from 'stores';

function show() {
  const mainContent = document.getElementsByClassName('main-content')[0];

  footerStore.setValue({
    ...footerStore.value,
    hide: false,
  });

  if (mainContent) {
    // @ts-ignore
    mainContent.style!.paddingBottom = '';
  }
}

function hide() {
  const mainContent = document.getElementsByClassName('main-content')[0];

  footerStore.setValue({
    ...footerStore.value,
    hide: true,
  });

  if (mainContent) {
    // @ts-ignore
    mainContent.style.paddingBottom = '0';
  }
}

const isFooterHidden = () => {
  const scrollTop: number = scrollManagerStore.scrollTop;

  return scrollTop > 1;
};

const isFooterHiddenOnlyMobile = () => {
  const isMobile: boolean = windowSize.isLessThan('large');
  const scrollTop: number = scrollManagerStore.scrollTop;

  return isMobile ? scrollTop > 1 : false;
};

export const footerVisibility = {
  show,
  hide,
  isFooterHidden,
  isFooterHiddenOnlyMobile,
};
