import * as React from 'react';
import styled from 'styled-components';

import { Icons } from './Components';

const StyledSvg = styled.div<{ size: number | string }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  name: keyof typeof Icons | string;
  size?: number | string;
  className?: string;
  onClick?: AnyFunction;
} & TestAnchortProperties;

export const Svg: React.FC<Props> = (props) => {
  const { name, size = 24, className, onClick, ...rest } = props;
  const Icon = Icons[name];

  if (!Icon) return null;

  return (
    <StyledSvg {...rest} size={size} className={className} onClick={onClick}>
      {Icon}
    </StyledSvg>
  );
};
