// @flow
import { matchPath } from 'react-router-dom';

import { ROUTER, ROUTER_WITH_LOCALES } from 'src/constants';

import sessionStore from 'stores/session';

import { isThereBackHistory } from './isThereBackHistory';

// In case if there are no history to go back to,
// fallbacks will be used
function generateSafeGoBack(history: any, fullHistoryStore: any) {
  return function (options: { fallbackLoggedRoute?: string; fallbackNonLoggedRoute?: string } = {}) {
    if (isThereBackHistory(fullHistoryStore)) {
      return history.goBack();
    }

    if (sessionStore.isAuth) {
      return history.replace(options.fallbackLoggedRoute || ROUTER.HOME);
    }

    return history.replace(options.fallbackNonLoggedRoute || ROUTER.HOME);
  };
}

export type safeGoBack = (options?: { fallbackLoggedRoute?: string; fallbackNonLoggedRoute?: string }) => void;

export { generateSafeGoBack };
