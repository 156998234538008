import { RESOLUTIONS } from 'constants/general';
import { Observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { PageContent } from 'src/styledComponents/PageContent';
import { Skeleton } from 'src/styledComponents/Skeleton';

import { windowSize } from 'stores';

export const TitleSkeleton = styled.div`
  position: relative;
  padding-left: 29px;
  margin: 20px 0;

  &::before {
    content: '';
    position: absolute;
    width: 3px;
    height: 40px;
    left: 0px;
    top: -6px;
    background: ${(props) => props.theme.main.colors.clay1};
  }
`;

const SkeletonWrapper = styled(PageContent)`
  .flex-row {
    display: flex;
    align-items: center;
  }

  .title-wrapper {
    padding: 51px 0 30px;
  }

  .distance {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
    padding-bottom: 37px;
    margin-bottom: 37px;

    .first-row {
      padding-bottom: 20px;

      div:first-child {
        margin-right: 16px;
      }
    }

    .separator {
      width: 3px;
      height: 3px;
      background: rgb(101, 107, 111);
      margin: 0px 12px;
    }

    div:last-child {
      margin-left: 4px;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .first-row {
      align-items: start;
    }
  }
`;

export const RegisterRaceSkeleton = () => (
  <Observer>
    {() => {
      const isMobile = windowSize.isLessThan('medium');

      return (
        <SkeletonWrapper>
          <div className='title-wrapper'>
            <TitleSkeleton>
              <Skeleton width={150} height={28} variant='text' />
            </TitleSkeleton>
          </div>

          <div className='distances'>
            {[...Array(5).keys()].map((key) => (
              <div key={`reg-race-sk-${key}`} className='distance'>
                <div>
                  <div className='flex-row first-row'>
                    <Skeleton width={isMobile ? 16 : 40} height={isMobile ? 31 : 20} variant='text' />
                    <Skeleton width={isMobile ? 165 : 215} height={20} variant='text' />
                  </div>

                  <div className='flex-row'>
                    <Skeleton width={isMobile ? 85 : 110} height={20} variant='text' />
                    <div className='separator' />
                    <Skeleton width={isMobile ? 70 : 80} height={20} variant='text' />
                    <div className='separator' />
                    <Skeleton width={40} height={20} variant='text' />
                  </div>
                </div>

                <Skeleton width={90} height={48} variant='rectangular' />
              </div>
            ))}
          </div>
        </SkeletonWrapper>
      );
    }}
  </Observer>
);
