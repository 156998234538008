import { HEADER_ANIMATION_DURATION } from 'modules/header/constants';
import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { HardElevation } from 'src/styledComponents/Elevation';

type SearchWrapperPorps = {
  hasActive: boolean;
  compressed?: boolean;
  isTablet?: boolean;
};

const compressedHover = (props) => {
  return props.level !== 1
    ? ''
    : css`
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
      `;
};

const getTopPosition = (props: SearchWrapperPorps) => {
  const { hasActive, isTablet } = props;
  if (hasActive && isTablet) return 100;

  return 180;
};

const getLeftPosition = (props: SearchWrapperPorps) => {
  const { hasActive, isTablet } = props;
  if (hasActive && isTablet) return 'calc(50% - 345px)';
  return '96px';
};

const getTabletStyles = (props: SearchWrapperPorps) => {
  if (!props.isTablet) return '';

  return css`
    top: ${getTopPosition}px;
    left: ${getLeftPosition};
    z-index: 10;
  `;
};

export const SearchWrapper = styled(HardElevation)<SearchWrapperPorps>`
  ${getTabletStyles};
  display: flex;
  align-items: center;
  position: ${(props) => (props.isTablet ? 'absolute' : 'relative')};
  border-radius: 64px;
  height: ${(props) => (props.compressed ? 44 : 52)}px;
  width: ${(props) => (props.compressed ? 371 : 374)}px;
  background-color: ${(props) => props.theme.main.colors.white};
  transition: ${HEADER_ANIMATION_DURATION};

  &:hover {
    ${compressedHover}
  }

  border: 1px solid ${(props) => props.theme.main.colors.clay5};
  @media (min-width: ${RESOLUTIONS.large}px) {
    position: absolute;
    left: calc(50% - 186px);
    top: ${({ hasActive }) => (hasActive ? 6 : 10)}px;
  }
`;
