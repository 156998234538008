import { Observer } from 'mobx-react';
import * as React from 'react';

import { Distance } from 'src/models/distance';
import { DistanceHeader } from 'src/modules/racersTable/components';

import { data, generateCheckpointId } from '../../derivations';
import { LeftSideSingleRow } from './LeftSideSingleRow';
import { LeftSideTeamRow } from './LeftSideTeamRow';

type Props = {
  distance: Distance;
};

const Rows = {
  single: LeftSideSingleRow,
  team: LeftSideTeamRow,
};

export const LeftSide: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const dataGenerator = data.generateDataEntries(distanceId);
  const checkpointGenerator = generateCheckpointId(distanceId);
  const Row = Rows[distance.value.type];

  return (
    <Observer>
      {() => {
        const checkpointId = checkpointGenerator.get();
        const data: any[] = dataGenerator.get() || [];

        return (
          <>
            <DistanceHeader name={distance.title()} />
            {data.map((item) => (
              <Row key={`table-lef-row-${item.id}`} value={item} checkpointId={checkpointId} distanceId={distanceId} />
            ))}
          </>
        );
      }}
    </Observer>
  );
};
