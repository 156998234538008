import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Show } from 'src/components/condition';
import { RESOLUTIONS } from 'src/constants';

import { Truncate } from 'styles';

import { Icon, Svg } from '../Icons';
import { Utility } from '../Typography';

const AccordionWrapper = styled.div`
  width: 100%;
`;

const AccordionHeader = styled.div<{ withError?: boolean; disableСompression?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  cursor: ${(props) => !props.disableСompression && 'pointer'};
  background: ${(props) => props.theme.main.colors.clay1};

  .heading-section {
    display: flex;
    align-items: center;

    .heading-text {
      color: ${(props) => props.theme.main.colors.white};
    }

    span {
      margin-right: 7px;
      max-width: 540px;
      ${Truncate};
    }

    ${(props) =>
      props.withError &&
      css`
        span {
          color: ${props.theme.main.colors.error};
        }
      `}
  }

  .arrow-icon {
    min-width: 24px;
    height: 24px;
  }

  .check-icon,
  .error-icon {
    margin: 1px 0 3px;
  }

  .error-icon {
    svg > path {
      stroke-width: 2px;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .heading-section {
      max-width: calc(100% - 32px);
    }
  }
`;

const InnerAccordionHeader = styled(AccordionHeader)`
  background: ${(props) => props.theme.main.colors.white};
  padding: 16px 0;
`;

const AccordionBody = styled.div`
  padding: 24px 32px;
  background: ${(props) => props.theme.main.colors.white};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 32px 56px;
  }
`;

const InnerAccordionBody = styled(AccordionBody)`
  padding: 24px 0;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 32px 0;
  }
`;

type Props = {
  children: React.ReactNode;
  headingVariant?: 'u1' | 'u2' | 'u3' | 'u4';
  headingText: string | React.ReactNode;
  isInner: boolean;
  className?: string;
  initialState?: boolean;
  withError?: boolean;
  disableСompression?: boolean;
};

export const Accordion: React.FC<Props> = ({
  children,
  headingVariant = 'u3',
  headingText,
  isInner = false,
  className,
  initialState = false,
  withError,
  disableСompression,
}) => {
  const [isOpen, setIsOpen] = useState(initialState);

  return (
    <AccordionWrapper className={className}>
      <Show if={isInner}>
        <InnerAccordionHeader
          onClick={() => !disableСompression && setIsOpen(!isOpen)}
          withError={withError}
          disableСompression={disableСompression}
        >
          <div className='heading-section'>
            <Utility variant={headingVariant} weight='bold'>
              {headingText}
            </Utility>
            <Icon className='check-icon' name='CheckLemon' size={16} />
            <Show if={!!withError}>
              <Svg className='error-icon' name='ErrorRed' size={16} />
            </Show>
          </div>
          <Show if={!disableСompression}>
            {isOpen ? (
              <Svg className='arrow-icon' name='CircleUpArrow' size={24} {...testAnchors.useButton('up')} />
            ) : (
              <Svg className='arrow-icon' name='CircleDownArrow' size={24} {...testAnchors.useButton('down')} />
            )}
          </Show>
        </InnerAccordionHeader>
      </Show>

      <Show if={!isInner}>
        <AccordionHeader
          onClick={() => !disableСompression && setIsOpen(!isOpen)}
          withError={withError}
          disableСompression={disableСompression}
        >
          <div className='heading-section'>
            <Utility variant={headingVariant} weight='bold' className='heading-text'>
              {headingText}
            </Utility>
            <Icon className='check-icon' name='CheckLemon' size={16} />
            <Show if={!!withError}>
              <Svg className='error-icon' name='ErrorRed' size={16} />
            </Show>
          </div>
          <Show if={!disableСompression}>
            {isOpen ? (
              <Svg className='arrow-icon' name='CircleUpArrow' size={24} {...testAnchors.useButton('up')} />
            ) : (
              <Svg className='arrow-icon' name='CircleDownArrow' size={24} {...testAnchors.useButton('down')} />
            )}
          </Show>
        </AccordionHeader>
      </Show>

      <Show if={isOpen}>
        <Show if={isInner}>
          <InnerAccordionBody className='accordion-body'>{children}</InnerAccordionBody>
        </Show>

        <Show if={!isInner}>
          <AccordionBody className='accordion-body'>{children}</AccordionBody>
        </Show>
      </Show>
    </AccordionWrapper>
  );
};
