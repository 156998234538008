import React from 'react';
import styled from 'styled-components';

import { theme } from 'styles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  borderWidth?: string;
  borderGradient?: string;
  onClick?: AnyFunction;
};

const Container = styled.div<{ borderWidth: string; borderGradient: string }>`
  z-index: 1;
  .card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    position: relative;

    background: ${({ theme }) => theme.miles.colors.brand.grad.c900C70045};
    box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion1};
    color: ${(props) => props.theme.miles.colors.dark.alpha['900']};

    background-clip: padding-box;
    border: solid ${(props) => props.borderWidth} transparent;
    border-radius: 8px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -${(props) => props.borderWidth};
      border-radius: inherit;
      background: ${(props) => props.borderGradient};
    }
  }
`;

export const CardWirthGradientBorder = ({
  children,
  borderWidth = '2px',
  borderGradient = theme.miles.colors.brand.grad.c500Aur45,
  ...rest
}: Props) => {
  return (
    <Container borderWidth={borderWidth} borderGradient={borderGradient} {...rest}>
      <div className='card'>{children}</div>
    </Container>
  );
};
