import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import { SmoothElevation } from '../Elevation';
import { BodyText } from '../Typography';

type Item = {
  title: string | ReactNode;
  icon?: ReactNode;
  onClick: AnyFunction;
};

type Props = {
  items: Item[];
  handleClose: AnyFunction;
  withPortal?: boolean;
  portalStyles?: AnyObject;
};

const Wrapper = styled(SmoothElevation)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 24px;
  background: ${(props) => props.theme.main.colors.white};
  padding: 24px 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 16px 24px;
    color: ${(props) => props.theme.main.colors.clay1};

    > div {
      margin-right: 16px;

      svg path {
        stroke: ${(props) => props.theme.main.colors.clay1};
      }
    }

    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.main.colors.clay6};
    }
  }
`;

@onClickOutside
export class Popup extends React.PureComponent<Props> {
  handleClickOutside = () => {
    this.props.handleClose();
  };

  render() {
    if (this.props.withPortal) {
      return ReactDOM.createPortal(
        <Wrapper level={5} className='popup' style={this.props.portalStyles}>
          <ul>
            {this.props.items?.map((item, index) => (
              <li key={index} onClick={item.onClick}>
                {item.icon && item.icon}
                <BodyText variant='body2'>{item.title}</BodyText>
              </li>
            ))}
          </ul>
        </Wrapper>,
        document.body,
      );
    }

    return (
      <Wrapper level={5} className='popup'>
        <ul>
          {this.props.items?.map((item, index) => (
            <li key={index} onClick={item.onClick}>
              {item.icon && item.icon}
              <BodyText variant='body2'>{item.title}</BodyText>
            </li>
          ))}
        </ul>
      </Wrapper>
    );
  }
}
