import { borderStore } from 'src/modules/racersTable/stores';
import { form } from 'src/stores';
import { footerVisibility } from 'src/utils';

import { FORM_FILTERS_ID } from '../constants';

import { getRaceId } from '../utils';

import { userModal, loader } from '../actions';

import {
  classesStore,
  wavesStore,
  distancesStore,
  disposers,
  isFindInterceptionHandled,
  lifeStore,
  requestsVersionStore,
  dataStore,
  paginationMetaStore,
  isAppInitializedStore,
} from '../stores';

import {
  observeFormFilters,
  observeViewportChange,
  observeScroll,
  observeHasMore,
  observeFindShortcuts,
  observeFilters,
} from '../reactions';
import { updateHistoryBasedOnFilters, initFiltersAndDistance } from './filters';
import { loadBaseData } from './loadBaseData';
import { refreshApp } from './refreshApp';

async function mount(id: string) {
  lifeStore.startLife(id);
  await lifeStore.waitInLine(id);

  if (lifeStore.isBeingKilled(id)) {
    lifeStore.endLife(id);
    return unmount(id);
  }

  isFindInterceptionHandled.on();
  observeFindShortcuts();

  requestsVersionStore.generate();

  const raceId = +getRaceId()!;

  if (!raceId) {
    return;
  }

  // Init base data
  await loadBaseData(raceId);
  await form.registerForm(FORM_FILTERS_ID);

  if (lifeStore.isBeingKilled(id)) {
    lifeStore.endLife(id);
    return unmount(id);
  }

  // Init main data and ui data
  initFiltersAndDistance();

  // Load counter for bib numbers
  loader.loadCounter();

  observeFormFilters();
  observeFilters();

  updateHistoryBasedOnFilters();
  observeHasMore();

  refreshApp();

  // Init pagination
  observeViewportChange();
  observeScroll();

  loader.loadUntilViewport();

  isAppInitializedStore.on();
  lifeStore.endLife(id);
}

function unmount(id: string) {
  lifeStore.kill(id);

  disposers.disposeAll();

  classesStore.cleanValues();
  wavesStore.cleanValues();
  distancesStore.cleanValues();
  borderStore.clear();

  dataStore.clean();
  paginationMetaStore.clean();

  userModal.close();

  form.clean(FORM_FILTERS_ID);
  footerVisibility.show();
  isAppInitializedStore.off();
}

export { mount, unmount };
