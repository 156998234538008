import axios from 'axios';

import { API_ARTICLES_URL, API_URL, LOAD_ARTICLES, ARTICLE, LOAD_ARTICLE } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { articlesStore, Article } from 'stores';

class Articles extends LoadableService<ArticleType, FiltersType, Article> {
  constructor(store: Article) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_ARTICLES_URL}`,
      resource: ARTICLE,
    });
  }

  @request({ action: LOAD_ARTICLES })
  async loadResourcesRequest(params: FiltersType | {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_ARTICLES, minRequestTime: 800 })
  async loadResources(params: FiltersType = { limit: 15 }, pageNum: number = 1): Promise<any> {
    return super.loadResources(params, pageNum);
  }

  @request({ action: LOAD_ARTICLE })
  async loadArticleRequest(id: string, params: FiltersType | {}): Promise<any> {
    return axios.get(`${this.resourceUrl}/${id}`, {
      params,
    });
  }

  @action({ action: LOAD_ARTICLE, minRequestTime: 800 })
  async loadResource(id: string, params: FiltersType = {}): Promise<any> {
    const [status, response] = await this.loadArticleRequest(id, params);

    if (status) {
      articlesStore.addSelectedValue(response.data.data);
    }
  }

  @action({ action: LOAD_ARTICLE, minRequestTime: 800 })
  async loadMore(): Promise<any> {
    const page = this.store.page + 1;
    const [status, responce] = await this.loadResourcesRequest({
      page: page,
      limit: 15,
    });
    if (status) {
      articlesStore.appendValues(responce.data.data, page, {});
    }
  }
}

const articlesService = new Articles(articlesStore);

export { Articles, articlesService };
