import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { WavesStore } from '../../stores';

function wavesConditions(wavesStore: WavesStore) {
  function generateIsWaves(distanceId: number): IComputedValue<boolean> {
    return computed(() => {
      return !!wavesStore.findWavesByDistance(+distanceId).length;
    });
  }

  const isAnyDistanceHasWaves: IComputedValue<boolean> = computed(() => {
    return !!wavesStore.values.length;
  });

  return { generateIsWaves, isAnyDistanceHasWaves };
}

export { wavesConditions };
