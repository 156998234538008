import axios from 'axios';

import { PAST_SESSION } from 'src/constants';

import { action, request } from 'utils';

import { GET_SESSION_HISTORY } from '../constants';

import { sessionHistoryStore } from '../stores';

export class SessionHistory {
  @request({ action: GET_SESSION_HISTORY })
  private getSessionHistoryRequest(): Promise<any> {
    return axios.get(PAST_SESSION);
  }

  @action({ action: GET_SESSION_HISTORY })
  async getSessionHistory() {
    const [isOk, data] = await this.getSessionHistoryRequest();
    if (isOk) {
      sessionHistoryStore.setSessionHistory(data.data.data.running.data.plan);
    }
  }
}

export const sessionHistoryService = new SessionHistory();
