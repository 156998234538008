import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

type Props = {
  progress: number;
};

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  .percents {
    margin: 0 0 0 8px;
    color: ${(props) => props.theme.main.colors.white};
  }
`;

const WrapperBar = styled.div`
  width: 313px;
  height: 12px;
  background: ${(props) => props.theme.main.colors.clay2};
`;

const Bar = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 12px;
  background: ${(props) => props.theme.main.colors.auroraLemon};
`;

export const ProgressBar = (props: Props) => {
  const { progress } = props;

  return (
    <Wrapper>
      <WrapperBar>
        <Bar progress={progress} />
      </WrapperBar>
      <Utility variant='u4' weight='medium' className='percents'>
        {progress}%
      </Utility>
    </Wrapper>
  );
};
