import { set, mergeWith, isObject, isArray } from 'lodash';
import { isObservableArray } from 'mobx';

import { t } from 'utils';

import { errorsStore } from 'stores';

export class Errors {
  action: string;

  constructor(action: string) {
    this.action = action;
  }

  formatError(error: string) {
    const errorsAray = error.split(' ');
    let fullErrorLabel = '';

    errorsAray.map((error) => {
      return (fullErrorLabel += `${t.staticAsString(error as TranslationLockedKeys)} `);
    });

    return fullErrorLabel;
  }

  hasError(name: string) {
    if (errorsStore.errors.front[this.action] && errorsStore.errors.front[this.action][name]) {
      const error = errorsStore.errors.front[this.action][name][0];
      const formatError = this.formatError(error).trim();

      return formatError.length === 0 ? error : formatError;
    }

    if (errorsStore.errors.api[this.action] && errorsStore.errors.api[this.action][name]) {
      return errorsStore.errors.api[this.action][name][0];
    }

    return '';
  }

  clean() {
    const { action } = this;
    errorsStore.clearError(action);
    errorsStore.clear(action);
  }

  static unfold(values: RawError): ErrorType {
    const keys = Object.keys(values);

    let errors = {};

    keys.forEach((key) => {
      const value = values[key];
      set(errors, key, value);
    });

    return errors;
  }

  static mergeValues(values1: ErrorType = {}, values2: ErrorType = {}) {
    return mergeWith(values1, values2, (value1, value2) => {
      if (!value1 && (isObservableArray(value2) || isArray(value2))) {
        return value2;
      }

      if (!value2 && (isObservableArray(value1) || isArray(value1))) {
        return value1;
      }

      if ((isObservableArray(value1) || isArray(value1)) && (isObservableArray(value2) || isArray(value2))) {
        const isValue1Primitive = value1.every((el) => !isObject(el));
        const isValue2Primitive = value1.every((el) => !isObject(el));

        if (isValue1Primitive && isValue2Primitive) {
          return [...value1, ...value2];
        }

        value1.map((el1, index) => {
          const el2 = value2[index];
          return this.mergeValues(el1, el2);
        });
      }
    });
  }
}
