import { separatedTimeStore } from 'components/form';

import { form } from 'stores';

import { FORM_ID } from '../constants';

import { segmentService } from '../services';

import { racerIdStore, segmentIdStore } from '../stores';

import { formToBeSegmentMapper } from '../mapper';

async function setupSegmentForm() {
  const racerId = racerIdStore.value;
  const segmentId = segmentIdStore.value;

  if (!racerId || !segmentId) {
    return;
  }

  const segment = await segmentService.load(racerId, segmentId);

  if (!segment) {
    return;
  }

  const mappedData = formToBeSegmentMapper.parseApiData(segment);
  const formData = formToBeSegmentMapper.mapFrom(formToBeSegmentMapper.parseApiData(segment));
  separatedTimeStore.parseString(mappedData.time_result);

  form.registerForm(FORM_ID);
  form.merge(FORM_ID, formData);
}

export { setupSegmentForm };
