import { HeaderVariants } from 'modules/header/types';
import { getBackgroundColor, getShadow } from 'modules/header/utils';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

export const TopWrapper = styled.div<{ fullWidth?: boolean; isMiles?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isMiles ? '5fr auto' : '128px 5fr auto')};
  align-items: flex-start;

  max-width: ${(props) => (props.fullWidth ? 'unset' : '1280px')};
  width: 100%;
  flex-direction: row;
  padding: 2px 10px 2px 12px;

  @media (min-width: ${RESOLUTIONS.large}px) {
    width: 1180px;
    padding: 2px 0;
    margin: 0 10px 0 12px;
    position: relative;
  }

  @media (min-width: ${RESOLUTIONS.xlarge}px) {
    width: 1280px;
    padding: 2px 0;
    margin: 0 10px 0 12px;
  }

  .right-block {
    display: flex;
    align-self: center;
    gap: 8px;
    padding: 8px 0;
  }
`;

export const HeaderWrapper = styled.div<{
  hide?: boolean;
  isSnackShown?: boolean;
  variant?: HeaderVariants;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  display: ${(props) => (props?.hide ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: ${({ variant }) => getBackgroundColor(variant)};
  box-shadow: ${({ variant }) => getShadow(variant)};

  ${(props) => (props?.isSnackShown ? 'margin-top:56px;' : '')};
  .border {
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
  //.right-block,
  .logo-block {
    align-self: center;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;
