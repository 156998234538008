import { raceDistancesListStore } from 'modules/registration/stores';

import { Distance } from 'models';

import { distanceToSelectItem } from './distanceToSelectItem';

class DistanceIdMapper implements MapperMiddleware<NewSelectItemType | null, number | null> {
  mapTo(value: NewSelectItemType | null): number | null {
    return value?.value || null;
  }

  mapFrom(value: number | null): NewSelectItemType | null {
    const distances = raceDistancesListStore.value || [];
    const selectedDistance = distances.find((distance) => distance.id === value);

    if (!selectedDistance) {
      return null;
    }

    return distanceToSelectItem(new Distance(selectedDistance));
  }
}

/**
 * @description
 * map distance id to select option and vice verca
 */
const distanceIdMapper = new DistanceIdMapper();
export { distanceIdMapper };
