import { GoogleOAuthProvider } from '@react-oauth/google';
import classNames from 'classnames';
import { Modals } from 'containers';
import { Location } from 'history';
import { observer } from 'mobx-react';
import { Sidebar } from 'modules/Sidebar';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Routes } from 'routes';

import { mount } from 'src/actions';
import { LoadingBar } from 'src/components/loadingBar';
import { NativeAppBanner } from 'src/components/smartBanner';
import { SnackContainerWithStore } from 'src/components/snack';
import { ROUTER, INIT_TRANSLATIONS, ENVIRONMENTS, GOOGLE_CLIENT_ID_KEYS } from 'src/constants';
import { Footer } from 'src/modules/footer';
import { Header } from 'src/modules/header';
import { LanguageSelector } from 'src/modules/languageSelector';
import { Overlay } from 'src/styledComponents/Overlay';
import { ToasterComponent } from 'src/styledComponents/Toast';
import { StyledMain } from 'src/styledComponents/app';
import { CookieBanner } from 'src/styledComponents/cookiesBanner';

import { withProgressSpinner } from 'hocs';

import { Show } from 'components/condition';

import { footerVisibility, history, handleMissingChunk, appEnvControl } from 'utils';

import { EventOrganizerBanner } from './styledComponents/EventOrganizerBanner/EventOrganizerBanner';

let lastPageOpts: AnyObject = {};

type Props = {
  location?: Location;
};

handleMissingChunk();

@withRouter
@withProgressSpinner(INIT_TRANSLATIONS)
@observer
export default class App extends React.Component<Props, any> {
  componentDidMount() {
    mount();
  }

  componentDidUpdate(prevProps: Props) {
    const isPathnameChanged = this.props.location!.pathname !== prevProps.location!.pathname;
    const isSearchChanged = this.props.location!.search !== prevProps.location!.search;
  }

  componentDidCatch(error: Error) {
    console.error(error);
    history.push(ROUTER.ERROR_PAGE);
  }

  isMiles() {
    return history.location.pathname.includes('miles');
  }

  render() {
    const clientIdRelatedToEnv = () => {
      switch (appEnvControl.currentEnvWithDefaultDev()) {
        case ENVIRONMENTS.development:
          return GOOGLE_CLIENT_ID_KEYS.development;
        case ENVIRONMENTS.staging:
          return GOOGLE_CLIENT_ID_KEYS.staging;
        case ENVIRONMENTS.production:
          return GOOGLE_CLIENT_ID_KEYS.production;
        default:
          return GOOGLE_CLIENT_ID_KEYS.production;
      }
    };

    return (
      <React.Fragment>
        <GoogleOAuthProvider clientId={clientIdRelatedToEnv()}>
          <div id='snack-container' />
          <SnackContainerWithStore />
          <StyledMain className={classNames('main-content', { 'main-content-no-footer': footerVisibility.isFooterHidden() })}>
            <ToasterComponent />
            <LanguageSelector />
            <Sidebar />
            <LoadingBar />
            <Show if={!this.isMiles()}>
              <NativeAppBanner />
            </Show>
            <Header />
            <Routes />
            <Show if={!this.isMiles()}>
              <CookieBanner />
              <EventOrganizerBanner />
            </Show>
            <Footer />
          </StyledMain>
          <Overlay />
          <Modals />
        </GoogleOAuthProvider>
      </React.Fragment>
    );
  }
}
