import { generateFinishResult } from './finishResult';
import { generateLeaderDiff } from './leaderDiff';
import { generatePlace } from './place';
import { generatePreviousSplitDiff } from './previousSplitDiff';
import { generateSplitFinishResult } from './splitFinishResult';

/*
 * Results table scheme - https://imgur.com/a/bf1NWvt
 */
export const resultsPresentation = {
  generateFinishResult,
  generateLeaderDiff,
  generateSplitFinishResult,
  generatePreviousSplitDiff,
  generatePlace,
};
