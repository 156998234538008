import axios from 'axios';
import { observable, action, makeObservable, computed } from 'mobx';

import { MILES_CONNECTED_DEVICES } from '../../../../constants';

export type Service = {
  id: string;
  label: string;
  icon: string;
  provider: string;
  integrator: string;
  onConnect: AnyFunction;
  isConnected: boolean;
};

class ConnectedServices {
  @observable
  services: Service[] = [];

  @observable
  modalOpen: boolean = false;

  @observable
  modalServiceId: Service['id'] | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get serviceInModal(): Service | undefined {
    return this.services.find((item) => item.id === this.modalServiceId);
  }

  @computed
  get isStravaConnected(): boolean {
    return this.services.some((item) => item.id === 'strava' && item.isConnected);
  }

  @action
  async loadServices() {
    const connectedServices = (await axios.get(MILES_CONNECTED_DEVICES)).data;
    Object.keys(connectedServices).forEach((device) => {
      const data = connectedServices[device];
      if (data) {
        if (Array.isArray(data)) {
          if (data[0]?.status === 'true') {
            this.setServiceConnected(device, data[0].integrator);
          }
        } else {
          if (data?.status === 'true') {
            this.setServiceConnected(device, data.integrator);
          }
        }
      }
    });
  }

  @action
  setService(value: Service) {
    this.services = [...this.services, value];
  }

  @action
  setServices(value: Service[]) {
    this.services = value;
  }

  @action
  setServiceConnected(id: Service['id'], integrator: string) {
    this.services = this.services.map((item) =>
      item.id === id ? { ...item, id: item.provider.toLocaleLowerCase(), integrator, isConnected: true } : item,
    );
  }

  @action
  disconnectService(id: Service['id']) {
    this.services = this.services.map((item) => (item.id === id ? { ...item, isConnected: false } : item));
  }

  @action
  showModal(serviceId: Service['id']) {
    this.modalOpen = true;
    this.modalServiceId = serviceId;
  }

  @action
  hideModal() {
    this.modalOpen = false;
    this.modalServiceId = null;
  }

  @action
  clear() {
    this.services = [];
    this.hideModal();
  }
}

export { ConnectedServices };
