import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

type Props = {
  color: '#00DB62' | '#C3C6C8' | '#F2BE00';
  text: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .button-text {
    color: ${(props) => props.theme.main.colors.black};
  }
`;

const Square = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background: ${(props) => props.color};
  margin: 0 8px 1px 0;
`;

export const OneButton = (props: Props) => {
  const { color, text } = props;

  return (
    <Wrapper>
      <Square color={color} />
      <Utility weight='bold' variant='u3' className='button-text'>
        {text}
      </Utility>
    </Wrapper>
  );
};
