import { isEmpty } from 'lodash';
import { observable, computed, action, makeObservable, override } from 'mobx';
import moment from 'moment';

import { BACKEND_DATE_FORMAT } from 'src/constants';

import { SearchSerializer } from 'utils';

import { Race as RaceModel } from 'models/race';

import { Loadable } from 'stores/loadable';

class Race extends Loadable<RacesType, FiltersType> {
  declare filters: FiltersType;

  defaultFilters: FiltersType = {
    date_to: '',
    date_from: '',
    sports: [],
    term: '',
    length_from: null,
    length_to: null,
  };

  declare page: number;
  size: number = 9;

  @observable firstLoaded: boolean = false;

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.defaultFilters;
    this.size = params.size || this.size;
    this.page = 0;
  }

  handlers = {
    'location.city': 'like',
    name: 'like',
    'distances.race_length': 'between',
    race_date: '>=',
    race_end_date: '<=',
    sport_id: 'in',
    id: '=',
  };

  // Handlers for default date filters
  defaultHandlers = {
    'location.city': 'like',
    name: 'like',
    'distances.race_length': 'between',
    race_date: '>=',
    race_end_date: '>=',
    sport_id: 'in',
    id: '=',
  };

  nestedJoins = '';
  searchJoin = 'AND';
  orderBy = 'race_date';
  sortedBy = 'asc';

  isPastRacesShown: boolean = false;

  @action
  setIsPastRacesShown(value: boolean): void {
    if (this.isPastRacesShown !== value) {
      this.isPastRacesShown = value;
    }
  }

  @computed
  get sortedListValues(): any {
    const res: AnyObject = {};

    this.values.forEach((item) => {
      const now = moment();
      const raceDate = moment(item.race_date);
      const time = raceDate.format('MMMM YYYY');
      const race = new RaceModel(item);

      if (res[time]) {
        res[time].races.push(race);
      } else {
        res[time] = {
          races: [race],
        };
      }
    });

    return res;
  }

  @computed
  get modelValues(): Array<RaceModel> {
    return this.values.map((race) => new RaceModel(race));
  }

  @computed
  get modelSelectedValue(): RaceModel | null {
    return new RaceModel(this.selected as any);
  }

  @action
  commitFirstLoad() {
    this.firstLoaded = true;
  }

  @override
  clearData() {
    this.values = [];
    this.page = 0;
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null;
      this.filters.searchFields = null;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters as any;
    return new SearchSerializer({
      search: search,
      handlers: this.handlers,
      nestedJoins: this.nestedJoins,
      searchJoin: this.searchJoin,
    });
  }

  @computed
  get hasMoreToLoad() {
    const { paginationMeta, page } = this;
    return (paginationMeta.total_pages || 0) > page;
  }

  @computed
  get total(): number {
    return this.paginationMeta.total || 0;
  }

  @computed
  get isFiltersEmpty(): boolean {
    const searchModel = this.searchDataModel;
    return searchModel.isSearchEmpty((search) => {
      const endDate = moment(search.date_to as any);
      const query = this.filters.q;

      const isSearchEmpty =
        !search.date_to && !search.date_from && isEmpty(search.sports) && !search.term && !search.length_from && !search.length_to;

      if (!endDate.isValid()) {
        return isSearchEmpty && !query;
      }
      return this._isDateInCurrentDate(endDate) && isSearchEmpty && !query;
    });
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }

  getSortedSearchModel(): SearchSerializer {
    const search = this.filters.search as any;
    return new SearchSerializer({
      search: search,
      handlers: this.handlers,
      nestedJoins: this.nestedJoins,
      searchJoin: this.searchJoin,
      // ! Not accepted in the constructor
      // orderBy: this.orderBy,
      // sortedBy: this.sortedBy
    });
  }

  needToSetCurrentDateFilter(params: FiltersType): boolean {
    // If id filter is presented, then race date is must be disabled
    if (!!params.id) {
      return false;
    }

    if (!params.race_date && !params.race_end_date) {
      return true;
    }

    return false;
  }

  currentDateFilter(): string {
    const currentDatePattern = moment();
    return currentDatePattern.format(BACKEND_DATE_FORMAT);
  }

  _isDateInCurrentDate(compareDate: Date | moment.Moment | string): boolean {
    const current = moment();
    return current.isSame(compareDate, 'day');
  }

  @computed
  get filteredValues() {
    return this.values;
  }
}

const homeRacesStore = new Race({
  size: 8,
});

export { Race, homeRacesStore };
export default new Race();
