import ResizeObserver from 'resize-observer-polyfill';

import { progressStore } from 'stores';

import { GET_SEGMENTS_LIST } from '../constants';

import { loadMoreSegments } from '../actions/loadSegments';

import { segmentsStore } from '../stores';

import { observeInfiniteScroll } from './infiniteScroll';

/*
 * NOTE, Initial data fill up, to enable infinite scroll
 * It will recursively call itself util fill up the viewport
 */

let resizeObserver: AnyFunction | any;

function observeViewportChange() {
  resizeObserver = new ResizeObserver(observer);
  const viewportEl = document.getElementById('segments');

  if (!viewportEl) {
    return false;
  }

  resizeObserver.observe(viewportEl);
}

function unmountViewportChange() {
  resizeObserver && resizeObserver.disconnect();
  resizeObserver = null;
}

function observer(entries: ResizeObserverEntry[]) {
  if (!isViewportFilled()) {
    !progressStore.isLoading(GET_SEGMENTS_LIST) && loadMoreSegments();
  }

  if (isViewportFilled() || !segmentsStore.hasMore) {
    observeInfiniteScroll();
    return;
  }
}

function isViewportFilled(): boolean {
  const viewportEl = document.getElementById('segments');

  if (!viewportEl) {
    return false;
  }

  const viewport = window.innerHeight * 2;
  const loadedViewportPosition = viewportEl.getBoundingClientRect().bottom;

  return loadedViewportPosition > viewport;
}

export { observeViewportChange, unmountViewportChange };
