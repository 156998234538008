import { progressStore } from 'stores';

import { PRELOADER, INIT_DISTANCE } from '../constants';

function generate(action: string) {
  function enable() {
    progressStore.log(action, 'progress');
  }

  function disable() {
    progressStore.log(action, 'completed');
  }

  function isLoading() {
    return progressStore.isLoading(action);
  }

  return {
    enable,
    disable,
    isLoading,
  };
}

const preloader = {
  ...generate(PRELOADER),
};

const initDistanceLoader = {
  ...generate(INIT_DISTANCE),
};

export { preloader, initDistanceLoader };
