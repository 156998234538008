import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { modeStore } from '../stores';

const isModeNew: IComputedValue<boolean> = computed(() => modeStore.value === 'new');
const isModeFirstNew: IComputedValue<boolean> = computed(() => modeStore.value === 'first-new');
const isModeEdit: IComputedValue<boolean> = computed(() => modeStore.value === 'edit');

export { isModeEdit, isModeFirstNew, isModeNew };
