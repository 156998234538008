import { escapeRegExp, filter, isEmpty, isString } from 'lodash';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import onClickOutside from 'react-onclickoutside';

import { SELECT_DROP_MAX_HEIGHT } from 'src/constants';

import { Show } from 'components/condition';
import { CustomScrollBar } from 'components/core/customScrollBar';
import { CloseDropdown, DropArrow } from 'components/icons/';

import { isSelectDropUp } from 'utils';

import { InputWrapComponent } from '../inputWrap';
import { SelectListItem } from './SelectListItem';

type Props = {
  id: number;
  itemHeight: number;
  name: string;
  placeholder: string;
  type: string;
  readOnly: boolean;
  disabled: boolean;
  label: string;
  requiredMark: boolean;
  clearable?: boolean;
  errorMessage: string;
  value: AnyObject;
  onChange: AnyFunction;
} & SelectType;

type SelectListState = {
  listHeight: number;
  isOpened: boolean;
  search: string;
  searchedValues: Array<unknown>;
  isDropUp: boolean;
};

@onClickOutside
class SelectListSearch extends React.Component<Props, SelectListState> {
  outerHeight: number | any;

  dropdownRef: RefType<HTMLDivElement> | any = React.createRef<HTMLDivElement>();
  listRef: RefType<HTMLUListElement> | any = React.createRef<HTMLUListElement>();

  static defaultProps = {
    type: 'text',
    disabled: false,
    readOnly: false,
    value: '',
    itemHeight: 34,
  };

  state = {
    listHeight: 0,
    isOpened: false,
    search: '',
    searchedValues: [],
    isDropUp: false,
  };

  static getDerivedStateFromProps(props: Props, state: SelectListState) {
    if (state.isOpened) {
      const list = isEmpty(state.searchedValues) ? props.dropListItems : state.searchedValues;

      return {
        listHeight: list.length * props.itemHeight,
      };
    }
  }

  handleClickOutside = () => {
    this.handleCloseDropList();
  };

  handleCloseDropList = () => {
    this.setState({
      listHeight: 0,
      isOpened: false,
    });
  };

  handleOpenDropList() {
    const { isOpened, searchedValues } = this.state;
    const { dropListItems, itemHeight } = this.props;
    let listHeight = 0;

    const list = isEmpty(searchedValues) ? dropListItems : searchedValues;

    this.outerHeight = this.getOuterHeight();

    let isDropUp = this.state.isDropUp;

    if (!isOpened) {
      listHeight = list.length * itemHeight;

      const dropRect = this.dropdownRef.current && this.dropdownRef.current.getBoundingClientRect();
      isDropUp = isSelectDropUp(dropRect, listHeight);
    }

    this.setState({
      listHeight,
      isOpened: !isOpened,
      isDropUp,
    });
  }

  onSelect = (value: SelectItemType) => {
    const { onChange, name } = this.props;

    this.handleCloseDropList();

    if (value?.id === 0) {
      return;
    }

    onChange({ name, value });
  };

  handleDropDownListItem() {
    const { dropListItems, value } = this.props;
    const { searchedValues } = this.state;
    const list = isEmpty(searchedValues) ? dropListItems : searchedValues;

    const res: any = [];
    res.push(
      list.map((item: AnyObject, key: number) => {
        return (
          <SelectListItem item={item} activeItem={value?.id || ''} key={`${key}-${item.id}`} title={item.title} onSelect={this.onSelect} />
        );
      }),
    );

    return res;
  }

  getCurrentItem = () => {
    const { value, dropListItems } = this.props;

    const currentItem = (dropListItems || []).find((item: AnyObject) => {
      if (item.id === value?.id) {
        return item;
      }
    });

    if (!currentItem) {
      return '';
    }

    if (isString(currentItem.title)) return currentItem.title;
    return renderToStaticMarkup(currentItem.title);
  };

  getOuterHeight() {
    if (!this.listRef.current) {
      return 0;
    }

    return this.listRef.current.getBoundingClientRect().height;
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { dropListItems } = this.props;
    const { value } = e.target;
    let searchedValues = [...dropListItems];

    searchedValues = filter(dropListItems, (item: AnyObject) => new RegExp(escapeRegExp(value), 'i').test(item.title));

    if (isEmpty(searchedValues)) {
      searchedValues.push({
        id: 0,
        title: 'No search results',
        noResults: true,
      });
    }

    this.setState({
      search: value,
      searchedValues,
    });
  };

  renderInput = () => {
    const { placeholder, type, readOnly, disabled, name } = this.props;

    const { search, listHeight } = this.state;
    const isOpened = !!listHeight;

    if (!isOpened) {
      return (
        <React.Fragment>
          <div className='dropdown-wrap'>
            <input
              {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
              id={name}
              type={type}
              name={name}
              value={this.getCurrentItem()}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              className='dropdown-chosen'
              onChange={() => {}}
            />
          </div>
          <div className='dropdown-click-zone' onClick={this.handleOpenDropList.bind(this)} />
        </React.Fragment>
      );
    }

    return (
      <div className='dropdown-wrap'>
        <input value={search} autoFocus placeholder={placeholder} className='dropdown-chosen' onChange={this.handleSearch} />
      </div>
    );
  };

  render() {
    const { dropListItems, disabled, name, value, label, requiredMark, errorMessage, additionalInfo, id, clearable } = this.props;

    const { listHeight, searchedValues, isDropUp, isOpened } = this.state;
    const hasItems = dropListItems && !!dropListItems.length;
    const isValuePresented = Boolean(this.getCurrentItem());

    const list = isEmpty(searchedValues) ? dropListItems : searchedValues;

    const classNameForUl =
      name.includes('country_id') || name.includes('nationality_id') ? 'dropdown-list country-nationality-list' : 'dropdown-list';

    return (
      <InputWrapComponent
        disabled={disabled}
        name={name}
        label={label}
        additionalInfo={additionalInfo}
        requiredMark={requiredMark}
        errorMessage={errorMessage}
      >
        <div className='dropdown' ref={this.dropdownRef}>
          {this.renderInput()}

          <Show if={(hasItems && !isValuePresented) || !clearable}>
            <div onClick={!isOpened ? this.handleOpenDropList.bind(this) : this.handleCloseDropList.bind(this)}>{DropArrow}</div>
          </Show>

          <Show if={Boolean(isValuePresented && clearable)}>
            <div onClick={() => this.props.onChange({ name, value: null })}>{CloseDropdown}</div>
          </Show>

          {hasItems && !!listHeight && (
            <div
              className={isDropUp ? 'dropdown-expand-upper' : 'dropdown-expand'}
              style={{
                height: `${listHeight}px`,
                maxHeight: SELECT_DROP_MAX_HEIGHT,
              }}
            >
              <CustomScrollBar>
                <ul className={classNameForUl} id={`select-list-${id || name}`} ref={this.listRef}>
                  <List name={name} list={list} active={value} onSelect={this.onSelect} onClose={this.handleClickOutside} />
                </ul>
              </CustomScrollBar>
            </div>
          )}
        </div>
      </InputWrapComponent>
    );
  }
}

type ListProps = {
  list: Array<Object>;
  active: AnyObject | null;
  onSelect: AnyFunction;
  onClose: Function;
  name: string;
};

class List extends React.Component<ListProps> {
  render() {
    const { list, active, onSelect, name } = this.props;

    return list.map<React.ReactNode>((item: AnyObject | any, key: number) => {
      return (
        <SelectListItem
          name={name}
          item={item}
          activeItem={active?.id || ''}
          key={`${key}-${item.id}`}
          title={item.title}
          onSelect={onSelect}
        />
      );
    });
  }
}

export { SelectListSearch, SelectListSearch as SelectWithSearch };
