import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const DefaultAvatarPath = (
  <>
    <rect width='64' height='64' rx='32' fill='#070A0F' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32 32.6667C36.0501 32.6667 39.3333 29.3835 39.3333 25.3333C39.3333 21.2832 36.0501 18 32 18C27.9499 18 24.6667 21.2832 24.6667 25.3333C24.6667 29.3835 27.9499 32.6667 32 32.6667ZM18.6991 38.6884C22.6571 36.548 27.1887 35.3333 32 35.3333C36.8113 35.3333 41.3429 36.548 45.3009 38.6884L46 39.0664V46H18V39.0664L18.6991 38.6884Z'
      fill='white'
      fillOpacity='0.85'
    />
  </>
);

export const DefaultAvatar = (props: IconProps) => <SvgIcon path={DefaultAvatarPath} viewBox='0 0 64 64' {...props} />;
