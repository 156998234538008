import { mapDistances } from 'modules/home/utils';

import { ROUTER } from 'src/constants';

import { encodeQuery, setNestedValidation, history, fireToast, t } from 'utils';

import { form, errorsStore } from 'stores';

import { MY_RESULTS_FORM, RESULT_TYPES } from '../constants';

import { dataMapper } from '../utils';

import { myResultsService } from '../services';

import { resultStore, tabStore } from '../stores';

import { getResultValidation } from '../validation';

export const openResultForm = (racerId: number) => (): void => {
  const value = resultStore.virtual.find((item) => item.racers[0].id === racerId);

  resultStore.toggleEditResult(true);

  resultStore.setRacerId(racerId);
  resultStore.setSelected(value!);
};

export const openEditResult = (racerId: number) => (): void => {
  const value = resultStore.virtual.find((item) => item.racers[0].id === racerId);

  if (!value) {
    return;
  }

  if (!value.published) {
    fireToast(t.staticAsString('toast.message.unpublishedRace'), 'warning', t.staticAsString('toast.title.unpublishedRace'), 'top-right');
    return;
  }

  const mappedData = dataMapper.formResult(value!);

  resultStore.setRacerId(racerId);
  resultStore.setIsEdit(mappedData);
  resultStore.setSelected(value);

  resultStore.toggleEditResult(true);
  form.merge(MY_RESULTS_FORM, mappedData);
};

export const closeResultForm = (): void => {
  resultStore.toggleEditResult(false);
  resultStore.setRacerId(null);
};

export const redirectRaceUrl = (distance: { distanceId: number; raceId: number; type: string } = {} as any): string => {
  const { distanceId, raceId, type } = distance;
  return encodeQuery.generatePath(
    ROUTER.RACE_RESULT,
    {
      id: raceId,
    },
    {
      currentDistanceId: distanceId,
      currentDistanceType: type,
    },
  );
};

export const submitResult = (load?: (distanceId: number) => Promise<void>) => async () => {
  errorsStore.clear(MY_RESULTS_FORM);

  const values = form.fetchWithoutObservable<any>(MY_RESULTS_FORM);
  const schema = getResultValidation();
  const id = resultStore.racerId;
  if (!id) {
    return;
  }

  const dataForValidation = dataMapper.resultForValidation(values);
  const valid = setNestedValidation(MY_RESULTS_FORM, schema, dataForValidation);

  if (valid) {
    const mappedData = dataMapper.postResult(values);
    const status = await myResultsService.sendResult(id, mappedData);

    switch (status) {
      case 'conflict':
      case 'success':
        load && load(resultStore.selectedDistance?.id);
        closeResultForm();
        break;
    }
  }
};

export const editResult = (load?: (distanceId: number) => Promise<void>) => async () => {
  errorsStore.clear(MY_RESULTS_FORM);

  const values = form.fetchWithoutObservable<any>(MY_RESULTS_FORM);
  const schema = getResultValidation();
  const id = resultStore.racerId;
  if (!id) {
    return;
  }

  const dataForValidation = dataMapper.resultForValidation(values);
  const valid = setNestedValidation(MY_RESULTS_FORM, schema, dataForValidation);

  if (valid) {
    const patchData = dataMapper.patchResult(values);
    const postData = dataMapper.postResult(values);

    let status = await myResultsService.editResult(id, patchData);

    if (status === 'notFound') {
      status = await myResultsService.sendResult(id, postData);
    }

    switch (status) {
      case 'conflict':
      case 'success':
        load && load(resultStore.selectedDistance?.id);
        closeResultForm();
        break;
    }
  }
};

export const onViewAll = (type: 'races' | 'virtual' | 'cumulative') => (): void => {
  switch (type) {
    case RESULT_TYPES.races:
      history.push(ROUTER.PROFILE_RESULTS_RACES);
      break;

    case RESULT_TYPES.virtual:
      history.push(ROUTER.PROFILE_RESULTS_VIRTUAL);
      break;

    default:
      return;
  }
};

export const handleChangeResultsPage = async (page: number, tab: 'classic' | 'virtual' | 'cumulative') => {
  switch (tab) {
    case 'classic':
      const classic = await myResultsService.loadClassic(page);
      resultStore.setClassic(mapDistances(classic.data), classic.meta.pagination);
      tabStore.changeValues([...tabStore.values, ...classic.data]);
      break;

    case 'virtual':
      const virtual = await myResultsService.loadVirtual(page);
      resultStore.setVirtual(mapDistances(virtual.data), virtual.meta.pagination);
      tabStore.changeValues([...tabStore.values, ...virtual.data]);
      break;
    case 'cumulative':
      const cumulative = await myResultsService.loadCumulative(page);
      tabStore.changeValues([...tabStore.values, ...cumulative.data]);
      break;
    default:
      return;
  }

  window.scrollTo({
    top: 0,
  });
};
