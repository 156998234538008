/**
 * @description
 * Coupon type local for the registration
 * L - local
 */
export type LCoupon = {
  uses_per_coupon: number;
  qty: number;
  usage_type: LCouponUsageType;
  discount_amount: string;
  discount_percentage: boolean;
  uses_per_customer?: number;
  code: string;
  id: number;
};

export enum LCouponUsageType {
  DistanceOnly = 0,
  CustomFieldsOnly = 1,
  DistanceAndCustomFields = 2,
}
