import * as React from 'react';

export const SearchYellow = (
  <svg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' width='40' height='40' rx='20' fill='#D8FF3A' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.4394 19.1667C24.4394 21.8026 22.3025 23.9394 19.6666 23.9394C17.0307 23.9394 14.8939 21.8026 14.8939 19.1667C14.8939 16.5308 17.0307 14.394 19.6666 14.394C22.3025 14.394 24.4394 16.5308 24.4394 19.1667ZM25.7801 23.5124C26.6531 22.2863 27.1666 20.7865 27.1666 19.1667C27.1666 15.0246 23.8088 11.6667 19.6666 11.6667C15.5245 11.6667 12.1666 15.0246 12.1666 19.1667C12.1666 23.3088 15.5245 26.6667 19.6666 26.6667C21.2864 26.6667 22.7863 26.1532 24.0123 25.2801L27.1161 28.3839L28.8838 26.6161L25.7801 23.5124Z'
      fill='#002A7C'
    />
  </svg>
);
