import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_CLASSIC_RESULTS, API_CUMULATIVE_RESULTS, API_SEND_RESULT, API_URL, API_VIRTUAL_RESULTS } from 'src/constants';

import { action, request } from 'utils';

import { MY_RESULTS_FORM, LOAD_MY_RESULTS } from '../constants';

import { handleApiError, profileMapFrom } from '../utils';

import { Result as ResultType, ResponseType } from '../types';

class MyResult {
  params: { with: string; limit: number; withCount: string } = {
    with: 'race.sport;goal',
    withCount: 'racers;teams',
    limit: 20,
  };

  @request({ action: LOAD_MY_RESULTS })
  async loadClassicRequest(params: Object): Promise<any> {
    return axios.get(`${API_URL}${API_CLASSIC_RESULTS}`, { params });
  }

  @request({ action: LOAD_MY_RESULTS })
  async loadVirtualRequest(params: Object): Promise<any> {
    return axios.get(`${API_URL}${API_VIRTUAL_RESULTS}`, { params });
  }

  @request({ action: LOAD_MY_RESULTS })
  async loadCumulativeRequest(params: Object): Promise<any> {
    return axios.get(`${API_URL}${API_CUMULATIVE_RESULTS}`, { params });
  }

  @action({ action: LOAD_MY_RESULTS })
  async loadClassic(page = 1): Promise<any> {
    const params = {
      ...this.params,
      from: (page - 1) * this.params.limit,
      page,
    };

    const [status, response] = await this.loadClassicRequest(params);

    if (status) {
      return {
        data: profileMapFrom(response.data.data),
        meta: response.data.meta,
      };
    }
  }

  @action({ action: LOAD_MY_RESULTS })
  async loadVirtual(page = 1): Promise<any> {
    const params = {
      ...this.params,
      from: (page - 1) * this.params.limit,
      page,
    };

    const [status, response] = await this.loadVirtualRequest(params);

    if (status) {
      return {
        data: profileMapFrom(response.data.data),
        meta: response.data.meta,
      };
    }
  }

  @action({ action: LOAD_MY_RESULTS })
  async loadCumulative(page = 1): Promise<any> {
    const params = {
      ...this.params,
      from: (page - 1) * this.params.limit,
      page,
    };

    const [status, response] = await this.loadCumulativeRequest(params);

    if (status) {
      return {
        data: profileMapFrom(response.data.data),
        meta: response.data.meta,
      };
    }
  }

  // Form manipulation

  @request({ action: MY_RESULTS_FORM })
  async sendResultRequest(id: number, data: ResultType): Promise<any> {
    const url = generatePath(`${API_URL}${API_SEND_RESULT}`, { id });
    return axios.post(url, data);
  }

  @action({ action: MY_RESULTS_FORM, minRequestTime: 400 })
  async sendResult(id: number, data: ResultType): Promise<ResponseType> {
    const [status, response] = await this.sendResultRequest(id, data);

    handleApiError(response);

    if (response.status === 404) {
      return 'notFound';
    }

    if (response.status === 409) {
      return 'conflict';
    }

    return status ? 'success' : 'error';
  }

  @request({ action: MY_RESULTS_FORM })
  async editResultRequest(id: number, data: ResultType): Promise<any> {
    const url = generatePath(`${API_URL}${API_SEND_RESULT}`, { id });
    return axios.patch(url, data);
  }

  @action({ action: MY_RESULTS_FORM, minRequestTime: 400 })
  async editResult(id: number, data: ResultType): Promise<ResponseType> {
    const [status, response] = await this.editResultRequest(id, data);

    handleApiError(response);

    if (response.status === 404) {
      return 'notFound';
    }

    if (response.status === 409) {
      return 'conflict';
    }

    return status ? 'success' : 'error';
  }
}

const myResultsService = new MyResult();
export { myResultsService };
