import * as React from 'react';

type Params = {
  service: ILoadableService<any, any, ILoadable<any, any>>;
  // FIXME: types described below by Gleb don't resolve error with passing service
  // service: ILoadableService<Resource, FiltersType, ILoadable<Resource, FiltersType>>;
  paramName?: string;
  loadResourcesOnResourceLoad?: boolean;
  resourcesOnly?: boolean;
  cleanOnUnmount?: boolean;
};

// // DEPRECATED
// TODO Do not reload list if loadResourcesOnResourceLoad is applied. Currenly every resource load triggering loading resources.
// WARNING, withRouter must be applied
export const loadable =
  ({ service, loadResourcesOnResourceLoad = false, resourcesOnly = false, cleanOnUnmount = true, paramName = 'id' }: Params) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<P> {
      UNSAFE_componentWillMount() {
        this.loadResources();
        this.loadResource();
      }

      componentWillUnmount() {
        if (cleanOnUnmount) {
          service.clean();
        }
      }

      componentDidUpdate(prevProps: any) {
        if (resourcesOnly) {
          return;
        }
        const selectedValue = service.getStore().selected;
        // @ts-ignore
        if ((selectedValue && selectedValue.id + '') !== this.props.match.params[paramName] + '') {
          this.loadResource();
        }
        // @ts-ignore
        if (prevProps.match.params[paramName] !== this.props.match.params[paramName]) {
          this.loadResources();
        }
      }

      loadResource = (props = this.props) => {
        if (resourcesOnly) {
          return;
        }
        if (!props) {
          props = this.props;
        }
        // @ts-ignore
        const matchParam = props.match.params[paramName];
        if (matchParam) {
          service.loadResource(matchParam);
        }
      };

      loadResources = () => {
        // @ts-ignore
        const matchParam = this.props.match.params[paramName];
        if (!matchParam) {
          service.loadResources();
        }
        if (matchParam && loadResourcesOnResourceLoad) {
          service.loadResources();
        }
      };

      render() {
        return <WrappedComponent {...this.props} />;
      }
    } as any;
