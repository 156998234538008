import * as React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img<{ size: number | string }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

type Props = {
  name: string;
  size?: number | string;
  className?: string;
  onClick?: AnyFunction;
  id?: string;
} & TestAnchortProperties;

export const Icon: React.FC<Props> = (props) => {
  const { name, size = 24, className, onClick, id, ...rest } = props;

  return (
    <StyledImg
      id={id}
      onClick={onClick}
      className={className}
      size={size}
      src={require(`src/styledComponents/Icons/Files/${name}.svg`)}
      alt='name'
      {...rest}
    />
  );
};
