class LocalStorageHandler {
  saveToStorage = (stringState: string, storeName: string) => {
    localStorage.setItem(storeName, stringState);
  };

  getFromStorage = (storeName: string): string => {
    return localStorage.getItem(storeName) || '';
  };

  clearStorageItem = (storeName: string) => {
    localStorage.removeItem(storeName);
  };
}

const localStorageHandler = new LocalStorageHandler();
export { localStorageHandler };
