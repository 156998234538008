import { Observer } from 'mobx-react';
import * as React from 'react';
import { ScrollSync } from 'react-scroll-sync';

import { LeftSideWrapper, RightSideWrapper, TableWrapper } from 'src/modules/racersTable/components';
import { onHorizontalScroll } from 'src/modules/racersTable/utils';

import { RightSide, LeftSide, RightHeader, LeftHeader } from '../../components/ShowAll';

import { currentDistance } from '../../derivations';

export const AllTable = () => (
  <Observer>
    {() => {
      const distance = currentDistance.model.get();
      if (distance) return null;

      return (
        <ScrollSync onSync={onHorizontalScroll}>
          <TableWrapper>
            <LeftHeader />
            <RightSideWrapper sticky>
              <RightHeader />
            </RightSideWrapper>

            <LeftSideWrapper>
              <LeftSide />
            </LeftSideWrapper>
            <RightSideWrapper withBorder>
              <RightSide />
            </RightSideWrapper>
          </TableWrapper>
        </ScrollSync>
      );
    }}
  </Observer>
);
