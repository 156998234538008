import { observable, action, makeObservable } from 'mobx';

class AnyStore<Value> {
  constructor(value: Value | null = null) {
    makeObservable(this);
    this.value = value;
    this.defaultValue = value;
  }

  defaultValue: Value | null;

  @observable value: Value | null = null;

  @action
  setValue(value: Value) {
    this.value = value;
  }

  @action
  clear() {
    this.value = this.defaultValue;
  }
}

export { AnyStore };
