import { dataStore } from '../stores';

import { currentDistance } from '../derivations';

export const getTeamDistanceRacersCount = () => {
  const distance = currentDistance.model.get();
  const distanceId = distance?.value.id;
  const isTeam = Boolean(currentDistance.model.get()?.isTeam());

  const distanceArr = isTeam && distanceId && dataStore.value[distanceId] && dataStore.value[distanceId][1];

  return distanceArr && distanceArr.map((el) => el.racers?.length).reduce((result: number, current: number) => result + current, 0);
};
