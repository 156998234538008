import { HEADER_FORM } from 'src/constants';
import { form } from 'src/stores';
import { encodeQuery, history } from 'src/utils';

import { mapQueryToForm } from '../utils';

export const queryToForm = () => {
  const queryObject = encodeQuery.parseQuery(history.location.search);
  form.merge(HEADER_FORM, mapQueryToForm(queryObject));
};
