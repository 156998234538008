import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { TeamParticipants } from 'src/modules/racersTable/components';
import { Utility } from 'src/styledComponents/Typography';

import { userModal } from '../../actions';

const Wrapper = styled.div<{ grid: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.grid};
  gap: 8px;

  padding: 20px 10px 20px 40px;
  height: 132px;
  width: 100%;

  &:nth-child(even) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
`;

type Props = {
  value: RacersTeamType;
  withBibs?: boolean;
  bibCellActive?: boolean;
};

export const LeftSideTeamRow: React.FC<Props> = (props) => {
  const { value, withBibs = true, bibCellActive = false } = props;
  const showBibCell = withBibs || bibCellActive;

  const grid = `${showBibCell ? '60px' : ''} 1fr`;

  const bib = withBibs ? value.bib_number : '';

  return (
    <Wrapper grid={grid}>
      <Show if={showBibCell}>
        <StyledUtility variant='u2' weight='medium'>
          {bib}
        </StyledUtility>
      </Show>
      <Utility variant='u2' weight='bold'>
        {value.name}
      </Utility>

      <div />
      <TeamParticipants name={value.name} members={value.racers || []} onShowParticipant={userModal.onOpen} />
    </Wrapper>
  );
};
