import axios from 'axios';
import { Data, Response } from 'modules/registration/types';
import { generatePath } from 'react-router-dom';

import { API_RACER, API_URL } from 'src/constants';

import { request, action } from 'utils';

const actionId = 'RACERS_LOAD_RACER";';

class LoadRacerService {
  @request({ action: actionId })
  request(id: number): any {
    const url = generatePath(API_URL + API_RACER, { id });
    return axios.get<any>(url, { params: { with: 'fields;team' } });
  }

  @action({ action: actionId })
  async load(id: number): Promise<RacersType | null> {
    const [status, response] = await this.request(id);

    if (!status) {
      return null;
    }

    return response.data.data;
  }

  @request({ action: actionId })
  updateRacerRequest(racerId: number, data: any): any {
    const url = generatePath(API_URL + API_RACER, { id: racerId });
    return axios.patch<any>(url, data);
  }

  @action({ action: actionId })
  async updateRacer(
    racerId: number,
    racerData: Data.Backend.Racer,
  ): Promise<{
    data: null | Response.Registration.SingleGroup;
    isOk: boolean;
    errors: RawError;
    message: string | null;
  }> {
    const [isOk, response] = await this.updateRacerRequest(racerId, racerData);

    return {
      isOk,
      data: response.data,
      errors: response.data.errors,
      message: response.data.message,
    };
  }
}

export { LoadRacerService };
