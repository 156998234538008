import { fetchBEDiff } from './fetchBEDiff';
import { findSplit } from './findSplit';
import { formatFallbackResult, formatFallbackSplit, formatFallbackLeaderDiff } from './formatFallback';
import { formatTime, formatDiff } from './formatTime';
import { parseTime } from './parseTime';

export const resultsUtil = {
  formatTime,
  formatDiff,
  formatFallbackResult,
  formatFallbackSplit,
  formatFallbackLeaderDiff,
  findSplit,
  parseTime,
  fetchBEDiff,
};
