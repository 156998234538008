import * as React from 'react';
import styled from 'styled-components';

import { MobileAdditionalInfo, TeamMembers } from 'src/modules/racersTable/components';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { mobileItemHeight } from '../../utils';

import { userModal } from '../../actions';

import { classesStore, wavesStore } from '../../stores';

type Props = {
  value: RacersTeamType;
  withBibs?: boolean;
};

const Wrapper = styled.div<{ active: boolean; height: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  padding: 20px 0;
  background-color: ${(props) => props.theme.main.colors.clay6};
  overflow: hidden;
  transition: height 300ms ease-in-out;

  &:nth-of-type(odd) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

const Header = styled.div<{ withBibs: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.withBibs ? 35 : 0)}px 1fr 24px;
  gap: ${(props) => (props.withBibs ? 8 : 0)}px;

  align-items: center;
  padding: 0 20px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledSvg = styled(Svg)<{ active?: boolean }>`
  cursor: pointer;
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'initial')};
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
  white-space: nowrap;
`;

const CollapsibleInfo = styled.div`
  padding: 0 20px;
`;

export const MobileTeamItem: React.FC<Props> = (props) => {
  const { value, withBibs = true } = props;
  const [active, setActive] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const classModel = classesStore.find(+value.class_id!);
  const waveModel = wavesStore.find(+value.wave_id!);

  const expandedHeight = active ? ref.current?.offsetHeight || 0 : 0;
  const height = mobileItemHeight(value) + expandedHeight;

  return (
    <Wrapper active={active} height={height}>
      <Header withBibs={withBibs}>
        <StyledUtility variant='u2' weight='medium'>
          {withBibs ? value.bib_number : ''}
        </StyledUtility>
        <Utility variant='u1' weight='bold'>
          {value.name}
        </Utility>
        <StyledSvg name='CircleDown' size={24} active={active} onClick={() => setActive(!active)} />
      </Header>
      <MobileAdditionalInfo classTitle={classModel?.value.title} wave={waveModel?.value.name} withBibs={withBibs} />
      <CollapsibleInfo ref={ref}>
        <TeamMembers members={value.racers || []} onOpenRacer={userModal.onOpen} />
      </CollapsibleInfo>
    </Wrapper>
  );
};
