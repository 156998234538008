import { ROUTER } from 'src/constants';

import { GiftBox } from '../../../styledComponents/Icons/Components/GiftBox';

export const MENU_LINKS = [
  {
    title: 'settings.company',
    links: [
      { title: 'navigation.aboutLink', link: ROUTER.ABOUT, ATN: 'about' },
      { title: 'navigation.press', link: ROUTER.PRESS, isExternal: true, ATN: 'press' },
      { title: 'navigation.careers', link: ROUTER.CAREER, isExternal: true, ATN: 'careers' },
    ],
  },
  {
    title: 'redesign.policies',
    links: [{ title: 'navigation.terms', link: ROUTER.PUBLIC_TERMS, ATN: 'terms' }],
  },
  {
    title: 'redesign.support',
    links: [
      { title: 'navigation.faqLink', link: ROUTER.PUBLIC_FAQ, ATN: 'faq' },
      { title: 'navigation.organizer', link: ROUTER.ORGANIZER, ATN: 'organizer' },
    ],
  },
  {
    title: 'redesign.inspiration',
    links: [
      {
        icon: GiftBox,
        title: 'navigation.giftCard',
        link: ROUTER.GIFT_CARDS,
        ATN: 'giftCards',
      },
      {
        title: 'articles.articles',
        link: ROUTER.ARTICLES,
        ATN: 'insnpiration',
      },
    ],
  },
  // {
  //   title: 'navigation.raceIdMiles',
  //   links: [{ title: 'navigation.trainingPlan', link: ROUTER.MILES_TRAINING_PLAN, ATN: 'faq' }],
  // },
];
