import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';

export const FilterOpener: StyledComponent<'span', any, { isActive: boolean }> = styled('span')(
  ({ isActive, theme }: { theme: DefaultTheme; isActive: boolean }) => css`
    align-items: center;
    position: relative;
    padding: 6px 40px 6px 16px;

    background-color: ${isActive ? theme.main.colors.clay3 : '#fff'};
    color: ${isActive ? '#fff' : theme.main.colors.clay3};
    outline: none;
    border: 2px solid #656b6f;
    border-radius: 129px;

    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    white-space: nowrap;

    .icon-box {
      position: absolute;
      top: 50%;
      right: 14px;
      display: flex;

      svg {
        cursor: pointer;
        transform: translate3D(0, -50%, 0);

        path {
          stroke: ${isActive ? theme.main.colors.clay7 : theme.main.colors.clay3};
        }
      }
    }
  `,
);
