import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

const PageContentWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .section-content {
    padding: 20px;
    width: 100%;

    @media (min-width: ${RESOLUTIONS.medium}px) {
      width: calc(100vw - 48px);
      padding: 0;
    }
    @media (min-width: ${RESOLUTIONS.large}px) {
      width: 1180px;
      padding: 0;
    }
    @media (min-width: ${RESOLUTIONS.xlarge}px) {
      width: 1280px;
      padding: 0;
    }
  }
`;

type Props = {
  className?: string;
  id?: string;
  children?: React.ReactNode;
};

export const PageContent: React.FC<Props> = (props) => {
  const { className, children, id } = props;

  return (
    <PageContentWrap id={id} className={className}>
      <div className='section-content'>{children}</div>
    </PageContentWrap>
  );
};
