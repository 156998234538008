import { truncate } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { DEFAULT_RACE_IMAGE, LOAD_RACE } from 'src/constants';
import { racesStore, progressStore } from 'src/stores';
import { Map } from 'src/styledComponents/Map';
import { PageContent } from 'src/styledComponents/PageContent';
import { Skeleton } from 'src/styledComponents/Skeleton';

import SEO from 'components/SEO';

import { stripHtml } from 'utils';

import { Pin, ZoomControls } from '../components';

import { handleChangeMap, handleMountMap } from '../actions';

import { mapZoomStore } from '../stores';

const StyledPage = styled(PageContent)`
  .section-content {
    position: relative;
    margin-top: 64px;
    margin-bottom: 64px;
    height: 804px;
  }
`;

export const MapContainer = () => {
  return (
    <Observer>
      {() => {
        const { value } = racesStore.modelSelectedValue!;

        if (progressStore.isLoading(LOAD_RACE) || !value) {
          return (
            <StyledPage>
              <Skeleton width='100%' height='calc(100vh - 88px)' variant='rectangular' />
            </StyledPage>
          );
        }

        if (!value.location) {
          return <Redirect to='./about' />;
        }

        const center = {
          lng: Number(value.route?.lng),
          lat: Number(value.route?.lat),
        };

        return (
          <StyledPage>
            <SEO
              title={racesStore.selected?.name}
              description={truncate(stripHtml(racesStore.selected?.description ?? ''), { length: 250 })}
              ogImage={racesStore.selected?.image || DEFAULT_RACE_IMAGE}
            />
            <ZoomControls />
            <Map zoom={mapZoomStore.value!} center={center} onChange={handleChangeMap} onMount={handleMountMap}>
              <Pin icon={value.sport.icon} lat={Number(value.route?.lat)} lng={Number(value.route?.lng)} />
            </Map>
          </StyledPage>
        );
      }}
    </Observer>
  );
};
