import React, { useState } from 'react';
import styled from 'styled-components';

import { InputWrapComponent } from 'src/styledComponents/InputTools';

const getBorderColor = (color?: string) => (props) => {
  return !props.hasError
    ? color || props.theme.main.colors.clay3
    : props.isWarning
    ? props.theme.main.colors.warning
    : props.theme.main.colors.error;
};

const getBackgroundColor = (props) => {
  return !props.hasError
    ? props.theme.main.colors.clay6
    : props.isWarning
    ? props.theme.main.colors.warning20
    : props.theme.main.colors.error20;
};

const TextAreaAutosize = styled.label<{ hasError?: boolean; isWarning?: boolean }>`
  display: inline-grid;
  vertical-align: top;
  position: relative;
  padding: 0 !important;
  align-items: stretch;
  background: ${getBackgroundColor};
  border-radius: 2px;
  border: 2px solid ${getBorderColor('transparent')};

  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};

  &::after,
  textarea {
    grid-area: 1 / 1;
    width: auto;
    min-width: 1em;
    padding: 12px 16px;
    font: inherit;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
    max-height: 88px;
    word-break: break-word;
  }
  textarea {
    &::placeholder {
      color: ${(props) => props.theme.main.colors.clay4};
    }
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
    padding: 12px 20px;
  }

  &:hover,
  :focus,
  :focus-within {
    outline: none;
    border-color: ${getBorderColor()};
    textarea:focus {
      outline: none;
      border-color: ${getBorderColor()};
    }
  }
`;

const TextAreaField = (props: BaseInputProps) => {
  const { placeholder, value, readOnly, disabled, name, label, requiredMark, errorMessage, onChange, onBlur } = props;
  const [textareaValue, setTextareaValue] = useState('');
  return (
    <InputWrapComponent errorMessage={errorMessage} disabled={disabled} label={label} requiredMark={requiredMark} name={name} value={value}>
      <TextAreaAutosize data-value={textareaValue || placeholder} hasError={Boolean(errorMessage)}>
        <textarea
          {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
          rows={1}
          name={name}
          id={name}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = e.currentTarget;
            setTextareaValue(value);
            onChange({ name, value });
          }}
          onBlur={onBlur}
        />
      </TextAreaAutosize>
    </InputWrapComponent>
  );
};

export default TextAreaField;
