import { Observer } from 'mobx-react';
import { chooseLocationMobile, chooseLocationDesktop } from 'modules/search/actions';
import { locationStore, recentLocationStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import * as React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import { HEADER_FORM, RESOLUTIONS } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { Svg } from 'src/styledComponents/Icons';
import { BodyText, Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils/t';

import { form } from 'stores';

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
  padding: 0 0 16px 20px;
  display: block;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }
`;

const NoResult = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
  padding-left: 20px;
  display: block;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }
`;

const LocationOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 20px;

  &:hover {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }
`;

const StyledOptionLabel = styled(BodyText)`
  margin-left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledCountryLabel = styled(BodyText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 5px;

  color: ${(props) => props.theme.main.colors.clay4};
`;

type Props = {
  isRecentValues: boolean;
};

export const Locations: React.FC<Props> = (props) => {
  const { isRecentValues } = props;
  return (
    <Observer>
      {() => {
        const valueToLoad = isRecentValues ? recentLocationStore.value! : locationStore.value!;
        const locationName = form.fetch(HEADER_FORM, FieldNames.location);

        if (locationName && !locationStore.value?.length) {
          return (
            <NoResult variant='u3' weight='medium'>
              {t.staticAsString('header.noResults')}
            </NoResult>
          );
        }

        return !locationStore.value!.length && !recentLocationStore.value!.length ? null : (
          <>
            <ShowDesktop>
              <StyledUtility variant='u3' weight='medium'>
                {isRecentValues ? t('search.recentSearch') : t('profile.savedTable.head.location')}
              </StyledUtility>
            </ShowDesktop>
            <ShowMobile>
              <StyledUtility variant='u2' weight='bold'>
                {isRecentValues ? t('search.recentSearch') : t('profile.savedTable.head.location')}
              </StyledUtility>
            </ShowMobile>
            {valueToLoad.map((item) => (
              <React.Fragment key={shortid()}>
                <ShowDesktop>
                  <LocationOption onMouseDown={(e) => e.preventDefault()} onClick={chooseLocationDesktop(item.city)}>
                    <Svg name='AltLocation' size={24} />
                    <StyledOptionLabel variant='body1'>{item.city}</StyledOptionLabel>
                    <StyledCountryLabel variant='body1'>{item.country}</StyledCountryLabel>
                  </LocationOption>
                </ShowDesktop>
                <ShowMobile>
                  <LocationOption onMouseDown={(e) => e.preventDefault()} onClick={chooseLocationMobile(item.city)}>
                    <Svg name='Location' size={24} />
                    <StyledOptionLabel variant='body2'>{item.city}</StyledOptionLabel>
                    <StyledCountryLabel variant='body2'>{item.country}</StyledCountryLabel>
                  </LocationOption>
                </ShowMobile>
              </React.Fragment>
            ))}
          </>
        );
      }}
    </Observer>
  );
};
