import { cloneDeep } from 'lodash';

export const mapSegments = (data: Array<Object> = []): Array<Object> => {
  const copy = cloneDeep(data);

  return copy.map((segment: AnyObject) => {
    const time_result = segment.time_result.split('.')[0];

    return {
      ...segment,
      time_result,
    };
  });
};
