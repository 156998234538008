import { formatter } from 'utils';

import { form } from 'stores';

import { FORM_ID } from '../constants';

export const onBlurLink = (name: string) => (): void => {
  const link = form.fetchWithoutObservable<string>(FORM_ID, name);

  if (link) {
    const newLink = formatter.withHttpUrl(link);
    form.onChange(FORM_ID, name, newLink);
  }
};
