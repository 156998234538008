export * from './insights';
export * from './journal';

export enum WorkoutDifficultyLevel {
  rest = 'rest',
  easy = 'easy',
  moderate = 'moderate',
  hard = 'hard',
}

export type HeartRateZoneProps = {
  title: string;
  description: string;
  heartRate: string;
  pace: string;
};

export type HeartRateZonesDescription = {
  hr: number[];
  pace: number[];
};
