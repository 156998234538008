import { Routes as ManageTeamContainer } from 'modules/manageTeam';
import { MyResultsRouter } from 'modules/myResults';
import { ProfileContainer } from 'modules/profile';
import { SettingsContainer } from 'modules/profileSettings';
import { RaceSalesPages } from 'modules/raceSalesPages';
import { RouterWrap as SegmentForm } from 'modules/segmentForm';
import { SegmentsContainer } from 'modules/segments';
import * as React from 'react';
import { Route } from 'react-router-dom';

// TODO, remove. I hope
import { ROUTER_WITH_LOCALES } from 'src/constants';

const generatePrivateRoutes = () => {
  return [
    <Route exact path={ROUTER_WITH_LOCALES.PROFILE} component={ProfileContainer} key='profile' />,
    <Route exact path={ROUTER_WITH_LOCALES.SEGMENTS} component={SegmentsContainer} key='segments' />,
    <Route path={ROUTER_WITH_LOCALES.SETTINGS} component={SettingsContainer} key='settings' />,
    <Route path={ROUTER_WITH_LOCALES.MANAGE_TEAM} component={ManageTeamContainer} key='manage-team' />,
    <Route exact path={ROUTER_WITH_LOCALES.SEGMENT_FORM} component={SegmentForm} key='segment-form' />,
    <Route component={MyResultsRouter} key='my-results' />,
    <Route path={ROUTER_WITH_LOCALES.RACE_SALES_PAGES_ROOT} component={RaceSalesPages} key='sales-root' />,
  ];
};

export { generatePrivateRoutes };
