import { AnyStore } from 'stores';

import { UnionType } from '../types';
import { ActionMenu } from './actionMenu';

export const xtriStore = new AnyStore<Array<UnionType>>([]);
export const distancesStore = new AnyStore<Array<AnyObject>>([]);
export const actionMenuStore = new ActionMenu();

export * from './tabStore';
export * from './result';
