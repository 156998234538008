import * as React from 'react';

export const Twitter = (
  <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.6667 12.4492C22.1708 12.6667 21.6425 12.8109 21.0917 12.8809C21.6583 12.5426 22.0908 12.0109 22.2942 11.3701C21.7658 11.6851 21.1825 11.9076 20.5608 12.0317C20.0592 11.4976 19.3442 11.1667 18.5642 11.1667C17.0508 11.1667 15.8325 12.3951 15.8325 13.9009C15.8325 14.1176 15.8508 14.3259 15.8958 14.5242C13.6233 14.4134 11.6125 13.3242 10.2617 11.6651C10.0258 12.0742 9.88751 12.5426 9.88751 13.0467C9.88751 13.9934 10.375 14.8326 11.1017 15.3184C10.6625 15.3101 10.2317 15.1826 9.86668 14.9817C9.86668 14.9901 9.86668 15.0009 9.86668 15.0117C9.86668 16.3401 10.8142 17.4434 12.0567 17.6976C11.8342 17.7584 11.5917 17.7876 11.34 17.7876C11.165 17.7876 10.9883 17.7776 10.8225 17.7409C11.1767 18.8234 12.1817 19.6192 13.3767 19.6451C12.4467 20.3726 11.2658 20.8109 9.98751 20.8109C9.76334 20.8109 9.54834 20.8009 9.33334 20.7734C10.5442 21.5542 11.9792 22.0001 13.5267 22.0001C18.5567 22.0001 21.3067 17.8334 21.3067 14.2217C21.3067 14.1009 21.3025 13.9842 21.2967 13.8684C21.8392 13.4834 22.295 13.0026 22.6667 12.4492Z'
      fill='#656B6F'
    />
    <rect x='0.75' y='1.25' width='30.5' height='30.5' rx='15.25' stroke='#656B6F' strokeWidth='1.5' />
  </svg>
);
