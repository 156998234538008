import { isEmpty } from 'lodash';
import { CheckpointsTable } from 'modules/results/components/Checkpoint';
import { PresentResult } from 'modules/results/components/PresentResult';
import { resultsPresentation } from 'modules/results/derivations';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { MobileAdditionalInfo, TeamMembers } from 'src/modules/racersTable/components';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { mobileItemHeight } from '../../utils';

import { userModal } from '../../actions';

import { classesStore, distancesStore, wavesStore } from '../../stores';

import { LResult } from '../../types';
import { ItemWrapper } from './MobileSingleItem';

type Props = {
  value: LResult;
  distanceId: number;
  checkpointId: number;
};

const Wrapper = styled(ItemWrapper)<{ active: boolean }>`
  width: 100%;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 24px;
  gap: 8px;

  align-items: center;
  padding: 0 20px;
  margin-bottom: 8px;
`;

const TimeInfo = styled.div`
  display: flex;
  gap: 8px;

  padding-left: 63px;
`;

const StyledSvg = styled(Svg)<{ active?: boolean }>`
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'initial')};
  transition: All ease-out 0.3s;
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
  white-space: nowrap;
`;

const StyledMobileAdditionalInfo = styled(MobileAdditionalInfo)`
  margin-top: 16px;
`;

const CollapsibleInfo = styled.div`
  padding: 0 20px;
  transition: All 0.3s;
  .team-members {
    gap: 0;
    padding: 0 20px 0 44px;
    transition: All 0.3s;
    div {
      transition: All 0.3s;
      opacity: 0;
      max-height: 0;
    }
  }
  &.active {
    .team-members {
      gap: 16px;
      padding: 24px 20px 20px 44px;
      div {
        opacity: 1;
        max-height: 20px;
      }
    }
  }
`;

export const MobileTeamItem: React.FC<Props> = (props) => {
  const { value, distanceId, checkpointId } = props;
  const [active, setActive] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const team = value.team;
  const distanceModel = distancesStore.find(+distanceId!);

  if (!team || !distanceModel) return null;

  const classModel = classesStore.find(+team.class_id!);
  const waveModel = wavesStore.find(+team.wave_id!);
  const place = resultsPresentation.generatePlace(value, distanceId!, checkpointId);

  const height = mobileItemHeight(value);

  const expandCondition = !isEmpty(distanceModel.checkpoints) || !isEmpty(team.racers);

  return (
    <Wrapper active={active} place={place}>
      <Header>
        <StyledUtility variant='u2' weight='medium'>
          {place}
        </StyledUtility>
        <Utility variant='u1' weight='bold'>
          {team.name}
        </Utility>
        <Show if={expandCondition}>
          <StyledSvg name='CircleDown' size={24} active={active} onClick={() => setActive(!active)} />
        </Show>
      </Header>
      <TimeInfo>
        <StyledUtility variant='u2' weight='medium'>
          {team.bib_number}
        </StyledUtility>
        <StyledUtility variant='u2' weight='bold'>
          <PresentResult.FinishResult result={value} checkpointId={checkpointId} distance={distanceModel} />
        </StyledUtility>
        <StyledUtility variant='u2' weight='medium'>
          <PresentResult.LeaderTime result={value} checkpointId={checkpointId} distanceId={Number(distanceId)} />
        </StyledUtility>
      </TimeInfo>
      <Show if={height === 140}>
        <StyledMobileAdditionalInfo classTitle={classModel?.value.title} wave={waveModel?.value.name} isPlace />
      </Show>
      <CollapsibleInfo className={`${active ? 'active' : ''}`}>
        <TeamMembers className='team-members' members={team.racers || []} onOpenRacer={userModal.onOpen} />
        <Show if={!isEmpty(distanceModel.checkpoints)}>
          <CheckpointsTable ref={ref} active={active} value={value} checkpointId={checkpointId} distanceId={distanceId} />
        </Show>
      </CollapsibleInfo>
    </Wrapper>
  );
};
