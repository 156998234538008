import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants/general';

import { ToastClose } from './ToastClose';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    min-height: 74px;
    padding: 16px;
  }

  .Toastify__toast--error {
    background: linear-gradient(85.23deg, rgba(236, 94, 75, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%),
      ${(props) => props.theme.main.colors.clay1};
  }

  .Toastify__toast--success {
    background: linear-gradient(85.23deg, rgba(0, 219, 98, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%),
      ${(props) => props.theme.main.colors.clay1};
  }

  .Toastify__toast--warning {
    background: linear-gradient(85.23deg, rgba(242, 190, 0, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%),
      ${(props) => props.theme.main.colors.clay1};
  }

  .Toastify__toast--info {
    background: linear-gradient(85.23deg, rgba(214, 241, 0, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%),
      ${(props) => props.theme.main.colors.clay1};
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    min-width: 460px;

    .Toastify__toast {
      min-height: 114px;
      min-width: 460px;
      padding: 32px;
    }
  }
`;

export const ToasterComponent = () => {
  return (
    <TestAnchorContext.Provider value={{ container: 'Toast' }}>
      <StyledToastContainer
        icon={false}
        hideProgressBar={true}
        pauseOnFocusLoss={true}
        autoClose={false}
        className='toast-container'
        closeButton={ToastClose}
        limit={3}
        {...testAnchors.useButton('toast')}
      />
    </TestAnchorContext.Provider>
  );
};
