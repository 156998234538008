import * as React from 'react';

export const Check = (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.625 1.37451L5.079 12.154C4.87702 12.4442 4.54784 12.6197 4.1943 12.6255C3.84076 12.6314 3.50596 12.4669 3.2945 12.1835L1.375 9.62451'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
