import * as React from 'react';
import styled, { css } from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';

type Props = {
  color: string;
  name: string;
  link: string;
};

const Wrapper = styled.div<{ color: string }>`
  padding-left: 8px;
  cursor: pointer;
  :first-child {
    padding-left: 0;
  }
  svg {
    :hover {
      path {
        fill: #dffc68;
      }
      rect {
        stroke: #dffc68;
      }
    }
  }
  ${(props) =>
    props.color === 'gray' &&
    css`
      svg path {
        fill: ${(props) => props.theme.main.colors.clay3};
      }
      svg rect {
        stroke: ${(props) => props.theme.main.colors.clay3};
      }
      svg:hover {
        border-radius: 50%;
        background: ${(props) => props.theme.main.colors.clay3};
        path {
          fill: ${(props) => props.theme.main.colors.white};
        }
        rect {
          stroke:  ${(props) => props.theme.main.colors.clay3};
        }
    `}
`;

export const SocialIcon = (props: Props & RequiredTestAnchorProps) => {
  const { name, link, ATN } = props;
  return (
    <Wrapper color={props.color}>
      <a href={link} target='_blank' {...testAnchors.useLink(ATN)}>
        <Svg name={name} size={32} />
      </a>
    </Wrapper>
  );
};
