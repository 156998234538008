import { PAYMENT_TYPES } from 'src/constants';

import { Data } from '../types';

function findPaymentOperation(operations: Data.Backend.OperationFromBE[] | nil): Data.Backend.OperationFromBE | null {
  if (!operations) {
    return null;
  }

  return operations.find((operation) => operation.rel === PAYMENT_TYPES.authorization || operation.rel === PAYMENT_TYPES.sale) || null;
}

export { findPaymentOperation };
