import { isEmpty } from 'lodash';

type InputType = {
  id?: string | number;
  ref?: AnyObject | AnyFunction | React.Ref<any>;
  errorMessage?: string;
};

interface ScrollErrorType {
  current: InputType;
  last: InputType;
  scrollTrigger: boolean;
  registerInput: (newCurrent: InputType) => void;
  scrollToInvalidField: () => void;
  toggleTrigger: (trigger: boolean) => void;
}

class ScrollStore implements ScrollErrorType {
  scrollTrigger = false;

  current: AnyObject = {};
  last: AnyObject = {};

  toggleTrigger(trigger: boolean) {
    this.scrollTrigger = trigger;
  }
  registerInput(newCurrent: InputType): void {
    if (!this.scrollTrigger) {
      return;
    }

    if (newCurrent.id !== this.current.id && !isEmpty(this.current)) {
      return;
    }

    if (newCurrent.id === this.current.id && newCurrent.errorMessage === this.current.errorMessage) {
      return;
    }

    if (!newCurrent.errorMessage) {
      return;
    }

    this.current = newCurrent;
  }

  scrollToInvalidField(): void {
    const { current, last } = this;

    if (!this.scrollTrigger) {
      return;
    }

    if (current.id && current.errorMessage && (!current.ref || !current.errorMessage)) {
      return;
    }

    if (this.current.ref) {
      this.current.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    this.last = this.current;
    this.clearCurrent();
    this.toggleTrigger(false);
  }

  clearCurrent() {
    this.current = {};
  }
}

export { ScrollStore };
export default new ScrollStore();
