import { first } from 'lodash';

import { toastService, t } from 'utils';

import { manageTeamStore } from 'stores';

import { manageTeamService } from '../services';

async function resendInvitation(distanceId: number, memberId: number) {
  const distanceModel: any = manageTeamStore.getNeededDistance(distanceId);

  const team = first(distanceModel?.value?.teams);

  if (!team) {
    return;
  }

  const [status] = await manageTeamService.resendInvitation((team as any).id, memberId);

  if (status) {
    toastService.success(t.staticAsString('manageTeam.successfullySent'));
  }
}

export { resendInvitation };
