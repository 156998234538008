import * as React from 'react';
import styled from 'styled-components';

import { BodyText } from 'src/styledComponents/Typography';
import { htmlSanitizer, t } from 'src/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0 0 0 0;

  .value,
  .value-with-padding {
    color: ${(props) => props.theme.main.colors.clay2};
  }

  .value-with-padding {
    padding-bottom: 40px;
  }
`;

type Props = {
  text: TranslationLockedKeys;
  isNeedPadding: boolean;
};

export const BlockRecipientInfo: React.FC<Props> = (props: Props) => {
  return (
    <Wrapper>
      <BodyText variant='body2' className={props.isNeedPadding ? 'value-with-padding' : 'value'}>
        <span dangerouslySetInnerHTML={{ __html: htmlSanitizer(t.staticAsString(props.text)) }} />
      </BodyText>
    </Wrapper>
  );
};
