import { css } from 'styled-components';

export const scrollBarStyles = css`
  ::-webkit-scrollbar {
    background: ${(props) => props.theme.main.colors.clay5};
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    height: 80px;
    background: ${(props) => props.theme.main.colors.clay3};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb::before {
    content: '';
    background: ${(props) => props.theme.main.colors.clay1};
    border-radius: 4px;
  }
`;
