import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { HardElevation } from 'src/styledComponents/Elevation';
import { Svg } from 'src/styledComponents/Icons';

import { scrollToTop, unmount, mount } from './actions';
import { displayScrollStore } from './stores';

type Props = {
  rootElementId?: string;
  className?: string;
  offsetTop?: number;
};

const Wrapper = styled.div<{ show: boolean }>`
  display: flex;
  justify-content: flex-end;
  height: 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-right: 48px;

  .button-scroll {
    display: none;
    padding: 4px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    bottom: 120px;
    right: 20px;
    z-index: 100;
    cursor: pointer;
    border: none;
    background: ${(props) => props.theme.main.colors.white};
  }

  ${(props) =>
    props.show &&
    css`
      .button-scroll {
        display: block;
      }
    `}
`;

@observer
export class ButtonToTop extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    mount(props.rootElementId, props.offsetTop);
  }
  static contextType = TestAnchorContext;
  declare context: React.ContextType<typeof TestAnchorContext>;

  componentWillUnmount(): void {
    unmount(this.props.rootElementId);
  }

  render() {
    const { rootElementId, className, offsetTop } = this.props;

    return (
      <Wrapper className={classNames('button-scroll-container', className)} show={!!displayScrollStore.value}>
        <HardElevation
          level={1}
          className='button-scroll'
          onClick={scrollToTop(rootElementId, offsetTop)}
          {...testAnchors.button(this.context.container, 'to-top')}
        >
          <Svg name='Navigate' size={32} />
        </HardElevation>
      </Wrapper>
    );
  }
}
