import classNames from 'classnames';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'src/styledComponents/Button';

import { t } from 'utils';

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  display: boolean;
  hide: boolean;
};

const Wrapper = styled.div<{ hide: boolean }>`
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;
export class SegmentsLinkCell extends React.Component<Props> {
  render() {
    const { onClick, display, hide } = this.props;

    if (!display) {
      return null;
    }

    return (
      <Wrapper className={classNames('segments-expand-icon', { 'hide-icon': hide })} hide={hide} onClick={onClick}>
        <Button variant='primary' size='medium' ATN='viewAllResults'>
          {t.staticAsString('profile.viewSegments')}
        </Button>
      </Wrapper>
    );
  }
}
