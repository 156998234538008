import { getDefaultCheckpoint, preloader, initDistanceLoader } from '../../utils';

import { initVersionStore, loaderDisposers } from '../../stores';

import { loadDefaultCheckpoint } from '../';
import { currentDistance, currentFormFilters } from '../../derivations';
import { checkpointChanger, distanceChanger } from '../../mutations';
import { updateHistoryBasedOnFilters } from '../filters';
import { initData } from './initData';

async function initDistance(newDistanceId?: number | null, doNotInitDistanceInFilters?: boolean) {
  loaderDisposers.disposeAll();
  preloader.enable();
  initDistanceLoader.enable();

  if (!doNotInitDistanceInFilters) {
    distanceChanger.change(newDistanceId);
  }

  initVersionStore.generate();
  const currentVersion = initVersionStore.fetch();

  const distanceId = currentDistance.id.get();

  if (!currentFormFilters.main.get().checkpoint_id && !!distanceId) {
    const defaultCheckpointId = getDefaultCheckpoint(distanceId!, await loadDefaultCheckpoint(distanceId));

    if (!initVersionStore.compare(currentVersion)) {
      return;
    }

    if (defaultCheckpointId) {
      checkpointChanger.change(defaultCheckpointId);
    }
  }

  updateHistoryBasedOnFilters();
  initData();
}

export { initDistance };
