import { VirtualDistanceMapped } from 'modules/myResults/types';

import { displayLengthByGoal, raceUtil } from 'utils';

import { Distance } from 'models';

import { VirtualDistance } from '../types';

export const getLength = (distance: VirtualDistanceMapped | VirtualDistance): string => {
  const distanceModel = new Distance(distance as any);
  const result = distance.resultData || distance.racers[0].result;

  if (!distanceModel.isMode('cumulative') || !distance.goal) {
    return raceUtil.humanizeDistance(+distance.race_length);
  }

  if (!result?.distance_logged) {
    return '';
  }

  return displayLengthByGoal(+result.distance_logged, distance.goal);
};
