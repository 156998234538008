import {
  ClassesStore,
  WavesStore,
  SquadsStore,
  CheckpointsStore,
  UserModal,
  Filters,
  PaginationMetaStore,
  RegistrationFieldsStore,
  LifeStore,
  GoalsStore,
  VersionStore,
} from 'modules/racersTable/stores';

import { BooleanStore, Disposers, AnyStore } from 'stores';

import { InternalFilters } from '../types';
import { DataStore } from './data';
import { DistancesStore } from './distances';
import { TimeLeadersStore } from './timeLeaders';

const distancesStore = new DistancesStore();
const classesStore = new ClassesStore();
const wavesStore = new WavesStore();
const squadsStore = new SquadsStore();
const checkpointsStore = new CheckpointsStore();
const registrationFieldsStore = new RegistrationFieldsStore();
const dataStore = new DataStore();
const timeLeadersStore = new TimeLeadersStore();

const filtersOpenedStore = new BooleanStore();
const isFindInterceptionHandled = new BooleanStore();
const userModalStore = new UserModal();

const filtersStore = new Filters<InternalFilters>();
const paginationMetaStore = new PaginationMetaStore();
const disposers = new Disposers();
const loaderDisposers = new Disposers();

const lifeStore = new LifeStore();
const goalsStore = new GoalsStore();
const requestsVersionStore = new VersionStore();
const initVersionStore = new VersionStore();
const isAppInitializedStore = new BooleanStore();
const totalCountStore = new AnyStore<number | nil>();

const activeSplitStore = new AnyStore<number | null>(null);

export {
  isAppInitializedStore,
  dataStore,
  timeLeadersStore,
  distancesStore,
  classesStore,
  wavesStore,
  squadsStore,
  checkpointsStore,
  registrationFieldsStore,
  filtersOpenedStore,
  userModalStore,
  filtersStore,
  paginationMetaStore,
  requestsVersionStore,
  initVersionStore,
  disposers,
  loaderDisposers,
  goalsStore,
  lifeStore,
  isFindInterceptionHandled,
  totalCountStore,
  activeSplitStore,
};

export * from './distances';
export * from './params';
