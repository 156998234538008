import {
  mountChangePasswordForm,
  onSubmitChangePasswordForm,
  unmountChangePasswordForm,
} from 'modules/profileSettings/actions/changePasswordForm';
import FormControls from 'modules/profileSettings/components/FormControls';
import { CHANGE_PASS_FORM_ID } from 'modules/profileSettings/constants';
import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import { Form, InputWrapper } from 'components/form';

import { t } from 'utils';

import { TextField } from '../../../styledComponents/TextField';

const FormWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  form > div:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const ChangePasswordComponent = () => {
  useEffect(() => {
    mountChangePasswordForm();
    return () => {
      unmountChangePasswordForm();
    };
  }, []);

  return (
    <TestAnchorContext.Provider value={{ container: 'Profile.Change-Password' }}>
      <FormWrapper>
        <Form id={CHANGE_PASS_FORM_ID} onSubmit={onSubmitChangePasswordForm} cleanErrorOnChange>
          <InputWrapper
            name='current_password'
            settings={{
              label: t.staticAsString('settings.current_password'),
              placeholder: t.staticAsString('settings.current_password'),
              required: true,
              requiredMark: true,
              type: 'password',
            }}
            Component={TextField}
          />
          <InputWrapper
            name='password'
            settings={{
              label: t.staticAsString('settings.password'),
              placeholder: t.staticAsString('settings.password'),
              required: true,
              requiredMark: true,
              type: 'password',
            }}
            Component={TextField}
          />
          <InputWrapper
            name='password_confirmation'
            settings={{
              label: t.staticAsString('settings.password_confirmation'),
              placeholder: t.staticAsString('settings.password_confirmation'),
              required: true,
              requiredMark: true,
              type: 'password',
            }}
            Component={TextField}
          />
        </Form>
        <FormControls onSubmit={onSubmitChangePasswordForm} />
      </FormWrapper>
    </TestAnchorContext.Provider>
  );
};
