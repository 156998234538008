import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';

import { BodyText, Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { getNumberOfChances, getStatus, getTicketTitle } from '../../utils';

import { getFilteredTickets } from '../../selections';

type Props = {
  title: string;
  unionId: number | string;
  isVisible: boolean;
};

const Wrapper = styled.div`
  margin-top: 20px;

  .ticket-card {
    padding: 40px 20px 0 20px;
  }

  .ticket-title {
    font-size: 20px;
    line-height: 24px;
  }

  .info-box {
    margin-top: 8px;

    & > div {
      display: flex;
      max-width: 270px;
    }

    p,
    span {
      flex: 1 1 0;
    }

    p {
      color: ${(props) => props.theme.main.colors.clay3};
      margin-bottom: 2px;
    }
    span {
      color: ${(props) => props.theme.main.colors.clay2};
    }

    .status-info {
      .valid {
        color: ${(props) => props.theme.main.colors.clay2};
      }
      .used {
        color: ${(props) => props.theme.main.colors.clay4};
      }
    }
  }

  .union-footer {
    width: 100%;
    padding: 12px 20px;
    background: ${(props) => props.theme.main.colors.clay6};
    margin-top: 20px;

    span {
      padding-right: 8px;
    }
  }
`;

export const XtriUnionMobile: FC<Props> = (props) => {
  const { title, unionId, isVisible } = props;
  const tickets = getFilteredTickets(unionId);

  if (isEmpty(tickets) || !isVisible) {
    return null;
  }

  const renderTickets = (tickets: Array<AnyObject>): Array<React.ReactNode> =>
    tickets.map((item) => (
      <div className='ticket-card'>
        <Utility variant='u2' weight='bold' className='ticket-title'>
          {getTicketTitle(item, title)}
        </Utility>
        <div className='info-box'>
          <div className='number-info'>
            <BodyText variant='body2' className='title'>
              {t('xtriTab.table.drawNumber')}
            </BodyText>
            <BodyText variant='body2' className='title'>
              {t('xtriTab.table.statusShort')}
            </BodyText>
          </div>

          <div className='status-info'>
            <Utility variant='u4' weight='bold'>
              {item.ticket_number}
            </Utility>
            <Utility variant='u4' weight='bold' className={item.validity}>
              {getStatus(item.validity)}
            </Utility>
          </div>
        </div>
      </div>
    ));

  return (
    <Wrapper>
      {renderTickets(tickets)}
      <div className='union-footer'>
        <Utility variant='u4' weight='medium'>
          {t('xtriTable.numberOfChance')}
        </Utility>
        <Utility variant='u4' weight='bold'>
          {getNumberOfChances(tickets as any)}
        </Utility>
      </div>
    </Wrapper>
  );
};
