import * as React from 'react';

export const NoSportType = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M3.7998 3.8V20.2' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M3.7998 4.89999H19.6498C19.7547 4.9011 19.8571 4.93219 19.945 4.98961C20.0328 5.04702 20.1024 5.12837 20.1455 5.22403C20.1886 5.3197 20.2034 5.4257 20.1882 5.52953C20.1731 5.63335 20.1285 5.73067 20.0598 5.80999L17.1398 9.08999C16.962 9.28963 16.8637 9.54765 16.8637 9.81499C16.8637 10.0823 16.962 10.3404 17.1398 10.54L20.0498 13.82C20.1506 13.9228 20.207 14.061 20.207 14.205C20.207 14.349 20.1506 14.4872 20.0498 14.59C19.9512 14.6795 19.823 14.7293 19.6898 14.73H3.7998'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
