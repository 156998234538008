import { throttle } from 'lodash';

import { isViewportFilled } from '../utils';

import { loader } from '../actions';

import { disposers } from '../stores';

// Infinite scroll
function observeScroll() {
  const trottledObserver = throttle(observer, 100);

  document.addEventListener('scroll', trottledObserver);

  disposers.register(() => document.removeEventListener('scroll', trottledObserver));
}

function observer() {
  if (isViewportFilled()) {
    return;
  }

  loader.loadMore();
}

export { observeScroll };
