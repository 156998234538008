import * as React from 'react';

export const UploadImage = (
  <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='40' cy='40' r='40' fill='#272E34' />
    <path
      d='M37 39.75C37 40.4394 37.1358 41.1221 37.3996 41.7591C37.6635 42.396 38.0502 42.9748 38.5377 43.4623C39.0252 43.9498 39.604 44.3365 40.2409 44.6004C40.8779 44.8642 41.5606 45 42.25 45C42.9394 45 43.6221 44.8642 44.2591 44.6004C44.896 44.3365 45.4748 43.9498 45.9623 43.4623C46.4498 42.9748 46.8365 42.396 47.1004 41.7591C47.3642 41.1221 47.5 40.4394 47.5 39.75C47.5 39.0606 47.3642 38.3779 47.1004 37.7409C46.8365 37.104 46.4498 36.5252 45.9623 36.0377C45.4748 35.5502 44.896 35.1635 44.2591 34.8996C43.6221 34.6358 42.9394 34.5 42.25 34.5C41.5606 34.5 40.8779 34.6358 40.2409 34.8996C39.604 35.1635 39.0252 35.5502 38.5377 36.0377C38.0502 36.5252 37.6635 37.104 37.3996 37.7409C37.1358 38.3779 37 39.0606 37 39.75V39.75Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M47.5 33L46.415 30.829C46.2904 30.5798 46.0988 30.3703 45.8618 30.2239C45.6247 30.0774 45.3516 29.9999 45.073 30H39.427C39.1484 29.9999 38.8753 30.0774 38.6382 30.2239C38.4012 30.3703 38.2096 30.5798 38.085 30.829L37 33H30.25C29.8522 33 29.4706 33.158 29.1893 33.4393C28.908 33.7206 28.75 34.1022 28.75 34.5V46.5C28.75 46.8978 28.908 47.2794 29.1893 47.5607C29.4706 47.842 29.8522 48 30.25 48H49.75C50.1478 48 50.5294 47.842 50.8107 47.5607C51.092 47.2794 51.25 46.8978 51.25 46.5V34.5C51.25 34.1022 51.092 33.7206 50.8107 33.4393C50.5294 33.158 50.1478 33 49.75 33H47.5Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M32.125 36C32.1992 36 32.2717 36.022 32.3333 36.0632C32.395 36.1044 32.4431 36.163 32.4715 36.2315C32.4998 36.3 32.5073 36.3754 32.4928 36.4482C32.4783 36.5209 32.4426 36.5877 32.3902 36.6402C32.3377 36.6926 32.2709 36.7283 32.1982 36.7428C32.1254 36.7573 32.05 36.7498 31.9815 36.7215C31.913 36.6931 31.8544 36.645 31.8132 36.5833C31.772 36.5217 31.75 36.4492 31.75 36.375C31.75 36.2755 31.7895 36.1802 31.8598 36.1098C31.9302 36.0395 32.0255 36 32.125 36Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M33.25 33V31.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
