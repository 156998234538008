import 'cropperjs/dist/cropper.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'intersection-observer';
import { Provider } from 'mobx-react';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'racese-react-phone-input-2/dist/style.css';
import 'rc-slider/assets/index.css';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Div100vh from 'react-div-100vh';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { addLocaleData } from 'react-intl';
// import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import no from 'react-intl/locale-data/no';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import sv from 'react-intl/locale-data/sv';
import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
// import 'core-js/shim';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components';
import 'subworkers';

import './utils/setupGlobal';

import { INTERCOM_APP_ID } from 'src/constants';
import { IntercomController } from 'src/styledComponents/IntercomController/IntercomController';
import { theme } from 'src/styles';
import { GlobalStyle } from 'src/styles';

import { IntlProvider } from 'components/intl';

import { history, appVersionControl, app, appEnvControl, tags, apiOriginManager, setupUserPlatform } from 'utils';
import { mapUser } from 'utils/intercom/mapper';

import { translations } from 'actions';

import * as stores from 'stores';

import App from './App';
import './assets/scss/main.scss';
import registerServiceWorker from './registerServiceWorker';

smoothscroll.polyfill();
app.init();
appVersionControl.setCurrentVersionForApp();
setupUserPlatform();
appEnvControl.setCurrentEnvForApp();
translations.init();
tags.gtm.init();
// For future
// apiOriginManager.init();

addLocaleData([...en, ...sv, ...fr, ...es, ...de, ...pt, ...pl, ...no, ...fi]);
ReactGA.initialize('G-N8KPPZ7QGJ');
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <IntercomProvider
      onShow={() => {
        window.Intercom('boot', { ...mapUser(true) });
        window.Intercom('update', { ...mapUser(true) });
      }}
      appId={INTERCOM_APP_ID}
    >
      <IntercomController />
      <Div100vh>
        <Provider {...stores}>
          <IntlProvider>
            <ThemeProvider theme={theme}>
              <TestAnchorContext.Provider value={{ container: 'Root' }}>
                <GlobalStyle />
                <Router history={history}>
                  <App />
                </Router>
              </TestAnchorContext.Provider>
            </ThemeProvider>
          </IntlProvider>
        </Provider>
      </Div100vh>
    </IntercomProvider>,
  );
}
registerServiceWorker();
