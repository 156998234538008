import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

export const CalendarWrapper = styled.div<{ showCalendarAsModal?: boolean }>`
  ${({ showCalendarAsModal }) =>
    !showCalendarAsModal &&
    css`
      position: absolute;
      z-index: 1;
      left: calc(50% - 192px);
      top: calc(100% + 16px);
    `}

  border-radius: 24px 24px 0 0;
  overflow: hidden;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.12);
  background: ${(props) => props.theme.main.colors.white};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    border-radius: 24px;
  }

  .CalendarMonth_caption {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.main.colors.clay2};

    strong {
      font-weight: 500;
    }
  }

  .DayPicker_weekHeader_li {
    color: ${(props) => props.theme.main.colors.clay4};
    font-weight: 700;
    font-size: 20px;
    width: 46px !important;
  }

  .CalendarDay__selected {
    background: ${(props) => props.theme.main.colors.clay1};
    color: ${(props) => props.theme.main.colors.white};
  }

  .CalendarDay__hovered_span {
    background: ${(props) => props.theme.main.colors.clay6};
    color: ${(props) => props.theme.main.colors.clay1};
  }

  .CalendarDay__selected_span {
    background: ${(props) => props.theme.main.colors.clay6};
    color: ${(props) => props.theme.main.colors.clay1};
  }

  .CalendarDay__outside {
    background: none;
    color: ${(props) => props.theme.main.colors.clay4};
  }

  .CalendarDay__blocked_calendar {
    background: none;
    color: ${(props) => props.theme.main.colors.clay4};
  }

  .CalendarMonthGrid > .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1:not(:nth-child(2)) .change-year {
    display: none;
  }
`;
