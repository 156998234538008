import { Observer } from 'mobx-react';
import { RaceType } from 'modules/home/types';
import React, { FC } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Card } from 'src/styledComponents/Card';

import { tabStore } from '../../stores';

type Props = {
  handleAddToWish: Function;
};

export const StyledCard = styled(Card)`
  .content-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    @media (min-width: ${RESOLUTIONS.medium}px) {
      min-height: 72px;
    }
  }
  .content-distances {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }
  .icon-location {
    align-self: flex-start;
  }

  .content-location {
    font-size: 14px;
    line-height: 18px;

    @media (min-width: ${RESOLUTIONS.medium}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const SavedRacesTab: FC<Props> = ({ handleAddToWish }) => {
  return (
    <Observer>
      {() => {
        return tabStore.values.map((race: RaceType) => {
          const isFollow = !(tabStore.deletedValues as any)[race.id];

          if (!isFollow) return null;

          return (
            <StyledCard
              key={race.id}
              active={isFollow}
              bodyTitle={race.name}
              startDate={race.startDate}
              endDate={race.endDate}
              distances={race.distances}
              image={race.image_default}
              locations={race.locations}
              raceLink={race.raceLink}
              onChangeFavorite={handleAddToWish(race.id)}
              sportIcon={race.sportIcon}
              sportType={race.sportTypeName}
              published={race.published}
            />
          );
        });
      }}
    </Observer>
  );
};
