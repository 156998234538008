export namespace AnalyticsEvent {
  export const AbandonedDistanceRegistration = 'Abandoned Distance Registration';
  export const AbandonedDistanceConfirmation = 'Abandoned Distance Confirmation';
  export const SuccessfulPaidDistanceRegistration = 'Successful Paid Distance Registration';
  export const SuccessfulFreeDistanceRegistration = 'Successful Free Distance Registration';
  export const NavigatedToRegistration = 'Navigated to Registration';
  export const PaymentBegin = 'Payment begin';
  export const PaymentAborted = 'Payment aborted';
  export const PaymentSuccessful = 'Payment successful';

  export const FreeGroupRegistrationSuccessful = 'Free Group Registration Successful';
  export const FreeTeamRegistrationSuccessful = 'Free Team Registration Successful';
  export const ExternalRegistrationUrlNavigated = 'External Registration URL Navigated';
  export const UserOpenedRace = 'User Opened Race';

  export const ProductViewed = 'Product Viewed';
  export const ProductsSizeSelected = 'Product Size Selected';
  export const ProductAddedToCart = 'Product Added to Cart';
  export const ProductRemovedFromCart = 'Product Removed from Cart';
}

export namespace MilesEvents {
  export const VistLandingPage = 'Visit Landing Page';
  export const MilesSignUpFitnessLevel = 'Fitness Level';
  export const MilesSignUpRunDuration = 'Run Duration';
  export const MilesSignUpPersonalDetails = 'Personal Details';
  export const MilesSignUpFinalise = 'Finalise';
  export const MilesSignUpConnectDevices = 'Connect Devices';
  export const MilesignUpPayment = 'Payment';
  export const MilesSignUpAddMiles = 'Add Miles';
  export const VisitTrainingPlan = 'Visit Training Plan';
}
