import { Observer } from 'mobx-react';
import { currentDistance } from 'modules/startlist/derivations';
import * as React from 'react';

import { getTeamDistanceRacersCount } from 'src/modules/startlist/utils';

import { Show } from 'components/condition';

import { counterStore, paginationMetaStore } from '../../stores';

type Props = {
  distanceId?: number;
  allDistancesCount?: number;
};

function UsersCountComponent({ allDistancesCount = 0, distanceId }: Props) {
  if (allDistancesCount > 0) {
    return <span>{allDistancesCount}</span>;
  }
  return (
    <Observer>
      {() => {
        const meta = paginationMetaStore.fetch(distanceId || 0);
        const total = meta?.total;
        const allRacers = counterStore.getCounter();
        const isShowAlLDistances = !Boolean(distanceId);

        const isTeam = Boolean(currentDistance.model.get()?.isTeam());
        const teamDistanceRacers = getTeamDistanceRacersCount();

        return (
          <span className='user-count'>
            <Show if={!isShowAlLDistances && !isTeam}>({total || null})</Show>
            <Show if={isShowAlLDistances}>({allRacers})</Show>
            <Show if={isTeam}>{teamDistanceRacers}</Show>
          </span>
        );
      }}
    </Observer>
  );
}

const UsersCount = React.memo<Props>(UsersCountComponent);

export { UsersCount };
