import { observable, action, computed, makeObservable } from 'mobx';

import { Distance } from 'models';

import type { RegisteredDistanceType } from '../types';

class ActionMenu {
  @observable
  distance: RegisteredDistanceType | null = null;

  @observable
  result: ResultType | null = null;

  @observable
  isOpen: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  get distanceModel() {
    if (!this.distance) {
      return null;
    }

    return new Distance(this.distance as any);
  }

  @action
  setDistance(value: RegisteredDistanceType | null) {
    this.distance = value;
  }

  @action
  setResult(value: ResultType | null) {
    this.result = value;
  }

  @action
  show() {
    this.isOpen = true;
  }

  @action
  hide() {
    this.isOpen = false;
  }

  @action
  clear() {
    this.setDistance(null);
    this.setResult(null);
    this.hide();
  }
}

export { ActionMenu };
