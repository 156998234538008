import * as React from 'react';
import styled from 'styled-components';

import { windowSize } from 'src/stores/windowSize';

import { Utility } from '../Typography';
import { BaseButtonProps } from './types';

type BaseProps = {
  fullWidth?: boolean;
};

const WhiteLineBase = styled.button<BaseProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  border-radius: 1px;
  background: transparent;
  border: 2px solid #fff;
  cursor: pointer;
  span {
    color: ${(props) => props.theme.main.colors.white};
  }

  &:checked,
  &:hover {
    background: ${(props) => props.theme.main.colors.white};
    border: 2px solid ${(props) => props.theme.main.colors.clay1};

    span {
      color: ${(props) => props.theme.main.colors.clay1};
    }
  }

  &:disabled {
    pointer-events: none;
    border-color: ${(props) => props.theme.main.colors.clay4};

    span {
      color: ${(props) => props.theme.main.colors.clay4};
    }
  }
`;

const WhiteLineSmall = styled(WhiteLineBase)`
  padding: 11px 40px;
`;

const WhiteLineMedium = styled(WhiteLineBase)`
  padding: 16px 48px;
`;

const WhiteLineLarge = styled(WhiteLineBase)`
  padding: 26px 48px;
`;

const ComponentTypes = {
  large: WhiteLineLarge,
  medium: WhiteLineMedium,
  small: WhiteLineSmall,
};

const ComponentMobileSizes = {
  large: 'u1',
  medium: 'u2',
  small: 'u3',
};

export const WhiteLine: React.FC<BaseButtonProps> = (props) => {
  const { size = 'large', ...rest } = props;
  const Component = ComponentTypes[size];
  let utilityVariant: 'u1' | 'u2' | 'u3' | 'u4' = ComponentMobileSizes[size] as 'u1' | 'u2' | 'u3';

  if (windowSize.isGreaterThan('medium')) {
    utilityVariant = size === 'small' ? 'u4' : 'u2';
  }

  return (
    <Component {...rest} {...testAnchors.useButton(props.ATN)}>
      <Utility variant={utilityVariant} weight='bold'>
        {props.children}
      </Utility>
    </Component>
  );
};
