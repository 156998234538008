import { get, cloneDeep, omit } from 'lodash';
import moment from 'moment';

import { DEFAULT_RACE_IMAGE } from 'src/constants';

import { objectsDifference } from 'utils';

import { sportTypesStore } from 'stores';

import { REGISTERED_DISTANCE_SCHEMA } from '../constants';

import { resultStore } from '../stores';

import { getTimeResult } from '../selections';
import { RegisteredDistanceType, VirtualDistance } from '../types';

export const dataMapper = {
  distanceToRegisteredDistance: (value: DistanceType): RegisteredDistanceType => {
    const sportType = sportTypesStore.values.find((sport) => sport.id === value?.race?.sport_id);
    const bib = get(value, 'racers[0].bib_number');
    const racerClass = get(value, 'racers[0].class.title');
    const wave = get(value, 'racers[0].wave.name');
    const isOwner = get(value, 'racers[0].is_owner');
    const racerId = get(value, 'racers[0].id');
    const raceId = get(value, 'race.id');
    const withTeam = !!get(value, 'racers[0].team_id');
    const deletedAt = get(value, 'deleted_at');
    const default_image = get(value, 'default_image') || get(value, 'race.image') || DEFAULT_RACE_IMAGE;
    const location = get(value, 'location');
    const registration_starts_at = get(value, 'registration_starts_at');
    const registration_ends_at = get(value, 'registration_ends_at');
    const isTransferRegistrationAvailable =
      get(value, 'transfer_registration.available') &&
      get(value, 'race.allow_registration') &&
      !get(value, 'racers[0].registrationTransfers[0]');
    const published = get(value, 'race.published');
    const editInformationSettings = value.type === 'team' ? undefined : get(value, 'editInformationSettings');

    const defValue = {
      sport: sportType,
      race_id: raceId,
      is_owner: isOwner,
      withTeam,
      deleted_at: deletedAt,
      racer_id: racerId || null,
      default_image,
      location,
      registration_starts_at,
      registration_ends_at,
      raceName: get(value, 'race.name'),
      bib,
      racerClass,
      wave,
      published,
      isTransferRegistrationAvailable,
      registrationTransfer: get(value, 'transfer_registration.available')
        ? getRegistrationTransfer(get(value, 'racers[0].registrationTransfers[0]'))
        : null,
      editInformationSettings,
    };

    const mappedData = REGISTERED_DISTANCE_SCHEMA.reduce((acc, col) => {
      return { ...acc, [col]: (value as any)[col] };
    }, {});

    return { ...mappedData, ...defValue } as any;
  },

  formResult: (value: VirtualDistance) => {
    const copy = cloneDeep(value);

    return {
      time_result: getTimeResult(value, false, true),
      distance_logged: copy.racers[0].result.distance_logged,
      distance_logged_at: copy.racers[0].result.distance_logged_at,
      activity_link: copy.racers[0].result.activity_link,
    };
  },

  patchResult: (value: Object) => {
    const copy = cloneDeep(value);
    let diff = objectsDifference(copy, resultStore.defaultResult);
    Object.keys(diff).forEach((name) => {
      diff[name] = modifyValue(name, diff[name]);
    });
    return diff;
  },

  postResult: (value: any) => {
    let copy = cloneDeep(value);
    Object.keys(copy).forEach((name) => {
      if (!copy[name]) {
        copy = omit(copy, [name]);
        return;
      }

      copy[name] = modifyValue(name, copy[name]);
    });
    return copy;
  },

  resultForValidation: (value: any) => {
    const copy = cloneDeep(value);
    Object.keys(copy).forEach((name) => {
      copy[name] = modifyValue(name, copy[name]);
    });
    return copy;
  },
};

const modifyValue = (name: string, value: any): any => {
  if (!value) {
    return null;
  }

  switch (name) {
    case 'activity_link':
      return value.trim();

    case 'distance_logged_at':
      return moment(value).format('YYYY-MM-DD');

    case 'distance_logged':
      return +value;

    default:
      return value;
  }
};

const getRegistrationTransfer = (data) => {
  if (data) {
    return {
      distanceId: data.distance_id,
      fromUserId: data.from_user_id,
      id: data.id,
      isConfirmed: data.is_confirmed,
      isRejected: data.isRejected,
      racerId: data.racer_id,
      toUserId: data.to_user_id,
      token: data.token,
    };
  }

  return null;
};
