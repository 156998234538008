import { AnyStore } from 'src/stores';

export const focusStore = new AnyStore<boolean>(false);
export const borderStore = new AnyStore<boolean>(false);

export * from './distances';
export * from './classes';
export * from './waves';
export * from './squads';
export * from './checkpoints';
export * from './registrationFields';
export * from './goals';

export * from './filters';
export * from './paginationMeta';

export * from './userModal';
export * from './life';
export * from './version';
