import isEmpty from 'lodash/isEmpty';

import { API_LOCATIONS_URL, API_URL, LOAD_FILTERS } from 'src/constants';

import { action, request } from 'utils';

import { LoadableService } from 'services';

import { filterStore, Filter, sportTypesStore } from 'stores';

// @ts-ignore
class Filters extends LoadableService<RacesType, FiltersType, Filter> {
  constructor(store: Filter) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_LOCATIONS_URL}`,
      resource: LOAD_FILTERS,
    });
  }

  @request({ action: LOAD_FILTERS })
  async loadResourcesRequest(params: FiltersType = {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_FILTERS, minRequestTime: 800 })
  async loadSearchResources(
    params: FiltersType = {},
    cityRaceField?: {
      q: string;
    },
    pageNum: number = 1,
  ): Promise<any> {
    const newParams = this.getSearchModelParams(params);
    let queryParams = {
      search: '',
      searchFields: '',
    };

    if (!isEmpty(newParams)) {
      queryParams = {
        ...queryParams,
        ...newParams,
      };
    }

    if (cityRaceField && cityRaceField.q) {
      queryParams = {
        ...queryParams,
        ...cityRaceField,
      };
    }

    await super.plainLoadResources(queryParams, pageNum);
  }

  addValues(values: Array<AnyObject | any>, page: number, queryParams: AnyObject) {
    const mapped: any = values.map((item) => ({
      ...item,
      races: item.races.map((race: AnyObject) => ({
        ...race,
        sport: sportTypesStore.values.find((sport) => sport.id === race.sport_id),
      })),
    }));
    this.store.addValues(mapped, page, queryParams);
  }
}

const filtersService = new Filters(filterStore);

export { Filters, filtersService };
