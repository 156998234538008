import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const TimeClockPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM11 5V12V13H12H17V11H13V5H11Z'
    fill='currentColor'
  />
);

export const TimeClock = (props: IconProps) => <SvgIcon path={TimeClockPath} {...props} />;
