import { calculateCustomFieldsPrice } from './calculateCustomFieldPrice';
import { calculateMemberPriceWithDiscount } from './calculateMemberPriceWithDiscount';
import { calculatePriceWithSmartLinkDiscount } from './calculatePriceWithSmartLinkDiscount';
import { fetchPrice } from './fetchPrice';
import { format } from './format';
import { round } from './round';

export const priceUtils = {
  /**
   * @description
   * Fetch current distance price
   */
  fetchPrice,
  /**
   * @description
   * Calculate member price based on the coupon settings and provided distance and custom field prices
   */
  calculateMemberPriceWithDiscount,
  /**
   * @description
   * sum custom field value ids prices for one group sign up member
   */
  calculateCustomFieldsPrice,
  /**
   * @description
   * calculate price when SmartLink discount applied
   */
  calculatePriceWithSmartLinkDiscount,
  round,
  format,
};
