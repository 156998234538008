import { resultsUtil } from '../../utils';

import { distancePlaceColumn } from '../../derivations';
import { LResult } from '../../types';

function generatePlace(result: LResult, distanceId: number, checkpointId: number | nil) {
  const placeColGenerator = distancePlaceColumn(distanceId);
  const split = resultsUtil.findSplit(result, checkpointId);
  const placeCol = placeColGenerator.get();

  switch (placeCol) {
    case 'place':
      return result.place || null;
    case 'gender_place':
      return result.gender_place || null;
    case 'gender_class_place':
      return result.gender_class_place || null;
    case 'wave_place':
      return result.wave_place || null;
    case 'class_place':
      return result.class_place || null;
    case 'splits.place':
      return split?.place || null;
    case 'splits.gender_place':
      return split?.gender_place || null;
    case 'splits.class_place':
      return split?.class_place || null;
    case 'splits.wave_place':
      return split?.wave_place || null;
    case 'splits.gender_class_place':
      return split?.gender_class_place || null;
    default:
      return null;
  }
}

export { generatePlace };
