import { formatter } from 'utils';

import { form } from 'stores';

export const onBlurLink = (formName: string, name: string) => (): void => {
  const link = form.fetchWithoutObservable<string>(formName, name);

  if (link) {
    const newLink = formatter.withHttpUrl(link);
    form.onChange(formName, name, newLink);
  }
};
