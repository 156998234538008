import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { form } from 'stores';

import { MORE_INFO_FORM_ID } from '../constants';

const selectedSportsIds: IComputedValue<Array<number>> = computed(
  (): Array<number> => form.fetch<Array<number>>(MORE_INFO_FORM_ID, 'sports'),
);

export { selectedSportsIds };
