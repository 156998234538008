import axios from 'axios';

type Props<T> = {
  store: T;
  resourcesUrl: string;
  resourcesRoot?: string;
  resourceUrl?: string;
  resourceRoot?: string;
  resource: string;
  metaRoot?: string;
  paginationMetaRoot?: string;
};

class LoadableService<R extends Resource, F extends FiltersType, T extends ILoadable<R, F>> implements ILoadableService<R, F, T> {
  store: T;
  resourcesUrl: string;
  resourcesRoot: string;
  resourceUrl: string;
  resourceRoot: string;
  resource: string;
  metaRoot: string;
  paginationMetaRoot: string;

  constructor(props: Props<T>) {
    this.store = props.store;
    this.resourcesUrl = props.resourcesUrl;
    this.resourcesRoot = props.resourcesRoot || 'data';
    this.resourceUrl = props.resourceUrl || this.resourcesUrl;
    this.resourceRoot = props.resourceRoot || 'data';
    this.resource = props.resource;
    this.metaRoot = props.metaRoot || 'meta';
    this.paginationMetaRoot = props.paginationMetaRoot || 'pagination';
  }

  // LOAD resourceS
  async loadResourcesRequest(params: F | {}): Promise<any> {
    return axios.get(this.resourcesUrl, {
      params,
    });
  }

  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    let { filters, page } = this.store || {};
    page = page !== pageNum && pageNum ? pageNum : page;

    const queryParams = {
      ...filters,
      ...params,
      page,
    };

    const [status, response] = await this.loadResourcesRequest(queryParams);

    if (status) {
      let values = [];
      if (this.resourcesRoot === 'none') {
        values = response.data;
      } else {
        values = response.data[this.resourcesRoot];
      }
      this.addValues(values, page, queryParams);

      const paginationMeta = response.data[this.metaRoot] && response.data[this.metaRoot][this.paginationMetaRoot];
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  async plainLoadResources(params: FiltersType = {}, paramPage: number, interuptCallback: Function = () => false): Promise<any> {
    const page = (params.page as number) || paramPage || 1;
    const queryParams = {
      ...params,
      page,
    };
    const [status, response] = await this.loadResourcesRequest(queryParams);

    if (status && !interuptCallback()) {
      let values = [];
      if (this.resourcesRoot === 'none') {
        values = response.data;
      } else {
        values = response.data[this.resourcesRoot];
      }
      this.addValues(values, page, queryParams as any);

      const paginationMeta = response.data[this.metaRoot] && response.data[this.metaRoot][this.paginationMetaRoot];
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  loadMore(): any {
    const page = this.store.page;
    return this.loadResources({}, page + 1);
  }

  // LOAD resource
  async loadResourceRequest(id: string, params: F | FiltersType): Promise<any> {
    return axios.get(`${this.resourceUrl}/${id}`, {
      params,
    });
  }

  async loadResource(id: string, params: FiltersType = {}): Promise<any> {
    let { resourceParams } = this.store || {};

    const queryParams = {
      ...resourceParams,
      ...params,
    };

    const response = await this.loadResourceRequest(id, queryParams);
    const value = response.data[this.resourceRoot];
    this.store.addSelectedValue(value);

    return response;
  }

  addValues(values: Array<R>, page: number, queryParams: F) {
    this.store.addValues(values, page, queryParams);
  }

  clean(): void {
    this.store.clearData();
  }

  getStore(): T {
    return this.store;
  }

  getSearchModelParams(params: F): F | void {
    if (!this.store?.retrieveSearchDataModel) return;
    const searchModel = this.store.retrieveSearchDataModel();
    searchModel?.clearFilters();
    searchModel?.addFilters(params);
    // @ts-ignore
    return searchModel?.params();
  }
}

export { LoadableService };
