import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const InstagramPath = (
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM9.36164 5.63867C10.0443 5.6076 10.2624 5.6 12.0006 5.6H11.9986C13.7373 5.6 13.9546 5.6076 14.6373 5.63867C15.3186 5.66987 15.784 5.77774 16.192 5.936C16.6133 6.09934 16.9693 6.31801 17.3253 6.67401C17.6813 7.02975 17.9 7.38682 18.064 7.80776C18.2213 8.21469 18.3293 8.67977 18.3613 9.36111C18.392 10.0438 18.4 10.2619 18.4 12.0001C18.4 13.7382 18.392 13.9558 18.3613 14.6385C18.3293 15.3196 18.2213 15.7848 18.064 16.1918C17.9 16.6126 17.6813 16.9697 17.3253 17.3255C16.9697 17.6815 16.6132 17.9007 16.1924 18.0641C15.7852 18.2224 15.3196 18.3303 14.6382 18.3615C13.9556 18.3925 13.7381 18.4001 11.9998 18.4001C10.2618 18.4001 10.0438 18.3925 9.36111 18.3615C8.6799 18.3303 8.21469 18.2224 7.80749 18.0641C7.38682 17.9007 7.02975 17.6815 6.67414 17.3255C6.31827 16.9697 6.09961 16.6126 5.936 16.1917C5.77787 15.7848 5.67 15.3197 5.63867 14.6384C5.60773 13.9557 5.6 13.7382 5.6 12.0001C5.6 10.2619 5.608 10.0436 5.63853 9.36097C5.6692 8.6799 5.7772 8.21469 5.93587 7.80762C6.09987 7.38682 6.31854 7.02975 6.67454 6.67401C7.03028 6.31814 7.38735 6.09947 7.80829 5.936C8.21523 5.77774 8.6803 5.66987 9.36164 5.63867Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.4265 6.75331C11.538 6.75313 11.6579 6.75319 11.7873 6.75325L12.0006 6.75331C13.7094 6.75331 13.912 6.75944 14.5868 6.79011C15.2108 6.81864 15.5495 6.92291 15.7751 7.01051C16.0737 7.12651 16.2867 7.26518 16.5105 7.48918C16.7345 7.71319 16.8732 7.92652 16.9895 8.22519C17.0771 8.45053 17.1815 8.7892 17.2099 9.4132C17.2405 10.0879 17.2472 10.2905 17.2472 11.9986C17.2472 13.7066 17.2405 13.9093 17.2099 14.5839C17.1813 15.2079 17.0771 15.5466 16.9895 15.7719C16.8735 16.0706 16.7345 16.2833 16.5105 16.5071C16.2865 16.7311 16.0739 16.8698 15.7751 16.9858C15.5497 17.0738 15.2108 17.1778 14.5868 17.2064C13.9121 17.237 13.7094 17.2437 12.0006 17.2437C10.2917 17.2437 10.0891 17.237 9.41447 17.2064C8.79046 17.1776 8.45179 17.0733 8.22605 16.9857C7.92739 16.8697 7.71405 16.731 7.49005 16.507C7.26604 16.283 7.12738 16.0702 7.01111 15.7714C6.92351 15.5461 6.81911 15.2074 6.79071 14.5834C6.76004 13.9087 6.75391 13.706 6.75391 11.997C6.75391 10.2879 6.76004 10.0863 6.79071 9.4116C6.81924 8.7876 6.92351 8.44893 7.01111 8.22332C7.12711 7.92465 7.26604 7.71132 7.49005 7.48732C7.71405 7.26331 7.92739 7.12465 8.22605 7.00838C8.45166 6.92038 8.79046 6.81638 9.41447 6.78771C10.0049 6.76104 10.2337 6.75304 11.4265 6.75171V6.75331ZM15.4169 7.81599C14.9929 7.81599 14.6489 8.15959 14.6489 8.58373C14.6489 9.00773 14.9929 9.35174 15.4169 9.35174C15.8409 9.35174 16.1849 9.00773 16.1849 8.58373C16.1849 8.15972 15.8409 7.81572 15.4169 7.81572V7.81599ZM8.71393 12C8.71393 10.185 10.1855 8.7134 12.0005 8.71333C13.8156 8.71333 15.2868 10.1849 15.2868 12C15.2868 13.8151 13.8157 15.2861 12.0006 15.2861C10.1855 15.2861 8.71393 13.8151 8.71393 12Z'
      fill='currentColor'
    />
    <path
      d='M12.0005 9.8667C13.1787 9.8667 14.1339 10.8218 14.1339 12.0001C14.1339 13.1782 13.1787 14.1334 12.0005 14.1334C10.8223 14.1334 9.86719 13.1782 9.86719 12.0001C9.86719 10.8218 10.8223 9.8667 12.0005 9.8667Z'
      fill='currentColor'
    />
  </>
);

export const Instagram = (props: IconProps) => <SvgIcon path={InstagramPath} {...props} />;
