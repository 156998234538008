import trim from 'lodash/trim';
import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';
import { Close } from 'components/modal';

export type Props = BaseInputProps & {
  onlyDigits?: boolean;
  shouldTrim?: boolean;
  additionalComponent?: AnyFunction;
  unit?: string | React.ReactElement;
  onClick?: AnyFunction;
  isWarning?: boolean;
  onClose?: AnyFunction;
  showCloseButton?: boolean;
  autoFocus?: boolean;
};

export const getBorderColor = (color?: string) => (props) => {
  return !props.hasError
    ? color || props.theme.main.colors.clay3
    : props.isWarning
    ? props.theme.main.colors.warning
    : props.theme.main.colors.error;
};

export const getBackgroundColor = (props) => {
  return !props.hasError
    ? props.theme.main.colors.clay6
    : props.isWarning
    ? props.theme.main.colors.warning20
    : props.theme.main.colors.error20;
};

export const StyledInput = styled.input<{ hasError?: boolean; isWarning?: boolean; withUnit: boolean }>`
  display: flex;
  align-items: center;
  height: 56px;
  flex: 1;

  padding: 0 16px;
  ${(props) => (props.withUnit ? 'padding-right: 52px;' : '')}

  background: ${getBackgroundColor};
  border-radius: 2px;
  border: 2px solid ${getBorderColor('transparent')};

  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};
  ${(props) => (props.disabled ? 'pointer-events: none;' : '')}
  opacity: 1;

  // removed microsoft edge show-password icon
  &::-ms-reveal {
    display: none;
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: ${getBorderColor()};
  }

  &::placeholder {
    color: ${(props) => props.theme.main.colors.clay4};
  }

  &:disabled {
    color: ${(props) => props.theme.main.colors.clay4};
  }
`;

const Unit = styled(Utility)<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  min-width: 52px;
  cursor: pointer;
`;

const InputBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .close-icon {
    cursor: pointer;
    margin-left: -34px;
  }
`;

export const InputComponent = (props: Props): React.ReactNode | any => {
  const {
    errorMessage,
    isWarning,
    type,
    placeholder,
    name,
    value,
    disabled,
    readOnly,
    onChange,
    onBlur,
    onKeyPress,
    id,
    autoComplete,
    inputRef,
    onlyDigits,
    shouldTrim,
    unit,
    onClick,
    onClose,
    showCloseButton = false,
    autoFocus,
  } = props;

  return (
    <InputBlock onClick={onClick}>
      <StyledInput
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
        ref={inputRef}
        type={onlyDigits ? 'number' : type}
        placeholder={placeholder}
        id={id}
        name={name}
        value={value || ''}
        disabled={disabled}
        readOnly={readOnly}
        hasError={Boolean(errorMessage)}
        isWarning={isWarning}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let { value } = e.currentTarget;
          if (onlyDigits && !new RegExp('^[0-9]*$').test(value)) return;
          if (shouldTrim) value = trim(value);
          onChange({ name, value }, e);
        }}
        withUnit={Boolean(unit)}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        {...(autoComplete ? { autoComplete } : {})}
        size={1}
      />
      <Unit variant='u4' weight='bold' show={Boolean(unit)}>
        {unit}
      </Unit>

      <Show if={showCloseButton}>
        <Close onClick={onClose as AnyFunction} />
      </Show>
    </InputBlock>
  );
};
