import axios from 'axios';
import { first } from 'lodash';
import shortid from 'shortid';

import { API_URL, API_DISTANCE_RESULTS } from 'src/constants';

import { request, action } from 'utils';
import { encodeQuery } from 'utils';

class RacerResultService {
  @request({ action: shortid() })
  request(distanceId: number, params: any): Promise<any> {
    const url = encodeQuery.generatePath(API_URL + API_DISTANCE_RESULTS, { id: distanceId });
    return axios.get(url, { params });
  }

  @action({ action: shortid() })
  async load(distanceId: number, racerId: number): Promise<any> {
    const [, response] = await this.request(distanceId, this.params(racerId));
    return first(response.data.data) || null;
  }

  params(racerId: number) {
    return {
      search: `racer_id:${racerId}`,
      searchFields: 'racer_id:=',
      searchJoin: 'AND',
    };
  }
}

export { RacerResultService };
