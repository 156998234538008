import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { BodyText } from '../Typography';

export const PaginationContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PaginationElement = styled.li<{ active: boolean }>`
  margin: 0 4px;

  > div {
    height: 16px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.theme.main.colors.clay1};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.main.colors.clay3};
      border-color: ${(props) => props.theme.main.colors.clay3};
      color: ${(props) => props.theme.main.colors.white};
    }

    ${(props) =>
      props.active &&
      css`
        background-color: ${(props) => props.theme.main.colors.clay1};
        color: ${(props) => props.theme.main.colors.white};
      `}
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin: 0 8px;
  }
`;

const Arrow = styled.li<{ disabled: boolean }>`
  svg path {
    stroke: ${(props) => props.theme.main.colors.clay1};
  }

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    css`
      svg path {
        stroke: ${props.theme.main.colors.clay4};
      }

      &:hover {
        cursor: not-allowed;
      }
    `}
`;

export const ArrowLeft = styled(Arrow)`
  margin-right: 16px;

  svg {
    transform: translate3d(0, 0, 0) rotate(90deg);
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-right: 32px;
  }
`;

export const ArrowRight = styled(Arrow)`
  margin-left: 16px;

  svg {
    transform: translate3d(0, 0, 0) rotate(270deg);
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-left: 32px;
  }
`;

export const Description = styled(BodyText)`
  text-align: center;
  font-weight: 500;
  margin-top: 24px;
`;
