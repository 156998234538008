import { distanceService } from '../services';

import { distanceStore } from '../stores';

async function setupMainData(distanceId: number) {
  const distance = await distanceService.load(distanceId);

  if (!distance) {
    return;
  }

  distanceStore.setValue(distance);
}

export { setupMainData };
