import { CheckpointModel } from 'models';

import { LCheckpoint } from '../types';

class LCheckpointModel extends CheckpointModel<LCheckpoint> {
  isLast() {
    return Boolean(this.value.isLast);
  }

  notLast() {
    return !this.isLast();
  }
}

export { LCheckpointModel };
