import { Observer } from 'mobx-react';
import * as React from 'react';
import { ScrollSync } from 'react-scroll-sync';

import { LeftSideWrapper, RightSideWrapper, TableWrapper, LeftTableHeader } from 'src/modules/racersTable/components';
import { onHorizontalScroll } from 'src/modules/racersTable/utils';

import { RightSide, LeftSide, RightHeader } from '../../components/Table';
import { UsersCount } from '../../components/UsersCount';

import { currentDistance } from '../../derivations';

export const Table = () => (
  <Observer>
    {() => {
      const distance = currentDistance.model.get();
      if (!distance) return null;

      return (
        <ScrollSync onSync={onHorizontalScroll}>
          <TableWrapper>
            <LeftTableHeader withPlace participantsCount={<UsersCount />} />
            <RightSideWrapper sticky>
              <RightHeader distance={distance} />
            </RightSideWrapper>
            <LeftSideWrapper>
              <LeftSide distance={distance} />
            </LeftSideWrapper>
            <RightSideWrapper withBorder>
              <RightSide distance={distance} />
            </RightSideWrapper>
          </TableWrapper>
        </ScrollSync>
      );
    }}
  </Observer>
);
