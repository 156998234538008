import { Observer } from 'mobx-react';
import { handleMilesRedirect } from 'modules/header/actions';
import { DesktopSearch } from 'modules/search/containers';
import { focusedStore } from 'modules/search/stores';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'src/styledComponents/Button';
import { Field as NavigationField } from 'src/styledComponents/Navigation/NavigationTypes';

import { AuthMenuTypes } from '../../types';
import { AuthMenu } from './AuthMenu';
import { LanguageSelect } from './LanguageSelect';
import { Props as LanguageSelectTypes } from './LanguageSelect/LanguageSelectTypes';
import { NavigationMenu, Logo } from './index';

type Props = {
  navList: NavigationField[];
  language: LanguageSelectTypes;
  auth: AuthMenuTypes;
  fullWidth?: boolean;
  hide?: boolean;
};

type HeaderWrapperProps = {
  hide?: boolean;
  hasActive?: boolean;
};

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 16px 24px;

  display: ${(props) => (props?.hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;

  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
`;

export const TopWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  justify-content: space-between;

  max-width: ${(props) => (props.fullWidth ? 'unset' : '1280px')};
  width: 100%;
  flex-direction: row;
  padding: 0;

  .right-block {
    display: flex;
    align-items: center;
  }
`;

export const TabletHeader: React.FC<Props> = (props) => {
  const { navList, language, auth, hide, fullWidth } = props;

  return (
    <Observer>
      {() => {
        const hasActive = Boolean(focusedStore.value);

        return (
          <>
            <HeaderWrapper hide={hide} hasActive={hasActive}>
              <TopWrapper fullWidth={fullWidth}>
                <Logo />
                <div className='right-block'>
                  <NavigationMenu navList={navList} isTablet />
                  <Button variant='secondary' size='small' onClick={handleMilesRedirect}>
                    Go to miles
                  </Button>
                  <LanguageSelect {...language} isDark />
                  <AuthMenu {...auth} isTablet />
                </div>
              </TopWrapper>
              <DesktopSearch isTablet />
            </HeaderWrapper>
          </>
        );
      }}
    </Observer>
  );
};
