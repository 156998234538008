export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCOUNT_INFO = 'ACCOUNT_INFO';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_MORE_INFO = 'UPDATE_MORE_INFO';
export const HEADER_FILTER = 'HEADER_FILTER';
export const REGISTRATION = 'REGISTRATION';

export const APP_INITIALIZE = 'APP_INITIALIZE';

// services
export const LOAD_SPORT_TYPES = 'LOAD_SPORT_TYPES';
export const SPORT_TYPE = 'SPORT_TYPE';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const LOAD_ARTICLES = 'LOAD_ARTICLES';
export const LOAD_ARTICLE = 'LOAD_ARTICLE';
export const LOAD_RACES = 'LOAD_RACES';
export const LOAD_RACE = 'LOAD_RACE';
export const LOAD_MORE_RACES = 'LOAD_MORE_RACES';
export const LOAD_FILTERS = 'LOAD_FILTERS';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_WISHLISTS = 'LOAD_WISHLISTS';
export const LOAD_AVATAR = 'LOAD_AVATAR';
export const USER = 'USER';
export const COUNTRY = 'COUNTRY';
export const ARTICLE = 'ARTICLE';
export const RACE = 'RACE';
export const LOCATION = 'LOCATION';
export const WISHLIST = 'WISHLIST';
export const RACERS = 'RACERS';
export const RESULTS = 'RESULTS';
export const ADD_RACE_TO_FAVORITE = 'ADD_RACE_TO_FAVORITE';
export const REMOVE_RACE_FROM_FAVORITE = 'REMOVE_RACE_FROM_FAVORITE';
export const CHANGE_WISHLISTS = 'CHANGE_WISHLISTS';
export const LOAD_DISTANCES = 'LOAD_DISTANCES';
export const LOAD_REGISTRATION = 'REGISTRATION';
export const LOAD_REGISTRATION_STATUS = 'LOAD_REGISTRATION_STATUS';
export const GET_REGISTRATION_DATA = 'GET_REGISTRATION_DATA';
export const LOAD_REGISTRATION_COUPON = 'LOAD_REGISTRATION_COUPON';
export const DELETE_CANCELED_PAYMENTS = 'DELETE_CANCELED_PAYMENTS';
export const LOAD_TEAM_NAME = 'LOAD_TEAM_NAME';
export const LOAD_RACERS = 'LOAD_RACERS';
export const LOAD_MORE_RACERS = 'LOAD_MORE_RACERS';
export const LOAD_RESULTS = 'LOAD_RESULTS';
export const LOAD_MORE_RESULTS = 'LOAD_MORE_RESULTS';
export const PAYMENTS = 'PAYMENTS';
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const FETCH_RACE = 'FETCH_RACE';
export const PATCH_PAYMENT = 'PATCH_PAYMENT';
export const LOAD_DISTANCES_COUNT = 'LOAD_DISTANCES_COUNT';
export const GROUP_SIGN_UP = 'GROUP_SIGN_UP';
export const GROUP_SIGN_UP_EMAIL = 'GROUP_SIGN_UP_EMAIL';
export const REGISTRATION_CONFIG = 'REGISTRATION_CONFIG';
export const LOAD_RACE_AUTH = 'LOAD_RACE_AUTH';
export const RACE_AUTH_OVERLAY = 'RACE_AUTH_OVERLAY';
export const LOAD_LANGUAGES = 'LOAD_LANGUAGES';

export const LOAD_PAYMENTS = 'LOAD_PAYMENTS';

export const GENERATE_DYNAMIC_LINK = 'GENERATE_DYNAMIC_LINK';
export const TRAINING_PLAN = 'TRAINING_PLAN';

// COUNTER STORE
export const LOAD_COUNTER = 'LOAD_COUNTER';
export const INIT_TRANSLATIONS = 'INIT_TRANSLATIONS';
export const HEADER_FORM = 'HEADER_FORM';
