import { API_COUNTRIES_URL, API_URL, LOAD_COUNTRIES, COUNTRY } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { countriesStore, Country } from 'stores';

class Countries extends LoadableService<CountriesType, FiltersType, Country> {
  constructor(store: Country) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_COUNTRIES_URL}`,
      resourcesRoot: 'data',
      resource: COUNTRY,
    });
  }

  @request({ action: LOAD_COUNTRIES })
  async loadResourcesRequest(params: FiltersType | {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_COUNTRIES, minRequestTime: 800 })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    if (!this.store.values.length) {
      return super.loadResources(params, pageNum);
    }
  }
}

const countriesService = new Countries(countriesStore);

export { Countries, countriesService };
// export default new Countries(countriesStore);
