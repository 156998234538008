import { createBrowserHistory } from 'history';
import { History, Location } from 'history';

import { historyRouteActionWrapper } from './historyRouteActionWrapper';
import { generateSafeGoBack } from './safeGoBack';
import { safeGoBack } from './safeGoBack';

type ExtendedRouterHistory = History & {
  safeGoBack: safeGoBack;
};

const history: ExtendedRouterHistory = new Proxy(createBrowserHistory(), {
  get(target, property) {
    switch (property) {
      case 'replace':
      case 'push':
        return historyRouteActionWrapper(target[property]);
      default:
        // @ts-ignore
        return target[property];
    }
  },
}) as any;

const historyStore: Location[] = [];
const fullHistoryStore: AnyObject[] = [];

history.listen((location, action) => {
  fullHistoryStore.push({ location, action });
  historyStore.push(location);
});

history.safeGoBack = generateSafeGoBack(history, fullHistoryStore);

export { history, historyStore, ExtendedRouterHistory };
