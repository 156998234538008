import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_VIRTUAL_RESULT } from 'src/constants';

import { action, request } from 'utils';

class SingleVirtual {
  params: Object = {
    with: 'race.sport;goal',
    withCount: 'racers;teams',
  };

  @request({ action: shortid() })
  request(id: number): any {
    const url = generatePath(API_URL + API_VIRTUAL_RESULT, { id });
    return axios.get(url, { params: this.params });
  }

  @action({ action: shortid() })
  async load(id: number): Promise<null | Object> {
    const [status, response] = await this.request(id);

    if (!status) {
      return null;
    }

    // TODO discuss
    return response.data.data;
  }
}

export { SingleVirtual };
