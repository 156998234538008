import * as React from 'react';
import styled from 'styled-components';

export const getGradientByPlace = (props) => {
  switch (props.place) {
    case 1:
      return 'background: linear-gradient(286.72deg, rgba(244, 211, 94, 0) 31.17%, rgba(204, 164, 59, 0.6) 186.45%);';

    case 2:
      return 'background: linear-gradient(113.29deg, rgba(173, 181, 189, 0.6) -35.22%, rgba(222, 226, 230, 0) 67.95%);';

    case 3:
      return 'background: linear-gradient(266.42deg, rgba(195, 142, 112, 0) 15.12%, rgba(119, 73, 54, 0.6) 293.19%);';
  }
};

export const ItemWrapper = styled.div<{ place?: number | nil; additionalHeight: number; height: number }>`
  background-color: ${(props) => props.theme.main.colors.clay7};
  padding: 20px 0;
  overflow: hidden;
  transition: height 300ms ease-in-out;
  position: relative;
  height: ${(props) => props.additionalHeight + props.height}px;

  ${getGradientByPlace}

  &:nth-of-type(odd) {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }
`;
