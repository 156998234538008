import { cloneDeep } from 'lodash';
import moment from 'moment';

import { BE_DATE_FORMAT } from 'src/constants';
import { sportTypesStore } from 'src/stores';

import { SportType } from 'models/sportType';

import { amountInDropdown, DistanceOptionsMapped } from '../constants';

import { getOriginalLocationName } from '../selectors';

export const mapSearchRacesResults = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  return copy.slice(0, amountInDropdown.raceName).map((item) => {
    const sport = sportTypesStore.valuesById[item.sport_id];

    return {
      key: item.id,
      value: item.name,
      label: item.name,
      sportIcon: sport?.icon,
    };
  });
};

export const mapLocations = (data: AnyObject[]) => {
  const copy = cloneDeep(data);

  return copy.slice(0, amountInDropdown.locations).map((item) => ({
    id: item.place_id,
    city: item.structured_formatting.main_text,
    country: item.terms.length === 1 ? null : item.terms[item.terms.length - 1].value,
  }));
};

export const mapSportOptions = (data: SportTypesType[]): DropdownItemType[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    const model = new SportType(item);
    return {
      key: Number(item.id),
      label: model.title,
      value: model.title,
    };
  });
};

export const mapFormToQuery = (data: AnyObject) => {
  return {
    location: data.location,
    raceName: data.raceName,
    sports: (data.sports || []).map((item) => item.key),
    distances: (data.distance || []).map((item) => item.id || item.key),
    date:
      !data.date?.startDate && !data.date?.endDate
        ? {}
        : {
            startDate: data.date.startDate?.format(BE_DATE_FORMAT),
            endDate: data.date.endDate?.format(BE_DATE_FORMAT),
          },
  };
};

export const mapQueryToForm = (data: AnyObject) => {
  const originalLocationName = getOriginalLocationName();
  const location = data.location && originalLocationName ? originalLocationName : data.location;

  return {
    location: location || '',
    raceName: data.raceName || '',
    sports:
      data.sports?.map((id) => {
        const item = sportTypesStore.valuesById[Number(id)];
        const model = new SportType(item);
        return {
          key: Number(id),
          label: model.title,
          value: model.title,
        };
      }) || [],
    distance: data.distances?.map((distanceId) => DistanceOptionsMapped.find((item) => item.key === distanceId)),

    date:
      !data.date?.startDate && !data.date?.endDate
        ? {}
        : {
            startDate: data.date.startDate ? moment(data.date.startDate) : null,
            endDate: data.date.endDate ? moment(data.date.endDate) : null,
          },
  };
};
