import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Icon } from 'src/styledComponents/Icons';
import { BodyText, Headline } from 'src/styledComponents/Typography';

import { t } from 'utils';

import AuthPageTemplate from './AuthPageTemplate';

const StyledAuthTemplate = styled(AuthPageTemplate)`
  .auth-template-content {
    padding: 0 0 120px;
    height: calc(100vh - 120px);

    @media (max-width: ${RESOLUTIONS.small}px) {
      padding: 0 0 52px;
      height: calc(100vh - 72px);
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledHeadline = styled(Headline)`
  margin: 32px 0 12px;
`;

export const PasswordRecoveryConfirm = () => {
  return (
    <StyledAuthTemplate testAnchorContainer='ForgotPassword'>
      <Content>
        <Icon name='ConfirmCheckmark' size={48} />
        <StyledHeadline variant='h4'>{t.staticAsString('passwordRecovery.confirmHeading')}</StyledHeadline>
        <BodyText variant='body2'>{t.staticAsString('passwordRecovery.confirmBody')}</BodyText>
      </Content>
    </StyledAuthTemplate>
  );
};
