import * as React from 'react';
import styled, { css } from 'styled-components';

import { TagLine } from 'src/styledComponents/Typography';

const getTagStyles = (props) => {
  if (props.isPlaceholder) return '';

  return css`
    position: absolute;
    top: 10px;
    left: 40px;
    z-index: 1;
    white-space: nowrap;
  `;
};

const Wrapper = styled.div<{ isPlaceholder?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  height: 32px;
  width: 100%;
  padding-left: 40px;
  background-color: white;

  ${TagLine} {
    ${getTagStyles}
  }
`;

type Props = {
  name?: string;
  isPlaceholder?: boolean;
};

export const DistanceHeader: React.FC<Props> = (props) => {
  const { name, isPlaceholder } = props;

  return (
    <Wrapper isPlaceholder={isPlaceholder}>
      <TagLine>{name}</TagLine>
    </Wrapper>
  );
};
