import { computed } from 'mobx';
import { headerStore } from 'modules/header/stores';
import { HeaderVariants } from 'modules/header/types';

export const isMiles = computed((): boolean => {
  const variant = headerStore.value?.variant;
  return variant === HeaderVariants.milesApp || variant === HeaderVariants.milesLanding;
});

export const isMilesLanding = computed((): boolean => {
  const variant = headerStore.value?.variant;
  return variant === HeaderVariants.milesLanding;
});
