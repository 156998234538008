import { trainingPlanStore } from 'modules/miles/app/home/stores';

import { ROUTER, TRAINING_PLAN } from 'src/constants';
import { progressStore, sessionStore } from 'src/stores';
import { encodeQuery, history } from 'src/utils';

import { headerStore } from '../stores';

export const setSlimOptions = () => {
  headerStore.setValue({
    ...headerStore.value!,
  });
};

export const setNormalOptions = () => {
  headerStore.setValue({
    ...headerStore.value!,
  });
};

export const onChooseAuthOption = (data: { name: string; value: DropdownItemType }) => {
  switch (data.value.key) {
    case 1:
      history.push(ROUTER.PROFILE);
      return;

    case 2:
      history.push(ROUTER.SETTINGS);
      return;

    case 3:
      sessionStore.logout();
      return;
  }
};

export const onClickAuth = () => {
  if (sessionStore.isAuth) {
    history.push(ROUTER.PROFILE);
    return;
  }

  const redirectLocation = `${history.location.pathname}${history.location.search}`;
  const path = `${ROUTER.SIGNIN}?redirect_location=${redirectLocation}`;

  history.push(path);
};

export const handleFindRaces = () => {
  const query = encodeQuery.stringify({ showAll: true });
  history.push(`${ROUTER.RACES}?${query}`);
};

export const handleMilesRedirect = () => {
  const redirectRoute = trainingPlanStore.isTrainingPlanAvailable ? ROUTER.MILES_TRAINING_PLAN : ROUTER.MILES;
  history.push(redirectRoute);
};
