import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const CheckinDailyPath = (
  <>
    <path
      d='M10.1905 18.8C10.947 16.0437 14.1395 15 17 15C19.8605 15 22.8674 17.1725 23.0135 20.102C23.05 20.8737 22.9456 21.614 22.7213 22.3017C22.5856 22.7217 22.1786 23 21.7351 23H5.17522C2.54134 23 0.565923 20.575 1.08247 17.9763L3.77791 4.41515C4.05696 3.01123 5.28897 2 6.72035 2H9.93707C10.2969 2 10.6289 2.1933 10.8066 2.50617L12.1628 4.89411C12.415 5.33829 12.292 5.90119 11.8774 6.19959L8.91972 8.32825C8.48199 8.64329 7.8733 8.55447 7.54384 8.12748L6.86679 7.25'
      fill='currentColor'
    />
    <rect x='12' y='11' width='10' height='2' fill='currentColor' />
    <rect x='15' y='7' width='7' height='2' fill='currentColor' />
    <rect x='18' y='3' width='4' height='2' fill='currentColor' />
  </>
);

export const CheckinDaily = (props: IconProps) => <SvgIcon path={CheckinDailyPath} {...props} />;
