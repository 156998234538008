function formatDigits(digits: string): string {
  return digits.length >= 2 ? digits : `0${digits}`;
}

function formatMillisecondsDigits(digits: string): string {
  switch (digits.length) {
    case 1:
      return `00${digits}`;
    case 2:
      return `0${digits}`;
    default:
      return digits;
  }
}

export { formatDigits, formatMillisecondsDigits };
