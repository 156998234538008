import { REGISTERED, SAVED, XTRI, MY_RESULTS } from 'src/constants';

export const registeredTo = {
  id: REGISTERED,
  title: 'profile.myRaces',
  message: 'profile.notAppliedMessage',
};

export const saved = {
  id: SAVED,
  title: 'profile.savedRaces',
  message: 'profile.notInterstedMessage',
};

export const xtri = {
  id: XTRI,
  title: 'profile.myXtri',
  message: 'profile.notInterstedMessage',
};

export const my_results = {
  id: MY_RESULTS,
  title: 'profile.my_results',
  message: 'profile.notAppliedMessage',
};

export const INITIALIZE_PROFILE = 'INITIALIZE_PROFILE';
export const REGISTERED_TO_PROFILE = 'REGISTERED_TO_PROFILE';
export const SAVED_RACES_PROFILE = 'SAVED_RACES_PROFILE';
export const XTRI_TICKETS_PROFILE = 'XTRI_TICKETS_PROFILE';
export const CHANGE_WISHLIST = 'CHANGE_WISHLIST';
export const LOAD_MY_RESULTS = 'LOAD_MY_RESULTS';

export const LOAD_MY_RESULTS_CLASSIC = 'LOAD_MY_RESULTS_CLASSIC';
export const LOAD_MY_RESULTS_VIRTUAL = 'LOAD_MY_RESULTS_VIRTUAL';
export const LOAD_MY_RESULTS_CUMULATIVE = 'LOAD_MY_RESULTS_CUMULATIVE';

export const INITIALIZE_XTRI = 'INITIALIZE_XTRI';
export const LOAD_UNIONS = 'LOAD_UNIONS';
export const LOAD_DISTANCES_FOR_TICKETS = 'LOAD_DISTANCES_FOR_TICKETS';

export const MY_RESULTS_FORM = 'MY_RESULTS_FORM';
export const CUMULATIVE_FORM = 'CUMULATIVE_FORM';

export const DOWNLOAD_RECEIPT = 'DOWNLOAD_RECEIPT';

export const TEAM = 'team';
export const SINGLE = 'single';

export const REGISTERED_DISTANCE_SCHEMA = [
  'id',
  'race_id',
  'name',
  'race_date',
  'ends_at',
  'race_length',
  'type',
  'sport',
  'order',
  'is_owner',
  'deleted_at',
  'distance_mode',
  'status',
  'published',
  'show_bibs',
  'show_startlist',
  'transfer_registration',
];

export const ACTIVE_REGISTER_DATE = '2019.10.30 20:00';
export const ACTIVE_REGISTER_DATE_FORMAT = 'YYYY.MM.DD HH:mm';

// Maybe convert to proper Typescript Enums?
export const RESULT_TYPES = {
  races: 'races',
  virtual: 'virtual',
  cumulative: 'cumulative',
} as const;
