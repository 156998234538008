import { generateDistanceSelect, generateClassesSelect, generateWavesSelect, gendersSelect } from 'modules/racersTable/utils';

import { Distance } from 'models';

import { distancesStore, classesStore, wavesStore } from '../stores';

import { currentDistance } from '../derivations/currentDistance';

function distancesDisableShowAllIf(distances: Distance[]) {
  return distances.length <= 1;
}

const distanceSelect = generateDistanceSelect(distancesStore, distancesDisableShowAllIf);
const classesSelect = generateClassesSelect(currentDistance.id, classesStore);
const wavesSelect = generateWavesSelect(currentDistance.id, wavesStore);

export { distanceSelect, classesSelect, gendersSelect, wavesSelect };
