import axios from 'axios';
import take from 'lodash/take';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { activeDayWorkout, trainingPlanStore } from 'modules/miles/app/home/stores';
import { FutureWeeksBlock } from 'modules/miles/app/home/ui/TrainingPlan/FutureWeeksBlock';
import { ToolBar } from 'modules/miles/app/home/ui/TrainingPlan/ToolBar';
import { WeekPlanBlock } from 'modules/miles/app/home/ui/TrainingPlan/WeekPlanBlock';
import { IconButton } from 'modules/miles/app/home/ui/styled-components';
import { Settings, Text } from 'modules/miles/shared/components';
import TrainingMessageAccordion from 'modules/miles/shared/components/Accordion/TrainingMessage';
import moment from 'moment';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Miles } from 'src/assets/images/miles/logotypes';
import { MILES_NEWS, ROUTER } from 'src/constants';
import { PulseLoader } from 'src/styledComponents/Loader/PulseLoader';

import { Hide, Show } from 'components/condition';

import { history } from 'utils';

import { windowSize } from 'stores';

import { MilesEvents } from '../../../../../../constants/analytics';

import { LogEventWithUTM } from '../../../../../registration/utils';
import { BrandButton } from '../../../../shared/components/Button/Brand';
import { Sessions } from '../Metrics/Insights/Sessions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 0;
  .row {
    display: flex;
    justify-content: space-between;
    text-align: start;
    align-items: center;
  }
  .logo {
    height: 20px;
  }
`;

export const TrainingMessage = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
  background: ${({ theme }) => theme.miles.colors.dark.alpha.cobalt300};
  box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion1};
`;

const TextWithColor = styled(Text)`
  color: ${({ theme }) => theme.miles.colors.dark.thin.cobalt};
`;
const cachedNews = localStorage.getItem('milesNews') ?? '';
export const EMPTY_MILES_MESSAGE = 'No message available';
export const TrainingPlan = observer(() => {
  const isTrainingPlanLoading = trainingPlanStore.isLoading;
  const trainingPlanByWeeks = trainingPlanStore.trainingPlanByWeeks;
  const weeksArray = Object.keys(trainingPlanByWeeks);
  const isDesktop = windowSize.isGreaterThan('large');
  const hasNotification =
    trainingPlanStore.changeMsg !== '' ||
    trainingPlanStore.trainingMsg !== '' ||
    trainingPlanStore.readinessMsg !== '' ||
    trainingPlanStore.changedParams !== '';
  const [newsMessage, setNewsMessage] = useState('');
  const isPlanChanging = trainingPlanStore.changedParams !== '';
  useEffect(() => {
    const loadNews = async () => {
      const response = await axios.get(MILES_NEWS);
      const data = await response.data.news;
      setNewsMessage(data);
      localStorage.setItem('milesNews', data);
    };
    loadNews();
    LogEventWithUTM(MilesEvents.VisitTrainingPlan);
    const params = QueryString.parse(history.location.search, { ignoreQueryPrefix: true });
    if ('date' in params) {
      const date = moment(params['date'] as string);
      const weekOfKey = weeksArray.reverse().find((weekStartDate) => weekStartDate <= date.format('YYYY-MM-DD'));
      if (weekOfKey) {
        const plan = trainingPlanByWeeks[weekOfKey];
        const day = plan.find((day) => moment(day.date).isSame(date, 'day'));
        if (day) activeDayWorkout.setValue(day);
        else history.replace(ROUTER.MILES_TRAINING_PLAN);
      }
    }
  }, []);
  if (isTrainingPlanLoading) {
    return (
      <Wrapper>
        <PulseLoader />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderBlock>
        <Hide if={isDesktop}>
          <div className='row'>
            <img className='logo' alt={'logo'} src={Miles} />
            <IconButton message={'subtle'} mode={'dark'} size={'sm'} onClick={() => history.push(ROUTER.MILES_SETTINGS)}>
              <Settings />
            </IconButton>
          </div>
        </Hide>
        <Show if={hasNotification}>
          <div>
            <Show if={trainingPlanStore.changedParams !== ''}>
              <TrainingMessageAccordion keyName='changedParams' defaultExpanded={true} headingText={'New plans being generated'}>
                <Text size={'md'} weight={'regular'} dangerouslySetInnerHTML={{ __html: trainingPlanStore.changedParams }}></Text>
              </TrainingMessageAccordion>
            </Show>
            <div style={{ margin: '8px 0' }} />
            <Show if={newsMessage.length > 0}>
              <TrainingMessageAccordion
                keyName='newsMsg'
                defaultExpanded={newsMessage !== cachedNews && newsMessage.length > 0}
                headingText={'News'}
              >
                <Text size={'md'} weight={'regular'} dangerouslySetInnerHTML={{ __html: newsMessage }}></Text>
              </TrainingMessageAccordion>
            </Show>
            <div style={{ margin: '8px 0' }} />
            <Show if={trainingPlanStore.trainingMsg !== EMPTY_MILES_MESSAGE && !isPlanChanging}>
              <TrainingMessageAccordion
                keyName='trainingMsg'
                defaultExpanded={trainingPlanStore.collapsableState.trainingMsg}
                headingText={'Training update'}
              >
                <Text size={'md'} weight={'regular'}>
                  {trainingPlanStore.trainingMsg}
                </Text>
              </TrainingMessageAccordion>
            </Show>
            <div style={{ margin: '8px 0' }} />
            <Show if={trainingPlanStore.changeMsg !== EMPTY_MILES_MESSAGE && !isPlanChanging}>
              <TrainingMessageAccordion
                keyName='changeMsg'
                defaultExpanded={trainingPlanStore.collapsableState.changeMsg}
                headingText={'Change'}
              >
                <Text style={{ textAlign: 'left' }} size={'md'} weight={'regular'}>
                  {trainingPlanStore.changeMsg}
                </Text>
              </TrainingMessageAccordion>
            </Show>
            <div style={{ margin: '8px 0' }} />

            <Show if={trainingPlanStore.optimalPlanMsg.length > 0 && !isPlanChanging}>
              <TrainingMessageAccordion
                keyName='optimalPlanMsg'
                defaultExpanded={trainingPlanStore.collapsableState.optimalPlanMsg}
                headingText={'Optimal plan'}
              >
                {trainingPlanStore.optimalPlanMsg.map((text) => (
                  <Text key={text} size={'md'} weight={'regular'}>
                    {text}
                  </Text>
                ))}
              </TrainingMessageAccordion>
            </Show>
            <div style={{ margin: '8px 0' }} />

            <Show if={trainingPlanStore.readinessMsg !== EMPTY_MILES_MESSAGE && !isPlanChanging}>
              <TrainingMessageAccordion
                keyName='readinessMsg'
                defaultExpanded={trainingPlanStore.collapsableState.readinessMsg}
                headingText={'Readiness'}
              >
                <Text size={'md'} weight={'regular'}>
                  {trainingPlanStore.readinessMsg}
                </Text>
              </TrainingMessageAccordion>
            </Show>
          </div>
        </Show>

        <Hide if={isDesktop}>
          <TextWithColor size='sm' weight='regular'>
            Last update: {trainingPlanStore.latestUpdate}
          </TextWithColor>
          <Text size='sm' weight='regular'>
            If you have done something to trigger a training plan update, wait a little bit and refresh the page.
          </Text>

          <ToolBar />
        </Hide>
        <Sessions />
      </HeaderBlock>
      {take(weeksArray, 2).map((week, index) => {
        const weekPlan = trainingPlanByWeeks[week];
        activeDayWorkout.value;
        return (
          <WeekPlanBlock
            key={week}
            label={index === 0 ? 'This week' : 'Next week'}
            month={index === 0 ? moment(weekPlan[0].date).format('MMMM') : undefined}
            trainingPlan={weekPlan}
          />
        );
      })}
      {!trainingPlanStore.isTrainingPlanExpired && <FutureWeeksBlock />}
      {trainingPlanStore.isTrainingPlanExpired && (
        <BrandButton
          onClick={() =>
            history.push(ROUTER.MILES_SETTINGS + '?step=finalise-plan&highlight=runningDataEndDate&highlight=runningDataStartDate')
          }
          size='md'
          message='main'
        >
          Start new plan
        </BrandButton>
      )}
    </Wrapper>
  );
});
