import { CreateService } from './create';
import { DistanceService } from './distance';
import { SegmentService } from './segment';
import { UpdateService } from './update';

const segmentService = new SegmentService();
const distanceService = new DistanceService();
const createService = new CreateService();
const updateService = new UpdateService();

export { segmentService, distanceService, createService, updateService };
