import { Observer } from 'mobx-react';
import { isMiles } from 'modules/header/selectors';
import { headerStore } from 'modules/header/stores';
import React, { FC } from 'react';

import { Hide, Show } from 'src/components/condition';
import { HEADER_FORM } from 'src/constants';
import { form, snackStore } from 'src/stores';
import { Svg } from 'src/styledComponents/Icons';

import { t } from 'utils';

import { Logo } from '../Logo';
import { SearchInput } from '../SearchInput';
import { HeaderWrap, StyledSvg } from './styled';

type Props = {
  onClickBurger: AnyFunction;
  onClickSearch: AnyFunction;
  mobileSearch?: boolean;
  onFocusSearch?: AnyFunction;
  handleClear?: AnyFunction;
  hide?: boolean;
};

export const MobileHeader: FC<Props> = ({ onClickBurger, onClickSearch, mobileSearch, onFocusSearch, handleClear, hide }) => {
  return (
    <Observer>
      {() => {
        const isSnackShown = Boolean(!snackStore.isAllSnacksHidden && snackStore.values.length);
        const raceName = form.fetch(HEADER_FORM, 'raceName') || '';
        const location = form.fetch(HEADER_FORM, 'location') || '';
        const inputValue = !raceName && !location ? '' : `${raceName} ${location}`;
        const variant = headerStore.value?.variant;

        return (
          <HeaderWrap isSnackShown={isSnackShown} hide={hide} variant={variant}>
            <Logo />
            <Show if={!mobileSearch}>
              <div className='right-group'>
                <Hide if={isMiles.get()}>
                  <Svg onClick={onClickSearch} name='Search' size={16} />
                </Hide>
                <StyledSvg onClick={onClickBurger} name='BurgerMenu' size={24} isMiles={isMiles.get()} />
              </div>
            </Show>
            <Show if={Boolean(mobileSearch)}>
              <Observer>
                {() => (
                  <SearchInput
                    placeholder={t.staticAsString('header.searchMobile')}
                    value={inputValue}
                    handleFocus={onFocusSearch}
                    handleClear={handleClear}
                  />
                )}
              </Observer>
            </Show>
          </HeaderWrap>
        );
      }}
    </Observer>
  );
};
