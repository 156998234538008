import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { TagLine } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

import { CELL_SIZES } from '../../constants';

import { getCellSizes, isLastColumn } from '../../utils';

import { RightSideColumnsType } from '../../types';

const Wrapper = styled.div<{ sizes: string }>`
  display: grid;
  justify-content: flex-end;
  grid-template-columns: ${(props) => props.sizes};
  gap: 32px;
  padding: 8px 40px 16px 32px;
  height: 38px;
  width: 100%;
  background-color: ${(props) => props.theme.main.colors.clay6};
`;

const StyledTagLine = styled(TagLine)<{ minWidth?: string; alignRight?: boolean }>`
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  min-width: ${(props) => props.minWidth};
`;

type Props = {
  columnsActive: RightSideColumnsType;
  isAltitude?: boolean;
};

type CellProps = {
  tKey?: TranslationLockedKeys;
  name: string;
  minWidth?: string;
  columnsActive: RightSideColumnsType;
};

const Cell: React.FC<CellProps> = (props) => {
  const { tKey = '', name, columnsActive, minWidth } = props;
  const condition = columnsActive[name];

  if (!condition) return null;

  const alignRight = isLastColumn(name, columnsActive);

  return (
    <StyledTagLine minWidth={minWidth} alignRight={alignRight}>
      <Show if={Boolean(tKey)}>{t(tKey as TranslationLockedKeys)}</Show>
    </StyledTagLine>
  );
};

export const RightSideHeader: React.FC<Props> = (props) => {
  const { columnsActive, isAltitude } = props;
  const sizes = getCellSizes(columnsActive);
  const distanceKey = isAltitude ? 'results.m' : 'results.km';

  return (
    <Wrapper sizes={sizes}>
      <Cell name='club' tKey='results.union' minWidth={CELL_SIZES[0].minWidth} columnsActive={columnsActive} />
      <Cell name='squad' tKey='results.team' minWidth={CELL_SIZES[1].minWidth} columnsActive={columnsActive} />
      <Cell name='class' tKey='registration.class' minWidth={CELL_SIZES[2].minWidth} columnsActive={columnsActive} />
      <Cell name='wave' tKey='registration.waveHeader' minWidth={CELL_SIZES[3].minWidth} columnsActive={columnsActive} />
      <Cell name='time' tKey='results.time' columnsActive={columnsActive} />
      <Cell name='diff' tKey='results.diff' columnsActive={columnsActive} />
      <Cell name='goalDistance' tKey={distanceKey} columnsActive={columnsActive} />
      <Cell name='goalAltitude' tKey='results.distance_logged_altitude' columnsActive={columnsActive} />
      <Cell name='tracker' tKey='results.activity_tracker' columnsActive={columnsActive} />
      <Cell name='checkpoint' columnsActive={columnsActive} />
    </Wrapper>
  );
};
