export type SmartLink = {
  id: number;
  name: string;
  token: string;
  max_usages: number;
  used_times: number;
  date_from: string;
  date_to: string;
  discount?: number;
  discount_type: number;
  discount_sum: number;
  discount_sum_type?: number;
  race_id: number;
  distance_id: number;
  wave_id?: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
};

export enum SmartLinkCouponUsageType {
  DistanceOnly = 1,
  CustomFieldsOnly = 2,
  DistanceAndCustomFields = 3,
}

export enum SmarLinkCouponType {
  DiscountAmontType = 1,
  DiscountPercentageType = 2,
}
