import { parseUrlParams } from '../utils';

import { segmentsService } from '../services';

async function reloadDistance() {
  const { distanceId } = parseUrlParams();

  if (!distanceId) {
    return;
  }

  await segmentsService.getDistance(distanceId);
}

export { reloadDistance };
