import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_SEGMENT } from 'src/constants';

import { request, action, toastService } from 'utils';

import { FORM_ID } from '../constants';

import { BeSegment, ResponseType } from '../types';

class UpdateService {
  @request({ action: FORM_ID })
  request(racerId: number, id: number, body: BeSegment): Promise<any> {
    const url = generatePath(API_URL + API_SEGMENT, { racerId, id });
    return axios.patch(url, body);
  }

  @action({ action: shortid() })
  async ignite(racer: number, segment: number, value: BeSegment): Promise<[boolean, ResponseType]> {
    const [status, response] = await this.request(racer, segment, value);
    if (!status) {
      this.handleError(response);
    }

    if (response.status === 409) {
      return [status, 'conflict'];
    }

    return [status, status ? 'success' : 'error'];
  }

  handleError(response: any) {
    if (response.status === 409) {
      const message = response.data.message;

      if (!message) {
        return;
      }

      toastService.error(message);
    }
  }
}

export { UpdateService };
