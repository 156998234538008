import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const StressPath = (
  <path
    id='Subtract'
    fillRule='evenodd'
    clipRule='evenodd'
    d='M23.5 12.5C23.5 18.5751 18.5751 23.5 12.5 23.5C6.42487 23.5 1.5 18.5751 1.5 12.5C1.5 6.42487 6.42487 1.5 12.5 1.5C18.5751 1.5 23.5 6.42487 23.5 12.5ZM10.2809 12.3753L7.08087 8.37531L5.51913 9.62469L7.41938 12H4.5V14H9.5H11.5806L10.2809 12.3753ZM17.9191 8.37531L14.7191 12.3753L13.4194 14H15.5H20.5V12H17.5806L19.4809 9.62469L17.9191 8.37531ZM8.5 20C8.5 17.7909 10.2909 16 12.5 16C14.7091 16 16.5 17.7909 16.5 20H8.5Z'
    fill='currentColor'
  />
);

export const Stress = (props: IconProps) => <SvgIcon path={StressPath} {...props} />;
