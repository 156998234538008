import { matchPath } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';

import { encodeQuery } from 'utils/encodeQuery';
import { history } from 'utils/history';

function parseUrlParams(): {
  distanceId: number | null;
  raceId: number;
  inviteToken: string | null;
  requestToken: string | null;
  registrationComplete: string | null;
  editRacerId: number | null;
  smartLinkToken: string | null;
} {
  const { search } = history.location;

  const params = encodeQuery.parseQuery(search);

  const match = matchPath(history.location.pathname, { path: ROUTER_WITH_LOCALES.RACE_REGISTRATION, exact: false });

  return {
    distanceId: params?.distance || null,
    raceId: match?.params.id,
    inviteToken: params?.invite_token || null,
    requestToken: params?.request_token || null,
    registrationComplete: params?.registration_complete || null,
    editRacerId: params?.edit_registration || null,
    smartLinkToken: params['magic-link'] || null,
  };
}

export { parseUrlParams };
