import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { RegistrationFieldsStore } from '../../stores';

function registrationFieldsConditions(fieldsStore: RegistrationFieldsStore) {
  function generateIsFieldSelected(distanceId: number, name: registrationFields): IComputedValue<boolean> {
    return computed(() => {
      const field = fieldsStore.find(distanceId, name);

      return !!field?.selected;
    });
  }

  function generateIsAnyDistanceFieldSelected(name: registrationFields): IComputedValue<boolean> {
    return computed(() => {
      const fields = fieldsStore.groupedByName[name] || [];
      return fields.some((field) => field.selected);
    });
  }

  return { generateIsFieldSelected, generateIsAnyDistanceFieldSelected };
}

export { registrationFieldsConditions };
