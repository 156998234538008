import axios from 'axios';

import { LOAD_RACES, LOAD_MORE_RACES, API_RACES_URL, API_URL, API_V2_URL, PAST_RACES_FILTER_LOAD_VERSION } from 'src/constants';

import { request, action, versionControl } from 'utils';

import { progressStore, sportTypesStore } from 'stores';
import { PastRace } from 'stores';

const RACES_URL = `${API_V2_URL}${API_RACES_URL}`;

class PastRaces {
  store: PastRace;

  constructor(store: PastRace) {
    this.store = store;
  }

  @action({ action: LOAD_RACES, minRequestTime: 200 })
  async loadWithFilters(params: FiltersType = {}) {
    // To reject parallel requests, and accept only the last
    // Because debounce IS NOT ENOUGH
    const requestVersion = versionControl.incrementVersion(PAST_RACES_FILTER_LOAD_VERSION);
    const interuptCallback = () => {
      return requestVersion !== versionControl.currentVersion(PAST_RACES_FILTER_LOAD_VERSION);
    };

    const filters = { ...params, page: 1 };

    const generatedParams = this.store.generateFiltersForSearch(filters);

    const requestParams = {
      ...generatedParams,
    };

    await this.loadResources(requestParams, this.store.addValues, interuptCallback);
  }

  @action({ action: LOAD_MORE_RACES, minRequestTime: 200 })
  async loadMore(params: FiltersType = {}) {
    if (!this.store.isLoadNecessary) {
      return;
    }

    const interuptCallback = () => {
      return !!progressStore.isLoading(LOAD_RACES);
    };

    const filters = {
      ...params,
      page: this.store.nextPage,
    };

    const generatedParams = this.store.generateFiltersForLoadMore(filters);

    const requestParams = {
      ...generatedParams,
    };

    await this.loadResources(requestParams, this.store.appendValues, interuptCallback);
  }

  async loadResources(params: FiltersType, addFunction: Function, interuptCallback: Function = () => false) {
    const [status, response] = await this.loadResourcesRequest(params);

    if (status && !interuptCallback()) {
      const { data, meta } = response.data;
      const currentPage = meta.pagination.current_page;

      const races = this.attachSportTypes(data);

      addFunction.bind(this.store)(races, currentPage, {});
      this.store.addPaginationMeta(meta.pagination);
    }
  }

  @request({ action: LOAD_RACES })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return axios.get(RACES_URL, {
      params,
    });
  }

  attachSportTypes(data: any[]): any[] {
    return data.map((race) => ({
      ...race,
      sport: sportTypesStore.findById(race.sport?.id),
    }));
  }
}

export { PastRaces };
