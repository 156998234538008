import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_DISTANCE_LAST_FINISHED_CHECKPOINT } from 'src/constants';

import { action, request } from 'utils';

class LastFinishedCheckpointService {
  @request({ action: shortid() })
  request(distanceId: number): any {
    const url = generatePath(API_URL + API_DISTANCE_LAST_FINISHED_CHECKPOINT, { id: distanceId });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async load(distanceId: number): Promise<CheckpointType | null> {
    const [isOK, response] = await this.request(distanceId);

    if (!isOK) {
      return null;
    }

    return response.data.data || null;
  }
}

export { LastFinishedCheckpointService };
