import * as React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';
import { seo } from 'src/constants';
import { useFooterSettings } from 'src/modules/footer';
import { iFrameDetect } from 'src/utils';

import SEO from 'components/SEO';

import {
  SignIn as SignInContainer,
  SignUp as SignUpContainer,
  ForgotPassword as ForgotPasswordContainer,
  ResetPassword as ResetPasswordContainer,
  PasswordRecoveryConfirm,
} from '../components';

const AuthContainerComponent = () => {
  useFooterSettings({
    hide: true,
  });

  const handleAuth = () => {
    return (
      <React.Fragment>
        {iFrameDetect().isWithinIFrame ? (
          <Route path={ROUTER_WITH_LOCALES.IFRAME_FORGOT} component={ForgotPasswordContainer} />
        ) : (
          <Switch>
            <Route path={ROUTER_WITH_LOCALES.SIGNIN} component={SignInContainer} />
            <Route path={ROUTER_WITH_LOCALES.SIGNUP} component={SignUpContainer} />
            <Route exact path={ROUTER_WITH_LOCALES.FORGOT} component={ForgotPasswordContainer} />
            <Route path={ROUTER_WITH_LOCALES.RESET} component={ResetPasswordContainer} />
            <Route path={ROUTER_WITH_LOCALES.FORGOT_CONFIRM} component={PasswordRecoveryConfirm} />
          </Switch>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className='container'>
      <SEO {...seo.authContainer} />
      <div className='signup row'>
        <div className='col-xs-12 col-s-10 col-md-8 col-lg-4'>{handleAuth()}</div>
      </div>
    </div>
  );
};

export const AuthContainer = withRouter(AuthContainerComponent);
