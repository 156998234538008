import { matchPath } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';

import { history, encodeQuery } from 'utils';

import { progressStore } from 'stores';

import { PRELOADER } from '../../constants';

import { callbackStore, modeStore, racerIdStore, segmentIdStore } from '../../stores';

import { setupMainData } from '../setupMainData';
import { setupSegmentForm } from '../setupSegmentForm';

export const mountForm = async () => {
  progressStore.log(PRELOADER, 'progress');

  const match = matchPath(history.location.pathname, { path: ROUTER_WITH_LOCALES.SEGMENT_FORM });
  const query = encodeQuery.parseQuery(history.location.search);
  const start = new Date();

  const distanceId = +match?.params?.distanceId;
  const racerId = +match?.params?.racerId;
  const segmentId = +query.segmentId;

  if (!distanceId || !racerId) return;

  modeStore.setValue(segmentId ? 'edit' : 'new');
  racerIdStore.setValue(racerId);

  const emptyFn = () => {};
  callbackStore.setValue(emptyFn as any);

  if (segmentId) {
    segmentIdStore.setValue(segmentId);

    await Promise.all([setupMainData(distanceId), setupSegmentForm()]);
  } else {
    await setupMainData(distanceId);
  }

  progressStore.log(PRELOADER, 'completed', { requestStartTime: start, minRequestTime: 200 });
};
