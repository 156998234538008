import { PropsMobile } from 'modules/tourProfileManagment/components/Header/styled';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Headline } from 'src/styledComponents/Typography';

import { windowSize } from 'stores';

interface Props {
  text: string;
}
const RaceTitle = styled.div<PropsMobile>`
  padding: ${(props) => (props.isMobile ? '44px 0 14px 0' : '51px 0 14px 0')};

  .title {
    position: relative;
    padding-left: 29px;
    ::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 40px;
      left: 0px;
      top: -6px;
      background: #272e34;
    }
  }
`;

export const RaceTabTitle = (props: Props): ReactElement => {
  const isMobile = windowSize.isLessThan('small');

  return (
    <RaceTitle isMobile={isMobile}>
      <Headline className='title' variant='h4'>
        {props.text.toUpperCase()}
      </Headline>
    </RaceTitle>
  );
};
