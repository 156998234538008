import axios from 'axios';
import { get } from 'lodash';
import { generatePath } from 'react-router-dom';

import { API_USER_REGISTERED_DISTANCES, API_V2_URL } from 'src/constants';

import { action, request } from 'utils';

import { sessionStore } from 'stores';

import { REGISTERED_TO_PROFILE } from '../constants';

import { dataMapper } from '../utils';

import { MetaType, RegisteredDistanceType } from '../types';

class RegisteredTo {
  @request({ action: REGISTERED_TO_PROFILE, authOnly: true })
  loadRegistered(id: number, params: any): Promise<any> {
    const url = generatePath(API_V2_URL + API_USER_REGISTERED_DISTANCES, { id });
    return axios.get(url, { params });
  }

  async loadResources(userId: number, params: any): Promise<{ data: Array<DistanceType & { order?: Object }>; meta: MetaType }> {
    const [status, response] = await this.loadRegistered(userId, params);

    return {
      data: status ? response.data.data : [],
      meta: status ? response.data.meta.pagination : {},
    };
  }

  @action({ action: REGISTERED_TO_PROFILE })
  async load(page): Promise<{ data: RegisteredDistanceType[]; meta: MetaType }> {
    const userId = +sessionStore.getUserData().id!;
    const defaultParams = this._defaultParams();
    const params = {
      ...defaultParams,
      from: (page - 1) * defaultParams.limit,
      page,
    };

    const { data, meta } = await this.loadResources(userId, params);

    const mappedData = data.map((distance) => {
      const receipt = get(distance, 'racers[0].order');

      if (!!receipt) {
        distance.order = receipt;
      }

      return dataMapper.distanceToRegisteredDistance(distance);
    });

    return { data: mappedData, meta };
  }

  _defaultParams() {
    return {
      limit: 20,
      orderBy: 'race_date',
      sortedBy: 'desc',
    };
  }
}

const registeredToService = new RegisteredTo();

export { RegisteredTo, registeredToService };
