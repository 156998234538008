import React from 'react';
import AppleLogin from 'react-apple-login';
import styled from 'styled-components';

import { APPLE_CLIENT_ID, RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { Icon } from 'src/styledComponents/Icons';

import { FacebookButton } from 'components/facebookBtn';
import { GoogleButton } from 'components/googleBtn';

import { t } from 'utils';

const SocialButtonWrapper = styled(Button)`
  background-color: ${(props) => props.theme.main.colors.white};
  margin-bottom: 18px;
  align-items: start;
  padding: 26px 26px 26px 70px;
  span {
    font-size: 18px;
  }

  &:hover {
    .apple-icon {
      filter: invert(1);
    }
  }

  @media (max-width: ${RESOLUTIONS.small}px) {
    &:hover {
      background-color: ${(props) => props.theme.main.colors.white};

      span {
        color: ${(props) => props.theme.main.colors.clay1};
      }
      .apple-icon {
        filter: none;
      }
    }
    padding: 26px 26px 26px 35px;
    span {
      font-size: 18px;
    }
  }
`;

const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 16px;
  }
`;

type SocialButtonsType = {
  onFacebookClick: (token: string) => void;
  onGoogleClick: (token: string) => void;
  onAppleClick: AnyFunction;
};
declare const AppleID: any;
const SocialButtons = (props: SocialButtonsType) => {
  const { onFacebookClick, onGoogleClick, onAppleClick } = props;

  return (
    <div style={{ paddingTop: 38 }}>
      <FacebookButton type='signUp' handleClick={onFacebookClick}>
        <SocialButtonWrapper className='social-btn' size='large' variant='darkLine' ATN='facebook' fullWidth>
          <TextWithIcon>
            <Icon name='Facebook' size={24} />
            <span>
              {t('login.continueWith')}
              &nbsp;Facebook
            </span>
          </TextWithIcon>
        </SocialButtonWrapper>
      </FacebookButton>

      <GoogleButton type='signUp' handleClick={onGoogleClick}>
        <SocialButtonWrapper className='social-btn' size='large' variant='darkLine' ATN='google' fullWidth>
          <TextWithIcon>
            <Icon name='Google' size={24} />
            <span>
              {t('login.continueWith')}
              &nbsp;Google
            </span>
          </TextWithIcon>
        </SocialButtonWrapper>
      </GoogleButton>

      <AppleLogin
        clientId={APPLE_CLIENT_ID}
        redirectURI={`${window.location.protocol}//${window.location.hostname}`}
        usePopup
        scope={'name email'}
        responseMode={'form_post'}
        responseType={'code'}
        callback={onAppleClick}
        render={(renderProps) => (
          <SocialButtonWrapper className='social-btn' size='large' variant='darkLine' onClick={renderProps.onClick} fullWidth ATN='apple'>
            <TextWithIcon>
              <Icon className='apple-icon' name='Apple' size={24} />
              <span>
                {t('login.continueWith')}
                &nbsp;Apple
              </span>
            </TextWithIcon>
          </SocialButtonWrapper>
        )}
      />
    </div>
  );
};

export default SocialButtons;
