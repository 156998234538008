import { omit } from 'lodash';

import { AdditionalRegistrationFields } from '../../constants';

import { CouponsStore } from '../../stores';

import { Data } from '../../types';

/**
 * @description
 * Filter form racer field coupon_code if there are no valid coupon loaded
 */
function filterFormRacerNonExistingCoupon(couponsStore: CouponsStore, index: number) {
  // TODO, replace with ../*/utils.fieldIdUtils.groupRegistrationsCoupon
  const couponCodeFieldId = `groupRegistrations.${index}.coupon_code`;
  return (data: Data.Form.Racer): Data.Form.Racer => {
    if (!(couponCodeFieldId in couponsStore.value)) {
      return omit(data, [AdditionalRegistrationFields.coupon_code]);
    }
    if (Boolean(couponsStore.invalidValues[couponCodeFieldId])) {
      return omit(data, [AdditionalRegistrationFields.coupon_code]);
    }
    return data;
  };
}

export { filterFormRacerNonExistingCoupon };
