import * as React from 'react';
import styled from 'styled-components';

import { TextField } from 'src/styledComponents/TextField';

import { InputWrapper } from 'components/form';

import { t } from 'utils';

import { windowSize } from 'stores';

const Wrapper = styled.div<{ isMobile: boolean }>`
  margin: 24px 0 0 0;
  width: ${(props) => (props.isMobile ? '100%' : '436px')};
`;

const Component = () => {
  const isMobile = windowSize.isLessThan('small');

  return (
    <Wrapper className='field-wrapper' isMobile={isMobile}>
      <InputWrapper
        name='email'
        settings={{
          placeholder: t.staticAsString('transferRegistration.form.emailPlaceHolder'),
          label: t.staticAsString('login.email'),
          required: true,
          requiredMark: true,
        }}
        Component={TextField}
      />
    </Wrapper>
  );
};

export const EmailField = React.memo<{}>(Component);
