import { inject, observer } from 'mobx-react';
import { ButtonWrapper, Controls, FormField } from 'modules/auth';
import AuthPageTemplate from 'modules/auth/components/AuthPageTemplate';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { RESET_PASSWORD } from 'src/constants';
import { TextField } from 'src/styledComponents/TextField';

import { withErrorClean } from 'hocs';

import { t, toastService, validation, Errors, encodeQuery, history, fireToast } from 'utils';

import { AuthService } from 'services';

import { sessionStore } from 'stores';

import { resetService } from '../services';

const PasswordTip = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.main.colors.clay3};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
`;

const resetPassword = {
  password: {
    presence: true,
    length: {
      minimum: 6,
      tooShort: () => t.staticAsString('validation.shortPass'),
    },
  },
  password_confirmation: {
    equality: {
      attribute: 'password',
      message: () => t.staticAsString('login.password_confirm_not_eql_pass'),
    },
  },
};

const StyledTemplate = styled(AuthPageTemplate)`
  .loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

@withRouter
@withErrorClean(RESET_PASSWORD)
@inject('errorsStore')
@observer
export class ResetPassword extends React.Component<ResetPasswordProps, ResetState> {
  action: string;
  service: AuthService;
  errorsHandler: Errors;

  static defaultProps = {
    errorsStore: null as any,
    location: null as any,
  };

  state = {
    token: '',
    password: '',
    password_confirmation: '',
  };

  constructor(props: ResetPasswordProps) {
    super(props);
    this.action = RESET_PASSWORD;
    this.errorsHandler = new Errors(RESET_PASSWORD);
    this.service = new AuthService();
  }

  componentDidMount() {
    const { location } = this.props;
    const encodeToken = encodeQuery.parseQuery(location.search);
    this.setState({
      ...this.state,
      ...encodeToken,
    });

    sessionStore.logout();

    resetService.resetPasswordCheck();
  }

  onSubmit = async (): Promise<any> => {
    if (validation(this.action, this.state, resetPassword)) {
      const onSuccess = await this.service.resetPassword(this.state);
      const { location } = history;
      const query = encodeQuery.parseQuery(location.search);

      const redirectLocation = query.redirect_location;

      if (onSuccess) {
        history.push(redirectLocation);
        fireToast(t.staticAsString('auth.newPasswordSaved'), 'success', t.staticAsString('auth.passwordSaved'));
      }
    }
  };

  onChange = (arg0, e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  hasError = (name: string): string => {
    return this.errorsHandler.hasError(name);
  };

  render(): React.ReactNode {
    const { password, password_confirmation } = this.state;

    return (
      <StyledTemplate title={t.staticAsString('passwordReset.title')} testAnchorContainer='ChangePassword'>
        <FormField>
          <TextField
            name='password'
            type='password'
            value={password}
            label={t.staticAsString('login.newPassword')}
            requiredMark
            errorMessage={this.hasError('password')}
            placeholder={t.staticAsString('login.newPassword')}
            onChange={this.onChange}
          />
          {password?.length < 6 && <PasswordTip>{'Minimum of 6 characters'}</PasswordTip>}
        </FormField>
        <FormField>
          <TextField
            name='password_confirmation'
            type='password'
            value={password_confirmation}
            label={t.staticAsString('login.confirmPassword')}
            requiredMark
            errorMessage={this.hasError('password_confirmation')}
            placeholder={t.staticAsString('login.confirmPassword')}
            onChange={this.onChange}
          />
        </FormField>
        <Controls>
          <ButtonWrapper size='large' variant='primary' onClick={this.onSubmit} fullWidth>
            {t('passwordRecovery.saveButton')}
          </ButtonWrapper>
        </Controls>
      </StyledTemplate>
    );
  }
}
