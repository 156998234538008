import { SocialIcon } from 'modules/miles/shared/components/Footer/SocialIcon';
import { Facebook, Instagram, LinkedIn } from 'modules/miles/shared/components/Icon';
import React from 'react';
import styled from 'styled-components';

import { SOCIAL_LINKS } from 'src/constants';

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const SocialLinks: React.FC<Props> = (props) => {
  return (
    <Wrapper {...props}>
      <SocialIcon link={SOCIAL_LINKS.linkedin}>
        <LinkedIn />
      </SocialIcon>
      <SocialIcon link={SOCIAL_LINKS.instagram}>
        <Instagram />
      </SocialIcon>
      <SocialIcon link={SOCIAL_LINKS.facebook}>
        <Facebook />
      </SocialIcon>
    </Wrapper>
  );
};
