import { progressStore, sessionStore } from 'stores';

import { INITIALIZE_XTRI } from '../../constants';

import { distancesService } from '../../services';

import { distancesStore } from '../../stores';

export const mountXtri = () => {
  progressStore.log(INITIALIZE_XTRI, 'progress');

  (async () => {
    const tickets = sessionStore.session.user.xtriTickets || [];
    const IDList = tickets.reduce((result: any[], current: AnyObject) => {
      if (current.distance_id) {
        return [...result, current.distance_id];
      }

      return result;
    }, []);

    await distancesService.loadDistances(IDList);

    progressStore.log(INITIALIZE_XTRI, 'completed');
  })();
};

export const unmountXtri = () => {
  distancesStore.clear();
};
