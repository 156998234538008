import classNames from 'classnames';
import * as React from 'react';

import { ErrorLabel } from '../errorLabel';

const CheckboxComponent = (props: BaseInputProps) => {
  const { label, name, value, disabled, readOnly, onChange, id, errorMessage } = props;

  const hasError = Boolean(errorMessage);
  return (
    <div className={classNames('checkbox-wrap', { disabled: disabled })} title={label}>
      <input
        type='checkbox'
        id={id}
        name={name}
        checked={Boolean(value)}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target;
          return onChange({ name, value: checked });
        }}
        className='checkbox-input'
      />
      <label htmlFor={id} className='checkbox-label'>
        {label}
      </label>
      {hasError && <ErrorLabel name={name} errorMessage={errorMessage} />}
    </div>
  );
};

export { CheckboxComponent, CheckboxComponent as Checkbox };
