import { InternalFilters } from '../types';

function racers(value: InternalFilters) {
  let filters: AnyObject = {};

  if (value.gender) {
    filters['gender'] = value.gender;
  }

  if (value.name) {
    filters['full_name'] = value.name;
  }

  return filters;
}

function single(value: InternalFilters) {
  let filters: AnyObject = {};

  if (value.gender) {
    filters['gender'] = value.gender;
  }

  if (value.name) {
    filters['q'] = value.name;
  }

  if (value.class_id) {
    filters['classes'] = value.class_id;
  }

  if (value.wave_id) {
    filters['waves'] = value.wave_id;
  }

  return filters;
}

function teams(value: InternalFilters) {
  let filters: AnyObject = {};

  if (value.name) {
    filters['q'] = value.name;
  }

  if (value.class_id) {
    filters['classes'] = value.class_id;
  }

  if (value.wave_id) {
    filters['waves'] = value.wave_id;
  }

  if (value.gender) {
    filters['gender'] = value.gender;
  }

  return filters;
}

const internalBe = {
  racers,
  teams,
  single,
};

export { internalBe };
