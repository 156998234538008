import * as React from 'react';

import { DarkLineButton } from './DarkLine';
import { PrimaryButton } from './Primary';
import { SecondaryButton } from './Secondary';
import { WhiteLine } from './WhiteLine';
import { BaseButtonProps } from './types';

type Props = BaseButtonProps & {
  variant: keyof typeof Variants;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  OptionalTestAnchorProps;

enum Variants {
  primary = 'primary',
  secondary = 'secondary',
  darkLine = 'darkLine',
  whiteLine = 'whiteLine',
}

export const Button: React.FC<Props> = (props) => {
  const { variant, ...rest } = props;

  switch (variant) {
    case Variants.primary:
      return <PrimaryButton {...rest} />;

    case Variants.secondary:
      return <SecondaryButton {...rest} />;

    case Variants.darkLine:
      return <DarkLineButton {...rest} />;

    case Variants.whiteLine:
      return <WhiteLine {...rest} />;

    default:
      return null;
  }
};
