import { observable, action, makeObservable } from 'mobx';

class Countdown {
  @observable timerObject: AnyObject = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setTime(timerId: string | number, time: number) {
    this.timerObject[timerId] = time;
  }

  @action
  clear(timerId: string | number) {
    this.timerObject[timerId] = undefined;
  }
}

export { Countdown };
export default new Countdown();
