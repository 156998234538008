import { Observer } from 'mobx-react';
import * as React from 'react';

import { RightSideHeader } from 'src/modules/racersTable/components';

import {
  isAnyDistanceHasCheckpoints,
  isAnyDistanceHasClasses,
  isAnyDistanceHasSquads,
  isAnyDistanceHasUnion,
  isAnyDistanceHasWaves,
  isAnyDistanceVirtual,
} from '../../derivations';

export const RightHeader = () => {
  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isAnyDistanceHasWaves.get(),
          class: isAnyDistanceHasClasses.get(),
          club: isAnyDistanceHasUnion.get(),
          squad: isAnyDistanceHasSquads.get(),
          time: true,
          diff: true,
          tracker: isAnyDistanceVirtual.get(),
          checkpoint: isAnyDistanceHasCheckpoints.get(),
        };

        return <RightSideHeader columnsActive={columnsActive} />;
      }}
    </Observer>
  );
};
