import { get } from 'lodash';

import { getTimeResult as getTimeResultWithoutDns, getTimeResultWithDns } from 'utils';

import type { VirtualDistance } from '../types';

// @Note this function is using in My Results & segments module
export const getTimeResult = (distance: VirtualDistance = {} as any, dns: boolean = false, withMillis: boolean = false) => {
  let result;

  if (distance.type === 'team') {
    result = get(distance, ['teams', '0', 'result']);
  } else {
    result = get(distance, ['racers', '0', 'result']);
  }

  const formatFun = dns ? getTimeResultWithDns : getTimeResultWithoutDns;
  return formatFun(result?.start_time, result?.finish_time, withMillis);
};
