import Xregexp from 'xregexp';

import { fieldIdStatic } from './general';
import { AdditionalRegistrationFields, TeamFields } from './general';

/**
 * @description
 * match backend error keys
 */
export const REGISTRATION_BE_ERROR_KEY_PATTERNS = {
  /**
   * @description
   * Errors that are related to registration fields per group registration member
   * match group registration index and field name
   */
  groupRegistration: (withAnchors: boolean = true) => {
    let pattern = 'registrations\\.(?<groupRegistrantIndex>\\d+)\\.member.(?<fieldName>\\w+)';

    if (withAnchors) {
      pattern = `^${pattern}$`;
    }

    return Xregexp(pattern);
  },

  /**
   * @description
   * Additional regitration fields, fields that are not included in the registration fields
   * Fields that are added outside of registration_fields_values but in the group registration
   */
  groupRegistrationAdditionalFields: (withAnchors: boolean = true) => {
    let pattern =
      `registrations\\.(?<groupRegistrantIndex>\\d+)\\.(?<fieldName>` +
      `${AdditionalRegistrationFields.class_id}|` +
      `${AdditionalRegistrationFields.wave_id}|` +
      `${AdditionalRegistrationFields.discipline_id}` +
      ')';

    if (withAnchors) {
      pattern = `^${pattern}$`;
    }

    return Xregexp(pattern);
  },

  /**
   * @description
   * Errors that are related to custom fields for group registrations
   * match group registration index and custom field index
   */
  groupRegistrationCustomFields: (withAnchors: boolean = true) => {
    let pattern = `registrations\\.(?<groupRegistrantIndex>\\d+)\\.member\\.custom\\.(?<customFieldIndex>\\d+)`;

    if (withAnchors) {
      pattern = `^${pattern}$`;
    }

    return Xregexp(pattern);
  },

  /**
   * @description
   * Errors that are related to registration fields per team registration member
   * match group registration index and custom field index
   */
  teamMember: (withAnchors: boolean = true) => {
    let pattern = 'registrations\\.0\\.team.members\\.(?<teamMemberIndex>\\d+)\\.(?<fieldName>\\w+)';

    if (withAnchors) {
      pattern = `^${pattern}$`;
    }

    return Xregexp(pattern);
  },

  /**
   * @description
   * Errors that are related to custom fields for team members
   * match team member index and custom field index
   */
  teamMemberCustomFields: (withAnchors: boolean = true) => {
    let pattern = `registrations\\.0\\.team.members\\.(?<teamMemberIndex>\\d+)\\.custom\\.(?<customFieldIndex>\\d+)`;

    if (withAnchors) {
      pattern = `^${pattern}$`;
    }

    return Xregexp(pattern);
  },

  /**
   * @description
   * Matchers that do not relate to any of the fields in the form and should be displated in the toast
   */
  toastMatchers: () => [/^date$/, /^is_visible$/],

  /**
   * @description
   * Errors that'l to catch errors for team registration
   * related to the whole team and not to the registration fields
   * team_name, class_id, wave_id
   */
  teamRegistrationErrors: (withAnchors: boolean = true) => {
    let pattern =
      `registrations\\.0\\.team.(?<teamRegitrationField>` +
      `${AdditionalRegistrationFields.class_id}|` +
      `${AdditionalRegistrationFields.wave_id}|` +
      `${TeamFields.team_name}|` +
      ')';

    if (withAnchors) {
      pattern = `^${pattern}$`;
    }

    return Xregexp(pattern);
  },
};

/**
 * @description
 * match field id string by patterns
 */
export const FIELD_ID_PATTERNS = {
  /**
   * @description
   * match group registration index and custom field id
   */
  groupRegistrationCustomFields: Xregexp(
    `^${fieldIdStatic.groupRegistrations}\\.(?<racerIndex>\\d+)\\.${fieldIdStatic.customFieldsKey}\\.${fieldIdStatic.customFieldValueIdPrefix}(?<id>\\d+)$`,
  ),

  /**
   * @description
   * match custom field id
   */
  customField: Xregexp(`^${fieldIdStatic.customFieldValueIdPrefix}(?<id>\\d+)$`),
};
