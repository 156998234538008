import * as React from 'react';

import { formatDate } from 'src/components/FormattedDate';
import { commonDateFormats } from 'src/utils';

import { parsedDate } from '../types/index';

/** Format moment in observable jsx */
function observableFormatDate(date: parsedDate, format: CommonDateFormats): React.ReactNode {
  const formatGenerator = commonDateFormats[format] || commonDateFormats.date;
  return formatDate(date, formatGenerator);
}

/** Format moment in plain string */
function stringFormatDate(date: parsedDate, format: CommonDateFormats): string {
  const generatedFormat = (commonDateFormats[format] || commonDateFormats.date)();
  return date.format(generatedFormat);
}

export { observableFormatDate, stringFormatDate };
