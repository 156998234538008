import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { AvatarUpload } from 'modules/Shared/AvatarAccountUpload';
import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { findCountryCode } from 'src/modules/results/utils';
import { Utility1 } from 'src/stories/Core/Typorgaphy/Utilities.stories';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { HardElevation } from 'src/styledComponents/Elevation';
import { HorizontalScroll } from 'src/styledComponents/HorizontalScroll';
import { Svg } from 'src/styledComponents/Icons';
import { Skeleton } from 'src/styledComponents/Skeleton';
import { Headline, Utility } from 'src/styledComponents/Typography';

import { Truncate } from 'styles';

import SEO from 'components/SEO';

import { t } from 'utils';

import { progressStore, sessionStore, windowSize } from 'stores';
import countryStore from 'stores/countries';

import { INITIALIZE_PROFILE } from '../constants';

type Props = {
  onChangeImage: (event: ChangeEventHandler<HTMLInputElement> | any) => void;
  handleRedirect: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const UserInfo = styled(HardElevation)<{ isFirstChild?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  //justify-content: center;
  margin: 0 0 auto;
  padding: 0 20px 40px 20px;
  border-radius: 1px;

  min-height: 434px;
  justify-content: start;

  .user-header {
    h1 {
      margin-bottom: 0;
    }

    h3 {
      margin: 0;
      text-transform: uppercase;
      padding: 0 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  h1 {
    margin: 0;
    align-self: center;
    text-transform: uppercase;
  }

  .location-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;

    .location-info-item {
      display: flex;
      align-items: center;
      ${Truncate}

      svg path {
        stroke: ${(props) => props.theme.main.colors.clay1};
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;

        margin-top: 8px;
        color: ${(props) => props.theme.main.colors.clay3};
      }

      .profile-city {
        margin-right: 16px;
      }

      & > div {
        margin: 8px 4px 0 0;
        min-width: 24px;
      }

      .flag-icon {
        margin-right: 8px;
        border-radius: 2px;
        width: 24px;
        height: 18px;
      }
    }
  }

  .interests {
    margin: 40px 0 20px;
    max-width: calc(100% + 20px);

    .scroll-block {
      margin-bottom: -24px;
    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      padding: 0;
      margin: 16px 0;
      overflow-x: auto;

      li {
        padding: 12px;
        background: ${(props) => props.theme.main.colors.white};
        margin: 0 16px 24px 0;
        flex: 0 0 auto;

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .clubs {
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.isFirstChild && '48px 0 8px'};

    span {
      color: ${(props) => props.theme.main.colors.clay3};
    }
    span:not(:first-child) {
      padding-top: 8px;
    }
    span:nth-child(2) {
      padding-top: 16px;
    }

    h1 {
      align-self: start;
    }
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    background: ${(props) => props.theme.main.colors.white};
    padding: 40px 32px;

    .user-header {
      align-self: center;
    }
    .user-name {
      text-align: center;
    }

    h1 {
      text-transform: none;
      margin: 24px 0 8px;
    }

    .location-info span {
      color: ${(props) => props.theme.main.colors.clay2};
    }

    .interests {
      max-width: 100%;
      margin: 48px 0 8px;

      ul {
        flex-wrap: wrap;
        margin: 16px 0;

        li {
          background: ${(props) => props.theme.main.colors.clay6};
        }

        li:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .clubs span {
      color: ${(props) => props.theme.main.colors.clay1};
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .user-header {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 16px;
      h3 {
        align-self: center;
      }
      .settings {
        margin-left: auto;
        min-width: 24px;
      }
    }
  }
`;

const SkeletonWrapper = styled(UserInfo)`
  .center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .flex-row {
    display: flex;
  }
`;

const EmptySpace = styled.div<{ width?: string; height?: string }>`
  min-height: ${(props) => props.height ?? '100%'};
  min-width: ${(props) => props.width ?? '100%'};
`;

export const ProfileInfo: FC<Props> = ({ handleRedirect, onChangeImage }) => {
  const fullname = (user: AnyObject): React.ReactNode => {
    return [user.firstname, user.lastname].join(' ').trim();
  };

  const getCountryId = (user: AnyObject): number | null => {
    if (user.nationality_id) {
      return user.nationality_id;
    }

    if (user.country_id) {
      return user.country_id;
    }

    return null;
  };

  const renderXtriPoints = () => {
    const points = sessionStore.userModel?.value.xtri_x_points_balance;
    if (points === null) return null;
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Utility1 variant='u1' weight='bold'>
          <b>MY X-POINTS:</b> {points}
        </Utility1>
      </div>
    );
  };

  return (
    <Observer>
      {() => {
        const user = sessionStore.getUserData();
        const countryId = getCountryId(user);
        const countryCode = countryId ? findCountryCode(countryId) : '';
        const country = countryId ? countryStore.getModelValue(countryId)?.name : '';
        const isMobile = windowSize.isLessThan('medium');

        if (progressStore.isLoading(INITIALIZE_PROFILE)) {
          return (
            <SkeletonWrapper level={1}>
              <ShowDesktop>
                <div className='center-column'>
                  <Skeleton width={172} height={172} variant='circular' />
                  <EmptySpace height='24px' />
                  <Skeleton width={140} height={28} variant='text' />
                  <EmptySpace height='8px' />
                  <Skeleton width={130} height={24} variant='text' />
                  <EmptySpace height='8px' />
                  <Skeleton width={220} height={24} variant='text' />
                  <EmptySpace height='48px' />
                </div>
              </ShowDesktop>

              <ShowMobile>
                <div className='user-header'>
                  <div className='user-info'>
                    <Skeleton width={64} height={64} variant='circular' />
                    <EmptySpace width='16px' />
                    <Skeleton width={120} height={24} variant='text' />
                  </div>

                  <Skeleton className='settings' width={24} height={24} variant='circular' />
                </div>
                <EmptySpace height='8px' />
                <Skeleton height={24} variant='text' />
                <EmptySpace height='40px' />
              </ShowMobile>

              <Skeleton width={110} height={isMobile ? 24 : 28} variant='text' />
              <EmptySpace height='16px' />

              <div className='flex-row'>
                {[...Array(3).keys()].map((key) => (
                  <React.Fragment key={`profile-ske-${key}`}>
                    <Skeleton width={64} height={70} variant='rectangular' />
                    <EmptySpace width='16px' />
                  </React.Fragment>
                ))}
              </div>

              <EmptySpace height={isMobile ? '38px' : '24px'} />
              <Skeleton width={70} height={isMobile ? 24 : 28} variant='text' />
              <EmptySpace height='16px' />
              <Skeleton width={100} height={24} variant='text' />
            </SkeletonWrapper>
          );
        }

        return (
          <UserInfo level={1} isFirstChild={isEmpty(user.sports || [])}>
            <SEO title={t.staticAsString('navigation.myProfile')} />

            <div className='user-header'>
              <AvatarUpload avatar={user.avatar} onChangeFile={onChangeImage} isMobile={isMobile} />
              <Headline className='user-name' variant={isMobile ? 'h3' : 'h4'}>
                {fullname(user)}
              </Headline>
              {isMobile && <Svg className='settings' name='Settings' size={24} onClick={handleRedirect} />}
            </div>

            <div className='location-info'>
              {user.city && (
                <div className='location-info-item'>
                  <Svg name='Location' size={24} />
                  <Utility variant={isMobile ? 'u1' : 'u2'} weight='bold' className='profile-city'>
                    {user.city}
                  </Utility>
                </div>
              )}
              <div className='location-info-item'>
                <span className={`flag-icon flag-icon-${countryCode}`} />
                <Utility variant={isMobile ? 'u1' : 'u2'} weight='bold'>
                  {country}
                </Utility>
              </div>
            </div>

            {(user.sports || []).length > 0 && (
              <div className='interests'>
                {isMobile ? (
                  <Headline variant='h4'>{t.staticAsString('Interests' as any)}</Headline>
                ) : (
                  <Utility variant='u1' weight='bold'>
                    {t.staticAsString('Interests' as any)}
                  </Utility>
                )}

                <HorizontalScroll>
                  <ul>
                    {(user.sports || []).map((sport) => (
                      <li key={sport.id}>
                        <img src={sport.icon} alt='sport icon' />
                      </li>
                    ))}
                  </ul>
                </HorizontalScroll>
              </div>
            )}

            {user.union && (
              <div className='clubs'>
                {isMobile ? (
                  <Headline variant='h4'>{t.staticAsString('Club' as any)}</Headline>
                ) : (
                  <Utility variant='u1' weight='bold'>
                    {t.staticAsString('navigation.clubs')}
                  </Utility>
                )}

                <Utility variant={isMobile ? 'u1' : 'u2'} weight={isMobile ? 'bold' : 'medium'}>
                  {user.union}
                </Utility>
              </div>
            )}
            {renderXtriPoints()}
          </UserInfo>
        );
      }}
    </Observer>
  );
};
