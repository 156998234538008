import { STATUS_FINISHED, STATUS_STARTED } from 'src/constants';

import { resultUtil } from 'utils';

import { ParrentModel } from './parrentModel';

class Result extends ParrentModel<ResultType> {
  // resultTime(): string {
  //   if (this.value.start_time && this.value.finish_time) {
  //     return resultUtil.diffTime(this.value.finish_time, this.value.start_time);
  //   }
  //   return this._showTime();
  // }
  // _showTime(): string {
  //   const isStarted = !!this.value.start_time;
  //   const distanceIsFinished = this.value.distance.status === STATUS_FINISHED;
  //   const distanceIsStarted = this.value.distance.status === STATUS_STARTED;
  //   if (!isStarted && (distanceIsFinished || distanceIsStarted)) {
  //     return 'DNS';
  //   }
  //   if (isStarted && !this.value.finish_time && distanceIsFinished) {
  //     return 'DNF';
  //   }
  //   return '--:--';
  // }
}

export { Result };
