import classNames from 'classnames';
import * as React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import styled, { css } from 'styled-components';

import { RESOLUTIONS, DEFAULT_RACE_IMAGE } from 'src/constants';
import { Svg } from 'src/styledComponents/Icons';

type Props = {
  type: 'horizontal' | 'vertical';
  picture?: string;
  raceURL: string;
  titles: {
    fb?: string;
    linked?: string;
    tw?: string;
  };
};

const flexDirection = {
  horizontal: 'row',
  vertical: 'column',
};

const Wrapper = styled.ul<{ type: 'horizontal' | 'vertical' }>`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: ${(props) => flexDirection[props.type]};

  li {
    height: 32px;

    svg path {
      fill: ${(props) => props.theme.main.colors.clay3};
    }
    svg rect {
      stroke: ${(props) => props.theme.main.colors.clay3};
    }
  }

  li:hover {
    svg {
      background: ${(props) => props.theme.main.colors.clay3};
      border-radius: 50%;

      path {
        fill: ${(props) => props.theme.main.colors.white};
      }
    }
  }

  ${(props) =>
    props.type === 'horizontal' &&
    css`
      li:not(:first-child) {
        padding-left: 8px;
      }
    `}

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding-bottom: 24px;
  }
`;

export const SocialsSharing = ({ titles, type, raceURL, picture = DEFAULT_RACE_IMAGE }: Props) => (
  <Wrapper className={classNames('socials', { [type]: true })} type={type}>
    {titles.fb && (
      <li>
        <FacebookShareButton
          url={raceURL}
          title={titles.fb}
          // TODO TS, check is picture attr necessary
          // @ts-ignore
          picture={picture}
        >
          <Svg name='Facebook' className='social-item' size={32} />
        </FacebookShareButton>
      </li>
    )}
    {titles.tw && (
      <li>
        <TwitterShareButton url={raceURL} title={titles.tw}>
          <Svg name='Twitter' className='social-item' size={32} />
        </TwitterShareButton>
      </li>
    )}
    {titles.linked && (
      <li>
        <LinkedinShareButton
          url={raceURL}
          title={titles.linked}
          // TODO TS, check is picture attr necessary
          // @ts-ignore
          picture={picture}
        >
          <Svg name='Linkedin' className='social-item' size={32} />
        </LinkedinShareButton>
      </li>
    )}
  </Wrapper>
);
