import { Observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Swipe from 'react-easy-swipe';

import { Form } from 'src/components/form';
import { Loading } from 'src/components/loading';
import { Modal } from 'src/components/modal';
import { onCancel, onSearchEmail } from 'src/modules/transferRegistration/actions';
import { BlockSubtitle, BlockInfo } from 'src/modules/transferRegistration/components/Fields';
import {
  Wrapper,
  DraggableStroke,
  Header,
  StyledDivider,
  Controls,
  ScrollBarWrapper,
  Content,
} from 'src/modules/transferRegistration/components/styled';
import { PRELOADER, LOADER, TRANSFER_REGISTRATION_FORM } from 'src/modules/transferRegistration/constants';
import { modalStore } from 'src/modules/transferRegistration/stores';
import { ModalStoreType } from 'src/modules/transferRegistration/types';
import { Button } from 'src/styledComponents/Button';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { blockBodyScroll, t, unblockBodyScroll } from 'src/utils';

import { windowSize } from 'stores';

import { BlockRecipientInfo, BlockSubtitleAccordion, EmailField } from './Fields';
import { Title } from './Title';

type Props = {
  open: boolean;
};

const FormModal = ({ open }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSwipeEnd, setIsSwipeEnd] = useState(false);

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const handleTransitionEnd = () => {};

  useEffect(() => {
    blockBodyScroll();
    return () => {
      unblockBodyScroll();
    };
  }, []);

  return (
    <TestAnchorContext.Provider value={{ container: 'Transfer-Registration-Popup-Form' }}>
      <Observer>
        {() => {
          const isMobile = windowSize.isLessThan('large');
          const { name, email, distanceName } = modalStore.value as ModalStoreType;

          return (
            <Modal open={open}>
              <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={handleTransitionEnd} isDesktop={!isMobile} maxHeight={780}>
                <ScrollBarWrapper isDesktop={!isMobile}>
                  <ShowMobile>
                    <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
                      <DraggableStroke />
                    </Swipe>
                  </ShowMobile>
                  <Loading action={PRELOADER} opacity={1} />
                  <Loading action={LOADER} opacity={1} />

                  <Header>
                    <Title isMobile={isMobile} title={t.staticAsString('transferRegistration.form.title')} />
                  </Header>

                  <StyledDivider light />

                  <Content>
                    <BlockSubtitle title='transferRegistration.form.registrantInfo' />
                    <BlockInfo title='transferRegistration.form.name' value={name} />
                    <BlockInfo title='transferRegistration.form.email' value={email} />
                    <BlockInfo title='transferRegistration.form.distance' value={distanceName} />
                    <BlockSubtitle title='transferRegistration.form.recipientInfo' />
                    <BlockRecipientInfo text='transferRegistration.form.p' isNeedPadding={false} />

                    <Form id={TRANSFER_REGISTRATION_FORM} disableAutofill>
                      <EmailField />
                    </Form>

                    <BlockSubtitleAccordion />
                  </Content>

                  <Controls>
                    <ShowDesktop>
                      <Button variant='secondary' size='medium' ATN='cancel' onClick={onCancel}>
                        {t.staticAsString('settings.cancel')}
                      </Button>
                    </ShowDesktop>

                    <Button variant='primary' size='medium' fullWidth={isMobile} ATN='submit' onClick={onSearchEmail}>
                      {t.staticAsString('transferRegistration.form.saveChanges')}
                    </Button>
                  </Controls>
                </ScrollBarWrapper>
              </Wrapper>
            </Modal>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};

export { FormModal };
