import styled from 'styled-components';

export const LanguagesBlock = styled.ul`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto auto;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 76px;
`;
