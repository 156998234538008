import React from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';

import { theme } from 'styles';

import { Show } from 'components/condition';

import { Text } from '../Typography';
import { BaseButton, getButtonSizes } from './Base';
import { ButtonProps, Message } from './types';

const colors = theme.miles.colors.brand;

const getBackground = (message: keyof typeof Message) => {
  switch (message) {
    case Message.main:
      return {
        default: colors.aurora[500],
        hovered: colors.aurora[100],
      };
    case Message.mainSwap:
      return {
        default: colors.cobalt.cobalt900,
        hovered: colors.cobalt.cobalt700,
      };
    case Message.subtle:
      return {
        default: colors.alphas.light300,
        hovered: colors.alphas.light300,
      };
    default:
      return null;
  }
};

const getTextColor = (message: keyof typeof Message) => {
  switch (message) {
    case Message.main:
      return colors.cobalt.cobalt800;
    case Message.mainSwap:
      return colors.aurora[500];
    case Message.subtle:
      return colors.alphas.light800;
    default:
      return null;
  }
};

type StyledBrandButtonOutlined = ButtonProps & {
  isOutlined?: boolean;
};

const StyledBrandButton = styled(BaseButton)<StyledBrandButtonOutlined>`
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);

  ${(props) =>
    props.isOutlined
      ? css`
          background: transparent;
          border: 2px solid rgb(216, 255, 58);
          color: white;
        `
      : css`
          background: ${getBackground(props.message)?.default};
          &:hover {
            background: ${getBackground(props.message)?.hovered};
          }
          color: ${getTextColor(props.message)};
        `}
`;

export const BrandButton: React.FC<ButtonProps> = (props) => {
  const { message, size, children, isOutlined = false, type = 'button', iconLeft, iconRight, iconName, ...rest } = props;
  return (
    <StyledBrandButton isOutlined={isOutlined} type={type} message={message} size={size} {...rest}>
      <Show if={Boolean(iconLeft)}>
        <Icon className='left-icon' name={iconName || ''} size={getButtonSizes(size).icon} />
      </Show>
      <Text weight='bold' size={getButtonSizes(size).text}>
        {children}
      </Text>
      <Show if={Boolean(iconRight)}>
        <Icon className='right-icon' name={iconName || ''} size={getButtonSizes(size).icon} />
      </Show>
    </StyledBrandButton>
  );
};
