import { isEqual } from 'lodash';
import { reaction } from 'mobx';

import { borderStore } from 'src/modules/racersTable/stores';

import { onDistanceChange, updateHistoryBasedOnFilters } from '../actions';

import { filtersStore, disposers } from '../stores';

import { currentFormFilters } from '../derivations';
import { filterChanger } from '../mutations';

function observeFormFilters() {
  observeName();
  observeFilters();
  observeDistance();
  observeForHistory();
}

/* NOTE
 * Debounced track name change
 * WARN form store
 */
function observeName() {
  const dispose = reaction(
    () => currentFormFilters.name.get(),

    (name) => {
      if (filtersStore.value.name === name) {
        return;
      }

      filterChanger.onNameChange(name);
    },

    { delay: 500 },
  );

  disposers.register(dispose);
}

/* NOTE
 * Debounced track main filters(gender, class, checkpoint change)
 * WARN form store
 */
function observeFilters() {
  const dispose = reaction(
    () => currentFormFilters.main.get(),

    (mainFilters) => {
      const nameFilter = filtersStore.value.name ? { name: filtersStore.value.name } : {};
      const distanceFilter = filtersStore.value.distance_id ? { distance_id: filtersStore.value.distance_id } : {};
      const allFilters = { ...mainFilters, ...nameFilter, ...distanceFilter };

      if (isEqual(allFilters, filtersStore.value)) {
        return;
      }

      filterChanger.onChange(allFilters);
    },

    { equals: isEqual, delay: 350 },
  );

  disposers.register(dispose);
}

/* NOTE
 * Track distance change
 * WARN form store
 */
function observeDistance() {
  const dispose = reaction(
    () => currentFormFilters.distance.get(),

    (distanceId) => {
      if (distanceId === filtersStore.value.distance_id) {
        return;
      }

      borderStore.clear();
      onDistanceChange(distanceId as any);
    },
  );

  disposers.register(dispose);
}

/* NOTE
 * Track filters change, commited to filters store
 * to update filters in the history query params
 * WARN filterStore, different than the rest of filter observers
 */
function observeForHistory() {
  const dispose = reaction(
    () => filtersStore.value,

    () => {
      updateHistoryBasedOnFilters();
    },

    { equals: isEqual, delay: 400 },
  );

  disposers.register(dispose);
}

export { observeFormFilters };
