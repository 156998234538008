import axios from 'axios';

import { API_URL, DISCOVER_RACES } from 'src/constants';
import { CoordinatesType } from 'src/modules/home/types';
import { action, request } from 'src/utils';

import { GET_HOME_RACES } from '../constants';

import { mapRaces } from '../utils';

import { racesStore } from '../stores';

class Races {
  @request({ action: GET_HOME_RACES })
  getRacesRequest(coordinates: CoordinatesType | null): Promise<any> {
    let data: any = {
      page: 1,
    };

    if (coordinates) {
      data['coordinates'] = coordinates;
    }

    return axios.post(`${API_URL}${DISCOVER_RACES}`, data);
  }

  @action({ action: GET_HOME_RACES })
  async getRaces(coordinates: CoordinatesType | null) {
    const [success, response] = await this.getRacesRequest(coordinates);

    if (success) {
      const data = mapRaces(response.data.data);
      racesStore.setValue(data);
    }
  }
}

export const racesService = new Races();
