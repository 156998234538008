import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { SNACK_TYPE } from 'src/constants';
import { Icon, Svg } from 'src/styledComponents/Icons';
import { BodyText } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { snackStore, localeStore } from 'stores';

type Props = {
  type: string;
  closeButton?: boolean;
  onClose?: Function;
  children: React.ReactNode;
  id: string;
  title?: {
    // Title for intl translations
    i18key: string;
    i18params: Object;
  };
  body: React.ReactNode; // body for node elements or pure string,
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  color: ${(props) => props.theme.main.colors.white};

  &.snack-success {
    background: ${(props) => props.theme.main.colors.successBG};
  }

  &.snack-warn {
    background: ${(props) => props.theme.main.colors.warningBG};
  }

  &.snack-error {
    background: ${(props) => props.theme.main.colors.errorBG};
  }

  &.snack-info {
    background: ${(props) => props.theme.main.colors.infoBG};
  }

  .snack-body-wrapper {
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    .snack-body {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .snack-label {
        color: white !important;
        margin: 0;
        padding-left: 16px;

        .d-inline-block {
          display: inline-block;
        }
      }

      .close-icon {
        width: 80px;
        display: flex;
        justify-content: end;

        > div {
          cursor: pointer;

          svg {
            circle {
              fill: ${(props) => props.theme.main.colors.clay2};
            }

            path {
              stroke: ${(props) => props.theme.main.colors.clay4};
            }
          }
        }
      }
    }
  }
`;

@observer
class Snack extends React.Component<Props> {
  static defaultProps = {
    children: null,
    closeButton: false,
  };

  static contextType = TestAnchorContext;

  onClose = () => {
    const { id } = this.props;
    snackStore.removeValue(id);
  };

  title = () => {
    const { title } = this.props;
    if (!title) {
      return null;
    }

    const { i18key, i18params } = title;
    return t(i18key as TranslationLockedKeys, i18params || {});
  };

  renderIcon = (): React.ReactNode => {
    const { type } = this.props;

    if (type === SNACK_TYPE.error) return <Icon name='ErrorRed' />;
    if (type === SNACK_TYPE.warn) return <Icon name='WarningYellow' />;
    if (type === SNACK_TYPE.success) return <Icon name='CheckGreen' />;

    return <Icon name='InfoLemon' />;
  };

  render() {
    localeStore.value;
    const { type, body, children, closeButton } = this.props;
    return (
      <Wrapper className={classNames('snack-item', `snack-${type || 'info'}`)} {...testAnchors.field('Snack', 'root', `${type}`)}>
        <div className='snack-body-wrapper'>
          <div className='snack-body container'>
            {this.renderIcon()}

            <BodyText variant='body2' className='snack-label'>
              {this.title()}
              {body}
            </BodyText>
            {children}
            {closeButton && (
              <div onClick={this.onClose} className='close-icon' {...testAnchors.button('Snack', 'close')}>
                <Svg name='CloseLight' />
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export { Snack };
