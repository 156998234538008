import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { GENERATE_DYNAMIC_LINK, API_DYNAMIC_LINK, API_DYNAMIC_RACE_LINK } from 'src/constants';

import { request } from 'utils';

class DynamicLink {
  @request({ action: GENERATE_DYNAMIC_LINK })
  async generateDynamicRaceLinkRequest(id: number): Promise<any> {
    const path = generatePath(API_DYNAMIC_RACE_LINK, { id });
    return axios.get(path);
  }

  @request({ action: GENERATE_DYNAMIC_LINK })
  async generateDynamicLinkRequest(): Promise<any> {
    return axios.get(API_DYNAMIC_LINK);
  }

  async generateDynamicRaceLink(id: number) {
    const [status, response] = await this.generateDynamicRaceLinkRequest(id);

    if (status) {
      return response.data.data.link;
    }
  }

  async generateDynamicLink() {
    const [status, response] = await this.generateDynamicLinkRequest();

    if (status) {
      return response.data.main_link;
    }
  }
}

export const dynamicLink = new DynamicLink();
