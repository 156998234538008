import * as React from 'react';

export const TransferRegistration = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.33301 5.66667C4.33301 6.63913 4.71932 7.57176 5.40695 8.25939C6.09458 8.94702 7.02721 9.33333 7.99967 9.33333C8.97213 9.33333 9.90477 8.94702 10.5924 8.25939C11.28 7.57176 11.6663 6.63913 11.6663 5.66667C11.6663 4.69421 11.28 3.76158 10.5924 3.07394C9.90477 2.38631 8.97213 2 7.99967 2C7.02721 2 6.09458 2.38631 5.40695 3.07394C4.71932 3.76158 4.33301 4.69421 4.33301 5.66667V5.66667Z'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 16.6676C2.00007 15.5101 2.33496 14.3773 2.96429 13.4058C3.59362 12.4343 4.49052 11.6656 5.54687 11.1924C6.60321 10.7191 7.7739 10.5615 8.9178 10.7386C10.0617 10.9156 11.1299 11.4198 11.9938 12.1903'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.334 19.334L14.0007 22.0007L16.6673 19.334'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M14 22V14' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.667 16.6667L19.3337 14L22.0003 16.6667' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M19.333 14V22' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
