import * as React from 'react';

export const Time = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12V12Z'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 12V8.78572' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 12L16.0174 16.0183' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
