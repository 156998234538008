import { Dictionary } from 'lodash';
import { TrainingPlanType } from 'modules/miles/app/home/stores/trainingPlan';
import { WorkoutDifficultyLevel } from 'modules/miles/app/home/types';
import { animation } from 'modules/miles/app/home/ui/styled-components';
import { getStatusColor, weeksToGoMessage } from 'modules/miles/app/home/utils';
import { ArrowDown, Display, Text } from 'modules/miles/shared/components';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { Show } from 'components/condition';

const Dot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 8px 10px 8px 12px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['100']};
  background: ${({ theme }) => theme.miles.colors.dark.background['600']};
  box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion2Strong};
  color: ${(props) => props.theme.miles.colors.dark.alpha['700']};

  cursor: pointer;

  ${animation}
  &:hover {
    background: ${({ theme }) => theme.miles.colors.dark.background['800']};
  }
  .icon {
    display: flex;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .title {
    color: ${(props) => props.theme.miles.colors.dark.alpha['900']};
  }
  .workout-data {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .workout-by-type {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
`;

type Props = {
  week?: { trainingPlan: Dictionary<TrainingPlanType[]>; startWeekDate: string };
  endDate?: string;
  compact?: boolean;
  title?: React.ReactNode;
  onClick?: AnyFunction;
};
export const WeekPlanCard: React.FC<Props> = ({ week, endDate, compact, onClick, title }) => {
  if (compact)
    return (
      <Wrapper onClick={onClick}>
        <Content>
          <Display className='title' weight={'bold'} size={'xs'}>
            See Future Weeks
          </Display>
        </Content>
        <div className='icon'>
          <ArrowDown style={{ transform: 'rotate(-90deg)' }} />
        </div>
      </Wrapper>
    );

  if (!week || !endDate) return null;

  const mappedData = Object.keys(week.trainingPlan)
    .filter((item) => item !== 'undefined_0')
    .map((key) => {
      const [name, difficulty] = key.split('_');
      return {
        difficulty: difficulty as WorkoutDifficultyLevel,
        name,
        count: week.trainingPlan[key].length,
      };
    });

  return (
    <Wrapper onClick={onClick}>
      <Content>
        <Show if={Boolean(title)}>{title}</Show>
        <Show if={!title}>
          <Display className='title' weight={'bold'} size={'xs'}>
            {`${moment(week.startWeekDate).format('DD MMM')} - ${moment(week.startWeekDate).endOf('isoWeek').format('DD MMM')}`}
          </Display>
          <Text weight='bold' size={'sm'}>
            {weeksToGoMessage(week.startWeekDate, endDate)}
          </Text>
        </Show>
        <div className='workout-data'>
          {mappedData.map((item) => (
            <div key={`${item.difficulty}_${item.count}_${item.name} `} className='workout-by-type'>
              <Dot color={getStatusColor(item.difficulty)} />
              <Text weight='regular' size={'sm'}>
                {`${item.count} ${item.name} `}
              </Text>
            </div>
          ))}
        </div>
      </Content>
      <div className='icon'>
        <ArrowDown style={{ transform: 'rotate(-90deg)' }} />
      </div>
    </Wrapper>
  );
};
