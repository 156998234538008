import { Formula } from 'components/racersTable';

export const tableFormula: Formula = {
  'distance-icon': ['small-box', 'center'],
  distance: ['left'],
  'users-count': ['left', 'spaced-left-32'],
  place: ['small-box', 'center', 'bold', 'large-font', 'black', 'spaced-left-20'],
  bib: ['small-box', 'left', 'large-font', 'spaced-left-44', 'spaced-right-20'],
  class: ['large-box', 'right', 'medium-font', 'smart-word-break', 'text-center', 'spaced-left-32'],
  union: ['right', 'medium-font', 'smart-word-break', 'text-center', 'spaced-left-20'],
  wave: ['medium-box', 'right', 'medium-font', 'smart-word-break', 'text-center', 'spaced-left-32'],

  time: ['smedium-box', 'right', 'bold', 'medium-font', 'spaced-left-32'],
  diff: ['smedium-box', 'right', 'medium-font', 'spaced-left-32'],
  'mobile-place': ['small-box', 'left', 'bold', 'medium-font', 'black'],
  'mobile-bib': ['small-box', 'left', 'medium-font'],
  'mobile-time': ['smedium-box', 'left', 'bold', 'medium-font'],
  'mobile-diff': ['smedium-box', 'left', 'medium-font'],
  'mobile-distance_logged': ['left', 'medium-font'],
  'mobile-progress': ['left', 'medium-font', 'bold'],

  teamname: ['bold', 'large-font', 'spaced-left-32', 'smart-word-break'],
  'mobile-teamname': ['bold', 'large-font', 'smart-word-break'],
  name: ['bold', 'large-font', 'smart-word-break', 'spaced-left-32', 'left', 'black'],
  'mobile-name': ['bold', 'large-font', 'smart-word-break', 'left', 'black'],
  members: ['left', 'medium-font', 'spaced-left-140'],
  'mobile-members': ['left', 'medium-font'],
  'expand-icon': ['small-box', 'right', 'spaced-left-32', 'spaced-right-20'],
  'mobile-expand-icon': ['right'],

  distance_logged: ['smedium-box', 'right', 'medium-font', 'spaced-left-32'],

  'tracking-source': ['medium-box', 'non-adaptive-small', 'right', 'medium-font', 'spaced-left-32', 'spaced-right-20'],
  'tracking-source-with-progress': ['medium-box', 'non-adaptive-small', 'right', 'medium-font', 'spaced-left-32', 'spaced-right-20'],
  'mobile-tracking-source': ['right', 'spaced-left-8'],

  'split-data': ['left', 'small-font', 'black'],
  'split-data-small': ['smedium-box', 'center', 'small-font', 'black', 'spaced-left-24'],
  'split-body-data': ['left', 'medium-font', 'black'],
  'split-body-data-small': ['smedium-box', 'center', 'medium-font', 'black', 'spaced-left-24'],
};
