import { HEADER_HEIGHT } from 'modules/header/constants';
import { HeaderVariants } from 'modules/header/types';
import { getBackgroundColor } from 'modules/header/utils';
import styled, { css } from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';

export const HeaderWrap = styled('div')(
  ({ isSnackShown, hide, variant }: { isSnackShown?: boolean; hide?: boolean; variant?: HeaderVariants }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    padding: 8px 20px 8px 12px;
    background: ${getBackgroundColor(variant)};
    height: ${HEADER_HEIGHT}px;
    ${isSnackShown ? 'margin-top: 56px;' : ''};
    ${hide ? 'display:none;' : ''};
    .border {
      border: 1px solid rgba(255, 255, 255, 0.08);
    }
    .right-group {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  `,
);

export const StyledSvg = styled(Svg)<{ isMiles?: boolean }>`
  .stroke {
    stroke: ${(props) => (props.isMiles ? props.theme.miles.colors.dark.alpha['900'] : props.theme.main.colors.clay1)};
  }
`;
