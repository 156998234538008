import { generateGenderValues } from 'utils';

export const generateValidation = (options: { isCountry: boolean; isNationality: boolean }) => {
  const countryValidation = options.isCountry
    ? {
        country_id: {
          presence: {
            allowEmpty: false,
            message: 'settings.country validation.required',
          },
        },
      }
    : {};

  const nationalityValidation = options.isNationality
    ? {
        nationality_id: {
          presence: {
            allowEmpty: false,
            message: 'settings.nationality_id validation.required',
          },
        },
      }
    : {};

  return {
    firstname: {
      length: {
        maximum: 250,
      },
      presence: {
        allowEmpty: false,
        message: 'settings.firstName validation.required',
      },
    },
    lastname: {
      length: {
        maximum: 250,
      },
      presence: {
        allowEmpty: false,
        message: 'settings.lastName validation.required',
      },
    },
    email: {
      length: {
        maximum: 250,
      },
      presence: {
        allowEmpty: false,
        message: 'login.email validation.required',
      },
      email: {
        message: 'login.email validation.notValid',
      },
    },
    gender: {
      presence: {
        message: 'settings.gender validation.required',
      },
      inclusion: {
        within: generateGenderValues(),
        message: 'settings.gender validation.required',
      },
    },
    ...countryValidation,
    ...nationalityValidation,
  };
};

const count = 'count';

export const apiErrors = [count];
