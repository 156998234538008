import { reject } from 'lodash';
import { observable, action, makeObservable } from 'mobx';
import * as React from 'react';
import shortid from 'shortid';

import { SNACK_TYPE } from 'src/constants';

const REMOVE_TIMEOUT = 5000;

type SnackItem = {
  id: string;
  body: React.ReactNode;
  type: string;
  closeButton?: boolean;
  open?: boolean;
  onClose?: Function;
  children: React.ReactNode;
  options: AnyObject;
};

type CallParams = {
  i18key: string;
  i18params?: Object;
  payload?: Object;
};

class Snack {
  @observable values: Array<SnackItem> = [];

  // Hide all snacks, But do not delete
  @observable isAllSnacksHidden: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  addValue(value: SnackItem) {
    this.values = [value, ...this.values];
    return value.id;
  }

  @action
  removeValue(id: string | null) {
    this.values = this.values.filter((el) => el.id !== id);
  }

  @action
  updateValue(id: string, payload: Object = {}) {
    this.values = this.values.map((el) => {
      if (el.id === id) {
        return { ...el, ...payload };
      }
      return el;
    });
  }

  @action
  success({ i18key, i18params, payload = {} }: CallParams, options: Object = {}) {
    const id = shortid();
    const type = SNACK_TYPE.success;
    let title: AnyObject | null = null;

    if (i18key) {
      title = {
        i18key,
        i18params,
      };
    }

    const props = { id, type, title, ...payload, options };
    return this.addValue(props as any);
  }

  @action
  warn({ i18key, i18params, payload = {} }: CallParams, options: Object = {}) {
    const id = shortid();
    const type = SNACK_TYPE.warn;
    let title: AnyObject | null = null;

    if (i18key) {
      title = {
        i18key,
        i18params,
      };
    }

    const props = { id, type, title, ...payload, options };
    return this.addValue(props as any);
  }

  @action
  error({ i18key, i18params, payload = {} }: CallParams, options: Object = {}) {
    const id = shortid();
    const type = SNACK_TYPE.error;
    let title: AnyObject | null = null;

    if (i18key) {
      title = {
        i18key,
        i18params,
      };
    }

    const props = { id, type, title, ...payload, options };
    return this.addValue(props as any);
  }

  @action
  removeAll(filter: (item: SnackItem) => boolean) {
    this.values = reject(this.values, filter);
  }

  removeWithTimeout(id: string, timeout: number = REMOVE_TIMEOUT) {
    setTimeout(() => {
      this.removeValue(id);
    }, timeout);
  }

  @action
  hideAllSnacks() {
    this.isAllSnacksHidden = true;
  }

  @action
  showAllSnacks() {
    this.isAllSnacksHidden = false;
  }
}

export default new Snack();
export { Snack };
