import classNames from 'classnames';
import { observer } from 'mobx-react';
import { PlaceCell } from 'modules/profile/components';
import moment from 'moment';
import * as React from 'react';

import { RESULT_DATE_FORMATE } from 'src/constants';
import { Link } from 'src/styledComponents/Link';

import { Table } from 'components/table';

import { raceUtil, t } from 'utils';

import { Distance } from 'models';

import { getDistanceUrl } from '../../actions';

import { classicStore } from '../../stores';

import { VirtualDistanceMapped } from '../../types';

type Props = {};

@observer
export class TableView extends React.Component<Props> {
  getResult = (distance: VirtualDistanceMapped = {} as VirtualDistanceMapped): AnyObject => {
    if (distance.type === 'team') {
      return (distance.teams || [])[0]?.result || {};
    }

    return (distance.racers || [])[0]?.result || {};
  };

  getFormattedDate = (date?: string): string => {
    if (!date) {
      return '';
    }

    return moment(date, RESULT_DATE_FORMATE).format('MM/DD/YYYY');
  };

  renderRows = (): Array<React.ReactNode> | React.ReactNode => {
    return classicStore.list.map((distance: VirtualDistanceMapped) => {
      if (!distance.race) return <div>NONONONO</div>;

      const distanceModel = new Distance(distance as any);
      const length = raceUtil.humanizeDistance(+distance.race_length);
      const result = this.getResult(distance);
      const place = result.place || '- -';

      const disableDistanceTitle = !distance.show_results || !distance.race.show_results;

      return (
        <Table.Row key={distance.id}>
          <Table.Cell className='place' value={<PlaceCell value={place} hex={distance?.race?.sport?.hex_color} />} />
          <Table.Cell
            className='name'
            value={
              <Link
                className={classNames('distance-title truncate', { disabled: disableDistanceTitle })}
                to={getDistanceUrl(distance.id, distance.race.id, distance.type)}
              >
                {t(() => distanceModel.value.name)}
              </Link>
            }
          />
          <Table.Cell className='length' value={this.getFormattedDate(distance.race_date)} />
          <Table.Cell className='length' value={length} />
          <Table.Cell className='time' value={distance.resultData.time_result} />
        </Table.Row>
      );
    });
  };

  render() {
    return (
      <Table.Wrapper className='results-table classic'>
        <Table.Head>
          <Table.Cell className='place' value='profile.registeredTable.head.place' />
          <Table.Cell className='name' value='profile.registeredTable.head.name' />
          <Table.Cell className='length' value='profile.registeredTable.head.date' />
          <Table.Cell className='length' value='profile.registeredTable.head.length' />
          <Table.Cell className='time' value='profile.registeredTable.head.time' />
        </Table.Head>
        <Table.Body>{this.renderRows()}</Table.Body>
      </Table.Wrapper>
    );
  }
}
