import { keys, isArray, isString, compact, last, groupBy, isEmpty, isDate } from 'lodash';
import moment from 'moment';

import { CustomFieldTypes } from '../../constants';

import { distanceSelector } from '../../selectors/mappedData';
import { Data } from '../../types';
import { fieldIdUtils } from '../fieldIdUtils';
import { mapCustomFieldOptionsToSelectOptions } from './mapCustomFieldOptionsToSelectOptions';

type CustomFieldValueOptionsMap = { [k in number]: NewSelectItemType | nil };

function findCustomField(id: number | nil): CustomFields | null {
  if (!id) {
    return null;
  }

  return distanceSelector.expandedCustomFields.get()[id] || null;
}

function generateCustomFieldValueOptionsMap(): CustomFieldValueOptionsMap {
  return distanceSelector.customFields.get().reduce((customFieldOptions, customField) => {
    const options = mapCustomFieldOptionsToSelectOptions(customField.values);

    options.forEach((option) => {
      customFieldOptions[option.value] = option;
    });

    return customFieldOptions;
  }, {});
}

function mapTextField(values: Data.Backend.CustomFieldValueFromBE[]): string | null {
  const customFieldValue = last(values)?.value || null;

  if (!isString(customFieldValue)) {
    return null;
  }

  return customFieldValue || null;
}

function mapDateField(values: Data.Backend.CustomFieldValueFromBE[]): moment.Moment | nil {
  const customFieldValue = last(values)?.value || NaN;
  const momentValue = moment(customFieldValue);

  if (!momentValue.isValid()) {
    return null;
  }

  return momentValue;
}

function mapTimeField(values: Data.Backend.CustomFieldValueFromBE[]): string | number | nil {
  const customFieldValue = last(values)?.value || NaN;

  if (!customFieldValue) {
    return null;
  }

  return customFieldValue;
}

function mapCheckboxField(values: Data.Backend.CustomFieldValueFromBE[] | nil): number[] | null {
  if (!isArray(values)) {
    return [];
  }

  return compact(values.map((customFieldValue) => customFieldValue.id));
}

function mapDropdownField(
  values: Data.Backend.CustomFieldValueFromBE[] | nil,
  customFieldValueOptions: CustomFieldValueOptionsMap,
): NewSelectItemType | nil {
  const customFieldValueId = last(values)?.id;
  const option = customFieldValueId && customFieldValueOptions[customFieldValueId];

  return option || null;
}

function mapCustomFieldValue(
  fieldValues: Data.Backend.CustomFieldValueFromBE[],
  customField: CustomFields,
  customFieldOptions: CustomFieldValueOptionsMap,
) {
  switch (customField.type) {
    case CustomFieldTypes.textfield:
      return mapTextField(fieldValues);
    case CustomFieldTypes.date:
      return mapDateField(fieldValues);
    case CustomFieldTypes.time:
      return mapTimeField(fieldValues);
    case CustomFieldTypes.checkbox:
      return mapCheckboxField(fieldValues);
    case CustomFieldTypes.radio:
    case CustomFieldTypes.drop_down:
    case CustomFieldTypes.extra:
      return mapDropdownField(fieldValues, customFieldOptions);
    default:
      return null;
  }
}

/**
 * @description
 * Map array of custom field values returned from the Backend to an custom fields object stored in the form
 * map custom fields from BE format to form format
 *
 * WARNING
 * empty values will be omitted
 */
export function mapBECFToFormCF(fieldIdPrefix: string, data: Data.Backend.CustomFieldValueFromBE[]): Data.Form.CustomFields {
  const groupedFieldValues = groupBy(data, 'field_id');
  const customFieldKeys = keys(groupedFieldValues);
  const customFieldValueOptions = generateCustomFieldValueOptionsMap();

  return customFieldKeys.reduce((formCustomFields, beCustomFieldKey: string) => {
    const beCustomFieldId = parseInt(beCustomFieldKey);
    const customField = findCustomField(beCustomFieldId);

    if (!customField) {
      return formCustomFields;
    }

    const mappedValue = mapCustomFieldValue(groupedFieldValues[beCustomFieldId], customField, customFieldValueOptions);

    if (!isDate(mappedValue) && isEmpty(mappedValue)) {
      return formCustomFields;
    }

    formCustomFields[fieldIdUtils.customFieldsFieldId(fieldIdPrefix, beCustomFieldId)] = mappedValue;

    return formCustomFields;
  }, {});
}
