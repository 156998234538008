import classNames from 'classnames';
import * as React from 'react';

import { withBlockedScroll, withoutHeader } from 'components/hocs';
import { Close } from 'components/icons';

import { resize } from 'utils';

type Props = {
  isModal?: boolean;
  handleClose: (e?: React.MouseEvent<HTMLDivElement>) => void;
};

@withBlockedScroll({ desktopOnly: true })
@withoutHeader({ mobileOnly: true })
export class Magazine extends React.PureComponent<Props> {
  UNSAFE_componentWillMount() {
    if (resize.isMobile()) {
      const mainContent = document.getElementsByTagName('main')[0];
      mainContent.style.paddingBottom = '0';
    }
  }

  componentWillUnmount() {
    if (resize.isMobile()) {
      const mainContent = document.getElementsByClassName('main-content')[0] as HTMLElement;
      mainContent.style.paddingBottom = '';
    }
  }

  handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    const { handleClose } = this.props;

    // $FlowFixMe
    const isParent = (e.target as HTMLElement).closest('.magazine-wrap');

    if (!isParent) {
      handleClose();
    }
  };

  render() {
    const { isModal, handleClose } = this.props;

    return (
      <div
        className={classNames('magazine', {
          'modal-view': isModal,
        })}
        onClick={this.handleClickOutside}
      >
        <div className='magazine-wrap'>
          <div className='magazine-head'>
            <div className='close-icon' onClick={handleClose}>
              {Close}
            </div>
          </div>
          <iframe
            src='https://www.yumpu.com/en/embed/view/8zglfA65hSRKMnId'
            frameBorder='0'
            allowFullScreen={true}
            allowTransparency={true}
          />
        </div>
      </div>
    );
  }
}
