import axios from 'axios';
import { MILES_TRAINING_PLAN, USER_PARAMS } from 'constants/api';
import { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import { action, computed, makeObservable, observable } from 'mobx';
import { RaceType } from 'modules/home/types';
import moment from 'moment/moment';

import { history } from 'utils';

import { CACHE_KEYS } from '../constants/localStorageKeys';

import { mapToTrainingPlanType } from '../utils';

import { connectedServicesStore } from '../../stores';

import { DEVICE_SERVICES } from '../../form/ui/steps/integrate-services/IntegrateServices';
import { WorkoutDifficultyLevel } from '../types';
import { MilesDataInterval } from '../types/miles_data';
import { EMPTY_MILES_MESSAGE } from '../ui/TrainingPlan';
import { Unit } from './mock';

type TextObj = {
  text: string;
};

type IntervalSlice = {
  value: number | nil;
  unit: Unit | nil;
  ui: string;
};

export type Interval = {
  setsCount: number;
  sets: {
    intervalSection: number;
    setCount: number;
    intervals: {
      rep: IntervalSlice;
      rest: IntervalSlice;
    }[];
  }[];
  set: {
    rep: IntervalSlice;
    rest: IntervalSlice;
  }[];
  warmup: IntervalSlice;
  cooldown: IntervalSlice;
};

export type MilesData = {
  session_title: string;
  session_info: string;
  session_time: string;
  session_description: string;
  date: string;
  rpe: string;
  total_workout_time?: string;
  hr: string;
  speed: string;
  warmup_cooldown?: string;
  recovery?: string;
  intensity_msg: string;
  intensity_int: number;
  intervals?: MilesDataInterval;
};

export type TrainingPlanType = {
  id: number;
  difficultyLevel: WorkoutDifficultyLevel;
  date: string;
  name?: string;
  difficultyLevelLabel: string;
  session_description: string;
  interval: Interval | nil;
  hr_desc?: string;
  speed_desc?: string;
  interval_desc?: string;
  time?: string;
  isRacePrep?: boolean;
  racePrepMessage?: string;
};

class TrainingPlanStore {
  @observable
  private isPlanExpired: boolean = false;

  @observable
  isLoading: boolean = true;

  @observable
  trainingPlan: TrainingPlanType[] = [];

  @observable
  endDate: string = '';

  @observable
  trainingMsg: string = '';

  @observable
  changeMsg: string = '';

  @observable
  changedParams: string = '';

  @observable
  optimalPlanMsg: string[] = [];

  @observable
  readinessMsg: string = '';
  isDailyDataFilled: boolean = false;

  @observable
  latestUpdate: string = '';

  blacklist: string[] = ['No message available'];

  collapsableState = {
    trainingMsg: true,
    changeMsg: true,
    readinessMsg: true,
    optimalPlanMsg: true,
  };

  @observable
  race: RaceType;
  constructor() {
    makeObservable(this);
  }

  setCollapsableState(key: string, value: boolean) {
    this.collapsableState[key] = value;
    localStorage.setItem(CACHE_KEYS.collapsableState, JSON.stringify(this.collapsableState));
  }

  restoreCollapsableState() {
    const cache = localStorage.getItem(CACHE_KEYS.collapsableState);
    if (cache) {
      this.collapsableState = JSON.parse(cache);
    }
  }

  @computed
  get isTrainingPlanAvailable(): boolean {
    return this.trainingPlan.length > 0;
  }

  @computed
  get isTrainingPlanExpired(): boolean {
    return this.isPlanExpired;
  }

  @computed
  get trainingPlanByWeeks(): Dictionary<TrainingPlanType[]> {
    return groupBy(this.trainingPlan, (day) => moment(day.date).startOf('isoWeek').format('YYYY-MM-DD'));
  }

  isLastWeek(startWeekDate: string): boolean {
    return last(Object.keys(this.trainingPlanByWeeks)) === startWeekDate;
  }

  combineText(textObjs: TextObj[][]): string {
    const concatenatedString = textObjs.map((obj) => obj.map((innerObj) => innerObj.text).join(' ')).join('\n');
    if (concatenatedString === '') {
      return '';
    }
    return concatenatedString;
  }

  dailyDataFilled() {
    this.readinessMsg = 'Updating readiness message. This might take a while.';
    this.isDailyDataFilled = true;
  }

  getFormattedDate(timestamp: number) {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');

    return formattedDate;
  }

  @computed
  get compactPlanByWeeks(): { trainingPlan: Dictionary<TrainingPlanType[]>; startWeekDate: string }[] {
    const weeks = Object.keys(this.trainingPlanByWeeks);
    const trainingPlanByWeeks = this.trainingPlanByWeeks;

    return weeks.map((week) => {
      return { startWeekDate: week, trainingPlan: groupBy(trainingPlanByWeeks[week], (item) => `${item.name}_${item.difficultyLevel}`) };
    });
  }

  @action
  setTrainingPlan(value: TrainingPlanType[]) {
    this.trainingPlan = value;
  }

  @action
  clear() {
    this.trainingPlan = [];
  }

  tr: AnyObject = {};

  async loadTrainingPlan() {
    this.isLoading = true;
    try {
      connectedServicesStore.setServices(DEVICE_SERVICES);
      await connectedServicesStore.loadServices();
      const [trainingPlan, userParams] = await Promise.all([axios.get(MILES_TRAINING_PLAN), axios.get(USER_PARAMS)]);
      const isReverseEllida = userParams.data.data.ellida.planStyle.reverseEllida;
      if (trainingPlan.data.data.plan.data.running.data.plan.length === 0) {
        this.isPlanExpired = true;
        this.trainingMsg =
          'Great job finishing your plan! Go to the settings to create a new one, it should be delivered within 15 minutes.';
        this.changeMsg = EMPTY_MILES_MESSAGE;
        this.readinessMsg = EMPTY_MILES_MESSAGE;
        return;
      }
      this.trainingPlan = trainingPlan.data.data.plan.data.running.data.plan.slice(0, -1).map(mapToTrainingPlanType);
      this.endDate = trainingPlan.data.data.plan.data.running.data.plan.slice(-1)[0].date.texts[0].text;
      this.latestUpdate = this.getFormattedDate(trainingPlan.data.data.plan.data.running.data.latest_update);

      if (trainingPlan.data.data.plan.data.running.data.changed_parameters) {
        this.changedParams = this.combineText(
          [trainingPlan.data.data.plan.data.running.data.changed_parameters.texts].filter((textArray) => textArray != null),
        );
      }

      // Set training message
      this.trainingMsg = this.combineText(
        [
          trainingPlan.data.data.plan.data.running.data.training?.texts,
          trainingPlan.data.data.plan.data.running.data.planned_and_done_load?.texts,
        ].filter((textArray) => {
          return textArray != null && !this.blacklist.includes(textArray[0].text);
        }),
      );

      // Set change message
      this.changeMsg = this.combineText(
        [trainingPlan.data.data.plan.data.running.data.change?.texts].filter((textArray) => textArray != null),
      );

      if (isReverseEllida) {
        this.optimalPlanMsg =
          trainingPlan.data.data.plan.data.running.data.optimal_plan?.optimal_plan_msg?.texts.map((text) => text.text) ?? [];
      } else {
        this.optimalPlanMsg = [];
      }

      if (trainingPlan.data.data.plan.data.running.data.readiness?.texts) {
        // Set readiness message
        if (!this.isDailyDataFilled) {
          this.readinessMsg = this.combineText([trainingPlan.data.data.plan.data.running.data.readiness.texts]);
        }
      } else {
        this.readinessMsg = '';
      }

      this.restoreCollapsableState();
    } catch (error) {
      if (window.location.pathname.includes('/miles/')) {
        if (error.response?.status === 403) {
          history.push('/en/miles/renew-subscription');
        }
      }
      throw new Error(error);
    } finally {
      this.isLoading = false;
      // this.cacheEverything();
    }
  }

  @action
  dropCache() {
    localStorage.removeItem(CACHE_KEYS.trainingPlan);
    this.trainingPlan = [];
    this.endDate = '';
    this.trainingMsg = '';
    this.changeMsg = '';
    this.readinessMsg = '';
    this.isDailyDataFilled = false;
    this.latestUpdate = '';
  }

  @action
  restoreCache() {
    const cache = localStorage.getItem(CACHE_KEYS.trainingPlan);
    if (cache) {
      const parsedCache = JSON.parse(cache);
      this.trainingPlan = parsedCache.trainingPlan;
      this.endDate = parsedCache.endDate;
      this.trainingMsg = parsedCache.trainingMsg;
      this.changeMsg = parsedCache.changeMsg;
      this.readinessMsg = parsedCache.readinessMsg;
      this.isDailyDataFilled = parsedCache.isDailyDataFilled;
      this.latestUpdate = parsedCache.latestUpdate;
    }
  }

  cacheEverything() {
    const cache = {
      trainingPlan: this.trainingPlan,
      endDate: this.endDate,
      trainingMsg: this.trainingMsg,
      changeMsg: this.changeMsg,
      readinessMsg: this.readinessMsg,
      isDailyDataFilled: this.isDailyDataFilled,
      latestUpdate: this.latestUpdate,
    };

    localStorage.setItem(CACHE_KEYS.trainingPlan, JSON.stringify(cache));
  }
}

export { TrainingPlanStore };
