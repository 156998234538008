import { distancesStore, checkpointsStore } from '../stores';

function isValidDistance(id: number | null | string) {
  return !!distancesStore.find(+id!);
}

function isValidCheckpoint(id: number | nil | string) {
  return !!checkpointsStore.find(+id!);
}

export const validateData = {
  isValidDistance,
  isValidCheckpoint,
};
