import axios from 'axios';
import shortid from 'shortid';

import { API_RACES_URL, API_V2_URL } from 'src/constants';

import { request, action } from 'utils';

class RaceService {
  @request({ action: shortid() })
  request(raceId: number): any {
    const url = `${API_V2_URL}${API_RACES_URL}/${raceId}`;
    return axios.get(url, { params: { with: 'distances.prices' } });
  }

  @action({ action: shortid() })
  async load(raceId: number) {
    const [isOk, response] = await this.request(raceId);

    if (isOk) {
      return response.data.data;
    }

    return null;
  }
}

const raceService = new RaceService();
export { RaceService, raceService };
