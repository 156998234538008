import * as React from 'react';
import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants/general/settings';
import { Truncate } from 'src/styles/effects';

import { UtilityContext } from './context';

type BaseProps = {
  weight: Weight;
  truncate?: boolean;
  title?: string;
  className?: string;
  withoutMedia: boolean;
};

const getDesktopStyles = (size: number, height: number) => (props: BaseProps) =>
  props.withoutMedia
    ? ''
    : css`
        font-size: ${size}px;
        line-height: ${height}px;
      `;

const BaseUtility = styled.span<BaseProps>`
  font-family: Aeonik, sans-serif;
  font-style: normal;
  color: ${(props) => props.theme.main.colors.clay1};
  font-weight: ${(props) => (props.weight === 'medium' ? 500 : 700)};
  letter-spacing: 0.03em;
  text-transform: uppercase;

  ${(props) => (props.truncate ? Truncate : '')}
`;

const Utility1 = styled(BaseUtility)<BaseProps>`
  font-size: 20px;
  line-height: 24px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    ${getDesktopStyles(24, 28)}
  }
`;

const Utility2 = styled(BaseUtility)<BaseProps>`
  font-size: 14px;
  line-height: 18px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    ${getDesktopStyles(20, 24)}
  }
`;

const Utility3 = styled(BaseUtility)<BaseProps>`
  font-size: 12px;
  line-height: 16px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    ${getDesktopStyles(16, 20)}
  }
`;

const Utility4 = styled(BaseUtility)<BaseProps>`
  font-size: 14px;
  line-height: 18px;
`;

type Props = {
  variant: 'u1' | 'u2' | 'u3' | 'u4';
  weight: 'medium' | 'bold';
  className?: string;
  truncate?: boolean;
  onClick?: AnyFunction;
  title?: string;
  children?: React.ReactNode;
} & TestAnchortProperties;

const Variants = {
  u1: 'u1',
  u2: 'u2',
  u3: 'u3',
  u4: 'u4',
};

enum Weight {
  medium = 'medium',
  bold = 'bold',
}

const components = {
  [Variants.u1]: Utility1,
  [Variants.u2]: Utility2,
  [Variants.u3]: Utility3,
  [Variants.u4]: Utility4,
};

export const Utility: React.FC<Props> = (props) => {
  const context = React.useContext(UtilityContext);
  const { variant = Variants.u1, weight = Weight.bold, children, className, truncate, onClick, title, ...rest } = props;
  const Component = components[variant];

  return (
    <Component
      withoutMedia={context.withoutMediaConditions}
      weight={weight as Weight}
      className={className}
      truncate={truncate}
      onClick={onClick}
      title={title}
      {...rest}
    >
      {children}
    </Component>
  );
};
