import { AdditionalRegistrationFields, CustomFieldTypes, OtherFields, RegistrationFields, TeamFields } from '../constants';

import { fieldIdUtils } from '../utils';

import { fieldConditions } from '../selectors/fieldConditions';
import { mainConditions } from '../selectors/mainConditions';
import { distanceSelector } from '../selectors/mappedData';
import { validationRule, customFieldValidationRule } from './rules';

/**
 * Ready to use validation schemes
 */
export const schemes = {
  /**
   * Registration fields, additional fields
   */
  racerScheme: function (racerFormId: string) {
    const registrationFieldRules = distanceSelector.registrationFields.get().reduce((scheme, field) => {
      const isRequired = field.required;
      const isSelected = field.selected;
      const name = field.name as RegistrationFields;

      if (!isSelected) {
        return scheme;
      }

      scheme[name] = validationRule(name, isRequired);

      return scheme;
    }, {});

    const additionFieldRules = fieldConditions.racerAdditionalFields().reduce((scheme, field) => {
      scheme[field] = validationRule(field, true);
      return scheme;
    }, {});

    return { ...registrationFieldRules, ...additionFieldRules };
  },

  passwordScheme: function () {
    return {
      [OtherFields.password]: validationRule(OtherFields.password, true),
      [OtherFields.password_confirmation]: validationRule(OtherFields.password_confirmation, true),
    };
  },

  teamScheme: function () {
    const scheme = {
      [TeamFields.team_name]: validationRule(TeamFields.team_name, true),
    };

    if (fieldConditions.isTeamClassFieldEnabled.get()) {
      scheme[AdditionalRegistrationFields.class_id] = validationRule(AdditionalRegistrationFields.class_id, true);
    }

    if (fieldConditions.isTeamWaveFieldEnabled.get()) {
      scheme[AdditionalRegistrationFields.wave_id] = validationRule(AdditionalRegistrationFields.wave_id, true);
    }

    return scheme;
  },

  termsScheme: function () {
    return {
      [OtherFields.terms]: validationRule(OtherFields.terms, true),
    };
  },

  customFieldsScheme: function () {
    return distanceSelector.customFields.get().reduce((scheme, field) => {
      const isPaid = (field.values || []).some((item) => Number(item.price));

      if ((mainConditions.isTransferredRegistration.get() || mainConditions.isEditRegistration()) && isPaid) {
        return scheme;
      }

      if (field.is_required) {
        const customFieldFieldId = fieldIdUtils.customFieldsFieldId(null, field.id);
        scheme[customFieldFieldId] = customFieldValidationRule(field.type as CustomFieldTypes);
      }

      return scheme;
    }, {});
  },

  refundProtectScheme: function () {
    return {
      [AdditionalRegistrationFields.refund_protect]: validationRule(AdditionalRegistrationFields.refund_protect, true),
    };
  },
};
