import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { dataStore, distancesStore } from '../../stores';

const hasAny: IComputedValue<boolean> = computed(() => {
  return distancesStore.filteredValues.some((distance) => {
    return !!dataStore.lastPage(distance.id);
  });
});

export { hasAny };
