import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import styled, { css } from 'styled-components';

import { Hide, Show } from 'components/condition';

import { Svg } from '../Icons';
import { InputWrapComponent, StyledInput } from '../InputTools';
import { Calendar } from './Calendar';
import { CalendarModal } from './CalendarModal';
import { CalendarWrapper } from './styled';

enum Sizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type DateRangeType = {
  startDate?: Moment;
  endDate?: Moment;
};

type Props = {
  isRange: boolean;
  label?: string;
  name: string;
  onChange: (arg: { name: string; value: DateRangeType | Moment }) => void;
  minDate?: Moment;
  maxDate?: Moment;
  value: DateRangeType | Moment;
  clearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  size?: Sizes;
  className?: string;
  withInputStyles?: boolean;
  isWarning?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  requiredMark?: boolean;
  withIcon?: boolean;
  showCalendarAsModal?: boolean;
};

type State = {
  isOpened: boolean;
};

type InputWrapProps = {
  size?: Sizes;
  isOpened: boolean;
  hasError?: boolean;
  isWarning?: boolean;
};

const StyledInputWrap = styled(InputWrapComponent)<InputWrapProps>`
  .field-wrap {
    position: relative;

    .icon-box {
      position: absolute;
      top: 48%;
      right: 18px;
      display: flex;

      svg {
        cursor: pointer;
        transform: translate3D(0, -50%, 0) ${(props) => (props.isOpened ? 'rotate(180deg)' : '')};
        path {
          stroke: ${(props) => props.theme.main.colors.clay3};
          stroke-width: 2;
        }
      }
    }

    .calendar svg path {
      stroke-width: 1.5;
    }

    .close-icon {
      top: 50%;
    }

    &:hover {
      input {
        cursor: pointer;
      }

      ${(props) =>
        props.disabled &&
        css`
          .field-wrap svg path {
            stroke: ${props.theme.main.colors.clay4};
          }

          input {
            border: 2px solid ${props.theme.main.colors.clay4};
            color: ${props.theme.main.colors.clay4};
            background: transparent;
            cursor: not-allowed;
          }
        `}
    }
  }

  input {
    width: 100%;
  }

  ${(props) =>
    props.isOpened &&
    css`
      .field-wrap .icon-box svg path {
        stroke: ${props.theme.main.colors.white};
      }

      input {
        border: 2px solid transparent !important;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.main.colors.clay4};
      background: transparent;
      cursor: not-allowed;

      input::placeholder {
        color: ${props.theme.main.colors.clay4};
      }

      .field-wrap .icon-box svg {
        cursor: not-allowed;

        path {
          stroke: ${props.theme.main.colors.clay4};
        }
      }
    `}
`;

@onClickOutside
export class DatePickerInput extends Component<Props, State> {
  static contextType = TestAnchorContext;
  declare context: React.ContextType<typeof TestAnchorContext>;

  state = {
    isOpened: false,
  };

  onChange = (value: { startDate?: moment.Moment; endDate?: moment.Moment }): void => {
    const { onChange, isRange, name } = this.props;
    onChange({ name, value });

    if ((isRange && value.endDate) || (!isRange && !isEmpty(value))) {
      this.setState({ isOpened: false });
    }
  };

  handleCloseDropList() {
    this.setState({
      isOpened: false,
    });
  }

  handleOpenDropList() {
    const { isOpened } = this.state;

    this.setState({
      ...this.state,
      isOpened: !isOpened,
    });
  }

  handleClickOutside() {
    this.handleCloseDropList();
  }

  formatDate(date: Moment | undefined) {
    if (date) return date.format('D MMM YYYY');

    return '';
  }

  render() {
    const {
      isRange,
      minDate,
      maxDate,
      label,
      name,
      clearable,
      disabled,
      placeholder,
      size,
      value,
      className,
      errorMessage,
      isWarning,
      requiredMark,
      withIcon,
      showCalendarAsModal,
    } = this.props;
    const { isOpened } = this.state;

    const isValuePresented = !isEmpty(value);
    const singleDate = !isValuePresented ? undefined : moment(value as string);
    const { startDate, endDate } = (value as DateRangeType) || {};

    const getValue = () => {
      if (!isValuePresented) return '';

      return isRange ? `${this.formatDate(startDate)} - ${this.formatDate(endDate)}` : `${this.formatDate(singleDate)}`;
    };

    return (
      <StyledInputWrap
        size={size}
        isOpened={isOpened}
        disabled={disabled}
        name={name}
        label={label}
        requiredMark={requiredMark}
        errorMessage={errorMessage}
        className={className}
        hasError={Boolean(errorMessage)}
        isWarning={isWarning}
      >
        <div className='field-wrap'>
          <StyledInput
            {...testAnchors.field(this.context.container, name, TEST_ANCHORS.fieldStructure.root)}
            id={name}
            name={name}
            type='text'
            value={getValue()}
            disabled={disabled}
            placeholder={placeholder}
            onChange={() => {}}
            onClick={!disabled && this.handleOpenDropList.bind(this)}
            hasError={Boolean(errorMessage)}
            isWarning={isWarning}
            readOnly
            withUnit
          />

          <Show if={Boolean(!isOpened && isValuePresented && clearable)}>
            <Svg className='icon-box close-icon' name='CloseIcon' size={16} onClick={() => (disabled ? {} : this.onChange({}))} />
          </Show>

          <Hide if={Boolean((isValuePresented && clearable) || !withIcon)}>
            <Svg className='icon-box calendar' name='DateCalendar' size={16} onClick={this.handleOpenDropList.bind(this)} />
          </Hide>
        </div>

        <CalendarModal isOpened={isOpened && showCalendarAsModal} onClickOutside={this.handleOpenDropList.bind(this)}>
          <Calendar
            startDate={startDate}
            endDate={endDate}
            date={singleDate}
            isSingleDate={!isRange}
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.onChange as AnyFunction}
          />
        </CalendarModal>

        <Show if={isOpened && !showCalendarAsModal}>
          <CalendarWrapper>
            <Calendar
              startDate={startDate}
              endDate={endDate}
              date={singleDate}
              isSingleDate={!isRange}
              minDate={minDate}
              maxDate={maxDate}
              onChange={this.onChange as AnyFunction}
            />
          </CalendarWrapper>
        </Show>
      </StyledInputWrap>
    );
  }
}
