export const DEFAULT_LOCALE: availableLocales = 'en';
export const AVAILABLE_DYNAMIC_LOCALES: availableLocales[] = ['en', 'sv'];
export const LOCALES: availableLocales[] = [
  'en',
  'sv',
  'fr',
  'es',
  'de',
  'pt',
  'pl',
  'fi',
  'no',
  'el',
  // 'da'
];
export const LOCALES_STRING = LOCALES.join('|');
export const SELECT_DROP_MAX_HEIGHT = 400;
export const USER_PLATFORM_WEBSITE = 'RaceID Website';

export const RESOLUTIONS: { [K in resolutions]: number } = {
  // small: 576,
  // medium: 768,
  // large: 992,
  // xlarge: 1200,
  // xxlarge: 2560
  small: 576,
  medium: 768,
  large: 1200,
  xlarge: 1400,
  xxlarge: 2560,
};

export const FULL_LOCALES: { title: string; value: availableLocales }[] = [
  { title: 'Svenska', value: 'sv' },
  { title: 'Deutsch', value: 'de' },
  { title: 'English', value: 'en' },
  { title: 'Français', value: 'fr' },
  { title: 'Español', value: 'es' },
  { title: 'Norsk', value: 'no' },
  { title: 'Polski', value: 'pl' },
  { title: 'Português', value: 'pt' },
  { title: 'Suomi', value: 'fi' },
  { title: 'ελληνικά', value: 'el' },
  // { title: 'Dansk', value: 'da' },
];
