import classNames from 'classnames';
import { sortBy, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { JOINED } from 'src/constants';

import noAvatar from 'img/no_avatar.png';
import EditPencil from 'img/pencil-icon.svg';

import { Show } from 'components/condition';
import { LeaderIcon, EditUserIcon, AddMemberIcon, ResendInvitationIcon, PersonIcon } from 'components/icons';

import { Errors, toastService, t } from 'utils';

import { manageTeamStore } from 'stores';

import { TeamNameInput } from '../components';

import { PATCH_TEAM_NAME } from '../constants';

import { addMemberPopup, editMemberPopup, resendInvitation } from '../actions';

import { manageTeamService } from '../services';

type Props = {
  distanceId: number | null;
  match: Object;
  location: Object;
};

type State = {
  editTitleOpen: boolean;
  teamFields: Object;
};

@observer
class TeamManageContainer extends React.Component<Props, State> {
  fieldsErrorHandler: Errors;

  state = {
    editTitleOpen: false,
    teamFields: {
      name: '',
    },
  };

  constructor(props: Props) {
    super(props);

    this.fieldsErrorHandler = new Errors(PATCH_TEAM_NAME);
  }

  handleEditMember = (racerId: number) => {
    const { distanceId } = this.props;
    editMemberPopup(+distanceId!, racerId);
  };

  handleResendInvitation = async (memberId: number) => {
    const { distanceId } = this.props;
    resendInvitation(+distanceId!, memberId);
  };

  toggleEditTeamName = (field?: string) => {
    const { editTitleOpen, teamFields } = this.state;

    this.setState({
      editTitleOpen: !editTitleOpen,
      teamFields: {
        ...teamFields,
        ...{ name: field },
      },
    });
  };

  handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { teamFields } = this.state;

    this.setState({
      teamFields: {
        ...teamFields,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmitTeamName = async (teamId: number) => {
    const { distanceId } = this.props;
    const { teamFields } = this.state;

    const success = await manageTeamService.patchTeamName(teamFields, distanceId!, teamId);

    if (success) {
      this.toggleEditTeamName();
    }
  };

  handlePressEnter = (e: React.KeyboardEvent, teamId: number) => {
    const enterCode = 13;

    if (e.which === enterCode || e.keyCode === enterCode) {
      this.handleSubmitTeamName(teamId);
    }
  };

  hasError = (name: string) => {
    return this.fieldsErrorHandler.hasError(name);
  };

  renderMembers = (members: Array<AnyObject>): React.ReactNode => {
    const { isActiveUserEdit, isTeamOwner } = manageTeamStore;

    return sortBy(members, (member) => +member.is_owner)
      .reverse()
      .map((member) => (
        <div key={`${member.id}-member`} className='member-row'>
          <div className='member-avatar-wrap'>
            <div
              className='member-avatar'
              style={{
                backgroundImage: `url(${member.user && member.user.avatar}), url(${noAvatar})`,
              }}
            />
            {!!member.is_owner ? LeaderIcon : null}
          </div>
          <div className='member-info'>
            <div className='fullname'>{`${member.firstname} ${member.lastname}`}</div>
            <span
              className={classNames('status', {
                confirmed: member.team_status === JOINED,
              })}
            >
              {t(`manageTeam.${member.team_status}` as TranslationLockedKeys)}
            </span>
          </div>
          <div className='member-resend' title='Resend invitation'>
            <Show if={!member.is_owner && manageTeamStore.isTeamOwner && !manageTeamStore.readOnlyMode}>
              <span onClick={() => this.handleResendInvitation(member.id)}>{ResendInvitationIcon}</span>
            </Show>
          </div>
          <Show if={(manageTeamStore.isTeamOwner || manageTeamStore.isActiveUserEditing(member)) && !manageTeamStore.readOnlyMode}>
            <div className='member-edit'>
              <span onClick={() => this.handleEditMember(member.id)}>{EditUserIcon}</span>
            </div>
          </Show>
          <Show if={!(manageTeamStore.isTeamOwner || manageTeamStore.isActiveUserEditing(member)) || manageTeamStore.readOnlyMode}>
            <div className='member-edit'>
              <span onClick={() => this.handleEditMember(member.id)}>{PersonIcon}</span>
            </div>
          </Show>
        </div>
      ));
  };

  renderDeletedTeam = () => {
    return (
      <div className='team-manage-control'>
        <div className='team-title'>{t('manageTeam.deletedTeam')}</div>
      </div>
    );
  };

  render() {
    const { distanceId } = this.props;
    const { editTitleOpen, teamFields } = this.state;

    if (!distanceId) {
      return null;
    }

    const distanceModel: AnyObject = manageTeamStore.getNeededDistance(distanceId) || {};

    const team = ((distanceModel.value || {}).teams || [])[0] || {};
    const members = team.racers || [];

    if (isEmpty(members)) {
      return this.renderDeletedTeam();
    }

    const alreadyMaxMemberCount = distanceModel.value.max_members_quantity === members.length;

    return (
      <div className='team-manage-control'>
        {editTitleOpen ? (
          <div className='team-name-input'>
            <TeamNameInput
              name='name'
              value={teamFields.name}
              onChange={this.handleTeamNameChange}
              autoFocus
              errorMessage={this.hasError('name')}
              onClickOutside={this.toggleEditTeamName}
              onSubmit={() => this.handleSubmitTeamName(team.id)}
              onKeyPress={(e) => this.handlePressEnter(e, team.id)}
            />
          </div>
        ) : (
          <div className='team-title'>
            <div className='team-title-truncate'>
              <p>{team.name}</p>
            </div>
            <Show if={manageTeamStore.isTeamOwner && !manageTeamStore.readOnlyMode}>
              <div className='flex-btn'>
                <div className='edit-btn' onClick={() => this.toggleEditTeamName(team.name)}>
                  <img src={EditPencil} alt='Edit team name' />
                </div>
              </div>
            </Show>
          </div>
        )}
        <div className='member-list'>{this.renderMembers(members)}</div>
        <Show if={!alreadyMaxMemberCount && manageTeamStore.isTeamOwner && !manageTeamStore.readOnlyMode}>
          <div className='add-member' onClick={addMemberPopup}>
            {AddMemberIcon}
            {t('manageTeam.addTeamMemberButton')}
          </div>
        </Show>
      </div>
    );
  }
}

export { TeamManageContainer };
