import { Observer } from 'mobx-react';
import { displayEditControls } from 'modules/segments/selections';
import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';
import { Spinner } from 'components/spinner';
import { Table } from 'components/table';

import { calculatePercents, displayLengthByGoal, getUnitsByGoal } from 'utils';

import { VIEWPORT_OBSERVABLE } from '../constants';

import { onEditSegment } from '../actions';

import { segmentsStore } from '../stores';

import { TrackingSource } from './TrackingSource';

type Props = {};

const Wrapper = styled(Table.Wrapper)`
  .table-cell {
    display: flex;
    align-items: center;
    flex: 1;

    &.start {
      justify-content: start;
    }
    &.end {
      justify-content: flex-end;
    }

    &.small-box {
      min-width: 45px;
      max-width: 45px;
    }
    &.medium-box {
      min-width: 65px;
      max-width: 65px;
    }
    &.large-box {
      min-width: 165px;
      max-width: 165px;
    }

    &.number {
      min-width: 32px;
      max-width: 32px;
    }

    &.spaced-left {
      margin-left: 48px;
    }

    &.percents {
      min-width: 39px;
      max-width: 39px;
    }
    &.controls-box {
      min-width: 112px;
      max-width: 112px;
      margin-left: 16px;
    }

    &.hide-controls-box {
      min-width: 32px;
      max-width: 32px;
      margin-left: 16px;
    }
  }

  .table-head {
    padding: 0 40px 24px;

    span {
      font-size: 12px;
      line-height: 14px;
    }
    .head-middle-box {
      min-width: 85px;
      max-width: 85px;
    }
  }

  .table-body {
    .table-row {
      position: relative;
      padding: 24px 40px;
      border-top: 1px solid ${(props) => props.theme.main.colors.clay4};
      .tooltip-text {
        right: 171px;
      }
    }

    .date {
      color: ${(props) => props.theme.main.colors.clay2};
    }

    .source {
      justify-content: space-between;
    }
  }
`;

const Percents = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
`;

export const TableView: React.FC<Props> = () => {
  const renderRows = (isLoggingClosed = false): Array<React.ReactNode> => {
    const goalModel = segmentsStore.goalModel;
    if (!goalModel) return [];

    return segmentsStore.list.map((segment: any, index: number | any) => {
      const date = moment(segment.distance_logged_at).format('DD/MM/YY');
      const distanceLength = displayLengthByGoal(segment.distance_logged, segmentsStore.distance.goal, true);

      const percents = calculatePercents(segment.distance_logged, segmentsStore.distance?.goal?.goal_max_length);
      const percentsToDisplay = segmentsStore.distance?.goal?.goal_max_length ? `${percents}%` : '';

      return (
        <Table.Row key={`segment-table:${segment.id}`}>
          <Table.Cell weight='bold' className='number left' value={index + 1} />
          <Table.Cell className='date' value={date} />
          <Table.Cell weight='bold' className='time medium-box end spaced-left' value={segment.time_result} />
          <Table.Cell weight='bold' className='length small-box end spaced-left' value={distanceLength} />
          <Table.Cell
            weight='bold'
            className='length small-box end spaced-left percents'
            value={
              <Percents variant='u3' weight='medium'>
                {percentsToDisplay}
              </Percents>
            }
          />
          <Table.Cell
            className={`source ${isLoggingClosed ? 'hide-controls-box' : 'controls-box'} end spaced-left`}
            value={<TrackingSource link={segment.activity_link} segmentId={segment.id} onEdit={onEditSegment(segment.id)} />}
          />
        </Table.Row>
      );
    });
  };

  return (
    <Observer>
      {() => {
        const hide = !displayEditControls.get();

        return (
          <Wrapper className='segments-table'>
            <Table.Head>
              <Table.Cell weight='bold' className='date' value='profile.registeredTable.head.segments' />
              <Table.Cell weight='bold' className='time medium-box end spaced-left' value='profile.registeredTable.head.time' />
              <Table.Cell weight='bold' className='length small-box end spaced-left' value={getUnitsByGoal(segmentsStore.distance?.goal)} />
              <Table.Cell
                weight='bold'
                className={`source ${hide ? 'head-middle-box' : 'large-box'} end spaced-left`}
                value='results.activity_tracker'
              />
            </Table.Head>
            <Table.Body>
              {renderRows(hide)}
              <Show if={segmentsStore.hasMore}>
                <div id={VIEWPORT_OBSERVABLE}>
                  <Spinner type='cubesSpinner' />
                </div>
              </Show>
            </Table.Body>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
