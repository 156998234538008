import { Observer } from 'mobx-react';
import * as React from 'react';

import { distancePlaceColumn } from '../../derivations';

function PlaceColumn({ distanceId }: { distanceId: number | nil }) {
  if (!distanceId) {
    return null;
  }

  const generator = distancePlaceColumn(distanceId);

  return (
    <Observer>
      {() => {
        const placeCol = generator.get();

        if (!placeCol) {
          return null;
        }

        return <div className='tooltip-text'>{placeCol}</div>;
      }}
    </Observer>
  );
}

export { PlaceColumn };
