import * as React from 'react';
import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants/general';

const editorialBody1 = css`
  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  margin: 0;

  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

const editorialBody2 = css`
  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0;

  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const body1 = css`
  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  margin: 0;

  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 20px;
    line-height: 32px;
  }
`;
const body2 = css`
  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const EditorialBody1 = styled.p`
  ${editorialBody1}
`;

const Body1 = styled.p`
  ${body1}
`;

const EditorialBody2 = styled.p`
  ${editorialBody2}
`;

const Body2 = styled.p`
  ${body2}
`;

export const TagLine = styled.p`
  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin: 0;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.colors.clay1};
`;

type Props = {
  variant: 'body1' | 'body2' | 'editorial1' | 'editorial2';
  className?: string;
  children?: React.ReactNode | JSX.Element;
};

enum Variants {
  editorial1 = 'editorial1',
  editorial2 = 'editorial2',
  body1 = 'body1',
  body2 = 'body2',
}

const components = {
  [Variants.editorial1]: EditorialBody1,
  [Variants.editorial2]: EditorialBody2,
  [Variants.body1]: Body1,
  [Variants.body2]: Body2,
};

export const BodyText: React.FC<Props> = (props) => {
  const { variant = Variants.body1, children, className } = props;
  const Component = components[variant];

  return <Component className={className}>{children}</Component>;
};

export const BodyTextStyles = {
  editorialBody1,
  editorialBody2,
  body1,
  body2,
};
