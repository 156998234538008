import React, { FC } from 'react';

import { ScrollWrap } from './styled';

type Props = {
  mobileInvertSize?: number;
  children: React.ReactNode;
  className?: string;
};

export const HorizontalScroll: FC<Props> = ({ mobileInvertSize = 0, children, className }) => {
  return (
    <ScrollWrap invertSize={mobileInvertSize} className={className}>
      <div className='scroll-block'>{children}</div>
    </ScrollWrap>
  );
};
