import { Observer } from 'mobx-react';
import { goalsStore } from 'modules/results/stores';
import * as React from 'react';

import { Distance } from 'src/models/distance';
import { RightSideHeader } from 'src/modules/racersTable/components';

import { generateIsClasses, generateIsUnion, generateIsWaves, generateIsSquads, generateIsCheckpoints } from '../../derivations';

type Props = {
  distance: Distance;
};

export const RightHeader: React.FC<Props> = (props: Props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const isWaves = generateIsWaves(distanceId);
  const isClasses = generateIsClasses(distanceId);
  const isSquads = generateIsSquads(distanceId);
  const isUnion = generateIsUnion(distanceId);
  const isCheckpoints = generateIsCheckpoints(distanceId);
  const isAltitude = goalsStore.findByDistance(+distanceId!)?.isGoalType('altitude');

  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isWaves.get(),
          class: isClasses.get(),
          club: isUnion.get(),
          squad: isSquads.get(),
          time: true,
          diff: !distance.isMode('cumulative'),
          goalDistance: distance.isMode('cumulative'),
          tracker: !distance.isMode('classic'),
          checkpoint: isCheckpoints.get(),
        };

        return <RightSideHeader columnsActive={columnsActive} isAltitude={isAltitude} />;
      }}
    </Observer>
  );
};
