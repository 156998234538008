import { Observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { ShowMobile } from 'src/styledComponents/Conditions';
import { history, isAndroidDevice, t } from 'src/utils';

import { RaceAppIcon } from 'img/race_logo';

import { Show } from 'components/condition';

import { dynamicLink } from 'services';

import { Svg } from '../../styledComponents/Icons';
import { StyledCloseBtn, StyledImg, StyledText, StyledTitle, SubBox, Wrapper } from './styled';

export const NativeAppBanner = () => {
  const bannerStore = localStorage.getItem('installAppBanner') !== 'hide';
  const [isVisible, setVisible] = useState(bannerStore);
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    const urlParts = history.location.pathname.split('/');
    const isIframe = Boolean(urlParts.find((item) => item === 'iframe'));
    setIsAndroid(isAndroidDevice());
    if (isIframe) {
      localStorage.setItem('installAppBanner', 'hide');
      setVisible(false);
    }
  }, []);

  const handleOpenApp = async () => {
    const { params } = matchPath(history.location.pathname, ROUTER_WITH_LOCALES.RACE) || {};
    if (params?.id && !isAndroid) {
      window.location = await dynamicLink.generateDynamicRaceLink(Number(params.id));
    } else {
      window.location = await dynamicLink.generateDynamicLink();
    }
  };

  const handleCloseBanner = () => {
    localStorage.setItem('installAppBanner', 'hide');
    setVisible(false);
  };

  return (
    <Observer>
      {() => (
        <>
          <ShowMobile>
            <Show if={isVisible}>
              <Wrapper>
                <SubBox>
                  <StyledImg className='race-logo' src={RaceAppIcon} alt='race-logo' />
                  <div className='banner-app-info'>
                    <StyledTitle variant='u2' weight='bold'>
                      {t('persistentSnack.appName')}
                    </StyledTitle>
                    <StyledText variant='body2'>{t('persistentSnack.invitationText')} </StyledText>
                  </div>
                </SubBox>
                <SubBox>
                  <Button size='medium' variant='primary' onClick={handleOpenApp}>
                    {t('smartBanner.view')}
                  </Button>
                  <StyledCloseBtn onClick={handleCloseBanner}>
                    <Svg name='Cross' size={22} />
                  </StyledCloseBtn>
                </SubBox>
              </Wrapper>
            </Show>
          </ShowMobile>
        </>
      )}
    </Observer>
  );
};
