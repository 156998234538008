import { ROUTER } from 'src/constants';

import { history, encodeQuery, fireToast, t } from 'utils';

import { actionMenuStore } from '../../stores';

import { close } from './display';

function navigateToRace() {
  const distance = actionMenuStore.distance;

  if (!distance) {
    return;
  }

  const id = distance.race_id;

  close();

  if (!distance.published) {
    fireToast(t.staticAsString('toast.message.unpublishedRace'), 'warning', t.staticAsString('toast.title.unpublishedRace'), 'top-right');
  } else {
    history.push(encodeQuery.generatePath(ROUTER.RACE_ABOUT_US, { id }));
  }
}

export { navigateToRace };
