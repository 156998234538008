import styled, { css } from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/topographic-pattern.svg';

import { ButtonBlock, Language, LanguageItem, LanguagesBlock } from '.';

export const SelectorWrapper = styled.div<{ isMobile: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  background-image: url('${PatternImage}');
  background-color: white;

  height: 100vh;
  width: 100vw;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
  }

  ${(props) =>
    props.isMobile &&
    css`
      padding: 4px 0 20px;

      ${LanguagesBlock} {
        padding-top: 40px;
        grid-template-columns: auto auto;
        max-width: 375px;
        margin: 0 auto;
      }

      ${ButtonBlock} {
        padding: 20px;
        padding-bottom: 40px;
      }

      ${LanguageItem} {
        padding: 16px 24px;
        margin: 12px 0;

        ${Language} {
          padding-left: 8px;
        }
      }
    `}
`;
