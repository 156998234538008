import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const MuscleSorePath = (
  <>
    <path
      id='Vector'
      d='M16.5384 6.82865C15.8301 7.27015 14.3098 8 12 8C8.68864 8 8.5 9 3.5 9L3.5 2L17.4937 2.00001C20.1276 2.00001 22.103 4.42499 21.5865 7.02374L18.5 19L21.5388 19.6078C22.6792 19.8358 23.5 20.8371 23.5 22L14.5 22C14.5 21 14.5 19 15.4385 19C15.2208 14.433 14.5 15.1696 14.5 10.5C14.5 8.68617 15.816 7.39873 16.5384 6.82865Z'
      fill='currentColor'
    />
    <path id='Vector 381' d='M2.5 15L8.5 23L7.5 18H12.5L6.5 10L7.5 15H2.5Z' fill='currentColor' />
  </>
);

export const MuscleSore = (props: IconProps) => <SvgIcon path={MuscleSorePath} {...props} />;
