import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

type AdditionalInfoProps = {
  name: TranslationLockedKeys;
  value?: string;
};

type Props = {
  union?: string;
  classTitle?: string;
  className?: string;
  wave?: string;
  squad?: string;
  withBibs?: boolean;
  isPlace?: boolean;
};

const NoSliderWrap = styled.div`
  overflow-y: hidden;
  height: 34px;
`;

const ScrollableWrapper = styled.div`
  overflow-x: scroll;
  padding-bottom: 15px;
`;

const ScrollableBody = styled.div<{ withBibs: boolean; isPlace: boolean }>`
  display: flex;
  padding-left: ${(props) => (props.withBibs || props.isPlace ? 62 : 20)}px;
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
  white-space: nowrap;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-right: 16px;
`;

const AdditionalInfo: React.FC<AdditionalInfoProps> = (props) => {
  const { name, value } = props;

  if (!value) return null;

  return (
    <InfoWrapper>
      <StyledUtility variant='u3' weight='bold'>
        {t(name)}:
      </StyledUtility>
      <StyledUtility variant='u3' weight='medium'>
        {value}
      </StyledUtility>
    </InfoWrapper>
  );
};

export const MobileAdditionalInfo: React.FC<Props> = (props) => {
  const { classTitle, union, squad, wave, withBibs, isPlace, className } = props;

  if (!classTitle && !union && !squad && !wave) return null;

  return (
    <NoSliderWrap className={className}>
      <ScrollableWrapper>
        <ScrollableBody withBibs={Boolean(withBibs)} isPlace={Boolean(isPlace)}>
          <AdditionalInfo name='registration.class' value={classTitle} />
          <AdditionalInfo name='results.union' value={union} />
          <AdditionalInfo name='registration.waveHeader' value={wave} />
          <AdditionalInfo name='results.team' value={squad} />
        </ScrollableBody>
      </ScrollableWrapper>
    </NoSliderWrap>
  );
};
