import { Observer } from 'mobx-react';
import * as React from 'react';

import { RightSideHeader } from 'src/modules/racersTable/components';

import { isAnyDistanceHasClasses, isAnyDistanceHasSquads, isAnyDistanceHasUnion, isAnyDistanceHasWaves } from '../../derivations';

export const RightHeader = () => {
  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isAnyDistanceHasWaves.get(),
          class: isAnyDistanceHasClasses.get(),
          club: isAnyDistanceHasUnion.get(),
          squad: isAnyDistanceHasSquads.get(),
        };

        return <RightSideHeader columnsActive={columnsActive} />;
      }}
    </Observer>
  );
};
