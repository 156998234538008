import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { searchRaceValues } from 'modules/search/selectors';
import { focusedStore, racesAutocompleteOpenStore, recentRacesStore, recomendedRacesStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import * as React from 'react';
import styled from 'styled-components';

import { Races } from './Races';
import { RacesOptionsLoader } from './RacesOptionsLoader';

const Container = styled.div<{ open: boolean }>`
  padding-bottom: 24px;
  padding-top: 24px;
  background-color: white;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const RacesMenuMobile: React.FC = () => {
  return (
    <Observer>
      {() => {
        const raceValue = searchRaceValues();
        const isRecentValues = !raceValue && !isEmpty(recentRacesStore.value);
        const isRecomendedValues = !raceValue && !isEmpty(recomendedRacesStore.value);
        const isOpen = Boolean(racesAutocompleteOpenStore.value) && focusedStore.value === FieldNames.raceName;

        return (
          <Container open={isOpen}>
            <RacesOptionsLoader isRecentValues={isRecentValues} isRecomendedValues={isRecomendedValues}>
              <Races isRecentValues={isRecentValues} isRecomendedValues={isRecomendedValues} />
            </RacesOptionsLoader>
          </Container>
        );
      }}
    </Observer>
  );
};
