import { keys } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { Data } from '../types';

class DisabledCustomFields {
  /**
   * k in string is a field_id
   */
  @observable
  value: { [k in string]: boolean } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  init(disabledCustomFieldsStore: Data.Form.CustomFields) {
    this.value = keys(disabledCustomFieldsStore).reduce(
      (acc, fieldId) => ({
        ...acc,
        [fieldId]: true,
      }),
      {},
    );
  }

  @action
  add(disabledCustomFields: Data.Form.CustomFields) {
    this.value = {
      ...this.value,
      ...keys(disabledCustomFields).reduce(
        (acc, fieldId) => ({
          ...acc,
          [fieldId]: true,
        }),
        {},
      ),
    };
  }

  @action
  addCustomFieldsById(disabledCustomFieldsId: string[]) {
    this.value = {
      ...this.value,
      ...disabledCustomFieldsId.reduce(
        (acc, fieldId) => ({
          ...acc,
          [fieldId]: true,
        }),
        {},
      ),
    };
  }

  @action
  clean(): void {
    this.value = {};
  }

  @computed
  get disabledFieldIds() {
    return keys(this.value);
  }

  /**
   * @description
   * check is cusotm field is disabled checking by field id
   * group.0.fields.custom_fiield_1232
   *
   */
  isDisabledByFieldId(fieldId: string): boolean {
    return this.value[fieldId];
  }
}

export { DisabledCustomFields };
