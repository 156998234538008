import * as React from 'react';

export const CrossClose = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Icon/Cross-Close'>
      <path
        id='Vector'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 14.3741L19.626 22L22 19.626L14.3741 12L22 4.374L19.626 2L12 9.62602L4.374 2L2 4.374L9.62602 12L2 19.626L4.374 22L12 14.3741Z'
        fill='white'
        fillOpacity='0.85'
      />
    </g>
  </svg>
);
