import { observable, computed, action, makeObservable } from 'mobx';

import { Distance as DistanceModel } from 'models/distance';

import { Loadable } from 'stores/loadable';

class Distances extends Loadable<DistanceType, FiltersType> {
  static defaultFilters: FiltersType = {
    limit: 9999,
    with: 'registration_fields',
  };

  @observable selectedDistanceId: number | null = null;

  @observable prevSelectedDistanceId: number | null = null;

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.filters;
  }

  @computed
  get modelValues(): Array<DistanceModel> {
    return (this.values || []).map((distance) => new DistanceModel(distance));
  }

  @computed
  get selectedDistance(): DistanceModel | undefined {
    return this.modelValues.find((el) => el.value.id === this.selectedDistanceId);
  }

  @computed
  get distancesToRender(): Object {
    return this.modelValues.map((el) => ({
      ...el.value,
      title: el.value.name,
    }));
  }

  @action
  setSelectedDistance(id: number | null) {
    this.prevSelectedDistanceId = this.selectedDistanceId || this.prevSelectedDistanceId;
    this.selectedDistanceId = id;
  }

  @action
  clear() {
    this.clearData();
    this.setSelectedDistance(null);
  }
}

export { Distances, Distances as DistancesStore };
