import { matchPath } from 'react-router-dom';

import { DEFAULT_LOCALE, LOCALES } from 'src/constants';

import { history, browserLanguage } from 'utils';

function fetchDefault(): availableLocales {
  return [urlLocale(), savedLocale(), browserLocale(), DEFAULT_LOCALE].find((locale) => LOCALES.includes(locale));
}

function savedLocale() {
  return localStorage.getItem('locale') || null;
}

function browserLocale() {
  return browserLanguage() || null;
}

function urlLocale() {
  const match = matchPath(history.location.pathname, '/:locale');

  return match?.params?.locale || null;
}

export { fetchDefault };
