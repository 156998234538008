import { pickBy } from 'lodash';

import { CustomFieldTypes } from '../../constants';

import { distanceSelector } from '../../selectors/mappedData';
import { Data } from '../../types';
import { fieldIdUtils } from '../fieldIdUtils';

function findCustomField(id: number | nil): CustomFields | null {
  if (!id) {
    return null;
  }

  return distanceSelector.expandedCustomFields.get()[id] || null;
}

function parseId(customFieldKey: nil | string): number | nil {
  if (!customFieldKey) {
    return null;
  }

  const idMatch = customFieldKey.split(fieldIdUtils.staticKeys.customFieldValueIdPrefix);

  return parseInt(idMatch?.[1]) || null;
}

/**
 * @description
 * Filter object of form CustomFields by provided types
 * so for example only custom fields with type time is left
 */
export function filterCFByType(data: Data.Form.CustomFields | nil, targetCustomFieldTypes: CustomFieldTypes[]): Data.Form.CustomFields {
  if (!data) {
    return {};
  }

  return pickBy(data, (_value, cfkey) => {
    const id = parseId(cfkey);
    const customField = findCustomField(id);

    if (!id || !customField) {
      return false;
    }

    return targetCustomFieldTypes.includes(customField.type as CustomFieldTypes);
  });
}
