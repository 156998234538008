import { borderStore } from 'src/modules/racersTable/stores';
import { form } from 'src/stores';
import { footerVisibility } from 'src/utils';

import { FORM_FILTERS_ID } from '../constants';

import { getRaceId } from '../utils';

import { userModal, initializer } from '../actions';

import {
  classesStore,
  wavesStore,
  checkpointsStore,
  distancesStore,
  registrationFieldsStore,
  disposers,
  loaderDisposers,
  isFindInterceptionHandled,
  lifeStore,
  requestsVersionStore,
  goalsStore,
  timeLeadersStore,
  dataStore,
  isAppInitializedStore,
} from '../stores';

import { currentDistance } from '../derivations';
import { observeFormFilters, observeHasMore, observeFindShortcuts } from '../reactions';
import { updateHistoryBasedOnFilters, initFiltersAndDistance } from './filters';
import { loadBaseData } from './loadBaseData';

async function mount(id: string) {
  lifeStore.startLife(id);
  await lifeStore.waitInLine(id);

  if (lifeStore.isBeingKilled(id)) {
    lifeStore.endLife(id);
    return unmount(id);
  }

  isFindInterceptionHandled.on();
  observeFindShortcuts();

  requestsVersionStore.generate();

  const raceId = +getRaceId()!;

  if (!raceId) {
    return;
  }

  // Init base data
  await loadBaseData(raceId);
  await form.registerForm(FORM_FILTERS_ID);

  if (lifeStore.isBeingKilled(id)) {
    lifeStore.endLife(id);
    return unmount(id);
  }

  // Init main data and ui data
  initFiltersAndDistance();

  initializer.initDistance(currentDistance.id.get() || null, true);

  observeFormFilters();

  updateHistoryBasedOnFilters();
  observeHasMore();

  isAppInitializedStore.on();
  lifeStore.endLife(id);
}

function unmount(id: string) {
  lifeStore.kill(id);

  disposers.disposeAll();
  loaderDisposers.disposeAll();

  classesStore.cleanValues();
  wavesStore.cleanValues();
  checkpointsStore.cleanValues();
  registrationFieldsStore.cleanValues();
  distancesStore.cleanValues();
  goalsStore.cleanValues();
  borderStore.clear();

  timeLeadersStore.clean();
  dataStore.clean();

  userModal.close();

  form.clean(FORM_FILTERS_ID);
  footerVisibility.show();
  isAppInitializedStore.off();
}

export { mount, unmount };
