import moment, { Moment } from 'moment';

const getBlankDaysBeforeFirstDay = (firstDayOfMonth: Moment, firstDayOfWeek: number): number => {
  const weekDayDiff = firstDayOfMonth.day() - firstDayOfWeek;
  return (weekDayDiff + 7) % 7;
};

// firstDayOfWeek: 0 - Sunday, 1 - Monday ...
export const getNumberOfCalendarMonthWeeks = (month: Moment, firstDayOfWeek: number = moment.localeData().firstDayOfWeek()): number => {
  const firstDayOfMonth = month.clone().startOf('month');
  const numBlankDays = getBlankDaysBeforeFirstDay(firstDayOfMonth, firstDayOfWeek);
  return Math.round((numBlankDays + month.daysInMonth()) / 7);
};
