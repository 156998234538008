import { toJS } from 'mobx';

import type { LResult } from '../../types';
import { findSplit } from './findSplit';

/**
 * @description
 * If checkpointId is prenset
 *  fetch found split.result_diff (which is split.finish_time - result.start_time)
 * else
 *  fetch result.time_result
 */
function fetchBEDiff(result: LResult, checkpointId?: number | nil) {
  if (checkpointId) {
    const split = findSplit(result, checkpointId);
    return split?.time_result_ms;
  } else {
    return result.time_result_ms ? result.time_result_ms : null;
  }
}

export { fetchBEDiff };
