import { isNumber } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Avatar } from 'src/styledComponents/Avatar';
import { Divider } from 'src/styledComponents/Divider';
import { Svg } from 'src/styledComponents/Icons';
import noAvatar from 'src/styledComponents/Icons/Files/DefaultAvatar.svg';
import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { t } from 'utils';

import { windowSize } from 'stores';

import { findCountryCode } from '../../utils/shared';

type Props = {
  value: RacersType;
  withUnion: boolean;
  getGender: AnyFunction;
  getWave: AnyFunction;
  getClass: AnyFunction;
  getUserAge: AnyFunction;
  showAvatarBorder?: boolean;
};
const Wrapper = styled.div<{ showAvatarBorder?: boolean }>`
  .user-info-modal-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 48px 20px 40px;

    .user-info-avatar {
      border-radius: 50%;
      overflow: hidden;
      margin-right: 16px;
      border: ${(props) => (props.showAvatarBorder ? 'border: 1.5px solid #272E34' : 'none')};
    }

    .flag {
      height: 15px;
    }
    .flag,
    .place {
      margin-right: 8px;
    }

    .place {
      stroke-width: 1.5px;
      path {
        stroke: ${(props) => props.theme.main.colors.clay1};
      }
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;

      span {
        color: ${(props) => props.theme.main.colors.clay0};
      }
    }

    .user-info-name {
      padding-bottom: 10px;
    }
  }

  .user-modal-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${RESOLUTIONS.large}px) {
    .user-info-modal-top,
    .user-info-modal-bottom {
      justify-content: flex-start;
    }

    .user-info-modal-top {
      align-items: center;
      padding: 0;
      padding-bottom: 48px;
      border: 0 solid transparent;
    }

    .user-modal-list {
      flex-direction: row;

      .user-modal-list-item {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          border-right: 1px solid ${(props) => props.theme.main.colors.clay4};
          padding-right: 32px;
        }
        &:not(:first-child) {
          padding-left: 24px;
        }

        span:first-child {
          padding-bottom: 4px;
        }
      }
    }
  }

  @media (max-width: ${RESOLUTIONS.large}px) {
    .user-info-modal-bottom {
      padding: 0 20px;

      .user-divider {
        margin: 8px 0;
        height: 1px;
        background-color: ${(props) => props.theme.main.colors.clay4};
      }

      .user-modal-list {
        display: flex;
        margin-top: 32px;

        .user-modal-list-item {
          padding: 0;
          border: none;

          &:not(:last-child) {
            padding-bottom: 24px;
          }

          span:first-child {
            padding-right: 8px;
          }
        }
      }
    }
  }
`;

const UserInfo = (props: Props) => {
  const { value, withUnion, showAvatarBorder } = props;
  const { getGender, getWave, getClass, getUserAge } = props;
  const isMobileAndTablet = windowSize.isLessThan('large');

  if (!value) {
    return null;
  }

  const getCountryCode = () => {
    if (value.nationality_id) {
      return findCountryCode(value.nationality_id);
    }

    if (value.country_id) {
      return findCountryCode(value.country_id);
    }

    return '';
  };

  const addColonIfMobile = () => (isMobileAndTablet ? ':' : '');

  const avatar = value?.user?.avatar;
  const countryCode = getCountryCode();

  return (
    <Wrapper showAvatarBorder={showAvatarBorder}>
      <div className='user-info-modal-top'>
        <div className='user-info-avatar'>
          <Avatar img={avatar || noAvatar} size={48} />
        </div>
        <div className='user-info-wrap'>
          <p className='user-info-name'>
            <Show if={Boolean(countryCode)}>
              <span className={`result-flag flag flag-icon flag-icon-${countryCode}`} />
            </Show>
            <Utility variant='u2' weight='bold' className='user-fullname' title={value.full_name}>
              {value.full_name}
            </Utility>
          </p>
          <p className='user-info-city'>
            {value.city && <Svg name='Location' size={20} className='place' />}
            <Utility variant='u3' weight='medium'>
              {value.city}
            </Utility>
          </p>
        </div>
      </div>

      <div className='user-info-modal-bottom'>
        <Show if={isMobileAndTablet}>
          <Divider className='user-divider' />
        </Show>

        <ul className='user-modal-list'>
          <Show if={Boolean(value.birthday)}>
            <li className='user-modal-list-item'>
              <Utility variant='u4' weight='bold' className='title'>
                {t('profile.age')}
                {addColonIfMobile()}
              </Utility>
              <Utility variant='u4' weight='medium' className='info'>
                {getUserAge(value.birthday)}
              </Utility>
            </li>
          </Show>

          <Show if={isNumber(value.gender)}>
            <li className='user-modal-list-item'>
              <Utility variant='u4' weight='bold' className='title'>
                {t('settings.gender')}
                {addColonIfMobile()}
              </Utility>
              <Utility variant='u4' weight='medium' className='info'>
                {getGender()}
              </Utility>
            </li>
          </Show>

          <Show if={Boolean(value.class_id)}>
            <li className='user-modal-list-item'>
              <Utility variant='u4' weight='bold' className='title'>
                {t('registration.class')}
                {addColonIfMobile()}
              </Utility>
              <Utility variant='u4' weight='medium' className='info'>
                {(getClass(value.class_id) || {}).title}
              </Utility>
            </li>
          </Show>

          <Show if={Boolean(value.wave_id)}>
            <li className='user-modal-list-item'>
              <Utility variant='u4' weight='bold' className='title'>
                {t('registration.wave')}
                {addColonIfMobile()}
              </Utility>
              <Utility variant='u4' weight='medium' className='info'>
                {getWave()}
              </Utility>
            </li>
          </Show>

          <Show if={Boolean(value.union) && withUnion}>
            <li className='user-modal-list-item'>
              <Utility variant='u4' weight='bold' className='title'>
                {t('results.union')}
                {addColonIfMobile()}
              </Utility>
              <Utility variant='u4' weight='medium' className='info'>
                {value.union}
              </Utility>
            </li>
          </Show>

          <Show if={Boolean(value.discipline)}>
            <li className='user-modal-list-item'>
              <Utility variant='u4' weight='bold' className='title'>
                {t('registration.relay')}
                {addColonIfMobile()}
              </Utility>
              <Utility variant='u4' weight='medium' className='info'>
                {value.discipline?.title}
              </Utility>
            </li>
          </Show>
        </ul>
      </div>
    </Wrapper>
  );
};

export { UserInfo };
