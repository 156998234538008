import { computed } from 'mobx';

import { distancesStore } from '../../stores';

import { currentDistance } from '../currentDistance';

const isGenderFilterAvailable = computed(() => {
  if (currentDistance.isTeam.get()) {
    return false;
  }

  if (!currentDistance.id.get()) {
    return Boolean(distancesStore.filteredModelValues.some((distance) => distance.isSingle()));
  }

  return true;
});

export { isGenderFilterAvailable };
