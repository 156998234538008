import remove from 'lodash/remove';
import { observable, computed, action, toJS, makeObservable } from 'mobx';

import { Race as RaceModel } from 'models/race';

import { Loadable } from 'stores/loadable';

class Wishlist extends Loadable<RacesType, FiltersType> {
  declare filters: FiltersType;

  constructor() {
    super();
    makeObservable(this);
    this.loadWishListFromStorage();
    this.filters = {
      limit: 9999,
      with: 'distances',
    };
  }

  @observable
  registeredValues: any[] = [];

  @computed
  get modelValues(): Array<RaceModel> {
    return this.values.map((race) => new RaceModel(race));
  }

  @computed
  get modelAppliedRaces(): Array<RaceModel> {
    return this.registeredValues.map((race) => new RaceModel(race));
  }

  @action
  addRegisteredValues(values: Array<Object>) {
    this.registeredValues = values;
  }

  @action
  getAllValues() {
    return toJS(this.values);
  }

  @action
  removeFromWishList(id: number): void {
    remove(this.values, (item) => item.id === id);
  }

  @action
  addToWishList(id: number): void {
    this.values.push({ id } as any);
  }

  @action
  updateWishListInStorage(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    localStorage.setItem('user', JSON.stringify({ ...user, wishlist: this.values }));
  }

  @action
  loadWishListFromStorage(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.values = user?.wishlist || [];
  }
}

export { Wishlist };
export default new Wishlist();
