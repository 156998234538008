import { Display } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import LeahPatel from 'src/assets/images/miles/LeahPatel.png';
import MarcusKim from 'src/assets/images/miles/MarcusKim.png';

import { FannyImg } from 'img/aboutUs';

import { TestimonialCard } from './TestimonialCard';

const TRANSITION_SPEED = 700;

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;
  width: 100%;
  overflow: hidden;
  .title {
    color: ${(props) => props.theme.miles.colors.dark.alpha['900']};
  }
`;

const Content = styled.div`
  padding: 0 16px;
  width: 100%;
  .swiper {
    width: 100%;
  }
  .swiper-slide {
    .slide {
      transition: transform ${TRANSITION_SPEED}ms ease;
      transform: scale(0.77);
    }
  }
  .swiper-slide-active {
    .slide {
      transform: scale(1);
    }
  }
`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const Testimonials: React.FC<Props> = (props) => {
  return (
    <>
      <BlockWrapper {...props} id='Testimonials'>
        <Display className='title' weight='regular' size='xs' style={{ margin: '0 0 24px' }}>
          What Our Customers Say
        </Display>
        <Content>
          <Swiper
            speed={TRANSITION_SPEED}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
            spaceBetween={16}
            centeredSlides={true}
            loop={true}
            breakpoints={{
              640: {
                slidesPerView: 1.5,
                spaceBetween: -10,
              },
              860: {
                slidesPerView: 1.9,
                spaceBetween: -10,
              },
              1100: {
                slidesPerView: 2.3,
                spaceBetween: -40,
              },
              2400: {
                slidesPerView: 3,
                spaceBetween: -70,
              },
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <TestimonialCard
                className='slide'
                avatar={LeahPatel}
                rating={5}
                userName={'Leah Patel'}
                userComment={
                  'Since starting RaceID Miles, my running performance has improved significantly. The tailored program and expert support make all the difference!'
                }
              />
            </SwiperSlide>

            <SwiperSlide>
              <TestimonialCard
                className='slide'
                avatar={FannyImg}
                rating={5}
                userName={'Fanny Kuhn'}
                userComment={
                  'Miles RacePlan was an easy and satisfying way for me to improve my race time and it made training so much easier!'
                }
              />
            </SwiperSlide>

            <SwiperSlide>
              <TestimonialCard
                className='slide'
                avatar={MarcusKim}
                rating={5}
                userName={'Marcus Kim'}
                userComment={
                  "I love the flexibility and personalization of RaceID Miles. It's challenging yet achievable, and I'm making steady progress towards my goals."
                }
              />
            </SwiperSlide>

            {/* Doubled number of slides to make possible slide them in round; P.S required for 'loop' prop */}
            <SwiperSlide>
              <TestimonialCard
                className='slide'
                avatar={LeahPatel}
                rating={5}
                userName={'Leah Patel'}
                userComment={
                  'Since starting RaceID Miles, my running performance has improved significantly. The tailored program and expert support make all the difference!'
                }
              />
            </SwiperSlide>

            <SwiperSlide>
              <TestimonialCard
                className='slide'
                avatar={FannyImg}
                rating={5}
                userName={'Fanny Kuhn'}
                userComment={
                  'Miles RacePlan was an easy and satisfying way for me to improve my race time and it made training so much easier!'
                }
              />
            </SwiperSlide>

            <SwiperSlide>
              <TestimonialCard
                className='slide'
                avatar={MarcusKim}
                rating={5}
                userName={'Marcus Kim'}
                userComment={
                  "I love the flexibility and personalization of RaceID Miles. It's challenging yet achievable, and I'm making steady progress towards my goals."
                }
              />
            </SwiperSlide>
          </Swiper>
        </Content>
      </BlockWrapper>
    </>
  );
};
