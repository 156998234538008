import { omit } from 'lodash';

import { AdditionalRegistrationFields, RegistrationFields } from '../../constants';

import { Data } from '../../types';

function omitWave(data: Data.Form.Racer): Omit<Data.Form.Racer, AdditionalRegistrationFields.wave_id> {
  return omit(data, [AdditionalRegistrationFields.wave_id]);
}

function omitClass(data: Data.Form.Racer): Omit<Data.Form.Racer, AdditionalRegistrationFields.class_id> {
  return omit(data, [AdditionalRegistrationFields.class_id]);
}

function omitDiscipline(data: Data.Form.Racer): Omit<Data.Form.Racer, AdditionalRegistrationFields.discipline_id> {
  return omit(data, [AdditionalRegistrationFields.discipline_id]);
}

function omitCoupon(data: Data.Form.Racer): Omit<Data.Form.Racer, AdditionalRegistrationFields.coupon_code> {
  return omit(data, [AdditionalRegistrationFields.coupon_code]);
}

function omitRegistrationFieldsExcept(except: (keyof typeof RegistrationFields)[] | undefined = []) {
  return (data: Data.Form.Racer) => {
    const omitFields = Object.values(RegistrationFields).filter((item) => except.some((exceptItem) => exceptItem !== item));

    return omit(data, omitFields);
  };
}

export { omitWave, omitClass, omitDiscipline, omitCoupon, omitRegistrationFieldsExcept };
