import * as React from 'react';

export const Xtri = (
  <svg width='87' height='64' viewBox='0 0 87 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.46442 0.0546875L16.2199 4.3742L22.3256 13.8166L28.8186 4.3742L41.6528 0.0546875L25.8449 21.2554L56.1285 63.9559L38.7432 50.719L22.1357 28.9944L16.2525 37.7443L14.7498 37.1714L11.0348 42.6647L0.17334 46.3991L16.8045 21.5692L3.46442 0.0546875Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.139 58.782C67.2475 58.4555 67.288 58.007 67.288 56.797C67.288 55.5862 67.2475 55.1376 67.139 54.8112C66.9356 54.172 66.4482 53.7915 65.6898 53.7915C64.9313 53.7915 64.4432 54.172 64.2405 54.8112C64.132 55.1376 64.0909 55.5862 64.0909 56.797C64.0909 58.007 64.132 58.4555 64.2405 58.782C64.4432 59.4214 64.9313 59.8019 65.6898 59.8019C66.4482 59.8019 66.9356 59.4214 67.139 58.782ZM62.3304 59.3259C62.1271 58.6872 62.0732 58.17 62.0732 56.797C62.0732 55.4234 62.1271 54.9062 62.3304 54.2673C62.8045 52.771 64.0775 52.0234 65.6898 52.0234C67.3014 52.0234 68.575 52.771 69.0491 54.2673C69.252 54.9062 69.3064 55.4234 69.3064 56.797C69.3064 58.17 69.252 58.6872 69.0491 59.3259C68.575 60.8216 67.3014 61.5699 65.6898 61.5699C64.0775 61.5699 62.8045 60.8216 62.3304 59.3259Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.1064 58.0188V52.3074C71.1064 52.2261 71.1609 52.1713 71.2421 52.1713H72.9625C73.0437 52.1713 73.0976 52.2261 73.0976 52.3074V58.0735C73.0976 59.1749 73.7071 59.8003 74.6419 59.8003C75.5634 59.8003 76.173 59.1749 76.173 58.0735V52.3074C76.173 52.2261 76.2269 52.1713 76.308 52.1713H78.0285C78.1097 52.1713 78.1641 52.2261 78.1641 52.3074V58.0188C78.1641 60.3176 76.7008 61.5684 74.6419 61.5684C72.5696 61.5684 71.1064 60.3176 71.1064 58.0188Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M84.6901 55.0408C84.6901 54.3473 84.2027 53.8987 83.444 53.8987H81.8187C81.7642 53.8987 81.7369 53.9253 81.7369 53.9802V56.1153C81.7369 56.1694 81.7642 56.1966 81.8187 56.1966H83.444C84.2027 56.1966 84.6901 55.7481 84.6901 55.0408ZM84.8389 61.4188C84.7172 61.4188 84.6767 61.3781 84.6362 61.2826L83.0512 57.8966H81.8187C81.7642 57.8966 81.7369 57.9241 81.7369 57.9781V61.2826C81.7369 61.3647 81.683 61.4188 81.6019 61.4188H79.8812C79.8002 61.4188 79.7458 61.3647 79.7458 61.2826V52.3074C79.7458 52.2261 79.8002 52.1713 79.8812 52.1713H83.5525C85.4353 52.1713 86.6812 53.3548 86.6812 55.0408C86.6812 56.2241 86.0582 57.1625 85.0423 57.6111L86.8573 61.2554C86.8978 61.3373 86.8573 61.4188 86.7628 61.4188H84.8389Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.2886 50.7544C55.2028 50.7544 55.1534 50.7053 55.129 50.6312L52.656 45.341L51.8549 50.6312C51.8305 50.7053 51.7809 50.7544 51.6951 50.7544H50.8956C50.8218 50.7544 50.7728 50.7053 50.7483 50.6312L44.9899 42.5059C44.9773 42.4318 45.0023 42.3828 45.0881 42.3828H46.7122C46.7981 42.3828 47.0885 42.3828 47.1668 42.5867L50.9425 47.6507L51.7625 42.5059C51.7869 42.4318 51.8358 42.3828 51.9216 42.3828H52.9888C53.0623 42.3828 53.1117 42.4318 53.1361 42.5059L55.6081 48.1356L56.3952 42.5059C56.4072 42.4198 56.456 42.3828 56.5425 42.3828H58.1122C58.198 42.3828 58.2222 42.4318 58.2098 42.5059L56.6742 50.6312C56.6616 50.7053 56.6008 50.7544 56.5144 50.7544H55.2886Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.4357 48.3641C63.534 48.0686 63.571 47.6623 63.571 46.5663C63.571 45.4709 63.534 45.0645 63.4357 44.769C63.252 44.1905 62.8106 43.846 62.1241 43.846C61.437 43.846 60.9955 44.1905 60.8118 44.769C60.7135 45.0645 60.6765 45.4709 60.6765 46.5663C60.6765 47.6623 60.7135 48.0686 60.8118 48.3641C60.9955 48.9427 61.437 49.2872 62.1241 49.2872C62.8106 49.2872 63.252 48.9427 63.4357 48.3641ZM59.0824 48.8565C58.8983 48.2779 58.8495 47.8101 58.8495 46.5663C58.8495 45.3231 58.8983 44.8552 59.0824 44.2767C59.5116 42.9222 60.6647 42.2456 62.1241 42.2456C63.583 42.2456 64.7359 42.9222 65.1651 44.2767C65.3491 44.8552 65.398 45.3231 65.398 46.5663C65.398 47.8101 65.3491 48.2779 65.1651 48.8565C64.7359 50.211 63.583 50.8876 62.1241 50.8876C60.6647 50.8876 59.5116 50.211 59.0824 48.8565Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.807 44.9805C70.807 44.3522 70.3654 43.946 69.6783 43.946H68.2069C68.1581 43.946 68.1336 43.9706 68.1336 44.0202V45.9532C68.1336 46.0022 68.1581 46.0269 68.2069 46.0269H69.6783C70.3654 46.0269 70.807 45.6205 70.807 44.9805ZM70.9417 50.7544C70.8314 50.7544 70.7944 50.7174 70.7576 50.6312L69.3232 47.5656H68.2069C68.1581 47.5656 68.1336 47.5902 68.1336 47.6398V50.6312C68.1336 50.7053 68.0842 50.7544 68.011 50.7544H66.4531C66.3799 50.7544 66.3311 50.7053 66.3311 50.6312V42.5059C66.3311 42.4318 66.3799 42.3828 66.4531 42.3828H69.7766C71.4809 42.3828 72.6096 43.4537 72.6096 44.9805C72.6096 46.0514 72.0453 46.9009 71.1257 47.3071L72.7687 50.6067C72.8057 50.6808 72.7687 50.7544 72.683 50.7544H70.9417Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.8081 42.5059C73.8081 42.4318 73.8569 42.3828 73.9304 42.3828H75.488C75.5613 42.3828 75.6107 42.4318 75.6107 42.5059V49.0678C75.6107 49.117 75.6353 49.1415 75.6841 49.1415H79.3627C79.4366 49.1415 79.4856 49.1911 79.4856 49.2647V50.6312C79.4856 50.7053 79.4366 50.7544 79.3627 50.7544H73.9304C73.8569 50.7544 73.8081 50.7053 73.8081 50.6312V42.5059Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M82.3345 49.1415H83.2789C84.1127 49.1415 84.6157 48.8956 84.8236 48.2183C84.9094 47.9718 84.9589 47.6518 84.9589 46.5683C84.9589 45.4854 84.9094 45.1654 84.8236 44.9189C84.6157 44.2415 84.1127 43.9957 83.2789 43.9957H82.3345C82.2855 43.9957 82.2611 44.0202 82.2611 44.0694V49.0678C82.2611 49.117 82.2855 49.1415 82.3345 49.1415ZM80.4585 42.5059C80.4585 42.4318 80.5073 42.3828 80.5806 42.3828H83.6837C85.1425 42.3828 86.1604 43.0104 86.565 44.266C86.712 44.7339 86.786 45.2017 86.786 46.5683C86.786 47.9352 86.712 48.4031 86.565 48.8711C86.1604 50.1268 85.1425 50.7544 83.6837 50.7544H80.5806C80.5073 50.7544 80.4585 50.7053 80.4585 50.6312V42.5059Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.3768 52.1713H54.5631C54.4821 52.1713 54.4276 52.2261 54.4276 52.3074V53.8166C54.4276 53.8987 54.4821 53.9528 54.5631 53.9528H56.8933C56.9471 53.9528 56.9744 53.9802 56.9744 54.0343V59.2212L58.5321 61.4188H58.8299C58.9111 61.4188 58.9656 61.3647 58.9656 61.2826V54.0343C58.9656 53.9802 58.9929 53.9528 59.0468 53.9528H61.3768C61.4579 53.9528 61.5118 53.8987 61.5118 53.8166V52.3074C61.5118 52.2261 61.4579 52.1713 61.3768 52.1713Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 13.888L31.208 18.1858L39.4031 18.1881V34.3941L44.6767 41.85V18.1881H52.7007V13.8789L34 13.888Z'
      fill='#FFFFFE'
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M86.0284 13.8875L80.7954 13.8789V39.2217H86.0327L86.0284 13.8875Z' fill='#FFFFFE' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.8004 18.1635H64.0309C67.3127 18.1635 68.5919 19.2701 68.5919 22.1097C68.5919 24.7986 66.7744 26.1619 63.1899 26.1619H60.8004V18.1635ZM69.8988 28.7649C72.5493 27.3767 74.0041 24.962 74.0041 21.934C74.0041 16.822 70.6495 13.8906 64.8009 13.8906H55.5287V36.705L55.4795 39.2023H60.7683L60.8004 30.1532H62.9797C63.5379 30.1532 64.1421 30.119 64.5974 30.0632L72.1575 39.2249H78.3537L69.8988 28.7649Z'
      fill='#FFFFFE'
    />
  </svg>
);
