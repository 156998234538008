type ArgType = {
  height?: number;
  width?: number;
};

type Result = {
  height: number;
  width: number;
};

const ratioHeight = 214;
const ratioWidth = 377;

export const getImageSize = (sizes: ArgType): Result => {
  const { width = 0, height = 0 } = sizes;

  if (!width) {
    return {
      width: (ratioWidth * height) / ratioHeight,
      height,
    };
  }

  if (!height) {
    return {
      height: (ratioHeight * width) / ratioWidth,
      width,
    };
  }

  return { width, height };
};
