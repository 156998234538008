export const search = {
  _validation: {
    email: {
      length: { maximum: 250 },
      presence: {
        allowEmpty: false,
        message: '^confirmProfile.validation.required',
      },
      email: {
        message: '^confirmProfile.validation.invalidFormat',
      },
    },
  },
};
