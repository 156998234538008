import axios from 'axios';

import {
  API_WISHLIST,
  API_URL,
  LOAD_WISHLISTS,
  WISHLIST,
  CHANGE_WISHLISTS,
  ADD_RACE_TO_FAVORITE,
  REMOVE_RACE_FROM_FAVORITE,
} from 'src/constants';

import { request, action, toastService, t, fireToast } from 'utils';

import { LoadableService } from 'services';

import { wishlistsStore, Wishlist, sessionStore } from 'stores';

class Wishlists extends LoadableService<RacesType, FiltersType, Wishlist> {
  constructor(store: Wishlist) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_WISHLIST}`,
      resource: WISHLIST,
    });
  }

  @request({ action: LOAD_WISHLISTS })
  async loadResourcesRequest(params: FiltersType | {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @request({ action: ADD_RACE_TO_FAVORITE })
  async addRaceToFavoriteRequest(params: Object): Promise<any> {
    return axios.post(`${API_URL}${API_WISHLIST}`, params);
  }

  @request({ action: REMOVE_RACE_FROM_FAVORITE })
  async removeRaceFromFavoriteRequest(params: AnyObject): Promise<any> {
    return axios.delete(`${API_URL}${API_WISHLIST}/${params.id}`);
  }

  @action({ action: LOAD_WISHLISTS, minRequestTime: 800 })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    if (!this.store.values.length && sessionStore.isAuth) {
      return super.loadResources(params, pageNum);
    }
  }

  @action({ action: CHANGE_WISHLISTS, minRequestTime: 800 })
  async changeFavoriteRace(race: AnyObject): Promise<any> {
    if (sessionStore.isAuth) {
      if (wishlistsStore.findById(race.id)) {
        wishlistsStore.removeValue(race.id);
        const [status, response] = await this.removeRaceFromFavoriteRequest({
          id: race.id,
        });
        if (status) {
          wishlistsStore.updateWishListInStorage();
        } else {
          wishlistsStore.addValue(race as RacesType);
          toastService.error(response.data.message);
        }
      } else {
        wishlistsStore.addValue(race as RacesType);
        const [status, response] = await this.addRaceToFavoriteRequest({
          id: race.id,
        });
        if (status) {
          wishlistsStore.updateWishListInStorage();
          fireToast(
            t.staticAsString('toast.message.raceSavedSuccessfully'),
            'success',
            t.staticAsString('toast.title.raceSavedSuccessfully'),
            'top-right',
          );
        } else {
          wishlistsStore.removeValue(race.id);
          toastService.error(response.data.message);
        }
      }
    } else {
      fireToast(t.staticAsString('toast.message.needLogIn'), 'warning', t.staticAsString('toast.title.needLogIn'), 'top-right');
    }
  }
}

const wishlistsService = new Wishlists(wishlistsStore);

export { Wishlists, wishlistsService };
