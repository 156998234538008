import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  API_EXPORT_BIB_URL,
  API_EXPORT_DIPLOMA_URL,
  API_EXPORT_JOB_ID,
  API_EXPORT_PAYMENT_TRANSACTIONS,
  API_EXPORT_REGISTERED_TO,
  API_URL,
} from 'src/constants';

import { action, request } from 'utils';

class ExportJobService {
  @request({ action: 'CHECK_JOB_ID' })
  checkJobIdRequest(id: number): Promise<any> {
    return axios.get(generatePath(`${API_URL}${API_EXPORT_JOB_ID}`, { id }));
  }

  @action({ action: 'CHECK_JOB_ID' })
  async checkJobId(id: number): Promise<any> {
    const result = await this.checkJobIdRequest(id);
    return result;
  }

  @request({ action: 'EXPORT_PAYMENT_TRANSACTIONS' })
  exportPaymentTransactionsRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(`${API_URL}${API_EXPORT_PAYMENT_TRANSACTIONS}`), {
      params,
    });
  }

  @action({ action: 'EXPORT_PAYMENT_TRANSACTIONS' })
  async exportPaymentTransactions(type: string, id: number, params: FiltersType): Promise<any> {
    const updParams = { type, ...params };
    return await this.exportRegisteredRequest(id, updParams);
  }

  @request({ action: 'EXPORT_REGISTERED_TO' })
  exportRegisteredRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(`${API_URL}${API_EXPORT_REGISTERED_TO}`, { id }), {
      params,
    });
  }

  @action({ action: 'EXPORT_REGISTERED_TO' })
  async exportRegistered(type: string, id: number, params: FiltersType): Promise<any> {
    const updParams = { type, ...params };
    return await this.exportRegisteredRequest(id, updParams);
  }

  @request({ action: 'EXPORT_BIB' })
  exportBibRequest(racerId: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(`${API_URL}${API_EXPORT_BIB_URL}`, { racerId }), {
      params,
    });
  }

  @action({ action: 'EXPORT_BIB' })
  async exportBib(type: string, racerId: number, params: FiltersType): Promise<any> {
    const updParams = { type, ...params };
    return await this.exportBibRequest(racerId, updParams);
  }

  @request({ action: 'EXPORT_DIPLOMA' })
  exportDiplomaRequest(racerId: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(`${API_URL}${API_EXPORT_DIPLOMA_URL}`, { racerId }), {
      params,
    });
  }

  @action({ action: 'EXPORT_DIPLOMA' })
  async exportDiploma(type: string, racerId: number, params: FiltersType): Promise<any> {
    const updParams = { type, ...params };
    return await this.exportDiplomaRequest(racerId, updParams);
  }
}

const exportJobService = new ExportJobService();
export { exportJobService };
