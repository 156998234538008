import classNames from 'classnames';
import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

type Props = {
  bg: string;
  link: string;
  mutedOverlay?: boolean;
};

export const AnimateImgComponent = (props: Props = { mutedOverlay: false, bg: '', link: '' }): React.ReactElement => {
  const { bg, link } = props;

  return (
    <div
      className={classNames('animate-img-container', { 'muted-overlay': props.mutedOverlay })}
      style={{ backgroundImage: `url("${bg}")` }}
    >
      <Link to={link} className='overlay-link' />
    </div>
  );
};
