import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

export const ScrollWrap = styled('div')(
  ({ invertSize }: { theme; invertSize: number }) => css`
    margin-left: -${invertSize}px;
    margin-right: -${invertSize}px;
    overflow: hidden;
    width: calc(100% + ${invertSize}px);

    .scroll-block {
      overflow-x: scroll;
      margin-bottom: -15px;
      padding-left: ${invertSize}px;
    }

    @media (min-width: ${RESOLUTIONS.medium}px) {
      margin-left: 0;
      margin-right: 0;

      .scroll-block {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `,
);
