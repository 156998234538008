import axios from 'axios';
import shortid from 'shortid';

import { API_URL, API_EXPORT_BIB_URL } from 'src/constants';

import { request, action } from 'utils';
import { encodeQuery } from 'utils';

class ExportBibService {
  @request({ action: shortid() })
  request(racerId: number): Promise<any> {
    const url = encodeQuery.generatePath(API_URL + API_EXPORT_BIB_URL, { racerId });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async load(racerId: number) {
    const [isOk, response] = await this.request(racerId);

    if (!isOk) {
      return null;
    }

    return response.data.url;
  }
}

export { ExportBibService };
