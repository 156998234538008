import { AnyStore } from './anyStore';

const shouldShowCookieBanner = (): boolean => {
  const storageJson = localStorage.getItem('cookies-banner');
  if (storageJson) {
    return JSON.parse(storageJson);
  }
  localStorage.setItem('cookies-banner', 'true');
  return true;
};

export const cookieBannerStore = new AnyStore<boolean>(shouldShowCookieBanner());
