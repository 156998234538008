import * as React from 'react';

export const RaceTwitter = (
  <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.6667 12.4491C22.1708 12.6666 21.6425 12.8108 21.0917 12.8808C21.6583 12.5425 22.0908 12.0108 22.2942 11.37C21.7658 11.685 21.1825 11.9075 20.5608 12.0316C20.0592 11.4975 19.3442 11.1666 18.5642 11.1666C17.0508 11.1666 15.8325 12.395 15.8325 13.9008C15.8325 14.1175 15.8508 14.3258 15.8958 14.5241C13.6233 14.4133 11.6125 13.3241 10.2617 11.665C10.0258 12.0741 9.88751 12.5425 9.88751 13.0466C9.88751 13.9933 10.375 14.8325 11.1017 15.3183C10.6625 15.31 10.2317 15.1825 9.86668 14.9816C9.86668 14.99 9.86668 15.0008 9.86668 15.0116C9.86668 16.34 10.8142 17.4433 12.0567 17.6975C11.8342 17.7583 11.5917 17.7875 11.34 17.7875C11.165 17.7875 10.9883 17.7775 10.8225 17.7408C11.1767 18.8233 12.1817 19.6191 13.3767 19.645C12.4467 20.3725 11.2658 20.8108 9.98751 20.8108C9.76334 20.8108 9.54834 20.8008 9.33334 20.7733C10.5442 21.5541 11.9792 22 13.5267 22C18.5567 22 21.3067 17.8333 21.3067 14.2216C21.3067 14.1008 21.3025 13.9841 21.2967 13.8683C21.8392 13.4833 22.295 13.0025 22.6667 12.4491Z'
      fill='#656B6F'
    />
    <rect x='0.75' y='1.25' width='30.5' height='30.5' rx='15.25' stroke='#656B6F' strokeWidth='1.5' />
  </svg>
);
