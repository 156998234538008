import moment from 'moment';

import { t } from 'utils';

import { Distance } from 'models';

function cannotLogError(distance: Distance) {
  if (!distance) {
    return;
  }

  const from = moment(distance.value.race_date).format('DD MMM YYYY');
  const to = moment(distance.value.ends_at).format('DD MMM YYYY');

  return t.staticAsString('profile.result.cannotLogDistanceNotStarted', { from, to });
}

export { cannotLogError };
