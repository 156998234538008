import { Observer } from 'mobx-react';
import * as React from 'react';

import type { Distance } from 'models';

import { resultsPresentation } from '../../derivations';
import type { LResult } from '../../types';

type Props = {
  result: LResult;
  checkpointId: number | nil;
  distance: Distance;
};

function FinishResult({ result, checkpointId, distance }: Props) {
  const generator = resultsPresentation.generateFinishResult(result, checkpointId, distance);

  return (
    <Observer>
      {() => {
        return <>{generator.get()}</>;
      }}
    </Observer>
  );
}

export { FinishResult };
