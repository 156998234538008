import axios from 'axios';

import { AUTHORIZATION } from 'src/constants';

import { Session } from 'services/session';

import { errorsStore, progressStore, sessionStore } from 'stores';

import { ResponseProcessor } from './responseProcessor';

function isAuth() {
  return !!axios.defaults.headers.common[AUTHORIZATION];
}

// request annotation
export const request = (reqArgs: { action: string; root?: string; writeError?: boolean; authOnly?: boolean }): any => {
  reqArgs = { root: 'errors', ...reqArgs, writeError: true };

  return (target: any, propertyKey: string, descriptor: any): any => {
    var oldValue = descriptor.value;
    const sessionService = new Session(sessionStore);

    descriptor.value = async function (...args: any[]) {
      try {
        // ! Remove this line when the feature is tested
        // await sessionService.refreshTokenIfNeeded();
        if (reqArgs.authOnly && !isAuth()) {
          return [false, null];
        }

        const response = await oldValue.call(this, ...args);
        new ResponseProcessor({ ...reqArgs, response } as any).process();

        return [true, response];
      } catch (e) {
        new ResponseProcessor({ ...reqArgs, response: e.response, error: e } as any).process();
        return [false, e.response];
      }
    };

    return descriptor;
  };
};

export function action(reqArgs: { action: string; minRequestTime?: number; onlyFirst?: boolean }) {
  reqArgs = { ...reqArgs };

  return function (target: any, propertyKey: string, descriptor: any): any {
    let oldValue = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      const action = reqArgs.action;

      if (reqArgs.onlyFirst && progressStore.isLoading(action)) {
        return false;
      }

      progressStore.log(action, 'progress');
      errorsStore.clearError(action);
      errorsStore.clear(action);

      const requestStartTime = new Date();

      const status = await oldValue.call(this, ...args);

      let logStatus: any = 'completed';
      if (status === false || logStatus === true) {
        logStatus = !!status ? 'completed' : 'failed';
      }

      let timeProgressParams: {
        requestStartTime: Date;
        minRequestTime: number;
      } | null = null;

      if (reqArgs.minRequestTime) {
        timeProgressParams = {
          requestStartTime,
          minRequestTime: reqArgs.minRequestTime,
        };
      }

      progressStore.log(action, logStatus, timeProgressParams);
      return status;
    };

    return descriptor;
  };
}
