import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const SettingsPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M22.935 9.43959L20.6618 10.7521C20.7201 11.16 20.7502 11.5767 20.7502 12C20.7502 12.4234 20.7201 12.8401 20.6618 13.248L22.935 14.5605L19.685 20.1896L17.4112 18.8768C16.7596 19.3901 16.033 19.8131 15.2502 20.1264V22.75H8.75023V20.1264C7.96745 19.8131 7.24083 19.3901 6.58928 18.8768L4.31544 20.1896L1.06544 14.5605L3.33865 13.248C3.28035 12.8401 3.25023 12.4234 3.25023 12C3.25023 11.5767 3.28035 11.16 3.33864 10.7521L1.06543 9.43959L4.31543 3.81042L6.58928 5.12323C7.24083 4.60992 7.96744 4.18699 8.75023 3.87367V1.25H15.2502V3.87367C16.033 4.18699 16.7596 4.60992 17.4112 5.12323L19.685 3.81042L22.935 9.43959ZM12 15.4998C13.933 15.4998 15.5 13.9328 15.5 11.9998C15.5 10.0668 13.933 8.49976 12 8.49976C10.067 8.49976 8.5 10.0668 8.5 11.9998C8.5 13.9328 10.067 15.4998 12 15.4998Z'
    fill='currentColor'
  />
);

export const Settings = (props: IconProps) => <SvgIcon path={SettingsPath} {...props} />;
