import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { InputWrapComponent, StyledInput } from '../../InputTools';
import { BodyText, Utility } from '../../Typography';
import { separatedTimeStore } from './store';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;

  .input-item {
    display: flex;
    flex-direction: column;

    input::-webkit-inner-spin-button {
      display: none;
    }

    & > span {
      padding-bottom: 8px;
      text-transform: none;
    }
  }

  .input-component {
    width: 100%;
    flex: unset;
  }

  .divider {
    color: ${(props) => props.theme.main.colors.clay4};
    padding: 24px 6px 0;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-top: 24px;
  }
`;

const isObjectHasAllEmptyValues = (obj) =>
  Object.values(obj).every((value) => {
    return value === null || value === undefined;
  });

const getPlaceholders = (withMilliseconds: boolean) => ({
  hours: 'HH',
  minutes: 'MM',
  seconds: `SS${withMilliseconds ? '.ss' : ''}`,
});

const ONLY_DIGITS_REGEX = /^[\d]*$/;
const SECONDS_WITH_POINT_REGEX = /^[\d]{2}\.?[\d]*$/; //59.123

type Props = BaseInputProps & {
  withMilliseconds: boolean;
};

export const SeparatedTimeField: React.FC<Props> = (props) => {
  const { withMilliseconds, value } = props;
  const [filled, setFilled] = useState('');
  const refSS = useRef<HTMLInputElement>(null);
  const refMS = useRef<HTMLInputElement>(null);
  const placeholders = getPlaceholders(withMilliseconds);

  useEffect(() => {
    if (isObjectHasAllEmptyValues(separatedTimeStore.time) && value) {
      separatedTimeStore.parseString(value, withMilliseconds);
    }
  }, [value]);

  useEffect(() => {
    if (filled === 'hours') {
      refSS.current?.focus();
    }
    if (filled === 'minutes') {
      refMS.current?.focus();
    }
  }, [filled]);

  useEffect(() => {
    return () => {
      separatedTimeStore.clear();
    };
  }, []);

  const getInputProps = (fieldName: 'hours' | 'minutes' | 'seconds') => {
    const { name, disabled, readOnly, id } = props;

    return {
      className: 'input-component',
      type: 'text',
      placeholder: placeholders[fieldName],
      name: `${name}:${fieldName}`,
      value: separatedTimeStore.time[fieldName],
      onChange: handleChange(fieldName),
      id,
      disabled,
      readOnly,
      withUnit: false,
    };
  };

  const handleSingleDigit = (field: string | number) => (field.toString().length >= 2 ? field : `0${field}`);

  const handleChange =
    (field: 'hours' | 'minutes' | 'seconds') =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { onChange, name } = props;

      if (['hours', 'minutes'].includes(field) && !e.currentTarget.value.match(ONLY_DIGITS_REGEX)) {
        return;
      }

      let stateValue: string | number = e.currentTarget.value;

      const numberValue: number = +e.currentTarget.value;

      if (isNaN(numberValue)) return;

      if (numberValue > 99 && field === 'hours') stateValue = 99;
      if (numberValue < 0 && field === 'hours') {
        stateValue = '';
      }

      if (numberValue > 59 && field === 'minutes') stateValue = 59;
      if (stateValue.toString().length > 2 && ['hours', 'minutes'].includes(field)) {
        stateValue = numberValue.toString();
      }

      if (numberValue >= 0 && field === 'seconds') {
        const stringState = e.currentTarget.value;
        let ss = stringState.slice(0, 2);
        if (+ss > 59) ss = '59';

        if (withMilliseconds) {
          const regex = stringState.length > 2 ? SECONDS_WITH_POINT_REGEX : ONLY_DIGITS_REGEX;
          if (!stringState.match(regex)) {
            return;
          }
          const dot = stringState.length > 2 ? '.' : '';
          const isDotPlaced = stringState.slice(2, 3) === '.' ? '' : '.';
          const ms = stringState.slice(isDotPlaced ? 2 : 3, 5);
          stateValue = `${ss}${dot}${ms}`;
        } else {
          if (!stringState.match(ONLY_DIGITS_REGEX)) {
            return;
          }
          stateValue = `${ss}`;
        }
      }

      if (numberValue < 0) stateValue = 0;

      if (isEmpty(e.currentTarget.value)) stateValue = '';

      separatedTimeStore.setTime({
        [field]: stateValue,
      });

      if (stateValue.toString().length === 2) {
        if (field === 'hours') setFilled('hours');
        if (field === 'minutes') setFilled('minutes');
      }

      const { hours = '', minutes = '', seconds = '' } = separatedTimeStore.time;

      let time: string;
      if (withMilliseconds && ((seconds.toString().length > 0 && seconds.toString().length <= 3) || seconds.toString().length === 5)) {
        time = `${handleSingleDigit(minutes)}:${moment(seconds, 'ss.SSS').format('ss.SSS')}`;
      } else {
        time = `${handleSingleDigit(minutes)}:${handleSingleDigit(seconds)}`;
      }
      onChange({ name, value: `${handleSingleDigit(hours)}:${time}` }, e);
    };

  const { name } = props;
  const hoursTestSelector = testAnchors.useField(name, TEST_ANCHORS.fieldStructure.fieldPart, 'hours');
  const minutesTestSelector = testAnchors.useField(name, TEST_ANCHORS.fieldStructure.fieldPart, 'minutes');
  const secondsTestSelector = testAnchors.useField(name, TEST_ANCHORS.fieldStructure.fieldPart, 'seconds');

  return (
    <Observer>
      {() => {
        const { errorMessage, label, name, value, disabled } = props;

        return (
          <InputWrapComponent errorMessage={errorMessage} disabled={disabled} label={label} name={name} value={value}>
            <Wrapper className='separated-time'>
              <div className='input-item'>
                <Utility variant='u4' weight='bold'>
                  {placeholders.hours}*
                </Utility>
                <StyledInput {...hoursTestSelector} {...getInputProps('hours')} hasError={!!errorMessage} />
              </div>
              <BodyText variant='body1' className='divider'>
                :
              </BodyText>

              <div className='input-item'>
                <Utility variant='u4' weight='bold'>
                  {placeholders.minutes}*
                </Utility>
                <StyledInput {...minutesTestSelector} {...getInputProps('minutes')} hasError={!!errorMessage} ref={refSS} />
              </div>
              <BodyText variant='body1' className='divider'>
                :
              </BodyText>

              <div className='input-item'>
                <Utility variant='u4' weight='bold'>
                  {placeholders.seconds}*
                </Utility>
                <StyledInput {...secondsTestSelector} {...getInputProps('seconds')} hasError={!!errorMessage} type='text' ref={refMS} />
              </div>
            </Wrapper>
          </InputWrapComponent>
        );
      }}
    </Observer>
  );
};
