import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const DotPath = (
  <>
    <g id='Ellipse 118' filter='url(#filter0_d_196_25544)' fill='currentColor'>
      <circle cx='11.5' cy='8.50012' r='7.5' fill='currentColor' />
    </g>
    <defs>
      <filter
        id='filter0_d_196_25544'
        x='0'
        y='0.00012207'
        width='23'
        height='23'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_196_25544' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_196_25544' result='shape' />
      </filter>
    </defs>
  </>
);

export const Dot = (props: IconProps) => <SvgIcon size={23} viewBox='0 0 23 23' path={DotPath} {...props} />;
