import * as React from 'react';

export const Distance = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22 5.33333C22 6.84444 19.7449 10.3804 18.9449 11.5876C18.9145 11.6334 18.8732 11.671 18.8247 11.697C18.7762 11.723 18.7221 11.7366 18.6671 11.7366C18.6121 11.7366 18.5579 11.723 18.5095 11.697C18.461 11.671 18.4197 11.6334 18.3893 11.5876C17.5884 10.3813 15.3333 6.84444 15.3333 5.33333C15.3333 4.44928 15.6845 3.60143 16.3096 2.97631C16.9347 2.35119 17.7826 2 18.6666 2C19.5507 2 20.3985 2.35119 21.0237 2.97631C21.6488 3.60143 22 4.44928 22 5.33333V5.33333Z'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.6667 5C18.7551 5 18.8399 5.03512 18.9024 5.09763C18.9649 5.16014 19 5.24493 19 5.33333'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.3333 5.33333C18.3333 5.24493 18.3684 5.16014 18.4309 5.09763C18.4935 5.03512 18.5782 5 18.6666 5'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.6666 5.66667C18.5782 5.66667 18.4935 5.63155 18.4309 5.56904C18.3684 5.50653 18.3333 5.42174 18.3333 5.33334'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 5.33334C19 5.42174 18.9649 5.50653 18.9024 5.56904C18.8399 5.63155 18.7551 5.66667 18.6667 5.66667'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 12.3333C6.08841 12.3333 6.17319 12.3685 6.2357 12.431C6.29821 12.4935 6.33333 12.5783 6.33333 12.6667'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.66669 12.6667C5.66669 12.5783 5.70181 12.4935 5.76432 12.431C5.82683 12.3685 5.91161 12.3333 6.00002 12.3333'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.00002 13C5.91161 13 5.82683 12.9649 5.76432 12.9024C5.70181 12.8399 5.66669 12.7551 5.66669 12.6667'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.33333 12.6667C6.33333 12.7551 6.29821 12.8399 6.2357 12.9024C6.17319 12.9649 6.08841 13 6 13'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 8.66666C7.06087 8.66666 8.07828 9.08809 8.82843 9.83824C9.57857 10.5884 10 11.6058 10 12.6667C10 14.3742 7.61067 17.624 6.52533 19.0116C6.46298 19.0914 6.38327 19.1559 6.29224 19.2003C6.20122 19.2447 6.10127 19.2678 6 19.2678C5.89873 19.2678 5.79878 19.2447 5.70776 19.2003C5.61673 19.1559 5.53702 19.0914 5.47467 19.0116C4.38933 17.6231 2 14.3742 2 12.6667C2 11.6058 2.42143 10.5884 3.17157 9.83824C3.92172 9.08809 4.93913 8.66666 6 8.66666Z'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.33331 22L18.6666 14.6667' stroke='#656B6F' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
