import React from 'react';
import styled, { css } from 'styled-components';

import { BodyText } from '../Typography';

type Props = {
  active: boolean;
  disabled: boolean;
};

const StyledListItem = styled.li<Props>`
  padding: 16px 24px;
  cursor: pointer;
  transition: all 0.3s;
  color: ${(props) => props.theme.main.colors.clay1};

  p {
    line-height: 22px;
    margin: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.main.colors.clay6};
    `}

  ${(props) =>
    props.disabled &&
    !props.active &&
    css`
      cursor: not-allowed;
      background-color: ${(props) => props.theme.main.colors.clay5};

      &:hover {
        background-color: ${(props) => props.theme.main.colors.clay5};
        color: ${(props) => props.theme.main.colors.clay3};
      }
    `}
`;

export const SelectListItem = (props: SelectListItemType & { name: string }) => {
  const { activeItem, item, name } = props;
  const isActive = activeItem === item.value;
  const isDisabled = !!item.disabled;

  return (
    <StyledListItem
      {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.selectItem, item.value)}
      active={isActive}
      disabled={isDisabled && !isActive}
      onClick={() => !isDisabled && props.onSelect(item)}
    >
      <BodyText variant='body2'>{item.label}</BodyText>
    </StyledListItem>
  );
};
