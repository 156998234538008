import * as React from 'react';

import { htmlSanitizer } from 'utils';

type Props = {
  text: string;
  length: number;
};

export const Teaser = (props: Props) => {
  const { text, length } = props;

  const extractedText = new DOMParser().parseFromString(text, 'text/html').documentElement.textContent as string;
  const isCut = extractedText && extractedText.length > props.length;

  const getText = () => (isCut ? extractedText.substr(0, length) + '...' : extractedText);
  const getTitle = () => (isCut ? extractedText : '');

  return <div title={getTitle()} dangerouslySetInnerHTML={{ __html: htmlSanitizer(getText()) }} />;
};
