import { set } from 'lodash';
import { mapDistances } from 'modules/home/utils';

import { singleVirtualService } from '../services';

import { resultStore } from '../stores';

/*
 * NOTE.
 * In case of updated data or 409 error
 * there are necessity to update current loaded distance
 */
async function reloadDistance(id: number, type: 'virtual' | 'cumulative' = 'virtual') {
  const index = resultStore[type].findIndex((el) => el.id === id);
  if (index < 0) {
    return;
  }

  const updatedDistance = await singleVirtualService.load(id);

  if (!updatedDistance) {
    return;
  }

  const list = [...resultStore[type]];

  set(list, [index], updatedDistance);

  type === 'virtual' ? resultStore.setVirtual(mapDistances(list), {}) : resultStore.setCumulative(mapDistances(list), {});
}

export { reloadDistance };
