export const SHADOWS = {
  dark: {
    glowPrimary: '0px 0px 0px 3px rgba(24, 111, 250, 0.65)',
    pressed: 'inset 0px 4px 12px rgba(0, 0, 0, 0.4)',
    elevetion1: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    elevetion1Strong: '0px 2px 3px rgba(0, 0, 0, 0.4)',
    elevetion2: '0px 8px 12px rgba(0, 0, 0, 0.3)',
    elevetion2Strong: '0px 3px 4px rgba(0, 0, 0, 0.4)',
    elevetion2Up: '0px -10px 14px rgba(0, 0, 0, 0.32)',
    elevetion3: '0px 14px 16px rgba(0, 0, 0, 0.3)',
    elevetion3Up: '0px -6px 16px rgba(0, 0, 0, 0.2)',
  },
  light: {
    glowPrimary: 'px 0px 0px 3px rgba(24, 111, 250, 0.65)',
    pressed: 'inset 0px 4px 6px rgba(0, 0, 0, 0.08)',
    elevetion1: '0px 2px 6px rgba(0, 0, 0, 0.08)',
    elevetion1Strong: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    elevetion2: '0px 6px 10px rgba(0, 0, 0, 0.16)',
    elevetion2Strong: '0px 3px 5px rgba(0, 0, 0, 0.3)',
    elevetion2Up: '0px -2px 16px rgba(0, 0, 0, 0.2)',
    elevetion3: '0px 10px 16px rgba(0, 0, 0, 0.17)',
    elevetion3Up: '0px -6px 16px rgba(0, 0, 0, 0.04)',
  },
};
