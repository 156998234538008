import { UserType } from 'typings/old-types/UserType';

import { Gender } from 'components/core/genderWrap';

import { SWEDEN_COUNTRY_ID } from 'stores/countries';

import { ParrentModel } from './parrentModel';
import { RaceUnion } from './raceUnion';

export const genderMap = {
  1: 'male',
  0: 'female',
};

export enum STRIPE_STATUS {
  trialing = 'trialing',
  active = 'active',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  canceled = 'canceled',
  unpaid = 'unpaid',
  paused = 'paused',
}

class User extends ParrentModel<UserType> {
  raceUnions: RaceUnion[];

  constructor(value: UserType) {
    super(value);
    this.raceUnions = [];
    this.initRaceUnions();
  }

  initRaceUnions = () => {
    const { raceUnions } = this.value;
    this.raceUnions = (raceUnions || []).map((union) => new RaceUnion(union));
  };

  isXtriMember(): boolean {
    return this.raceUnions.some((union) => union.isXtri());
  }

  isNorsemanMember(): boolean {
    return this.raceUnions.some((union) => union.isNorseman());
  }

  get isBasedInSweden(): boolean {
    return this.value.country_id === SWEDEN_COUNTRY_ID;
  }

  get isMilesTrial(): boolean {
    return Boolean(this.value.subscriptions?.stripe_status === STRIPE_STATUS.trialing);
  }

  get trialDaysLeft(): number {
    const end = this.value.subscriptions?.trial_end;
    const now = new Date();

    if (!end) {
      return 0;
    }

    const endDateTime = new Date(end * 1000);
    const differenceInTime = endDateTime.getTime() - now.getTime();

    if (differenceInTime < 0) {
      return 0;
    }
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.floor(differenceInDays);
  }

  get fullName(): string | null {
    if (!this.value.firstname && !this.value.lastname) {
      return null;
    }

    return `${this.value.firstname || ''} ${this.value.lastname || ''}`;
  }

  get genderString(): string {
    if (this.value.gender) {
      return genderMap[this.value.gender] ?? 'Other';
    }
    return 'Other';
  }

  get hasMilesCoupon(): boolean {
    return Boolean(this.value.stripe_coupon_id);
  }

  // Thank you, ChatGPT
  get age(): number | null {
    if (!this.value.birthday) {
      return null;
    }
    const birthdate = new Date(this.value.birthday);
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();
    const m = today.getMonth() - birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }
    return age;
  }
}

export { User };
