import { transaction } from 'mobx';

import { counterStore, dataStore, paginationMetaStore } from '../stores';

function add(id: number, page: number, data: RacersType[] | RacersTeamType[], meta: PaginationMeta) {
  transaction(() => {
    dataStore.setValues(id, page, data);
    paginationMetaStore.setValue(id, meta);
  });
}

function setCounter(counter: number) {
  transaction(() => {
    counterStore.setCounter(counter);
  });
}

export const dataChanger = {
  add,
  setCounter,
};
