import React, { FC, useMemo } from 'react';

import { timeDurationMask } from 'components/form/Fields/core/mask';

type TimeFieldComponentProps = {
  maxHours: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TimeFieldComponent: FC<TimeFieldComponentProps> = (props) => {
  const { maxHours, ...rest } = props;
  const Component = useMemo(() => timeDurationMask(maxHours), [maxHours]);
  return <Component {...rest} />;
};
