import * as React from 'react';

export const LogoUkraine = (
  <svg width='124' height='124' viewBox='0 0 124 124' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 2C0 0.895432 0.89543 0 2 0H122C123.105 0 124 0.89543 124 2V62H0V2Z' fill='#2160A7' />
    <path d='M0 62H124V122C124 123.105 123.105 124 122 124H2C0.895429 124 0 123.105 0 122V62Z' fill='#EDCF2B' />
    <path
      d='M105.979 45.1217C105.987 45.1967 105.987 45.2723 105.979 45.3473C105.97 45.3891 105.96 45.4303 105.946 45.4709L99.8151 69.4197L99.7775 69.5701C99.7128 69.7616 99.5952 69.9311 99.4383 70.0589C99.411 70.0821 99.3822 70.1037 99.3522 70.1234C96.2785 72.4119 84.1669 82.8607 84.1454 82.8714C83.8833 83.0893 83.7315 83.412 83.7309 83.7524C83.7473 84.0505 83.8672 84.3335 84.07 84.5529L91.0679 93.5672C91.4816 94.0251 91.4816 94.721 91.0679 95.1789C90.88 95.3631 90.6353 95.4786 90.3735 95.5066H70.1066C69.8284 95.491 69.565 95.3767 69.3638 95.1842C69.3638 95.1842 61.8277 85.4124 60.7511 84.0318V84.0049C60.5662 83.7894 60.3067 83.6513 60.0244 83.6181H39.6768C39.2581 83.6466 38.8914 83.9086 38.7294 84.295L38.6487 84.5958L36.0918 94.6148C36.0861 94.6537 36.0752 94.6916 36.0595 94.7276C35.9137 95.197 35.4753 95.5143 34.9829 95.5066H19.1786C19.1357 95.5117 19.0923 95.5117 19.0494 95.5066C18.7211 95.4663 18.4242 95.2921 18.2294 95.0254C18.0345 94.7587 17.9592 94.4233 18.0212 94.0991C18.0212 94.04 24.9868 66.8249 26.634 60.3354C26.634 60.271 26.6662 60.2065 26.677 60.1474C26.7672 59.8967 26.9449 59.6869 27.1776 59.5565H27.2153L45.5927 49.645L45.7165 49.5751C46.0566 49.4038 46.4622 49.4246 46.7829 49.6299C47.1037 49.8352 47.2918 50.1943 47.2776 50.5743C47.2858 50.6475 47.2858 50.7214 47.2776 50.7946C47.2705 50.8309 47.2615 50.8667 47.2506 50.902L43.6871 64.8695C43.6837 64.9285 43.6837 64.9877 43.6871 65.0468C43.6856 65.6308 44.137 66.1165 44.7207 66.1588H80.5603C80.9854 66.1459 81.3676 65.8971 81.5508 65.5141L86.0133 48.0871C86.0455 47.9808 86.0618 47.8704 86.0617 47.7594C86.0588 47.1405 85.5568 46.6395 84.9367 46.6366H47.9235C47.6309 46.6361 47.3504 46.5203 47.143 46.3143L46.9976 46.137L34.5307 30.7835C34.3441 30.4004 34.3674 29.9486 34.5924 29.5866C34.8175 29.2247 35.2128 29.0032 35.6396 29H52.8651H89.7867C90.0773 29.0077 90.3542 29.1248 90.5619 29.3277L105.575 44.3696L105.72 44.52C105.867 44.6888 105.957 44.8992 105.979 45.1217Z'
      fill='#F7F7F7'
    />
  </svg>
);
