import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const EditPath = (
  <>
    <path
      d='M1.15734 14.5961L2.72249 10.4233L5.79536 13.4443L1.64843 15.0789C1.48814 15.1421 1.32595 15.1193 1.22416 15.0192C1.12239 14.9191 1.09684 14.7574 1.15734 14.5961Z'
      fill='#D8FF3A'
    />
    <path
      d='M11.9635 1.37524C12.1477 1.29773 12.3455 1.25781 12.5453 1.25781C12.7451 1.25781 12.9429 1.29773 13.1271 1.37524C13.3106 1.45244 13.4768 1.56539 13.6161 1.7075L14.4896 2.56832L14.491 2.56966C14.6309 2.70882 14.742 2.87424 14.8179 3.05644C14.8941 3.23923 14.9333 3.43528 14.9333 3.6333C14.9333 3.83132 14.8941 4.02737 14.8179 4.21015C14.742 4.39235 14.6309 4.55776 14.491 4.69693L14.4896 4.69828L6.59294 12.6692L3.53857 9.63751L11.4754 1.70646C11.6146 1.56484 11.7805 1.45225 11.9635 1.37524Z'
      fill='#D8FF3A'
    />
  </>
);

export const Edit = (props: IconProps) => <SvgIcon path={EditPath} viewBox='0 0 16 16' {...props} />;
