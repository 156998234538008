import { distanceOrderBy } from 'modules/results/derivations';

import { PER_PAGE } from '../constants';

import { filtersStore, distancesStore } from '../stores';

import { internalBe } from '../mapper/internalBe';

function resultsParams(distanceId: number) {
  const distance = distancesStore.find(distanceId);
  if (!distance) {
    return {};
  }

  let filters: AnyObject;
  if (distance.isSingle()) {
    filters = internalBe.singleResults(filtersStore.value);
  } else {
    filters = internalBe.teamResults(filtersStore.value);
  }

  return { ...filters, limit: PER_PAGE, ...distanceOrderBy(distanceId) };
}

function counterParams() {
  return internalBe.mixedResults(filtersStore.value);
}

export const params = {
  resultsParams,
  counterParams,
};
