import * as React from 'react';

export type ContextType = {
  withCloseButton?: boolean;
  onClose?: AnyFunction;
};

const Context = React.createContext<ContextType>({});

export { Context };
