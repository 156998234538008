import { WorkoutDifficultyLevel } from 'modules/miles/app/home/types';

import { theme } from 'styles';

export const getStatusColor = (type: WorkoutDifficultyLevel) => {
  switch (type) {
    case WorkoutDifficultyLevel.easy:
      return theme.miles.colors.dark.icon.positive;
    case WorkoutDifficultyLevel.moderate:
      return theme.miles.colors.dark.icon.primary;
    case WorkoutDifficultyLevel.hard:
      return theme.miles.colors.dark.icon.negative;
    default:
      return 'none';
  }
};
