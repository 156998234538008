import { Goal } from 'models';

export const displayLength = (length: number, onlyMeters: boolean = false): string => {
  return onlyMeters ? `${length} m` : `${length / 1000} km`;
};

export const displayLengthForTable = (length: number, onlyMeters: boolean = false): string | any =>
  onlyMeters ? `${length}` : `${length / 1000}`;

export const displayLengthByGoal = (length: number, goal: GoalType, onlyNumbers: boolean = false): string | any => {
  const goalModel = new Goal(goal);
  return onlyNumbers
    ? displayLengthForTable(length, goalModel.isGoalType('altitude'))
    : displayLength(length, goalModel.isGoalType('altitude'));
};

export const getUnitsByGoal = (goal: GoalType): string => {
  if (!goal) return 'profile.registeredTable.head.km';
  const goalModel = new Goal(goal);
  return goalModel.isGoalType('altitude') ? 'profile.registeredTable.head.m' : 'profile.registeredTable.head.km';
};
