import trim from 'lodash/trim';
import * as React from 'react';

import { InputWrapComponent } from '../inputWrap';

type Props = BaseInputProps & {
  onlyDigits?: boolean;
  shouldTrim?: boolean;
  additionalComponent?: AnyFunction;
};

export const InputComponent = (props: Props): React.ReactNode | any => {
  const {
    requiredMark,
    errorMessage,
    label,
    type,
    placeholder,
    name,
    value,
    disabled,
    readOnly,
    onChange,
    onBlur,
    onKeyPress,
    id,
    autoComplete,
    inputRef,
    onlyDigits,
    shouldTrim,
    additionalComponent,
  } = props;

  return (
    <InputWrapComponent
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={value}
      additionalComponent={additionalComponent}
    >
      <input
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
        ref={inputRef}
        type={onlyDigits ? 'number' : type}
        placeholder={placeholder}
        id={id}
        name={name}
        value={value || ''}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let { value } = e.currentTarget;
          if (onlyDigits && !new RegExp('^[0-9]*$').test(value)) return;
          if (shouldTrim) value = trim(value);
          onChange({ name, value }, e);
        }}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        className='input-component'
        {...(autoComplete ? { autoComplete } : {})}
      />
    </InputWrapComponent>
  );
};
