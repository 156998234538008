import * as React from 'react';
import styled from 'styled-components';

import { HardElevation } from 'src/styledComponents/Elevation';

import { Option } from './Option';

const MenuPopup = styled(HardElevation)<{ open: boolean; isMiles?: boolean }>`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 64px;
  right: 0;
  z-index: 100;

  width: 240px;
  background: ${({ isMiles, theme }) => (isMiles ? theme.miles.colors.dark.background['900'] : theme.miles.colors.dark.alpha['900'])};

  overflow-y: auto;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.07), 0px 0px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 0px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 0px 10.0172px rgba(0, 0, 0, 0.035), 0px 0px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 24px;

  ${(props) => (props.open ? '' : 'display: none;')}
  p,svg {
    color: ${({ isMiles, theme }) => (isMiles ? theme.miles.colors.dark.alpha['900'] : theme.main.colors.clay1)};
  }
  .menu-paper {
    width: 100%;
    padding: 16px 0;
  }
`;

type Props = {
  open: boolean;
  isMiles?: boolean;
  options: Array<DropdownItemType & RequiredTestAnchorProps>;
  value?: DropdownItemType;
  handleSelect: (value: DropdownItemType) => void;
};

export const Menu: React.FC<Props> = (props) => {
  const { open, options, value, handleSelect, isMiles } = props;

  return (
    <MenuPopup level={3} open={open} isMiles={isMiles}>
      <div className='menu-paper'>
        {options.map((item) => (
          <Option
            active={value?.key === item.key}
            label={item.label}
            key={item.key}
            handleSelect={() => handleSelect(item)}
            svgName={item.svgName}
            ATN={item.ATN}
            isMiles={isMiles}
          />
        ))}
      </div>
    </MenuPopup>
  );
};
