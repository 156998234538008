import * as React from 'react';

export const HeaderAccountProfile = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.63965 7.275C7.63965 8.4088 8.09005 9.49616 8.89177 10.2979C9.69348 11.0996 10.7808 11.55 11.9146 11.55C13.0484 11.55 14.1358 11.0996 14.9375 10.2979C15.7392 9.49616 16.1896 8.4088 16.1896 7.275C16.1896 6.1412 15.7392 5.05384 14.9375 4.25212C14.1358 3.4504 13.0484 3 11.9146 3C10.7808 3 9.69348 3.4504 8.89177 4.25212C8.09005 5.05384 7.63965 6.1412 7.63965 7.275V7.275Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.90039 21.0002C3.90039 18.9315 4.75378 16.9475 6.27283 15.4848C7.79187 14.022 9.85214 13.2002 12.0004 13.2002C14.1486 13.2002 16.2089 14.022 17.728 15.4848C19.247 16.9475 20.1004 18.9315 20.1004 21.0002'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
