import { observer } from 'mobx-react';
import { Button, CardWirthGradientBorder, Display, Info, Integrate, Text } from 'modules/miles/shared/components';
import moment from 'moment/moment';
import React from 'react';
import styled from 'styled-components';

import { Hide, Show } from 'components/condition';

import { weeksToGoMessage } from '../../utils';

import { insightsStore } from '../../stores';
import { trainingPlanStore } from '../../stores';

const Wrapper = styled(CardWirthGradientBorder)`
  margin-top: 4px;
  .content {
    padding: 8px;
  }
  .statistic {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    .row {
      display: flex;
      gap: 8px;
      align-items: center;
      &.last {
        color: ${(props) => props.theme.miles.colors.dark.thin.primary};
      }
      .label {
        flex-grow: 1;
        text-align: start;
      }
    }
  }
  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 8px 8px;

    color: ${({ theme }) => theme.miles.colors.dark.alpha['800']};
    &.notice-card {
      padding: 6px 8px;
      border-radius: 4px;
      background: ${(props) => props.theme.miles.colors.dark.alpha['300']};
    }

    .icon {
      display: flex;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  background: ${(props) => props.theme.miles.colors.dark.alpha['500']};
  height: 1px;
`;

const Title = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.miles.colors.dark.alpha['300']};

  .icon {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.miles.colors.dark.alpha['700']};
  }
`;

export const RaceDayCard = observer(() => {
  const isDeviceConnected = true;
  const isBadEstimates = false;
  const currentRaceTime = insightsStore.value?.predicted_race_time.current_prediction;
  const predictedRaceTime = insightsStore.value?.predicted_race_time.after_completing_training;
  const race_date = trainingPlanStore.endDate;
  return (
    <Wrapper>
      <Title>
        <Display weight={'bold'} size={'xs'}>
          RACE DAY
        </Display>
      </Title>

      <Text className='label' weight={'bold'} size={'md'}>
        {weeksToGoMessage(moment().startOf('isoWeek').format('YYYY-MM-DD'), race_date)}
      </Text>
      <Text className='label' weight={'regular'} size={'md'}>
        Race date is {race_date}!
      </Text>

      <Show if={isDeviceConnected && !isBadEstimates}>
        <div className='content'>
          <div className='statistic'>
            <div className='row'>
              <Text className='label' weight={'regular'} size={'md'}>
                Current Estimate
              </Text>
              <Display weight={'bold'} size={'xs'}>
                {currentRaceTime ?? 'N/A'}
              </Display>
            </div>
            <Divider />
            <div className='row last'>
              <Text className='label' weight={'regular'} size={'md'}>
                Prediction on Race Day
              </Text>
              <Display weight={'bold'} size={'xs'}>
                {predictedRaceTime ?? 'N/A'}
              </Display>
            </div>
          </div>
          <div className='notice notice-card'>
            <div className='icon'>
              <Info />
            </div>
            <Text weight={'regular'} size={'md'}>
              By changing the number of training sessions per week you can affect the predicted race time.
            </Text>
          </div>
        </div>
      </Show>

      <Hide if={isDeviceConnected && !isBadEstimates}>
        <div className='notice'>
          <Show if={!isDeviceConnected}>
            <div className='icon'>
              <Integrate />
            </div>
            <Text weight={'regular'} size={'md'}>
              Add your wearable device to get your race time estimate.
            </Text>
            <Button message={'subtle'} size={'xs'} mode={'dark'}>
              Setup
            </Button>
          </Show>
          <Show if={isBadEstimates && isDeviceConnected}>
            <div className='icon'>
              <Info />
            </div>
            <Text weight={'regular'} size={'md'}>
              Complete more sessions to get your race time estimate.
            </Text>
          </Show>
        </div>
      </Hide>
    </Wrapper>
  );
});
