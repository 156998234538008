import axios from 'axios';

import { API_V2_URL, API_RACES_URL } from 'src/constants';

import { action, request } from 'utils';

const ACTION = 'LOAD_RACE';

class FetchRace {
  @request({ action: ACTION })
  loadRaceRequest(id: string | number): Promise<any> {
    return axios.get<any>(API_V2_URL + API_RACES_URL + `/${id}`);
  }

  @action({ action: ACTION })
  async getRaceId(slugOrId: string | null | number | null): Promise<number | void> {
    if (!slugOrId) {
      return;
    }

    const [status, response] = await this.loadRaceRequest(slugOrId);
    const race = response.data.data && response.data.data;

    if (!status || !race) {
      return;
    }
    return race?.id;
  }
}

const fetchRace = new FetchRace();
export { FetchRace, fetchRace };
