import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { Divider } from 'src/styledComponents/Divider';
import { DraggedItem } from 'src/styledComponents/DraggedItem';
import { Utility } from 'src/styledComponents/Typography';

import { Form } from 'components/form';
import { Loading } from 'components/loading';
import { Modal, Body, Content, Controls } from 'components/modal';

import { t } from 'utils';

import { Fields } from '../components/Fields';
import { Title } from '../components/Title';

import { FORM_ID, LOADER, PRELOADER } from '../constants';

import { ignition, onSubmitViaRoute, onCancelViaRoute, mountForm } from '../actions';

import { distanceStore } from '../stores';

type Props = {};

const SaveButton = styled(Button)`
  border: 2px solid ${(props) => props.theme.main.colors.auroraLemon};
  margin-left: 32px;
`;

const DistanceName = styled.div`
  .divider {
    background: ${(props) => props.theme.main.colors.clay4};
    margin: 16px 0 40px;
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    text-align: center;
  }
`;

const Wrapper = styled.div<{ offset: number }>`
  .body {
    bottom: ${(props) => (props.offset > 0 ? -props.offset : 0)}px;
  }
`;

export class RouterWrap extends React.Component<Props> {
  state = {
    offset: 0,
  };

  constructor(props: Props) {
    super(props);
    mountForm();
  }

  componentWillUnmount() {
    ignition.switchOff();
  }

  render() {
    return (
      <Observer>
        {() => (
          <Wrapper className='segments-form container' offset={this.state.offset}>
            <Modal open>
              <Body className='body'>
                <Loading action={PRELOADER} opacity={1} />
                <Loading action={LOADER} />

                <DraggedItem
                  setTopOffset={(offset) => {
                    if (offset > this.state.offset) {
                      this.setState({ offset });
                    }
                  }}
                  onDragEnd={() => {
                    if (this.state.offset > 100) {
                      onCancelViaRoute();
                    } else {
                      this.setState({ offset: 0 });
                    }
                  }}
                />

                <Title />

                <DistanceName>
                  <Utility variant='u2' weight='medium'>
                    {distanceStore.value?.name}
                  </Utility>
                  <Divider className='divider' />
                </DistanceName>

                <Content>
                  <Form id={FORM_ID} onSubmit={onSubmitViaRoute} disableAutofill>
                    <Fields />
                  </Form>
                </Content>

                <Controls>
                  <Button variant='secondary' size='medium' onClick={onCancelViaRoute}>
                    {t('settings.cancel')}
                  </Button>
                  <SaveButton variant='primary' size='medium' onClick={onSubmitViaRoute}>
                    {t('settings.save')}
                  </SaveButton>
                </Controls>
              </Body>
            </Modal>
          </Wrapper>
        )}
      </Observer>
    );
  }
}
