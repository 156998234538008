import React, { FC } from 'react';

import { Checkbox } from '.';
import { InputWrapComponent } from '../InputTools';

type Props = BaseInputProps & {
  labelPosition: 'left' | 'right';
  labelBodyVariant?: 'body1' | 'body2' | 'editorial1' | 'editorial2';
  checkValue?: any;
  isError?: boolean;
  isWarning?: boolean;
};

export const CheckboxWithWrapper: FC<Props> = (props) => {
  const { errorMessage, name, value, disabled, isWarning, className } = props;

  return (
    <InputWrapComponent
      errorMessage={errorMessage}
      isWarning={isWarning}
      disabled={disabled}
      name={name}
      value={value}
      className={className}
    >
      <Checkbox {...props} />
    </InputWrapComponent>
  );
};
