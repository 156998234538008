import { debounce } from 'lodash';
import { useCallback } from 'react';

type AnyFunc = (...args: any[]) => Promise<any>;

const useDebounceLiveUpdate = (fun: AnyFunc, time = 100) => {
  return useCallback(debounce(fun, time), []);
};

export { useDebounceLiveUpdate };
