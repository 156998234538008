import { actionMenuStore } from '../../stores';

import { exportBib } from '../exportData';
import { close } from './display';

const onExportBib = async () => {
  const distance = actionMenuStore.distance;
  if (!distance) return;
  const id = distance.id;
  close();
  let racerId = distance.racer_id;

  // After patching the results racer_id is not set on the distance
  // so we need to get it from the racers array
  if (!racerId && Array.isArray(distance.racers)) {
    racerId = distance.racers[0].id;
  }
  exportBib('pdf', id, racerId);
};

export { onExportBib };
