import { Observer } from 'mobx-react';
import * as React from 'react';

import { Distance } from 'src/models/distance';
import { LeftTableHeader } from 'src/modules/racersTable/components';

import { generateIsBibs } from '../../derivations';
import { UsersCount } from '../UsersCount';

type Props = {
  distance: Distance;
};

export const LeftHeader: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const isBibs = generateIsBibs(distanceId);

  return (
    <Observer>
      {() => {
        const withBib = isBibs.get();

        return <LeftTableHeader withBib={withBib} participantsCount={<UsersCount distanceId={distanceId} />} />;
      }}
    </Observer>
  );
};
