import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Distance } from 'src/models/distance';
import { OneButton } from 'src/modules/race/components/register';
import { isExternalRegistration } from 'src/modules/race/derivations/conditions/isExternalRegistration';
import { Button } from 'src/styledComponents/Button';

import { raceUtil, t } from 'utils';

import { ShowDesktop, ShowMobile } from '../../../../styledComponents/Conditions';
import { Utility } from '../../../../styledComponents/Typography';

type Props = {
  model: Distance;
  onSignUp: Function;
  allowRegistration: boolean;
};

const RegisterButton = styled(Button)`
  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 15px 17px;
  }
  padding: 15px 17px;
`;

export const DistanceButtons = (props: Props) => {
  const { model, onSignUp, allowRegistration } = props;

  const { value } = model;
  const allowed = model.isRegistrationOpen();
  const isExternal = isExternalRegistration.get();

  const getButtons = (model: Distance) => {
    if (model.isTherePendingPayment() && !model.value.allow_no_profile_registration) {
      return <OneButton color='#F2BE00' text={t.staticAsString('manageTeam.awaiting')} />;
    }

    if (!isExternal && value.already_registered) {
      return <OneButton color='#00DB62' text={t.staticAsString('race.registered')} />;
    }

    if (!isExternal && (!allowRegistration || !allowed)) {
      return <OneButton color='#C3C6C8' text={t.staticAsString('race.closed')} />;
    }

    if (raceUtil.isDistanceSoldOut(value) && !isExternal) {
      return <OneButton color='#C3C6C8' text={t.staticAsString('race.sold_out')} />;
    }

    return (
      <RegisterButton size='small' variant='primary' onClick={() => onSignUp(value.id)} className='btn-sign-in' ATN='register'>
        <ShowDesktop>{t.staticAsString('confirmProfile.buttons.register')}</ShowDesktop>
        <ShowMobile>
          <Utility weight='bold' variant='u2'>
            {t.staticAsString('confirmProfile.buttons.register')}
          </Utility>
        </ShowMobile>
      </RegisterButton>
    );
  };

  return getButtons(model);
};
