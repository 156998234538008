import { Auth } from 'modules/raceAuth';
import * as React from 'react';

import { authorizedRace, overlayStore } from 'stores';

export const withRaceAuth = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class extends React.Component<
    any,
    {
      snackId: string | null;
    }
  > {
    state = { snackId: null };

    componentWillUnmount() {
      authorizedRace.clearActive();
    }

    authorizeRace = (race: RacesType) => {
      if (!race) {
        return;
      }

      const { id, is_secret } = race;

      authorizedRace.addOrUpdateValue(id, { isSecret: is_secret });
      authorizedRace.setActive(id);
    };

    _wrapperProps = (): withRaceAuthComponent => {
      const { authorizeRace } = this;

      return {
        authorizeRace,
      };
    };

    render() {
      return (
        <>
          <WrappedComponent {...(this.props as any)} {...this._wrapperProps()} />
          <Auth />
        </>
      );
    }
  } as any;
