import { isObject, isString } from 'lodash';

import { LOCALES, LOCALES_STRING } from 'src/constants';

function toLocaleUrl(path: Location, locale: string) {
  let newPath;

  if (isObject(path) && path.pathname) {
    const pathname = path.pathname.replace(new RegExp(`^\\/(${LOCALES_STRING})`), '');
    let fullPath = `/${locale}${pathname}`;

    fullPath = path.pathname[0] === '/' ? fullPath : path.pathname;

    newPath = {
      ...path,
      pathname: fullPath,
    };
  }

  if (isString(path)) {
    const [empty, firstPathElement, ...rest] = path.split('/');
    const pathname = LOCALES.some((locale) => locale === firstPathElement)
      ? `/${rest.join('/')}`
      : [empty, firstPathElement, ...rest].join('/');
    newPath = `/${locale}${pathname}`;

    newPath = path[0] === '/' ? newPath : path;
  }

  return newPath;
}

export { toLocaleUrl };
