import { profileService } from '../services';

export const handleAddToWish = (id: number) => () => {
  profileService.changeWishList(id);
};

export const handleChangeSavedPage = async (page: number) => {
  window.scrollTo({
    top: 0,
  });
  await profileService.loadSaved(page);
};
