import axios from 'axios';

import { API_URL, API_USER_AVATAR_URL, LOAD_AVATAR } from 'src/constants';
import { action, request } from 'src/utils';

class AccountAvatar {
  @request({ action: LOAD_AVATAR })
  async updateUploadAvatarAPI(avatar: FormData): Promise<any> {
    return axios.post(`${API_URL}${API_USER_AVATAR_URL}`, avatar);
  }

  @action({ action: LOAD_AVATAR })
  async uploadAvatar(data: string): Promise<[boolean, AnyObject]> {
    const resp = await fetch(data);
    const blobAvatar = await resp.blob();
    const formData = new FormData();
    formData.append('avatar', blobAvatar);

    const [status, response]: any = await this.updateUploadAvatarAPI(formData);

    return [status, response.data];
  }
}

const accountAvatar = new AccountAvatar();

export { accountAvatar };
