import axios from 'axios';
import { remove, cloneDeep } from 'lodash';
import { generatePath } from 'react-router-dom';

import { API_URL, API_SEGMENTS, API_SEGMENT, API_DISTANCE_BY_ID, API_SEND_RESULT } from 'src/constants';

import { action, request, t, history, fireToast } from 'utils';

import { GET_SEGMENTS_LIST, GET_DISTANCE, DELETE_SEGMENT, GET_TOTAL_SEGMENTS } from '../constants';

import { mapSegments } from '../utils';

import { segmentsStore } from '../stores';

class Segments {
  @request({ action: GET_SEGMENTS_LIST })
  async getSegmentsRequest(id: number | string, params: AnyObject): Promise<AnyObject> {
    const url = generatePath(`${API_URL}${API_SEGMENTS}`, { id });
    return axios.get(url, { params });
  }

  @action({ action: GET_SEGMENTS_LIST })
  async getSegments(
    id: number | string,
    page: number,
  ): Promise<null | {
    data: any;
    hasMore: boolean;
  }> {
    const params = {
      page: page,
      limit: segmentsStore.limit,
    };

    const [status, response]: any = await this.getSegmentsRequest(id, params);

    if (!status) {
      return null;
    }

    return {
      data: mapSegments(response.data.data),
      hasMore: response.data.meta.pagination.total_pages > response.data.meta.pagination.current_page,
    };
  }

  @request({ action: GET_DISTANCE })
  async getDistanceRequest(id: number | string): Promise<AnyObject> {
    const url = generatePath(`${API_URL}${API_DISTANCE_BY_ID}`, { id });
    const params = {
      with: 'goal',
    };
    return axios.get(url, { params });
  }

  @action({ action: GET_DISTANCE })
  async getDistance(id: number | string): Promise<void> {
    const [status, response]: any = await this.getDistanceRequest(id);

    if (status) {
      segmentsStore.setDistance(response.data.data);
    }
  }

  @request({ action: DELETE_SEGMENT })
  async deleteSegmentRequest(racerId: number, segmentId: number): Promise<any> {
    const url = generatePath(`${API_URL}${API_SEGMENT}`, { racerId, id: segmentId });
    return axios.delete(url);
  }

  @action({ action: DELETE_SEGMENT })
  async deleteSegment(racerId: number, segmentId: number): Promise<void> {
    const [status] = await this.deleteSegmentRequest(racerId, segmentId);

    if (status) {
      const copy = cloneDeep(segmentsStore.list);
      remove(copy, (segment: AnyObject) => segment.id === segmentId);
      segmentsStore.setList(copy);
      fireToast(t.staticAsString('segments.successfullyDeleted'), 'success', t.staticAsString('segments.successfullyDeleted'));
    }

    return status;
  }

  @request({ action: GET_TOTAL_SEGMENTS })
  async getTotalRequest(id: number): Promise<AnyObject> {
    const url = generatePath(API_URL + API_SEND_RESULT, { id });
    return axios.get(url);
  }

  @action({ action: GET_TOTAL_SEGMENTS })
  async getTotal(id: number): Promise<void> {
    const [status, response]: any = await this.getTotalRequest(id);

    if (status) {
      const result = response.data.data;
      if (!result) {
        history.safeGoBack();
        return;
      }

      segmentsStore.setTotalInfo(result);
    }
  }
}

export const segmentsService = new Segments();
