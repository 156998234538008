import styled, { css } from 'styled-components';

import { BodyText, Utility } from 'src/styledComponents/Typography';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  padding: 20px 16px;
  background: linear-gradient(85.23deg, rgba(214, 241, 0, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%),
    linear-gradient(0deg, #272e34, #272e34);
`;

export const SubBox = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledImg = styled('img')`
  margin-right: 16px;
`;

export const StyledTitle = styled(Utility)(
  ({ theme }) => css`
    color: ${theme.main.colors.auroraLemon};
  `,
);
export const StyledText = styled(BodyText)(
  ({ theme }) => css`
    color: ${theme.main.colors.clay6};
  `,
);
export const StyledCloseBtn = styled('div')(
  ({ theme }) => css`
    margin-left: 16px;
    background: ${theme.main.colors.clay2};
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    svg {
      width: 13.5px;

      path {
        stroke-width: 2;
        stroke: ${theme.main.colors.clay4};
      }
    }

    &:hover {
      background: ${theme.main.colors.clay4};

      svg {
        path {
          stroke: ${theme.main.colors.clay2};
        }
      }
    }
  `,
);
