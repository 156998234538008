import { observable, action, makeObservable } from 'mobx';

import { Data } from '../types';

/**
 * @description
 * loaded confirmationd data
 * and loading status
 */
class ConfirmationStore {
  @observable
  tokenData: Data.Backend.RacerToReceive | null = null;
  @observable
  isInvited: boolean = false;
  @observable
  isInvalidInviteToken: boolean = false;
  @observable
  token: string | nil;

  constructor() {
    makeObservable(this);
  }

  @action
  setTokenData(token: string, data: Data.Backend.RacerToReceive | null): void {
    this.tokenData = data;
    this.isInvited = true;
    this.token = token;
  }

  @action
  setInvalidInviteToken() {
    this.isInvited = true;
    this.isInvalidInviteToken = true;
    this.token = null;
  }

  @action
  clean(): void {
    this.tokenData = null;
    this.isInvited = false;
    this.isInvalidInviteToken = false;
    this.token = null;
  }
}

export { ConfirmationStore };
