import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';

type Props = {
  isMobile?: boolean;
  avatar: string | nil;
  onChangeFile: (event: ChangeEventHandler<HTMLInputElement> | any) => void;
};

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AvatarBlock = styled.div`
  position: relative;
`;

const Avatar = styled.div<{ size: number | string }>`
  display: flex;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  justify-content: center;
  align-items: center;

  border: 2px solid ${(props) => props.theme.main.colors.clay1};
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarUploadButton = styled.label<{ isMobile?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (!props.isMobile ? 32 : 24)}px;
  height: ${(props) => (!props.isMobile ? 32 : 24)}px;
  background: ${(props) => props.theme.main.colors.clay5};
  border: 1.5px solid ${(props) => props.theme.main.colors.clay1};
  border-radius: 50%;
  overflow: hidden;

  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.main.colors.clay3};
  }

  input {
    display: none;
  }
`;

const AvatarUpload: FC<Props> = (props) => {
  const { avatar, onChangeFile, isMobile } = props;

  return (
    <React.Fragment>
      <AvatarWrapper>
        <AvatarBlock>
          <Avatar size={!isMobile ? 120 : 64}>
            {avatar ? (
              <img src={avatar} alt='avatar' style={{ width: '100%', objectFit: 'cover' }} />
            ) : (
              <Icon name='User' size={!isMobile ? 48 : 32} />
            )}
          </Avatar>
          <AvatarUploadButton
            isMobile={isMobile}
            htmlFor='avatar-upload'
            {...testAnchors.button('Profile.Account-Information', 'avatar', 'upload')}
          >
            <Icon name='Photo' size={!isMobile ? 24 : 18} />
            <input id='avatar-upload' type='file' accept='image/*' onChange={onChangeFile} />
          </AvatarUploadButton>
        </AvatarBlock>
      </AvatarWrapper>
    </React.Fragment>
  );
};

export { AvatarUpload };
