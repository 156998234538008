import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { resultsPresentation } from '../../derivations';
import type { LResult } from '../../types';

type Props = {
  result: LResult;
  checkpointId: number;
  checkpointIndex: number;
};

function PreviousSplitDiff({ result, checkpointId, checkpointIndex }: Props) {
  const generator = resultsPresentation.generatePreviousSplitDiff(result, checkpointId, checkpointIndex);
  return (
    <Observer>
      {() => {
        return <>{generator.get()}</>;
      }}
    </Observer>
  );
}

export { PreviousSplitDiff };
