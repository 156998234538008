import axios from 'axios';
import { sortedUniq } from 'lodash';
import { generatePath } from 'react-router-dom';

import { API_URL, API_DISTANCE_V2 } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_DISTANCES_FOR_TICKETS } from '../constants';

import { distancesStore } from '../stores';

class DistancesService {
  @request({ action: LOAD_DISTANCES_FOR_TICKETS })
  async loadDistancesRequest(id: number | string): Promise<Object> {
    const url = generatePath(API_URL + API_DISTANCE_V2, { id });
    return axios.get(url);
  }

  @action({ action: LOAD_DISTANCES_FOR_TICKETS })
  async loadDistances(IDList: Array<number | string> = []): Promise<void> {
    const list = sortedUniq(IDList);
    const data = await Promise.all(list.map((id) => this.loadDistancesRequest(id)));

    const distances = (data || []).reduce((result: any, current) => {
      if (current as any[0]) {
        return [...result, (current as any)[1].data.data];
      }

      return result;
    }, []);

    distancesStore.setValue(distances || []);
  }
}

export const distancesService = new DistancesService();
