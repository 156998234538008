// @ts-nocheck
import isEmpty from 'lodash/isEmpty';
import { observable, computed, makeObservable } from 'mobx';

import { ClassModel } from 'models/classes';
import { Distance as DistanceModel } from 'models/distance';

import { Loadable } from 'stores/loadable';

// Deprecated, DO NOT USE IT
class Distances extends Loadable<DistanceType, FiltersType> {
  declare filters: FiltersType;

  defaultFilters: FiltersType = {
    limit: 9999,
    with: 'classes;checkpoints;waves;disciplines;registration_fields',
  };

  searchJoin = 'AND';
  sortBy = 'asc';
  orderBy = 'index';

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.defaultFilters;
  }

  @computed
  get modelValues(): Array<DistanceModel> {
    return (this.values || []).map((distance) => new DistanceModel(distance));
  }

  @computed
  get modelSelectedValue(): DistanceModel | Object {
    return new DistanceModel(this.selected);
  }

  @computed
  get allClasses(): Array<ClassModel> {
    return this.modelValues.reduce((acc, value) => {
      if (!value || !value.classes) {
        return acc;
      }

      return [...acc, ...value.classes];
    }, []);
  }

  @computed
  get distinctClasses(): Array<ClassModel> {
    const { modelValues } = this;
    const similarValuesTable = {};

    modelValues.forEach((value) => {
      (value.classes || []).forEach((modelClass) => {
        similarValuesTable[modelClass.hashForCompare] = [...(similarValuesTable[modelClass.hashForCompare] || []), modelClass];
      });
    });

    const distinctClasses = [];

    Object.keys(similarValuesTable).forEach((hash) => {
      const similarClasses = similarValuesTable[hash];

      if (!similarClasses.length) {
        return;
      }

      const idValues = similarClasses.map((el) => el.value.id);
      const id = ClassModel.serializeSimilarClassIds(idValues);
      const distinctValue = new ClassModel({ ...similarClasses[0].value, id });

      distinctClasses.push(distinctValue);
    });

    return distinctClasses;
  }

  @computed
  get distancesWithWaves(): Object {
    let distancesWithWaves = {
      [-1]: false,
    };
    this.values.forEach((value) => {
      const emptyWaves = isEmpty(value.waves);

      distancesWithWaves = {
        ...distancesWithWaves,
        [value.id]: !emptyWaves,
      };

      if (!distancesWithWaves[-1] && !emptyWaves) {
        distancesWithWaves[-1] = true;
      }
    });

    return distancesWithWaves;
  }
}

export { Distances };
export default new Distances();
