import { parseRaceId } from '../utils/parseRaceId';

import { raceService } from '../services/raceService';

import { raceStore } from '../stores';

export async function loadRace() {
  const raceId = Number(parseRaceId());
  if (!raceId) {
    return;
  }
  const race = await raceService.load(raceId);
  raceStore.setValue(race);
}
