import { openConfirmModalStore, openDeleteModalStore, openModalStore } from 'src/modules/transferRegistration/stores';

import { clearForm } from '.';

export const openFormModal = () => {
  openModalStore.on();
};

export const closeFormModal = () => {
  openModalStore.off();
};

export const onCancel = () => {
  openModalStore.off();
  clearForm();
};

export const openConfirmModal = () => {
  closeFormModal();
  openConfirmModalStore.on();
};

export const closeConfirmModal = () => {
  openConfirmModalStore.off();
  clearForm();
};

export const openDeleteFormModal = () => {
  openDeleteModalStore.on();
};

export const closeDeleteFormModal = () => {
  openDeleteModalStore.off();
  clearForm();
};
