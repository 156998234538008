import classNames from 'classnames';
import * as React from 'react';
import shortId from 'shortid';

import { CHANGE_WISHLISTS, ROUTER, DEFAULT_RACE_IMAGE } from 'src/constants';

import { AnimateImgComponent } from 'components/animateImg';
import { RaceDateComponent } from 'components/raceDate';
import { SportTypesComponent } from 'components/sportTypes';
import { WhishAddComponent } from 'components/wish';

import { raceUtil, t } from 'utils';

import { wishlistsService } from 'services';

type Props = {
  race: AnyObject;
  wishlists: Array<Number>;
};

const distanceItem = (distance: AnyObject): React.ReactNode => {
  return <li key={'distance' + shortId()}>{raceUtil.humanizeDistance(distance.race_length || distance.length)}</li>;
};

const PastRacesDivider = () => {
  return (
    <div className='prev-races-divider'>
      <p>{t('race.pastRaces')}</p>
    </div>
  );
};
const getDistances = (race: AnyObject): React.ReactNode => {
  return (race.distances || []).map((distance: AnyObject) => distanceItem(distance));
};

const RaceCardItemComponent = (props: Props): any => {
  const { race, wishlists } = props;
  const sportIcon = race.value.sport ? race.value.sport.icon : '';
  const isChecked = wishlists.includes(race.value.id);
  const isPast = race.isRaceInPast;
  const location = race.location && race.location.cityOrCountry();

  return (
    <figure className='race-card-item'>
      <SportTypesComponent sportIcon={sportIcon} />
      <AnimateImgComponent
        mutedOverlay={!!isPast}
        bg={`${race.value.image_default || DEFAULT_RACE_IMAGE}`}
        link={`${ROUTER.RACES}/${race.value.id}/about`}
      />
      <figcaption
        className={classNames('race-card-item-content', {
          'past-race': isPast,
        })}
      >
        <RaceDateComponent showCalendar={false} dates={race.compareDate()} />
        <h4 className='title' title={race.value.name}>
          {race.value.name}
        </h4>
        <p className='location'>{location}</p>

        <ul className='distances'>{getDistances(race.value)}</ul>
      </figcaption>
      <div className='follow'>
        <div className='following'>
          <WhishAddComponent
            isFollow={isChecked}
            actionString={CHANGE_WISHLISTS}
            handleClick={() => wishlistsService.changeFavoriteRace(race.value)}
          />
        </div>
      </div>
    </figure>
  );
};

export { PastRacesDivider };
export { RaceCardItemComponent };
