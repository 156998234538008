import { keys, compact } from 'lodash';

/**
 * @description
 * Process object as follows:
 *
 * From:
 * { [someKey]: value }
 * To:
 * { [prefix.someKey]: value }
 */
function prependPrefixToObject<T extends AnyObject>(prefix: string | nil, value: T): T {
  const objKeys = keys(value);

  return objKeys.reduce((remapedValue, key) => {
    const newKey = compact([prefix, key]).join('.');
    remapedValue[newKey] = value[key];

    return remapedValue;
  }, {}) as T;
}

export { prependPrefixToObject };
