import * as React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'src/styledComponents/Checkbox';
import { InputWrapComponent } from 'src/styledComponents/InputTools';

const StyledInputWrapper = styled(InputWrapComponent)`
  .multi-checkbox-wrap {
    > div label {
      padding-bottom: 0;
    }

    > div:not(:last-child) label {
      padding-bottom: 16px;
    }
  }
`;

type Props = {
  additionalComponent?: AnyFunction;
} & MultiCheckboxType;

export class MultiCheckboxComponent extends React.Component<Props> {
  static defaultProps = {
    value: [],
  };

  handleCheckboxList = (): React.ReactNode => {
    const { checkboxList, name, disabled } = this.props;

    return checkboxList.map((checkbox) => {
      return (
        <Checkbox
          id={`${name}-${checkbox.value}`}
          key={`${checkbox.value}`}
          name={checkbox.label}
          label={checkbox.label}
          value={this.isChecked(checkbox.value)}
          onChange={(data) => this.handleChange({ ...data, id: checkbox.value })}
          labelPosition='right'
          disabled={disabled}
        />
      );
    });
  };

  handleChange = (checkbox: AnyObject) => {
    const { onChange, name } = this.props;

    let currentSelection = this.props.value ? [...this.props.value] : [];

    let result;
    if (checkbox.value) {
      currentSelection.push(Number(checkbox.id));
    } else {
      currentSelection = currentSelection.filter((val) => val !== Number(checkbox.id));
    }

    result = {
      name,
      value: currentSelection.length ? currentSelection : undefined,
    };
    onChange(result);
  };

  isChecked = (checkbox_id: number | any): boolean | any => {
    const { value } = this.props;

    if (!(value || []).length) {
      return false;
    }

    return value.findIndex((item: AnyObject | any) => parseInt(item, 10) === checkbox_id) >= 0;
  };

  render(): React.ReactNode {
    const { additionalComponent, requiredMark, errorMessage, label, name, disabled } = this.props;

    return (
      <StyledInputWrapper
        additionalComponent={additionalComponent}
        errorMessage={errorMessage}
        disabled={disabled}
        label={label}
        requiredMark={requiredMark}
        name={name}
      >
        <div className='multi-checkbox-wrap'>{this.handleCheckboxList()}</div>
      </StyledInputWrapper>
    );
  }
}
