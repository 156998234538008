import { isEmpty } from 'lodash';
import { action, computed, observable, makeObservable } from 'mobx';
import { RegistrationStatus } from 'modules/registration/constants';

import { Data } from '../types';

class PaymentStore {
  @observable
  token: string | nil;

  @observable
  registration_uuid: string | nil;

  @observable
  registrationStatus: RegistrationStatus | nil;

  @observable
  paymentUuid: string | nil;

  @observable
  operations: Data.Backend.OperationFromBE[] | nil;

  @observable
  registrationTTL: number | nil;

  @observable
  payment_method_order: string[] | nil;

  constructor() {
    makeObservable(this);
  }

  @action
  startPayment(
    token: string,
    operations: Data.Backend.OperationFromBE[],
    payment_method_order: string[],
    registration_uuid?: string,
    paymentUuid?: string,
  ) {
    this.token = token;
    this.operations = operations;
    this.registration_uuid = registration_uuid;
    this.paymentUuid = paymentUuid;
    this.payment_method_order = payment_method_order;
  }

  @action
  endPayment() {
    this.token = null;
    this.operations = null;
    this.registration_uuid = null;
    this.paymentUuid = null;
  }

  @action
  setRegistrationTTL(value: number | null) {
    this.registrationTTL = value;
  }

  @action
  setRegistrationStatus(value: RegistrationStatus | null) {
    this.registrationStatus = value;
  }

  @action
  clear() {
    this.endPayment();
    this.registrationTTL = null;
  }

  @computed
  get isPaymentInProgress() {
    return !isEmpty(this.token && this.operations);
  }

  @computed
  get isRedirectPaymentCompleted() {
    return this.registrationStatus === RegistrationStatus.completed;
  }
}

export { PaymentStore };
