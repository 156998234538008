import { mapSegments, parseUrlParams } from '../utils';

import { segmentService } from '../services';

import { segmentsStore } from '../stores';

async function actualizeSegment(id: number) {
  const { racerId } = parseUrlParams();

  if (!racerId || !id) {
    return;
  }

  const segment = await segmentService.load(racerId, id);

  if (!segment) {
    return;
  }

  segmentsStore.updateSegmentById(id, mapSegments([segment])[0]);
}

export { actualizeSegment };
