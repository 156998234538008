import { action, observable, makeObservable } from 'mobx';

type Time = {
  hours?: number | string;
  minutes?: number | string;
  seconds?: number | string;
};

class SeparatedTime {
  @observable
  time: Time = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setTime(value: Time): void {
    this.time = {
      ...this.time,
      ...value,
    };
  }

  @action
  parseString(value?: string): void {
    const time = value ? value.split(':') : [];

    this.time = {
      hours: time[0],
      minutes: time[1],
      seconds: time[2],
    };
  }

  @action
  clear(): void {
    this.time = {};
  }
}

export const separatedTimeStore = new SeparatedTime();
