import { countriesStore } from 'stores';

function findCountryCode(id: number): void | null | string {
  const code = countriesStore.findById(id)?.alpha_2_code;

  if (!code) {
    return null;
  }

  return code.toLowerCase();
}

export { findCountryCode };
