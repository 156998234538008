import { Observer } from 'mobx-react';
import { DialogHelperModal } from 'modules/auth';
import * as React from 'react';

import { openModalStore } from '../stores';

const ModalContainer = () => {
  return (
    <Observer>
      {() => {
        if (!openModalStore.value) return null;
        return <DialogHelperModal open={openModalStore.value} />;
      }}
    </Observer>
  );
};

export { ModalContainer };
