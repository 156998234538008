import { paginationMetaStore, dataStore } from '../stores';

function isDistanceHasMore(distanceId: number) {
  const lastPage = dataStore.lastPage(distanceId);
  const meta = paginationMetaStore.fetch(distanceId);
  const totalPages = meta?.total_pages;

  if (!lastPage || !totalPages) {
    return true;
  }

  return totalPages > lastPage;
}

export { isDistanceHasMore };
