import * as React from 'react';
import { use100vh } from 'react-div-100vh';
import styled, { css } from 'styled-components';

import { HardElevation } from 'src/styledComponents/Elevation';
import { scrollBarStyles } from 'src/styles';

import { Option } from './Option';

type StyleProps = {
  open: boolean;
  topOrientated: boolean;
  height: number;
  showDividerAfterOptionNumber?: number;
};

const MAX_HEIGHT = 464;
const FIELD_OFFSET = 56;

const getTopProperty = (props) => {
  return props.topOrientated
    ? css`
        bottom: ${FIELD_OFFSET}px;
      `
    : css`
        top: 100%;
      `;
};

const MenuPopup = styled(HardElevation)<StyleProps>`
  display: flex;
  flex-direction: column;

  position: absolute;
  ${getTopProperty};
  left: 0;
  z-index: 100;

  width: 100%;
  max-height: ${(props) => props.height}px;
  background: ${(props) => props.theme.main.colors.white};
  border-radius: 2px;
  overflow-y: auto;

  ${(props) => (props.open ? '' : 'display: none;')}
  ${scrollBarStyles}

  .menu-paper {
    width: 100%;
    padding: 8px 0;
      ${(props) =>
        props.showDividerAfterOptionNumber &&
        css`
          > div:nth-child(${props.showDividerAfterOptionNumber}) {
              border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
      `}} 
  }
`;

type Props = {
  open: boolean;
  options: DropdownItemType[];
  value?: DropdownItemType;
  handleSelect: (value: DropdownItemType) => void;
  className?: string;
  suggestionOptionsCount?: number;
};

export const Menu: React.FC<Props> = (props) => {
  const { className, open, options, value, handleSelect, suggestionOptionsCount } = props;
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const viewportHeight = use100vh();

  const [topOrientated, setOrientation] = React.useState(false);
  const [height, setHeight] = React.useState(MAX_HEIGHT);

  React.useEffect(() => {
    if (open && wrapRef.current?.parentElement && viewportHeight) {
      const position = wrapRef.current.parentElement.getBoundingClientRect();
      const offsetTop = position.y;
      const offsetBottom = viewportHeight - position.height - position.y;

      const topAdditionalSpace = offsetTop - MAX_HEIGHT;
      const bottomAdditionalSpace = offsetBottom - MAX_HEIGHT;
      const orientation = topAdditionalSpace > bottomAdditionalSpace;

      setOrientation(orientation);
      setHeight(Math.min(MAX_HEIGHT, orientation ? offsetTop : offsetBottom));
    }
  }, [open]);

  return (
    <MenuPopup
      level={3}
      open={open}
      ref={wrapRef}
      topOrientated={topOrientated}
      height={height}
      className='menu-wrapper'
      showDividerAfterOptionNumber={options.length - Number(suggestionOptionsCount) > 0 ? suggestionOptionsCount : 0}
    >
      <div className={className}>
        {options.map((item) => (
          <Option
            active={value?.key === item.key}
            label={item.label}
            key={item.key}
            handleSelect={() => handleSelect(item)}
            disabled={item.disabled}
          />
        ))}
      </div>
    </MenuPopup>
  );
};
