import Xregexp from 'xregexp';

// all project regex in here

export const TIME_PATTERN: RegExp = Xregexp(
  `
    ^
    (?<hours>
      (?<h1Char>\\d) # First digit of the hour
      (?<h2Char>\\d) # Second digit of the hour
    )?
    :
    (?<minutes>
      (?<m1Char>\\d) # First digit of the minute
      (?<m2Char>\\d) # Second digit of the minute
    )?
    :
    (?<seconds>
      (?<s1Char>\\d) # First digit of the second
      (?<s2Char>\\d) # Second digit of the second
    )?
    $
  `,
  'ix',
);
