import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { progressStore } from 'src/stores';
import { Skeleton } from 'src/styledComponents/Skeleton';

type Props = {
  actions: string[];
  withFakePlace?: boolean;
  children?: React.ReactNode;
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 447px 1fr;
  padding-bottom: 105px;
`;

const DistanceNameWrap = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 40px;
  background-color: white;
`;

const LeftHead = styled.div<{ withFakePlace?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.withFakePlace ? '60px 60px 1fr' : '60px 1fr')};
  gap: 8px;
  padding: 8px 40px 16px;
`;

const RightHead = styled(LeftHead)`
  grid-template-columns: 1fr 60px 60px;
  gap: 32px;
`;

const LeftRow = styled.div<{ withFakePlace?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.withFakePlace ? '60px 60px 1fr' : '60px 1fr')};
  gap: 8px;
  padding: 0 40px;
  height: 64px;
  align-items: center;

  &:nth-child(even) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

const RightRow = styled(LeftRow)`
  grid-template-columns: 1fr 60px 60px;
  gap: 32px;
`;

export const DesktopSkeleton: React.FC<Props> = (props) => {
  const { actions = [], children, withFakePlace = false } = props;

  return (
    <Observer>
      {() => {
        const isLoading = actions.some((item) => progressStore.isLoading(item));

        const Loader = !isLoading ? null : (
          <Wrapper>
            <div>
              <LeftHead withFakePlace={withFakePlace}>
                <Show if={withFakePlace}>
                  <Skeleton height={24} />
                </Show>
                <Skeleton height={24} />
                <Skeleton height={24} />
              </LeftHead>
              <DistanceNameWrap>
                <Skeleton height={14} />
              </DistanceNameWrap>

              {[...Array(8).keys()].map((item, index) => (
                <LeftRow withFakePlace={withFakePlace} key={`loader-${item}-${index}`}>
                  <Show if={withFakePlace}>
                    <Skeleton height={24} />
                  </Show>
                  <Skeleton height={24} />
                  <Skeleton height={24} />
                </LeftRow>
              ))}
            </div>
            <div>
              <RightHead>
                <div />
                <Skeleton height={24} />
                <Skeleton height={24} />
              </RightHead>
              <DistanceNameWrap />

              {[...Array(8).keys()].map((item, index) => (
                <RightRow key={`right-loader-${item}-${index}`}>
                  <div />
                  <Skeleton height={24} />
                  <Skeleton height={24} />
                </RightRow>
              ))}
            </div>
          </Wrapper>
        );

        return (
          <>
            {Loader}
            {children}
          </>
        );
      }}
    </Observer>
  );
};
