import classNames from 'classnames';
import * as React from 'react';

import { Utility } from 'src/styledComponents/Typography';

type Props = {
  value: SelectItemType;
  onChange: Function;
  isActive: boolean;
  isLast: boolean;
  index: number;
};

function Item({ value, onChange, isActive, index, isLast }: Props) {
  const stepNumber = index + 1;

  return (
    <li key={value.id}>
      <label htmlFor={`step-item-${index}`} className={classNames('step-item', { 'is-active': isActive })}>
        <input
          id={`step-item-${index}`}
          type='radio'
          name='step-item'
          checked={isActive}
          onChange={(e) => onChange(value, e)}
          {...testAnchors.useLink(`step${stepNumber}`)}
        />
        <div className='step-item-count-wrap'>
          <Utility variant='u2' weight='medium' className='step-item-count'>
            {stepNumber}
          </Utility>
        </div>
      </label>
    </li>
  );
}

export { Item };
