import * as React from 'react';

export const PinEllipse = (
  <svg width='32' height='45' viewBox='0 0 32 45' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M32 16C32 23.6631 19.9673 40.0953 16.7715 44.3243C16.3792 44.8434 15.6208 44.8434 15.2285 44.3243C12.0327 40.0953 0 23.6631 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z'
      fill='#272E34'
    />
  </svg>
);
