import { observable, computed, makeObservable, override } from 'mobx';

import { SportType as SportTypeModel } from 'models/sportType';

import { Loadable } from 'stores/loadable';

class SportType extends Loadable<SportTypesType, FiltersType> {
  declare filters: FiltersType;

  @observable
  valuesById: Object = {};

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      limit: 100,
      withCount: 'races',
      orderBy: 'index',
      sortedBy: 'asc',
    };
  }

  @computed
  get modelValues(): Array<SportTypeModel> {
    return (this.values || []).map((sportType) => new SportTypeModel(sportType));
  }

  @override
  addValues(values: Array<SportTypesType>, page: number, filters: FiltersType): void {
    super.addValues(values, page, filters);
    this.valuesById = values.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item,
      };
    }, {});
  }

  modelValue(sportType: SportTypesType): SportTypeModel {
    return new SportTypeModel(sportType);
  }
}

export { SportType };
export default new SportType();
