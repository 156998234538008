import axios from 'axios';

import { MILES_INSIGHTS } from 'src/constants';

import { action, request } from 'utils';

import { GET_INSIGHTS } from '../constants';

import { insightsStore } from '../stores';

export class Insights {
  @request({ action: GET_INSIGHTS })
  private getInsightsRequest(): Promise<any> {
    return axios.get(MILES_INSIGHTS);
  }

  @action({ action: GET_INSIGHTS })
  async getInsights() {
    const [isOk, data] = await this.getInsightsRequest();
    if (isOk) {
      insightsStore.setValue(data.data);
    }
  }
}

export const insightsService = new Insights();
