import { computed } from 'mobx';

import { resultsUtil } from '../../utils';

import { timeLeadersStore } from '../../stores';

/**
 * @description
 * Returns result.time_result for the result with 1 place(or first in the 1 page)
 */
function generateLeadersTime(distanceId: number, checkpointId: number | nil) {
  return computed((): number | nil => {
    const result = timeLeadersStore.fetch(distanceId);
    if (!result) {
      return null;
    }

    return resultsUtil.fetchBEDiff(result, checkpointId);
  });
}

export { generateLeadersTime };
