import * as React from 'react';

export const ZoomPlus = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.16663 10H15.8333' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 15.8334V4.16675' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export const ZoomMinus = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.16663 10H15.8333' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
