import * as React from 'react';
import styled from 'styled-components';

import { ProgressBar } from 'src/components/loadingBar/ProgressBar';
import { SubTitle } from 'src/components/loadingBar/SubTitle';
import { Title } from 'src/components/loadingBar/Title';
import { PropsMobile } from 'src/modules/tourProfileManagment/components/Header/styled';

import { t } from 'utils';

import { windowSize } from 'stores';

type Props = {
  progress: number;
  timeLeft: number;
  fileName: string;
  upload: boolean;
  isFinished: boolean;
  isCancelled: boolean;
  isError: boolean;
  noConnection: boolean;
  onCancel: AnyFunction;
  onRetry: AnyFunction;
};

const Wrapper = styled.div<PropsMobile>`
  width: 459px;
  height: 148px;
  padding: 32px 32px 35px;

  ${(props) => props.isMobile && WrapperStyledMobile}
`;

const WrapperStyledMobile = `
  width: 352px;
`;

export const Content = (props: Props) => {
  const { progress, timeLeft, onCancel, upload, isFinished, fileName, noConnection, isCancelled, isError, onRetry } = props;
  const isMobile = windowSize.isLessThan('small');
  const progressStr = t('shared.loadPopup.progressLeft', { value: 100 - Math.floor(progress) }) as any;
  const remainingTime = t('shared.loadPopup.remainingTime', { time: Math.round(timeLeft) }) as any;
  const showPercentsDownload = !isFinished && !isError && !isCancelled && !noConnection;

  const getTitleText = () => {
    let key: any = '';

    if (showPercentsDownload) {
      return upload
        ? t('shared.loadPopup.upload.title', { name: fileName })
        : (t('shared.loadPopup.download.title', { name: fileName }) as any);
    }

    if (isFinished) {
      key = upload ? 'shared.loadPopup.uploadComplete' : 'shared.loadPopup.loadComplete';
      return t.staticAsString(key) as any;
    }

    if (noConnection) {
      key = 'No connection';

      return t.staticAsString(key) as any;
    }

    if (isFinished) {
      key = upload ? 'shared.loadPopup.uploadCancelled' : 'shared.loadPopup.loadCancelled';
      return t.staticAsString(key) as any;
    }

    if (isError) {
      key = upload ? 'shared.loadPopup.uploadError' : 'shared.loadPopup.loadError';
      return t.staticAsString(key) as any;
    }
  };

  return (
    <Wrapper isMobile={isMobile}>
      <Title text={getTitleText()} onCancel={onCancel} showCloseIcon={showPercentsDownload} />
      <SubTitle progress={progressStr} remainingTime={remainingTime} />
      <ProgressBar progress={progress} />
    </Wrapper>
  );
};
