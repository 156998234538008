import { getRaceId } from '../../utils';

import { counterService } from '../../services';

import { params } from '../../stores';

import { dataChanger } from '../../mutations';

async function loadCounter() {
  const counter = await counterService.load(Number(getRaceId()), params.count());
  dataChanger.setCounter(counter);
}

export { loadCounter };
