import { cache } from './cache';
import { existsFun } from './exists';
import { fetchFun } from './fetch';
import { mapToFeFormat } from './mapper';
import { store } from './store';
import { version } from './version';

/*
 * Store loaded messages, manage version
 */

function initFun() {
  version.init();
  const translations = store.fetch();
  cache.store(translations || ({} as Translations));
}

function storeFun(newVersion: number, rawTranslations: ApiTranslations) {
  const translations = mapToFeFormat(rawTranslations);
  version.store(newVersion);
  cache.store(translations);
  store.store(translations);
}

function clearFun() {
  cache.clear();
  store.clear();
}

export const messages = {
  version,
  init: initFun,
  fetch: fetchFun,
  store: storeFun,
  clear: clearFun,
  exists: existsFun,
};
