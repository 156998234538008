import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { MobileDistanceHeader, MobileHeader } from 'src/modules/racersTable/components';

import { Show } from 'components/condition';

import { MobileRacerList } from '../../components/Mobile';
import { UsersCount } from '../../components/UsersCount';

import { distancesStore } from '../../stores';

import { currentDistance, data, isAnyDistanceHasBibs } from '../../derivations';

export const MobileAllLists = () => {
  return (
    <Observer>
      {() => {
        const distance = currentDistance.model.get();
        if (distance) return null;
        const hasBibs = isAnyDistanceHasBibs.get();

        return (
          <>
            <MobileHeader isTeam={false} participantsCount={<UsersCount />} hasBibs={hasBibs} />
            {distancesStore.filteredModelValues.map((distance) => {
              const distanceId = distance.value.id;
              const show = !isEmpty(data.generateDataEntries(distanceId).get());
              return (
                <Show if={show}>
                  <MobileDistanceHeader name={distance.title()} />
                  <MobileRacerList distanceId={distanceId} type={distance.value.type} />
                </Show>
              );
            })}
          </>
        );
      }}
    </Observer>
  );
};
