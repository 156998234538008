import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { isReceipt } from '../../utils';

import type { RegisteredDistanceType } from '../../types';

function generateIsMenuEnabled(distance: RegisteredDistanceType): IComputedValue<boolean> {
  return computed(() => {
    return isReceipt(distance) || !distance.deleted_at;
  });
}

export { generateIsMenuEnabled };
