import { observer } from 'mobx-react';
import { TrainingPlanType } from 'modules/miles/app/home/stores/trainingPlan';
import { Divider } from 'modules/miles/app/home/ui/TrainingPlan/Divider';
import { TrainDayCard } from 'modules/miles/app/home/ui/TrainingPlan/TrainDayCard';
import { Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

const Title = styled.div`
  padding: 8px 16px;
  .divider-box {
    padding: 0 16px;
  }
  .week {
    text-transform: capitalize;
  }
  .month {
    color: ${(props) => props.theme.miles.colors.dark.alpha['700']};
    letter-spacing: 0.7px;
    text-transform: uppercase;
    &:empty {
      padding: 12px 0;
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 0 16px;
`;
type Props = {
  label: string;
  month?: string;
  trainingPlan: TrainingPlanType[];
};
export const WeekPlanBlock: React.FC<Props> = observer(({ label, month, trainingPlan }) => {
  return (
    <div>
      <Title>
        <div className='divider-box'>
          <Divider>
            <Text className='week' weight='regular' size='lg'>
              {label}
            </Text>
          </Divider>
        </div>
        <Text className='month' weight='regular' size='sm'>
          {month}
        </Text>
      </Title>

      <Box>
        {trainingPlan.map((dayWorkout) => (
          <TrainDayCard key={dayWorkout.id} hasUpdate={false} trainData={dayWorkout} />
        ))}
      </Box>
    </div>
  );
});
