import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import { raceUtil } from 'utils';

import { sessionStore, windowSize } from 'stores';

import { targetRaceService } from './services';
import { convertDaysToWeeksAndDaysString } from './utils';

type DetailsData = {
  raceName: string;
  distanceName: string;
  image: string;
  date: string;
  length: string;
  timeLeft: string;
};

export const useRaceDetails = () => {
  const [detailsData, setDetailsData] = useState<DetailsData | AnyObject>({});

  const fetchData = async () => {
    const userId = sessionStore.getUserData().id;
    const suggestedRace = await targetRaceService.loadSuggestedRace(userId);
    if (!suggestedRace) return;
    setDetailsData({
      raceName: suggestedRace?.race.name,
      distanceName: suggestedRace?.name,
      image: suggestedRace?.race.image,
      date: moment(suggestedRace?.race_date).format('MMMM Do'),
      length: raceUtil.humanizeDistance(Number(suggestedRace?.race_length)),
      timeLeft: suggestedRace?.days_left ? convertDaysToWeeksAndDaysString(suggestedRace.days_left) : null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return detailsData;
};

export const useImageWidth = () => {
  const [pictureWidth, setPictureWidth] = useState(440);
  const pictureRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const windowWidth = windowSize.value.width;

  useEffect(() => {
    setPictureWidth(pictureRef.current?.clientWidth || 440);
  }, [windowWidth]);

  return { pictureWidth, pictureRef };
};
