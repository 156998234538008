import * as React from 'react';
import { useIntercom } from 'react-use-intercom';

import { cookieBannerStore } from 'src/stores';

import { mapUser, mapLocale, mapInitSettings } from './mapper';

// ! Scheduled for removal after 7.1.1 release, replaced by the HubSpot integration
// ! Look at the frontend/web/src/stores/hubstop.ts

let isBooted = false;
let isShowed = false;

function boot() {
  const intercomInternalTool = useIntercom();

  if (isBooted) {
    return;
  }

  intercomInternalTool.boot(mapInitSettings());
  isBooted = true;
}

function shutdown() {
  const intercomInternalTool = useIntercom();

  intercomInternalTool.shutdown();
  isBooted = false;
}

function useShowIntercom() {
  const intercomInternalTool = useIntercom();

  React.useEffect(() => {
    isShowed = true;
    if (!cookieBannerStore.value) {
      intercomInternalTool.update({ hideDefaultLauncher: false });
    }

    return () => {
      intercomInternalTool.update({ hideDefaultLauncher: true });
      isShowed = false;
    };
  }, []);
}

function useCookiesHideIntercom() {
  const intercomInternalTool = useIntercom();

  React.useEffect(() => {
    return () => {
      if (isShowed) {
        intercomInternalTool.update({ hideDefaultLauncher: false });
      }
    };
  }, []);
}

function updateIntercomUser() {
  const intercomInternalTool = useIntercom();

  intercomInternalTool.update(mapUser());
}

function updateIntercomLocale() {
  const intercomInternalTool = useIntercom();

  intercomInternalTool.update(mapLocale());
}

export const intercom = {
  boot,
  shutdown,
  useShowIntercom,
  useCookiesHideIntercom,
  updateIntercomUser,
  updateIntercomLocale,
};
