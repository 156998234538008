import { openConfirmation } from 'modules/confirmation';

import { ROUTER } from 'src/constants';

import { history } from 'utils';
import { t } from 'utils';

import { segmentsService } from '../services';

import { segmentsStore } from '../stores';

export const onDeleteSegment = (id: number) => (): void => {
  openConfirmation(
    {
      confirmCallback: async () => {
        const status: any = await segmentsService.deleteSegment(+segmentsStore.racerId, +id);
        if (status) segmentsService.getTotal(+segmentsStore.racerId);
      },
    },
    {
      title: t.staticAsString('segments.modal.title'),
      body: t.staticAsString('segments.modal.body'),
    },
  );
};

export const onBack = () => {
  history.push(ROUTER.PROFILE_RESULTS_VIRTUAL);
};
