import { Observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { WhishAddComponent } from 'src/components/wish';
import { CHANGE_WISHLISTS } from 'src/constants';
import { wishlistsService } from 'src/services';
import { racesStore, windowSize, wishlistsStore } from 'src/stores';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';
import { history, t } from 'src/utils';

import { handleBack } from '../actions';

type Props = RouteComponentProps & {};

const Wrapper = styled.div<{ isMobile: boolean; isChecked: boolean }>`
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  align-items: start;

  .controls {
    ${(props) => (props.isMobile ? 'padding-top: 20px' : '')};
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    width: ${(props) => (props.isMobile ? '100%' : 'auto')};

    .go-back-text {
      color: ${(props) => props.theme.main.colors.clay3};
      padding-left: 8px;
      padding-right: 32px;
      border-right: 1px solid ${(props) => (props.isMobile ? 'none' : props.theme.main.colors.clay5)};
    }
    .flex-wrapper {
      flex: 1;
      display: contents;
      cursor: pointer;

      &:hover {
        .go-back-icon {
          cursor: pointer;

          svg > path {
            stroke: ${(props) => props.theme.main.colors.clay2};
          }

          svg > path {
            stroke: ${(props) => props.theme.main.colors.white};
            &.circle {
              fill: ${(props) => props.theme.main.colors.clay3};
              stroke: ${(props) => props.theme.main.colors.clay3};
            }
          }
        }
      }
    }
    .wish {
      flex-direction: ${(props) => (props.isMobile ? 'row-reverse' : 'row')};
      flex: 1;

      ${(props) =>
        !props.isChecked &&
        css`
          &:hover {
            div:first-child {
              svg path:first-child {
                fill: ${props.theme.main.colors.auroraLemon};
                stroke: ${props.theme.main.colors.auroraLemon};
              }
            }
          }
        `}
    }
  }
`;

const RaceControlsComponent = (props: Props): ReactElement | null => {
  const racesModel: AnyObject = racesStore.modelSelectedValue || {};
  const value = racesModel && racesModel.value;
  const isMobile = windowSize.isLessThan('small');

  if (!value) {
    return null;
  }
  return (
    <Observer>
      {() => {
        const isChecked = (wishlistsStore.values || []).map((race) => race.id).includes(value.id);

        return (
          <Wrapper isMobile={isMobile} isChecked={isChecked}>
            <div className='controls'>
              <div className='flex-wrapper' onClick={handleBack}>
                <Svg name='NavigateLeft' className='go-back-icon' />
                <Utility variant='u3' weight='bold' className='go-back-text'>
                  {t.staticAsString('registration.back')}
                </Utility>
              </div>
              <WhishAddComponent
                noMargin={isMobile}
                isFollow={isChecked}
                actionString={CHANGE_WISHLISTS}
                handleClick={() => wishlistsService.changeFavoriteRace(value)}
              />
            </div>
          </Wrapper>
        );
      }}
    </Observer>
  );
};

export const RaceControls = withRouter(RaceControlsComponent);
