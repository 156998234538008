import { throttle } from 'lodash';

import { dataStore, distancesStore } from '../../stores';

import { pagination } from '../../derivations';
import { loadFinished } from './_loadFinished';
import { loadNonFinished } from './_loadNonFinished';

async function loadMoreFun() {
  const nextDistance = pagination.distanceToBeLoaded.get();
  const distance = distancesStore.find(nextDistance!);

  if (!distance || !nextDistance) {
    return;
  }

  if (!pagination.hasMore.get()) {
    return;
  }

  let lastPage = dataStore.lastPage(nextDistance);

  if (!lastPage) {
    lastPage = 0;
  }
  let dataLength;
  if (distance.isStatus('started')) {
    dataLength = await loadNonFinished(nextDistance, lastPage + 1);
  } else {
    dataLength = await loadFinished(nextDistance, lastPage + 1);
  }
  if (!dataLength) {
    loadMore();
  }
}

const loadMore = throttle(loadMoreFun, 50);

export { loadMore };
