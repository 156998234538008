import { computed } from 'mobx';

import { priceUtils } from '../../utils';

import { formDataSelectors } from '../formDataSelectors';
import { distanceSelector } from '../mappedData';

/**
 * @description
 * total for all team members custom fields values
 * calculated with coupon
 * including custom fields BUT WITHOUT distance price
 */
const _teamSignUpMembersTotal = computed((): number => {
  const coupon = formDataSelectors.fetchLeaderCoupon();
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();
  const members = formDataSelectors.fetchTeamRegistrationMembers();

  return members.reduce((totalPrice, _member, index) => {
    const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(
      formDataSelectors.fetchTeamRegistrationMemberCustomFields(index),
      distanceCustomFieldValuesPrices,
    );

    return totalPrice + priceUtils.calculateMemberPriceWithDiscount({ distancePrice: 0, coupon, customFieldTotalPrice });
  }, 0);
});

const _teamSignUpMembersTotalWithoutDiscount = computed((): number => {
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();
  const members = formDataSelectors.fetchTeamRegistrationMembers();

  return members.reduce((totalPrice, _member, index) => {
    const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(
      formDataSelectors.fetchTeamRegistrationMemberCustomFields(index),
      distanceCustomFieldValuesPrices,
    );
    return totalPrice + priceUtils.calculateMemberPriceWithDiscount({ distancePrice: 0, coupon: null, customFieldTotalPrice });
  }, 0);
});

export { _teamSignUpMembersTotal, _teamSignUpMembersTotalWithoutDiscount };
