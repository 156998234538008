import { overlayStore, windowSize } from 'stores';

import { actionMenuStore } from '../../stores';

function open() {
  actionMenuStore.show();
}

function close() {
  actionMenuStore.hide();
}

export { open, close };
