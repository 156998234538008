import { isEqual, isArray } from 'lodash';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Sizes, stylesBySize } from '../DropDownFilter';

export type ItemType = {
  id: number;
  label: string;
  value: any;
};

type Props = {
  className?: string;
  name: string;
  value: any | ItemType[] | null;
  disabledItems?: any[];
  tagsList: ItemType[];
  onChange: AnyFunction;
  size?: Sizes;
  multiselect?: boolean;
};

type TagProps = {
  active: boolean;
  disabled: boolean;
  size?: Sizes;
};

const Tag = styled.label<TagProps>`
  display: inline-block;
  color: ${(props) => props.theme.main.colors.clay3};
  background: inherit;
  border: 2px solid ${(props) => props.theme.main.colors.clay3};
  border-radius: 129px;

  font-weight: 700;
  text-transform: uppercase;
  margin-right: 12px;

  ${(props) => stylesBySize[props.size ?? 'medium']}

  @media (hover: hover) {
    &:hover {
      color: ${(props) => (props.disabled ? props.theme.main.colors.clay4 : props.theme.main.colors.white)};
      background: ${(props) => (props.disabled ? 'inherit' : props.theme.main.colors.clay3)};
      border: 2px solid ${(props) => (props.disabled ? props.theme.main.colors.clay4 : props.theme.main.colors.clay3)};
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    }
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.main.colors.white};
      background: ${(props) => props.theme.main.colors.clay3};
      border: 2px solid ${(props) => props.theme.main.colors.clay3};
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.main.colors.clay4};
      background: ${(props) => props.theme.main.colors.white};
      border: 2px solid ${(props) => props.theme.main.colors.clay4};
      pointer-events: none;
    `}

  &:last-child {
    margin-right: 0;
  }
`;

export const TagsFilter: React.FC<Props> = ({
  className,
  name,
  value,
  tagsList,
  disabledItems = [],
  onChange,
  size = Sizes.medium,
  multiselect,
}): React.ReactElement<any, any> | null => {
  const handleChange = (item: ItemType) => () => {
    if (multiselect) {
      const values = isArray(value) ? value : [];
      const isActive = Boolean((values || []).find((tag) => tag.id === item.id));

      onChange({
        name,
        value: isActive ? values.filter((tag) => tag.id !== item.id) : [...values, item],
      });

      return;
    }

    onChange({ name, value: value === item.value ? null : item.value });
  };

  const isActive = (item: ItemType) => {
    if (multiselect) {
      return Boolean(((value as ItemType[]) || []).find((tag) => tag.id === item.id));
    }

    return isEqual(value, item.value);
  };

  return (
    <div className={className}>
      {tagsList.map((item) => (
        <Tag
          {...testAnchors.useButton(`${item.id}`)}
          key={item.id}
          size={size}
          active={isActive(item)}
          disabled={disabledItems.includes(item.id)}
          onClick={handleChange(item)}
        >
          {item.label}
        </Tag>
      ))}
    </div>
  );
};
