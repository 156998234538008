export const DISTANCE_OPTIONS = [
  {
    id: '0-5',
    label: '0-5 km',
    value: [0, 5],
  },
  {
    id: '6-10',
    label: '6-10 km',
    value: [6, 10],
  },
  {
    id: '11-20',
    label: '11-20 km',
    value: [11, 20],
  },
  {
    id: '21-30',
    label: '21-30 km',
    value: [21, 30],
  },
  {
    id: '31-50',
    label: '31-50 km',
    value: [31, 50],
  },
  {
    id: '51-100',
    label: '51-100 km',
    value: [51, 100],
  },
  {
    id: '100-',
    label: 'over 100 km',
    value: [100, 1000000],
  },
];

export const DistanceOptionsMapped = DISTANCE_OPTIONS.map((item) => ({
  key: item.id,
  label: item.label,
  value: item.value,
}));

export const DISTANCE_FILTER = 'DISTANCE_FILTER';
export const SPORT_FILTER = 'SPORT_FILTER';
export const DATE_FILTER = 'DATE_FILTER';

export const DEFAULT_CENTER_COORDINATES = {
  lat: 59.334591,
  lng: 18.06324,
};

export const MOBILE_LIST_ID = 'MOBILE_LIST_ID';
