import { computed } from 'mobx';
import Supercluster from 'supercluster';

import { mapPointsStore, mapControlsStore, mapBoundsStore } from '../stores';

export const supercluster = new Supercluster({
  radius: 110,
  maxZoom: 9,
});

export const pointClusters = computed(() => {
  if (!mapBoundsStore.value) return [];

  supercluster.load(mapPointsStore.value!);
  return supercluster.getClusters(mapBoundsStore.value, mapControlsStore.value!);
});
