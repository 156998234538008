import { action, observable, computed, makeObservable } from 'mobx';

class RacerRegistration {
  @observable racer: RacersType | null = null;
  @observable groupMembers: RacersType[] | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setRacer(value: RacersType) {
    this.racer = value;
  }

  @action
  setGroupMembers(value: RacersType[]) {
    this.groupMembers = value;
  }

  @action
  clear() {
    this.racer = null;
    this.groupMembers = null;
  }
}

export { RacerRegistration };
