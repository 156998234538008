import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const MoodPath = (
  <path
    id='Subtract'
    fillRule='evenodd'
    clipRule='evenodd'
    d='M23.5 12C23.5 18.0751 18.5751 23 12.5 23C6.42487 23 1.5 18.0751 1.5 12C1.5 5.92487 6.42487 1 12.5 1C18.5751 1 23.5 5.92487 23.5 12ZM8.5 6.10059C6.84315 6.10059 5.5 7.44374 5.5 9.10059H7.5C7.5 8.54831 7.94772 8.10059 8.5 8.10059C9.05228 8.10059 9.5 8.54831 9.5 9.10059H11.5C11.5 7.44374 10.1568 6.10059 8.5 6.10059ZM13.5 9.10059C13.5 7.44374 14.8431 6.10059 16.5 6.10059C18.1569 6.10059 19.5 7.44374 19.5 9.10059H17.5C17.5 8.54831 17.0523 8.10059 16.5 8.10059C15.9477 8.10059 15.5 8.54831 15.5 9.10059H13.5ZM12.5 15.1006C10.8431 15.1006 9.5 13.7574 9.5 12.1006H7.5C7.5 14.862 9.73858 17.1006 12.5 17.1006C15.2614 17.1006 17.5 14.862 17.5 12.1006H15.5C15.5 13.7574 14.1569 15.1006 12.5 15.1006Z'
    fill='currentColor'
  />
);

export const Mood = (props: IconProps) => <SvgIcon path={MoodPath} {...props} />;
