import classNames from 'classnames';
import * as React from 'react';
import { Link, generatePath } from 'react-router-dom';

import { ROUTER, DEFAULT_RACE_IMAGE } from 'src/constants';

import { RaceLocation, Calendar } from 'components/icons';
import { SportTypesComponent } from 'components/sportTypes';

type Props = {
  title: string;
  dateList: Array<Date>;
  location: AnyObject | null;
  image: string | null;
  sportType: AnyObject;
  raceId: number;
};

class RaceInfo extends React.PureComponent<Props> {
  static defaultProps: Props;

  renderDates = () => {
    const { dateList } = this.props;

    return (
      <React.Fragment>
        {Calendar}
        <div
          className={classNames('race-date-item-wrap', {
            single: dateList.length === 1,
          })}
        >
          {dateList.map((date) => (
            <span className='race-date-item' key={date as any}>
              {date.toString()}
            </span>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { title, location, image, sportType, raceId } = this.props;

    const racePath = generatePath(ROUTER.RACE_ABOUT_US, {
      id: raceId,
    });

    return (
      <div className='race-info-wrap'>
        <div className='race-img-wrap'>
          <div className='race-img'>
            <div className='block-content'>
              <img src={image || DEFAULT_RACE_IMAGE} />
              <SportTypesComponent sportIcon={sportType.icon || ''} />
            </div>
          </div>
        </div>
        <div className='race-info'>
          <div className='race-title'>
            <Link to={racePath}>{title}</Link>
          </div>

          <div className='sub-info-wrap'>
            <div className='race-date'>{this.renderDates()}</div>

            {location && (
              <div className='race-location'>
                {RaceLocation}
                <span className='location'>{location && location.city}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

RaceInfo.defaultProps = {
  title: '',
  dateList: [],
  location: {},
  image: null,
  sportType: {},
  raceId: 0,
};

export { RaceInfo };
