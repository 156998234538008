import { DataAttributes, DataAttributesAvatar, MessengerAttributes } from 'react-use-intercom';

import { sessionStore, localeStore } from 'src/stores';

import { generateGuestId } from './guestId';

// By default we don't want to include credentials in the user data.
// However, if the user is logged in & clicks on the chat box, we want to include their credentials.
function mapUser(shouldIncludeCredentials = false): DataAttributes {
  const user = sessionStore.userModel;
  if (!user || !shouldIncludeCredentials) {
    return {
      name: undefined,
      userId: undefined,
      email: undefined,
      createdAt: undefined,
    };
  }

  return {
    name: user.fullName || '',
    // @ts-ignore
    user_id: user.value.id,
    email: user.value.email || '',
    createdAt: user.value.created_at,
  };
}

function mapLocale(): DataAttributes {
  const locale = localeStore.value;

  return {
    languageOverride: locale,
  };
}

function mapAdditionalSettings(): DataAttributes & MessengerAttributes {
  const sourceWebsite = window.location.hostname;

  return {
    customAttributes: {
      source_website: sourceWebsite,
    },
    hideDefaultLauncher: true,
  };
}

function mapInitSettings() {
  return { ...mapUser(), ...mapLocale(), ...mapAdditionalSettings() };
}

export { mapUser, mapLocale, mapInitSettings };
