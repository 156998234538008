import * as React from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';

import { Base } from '../containers';

const RoutesComponent = () => {
  return (
    <div className='manage-team-container'>
      <Switch>
        <Route exact path={`${ROUTER_WITH_LOCALES.MANAGE_TEAM}`} component={Base} />
      </Switch>
    </div>
  );
};

const Routes = withRouter(RoutesComponent);
export { Routes };
