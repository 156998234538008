import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';

type Time = {
  hours?: number | string;
  minutes?: number | string;
  seconds?: number | string;
};

class SeparatedTime {
  @observable
  time: Time = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setTime(value: Time): void {
    this.time = {
      ...this.time,
      ...value,
    };
  }

  @action
  parseString(value?: string, withMilliseconds?: boolean): void {
    const time = value ? value.split(':') : [];

    this.time = {
      hours: time[0],
      minutes: time[1],
      seconds: withMilliseconds ? moment(time[2], 'ss.SSS').format('ss.SS') : time[2],
    };
  }

  @action
  clear(): void {
    this.time = {};
  }
}

export const separatedTimeStore = new SeparatedTime();
