import { validateData } from '../utils';

import { lastFinishedCheckpointService } from '../services';

import { checkpointsStore } from '../stores';

async function loadDefaultCheckpoint(distanceId: number | nil): Promise<number | null> {
  if (!distanceId) {
    return null;
  }

  if (!checkpointsStore.findCheckpointsByDistance(distanceId).length) {
    return null;
  }

  const checkpoint = await lastFinishedCheckpointService.load(distanceId);

  if (checkpoint && validateData.isValidCheckpoint(checkpoint.id)) {
    return checkpoint.id;
  }

  return null;
}

export { loadDefaultCheckpoint };
