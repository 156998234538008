import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { InputWrapper } from 'src/components/form';
import { RESOLUTIONS } from 'src/constants';
import { DropDownFilter } from 'src/styledComponents/DropDownFilter';
import { t } from 'src/utils/t';

import { focusStore } from '../../stores';

import { Search } from './Search';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0;
  width: 100%;

  .distances {
    max-width: 250px;
  }
  .classes {
    max-width: 173px;
  }
  .waves {
    max-width: 173px;
  }
  .gender {
    max-width: 142px;
  }
  @media (min-width: ${RESOLUTIONS.xlarge}px) {
    .distances {
      max-width: 300px;
    }
    .classes {
      max-width: 198px;
    }
    .waves {
      max-width: 198px;
    }
  }
`;

const StyledRightSide = styled.div<{ show: boolean }>`
  display: flex;
  gap: 8px;

  transition: opacity 200ms ease;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

type RightSideProps = {
  children: React.ReactNode;
};

const RightSide: React.FC<RightSideProps> = (props) => {
  return <Observer>{() => <StyledRightSide show={!focusStore.value}>{props.children}</StyledRightSide>}</Observer>;
};

type Props = {
  distanceOptions: NewSelectItemType[];
  classOptions: NewSelectItemType[];
  waveOptions: NewSelectItemType[];
  isGenderAvailable: boolean;
  genderOptions: NewSelectItemType[];
  show: boolean;
};

export const DesktopFilters: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <Search show={props.show} />
      <RightSide>
        <Show if={!isEmpty(props.distanceOptions)}>
          <InputWrapper
            name='distance_id'
            settings={{
              placeholder: t.staticAsString('racersTable.filters.distance'),
              dropListItems: props.distanceOptions,
              forceDropDirection: 'down',
              size: 'medium',
              className: 'distances',
            }}
            Component={DropDownFilter}
          />
        </Show>
        <Show if={!isEmpty(props.classOptions)}>
          <InputWrapper
            name='class_id'
            settings={{
              placeholder: t.staticAsString('racersTable.filters.class'),
              dropListItems: props.classOptions,
              forceDropDirection: 'down',
              size: 'medium',
              className: 'classes',
              dropDirection: 'right',
            }}
            Component={DropDownFilter}
          />
        </Show>
        <Show if={!isEmpty(props.waveOptions)}>
          <InputWrapper
            name='wave_id'
            settings={{
              placeholder: t.staticAsString('racersTable.filters.wave'),
              dropListItems: props.waveOptions,
              forceDropDirection: 'down',
              size: 'medium',
              className: 'waves',
              dropDirection: 'right',
            }}
            Component={DropDownFilter}
          />
        </Show>
        <Show if={props.isGenderAvailable}>
          <InputWrapper
            name='gender'
            settings={{
              placeholder: t.staticAsString('racersTable.filters.gender'),
              dropListItems: props.genderOptions,
              forceDropDirection: 'down',
              size: 'medium',
              className: 'gender',
              dropDirection: 'right',
            }}
            Component={DropDownFilter}
          />
        </Show>
      </RightSide>
    </Wrapper>
  );
};
