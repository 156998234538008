import * as React from 'react';

import { snackStore } from 'stores';

// NOTE,
// Hide all snacks for specific component

export const withHiddenSnacks =
  () =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<any> {
      componentDidMount() {
        snackStore.hideAllSnacks();
      }

      componentWillUnmount() {
        snackStore.showAllSnacks();
      }

      render() {
        return <WrappedComponent {...(this.props as any)} />;
      }
    } as any;
