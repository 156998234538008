import { isEmpty } from 'lodash';
import { Option } from 'modules/registration/components/MultiMenu/Option';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'src/styledComponents/Button';
import { SmoothElevation } from 'src/styledComponents/Elevation';
import { Utility } from 'src/styledComponents/Typography';
import { useOnClickOutside, t } from 'src/utils';

const MenuWrap = styled(SmoothElevation)<{ open: boolean; ref: React.RefObject<HTMLDivElement> }>`
  position: absolute;
  top: calc(100% + 46px);
  left: 0;
  right: 0;
  z-index: 10;
  overflow: hidden;
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  min-width: 354px;
  border-radius: 24px;

  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const Scroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  max-height: 400px;
  margin-top: 19px;

  ::-webkit-scrollbar {
    background: ${(props) => props.theme.main.colors.clay5};
    width: 4px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.main.colors.clay3};
    border-radius: 4px;
  }
`;

const MenuFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 80px;
  padding: 0 24px;

  border-top: 2px solid ${(props) => props.theme.main.colors.clay4};
  background-color: white;
`;

const LinkStyled = styled(Utility)`
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
`;

type Props = {
  options: DropdownItemType[];
  open: boolean;
  value: DropdownItemType[];
  setOpen: AnyFunction;
  onChoose: (value: DropdownItemType) => AnyFunction;
  handleClear: AnyFunction;
};

export const Menu: React.FC<Props> = (props) => {
  const { options = [], onChoose, open, setOpen, value, handleClear } = props;
  const menuRef = React.useRef(null);

  useOnClickOutside(
    menuRef,
    () => {
      if (!open) return;

      setOpen(false);
    },
    open,
  );

  if (isEmpty(options)) return null;

  return (
    <MenuWrap ref={menuRef} level={5} open={open}>
      <Scroll>
        {options.map((item) => (
          <Option key={item.key} name={item.key} label={item.label} onChange={onChoose(item)} value={item} values={value} />
        ))}
      </Scroll>

      <MenuFooter>
        <LinkStyled variant='u4' weight='medium' onClick={handleClear}>
          {t('settings.clear')}
        </LinkStyled>
        <Button size='small' variant='secondary' onClick={() => setOpen(false)}>
          {t('settings.save')}
        </Button>
      </MenuFooter>
    </MenuWrap>
  );
};
