import { sessionStore } from 'src/stores';

import { accountAvatar } from '../../AvatarAccountUpload';

const changeProfilePicture = async (image: string): Promise<boolean> => {
  const [status, response] = await accountAvatar.uploadAvatar(image);

  if (status) {
    let user = sessionStore.getUserData();
    user.avatar = response.avatar;
    sessionStore.userProfile(user);
    return status;
  }

  return status;
};

export { changeProfilePicture };
