import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { Utility } from 'src/styledComponents/Typography';

type Props = {
  className?: string;
  errorMessage?: ErrorMessageType;
  isWarning?: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  value?: string;
  name: string;
  requiredMark?: boolean;
  children: React.ReactNode | React.ReactChildren | React.ReactElement;
  elementRef?: React.MutableRefObject<any>;
  additionalInfo?: string;
  additionalComponent?: AnyFunction;
};

const WrapBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    padding-bottom: 8px;
  }

  .additional-info {
    padding-left: 8px;
  }
`;

const ErrorLabel = styled(Utility)<{ isWarning?: boolean }>`
  color: ${(props) => (props.isWarning ? props.theme.main.colors.warning : props.theme.main.colors.error)};
  padding-top: 8px;
`;

const Label = styled(Utility)<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? props.theme.main.colors.clay4 : 'inherit')};
`;

export const InputWrapComponent: React.FC<Props> = (props) => {
  const {
    additionalInfo,
    additionalComponent,
    className,
    errorMessage,
    isWarning,
    disabled,
    label,
    children,
    requiredMark,
    elementRef,
    name,
  } = props;

  const hasError = Boolean(errorMessage);

  return (
    <WrapBlock className={className} ref={elementRef}>
      <>
        <Show if={Boolean(label)}>
          <label>
            <Label variant='u4' weight='bold' disabled={disabled} {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.title)}>
              {label}
              {requiredMark ? '*' : ''}

              {additionalInfo && <span className='additional-info'>{additionalInfo}</span>}
            </Label>
          </label>
        </Show>

        {children}

        <Show if={hasError}>
          <ErrorLabel variant='u4' weight='bold' isWarning={isWarning} {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.error)}>
            {errorMessage}
          </ErrorLabel>
        </Show>

        <Show if={Boolean(additionalComponent && !hasError)}>{additionalComponent && additionalComponent()}</Show>
      </>
    </WrapBlock>
  );
};
