import { ParrentModel } from './parrentModel';

class Location extends ParrentModel<LocationType> {
  cityOrCountry(): string {
    const { city, country } = this.value;

    if (!!city) {
      return city;
    }

    if (!!country) {
      return country;
    }

    return '';
  }
}

export { Location };
