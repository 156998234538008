import * as React from 'react';
import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Divider } from 'src/styledComponents/Divider';
import { Utility } from 'src/styledComponents/Typography';

import { scrollBarStyles } from 'styles';

export const Wrapper = styled.div<{ isSwipeEnd: boolean; isDesktop: boolean; maxHeight: number }>`
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  border-radius: 24px 24px 0 0;
  padding: 0 20px 40px;

  ${(props) =>
    props.isDesktop &&
    css`
      height: calc(100vh - 200px);
      max-height: ${props.maxHeight}px;
      overflow-y: hidden;
    `}

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 905px;
    padding: 32px 0 40px;
    border-radius: 24px;
  }

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }

  .loading-overlay {
    .spinner {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const ScrollBarWrapper = styled.div<{ isDesktop: boolean }>`
  padding: 0 20px;
  height: 100%;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 0 40px;
  }

  ${({ isDesktop }) => isDesktop && 'overflow-y: auto;'}

  ${scrollBarStyles}
`;

export const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

export const DistanceNameUtility = styled(Utility)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  align-items: center;

  > :not(:last-of-type) {
    margin-bottom: 8px;
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    align-items: flex-start;
  }
`;

export const StyledDivider = styled(Divider)`
  height: 1px;
  margin-bottom: 7px;
`;

export const Content = styled.div`
  margin: 30px 0 40px;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  height: 56px;
  button {
    margin-left: 0;
    span {
      font-size: 20px;
      line-height: 24px;
    }

    @media (min-width: ${RESOLUTIONS.medium}px) {
      margin-left: 32px;
    }
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-top: 40px;
  }

  .margin-right {
    margin-right: 24px !important;
  }
`;
