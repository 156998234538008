import { ExportBibService } from './exportBib';
import { ExportDiplomaService } from './exportDiploma';
import { RacerResultService } from './racerResult';
import { SingleVirtual } from './singleVirtual';
import { UserResults } from './userResults';

const singleVirtualService = new SingleVirtual();
const exportBibService = new ExportBibService();
const exportDiplomaService = new ExportDiplomaService();
const racerResultService = new RacerResultService();
const userResultsStore = new UserResults();

export { singleVirtualService, exportBibService, exportDiplomaService, racerResultService, userResultsStore };
export * from './registeredTo';
export * from './profile';
export * from './results';
export * from './xtri';
export * from './distances';
