import { ROUTER } from 'src/constants';

import { history, encodeQuery } from 'utils';

import { VirtualDistance } from '../types';

function onNavigateToSegments(distance: VirtualDistance) {
  return () => {
    const distanceId = distance.id;
    const racerId = distance.racers[0].id;

    history.push(
      encodeQuery.generatePath(
        ROUTER.SEGMENTS,
        {},
        {
          distance_id: distanceId,
          racer_id: racerId,
        },
      ),
    );
  };
}

export { onNavigateToSegments };
