import classNames from 'classnames';
import * as React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export function Head(props: Props) {
  const { children, className } = props;

  return <div className={classNames('table-head', className)}>{children}</div>;
}
