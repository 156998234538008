import { Observer } from 'mobx-react';
import { animation } from 'modules/miles/app/home/ui/styled-components';
import { CheckinDaily, Dot, FireStreak, GraphIncreaseAnalytics, Text, TimeClock } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';

import { Show } from 'components/condition';

import { history } from 'utils';

import { insightsStore } from '../../stores';
import { dailyDataStore } from '../../stores/dailyData';

import DailyDataDisplay from './DailyDataDisplay';

type LabelProps = {
  isInsights: boolean;
};

const ToolbarContainer = styled.div<LabelProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  color: ${({ theme }) => theme.miles.colors.dark.alpha['900']};
  .toolbar-card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha.dark500};
    background: ${({ theme }) => theme.miles.colors.dark.alpha.dark300};
    cursor: pointer;

    &:hover {
      .content-insights {
        background: ${({ isInsights, theme }) =>
          isInsights ? theme.miles.colors.dark.alpha.cobalt500 : theme.miles.colors.dark.background['800']};
      }
      .daily-data-filled {
        background: ${({ theme }) => theme.miles.colors.dark.background['800']};
      }
      .daily-data-unfilled {
        background: ${({ theme }) => theme.miles.colors.dark.background['800']};
      }
    }

    .content-insights {
      display: flex;
      height: 56px;
      margin: -1px;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      gap: 4px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
      background: ${({ isInsights, theme }) =>
        isInsights ? theme.miles.colors.dark.alpha.cobalt300 : theme.miles.colors.dark.background['600']};
      box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion2Strong};
      ${animation}
      .icon {
        display: flex;
      }
    }
    .daily-data-unfilled {
      display: flex;
      height: 56px;
      margin: -1px;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      gap: 4px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
      background: ${({ theme }) => theme.miles.colors.dark.background['600']};
      box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion2Strong};
      ${animation}
      .icon {
        display: flex;
      }
    }
    .daily-data-filled {
      display: flex;
      height: 56px;
      margin: -1px;
      padding: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      gap: 4px;
      border-radius: 8px;
      color: ${({ theme }) => theme.miles.colors.dark.thin.primary};
      border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
      background: ${({ theme }) => theme.miles.colors.dark.background['600']};
      box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion2Strong};
      ${animation}
      .icon {
        display: flex;
      }
    }
    .notice {
      display: flex;
      align-self: stretch;
      height: 36px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      text-align: center;
      &.daily {
        color: ${({ theme }) => theme.miles.colors.dark.thin.warning};
      }
      &.insights {
        color: ${({ theme }) => theme.miles.colors.dark.thin.positive};
      }
    }
  }
`;

const StyledDot = styled(Dot)`
  position: absolute;
  left: -12px;
  top: -9px;
  color: ${({ theme }) => theme.miles.colors.dark.icon.negative};
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.4));
`;
type Props = {
  className?: string;
};
export const ToolBar: React.FC<Props> = ({ className }) => {
  const isInsights = history.location.pathname.includes('insights');

  return (
    <Observer>
      {() => {
        const currentRaceTime = insightsStore.value?.predicted_race_time.current_prediction || 'N/A';
        return (
          <ToolbarContainer isInsights={isInsights} className={className}>
            <div className='toolbar-card' onClick={() => history.push(ROUTER.MILES_DAILY_DATA)}>
              <DailyDataDisplay />
              <div className='notice daily'>
                <FireStreak size={16} />
                <Text weight={'regular'} size={'sm'}>
                  {dailyDataStore.streak} Day Streak
                </Text>
              </div>
            </div>
            <div className='toolbar-card' onClick={() => history.push(ROUTER.MILES_METRICS_INSIGHTS)}>
              <div className='content-insights'>
                <div className='icon'>
                  <GraphIncreaseAnalytics />
                </div>
                <Text weight={'regular'} size={'md'}>
                  Insights
                </Text>
              </div>
              <div className='notice insights'>
                <TimeClock size={16} />
                <Text weight={'regular'} size={'sm'}>
                  Est. Time: {currentRaceTime}
                </Text>
              </div>
            </div>
          </ToolbarContainer>
        );
      }}
    </Observer>
  );
};
