class VersionControl {
  counters: {
    [K in string]: number;
  };

  constructor() {
    this.counters = {};
  }
  registerCounter(name: string): void {
    this.counters[name] = 1;
  }

  deregisterCounter(name: string): void {
    delete this.counters[name];
  }

  incrementVersion(name: string): number | null {
    if (this.counters[name]) {
      this.counters[name]++;
    }

    return this.currentVersion(name);
  }

  currentVersion(name: string): number | null {
    return this.counters[name];
  }
}

const versionControl = new VersionControl();
export { VersionControl, versionControl };
