import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { Svg } from 'src/styledComponents/Icons';
import { BodyText } from 'src/styledComponents/Typography';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: 16px;
`;

const PlaceholderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

const Input = styled.input`
  padding: 0 46px 0 16px;
  background-color: ${(props) => props.theme.main.colors.clay6};
  border: none;
  border-radius: 80px;
  outline: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.colors.clay1};

  height: 48px;
  width: 100%;
`;

const Placeholder = styled(BodyText)`
  color: ${(props) => props.theme.main.colors.clay3};
  margin-right: 12px;
`;

const CrossWrap = styled.div`
  position: absolute;
  height: 48px;
  width: 48px;
  top: 0;
  right: 0;
  z-index: 1;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  placeholder: string;
  value: string;
  handleFocus?: AnyFunction;
  handleClear?: AnyFunction;
};

export const SearchInput: React.FC<Props> = (props) => {
  const { placeholder, value, handleFocus, handleClear } = props;

  return (
    <Wrapper>
      <Input value={value} onFocus={handleFocus} {...testAnchors.useField('search', TEST_ANCHORS.fieldStructure.root)} />
      <Show if={!value}>
        <PlaceholderWrap onClick={handleFocus}>
          <Placeholder variant='body2'>{placeholder}</Placeholder>
          <Svg name='Search' size={16} {...testAnchors.useField('search', TEST_ANCHORS.fieldStructure.button, 'submitSearch')} />
        </PlaceholderWrap>
      </Show>
      <Show if={Boolean(value)}>
        <CrossWrap id='kek' onClick={handleClear} {...testAnchors.useField('search', TEST_ANCHORS.fieldStructure.button, 'close')}>
          <Svg name='Cross' size={16} />
        </CrossWrap>
      </Show>
    </Wrapper>
  );
};
