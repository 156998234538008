import { includes } from 'lodash';

import { DISTANCE_MODES } from 'src/constants';

import { fireToast, t } from 'utils';

import { segmentIgnition } from 'actions';

import { VirtualDistance } from '../types';
import { reloadDistance } from './reloadDistance';
import { openResultForm } from './results';

function onAddResult(value: VirtualDistance) {
  return () => {
    const distanceId = value.id;
    const racerId = value.racers[0].id;

    if (!value.published) {
      fireToast(t.staticAsString('toast.message.unpublishedRace'), 'warning', t.staticAsString('toast.title.unpublishedRace'), 'top-right');
      return;
    }

    if (value.distance_mode !== DISTANCE_MODES.cumulative) {
      return openResultForm(racerId)();
    }

    segmentIgnition.switchFirstNew(distanceId, racerId, async (resposeState, type) => {
      if (!includes(['success', 'conflict'], resposeState)) {
        return;
      }

      reloadDistance(distanceId, 'cumulative');
    });
  };
}

export { onAddResult };
