import { isEmpty, mapValues } from 'lodash';
import { validate as executeValidateJS } from 'validate.js';

import { t } from 'utils';

import { errorsStore } from 'stores';

import { REGISTRATION_FORM } from '../constants';

import { mapperUtils } from './mappers';

function _translateValues(values: RawError): RawError {
  return mapValues(values, (errors: string[]) => (errors || []).map((error) => t.staticAsStringMultiple(error))) as any as RawError;
}

function validate(object: AnyObject, scheme: AnyObject, prefix: string | nil): boolean {
  const rawErrors = executeValidateJS(object, scheme, { fullMessages: false });

  const processedErrors = _translateValues(mapperUtils.prependPrefixToObject<RawError>(prefix, rawErrors));

  if (!processedErrors) {
    return true;
  }

  errorsStore.add(REGISTRATION_FORM, processedErrors);

  return isEmpty(processedErrors);
}

export { validate };
