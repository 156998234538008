import { sortBy } from 'lodash';
import { computed, makeObservable } from 'mobx';

import { PERSONAL_STEP_FIELDS } from 'src/constants';

import { Distance as DistanceModel } from 'models/distance';

import { Loadable } from 'stores/loadable';

// Deprecated, DO NOT USE IT
class Distance extends Loadable<DistanceType, FiltersType> {
  declare filters: FiltersType;

  defaultFilters: FiltersType = {
    with: 'registration_fields;custom_fields.values;classes;disciplines;firstPrice;lastPrice',
    withCount: 'racers;teams',
  };

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.defaultFilters;
  }

  @computed
  get modelSelectedValue(): DistanceModel | null {
    if (this.selected) {
      return new DistanceModel(this.selected);
    }

    return null;
  }

  @computed
  get customFields(): Array<AnyObject> {
    return sortBy(this.selected?.custom_fields || [], ['index']);
  }

  @computed
  get sortedRegistrationField(): Array<RegistrationFieldsType> | null {
    if (this.selected && this.selected.registration_fields && this.selected.registration_fields.length) {
      const steps = this._getStepsKeys();
      const registrationFields = [...this.selected.registration_fields];

      registrationFields.sort((prevEl, nextEl) => steps.indexOf(prevEl.name) - steps.indexOf(nextEl.name));

      return registrationFields;
    }
    return null;
  }

  _getStepsKeys(): Array<string> {
    return Object.keys(PERSONAL_STEP_FIELDS);
  }
}

export { Distance };
export default new Distance();
