import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const SpotifyPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM15.9122 16.4108C16.2112 16.5816 16.5939 16.5019 16.7733 16.2172C16.9526 15.9326 16.857 15.5682 16.558 15.3975C13.927 13.8604 10.6501 13.5074 6.83511 14.3386C6.50025 14.4069 6.29695 14.7257 6.3687 15.0445C6.44046 15.3633 6.77532 15.5569 7.11018 15.4885C10.6023 14.7371 13.5562 15.0445 15.9122 16.4108ZM16.9168 13.8718C17.2875 14.0767 17.7659 13.9742 17.9931 13.6213C18.2203 13.2683 18.1007 12.8129 17.7539 12.608C14.6565 10.7977 10.1359 10.2967 6.50025 11.3442C6.08168 11.458 5.85445 11.8679 5.97405 12.2664C6.09364 12.6535 6.52417 12.8698 6.94275 12.756C10.1239 11.8337 14.2259 12.2892 16.9168 13.8718ZM6.46438 9.80711C9.54986 8.91903 14.8717 9.08981 18.1007 10.9115C18.5432 11.1734 19.1173 11.0254 19.3684 10.5927C19.6315 10.1714 19.488 9.62494 19.0455 9.37445C15.3381 7.27949 9.45419 7.07455 5.91425 8.09926C5.42392 8.24727 5.1369 8.74824 5.29237 9.21505C5.44784 9.69325 5.97405 9.95512 6.46438 9.80711Z'
    fill='#00C966'
  />
);

export const Spotify = (props: IconProps) => <SvgIcon path={SpotifyPath} {...props} />;
