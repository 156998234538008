import * as React from 'react';

import { t } from 'utils';

export type Props = {
  type: 'login' | 'signUp';
  btnProps?: AnyObject;
  handleClick: (token: string) => void;
  children?: React.ReactNode;
};

type State = {
  connected: boolean;
};

class FacebookButton extends React.Component<Props, State> {
  state = {
    connected: false,
  };

  componentDidMount() {
    if (document.getElementById('facebook-jssdk')) {
      return;
    }

    this.setFbInit();
    this.loadSDK();
  }

  setFbInit() {
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      window.FB.init({
        appId: '1762251147373205',
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      });
      // @ts-ignore
      window.FB.AppEvents.logPageView();
    };
  }

  loadSDK() {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      // @ts-ignore
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  handleClick = () => {
    const promise: Promise<string> = new Promise((resolved, rejected) => {
      // @ts-ignore
      window.FB.login(
        (res) => {
          if (res.status === 'connected') {
            return resolved(res.authResponse.accessToken);
          }

          return rejected();
          // user_birthday removed - 12-01-2021
        },
        { scope: 'email,user_friends', return_scopes: true },
      );
    });

    promise.then((token) => {
      const { handleClick } = this.props;
      handleClick(token);
      this.setState({ connected: true });
    });
  };

  render(): React.ReactNode {
    const { type, btnProps, children } = this.props;
    const btnText = type === 'login' ? 'login.facebookSignin' : 'login.facebookSignup';
    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClick: this.handleClick } as any);
      }

      return child;
    });

    return (
      <>
        {childrenWithProps}
        {!children && (
          <button {...btnProps} className='btn fb' onClick={this.handleClick}>
            {t(btnText)}
          </button>
        )}
      </>
    );
  }
}

export { FacebookButton };
