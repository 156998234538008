import { displayScrollStore } from '../stores';

let scrollHandler = () => {};

const getRootElement = (id?: string) => {
  const element = id ? document.querySelector(`#${id}`) : document.documentElement;

  const scrollElement = id ? document.querySelector(`#${id}`) : document;

  return {
    element,
    scrollElement,
  };
};

export const scrollToTop =
  (id?: string, offsetTop: number = 0) =>
  (): void => {
    const { element, scrollElement } = getRootElement(id);

    if (!element || !scrollElement) {
      return console.error('Scroll to top root element is not found', id);
    }

    element.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });
  };

const handleDisplay =
  (element: Element, offsetTop: number = 50) =>
  () => {
    if (element.scrollTop <= offsetTop && displayScrollStore.value) {
      displayScrollStore.setValue(false);
    }
    if (element.scrollTop > offsetTop && !displayScrollStore.value) {
      displayScrollStore.setValue(true);
    }
  };

export const mount = (id?: string, offsetTop?: number) => {
  const { element, scrollElement } = getRootElement(id);

  if (!element || !scrollElement) {
    return console.error('Scroll to top root element is not found', id);
  }

  scrollHandler = handleDisplay(element, offsetTop);
  scrollElement.addEventListener('scroll', scrollHandler);
};

export const unmount = (id?: string) => {
  const { element, scrollElement } = getRootElement(id);

  if (!element || !scrollElement) {
    return console.error('Scroll to top root element is not found', id);
  }

  scrollElement.removeEventListener('scroll', scrollHandler);
  scrollHandler = () => {};
};
