import { pick, merge } from 'lodash';

import { history, encodeQuery } from 'utils';

import { EMPTY_FILTERS, FILTER_NAMES } from '../constants';

import { HistoryFilters } from '../types';

function generateHistoryFilterParams(): HistoryFilters {
  const params = encodeQuery.parseQuery(history.location.search);
  const filteredParams = pick(params, FILTER_NAMES);

  return merge({}, EMPTY_FILTERS, filteredParams);
}

export { generateHistoryFilterParams };
