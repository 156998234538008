import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';
import { BodyText, Utility } from 'src/styledComponents/Typography';

type Props = {
  progress: string;
  remainingTime: string;
};

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;

  .white {
    color: ${(props) => props.theme.main.colors.white};
  }
`;

const Square = styled.div`
  width: 4px;
  height: 4px;
  background: ${(props) => props.theme.main.colors.white};
  margin: 0 8px 2px 8px;
`;

export const SubTitle = (props: Props) => {
  const { progress, remainingTime } = props;

  return (
    <Wrapper>
      <BodyText variant='body2' className='white'>
        {progress}
      </BodyText>
      <Square />
      <BodyText variant='body2' className='white'>
        {remainingTime}
      </BodyText>
    </Wrapper>
  );
};
