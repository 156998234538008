import { Description, Unit, Plan } from '../stores/mock';
import { TrainingPlanType } from '../stores/trainingPlan';

import { WorkoutDifficultyLevel } from '../types';
import { MilesDataInterval } from '../types/miles_data';

const generateRandomId = (): number => {
  return Math.floor(Math.random() * 1000000);
};

const getIntervals = (desc?: Description, milesIntervals?: MilesDataInterval) => {
  if (!desc || !desc.intervals) return null;
  const sets = milesIntervals?.sets[0];
  const setsCount = sets?.setCount;
  const repsValue = sets?.intervals ?? [];

  const getSet = () => {
    return repsValue.map((rep, idx) => {
      return {
        rep: {
          value: rep.interval.value,
          unit: rep.interval.unit,
          ui: rep.interval.ui,
        },
        rest: {
          value: rep.rest.value,
          unit: rep.rest.unit,
          ui: rep.rest.ui,
        },
      };
    });
  };

  const getSets = () => {
    return (
      milesIntervals?.sets?.map((set, idx) => {
        return {
          intervalSection: idx + 1,
          setCount: set.setCount,
          intervals:
            set.intervals?.map((rep) => {
              return {
                rep: {
                  value: rep.interval.value,
                  unit: rep.interval.unit,
                  ui: rep.interval.ui,
                },
                rest: {
                  value: rep.rest.value,
                  unit: rep.rest.unit,
                  ui: rep.rest.ui,
                },
              };
            }) ?? [],
        };
      }) ?? []
    );
  };

  return {
    setsCount,
    set: getSet(),
    sets: getSets(),
    warmup: {
      value: milesIntervals?.warmup.value,
      unit: milesIntervals?.warmup.unit,
      ui: milesIntervals?.warmup.ui,
    },
    cooldown: {
      value: milesIntervals?.cooldown.value,
      unit: milesIntervals?.cooldown.unit,
      ui: milesIntervals?.cooldown.ui,
    },
  };
};

export const mapToTrainingPlanType = (plan: Plan): TrainingPlanType => {
  const milesData = plan.miles_data;
  const isRacePrep = plan.title.texts[0].text.includes('Race preparation');
  const difficultyLevel = milesData.intensity_msg as WorkoutDifficultyLevel;
  const difficultyLevelLabel = milesData.rpe;

  let id = generateRandomId();
  let date = milesData.date;
  let name = milesData.session_title;
  let time = milesData.session_time;
  let hr_desc = milesData.hr;
  let speed_desc = milesData.speed;
  let interval_desc = milesData.session_info;
  let interval = getIntervals(plan.description, milesData.intervals) as any;
  let message;

  if (isRacePrep) {
    message = plan.title.texts
      .map((txt) => txt.text)
      .join(' ')
      .split(':')
      .pop()
      ?.trim();
  }

  return {
    id,
    difficultyLevel,
    date,
    name,
    difficultyLevelLabel,
    hr_desc,
    speed_desc,
    session_description: milesData.session_description,
    interval_desc,
    time,
    interval,
    isRacePrep,
    racePrepMessage: message,
  };
};
