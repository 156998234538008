import isEmpty from 'lodash/isEmpty';
import { Observer } from 'mobx-react';
import { DistanceType } from 'modules/home/types';
import { cannotLogError } from 'modules/myResults/utils';
import { actionMenuActions } from 'modules/profile/actions';
import { calcProgress, calcProgressLength } from 'modules/profile/selections';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Button } from 'src/styledComponents/Button';
import { RaceProfileCard } from 'src/styledComponents/Card';

import { Hide } from 'components/condition';

import { fireToast, t } from 'utils';

import { Distance, Goal, SportType } from 'models';

import { openEditResult, onAddResult, onNavigateToSegments } from '../../actions';

import { resultStore } from '../../stores';

import { AddResult, EditCell, SegmentsLinkCell } from '.';
import { menuItems } from '../ActionMenuItems';
import { NoResults } from '../NoResults';

type Props = {
  distanceType: 'virtual' | 'cumulative';
};

const ExtraBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: 24px;

    button {
      width: 100%;
      height: 56px;
      span {
        font-size: 20px !important;
        line-height: 24px !important;
      }
    }
  }
`;

export const VirtualRaces: FC<Props> = ({ distanceType }) => {
  const _isDataEmpty = (): boolean => {
    return isEmpty(resultStore[distanceType]);
  };

  const getRacerResult = (distance: DistanceType = {} as any) => {
    return (distance.racers || [])[0]?.result || {};
  };

  return (
    <Observer>
      {() => {
        if (_isDataEmpty()) {
          return <NoResults message='profile.onlyVirtualRaces' />;
        }

        return (
          <>
            {resultStore[distanceType].map((distance: DistanceType) => {
              const distanceModel = new Distance(distance as any);
              const goalModel = new Goal(distance.goal || ({} as any));
              const result = getRacerResult(distance);
              const editMode = !!result?.id;
              const isLogResultsDisabled = distanceModel.isLoggingClosed();

              const sportModel = new SportType(distance.race?.sport as any);
              const sportIcon = distance.race?.sport && sportModel.value.icon;
              const sportTitle = distance.race?.sport && sportModel.title;

              const handleEditCellClick = () => {
                if (isLogResultsDisabled) {
                  fireToast(
                    cannotLogError(distanceModel) as string,
                    'info',
                    t.staticAsString('profile.toast.title.addResultsInformation'),
                    'top-right',
                    0,
                  );
                  return;
                }
                openEditResult(distance.racers[0].id)();
              };

              return (
                <RaceProfileCard
                  key={distance.id}
                  distanceId={distance.id}
                  bodyTitle={distance.raceName}
                  distanceName={distance.name}
                  distanceType={distanceType}
                  distanceProgress={calcProgressLength(result.distance_logged, distance.goal?.goal_max_length)}
                  startDate={distance.startDate}
                  endDate={distance.endDate}
                  distanceLength={distance.distanceLength}
                  image={distance.image}
                  onClickMore={() => actionMenuActions.toggleSwitchOn(distance as any)}
                  place={distance.place}
                  settings={menuItems()}
                  sportIcon={sportIcon ?? ''}
                  sportType={sportTitle ?? ''}
                  teamName={distance.teamName}
                  time={distance.time}
                  total={distance.total}
                  percents={calcProgress(result.distance_logged, distance.goal?.goal_max_length)}
                  raceResultsLink={distance.raceResultsLink}
                  published={distance.published}
                  extraCotent={
                    <ExtraBox>
                      <Hide if={editMode}>
                        <AddResult
                          disabled={isLogResultsDisabled}
                          disabledReason={cannotLogError(distanceModel)}
                          editMode={editMode}
                          isAltitude={goalModel.isGoalType('altitude')}
                          isLength={goalModel.isGoalType('distance')}
                          percents={calcProgress(result.distance_logged, distance.goal?.goal_max_length)}
                          link={result.activity_link}
                          onClick={onAddResult(distance as any)}
                          expandButton={
                            <Button variant='primary' size='medium' disabled={!result.activity_link} ATN='logResults'>
                              {t.staticAsString('race.logResults')}
                            </Button>
                          }
                        />
                      </Hide>

                      <EditCell
                        display={editMode && distanceModel.isMode('virtual')}
                        hide={distanceModel.isLoggingClosed()}
                        onClick={handleEditCellClick}
                      />
                      <SegmentsLinkCell
                        display={editMode && distanceModel.isMode('cumulative')}
                        hide={false}
                        onClick={onNavigateToSegments(distance as any)}
                      />
                    </ExtraBox>
                  }
                  withResults
                />
              );
            })}
          </>
        );
      }}
    </Observer>
  );
};
