import { computed } from 'mobx';

import { FIELD_ID_PATTERNS } from '../../constants';

import { mapperUtils, priceUtils } from '../../utils';

import { disabledCustomFieldsStore, smartLinkStore } from '../../stores';

import { formDataSelectors } from '../formDataSelectors';
import { mainConditions } from '../mainConditions';
import { distanceSelector } from '../mappedData';
import { distancePrice as distancePriceSelector } from './distancePrice';

/**
 * @description
 * total of group sign up members
 * calculated with coupon
 * including custom fields and distance price
 */
const _groupSignUpMembersTotal = computed(() => {
  const distancePrice = distancePriceSelector.get();
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();

  const members = formDataSelectors.fetchGroupRegistrationMembers();

  return members.reduce<number>((totalPrice, _member, index) => {
    const coupon = formDataSelectors.fetchGroupMemberCoupon(index);

    const customFields = mapperUtils.rejectCustomFields({
      index,
      pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
      fieldIds: disabledCustomFieldsStore.disabledFieldIds,
    })(formDataSelectors.fetchGroupRegistrationCustomFields(index));

    const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);
    totalPrice +=
      mainConditions.isSmartLinkRegistration() && mainConditions.isCouponEnableBySmartLink.get() && smartLinkStore.value
        ? priceUtils.calculatePriceWithSmartLinkDiscount({ distancePrice, customFieldTotalPrice, smartLink: smartLinkStore.value })
        : priceUtils.calculateMemberPriceWithDiscount({ distancePrice, coupon, customFieldTotalPrice });

    return totalPrice;
  }, 0);
});

/**
 * @description
 * total of group sign up members
 * calculated without coupon
 * including custom fields and distance price
 */
const _groupSignUpMembersTotalWithoutDiscount = computed(() => {
  const distancePrice = distancePriceSelector.get();
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();

  const members = formDataSelectors.fetchGroupRegistrationMembers();

  return members.reduce<number>((totalPrice, _member, index) => {
    const customFields = mapperUtils.rejectCustomFields({
      index,
      pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
      fieldIds: disabledCustomFieldsStore.disabledFieldIds,
    })(formDataSelectors.fetchGroupRegistrationCustomFields(index));

    const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);

    totalPrice += priceUtils.calculateMemberPriceWithDiscount({ distancePrice, coupon: null, customFieldTotalPrice });

    return totalPrice;
  }, 0);
});

export { _groupSignUpMembersTotal, _groupSignUpMembersTotalWithoutDiscount };
