import { MORE_INFO_FORM_ID } from 'modules/profileSettings/constants';
import { observeBloodType } from 'modules/profileSettings/observers';
import { disposers } from 'modules/profileSettings/stores';

import { errorsStore } from 'src/stores';

import { clearUserProfile } from './clearUserProfile';
import { loadUserProfile } from './loadUserProfile';

export const mount = () => {
  loadUserProfile();
  observeBloodType();
};

export const unmount = () => {
  clearUserProfile();
  errorsStore.clear(MORE_INFO_FORM_ID);
  disposers.disposeAll();
};
