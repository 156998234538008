import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { windowSize } from 'src/stores/windowSize';
import { Icon } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

const StyleOption = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 16px;
  height: 56px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${(props) => props.theme.main.colors.clay6};
  }
`;

type Props = {
  label: string;
  active: boolean;
  handleSelect: AnyFunction;
  disabled?: boolean;
};

export const Option: React.FC<Props> = (props) => {
  const { label, active, handleSelect, disabled } = props;
  const variant = windowSize.isGreaterThan('medium') ? 'u2' : 'u1';

  return (
    <StyleOption onClick={disabled ? () => {} : handleSelect} disabled={disabled}>
      <Utility variant={variant} weight='medium'>
        {label}
      </Utility>
      <Show if={active}>
        <Icon name='FormTick' size={16} />
      </Show>
    </StyleOption>
  );
};
