import { Observer } from 'mobx-react';
import { onHorizontalScroll } from 'modules/racersTable/utils';
import * as React from 'react';
import { ScrollSync } from 'react-scroll-sync';

import { LeftSideWrapper, LeftTableHeader, RightSideWrapper, TableWrapper } from 'src/modules/racersTable/components';

import { RightSide, LeftSide, RightHeader } from '../../components/ShowAll';
import { UsersCount } from '../../components/UsersCount';

import { currentDistance } from '../../derivations';

export const AllTable = () => (
  <Observer>
    {() => {
      const distance = currentDistance.model.get();
      if (distance) return null;

      return (
        <ScrollSync onSync={onHorizontalScroll}>
          <TableWrapper>
            <LeftTableHeader withPlace participantsCount={<UsersCount />} />
            <RightSideWrapper sticky>
              <RightHeader />
            </RightSideWrapper>

            <LeftSideWrapper>
              <LeftSide />
            </LeftSideWrapper>
            <RightSideWrapper withBorder>
              <RightSide />
            </RightSideWrapper>
          </TableWrapper>
        </ScrollSync>
      );
    }}
  </Observer>
);
