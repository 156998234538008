import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

type Props = {
  showCalendar: boolean;
  dates: Array<any>;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${(props) => props.theme.main.colors.clay3};
  }
`;

const Calendar = styled(Svg)`
  margin-right: 8px;

  svg path {
    stroke: ${(props) => props.theme.main.colors.clay3};
  }
`;

export const RaceDateComponent = ({ showCalendar, dates }: Props) => (
  <Wrapper className='race-date'>
    {showCalendar && <Calendar name='DateCalendar' />}
    {dates.map((date, index) => {
      if (dates[index - 1] === date) {
        return null;
      }

      return (
        <Utility variant='u3' weight='bold' className='race-date-item' key={date}>
          {date}
          {!index && '\xa0-\xa0'}
        </Utility>
      );
    })}
  </Wrapper>
);
