import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { isDistanceHasMore } from '../../utils';

import { distancesStore } from '../../stores';

import { currentDistance } from '../currentDistance';

const distanceToBeLoaded: IComputedValue<null | number> = computed(() => {
  const distanceId = currentDistance.id.get();

  if (!!distanceId) {
    return distanceId;
  }

  // Find distance that still has items to load
  const nextDistance = distancesStore.filteredValues.find((distance) => isDistanceHasMore(distance.id));

  return nextDistance?.id || null;
});

export { distanceToBeLoaded };
