import axios from 'axios';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {
  API_RACES_URL,
  API_URL,
  API_V2_URL,
  LOAD_RACES,
  LOAD_RACE,
  RACE,
  LOAD_MORE_RACES,
  FETCH_RACE,
  CURRENT_RACES_FILTER_LOAD_VERSION,
  API_SEARCH,
} from 'src/constants';

import { request, action, versionControl } from 'utils';

import { LoadableService } from 'services';

import { racesStore, Race, pastRacesStore } from 'stores';
import { progressStore, sportTypesStore } from 'stores';

import { PastRaces as PastRacesService } from './pastRaces';

class Races extends LoadableService<RacesType, FiltersType, Race> {
  pastRaceService: PastRacesService;
  racesRequestVersion: number = 0;

  constructor(store: Race) {
    super({
      store,
      resourcesUrl: `${API_V2_URL}${API_RACES_URL}`,
      resource: RACE,
    });

    this.pastRaceService = new PastRacesService(pastRacesStore);
  }

  addValues(values: Array<RacesType>, page: number, queryParams: FiltersType) {
    const mappedValues = values.map((value) => ({
      ...value,
      sport: sportTypesStore.values.find((sport) => sport.id === value.sport?.id),
    }));
    this.store.addValues(mappedValues, page, queryParams);
  }

  @request({ action: LOAD_RACES })
  async loadResourcesRequest(params: FiltersType | {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @request({ action: FETCH_RACE })
  async loadResourceRequest(id: string, params: FiltersType | {}): Promise<any> {
    return super.loadResourceRequest(id, params);
  }

  @request({ action: LOAD_MORE_RACES })
  async loadMoreRequest(params: Object, filters: Object): Promise<any> {
    return axios.post(`${API_URL}${API_SEARCH}`, filters, { params });
  }

  @action({ action: LOAD_RACE, minRequestTime: 800 })
  async loadResource(id: string, params: FiltersType = {}, sendStatistics: boolean = true): Promise<any> {
    return await this._loadResource(id, sendStatistics);
  }

  // To load resource without preloader in some of cases
  @action({ action: FETCH_RACE })
  async _loadResource(id: string, sendStatistics: boolean = true): Promise<any> {
    const [status, response] = await this.loadResourceRequest(id, {});

    if (status && sendStatistics) {
      this.store.addSelectedValue(response.data.data);
    }

    return response;
  }

  @action({ action: LOAD_MORE_RACES, minRequestTime: 200 })
  async loadMore(params: FiltersType = {}): Promise<any> {
    const version = this.racesRequestVersion + 1;
    this.racesRequestVersion = version;

    let { filters, page, size } = this.store || {};

    // Here will be calculated page
    const query = {
      from: size * page,
      size,
    };
    // And that incrementing of page
    page = ++page;

    const body = {
      ...filters,
      ...params,
      page,
    };

    const bodyFilters = Object.keys(body).reduce((result, value) => {
      if (!body[value]) {
        return result;
      }

      if (isArray(body[value]) && isEmpty(body[value])) {
        return result;
      }

      return {
        ...result,
        [value]: body[value],
      };
    }, {});

    const [status, response] = await this.loadMoreRequest(query, bodyFilters);
    if (this.racesRequestVersion !== version) return;

    if (status) {
      let values = [];
      if (this.resourcesRoot === 'none') {
        values = response.data;
      } else {
        values = response.data[this.resourcesRoot];
      }
      const mappedValues = values.map((value: AnyObject) => {
        const now = moment();
        const raceEndDate = moment(value.race_end_date).endOf('day');
        const isPast = raceEndDate.isBefore(now);

        const result = {
          ...value,
          isPast: isPast && !pastRacesStore.isPastRacesShown,
          sport: sportTypesStore.values.find((sport) => sport.id === value.sport_id),
        };

        if (isPast && !pastRacesStore.isPastRacesShown) {
          pastRacesStore.setIsPastRacesShown(isPast);
        }

        return result;
      });

      this.store.appendValues(mappedValues as any[], page, bodyFilters);

      const paginationMeta = response.data[this.metaRoot] && response.data[this.metaRoot][this.paginationMetaRoot];
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  @action({ action: LOAD_RACES, minRequestTime: 300 })
  async loadResourcesSearch(
    params: FiltersType = {},
    cityRaceField?: {
      q: string;
    },
  ): Promise<any> {
    // To reject parallel requests, and accept only the last
    // Because debounce IS NOT ENOUGH
    const requestVersion = versionControl.incrementVersion(CURRENT_RACES_FILTER_LOAD_VERSION);
    const interuptCallback = () => {
      return requestVersion !== versionControl.currentVersion(CURRENT_RACES_FILTER_LOAD_VERSION);
    };

    const newParams = this.getSearchModelParams(params);
    const queryParams = this.generateSearchParams(newParams, cityRaceField);

    await super.plainLoadResources(queryParams, 0, interuptCallback);

    if (interuptCallback()) {
      return;
    }

    await this.pastRaceService.loadWithFilters(queryParams);

    this.store.commitFirstLoad();
  }

  @action({ action: LOAD_RACES, minRequestTime: 300 })
  async loadListViewRaces(params: FiltersType = {}, cityRaceField?: { q: string }): Promise<any> {
    // To reject parallel requests, and accept only the last
    // Because debounce IS NOT ENOUGH
    const requestVersion = versionControl.incrementVersion(CURRENT_RACES_FILTER_LOAD_VERSION);
    const interuptCallback = () => {
      return requestVersion !== versionControl.currentVersion(CURRENT_RACES_FILTER_LOAD_VERSION);
    };

    const newParams = this.getSearchModelParams(params);
    const queryParams = this.generateSearchParams(newParams, cityRaceField);

    await super.plainLoadResources(queryParams, 0, interuptCallback);

    if (interuptCallback()) {
      return;
    }

    await this.pastRaceService.loadWithFilters(queryParams);

    this.store.commitFirstLoad();
  }

  getSearchModelParams(params: FiltersType) {
    const searchModel = this.store.retrieveSearchDataModel();
    searchModel.clearFilters();

    let dateFilters = {};
    let nameFilters = {};

    if (this.store.needToSetCurrentDateFilter(params)) {
      dateFilters = { race_end_date: this.store.currentDateFilter() };

      searchModel.handlers = this.store.defaultHandlers;
    } else {
      searchModel.handlers = this.store.handlers;
    }

    if (params.id) {
      nameFilters = { name: '' };
    }

    searchModel.addFilters({
      ...params,
      ...dateFilters,
      ...nameFilters,
    });

    return searchModel.params();
  }

  generateSearchParams(newParams: FiltersType, cityRaceField?: { q: string }): any {
    let { orderBy, sortedBy, filters } = this.store;
    const page = 1;
    let queryParams = {
      search: '',
      searchFields: '',
      with: filters.with,
      limit: filters.limit,
      orderBy,
      sortedBy,
      page,
    };

    if (!isEmpty(newParams)) {
      queryParams = {
        ...queryParams,
        ...newParams,
        orderBy,
        sortedBy,
        page,
      };
    }

    if (!cityRaceField) {
      return;
    }

    if (cityRaceField.q) {
      queryParams = {
        ...queryParams,
        ...cityRaceField,
      };
    }
    return queryParams;
  }
}

const racesService = new Races(racesStore);

export { Races, racesService };
