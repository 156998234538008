import {
  ClassesStore,
  WavesStore,
  SquadsStore,
  UserModal,
  Filters,
  PaginationMetaStore,
  RegistrationFieldsStore,
  LifeStore,
  VersionStore,
} from 'modules/racersTable/stores';

import { BooleanStore, Disposers } from 'stores';

import { InternalFilters } from '../types';
import { CounterStore } from './counter';
import { DataStore } from './data';
import { DistancesStore } from './distances';

const distancesStore = new DistancesStore();
const counterStore = new CounterStore();
const classesStore = new ClassesStore();
const wavesStore = new WavesStore();
const squadsStore = new SquadsStore();
const registrationFieldsStore = new RegistrationFieldsStore();

const filtersOpenedStore = new BooleanStore();
const isFindInterceptionHandled = new BooleanStore();

const dataStore = new DataStore();
const userModalStore = new UserModal();

const filtersStore = new Filters<InternalFilters>();
const paginationMetaStore = new PaginationMetaStore();
const disposers = new Disposers();
const lifeStore = new LifeStore();
const requestsVersionStore = new VersionStore();
const focusStore = new BooleanStore();
const isAppInitializedStore = new BooleanStore();

export {
  dataStore,
  distancesStore,
  counterStore,
  classesStore,
  wavesStore,
  squadsStore,
  filtersOpenedStore,
  userModalStore,
  filtersStore,
  paginationMetaStore,
  disposers,
  lifeStore,
  registrationFieldsStore,
  isFindInterceptionHandled,
  isAppInitializedStore,
  requestsVersionStore,
  focusStore,
};

export * from './distances';
export * from './params';
