import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

type Props = {
  currency: string;
  price: string | number;
  raceStarts: string;
  raceEnds: string;
  racersCount: string | number;
  racersMax: string | number | null;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .clay3 {
    color: ${(props) => props.theme.main.colors.clay3};
    margin-top: 1px;
  }
`;

const Square = styled.div`
  width: 3px;
  height: 3px;
  background: #656b6f;
  margin: 0 12px;
`;

export const DistanceBottomArea = (props: Props) => {
  const { currency, price, raceStarts, raceEnds } = props;

  const dateMask = (date) => moment(date).format('DD MMM YYYY');
  const raceDate = raceEnds ? `${dateMask(raceStarts)} - ${dateMask(raceEnds)}` : dateMask(raceStarts);

  return (
    <Wrapper>
      <Utility weight='medium' variant='u3' className='clay3'>
        {raceDate}
      </Utility>
      <Square />
      <Utility weight='medium' variant='u3' className='clay3'>
        {price} {currency}
      </Utility>
    </Wrapper>
  );
};

/* This is commented code of participants (Request of XTRI)


  const UserWrapper = styled.div`
   margin-top: 1px;

   img {
     width: 10px;
     height: 12px;
     margin: 0 8px -1px 0;
   }
 `;

  <Square />
  <UserWrapper>
    <Icon name='User2' size={12} />
    <Utility weight='medium' variant='u3' className='clay3'>
      {racersCount} / {racersMax}
    </Utility>
  </UserWrapper>

 */
