import { classOptions } from './classes';
import { isDisciplineOptionAvailable, disciplines } from './disciplines';
import { distanceOptions } from './distances';
import { isWaveOptionAvailable, waveOptions } from './waves';

export const selectOptions = {
  classes: classOptions,
  distanceOptions,
  waves: waveOptions,
  isWaveOptionAvailable,
  disciplines,
  isDisciplineOptionAvailable,
};
