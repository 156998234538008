import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, MILES_SUGGESTED_RACE_URL } from 'src/constants';

import { request, action } from 'utils';

type TargetRace = {
  id: number;
  name: string;
  race_date: Date;
  race_length: number;
  race: Race;
  days_left: number;
};
type Race = {
  id: number;
  name: string;
  image: string;
};

class TargetRaceService {
  @request({ action: shortid() })
  private requestSuggestedRace(userId: number): Promise<any> {
    const url = generatePath(API_URL + MILES_SUGGESTED_RACE_URL, { userId });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async loadSuggestedRace(userId: number): Promise<TargetRace | null> {
    const [isOk, response] = await this.requestSuggestedRace(userId);
    if (isOk) {
      return response.data.data;
    }
    return null;
  }
}

const targetRaceService = new TargetRaceService();
export { targetRaceService };
