import * as React from 'react';
import shortId from 'shortid';
import styled from 'styled-components';

import { LinksBlock } from 'src/modules/footer/components/LinksBlock';
import { Link as LinkType } from 'src/modules/footer/types';

import { t } from 'utils';

import { Title } from './';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 56px 0;
  letter-spacing: 0.03em;
`;

const LeftWrapper = styled.div`
  display: flex;
`;

const BlockWrapper = styled.div`
  margin-right: 67px;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 174px;
`;

const Paragraph = styled.div`
  color: ${(props) => props.theme.main.colors.clay7};
  font-family: ${(props) => props.theme.main.font};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 16px 0 0 0;
  display: block;
`;

type Props = {
  menuLinks: Array<{ title: string; links: Array<LinkType> }>;
};

export const TabletDefaultFooterComponent: React.FC<Props> = (props: Props) => {
  return (
    <Wrapper>
      <LeftWrapper>
        {props.menuLinks.map((item, index) => (
          <BlockWrapper key={`footer-link-${item.title}`}>
            <LinksBlock key={'footer_links_block_' + index} title={item.title} links={item.links} />
          </BlockWrapper>
        ))}
      </LeftWrapper>

      <RightWrapper>
        <Title>{t.staticAsString('redesign.office')}</Title>
        <Paragraph>{t.staticAsString('redesign.footerAddress')}</Paragraph>
        <Paragraph>{t.staticAsString('redesign.footerAddress2')}</Paragraph>
      </RightWrapper>
    </Wrapper>
  );
};
