import { pick } from 'lodash';
import { Data } from 'modules/registration/types/data';

import { AdditionalRegistrationFields } from '../../constants';

/**
 * @description
 * Select only provided additional fields,
 * possible return value: { class_id: number, wave_id: number, discipline_id: number }
 */
function selectRacerFormAdditionalFields(fields: AdditionalRegistrationFields[]) {
  return (data: Data.Form.Racer): Data.Form.Racer => {
    return pick(data, fields);
  };
}

/**
 * @description
 * Select only provided additional fields,
 * possible return value: { class_id: number, discipline_id: number }
 */
function selectRacerBEAdditionalFields(fields: AdditionalRegistrationFields[]) {
  return (data: Data.Backend.RacerToReceive): Data.Backend.RacerToReceive => {
    return pick(data, fields) as Data.Backend.RacerToReceive;
  };
}

export { selectRacerFormAdditionalFields, selectRacerBEAdditionalFields };
