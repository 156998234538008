import { cloneDeep } from 'lodash';
import { getTimeResult } from 'modules/profile/selections';

import { VirtualDistance, VirtualDistanceMapped } from '../types';

export const virtualListMapper = (
  values: Array<VirtualDistance> = [],
  type: 'virtual' | 'classic' = 'virtual',
): Array<VirtualDistanceMapped> => {
  const copy = cloneDeep(values);

  const isDns = type === 'classic';

  return copy.map((item) => ({
    ...item,
    editMode: !!item.racers[0].result?.id,
    resultData: {
      place: item.racers[0].result?.place,
      time_result: getTimeResult(item, isDns),
      distance_logged: item.racers[0].result?.distance_logged,
      distance_logged_at: item.racers[0].result?.distance_logged_at,
      activity_link: item.racers[0].result?.activity_link,
    },
  }));
};
