import * as React from 'react';

type Props = {
  if: boolean;
  children: React.ReactNode;
};

function Hide(props: Props) {
  if (props.if) {
    return null;
  }

  return props.children as React.ReactElement<any>;
}

function Show(props: Props) {
  if (!props.if) {
    return null;
  }

  return props.children as React.ReactElement<any>;
}

export { Hide, Show };
