import moment from 'moment';

export const countWeeks = (startDate: string, endDate: string): number => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const duration = moment.duration(endMoment.diff(startMoment));
  return Math.floor(duration.asWeeks());
};

export const weeksToGoMessage = (startDate: string, endDate: string) => {
  const weeksToGo = countWeeks(startDate, endDate);
  if (weeksToGo === 1) return '1 Week to go';
  return weeksToGo ? `${weeksToGo} Weeks to go` : 'Race week!';
};
