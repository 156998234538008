import * as React from 'react';
import styled from 'styled-components';

import { MobileAdditionalInfo } from 'src/modules/racersTable/components';
import { Utility } from 'src/styledComponents/Typography';

import { classesStore, squadsStore, wavesStore } from '../../stores';

import { RacerName } from '../RacerName';

type Props = {
  value: RacersType;
  withBibs?: boolean;
};

const Wrapper = styled.div`
  padding: 20px 0;
  background-color: ${(props) => props.theme.main.colors.clay6};

  &:nth-of-type(odd) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

const Header = styled.div<{ withBibs: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.withBibs ? 35 : 0)}px 1fr 24px;
  gap: ${(props) => (props.withBibs ? 8 : 0)}px;

  align-items: center;
  padding: 0 20px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
  white-space: nowrap;
`;

export const MobileSingleItem: React.FC<Props> = (props) => {
  const { value, withBibs = true } = props;

  const classModel = classesStore.find(+value.class_id!);
  const waveModel = wavesStore.find(+value.wave_id!);
  const squad = squadsStore.values.find((item) => item.id === value.squad_id!);

  return (
    <Wrapper>
      <Header withBibs={withBibs}>
        <StyledUtility variant='u2' weight='medium'>
          {withBibs ? value.bib_number : ''}
        </StyledUtility>
        <RacerName value={value} />
      </Header>
      <MobileAdditionalInfo
        classTitle={classModel?.value.title}
        union={value.union}
        wave={waveModel?.value.name}
        squad={squad?.name}
        withBibs={withBibs}
      />
    </Wrapper>
  );
};
