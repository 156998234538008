import { isNil } from 'lodash';
import { computed, toJS } from 'mobx';

import { resultsUtil } from '../../utils';

import { ResultStatus, LResult } from '../../types';

/**
 * @description
 * Formatted split.prev_split_diff
 * Formula: (PrevSplit.finish_time || Result.start_time) - NextSplit.finish_time
 * https://imgur.com/bybwS4E
 */
function generatePreviousSplitDiff(result: LResult, checkpointId: number, checkpointIndex: number) {
  return computed(() => {
    const firstCheckpointIndex = 0;
    const split = resultsUtil.findSplit(result, checkpointId);

    const computeDiff = () => {
      const currentSplit = result.splits.find((split) => split.checkpoint_id === checkpointId);
      const previousSplit = result.splits[checkpointIndex - 1]!;
      if (!currentSplit || !previousSplit) return null;
      let currentSplitRounded = Math.round(currentSplit.time_result_ms / 1000) * 1000;
      let previousSplitRounded = Math.round(previousSplit.time_result_ms / 1000) * 1000;

      return previousSplitRounded - currentSplitRounded;
    };

    // For fist checkpoint we need use time 0:00
    const diff = split && checkpointIndex === firstCheckpointIndex ? 0 : computeDiff();

    if (result.status === ResultStatus.DNS || isNil(diff)) {
      return resultsUtil.formatFallbackSplit();
    }
    return resultsUtil.formatDiff(diff);
  });
}

export { generatePreviousSplitDiff };
