import classNames from 'classnames';
import { observer } from 'mobx-react';
import { displayEditControls, getTotalInfo } from 'modules/segments/selections';
import * as React from 'react';
import styled from 'styled-components';

import { NO_SOURCE_LINK, RESOLUTIONS } from 'src/constants';
import { HardElevation } from 'src/styledComponents/Elevation';
import { HoverImage } from 'src/styledComponents/HoverImage';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import * as Icons from 'img/thirdParty';
import * as GreyIcons from 'img/thirdParty/grey/';

import { Hide, Show } from 'components/condition';

import { recognizeLink, urlSanitizer } from 'utils';

import { onDeleteSegment } from '../actions';

type Props = {
  link?: string | void | null;
  segmentId: number;
  actionDisabled?: boolean;
  onEdit?: AnyFunction;
  showPercents?: boolean;
  progressText?: string;
};

const Wrapper = styled.div`
  height: 24px;

  .activity-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;

    .source-image {
      width: 24px;
      height: 24px;
      border-radius: 1px;
    }
  }

  .tooltip-text {
    position: absolute;
    z-index: 5;
    background-color: ${(props) => props.theme.main.colors.white};
    color: ${(props) => props.theme.main.colors.clay1};
    word-wrap: break-word;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    min-width: 300px;
    text-align: center;
    transition: opacity 200ms;
    text-transform: none;
    right: 92px;

    &::after {
      position: absolute;
      content: '';
      width: 25px;
      height: 25px;
      transform: translate3d(50%, -50%, 0) rotate(-45deg);
      z-index: 10;
      background-color: ${(props) => props.theme.main.colors.white};
      top: 50%;
      right: 0;
    }
  }
  .percent {
    position: absolute;
    z-index: 1;
    top: 35%;
    display: block;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    &:hover {
      .tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .tooltip-text {
      display: none;
    }
    .percent {
      display: none;
    }
    .activity-link {
      .source-image {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

const StyledSvg = styled(Svg)`
  :hover {
    cursor: pointer;
  }
`;

@observer
export class TrackingSource extends React.Component<Props> {
  static contextType = TestAnchorContext;
  declare context: React.ContextType<typeof TestAnchorContext>;

  renderSourceLink = () => {
    const { link } = this.props;
    if (!link) {
      return (
        <Wrapper>
          {this.renderPercents()}
          <div className='activity-link no-source'>
            <img src={Icons[NO_SOURCE_LINK.icon as keyof typeof Icons]} alt='no_source' className='source-image' />
          </div>
        </Wrapper>
      );
    }

    const source = recognizeLink(link);

    const src = GreyIcons[`${source.icon}Grey` as keyof typeof GreyIcons];
    const src2x = GreyIcons[`${source.icon}2xGrey` as keyof typeof GreyIcons];
    const srcSet = src2x ? `${src2x} 2x` : '';

    const hoverSrc = Icons[source.icon as keyof typeof Icons];
    const hoverSrc2x = Icons[`${source.icon}2x` as keyof typeof Icons];
    const hoverSrcSet = hoverSrc2x ? `${hoverSrc2x} 2x` : '';

    return (
      <Wrapper>
        {this.renderPercents()}
        <a
          className='activity-link tooltip'
          href={urlSanitizer(link)}
          target='_blank'
          {...testAnchors.link(this.context.container, 'fromActivityApp')}
        >
          <HoverImage src={src} srcSet={srcSet} hoverSrc={hoverSrc} hoverSrcSet={hoverSrcSet} alt='source' className='source-image' />
          <HardElevation level={4} className='tooltip-text'>
            {link}
          </HardElevation>
        </a>
      </Wrapper>
    );
  };

  renderPercents = () => {
    const { showPercents, progressText } = this.props;

    return (
      <Show if={Boolean(showPercents)}>
        <Utility variant='u4' weight='medium' className='percent'>
          {progressText}
        </Utility>
      </Show>
    );
  };

  render() {
    const { segmentId, onEdit } = this.props;
    const hide = !displayEditControls.get();

    return (
      <React.Fragment>
        {this.renderSourceLink()}

        <Hide if={hide}>
          <StyledSvg
            name='Edit'
            size={24}
            onClick={onEdit}
            className={classNames('edit-icon', { 'hide-icon': hide })}
            {...testAnchors.button(this.context.container, 'edit')}
          />
          <StyledSvg
            name='Delete'
            size={24}
            onClick={onDeleteSegment(segmentId)}
            className={classNames('edit-icon delete-icon', { 'hide-icon': hide })}
            {...testAnchors.button(this.context.container, 'delete')}
          />
        </Hide>
      </React.Fragment>
    );
  }
}
