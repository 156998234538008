import * as React from 'react';

type Props = {
  value: string | number;
  hex?: string;
};

export class PlaceCell extends React.Component<Props> {
  render() {
    const { hex, value } = this.props;
    const borderLeftColor = hex && `#${hex}`;

    return (
      <div
        className='place-cell'
        style={{
          borderLeftColor: borderLeftColor ? borderLeftColor : 'inherit',
        }}
      >
        {value}
      </div>
    );
  }
}
