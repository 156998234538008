import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { SmoothElevation } from 'src/styledComponents/Elevation';

const EventOrganizerBannerWrapper = styled(SmoothElevation)<{ size: 'mobile' | 'tablet' | 'desktop' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: linear-gradient(85.23deg, rgba(214, 241, 0, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%), #272e34;
  z-index: 15;

  .event-organizer-banner-text {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-transform: initial;
    color: ${(props) => props.theme.main.colors.clay6};
    @media (max-width: ${RESOLUTIONS.medium}px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  ${(props) =>
    (props.size === 'tablet' || props.size === 'mobile') &&
    css`
      padding: 16px;
    `}
`;

const BannerContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1280px;

  @media (min-width: ${RESOLUTIONS.large}px) {
    width: 1180px;
    padding: 0;
  }

  @media (min-width: ${RESOLUTIONS.xlarge}px) {
    width: 1280px;
    padding: 0;
  }
`;

export const SubBox = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseBtn = styled('div')(
  ({ theme }) => css`
    margin-left: 32px;
    background: ${theme.main.colors.white};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    svg {
      width: 13.5px;

      path {
        stroke-width: 2;
        stroke: ${theme.main.colors.clay0};
      }
    }

    &:hover {
      background: ${theme.main.colors.clay2};

      svg {
        path {
          stroke: ${theme.main.colors.clay4};
        }
      }
    }

    @media (max-width: ${RESOLUTIONS.medium}px) {
      margin-left: 16px;
    }
  `,
);
export { EventOrganizerBannerWrapper, BannerContentWrapper };
