import { set, get } from 'lodash';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

class App {
  init() {
    window.app = {};
  }

  reload() {
    window.location.reload();
  }

  stub() {
    history.push(ROUTER.SERVICE_UNAVAILABLE);
  }

  assign(path: string, value: any) {
    return set(window.app, path, value);
  }

  fetch(path: string) {
    return get(window.app, path);
  }
}

const app = new App();
export { App, app };
