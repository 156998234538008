import { teamsService, racersService } from '../../services';

import { distancesStore, requestsVersionStore, dataStore } from '../../stores';
import { params } from '../../stores/params';

import { dataChanger } from '../../mutations';

async function load(distanceId: number, page: number) {
  const version = requestsVersionStore.fetch();
  const distance = distancesStore.find(distanceId);

  if (!distance) {
    return;
  }

  if (dataStore.exists(distanceId, page)) {
    return;
  }

  let response;

  if (distance.isSingle()) {
    response = await racersService.load(version, distanceId, page, params.single());
  }

  if (distance.isTeam()) {
    response = await teamsService.load(version, distanceId, page, params.team());
  }

  if (!response) {
    return;
  }
  if (!requestsVersionStore.compare(version)) {
    return;
  }

  const { meta, data } = response;

  dataChanger.add(distanceId, page, data, meta);

  return data.length;
}

export { load };
