import classNames from 'classnames';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { CollapseRace } from 'components/icons';

import { raceUtil } from 'utils';

type Props = {
  collapsed: boolean;
  handleCollapse: Function;
  isCollapsable: boolean;
  value: any;
};

function ActiveMobileHeader({ isCollapsable, collapsed, value, handleCollapse }: Props) {
  if (isEmpty(value)) {
    return null;
  }

  const collapse = () => {
    if (isCollapsable) {
      handleCollapse();
    }
  };

  return (
    <div className={classNames('distance-row active hide-desktop', { collapsed })} onClick={collapse}>
      <div className='distance-info-wrap'>
        <div className='distance-length'>{raceUtil.humanizeDistance(value.race_length)}</div>
        <span className='distance-title'>{value.title}</span>
      </div>
      {isCollapsable && <span>{CollapseRace}</span>}
    </div>
  );
}

export { ActiveMobileHeader };
