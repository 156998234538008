import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { DefaultFlag } from 'src/styledComponents/Flag';
import { Utility } from 'src/styledComponents/Typography';

import { findCountryCode } from '../../utils/shared';

type ParticipantProps = {
  value: RacersType;
  onShow: (racerId: number) => AnyFunction;
};

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
  white-space: nowrap;
`;

const NameWrap = styled.div`
  display: grid;
  grid-template-columns: 21px 1fr;
  gap: 16px;
  align-items: center;
  cursor: pointer;

  .flag {
    min-width: 21px;
    height: 15px;
    border-radius: 2px;
  }
`;

export const Participant: React.FC<ParticipantProps> = (props) => {
  const { value, onShow } = props;

  const getCountryCode = () => {
    if (value.nationality_id) {
      return findCountryCode(value.nationality_id);
    }

    if (value.country_id) {
      return findCountryCode(value.country_id);
    }

    return '';
  };

  const countryCode = getCountryCode();
  const countryClassName = countryCode ? `flag-icon-${countryCode}` : '';

  return (
    <NameWrap onClick={onShow(value.id)}>
      <Show if={Boolean(countryCode)}>
        <span className={`flag ${countryClassName}`} />
      </Show>
      <Show if={!countryCode}>
        <DefaultFlag className='flag' />
      </Show>
      <StyledUtility variant='u4' weight='bold'>
        {value.full_name}
      </StyledUtility>
    </NameWrap>
  );
};
