import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

import { ROUTER, DEFAULT_RACE_IMAGE } from 'src/constants';
import { SportType } from 'src/models';
import { Distance } from 'src/models';
import { getTimeResult } from 'src/modules/profile/selections';
import { RegisteredDistanceType } from 'src/modules/profile/types';
import { sportTypesStore } from 'src/stores';
import { raceUtil, time } from 'src/utils';

import { DistanceType, RaceType } from '../types';

export const mapRaces = (data: AnyObject[]): RaceType[] => {
  const copy = cloneDeep(data);
  const races: RaceType[] = [];

  copy.forEach((item, index) => {
    const sport = sportTypesStore.valuesById[item.sport_id || item.sport?.id];
    const sportModel = new SportType(sport);
    const { country, city, street_address, street_number } = item.location || {};
    const filteredLocations = [country, city, street_address, street_number].filter((item) => !!item);
    const isPast = moment().startOf('day').isAfter(item.race_end_date);
    const firstInPast = index === 0 ? false : !races[index - 1].isPast && isPast;

    races.push({
      ...item,
      image_default: item.image_default || get(item, 'image') || DEFAULT_RACE_IMAGE,
      image: item.image || DEFAULT_RACE_IMAGE,
      name: item.name,
      distances: item.distances.map((distance) => Math.round(distance.race_length / 100) * 100),
      start_date: time(item.race_date, 'date').parse().format('date'),
      end_date: item.race_end_date ? time(item.race_end_date, 'date').parse().format('date') : '',
      locations: filteredLocations,
      raceLink: generatePath(ROUTER.RACE_ABOUT_US, { id: item.id }),
      sportIcon: sport?.icon || '',
      sportTypeName: sportModel.title,
      route: item.route,
      firstInPast,
      isPast,
      startDate: time(item.race_date, 'date').parse().formatAsString('date'),
      endDate: item.race_end_date ? time(item.race_end_date, 'date').parse().formatAsString('date') : '',
    } as unknown as RaceType);
  });

  return races;
};

export const mapDistances = (data: RegisteredDistanceType[]): DistanceType[] => {
  const copy = cloneDeep(data);

  const getRacerResult = (distance: AnyObject = {} as any): AnyObject => {
    if (distance.type === 'team') {
      return (distance.teams || [])[0]?.result || {};
    }
    return (distance.racers || [])[0]?.result || {};
  };

  return copy.map((item) => {
    const distanceModel = new Distance(item as any);
    const result = getRacerResult(item);
    const teamName = distanceModel.value.teams?.find((team) => team.id === result.team_id)?.name ?? '';
    const distanceStartsAt = distanceModel.value.race_date;
    const distanceEndsAt = distanceModel.value.ends_at;

    return {
      ...item,
      id: distanceModel.value.id,
      name: distanceModel.value.name,
      raceName: get(item, 'race.name') || get(item, 'raceName'),
      startDate: distanceStartsAt ? time(distanceStartsAt, 'date').parse().formatAsString('date') : '',
      endDate: distanceEndsAt ? time(distanceEndsAt, 'date').parse().formatAsString('date') : '',
      distanceLength: raceUtil.humanizeDistance(+item.race_length),
      image: distanceModel.value.default_image || get(item, 'race.image') || DEFAULT_RACE_IMAGE,
      location: distanceModel.value.location,
      place: result.place || '-',
      raceResultsLink: generatePath(ROUTER.RACE_RESULT_WITH_DISTANCE, {
        id: item.race_id || get(item, 'race_parent_id'),
        distanceId: distanceModel.value.id,
      }),
      sportIcon: distanceModel.value.race?.sport.icon ?? '',
      sportType: distanceModel.value.race?.sport.title ?? '',
      teamName: teamName,
      time: getTimeResult(item as any, true),
      total: distanceModel.isSingle() ? distanceModel.value.racers_count : distanceModel.value.teams_count,
    } as unknown as DistanceType;
  });
};
