import flat from 'flat';
import { flatMap, unset } from 'lodash';
import { get } from 'lodash';
import { observable, action, makeObservable } from 'mobx';

import { Errors as ErrorUtil } from 'utils';

class Errors {
  // @deprecated, since erroribus
  @observable
  errors: any = {
    front: {},
    api: {},
  };

  // New frontend and backend unified errors
  @observable erroribus: { [K in string]: ErrorType | null } = {};

  constructor() {
    makeObservable(this);
  }

  // @deprecated, since erroribus
  @action
  addFrontErrors(action: string, errors: { [K in string]: Array<string> }): void {
    this.errors.front[action] = errors;
  }

  // @deprecated, added for old functional
  @action
  addSingleError(action: string, name: string, error: Array<string>): void {
    this.errors.front = {
      ...this.errors.front,
      [action]: {
        ...this.errors.front[action],
        [name]: error,
      },
    };
  }

  // @deprecated, since erroribus
  @action
  addApiErrors(action: string, errors: { [K in string]: Array<string> }): void {
    this.errors.api[action] = { ...errors };
  }

  // @deprecated, since erroribus
  @action
  clearError(action: string): void {
    this.errors.api[action] = null;
    this.errors.front[action] = null;
  }

  // @deprecated, added for old functional
  @action
  cleanError(action: string, ...keys: Array<string>) {
    const values = this.errors.front[action];
    keys.forEach((key) => {
      unset(values, key);
    });

    this.errors.front[action] = values;
  }

  @action
  add(action: string, values: RawError) {
    const oldValues = this.erroribus[action] || {};
    this.erroribus[action] = {
      ...ErrorUtil.mergeValues(oldValues, ErrorUtil.unfold(values)),
    };
  }

  @action
  clean(action: string, ...keys: Array<string>) {
    const values = this.erroribus[action];
    keys.forEach((key) => {
      unset(values, key);
    });

    this.erroribus[action] = values;
  }

  @action
  clear(action: string) {
    this.erroribus[action] = null;
  }

  get(action: string, key: string | string[]) {
    return get(this.erroribus[action] || {}, key);
  }

  /**
   * @description
   * Fetch errors by action in the following notation:
   * { ['key.separated.with.dot']: errors array[] }
   */
  getWithFlatKeys(action: string) {
    return flat(this.erroribus[action] || {});
  }
}

export { Errors };

export default new Errors();
