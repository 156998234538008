import { useState, ChangeEventHandler } from 'react';

const useLoadPreviewAvatar = () => {
  const [isShowAvatarModal, setIsShowAvatarModal] = useState<boolean>(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');

  const handleChangeFile = (e: ChangeEventHandler | any) => {
    const file: any = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(file);

    setIsShowAvatarModal(true);

    e.target.value = '';
  };

  const closeModal = () => {
    setIsShowAvatarModal(false);
  };

  return { isShowAvatarModal, imagePreviewUrl, handleChangeFile, closeModal };
};

export { useLoadPreviewAvatar };
