import { Text, Button } from 'modules/miles/shared/components';
import { CloseSharp } from 'modules/miles/shared/components/Icon';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClose: AnyFunction;
  onConfirm: AnyFunction;
  serviceLabel?: string;
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  background: ${(props) => props.theme.main.colors.clay3}A9;
`;

const Box = styled.div`
  max-width: 385px;
  border-radius: 16px;
  background: ${(props) => props.theme.miles.colors.dark.background['600']};
  box-shadow: ${(props) => props.theme.miles.shadows.dark.elevetion3};
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .header {
    position: relative;
    padding: 0 32px;
    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      &:hover {
        color: ${(props) => props.theme.miles.colors.dark.alpha['500']};
      }
    }
  }

  .controls {
    margin-top: 16px;
  }
`;

export const ConfirmationPopup = ({ onClose, onConfirm, serviceLabel }: Props) => {
  return (
    <Wrapper>
      <Box>
        <div className='header'>
          <CloseSharp className='close-icon' onClick={onClose} />
          <Text weight={'bold'} size={'lg'}>
            Remove {serviceLabel} Integration?
          </Text>
        </div>
        <Text weight={'regular'} size={'md'}>
          You can always reconnect later
        </Text>
        <Button className='controls' message={'negative'} size={'xl'} mode={'dark'} onClick={onConfirm}>
          Remove Integration
        </Button>
      </Box>
    </Wrapper>
  );
};
