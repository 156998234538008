import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Distance } from 'src/models/distance';
import { DistanceHeader } from 'src/modules/racersTable/components';

import { distancesStore } from '../../stores';

import { data, isAnyDistanceHasClasses, isAnyDistanceHasSquads, isAnyDistanceHasUnion, isAnyDistanceHasWaves } from '../../derivations';
import { RightSideRow } from '../Table/RightSideSingleRow';

type Props = {
  distance: Distance;
};

const Divider = styled.div`
  height: 30px;
  width: 100%;

  &:last-child {
    display: none;
  }
`;

const DistanceTable: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;

  const dataGenerator = data.generateDataEntries(distanceId);

  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isAnyDistanceHasWaves.get(),
          class: isAnyDistanceHasClasses.get(),
          club: isAnyDistanceHasUnion.get(),
          squad: isAnyDistanceHasSquads.get(),
        };

        const data: any[] = dataGenerator.get() || [];

        if (isEmpty(data)) return null;

        return (
          <>
            <DistanceHeader name='' />
            {data.map((item) => (
              <RightSideRow
                key={`table-right-row-${item.id}`}
                columnsActive={columnsActive}
                value={item}
                isTeam={distance.value.type === 'team'}
              />
            ))}
            <Divider />
          </>
        );
      }}
    </Observer>
  );
};

export const RightSide = () => {
  return (
    <Observer>
      {() => (
        <>
          {distancesStore.filteredModelValues.map((distance) => (
            <DistanceTable key={`right-all-${distance.value.id}`} distance={distance} />
          ))}
        </>
      )}
    </Observer>
  );
};
