import moment from 'moment';

import { duration } from 'utils';

export const getTimeResult = format({ dnsFormatting: false, emptyStub: '00:00:00' });
export const getTimeResultWithDns = format({ dnsFormatting: true, emptyStub: '00:00:00' });

function format(options: { dnsFormatting?: boolean; emptyStub: string }) {
  return function (startDate?: string, endDate?: string, withMillis?: boolean): string {
    let timeResult = options.emptyStub;

    const finish = moment.utc(endDate || NaN);
    const start = moment.utc(startDate || NaN);

    if (start.isValid() && !finish.isValid()) {
      timeResult = 'DNF';
    }

    if (options.dnsFormatting && !start.isValid()) {
      timeResult = 'DNS';
    }

    if (start.isValid() && finish.isValid()) {
      return withMillis
        ? duration.time(duration.calcDiff(start, finish), true)
        : duration.time(duration.roundDiff(duration.calcDiff(start, finish)));
    }

    return timeResult;
  };
}
