import { open, close } from './display';
import { editRegistrationInfo } from './editRegistrationInfo';
import { navigateToRace } from './navigateToRace';
import { onExportBib } from './onExportBib';
import { onExportDiploma } from './onExportDiploma';
import { onExportReceipt } from './onExportReceipt';
import { openDeleteTransferRegistrationModal } from './openDeleteTransferRegistrationModal';
import { openTransferRegistrationModal } from './openTransferRegistrationModal';
import { switchOn, toggleSwitchOn } from './switchOn';

export const actionMenuActions = {
  switchOn,
  toggleSwitchOn,
  open,
  close,
  onExportBib,
  onExportReceipt,
  onExportDiploma,
  navigateToRace,
  editRegistrationInfo,
  openTransferRegistrationModal,
  openDeleteTransferRegistrationModal,
};
