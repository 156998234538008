import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';

const CloseWrap = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  closeToast: AnyFunction;
};

export const ToastClose = (props: Props) => {
  const { closeToast } = props;

  return (
    <CloseWrap>
      <Icon name='AlertClose' size='24' onClick={closeToast} {...testAnchors.useButton('close')} />
    </CloseWrap>
  );
};
