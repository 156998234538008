import { isEqual } from 'lodash';
import { reaction } from 'mobx';

import { refreshApp, loader } from '../actions';

import { disposers, filtersStore } from '../stores';

/*
 * NOTE
 * Observe current filters state, in case it changes
 * reset pagination and reset app state as for loading
 */
function observeFilters() {
  const dispose = reaction(
    () => filtersStore.value,
    () => {
      refreshApp();
      loader.loadUntilViewport();
      loader.loadCounter();
    },
    { equals: isEqual },
  );

  disposers.register(dispose);
}

export { observeFilters };
