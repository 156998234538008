import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const UserIconPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12 12.5C15.0376 12.5 17.5 10.0376 17.5 7C17.5 3.96243 15.0376 1.5 12 1.5C8.96243 1.5 6.5 3.96243 6.5 7C6.5 10.0376 8.96243 12.5 12 12.5ZM2.02432 17.0163C4.99281 15.411 8.39151 14.5 12 14.5C15.6085 14.5 19.0072 15.411 21.9757 17.0163L22.5 17.2998V22.5H1.5V17.2998L2.02432 17.0163Z'
    fill='currentColor'
  />
);

export const UserIcon = (props: IconProps) => <SvgIcon path={UserIconPath} {...props} />;
