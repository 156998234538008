import axios from 'axios';

import { API_URL, API_CHECK_PASSWORD, ROUTER } from 'src/constants';

import { action, request, history, encodeQuery, t, fireToast } from 'utils';

import { authService } from 'services';

import { RESET_TOKEN_CHECKER } from '../constants';

class ResetPassword {
  @request({ action: RESET_TOKEN_CHECKER })
  async resetPasswordCheckRequest(token: string): Promise<any> {
    return axios.get(`${API_URL}${API_CHECK_PASSWORD}/${token}`);
  }

  @action({ action: RESET_TOKEN_CHECKER })
  async resetPasswordCheck() {
    const query = encodeQuery.parseQuery(history.location.search);

    const [status, response] = await this.resetPasswordCheckRequest(query.token);

    await authService.handleLogin(response);

    if (!status) {
      fireToast(t.staticAsString('login.resetErrorMessage'), 'error', t.staticAsString('login.errorHeader'), 'top-right');
      history.push(ROUTER.HOME);
    }
  }
}

const resetService = new ResetPassword();
export { resetService };
