import classNames from 'classnames';
import { Observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'src/styledComponents/Button';

import { Hide } from 'components/condition';

import { t } from 'utils';

import { windowSize } from 'stores';

import { onAddSegment } from '../actions';

import { displayLog } from '../selections';

type Props = {};

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding: 24px 0 32px;

  :empty {
    padding: 16px 0;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      .button {
        width: 100%;
        padding: 24px;
      }
    `}
`;

export const ButtonLog: React.FC<Props> = (props) => (
  <Observer>
    {() => {
      const hide = !displayLog.get();
      const isMobile = !windowSize.isGreaterThan('medium');

      return (
        <Wrapper className='button-holder' isMobile={isMobile}>
          <Hide if={hide}>
            <Button
              variant='primary'
              size={isMobile ? 'large' : 'small'}
              className={classNames('button outlined-grey', { 'hide-n-reserve-place': hide })}
              onClick={onAddSegment}
              ATN='submit'
            >
              {t('segmentsForm.addNewSegment')}
            </Button>
          </Hide>
        </Wrapper>
      );
    }}
  </Observer>
);
