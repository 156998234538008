import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const MoneyCardPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M22 6C22 4.89543 21.1046 4 20 4H4C2.89543 4 2 4.89543 2 6V8H22V6ZM4 20C2.89543 20 2 19.1046 2 18V11H22V18C22 19.1046 21.1046 20 20 20H4ZM15 17H19V15H15V17Z'
    fill='currentColor'
    fillOpacity='0.85'
  />
);

export const MoneyCard = (props: IconProps) => <SvgIcon path={MoneyCardPath} {...props} />;
