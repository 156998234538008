import * as React from 'react';

import { Svg } from 'src/styledComponents/Icons';

import { Props } from './LanguageSelectTypes';
import { Wrapper, SelectedLanguage } from './styled';

export const LanguageSelect: React.FC<Props> = (props) => {
  const { value, onClick, isDark = false } = props;

  return (
    <Wrapper {...testAnchors.useButton('languageSelector')} onClick={onClick} isDark={isDark}>
      <Svg name='Globe' className='icon' />
      <SelectedLanguage>{value}</SelectedLanguage>
    </Wrapper>
  );
};
