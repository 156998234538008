import { distanceInfoOpeningModalStore, distanceInfoStore } from '../stores';

export const openModal = (distanceInfo: AnyObject) => {
  distanceInfoOpeningModalStore.on();
  distanceInfoStore.setValue(distanceInfo);
};

export const closeModal = () => {
  distanceInfoOpeningModalStore.off();
  distanceInfoStore.clear();
};
