class Suffix {
  suffixes: Array<string>;

  constructor() {
    this.suffixes = ['th', 'st', 'nd', 'rd'];
  }

  _isNumber(number: number): boolean {
    if (typeof number !== 'number') {
      throw new TypeError(`Cannot determine ordinal suffix of something of type ${typeof number}`);
    }

    if (Number.isNaN(number)) {
      return false;
    }

    return true;
  }

  getSuffix(number: number): string {
    if (this._isNumber(number)) {
      const v = Math.abs(number) % 100;
      return this.suffixes[(v - 20) % 10] || this.suffixes[v] || this.suffixes[0];
    }
    return '';
  }

  toOrdinal(number: number): string {
    const s = this.getSuffix(number);
    return s ? number + s : number.toString();
  }
}

export const suffix = new Suffix();
