import styled from 'styled-components';

import { Divider } from 'src/styledComponents/Divider';

export const SidebarDivider = styled(Divider)`
  margin: 20px;
  width: unset;
  height: 1px;
  background-color: ${(props) => props.theme.main.colors.clay5};
`;
