import { Observer } from 'mobx-react';
import * as React from 'react';

import { windowSize } from 'stores';

import { toogleCollapse } from '../../actions';

import { ActiveMobileHeader } from './ActiveMobileHeader';
import { Item } from './Item';

type Props = {
  distances: Array<AnyObject>;
  activeId: number | null;
  collapsed: boolean;
  handleDistanceClick: () => void;
  handleCollapse: () => void;
};

class DistancesList extends React.PureComponent<Props> {
  static defaultProps: Props;

  render() {
    const { distances, activeId, collapsed } = this.props;

    const isCollapsable = distances.length > 1;
    const selectedDistance = distances.find((distance) => distance.id === activeId) || {};

    return (
      <div className='distances-block'>
        <ActiveMobileHeader isCollapsable={isCollapsable} handleCollapse={toogleCollapse} collapsed={collapsed} value={selectedDistance} />

        <Observer>
          {() => {
            // NOTE, For desktop there is no collapse feature
            const isCollapsed = collapsed && windowSize.isLessThan('medium');

            return (
              <>
                {distances.map((distance, index) => {
                  const isActive = distance.id === selectedDistance.id;
                  const isNextActive = distances[index + 1]?.id === selectedDistance.id;

                  return <Item isActive={isActive} isNextActive={isNextActive} collapsed={isCollapsed} value={distance} />;
                })}
              </>
            );
          }}
        </Observer>
      </div>
    );
  }
}

DistancesList.defaultProps = {
  distances: [],
  activeId: null,
  collapsed: false,

  handleDistanceClick: () => {},
  handleCollapse: () => {},
};

export { DistancesList };
