import { observer } from 'mobx-react';
import { disableActiveDay } from 'modules/miles/app/home/actions';
import { trainingPlanStore } from 'modules/miles/app/home/stores';
import { Divider } from 'modules/miles/app/home/ui/TrainingPlan/Divider';
import { RaceDayCard } from 'modules/miles/app/home/ui/TrainingPlan/RaceDayCard';
import { WeekPlanCard } from 'modules/miles/app/home/ui/TrainingPlan/WeekPlanCard';
import { Text } from 'modules/miles/shared/components';
import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';

import { Show } from 'components/condition';

import { history } from 'utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
  .divider-box {
    padding: 0 16px 24px;
  }
`;
export const FutureWeeksBlock = observer(() => {
  const compactPlanByWeeks = trainingPlanStore.compactPlanByWeeks.slice(2, 4);

  if (!compactPlanByWeeks) return null;

  return (
    <Wrapper>
      <div className='divider-box'>
        <Divider>
          <Text weight='bold' size={'lg'}>
            {'Future Weeks'}
          </Text>
        </Divider>
      </div>

      {compactPlanByWeeks.map((week) => (
        <WeekPlanCard
          key={week.startWeekDate}
          week={week}
          endDate={trainingPlanStore.endDate}
          onClick={() => {
            disableActiveDay();
            history.push(generatePath(ROUTER.MILES_TRAINING_PLAN_WEEK, { weekStartDate: week.startWeekDate }));
          }}
        />
      ))}
      <Show if={trainingPlanStore.compactPlanByWeeks.length > 4}>
        <WeekPlanCard
          compact
          onClick={() => {
            disableActiveDay();
            history.push(ROUTER.MILES_TRAINING_PLAN_FUTURE_WEEKS);
          }}
        />
      </Show>
      <RaceDayCard />
    </Wrapper>
  );
});
