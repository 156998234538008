import { action, observable, computed, makeObservable } from 'mobx';

class UserModal {
  @observable position?: ClientRect | null;

  @observable userId?: number | null;

  @observable user: RacersType | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setValue(userId: number | null, position: ClientRect | null) {
    this.position = position;
    this.userId = userId;
    this.user = null;
  }

  @action
  setUser(value: RacersType) {
    this.user = value;
  }

  @computed
  get isOpen() {
    return !!(this.position && this.userId);
  }
}

export { UserModal };
