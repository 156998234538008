import { Formula } from 'components/racersTable';

export const tableFormula: Formula = {
  'distance-icon': ['small-box', 'center'],
  distance: ['left'],
  'users-count': ['left', 'spaced-left-32'],

  bib: ['small-box', 'left', 'medium-font', 'spaced-left-44', 'spaced-right-20'],

  class: ['large-box', 'right', 'medium-font', 'smart-word-break', 'text-center', 'spaced-left-32'],
  union: ['right', 'medium-font', 'smart-word-break', 'text-center', 'spaced-left-20'],
  wave: ['medium-box', 'right', 'medium-font', 'smart-word-break', 'text-center', 'spaced-left-32'],

  teamname: ['bold', 'large-font', 'spaced-left-32', 'smart-word-break', 'black'],
  name: ['bold', 'large-font', 'smart-word-break', 'spaced-left-32', 'left', 'black'],
  members: ['left', 'medium-font', 'spaced-left-85'],

  'expand-icon': ['small-box', 'center'],

  'mobile-bib': ['small-box', 'left', 'medium-font'],
  'mobile-teamname': ['bold', 'large-font', 'smart-word-break'],
  'mobile-name': ['bold', 'large-font', 'smart-word-break', 'left', 'black'],
  'mobile-members': ['left', 'medium-font'],
  'mobile-expand-icon': ['small-box', 'right'],
};
