import { computed, makeObservable } from 'mobx';

import { SearchSerializer } from 'utils';

import { Loadable } from 'stores/loadable';

class Location extends Loadable<LocationType, FiltersType> {
  declare filters: FiltersType;

  handlers = {
    city: 'like',
    name: 'like',
    'distances.race_length': 'between',
    race_date: '>=',
    race_end_date: '<=',
    sport_id: 'in',
  };

  searchJoin = 'AND';
  nestedJoins = '';

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      limit: 9999,
    };
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters.search as any;
    return new SearchSerializer({
      search: search,
      handlers: this.handlers,
      nestedJoins: this.nestedJoins,
      searchJoin: this.searchJoin,
    });
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }
}

export { Location };
export default new Location();
