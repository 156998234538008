import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { getDefaultLocations, handleFocus, onBlurLocationInput } from 'modules/search/actions';
import { recentLocationStore } from 'modules/search/stores';
import React from 'react';

import { LocationMenu } from './LocationMenu';

type Props = {
  compressed?: boolean;
  fieldName: string;
  label: string;
  placeholder: string;
  value: any;
  onChange: ({ name, value }: { name: string; value: any }) => void;
  isFocused: boolean;
};

export const LocationSearch: React.FC<Props> = (props) => {
  const { compressed, fieldName, value, onChange, isFocused, label, placeholder } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isFocused && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const setFocusedField = () => {
    handleFocus(fieldName);
    getDefaultLocations();
  };

  const handleChange = (e) => {
    onChange({ name: e.currentTarget.name, value: e.currentTarget.value });
  };

  return (
    <Observer>
      {() => {
        const isRecentValues = !value && !isEmpty(recentLocationStore.value);
        return (
          <>
            <input
              {...testAnchors.useField(fieldName, TEST_ANCHORS.fieldStructure.root)}
              ref={inputRef}
              name={fieldName}
              type='text'
              placeholder={label}
              value={value || ''}
              onChange={handleChange}
              onFocus={setFocusedField}
              onBlur={onBlurLocationInput}
            />
            <LocationMenu isRecentValues={isRecentValues} />
          </>
        );
      }}
    </Observer>
  );
};
