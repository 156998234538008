export const MORE_INFORMATION = 'MORE_INFORMATION';
export const MORE_INFO_FORM_ID = 'MORE_INFO_FORM_ID';
export const CHANGE_PASS_FORM_ID = 'CHANGE_PASS_FORM_ID';
export const ACCOUNT_INFO_FORM_ID = 'ACCOUNT_INFO_FORM';
export const USER_UPDATE = 'USER_UPDATE';
export const AVATAR_KEY = 'avatar';
export const ENTRY_QUERY_PARAMS = 'sports;wishlist;raceUnions;xtriTickets';

export const CLEAR_BLOOD_OPTION = 'CLEAR_BLOOD_OPTION';
export const BLOOD_OPTIONS = [
  {
    id: 1,
    title: '0+',
    value: '0+',
  },
  {
    id: 2,
    title: '0-',
    value: '0-',
  },
  {
    id: 3,
    title: 'A+',
    value: 'A+',
  },
  {
    id: 4,
    title: 'A-',
    value: 'A-',
  },
  {
    id: 5,
    title: 'B+',
    value: 'B+',
  },
  {
    id: 6,
    title: 'B-',
    value: 'B-',
  },
  {
    id: 7,
    title: 'AB+',
    value: 'AB+',
  },
  {
    id: 8,
    title: 'AB-',
    value: 'AB-',
  },
  {
    id: 9,
    title: 'Not set',
    value: CLEAR_BLOOD_OPTION,
  },
];
