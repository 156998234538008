import { isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { DAYS } from 'modules/miles/shared/constants';
import moment from 'moment';

import { Day, LoadOverTime, WeeklyLoad } from '../types';

export const generateWeeklyLoadData = (searchableDay: Date, loadOverTime: LoadOverTime) => {
  if (isEmpty(loadOverTime.planned)) return [];

  let plannedDay;
  plannedDay = loadOverTime.planned.find((day) => {
    return new Date(day.x).toDateString() === searchableDay.toDateString();
  });

  // checks if date is before "Training plan" start and if it is in the same week as "TP" start
  if (
    searchableDay < new Date(loadOverTime.planned[0].x) &&
    moment(searchableDay).isoWeek() === moment(loadOverTime.planned[0].x).isoWeek()
  ) {
    plannedDay = loadOverTime.planned[0];
  }

  if (!plannedDay) return [];

  const getPlannedDays = (): Day[] => {
    const indexOfPlannedDay = loadOverTime.planned.indexOf(plannedDay);
    const dayOfWeek = new Date(plannedDay.x).getDay();

    // finds sequence number of day (0 - 6) in week (Mon - Sun)
    const normalDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    const indexOfLastDayOfWeek = indexOfPlannedDay + 6 - normalDayOfWeek;
    const indexOfFirstDayOfWeek = indexOfPlannedDay - normalDayOfWeek;

    let daysBeforePlanned: Day[];
    if (indexOfFirstDayOfWeek < 0) {
      daysBeforePlanned = loadOverTime.planned.slice(0, indexOfPlannedDay);

      // produces placeholders for weekdays with not existing plan
      daysBeforePlanned.splice(0, 0, ...Array(Math.abs(indexOfFirstDayOfWeek)).fill({ x: 'placeholder', y: 0 }));
    } else {
      daysBeforePlanned = loadOverTime.planned.slice(indexOfFirstDayOfWeek, indexOfPlannedDay);
    }

    const daysAfterPlanned = loadOverTime.planned.slice(indexOfPlannedDay, indexOfLastDayOfWeek + 1);
    return [...daysBeforePlanned, ...daysAfterPlanned];
  };

  const weeklyLoadPlanned = getPlannedDays();

  const getCompletedDays = () => {
    const plannedDays = weeklyLoadPlanned.map(({ x }) => x);

    let completedDays = loadOverTime.completed.filter((day) => {
      return plannedDays.includes(day.x);
    });

    // logic for first week if we have less completed days then 7
    const isFirstWeek = moment(searchableDay).isoWeek() === moment(loadOverTime.planned[0].x).isoWeek();

    if (isFirstWeek) {
      if (completedDays.length < 7 && loadOverTime.completed.indexOf(completedDays[0]) < 7) {
        const placeholderOfCompletedWeek = [...Array(7 - completedDays.length).fill({ x: 'placeholder', y: 0 })];
        completedDays = [...placeholderOfCompletedWeek, ...completedDays];
      }
    }
    return completedDays;
  };

  const completedDays = getCompletedDays();

  const y = Math.ceil(Math.max(...weeklyLoadPlanned.map((day) => day.y), ...completedDays.map((day) => day.y)));

  return DAYS.map((day, index) => {
    return {
      name: day.label.slice(0, 1),
      planned: weeklyLoadPlanned[index]?.y,
      completed: completedDays[index]?.y,
      date: weeklyLoadPlanned[index]?.x,
      y,
    };
  });
};

export const generateOverAllLoadData = (loadOverTime: LoadOverTime) => {
  const completedY = loadOverTime.completed.filter(({ y }) => y !== undefined && y !== null).map((day) => day.y);
  const plannedY = loadOverTime.planned.filter(({ y }) => y !== undefined && y !== null).map((day) => day.y);
  const y = Math.ceil(Math.max(...completedY, ...plannedY));
  return loadOverTime.planned.map((dayLoad, index) => {
    const name = loadOverTime.completed.length < 7 ? moment(dayLoad.x).format('D MMM') : moment(dayLoad.x).format('MMM');
    return {
      name,
      completed: loadOverTime.completed[index]?.y,
      planned: dayLoad?.y,
      y,
    };
  });
};

export const calculateTotalWeeklyLoad = (weeklyLoadData: WeeklyLoad) => {
  if (isEmpty(weeklyLoadData)) return null;
  return weeklyLoadData.reduce(
    (total, curr) => {
      const planned = curr.planned ? (total.planned += curr.planned) : total.planned;
      const completed = curr.completed ? (total.completed += curr.completed) : total.completed;
      return { ...total, planned, completed };
    },
    { planned: 0, completed: 0 },
  );
};
