import { duration } from 'utils';

function formatTime(start: moment.Moment, finish: moment.Moment, withPlus: boolean = false) {
  return duration.time(duration.roundDiff(duration.calcDiff(start, finish)), false, withPlus);
}

function formatDiff(diff: number, withPlus = false) {
  return duration.time(duration.roundDiff(diff), false, withPlus);
}

export { formatTime, formatDiff };
