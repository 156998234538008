import { GOAL_TYPE } from 'src/constants';

import { ParrentModel } from './parrentModel';

class Goal extends ParrentModel<GoalType> {
  isLimitEnabled(): boolean {
    return !!this.value.goal_max_length;
  }

  isGoalType(type: 'distance' | 'altitude') {
    switch (type) {
      case 'distance':
        return this.value.goal_type === GOAL_TYPE.DISTANCE;
      case 'altitude':
        return this.value.goal_type === GOAL_TYPE.ALTITUDE;
      default:
        return false;
    }
  }
}

export { Goal, Goal as GoalModel };
