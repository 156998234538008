import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Icon } from 'src/styledComponents/Icons';
import { BodyText } from 'src/styledComponents/Typography';

import { t } from 'utils';

type Props = {
  message: TranslationLockedKeys;
  className?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0;

  .no-results-message {
    text-align: center;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    flex-direction: row;
    padding: 40px 0;
    .no-results-message {
      text-align: left;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 40px;
  height: 40px;
  margin-bottom: 16px;
  margin-right: 0;
  border-radius: 50%;
  background-color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-right: 16px;
    margin-bottom: 0;
  }
`;

export const NoResults: FC<Props> = ({ message, className }) => {
  return (
    <Wrapper className={className}>
      <IconWrapper>
        <Icon name={'Flag'} size={16} />
      </IconWrapper>
      <BodyText className='no-results-message' variant='body1'>
        {t(message)}
      </BodyText>
    </Wrapper>
  );
};
