import { BooleanStore, AnyStore } from 'stores';

import { ModalTypes, Callback } from '../types';

const openModalStore = new BooleanStore();
const modeStore = new AnyStore<ModalTypes>();
const racerIdStore = new AnyStore<number>();
const segmentIdStore = new AnyStore<number>();
const callbackStore = new AnyStore<Callback>();
const distanceStore = new AnyStore<DistanceType>();

export { openModalStore, modeStore, callbackStore, racerIdStore, segmentIdStore, distanceStore };
