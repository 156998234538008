import React from 'react';
import styled from 'styled-components';

import { RaceId, RaceIdBg } from 'src/assets/images/miles/logotypes';
import { ROUTER } from 'src/constants';
import { Icon, Svg } from 'src/styledComponents/Icons';
import { Link } from 'src/styledComponents/Link';

type Props = {
  size?: number;
  type?: 'white' | 'black' | 'withoutBackground' | 'main';
};

export const Wrapper = styled(Link)<Props>`
  display: flex;
  align-items: center;
`;

const getLogo = {
  white: (size: number) => <Icon name='LogoWhite' size={size} />,
  withoutBackground: (size: number) => <img className='logo' height={size} src={RaceId} alt='logo' />,
  main: (size: number) => <img className='logo' height={size} src={RaceIdBg} alt='logo' />,
  black: (size: number) => <Svg name='LogoUkraine' size={size} />,
};

export const Logo: React.FC<Props> = (props) => {
  const { type = 'main', size = 34 } = props;

  const Component = getLogo[type](size);

  return (
    <Wrapper to={ROUTER.HOME} ATN='logo'>
      {Component}
    </Wrapper>
  );
};
