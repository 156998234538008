/*
 * Google tag manager
 */
import TagManager from 'react-gtm-module';

import { GTM_ID, GTM_ID_OTILLO } from 'src/constants/env';

import { appEnvControl } from '../appEnvironment';

function init() {
  if (!appEnvControl.isEnv('production')) {
    return;
  }

  TagManager.initialize({ gtmId: GTM_ID });
  TagManager.initialize({ gtmId: GTM_ID_OTILLO });
}

export const gtm = {
  init,
};
