import { sessionStore } from 'src/stores';

import { fixBodyElement } from 'actions';

import { sidebarStore } from '../stores';

export const handleOpenSidebar = () => {
  sidebarStore.setValue(true);
  fixBodyElement(true);
};

export const handleCloseSidebar = () => {
  sidebarStore.setValue(false);
  fixBodyElement(false);
};

export async function handleLogout() {
  sessionStore.logout();
  sidebarStore.setValue(false);
  fixBodyElement(false);
}
