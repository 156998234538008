type Options = {
  viewportQuantator: number;
  loaderHeight: number;
};

function generateIsViewportFilled(
  containerID: string,
  options: Options = {
    viewportQuantator: 3,
    loaderHeight: 0,
  },
) {
  function isViewportFilled(): boolean {
    const resultsViewportEl = document.getElementById(containerID);

    if (!resultsViewportEl) {
      return false;
    }

    const viewport = window.innerHeight * options.viewportQuantator;
    const loadedViewportPosition = resultsViewportEl.getBoundingClientRect().bottom - options.loaderHeight;

    return loadedViewportPosition > viewport;
  }

  return isViewportFilled;
}

export { generateIsViewportFilled };
