import * as React from 'react';
import styled from 'styled-components';

import { TagLine } from 'src/styledComponents/Typography';

const Wrapper = styled.div`
  background-color: white;
  padding: 8px 20px;
  width: 100%;
`;

type Props = {
  name: string;
};

export const MobileDistanceHeader: React.FC<Props> = (props) => {
  const { name } = props;

  return (
    <Wrapper>
      <TagLine>{name}</TagLine>
    </Wrapper>
  );
};
