import { computed } from 'mobx';

import { FIELD_ID_PATTERNS } from '../../constants';

import { mapperUtils, priceUtils } from '../../utils';

import { disabledCustomFieldsStore } from '../../stores';

import { formDataSelectors } from '../formDataSelectors';
import { distanceSelector } from '../mappedData';

/**
 * @description
 * total for team leader
 * calculated with coupon
 * including ONLY custom fields
 *
 * WARNING
 * should not be used with combination to something
 *
 */
const _leaderCustomFieldsTotal = computed(() => {
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();

  const customFields = mapperUtils.rejectCustomFields({
    index: 0,
    pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
    fieldIds: disabledCustomFieldsStore.disabledFieldIds,
  })(formDataSelectors.fetchLeaderCustomFields());

  const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);

  const coupon = formDataSelectors.fetchLeaderCoupon();

  return priceUtils.calculateMemberPriceWithDiscount({ distancePrice: 0, coupon, customFieldTotalPrice });
});

/**
 * @description
 * total for team leader
 * calculated without coupon
 * including ONLY custom fields
 *
 * WARNING
 * should not be used with combination to something
 *
 */
const _leaderCustomFieldsTotalWithoutDiscount = computed(() => {
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();

  const customFields = mapperUtils.rejectCustomFields({
    index: 0,
    pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
    fieldIds: disabledCustomFieldsStore.disabledFieldIds,
  })(formDataSelectors.fetchLeaderCustomFields());

  return priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);
});

export { _leaderCustomFieldsTotal, _leaderCustomFieldsTotalWithoutDiscount };
