import classNames from 'classnames';
import React from 'react';

export const SelectListItem = (props: SelectListItemType | any) => {
  const { activeItem, item } = props;
  const isActive = activeItem === item.id;
  const isDisabled = item.disabled;

  return (
    <li
      {...testAnchors.useField(props.name, TEST_ANCHORS.fieldStructure.selectItem, props.item.value)}
      className={classNames({ active: isActive, disabled: isDisabled })}
      onClick={() => !isDisabled && props.onSelect(item)}
    >
      <p>{item.title}</p>
    </li>
  );
};
