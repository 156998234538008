import * as React from 'react';
import styled from 'styled-components';

import { InputWrapComponent } from 'src/styledComponents/InputTools';
import { RadioButton } from 'src/styledComponents/Radio';

type Props = {
  radioValues: Array<AnyObject>;
  additionalComponent?: AnyFunction;
} & BaseInputProps;

const StyledInputWrapper = styled(InputWrapComponent)`
  .multi-checkbox-wrap {
    > div label {
      padding-bottom: 0;
    }

    > div:not(:last-child) label {
      padding-bottom: 16px;
    }
  }
`;

export class MultiRadio extends React.Component<Props> {
  static defaultProps = {
    radioValues: [],
  };

  handleRadioList = (): React.ReactNode => {
    const { disabled, radioValues, value, name } = this.props;

    return radioValues.map((item) => {
      return (
        <RadioButton
          id={`${name}-${item.value}`}
          key={`${item.value}`}
          name={item.label}
          label={item.label}
          value={value?.value}
          radioValue={item.value}
          onChange={this.handleChange(item)}
          labelPosition='right'
          disabled={disabled}
        />
      );
    });
  };

  handleChange = (value: Object) => (): void => {
    const { onChange, name } = this.props;

    onChange({ name, value });
  };

  isChecked = (id: number): boolean => {
    const { value } = this.props;

    return value?.id === id;
  };

  render(): React.ReactNode {
    const { additionalComponent, requiredMark, errorMessage, label, name, disabled } = this.props;

    return (
      <StyledInputWrapper
        additionalComponent={additionalComponent}
        errorMessage={errorMessage}
        disabled={disabled}
        label={label}
        requiredMark={requiredMark}
        name={name}
      >
        <div className='multi-checkbox-wrap'>{this.handleRadioList()}</div>
      </StyledInputWrapper>
    );
  }
}
