import { isEmpty } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { calculatePercents } from 'utils';

import { Distance } from 'models';

import { segmentsStore } from '../stores';

export const displayLog: IComputedValue<boolean> = computed((): boolean => {
  const distanceModel = !isEmpty(segmentsStore.distance) && new Distance(segmentsStore.distance);

  const isLogClosedCondition = distanceModel ? distanceModel.isLoggingClosed() : true;
  const percents = calculatePercents(segmentsStore.total.distance_logged, segmentsStore.distance?.goal?.goal_max_length);
  const percentsCondition = percents === 100;

  return !percentsCondition && !isLogClosedCondition;
});

export const displayEditControls: IComputedValue<boolean> = computed((): boolean => {
  const distanceModel = !isEmpty(segmentsStore.distance) && new Distance(segmentsStore.distance);

  const isEditClosedCondition = distanceModel ? distanceModel.isLoggingClosed() : true;

  return !isEditClosedCondition;
});
