import classNames from 'classnames';
import * as React from 'react';

import { IconArrowRight } from 'components/icons';

import { raceUtil } from 'utils';

import { setActiveDistance } from '../../actions';

type Props = {
  collapsed: boolean;
  value: AnyObject;
  isActive: boolean;
  isNextActive: boolean;
};

function Item({ collapsed, value, isActive, isNextActive }: Props) {
  return (
    <div
      className={classNames(
        'distance-row',
        { hide: collapsed },
        { active: isActive },
        { 'hide-mobile': isActive }, // SHOULD BE HIDDEN FOR MOBILE IF ACTIVE
        { 'next-active': isNextActive },
      )}
      key={`${value.id}-distance`}
      onClick={() => setActiveDistance(value.id)}
    >
      <div className='distance-info-wrap'>
        <div className='distance-length'>{raceUtil.humanizeDistance(value.race_length)}</div>
        <span className='distance-title'>{value.title}</span>
      </div>
      <span className='active-arrow hide-mobile'>{IconArrowRight}</span>
    </div>
  );
}

export { Item };
