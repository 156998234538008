import * as React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { BodyText } from 'src/styledComponents/Typography';

import { t } from 'utils';

const SNACK_HEIGHT = 50;

type Props = {
  children: React.ReactNode | any;
  portal?: boolean;
};

const Wrapper = styled.div`
  .text-height {
    padding: 16px;
    min-height: 56px;
  }

  .fixed-snack-list {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
  }
`;

class SnackContainer extends React.PureComponent<Props> {
  static defaultProps = {
    portal: false,
  };

  render() {
    const { children, portal } = this.props;

    let text: string[] | any = [];

    if (children instanceof Array) {
      text = children.map((child) => {
        const message = t(child.props.title.i18key);
        return (
          <BodyText variant='body2' key={child.props.title.i18key} className='text-height'>
            {message}
          </BodyText>
        );
      });
    }

    if (!portal) {
      return (
        <Wrapper className='snack-container container'>
          {text}
          <div className='fixed-snack-list'>{children}</div>
        </Wrapper>
      );
    }

    const container = document.getElementById('snack-container');

    if (!container) {
      return null;
    }

    return ReactDOM.createPortal(<div className='snack-container'>{children}</div>, container);
  }
}

export { SnackContainer };
