import { css } from 'styled-components';

export const gradientAnimation = css`
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.main.colors.clay5},
    ${(props) => props.theme.main.colors.clay6},
    ${(props) => props.theme.main.colors.clay5}
  );
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;
