import axios from 'axios';

import { MILES_READINESS_EXPORT } from 'src/constants';

import { action, request } from 'utils';

import { GET_JOURNAL } from '../constants';

import { journalParamsStore, journalStore } from '../stores';

export class Journal {
  @request({ action: GET_JOURNAL })
  private getJournalRequest(): Promise<any> {
    return axios.get(MILES_READINESS_EXPORT, {
      headers: { locale: 'en' },
      params: {
        start: journalParamsStore.value?.start,
        end: journalParamsStore.value?.end,
        ratings: true,
      },
    });
  }

  @action({ action: GET_JOURNAL })
  async getJournal() {
    const [isOk, data] = await this.getJournalRequest();
    if (isOk) {
      journalStore.setValue(data.data);
    }
  }
}

export const journalService = new Journal();
