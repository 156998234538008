import * as React from 'react';

export const PulseLoader = (
  <svg width='44' height='44' viewBox='0 0 86 86' fill='none' xmlns='http://www.w3.org/2000/svg' className='animated'>
    <g id='3 step'>
      <g id='Group 5'>
        <path
          d='M21 43C21 30.8497 30.8497 21 43 21V21C55.1503 21 65 30.8497 65 43V43C65 55.1503 55.1503 65 43 65V65C30.8497 65 21 55.1503 21 43V43Z'
          fill='#272E34'
        />
        <path
          d='M54.8317 39.0439C54.8339 39.0627 54.8339 39.0817 54.8317 39.1005C54.8295 39.111 54.8266 39.1214 54.823 39.1315L53.1622 45.1388L53.1519 45.1765C53.1344 45.2246 53.1026 45.2671 53.0601 45.2991C53.0527 45.305 53.0449 45.3103 53.0368 45.3153C52.2042 45.8893 48.9233 48.5103 48.9175 48.513C48.8465 48.5676 48.8054 48.6486 48.8052 48.734C48.8097 48.8087 48.8422 48.8797 48.8971 48.9348L50.7927 51.1959C50.9047 51.3108 50.9047 51.4853 50.7927 51.6002C50.7418 51.6464 50.6755 51.6753 50.6046 51.6824H45.1147C45.0393 51.6785 44.968 51.6498 44.9135 51.6015C44.9135 51.6015 42.8721 49.1504 42.5804 48.804V48.7973C42.5304 48.7432 42.4601 48.7086 42.3836 48.7003H36.8718C36.7584 48.7074 36.6591 48.7732 36.6152 48.8701L36.5933 48.9455L35.9007 51.4587C35.8992 51.4684 35.8962 51.4779 35.892 51.487C35.8525 51.6047 35.7337 51.6843 35.6003 51.6824H31.3193C31.3076 51.6837 31.2959 51.6837 31.2843 51.6824C31.1953 51.6723 31.1149 51.6286 31.0621 51.5617C31.0094 51.4948 30.9889 51.4106 31.0058 51.3293C31.0058 51.3145 32.8926 44.4879 33.3388 42.8601C33.3388 42.8439 33.3475 42.8278 33.3504 42.813C33.3749 42.7501 33.423 42.6974 33.486 42.6647H33.4963L38.4743 40.1785L38.5079 40.161C38.6 40.118 38.7099 40.1233 38.7967 40.1747C38.8836 40.2262 38.9346 40.3163 38.9307 40.4117C38.933 40.43 38.933 40.4485 38.9307 40.4669C38.9288 40.476 38.9264 40.485 38.9234 40.4939L37.9582 43.9974C37.9572 44.0122 37.9572 44.0271 37.9582 44.0419C37.9577 44.1884 38.08 44.3102 38.2381 44.3208H47.9464C48.0615 44.3176 48.1651 44.2552 48.2147 44.1591L49.4235 39.7878C49.4322 39.7611 49.4366 39.7334 49.4366 39.7056C49.4358 39.5503 49.2998 39.4247 49.1319 39.4239H39.1057C39.0264 39.4238 38.9504 39.3947 38.8943 39.3431L38.8549 39.2986L35.4779 35.4474C35.4273 35.3513 35.4336 35.2379 35.4946 35.1472C35.5555 35.0564 35.6626 35.0008 35.7782 35H40.4443H50.4456C50.5243 35.0019 50.5994 35.0313 50.6556 35.0822L54.7224 38.8553L54.7617 38.893C54.8015 38.9354 54.8259 38.9881 54.8317 39.0439Z'
          fill='#F6F7F7'
        />
      </g>
    </g>
  </svg>
);
