import { debounce } from 'lodash';
import { reaction } from 'mobx';
import { RacesService } from 'modules/races/services';

import { disposersStore } from '../stores';

import { allFiltersValues } from '../selectors';

const debouncedRaces = debounce(() => {
  return RacesService.getRaces();
}, 600);

export const observeSearch = () => {
  const disposer = reaction(
    () => allFiltersValues.get(),
    () => debouncedRaces(),
  );
  disposersStore.register(disposer);
};
