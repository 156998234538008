import { isEqual } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'components/condition';

import { BodyText } from '../Typography';

type RadioDotProps = {
  selected?: boolean;
  isError?: boolean;
  labelPosition: 'left' | 'right';
};

const RadioDot = styled.div<RadioDotProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  
  min-width: 24px;
  width: 24px;
  height: 24px;
  border: 1px ${(props) => (!props.isError ? props.theme.main.colors.clay4 : props.theme.main.colors.error)} solid ;
  border-radius: 20px;
  margin-${(props) => props.labelPosition}: 12px;
  
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.theme.main.colors.clay1};
    border-radius: 20px;
    opacity: ${(props) => (props.selected ? 1 : 0)};
  }
`;

const getDisabledStyles = (props) =>
  props.disabled
    ? `
  ${RadioDot} {
    background: ${props.theme.main.colors.clay5};
    border-color: ${props.theme.main.colors.clay5};
  }
`
    : '';

const Radio = styled.div<{ disabled?: boolean }>`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    ${(props) => (props.disabled ? 'pointer-events: none;' : '')}

    &:hover {
      ${RadioDot} {
        border-color: ${(props) => props.theme.main.colors.clay1};
      }
    }

    p {
      color: ${(props) => (props.disabled ? props.theme.main.colors.clay3 : 'inherit')};
    }

    ${getDisabledStyles}
  }
`;

type RadioProps = {
  radioValue: any;
  isError?: boolean;
  labelPosition: 'left' | 'right';
} & BaseInputProps;

export const RadioButton: React.FC<RadioProps> = (props) => {
  const { label, name, value, radioValue, disabled, readOnly, onChange, id, isError, labelPosition } = props;
  const condition = value?.value ? isEqual(value, radioValue) || value?.value == radioValue?.value : isEqual(value, radioValue);

  return (
    <Radio disabled={disabled}>
      <input
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.value, value)}
        type='checkbox'
        id={id}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
          onChange({ name, value: radioValue }, e);
        }}
      />
      <label htmlFor={id}>
        <Show if={labelPosition === 'left'}>
          <BodyText variant='body2'>{label}</BodyText>
        </Show>
        <RadioDot selected={condition} isError={isError} labelPosition={labelPosition} />
        <Show if={labelPosition === 'right'}>
          <BodyText variant='body2'>{label}</BodyText>
        </Show>
      </label>
    </Radio>
  );
};
