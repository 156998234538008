import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { data, generateCheckpointId } from '../../derivations';
import { MobileSingleItem } from './MobileSingleItem';
import { MobileTeamItem } from './MobileTeamItem';

type Props = {
  distanceId: number;
  type: 'single' | 'team';
};

const ComponentByType = {
  single: MobileSingleItem,
  team: MobileTeamItem,
};

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

export const MobileRacerList: React.FC<Props> = (props) => {
  const { distanceId, type } = props;

  const dataGenerator = data.generateDataEntries(distanceId);
  const checkpointGenerator = generateCheckpointId(distanceId);

  return (
    <Wrapper>
      <Observer>
        {() => {
          const data: any[] = dataGenerator.get();
          const checkpointId = checkpointGenerator.get();
          if (!data) return null;

          const Component = ComponentByType[type];

          return (
            <>
              {data.map((item) => (
                <Component distanceId={distanceId} checkpointId={Number(checkpointId)} key={`mobile-${item.id}`} value={item} />
              ))}
            </>
          );
        }}
      </Observer>
    </Wrapper>
  );
};
