import { merge } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

import { LCheckpointModel } from '../models';

import { LCheckpoint } from '../types';

class Checkpoints {
  @observable values: LCheckpoint[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get modelValues() {
    const { values } = this;

    return values.map<LCheckpointModel>((el) => new LCheckpointModel(el));
  }

  @action
  async setValues(values: CheckpointType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  findCheckpointsByDistance(distanceId: number): LCheckpointModel[] {
    return this.groupedByDistance[distanceId] || [];
  }

  find(id: number): LCheckpointModel | null {
    return this.expandedCheckpoints[id];
  }

  @computed
  get expandedCheckpoints(): { [K in number]: LCheckpointModel } {
    const { modelValues } = this;
    return modelValues.reduce((acc, model) => {
      const id = model.value.id;
      return merge(acc, { [id]: model });
    }, {});
  }

  @computed
  get groupedByDistance(): { [K in number]: LCheckpointModel[] } {
    const { modelValues } = this;

    return modelValues.reduce((acc: AnyObject, checkpoint: LCheckpointModel) => {
      if (!acc[checkpoint.value.distance_id]) {
        acc[checkpoint.value.distance_id] = [];
      }

      acc[checkpoint.value.distance_id].push(checkpoint);

      return acc;
    }, {});
  }
}

export { Checkpoints, Checkpoints as CheckpointsStore };
