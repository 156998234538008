import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Fb, Instagram, Linked } from 'components/icons';

import { getSocials } from 'utils';

type Props = {
  type: 'horizontal' | 'vertical';
  links: {
    fb?: string;
    insta?: string;
    linked?: string;
    tw?: string;
  };
};

const SocialsImpl = ({ type }: Props) => {
  const links = getSocials();

  return (
    <ul className={classNames('socials', { [type]: true })}>
      <li>
        {links.fb && (
          <a className='social-item' href={links.fb} target='_blank' rel='noreferrer noopener'>
            {Fb}
          </a>
        )}
      </li>
      {links.insta && (
        <li>
          <a className='social-item' href={links.insta} target='_blank' rel='noreferrer noopener'>
            {Instagram}
          </a>
        </li>
      )}
      {links.linked && (
        <li>
          <a className='social-item' href={links.linked} target='_blank' rel='noreferrer noopener'>
            {Linked}
          </a>
        </li>
      )}
    </ul>
  );
};

export const Socials = observer(SocialsImpl);
