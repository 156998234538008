import { setNestedValidation } from 'utils';

import { errorsStore } from 'stores';

import { FORM_ID } from '../constants';

import { FormSegment } from '../types';
import { create, update } from '../validation';

const constrains = {
  create,
  update,
};

function validate(type: 'create' | 'update', value: FormSegment): boolean {
  errorsStore.clear(FORM_ID);

  const schema = constrains[type];
  return !!setNestedValidation(FORM_ID, schema, value);
}

export { validate };
