import * as React from 'react';

type InputType = {
  placeholder?: string;
  name: string;
  value: string;
  onChange: AnyFunction;
  onClick?: AnyFunction;
  className?: string;
};

class DatePickerInput extends React.PureComponent<InputType> {
  render(): React.ReactElement {
    const { placeholder, name, value, onChange, onClick, className } = this.props;

    return (
      <input
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
        type='text'
        placeholder={placeholder}
        id={name}
        name={name}
        value={value || ''}
        readOnly={true}
        onChange={onChange}
        onClick={onClick}
        className={className}
      />
    );
  }
}

export { DatePickerInput };
