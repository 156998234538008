import { RACE_UNTIONS } from 'src/constants';

import { ParrentModel } from './parrentModel';

class RaceUnion extends ParrentModel<RaceUnionType> {
  isXtri(): boolean {
    return this.value.name === RACE_UNTIONS.xtri;
  }

  isNorseman(): boolean {
    return this.value.name === RACE_UNTIONS.norseman;
  }
}

export { RaceUnion };
