import { changeProfilePicture } from 'modules/Shared/AvatarAccountUpload';
import { UserType } from 'typings/old-types/UserType';

import { validation } from 'src/utils';

import { sessionStore, form } from 'stores';

import { ACCOUNT_INFO_FORM_ID, AVATAR_KEY } from '../../constants';

import { accountInfoMapper } from '../../utils';

import { userProfile } from '../../services';

import { userFieldsType } from '../../validation';

const updateAccountField = async (value: AnyObject) => {
  const fieldKey = value?.name.join('');
  const data = accountInfoMapper.mapFieldFront(fieldKey);
  const validationData = accountInfoMapper.mapToValidation(data);

  if (fieldKey === AVATAR_KEY) {
    await changeProfilePicture(data[fieldKey] as string);
    return;
  }

  if (!validation(ACCOUNT_INFO_FORM_ID, validationData, { [fieldKey]: userFieldsType[fieldKey] })) {
    return;
  }

  const [responseStatus, response] = await userProfile.updateAccountInfo(data as UserType);

  if (responseStatus) {
    const user: any = accountInfoMapper.mapFrom(response.data);
    sessionStore.userProfile(response.data);
    form.merge(ACCOUNT_INFO_FORM_ID, user);
  }
};

export { updateAccountField };
