import { defaults } from 'lodash';

import { filterInternalFilters, validateData } from '../utils';

import { InternalFilters } from '../types';
import { filterChanger } from './filterChanger';

function init(initFilters: InternalFilters) {
  const filters = { ...initFilters };
  let distanceId = filters.distance_id;

  if (!validateData.isValidDistance(distanceId!)) {
    distanceId = null;
    filters.distance_id = null;
    filters.checkpoint_id = null;
  }

  if (!validateData.isValidCheckpoint(filters.checkpoint_id!)) {
    filters.checkpoint_id = null;
  }

  filterChanger.change(filters);
}

function changeDistance(distanceId: null | void | number) {
  const filters = filterInternalFilters({
    distance_id: distanceId as any,
  });
  return init(filters);
}

function initDistance(initFilters: null | InternalFilters, defaultDistanceId: null | void | number) {
  let filters = defaults({}, initFilters, {
    distance_id: defaultDistanceId,
  });
  return init(filters);
}

const distanceChanger = {
  init: initDistance,
  change: changeDistance,
};

export { distanceChanger };
