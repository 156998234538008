import { Observer } from 'mobx-react';
import * as React from 'react';

import { Modal } from 'components/modal';

import { windowSize } from 'stores';

import { actionMenuActions } from '../../actions';

import { actionMenu } from '../../selections';
import { ActionModalContent } from './ActionModalContent';

const ActionModal = () => {
  return (
    <Observer>
      {() => {
        const isMobile = !windowSize.isGreaterThan('medium');

        return (
          <Modal open={actionMenu.isModalOpen.get() && isMobile} className='profile-action-modal'>
            <ActionModalContent onClose={actionMenuActions.close} title={actionMenu.name.get()} />
          </Modal>
        );
      }}
    </Observer>
  );
};

export { ActionModal };
