import { observable, action, makeObservable } from 'mobx';

import { DistanceRegistrationModel } from 'models';

// { [payment token]: payment }
type PaymentByToken = { [K in string]: DistanceRegistrationModel };

// { [distance id]: payment }
type PaymentsByDistance = { [K in string]: DistanceRegistrationModel[] };

class PaymentTracker {
  @observable paymentByToken: PaymentByToken = {};
  @observable paymentsByDistance: PaymentsByDistance = {};

  constructor() {
    makeObservable(this);
  }

  @action
  async setPayments(...values: DistanceRegistrationRelation[]) {
    const payments = values.map((payment) => new DistanceRegistrationModel(payment));

    const paymentsByToken = this.generatePaymentsByToken(...payments);
    const paymentsByDistance = this.generatePaymentsByDistance(...payments);

    this.paymentByToken = { ...this.paymentByToken, ...paymentsByToken };
    this.paymentsByDistance = paymentsByDistance;
  }

  generatePaymentsByDistance(...payments: DistanceRegistrationModel[]): PaymentsByDistance {
    return payments.reduce((acc: AnyObject, payment) => {
      const distanceId = payment.value.distance_id.toString();
      const payments = acc[distanceId] || [];
      const newPayments = [...payments, payment];

      return { ...acc, [distanceId]: newPayments };
    }, {});
  }

  generatePaymentsByToken(...payments: DistanceRegistrationModel[]): PaymentByToken {
    return payments.reduce((acc, payment) => {
      const token = payment.value.token;
      return { ...acc, [token]: payment };
    }, {});
  }

  isPending(distanceId: number | nil): boolean {
    if (!distanceId) {
      return false;
    }

    return !!this.findPendingPaymentByDistance(distanceId);
  }

  findPendingPaymentByDistance(distanceId: number): DistanceRegistrationModel | null {
    const payments = this.paymentsByDistance[`${distanceId}`] || [];
    const payment = payments.find((value) => value.isPending());

    return payment || null;
  }

  findPaymentByToken(token: string | nil): DistanceRegistrationModel | null {
    if (!token) {
      return null;
    }

    const payment = this.paymentByToken[`${token}`];

    if (!payment) {
      return null;
    }

    return payment;
  }

  @action
  clean() {
    this.paymentByToken = {};
    this.paymentsByDistance = {};
  }
}

export { PaymentTracker, PaymentTracker as PaymentTrackerStore };
