import { distancePrice } from './distancePrice';
import { fullDiscount } from './isFullDiscount';
import { subtotalPrice } from './subtotalPrice';
import { totalPrice } from './totalPrice';
import { transferPrice } from './transferPrice';

const priceSelectors = {
  /**
   * @description
   * current distance price
   */
  distancePrice,
  /**
   * @description
   * total price for the registration
   * including coupon
   */
  totalPrice,
  /**
   * @description
   * subtotal price for the registration
   * excluding coupon
   */
  subtotalPrice,
  /**
   * @description
   * transfer registration price
   * */
  transferPrice,

  fullDiscount,
};

export { priceSelectors };
