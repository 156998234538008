import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const CheckPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M19.1668 5.15828L7.30731 17.0178L0.833496 10.544L3.00966 8.36782L7.30731 12.6655L16.9907 2.98212L19.1668 5.15828Z'
    fill='currentColor'
  />
);

export const Check = (props: IconProps) => <SvgIcon path={CheckPath} viewBox='0 0 20 20' {...props} />;
