import { Option } from 'modules/registration/components/MultiMenu/Option';
import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

import { Hide, Show } from 'components/condition';

const Wrapper = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: white;

  transition: all 0.3s ease;
  transform: ${(props) => (props.open ? 'initial' : 'translateY(100%)')};
`;

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 58px;
  width: 100%;
  padding: 0 19px 0 21px;

  border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
`;

const HeaderPhantom = styled.div`
  height: 58px;
  width: 100%;
  background: white;
`;

const Clear = styled(Utility)`
  position: relative;
  font-weight: 500;

  &:after {
    position: absolute;
    bottom: 1px;
    left: 0;
    z-index: 5;

    content: '';
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.main.colors.clay1};
  }
`;

const OptionsWrap = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;

  height: calc(100% - 58px - 90px);
  width: 100%;
  overflow-y: auto;
`;

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
  border: none;
  background-color: ${(props) => props.theme.main.colors.clay3};
`;

const ApplyButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
  border: none;
  background-color: ${(props) => props.theme.main.colors.clay3};
`;

const StyledConfirmText = styled(Utility)`
  color: white;
`;

type Props = BaseInputProps & {
  open: boolean;
  options: DropdownItemType[];
  confirmBtnText: string;
  onClose: AnyFunction;
  noSubmit?: boolean;
};

export const MobileMultiselectMenu: React.FC<Props> = (props) => {
  const { open, label, options, confirmBtnText, value, onChange, name, onClose, noSubmit } = props;

  const [optionsToChoose, changeOptions] = React.useState<DropdownItemType[]>(value || []);

  const handleChoose = (selectedOption: any) => () => {
    const neededValue = optionsToChoose.find((option) => option.key === selectedOption.key);
    const values = neededValue
      ? optionsToChoose.filter((option) => option.key !== selectedOption.key)
      : [...optionsToChoose, selectedOption];

    changeOptions(values);
  };

  const handleSearch = () => {
    onChange({ name, value: optionsToChoose });
    onClose();
  };

  const handleClear = () => {
    onChange({ name, value: [] });
    onClose();
  };

  useEffect(() => {
    if (value) {
      changeOptions(value);
    }
  }, [value]);

  return (
    <Wrapper open={open}>
      <Show if={open}>
        <MenuHeader>
          <Svg name='Cross' size={16} onClick={handleClear} {...testAnchors.useButton('close')} />
          <Utility variant='u2' weight='bold'>
            {label}
          </Utility>
          <Clear variant='u2' weight='medium' onClick={handleClear} {...testAnchors.useButton('clear')}>
            {t('settings.clear')}
          </Clear>
        </MenuHeader>
      </Show>
      <Show if={!open}>
        <HeaderPhantom />
      </Show>
      <OptionsWrap>
        {options.map((option) => (
          <Option
            key={option.key}
            name={`${name}-${option.key}`}
            label={option.label}
            values={optionsToChoose}
            onChange={handleChoose(option)}
            value={option}
          />
        ))}
      </OptionsWrap>
      <Show if={Boolean(noSubmit)}>
        <ApplyButton onClick={handleSearch} {...testAnchors.useButton('submit')}>
          <StyledConfirmText variant='u1' weight='bold'>
            {confirmBtnText}
          </StyledConfirmText>
        </ApplyButton>
      </Show>
      <Hide if={Boolean(noSubmit)}>
        <ConfirmButton onClick={handleSearch} {...testAnchors.useButton('submit')}>
          <StyledConfirmText variant='u1' weight='bold'>
            {confirmBtnText}
          </StyledConfirmText>
        </ConfirmButton>
      </Hide>
    </Wrapper>
  );
};
