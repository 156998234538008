import validate from 'validate.js';

validate.validators.phoneNumber = (value: any, option: any, key: string, attributes: Object) => {
  const MIN_LENGTH = 10;
  const MAX_LENGTH = 24;

  if (!value || !value.phone) {
    if (!option.allowEmpty) {
      return '^confirmProfile.validation.required';
    } else {
      return;
    }
  }

  if (!value.countryData?.dialCode) {
    return '^validation.phoneFormat';
  } else {
    const phoneProfixCode = value.phone.replace(/\D/g, '').substring(0, value.countryData.dialCode.length);

    if (phoneProfixCode !== value.countryData.dialCode) {
      return '^validation.phoneFormat';
    }
  }

  const wrongLength = value.phone.length < MIN_LENGTH || value.phone.length > MAX_LENGTH;

  if (wrongLength) {
    return '^validation.phoneFormat';
  }
};

validate.validators.customTextField = (value: any, option: any, key: string, attributes: Object) => {
  if (option.length?.maximum) {
    if (value && value[0].length > option.length.maximum) {
      return option.length.tooLong;
    }
  }
};

validate.validators.hours = (value: string, options: AnyObject) => {
  if (!value) return;

  const timeArray = value.split(':');

  if (+timeArray[0] > options.greaterThan?.value) {
    return options.greaterThan?.message;
  }
};
