import { CrossClose, SizesText, Text, WeightText } from 'modules/miles/shared/components';
import React from 'react';
import styled, { css } from 'styled-components';

import { theme } from 'styles';

enum TagSizes {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

enum TagMode {
  light = 'light',
  dark = 'dark',
  brand = 'brand',
}

enum TagVariants {
  default = 'default',
  positive = 'positive',
  warning = 'warning',
  negative = 'negative',
  primary = 'primary',
}

const sizeLg = css`
  padding: 4px 8px;
  gap: 6px;
  .icon {
    padding: 3px;
  }
`;

const sizeMd = css`
  padding: 2px 6px;
  gap: 5px;
  .icon {
    padding: 2px;
  }
`;

const sizeSm = css`
  padding: 3px 4px;
  gap: 4px;
  .icon {
    padding: 2px;
  }
`;

const getStylesBySize = (size: keyof typeof TagSizes) => {
  switch (size) {
    case TagSizes.lg:
      return sizeLg;
    case TagSizes.md:
      return sizeMd;
    case TagSizes.sm:
      return sizeSm;
    default:
      return sizeMd;
  }
};

const getIconSize = (size: keyof typeof TagSizes) => {
  switch (size) {
    case TagSizes.lg:
      return 12;
    case TagSizes.md:
      return 10;
    case TagSizes.sm:
      return 8;
    default:
      return 10;
  }
};

const getTextSizeProperties = (size: keyof typeof TagSizes): { size: SizesText; weight: WeightText } => {
  switch (size) {
    case TagSizes.lg:
      return { size: SizesText.md, weight: WeightText.regular };
    case TagSizes.md:
      return { size: SizesText.sm, weight: WeightText.medium };
    case TagSizes.sm:
      return { size: SizesText.xs, weight: WeightText.medium };
    default:
      return { size: SizesText.sm, weight: WeightText.medium };
  }
};

const Wrapper = styled.div<{ size: keyof typeof TagSizes; colorScheme: ColorScheme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  border: 1px solid ${(props) => props.colorScheme.borderColor};
  background: ${(props) => props.colorScheme.background};

  .label {
    color: ${(props) => props.colorScheme.textColor};
  }

  .icon {
    display: flex;
    color: ${(props) => props.colorScheme.iconColor};
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  ${(props) => getStylesBySize(props.size)}
`;

const colors = theme.miles.colors;
type ColorScheme = {
  background: string;
  borderColor: string;
  textColor: string;
  iconColor: string;
};
const COLORS_SCHEME: {
  [M in TagMode]: {
    [V in TagVariants]: ColorScheme;
  };
} = {
  light: {
    default: {
      background: colors.light.alpha['100'],
      borderColor: colors.light.alpha['300'],
      textColor: colors.light.alpha['800'],
      iconColor: colors.light.alpha['700'],
    },
    positive: {
      background: colors.light.background.positive,
      borderColor: colors.light.alpha['300'],
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
    warning: {
      background: colors.light.background.warning,
      borderColor: colors.light.alpha['300'],
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
    negative: {
      background: colors.light.background.negative,
      borderColor: colors.light.alpha['300'],
      textColor: colors.brand.alphas.light800,
      iconColor: colors.light.alpha.swap700,
    },
    primary: {
      background: colors.light.background.primary,
      borderColor: colors.light.alpha['300'],
      textColor: colors.light.alpha.swap800,
      iconColor: colors.light.alpha.swap700,
    },
  },
  dark: {
    default: {
      background: colors.dark.alpha['100'],
      borderColor: colors.dark.alpha['300'],
      textColor: colors.dark.alpha['800'],
      iconColor: colors.dark.alpha['700'],
    },
    positive: {
      background: colors.dark.background.positive,
      borderColor: colors.dark.alpha['300'],
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
    warning: {
      background: colors.dark.background.warning,
      borderColor: colors.dark.alpha['300'],
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
    negative: {
      background: colors.dark.background.negative,
      borderColor: colors.dark.alpha['300'],
      textColor: colors.brand.alphas.light800,
      iconColor: colors.brand.alphas.light700,
    },
    primary: {
      background: colors.dark.background.primary,
      borderColor: colors.dark.alpha['300'],
      textColor: colors.dark.alpha.swap800,
      iconColor: colors.dark.alpha.swap700,
    },
  },
  brand: {
    default: {
      background: colors.brand.alphas.light100,
      borderColor: colors.brand.alphas.light300,
      textColor: colors.brand.alphas.light800,
      iconColor: colors.brand.alphas.light700,
    },
    positive: {
      background: colors.brand.secondary.green500,
      borderColor: colors.brand.alphas.light300,
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
    warning: {
      background: colors.brand.secondary.orange500,
      borderColor: colors.brand.alphas.light300,
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
    negative: {
      background: colors.brand.secondary.red700,
      borderColor: colors.brand.alphas.light300,
      textColor: colors.brand.alphas.light800,
      iconColor: colors.brand.alphas.light700,
    },
    primary: {
      background: colors.brand.aurora['500'],
      borderColor: colors.brand.alphas.light300,
      textColor: colors.brand.alphas.dark800,
      iconColor: colors.brand.alphas.dark700,
    },
  },
};

export type TagProps = DefaultComponentsProps & {
  label: string;
  mode?: keyof typeof TagMode;
  variant: keyof typeof TagVariants;
  size: keyof typeof TagSizes;
  withCloseIcon?: boolean;
};

export const Tag: React.FC<TagProps> = ({ label, withCloseIcon = false, mode = 'dark', variant, ...rest }) => {
  const textProps = getTextSizeProperties(rest.size);
  const colorScheme = COLORS_SCHEME[mode][variant];
  return (
    <Wrapper colorScheme={colorScheme} {...rest}>
      <Text className='label' {...textProps}>
        {label}
      </Text>
      {withCloseIcon && (
        <div className='icon'>
          <CrossClose size={getIconSize(rest.size)} />
        </div>
      )}
    </Wrapper>
  );
};
