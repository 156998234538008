import { join } from 'lodash';

import { progressStore } from 'stores';

function loadingToolbelt(prefix: string) {
  return function (...keys: Array<string | number>) {
    const key = join([prefix, ...keys], '-');

    function isLoading() {
      return progressStore.isLoading(key);
    }

    function logStart() {
      progressStore.log(key, 'progress');
    }

    function logEnd() {
      progressStore.log(key, 'completed');
    }

    return { key, logStart, logEnd, isLoading };
  };
}

export { loadingToolbelt };
