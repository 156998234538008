import * as React from 'react';

import { SnakStoreKey } from 'src/constants';

import { snackStore, Snack as SnackStore } from 'stores';

// type snakStoreKey = keyof SnackStore
// type snakStoreKey = 'success' | 'warn' | 'error'
export const withSnackBar =
  (snackFunc: SnakStoreKey, namespace: string, messageKey: string | null = '') =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<
      any,
      {
        snackId: string | null;
      }
    > {
      state = { snackId: null };

      componentWillUnmount() {
        this.clean();
      }

      show = (options: AnyObject = {}) => {
        const { snackId } = this.state;
        const i18key = options.i18key || messageKey;

        if (snackId) {
          snackStore.removeValue(snackId);
        }

        if (!snackStore[snackFunc]) {
          return;
        }

        const newSnackId = snackStore[snackFunc].bind(snackStore)({ ...options, i18key });

        this.setState({ snackId: newSnackId });
      };

      clean = () => {
        const { snackId } = this.state;

        if (snackId) {
          snackStore.removeValue(snackId);
          this.setState({ snackId: null });
        }
      };

      _wrapperProps = (): withSnackBarComponent<symbol | string> => {
        const { show, clean, state } = this;
        const { snackId } = state;
        return {
          [namespace]: {
            show,
            clean,
            isShown: !!snackId,
          },
        };
      };

      render() {
        return <WrappedComponent {...(this.props as any)} {...this._wrapperProps()} />;
      }
    } as any;
