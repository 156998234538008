import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const IntegratePath = (
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5079 8.79694C10.4766 7.73435 8.80467 7.73435 7.77343 8.79695C6.74219 9.85954 6.74219 11.5824 7.77343 12.6449L12 17L16.2266 12.6449C17.2578 11.5824 17.2578 9.85954 16.2266 8.79695C15.1953 7.73435 13.5233 7.73435 12.4921 8.79695L12 9.30402L11.5079 8.79694Z'
      fill='currentColor'
      fillOpacity='0.85'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 18.6853C18.0314 20.8661 15.2308 22.5 12 22.5C5.90422 22.5 2 17.4101 2 13V12H4V13C4 16.3946 7.09578 20.5 12 20.5C15.284 20.5 18.1796 18.3192 19.8014 15.6086L20.0925 15.1221H22V20.0488H20V18.6853Z'
      fill='currentColor'
      fillOpacity='0.85'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 5.38536C5.97407 3.1604 8.79707 1.5 12 1.5C18.0889 1.5 22 6.48504 22 11V12H20V11C20 7.51496 16.9111 3.5 12 3.5C8.75605 3.5 5.81009 5.72256 4.20301 8.50073L3.91419 9H2V4H4V5.38536Z'
      fill='currentColor'
      fillOpacity='0.85'
    />
  </>
);

export const Integrate = (props: IconProps) => <SvgIcon path={IntegratePath} {...props} />;
