import classNames from 'classnames';
import * as React from 'react';

type RadioProps = {
  radioValue: any;
} & BaseInputProps;

export const RadioComponent = (props: RadioProps) => {
  const { label, name, value, radioValue, disabled, readOnly, onChange, id } = props;

  return (
    <div className={classNames('radio-wrap', { active: value === radioValue })} title={label}>
      <input
        type='checkbox'
        id={id}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
          onChange({ name, value: radioValue }, e);
        }}
        className='radio-input'
      />
      <label htmlFor={id} className='radio-label'>
        {label}
      </label>
    </div>
  );
};
