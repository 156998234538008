import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: ${(props) => props.theme.main.colors.white};
  height: 100%;
  width: 100%;
  z-index: 22;
  top: 0;
  left: 0;

  .showbox {
    padding: 0;
    z-index: 40;
  }

  .spin {
    position: relative;
    width: 60px;
    margin: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    transform-origin: center center;
    animation: rotate 2s linear infinite;
  }

  .path {
    stroke: ${(props) => props.theme.main.colors.auroraLemon};
    stroke-width: 6px;
    stroke-dasharray: 100;
    animation: color 6s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @supports (clip-path: none) {
    .path {
      stroke-width: 5px;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }

      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }

    @keyframes color {
      100%,
      0% {
        stroke: ${(props) => props.theme.main.colors.auroraLemon};
      }

      40% {
        stroke: ${(props) => props.theme.main.colors.error};
      }

      66% {
        stroke: ${(props) => props.theme.main.colors.auroraLemon};
      }

      80%,
      90% {
        stroke: ${(props) => props.theme.main.colors.error};
      }
    }
  }
`;

const spinners: AnyObject = {
  cubesSpinner: (
    <div className='showbox'>
      <div className='spin'>
        <svg className='circular' viewBox='25 25 50 50'>
          <circle className='path' cx='50' cy='50' r='20' fill='none' strokeWidth='2' strokeMiterlimit='10' />
        </svg>
      </div>
    </div>
  ),
  none: null,
};

const Spinner = ({ type = 'cubesSpinner', children }: { type: string; children?: React.ReactNode }) => {
  // if (!!spinners[type]) console.warn(`Current spinner is not found - ${type}`);
  return <Wrapper className='spinner'>{spinners[type]}</Wrapper>;
};

Spinner.types = {
  CUBES_SPINNER: 'cubesSpinner',
  none: 'none',
  default: 'cubesSpinner',
};

export { Spinner };
