import * as React from 'react';
import styled from 'styled-components';

import { SOCIAL_ICONS_COLORS, SOCIAL_LINKS } from 'src/constants';
import { SocialIcon } from 'src/styledComponents/SocialIcon';

import { t } from 'utils';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 0 0 0;
`;

const LeftWrapper = styled.div`
  flex: 1;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 112px;
`;

const Copy = styled.div`
  font-size: 12px;
  font-weight: 700;
  font-family: ${(props) => props.theme.main.font};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.colors.clay5};
`;

type Props = {};

export const SlimFooterComponent: React.FC<Props> = (props) => {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <LeftWrapper>
        <Copy>{t.staticAsString('redesign.copy', { year })}</Copy>
      </LeftWrapper>
      <RightWrapper>
        <SocialIcon color={SOCIAL_ICONS_COLORS.aurora} name='Facebook' link={SOCIAL_LINKS.facebook} ATN='facebook' />
        <SocialIcon color={SOCIAL_ICONS_COLORS.aurora} name='Instagram' link={SOCIAL_LINKS.instagram} ATN='twitter' />
        <SocialIcon color={SOCIAL_ICONS_COLORS.aurora} name='Linkedin' link={SOCIAL_LINKS.linkedin} ATN='linkedin' />
      </RightWrapper>
    </Wrapper>
  );
};
