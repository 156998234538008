import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { Goal } from 'models';

import { distanceStore } from '../stores';

const goal: IComputedValue<Goal | null> = computed(() => {
  const goal = distanceStore.value?.goal;

  if (!goal) {
    return null;
  }

  return new Goal(goal);
});

const isGoalAltitude: IComputedValue<boolean> = computed(() => {
  const goalModel = goal.get();

  if (!goalModel) {
    return false;
  }

  return goalModel.isGoalType('altitude');
});

const isGoalDistance: IComputedValue<boolean> = computed(() => {
  const goalModel = goal.get();

  if (!goalModel) {
    return false;
  }

  return goalModel.isGoalType('distance');
});

export { isGoalDistance, isGoalAltitude };
