import { Observer } from 'mobx-react';
import { toggleLocationAutocomplete } from 'modules/search/actions';
import { focusedStore, locationAutocompleteOpenStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import * as React from 'react';
import styled from 'styled-components';

import { SmoothElevation } from 'src/styledComponents/Elevation';

import { useOnClickOutside } from 'utils';

import { LocationOptionsLoader } from './LocationOptionsLoader';
import { Locations } from './Locations';

const Container = styled(SmoothElevation)<{ open: boolean }>`
  position: absolute;
  top: calc(100% + 16px);
  left: -110px;
  right: 0;
  z-index: 10;
  overflow-y: auto;

  width: 378px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 40px;
  background-color: white;

  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const LocationMenu: React.FC<{ isRecentValues: boolean }> = (props) => {
  const { isRecentValues } = props;
  const ref = React.useRef(null);

  useOnClickOutside(
    ref,
    () => {
      if (!locationAutocompleteOpenStore.value) return;
      toggleLocationAutocomplete(false);
    },
    locationAutocompleteOpenStore.value,
  );

  return (
    <Observer>
      {() => {
        const isOpen = Boolean(locationAutocompleteOpenStore.value) && focusedStore.value === FieldNames.location;
        return (
          <Container ref={ref} level={5} open={isOpen}>
            <LocationOptionsLoader isRecentValues={isRecentValues}>
              <Locations isRecentValues={isRecentValues} />
            </LocationOptionsLoader>
          </Container>
        );
      }}
    </Observer>
  );
};
