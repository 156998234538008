import axios from 'axios';
import { sortBy } from 'lodash';
import { generatePath } from 'react-router-dom';

import { LOAD_REGISTRATION, API_URL, API_V2_URL, API_DISTANCE_V2, LOAD_DISTANCES_COUNT, API_DISTANCES_URL } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { distanceStore, Distance as DistancesStore } from 'stores';

type IdsType = {
  raceId: string;
  distanceId: string;
};

// Deprecated, DO NOT USE IT
class Distance extends LoadableService<DistanceType, FiltersType, DistancesStore> {
  constructor(store: DistancesStore) {
    super({
      store,
      resourcesUrl: `${API_V2_URL}${API_DISTANCE_V2}`,
      resource: LOAD_REGISTRATION,
    });
  }

  @request({ action: LOAD_REGISTRATION })
  async loadDistanceRequest(ids: IdsType, params: FiltersType | {}): Promise<any> {
    const URL = generatePath(this.resourcesUrl, { id: ids.distanceId });
    return axios.get(URL, {
      params,
    });
  }

  @action({ action: LOAD_REGISTRATION })
  async loadDistance(ids: IdsType): Promise<any> {
    let { filters } = this.store || {};

    const [status, response] = await this.loadDistanceRequest(ids, filters);

    if (status) {
      let value: AnyObject | any = {};
      if (this.resourcesRoot === 'none') {
        value = response.data;
      } else {
        value = response.data[this.resourcesRoot];
      }

      if (value.custom_fields) {
        value.custom_fields = value.custom_fields.map((field: AnyObject) => {
          if (field.values) {
            field.values = sortBy(field.values, 'index');
          }

          return field;
        });

        value.custom_fields = sortBy(value.custom_fields, 'index');
      }

      this.store.addSelectedValue(value);
    }
  }

  @request({ action: LOAD_DISTANCES_COUNT })
  loadDistancesCountRequest(): any {
    return axios.get(`${API_URL}${API_DISTANCES_URL}`);
  }

  @action({ action: LOAD_DISTANCES_COUNT })
  async loadDistancesCount(): Promise<number> {
    const [status, response] = await this.loadDistancesCountRequest();

    if (!status) {
      return 0;
    }

    return response.data.data.count;
  }
}

const distanceService = new Distance(distanceStore);

export { Distance, distanceService };
