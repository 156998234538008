import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const HeartRatePath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M14.3229 4.67672C16.4283 2.5713 19.8419 2.57131 21.9473 4.67672C24.0527 6.78213 24.0527 10.1957 21.9473 12.3011L21.946 12.3024L21.9311 12.3173L20.0297 14.2186L12.954 21.2944L12.4319 21.8293L12.4255 21.8229L12.4214 21.8271L11.898 21.2954L4.81858 14.216L3.574 12.9714H5.68392H6.09756L6.39033 12.6792L7.95467 11.1179L11.2562 14.4129L11.9626 15.1179L12.669 14.4129L14.1133 12.9714H17.772V10.9714H13.6997H13.2861L12.9933 11.2636L11.9626 12.2923L8.66108 8.99728L7.95467 8.29225L7.24826 8.99728L5.27028 10.9714H1.92891C0.876993 8.94282 1.20195 6.38549 2.9038 4.68365C5.0073 2.58015 8.41775 2.58015 10.5213 4.68365C10.6098 4.77212 10.6946 4.863 10.7757 4.95609L12.4255 6.60585L13.9891 5.04228C14.0939 4.91649 14.2052 4.79448 14.3229 4.67672Z'
    fill='currentColor'
  />
);

export const HeartRate = (props: IconProps) => <SvgIcon path={HeartRatePath} {...props} />;
