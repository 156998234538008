import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { searchLocationValues } from 'modules/search/selectors';
import { focusedStore, locationAutocompleteOpenStore, recentLocationStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import * as React from 'react';
import styled from 'styled-components';

import { LocationOptionsLoader } from './LocationOptionsLoader';
import { Locations } from './Locations';

const Container = styled.div<{ open: boolean }>`
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: white;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const LocationMenuMobile: React.FC = () => {
  return (
    <Observer>
      {() => {
        const locationValue = searchLocationValues();
        const isRecentValues = !locationValue && !isEmpty(recentLocationStore.value);
        const isOpen = Boolean(locationAutocompleteOpenStore.value) && focusedStore.value === FieldNames.location;
        return (
          <Container open={isOpen}>
            <LocationOptionsLoader isRecentValues={isRecentValues}>
              <Locations isRecentValues={isRecentValues} />
            </LocationOptionsLoader>
          </Container>
        );
      }}
    </Observer>
  );
};
