import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { focusedStore } from 'modules/search/stores';
import { FieldNames, SearchFieldProps } from 'modules/search/types';
import React from 'react';

import { CloseIcon } from 'src/components/icons';

import { Show } from 'components/condition';

import { LocationSearch } from '../searchFields/location';
import { RaceSearch } from './race';
import { SportSearch } from './sport';
import { SearchButton, StyledIcon, StyledSearchItem, StyledWrapper } from './styled';

export const SearchItemDesktop: React.FC<SearchFieldProps> = (props) => {
  const { value, fieldName, label, isTablet, onChange, compressed } = props;
  const isSportField = fieldName === FieldNames.sports;

  const onClose = () => {
    onChange({ name: fieldName, value: isSportField ? [] : '' });
  };

  return (
    <Observer>
      {() => {
        const isFocused = focusedStore.value === fieldName;

        const getField = () => {
          switch (fieldName) {
            case FieldNames.location:
              return <LocationSearch isFocused={Boolean(focusedStore.value === fieldName)} {...props} />;
            case FieldNames.raceName:
              return <RaceSearch isFocused={Boolean(focusedStore.value === fieldName)} {...props} />;
            case FieldNames.sports:
              return <SportSearch isFocused={Boolean(focusedStore.value === fieldName)} {...props} />;
          }
        };

        return (
          <StyledWrapper hasActive={Boolean(focusedStore.value)} compressed={compressed} isFocused={isFocused}>
            <StyledSearchItem
              level={isFocused ? 5 : 0}
              hasActive={Boolean(focusedStore.value)}
              isTablet={isTablet}
              active={isFocused}
              compressed={compressed}
              isSportField={isSportField}
              isFocused={isFocused}
            >
              {getField()}
              <Show if={isSportField ? !isEmpty(value) : Boolean(value)}>
                <a
                  {...testAnchors.useField(fieldName, TEST_ANCHORS.fieldStructure.button, 'close')}
                  onClick={onClose}
                  type='button'
                  className={classNames('close-search', {
                    last: isSportField,
                  })}
                >
                  {CloseIcon}
                </a>
              </Show>
              <Show if={isSportField}>
                <SearchButton
                  {...testAnchors.useField(fieldName, TEST_ANCHORS.fieldStructure.button, 'submit-filter')}
                  type='submit'
                  compressed={compressed}
                >
                  <StyledIcon name='SearchYellow' size={40} />
                </SearchButton>
              </Show>
            </StyledSearchItem>
          </StyledWrapper>
        );
      }}
    </Observer>
  );
};
