import React, { ReactElement, useEffect } from 'react';
import Helmet from 'react-helmet';

import { t } from 'utils';

import { localeStore } from 'stores';

export default function SEO({
  title = 'RaceID',
  translatedTitle,
  description = '',
  translatedDescription,
  ogImage = 'https://filestorage-prd.s3.eu-north-1.amazonaws.com/production/files/uFI1Kedd5pN8XlAEQDVavkg34ejPrDkcl1tqer0Q.jpg',
  ogContentType = 'website',
  ogDescription = '',
}: SeoDataItem): ReactElement {
  if (translatedTitle && t.staticAsString(translatedTitle).length > 0) title = t.staticAsString(translatedTitle);
  if (translatedDescription && t.staticAsString(translatedDescription).length > 0) description = t.staticAsString(translatedDescription);
  if (description === '') description = t.staticAsString('home.par1');

  // TODO fix this via webpack config.
  // Facebook, twitter, etc parsers stop looking for meta tags once they encounter <style> tag in the head
  // Helmet is appending meta tags to already constructed head element, hence helmet tags fall below style tags.
  // The solution would be to instruct webpack to install styles in the <body>.
  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector('head')
        ?.querySelectorAll('meta')
        .forEach((n) => document.querySelector('head')?.insertAdjacentElement('afterbegin', n));
      window.prerenderReady = true;
    }, 1000);
    return () => {};
  }, []);
  return (
    <Helmet>
      <html lang={localeStore.value || 'en'} />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title === 'RaceID' ? title : `RaceID — ${title}`} />
      <meta property='og:site_name' content='RaceID' />
      <meta property='og:description' content={ogDescription.length > 0 ? ogDescription : description} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:type' content={ogContentType} />
      <meta property='og:url' content={window.location.href.split('?')[0]} />
      {/* Twitter */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:domain' content={window.location.hostname} />
    </Helmet>
  );
}
