import { Observer } from 'mobx-react';
import * as React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { Spinner } from 'components/spinner';

import localeStore from 'stores/locale';
import { messagesStore } from 'stores/messages';

type Props = {
  children: React.ReactNode;
};

const IntlProvider = (props: Props) => {
  const { children } = props;

  return (
    <Observer>
      {() => {
        const locale = localeStore.value;
        const messages = messagesStore.value;

        if (!messages) {
          return <Spinner type={Spinner.types.default} />;
        }

        return (
          <ReactIntlProvider locale={locale} messages={messages || {}}>
            {children}
          </ReactIntlProvider>
        );
      }}
    </Observer>
  );
};

export { IntlProvider };
