import { formatter } from 'utils';

function formatAltitude(value: number | null) {
  if (!value) {
    return '0';
  }

  return formatter.integer(value);
}

function formatDistance(value: number | null) {
  if (!value) {
    return '0';
  }

  return formatter.integer(Math.round(value / 100) / 10);
}

export { formatDistance, formatAltitude };
