import { computed } from 'mobx';

import { FIELD_ID_PATTERNS } from '../../constants';

import { mapperUtils, priceUtils } from '../../utils';

import { disabledCustomFieldsStore } from '../../stores';

import { formDataSelectors } from '../formDataSelectors';
import { distanceSelector } from '../mappedData';
import { distancePrice as distancePriceSelector } from './distancePrice';

/**
 * @description
 * total for team leader
 * calculated with coupon
 * including custom fields and distance price
 */
const _leaderTotal = computed(() => {
  const distancePrice = distancePriceSelector.get();
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();

  const customFields = mapperUtils.rejectCustomFields({
    index: 0,
    pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
    fieldIds: disabledCustomFieldsStore.disabledFieldIds,
  })(formDataSelectors.fetchLeaderCustomFields());

  const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);

  const coupon = formDataSelectors.fetchLeaderCoupon();

  return priceUtils.calculateMemberPriceWithDiscount({ distancePrice, coupon, customFieldTotalPrice });
});

const _leaderTotalWithoutDiscount = computed(() => {
  const distancePrice = distancePriceSelector.get();
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();
  const customFields = mapperUtils.rejectCustomFields({
    index: 0,
    pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
    fieldIds: disabledCustomFieldsStore.disabledFieldIds,
  })(formDataSelectors.fetchLeaderCustomFields());

  return priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);
});

const _leaderSubTotalWithoutDiscount = computed(() => {
  const distancePrice = distancePriceSelector.get();
  const distanceCustomFieldValuesPrices = distanceSelector.customFieldOptionsPrices.get();
  const customFields = mapperUtils.rejectCustomFields({
    index: 0,
    pattern: FIELD_ID_PATTERNS.groupRegistrationCustomFields,
    fieldIds: disabledCustomFieldsStore.disabledFieldIds,
  })(formDataSelectors.fetchLeaderCustomFields());

  const customFieldTotalPrice = priceUtils.calculateCustomFieldsPrice(customFields, distanceCustomFieldValuesPrices);

  return priceUtils.calculateMemberPriceWithDiscount({ distancePrice, coupon: null, customFieldTotalPrice });
});

export { _leaderTotal, _leaderTotalWithoutDiscount, _leaderSubTotalWithoutDiscount };
