import { Observer } from 'mobx-react';
import * as React from 'react';

import { InputWrapper } from 'components/form';

import { windowSize } from 'stores';

import { CheckpointStepper as CheckpointStepperComponent } from '../../components/CheckpointStepper';

import { checkpointsSelect, initDistanceLoader } from '../../utils';

function CheckpointStepper() {
  return (
    <Observer>
      {() => {
        const checkpointsOptions = checkpointsSelect.options.get();

        const isCheckpoints = !!checkpointsOptions.length;

        if (!isCheckpoints) {
          return null;
        }

        if (initDistanceLoader.isLoading()) {
          return null;
        }

        const isDesktop = windowSize.isGreaterThan('large');

        return (
          <div className='navigation-menu-bottom'>
            <InputWrapper
              name='checkpoint_id'
              settings={{
                placeholder: 'Checkpoint',
                dropListItems: checkpointsOptions,
                isDesktop: isDesktop,
              }}
              Component={CheckpointStepperComponent}
            />
          </div>
        );
      }}
    </Observer>
  );
}

export { CheckpointStepper };
