//
import { computed, makeObservable } from 'mobx';

import { Article as ArticleModel } from 'models/article';

import { Loadable } from 'stores/loadable';

class Article extends Loadable<ArticleType, FiltersType> {
  declare filters: FiltersType;

  defaultFilters: FiltersType = {
    limit: 12,
  };

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.defaultFilters;
  }

  @computed
  get modelValues(): Array<ArticleModel> {
    return (this.values || []).map((article) => new ArticleModel(article));
  }

  @computed
  get modelSelectedValue(): ArticleModel | Object {
    return new ArticleModel(this.selected as ArticleType);
  }
}

export { Article };
export default new Article();
