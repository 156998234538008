import { createAutoCorrectedDatePipe } from 'text-mask-addons';

import { generateMaskedInput } from '../mask';
import { format, mask, placeholderChar, placeholder } from './constants';

const timeMask = generateMaskedInput({
  mask,
  pipe: createAutoCorrectedDatePipe(format),
  placeholderChar,
  guide: true,
  keepCharPositions: true,
  alwaysShowMask: true,
  placeholder,
});

export * from './tools';
export { timeMask };
