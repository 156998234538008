import moment from 'moment';

import { localeStore } from 'stores';

import { ParrentModel } from './parrentModel';

class Article extends ParrentModel<ArticleType> {
  get created_at(): string {
    const momentValue = moment(this.value.created_at);
    return momentValue.format('DD MMM YYYY') || '';
  }

  get image(): string {
    return this.value.image || '';
  }

  get title(): string | null {
    const locale = localeStore.value;
    const defaultValue = this.value.title_en;

    let title = '';
    switch (locale) {
      case 'en':
        title = this.value.title_en;
        break;
      case 'sv':
        title = this.value.title;
        break;
      default:
        title = this.value.title_en;
    }
    return title || defaultValue || '';
  }

  get description(): string | null {
    const locale = localeStore.value;
    const defaultValue = this.value.description_en;

    let description = '';
    switch (locale) {
      case 'en':
        description = this.value.description_en;
        break;
      case 'sv':
        description = this.value.description;
        break;
      default:
        description = this.value.description_en;
    }
    return description || defaultValue || '';
  }

  get content(): string | null {
    const locale = localeStore.value;
    const defaultValue = this.value.content_en;

    let content = '';
    switch (locale) {
      case 'en':
        content = this.value.content_en;
        break;
      case 'sv':
        content = this.value.content;
        break;
      default:
        content = this.value.content_en;
    }
    return content || defaultValue || '';
  }
}

export { Article };
