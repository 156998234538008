import { trim } from 'lodash';

/*
 * Find incomplete or invalid occurrence start from htt of https:// and
 * replace it with valid.
 * aHR0cHM6Ly95b3V0dS5iZS9sLU81SUhWaFdqMD90PTg2
 * tip b64
 */
export function withHttpUrl(val: string): string {
  const url = trim(val);

  if (!url) {
    return url;
  }

  if (url.match(/^https?:\/\//)) {
    return url;
  }

  // I'ts focking tricky, and not perfect though
  // † if logic below got broken, 3 ave mariya should fix it †
  return url.replace(/^\s*(.*\/{2,}|\/{1,}|(http(s)?:?\/{0,})|htt|.*:\/{1,})?(.*?)\s*$/, 'http$3://$4');
}
