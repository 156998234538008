import { isNil } from 'lodash';

import { form } from 'stores';

import { REGISTRATION_FORM, DISTANCES_FORM, OtherFields, RegistrationFields } from '../../constants';

import { fieldIdUtils, mapperUtils } from '../../utils';

import { couponsStore } from '../../stores';

import { Data, LCoupon } from '../../types';

const formDataSelectors = {
  /**
   * @description
   * Fetch all group registrants, including the registration leader
   */
  fetchGroupRegistrationMembers: () => {
    return form.fetch<Data.Form.Racer[]>(REGISTRATION_FORM, fieldIdUtils.staticKeys.groupRegistrations) || [];
  },
  /**
   * @description
   * Fetch specific group registrant by an index
   */
  fetchGroupRegistrationMember: (index: number) => {
    return form.fetch<Data.Form.Racer | nil>(REGISTRATION_FORM, fieldIdUtils.groupRegistrationWithIndex(index));
  },

  /**
   * @description
   * Fetch coupon by group sign up indx
   * number[id's of custom field values]
   */
  fetchGroupMemberCoupon: (index: number): LCoupon | null => {
    const couponFormId = fieldIdUtils.groupRegistrationsCoupon(index);
    const coupon = couponsStore.findByFieldId(couponFormId);
    if (!Boolean(coupon)) return null;

    if (couponsStore.isCouponApplied(couponFormId)) {
      return coupon!;
    }

    return null;
  },

  /**
   * @description
   * Fetch registration leader ONLY
   */
  fetchRegistrationLeader: () => {
    return form.fetch<Data.Form.Racer>(REGISTRATION_FORM, fieldIdUtils.registrationLeader());
  },
  /**
   * @description
   * Fetch leader`s coupon
   * number[id's of custom field values]
   */
  fetchLeaderCoupon: () => {
    return formDataSelectors.fetchGroupMemberCoupon(0);
  },
  /**
   * @description
   * Fetch leader`s refund protect
   */
  fetchLeaderRefundProtect: () => {
    return form.fetch<boolean | nil>(REGISTRATION_FORM, fieldIdUtils.registrationLeaderRefundProtect()) || null;
  },

  fetchGiftcard: () => {
    return form.fetch<string | nil>(REGISTRATION_FORM, fieldIdUtils.registrationGiftcard()) || null;
  },
  /**
   * @description
   * Fetch leader`s payment method
   */
  fetchLeaderPaymentMethod: () => {
    return form.fetch<1 | 2 | nil>(REGISTRATION_FORM, fieldIdUtils.registrationLeaderPaymentMethod()) || null;
  },
  /**
   * @description
   * Fetch currently inputed leader email
   */
  fetchLeaderEmail: () => {
    return form.fetch<string | nil>(REGISTRATION_FORM, fieldIdUtils.registrationLeaderEmail()) || null;
  },

  /**
   * @description
   * Fetch team registration members
   * not including the registration leader
   */
  fetchTeamRegistrationMembers: () => {
    return form.fetch<Data.Form.Racer[]>(REGISTRATION_FORM, fieldIdUtils.staticKeys.teamMembers) || [];
  },

  /**
   * @description
   * Fetch specific team member by an index
   */
  fetchTeamRegistrationMember: (index: number) => {
    return form.fetch<Data.Form.Racer | nil>(REGISTRATION_FORM, fieldIdUtils.teamMemberWithIndex(index));
  },

  /**
   * @description
   * team name for team registration
   */
  fetchTeamTeamName: (): string | nil => {
    return form.fetch<string | nil>(REGISTRATION_FORM, fieldIdUtils.teamRegistrationTeamName());
  },
  /**
   * @description
   * class id for team registration
   */
  fetchTeamClassId: (): number | nil => {
    const selectItem = form.fetch<NewSelectItemType | nil>(REGISTRATION_FORM, fieldIdUtils.teamRegistrationClassId());
    return parseInt((selectItem?.value as any) || '') || null;
  },
  /**
   * @description
   * wave id for team registration
   */
  fetchTeamWaveId: (): number | nil => {
    const selectItem = form.fetch<NewSelectItemType | nil>(REGISTRATION_FORM, fieldIdUtils.teamRegistrationWaveId());
    return parseInt((selectItem?.value as any) || '') || null;
  },

  /**
   * @description
   * Fetch currently seleted distance id
   * distance in the dropdown that used for the registration
   */
  fetchCurrentDistanceId: () => {
    const selectedOption = form.fetch<NewSelectItemType | null>(DISTANCES_FORM, fieldIdUtils.staticKeys.currentDistance);
    return mapperUtils.distanceIdMapper.mapTo(selectedOption);
  },
  /**
   * @description
   * Fetch from form store, is terms was checked
   */
  fetchIsTermsChecked: () => {
    return form.fetch<boolean>(REGISTRATION_FORM, fieldIdUtils.staticKeys.terms);
  },
  /**
   * @description
   * Fetch email, password & password_confirmation for new user profile
   */
  fetchUserForRegistration: () => {
    return {
      [RegistrationFields.email]: form.fetch<string>(REGISTRATION_FORM, fieldIdUtils.registrationLeaderEmail()),
      [OtherFields.password]: form.fetch<string>(REGISTRATION_FORM, fieldIdUtils.registrationLeaderPassword()),
      [OtherFields.password_confirmation]: form.fetch<string>(REGISTRATION_FORM, fieldIdUtils.registrationLeaderPassword()),
    };
  },

  /**
   * @description
   * Fetch number of usages for each wave
   * { [wave id]: sum of users who selected the same wave }
   */
  fetchWavesUsage: (): { [K in number]: number } => {
    const groupRegistrations = formDataSelectors.fetchGroupRegistrationMembers();
    const teamWaveId = form.fetch<Data.Form.Racer | nil>(REGISTRATION_FORM)?.wave_id?.value;

    let wavesUsage = {};

    groupRegistrations.forEach((groupMember) => {
      const waveId = groupMember.wave_id?.value;

      if (waveId) {
        wavesUsage[waveId] = (wavesUsage[waveId] || 0) + 1;
      }
    });

    if (teamWaveId) {
      wavesUsage[teamWaveId] = (wavesUsage[teamWaveId] || 0) + 1;
    }

    return wavesUsage;
  },

  /**
   * @description
   * Fetch hash which indicates is discipline(relay) was already selected
   */
  fetchDisciplineOccupation: (): { [K in number]: boolean } => {
    const leader = formDataSelectors.fetchRegistrationLeader();
    const teamRegistrations = formDataSelectors.fetchTeamRegistrationMembers();

    let disciplineOccupation = {};

    [leader, ...teamRegistrations].forEach((teamMember) => {
      const disciplineId = teamMember.discipline_id?.value;

      if (disciplineId) {
        disciplineOccupation[disciplineId] = true;
      }
    });

    return disciplineOccupation;
  },

  isFormValueNotNil: (fieldId: string): boolean => {
    return !isNil(form.fetch<any>(REGISTRATION_FORM, fieldId));
  },
  isFormValueNil: (fieldId: string): boolean => {
    return isNil(form.fetch<any>(REGISTRATION_FORM, fieldId));
  },

  /**
   * @description
   * Fetch registration leaders custom fields
   */
  fetchLeaderCustomFields: (): Data.Form.CustomFields => {
    return formDataSelectors.fetchGroupRegistrationCustomFields(0);
  },

  /**
   * @description
   * Fetch custom fields for specific group registration member
   */
  fetchGroupRegistrationCustomFields: (index: number): Data.Form.CustomFields => {
    return (
      form.fetch<Data.Form.CustomFields | nil>(
        REGISTRATION_FORM,
        [fieldIdUtils.groupRegistrationWithIndex(index), fieldIdUtils.staticKeys.customFieldsKey].join('.'),
      ) || {}
    );
  },

  /**
   * @description
   * Fetch custom fields for specific team registraiton member
   * (NOT INCLUDING REGISTRATION LEADER)
   */
  fetchTeamRegistrationMemberCustomFields: (index: number): Data.Form.CustomFields => {
    return (
      form.fetch<Data.Form.CustomFields | nil>(
        REGISTRATION_FORM,
        [fieldIdUtils.teamMemberWithIndex(index), fieldIdUtils.staticKeys.customFieldsKey].join('.'),
      ) || {}
    );
  },

  /**
   * @description
   * Fetch custom fields for all of team members merged into one format
   * (NOT INCLUDING REGISTRATION LEADER)
   */
  fetchAllTeamRegistrationMembersCustomFields: (): Data.Form.CustomFields => {
    return formDataSelectors
      .fetchTeamRegistrationMembers()
      .reduce<Data.Form.CustomFields>((allCustomFields, _teamRegitrationMember, index) => {
        return { ...allCustomFields, ...formDataSelectors.fetchTeamRegistrationMemberCustomFields(index) };
      }, {});
  },
};

export { formDataSelectors };
