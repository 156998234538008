import * as React from 'react';
import styled from 'styled-components';

import { RacerName } from '../RacerName';

type Props = {
  members: RacersType[];
  onOpenRacer: (id: number) => AnyFunction;
  className?: string;
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 24px 20px 20px 44px;
`;

export const TeamMembers: React.FC<Props> = (props) => {
  const { onOpenRacer, members, className } = props;

  return (
    <Wrapper className={className}>
      {members.map((member) => (
        <RacerName key={`member-mobile-${member.id}`} value={member} onOpen={onOpenRacer} />
      ))}
    </Wrapper>
  );
};
