import { observable, action, makeObservable } from 'mobx';

type Value = {
  token: string;
  id: number;
  isSecret: boolean;
};

// Tokens for registration to register to blocked races.
class AuthorizedRace {
  @observable values: { [K in string]: Value } = {};
  @observable active: number | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  addOrUpdateValue(
    id: number,
    options: {
      token?: string;
      isSecret?: boolean;
    },
  ) {
    const value = this.findValue(id) || {};
    this.values[`${id}`] = { id, ...value, ...options } as any;
  }

  @action
  setActive(id: number) {
    this.active = id;
  }

  findValue(id: number): Value | null {
    const value = this.values[`${id}`];
    return value;
  }

  findToken(id: number): string | null {
    const value = this.findValue(id);

    if (!value) {
      return null;
    }

    return value.token;
  }

  isAutorized(id: number): boolean | null {
    const value = this.findValue(id);

    if (!value) {
      return null;
    }

    if (!value.isSecret) {
      return true;
    }

    return value.isSecret && !!value.token;
  }

  @action
  clearActive() {
    this.active = null;
  }

  @action
  cleanAll() {
    const keys = Object.keys(this.values);

    const currentValues = this.values;
    const newValues = keys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: { ...(currentValues[key] || {}), token: null },
      };
    }, {});

    this.values = newValues;
  }
}

const authorizedRace = new AuthorizedRace();

export { AuthorizedRace, authorizedRace };
