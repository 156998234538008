import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useWindowSize } from 'utils';

const Wrapper = styled.div`
  .draggable-element {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    bottom: 0;
    touch-action: none;

    span {
      display: block;
      height: 4px;
      width: 53px;
      background: ${(props) => props.theme.main.colors.clay5};
      border-radius: 10px;
    }
  }
`;

export const DraggedItem: FC<{ onDragEnd: AnyFunction; onDragStart?: AnyFunction; setTopOffset: AnyFunction }> = ({
  onDragEnd,
  onDragStart,
  setTopOffset,
}) => {
  const [isDragging, setDragging] = useState(false);
  const isMobile = useWindowSize();

  if (!isMobile) {
    return null;
  }

  const dragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
    onDragStart && onDragStart(true);
  };

  const dragging = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDragging) {
      const offsetTop = e.touches[0].clientY;
      const dif = offsetTop - e.currentTarget.getBoundingClientRect().top;

      setTopOffset(dif);
    }
  };

  const dragEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    onDragEnd(true);
  };

  return (
    <Wrapper>
      <div className='draggable-element' onTouchStart={dragStart} onTouchMove={dragging} onTouchEnd={dragEnd}>
        <span />
      </div>
    </Wrapper>
  );
};
