import { cloneDeep, pick } from 'lodash';
import moment from 'moment';

import { BACKEND_DATE_FORMAT } from 'src/constants';

import { SEGMENT_FORM_FIELDS } from '../constants';

import { BeSegment, FormSegment } from '../types';

class Mapper implements MapperMiddleware<FormSegment, BeSegment> {
  mapTo(data: FormSegment): BeSegment {
    const copy: any = cloneDeep(data);

    copy.distance_logged = parseInt(copy.distance_logged) || null;

    const distanceLoggedAt = moment(copy.distance_logged_at || NaN);

    if (distanceLoggedAt.isValid()) {
      copy.distance_logged_at = distanceLoggedAt.format(BACKEND_DATE_FORMAT);
    }

    if (!(copy.activity_link || '').trim()) {
      copy.activity_link = null;
    }

    return copy;
  }

  mapFrom(data: BeSegment): FormSegment {
    return cloneDeep(data) as any;
  }

  parseApiData(value: Segment): BeSegment {
    return pick(value, SEGMENT_FORM_FIELDS) as any;
  }
}

function deleteEmptyActivityLink(data: BeSegment): BeSegment {
  const copy = cloneDeep(data);

  if (!!data.activity_link) {
    return data;
  }

  delete copy.activity_link;

  return copy;
}

const formToBeSegmentMapper = new Mapper();

export { deleteEmptyActivityLink, formToBeSegmentMapper };
