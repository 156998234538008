import { SocialLinks } from 'modules/miles/landingPage/components';
import { Text } from 'modules/miles/shared/components';
import { Divider } from 'modules/miles/shared/components/Divider';
import moment from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';

import { RaceId } from 'src/assets/images/miles/logotypes';
import { RESOLUTIONS, ROUTER } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { Link } from 'src/styledComponents/Link';

const Wrapper = styled.div`
  padding: 48px 60px;
  display: flex;
  gap: 24px;

  flex-direction: column;
  color: ${(props) => props.theme.miles.colors.brand.alphas.light900};
  background: ${(props) => props.theme.miles.colors.dark.background[600]};
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.2);

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: 48px 40px;
  }
`;

const FooterNavigation = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 56px;
`;

const FooterCopyright = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Copyright = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    height: 17px;
    width: 93px;
    margin-right: 16px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const LinksBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinkStyles = css`
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-decoration: none;

  :active,
  :hover {
    color: ${(props) => props.theme.miles.colors.dark.thin.primary};
  }
`;

const NavLink = styled(Link)`
  ${LinkStyles}
`;

const ExternalLink = styled.a`
  ${LinkStyles}
`;

const NAVIGATION_COLUMNS = [
  {
    title: 'Miles',
    items: [
      { title: 'Training Plan', link: ROUTER.MILES_TRAINING_PLAN, internal: true },
      { title: 'Learn More', link: ROUTER.MILES, internal: true },
    ],
  },
  {
    title: 'RaceID',
    items: [
      { title: 'Find a Race', link: `${ROUTER.RACES}?showAll=true`, internal: true },
      { title: 'Organizers', link: ROUTER.FEATURES_PRICING, inNewTab: true },
      { title: 'Contact us', link: ROUTER.ABOUT, internal: true },
    ],
  },
  {
    title: 'Useful Links',
    items: [{ title: 'Terms & Conditions', link: ROUTER.PUBLIC_TERMS, internal: true }],
  },
];

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export const Footer: React.FC<Props> = (props) => {
  return (
    <Wrapper {...props}>
      <FooterNavigation>
        {NAVIGATION_COLUMNS.map(({ title, items }) => (
          <Column key={title}>
            <Text size={'lg'} weight={'bold'}>
              {title}
            </Text>
            <LinksBlock>
              {items.map(({ title, link, internal, inNewTab }) => (
                <Text key={title} size={'sm'} weight={'regular'}>
                  {internal ? (
                    <NavLink to={link}>{title}</NavLink>
                  ) : (
                    <ExternalLink href={link} {...(inNewTab && { target: '_blank' })}>
                      {title}
                    </ExternalLink>
                  )}
                </Text>
              ))}
            </LinksBlock>
          </Column>
        ))}
        <ShowMobile>
          <SocialLinks style={{ alignSelf: 'flex-end' }} />
        </ShowMobile>
      </FooterNavigation>
      <Divider />
      <FooterCopyright>
        <Copyright>
          <img src={RaceId} alt={'RaceId'} />
          <Text size={'md'} weight={'regular'}>
            ©️ {moment().year()} RaceID, AB.
          </Text>
        </Copyright>
        <ShowDesktop>
          <SocialLinks />
        </ShowDesktop>
      </FooterCopyright>
    </Wrapper>
  );
};
