import { Text } from 'modules/miles/shared/components';
import { Star } from 'modules/miles/shared/components/Icon';
import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  justify-content: center;

  padding: 24px 40px;

  background: ${(props) => props.theme.miles.colors.dark.background['700']};
  box-shadow: 0px 12px 14px ${(props) => props.theme.miles.colors.dark.alpha.swap100};
  border-radius: 8px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 278px;
    gap: 16px;
    text-align: center;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .rating {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 3px;
    color: ${(props) => props.theme.miles.colors.dark.background.secondary};
    .rated {
      color: ${(props) => props.theme.miles.colors.dark.background.primary};
    }
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 46px;
  height: 46px;
`;

type Props = {
  userName: string;
  rating: 0 | 1 | 2 | 3 | 4 | 5;
  userComment: string;
  avatar: string;

  className?: string;
  style?: React.CSSProperties;
};

export const TestimonialCard: React.FC<Props> = ({ userName, avatar, rating = 5, userComment, ...rest }) => {
  return (
    <Card {...rest}>
      <div className='content'>
        <Header>
          <Avatar src={avatar} />
          <div className='column'>
            <Text weight='medium' size='md' className='name'>
              {userName}
            </Text>
            <div className='rating'>
              {[...Array(rating)].map((item, index) => (
                <Star key={index} size={10} className='rated' />
              ))}
              {[...Array(5 - rating)].map((item, index) => (
                <Star key={index} size={10} />
              ))}
            </div>
          </div>
        </Header>
        <Text weight='light' size='md' style={{ height: '88px' }}>
          "{userComment}"
        </Text>
      </div>
    </Card>
  );
};
