import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { generateDataEntries } from 'src/modules/startlist/derivations/data/generateDataEntries';
import { distancesStore } from 'src/modules/startlist/stores';

export const hasAny: IComputedValue<boolean> = computed(() => {
  return distancesStore.filteredValues.some((distance) => {
    return generateDataEntries(distance.id).get().length > 0;
  });
});
