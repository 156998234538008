import * as React from 'react';

export const Otillo = (
  <svg width='204' height='64' viewBox='0 0 204 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M81.4785 60.7828H95.7639V17.0469H81.4785V60.7828Z' fill='#FFFFFE' />
    <path fillRule='evenodd' clipRule='evenodd' d='M113.803 17.0469H99.7832V60.7828H126.088V47.91H113.803V17.0469Z' fill='#FFFFFE' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.8783 11.8106C16.1343 11.8106 18.783 9.16193 18.783 5.90765C18.783 2.65159 16.1343 0.00292969 12.8783 0.00292969C9.62401 0.00292969 6.97534 2.65159 6.97534 5.90765C6.97534 9.16193 9.62401 11.8106 12.8783 11.8106Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.0612 11.8106C36.3155 11.8106 38.9642 9.16193 38.9642 5.90765C38.9642 2.65159 36.3155 0.00292969 33.0612 0.00292969C29.8052 0.00292969 27.1565 2.65159 27.1565 5.90765C27.1565 9.16193 29.8052 11.8106 33.0612 11.8106Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.955 42.7464V42.7482C31.4438 43.9273 30.742 44.9694 29.8656 45.8439C28.9892 46.7221 27.9472 47.4256 26.7681 47.9333C25.5889 48.4427 24.3118 48.701 22.9705 48.701C21.6275 48.701 20.3486 48.4427 19.1694 47.9333C17.992 47.4239 16.95 46.7221 16.0737 45.8439C15.1955 44.9676 14.4919 43.9256 13.9843 42.7464C13.4749 41.5672 13.2184 40.2901 13.2184 38.9488C13.2184 37.6058 13.4767 36.3287 13.9843 35.1495C14.4919 33.9721 15.1937 32.9301 16.0737 32.052C16.95 31.1739 17.992 30.4721 19.1694 29.9644C20.3539 29.455 21.6311 29.1967 22.9705 29.1967C24.3082 29.1967 25.5871 29.455 26.7681 29.9626C27.9455 30.4721 28.9875 31.1739 29.8656 32.052C30.7437 32.9301 31.4455 33.9721 31.955 35.1477C32.4644 36.3305 32.7209 37.6094 32.7209 38.9488C32.7209 40.2865 32.4644 41.5654 31.955 42.7464ZM39.2116 22.706C37.1454 20.638 34.682 18.9814 31.8926 17.7827C29.1068 16.5857 26.1037 15.9801 22.9705 15.9801C19.8338 15.9801 16.8307 16.5857 14.0466 17.7827C11.2573 18.9814 8.79385 20.638 6.72764 22.706C4.65787 24.774 3.00134 27.2374 1.80437 30.025C0.607394 32.8108 0 35.8121 0 38.9488C0 42.0856 0.607394 45.0869 1.80437 47.8709C3.00313 50.6603 4.65787 53.1237 6.72764 55.1899C8.79385 57.2579 11.2573 58.9162 14.0466 60.1132C16.8307 61.3102 19.8338 61.9176 22.9705 61.9176C26.1037 61.9176 29.1068 61.3102 31.8926 60.1132C34.6802 58.9145 37.1436 57.2579 39.2116 55.1899C41.2796 53.1237 42.9361 50.6603 44.1349 47.8709C45.3319 45.0851 45.9393 42.082 45.9393 38.9488C45.9393 35.8139 45.3319 32.8126 44.1349 30.025C42.9361 27.2374 41.2796 24.774 39.2116 22.706Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.838 28.6052C48.0054 29.0024 48.1764 29.4334 48.351 29.9179H55.3583V60.7828H69.2428V29.9179H77.4578V17.0469H38.3887C39.746 17.9731 41.0071 19.0187 42.1542 20.164C44.5445 22.5562 46.4558 25.3972 47.838 28.6052Z'
      fill='#FFFFFE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M170.485 11.8106C173.741 11.8106 176.388 9.16193 176.388 5.90765C176.388 2.65159 173.741 0.00292969 170.485 0.00292969C167.231 0.00292969 164.582 2.65159 164.582 5.90765C164.582 9.16193 167.231 11.8106 170.485 11.8106Z'
      fill='#FFFFFE'
    />
    <mask id='mask0_611_2420' maskUnits='userSpaceOnUse' x='157' y='0' width='47' height='62'>
      <path d='M157.609 0.00292969H203.546V61.9161H157.609V0.00292969Z' fill='white' />
    </mask>
    <g mask='url(#mask0_611_2420)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M190.668 11.8106C193.923 11.8106 196.573 9.16193 196.573 5.90765C196.573 2.65159 193.923 0.00292969 190.668 0.00292969C187.414 0.00292969 184.765 2.65159 184.765 5.90765C184.765 9.16193 187.414 11.8106 190.668 11.8106Z'
        fill='#FFFFFE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M189.562 42.746C189.053 43.927 188.349 44.969 187.473 45.8436C186.596 46.7217 185.554 47.4253 184.377 47.9329C183.198 48.4423 181.919 48.7006 180.578 48.7006C179.235 48.7006 177.956 48.4423 176.777 47.9329C175.599 47.4235 174.557 46.7217 173.681 45.8436C172.803 44.9672 172.099 43.9252 171.593 42.746C171.084 41.5686 170.825 40.2897 170.825 38.9485C170.825 37.6054 171.084 36.3265 171.593 35.1492C172.099 33.9736 172.803 32.9315 173.681 32.0516C174.557 31.1735 175.599 30.4717 176.778 29.964C177.959 29.4546 179.238 29.1963 180.578 29.1963C181.915 29.1963 183.194 29.4546 184.377 29.9623C185.553 30.4717 186.596 31.1735 187.473 32.0516C188.349 32.9298 189.053 33.9718 189.562 35.1492C190.071 36.3283 190.33 37.6072 190.33 38.9485C190.33 40.288 190.071 41.5651 189.562 42.746ZM201.744 30.0246C200.543 27.237 198.887 24.7736 196.821 22.7056C194.751 20.6376 192.287 18.9811 189.5 17.7823C186.712 16.5853 183.711 15.9797 180.578 15.9797C177.441 15.9797 174.44 16.5853 171.654 17.7823C168.864 18.9811 166.403 20.6376 164.335 22.7056C162.267 24.7736 160.61 27.2352 159.411 30.0246C158.214 32.8086 157.609 35.81 157.609 38.9485C157.609 42.0852 158.214 45.0883 159.411 47.8706C160.61 50.66 162.267 53.1234 164.335 55.1896C166.403 57.2593 168.866 58.9159 171.654 60.1129C174.44 61.3098 177.441 61.9172 180.578 61.9172C183.711 61.9172 186.712 61.3098 189.5 60.1129C192.287 58.9159 194.751 57.2576 196.821 55.1896C198.887 53.1234 200.543 50.66 201.744 47.8706C202.941 45.0848 203.548 42.0816 203.548 38.9485C203.548 35.8135 202.941 32.8122 201.744 30.0246Z'
        fill='#FFFFFE'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M155.71 49.2904C155.534 48.8772 155.359 48.4336 155.17 47.91H144.128V17.0469H130.11V60.7828H165.061C163.74 59.8708 162.509 58.8484 161.396 57.7334C159.002 55.3412 157.09 52.5019 155.71 49.2904Z'
      fill='#FFFFFE'
    />
  </svg>
);
