import { Observer } from 'mobx-react';
import { metaStore } from 'modules/races/stores';
import React from 'react';

import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { StyledButton } from './styled';

export const SubmitButton = () => {
  return (
    <Observer>
      {() => {
        const value = metaStore.value?.total || 0;
        return (
          <StyledButton className='sticky' variant='primary' size='large'>
            <Utility variant='u1' weight='bold'>
              {`${value} ${t.staticAsString('header.races')}`}
            </Utility>
          </StyledButton>
        );
      }}
    </Observer>
  );
};
