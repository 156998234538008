import { isEmpty } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { calculatePercents, getTimeResult, displayLengthByGoal, displayLengthForTable } from 'utils';

import { segmentsStore } from '../stores';

type ReturnedValue = {
  lengthShort: number;
  length: number;
  time: string;
  percents: number;
};

const defaultValue = {
  lengthShort: 0,
  length: 0,
  time: '00:00:00',
  percents: 0,
};

export const getTotalInfo: IComputedValue<ReturnedValue> = computed((): ReturnedValue => {
  if (!segmentsStore.distance || isEmpty(segmentsStore.total)) {
    return defaultValue;
  }

  return {
    lengthShort: displayLengthByGoal(segmentsStore.total.distance_logged, segmentsStore.distance.goal, true),
    length: displayLengthByGoal(segmentsStore.total.distance_logged, segmentsStore.distance.goal),
    time: getTimeResult(segmentsStore.total.start_time, segmentsStore.total.finish_time),
    percents: calculatePercents(segmentsStore.total.distance_logged, segmentsStore.distance?.goal?.goal_max_length),
  };
});
