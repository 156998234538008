import { PhoneInput as PhoneInputComponent } from 'racese-react-phone-input-2';
import * as React from 'react';
import styled from 'styled-components';

import { PREFERED_COUNTRIES } from 'src/constants';
import { HardElevation } from 'src/styledComponents/Elevation';

import { TooltipIcon } from 'components/icons';

import { t } from 'utils';

import { InputWrapComponent } from '../inputWrap';

interface CustomInputProps extends BaseInputProps {
  inputName?: string;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  .tooltip {
    padding-left: 8px;

    .tooltip-message {
      position: absolute;
      top: calc(100% + 8px);
      right: 0;
      width: 300px;
      background: ${(props) => props.theme.main.colors.white};
      padding: 20px;
      border-radius: 10px;
      transition: opacity 0.3s;
      opacity: 0;
    }

    .tooltip-icon:hover + .tooltip-message {
      opacity: 1;
      z-index: 20;
    }
  }

  .react-tel-input {
    input {
      width: 100%;
    }
  }
`;

export class PhoneInput extends React.Component<CustomInputProps> {
  static contextType = TestAnchorContext;
  declare context: React.ContextType<typeof TestAnchorContext>;

  handleChange = (phone: string, countryData: Object) => {
    const { onChange, name } = this.props;

    onChange({
      name,
      value: {
        phone,
        countryData,
      },
    });
  };

  render() {
    const { autoComplete, requiredMark, errorMessage, label, name, value, disabled, inputName } = this.props;

    return (
      <InputWrapComponent
        errorMessage={errorMessage}
        disabled={disabled}
        label={label}
        requiredMark={requiredMark}
        name={name}
        value={value?.phone || ''}
      >
        <Wrapper className='phone-field-with-tooltip-wrapper'>
          <div className='tooltip phone-input'>
            <span className='tooltip-icon'>{TooltipIcon}</span>
            <HardElevation level={2} className='tooltip-message phone-input'>
              <p>{t('phoneInput.tooltip.p1')}</p>
              <p>{t('phoneInput.tooltip.p2')}</p>
              <p>{t('phoneInput.tooltip.p3')}</p>
            </HardElevation>
          </div>

          <PhoneInputComponent
            {...testAnchors.field(this.context.container, name, TEST_ANCHORS.fieldStructure.root)}
            containerClass='react-tel-input phone-input'
            preferredCountries={PREFERED_COUNTRIES}
            value={value?.phone || ''}
            onChange={this.handleChange}
            enableSearchField
            enableLongNumbers
            disableSearchIcon
            disableAreaCodes
            isValid={() => true}
            inputExtraProps={{ autoComplete, name: inputName }}
          />
        </Wrapper>
      </InputWrapComponent>
    );
  }
}
