import { generateHistoryFilterParams, getDefaultDistanceId } from '../../utils';

import { internalHistoryMapper } from '../../mapper';
import { distanceChanger } from '../../mutations';

/*
 * Init filtrers and distance based on defaults and history params
 */
async function initFiltersAndDistance() {
  const historyInitFilters = generateHistoryFilterParams();

  const defaultDistance = getDefaultDistanceId();

  const internalFilters = internalHistoryMapper.mapFrom(historyInitFilters);

  distanceChanger.init(internalFilters, defaultDistance);
}

export { initFiltersAndDistance };
