import React from 'react';

import { SearchMobileView } from '../components/searchMobileView';

import { mountMobile, unmountMobile } from '../actions';

export const MobileSearch = () => {
  React.useEffect(() => {
    mountMobile();
    return () => {
      unmountMobile();
    };
  }, []);

  return <SearchMobileView />;
};
