import React from 'react';

import { BrandButton } from './Brand';
import { DarkButton } from './Dark';
import { LightButton } from './Light';
import { ButtonProps, Mode } from './types';

type Props = ButtonProps & {
  mode: keyof typeof Mode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<Props> = (props) => {
  const { mode, ...rest } = props;

  switch (mode) {
    case Mode.dark:
      return <DarkButton {...rest} />;

    case Mode.light:
      return <LightButton {...rest} />;

    case Mode.brand:
      return <BrandButton {...rest} />;

    default:
      return null;
  }
};
