import * as React from 'react';

export const Favourite = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.001 22.4971C17.8 22.4971 22.501 17.7961 22.501 11.9971C22.501 6.19808 17.8 1.49707 12.001 1.49707C6.20199 1.49707 1.50098 6.19808 1.50098 11.9971C1.50098 17.7961 6.20199 22.4971 12.001 22.4971Z'
      fill='#656B6F'
      className='circle'
    />
    <path
      d='M12.3229 6.69809L13.8099 9.6441L16.6719 9.92763C16.74 9.93329 16.8051 9.95836 16.8594 9.99986C16.9138 10.0414 16.9551 10.0976 16.9784 10.1618C17.0018 10.2261 17.0063 10.2957 16.9913 10.3624C16.9764 10.4291 16.9426 10.4901 16.8941 10.5383L14.5387 12.8727L15.412 16.045C15.4298 16.1122 15.4279 16.1833 15.4063 16.2495C15.3848 16.3157 15.3446 16.3742 15.2906 16.4181C15.2365 16.462 15.171 16.4894 15.1018 16.4969C15.0326 16.5044 14.9626 16.4918 14.9005 16.4605L11.9989 15.0237L9.10133 16.4587C9.03913 16.49 8.96922 16.5026 8.90001 16.4951C8.8308 16.4876 8.76524 16.4602 8.7112 16.4163C8.65717 16.3724 8.61697 16.3139 8.59543 16.2477C8.57389 16.1815 8.57194 16.1105 8.58981 16.0432L9.46308 12.871L7.10592 10.5365C7.05736 10.4883 7.02361 10.4273 7.00866 10.3606C6.99371 10.2939 6.99819 10.2243 7.02157 10.16C7.04495 10.0958 7.08625 10.0396 7.14058 9.99809C7.19491 9.95658 7.25999 9.93152 7.32812 9.92585L10.1901 9.64232L11.6749 6.69809C11.7054 6.63845 11.7519 6.5884 11.809 6.55345C11.8662 6.5185 11.9319 6.5 11.9989 6.5C12.0659 6.5 12.1316 6.5185 12.1888 6.55345C12.2459 6.5884 12.2923 6.63845 12.3229 6.69809Z'
      fill='#272E34'
      className='star'
    />
  </svg>
);
