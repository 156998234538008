import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { LOAD_DISTANCES, API_URL, API_DISTANCES } from 'src/constants';

import { request, action } from 'utils';

import { DistancesStore } from '../stores';

class Distances {
  @request({ action: LOAD_DISTANCES })
  async loadRequest(raceId: number, params: FiltersType | {}): Promise<any> {
    const url = generatePath(API_URL + API_DISTANCES, { id: raceId });

    return axios.get(url, { params });
  }

  @action({ action: LOAD_DISTANCES, minRequestTime: 800 })
  async load(raceId: number): Promise<DistanceType[]> {
    const queryParams = DistancesStore.filters;

    const [status, response] = await this.loadRequest(raceId, queryParams);
    const data = response?.data?.data;

    if (!status || !data) {
      return [];
    }

    return data;
  }
}

export { Distances };
