import { merge } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

import { GoalModel } from 'models';

class Goals {
  @observable values: GoalType[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get modelValues(): GoalModel[] {
    const { values } = this;

    return values.map<GoalModel>((el) => new GoalModel(el));
  }

  @action
  async setValues(values: GoalType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  findByDistance(distanceId: number): null | GoalModel {
    return this.groupedGoals[distanceId] || null;
  }

  @computed
  get groupedGoals(): { [K in number]: GoalModel } {
    const { modelValues } = this;
    return modelValues.reduce((acc, model) => {
      const distanceId = model.value.distance_id;
      return merge(acc, { [distanceId]: model });
    }, {});
  }
}

export { Goals, Goals as GoalsStore };
