import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { GoalModel } from 'models';

import { goalsStore } from '../stores';

function generateGoal(distanceId: number): IComputedValue<GoalModel | null> {
  return computed(() => {
    return goalsStore.findByDistance(distanceId);
  });
}

export { generateGoal };
