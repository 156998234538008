import { cloneDeep } from 'lodash';

export const profileMapFrom = (data) => {
  const copy = cloneDeep(data);

  return copy.map((distance) => ({
    ...distance,
    racer_id: distance.racers[0].id,
    race_id: distance.race.id,
  }));
};
