import { observable, computed, action, makeObservable } from 'mobx';

import { Payments as PaymentsModel } from 'models/payments';

import { Loadable } from 'stores/loadable';

class Payments extends Loadable<PaymentsType, FiltersType> {
  declare filters: FiltersType;

  @observable
  registrationConfigs: RegistrationConfigs = {
    registration_ttl: 0,
  };

  @observable
  paymentOptions: AnyObject = {
    token: null,
    url: null,
  };

  constructor() {
    super();

    makeObservable(this);
    this.filters = {};
  }

  @computed
  get modelValues(): Array<PaymentsModel> {
    return (this.values || []).map((payment) => new PaymentsModel(payment));
  }

  @action
  changeConfig(config: RegistrationConfigs) {
    this.registrationConfigs = {
      ...this.registrationConfigs,
      ...config,
    };
  }

  @action
  setPaymentOptions(options: AnyObject) {
    this.paymentOptions = {
      ...this.paymentOptions,
      ...options,
    };
    if (options.url) {
    } else {
    }
  }

  @action
  clearPaymentOptions() {
    this.paymentOptions = {
      token: null,
      url: null,
    };
  }

  @computed
  get isPaymentFrameOpened(): boolean {
    const url = this.paymentOptions.url;
    return !!url;
  }
}

export { Payments };
export default new Payments();
