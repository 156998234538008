import { overlayStore, errorsStore } from 'stores';

import { MY_RESULTS_FORM, CUMULATIVE_FORM } from '../constants';

import { resultStore, actionMenuStore } from '../stores';

export const mountForm = (): void => {
  overlayStore.show();
};

export const unmountForm = (): void => {
  errorsStore.clear(MY_RESULTS_FORM);
  errorsStore.clear(CUMULATIVE_FORM);
  resultStore.clearForm();
  overlayStore.hide();
  actionMenuStore.clear();
};

export const unmountResults = (): void => {
  resultStore.clear();
};
