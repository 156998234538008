import * as React from 'react';

import { CustomScrollBar } from 'components/core/customScrollBar';

import { InputWrapComponent } from '../inputWrap';

export const TextareaComponent = (props: BaseInputProps): React.ReactNode => {
  const { placeholder, value, readOnly, disabled, name, label, requiredMark, errorMessage, onChange, onBlur } = props;

  return (
    <InputWrapComponent disabled={disabled} name={name} label={label} requiredMark={requiredMark} errorMessage={errorMessage}>
      <div className='textarea-wrap'>
        <CustomScrollBar>
          <textarea
            {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
            name={name}
            id={name}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              const { value } = e.currentTarget;
              onChange({ name, value });
            }}
            onBlur={onBlur}
            className='input-component'
          />
        </CustomScrollBar>
      </div>
    </InputWrapComponent>
  );
};
