import { localeStore } from 'stores';

import { ParrentModel } from './parrentModel';

class Wave extends ParrentModel<WaveType> {
  id: string | number;
  hashForCompare: string;

  constructor(value: WaveType, id?: string) {
    super(value);
    this.value = value;
    this.id = id || value.id;
    this.hashForCompare = '';
    this._initHashForCompare();
  }

  _initHashForCompare() {
    const { name } = this.value;
    this.hashForCompare = `${name || ''}`.replace(/\s/, 'g').toLowerCase();
  }

  static serializeSimilarClassIds(idValues: Array<number>): string {
    return idValues.join(',');
  }

  toSelectItem(maxNameLength: number = -1) {
    const { id } = this;
    const text = this.value.name;
    const label = maxNameLength !== -1 && text.length > maxNameLength ? text.substr(0, maxNameLength) + '...' : text;

    return {
      value: id,
      key: id,
      label,
      title: label,
    };
  }
}
export { Wave };
