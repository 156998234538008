import { messages } from 'utils';

import { generateTranslationsService } from 'services/translations';

/*
 * Load translations, and store it for further use
 */

let service: AnyFunction | any;

async function apiLoad(locale: availableLocales) {
  if (!service) {
    service = generateTranslationsService();
  }

  const [version, translations] = await service.fetch(locale);

  if (!translations) {
    return;
  }

  const data = {
    [locale]: {
      name: locale,
      translations,
    },
  } as ApiTranslations;

  await messages.store(version, data);
}

export { apiLoad };
