import moment from 'moment';

function formatDate(date: string): [string, string] {
  const momentDate = moment(date);

  const isCurrentYear = momentDate.year() === new Date().getFullYear();
  const day = momentDate.format('DD');

  if (isCurrentYear) {
    return [day, momentDate.format('MMM')];
  }

  return [day, momentDate.format('MMM YYYY')];
}

export { formatDate };
