import moment from 'moment';
import { Moment } from 'moment';
import * as React from 'react';
import { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { HardElevation } from '../Elevation';
import { Svg } from '../Icons';
import { Utility } from '../Typography';
import { getNumberOfCalendarMonthWeeks } from './utils';

const CALENDAR_CELL_HEIGHT = 46;
const REST_CALENDAR_CONTENT_HEIGHT = 223;

type Props = {
  isSingleMonthRange?: boolean;
  isSingleDate: boolean;
  date?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  onChange: (arg: { startDate: Moment | null; endDate: Moment | null } | Moment | null) => void;
};

type State = {
  focus: 'startDate' | 'endDate';
  focused: boolean;
  calendarHeight: number;
};

const YearContainer = styled.div`
  .navigation-button {
    svg > path {
      stroke: ${(props) => props.theme.main.colors.clay2} !important;
    }
    &:hover {
      cursor: pointer;
      svg > path {
        stroke: ${(props) => props.theme.main.colors.white} !important;

        &.circle {
          fill: ${(props) => props.theme.main.colors.clay3} !important;
          stroke: ${(props) => props.theme.main.colors.clay3} !important;
        }
      }
    }
  }
`;

const SingleWrapper = styled(HardElevation)<{ containerHeight: number }>`
  width: 375px;

  .CalendarDay {
    box-sizing: content-box;
    padding: 0;
    width: 44px !important;
    height: 44px !important;

    border: 2px solid ${(props) => props.theme.main.colors.white};
    border-radius: 5px;
    color: ${(props) => props.theme.main.colors.clay1};
    font-weight: 500;
    font-size: 20px;
  }

  .CalendarDay__today:not(.CalendarDay__outside) {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.main.colors.clay1};
    }
  }

  .DayPicker {
    width: 375px !important;

    .DayPicker_weekHeader,
    .CalendarMonth {
      padding: 0 20px !important;
    }

    .DayPicker_weekHeader {
      padding-top: 96px !important;

      .DayPicker_weekHeader_li small {
        font-size: inherit;
      }
    }

    > div > div,
    .DayPicker_transitionContainer {
      width: 375px !important;
    }

    .DayPickerNavigation_button {
      svg > path {
        stroke: ${(props) => props.theme.main.colors.clay2};
      }
      &:hover {
        svg > path {
          stroke: ${(props) => props.theme.main.colors.white};
          &.circle {
            fill: ${(props) => props.theme.main.colors.clay3};
            stroke: ${(props) => props.theme.main.colors.clay3};
          }
        }
      }
      .navigate-left {
        position: absolute;
        top: 90px;
        left: 20px;
      }

      .navigate-right {
        position: absolute;
        top: 90px;
        right: 20px;
      }
    }

    .DayPicker_transitionContainer {
      height: ${(props) => props.containerHeight + 'px'} !important;
      padding-top: 36px !important;

      @media (min-width: ${RESOLUTIONS.medium}px) {
        padding-top: 52px !important;
      }
    }

    .CalendarMonth_caption {
      padding-top: 36px;
    }
  }

  .month-header {
    flex-direction: column;
    align-items: center;

    .change-year {
      position: absolute;
      top: -28px;
      width: 142px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .CalendarMonth_table {
    margin-top: 44px;
  }
`;

const RangeWrapper = styled(HardElevation)`
  width: 842px;

  .CalendarDay {
    box-sizing: content-box;
    padding: 0;
    width: 44px !important;
    height: 44px !important;

    border: 2px solid ${(props) => props.theme.main.colors.white};
    border-radius: 5px;
    color: ${(props) => props.theme.main.colors.clay1};
    font-weight: 500;
    font-size: 20px;
  }

  td.CalendarDay__today:not(.CalendarDay__outside) {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.main.colors.clay1};
    }
  }

  .DayPicker {
    border-radius: 24px;

    .CalendarMonth_caption {
      margin-top: 70px;
      padding-bottom: 76px;
    }

    .DayPicker_weekHeader {
      margin-top: 110px;
    }

    .DayPicker_weekHeaders {
      .DayPicker_weekHeader:last-child {
        padding-left: 156px !important;
      }
    }

    .DayPicker_weekHeader,
    .CalendarMonth {
      padding: 0 44px !important;
    }

    > div > div,
    .DayPicker_transitionContainer {
      width: 842px !important;
    }

    .DayPickerNavigation_button {
      svg > path {
        stroke: ${(props) => props.theme.main.colors.clay2};
      }
      &:hover {
        svg > path {
          stroke: ${(props) => props.theme.main.colors.white};
          &.circle {
            fill: ${(props) => props.theme.main.colors.clay3};
            stroke: ${(props) => props.theme.main.colors.clay3};
          }
        }
      }

      .navigate-left {
        position: absolute;
        top: 93px;
        left: 55px;
      }

      .navigate-right {
        position: absolute;
        top: 93px;
        right: 55px;
      }
    }

    .DayPicker_transitionContainer {
      border-radius: 24px;
      height: 496px !important;
    }

    .change-year {
      position: absolute;
      left: 342px;
      top: 32px;
      width: 142px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

const SingleMonthRangeWrapper = styled(HardElevation)`
  width: 423px;

  .CalendarDay {
    box-sizing: content-box;
    padding: 0;
    width: 44px !important;
    height: 44px !important;

    border: 4px solid ${(props) => props.theme.main.colors.white};
    border-radius: 5px;
    color: ${(props) => props.theme.main.colors.clay1};
    font-weight: 500;
    font-size: 20px;
  }

  .CalendarDay__today:not(.CalendarDay__outside) {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.main.colors.clay1};
    }
  }

  .DayPicker {
    .CalendarMonth_caption {
      margin-top: 70px;
      padding-bottom: 76px;
    }

    .DayPicker_weekHeader {
      margin-top: 100px;
    }
    .DayPicker_weekHeader_li {
      margin-right: 1px;
    }

    .DayPicker_weekHeader_ul {
      margin-left: 5px;
      text-transform: uppercase;
    }

    .DayPicker_weekHeader,
    .CalendarMonth {
      padding: 0 31px !important;
    }

    > div > div,
    .DayPicker_transitionContainer {
      width: 423px !important;
    }

    .DayPickerNavigation_button {
      svg > path {
        stroke: ${(props) => props.theme.main.colors.clay2};
      }
      &:hover {
        svg > path {
          stroke: ${(props) => props.theme.main.colors.white};
          &.circle {
            fill: ${(props) => props.theme.main.colors.clay3};
            stroke: ${(props) => props.theme.main.colors.clay3};
          }
        }
      }
      .navigate-left {
        position: absolute;
        top: 93px;
        left: 45px;
      }

      .navigate-right {
        position: absolute;
        top: 93px;
        right: 45px;
      }
    }

    .DayPicker_transitionContainer {
      border-radius: 24px;
      height: 496px !important;
    }
    .change-year {
      position: absolute;
      left: 130px;
      top: 32px;
      width: 142px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export class Calendar extends React.Component<Props, State> {
  static defaultProps = {
    startDate: null,
    endDate: null,
  };

  state: State = {
    focus: 'startDate',
    focused: true,
    calendarHeight: 450,
  };

  componentDidMount() {
    this.changeMonthHandler(this.props.minDate || moment());
  }

  onChangeFocus = (focus: 'startDate' | 'endDate') => {
    this.setState({ focus });
  };

  isDayBlocked = (day: Moment): boolean => {
    const { minDate, maxDate } = this.props;

    if (!maxDate && !!minDate) return day.isSameOrBefore(minDate);
    if (!minDate && !!maxDate) return day.isSameOrAfter(maxDate);

    if (!!minDate && !!maxDate) {
      return !day.isBetween(minDate, maxDate, 'day', '[]');
    }

    return false;
  };

  handleChange = (value: { startDate: Moment | null; endDate: Moment | null }) => {
    const { onChange, startDate, endDate } = this.props;

    if (startDate && endDate) {
      onChange({ startDate: value.startDate || value.endDate, endDate: null });
      return;
    }

    if (value.startDate && value.endDate) {
      this.setState({ focus: 'startDate' });
    }

    onChange(value);
  };

  handleSingleChange = (value: Moment | null) => {
    const { date, onChange } = this.props;

    if (date) {
      onChange(value);
      return;
    }

    if (value) {
      this.setState({ focused: true });
    }

    onChange(value);
  };

  changeMonthHandler = (date: moment.Moment) => {
    const height = CALENDAR_CELL_HEIGHT * getNumberOfCalendarMonthWeeks(date, 1) + REST_CALENDAR_CONTENT_HEIGHT;
    this.setState({ calendarHeight: height });
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect, isVisible }) => (
    <div className='month-header' style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <Utility variant='u1' weight='medium'>
          {month.format('MMMM')}
        </Utility>
      </div>
      <YearContainer className='change-year'>
        <Svg
          className='navigation-button'
          onClick={() => {
            const currentYear = month.year();
            onYearSelect(month, currentYear - 1);
            this.changeMonthHandler(month.year(currentYear - 1));
          }}
          name='NavigateLeft'
          size={24}
        />
        <Utility variant='u1' weight='medium'>
          {month.year()}
        </Utility>
        <Svg
          className='navigation-button'
          onClick={() => {
            const currentYear = month.year();
            onYearSelect(month, currentYear + 1);
            this.changeMonthHandler(month.year(currentYear + 1));
          }}
          name='NavigateRight'
          size={24}
        />
      </YearContainer>
    </div>
  );

  render() {
    const { startDate, endDate, date, isSingleDate, isSingleMonthRange } = this.props;

    if (isSingleMonthRange) {
      return (
        <SingleMonthRangeWrapper level={4}>
          <DayPickerRangeController
            startDate={(startDate as any) || null}
            endDate={(endDate as any) || null}
            onDatesChange={this.handleChange as AnyFunction}
            focusedInput={this.state.focus}
            onFocusChange={this.onChangeFocus}
            isDayBlocked={this.isDayBlocked as AnyFunction}
            initialVisibleMonth={null}
            numberOfMonths={1}
            minimumNights={0}
            keepOpenOnDateSelect={true}
            noBorder
            hideKeyboardShortcutsPanel
            enableOutsideDays
            renderMonthElement={this.renderMonthElement}
            navPrev={<Svg className='navigate-left' name='NavigateLeft' size={24} />}
            navNext={<Svg className='navigate-right' name='NavigateRight' size={24} />}
            transitionDuration={0}
            firstDayOfWeek={1}
          />
        </SingleMonthRangeWrapper>
      );
    }

    return isSingleDate ? (
      <SingleWrapper level={4} containerHeight={this.state.calendarHeight}>
        <DayPickerSingleDateController
          date={(date as any) || null}
          onDateChange={this.handleSingleChange as AnyFunction}
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          isDayBlocked={this.isDayBlocked as AnyFunction}
          noBorder
          numberOfMonths={1}
          initialVisibleMonth={null}
          hideKeyboardShortcutsPanel
          enableOutsideDays
          renderMonthElement={this.renderMonthElement}
          navPrev={<Svg className='navigate-left' name='NavigateLeft' size={24} />}
          navNext={<Svg className='navigate-right' name='NavigateRight' size={24} />}
          onNextMonthClick={this.changeMonthHandler}
          onPrevMonthClick={this.changeMonthHandler}
          firstDayOfWeek={1}
        />
      </SingleWrapper>
    ) : (
      <RangeWrapper level={4}>
        <DayPickerRangeController
          startDate={(startDate as any) || null}
          endDate={(endDate as any) || null}
          onDatesChange={this.handleChange as AnyFunction}
          focusedInput={this.state.focus}
          onFocusChange={this.onChangeFocus}
          isDayBlocked={this.isDayBlocked as AnyFunction}
          initialVisibleMonth={null}
          numberOfMonths={2}
          minimumNights={0}
          keepOpenOnDateSelect={true}
          noBorder
          hideKeyboardShortcutsPanel
          enableOutsideDays
          renderMonthElement={this.renderMonthElement}
          navPrev={<Svg className='navigate-left' name='NavigateLeft' size={24} />}
          navNext={<Svg className='navigate-right' name='NavigateRight' size={24} />}
          transitionDuration={0}
          firstDayOfWeek={1}
        />
      </RangeWrapper>
    );
  }
}
