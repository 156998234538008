import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { RESOLUTIONS } from 'src/constants';
import { DefaultFlag } from 'src/styledComponents/Flag';
import { Utility } from 'src/styledComponents/Typography';
import { Truncate } from 'src/styles';

import { findCountryCode } from '../../utils/shared';

type Props = {
  value: RacersType;
  onOpen: (racerId: number, distanceId: number) => (e: React.MouseEvent<HTMLSpanElement>) => void;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  .flag {
    margin-right: 12px;
    min-width: 21px;
    height: 15px;
    border-radius: 2px;
  }

  &:hover {
    cursor: pointer;
  }

  .truncate-wrap {
    flex: 1;
    min-width: 0;
  }

  .racer-name-value {
    display: block;
    ${Truncate}
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    .flag {
      margin-right: 16px;
    }
  }
`;

function RacerNameComponent({ value, onOpen }: Props) {
  const getCountryCode = () => {
    if (value.nationality_id) {
      return findCountryCode(value.nationality_id);
    }

    if (value.country_id) {
      return findCountryCode(value.country_id);
    }

    return '';
  };

  const countryCode = getCountryCode();
  const countryClassName = countryCode ? `flag-icon-${countryCode}` : '';

  return (
    <Wrapper>
      <Show if={Boolean(countryCode)}>
        <span className={`flag ${countryClassName}`} />
      </Show>
      <Show if={!countryCode}>
        <DefaultFlag className='flag' />
      </Show>
      <div className='truncate-wrap'>
        <Utility
          variant='u2'
          weight='bold'
          className='racer-name-value'
          onClick={onOpen(value.id, value.distance_id)}
          {...testAnchors.useLink(`racer`)}
        >
          {value.full_name}
        </Utility>
      </div>
    </Wrapper>
  );
}

const RacerName = React.memo<Props>(RacerNameComponent);

export { RacerName };
