import { Observer } from 'mobx-react';
import { toggleRacesAutocomplete } from 'modules/search/actions';
import { focusedStore, racesAutocompleteOpenStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import * as React from 'react';
import styled from 'styled-components';

import { SmoothElevation } from 'src/styledComponents/Elevation';

import { useOnClickOutside } from 'utils';

import { Races } from './Races';
import { RacesOptionsLoader } from './RacesOptionsLoader';

const Container = styled(SmoothElevation)<{ open: boolean }>`
  position: absolute;
  top: calc(100% + 16px);
  left: 0;
  right: 0;
  z-index: 10;
  overflow-y: auto;

  width: 378px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 40px;
  background-color: white;

  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const RacesMenu: React.FC<{ isRecentValues: boolean; isRecomendedValues: boolean }> = ({ isRecentValues, isRecomendedValues }) => {
  const ref = React.useRef(null);
  useOnClickOutside(
    ref,
    () => {
      if (!racesAutocompleteOpenStore.value) return;
      toggleRacesAutocomplete(false);
    },
    racesAutocompleteOpenStore.value,
  );

  return (
    <Observer>
      {() => {
        const isOpen = Boolean(racesAutocompleteOpenStore.value) && focusedStore.value === FieldNames.raceName;
        return (
          <Container ref={ref} level={5} open={isOpen}>
            <RacesOptionsLoader isRecentValues={isRecentValues} isRecomendedValues={isRecomendedValues}>
              <Races isRecentValues={isRecentValues} isRecomendedValues={isRecomendedValues} />
            </RacesOptionsLoader>
          </Container>
        );
      }}
    </Observer>
  );
};
