import styled from 'styled-components';

export const RoutesWrapper = styled.div`
  flex: 1 0 auto;
`;

export const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
  .toast-miles {
    background: none;
    padding: 0;
    color: ${(props) => props.theme.miles.colors.dark.alpha[800]};
  }
`;
