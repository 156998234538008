import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { actionMenuStore } from '../../stores';

export const isRegistrationTransfer: IComputedValue<boolean> = computed(() => {
  const distance = actionMenuStore.distanceModel as any;

  return distance && distance.value.registrationTransfer && distance.value.transfer_registration?.available;
});
