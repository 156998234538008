import { compact } from 'lodash';
import * as React from 'react';

const TestAnchorContext = React.createContext<{ container: TestAnchorContainers }>({ container: 'Root' });

const testAttributes = {
  id: 'data-test-id',
} as const;

const elementTypes = {
  button: 'buttons',
  link: 'links',
  field: 'fields',
} as const;

function generateId(...args: Array<string | nil | number>): string {
  const containerId = React.useContext(TestAnchorContext).container;
  return compact([containerId, ...args]).join('.');
}

function generateIdWithoutContext(prefix: string, ...args: Array<string | nil | number>): string {
  return compact([prefix, ...args]).join('.');
}

function generateGeneralProps(id: string) {
  return {
    [testAttributes.id]: id,
  };
}

const testAnchors = {
  useButton: (why?: string) => {
    const id = generateId(elementTypes.button, why);
    return generateGeneralProps(id);
  },

  useField: (name: string, ...locators: Array<string | number | nil>) => {
    const id = generateId(elementTypes.field, name, ...locators);
    return generateGeneralProps(id);
  },

  useLink: (where?: string) => {
    const id = generateId(elementTypes.link, where);
    return generateGeneralProps(id);
  },

  button: (prefix: string, why?: string, ...locators: string[]) => {
    const id = generateIdWithoutContext(prefix, elementTypes.button, why, ...locators);
    return generateGeneralProps(id);
  },

  field: (prefix: string, name: string, ...locators: Array<string | number | nil>) => {
    const id = generateIdWithoutContext(prefix, elementTypes.field, name, ...locators);
    return generateGeneralProps(id);
  },

  link: (prefix: string, where?: string) => {
    const id = generateIdWithoutContext(prefix, elementTypes.link, where);
    return generateGeneralProps(id);
  },
};

const withTestAnchorContext =
  (container: TestAnchorContainers) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<any> {
      render() {
        return (
          <TestAnchorContext.Provider value={{ container }}>
            <WrappedComponent {...(this.props as any)} />
          </TestAnchorContext.Provider>
        );
      }
    } as any;

const TEST_ANCHORS = {
  fieldStructure: {
    root: 'root',
    value: 'value', // in case of checkbox
    error: 'error',
    title: 'title',
    additional: 'additional',
    selectItem: 'selectItem',
    fieldPart: 'fieldPart',
    button: 'button',
    container: 'container',
  },

  noname: 'noname',
} as const;

type TestAnchorContextType = typeof TestAnchorContext;
type testAnchorsType = typeof testAnchors;
type testAnchorsConstsType = typeof TEST_ANCHORS;
type withTestAnchorContextType = typeof withTestAnchorContext;

export {
  TestAnchorContext,
  TestAnchorContextType,
  testAnchors,
  testAnchorsType,
  TEST_ANCHORS,
  testAnchorsConstsType,
  withTestAnchorContext,
  withTestAnchorContextType,
};
