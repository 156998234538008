import styled from 'styled-components';

type Props = {
  size?: number;
  img: string;
};

export const Avatar = styled.div<Props>`
  border-radius: 1px;
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
  background-position: center;
  background-size: cover;
  background-image: url('${(props) => props.img}');
`;
