import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const MessagePath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12.0006 1C5.92543 1 1.00056 5.92487 1.00056 12C1.00056 14.3923 1.76526 16.6081 3.06282 18.4135L0.196289 22.9997L12.0005 23C18.0757 23 23.0006 18.0751 23.0006 12C23.0006 5.92487 18.0757 1 12.0006 1ZM17.0005 10.5H7.00049V8.5H17.0005V10.5ZM7.00049 15.5H14.0005V13.5H7.00049V15.5Z'
    fill='currentColor'
    fillOpacity='0.85'
  />
);

export const Message = (props: IconProps) => <SvgIcon path={MessagePath} {...props} />;
