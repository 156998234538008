import { computed } from 'mobx';
import { locationStore, recentLocationStore } from 'modules/search/stores';

import { HEADER_FORM } from 'src/constants';
import { form } from 'src/stores';

import { latinizeLocation, mapFilters } from '../utils';

export const racesFilters = computed(() => {
  const date = form.fetch(HEADER_FORM, 'date');
  const distance = form.fetch(HEADER_FORM, 'distance');
  const sports = form.fetch(HEADER_FORM, 'sports');

  return [date, distance, sports];
});

export const requestFilters = computed((): AnyObject => {
  const formValues = form.fetchWithoutObservable<AnyObject>(HEADER_FORM) || {};
  return mapFilters(formValues);
});

export const latinizeFilters = async () => {
  const filters = requestFilters.get();
  const locationNormal = locationStore.value?.find((el) => el.city === filters.location);
  const locationRecent = recentLocationStore.value?.find((el) => el.city === filters.location);
  const latinizeFilters = location && (locationNormal || locationRecent) ? await latinizeLocation(filters) : filters;
  return latinizeFilters;
};
