import { ZONE_COLORS } from '../constants';

export const getZones = (heartRates: number[]) => {
  if (heartRates.length < 2) return [];
  const zonesWidth: Array<number> = [];

  // Calculate the largest heart rate zone
  // let totalWidth = 0;
  // for (let i = 1; i < heartRates.length; i++) {
  //   totalWidth = Math.max(totalWidth, Math.abs(heartRates[i] - heartRates[i - 1]));
  // }

  const totalWidth = heartRates[heartRates.length - 1] - heartRates[0];

  heartRates.forEach((hr, idx) => {
    if (idx === heartRates.length - 1) return;
    const hrWidth = heartRates[idx + 1] - hr;
    const percentWidth = (100 / totalWidth) * hrWidth;
    zonesWidth.push(percentWidth);
  });

  const marginLefts = [0];
  for (let i = 0; i < zonesWidth.length - 1; i++) {
    marginLefts.push(marginLefts[i] + zonesWidth[i]);
  }

  return zonesWidth.map((percentWidth, idx) => {
    return {
      percentWidth,
      marginLeft: marginLefts[idx],
      color: ZONE_COLORS[idx],
      text: String(idx + 1),
    };
  });
};
