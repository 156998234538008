import { computed } from 'mobx';

import { priceUtils } from '../../utils';

import { serviceFeeStore } from '../../stores';

import { formDataSelectors } from '../formDataSelectors';
import { mainConditions } from '../mainConditions';
import { _groupSignUpMembersTotalWithoutDiscount } from './_groupSignUpMembersTotal';
import { _leaderCustomFieldsTotalWithoutDiscount } from './_leaderCustomFieldsTotal';
import { _leaderTotalWithoutDiscount, _leaderSubTotalWithoutDiscount } from './_leaderTotal';
import { _teamSignUpMembersTotalWithoutDiscount } from './_teamSignUpMembersTotal';
import { distancePrice as distancePriceSelector } from './distancePrice';
import { refundProtectPrice } from './refundProtectPrice';

/**
 * @description
 * subtotal price for the registration
 * excluding coupon
 */
const subtotalPrice = computed((): number => {
  const calculateWithRefund = (resultPrice) => {
    const refundProtectFormValue = formDataSelectors.fetchLeaderRefundProtect();

    const { serviceFee } = serviceFeeStore;

    if (serviceFee) {
      resultPrice += serviceFee / 100;
    }

    if (refundProtectFormValue) {
      resultPrice += refundProtectPrice.get();
    }

    return priceUtils.round(resultPrice);
  };

  if (mainConditions.isPageRegistrationTeamWithCustomFields.get() || mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
    let resultPrice = priceUtils.round(
      priceUtils.calculateMemberPriceWithDiscount({
        distancePrice: distancePriceSelector.get(),
        coupon: null,
        customFieldTotalPrice: _leaderTotalWithoutDiscount.get() + _teamSignUpMembersTotalWithoutDiscount.get(),
      }),
    );

    return calculateWithRefund(resultPrice);
  }

  if (mainConditions.isPageRegistrationSingleWithCustomFields.get() || mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
    let resultPrice = priceUtils.round(_groupSignUpMembersTotalWithoutDiscount.get());

    return calculateWithRefund(resultPrice);
  }

  if (mainConditions.isPageConfirmationWithCustomFields.get() || mainConditions.isPageConfirmationWithoutCustomFields.get()) {
    let resultPrice;

    if (mainConditions.isPushPayment.get()) {
      resultPrice = priceUtils.round(_leaderSubTotalWithoutDiscount.get());
    } else {
      resultPrice = priceUtils.round(_leaderCustomFieldsTotalWithoutDiscount.get());
    }

    return calculateWithRefund(resultPrice);
  }

  if (mainConditions.isTransferredRegistration.get()) {
    const resultPrice = priceUtils.round(_leaderSubTotalWithoutDiscount.get());

    return calculateWithRefund(resultPrice);
  }

  return 0;
});

export { subtotalPrice };
