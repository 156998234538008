import { pick, omit } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

class Filters<T extends Object> {
  @observable value: T | AnyObject = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setValue(value: T) {
    this.value = value;
  }

  @action
  partialSetValue(value: Partial<T>) {
    this.value = { ...this.value, ...value };
  }

  @action
  clean() {
    this.value = {};
  }

  fetchFilters(...keys: string[]): AnyObject {
    return pick(this.value, keys);
  }

  @computed
  get allExceptDistance() {
    return omit(this.value, 'distance_id');
  }
}

export { Filters, Filters as FiltersStore };
