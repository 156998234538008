import { computed } from 'mobx';

import { priceUtils } from '../../utils';

import { serviceFeeStore } from '../../stores';

import { formDataSelectors } from '../formDataSelectors';
import { mainConditions } from '../mainConditions';
import { distanceSelector } from '../mappedData';
import { _groupSignUpMembersTotal } from './_groupSignUpMembersTotal';
import { _leaderCustomFieldsTotal } from './_leaderCustomFieldsTotal';
import { _leaderTotal, _leaderTotalWithoutDiscount } from './_leaderTotal';
import { _teamSignUpMembersTotalWithoutDiscount } from './_teamSignUpMembersTotal';
import { distancePrice as distancePriceSelector } from './distancePrice';

const refundProtectPrice = computed(() => {
  const refundProtectPercent = distanceSelector.refundProtect.get();

  const { serviceFee } = serviceFeeStore;

  const calculateRefundPrice = (price: number) => {
    if (serviceFee) {
      price += serviceFee / 100;
    }

    return priceUtils.round((price * refundProtectPercent) / 100);
  };

  if (mainConditions.isPageRegistrationTeamWithCustomFields.get() || mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
    let resultPrice = priceUtils.round(
      priceUtils.calculateMemberPriceWithDiscount({
        distancePrice: distancePriceSelector.get(),
        coupon: formDataSelectors.fetchLeaderCoupon(),
        customFieldTotalPrice: _leaderTotalWithoutDiscount.get() + _teamSignUpMembersTotalWithoutDiscount.get(),
      }),
    );

    return calculateRefundPrice(resultPrice);
  }

  if (mainConditions.isPageRegistrationSingleWithCustomFields.get() || mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
    let resultPrice = priceUtils.round(_groupSignUpMembersTotal.get());

    return calculateRefundPrice(resultPrice);
  }

  if (mainConditions.isPageConfirmationWithCustomFields.get() || mainConditions.isPageConfirmationWithoutCustomFields.get()) {
    let resultPrice;

    if (mainConditions.isPushPayment.get()) {
      resultPrice = priceUtils.round(_leaderTotal.get());
    } else {
      resultPrice = priceUtils.round(_leaderCustomFieldsTotal.get());
    }

    return calculateRefundPrice(resultPrice);
  }

  return 0;
});

export { refundProtectPrice };
