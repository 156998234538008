import * as React from 'react';
import styled from 'styled-components';

import { DistanceButtons, DistanceTopArea, DistanceBottomArea, DistanceInfoLink } from 'src/modules/race/components/register';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';

import { raceUtil } from 'utils';

import { Distance } from 'models/distance';

const StyledDistanceTable = styled.section`
  padding: 16px 0 120px;
`;

const DistanceRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 37px;
  margin-bottom: 37px;
`;

const DistanceRowMobile = styled.div`
  align-items: center;

  justify-content: space-between;
  border-bottom: 1px solid #c3c6c8;
  padding-bottom: 24px;
  margin-bottom: 24px;
`;

const DistanceInfo = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

const DistanceInfoMobile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const LinkContainer = styled.div`
  margin-top: 20px;
`;

type Props = {
  distances: Array<DistanceType>;
  onHandleClick: Function;
  allowRegistration: boolean;
  currency: string;
};

const RaceRegisterTable = (props: Props): React.ReactNode => {
  const { distances, onHandleClick, allowRegistration, currency } = props;
  const sortedDistances = distances && distances.length > 0 ? distances.sort((a, b) => a.index - b.index) : [];
  const distancesModel = (sortedDistances || []).map((distance) => new Distance(distance));

  return (
    <>
      <StyledDistanceTable className='distances'>
        {distancesModel.map((distanceModel, index) => {
          const { value } = distanceModel;
          const raceLength = raceUtil.humanizeDistance(distanceModel.value.race_length);

          const distanceTopAreaProps = {
            raceLength: raceLength,
            raceLengthMobile: raceLength.split(' '),
            raceName: distanceModel.value.name,
          };

          const distanceBottomAreaProps = {
            raceStarts: value.race_date,
            raceEnds: value.ends_at,
            currency: currency,
            racersCount: value.type === 'team' ? value.teams_count : value.racers_count,
            racersMax: value.race_qty,
            price: value.currentPrice?.value,
          };

          const distanceButtonsProps = {
            model: distanceModel,
            onSignUp: onHandleClick,
            allowRegistration: allowRegistration,
          };

          const wrapperProps = {
            key: 'distance_row_key' + index,
            id: value.id as number | any,
          };

          return (
            <div {...wrapperProps}>
              <ShowDesktop>
                <DistanceRow>
                  <DistanceInfo>
                    <DistanceTopArea {...distanceTopAreaProps} />
                    <DistanceBottomArea {...distanceBottomAreaProps} />
                    <DistanceInfoLink distanceInfo={value} />
                  </DistanceInfo>
                  <DistanceButtons {...distanceButtonsProps} />
                </DistanceRow>
              </ShowDesktop>

              <ShowMobile>
                <DistanceRowMobile>
                  <DistanceInfoMobile>
                    <DistanceTopArea {...distanceTopAreaProps} />
                    <DistanceButtons {...distanceButtonsProps} />
                  </DistanceInfoMobile>
                  <DistanceBottomArea {...distanceBottomAreaProps} />
                  <LinkContainer>
                    <DistanceInfoLink distanceInfo={value} />
                  </LinkContainer>
                </DistanceRowMobile>
              </ShowMobile>
            </div>
          );
        })}
      </StyledDistanceTable>
    </>
  );
};

export { RaceRegisterTable };
