import { makeAutoObservable } from 'mobx';

class HubSpotStore {
  showAt: string[] = ['myProfile', 'signUp', 'signIn', 'registration', 'forgotPassword', 'reset-password'];
  constructor() {
    makeAutoObservable(this);
  }

  toggleWidget() {
    if (!window.HubSpotConversations) return;
    const { loaded, pending } = window.HubSpotConversations.widget.status();
    const shouldLoad = this.showAt.some((url) => window.location.pathname.includes(url));
    if (shouldLoad && !loaded && !pending) {
      return this.loadWidget();
    } else if (!shouldLoad && loaded) {
      return this.closeWidget();
    }
  }

  loadWidget() {
    window.HubSpotConversations.widget.load();
  }

  closeWidget() {
    window.HubSpotConversations.widget.remove();
  }
}

const hubSpotStore = new HubSpotStore();

export { hubSpotStore };
