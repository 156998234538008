import { transaction } from 'mobx';

import { actionMenuStore, resultStore } from '../../stores';

import { actionMenu } from '../../selections';
import type { RegisteredDistanceType } from '../../types';
import { open, close } from './display';

async function switchOn(distance: RegisteredDistanceType) {
  if (!distance) {
    return;
  }

  transaction(() => {
    actionMenuStore.clear();
    actionMenuStore.setDistance(distance);
  });
  open();

  const chosenDistance = resultStore.allResults.find((dist) => dist.id === distance.id);
  const result = chosenDistance?.racers[0].result;

  transaction(() => {
    if (result) {
      actionMenuStore.setResult(result);
    }
  });
}

function toggleSwitchOn(distance: RegisteredDistanceType) {
  if (actionMenu.isModalOpen.get() || actionMenu.isAnyMenuOpen.get()) {
    return close();
  }

  switchOn(distance);
}

export { switchOn, toggleSwitchOn };
