import * as React from 'react';
import styled, { css } from 'styled-components';

import { Divider } from 'src/styledComponents/Divider';

type Props = {
  isOpen: boolean;
};

const StyledDivider = styled(Divider)`
  background: ${(props) => props.theme.main.colors.clay4};
  margin: 8px 0;
`;

export const FiltersStyledWrap = styled.div<Props>`
  display: ${(props) => (props.isOpen ? 'initial' : 'none')};

  .select-block-mobile {
    height: 100vh;
    .select-block-mobile-header {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px;
      border-bottom: 2px solid ${(props) => props.theme.main.colors.clay4};

      .clear-filters {
        text-decoration: none;
        border-bottom: 1px solid ${(props) => props.theme.main.colors.clay1};
      }
    }

    .select-block-mobile-body {
      flex: 1 1 auto;
      position: relative;
      overflow-y: auto;
      padding-top: 16px;
      padding-bottom: 138px;
      .select-block-mobile-item {
        padding: 24px 12px 0;

        & > span {
          padding-left: 8px;
          color: ${(props) => props.theme.main.colors.clay3};
        }

        label {
          justify-content: space-between;
          padding: 16px 8px;
          font-size: 16px;
          line-height: 22px;
        }

        ${StyledDivider} {
          margin-bottom: 32px;
        }
      }
    }
    .select-block-mobile-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: ${(props) => props.theme.main.colors.white};
      .mobile-button {
        padding: 33px 0;
      }
    }

    ${(props) =>
      props.isOpen &&
      css`
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background: ${(props) => props.theme.main.colors.white};

        bottom: 0;
        overflow: auto;
      `}
  }
`;
