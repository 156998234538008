import React from 'react';

import { SearchDesktopView } from '../components';

import { mount, unmount } from '../actions';

type Props = {
  isTablet?: boolean;
};

export const DesktopSearch: React.FC<Props> = ({ isTablet }) => {
  React.useEffect(() => {
    mount();
    return unmount;
  }, []);

  return <SearchDesktopView isTablet={isTablet} />;
};
