import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { action, computed, observable, makeObservable } from 'mobx';

class Result {
  @observable isOpenEditResult: boolean = false;
  @observable racerId: number | null = null;
  defaultResult: Object = {};
  selectedDistance: AnyObject = {};

  @observable allResults: Array<AnyObject> = [];

  @observable classic: Array<Object> = [];
  @observable classicMeta: Object | null = null;

  @observable virtual: Array<any> = [];
  @observable virtualMeta: Object | null = null;

  @observable cumulative: Array<any> = [];
  @observable cumulativeMeta: Object | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isEdit(): boolean {
    return !isEmpty(this.defaultResult);
  }

  @action
  toggleEditResult(value?: boolean): void {
    if (isNil(value)) {
      this.isOpenEditResult = !this.isOpenEditResult;
      return;
    }

    this.isOpenEditResult = value;
  }

  @action
  setRacerId(id: number | null): void {
    this.racerId = id;
  }

  @action
  setIsEdit(value: Object): void {
    this.defaultResult = value;
  }

  @action
  setSelected(distance: Object): void {
    this.selectedDistance = distance;
  }

  @action setAllResults(data: Array<AnyObject>) {
    this.allResults = data;
  }

  @action
  setClassic(data: Array<Object> = [], meta: Object): void {
    this.classicMeta = meta;
    this.classic = data;
  }

  @action
  setVirtual(data: Array<Object> = [], meta: Object): void {
    this.virtualMeta = meta;
    this.virtual = data;
  }

  @action
  setCumulative(data: Array<Object> = [], meta: Object): void {
    this.cumulativeMeta = meta;
    this.cumulative = data;
  }

  @action
  clear(): void {
    this.classic = [];
    this.classicMeta = null;

    this.virtual = [];
    this.virtualMeta = null;

    this.cumulative = [];
    this.cumulativeMeta = null;
  }

  @action
  clearForm(): void {
    this.isOpenEditResult = false;
    this.racerId = null;
    this.defaultResult = {};
    this.selectedDistance = {};
  }
}

const resultStore = new Result();
export { resultStore };
