import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { isReceipt as isThereReceipt } from '../../utils';

import { actionMenuStore } from '../../stores';

const isReceipt: IComputedValue<boolean> = computed(() => isThereReceipt(actionMenuStore.distance));

export { isReceipt };
