import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_RACE_RESULTS_COUNTER } from 'src/constants';

import { action, request } from 'utils';

class CounterService {
  @request({ action: shortid() })
  request(raceId: number, params: AnyObject): any {
    const url = generatePath(API_URL + API_RACE_RESULTS_COUNTER, { id: raceId });
    return axios.get(url, { params });
  }

  async load(raceId: number, params: AnyObject): Promise<number | nil> {
    const [isOk, response] = await this.request(raceId, params);

    if (isOk) {
      return response.data.count;
    }

    return null;
  }
}

export { CounterService };
