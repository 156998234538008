import { isNil } from 'lodash';

/*
 * Add spaces between digits
 */
function integer(value: number | null): string {
  if (isNil(value)) {
    return '';
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export { integer };
