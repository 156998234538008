import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { BodyText } from 'src/styledComponents/Typography';

import { Flag } from 'components/icons';

type Props = {
  message: string | React.ReactNode;
  withoutFlag?: boolean;
  className?: string;
};

const Wrapper = styled(BodyText)`
  color: ${(props) => props.theme.main.colors.clay3};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding-left: 8px;
  }
`;

const NoResultsComponent = ({ message, withoutFlag, className }: Props) => {
  return (
    <Wrapper variant='body1' className={classNames('no-results', className)}>
      {!withoutFlag && Flag}
      <span className='message'>{message}</span>
    </Wrapper>
  );
};

export { NoResultsComponent, NoResultsComponent as NoResults };
