import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

import { t } from 'utils';

import { Distance } from 'models';

import { getDistanceUrl } from '../../actions';

import { classicStore } from '../../stores';

import { VirtualDistanceMapped } from '../../types';

type Props = {};

@observer
export class MobileList extends React.Component<Props> {
  getResult = (distance: VirtualDistanceMapped = {} as VirtualDistanceMapped): AnyObject => {
    if (distance.type === 'team') {
      return (distance.teams || [])[0]?.result || {};
    }

    return (distance.racers || [])[0]?.result || {};
  };

  render(): Array<React.ReactNode> {
    return classicStore.list.map((distance: VirtualDistanceMapped) => {
      const distanceModel = new Distance(distance as any);
      const result = this.getResult(distance);

      const place = result.place || '- -';

      const borderLeftColor = distance.race.sport?.hex_code && `#${distance.race?.sport?.hex_code}`;
      const disableDistanceTitle = !distance.show_results || !distance.race.show_results;

      return (
        <div
          className='classic-distance-card'
          style={{
            borderLeftColor: borderLeftColor ? borderLeftColor : 'inherit',
          }}
          key={distance.id}
        >
          <div className='place'>{place}</div>
          <div className='info'>
            <p>
              <Link
                className={classNames('distance-title truncate', { disabled: disableDistanceTitle })}
                to={getDistanceUrl(distance.id, distance.race.id, distance.type)}
              >
                {t(() => distanceModel.value.name)}
              </Link>
            </p>
            <p>{distance.resultData.time_result}</p>
          </div>
        </div>
      );
    });
  }
}
