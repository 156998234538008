import { Observer } from 'mobx-react';
import * as React from 'react';

import { Distance } from 'src/models/distance';
import { RightSideHeader } from 'src/modules/racersTable/components';

import { generateIsClasses, generateIsUnion, generateIsWaves, generateIsSquads } from '../../derivations';

type Props = {
  distance: Distance;
};

export const RightHeader: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const isWaves = generateIsWaves(distanceId);
  const isClasses = generateIsClasses(distanceId);
  const isSquads = generateIsSquads(distanceId);
  const isUnion = generateIsUnion(distanceId);

  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isWaves.get(),
          class: isClasses.get(),
          club: isUnion.get(),
          squad: isSquads.get(),
        };

        return <RightSideHeader columnsActive={columnsActive} />;
      }}
    </Observer>
  );
};
