export const seo: { [key: string]: SeoDataItem } = {
  authContainer: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  raceContainer: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  fAQContainer: {
    title: undefined,
    translatedTitle: 'navigation.faqLink',
    description: undefined,
    translatedDescription: 'home.par1',
  },
  privacyPolicyContainer: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  homeContainer: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: 'home.par1',
  },
  articlesContainer: {
    title: undefined,
    translatedTitle: 'articles.articles',
    description: undefined,
    translatedDescription: 'home.par1',
  },
  virtualRacesBase: {
    title: undefined,
    translatedTitle: 'navigation.virtualRacesLink',
    description: undefined,
    translatedDescription: 'home.par1',
  },
  //Can't test
  afterPaymentContainer: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  organizer: {
    title: undefined,
    translatedTitle: 'navigation.organizerLink',
    description: undefined,
    translatedDescription: 'home.par1',
  },
  contactContainer: {
    title: undefined,
    translatedTitle: 'navigation.contactLink',
    description: undefined,
    translatedDescription: 'home.par1',
  },
  aboutContainer: {
    title: undefined,
    translatedTitle: 'about.navTitle',
    description: undefined,
    translatedDescription: 'home.par1',
  },
  termsContainer: {
    title: undefined,
    translatedTitle: 'navigation.termsAndConditionsLink',
    description: undefined,
    translatedDescription: undefined,
  },
  notFoundPage: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  errorPage: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  confirmProfile: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  publicFaq: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
  publicContact: {
    title: undefined,
    translatedTitle: 'navigation.contactLink',
    description: undefined,
    translatedDescription: undefined,
  },
  publicGroupedPages: {
    title: undefined,
    translatedTitle: undefined,
    description: undefined,
    translatedDescription: undefined,
  },
};
