import GoogleMap from 'google-map-react';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { MAP_SETTINGS, GOOGLE_MAP_URL } from 'src/constants';
import { localeStore } from 'src/stores/index';

import { appEnvControl } from 'utils';

const DEFAULT_CENTER_COORDINATES = {
  lat: 59.334591,
  lng: 18.06324,
};

type Props = {
  zoom: number;
  onChange: AnyFunction;
  onMount: AnyFunction;
  center?: { lat: number; lng: number };
  children?: React.ReactNode;
};

export const Map: React.FC<Props> = (props) => {
  const { children, zoom, onChange, onMount, center } = props;
  const key = GOOGLE_MAP_URL[appEnvControl.currentEnvWithDefaultDev()];
  return (
    <Observer>
      {() => {
        const language = localeStore.value;
        return (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={DEFAULT_CENTER_COORDINATES}
            bootstrapURLKeys={{ key, language }}
            zoom={zoom}
            center={center}
            onChange={onChange}
            onGoogleApiLoaded={onMount}
            yesIWantToUseGoogleMapApiInternals
            options={{
              styles: MAP_SETTINGS,
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              zoomControl: false,
              // @ts-ignore
              // It's just because the package doesn't have this option but exactly google maps have
              restriction: {
                latLngBounds: {
                  north: 85,
                  south: -85,
                  west: -180,
                  east: 180,
                },
                strictBounds: false,
              },
            }}
          >
            {children}
          </GoogleMap>
        );
      }}
    </Observer>
  );
};
