import { isString } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import onClickOutside from 'react-onclickoutside';

import { SELECT_DROP_MAX_HEIGHT } from 'src/constants';

import { Show } from 'components/condition';
import { CustomScrollBar } from 'components/core/customScrollBar';
import { CloseDropdown, DropArrow } from 'components/icons/';

import { isSelectDropUp } from 'utils';

import { InputWrapComponent } from '../inputWrap';
import { SelectListItem } from './SelectListItem';

type SelectListState = {
  listHeight: number;
  isOpened: boolean;
  isDropUp: boolean;
};

type Props = {
  id?: string;
  type: string;
  readOnly: boolean;
  disabled: boolean;
  label: string;
  requiredMark: boolean;
  errorMessage: string;
  value: AnyObject;
  name: string;
  placeholder: string;
  forceDropDirection?: 'up' | 'down';
  onChange: AnyFunction;
  clearable?: boolean;
} & SelectType;

@onClickOutside
@observer
class SelectList extends React.Component<Props, SelectListState> {
  outerHeight: number | any;

  dropdownRef: RefType<HTMLDivElement> | any = React.createRef<HTMLDivElement>();
  listRef: React.RefObject<HTMLUListElement> = React.createRef<HTMLUListElement>();

  static defaultProps = {
    type: 'text',
    disabled: false,
    readOnly: false,
    value: '',
  };

  state = {
    listHeight: 0,
    isOpened: false,
    isDropUp: false,
  };

  handleCloseDropList() {
    this.setState({
      listHeight: 0,
      isOpened: false,
    });
  }

  handleOpenDropList() {
    const { isOpened } = this.state;
    let listHeight = 0;

    this.outerHeight = this.getOuterHeight();

    let isDropUp = this.state.isDropUp;

    if (!isOpened) {
      listHeight = this.outerHeight;

      const dropRect = this.dropdownRef.current && this.dropdownRef.current.getBoundingClientRect();
      isDropUp = isSelectDropUp(dropRect, listHeight);
    }

    if (this.props.forceDropDirection === 'up') {
      isDropUp = true;
    }

    if (this.props.forceDropDirection === 'down') {
      isDropUp = false;
    }

    this.setState({
      listHeight,
      isOpened: !isOpened,
      isDropUp,
    });
  }

  handleClickOutside() {
    this.handleCloseDropList();
  }

  onSelect = (value: NewSelectItemType) => {
    const { onChange, name } = this.props;
    this.handleCloseDropList();

    onChange({ name, value });
  };

  handleDropDownListItem() {
    const { dropListItems, value, name } = this.props;
    const res: any = [];
    res.push(
      dropListItems.map((item: AnyObject, key: number) => {
        return (
          <SelectListItem
            item={item as NewSelectItemType}
            activeItem={value?.id}
            key={`${key}-${item.id}`}
            title={item.title}
            onSelect={this.onSelect}
            name={name}
          />
        );
      }),
    );

    return res;
  }

  onClickDropArrow() {
    const { isOpened } = this.state;
    return (
      <div className='dropdown-click-arrow' onClick={!isOpened ? this.handleOpenDropList.bind(this) : this.handleCloseDropList.bind(this)}>
        {DropArrow}
      </div>
    );
  }

  getCurrentItem = () => {
    const { value, dropListItems } = this.props;

    const currentItem = (dropListItems || []).find((item) => {
      if (item.id === value?.id) {
        return item;
      }
    });

    if (!currentItem) {
      return '';
    }

    if (isString(currentItem.title)) return currentItem.title;
    return renderToStaticMarkup(currentItem.title);
  };

  getOuterHeight() {
    if (!this.listRef.current) {
      return 0;
    }

    return this.listRef.current.getBoundingClientRect().height;
  }

  render() {
    const { placeholder, dropListItems, name, type, readOnly, disabled, label, requiredMark, errorMessage, additionalInfo, clearable } =
      this.props;

    const { listHeight, isDropUp, isOpened } = this.state;
    const hasItems = dropListItems && !!dropListItems.length;
    const isValuePresented = Boolean(this.getCurrentItem());

    const classNameForUl =
      name.includes('country_id') || name.includes('nationality_id') ? 'dropdown-list country-nationality-list' : 'dropdown-list';

    return (
      <InputWrapComponent
        disabled={disabled}
        name={name}
        label={label}
        additionalInfo={additionalInfo}
        requiredMark={requiredMark}
        errorMessage={errorMessage}
      >
        <div className='dropdown' ref={this.dropdownRef}>
          <div className='dropdown-wrap'>
            <input
              {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
              id={name}
              type={type}
              name={name}
              value={this.getCurrentItem()}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              className='dropdown-chosen'
              onChange={() => {}}
            />
          </div>
          <div className='dropdown-click-zone' onClick={this.handleOpenDropList.bind(this)} />

          <Show if={(hasItems && !isValuePresented) || !clearable}>
            <div onClick={!isOpened ? this.handleOpenDropList.bind(this) : this.handleCloseDropList.bind(this)}>{DropArrow}</div>
          </Show>

          <Show if={Boolean(isValuePresented && clearable)}>
            <div onClick={() => this.props.onChange({ name, value: null })}>{CloseDropdown}</div>
          </Show>

          {hasItems && (
            <div
              className={isDropUp ? 'dropdown-expand-upper' : 'dropdown-expand'}
              style={{
                height: `${listHeight}px`,
                maxHeight: SELECT_DROP_MAX_HEIGHT,
              }}
            >
              <CustomScrollBar>
                <ul className={classNameForUl} id={this.props.id} ref={this.listRef}>
                  {this.handleDropDownListItem()}
                </ul>
              </CustomScrollBar>
            </div>
          )}
        </div>
      </InputWrapComponent>
    );
  }
}

export { SelectList, SelectList as Select };
