import { generateGenderOptions, t } from 'utils';

const allOption = () => ({
  key: 0,
  value: 0,
  label: t.staticAsString('settings.genders.all'),
});

const options = () => [...generateGenderOptions(), allOption()];

function findOption(value: number | null) {
  return options().find((option) => option.value === value) || allOption();
}

const gendersSelect = {
  options,
  findOption,
};

export { gendersSelect };
