import { distanceChanger } from '../../mutations';
import { loader } from '../loader';
import { refreshApp } from '../refreshApp';
import { updateHistoryBasedOnFilters } from './updateHistoryBasedOnFilters';

/*
 * Init filtrers and distance based on defaults and history params
 */
function onDistanceChange(id: null | number) {
  distanceChanger.change(id, null);
  refreshApp();

  loader.loadUntilViewport();
  updateHistoryBasedOnFilters();
}

export { onDistanceChange };
