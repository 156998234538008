export function isMobileDevice(): boolean {
  return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
}

export const isAndroidDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('android') > -1; //&& ua.indexOf("mobile");
};

export const isiOSDevice = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
};
