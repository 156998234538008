import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const InfoPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9 11.5H11V15.5H9V17.5H15V15.5H13V10.5V9.5H12H9V11.5ZM13 8V6H11V8H13Z'
    fill='currentColor'
  />
);

export const Info = (props: IconProps) => <SvgIcon path={InfoPath} {...props} />;
