import { t } from 'utils';

export const getResultValidation = () => ({
  _validation: validation,
});

export const getCumulativeValidation = (lowerThan: number) => ({
  _validation: {
    ...validation,
    ...addition(lowerThan),
  },
});

const maxLength = 1000000000;

const validation = {
  activity_link: {
    length: { maximum: 450 },
    url: {
      message: '^profile.resultForm.errorLink.redesign',
    },
  },
  time_result: {
    length: { maximum: 250 },
    exclusion: {
      within: { '00:00:00': '' },
      message: '^profile.resultForm.withoutZero',
    },
    format: {
      pattern: '\\d{2}\\:\\d{2}:\\d{2}.\\d{3}',
      message: '^validation.invalidFormat',
    },
    presence: {
      allowEmpty: false,
      message: '^confirmProfile.validation.required',
    },
    hours: {
      greaterThan: {
        value: 99,
        message: '^segmentsForm.error.hoursLowerThan',
      },
    },
  },
};

const addition = (lowerThan: number) => ({
  distance_logged_at: {
    presence: {
      allowEmpty: false,
      message: '^confirmProfile.validation.required',
    },
  },
  distance_logged: {
    presence: {
      allowEmpty: false,
      message: '^confirmProfile.validation.required',
    },
    numericality: {
      onlyInteger: true,
      lessThanOrEqualTo: lowerThan || maxLength,
      message: `^${t.staticAsString('profile.resultForm.lowerThan')} ${lowerThan || maxLength}`,
    },
  },
});
