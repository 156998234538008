import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { PropsMobile } from 'src/modules/tourProfileManagment/components/Header/styled';

import { loadProgress, windowSize } from 'stores';

import { Content } from '.';

const Wrapper = styled.div<PropsMobile>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 4;

  background: linear-gradient(85.23deg, rgba(214, 241, 0, 0.2) -159.34%, rgba(39, 46, 52, 0.2) 101.35%), #272e34;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 1px;

  ${(props) => props.isMobile && WrapperStyledMobile}
`;

const WrapperStyledMobile = `
  left: 18px;
`;

export const LoadingBar = () => {
  return (
    <Observer>
      {() => {
        const activeJobsIds = Object.keys(loadProgress.loadingJobs);
        const isMobile = windowSize.isLessThan('small');

        return (
          <Wrapper isMobile={isMobile}>
            {activeJobsIds.map<React.ReactNode>((key) => {
              const { progress, isFinished, isError, isCancelled, remainingSec, fileName, noConnection, upload } =
                loadProgress.loadingJobs[key];

              const shortFileName = fileName.length > 15 ? fileName.substr(0, 12) + '...' : fileName;

              return (
                <Content
                  key={key}
                  upload={upload}
                  progress={progress}
                  fileName={shortFileName}
                  isFinished={isFinished}
                  isError={isError}
                  isCancelled={isCancelled}
                  timeLeft={remainingSec}
                  noConnection={noConnection}
                  onRetry={() => loadProgress.onRetry(+key)}
                  onCancel={() => loadProgress.cancelLoading(+key)}
                />
              );
            })}
          </Wrapper>
        );
      }}
    </Observer>
  );
};
