import { makeObservable, override } from 'mobx';
import { Distances } from 'modules/racersTable/stores';

import { Distance as DistanceModel } from 'models';

class DistancesStore extends Distances {
  static filters: FiltersType = {
    limit: 9999,
    with: 'classes;waves;registration_fields;squads',
    searchJoin: 'AND',
    sortBy: 'asc',
    orderBy: 'index',
  };

  constructor() {
    super();

    makeObservable(this);
  }

  @override
  get filteredModelValues(): DistanceModel[] {
    return this.modelValues.filter((distance) => distance.value.show_startlist);
  }

  @override
  get filteredValues(): DistanceType[] {
    return this.values.filter((distance) => distance.show_startlist);
  }
}

export { DistancesStore, DistancesStore as Distances };
