import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { getTeamDistanceRacersCount } from '../utils';

import { totalCountStore } from '../stores';
import { paginationMetaStore } from '../stores';

import { currentDistance } from './currentDistance';

const count: IComputedValue<number | nil> = computed(() => {
  if (!currentDistance.id.get()) {
    const total = totalCountStore.value;
    return total || null;
  }
  const teamDistanceRacers = getTeamDistanceRacersCount();

  const paginationMeta = paginationMetaStore.value[currentDistance.id.get()!];

  const total = teamDistanceRacers ? teamDistanceRacers : paginationMeta?.total;

  return total || null;
});

export { count };
