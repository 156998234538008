import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { sportTypesStore } from 'stores';

const sportTypesList: IComputedValue<Array<any>> = computed((): Array<any> => {
  return (sportTypesStore.modelValues || []).map((sport) => ({
    title: sport.title,
    id: sport.value.id,
  }));
});

export { sportTypesList };
