import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const LogoutPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M22 3H5V9H9.49977V6.5H12.6211L18.1211 12L12.6211 17.5H9.49977V15H5V21H22V3ZM10.9998 9V8H12L16 12L12 16H11L10.9998 15L11 13.5H0V10.5H11L10.9998 9Z'
    fill='currentColor'
  />
);

export const Logout = (props: IconProps) => <SvgIcon path={LogoutPath} {...props} />;
