import React from 'react';
import { LinkProps } from 'react-router-dom';

import { fireToast, t } from 'utils';

import { Link } from '../Link';

type Props = {
  condition: boolean;
};

export const LinkWithCondition = ({ to, children, condition, ...rest }: Props & LinkProps & OptionalTestAnchorProps) => {
  const handleOnclick = (e) => {
    if (!condition) {
      e.preventDefault();
      fireToast(t.staticAsString('toast.message.unpublishedRace'), 'warning', t.staticAsString('toast.title.unpublishedRace'), 'top-right');
    }
  };

  return (
    <Link {...rest} to={to} onClick={handleOnclick}>
      {children}
    </Link>
  );
};
