import { disposersStore } from '../stores';

import { observeLocationAutocomplete, observeRacesAutocomplete, observerSports, observeSearch } from '../reactions';
import { queryToForm } from './';

export const mount = () => {
  observeLocationAutocomplete();
  observeRacesAutocomplete();
  observerSports();
  queryToForm();
};

export const unmount = () => {
  disposersStore.disposeAll();
};

export const mountMobile = () => {
  observeSearch();
  observeLocationAutocomplete();
  observeRacesAutocomplete();
  observerSports();
  queryToForm();
};

export const unmountMobile = () => {
  disposersStore.disposeAll();
};
