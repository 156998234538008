import isEmpty from 'lodash/isEmpty';
import { Observer } from 'mobx-react';
import { handleChangeRegisteredPage, handleChangeSavedPage } from 'modules/profile/actions';
import React from 'react';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';
import { RESOLUTIONS } from 'src/constants';
import { RaceProfileCardSkeleton } from 'src/styledComponents/Card';
import { ShowDesktop } from 'src/styledComponents/Conditions';
import { Divider } from 'src/styledComponents/Divider';
import { ControlledPagination, DescriptionVariants } from 'src/styledComponents/Pagination';

import { Hide, Show } from 'components/condition';

import { encodeQuery } from 'utils';

import { progressStore } from 'stores';

import { RegisteredTab, MyResultsTab, CardWrapper, SavedRacesTab, Xtri, NoResults } from '../components';
import { MyRacesCardSkeleton } from '../components/RegisteredTo/MyRacesCard';

import { registeredTo, saved, xtri, my_results, REGISTERED_TO_PROFILE, SAVED_RACES_PROFILE, INITIALIZE_PROFILE } from '../constants';

import { profileService } from '../services';

import { tabStore } from '../stores';

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding-bottom: 48px;
  }
`;

const SavedRacesNoResults = styled(NoResults)`
  @media (max-width: ${RESOLUTIONS.medium}px) {
    .no-results-message {
      width: 173px;
    }
  }
`;
const RegisteredRacesNoResults = styled(NoResults)`
  @media (max-width: ${RESOLUTIONS.medium}px) {
    .no-results-message {
      width: 201px;
    }
  }
`;

const StyledCardWrapper = styled(CardWrapper)`
  :empty {
    display: none;
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: 56px 20px 0;
  }
`;

const RegisteredCardWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${RESOLUTIONS.medium}px) {
    gap: 48px 0;

    & > div {
      width: 100%;
    }
  }
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  margin: 24px 0;
`;

const StyledControlledPagination = styled(ControlledPagination)`
  margin-top: 48px;
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-top: 32px;
  }
`;

export const TabsWrapper = () => {
  const getRedirectManageTeam = (distance_id: number, raceId: number) => (): string => {
    return encodeQuery.generatePath(ROUTER.MANAGE_TEAM, { raceId }, { distance_id });
  };

  const handleAddToWish = (id: number) => () => {
    profileService.changeWishList(id);
    tabStore.removeValue(id);
  };

  const ATNRegisteredTo = { ...testAnchors.useField('registeredTo', TEST_ANCHORS.fieldStructure.container) };
  const ATNSaved = { ...testAnchors.useField('saved', TEST_ANCHORS.fieldStructure.container) };

  const renderTab = () => {
    switch (tabStore.activeTab as any) {
      case registeredTo.id:
        return (
          <>
            <RegisteredCardWrapper {...ATNRegisteredTo}>
              <Show if={progressStore.isLoading(REGISTERED_TO_PROFILE)}>
                {[...Array(3).keys()].map((value, index) => (
                  <React.Fragment key={`races-card-ske-${value}`}>
                    <MyRacesCardSkeleton />

                    <ShowDesktop>{index !== 2 && <StyledDivider light />}</ShowDesktop>
                  </React.Fragment>
                ))}
              </Show>
              <Hide if={progressStore.isLoading(REGISTERED_TO_PROFILE)}>
                <RegisteredTab getRedirectLink={getRedirectManageTeam} />
              </Hide>
            </RegisteredCardWrapper>
            <Show
              if={
                isEmpty(tabStore.values) && !progressStore.isLoading(REGISTERED_TO_PROFILE) && !progressStore.isLoading(INITIALIZE_PROFILE)
              }
            >
              <RegisteredRacesNoResults message='profile.notInterstedMessage' />
            </Show>
            <Observer>
              {() => {
                return (
                  <Hide if={progressStore.isLoading(REGISTERED_TO_PROFILE)}>
                    <StyledControlledPagination
                      currentPage={tabStore.valuesMeta!.current_page}
                      onPageChanged={handleChangeRegisteredPage}
                      pageLimit={tabStore.valuesMeta?.per_page}
                      totalRecords={tabStore.valuesMeta?.total}
                      descriptionVariant={DescriptionVariants.base}
                    />
                  </Hide>
                );
              }}
            </Observer>
          </>
        );
      case saved.id:
        return (
          <>
            <StyledCardWrapper {...ATNSaved}>
              <Show if={progressStore.isLoading(SAVED_RACES_PROFILE)}>
                {[...Array(3).keys()].map((value) => (
                  <RaceProfileCardSkeleton key={value} />
                ))}
              </Show>
              <Hide if={progressStore.isLoading(SAVED_RACES_PROFILE)}>
                <SavedRacesTab handleAddToWish={handleAddToWish} />
              </Hide>
            </StyledCardWrapper>
            <Show
              if={isEmpty(tabStore.values) && !progressStore.isLoading(SAVED_RACES_PROFILE) && !progressStore.isLoading(INITIALIZE_PROFILE)}
            >
              <SavedRacesNoResults message='profile.notSavedMessage' />
            </Show>
            <Observer>
              {() => {
                return (
                  <Hide if={progressStore.isLoading(SAVED_RACES_PROFILE)}>
                    <StyledControlledPagination
                      currentPage={tabStore.valuesMeta!.current_page}
                      onPageChanged={handleChangeSavedPage}
                      pageLimit={tabStore.valuesMeta?.per_page}
                      totalRecords={tabStore.valuesMeta?.total}
                      descriptionVariant={DescriptionVariants.base}
                    />
                  </Hide>
                );
              }}
            </Observer>
          </>
        );
      case xtri.id:
        return <Xtri />;
      case my_results.id:
        return <MyResultsTab />;
      default:
        return <div>empty tab</div>;
    }
  };

  return <Observer>{() => <Wrapper>{renderTab()}</Wrapper>}</Observer>;
};
