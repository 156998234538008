import { HistoryFilters } from '../types';

export const FORM_FILTERS_ID = 'RESULTS_FORM_FILTERS_ID';

export const EMPTY_FILTERS: HistoryFilters = {
  name: '',
  gender: '0',
  class_id: '0',
  currentDistanceId: '0',
  currentCheckpointId: null,
} as const;

export const FILTER_NAMES = ['name', 'gender', 'class_id', 'currentDistanceId', 'currentCheckpointId'];

export const RESULTS_CONTAINER_ID = 'results-table-viewport';
export const SEARCH_ID = 'RESULTS_SEARCH_ID';

export const LOAD_RESULTS = 'MODULE_LOAD_RESULTS';
export const INIT_DISTANCE = 'RESULTS_INIT_DISTANCE';
export const PRELOADER = 'RESULTS_PRELOADER';
export const PER_PAGE = 100;

export const EMPTY_DATA = {
  dns: 'DNS',
  dnf: 'DNF',
  empty: '--:--',
};

export * from './tableFormula';
