import styled, { css } from 'styled-components';

const Item = styled.li<{ isActive: boolean }>`
  padding: 7px 9px 5px;

  ${(props) =>
    props.isActive &&
    css`
      border-radius: 4px;
      border: 1px solid ${props.theme.miles.colors.dark.alpha.cobalt500};
      background: ${props.theme.miles.colors.dark.alpha.cobalt500};
    `}
`;

export const MenuItem = styled(Item)`
  h1 {
    text-transform: uppercase;
    margin: 0;
  }
`;
