import { RANGEMIN, RANGEMAX } from '../components';

export const BASE_FILTER_STATE = {
  filter: {
    city: '',
    'races.name': '',
    'races.sport_id': [],
    'races.race_date': '',
    'races.race_end_date': '',
    'races.distances.race_length': [RANGEMIN, RANGEMAX],
  },
  q: '',
};

export const CURRENT_RACES_FILTER_LOAD_VERSION = 'CURRENT_RACES_SEARCH';
export const PAST_RACES_FILTER_LOAD_VERSION = 'PAST_RACES_SEARCH';
// Map races
export const ALL_RACES_FILTER_LOAD_VERSION = 'ALL_RACES_FILTER_LOAD_VERSION';

export const MIN_DATE_FOR_FILTERS = '2000-01-01';
export const MAX_DATE_FOR_FILTERS = '2050-01-01';
