import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { activeSplitStore } from '../../stores';

import { LResult } from '../../types';
import { CheckpointsTable } from '../Checkpoint';

type Props = {
  className?: string;
  value: LResult;
  checkpointId: null | void | number;
  distanceId: number;
  height: number;
  isRightSide?: boolean;
  place?: number | null;
  children?: React.ReactNode;
};

const getRightSideStyles = (props) => {
  if (!props.isRightSide) return '';

  return `
    width: 0;
    overflow: hidden;
    opacity: 0;
  `;
};

const getGradientByPlace = (props) => {
  if (props.isRightSide) {
    switch (props.place) {
      case 1:
        return 'background: linear-gradient(90deg, #FDF8EA 0%, rgba(253, 248, 234, 0) 100%);';

      case 2:
        return 'background: linear-gradient(90deg, #F2F4F5 0%, rgba(242, 244, 245, 0) 100%);';

      case 3:
        return 'background: linear-gradient(90deg, #F7F2EF 0%, rgba(247, 242, 239, 0) 100%);';

      default:
        return '';
    }
  }

  switch (props.place) {
    case 1:
      return 'background: linear-gradient(90deg, #F2EACF 0%, #FDF8EA 100%);';

    case 2:
      return 'background: linear-gradient(90deg, #E6E9EB 0%, #F2F4F5 100%);';

    case 3:
      return 'background: linear-gradient(90deg, #F1E8E4 0%, #F7F2EF 100%);';

    default:
      return '';
  }
};

const Wrapper = styled.div<{ place?: number | null; isRightSide: boolean }>`
  position: relative;

  ${getGradientByPlace}

  &:nth-child(even) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

const GridWrap = styled.div<{ height: number }>`
  display: grid;
  align-items: center;

  height: ${(props) => props.height}px;
  width: 100%;
`;

const CheckpointWrap = styled.div<{ isRightSide: boolean; top: number; active: boolean }>`
  position: relative;
  z-index: 2;
  transition: All ease-out 0.3s;
  padding-bottom: ${(props) => (props.active ? 32 : 0)}px;
  height: ${(props) => (props.active ? '100%' : 0)};
  ${getRightSideStyles}
`;

export const RowWrap: React.FC<Props> = (props) => {
  const { className, children, value, checkpointId, distanceId, height, isRightSide = false, place } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    return () => {
      activeSplitStore.clear();
    };
  }, []);

  return (
    <Observer>
      {() => {
        const isActive = activeSplitStore.value === (value.team ? value.team_id : value.racer_id);
        return (
          <Wrapper className={className} place={place} isRightSide={isRightSide}>
            <GridWrap className='grid-wrap' height={height}>
              {children}
            </GridWrap>
            <CheckpointWrap active={isActive} top={height} className='checkpoint-wrap' isRightSide={isRightSide}>
              <CheckpointsTable active={isActive} value={value} checkpointId={checkpointId} ref={ref} distanceId={distanceId} />
            </CheckpointWrap>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
