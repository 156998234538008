import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { isDistanceHasMore } from '../../utils';

import { distancesStore } from '../../stores';

import { currentDistance } from '../currentDistance';

const hasMore: IComputedValue<boolean> = computed(() => {
  const distanceId = currentDistance.id.get();

  if (!!distanceId) {
    return isDistanceHasMore(distanceId);
  }

  return !!distancesStore.filteredValues.some((distance) => {
    return !!isDistanceHasMore(distance.id);
  });
});

export { hasMore };
