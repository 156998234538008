import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Divider } from 'src/styledComponents/Divider';
import { HardElevation } from 'src/styledComponents/Elevation';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { t } from 'utils';

import { windowSize } from 'stores';

import { Loading } from './Loading';
import { UserInfo } from './UserInfo';

type Props = {
  showUserModal: boolean;
  withUnion: boolean;
  closeModal: () => void;
  modalPosition: ClientRect;
  currentUser: RacersType | null;
  getClass: (id: number) => ClassType | null | undefined;
  getWave: (id: number) => WaveType | null | undefined;
  showAvatarBorder?: boolean;
};

const Wrapper = styled(HardElevation)<{}>`
  position: fixed;
  display: none;
  background-color: ${(props) => props.theme.main.colors.white};
  border-radius: 4px;
  z-index: 9999;
  top: 0;
  left: 0;
  min-width: 410px;
  height: 240px;
  padding: 48px;

  &.active {
    display: block;
  }

  .user-info-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 20px 16px;
  }

  .top-divider {
    margin: 8px 0;
    height: 1px;
    background-color: ${(props) => props.theme.main.colors.clay4};
  }

  @media (min-width: ${RESOLUTIONS.large}px) {
    &::before,
    &::after {
      position: absolute;
      content: '';
      top: var(--bottom-offset);
      left: 0;
    }

    &::after {
      width: 25px;
      height: 25px;
      transform: translate3d(-50%, -50%, 0) rotate(-135deg);
      z-index: 10;
      background-color: ${(props) => props.theme.main.colors.white};
    }
  }

  @media (max-width: ${RESOLUTIONS.large}px) {
    right: 0;
    min-width: 0;
    padding: 0;
    height: 100vh;
  }
`;

/*
 * Deprecated,
 * and need refactoring
 * please
 */
@onClickOutside
export class UserModal extends React.Component<Props> {
  node?: HTMLDivElement;

  static defaultProps = {
    showUserModal: false,
    intl: null as any,
  };

  componentDidMount(): any {
    window.addEventListener('scroll', this.props.closeModal);
  }

  componentWillUnmount(): any {
    window.removeEventListener('scroll', this.props.closeModal);
  }

  getModalHeight(): number {
    if (this.node) {
      return this.node.getBoundingClientRect().height;
    }

    return 0;
  }

  getGender = () => {
    const { currentUser } = this.props;
    const gender = currentUser?.gender;

    switch (gender) {
      case 1:
        return t.staticAsString('settings.genders.male');
      case 2:
        return t.staticAsString('settings.genders.female');
      case 3:
        return t.staticAsString('settings.genders.other');
      default:
        return '';
    }
  };

  getWave = (): string => {
    const { currentUser } = this.props;
    const waveName = `${(this.props.getWave(Number(currentUser?.wave_id)) || {}).name}`;

    return waveName || '';
  };

  getUserAge = (birthday: string): number => {
    return moment().diff(birthday, 'years');
  };

  setModalNode = (node: HTMLDivElement | null) => {
    if (!this.node && node) {
      this.node = node;
      this.forceUpdate();
    }
  };

  handleClickOutside = (e: React.FocusEvent<HTMLElement>) => {
    if (e.target.className !== 'user-name single') {
      const bodyElement = document.querySelector('body');
      bodyElement!.style.overflow = 'auto';
      this.props.closeModal();
    }
  };

  render(): React.ReactNode {
    const { showUserModal, modalPosition, currentUser, withUnion, showAvatarBorder } = this.props;
    const modalHeight = this.getModalHeight();
    const isMobileAndTablet = windowSize.isLessThan('large');
    let positionY = Math.round(modalPosition.top + window.pageXOffset + modalPosition.height / 2 - modalHeight / 2);

    const popUpWidth = 410;
    const popUpHeight = 240;

    let positionX = Math.round(modalPosition.left + window.pageXOffset + modalPosition.width);

    const isBottomOffScreen = popUpHeight + positionY > window.innerHeight;

    const isOffScreen = popUpWidth / 2 + positionX > window.innerWidth;
    const isSlightlyOffScreen = popUpWidth + positionX > window.innerWidth;

    if (isOffScreen) {
      // move position to the left
      positionX = Math.round(modalPosition.right - popUpWidth - window.pageXOffset - modalPosition.width);
    }

    let bottomOffset = popUpHeight / 2;
    if (isBottomOffScreen) {
      bottomOffset = bottomOffset + popUpHeight + positionY - window.innerHeight;
      positionY = window.innerHeight - popUpHeight;
    }

    if (isSlightlyOffScreen && !isOffScreen) {
      positionX = window.innerWidth - popUpWidth;
    }

    const transformStyle = {
      transform: `
        translate3d(${positionX}px,${positionY}px,0)`,
      '--bottom-offset': `${bottomOffset}px`,
    };

    if (isMobileAndTablet && showUserModal) {
      const bodyElement = document.querySelector('body');
      bodyElement!.style.overflow = 'hidden';
    }

    return (
      <Wrapper
        className={classNames('user-info-modal', {
          active: showUserModal,
          reverse: isOffScreen,
        })}
        style={isMobileAndTablet ? {} : transformStyle}
        ref={this.setModalNode}
        level={4}
      >
        <Show if={isMobileAndTablet}>
          <div className='user-info-close'>
            <Svg name='CrossGray' size={12} onClick={this.handleClickOutside} />

            <Utility variant='u4' weight='bold'>
              {t.staticAsString('results.participants')}
            </Utility>

            <span />
          </div>

          <Divider className='top-divider' />
        </Show>

        <Show if={!!currentUser}>
          <UserInfo
            showAvatarBorder={showAvatarBorder}
            value={currentUser as any}
            withUnion={withUnion}
            getGender={this.getGender}
            getWave={this.getWave}
            getUserAge={this.getUserAge}
            getClass={this.props.getClass}
          />
        </Show>
        <Show if={!currentUser}>
          <Loading />
        </Show>
      </Wrapper>
    );
  }
}
