import { TRANSFER_REGISTRATION_FORM } from 'src/modules/transferRegistration/constants';
import { ModalStoreType } from 'src/modules/transferRegistration/types';
import { search } from 'src/modules/transferRegistration/validations';

import { setNestedValidation } from 'utils';

import { errorsStore, form } from 'stores';
import {modalStore} from "modules/transferRegistration/stores";
import {openConfirmModal} from "modules/transferRegistration/actions/controls";

export const onSearchEmail = () => {
  errorsStore.clear(TRANSFER_REGISTRATION_FORM);

  const fields = form.fetch<ModalStoreType>(TRANSFER_REGISTRATION_FORM);
  const valid = setNestedValidation(TRANSFER_REGISTRATION_FORM, search, fields);

  if (!valid) {
    return;
  }

  const value = { ...(modalStore.value as any), recipientEmail: fields.email };
  modalStore.setValue(value);
  openConfirmModal();
};
