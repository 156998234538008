import React, { FC } from 'react';

type Props = {
  image1x: string;
  image2x: string;
  alt: string;
  [x: string]: any;
  className?: string;
};

export const ResponsiveImage: FC<Props> = ({ image1x, image2x, alt = '', ...rest }: Props) => {
  return <img srcSet={`${image2x} 2x, ${image1x} 1x`} src={image1x} alt={alt} {...rest} loading='lazy' />;
};
