import { observer } from 'mobx-react';
import { CheckinDaily, Dot, Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import { Show } from 'components/condition';

import { dailyDataStore } from '../../stores/dailyData';

const StyledDot = styled(Dot)`
  position: absolute;
  left: -12px;
  top: -9px;
  color: ${({ theme }) => theme.miles.colors.dark.icon.negative};
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.4));
`;

const DailyDataDisplay = observer(() => {
  const readinessAsPercentage = dailyDataStore.readinessAsPercentage();

  return (
    <>
      <Show if={dailyDataStore.readinessScore === null}>
        <StyledDot />
        <div className='daily-data-unfilled'>
          <div className='icon'>
            <CheckinDaily />
          </div>
          <Text weight={'regular'} size={'md'}>
            Update Daily Data
          </Text>
        </div>
      </Show>
      <Show if={dailyDataStore.readinessScore !== null}>
        <div className='daily-data-filled'>
          <Text weight={'regular'} size={'md'}>
            Today's Readiness:
          </Text>
          <Text weight={'bold'} size={'lg'}>
            {readinessAsPercentage ?? ''}
          </Text>
        </div>
      </Show>
    </>
  );
});

export default DailyDataDisplay;
