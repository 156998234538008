import React, { FC, ReactElement, useEffect, useState } from 'react';

import { Svg } from 'src/styledComponents/Icons/Svg';
import { Utility } from 'src/styledComponents/Typography';

import { PaginationContainer, PaginationElement, ArrowLeft, ArrowRight, Description } from './Components';

const DOTS = 'DOTS';

type Props = {
  className?: string;
  onPageChanged: AnyFunction;
  totalRecords?: number;
  pageLimit?: number;
  pageNeighbours?: 1 | 2;
  withoutInit?: boolean;
  initialPage?: number;
};

export const Pagination: FC<Props> = ({
  className,
  onPageChanged,
  totalRecords = 0,
  pageLimit = 15,
  pageNeighbours = 1,
  withoutInit,
  initialPage,
}): ReactElement<any, any> | null => {
  const [currentPage, setCurrentPage] = useState(initialPage || 1);
  const totalPages: number = Math.round(totalRecords / pageLimit);

  const range = (from, to, step = 1) => {
    let i = from;
    const range: any[] = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    setCurrentPage(currentPage);
    onPageChanged(paginationData);
  };

  useEffect(() => {
    if (withoutInit) return;
    gotoPage(1);
  }, []);

  const handleClick = (page, evt) => {
    if (page === DOTS) {
      return;
    }

    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    if (currentPage === 1) {
      return;
    }
    gotoPage(currentPage - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(currentPage + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages: any = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [DOTS, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, DOTS];
      } else if (leftSpill && rightSpill) {
        pages = [DOTS, ...pages, DOTS];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const getDescription = () => {
    const from = currentPage > 1 ? (currentPage - 1) * pageLimit : currentPage;
    const to = Math.min(currentPage * pageLimit, totalRecords);
    const max = totalRecords > 100 ? '100+' : `${totalRecords}`;

    return `${from} - ${to} of ${max} Articles`;
  };

  if (!totalRecords || totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <div className={className}>
      <PaginationContainer>
        <ArrowLeft key='btn-left' className='page-item' onClick={handleMoveLeft} disabled={currentPage === 1}>
          <Svg name='ArrowDown' size={16} />
        </ArrowLeft>

        {pages.map((page, index) => (
          <PaginationElement active={currentPage === page} key={index} onClick={(e) => handleClick(page, e)}>
            {page === DOTS ? (
              <Svg name='ThreeDots' size={10} />
            ) : (
              <Utility variant='u4' weight='medium'>
                {page}
              </Utility>
            )}
          </PaginationElement>
        ))}

        <ArrowRight key='btn-right' className='page-item' onClick={handleMoveRight} disabled={currentPage === totalPages}>
          <Svg name='ArrowDown' size={16} />
        </ArrowRight>
      </PaginationContainer>

      <Description variant='body2'>{getDescription()}</Description>
    </div>
  );
};
