import * as React from 'react';
//@ts-ignore
import { Match, Location } from 'react-router-dom';

import { NavLink } from 'src/styledComponents/Link';

type Props = {
  tabs: Array<Object>;
};

const isActive =
  (link: string): ((match: Match, location: Location) => boolean) =>
  (match: Match, location: Location): boolean => {
    return !!(location.pathname || '').match(link);
  };

const TabsRaceDetails = (props: Props): any => {
  return (
    <div className='container'>
      <div className='row'>
        <ul className='race-tabs'>
          {props.tabs.map((tab: AnyObject) => {
            return (
              <li key={tab.id}>
                <NavLink to={tab.link} className='race-tab-item' activeClassName='active' isActive={isActive(tab.link)}>
                  <span>{tab.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { TabsRaceDetails };
