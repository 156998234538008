import { get } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

class RegistrationFields {
  @observable values: RegistrationFieldsType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async setValues(values: RegistrationFieldsType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  find(id: number, name: registrationFields): RegistrationFieldsType | null {
    return get(this.groupedByDistanceAndName, [id, name]) || null;
  }

  @computed
  get groupedByDistanceAndName(): {
    [K in number]: { [K in registrationFields]: RegistrationFieldsType };
  } {
    return this.values.reduce((acc: AnyObject, field: RegistrationFieldsType) => {
      if (!acc[field.distance_id]) {
        acc[field.distance_id] = {};
      }

      acc[field.distance_id][field.name] = field;

      return acc;
    }, {});
  }

  @computed
  get groupedByName(): { [K in registrationFields]: RegistrationFieldsType[] } {
    return this.values.reduce((acc: AnyObject, field: RegistrationFieldsType) => {
      if (!acc[field.name]) {
        acc[field.name] = [];
      }

      acc[field.name].push(field);

      return acc;
    }, {}) as any;
  }
}

export { RegistrationFields, RegistrationFields as RegistrationFieldsStore };
