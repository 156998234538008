import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { HardElevation } from 'src/styledComponents/Elevation';
import { Utility } from 'src/styledComponents/Typography';
import { useOnClickOutside } from 'src/utils';

import { Participant } from '../Table/Participant';

type Props = {
  name: string;
  members: RacersType[];
  onShowUser: (racerId: number) => AnyFunction;
  onClose: AnyFunction;
  show: boolean;
};

const Wrapper = styled(HardElevation)`
  position: absolute;
  left: calc(100% + 26px);
  z-index: 10;
  padding: 48px;

  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  background-color: white;

  .arrow {
    position: absolute;
    top: calc(50% - 12px);
    left: -26px;
    z-index: 1;
  }
`;

const TeamName = styled(Utility)`
  margin-bottom: 8px;
  white-space: nowrap;
`;

export const ParticipantsModal: React.FC<Props> = (props) => {
  const { name, members, onShowUser, show, onClose } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    window.addEventListener('scroll', onClose);
    return () => window.removeEventListener('scroll', onClose);
  }, []);

  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <Show if={show}>
      <Wrapper level={4} ref={ref}>
        <svg className='arrow' width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0.999999 15.7321C-0.333335 14.9623 -0.333333 13.0378 1 12.268L22 0.143631V27.8564L0.999999 15.7321Z' fill='white' />
        </svg>
        <TeamName variant='u2' weight='bold'>
          {name}
        </TeamName>
        {members.map((item) => (
          <Participant key={`participant-${item.id}`} value={item} onShow={onShowUser} />
        ))}
      </Wrapper>
    </Show>
  );
};
