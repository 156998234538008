import * as React from 'react';

export const Linkedin = (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.2418 22.587H9.3631V14.3359H12.2418V22.587ZM18.2184 17.025C17.8822 17.025 17.5598 17.1586 17.3221 17.3963C17.0844 17.634 16.9508 17.9564 16.9508 18.2926V22.587H13.924V14.3359H16.9508V15.2777C17.6603 14.6771 18.5566 14.342 19.486 14.3299C21.3637 14.3299 22.6668 15.7219 22.6668 18.3637V22.587H19.486V18.2926C19.4868 18.1257 19.4546 17.9602 19.3912 17.8057C19.3279 17.6512 19.2346 17.5108 19.1168 17.3925C18.9991 17.2741 18.8591 17.1802 18.7049 17.1162C18.5507 17.0521 18.3854 17.0191 18.2184 17.0191V17.025ZM12.2951 11.4808C12.2951 11.7737 12.2083 12.06 12.0456 12.3035C11.8828 12.547 11.6516 12.7368 11.381 12.8489C11.1104 12.961 10.8127 12.9903 10.5254 12.9332C10.2382 12.8761 9.9743 12.735 9.7672 12.5279C9.56011 12.3208 9.41907 12.057 9.36193 11.7697C9.3048 11.4825 9.33412 11.1847 9.4462 10.9141C9.55828 10.6436 9.74808 10.4123 9.9916 10.2496C10.2351 10.0868 10.5214 10 10.8143 10C11.205 10.0031 11.5786 10.1605 11.8538 10.4379C12.1289 10.7153 12.2833 11.0901 12.2833 11.4808H12.2951Z'
      fill='#DCDEDF'
    />
    <rect x='0.75' y='0.75' width='30.5' height='30.5' rx='15.25' stroke='#DCDEDF' strokeWidth='1.5' />
  </svg>
);
