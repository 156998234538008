import { borderStore } from '../stores';

export const onHorizontalScroll = (element: Element) => {
  if (element.scrollLeft > 0 && !borderStore.value) {
    borderStore.setValue(true);
  }

  if (element.scrollLeft === 0 && borderStore.value) {
    borderStore.setValue(false);
  }
};
