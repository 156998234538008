import React, { useLayoutEffect, useState } from 'react';

import { RESOLUTIONS } from 'src/constants';

export const useWindowSize = (): boolean => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return width < RESOLUTIONS.medium;
};
