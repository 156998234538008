import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { SquadsStore } from '../../stores';

function squadsConditions(squadsStore: SquadsStore) {
  function generateIsSquads(distanceId: number): IComputedValue<boolean> {
    return computed(() => {
      return !!squadsStore.findSquadByDistance(+distanceId).length;
    });
  }

  const isAnyDistanceHasSquads: IComputedValue<boolean> = computed(() => {
    return !!squadsStore.values.length;
  });

  return { generateIsSquads, isAnyDistanceHasSquads };
}

export { squadsConditions };
