import { isDate, isNaN } from 'lodash';
import moment from 'moment';
import Xregexp from 'xregexp';

import { TIME_PATTERN } from 'src/constants';

import { momentFormat } from './constants';

export function formatTimeToMask(value: string | moment.Moment): string {
  const momentValue = moment(value);

  if (!momentValue.isValid()) {
    return '';
  }

  return moment(value).format(momentFormat);
}

export function formatMaskToTime(value: string, prevDate?: Date | null): Date | null {
  if (!TIME_PATTERN.test(value)) {
    return null;
  }

  // RegExp$matchResult
  const match = Xregexp.exec(value, TIME_PATTERN) as Xregexp.ExecArray & {
    hours: string | null;
    minutes: string | null;
    seconds: string | null;
  };
  const { hours, minutes, seconds } = match;

  const dateToChange = isDate(prevDate) && !!prevDate.getTime() ? prevDate : new Date();

  const hour = Number(hours);
  const minute = Number(minutes);
  const second = Number(seconds);

  if (isNaN(hour) || isNaN(minute) || isNaN(second)) {
    return null;
  }

  dateToChange.setHours(hour, minute, second);
  return dateToChange;
}
