import { first } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { LOAD_COUNTRIES } from 'src/constants';

import { withProgressSpinner } from 'hocs';

import { Show } from 'components/condition';

import { manageTeamStore, progressStore, windowSize, overlayStore } from 'stores';

import { RaceInfo, DistancesList } from '../components';

import { LOAD_DISTANCES, MANAGE_TEAM_RACE } from '../constants';

import { init, clean } from '../actions';

import { distancesStore, collapseStore } from '../stores';

import { TeamManageContainer, MemberFormContainer } from './';

type Props = RouteComponentProps & {
  match: Object;
  location: Object;
  history: Object;
};

@withRouter
@withProgressSpinner([MANAGE_TEAM_RACE, LOAD_COUNTRIES, LOAD_DISTANCES])
@observer
class Base extends React.Component<Props> {
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    init(+params.raceId);
  }

  componentWillUnmount() {
    manageTeamStore.closeAddModal();
    overlayStore.hide();
    clean();
  }

  render() {
    if (progressStore.isLoading(MANAGE_TEAM_RACE)) {
      return null;
    }

    const { match, location } = this.props;
    const raceModel = manageTeamStore.modelSelectedValue;

    if (!raceModel) {
      return null;
    }

    const race = raceModel.value;
    const title = raceModel.value.name;
    const dateList = raceModel.compareDate();
    const sportType = race.sport || {};
    const isCollapsed = collapseStore.value;

    const distances = distancesStore.distancesToRender;

    const selectedDistanceId = +(distancesStore.selectedDistanceId as number);
    const team = first(raceModel.findModelDistance(selectedDistanceId)?.value?.teams);

    if (windowSize.isLessThan('medium') && manageTeamStore.showAddModal) {
      return (
        <MemberFormContainer teamId={team?.id} raceId={match.params.raceId!} match={match} userFields={manageTeamStore.selectedMember} />
      );
    }

    return (
      <div className='container'>
        <div className='left-side'>
          <RaceInfo
            title={title}
            dateList={dateList}
            location={race.location}
            image={race.image_default}
            sportType={sportType}
            raceId={race?.id}
          />
          <DistancesList distances={distances as AnyObject[]} activeId={selectedDistanceId} collapsed={isCollapsed} />
        </div>
        <div className='right-side'>
          <TeamManageContainer distanceId={selectedDistanceId} match={match} location={location} />
        </div>

        <Show if={manageTeamStore.showAddModal && overlayStore.isShow}>
          <div className='manage-member-form'>
            <MemberFormContainer teamId={team?.id} raceId={match.params.raceId} match={match} userFields={manageTeamStore.selectedMember} />
          </div>
        </Show>
      </div>
    );
  }
}

export { Base };
