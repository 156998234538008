export type PlaceType = {
  city: string;
  country?: string;
  id: string;
};

export enum Variant {
  normal = 'normal',
  compressed = 'compressed',
}

export type SearchFieldProps = {
  name: string;
  compressed: boolean;
  label: string;
  fieldName: string;
  placeholder: string;
  isTablet?: boolean;
  withBackground?: boolean;
  value: any;
  onChange: ({ name, value }: { name: string; value: any }) => void;
  setFocusedField: (value: string) => void;
};

export enum FieldNames {
  raceName = 'raceName',
  location = 'location',
  sports = 'sports',
  date = 'date',
  distance = 'distance',
}
