import axios from 'axios';

import { API_URL, API_CLASSIC_RESULTS_FUTURE, API_CLASSIC_RESULTS_PAST, ROUTER } from 'src/constants';

import { action, request, history } from 'utils';

import { GET_CLASSIC_RESULTS } from '../constants';

import { virtualListMapper } from '../utils';

import { classicStore } from '../stores';

export class ClassicService {
  @request({ action: GET_CLASSIC_RESULTS })
  async getClassicFutureRequest(params: AnyObject): Promise<any> {
    return axios.get(`${API_URL}${API_CLASSIC_RESULTS_FUTURE}`, { params });
  }

  @request({ action: GET_CLASSIC_RESULTS })
  async getClassicPastRequest(params: AnyObject): Promise<any> {
    return axios.get(`${API_URL}${API_CLASSIC_RESULTS_PAST}`, { params });
  }

  @action({ action: GET_CLASSIC_RESULTS })
  async getClassicResults(): Promise<void> {
    const future = await this.getClassicFuture();

    if (future.length === classicStore.params.limit) {
      const data = virtualListMapper([...classicStore.list, ...future], 'classic');
      classicStore.setVirtualResults(data);
      return;
    }

    const past = await this.getClassicPast();

    const data = virtualListMapper([...classicStore.list, ...future, ...past], 'classic');
    classicStore.setVirtualResults(data);

    if (classicStore.list.length < 3) {
      history.push(`${ROUTER.PROFILE}?tab=my_results`);
    }
  }

  async getClassicFuture(): Promise<Array<any>> {
    if (classicStore.isPast) return [];
    const [status, response] = await this.getClassicFutureRequest(classicStore.params);

    if (!status) return [];

    if (classicStore.params.page === response.data.meta.pagination.total_pages) {
      classicStore.setParams({ page: 1 }, true);
    } else {
      classicStore.setParams({ page: classicStore.params.page + 1 });
    }

    return response.data.data;
  }

  async getClassicPast(): Promise<Array<Object>> {
    const [status, response] = await this.getClassicPastRequest(classicStore.params);
    if (!status) return [];

    classicStore.setHasMore(response.data.meta.pagination.total_pages > classicStore.params.page);
    classicStore.setParams({ page: classicStore.params.page + 1 });

    return response.data.data;
  }
}

const classicService = new ClassicService();
export { classicService };
