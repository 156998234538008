import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { countdownStore } from 'stores';

type Props = {
  className?: string;
  expireDate: moment.Moment;
  onExpireCallback?: Function;
};

const ITERATION_TIMEOUT = 500;

@observer
export class CountdownTimer extends React.Component<Props> {
  timerId: any = 0;

  UNSAFE_componentWillMount() {
    const { expireDate } = this.props;

    if (!expireDate.isValid()) {
      return;
    }

    this.timerId = setInterval(() => {
      const expireMillis = expireDate.toDate().getTime();
      this._coutdownIteration(expireMillis);
    }, ITERATION_TIMEOUT);
  }

  componentWillUnmount() {
    countdownStore.clear(this.timerId);
    clearInterval(this.timerId);
  }

  onExpire = () => {
    const { onExpireCallback } = this.props;
    onExpireCallback && onExpireCallback();
    clearInterval(this.timerId);
  };

  _coutdownIteration(expireMillis: number) {
    const currentMillis = Date.now();

    if (expireMillis < currentMillis) {
      return this.onExpire();
    }

    const millisToEnd = expireMillis - currentMillis;
    countdownStore.setTime(this.timerId, millisToEnd);
  }

  render() {
    const { className } = this.props;

    const timer = countdownStore.timerObject[this.timerId];

    if (!timer) {
      return null;
    }

    const time = moment(timer).format('mm:ss');

    return <div className={className}>{time}</div>;
  }
}
