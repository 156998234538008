import { matchPath } from 'react-router-dom';

import { history } from 'utils';

function generateGetRaceId(route: string) {
  function getRaceId(): number | null {
    const match = matchPath(history.location.pathname, route);

    if (!match) {
      return null;
    }

    return +match.params.id;
  }

  return getRaceId;
}

export { generateGetRaceId };
