import * as React from 'react';

export const Facebook = (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.9285 13.4855H17.4051V12.3542C17.3968 12.2716 17.406 12.1881 17.432 12.1093C17.4581 12.0304 17.5004 11.9579 17.5562 11.8965C17.6121 11.8351 17.6802 11.7861 17.7562 11.7527C17.8323 11.7193 17.9144 11.7022 17.9975 11.7026H19.7745V9.33331H17.2097C14.8818 9.33331 14.4435 11.1103 14.4435 12.212V13.4855H12.6665V15.8549H14.4435V22.6666H17.4051V15.8549H19.6856L19.9285 13.4855Z'
      fill='#DCDEDF'
    />
    <rect x='0.75' y='0.75' width='30.5' height='30.5' rx='15.25' stroke='#DCDEDF' strokeWidth='1.5' />
  </svg>
);
