import React, { FC } from 'react';

import { InputComponent, Props, InputWrapComponent } from 'src/styledComponents/InputTools';

export const InputWithWrapper: FC<Props> = (props) => {
  const { value, disabled, name, label, requiredMark, errorMessage, isWarning } = props;

  return (
    <InputWrapComponent
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={value}
      isWarning={isWarning}
    >
      <InputComponent {...props} />
    </InputWrapComponent>
  );
};
