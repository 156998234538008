import isArray from 'lodash/isArray';
import { observer } from 'mobx-react';
import * as React from 'react';

import { ScrollWrapper } from 'components/scrollWrapper';

import { t } from 'utils';

import { errorsStore } from 'stores';

import { ContextValue, FormContext } from '../FormWrapper';

type Props = {
  Component: any; // TODO, describe proper input component interface based om BaseInputProps,
  settings: AnyObject;
  name: string;
  onBeforeChange?: (options: Object, e?: React.SyntheticEvent) => void;
  disableScrollToError?: boolean;
  error?: string | React.ReactNode;
  watchable?: boolean;
};

type State = {
  lodashName: string[];
};

// Standartized input wrapper
@observer
class InputWrapper extends React.Component<Props, State> {
  static defaultProps = {
    settings: {},
  };
  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.state = {
      lodashName: [],
    };
  }

  static getDerivedStateFromProps = (props: Props) => {
    const { name } = props;
    const lodashName = name.split('.');

    return { lodashName };
  };

  declare context: ContextValue;

  componentDidMount() {
    const { lodashName } = this.state;
    this.context.form && this.context.form.registerField(lodashName);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.name !== this.props.name) {
      this.setState({ lodashName: this.props.name.split('.') });
    }
  };

  inputProps = () => {
    const { name, settings } = this.props;

    const formName = this.context.form && this.context.form.id;

    let id = settings.id;

    if (!id) {
      id = `${formName}_${name}`;
    }

    return { name, ...settings, id };
  };

  generateAutoComplete = (): Object => {
    const disableAutofill = this.context.form?.disableAutofill;

    if (!disableAutofill) {
      return {};
    }

    const autoComplete = 'new-password';
    return { autoComplete };
  };

  value = (): any => {
    const { name } = this.props;
    return this.context.form && this.context.form.fetch<any>(name); // TODO
  };

  error = (): string => {
    const { name, error } = this.props;

    if (error) {
      return t.staticAsString(error as any);
    }

    const formName = this.context.form && this.context.form.id;

    if (!formName || !name) {
      return '';
    }

    const errors = errorsStore.get(formName, name);
    if (!isArray(errors)) {
      return '';
    }

    return t.staticAsString(errors[0]) as any;
  };

  onChange = (changes: { value: any }, e: React.SyntheticEvent) => {
    const { onBeforeChange, name } = this.props;
    const { lodashName } = this.state;

    if (!!onBeforeChange) {
      onBeforeChange(changes, e);
    }

    if (this.context.form) {
      this.context.form.onChange({ ...changes, name: lodashName });

      this.props.settings?.additional?.onChange?.({ ...changes, name: lodashName }, e);
    }

    if (this.context.form?.cleanErrorOnChange) {
      errorsStore.clean(this.context.form.id, name);
    }
  };

  render() {
    const { Component, name, disableScrollToError, watchable } = this.props;
    const value = this.value();
    const error = this.error();
    const autoCompleteOptions = this.generateAutoComplete();

    const component = (
      <Component
        watchable={watchable}
        onChange={this.onChange}
        value={value}
        errorMessage={error}
        {...autoCompleteOptions}
        {...this.inputProps()}
      />
    );

    if (this.context?.form?.withScroll) {
      return (
        <ScrollWrapper id={name} errorMessage={error} disabled={disableScrollToError}>
          {component}
        </ScrollWrapper>
      );
    }

    return component;
  }
}

export { InputWrapper };
