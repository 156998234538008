import { omit } from 'lodash';
import { action, observable, makeObservable } from 'mobx';

class LoadProgress {
  @observable multipleLoad = false;

  @observable loadingJobs = {};

  constructor() {
    makeObservable(this);
  }

  @action
  addLoadingJob(id: number, estimateSec: number, params: Object) {
    const job: LoadingJob = {
      upload: false,
      estimateSec,
      fileName: '',
      remainingSec: estimateSec,
      progress: 0,
      isFinished: false,
      isError: false,
      noConnection: false,
      isCancelled: false,
      onRetry: () => {},
    };
    this.loadingJobs[id] = { ...job, ...params };

    if (!this.multipleLoad) {
      this.multipleLoad = Object.keys(this.loadingJobs).length > 1;
    }
  }

  @action
  updateLoadingProgress(id: number, progress: number) {
    if (!this.loadingJobs[id] || this.loadingJobs[id].isError) return;

    let { estimateSec, remainingSec } = this.loadingJobs[id];

    let updProgress = this.loadingJobs[id].progress + progress;
    // If loading takes longer than expected, keep progress bar close to the end
    // Keep it like that untill finish status received
    if (updProgress >= 100) {
      updProgress = 99;
    }

    this.loadingJobs[id].progress = Math.round(updProgress);

    remainingSec = Math.round(estimateSec * (1 - updProgress / 100));

    this.loadingJobs[id].remainingSec = remainingSec;
  }

  @action
  finishLoadingProgress(id: number) {
    if (!this.loadingJobs[id] || this.loadingJobs[id].isError || this.loadingJobs[id].isCancelled) return;

    this.loadingJobs[id].progress = 100;
    this.loadingJobs[id].remainingSec = 0;
    this.loadingJobs[id].isFinished = true;

    this.delayedClosing(id);
  }

  @action
  cancelLoading(id: number) {
    this.loadingJobs[id].isCancelled = true;
    this.delayedClosing(id);
  }

  @action
  errorLoading(id: number) {
    this.loadingJobs[id].isError = true;
    this.delayedClosing(id);
  }

  @action
  connectionInterrupt(id: number) {
    this.loadingJobs[id].noConnection = true;
    this.delayedClosing(id, 20000);
  }

  @action
  onRetry(id: number) {
    this.loadingJobs[id].onRetry();
    this.delayedClosing(id, 0);
  }

  @action
  delayedClosing(id: number, delay: number = 2000) {
    setTimeout(() => {
      this.loadingJobs = omit(this.loadingJobs, `${id}`);

      if (Object.keys(this.loadingJobs).length === 0) {
        this.multipleLoad = false;
      }
    }, delay);
  }
}

export { LoadProgress };
export default new LoadProgress();
