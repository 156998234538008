import { observer } from 'mobx-react';
import { activeDayWorkout } from 'modules/miles/app/home/stores';
import { TrainingPlanType } from 'modules/miles/app/home/stores/trainingPlan';
import { WorkoutDifficultyLevel } from 'modules/miles/app/home/types';
import { animation } from 'modules/miles/app/home/ui/styled-components';
import { getStatusColor } from 'modules/miles/app/home/utils';
import { showRaceDayDetailStore } from 'modules/miles/app/stores';
import { Text } from 'modules/miles/shared/components';
import moment from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';

import { Show } from 'components/condition';

import { history } from 'utils';

const Container = styled.div<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}
  position: relative;
  cursor: pointer;
  transition: 0.6s ease;
  align-self: stretch;
  -webkit-tap-highlight-color: transparent;
  .status-icon {
    position: absolute;
    top: calc(50% - 8px);
    left: -5px;
    border-radius: 50%;
    color: ${(props) => props.theme.miles.colors.dark.icon.positive};
    background: ${(props) => props.theme.miles.colors.dark.background[700]};
    z-index: 1;
  }
`;

const Wrapper = styled.div<{ active?: boolean; isToday?: boolean; isBeforeToday?: boolean }>`
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, active, isToday }) => (active || isToday ? theme.miles.colors.dark.alpha['300'] : theme.miles.colors.dark.alpha['100'])};
  background: ${({ theme, isToday, active }) =>
    active
      ? theme.miles.colors.dark.alpha.cobalt500
      : isToday
      ? theme.miles.colors.dark.background[900]
      : theme.miles.colors.dark.background['600']};
  box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion2Strong};
  color: ${(props) => props.theme.miles.colors.dark.alpha['900']};
  &:hover {
    background: ${({ theme, active }) => (active ? theme.miles.colors.dark.alpha.cobalt500 : theme.miles.colors.dark.background['800'])};
  }

  ${animation}
  p {
    ${animation}
  }
  .date {
    position: relative;
    display: flex;
    width: 62px;
    border-right: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
    .box {
      padding: ${(props) => (props.active || props.isToday ? 8 : 7)}px 0;
      flex-grow: 1;
      text-align: center;
      ${animation}
      .number {
        ${(props) =>
          (props.active || props.isToday) &&
          css`
            font-size: 30px;
            font-weight: 500;
            line-height: 34px;
            letter-spacing: 0.6px;
          `}
      }
    }
  }
  .workout {
    text-align: left;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .difficulty-level {
      color: ${({ theme }) => theme.miles.colors.dark.alpha['700']};
    }
  }
  .interval {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
    color: ${({ theme }) => theme.miles.colors.dark.alpha['700']};
  }

  .points {
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.miles.colors.dark.alpha['700']};
    border-left: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
  }
  p {
    color: ${(props) => props.isBeforeToday && props.theme.miles.colors.dark.alpha['700']};
  }
`;

const Status = styled.div<{ level: WorkoutDifficultyLevel }>`
  width: 4px;
  height: 100%;
  background: ${(props) => getStatusColor(props.level)};
`;

const Dot = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: -3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => props.theme.miles.colors.dark.icon.primary};
  z-index: 1;
`;

type Props = {
  hasUpdate?: boolean;
  isWorkoutComplete?: boolean;
  trainData: TrainingPlanType;
  className?: string;
};

export const TrainDayCard = observer((props: Props) => {
  const { trainData, hasUpdate = false, className, isWorkoutComplete } = props;
  const active = activeDayWorkout.value?.id === trainData?.id;
  const isDisabled = trainData.name?.trim() === 'Rest';
  const today = moment(moment().format('YYYY-MM-DD'));
  const chosenDate = moment(trainData.date).format('YYYY-MM-DD');
  const isToday = moment(chosenDate).isSame(today);
  const isBeforeToday = moment(chosenDate).isBefore(today);

  const handleClickCard = () => {
    if (active) {
      activeDayWorkout.clear();
      history.replace(window.location.pathname);
    } else {
      activeDayWorkout.setValue(trainData);
      history.replace(`?date=${moment(trainData.date).format('YYYY-MM-DD')}`);
    }
    showRaceDayDetailStore.off();
  };

  return (
    <Container onClick={handleClickCard} isDisabled={false}>
      <Show if={hasUpdate}>
        <Dot />
      </Show>
      <Wrapper active={active} className={className} isToday={isToday} isBeforeToday={isBeforeToday}>
        <div className='date'>
          <Status level={trainData.difficultyLevel} />
          <div className='box'>
            <Text weight={active || isToday ? 'bold' : 'regular'} size={active || isToday ? 'md' : 'sm'}>
              {moment(trainData.date).format('ddd')}
            </Text>
            <Text className='number' weight='regular' size='lg'>
              {moment(trainData.date).format('DD')}
            </Text>
          </div>
        </div>
        <div className='workout'>
          <Text className='name' weight={active || isToday ? 'heavy' : 'bold'} size={active || isToday ? 'md' : 'sm'}>
            {trainData.name}
          </Text>
          <Text className='difficulty-level' weight='regular' size={active || isToday ? 'md' : 'sm'}>
            {trainData.difficultyLevel}
          </Text>
        </div>
        <div className='interval'>
          <Text weight='regular' size={active || isToday ? 'md' : 'sm'}>
            {trainData.time}
          </Text>
        </div>
      </Wrapper>
    </Container>
  );
});
