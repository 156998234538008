import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { NoResults as NoResComponent } from 'src/modules/profile/components/NoResults';

import { currentDistance, count } from '../../derivations';

const StyledNoResults = styled(NoResComponent)`
  display: flex;
  justify-content: center;

  margin: 56px 0;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

export const NoResults: React.FC<Props> = (props) => {
  return (
    <Observer>
      {() => {
        if (Number(count.get()) > 0) return <>{props.children}</>;

        const distance = currentDistance.model.get();
        const tKey = distance ? 'race.noResultDistance' : 'race.noResultRace';

        return <StyledNoResults message={tKey} />;
      }}
    </Observer>
  );
};
