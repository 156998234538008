import axios from 'axios';
import { isNumber } from 'lodash';

import { RDI } from 'src/constants';

import { app } from 'utils';

const VERSION_HTML_ELEMENT = 'version';

class AppVersionControl {
  currentVersion(): number | null {
    const version = document.getElementById(VERSION_HTML_ELEMENT);

    if (!version) {
      return null;
    }

    return parseInt(version.dataset.version as string, 10);
  }

  setCurrentVersionForApp() {
    const version = this.currentVersion();

    if (!isNumber(version)) {
      return;
    }

    app.assign('version', version);
    axios.defaults.headers.common[RDI] = version;
  }
}

const appVersionControl = new AppVersionControl();

export { AppVersionControl, appVersionControl };
