import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const SleepPath = (
  <>
    <rect id='Rectangle 5597' x='2.5' y='11' width='2' height='11' fill='currentColor' />
    <rect id='Rectangle 5600' x='20.5' y='20' width='2' height='2' fill='currentColor' />
    <rect id='Rectangle 5598' x='4.5' y='17' width='18' height='3' fill='currentColor' />
    <path
      id='Rectangle 5599'
      d='M10.5 15C10.5 13.8954 11.3954 13 12.5 13H18.5C20.7091 13 22.5 14.7909 22.5 17H10.5V15Z'
      fill='currentColor'
    />
    <circle id='Ellipse 128' cx='7.5' cy='14' r='2' fill='currentColor' />
    <path id='Vector' d='M11.5 11H16.5V9H14.5L16.5 7V5H11.5V7H13.5L11.5 9V11Z' fill='currentColor' />
    <path id='Vector_2' d='M17.5 8H22.5V6H20.5L22.5 4V2H17.5V4H19.5L17.5 6V8Z' fill='currentColor' />
  </>
);

export const Sleep = (props: IconProps) => <SvgIcon path={SleepPath} {...props} />;
