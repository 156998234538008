import { ReactNode } from 'react';

export type ButtonProps = {
  disabled?: boolean;
  isOutlined?: boolean;
  onClick?: AnyFunction;
  fullWidth?: boolean;
  children?: ReactNode;
  message: keyof typeof Message;
  size: keyof typeof SizesButton;
  iconRight?: boolean;
  iconLeft?: boolean;
  iconName?: string;
  style?: any;
  type?: 'button' | 'submit' | 'reset';
};

export enum Mode {
  light = 'light',
  dark = 'dark',
  brand = 'brand',
}

export enum Message {
  main = 'main',
  mainSwap = 'mainSwap',
  subtle = 'subtle',
  negative = 'negative',
}

export enum SizesButton {
  xxl = 'xxl',
  xl = 'xl',
  l = 'l',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  xxs = 'xxs',
  xxxs = 'xxxs',
}
