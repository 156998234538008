import React, { FC } from 'react';
import styled from 'styled-components';

import { getBackgroundColor, getBorderColor, InputWrapComponent } from '../../InputTools';
import { TimeFieldComponent } from './TimeFieldComponent';

type Props = BaseInputProps & {
  maxHours: number;
};

const StyledTimeInput = styled(TimeFieldComponent)<{ hasError?: boolean; isWarning?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 52px;
  flex: 1;

  padding: 12px 16px;

  background: ${getBackgroundColor};
  border-radius: 2px;
  border: 2px solid ${getBorderColor('transparent')};

  font-family: Aeonik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  line-height: 32px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.main.colors.clay1};
  ${(props) => (props.disabled ? 'pointer-events: none;' : '')}

  &:hover,
  &:focus {
    outline: none;
    border-color: ${getBorderColor()};
  }

  &::placeholder {
    color: ${(props) => props.theme.main.colors.clay4};
  }

  &:disabled {
    color: ${(props) => props.theme.main.colors.clay4};
  }
`;

export const CombinedTimeField: FC<Props> = (props) => {
  const { requiredMark, errorMessage, label, name, disabled, onChange, maxHours, ...rest } = props;

  return (
    <InputWrapComponent errorMessage={errorMessage} disabled={disabled} label={label} requiredMark={requiredMark} name={name}>
      <StyledTimeInput
        {...rest}
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
        hasError={Boolean(errorMessage)}
        disabled={disabled}
        maxHours={maxHours}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.currentTarget;
          onChange({ name, value }, e);
        }}
      />
    </InputWrapComponent>
  );
};
