import { Observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/topographic-pattern.svg';
import { RESOLUTIONS, LOAD_RACE } from 'src/constants';
import { RaceControls, RaceNavigationTabs } from 'src/modules/race/components';
import { RaceTeaser } from 'src/modules/race/components/teaser';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { PageContent } from 'src/styledComponents/PageContent';
import { Skeleton } from 'src/styledComponents/Skeleton';

import { progressStore, racesStore, windowSize } from 'stores';

import { RaceInfoSkeleton } from './RaceInfoSkeleton';

type Props = RouteComponentProps & {};

const PageWrapper = styled.div<{ isMobile: boolean }>`
  ${(props) => (props.isMobile ? 'overflow-x: hidden' : '')};
  background-color: ${(props) => props.theme.main.colors.white};
  background-image: ${(props) => (props.isMobile ? 'none' : "url('" + PatternImage + "')")};
`;

const SkeletonWrapper = styled.div`
  @media (max-width: ${RESOLUTIONS.medium}px) {
    > section:first-child {
      padding-top: 32px;
    }
  }
`;

const NavigationSkeleton = styled(PageContent)`
  background: ${(props) => props.theme.main.colors.white};

  .section-content {
    > div {
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 16px 0;

      > div:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
`;

const MobileWrapper = styled.div`
  margin: 0 20px;
`;

const RaceInfoComponent = (props: Props): ReactElement | null => {
  const racesModel: AnyObject = racesStore.modelSelectedValue || {};
  const value = racesModel && racesModel.value;
  const isMobile = windowSize.isLessThan('small');

  if (!value) {
    return null;
  }

  return (
    <Observer>
      {() => {
        const isLoading = progressStore.isLoading(LOAD_RACE);

        if (isLoading) {
          return (
            <SkeletonWrapper>
              <RaceInfoSkeleton />
              <NavigationSkeleton>
                <div>
                  <Skeleton width={65} height={20} variant='text' />
                  <Skeleton width={94} height={20} variant='text' />
                  <Skeleton width={30} height={20} variant='text' />
                  <Skeleton width={75} height={20} variant='text' />
                  <Skeleton width={60} height={20} variant='text' />
                </div>
              </NavigationSkeleton>
            </SkeletonWrapper>
          );
        }

        return (
          <PageWrapper isMobile={isMobile}>
            <PageContent>
              <RaceControls />
              <RaceTeaser />
              <ShowDesktop>
                <RaceNavigationTabs />
              </ShowDesktop>
            </PageContent>
            <ShowMobile>
              <MobileWrapper>
                <RaceNavigationTabs />
              </MobileWrapper>
            </ShowMobile>
          </PageWrapper>
        );
      }}
    </Observer>
  );
};

export const RaceInfo = withRouter(RaceInfoComponent);
