class RaceUtil {
  humanizeDistance = (meters: number = 0): string => {
    return meters < 1000 ? `${meters} m` : `${meters / 1000} km`;
  };

  isDistanceSoldOut = (distance: DistanceType): boolean => {
    if (distance.race_qty === null) {
      return false;
    }
    if (distance.type !== 'team') {
      return distance.racers_count >= distance.race_qty;
    }

    return distance.teams_count >= distance.race_qty;
  };
}

const raceUtil = new RaceUtil();
export { raceUtil };
