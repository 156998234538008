import React, { useState } from 'react';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';

import { Modal } from 'components/modal';

import { useOnClickOutside } from 'utils';

import { windowSize } from 'stores';

import { CalendarWrapper } from './styled';

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

const Wrapper = styled.div<{ isSwipeEnd: boolean }>`
  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }
`;

export const CalendarModal = ({ isOpened, children, onClickOutside }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isSwipeEnd, setIsSwipeEnd] = useState(false);
  const isMobile = !windowSize.isGreaterThan('medium');

  useOnClickOutside(ref, () => {
    onClickOutside();
  });

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const handleTransitionEnd = () => {
    if (isSwipeEnd) onClickOutside();
  };

  return (
    <Modal open={isOpened}>
      <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={handleTransitionEnd}>
        <CalendarWrapper showCalendarAsModal>
          {isMobile && (
            <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
              <DraggableStroke />
            </Swipe>
          )}
          {children}
        </CalendarWrapper>
      </Wrapper>
    </Modal>
  );
};
