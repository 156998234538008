import { observable, action, makeObservable } from 'mobx';

import { Data } from '../types';

/**
 * @description
 * loaded transfer data
 * and loading status
 */
export class TransferStore {
  @observable
  tokenData: Data.Backend.RacerToReceive | null = null;
  @observable
  transferSettings: Data.Backend.TransferSettings | null = null;
  @observable
  isTransferred: boolean = false;
  @observable
  invalidTransferTokenStatus: number | nil;
  @observable
  token: string | nil;

  constructor() {
    makeObservable(this);
  }

  @action
  setTokenData(token: string, data: Data.Backend.RacerToReceive | null, settings: Data.Backend.TransferSettings | null): void {
    this.tokenData = data;
    this.transferSettings = settings;
    this.isTransferred = true;
    this.token = token;
  }

  @action
  setInvalidInviteTokenStatus(status: number) {
    this.isTransferred = true;
    this.invalidTransferTokenStatus = status;
    this.token = null;
  }

  @action
  clean(): void {
    this.tokenData = null;
    this.transferSettings = null;
    this.isTransferred = false;
    this.invalidTransferTokenStatus = 0;
    this.token = null;
  }
}
