import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';
import { Utility, BodyText } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

const Wrapper = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: white;

  transition: all 0.3s ease;
  transform: ${(props) => (props.open ? 'initial' : 'translateY(100%)')};
`;

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 58px;
  width: 100%;
  padding: 0 19px 0 21px;

  border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
`;

const Clear = styled(Utility)`
  position: relative;
  font-weight: 500;

  &:after {
    position: absolute;
    bottom: 1px;
    left: 0;
    z-index: 5;

    content: '';
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.main.colors.clay1};
  }
`;

const OptionsWrap = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;

  height: calc(100% - 58px - 90px);
  width: 100%;
  overflow-y: auto;
`;

const Option = styled.div<{ active?: boolean }>`
  padding: 16px 21px;
  background-color: ${(props) => (props.active ? props.theme.main.colors.clay6 : 'initial')};

  &:hover {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }
`;

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
  border: none;
  background-color: ${(props) => props.theme.main.colors.clay3};
`;

const StyledConfirmText = styled(Utility)`
  color: white;
`;

type Props = BaseInputProps & {
  open: boolean;
  options: DropdownItemType[];
  confirmBtnText: string;
  onClose: AnyFunction;
};

export const MobileMenu: React.FC<Props> = (props) => {
  const { open, label, options, confirmBtnText, value, onChange, name, onClose } = props;
  const [optionToChoose, changeOption] = React.useState<DropdownItemType | null>(value || null);

  const handleChoose = (item: DropdownItemType) => () => {
    changeOption(item);
  };

  const handleSearch = () => {
    onChange({ name, value: optionToChoose });
    onClose();
  };

  const handleClear = () => {
    onChange({ name, value: null });
    onClose();
  };

  return (
    <Wrapper open={open}>
      <MenuHeader>
        <Svg name='Cross' size={16} onClick={onClose} {...testAnchors.useButton('close')} />
        <Utility variant='u2' weight='bold'>
          {label}
        </Utility>
        <Clear variant='u2' weight='medium' onClick={handleClear} {...testAnchors.useButton('clear')}>
          {t('settings.clear')}
        </Clear>
      </MenuHeader>
      <OptionsWrap>
        {options.map((option) => (
          <Option key={option.key} active={optionToChoose?.key === option.key} onClick={handleChoose(option)}>
            <BodyText variant='body1'>{option.label}</BodyText>
          </Option>
        ))}
      </OptionsWrap>
      <ConfirmButton onClick={handleSearch} {...testAnchors.useButton('submit')}>
        <StyledConfirmText variant='u1' weight='bold'>
          {confirmBtnText}
        </StyledConfirmText>
      </ConfirmButton>
    </Wrapper>
  );
};
