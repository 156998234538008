import React, { FC } from 'react';

import { Utility } from 'src/styledComponents/Typography';

import { localeStore } from 'stores';

import { Language, LanguageItem } from '../components';

type Props = {
  onChange: (value: availableLocales) => void;
  option: { title: string; value: availableLocales };
};

export const Item: FC<Props> = ({ onChange, option }) => (
  <LanguageItem active={localeStore.value === option.value} key={option.value} onClick={() => onChange(option.value)}>
    <Language variant='u2' weight='bold'>
      {option.title}
    </Language>

    <Utility variant='u2' weight='medium'>
      ({option.value})
    </Utility>
  </LanguageItem>
);
