import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

type Props = {
  text: string;
  onCancel?: AnyFunction;
  showCloseIcon?: boolean;
};

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  position: relative;

  .title {
    color: ${(props) => props.theme.main.colors.white};
    white-space: nowrap;
    width: 350px;
  }
  .icon-wrapper {
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
  }
`;

export const Title = (props: Props) => {
  const { text, onCancel = () => {}, showCloseIcon = false } = props;

  return (
    <Wrapper>
      <Utility variant='u2' weight='medium' className='title'>
        {text}
      </Utility>
      <Show if={showCloseIcon}>
        <Icon className='icon-wrapper' name='AlertClose' size='24' onClick={onCancel} {...testAnchors.useButton('close')} />
      </Show>
    </Wrapper>
  );
};
