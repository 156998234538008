import { ParrentModel } from './parrentModel';

class Checkpoint<ExtendedCheckpoint = {}> extends ParrentModel<CheckpointType & ExtendedCheckpoint> {
  toSelectItem() {
    const { id, name } = this.value;
    return { id, title: name };
  }
}

export { Checkpoint, Checkpoint as CheckpointModel };
