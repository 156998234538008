import { observer } from 'mobx-react';
import * as React from 'react';

import { PulseLoader } from 'src/styledComponents/Loader/PulseLoader';

import { progressStore } from 'stores';

type Props = {
  action?: string;
  opacity?: number;
  show?: boolean;
};

@observer
export class Loading extends React.Component<Props> {
  static defaultProps = {
    opacity: 0.7,
  };

  styles() {
    const { opacity } = this.props;
    return { opacity };
  }

  render() {
    const { action, show } = this.props;
    const showLoader = show || progressStore.isLoading(action || '');

    if (!showLoader) {
      return null;
    }

    return (
      <div className='loading-overlay' style={this.styles()}>
        <PulseLoader />
      </div>
    );
  }
}
