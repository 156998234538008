import * as React from 'react';
import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants/general/settings';

const baseStyles = `
  font-family: 'Aeonik', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.01em;
`;

const h1 = css`
  ${baseStyles};
  font-size: 40px;
  line-height: 44px;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 64px;
    line-height: 68px;
  }
`;

const h2 = css`
  ${baseStyles};
  font-size: 32px;
  line-height: 36px;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 48px;
    line-height: 52px;
  }
`;

const h3 = css`
  ${baseStyles};
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

const h4 = css`
  ${baseStyles};
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.main.colors.clay1};

  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const H1 = styled.h1`
  ${h1}
`;

const H2 = styled.h2`
  ${h2}
`;

const H3 = styled.h3`
  ${h3}
`;

const H4 = styled.h1`
  ${h4}
`;

type Props = {
  variant: keyof typeof Variants;
  className?: string;
  children?: React.ReactNode;
};

enum Variants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
}

export const Headline: React.FC<Props> = (props) => {
  const { children, variant, className } = props;

  switch (variant) {
    case Variants.h1:
      return <H1 className={className}>{children}</H1>;

    case Variants.h2:
      return <H2 className={className}>{children}</H2>;

    case Variants.h3:
      return <H3 className={className}>{children}</H3>;

    case Variants.h4:
      return <H4 className={className}>{children}</H4>;

    default:
      return <H1 className={className}>{children}</H1>;
  }
};

export const HeadlineStyles = {
  h1,
  h2,
  h3,
  h4,
};
