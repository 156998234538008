import { localeStore } from 'stores';

import { ParrentModel } from './parrentModel';

class SportType extends ParrentModel<SportTypesType> {
  get title(): string {
    if (!this.value) {
      return '';
    }

    const locale = localeStore.value;
    const defaultValue = this.value.title_en;

    let title = '';
    switch (locale) {
      case 'en':
        title = this.value.title_en;
        break;
      case 'sv':
        title = this.value.title;
        break;
      default:
        title = this.value.title_en;
    }
    return title || defaultValue || '';
  }
}

export { SportType };
