import { first } from 'lodash';

import { getRaceId } from './getRaceId';
import { distanceSelect } from './selectOptions';

function getDefaultDistanceId(): null | number {
  const raceId = getRaceId();
  const distancesOptions = distanceSelect.options.get();
  const isSingleDistance = distancesOptions.length === 1;
  const isAbuHardcodeEnabled = raceId === 5153; // Abu hardcode

  if (isAbuHardcodeEnabled || isSingleDistance) {
    return first(distancesOptions)?.value || null;
  }

  return null;
}

export { getDefaultDistanceId };
