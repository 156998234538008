import * as React from 'react';

import { footerStore } from 'src/modules/footer/stores';
import { FooterStore } from 'src/modules/footer/types';

export const useFooterSettings = (settings: FooterStore) => {
  React.useEffect(() => {
    footerStore.setValue({
      ...footerStore.value,
      slim: settings?.slim,
      hide: settings?.hide,
    });

    return () => {
      footerStore.clear();
    };
  }, []);
};
