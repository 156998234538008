import { computed } from 'mobx';

import { filtersStore, distancesStore, checkpointsStore } from '../stores';

import { DistancePlaceColumn } from '../types';
import { generateCheckpointId } from './generateCheckpoint';

function distancePlaceColumn(distanceId: number) {
  return computed((): DistancePlaceColumn | never => {
    const distance = distancesStore.find(distanceId);
    const filters = filtersStore.value;

    if (!distance) {
      throw 'Distance must be present';
    }

    const checkpoint = checkpointsStore.find(generateCheckpointId(distanceId).get() || 0);

    const isClass = Boolean(filters.class_id);
    const isWave = Boolean(filters.wave_id);
    const isGender = Boolean(filters.gender);
    const isSingle = distance.isSingle();
    const isNotLastCheckpoint = Boolean(checkpoint?.notLast());
    const isTeam = !isSingle;

    if (isSingle) {
      if (!isNotLastCheckpoint) {
        if (isGender && !isClass && !isWave) {
          return 'gender_place';
        }

        if (!isGender && isClass && !isWave) {
          return 'class_place';
        }

        if (!isGender && !isClass && isWave) {
          return 'wave_place';
        }

        if (isGender && isClass && !isWave) {
          return 'gender_class_place';
        }

        return 'place';
      }

      if (isNotLastCheckpoint) {
        if (isGender && !isClass && !isWave) {
          return 'splits.gender_place';
        }

        if (!isGender && isClass && !isWave) {
          return 'splits.class_place';
        }

        if (!isGender && !isClass && isWave) {
          return 'splits.wave_place';
        }

        if (isGender && isClass && !isWave) {
          return 'splits.gender_class_place';
        }

        return 'splits.place';
      }
    }

    if (isTeam) {
      if (!isNotLastCheckpoint) {
        if (isClass) {
          return 'class_place';
        }

        if (isWave) {
          return 'wave_place';
        }

        return 'place';
      }

      if (isNotLastCheckpoint) {
        if (isClass) {
          return 'splits.class_place';
        }

        if (isWave) {
          return 'splits.wave_place';
        }

        return 'splits.place';
      }
    }

    throw `Unexpected combination, please handle ${JSON.stringify({ isSingle, isTeam, isGender, isClass, isNotLastCheckpoint })}`;
  });
}

export { distancePlaceColumn };
