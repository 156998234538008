import { app } from './app';

function handleMissingChunk() {
  window.onerror = function (msg: string, _url: any, _line: any, _col: any, error: any) {
    if (msg === 'SyntaxError' && error === "Unexpected token '<'") {
      app.reload();
    }
  };
}

export { handleMissingChunk };
