import { Observer, observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { ShowDesktop } from 'src/styledComponents/Conditions';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { progressStore } from 'stores';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FavouriteIcon = styled(Svg)<{ isFollow: boolean; noMargin?: boolean; className?: string }>`
  margin: ${(props) => (props.noMargin ? '0' : '0 8px 0 32px')};

  span {
    color: ${(props) => props.theme.main.colors.clay3};
  }

  ${(props) =>
    !props.isFollow &&
    css`
      svg {
        path:last-child {
          fill: ${(props) => props.theme.main.colors.white};
        }
      }
    `}

  ${(props) =>
    props.isFollow &&
    css`
      svg {
        path:first-child {
          fill: ${(props) => props.theme.main.colors.clay1};
        }
        path:last-child {
          fill: ${(props) => props.theme.main.colors.auroraLemon};
        }
      }
    `}
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
`;

type Props = {
  isFollow: boolean;
  actionString?: string;
  handleClick: () => void;
  noMargin?: boolean;
};

export const WhishAddComponent = (props: Props) => {
  const { isFollow = false, handleClick, actionString, noMargin } = props;
  const ATN = { ...testAnchors.useButton('wishAdd') };

  return (
    <Observer>
      {() => {
        const disabled = actionString ? progressStore.isLoading(actionString) : false;

        return (
          <Wrapper
            className='wish'
            onClick={() => {
              if (!disabled) {
                handleClick();
              }
            }}
          >
            <FavouriteIcon noMargin={noMargin} name='Favourite' {...ATN} isFollow={isFollow} />

            <ShowDesktop>
              <StyledUtility variant='u3' weight='bold'>
                {isFollow ? t.staticAsString('registration.saved') : t.staticAsString('registration.save')}
              </StyledUtility>
            </ShowDesktop>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
