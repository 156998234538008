import { API_LOCATIONS_URL, API_URL, LOAD_RACES, LOCATION } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { locationsStore, Location } from 'stores';

class Locations extends LoadableService<LocationType, FiltersType, Location> {
  constructor(store: Location) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_LOCATIONS_URL}`,
      resource: LOCATION,
    });
  }

  @request({ action: LOAD_RACES })
  async loadResourcesRequest(params: FiltersType | {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_RACES, minRequestTime: 300 })
  async loadResourcesSearch(params: FiltersType = {}): Promise<any> {
    let { filters, page } = this.store || {};
    const newParams = this.getSearchModelParams(params);
    const queryParams = {
      ...filters,
      ...newParams,
      page,
    };

    const [status, response] = await this.loadResourcesRequest(queryParams);

    if (status) {
      this.store.appendValues(response.data.data, page, queryParams);
    }
  }
}

const locationsService = new Locations(locationsStore);

export { Locations, locationsService };
