import classNames from 'classnames';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Loading } from 'src/components/loading';
import { Spinner } from 'src/components/spinner';
import { PulseLoader } from 'src/styledComponents/Loader/PulseLoader';

import { progressStore } from 'stores';

type Options = {
  hideClassName: string;
  spinner?: string;
  withOverlay?: boolean;
};

const withProgressSpinner = (
  action: string | string[],
  options: Options = {
    hideClassName: 'hidden',
  },
) => {
  return (WrappedComponent: any) => {
    const actions = [...(isArray(action) ? action : [action])];
    const spinnerType = options.spinner || Spinner.types.default;

    return observer(
      class extends React.Component<any> {
        render() {
          const isLoading = actions.some((action) => progressStore.isLoading(action || ''));

          if (options.withOverlay) {
            return (
              <React.Fragment>
                <Loading show={isLoading} />
                <div className='loader'>
                  <WrappedComponent {...this.props} />
                </div>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment>
              {isLoading && <PulseLoader />}
              <div className={classNames('loader', { [options.hideClassName]: isLoading })}>
                <WrappedComponent {...this.props} />
              </div>
            </React.Fragment>
          );
        }
      },
    );
  };
};

export { withProgressSpinner };
