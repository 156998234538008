import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { mapDistances, mapRaces } from 'modules/home/utils';

import { API_USER_REGISTERED_RACES, API_WISHLIST, API_RACES_URL, API_URL, API_V2_URL } from 'src/constants';

import { action, request, toastService, history, encodeQuery } from 'utils';

import { wishlistsService, userService, articlesService } from 'services';

import { sessionStore, wishlistsStore } from 'stores';

import {
  REGISTERED_TO_PROFILE,
  SAVED_RACES_PROFILE,
  XTRI_TICKETS_PROFILE,
  CHANGE_WISHLIST,
  INITIALIZE_PROFILE,
  LOAD_MY_RESULTS,
  registeredTo,
  saved,
  xtri,
  my_results,
} from '../constants';

import { tabStore, resultStore, xtriStore } from '../stores';

import { userResultsStore } from '.';
import { XtriTicket } from '../types';
import { registeredToService } from './registeredTo';
import { myResultsService } from './results';
import { xtriService } from './xtri';

class Profile {
  ballotQuery = {
    search: 'race_union_id:2',
    searchFields: 'race_union_id:=',
    searchJoin: 'AND',
    with: 'distances',
    limit: 10,
    page: 1,
  };

  params: { limit: number } = {
    limit: 20,
  };

  @action({ action: INITIALIZE_PROFILE })
  async initializeProfile() {
    await Promise.all([
      userService.loadUserProfile(),
      articlesService.loadResources({
        limit: 1,
      }),
      xtriService.loadUnions(),
      this.getBallot(),
      userResultsStore.loadUserResults(),
    ]);

    const user = sessionStore.userModel;

    let isXtri = false;

    if (!!user) {
      isXtri = user.isXtriMember();
    }

    const { search } = history.location;
    const query = encodeQuery.parseQuery(search);
    tabStore.changeTabPosition(isXtri);
    const activeTab = tabStore.tabList.find((item) => item.id === query.tab) || registeredTo;

    tabStore.changeActiveTab(activeTab.id);
    this.loadActiveTab();
  }

  loadActiveTab() {
    switch (tabStore.activeTab as any) {
      case registeredTo.id:
        this.loadRegistered();
        break;

      case saved.id:
        this.loadSaved();
        break;

      case xtri.id:
        this.loadXtri();
        break;

      case my_results.id:
        this.loadMyResults();
        break;
    }
  }

  @request({ action: REGISTERED_TO_PROFILE })
  async loadRegisteredRequest(): Promise<Object> {
    return axios.get(`${API_URL}${API_USER_REGISTERED_RACES}`);
  }

  @action({ action: REGISTERED_TO_PROFILE, minRequestTime: 800 })
  async loadRegistered(page = 1) {
    const { data, meta } = await registeredToService.load(page);

    if (tabStore.activeTab !== registeredTo.id) {
      return;
    }

    tabStore.changeValues(mapDistances(data), meta);
  }

  @request({ action: SAVED_RACES_PROFILE, authOnly: true })
  async loadSavedRequest(page): Promise<any> {
    const params = {
      ...this.params,
      with: 'distances;location;sport',
      from: (page - 1) * this.params.limit,
      page,
    };
    return axios.get(`${API_URL}${API_WISHLIST}`, { params });
  }

  @action({ action: SAVED_RACES_PROFILE, minRequestTime: 800 })
  async loadSaved(page = 1) {
    const [status, response] = await this.loadSavedRequest(page);

    if (tabStore.activeTab !== saved.id) {
      return;
    }

    if (status) {
      const data = mapRaces(response.data.data);
      tabStore.changeValues(data, response.data.meta.pagination);
    }
  }

  @action({ action: XTRI_TICKETS_PROFILE, minRequestTime: 800 })
  async loadXtri() {
    const user = sessionStore.getUserData();
    const tickets: XtriTicket[] = sortBy(user.xtriTickets || [], ['participating_year'])
      .reverse()
      .map((ticket) => {
        const race_union = (user.raceUnions || []).find((union) => ticket.race_union_id === union.id) || {};

        return {
          ...ticket,
          race_union,
        };
      });
    const sortedUnions = sortBy(user.raceUnions, ['id'])
      .map((union) => union.name)
      .reverse();

    tabStore.changeValues(tickets);
    tabStore.changeOrder(sortedUnions);
  }

  @action({ action: LOAD_MY_RESULTS })
  async loadMyResults(): Promise<void> {
    const [classic, virtual, cumulative] = await Promise.all([
      myResultsService.loadClassic(),
      myResultsService.loadVirtual(),
      myResultsService.loadCumulative(),
    ]);

    if (tabStore.activeTab !== my_results.id) {
      return;
    }

    resultStore.setClassic(mapDistances(classic.data), classic.meta.pagination);
    resultStore.setVirtual(mapDistances(virtual.data), virtual.meta.pagination);
    resultStore.setCumulative(mapDistances(cumulative.data), cumulative.meta.pagination);
    tabStore.changeValues([...classic.data, ...virtual.data, ...cumulative.data]);
  }

  @action({ action: CHANGE_WISHLIST })
  async changeWishList(id: number) {
    const isDeleted = (tabStore.deletedValues as any)[id];

    if (!isDeleted) {
      wishlistsStore.removeFromWishList(id);

      const [status, response] = await wishlistsService.removeRaceFromFavoriteRequest({
        id,
      });

      if (status) {
        tabStore.removeValue(id);
        wishlistsService.loadResources();
        this.loadSaved(tabStore.valuesMeta?.current_page);
      } else {
        toastService.error(response.data.message);
      }
    } else {
      wishlistsStore.addToWishList(id);

      const [status, response] = await wishlistsService.addRaceToFavoriteRequest({
        id,
      });

      if (status) {
        tabStore.unremoveValue(id);
        wishlistsService.loadResources();
        this.loadSaved(tabStore.valuesMeta?.current_page);
      } else {
        toastService.error(response.data.message);
      }
    }
  }

  // @ts-ignore
  @request({ action })
  async getBallotRequest(): Promise<any> {
    return axios.get(`${API_V2_URL}${API_RACES_URL}`, {
      params: this.ballotQuery,
    });
  }

  // @ts-ignore
  @action({ action })
  async getBallot(): Promise<any> {
    const [status, response] = await this.getBallotRequest();

    if (status) {
      const ballot = response.data.data[0];
      tabStore.setBallotRace(ballot);
    }
  }
}

const profileService = new Profile();

export { profileService };
