import { isString } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';
import { Truncate } from 'src/styles';

import { isLastColumn } from '../../utils';

import { RightSideColumnsType } from '../../types';

type CellType = {
  name: string;
  value?: string | React.ReactNode;
  minWidth?: string;
  weight?: 'medium' | 'bold';
  columnsActive: RightSideColumnsType;
};

const StyledUtility = styled(Utility)<{ minWidth?: string; alignRight?: boolean }>`
  color: ${(props) => props.theme.main.colors.clay2};
  text-transform: uppercase;
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  min-width: ${(props) => props.minWidth};

  ${Truncate}
`;

export const Cell: React.FC<CellType> = (props) => {
  const { value = '', name, minWidth, columnsActive, weight = 'medium' } = props;
  const condition = columnsActive[name];

  if (!condition) return null;

  const alignRight = isLastColumn(name, columnsActive);
  const title = isString(value) ? value : '';

  return (
    <StyledUtility variant='u4' weight={weight} title={title} minWidth={minWidth} alignRight={alignRight}>
      {value}
    </StyledUtility>
  );
};
