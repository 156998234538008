import classNames from 'classnames';
import { onOpenCategory, onOpenLocation } from 'modules/race/actions';
import { Teaser } from 'modules/race/components/teaser';
import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Show } from 'src/components/condition';
import { SportTypesComponent } from 'src/components/sportTypes';
import { DEFAULT_RACE_IMAGE, RESOLUTIONS } from 'src/constants';
import { SocialIcons } from 'src/modules/race/components/teaser';
import { racesStore, windowSize } from 'src/stores';
import { ShowDesktop, ShowMobile, ShowTablet } from 'src/styledComponents/Conditions';
import { Icon } from 'src/styledComponents/Icons';
import { RaceImage } from 'src/styledComponents/ImageWithProportions';
import { Headline, Utility } from 'src/styledComponents/Typography';
import { getImageSize } from 'src/utils';
import { t } from 'src/utils';

type Props = RouteComponentProps & {};

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: flex-start;
  padding: 0;
  margin-bottom: 24px;

  @media (min-width: calc(${RESOLUTIONS.medium}px + 1px)) {
    margin-top: 24px;
    .race-info {
      width: 50%;
      .trivia {
        margin-bottom: 15px;
      }
    }
  }

  .race-info {
    max-width: 100%;
    h2 {
      margin: ${(props) => (props.isMobile ? '16px 0 24px' : '48px 0 20px')};
    }
    .race-description {
      margin-bottom: 40px;
      font-family: 'Aeonik', sans-serif;
      color: ${(props) => props.theme.main.colors.clay3};
    }
    .trivia {
      display: flex;
      flex-wrap: wrap;
      align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
      margin-bottom: ${(props) => (props.isMobile ? '0' : '40px')};
      flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
      padding: ${(props) => (props.isMobile ? '30px 0 0' : '0')};
    }
    .socialIconsText {
      margin-bottom: 12px;
      display: block;
    }

    @media (min-width: calc(${RESOLUTIONS.medium}px + 1px)) and (max-width: ${RESOLUTIONS.large}px) {
      width: 100%;
      .trivia {
        flex-direction: column;
        align-items: start;
        margi: 50px 0 14px;
      }
      .trivia-item {
        margin-bottom: 12px;
      }
      .header {
        margin: 0 0 20px;
      }
      .race-description {
        margin-bottom: 30px;
      }
    }

    .trivia-item {
      display: flex;
      align-items: center;
      margin-right: 29px;
      padding: ${(props) => (props.isMobile ? '0 0 10px' : '0')};
    }
    .trivia-item:last-child {
      margin-right: 0;
    }
    .trivia-icon {
      margin-right: 9px;
    }
    .trivia-icon-first {
      margin-right: 4px;
    }
    .clay3 {
      color: ${(props) => props.theme.main.colors.clay3};
    }
  }
  .dates-block {
    margin-left: 5px;
  }
`;

const ImageMobileWrapper = styled.div`
  width: 100vw;
  position: relative;
  left: -20px;
`;

const ImageDesktopWrapper = styled.div<{ isDesktop: boolean }>`
  width: ${(props) => (props.isDesktop ? '519px' : '100%')};
  margin-right: ${(props) => (props.isDesktop ? '48px' : '30px')};
`;

const TabletWrapper = styled.div`
  @media (min-width: calc(${RESOLUTIONS.medium}px + 1px)) and (max-width: ${RESOLUTIONS.large}px) {
    h2 {
      margin: 16px 0 24px;
    }
  }
`;

export const TabletLessThan920 = styled.div`
  @media (min-width: ${RESOLUTIONS.medium}px) and (max-width: 920px) {
    display: block;
  }
  display: none;
`;

export const TabletMoreThan920 = styled.div`
  @media (min-width: 921px) and (max-width: ${RESOLUTIONS.large}px) {
    display: block;
  }
  display: none;
`;

const TopWrapper = styled.div`
  span {
    color: ${(props) => props.theme.main.colors.clay3};
  }

  .socialIconsText {
    margin-bottom: 12px;
    display: block;
  }
`;

const SportTypeWrapper = styled.div`
  cursor: pointer;
`;

const LocationWrapper = styled.div`
  cursor: pointer;
`;

const RaceTeaserComponent = (props: Props): ReactElement | null => {
  const racesModel: AnyObject = racesStore.modelSelectedValue || {};
  const value = racesModel && racesModel.value;

  if (!value) {
    return null;
  }
  const dates = racesModel.compareDate();
  const isMobile = windowSize.isLessThan('medium');
  const isDesktop = windowSize.isGreaterThan('large');
  const variant = isMobile ? 'u2' : 'u3';

  return (
    <>
      <TopWrapper>
        <ShowTablet>
          <TabletWrapper>
            <Headline variant='h2' className='header'>
              <Teaser text={racesModel.value.name} length={500} />
            </Headline>
            <TabletLessThan920>
              <Utility weight='bold' variant='u4' className={classNames('clay3', 'socialIconsText')}>
                {t.staticAsString('race.teaser.shareThisRace')}
              </Utility>
              <SocialIcons />
            </TabletLessThan920>
          </TabletWrapper>
        </ShowTablet>
      </TopWrapper>

      <Wrapper isMobile={isMobile}>
        <ShowDesktop>
          <ImageDesktopWrapper isDesktop={isDesktop}>
            <RaceImage src={value.image || DEFAULT_RACE_IMAGE} />
          </ImageDesktopWrapper>
        </ShowDesktop>

        <div className='race-info'>
          <ShowDesktop withTablet>
            <Headline variant='h2'>
              <Teaser text={racesModel.value.name} length={500} />
            </Headline>
          </ShowDesktop>

          <ShowMobile>
            <Headline variant='h2'>
              <Teaser text={racesModel.value.name} length={500} />
            </Headline>
            <Utility weight='bold' variant='u4' className={classNames('clay3', 'socialIconsText')}>
              {t.staticAsString('race.teaser.shareThisRace')}
            </Utility>
            <SocialIcons />
            <ImageMobileWrapper>
              <RaceImage src={value.image || DEFAULT_RACE_IMAGE} />
            </ImageMobileWrapper>
          </ShowMobile>

          <div className='trivia'>
            <div className='trivia-item'>
              <Icon className='trivia-icon-first' name='DateCalendar' />
              {dates.map((date: string, index: number) => {
                if (dates[index - 1] === date) return null;
                return (
                  <Utility weight='bold' variant={variant} key={date} className='dates-block clay3'>
                    {date}
                    {index === 0 && dates.length > 1 ? ' -' : ''}
                  </Utility>
                );
              })}
            </div>

            <Show if={racesModel.cityOrCountry() !== ''}>
              <LocationWrapper className='trivia-item' onClick={onOpenLocation(racesModel.location)}>
                <Icon className='trivia-icon' name='Location' />
                <Utility weight='bold' variant={variant} className='clay3'>
                  {racesModel.cityOrCountry()}
                </Utility>
              </LocationWrapper>
            </Show>
            <SportTypeWrapper className='trivia-item' onClick={onOpenCategory(racesModel.value.sport.id)}>
              <div className='trivia-icon'>
                <SportTypesComponent sportIcon={racesModel.value?.sport?.icon || ''} />
              </div>
              <Utility weight='bold' variant={variant} className='clay3'>
                {racesModel.sport().title}
              </Utility>
            </SportTypeWrapper>
          </div>

          <ShowDesktop withTablet>
            <Utility weight='bold' variant='u4' className={classNames('clay3', 'socialIconsText')}>
              {t.staticAsString('race.teaser.shareThisRace')}
            </Utility>
            <SocialIcons />
          </ShowDesktop>

          <ShowTablet>
            <TabletMoreThan920>
              <Utility weight='bold' variant='u4' className={classNames('clay3', 'socialIconsText')}>
                {t.staticAsString('race.teaser.shareThisRace')}
              </Utility>
              <SocialIcons />
            </TabletMoreThan920>
          </ShowTablet>
        </div>
      </Wrapper>
    </>
  );
};

export const RaceTeaser = withRouter(RaceTeaserComponent);
