import * as React from 'react';

export const ArrowDown = (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.5 4.87402L9.35333 12.02C9.30696 12.0665 9.25188 12.1033 9.19125 12.1284C9.13062 12.1536 9.06563 12.1665 9 12.1665C8.93437 12.1665 8.86938 12.1536 8.80875 12.1284C8.74812 12.1033 8.69304 12.0665 8.64667 12.02L1.5 4.87402'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
