import { InternalFilters } from '../types';

function teamResults(value: InternalFilters) {
  let filters: AnyObject = {};

  if (value.name) {
    filters['q'] = value.name;
  }

  if (value.class_id) {
    filters['classes'] = value.class_id;
  }

  if (value.wave_id) {
    filters['waves'] = value.wave_id;
  }

  return filters;
}

function singleResults(value: InternalFilters) {
  let filters: AnyObject = {};

  if (value.gender) {
    filters['gender'] = value.gender;
  }

  if (value.name) {
    filters['q'] = value.name;
  }

  if (value.class_id) {
    filters['classes'] = value.class_id;
  }

  if (value.wave_id) {
    filters['waves'] = value.wave_id;
  }

  return filters;
}

function mixedResults(value: InternalFilters) {
  let filters: AnyObject = {};

  if (value.gender) {
    filters['gender'] = value.gender;
  }

  if (value.name) {
    filters['q'] = value.name;
  }

  if (value.class_id) {
    filters['classes'] = value.class_id;
  }

  if (value.wave_id) {
    filters['waves'] = value.wave_id;
  }

  return filters;
}

const internalBe = {
  singleResults,
  teamResults,
  mixedResults,
};

export { internalBe };
