import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Accordion } from 'src/styledComponents/Accordion';
import { HardElevation } from 'src/styledComponents/Elevation';
import { BodyText, HeadlineStyles } from 'src/styledComponents/Typography';

import { Form } from 'components/form';

export const Headline4Wrap = styled.div`
  ${HeadlineStyles.h4}
`;

export const StyledElevation = styled(HardElevation)`
  margin-bottom: 1px;
`;

export const BodyTextCookies = styled(BodyText)`
  text-align: left;
  color: ${(props) => props.theme.main.colors.clay2};
`;

export const StyledAccordion = styled(Accordion)`
  .heading-section {
    span {
      max-width: 100%;
      white-space: normal;
    }

    div > span {
      min-width: 35px;
    }

    & > img {
      display: none;
    }
  }

  svg {
    path:first-child {
      fill: ${(props) => props.theme.main.colors.clay3};
    }

    path:last-child {
      stroke: ${(props) => props.theme.main.colors.white};
    }
  }

  .margin-left {
    margin-left: 16px;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    .heading-section div > span {
      min-width: 53px;
    }
  }
`;

export const Container = styled.div<{ revertColorOrder?: boolean }>`
  max-width: 800px;
  width: 100%;
  padding-bottom: 19px;

  a {
    color: ${(props) => props.theme.main.colors.clay2};
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid ${(props) => props.theme.main.colors.clay2};
  }

  & > p {
    margin: 32px 20px 40px;
    text-align: start;
    @media (max-width: ${RESOLUTIONS.medium}px) {
      line-height: 22px;
    }
  }

  .content-text {
    color: ${(props) => props.theme.main.colors.clay2};
  }

  .content-text:not(:last-child) {
    margin-bottom: 32px;
    @media (max-width: ${RESOLUTIONS.medium}px) {
      margin-bottom: 22px;
    }
  }
  .accordion-body {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  ${StyledElevation} {
    ${StyledAccordion} {
      & > div:first-child {
        background: ${(props) => props.theme.main.colors.clay7};
        padding: 20px;
      }

      .heading-text {
        color: ${(props) => props.theme.main.colors.clay1};
        font-weight: 700;
      }
    }

    &:nth-child(${(props) => (props.revertColorOrder ? 'odd' : '2n')}) ${StyledAccordion} > div:first-child {
      background: ${(props) => props.theme.main.colors.clay6};
    }
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-bottom: 40px;

    & > p {
      margin: 48px 0;
      text-align: center;
    }

    ${StyledElevation} ${StyledAccordion} {
      & > .content-text:first-child {
        padding: 20px 40px;
      }
    }
  }
`;

export const Description = styled.div`
  text-align: start !important;
  margin: 24px 20px 40px !important;

  p {
    color: ${(props) => props.theme.main.colors.clay2};

    &:not(last-child) {
      margin-bottom: 32px;
    }
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin: 56px 0 48px !important;
  }
`;

export const HeaderBox = styled.div`
  display: block;
  padding: 0 20px;

  span {
    padding-top: 8px;
    color: ${(props) => props.theme.main.colors.clay3};
    font-weight: 500;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 0;

    span {
      padding: 0;
    }
  }
`;

export const CookiesStyledAccordion = styled(StyledAccordion)`
  .heading-section {
    .heading-indentation {
      margin-left: 16px;
      margin-right: 310px;
    }
    @media (max-width: 820px) {
      .heading-indentation {
        margin-right: 280px;
      }
    }
    @media (max-width: 780px) {
      .heading-indentation {
        margin-right: 250px;
      }
    }
    span {
      white-space: nowrap;
    }

    @media (max-width: ${RESOLUTIONS.medium}px) {
      span {
        span {
          display: none;
        }
      }
      .heading-indentation {
        margin-right: 20px;
      }
    }
  }
`;

export const CookiesContainer = styled(Container)`
  & > p {
    margin: 16px 20px 40px;
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    & > p {
      margin: 24px 0;
      text-align: center;
    }

    & > ${BodyTextCookies} {
      text-align: left;
      color: ${(props) => props.theme.main.colors.clay2};
    }
  }
`;

export const Separator = styled.div`
  height: 40px;
  width: 3px;
  margin-right: 32px;
  background-color: ${(props) => props.theme.main.colors.clay1};
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-left: 20px;
    margin-right: 16px;
  }
`;

export const HeadingSection = styled.div`
  display: flex;
  align-items: center;
`;

export const SepHeading = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledForm = styled(Form)`
  margin: 48px 0 70px;
`;

export const CookiesDescription = styled(BodyText)`
  text-align: start !important;
  margin: 24px 20px 40px !important;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin: 32px 0 24px !important;
  }
`;
