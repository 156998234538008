import { isEmpty } from 'lodash';

import { distancesStore } from '../stores';

import { generateDataEntries } from '../derivations/data/generateDataEntries';

export const isAnyDistanceShown = () => {
  const isShownArr = distancesStore.filteredModelValues
    .map((distance) => {
      const distanceId = distance.value.id;
      const dataGenerator = generateDataEntries(distanceId);
      const dataToCheck: any[] = dataGenerator.get() || [];
      if (isEmpty(dataToCheck)) return null;
      return distance.value.name;
    })
    .filter((el) => el !== null);

  return !isEmpty(isShownArr);
};
