import * as React from 'react';

export const List = (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.7 4.4C4.36274 4.4 4.9 3.86274 4.9 3.2C4.9 2.53726 4.36274 2 3.7 2C3.03726 2 2.5 2.53726 2.5 3.2C2.5 3.86274 3.03726 4.4 3.7 4.4Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.7 9.20005C4.36274 9.20005 4.9 8.66279 4.9 8.00005C4.9 7.33731 4.36274 6.80005 3.7 6.80005C3.03726 6.80005 2.5 7.33731 2.5 8.00005C2.5 8.66279 3.03726 9.20005 3.7 9.20005Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.7 14.0001C4.36274 14.0001 4.9 13.4628 4.9 12.8001C4.9 12.1374 4.36274 11.6001 3.7 11.6001C3.03726 11.6001 2.5 12.1374 2.5 12.8001C2.5 13.4628 3.03726 14.0001 3.7 14.0001Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.50024 3.6001H14.5002' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.50024 8.3999H14.5002' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.50024 13.2H14.5002' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
