export type HeaderStore = {
  variant: HeaderVariants;
  mobileSearch?: boolean;
  hide?: boolean;
  fullWidth?: boolean;
  onAfterSearch?: AnyFunction;
};

export enum HeaderVariants {
  normal = 'normal',
  home = 'home',
  milesApp = 'milesApp',
  milesLanding = 'milesLanding',
}
