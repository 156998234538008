import { toJS } from 'mobx';

import { ROUTER } from 'src/constants';
import { history, fireToast, validation, t } from 'src/utils';

import { MORE_INFO_FORM_ID } from '../../constants';

import { mapper, moreInfoFormData } from '../../utils/mapperMiddleware';

import { moreInfoValidation } from '../../validation';

export const updateUserProfile = async () => {
  const formData = toJS(moreInfoFormData());
  const mappedUser = mapper.mapTo(formData);
  const validateData = mapper.mapToValidation(mappedUser);

  const isValid = validation(MORE_INFO_FORM_ID, validateData, moreInfoValidation);

  if (isValid) {
    history.push(ROUTER.PROFILE);
    fireToast(t.staticAsString('toast.profileUpdated') as any, 'success', '', 'top-right');
  } else {
    fireToast(t.staticAsString('toast.error.text'), 'error', '', 'top-right');
  }
};
