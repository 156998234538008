import { cloneDeep, pick, values } from 'lodash';
import { toJS } from 'mobx';
import { ACCOUNT_INFO_FORM_ID } from 'modules/profileSettings/constants';
import moment from 'moment';

import { BACKEND_DATE_FORMAT } from 'src/constants';

import { generateGenderOptions, phoneMapper } from 'utils';

import { countriesStore, form } from 'stores';

import { FrontendData, BackendData } from '../types';

class Mapper implements MapperMiddleware<FrontendData, BackendData> {
  schema = {
    firstname: 'firstname',
    lastname: 'lastname',
    avatar: 'avatar',
    birthday: 'birthday',
    telephone: 'telephone',
    gender: 'gender',
    post_code: 'post_code',
    country_id: 'country_id',
    nationality_id: 'nationality_id',
    city: 'city',
    address: 'address',
    email: 'email',
  };

  mapFrom(values: BackendData): FrontendData {
    const user: any = cloneDeep(values);

    user.country_id = countriesStore.valuesForDropdown().find((country) => country.value === user.country_id);
    user.nationality_id = countriesStore.valuesForNationalityDropdown().find((country) => country.value === user.nationality_id);
    user.telephone = phoneMapper(user.telephone);

    const genders = generateGenderOptions();
    user.gender = genders.find((option) => user.gender === option.value);

    return user;
  }

  mapFieldFront(fieldName: keyof FrontendData): BackendData {
    const fieldData: AnyObject = settingsFormData();
    const value = toJS(fieldData[fieldName]);

    switch (fieldName) {
      case 'country_id':
        return { [fieldName]: value?.value };
      case 'nationality_id':
        return { [fieldName]: value?.value };
      case 'telephone':
        return { [fieldName]: value?.phone };
      case 'birthday':
        return { [fieldName]: moment(value).format(BACKEND_DATE_FORMAT) };
      case 'gender':
        return { [fieldName]: value?.value };
      default:
        return { [fieldName]: value };
    }
  }

  mapTo(data: FrontendData): BackendData {
    const copy: any = cloneDeep(data);

    let schema = this.schema;

    copy.country_id = copy.country_id?.value;
    copy.nationality_id = copy.nationality_id?.value;
    copy.telephone = copy.telephone?.phone;

    const keyList = Object.entries(schema);

    // During patch request only difference must be mapped
    const mappedData = keyList.reduce((acc, keyValuePair) => {
      const [key, value] = keyValuePair;
      const processedValue = this._processToValue(key || '', copy[key]);
      return { ...acc, [value]: processedValue };
    }, {});

    return pick(mappedData, values(schema));
  }

  mapToValidation(data: any) {
    const copy: any = cloneDeep(data);
    copy.telephone = phoneMapper(data.telephone);

    return copy;
  }

  _processToValue(name: string, value: any): any {
    const { schema } = this;

    switch (name) {
      case schema.birthday:
        return moment(value).format(BACKEND_DATE_FORMAT);
      case schema.gender:
        return value?.value;
      default:
        return value;
    }
  }
}

const accountInfoMapper = new Mapper();
const settingsFormData = (name?: keyof FrontendData): AnyObject => form.fetch<FrontendData>(ACCOUNT_INFO_FORM_ID, name);
export { accountInfoMapper, settingsFormData };
