import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { racesStore } from 'stores';

const isExternalRegistration: IComputedValue<boolean> = computed((): boolean => {
  return racesStore.selected?.race_url !== null;
});

export { isExternalRegistration };
