import { classesStore, squadsStore, wavesStore } from '../stores';

export const mobileItemHeight = (value: RacersTeamType | RacersType): number => {
  const classModel = classesStore.find(+value.class_id!);
  const waveModel = wavesStore.find(+value.wave_id!);
  const squad = squadsStore.values.find((item) => item.id === (value as RacersType).squad_id!);

  if (classModel?.value.title || (value as RacersType).union || waveModel?.value.name || squad?.name) return 114;

  return 60;
};
