import { camelCase } from 'lodash';

import { t } from 'utils';

import { localeStore } from 'stores';

import { ParrentModel } from './parrentModel';

class CountryModel extends ParrentModel<CountriesType> {
  get name(): string {
    const locale = localeStore.value;
    const defaultValue = this.value.short_name_en;

    let name = '';
    switch (locale) {
      case 'en':
        name = this.value.short_name_en || '';
        break;
      case 'sv':
        name = this.value.short_name || '';
        break;
      default:
        name = this.value.short_name_en || '';
    }

    return name || defaultValue || '';
  }

  nationalityName(): string {
    return t.staticAsString(`nationalities.${camelCase(this.value.short_name_en)}` as TranslationLockedKeys);
  }
}

export { CountryModel };
