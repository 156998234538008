import { slice } from 'lodash';

import { progressStore } from 'stores';

import { GET_SEGMENTS_LIST } from '../constants';

import { parseUrlParams } from '../utils';

import { segmentsService } from '../services';

import { segmentsStore } from '../stores';

async function completeLastPageOrLoadMore() {
  const page = segmentsStore.page;
  const countLoaded = segmentsStore.list.length;
  const countLoadedInLastPage = countLoaded % segmentsStore.limit;

  if (!countLoadedInLastPage) {
    return await loadMore();
  }

  const respData = await load(page);

  if (!respData) {
    return;
  }

  const { data, hasMore } = respData;

  segmentsStore.setHasMore(hasMore);

  const oldList = slice(segmentsStore.list, 0, countLoaded - countLoadedInLastPage);
  segmentsStore.setList([...oldList, ...data]);
}

async function loadMoreSegments() {
  if (progressStore.isLoading(GET_SEGMENTS_LIST)) {
    return;
  }

  if (!segmentsStore.hasMore) {
    return;
  }

  await loadMore();
}

async function loadMore() {
  const page = segmentsStore.page + 1;
  const respData = await load(page);

  if (!respData) {
    return;
  }

  const { data, hasMore } = respData;
  segmentsStore.changePage(page);
  segmentsStore.setHasMore(hasMore);
  segmentsStore.setList([...segmentsStore.list, ...data]);
}

function load(page: number) {
  const { racerId } = parseUrlParams();

  if (!racerId) {
    return;
  }

  return segmentsService.getSegments(racerId, page);
}

export { completeLastPageOrLoadMore, loadMoreSegments };
