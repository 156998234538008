import { Display, Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { ResponsiveImage } from 'src/styledComponents/ResponsiveImage';

import { htmlSanitizer } from 'utils';

type CardProps = {
  imagePath1x: string;
  imagePath2x: string;
  textTop: string;
  textBottom: string;
  className?: string;
};

const StyledCard = styled.div`
  max-width: 358px;
  background: ${(props) => props.theme.miles.colors.dark.background[600]};
  box-shadow: ${(props) => props.theme.miles.shadows.dark.elevetion2};
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  .text {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: ${(props) => props.theme.miles.colors.dark.alpha[800]};
    b {
      color: ${(props) => props.theme.miles.colors.dark.alpha[900]};
    }
  }
  .top {
    padding: 16px 8px;
  }
  .card-header {
    height: 56px;
    letter-spacing: 1px;
  }
  .bottom {
    height: 118px;
    p {
      margin: 0 10px;
    }
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-bottom: 32px;
  }
`;

export const CardMedia: React.FC<CardProps> = (props) => {
  const { imagePath1x, imagePath2x, textTop, textBottom, className } = props;
  return (
    <StyledCard className={className}>
      <div className='top'>
        <Display weight='bold' size='xs' className='text card-header'>
          {textTop}
        </Display>
      </div>
      <ResponsiveImage image1x={imagePath1x} image2x={imagePath2x} alt='Discover Miles' style={{ width: '100%' }} />
      <div className='bottom text'>
        <Text weight='regular' size='md' dangerouslySetInnerHTML={{ __html: htmlSanitizer(textBottom) }} />
      </div>
    </StyledCard>
  );
};
