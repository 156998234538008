import { HEADER_HEIGHT } from 'modules/header/constants';
import React from 'react';
import styled from 'styled-components';

import { PageContent } from 'src/styledComponents/PageContent';
import { Skeleton } from 'src/styledComponents/Skeleton';

import { TitleSkeleton } from '.';

const SkeletonWrapper = styled(PageContent)`
  padding-bottom: ${HEADER_HEIGHT}px;

  .section-content {
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;

    .title-wrapper {
      padding: 51px 0 14px;
    }

    .info-card {
      width: 582px;
      background: ${(props) => props.theme.main.colors.white};
      margin: 65px 7px 0px 108px;

      .card-title {
        background: ${(props) => props.theme.main.colors.clay1};
        padding: 16px;
      }

      .card-body {
        padding: 56px 56px 72px;

        .empty-space {
          height: 22px;
        }
      }
    }
  }
`;

export const InformationSkeleton = () => (
  <SkeletonWrapper>
    <div className='title-wrapper'>
      <TitleSkeleton>
        <Skeleton width={160} height={28} variant='text' />
      </TitleSkeleton>
    </div>

    <div className='info-card'>
      <div className='card-title'>
        <Skeleton width={70} height={20} variant='text' />
      </div>
      <div className='card-body'>
        <Skeleton width='100%' height={22} variant='text' />
        <Skeleton width={130} height={22} variant='text' />
        <div className='empty-space' />
        <Skeleton width='100%' height={22} variant='text' />
        <Skeleton width={160} height={22} variant='text' />
      </div>
    </div>
  </SkeletonWrapper>
);
