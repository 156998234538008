import { computed } from 'mobx';

import { sessionStore } from 'src/stores';
import { t } from 'src/utils';

import { onChooseAuthOption, onClickAuth } from '../actions';

import { onAuthStore } from '../stores';

import { AuthMenuTypes } from '../types';

export const authProps = computed((): AuthMenuTypes => {
  return {
    name: '',
    value: '',
    isGuest: !sessionStore.isAuth,
    options: () => [
      { svgName: 'HeaderAccountProfile', label: t.staticAsString('navigation.myProfile'), value: 1, key: 1, ATN: 'myProfile' },
      { svgName: 'HeaderAccountSettings', label: t.staticAsString('navigation.settingsLink'), value: 2, key: 2, ATN: 'settings' },
      { svgName: 'HeaderAccountLogOut', label: t.staticAsString('navigation.logOutLink'), value: 3, key: 3, ATN: 'logout' },
    ],
    avatar: sessionStore.session.user.avatar || '',
    label: t.staticAsString('shared.logIn').toUpperCase(),
    onChange: onChooseAuthOption,
    onClick: onAuthStore.value || onClickAuth,
  };
});
