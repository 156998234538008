import { observer } from 'mobx-react';
import * as React from 'react';

import { authorizedRace, headerStore } from 'stores';

import { AuthFormWrapper } from './AuthFormWrapper';

type Props = {};

@observer
class Auth extends React.Component<Props> {
  static defaultProps = {
    intl: null as any,
  };

  isOpen = (): boolean => {
    const { active } = authorizedRace;

    if (!active) {
      return false;
    }

    if (headerStore.showMobileMenu) {
      return false;
    }

    const isAutorized = authorizedRace.isAutorized(active);

    if (isAutorized === null) {
      return false;
    }

    return !isAutorized;
  };

  render() {
    if (!this.isOpen()) {
      return null;
    }

    return <AuthFormWrapper />;
  }
}

export { Auth };
