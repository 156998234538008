import { reaction } from 'mobx';

import { form } from 'stores';

import { MORE_INFO_FORM_ID, CLEAR_BLOOD_OPTION } from '../constants';

import { disposers } from '../stores';

function observeBloodType() {
  const disposer = reaction(
    () => form.fetch(MORE_INFO_FORM_ID, 'blood_type'),

    (bloodType: AnyObject) => {
      if (bloodType?.value !== CLEAR_BLOOD_OPTION) {
        return;
      }

      form.onChange(MORE_INFO_FORM_ID, 'blood_type', null);
    },
  );

  disposers.register(disposer);
}

export { observeBloodType };
