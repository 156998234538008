import * as React from 'react';

import { resize } from 'utils';

type Options = {
  mobileOnly?: boolean;
};

export const withoutHeader =
  ({ mobileOnly = false }: Options = { mobileOnly: false }) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithoutHeader extends React.Component<P> {
      componentDidMount() {
        let allowHiding = true;

        if (mobileOnly) {
          allowHiding = resize.isMobile();
        }

        if (allowHiding) {
          const header = document.getElementsByTagName('header')[0];
          const footer = document.getElementsByTagName('footer')[0];
          const mainContent = document.getElementsByClassName('main-content')[0] as HTMLElement;

          if (!header || !footer || !mainContent) {
            return;
          }

          header.style.display = 'none';
          footer.style.display = 'none';
          mainContent.style.paddingBottom = '0';
        }
      }

      componentWillUnmount() {
        const header = document.getElementsByTagName('header')[0];
        const footer = document.getElementsByTagName('footer')[0];
        const mainContent = document.getElementsByClassName('main-content')[0] as HTMLElement;

        if (!header || !footer || !mainContent) {
          return;
        }

        header.style.display = '';
        footer.style.display = '';
        mainContent.style.paddingBottom = '';
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    } as unknown as void;
