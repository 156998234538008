import isEmpty from 'lodash/isEmpty';

import { sessionStore, form } from 'src/stores';
import { toastService } from 'src/utils';

import { MORE_INFO_FORM_ID } from '../../constants';

import { mapper } from '../../utils';

import { userProfile } from '../../services';

import { userProfileStore } from '../../stores';

export const loadUserProfile = async () => {
  const sessionUserData = sessionStore.getUserData();

  if (!isEmpty(sessionUserData)) {
    userProfileStore.setProfile(sessionUserData);

    const mappedUser = mapper.mapFrom(sessionUserData);
    form.merge(MORE_INFO_FORM_ID, mappedUser);

    return;
  }

  const [status, data] = await userProfile.loadUserProfile();

  if (!status) {
    return toastService.error(data.message);
  }

  const user = data.data;

  sessionStore.userProfile(user);
  userProfileStore.setProfile(user);

  const mappedUser = mapper.mapFrom(user);
  form.merge(MORE_INFO_FORM_ID, mappedUser);
};
