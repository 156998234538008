import { compact } from 'lodash';
import { some, isEmpty } from 'lodash';
import moment from 'moment';

import { raceUtil, history, encodeQuery } from 'utils';

import { SportType as SportTypeModel } from 'models/sportType';

import { Distance as DistanceModel } from './distance';
import { Location } from './location';
import { ParrentModel } from './parrentModel';

class Race extends ParrentModel<RacesType> {
  currencySekPattern = /sek/i;

  // value: RacesType;
  distancesModels: Array<DistanceModel>;
  isFirstInPastRace: boolean;
  isRaceInPast: boolean;
  location: Location | null;

  constructor(value: RacesType) {
    super(value);

    this.isRaceInPast = false;
    this.isFirstInPastRace = false;

    this.location = null;
    this.initLocation();

    this.distancesModels = [];
    this.modelDistances();
  }

  isAllSoldOut() {
    return (this.value.distances || []).every((distance) => {
      return raceUtil.isDistanceSoldOut(distance);
    });
  }

  sport(): SportTypeModel {
    return this.value && this.value.sport && new SportTypeModel(this.value.sport as SportTypesType);
  }

  compareDate(format?: string): Array<any> {
    if (this.value.race_date !== this.value.race_end_date) {
      return [this._formatDate(this.value.race_date, format), this._formatDate(this.value.race_end_date, format)];
    }

    return [this._formatDate(this.value.race_date)];
  }

  _formatDate(date: string, format?: string): string {
    return moment(date).format(format || 'DD MMM YYYY');
  }

  modelDistances(): Array<DistanceModel> {
    const { value } = this;
    const isDistances = value && !!value.distances && !!value.distances.length;
    if (!isDistances) {
      return [];
    }

    if (!isEmpty(this.distancesModels)) {
      return this.distancesModels;
    }

    this.distancesModels = value.distances.map((value) => new DistanceModel(value));

    return this.distancesModels;
  }

  currency(): string {
    const currency = this.value?.currency;

    if (!currency) {
      return '';
    }

    return currency.iso_code;
  }

  setAsFirstInPastRaces() {
    this.isFirstInPastRace = true;
  }

  markRaceAsPast() {
    this.isRaceInPast = true;
  }

  markRaceAsPastIfItIsPast() {
    const raceEndDate = moment(this.value.race_end_date);
    const today = moment();

    if (!raceEndDate.isValid()) {
      return;
    }

    if (raceEndDate.isBefore(today)) {
      this.markRaceAsPast();
    }
  }

  parsedRoute(): ParsedRoute | null {
    const { route } = this.value;
    const { isRaceInPast } = this;

    if (!route) {
      return null;
    }

    return {
      lat: parseFloat(route.lat),
      lng: parseFloat(route.lng),
      race_id: this.value.id,
      id: route.id,
      isRaceInPast,
    };
  }

  isCurrencySEK() {
    const currency = this.value?.currency;

    if (!currency) {
      return false;
    }

    if (this.currencySekPattern.test(currency.iso_code)) {
      return true;
    }

    return false;
  }

  isRaceRegistrationClosed(): boolean {
    const distances = this.modelDistances();
    return distances.every((el) => el.isRegistrationClosed());
  }

  distancesForSelect(values: Array<DistanceModel> | null): Array<Object> {
    const distances = values || this.modelDistances();

    return distances.map((el) => ({
      ...el.value,
      title: el.value.name,
    }));
  }

  filteredDistanceForSelect() {
    const distances = this.modelDistances();
    const filteredDistances = distances.filter((el) => {
      const query = encodeQuery.parseQuery(history.location.search);

      const isAlreadyRegistered = el.isAlreadyRegistered() && !query.invite_token;
      const isSoldOut = el.isSoldOut();
      const isPending = el.isTherePendingPayment();
      const isRegistrationClosed = el.isRegistrationClosed();

      return !some([isAlreadyRegistered, isSoldOut, isPending, isRegistrationClosed]);
    });

    return this.distancesForSelect(filteredDistances);
  }

  contactEmails(): string {
    const contactEmails = this.value?.contact_emails;

    if (!contactEmails) {
      return '';
    }

    const emails = contactEmails.map((value) => value.email);

    return emails.join('<br>');
  }

  isRegistrationAllowed(): boolean {
    const { allow_registration } = this.value;
    return !!allow_registration;
  }

  initLocation() {
    const location = this.value?.location;

    if (!location) {
      return;
    }

    this.location = new Location(location);
  }

  findModelDistance(id: number): DistanceModel | undefined {
    const distances = this.modelDistances();
    return distances.find((el) => el.value.id === id);
  }

  address(): string | nil {
    const { location } = this.value;
    if (!location) {
      return null;
    }

    const { street_number, street_address, city, country } = location;

    return compact([street_address, street_number, city, country]).join(', ');
  }

  cityOrCountry(): string {
    const { location } = this.value;
    if (!location) {
      return '';
    }

    const { city, country } = location;

    return city || country || '';
  }
}

export { Race };
