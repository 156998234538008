import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  sportIcon: string;
};

const Wrapper = styled.div`
  height: 24px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const SportTypesComponent: FC<Props> = ({ sportIcon }) => (
  <Wrapper className='sport-type'>
    <img src={sportIcon} alt='icon' />
  </Wrapper>
);
