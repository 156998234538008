export type DailyData = {
  load: number | null;
  muscle: number | null;
  mental: number | null;
  injury: number | null;
  illness: number | null;
  sleep: number | null;
  food: number | null;
  mood: number | null;
  energy: number | null;
};

export enum DailyDataFields {
  load = 'load',
  muscle = 'muscle',
  mental = 'mental',
  injury = 'injury',
  illness = 'illness',
  sleep = 'sleep',
  food = 'food',
  mood = 'mood',
  energy = 'energy',
}

export type DailyDataPayload = {
  ratings: DailyData;
  date: Date;
  tzoffset: number;
};
