import { AnalyticsEvent } from 'constants/analytics';
import { History } from 'history';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import snakeCase from 'lodash/snakeCase';
import { toJS } from 'mobx';
import qs from 'query-string';
import ReactGA from 'react-ga4';

import { appEnvControl, historyStore } from 'utils';

import { form } from 'stores';
import errors from 'stores/errors';
import registrationStatus from 'stores/registrationStatus';

import { REGISTRATION_FORM } from '../constants';

import { distanceSelector, raceSelector } from '../selectors/mappedData';
import { totalPrice } from '../selectors/prices/totalPrice';

export namespace AnalyticsRegistrationEvents {
  export function userSelectedProductSize(product: RaceProduct, size: string) {
    if (appEnvControl.isDev) return;
    const race = raceSelector.model.get();
    ReactGA.event(snakeCase(AnalyticsEvent.ProductsSizeSelected), {
      productId: product.id,
      productName: product.name,
      size,
      raceId: race?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      sportId: race?.value.sport_id,
      sportTitle: race?.value.sport.title_en,
      organizerId: race?.value.organizer_id,
    });
  }

  export function userAddedProductToCart(product: RaceProduct, quantity: number) {
    if (appEnvControl.isDev) return;
    const race = raceSelector.model.get();
    ReactGA.event(snakeCase(AnalyticsEvent.ProductAddedToCart), {
      productId: product.id,
      productName: product.name,
      quantity,
      raceId: race?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      sportId: race?.value.sport_id,
      sportTitle: race?.value.sport.title_en,
      organizerId: race?.value.organizer_id,
    });
  }

  export function userRemovedProductFromCart(product: RaceProduct) {
    if (appEnvControl.isDev) return;
    const race = raceSelector.model.get();
    ReactGA.event(snakeCase(AnalyticsEvent.ProductRemovedFromCart), {
      productId: product.id,
      productName: product.name,
      raceId: race?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      sportId: race?.value.sport_id,
      sportTitle: race?.value.sport.title_en,
      organizerId: race?.value.organizer_id,
    });
  }

  export function userOpenedProduct(product: RaceProduct) {
    if (appEnvControl.isDev) return;
    const race = raceSelector.model.get();
    ReactGA.event(snakeCase(AnalyticsEvent.ProductViewed), {
      productId: product.id,
      productName: product.name,
      raceId: race?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      sportId: race?.value.sport_id,
      sportTitle: race?.value.sport.title_en,
      organizerId: race?.value.organizer_id,
    });
  }

  export function freeTeamRegistrationSuccessful() {
    if (appEnvControl.isDev) return;

    const race = raceSelector.model.get();
    ReactGA.event(snakeCase(AnalyticsEvent.FreeTeamRegistrationSuccessful), {
      raceId: race?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      sportId: race?.value.sport_id,
      sportTitle: race?.value.sport.title_en,
      organizerId: race?.value.organizer_id,
      organizerName: race?.value.organizer?.full_name,
      organizedInCountry: race?.value.location?.country,
    });
  }

  export function freeGroupRegistrationSuccessful() {
    if (appEnvControl.isDev) return;

    const race = raceSelector.model.get();
    ReactGA.event(snakeCase(AnalyticsEvent.FreeGroupRegistrationSuccessful), {
      raceId: race?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      sportId: race?.value.sport_id,
      sportTitle: race?.value.sport.title_en,
      organizerId: race?.value.organizer_id,
      organizerName: race?.value.organizer?.full_name,
      organizedInCountry: race?.value.location?.country,
    });
  }

  export function trackPaymentSuccessful() {
    if (appEnvControl.isDev) return;

    ReactGA.event(snakeCase(AnalyticsEvent.PaymentSuccessful), {
      raceId: raceSelector.model.get()?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
    });
  }

  export function trackPaymentAborted() {
    if (appEnvControl.isDev) return;

    ReactGA.event(snakeCase(AnalyticsEvent.PaymentSuccessful), {
      raceId: raceSelector.model.get()?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
    });
  }

  export function trackPaymentBegin() {}

  export function trackDistanceAbandoned(abandonmentAction: string, nextPage: string) {
    if (appEnvControl.isDev) return;
    const errs = errors.getWithFlatKeys(REGISTRATION_FORM);
    ReactGA.event(snakeCase(AnalyticsEvent.AbandonedDistanceRegistration), {
      raceId: raceSelector.model.get()?.value.id,
      distanceId: distanceSelector.model.get()?.value.id,
      price: totalPrice.get(),
      attemptedPayment: registrationStatus.isAttemptedPayment,
      lastActiveForm: form.lastActiveForm,
      lastModifiedField:
        form.fetch(REGISTRATION_FORM, form.lastUpdatedField) === undefined
          ? ''
          : isArray(form.lastUpdatedField)
          ? form.lastUpdatedField.join('.')
          : form.lastUpdatedField,
      lastModifiedFieldValue: toJS(form.fetch(form.lastActiveForm, form.lastUpdatedField)),
      hasErrors: !isEmpty(errs),
      errors: errs,
      abandonmentAction,
      nextPage,
      sportTypeId: raceSelector.model.get()?.value.sport.id,
      sportType: raceSelector.model.get()?.value.sport.title_en,
    });
  }

  export function trackDistanceSource(history: History) {
    if (appEnvControl.isDev) return;

    let source = '';
    switch (true) {
      case historyStore.length === 2:
        source = 'directLink';
        break;
      case historyStore.length >= 2 && historyStore[historyStore.length - 2].pathname.includes('/about'):
        source = 'aboutRacePage';
        break;
      default:
        break;
    }
    ReactGA.event(snakeCase(AnalyticsEvent.NavigatedToRegistration), {
      raceId: history.location.pathname.split('/races/').pop()?.split('/registration').shift() ?? "couldn't determine",
      distanceId: get(qs.parse(history.location.search), 'distance', 'not selected'),
      sportTypeId: raceSelector.model.get()?.value.sport.id,
      sportType: raceSelector.model.get()?.value.sport.title_en,
      source,
    });
  }
}

export function LogEventWithUTM(event: string, data: any = {}) {
  if (appEnvControl.isDev) return;

  const source = get(qs.parse(window.location.search), 'utm_source');
  const campaign = get(qs.parse(window.location.search), 'utm_campaign');
  const medium = get(qs.parse(window.location.search), 'utm_medium');
  const utm = omitBy({ source, campaign, medium }, isEmpty);
  ReactGA.event(snakeCase(event), {
    ...data,
    ...utm,
  });
}
