import { PAYMENT_STATUSES } from 'src/constants';

import { ParrentModel } from './parrentModel';

class DistanceRegistration extends ParrentModel<DistanceRegistrationRelation> {
  isPending(): boolean {
    return this.value.state === PAYMENT_STATUSES.Pending;
  }

  isAborted(): boolean {
    return this.value.state === PAYMENT_STATUSES.Aborted;
  }

  isFailed(): boolean {
    return this.value.state === PAYMENT_STATUSES.Failed;
  }

  isReady(): boolean {
    return this.value.state === PAYMENT_STATUSES.Ready || this.value.state === PAYMENT_STATUSES.Reversed;
  }
}

export { DistanceRegistration, DistanceRegistration as DistanceRegistrationModel };
