import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

import { t } from 'utils';

type Props = {
  title: TranslationLockedKeys;
  seeAllLink?: string;
};

const PageTitleComponent = (props: Props) => {
  const { title, seeAllLink } = props;

  return (
    <h3 className='page-title'>
      {t(title as any)}
      {seeAllLink && (
        <Link to={seeAllLink} ATN='see-all'>
          {t('home.seeAll')}
        </Link>
      )}
    </h3>
  );
};

export { PageTitleComponent };
