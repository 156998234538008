import { t } from './t';

// TODO potential optimization candidate.
const generateGenderOptions = () => [
  {
    key: 1,
    label: t.staticAsString('settings.genders.male'),
    value: 1,
  },
  {
    key: 2,
    label: t.staticAsString('settings.genders.female'),
    value: 2,
  },
  {
    key: 3,
    label: t.staticAsString('settings.genders.other'),
    value: 3,
  },
];

const generateGenderValues = () => generateGenderOptions().map((option) => option.value);

export { generateGenderOptions, generateGenderValues };
