import classNames from 'classnames';
import { Avatar, Button as ButtonMiles } from 'modules/miles/shared/components';
import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';

import { Show } from 'components/condition';

import { useOnClickOutside } from 'utils';

import { AuthMenuTypes } from '../../../types';
import { Menu } from './Menu';

const CommonWrapper = styled.div<{ isGuest: boolean; isTablet?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const Text = styled.div`
  font-family: ${(props) => props.theme.main.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: center;
  color: ${(props) => props.theme.main.colors.white};
`;

const GuestWrapper = styled.div<{ isMiles?: boolean }>`
  background: ${(props) => props.theme.main.colors.clay1};
  border-radius: 2px;
  cursor: pointer;
  padding: 10px 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.main.colors.clay1};
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);

  &:hover {
    background-color: transparent;

    ${Text} {
      color: ${(props) => props.theme.main.colors.clay1};
    }
  }
`;

const LoggedWrapper = styled.div<{ isMiles?: boolean }>`
  background: ${(props) => (props.isMiles ? props.theme.miles.colors.dark.alpha[300] : props.theme.main.colors.clay1)};
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
  border-radius: ${(props) => (props.isMiles ? 4 : 2)}px;
  cursor: pointer;
  padding: 5px 12px;
  gap: 8px;

  display: flex;
  align-items: center;
  justify-content: start;

  &:hover {
    background: ${(props) => (props.isMiles ? props.theme.miles.colors.dark.alpha[500] : props.theme.main.colors.clay1)};
  }
`;

const IconClickZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownArrow = styled(Icon)<{ open: boolean }>`
  transform: ${(props) => props.open && 'rotate(180deg)'};
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const AuthMenu = (props: AuthMenuTypes): React.ReactNode | any => {
  const { isTablet, isGuest, isMiles = false, avatar, label, name, value, options, onChange, onClick } = props;

  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const handleSelect = (value: DropdownItemType) => {
    onChange({ name, value });
    setOpen(false);
  };

  const Wrapper = isGuest ? GuestWrapper : LoggedWrapper;

  return (
    <CommonWrapper ref={ref} isGuest={isGuest} isTablet={isTablet}>
      <Show if={isGuest && isMiles}>
        <ButtonMiles className='border' size='l' message='subtle' mode='dark' onClick={onClick}>
          Log In
        </ButtonMiles>
      </Show>

      <Show if={isGuest && !isMiles}>
        <Wrapper>
          <Text {...testAnchors.button('signin')} onClick={onClick} {...testAnchors.useButton('signIn')}>
            {label}
          </Text>
        </Wrapper>
      </Show>
      <Show if={!isGuest}>
        <Wrapper isMiles={isMiles} className={classNames({ border: isMiles })}>
          <Avatar size={32} onClick={onClick} />
          <IconClickZone onClick={() => setOpen(!open)} {...testAnchors.useButton('arrowDown')}>
            <DownArrow name='ArrowDownWhite' size={24} open={open} />
          </IconClickZone>
        </Wrapper>
      </Show>

      <Menu open={open} options={options()} value={value} handleSelect={handleSelect} isMiles={isMiles} />
    </CommonWrapper>
  );
};
