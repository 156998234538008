import TimePicker from 'rc-time-picker';
import * as React from 'react';

import { InputWrapComponent } from '../inputWrap';

const TimePickerComponent = (props: BaseInputProps): React.ReactNode => {
  const { placeholder, requiredMark, errorMessage, label, name, disabled, onChange, value } = props;

  return (
    <InputWrapComponent errorMessage={errorMessage} disabled={disabled} label={label} requiredMark={requiredMark} name={name}>
      <TimePicker
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
        placeholder={placeholder}
        showHour
        showMinute
        showSecond
        className='timepicker'
        popupClassName='timepicker-popup'
        value={value}
        onChange={(value: any) => {
          onChange({ name, value });
        }}
      />
    </InputWrapComponent>
  );
};

export { TimePickerComponent as TimePicker };
