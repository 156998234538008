import qs, { IParseOptions } from 'qs';
import { generatePath } from 'react-router-dom';

class EncodeQuery {
  defaultParseOptions = { arrayLimit: 100 };

  stringify(params: Object): string {
    return qs.stringify(this.checkEmptyParams(params)) || '';
  }

  parseQuery(query: string, options?: IParseOptions): AnyObject {
    const params = query.replace(/\?/, '');
    return qs.parse(params, { ...this.defaultParseOptions, ...options });
  }

  generatePath(pattern: string, params: Object, query?: Object): string {
    const path = generatePath(pattern, params);
    const generatedQuery = query ? `?${this.stringify(query)}` : '';

    return `${path}${generatedQuery}`;
  }

  checkEmptyParams(search: AnyObject): Object {
    const keys = Object.keys(search);
    return keys.reduce((formated, item) => {
      if (search[item] && search[item].toString()) {
        return {
          ...formated,
          [item]: search[item],
        };
      }
      return formated;
    }, {});
  }
}

const encodeQuery = new EncodeQuery();
export { encodeQuery };
