import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { Divider } from 'src/styledComponents/Divider';
import { InputWithWrapper } from 'src/styledComponents/InputTools';

import { Form, InputWrapper } from 'components/form';
import { Modal, Body, Content, Title, Controls } from 'components/modal';

import { t } from 'utils';

import { AUTH_ACTION } from '../constants';

const StyledModal = styled(Modal)`
  align-items: center;

  .body {
    max-width: 590px;
    position: unset;
    border-radius: 8px;
    padding: 20px;

    .content input {
      width: 100%;
    }

    .registration-holder {
      margin-top: 40px;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .body {
      width: 75vw;
    }
  }
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0 40px;
  height: 1px;
`;

type Props = {
  onSubmit: Function;
};

class AuthForm extends React.Component<Props> {
  render() {
    const { onSubmit } = this.props;

    return (
      <div className='registration'>
        <StyledModal open className='modal'>
          <Body className='login-form'>
            <Form onSubmit={onSubmit} id={AUTH_ACTION}>
              <Title>{t.staticAsString('raceAuth.form.label')}</Title>

              <StyledDivider light />
              <Content>
                <InputWrapper
                  name='password'
                  settings={{
                    type: 'password',
                    label: t.staticAsString('raceAuth.form.password'),
                    placeholder: t.staticAsString('raceAuth.form.password'),
                    required: true,
                    requiredMark: true,
                  }}
                  Component={InputWithWrapper}
                />
              </Content>
              <Controls className='registration-holder'>
                <Button variant='primary' size='medium' className='btn registration-btn' id='login_btn' type='submit'>
                  {t.staticAsString('raceAuth.form.submit')}
                </Button>
              </Controls>
            </Form>
          </Body>
        </StyledModal>
      </div>
    );
  }
}

export { AuthForm };
