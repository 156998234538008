import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { NavLink } from 'src/styledComponents/Link';

type Props = {
  title: string;
  link: string;
};

export const StyledLink = styled(NavLink)`
  display: inline-block;
  margin-right: 8px;
  padding: 11px 16px;
  color: ${(props) => props.theme.main.colors.clay3};
  background: inherit;
  border: 2px solid ${(props) => props.theme.main.colors.clay3};
  border-radius: 129px;

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  &.${(props) => props.activeClassName} {
    color: ${(props) => props.theme.main.colors.white};
    background: ${(props) => props.theme.main.colors.clay3};
    border: 2px solid ${(props) => props.theme.main.colors.clay3};
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-right: 16px;
  }

  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.main.colors.white};
      background: ${(props) => props.theme.main.colors.clay3};
      border: 2px solid ${(props) => props.theme.main.colors.clay3};
      cursor: pointer;
    }
  }
`;

export const SettingsTabItemComponent = (props: Props & OptionalTestAnchorProps): React.ReactNode | any => {
  const { link, title } = props;
  return (
    <StyledLink to={link} activeClassName='active' ATN={props.ATN}>
      <span>{title}</span>
    </StyledLink>
  );
};
