import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Distance } from 'src/models/distance';
import { DistanceHeader } from 'src/modules/racersTable/components';

import { distancesStore } from '../../stores';

import { data, isAnyDistanceHasBibs, generateIsBibs } from '../../derivations';
import { LeftSideSingleRow } from '../Table/LeftSideSingleRow';
import { LeftSideTeamRow } from '../Table/LeftSideTeamRow';

type Props = {
  distance: Distance;
};

const Divider = styled.div`
  height: 30px;
  width: 100%;

  &:last-child {
    display: none;
  }
`;

const Rows = {
  single: LeftSideSingleRow,
  team: LeftSideTeamRow,
};

const DistanceTable: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;

  const dataGenerator = data.generateDataEntries(distanceId);
  const isBib = generateIsBibs(distanceId);
  const Row = Rows[distance.value.type];

  return (
    <Observer>
      {() => {
        const data: any[] = dataGenerator.get() || [];
        const bibCellActive = isAnyDistanceHasBibs.get();
        const withBibs = isBib.get();

        if (isEmpty(data)) return null;

        return (
          <>
            <DistanceHeader name={distance.title()} />
            {data.map((item) => (
              <Row key={`table-left-row-${item.id}`} bibCellActive={bibCellActive} withBibs={withBibs} value={item} />
            ))}
            <Divider />
          </>
        );
      }}
    </Observer>
  );
};

export const LeftSide = () => {
  return (
    <Observer>
      {() => {
        return (
          <>
            {distancesStore.filteredModelValues.map((distance) => (
              <DistanceTable key={`left-all-${distance.value.id}`} distance={distance} />
            ))}
          </>
        );
      }}
    </Observer>
  );
};
