import { observable, action, makeObservable } from 'mobx';

class AfterPayment {
  @observable payment: AnyObject = {};

  @observable isPatchComplete: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  changePaymentStatus(status: string) {
    this.payment.status = status;
  }

  @action
  cleanPayment() {
    this.payment = {};
  }

  @action
  setPatchStatus(value: boolean) {
    this.isPatchComplete = value;
  }
}

export { AfterPayment };
export default new AfterPayment();
