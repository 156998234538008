import { form, progressStore } from 'stores';

import { FORM_ID, LOADER } from '../constants';

import { updateService } from '../services';

import { callbackStore, racerIdStore, segmentIdStore, modeStore } from '../stores';

import { formToBeSegmentMapper, mapToValidate } from '../mapper';
import { FormSegment } from '../types';
import { ignition } from './ignition';
import { validate } from './validate';

async function updateSegment(): Promise<boolean> {
  const formData = form.fetch<FormSegment>(FORM_ID);
  const racerId = racerIdStore.value;
  const segmentId = segmentIdStore.value;
  const isValid = validate('update', mapToValidate(formData));
  const callback = callbackStore.value;
  const data = formToBeSegmentMapper.mapTo(formData);
  const start = new Date();

  if (!isValid || !racerId || !segmentId || !data) {
    return false;
  }

  progressStore.log(LOADER, 'progress');

  const [status, state] = await updateService.ignite(racerId, segmentId, data);

  await progressStore.log(LOADER, 'completed', {
    requestStartTime: start,
    minRequestTime: 200,
  });

  callback && (await callback(state, modeStore.value));
  (state === 'success' || state === 'conflict') && ignition.switchOff();

  return status;
}

export { updateSegment };
