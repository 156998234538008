import React from 'react';
import styled from 'styled-components';

import { t } from 'utils';

const AppleSupportLink = styled.a`
  color: inherit;
  font-weight: 700;
  text-decoration: none;
  border-bottom: 2px solid inherit;
`;

export const AppleAuthError = () => {
  return (
    <>
      {t.staticAsString('toast.appleAuthError')}{' '}
      <AppleSupportLink href='https://support.apple.com/en-us/HT210426' target='_blank'>
        {t.staticAsString('toast.appleAuthError.here')}
      </AppleSupportLink>
    </>
  );
};
