import { modeStore } from '../stores';

import { ModalTypes } from '../types';
import { createSegment } from './create';
import { updateSegment } from './update';

const submits: { [K in ModalTypes]: Function } = {
  new: createSegment,
  'first-new': createSegment,
  edit: updateSegment,
};

function onSubmit() {
  const submit = submits[modeStore.value || 'new'];
  submit && submit();
}

export { onSubmit };
