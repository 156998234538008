import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { SettingsTabItemComponent } from './SettingsTabItemComponent';

type Props = {
  tabs: Array<AnyObject>;
};

const Container = styled.div`
  overflow: hidden;
  width: calc(100% + 40px);
  align-self: flex-start;
  margin-left: -20px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 100%;
    margin-left: 0;
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: auto;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const SettingsTabComponent = (props: Props): any => {
  return (
    <Container>
      <ScrollContainer>
        {props.tabs.map((tab) => {
          return <SettingsTabItemComponent key={`${tab.link}`} title={tab.title} link={tab.link} />;
        })}
      </ScrollContainer>
    </Container>
  );
};

export { SettingsTabComponent };
