import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { NO_SOURCE_LINK } from 'src/constants';
import { Button } from 'src/styledComponents/Button';

import * as Icons from 'img/thirdParty';

import { Show } from 'components/condition';

import { t, recognizeLink, urlSanitizer, fireToast } from 'utils';

type Props = {
  onClick: Function;
  link?: string;
  percents?: string;
  editMode: boolean;
  isLength?: boolean;
  isAltitude?: boolean;
  disabled?: boolean;
  disabledReason?: React.ReactNode;
  expandButton?: React.ReactNode;
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;

  .activity-link {
    text-decoration: none;
  }
`;

@observer
export class AddResult extends React.Component<Props> {
  static defaultProps = {
    percents: '',
  };

  generateLink = (): React.ReactNode => {
    const { link, percents, expandButton } = this.props;
    if (!link) {
      return (
        <Wrapper className='d-flex align-items-center'>
          <Show if={!expandButton}>
            <div className='cr-percents'>{percents}</div>

            <div className='activity-link no-source'>
              {/* @ts-ignore */}
              <img src={Icons[NO_SOURCE_LINK.icon]} alt='no_source' />
            </div>
          </Show>

          <div className='expand-wrapper'>{expandButton}</div>
        </Wrapper>
      );
    }

    const source = recognizeLink(link);

    // @ts-ignore
    const src = Icons[source.icon];
    // @ts-ignore
    const src2x = Icons[`${source.icon}2x`];
    const srcSet = src2x ? `${src2x} 2x` : '';

    return (
      <Wrapper className='d-flex align-items-center'>
        <Show if={!expandButton}>
          <div className='cr-percents'>{percents}</div>

          <a className='activity-link tooltip' href={urlSanitizer(link)} target='_blank'>
            <img src={src} srcSet={srcSet} alt='source' />
            <div className='tooltip-text'>{link}</div>
          </a>
        </Show>

        <a className='activity-link' href={urlSanitizer(link)} target='_blank'>
          <div className='expand-wrapper'>{expandButton}</div>
        </a>
      </Wrapper>
    );
  };

  onClick = () => {
    const { disabled, disabledReason, onClick } = this.props;

    if (disabled) {
      fireToast(disabledReason as string, 'info', t.staticAsString('profile.toast.title.addResultsInformation'), 'top-right', 0);
    }

    if (disabled) {
      return;
    }

    onClick();
  };

  render() {
    const { editMode, disabled, disabledReason } = this.props;

    // Maybe we will return it in future (isAltitude and isLength are in props)
    // const logAltitude = isAltitude && 'profile.logAltitude';
    // const logLength = isLength && 'profile.logLength';
    // const addResults = 'profile.addResults';
    const logResult = 'race.logResults';

    if (editMode) {
      return this.generateLink();
    }

    return (
      <Wrapper className={classNames({ tooltip: disabled })}>
        <StyledButton
          variant='primary'
          size='medium'
          className={classNames('source-button', { disabled: disabled })}
          onClick={this.onClick}
        >
          {t(logResult)}
        </StyledButton>
      </Wrapper>
    );
  }
}
