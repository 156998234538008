import { Observer } from 'mobx-react';
import * as React from 'react';

import { ROUTER } from 'src/constants';
import { ShowDesktop, ShowMobile, ShowTablet } from 'src/styledComponents/Conditions';
import { t, history } from 'src/utils';

import { Show } from 'components/condition';

import { sessionStore } from 'stores';

import { ContentBanner } from './ContentBanner';

const STORAGE_VISIT_KEY = 'has-visited';

export const EventOrganizerBanner: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const raceIncludedRoute = history.location.pathname.includes(ROUTER.RACES);

  React.useEffect(() => {
    if (!sessionStorage.getItem(STORAGE_VISIT_KEY) && !raceIncludedRoute) {
      sessionStorage.setItem(STORAGE_VISIT_KEY, JSON.stringify({ p: history.location.pathname, f: true }));
      setIsVisible(true);
      return;
    } else {
      const sessionObj = JSON.parse(sessionStorage.getItem(STORAGE_VISIT_KEY) as string);

      if (history.location.pathname !== sessionObj?.p) {
        if (sessionObj?.f) setIsVisible(false);
      }
    }
  }, [history.location.pathname]);

  const closeBanner = () => setIsVisible(false);

  return (
    <React.Fragment>
      <Observer>
        {() => {
          const isAuth = sessionStore.isAuth;

          return (
            <>
              <Show if={!isAuth && isVisible}>
                <ShowDesktop withTablet>
                  <ContentBanner
                    size='desktop'
                    level={5}
                    bodyText={t.staticAsString('eventOrganizer.banner')}
                    buttonText={t.staticAsString('eventOrganizer.button')}
                    onCloseBanner={closeBanner}
                  />
                </ShowDesktop>
                <ShowTablet>
                  <ContentBanner
                    size='tablet'
                    level={5}
                    bodyText={t.staticAsString('eventOrganizer.banner')}
                    buttonText={t.staticAsString('eventOrganizer.button')}
                    onCloseBanner={closeBanner}
                  />
                </ShowTablet>
                <ShowMobile>
                  <ContentBanner
                    size='mobile'
                    level={5}
                    bodyText={t.staticAsString('eventOrganizer.banner')}
                    buttonText={t.staticAsString('eventOrganizer.button')}
                    onCloseBanner={closeBanner}
                  />
                </ShowMobile>
              </Show>
            </>
          );
        }}
      </Observer>
    </React.Fragment>
  );
};
