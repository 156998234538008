import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { CheckpointsStore } from '../../stores';

function generateCheckpointsSelect(idGenerator: IComputedValue<null | number>, store: CheckpointsStore) {
  const checkpointSelectOptions: IComputedValue<SelectItemType[]> = computed(() => {
    const distanceId = idGenerator.get();

    if (!distanceId) {
      return [];
    }

    const checkpoints = store.findCheckpointsByDistance(distanceId);

    return checkpoints.map((model) => model.toSelectItem());
  });

  function findCheckpointSelectOption(id: number | null): SelectItemType | null {
    if (!id) {
      return null;
    }

    const match = store.find(id);

    if (!match) {
      return null;
    }

    return match.toSelectItem();
  }

  const checkpointsSelect = {
    options: checkpointSelectOptions,
    findOption: findCheckpointSelectOption,
  };

  return checkpointsSelect;
}

export { generateCheckpointsSelect };
