import { filter, escapeRegExp, isEmpty, isString, sortBy, uniq } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';
import { InputWrapComponent, InputComponent } from 'src/styledComponents/InputTools';
import { useOnClickOutside } from 'src/utils';

import { Truncate } from 'styles';

import { PROMOTE_COUNTRIES } from 'stores/countries';

import { Menu } from './Menu';

type Props = BaseInputProps & {
  onlyDigits?: boolean;
  shouldTrim?: boolean;
  additionalComponent?: AnyFunction;
  options: () => DropdownItemType[];
  isClearable?: boolean;
  watchable?: boolean;
  suggestionOptionsCount?: number;
  suggestions?: DropdownItemType['value'][];
};

const StyledInputWrapComponent = styled(InputWrapComponent)<{ readOnly?: boolean; isOpened: boolean }>`
  input {
    ${Truncate};
    ${(props) => props.readOnly && `cursor: pointer;`}
  }
  .drop-down-open-icon {
    margin-top: 16px;
    transform: translate3D(0, -50%, 0) ${(props) => (props.isOpened ? 'rotate(180deg)' : '')};
  }
`;

export const Dropdown = (props: Props): React.ReactNode | any => {
  const {
    additionalComponent,
    requiredMark,
    errorMessage,
    label,
    name,
    value,
    disabled,
    options,
    onChange,
    isClearable = true,
    readOnly,
    watchable,
    suggestions = [],
  } = props;

  const getOptions = () => sortBy(options(), (o) => !suggestions.includes(o.value));

  const getCurrentItem = () => {
    const currentItem = (getOptions() || []).find((item) => item.value === value?.value);

    if (!currentItem) {
      return value?.label;
    }

    if (isString(currentItem.label)) return currentItem.label;
    return renderToStaticMarkup(currentItem.label);
  };

  const [open, setOpen] = React.useState(false);
  const [searchList, setFilteredList] = React.useState<DropdownItemType[]>([]);
  const [search, setSearch] = React.useState(getCurrentItem() || '');
  const ref = React.useRef<HTMLDivElement>(null);

  const listItems = isEmpty(searchList) ? getOptions() : searchList;

  useOnClickOutside(
    ref,
    () => {
      setOpen(false);
      if (!search && isClearable && value) {
        // clear the value in the form if the user clears the input
        onChange({ name, value: null });
      } else {
        // If the user searched (entered characters into the input) and did not select a value from the drop-down list, return the previous value to the input
        setSearch(getCurrentItem() || '');
      }
    },
    [value, search],
  );

  useEffect(() => {
    if (!search) {
      setSearch(listItems.find((item) => item.value === value)?.label || '');
    }
  }, [listItems]);

  useEffect(() => {
    if (search && watchable) {
      setSearch(getCurrentItem() || '');
    }
  }, [options()]);

  useEffect(() => {
    setSearch(getCurrentItem() || '');
  }, [value]);

  const handleChange = ({ value }: { value: any }) => {
    const searchedValues = filter(getOptions(), (item: DropdownItemType) => new RegExp(escapeRegExp(value), 'i').test(item.label));

    setSearch(value);
    setFilteredList(searchedValues);
  };

  const handleSelect = (value: DropdownItemType) => {
    onChange({ name, value });
    setSearch(value.label);
    setFilteredList([]);
    setOpen(false);
  };

  const isCountryOrNationalityDropdown = name.includes('country_id') || name.includes('nationality_id');
  const promoteCountriesCount = listItems.filter((item) => uniq([...PROMOTE_COUNTRIES, ...suggestions]).includes(item.value)).length;
  const propsSuggestionCount = listItems.filter((item) => suggestions?.includes(item.value)).length;

  const showSuggestion = isCountryOrNationalityDropdown ? promoteCountriesCount : propsSuggestionCount;

  return (
    <StyledInputWrapComponent
      additionalComponent={additionalComponent}
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={getCurrentItem()}
      elementRef={ref}
      readOnly={readOnly}
      isOpened={open}
    >
      <InputComponent
        {...props}
        unit={
          <Icon
            className='drop-down-open-icon'
            name='ArrowDown'
            size={16}
            onClick={
              disabled
                ? () => {}
                : (e: MouseEvent) => {
                    e.stopPropagation();
                    setOpen(!open);
                  }
            }
          />
        }
        value={search}
        onClick={disabled ? () => {} : () => setOpen(true)}
        onChange={handleChange}
      />
      <Menu
        open={open}
        options={listItems}
        value={value}
        handleSelect={handleSelect}
        className='menu-paper'
        suggestionOptionsCount={showSuggestion}
      />
    </StyledInputWrapComponent>
  );
};
