import { throttle } from 'lodash';

import { dataStore } from '../../stores';

import { pagination } from '../../derivations';
import { load } from './load';

async function loadMoreFun() {
  const nextDistance = pagination.distanceToBeLoaded.get();

  if (!nextDistance) {
    return;
  }

  if (!pagination.hasMore.get()) {
    return;
  }

  let lastPage = dataStore.lastPage(nextDistance);

  if (!lastPage) {
    lastPage = 0;
  }

  const dataLength = await load(nextDistance, lastPage + 1);

  if (!dataLength) {
    loadMore();
  }
}

const loadMore = throttle(loadMoreFun, 50);

export { loadMore };
