import { t } from 'utils';

import localeStore from 'stores/locale';

import { ParrentModel } from './parrentModel';

class ClassModel extends ParrentModel<ClassType> {
  value: { id: number | string } & ClassType;
  hashForCompare: string;
  id: string | number;

  constructor(value: ClassType, id?: string) {
    super(value);
    this.value = value;
    this.id = id || value.id;
    this.hashForCompare = '';
    this._initHashForCompare();
  }

  static allClassesSelectItem() {
    let title = t.staticAsString('registration.classPlaceholder');

    return {
      id: 0,
      title,
      value: 0,
    };
  }

  static serializeSimilarClassIds(idValues: Array<number>): string {
    return idValues.join(',');
  }

  static deserializeSimilarClassIds(idValues: string): Array<number> {
    if (!idValues) {
      return [];
    }

    return idValues.split(',').map((el) => parseInt(el, 10));
  }

  _initHashForCompare() {
    const { title } = this.value;
    this.hashForCompare = `${title || ''}`.replace(/\s/, 'g').toLowerCase();
  }

  toSelectItem(maxNameLength: number = -1) {
    const { id } = this;
    const text = this.value.title;
    const label = maxNameLength !== -1 && text.length > maxNameLength ? text.substr(0, maxNameLength) + '...' : text;

    return {
      value: id,
      key: id,
      label,
      title: label,
    };
  }

  /**
   * @description
   * compare select itme with a value
   */
  static compareSelectItem(id: number, item: NewSelectItemType) {
    if (!id || !item) {
      return false;
    }

    return id.toString() === item.value.toString();
  }
}

export { ClassModel };
