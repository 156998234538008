import { Cell } from './Cell';
import { Row } from './Row';
import { Wrapper } from './Table';
import { Body } from './TableBody';
import { Head } from './TableHead';

const Table = {
  Wrapper,
  Cell,
  Row,
  Body,
  Head,
};

export { Table };
