import { DefaultTheme, createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  * {
    box-sizing: border-box;
  
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
  
  html {
    position: relative;
    height: 100%;
  }

  body, ul {
    ::-webkit-scrollbar {
      background: ${(props) => props.theme.main.colors.clay5};
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-button {
      display: none;
    }
  
    ::-webkit-scrollbar-thumb {
      height: 80px;
      background: ${(props) => props.theme.main.colors.clay3};
      border-radius: 4px;
    }
  
    ::-webkit-scrollbar-thumb::before {
      content: '';
      background: ${(props) => props.theme.main.colors.clay1};
      border-radius: 4px;
    }
  } 
  

  html,
  body {
    padding: 0;
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Aeonik', sans-serif;
    background-image: url("${require('../../assets/images/illistrations/topographic-pattern.svg')}");
    background-color: ${(props: any) => props.theme.main.colors.clay6};
    min-height: 100%;
  }
  
  input, button {
    font-family: 'Aeonik', sans-serif;
  }
`;
