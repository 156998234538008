import { distancePlaceColumn } from './distancePlaceColumn';
import { generateCheckpointId } from './generateCheckpoint';

type ResultsOrderByTypes =
  | 'place'
  | 'gender_place'
  | 'gender_class_place'
  | 'wave_place'
  | 'class_place'
  | 'splits.place'
  | 'splits.class_place'
  | 'splits.gender_place'
  | 'splits.wave_place'
  | 'splits.gender_class_place';

function distanceOrderBy(distanceId: number): { orderBy: ResultsOrderByTypes; checkpoint?: number } {
  const distancePlaceCol = distancePlaceColumn(distanceId).get();
  const checkpointId = generateCheckpointId(distanceId).get();

  switch (distancePlaceCol) {
    case 'place':
      return { orderBy: 'place' };
    case 'gender_place':
      return { orderBy: 'gender_place' };
    case 'gender_class_place':
      return { orderBy: 'gender_class_place' };
    case 'wave_place':
      return { orderBy: 'wave_place' };
    case 'class_place':
      return { orderBy: 'class_place' };
    case 'splits.place':
      return { orderBy: 'splits.place', ...(checkpointId && { checkpoint: checkpointId }) };
    case 'splits.class_place':
      return { orderBy: 'splits.class_place', ...(checkpointId && { checkpoint: checkpointId }) };
    case 'splits.gender_place':
      return { orderBy: 'splits.gender_place', ...(checkpointId && { checkpoint: checkpointId }) };
    case 'splits.wave_place':
      return { orderBy: 'splits.wave_place', ...(checkpointId && { checkpoint: checkpointId }) };
    case 'splits.gender_class_place':
      return { orderBy: 'splits.gender_class_place', ...(checkpointId && { checkpoint: checkpointId }) };
  }
}

export { distanceOrderBy };
