import { computed, toJS } from 'mobx';

import { resultsUtil } from '../../utils';

import type { LResult } from '../../types';
import { generateLeadersTime } from './_leadersTime';

/**
 * @description
 * (Leaders result result or split result) - result result or split result)
 *
 * Formula:
 * (leaderResult.split.result_diff || leaderResult.time_result) - split.result_diff || result.time_result
 * https://imgur.com/icNt7VU
 */
function generateLeaderDiff(result: LResult, checkpointId: number | nil, distanceId: number) {
  const leadersTimeGenerator = generateLeadersTime(distanceId, checkpointId);

  return computed(() => {
    const resultDiff = resultsUtil.fetchBEDiff(result, checkpointId);
    const leaderDiff = leadersTimeGenerator.get();

    if (result.start_time_ms && result.finish_time_ms) {
      const hardResults = result.finish_time_ms - result.start_time_ms;
      if (hardResults && leaderDiff) {
        return resultsUtil.formatDiff(Math.round(hardResults / 1000) * 1000 - Math.round(leaderDiff / 1000) * 1000, true);
      }
    }

    if (resultDiff && leaderDiff) {
      let resultDiffRounded = Math.round(resultDiff / 1000) * 1000;
      let leaderDiffRounded = Math.round(leaderDiff / 1000) * 1000;
      return resultsUtil.formatDiff(resultDiffRounded - leaderDiffRounded, true);
    }

    return resultsUtil.formatFallbackLeaderDiff();
  });
}

export { generateLeaderDiff };
