// @flow

function isThereBackHistory(fullHistoryStore: { action: string; location: any }[]) {
  const pushes = fullHistoryStore.filter((entry) => entry.action === 'PUSH');
  const pops = fullHistoryStore.filter((entry) => entry.action === 'POP');

  return pushes.length - pops.length > 0;
}

export { isThereBackHistory };
