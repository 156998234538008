import { computed, action, makeObservable } from 'mobx';

import { SearchSerializer } from 'utils';

import { Race as RaceModel } from 'models/race';

import { Loadable } from 'stores/loadable';

//TODO need new end point for autocomplete
class Filter extends Loadable<LocationType, FiltersType> {
  declare filters: FiltersType;

  defaultFilters: FiltersType = {
    limit: 9999,
    with: '',
  };

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.defaultFilters;
  }

  handlers = {
    city: 'like',
    'races.name': 'like',
    'races.sport_id': 'in',
    'races.race_date': '>=',
    'races.race_end_date': '<=',
    'races.distances.race_length': 'between',
  };

  nestedJoins = '';
  searchJoin = 'AND';

  @computed
  get modelValues(): Array<
    LocationType & {
      races?: {
        value: RaceModel;
      };
    }
  > {
    return this.values.map((location) => {
      const newLocation = {
        ...location,
      };

      if (location.races) {
        newLocation.races = location.races.map((race) => new RaceModel(race)) as any;
      }

      return newLocation;
    }) as any;
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null;
      this.filters.searchFields = null;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters.search as any;

    return new SearchSerializer({
      search: search,
      handlers: this.handlers,
      nestedJoins: this.nestedJoins,
      searchJoin: this.searchJoin,
    });
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }
}

export { Filter };
export default new Filter();
