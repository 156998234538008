import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { InputWrapper, FormContext } from 'src/components/form';
import { RESOLUTIONS } from 'src/constants';
import { Label } from 'src/modules/results/components/Filters';
import { SEARCH_ID } from 'src/modules/results/constants';
import { Svg } from 'src/styledComponents/Icons';
import { InputComponent } from 'src/styledComponents/InputTools';

import { Show } from 'components/condition';

import { t } from 'utils';

import { form } from 'stores';

import { focusStore } from '../../stores';

type Props = {
  show: boolean;
};

const Wrapper = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 24px;
  align-items: center;
  padding: 32px 20px 20px;

  input {
    border: 2px solid rgb(195, 198, 200);
    border-radius: 25px;
    padding: 0 16px 0 44px;
    height: 52px;
    background-color: rgb(255, 255, 255);
    width: 100%;

    transition: width 200ms ease;
    will-change: width;
  }

  span {
    left: 0;
    width: 52px;
  }

  @media (min-width: ${RESOLUTIONS.large}px) {
    position: absolute;
    top: 34px;
    left: 0;
    z-index: 1;

    display: initial;
    padding: initial;

    width: ${(props) => (props.open ? '100%' : '411px')};
    transition: width 200ms ease;
    will-change: width;
  }
`;

export const Search = (props: Props) => {
  const context = React.useContext(FormContext);

  return (
    <Observer>
      {() => {
        const onClose = () => {
          if (!context.form?.id) return;
          form.onChange(context.form.id, 'name', null);
        };

        const onBlur = () => {
          focusStore.value = false;
        };

        const onFocusIn = () => {
          focusStore.value = true;
        };

        return (
          <Show if={props.show}>
            <Wrapper open={focusStore.value!}>
              <InputWrapper
                name='name'
                settings={{
                  onClose: onClose,
                  onBlur: onBlur,
                  onClick: onFocusIn,
                  placeholder: t.staticAsString('racersTable.filters.searchPlaceholder'),
                  id: SEARCH_ID,
                  showCloseButton: focusStore.value!,
                  unit: <Svg name='Search' size={18} />,
                }}
                Component={InputComponent}
              />
              <Label />
            </Wrapper>
          </Show>
        );
      }}
    </Observer>
  );
};
