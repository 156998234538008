import * as React from 'react';

import { Checkbox } from '../checkbox';
import { InputWrapComponent } from '../inputWrap';

/**
 * @deprecated
 * Don't you dare to use it.
 * We will find ya and end ya.
 */
function WrappedCheckbox(props: BaseInputProps) {
  const { errorMessage, name } = props;

  return (
    <InputWrapComponent errorMessage={errorMessage} name={name}>
      {/* $FlowFixMe bleat */}
      <Checkbox {...props} />
      {/* Flow, why do you hate spread operator?! */}
    </InputWrapComponent>
  );
}

export { WrappedCheckbox };
