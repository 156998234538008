import * as React from 'react';
import styled from 'styled-components';

import { BodyText, Headline, Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0 0 18px 0;
  padding: 0 0 0 0;

  .title {
    color: ${(props) => props.theme.main.colors.clay1};
    padding: 0 12px 0 0;
  }
  .value {
    color: ${(props) => props.theme.main.colors.clay2};
  }
`;

type Props = {
  title: TranslationLockedKeys;
  value: string;
};

export const BlockInfo: React.FC<Props> = (props: Props) => {
  return (
    <Wrapper>
      <Utility variant='u3' weight='bold' className='title'>
        {t.staticAsString(props.title)}
      </Utility>
      <BodyText variant='body2' className='value'>
        {props.value}
      </BodyText>
    </Wrapper>
  );
};
