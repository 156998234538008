import moment from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';

import { dateCorrector } from 'utils';

import { InputWrapComponent } from '../inputWrap';
import { DatePickerInput } from './DatePickerInputComponent';

const DEFAULT_SHOWN_YEAR_ITEMS = 100;

type DateArgFunc = (date: Date) => void;
type ChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => void;
type DateChangeEvent = (date: Date | [Date, Date] | /* for selectsRange */ null, event: React.SyntheticEvent<any> | undefined) => void;

class DatePickerComponent extends React.Component<DatePickerType & BaseInputProps> {
  render(): React.ReactNode {
    const {
      label,
      placeholder,
      name,
      value,
      disabled,
      readOnly,
      errorMessage,
      onChange,
      requiredMark,
      minDate,
      maxDate,
      yearItems = DEFAULT_SHOWN_YEAR_ITEMS,
      popperPlacement = 'auto',
    } = this.props;

    let restrictRange: AnyObject = {};

    if (dateCorrector.isDateValid(minDate || null)) {
      restrictRange.minDate = dateCorrector.newDate(minDate || null);
    }

    if (dateCorrector.isDateValid(maxDate || null)) {
      restrictRange.maxDate = dateCorrector.newDate(maxDate || null);
    }

    const dateValue = value && moment(value).isValid() ? new Date(value) : null;
    const handleChange = (value: string | Date) => {
      return onChange({ name, value });
    };

    return (
      <InputWrapComponent disabled={disabled} name={name} label={label} requiredMark={requiredMark} errorMessage={errorMessage}>
        <DatePicker
          placeholderText={placeholder}
          onYearChange={handleChange as DateArgFunc}
          onMonthChange={handleChange as DateArgFunc}
          dateFormat='yyyy-MM-dd'
          selected={dateValue}
          onChange={handleChange as DateChangeEvent}
          disabled={disabled}
          readOnly={readOnly}
          isClearable={true}
          calendarClassName='calendar-component'
          className='input-component'
          popperClassName='calendar-popper'
          peekNextMonth
          showDisabledMonthNavigation
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={yearItems}
          scrollableYearDropdown
          popperPlacement={popperPlacement as any}
          // @ts-ignore NOTE, that react-datepicker is fetched from custom repo, and this prop is supported!
          yearDropdownScrollToSelected
          // @ts-ignore
          customInput={<DatePickerInput {...this.props} />}
        />
      </InputWrapComponent>
    );
  }
}

export { DatePickerComponent as DatePicker };
