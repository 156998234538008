import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';

import { toggleCheckpoint } from '../../actions';

import { activeSplitStore } from '../../stores';

type Props = {
  recordId: number;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSvg = styled(Svg)`
  cursor: pointer;
  transition: All ease-out 0.3s;
  transform: rotate(0deg);
  &.active {
    transform: rotate(180deg);
  }
`;

export const CheckpointArrow: React.FC<Props> = (props) => {
  const { recordId } = props;
  return (
    <Observer>
      {() => {
        const id = activeSplitStore.value === recordId ? null : recordId;
        return (
          <Wrapper>
            <StyledSvg
              name='CircleDown'
              className={activeSplitStore.value === recordId ? 'active' : ''}
              size={24}
              onClick={toggleCheckpoint(id)}
            />
          </Wrapper>
        );
      }}
    </Observer>
  );
};
