import * as React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'src/styledComponents/Checkbox';
import { Truncate } from 'src/styles';

const OptionWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  padding: 0 24px;
  height: 54px;
  &:last-child {
    scroll-snap-align: end;
  }
  &:nth-last-child(2) {
    scroll-snap-align: end;
  }
  &:nth-last-child(3) {
    scroll-snap-align: end;
  }
  &:nth-last-child(4) {
    scroll-snap-align: end;
  }

  &:hover {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }

  p {
    text-transform: none;
    ${Truncate}
  }
`;

type Props = {
  label: string;
  name: string | number;
  onChange: AnyFunction;
  value: DropdownItemType;
  values: DropdownItemType[];
};

export const Option: React.FC<Props> = (props) => {
  const { label, onChange, value, values, name } = props;

  return (
    <OptionWrap onClick={onChange}>
      <Checkbox
        checkValue={value}
        labelPosition='right'
        name={name.toString()}
        value={values.find((item) => item.key === value.key)}
        label={label}
        labelBodyVariant='body1'
        onChange={() => {}}
      />
    </OptionWrap>
  );
};
