import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import validate from 'validate.js';

import { t } from 'utils';

import { errorsStore } from 'stores';

const CONSTRAINTS_SCHEME_KEY = '_validation';

type ValidationScheme = {
  [K in string]: ValidationScheme;
};

type Errors = {
  [K in string]: Array<string>;
};

type DataForValidation =
  | {
      [K in string]: unknown;
    }
  | Array<DataForValidation>;

type SkipRule = (obj: Object, prefix: string) => boolean;

class Validator {
  validationScheme: ValidationScheme;
  prefix: string;
  errors: Errors | nil;
  dataForValidation: DataForValidation;
  skipRule: SkipRule;
  prependFieldName: boolean;

  static validate(
    object: {
      [K in any]: any;
    },
    validator:
      | {
          [K in any]: any;
        }
      | Function,
    prependFieldName: boolean,
  ):
    | {
        [K in string]: Array<[string]>;
      }
    | null {
    if (!object || !validator)
      // @ts-ignore
      return;

    if (isFunction(validator)) {
      return validate(object, validator(object), { fullMessages: prependFieldName });
    } else {
      return validate(object, validator, { fullMessages: prependFieldName });
    }
  }

  constructor(
    dataForValidation: DataForValidation,
    validationScheme: ValidationScheme,
    prefix: string,
    skipRule: SkipRule,
    prependFieldName: boolean,
  ) {
    this.validationScheme = validationScheme;
    this.prefix = prefix;
    this.dataForValidation = dataForValidation;
    this.errors = undefined;
    this.skipRule = skipRule;
    this.prependFieldName = prependFieldName;

    const errorKeyPrefix = prefix ? `${prefix}.` : '';

    this.validate(dataForValidation, validationScheme, errorKeyPrefix);
  }

  validate(dataForValidation: DataForValidation, schema: ValidationScheme = this.validationScheme, prefix: string = '') {
    for (let [key, nestedSchema] of Object.entries(schema)) {
      if (key === CONSTRAINTS_SCHEME_KEY) {
        if (dataForValidation instanceof Array) {
          this.validateArray(dataForValidation, nestedSchema, prefix);
        } else {
          this.validateObject(dataForValidation, nestedSchema, prefix);
        }
      } else {
        if (dataForValidation instanceof Array) {
          dataForValidation.forEach((objectForValidation: AnyObject, index) => {
            const nestedErrorKeyPrefix = `${prefix}${index}.${key}.`;
            this.validate(objectForValidation[key], nestedSchema, nestedErrorKeyPrefix);
          });
        } else {
          const nestedErrorKeyPrefix = `${prefix}${key}.`;

          if (!dataForValidation[key]) {
            return;
          }

          this.validate(dataForValidation[key] as AnyObject, nestedSchema, nestedErrorKeyPrefix);
        }
      }
    }
  }

  validateObject(dataForValidation: DataForValidation, schema: ValidationScheme = this.validationScheme, prefix: string = '') {
    if (dataForValidation instanceof Array || !dataForValidation) {
      return;
    }

    const isSkipped = this.skipRule(dataForValidation, prefix);
    if (isSkipped) {
      return;
    }

    const errors = Validator.validate(dataForValidation, schema, this.prependFieldName);
    if (!errors) {
      return;
    }

    this.addErrors(errors as any, prefix);
  }

  validateArray(dataForValidation: DataForValidation, schema: ValidationScheme = this.validationScheme, prefix: string = '') {
    if (!(dataForValidation instanceof Array) || !dataForValidation) {
      return;
    }

    dataForValidation.forEach((obj, index) => {
      this.validateObject(obj, schema, `${prefix}${index}.`);
    });
  }

  addErrors(
    errors: {
      [K in string]: Array<string>;
    },
    prefix: string = '',
  ) {
    if (!this.errors) {
      this.errors = {};
    }

    const errorKeys = Object.keys(errors);

    errorKeys.forEach((key) => {
      (this.errors || {})[`${prefix}${key}`] = this.translateErrors(errors[key]);
    });
  }

  translateErrors(errors: string[]) {
    return (errors || []).map<string>((key) => t.staticAsStringMultiple(key));
  }
}

export { Validator };

export function setNestedValidation(
  action: string,
  schema: {
    [K in any]: any;
  },
  objectForValidation: Object,
  options: {
    skipRule?: AnyFunction;
    prependFieldName?: boolean;
  } = {},
) {
  const skipRule = options.skipRule || (() => false);
  const prependFieldName = options.prependFieldName ?? true;

  const validator = new Validator(objectForValidation as any, schema, '', skipRule, prependFieldName);
  const errors: any = validator.errors;

  if (!isEmpty(errors)) {
    errorsStore.add(action, errors);

    return isEmpty(errors);
  }

  return true;
}
