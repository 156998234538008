import { RESOLUTIONS } from 'src/constants';

// ACHTUNG
// WARNING
// NOTE, DEPRECATED in favor to stores/windowSize

class Resize {
  // Deprecated---
  // mobileSize = 992;
  mobileSize = 1200;
  isMobile(): boolean {
    return this._getWindowWidth() <= this.mobileSize;
  }
  //--- Deprecated

  _getWindowWidth() {
    return window.innerWidth;
  }

  isLessThan(value: resolutions) {
    const currentWindowWidth = this._getWindowWidth();
    const max = RESOLUTIONS[value];

    return currentWindowWidth <= max;
  }

  isGreaterThan(value: resolutions) {
    const currentWindowWidth = this._getWindowWidth();
    const min = RESOLUTIONS[value];

    return currentWindowWidth >= min;
  }
}

const resize = new Resize();

export { resize };
