import { observable, action, makeObservable } from 'mobx';

const defaultOptions = {
  isWhite: false,
};

class Overlay {
  @observable isShow: boolean = false;
  options: AnyObject = defaultOptions;

  constructor() {
    makeObservable(this);
  }

  @action
  show(options: Object = defaultOptions) {
    this.isShow = true;
    this.options = options;
  }

  @action
  hide() {
    this.isShow = false;
    this.options = defaultOptions;
  }

  @action
  toggle(options: Object = defaultOptions) {
    this.isShow = !this.isShow;

    if (this.isShow) {
      this.options = options;
    } else {
      this.options = defaultOptions;
    }
  }
}

export { Overlay };
export default new Overlay();
