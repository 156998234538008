import { ROUTER } from 'src/constants';
import { t } from 'src/utils';

export const LANGUAGE_SETTINGS = {
  value: 'en',
  onClick: () => {},
};

export const AUTH_SETTINGS = {
  isGuest: true,
  options: () => [{ label: 'Sign In', value: 1, key: 1 }],
  avatar: null,
  label: 'SIGN UP',
  onChange: () => {},
};

export const NAVIGATION_OPTIONS = [
  {
    link: ROUTER.ORGANIZERS,
    titleLink: 'navigation.organizersLink',
    showInDesktop: true,
    ATN: 'organizers',
  },
  // {
  //   link: ROUTER.ARTICLES,
  //   titleLink: t.staticAsString('navigation.articlesLink'),
  //   showInDesktop: true,
  //   className: 'active',
  //   ATN: 'articles',
  // },
];

export const HEADER_ANIMATION_DURATION = '170ms';
export const MOBILE_HEADER_ANIMATION_DURATION = '300ms';
export const HEADER_HEIGHT = 64;
