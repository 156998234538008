import React from 'react';
import styled from 'styled-components';

type Props = {
  link: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
const Wrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.miles.colors.dark.alpha['900']};

    :active,
    :hover {
      color: ${(props) => props.theme.miles.colors.dark.thin.primary};
    }
  }
`;
export const SocialIcon: React.FC<Props> = ({ link, children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <a href={link} target='_blank'>
        {children}
      </a>
    </Wrapper>
  );
};
