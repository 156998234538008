export const geocodeByAddress = (address: string): Promise<any[]> => {
  const geocoder = new (window as any).google.maps.Geocoder();
  const OK = (window as any).google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};
