import { distanceSelector } from '../../selectors/mappedData';
import { Data } from '../../types';

function findCustomField(id: number | nil): CustomFields | null {
  if (!id) {
    return null;
  }

  return distanceSelector.expandedCustomFields.get()[id] || null;
}

export const onlyPriceFields = (data: Data.Backend.CustomFieldValueFromBE[]) => {
  return data.filter((item) => {
    const customField = findCustomField(item.field_id);
    return (customField?.values || []).some((value) => Number(value.price));
  });
};
