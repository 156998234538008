import { first } from 'lodash';

import { distancesStore } from '../stores';

/*
 * In case of distance change, necessary to init first distance
 */
function findFirstDistanceToLoad(distanceId: null | void | number): number | undefined {
  const matchDistance = distancesStore.find(distanceId || 0);

  if (matchDistance) {
    return matchDistance.value.id;
  }

  return first(distancesStore.filteredValues)?.id;
}

export { findFirstDistanceToLoad };
