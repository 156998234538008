import * as amplitude from '@amplitude/analytics-browser';
import axios from 'axios';
import { openModalStore } from 'modules/auth/stores';

import { LOGIN, SIGNUP, FORGOT_PASSWORD, LOGOUT, RESET_PASSWORD, API_APPLE_LOGIN_URL, ADMIN_API_LOGIN_URL } from 'src/constants';
import {
  API_URL,
  API_LOGIN_URL,
  API_FB_LOGIN_URL,
  API_GOOGLE_LOGIN_URL,
  API_LOGOUT_URL,
  API_REGISTRATION_URL,
  API_FORGOT_URL,
  API_RESSET_PASSWORD,
} from 'src/constants';

import { request, action } from 'utils';

import { userService } from 'services';

import { sessionStore, errorsStore } from 'stores';

export type AppleLoginType = {
  auth_code: string;
  firstname?: string;
  lastname?: string;
};

class AuthService {
  @request({ action: LOGIN })
  async loginRequest({ email, password }: LoginType): Promise<any> {
    return axios.post(`${API_URL}${API_LOGIN_URL}`, { email, password });
  }

  @request({ action: LOGIN })
  async loginAdminRequest({ email, password }: LoginType): Promise<any> {
    return axios.post(`${ADMIN_API_LOGIN_URL}`, { email, password });
  }

  @action({ action: LOGIN })
  async login({ email, password }: LoginType): Promise<any> {
    const [responseStatus, response] = await this.loginRequest({
      email,
      password,
    });

    if (responseStatus) {
      await this.handleLogin(response);
    } else {
      const [adminResponseStatus, adminResponse] = await this.loginAdminRequest({
        email,
        password,
      });

      if (adminResponseStatus) {
        openModalStore.on();
        amplitude.track('OrganizerHelperDialog.Open');
      } else {
        errorsStore.add(LOGIN, { email: [], password: [] });
      }
    }

    return responseStatus;
  }

  @request({ action: LOGIN })
  async loginFbRequest(access_token: string): Promise<any> {
    return axios.post(`${API_URL}${API_FB_LOGIN_URL}`, {
      access_token,
    });
  }

  @action({ action: LOGIN })
  async loginFb(access_token: string): Promise<any> {
    const [responseStatus, response] = await this.loginFbRequest(access_token);

    if (responseStatus) {
      await this.handleLogin(response);
      return responseStatus;
    }

    return responseStatus;
  }

  @request({ action: LOGIN })
  async loginAppleRequest({ auth_code, firstname, lastname }: AppleLoginType): Promise<any> {
    return axios.post(`${API_URL}${API_APPLE_LOGIN_URL}`, {
      auth_code,
      firstname,
      lastname,
      type: 'web',
    });
  }

  @action({ action: LOGIN })
  async loginApple({ auth_code, firstname, lastname }: AppleLoginType): Promise<any> {
    const [responseStatus, response] = await this.loginAppleRequest({ auth_code, firstname, lastname });

    if (responseStatus) {
      await this.handleLogin(response);
      return responseStatus;
    }

    return responseStatus;
  }

  @request({ action: LOGIN })
  async loginGoogleRequest(access_token: string): Promise<any> {
    return axios.post(`${API_URL}${API_GOOGLE_LOGIN_URL}`, {
      access_token: access_token,
      type: 'web',
    });
  }

  @action({ action: LOGIN })
  async loginGoogle(access_token: string): Promise<any> {
    const [responseStatus, response] = await this.loginGoogleRequest(access_token);

    if (responseStatus) {
      await this.handleLogin(response);
      return responseStatus;
    }

    return responseStatus;
  }

  @request({ action: SIGNUP })
  async registrationRequest({ email, password, password_confirmation, is_organizer, subscribe_for_newsletter }: SignupType): Promise<any> {
    return axios.post(`${API_URL}${API_REGISTRATION_URL}`, {
      email,
      password,
      password_confirmation,
      is_organizer,
      subscribe_for_newsletter,
    });
  }

  @action({ action: SIGNUP })
  async registration(fields: SignupType): Promise<any> {
    const [responseStatus, response] = await this.registrationRequest(fields);

    if (responseStatus) {
      await this.handleLogin(response);
      return responseStatus;
    }

    return responseStatus;
  }

  @request({ action: FORGOT_PASSWORD })
  async forgotPasswordRequest({ email, redirect_location }: ForgotPasswordType): Promise<any> {
    return axios.post(`${API_URL}${API_FORGOT_URL}`, {
      email,
      redirect_location,
    });
  }

  @action({ action: FORGOT_PASSWORD })
  async forgotPassword(state: ForgotPasswordType): Promise<any> {
    const [responseStatus] = await this.forgotPasswordRequest(state);

    return responseStatus;
  }

  @request({ action: RESET_PASSWORD })
  async resetPasswordRequest({ token, password, password_confirmation }: ResetState): Promise<any> {
    return axios.post(`${API_URL}${API_RESSET_PASSWORD}`, {
      token,
      password,
      password_confirmation,
    });
  }

  @action({ action: RESET_PASSWORD })
  async resetPassword(state: ResetState): Promise<any> {
    const [responseStatus, response] = await this.resetPasswordRequest(state);

    await this.handleLogin(response);

    return responseStatus;
  }

  @request({ action: LOGOUT })
  async logOutRequest(): Promise<any> {
    return axios.delete(`${API_URL}${API_LOGOUT_URL}`);
  }

  @action({ action: LOGOUT })
  async logOut(): Promise<any> {
    const [responseStatus] = await this.logOutRequest();

    if (responseStatus) {
      sessionStore.logout();
    }
  }

  async handleLogin(response: any) {
    const token = response.data.data?.token || '';

    if (token) {
      sessionStore.login(token);
      await userService.loadUserProfile();
    }
  }
}

const authService = new AuthService();
export { AuthService, authService };
