import { keys, isEmpty, isArray, isObject, isString, isDate, flatten } from 'lodash';
import moment from 'moment';

import { CustomFieldTypes } from '../../constants';

import { distanceSelector } from '../../selectors/mappedData';
import { Data } from '../../types';
import { fieldIdUtils } from '../fieldIdUtils';

function findCustomField(id: number | nil): CustomFields | null {
  if (!id) {
    return null;
  }

  return distanceSelector.expandedCustomFields.get()[id] || null;
}

function parseId(customFieldKey: nil | string): number | nil {
  if (!customFieldKey) {
    return null;
  }

  const idMatch = customFieldKey.split(fieldIdUtils.staticKeys.customFieldValueIdPrefix);

  return parseInt(idMatch?.[1]) || null;
}

function mapTextField(value: string | nil): string | null {
  if (!isString(value)) {
    return null;
  }

  return value || null;
}

function mapDateField(value: moment.Moment | nil): string | null {
  if (!moment.isMoment(value)) {
    return null;
  }

  if (!value.isValid()) {
    return null;
  }

  return value.format('YYYY-MM-DD');
}

function mapTimeField(value: string | nil): string | null {
  if (!value) {
    return null;
  }

  return value;
}

function mapCheckboxField(value: number[] | nil): number[] | null {
  if (!isArray(value) || isEmpty(value)) {
    return null;
  }

  return value;
}

function mapDropdownField(value: NewSelectItemType | nil): string | nil {
  if (!isObject(value)) {
    return null;
  }

  return value?.value.toString() || null;
}

function mapCustomFieldValue(customField: CustomFields, value: Data.Form.CustomFieldValue) {
  switch (customField.type) {
    case CustomFieldTypes.textfield:
      return mapTextField(value as string | nil);
    case CustomFieldTypes.date:
      return mapDateField(value as moment.Moment | nil);
    case CustomFieldTypes.time:
      return mapTimeField(value as string | nil);
    case CustomFieldTypes.checkbox:
      return mapCheckboxField(value as number[] | nil);
    case CustomFieldTypes.radio:
    case CustomFieldTypes.drop_down:
    case CustomFieldTypes.extra:
      return mapDropdownField(value as NewSelectItemType | nil);
    default:
      return null;
  }
}

function packCustomFieldValue(mappedValue: string | number | number[] | nil) {
  if (!mappedValue) {
    return null;
  }

  if (isArray(mappedValue)) {
    return flatten([mappedValue]);
  } else if (Number(mappedValue)) {
    return Number(mappedValue);
  }

  return mappedValue;
}

function packPriceCustomFieldValue(mappedValue: string | number | number[] | nil) {
  if (!mappedValue) {
    return null;
  }

  return flatten([mappedValue]);
}

/**
 * @description
 * Map custom fields being fetched from the form to the backend format of an array of custom field values
 * map custom fields from form to BE format
 *
 * WARNING
 * empty values will be omitted
 */
export function mapFormCFToBECF(data: Data.Form.CustomFields | nil): Data.Backend.CustomFieldValueToBE {
  if (!data || isEmpty(data)) {
    return {};
  }

  const customFieldKeys = keys(data);

  const mappedCustomFields = customFieldKeys.reduce((beCfValues: Data.Backend.CustomFieldValueToBE, cfkey) => {
    const id = parseId(cfkey);
    const customField = findCustomField(id);

    if (!id || !customField) {
      return beCfValues;
    }

    let customFieldValue;

    if (customField.type !== 'textfield') {
      customFieldValue = packCustomFieldValue(mapCustomFieldValue(customField, data[cfkey]));
    } else {
      customFieldValue = mapCustomFieldValue(customField, data[cfkey]);
    }

    if (!customFieldValue) {
      return beCfValues;
    }

    const customFieldId = customField.id;

    beCfValues[customFieldId] = customFieldValue;

    return beCfValues;
  }, {});

  return mappedCustomFields;
}

export function mapPriceFormCFToBECF(data: Data.Form.CustomFields | nil): Data.Backend.CustomFieldValueToBE[] {
  if (!data) {
    return [];
  }

  const customFieldKeys = keys(data);

  return customFieldKeys.reduce((beCfValues: Data.Backend.CustomFieldValueToBE[], cfkey) => {
    const id = parseId(cfkey);
    const customField = findCustomField(id);

    if (!id || !customField) {
      return beCfValues;
    }

    const customFieldValue = packPriceCustomFieldValue(mapCustomFieldValue(customField, data[cfkey]));

    if (isEmpty(customFieldValue)) {
      return beCfValues;
    }

    beCfValues.push({
      id: customField.id,
      values: customFieldValue,
    });

    return beCfValues;
  }, []);
}
