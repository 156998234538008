import ResizeObserver from 'resize-observer-polyfill';

import { progressStore } from 'stores';

import { STARTLIST_CONTAINER_ID, PRELOADER } from '../constants';

import { isViewportFilled, isViewportFilledEnough } from '../utils';

import { loader } from '../actions';

import { disposers } from '../stores';

/*
 * NOTE, Initial data fill up, to enable infinite scroll
 * It will recursively call itself util fill up the viewport
 */
function observeViewportChange() {
  const resizeObserver = new ResizeObserver(observer);
  const resultsViewportEl = document.getElementById(STARTLIST_CONTAINER_ID);

  if (!resultsViewportEl) {
    return false;
  }

  resizeObserver.observe(resultsViewportEl);
  disposers.register(() => resizeObserver.disconnect());
}

function observer(_entries: ResizeObserverEntry[]) {
  if (!isViewportFilledEnough()) {
    loader.loadMore();
  }

  if (isViewportFilled()) {
    progressStore.log(PRELOADER, 'completed');
    return;
  }
}

export { observeViewportChange };
