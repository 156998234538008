import { trainingPlanStore } from 'modules/miles/app/home/stores';
import { Text } from 'modules/miles/shared/components';
import { ArrowDown } from 'modules/miles/shared/components/Icon';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const TrainingMessageAccordionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.miles.colors.dark.alpha['900']};
  padding: 8px 12px;

  border-radius: 8px;
  -webkit-tap-highlight-color: transparent;

  border: 1px solid ${({ theme }) => theme.miles.colors.dark.alpha['300']};
  background: ${({ theme }) => theme.miles.colors.dark.alpha.cobalt300};
  box-shadow: ${({ theme }) => theme.miles.shadows.dark.elevetion1};

  .accordion-body {
    text-align: left;
    overflow: hidden;
    transition: max-height 0.2s ease;
  }

  .divider {
    margin: 20px 0;
  }

  p {
    margin: 0;
  }
`;

const AccordionHeader = styled.div<{ withError?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;

  cursor: pointer;

  .expand-icon {
    display: flex;
    width: 16px;
    .icon {
      color: ${(props) => props.theme.miles.colors.dark.alpha['700']};
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  ${(props) =>
    props.withError &&
    css`
      // Error state css properties
    `}
`;

type Props = {
  children: React.ReactNode;
  headingText: string | React.ReactNode;
  defaultExpanded?: boolean;
  withError?: boolean;
  keyName: string;
  className?: string;
  style?: React.CSSProperties;
};

const TrainingMessageAccordion: React.FC<Props> = ({
  children,
  headingText,
  className,
  keyName,
  defaultExpanded = false,
  withError,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);
  const [setHeight, setHeightState] = useState('0px');
  const content = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setHeightState(isOpen ? '0px' : `${content.current?.scrollHeight}px`);
    trainingPlanStore.setCollapsableState(keyName, !isOpen);
  };

  useEffect(() => {
    if (defaultExpanded) {
      setHeightState(`${content.current?.scrollHeight}px`);
    }
  }, []);

  return (
    <TrainingMessageAccordionWrapper className={className} {...rest}>
      <AccordionHeader onClick={toggleAccordion} withError={withError}>
        <div className='heading-section'>
          <Text size='md' weight='bold'>
            {headingText}
          </Text>
        </div>
        <div className='expand-icon'>
          {isOpen ? (
            <ArrowDown className='icon' size={16} style={{ transform: 'rotate(180deg)' }} />
          ) : (
            <ArrowDown className='icon' size={16} />
          )}
        </div>
      </AccordionHeader>

      <div ref={content} style={{ maxHeight: `${setHeight}` }} className='accordion-body'>
        <div style={{ marginTop: 8 }} />
        {children}
      </div>
    </TrainingMessageAccordionWrapper>
  );
};

export default TrainingMessageAccordion;
