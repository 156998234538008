import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { overlayStore } from 'src/stores';

const StyledOverlay = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;

  width: 100vw;
  height: 100vh;

  background-color: ${(props) => props.theme.main.colors.clay3};
  opacity: 0.66;

  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const Overlay = () => {
  return <Observer>{() => <StyledOverlay open={overlayStore.isShow} />}</Observer>;
};
