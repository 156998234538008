import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { Divider } from '../Divider';
import { Svg } from '../Icons';
import { Utility } from '../Typography';
import { Content, InlineFlex } from './Components';
import { LinkWithCondition } from './LinkWithCondition';

export const Wrapper = styled.div`
  width: 294px;

  ${Content} {
    padding: 0;

    & > span {
      padding-top: 24px;
    }
  }
`;

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.main.colors.clay1};
    padding: 12px;
    padding-left: 16px;

    div {
      display: flex;
      align-items: center;
    }
  `,
);

export const ResultProgress = styled('div')(
  ({ theme }) => css`
    padding: 3px 8px;
    background: ${theme.main.colors.clay3};
    border-radius: 1px;
    margin-right: 12px;
    span {
      color: ${theme.main.colors.auroraLemon};
    }
  `,
);

export const DistanceProgress = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: 10px;
    background: ${theme.main.colors.clay3};
    border-radius: 1px;

    span {
      color: ${theme.main.colors.white};
    }

    @media (min-width: ${RESOLUTIONS.medium}px) {
      margin-bottom: 16px;
    }
  `,
);

export const DateUtility = styled(Utility)(
  ({ theme }) => css`
    margin-left: 8px;
    color: ${theme.main.colors.white};
  `,
);

export const RaceDescription = styled('div')(
  ({ theme }) => css`
      padding-top: 16px;
    
         span {
            display: block;
            color: ${theme.main.colors.clay3};
        }
    }
    `,
);

export const More = styled(Svg)<{ isOpen: boolean }>(
  ({ theme, isOpen }: { theme; isOpen: boolean }) => css`
    border-radius: 50%;

    path {
      fill: ${theme.main.colors.clay2};
    }

    &:hover {
      cursor: pointer;

      path {
        fill: ${theme.main.colors.clay4};
      }
    }

    ${isOpen &&
    css`
      background: ${theme.main.colors.clay2};

      path {
        fill: ${theme.main.colors.clay4};
      }
    `}
  `,
);

export const SportType = styled.span(
  ({ theme }) => css`
    margin-left: 8px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
    color: ${theme.main.colors.white};
  `,
);

export const ResultsRow = styled(InlineFlex)<{ distanceType?: 'virtual' | 'cumulative' }>(
  ({ theme, distanceType }) => css`
    justify-content: space-between;
    padding: ${distanceType === 'cumulative' ? '20px 51px !important' : '16px 8px'};
    cursor: pointer;

    div {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      svg path {
        stroke: ${theme.main.colors.clay3};
        stroke-width: 1.5px;
      }
    }

    span {
      padding-top: 4px;
    }
    ${distanceType === 'cumulative' &&
    css`
      .place,
      .time {
        min-width: 80px;
      }
    `}
  `,
);

export const TitleUtility = styled(Utility)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    min-height: 72px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
  height: 1px;
`;

export const TruncatedUtility = styled(Utility)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RaceResultsLink = styled(LinkWithCondition)`
  color: inherit;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const MoreButtonDisabler = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: -36px;
  right: 12px;
  cursor: pointer;
`;
