import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants/general/settings';
import { windowSize } from 'src/stores/windowSize';

import { Utility } from '../Typography';
import { BaseButtonProps } from './types';

const SecondaryBase = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 1px;
  border: 2px solid ${(props) => props.theme.main.colors.clay3};
  background: ${(props) => props.theme.main.colors.clay3};
  cursor: pointer;

  span {
    color: ${(props) => props.theme.main.colors.white};
  }

  &:checked,
  &:hover {
    background: ${(props) => props.theme.main.colors.white};

    span {
      color: ${(props) => props.theme.main.colors.clay3};
    }
  }

  &:disabled {
    background: ${(props) => props.theme.main.colors.clay4};
    pointer-events: none;
    border-color: ${(props) => props.theme.main.colors.clay4};

    span {
      color: ${(props) => props.theme.main.colors.white};
    }
  }
`;

const SecondarySmall = styled(SecondaryBase)`
  padding: 6px 24px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 11px 40px;
  }
`;

const SecondaryMedium = styled(SecondaryBase)`
  padding: 11px 40px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 16px 48px;
  }
`;

const SecondaryLarge = styled(SecondaryBase)`
  padding: 16px 48px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 26px 48px;
  }
`;

const ComponentTypes = {
  large: SecondaryLarge,
  medium: SecondaryMedium,
  small: SecondarySmall,
};

const ComponentMobileSizes = {
  large: 'u1',
  medium: 'u2',
  small: 'u3',
};

export const SecondaryButton: React.FC<BaseButtonProps> = (props) => {
  const { size = 'large', ...rest } = props;
  const Component = ComponentTypes[size];
  let utilityVariant: 'u1' | 'u2' | 'u3' | 'u4' = ComponentMobileSizes[size] as 'u1' | 'u2' | 'u3';

  if (windowSize.isGreaterThan('medium')) {
    utilityVariant = size === 'small' ? 'u4' : 'u2';
  }

  return (
    <Component {...rest} {...testAnchors.useButton(props.ATN)}>
      <Utility variant={utilityVariant} weight='bold'>
        {props.children}
      </Utility>
    </Component>
  );
};
