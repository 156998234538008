import * as React from 'react';

import { Svg } from 'src/styledComponents/Icons';

type Props = {
  onClick: AnyFunction;
};

function Close({ onClick }: Props) {
  return (
    <div className='close-icon' onClick={onClick}>
      <Svg name='Close' size={18} />
    </div>
  );
}

export { Close };
