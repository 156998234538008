import * as React from 'react';
import styled from 'styled-components';

import { RACE_IMAGE_RATIO, DEFAULT_RACE_IMAGE } from 'src/constants';

type PropsImageWithProportions = {
  ratio: number;
  src: nil | string;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  fallbackUrl?: string;
};

type PropsRaceImage = {
  src: nil | string;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
};

const ImageContainer = styled.div`
  position: relative;
  height: 0;

  .inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover !important;
  }
`;

export const ImageWithProportions = ({ ratio, src, children, fallbackUrl, onClick }: PropsImageWithProportions) => {
  if (!ratio) {
    return null;
  }
  const ratioCoefficient = `${(1 / ratio) * 100}%`;
  return (
    <ImageContainer onClick={onClick} style={{ paddingBottom: ratioCoefficient }}>
      <div className='inner-wrapper' style={{ backgroundImage: `url(${src}), url(${fallbackUrl})` }} />
      {children || null}
    </ImageContainer>
  );
};

export const RaceImage = ({ src, children, onClick }: PropsRaceImage) => {
  return (
    <ImageWithProportions onClick={onClick} src={src || DEFAULT_RACE_IMAGE} ratio={RACE_IMAGE_RATIO} fallbackUrl={DEFAULT_RACE_IMAGE}>
      {children || null}
    </ImageWithProportions>
  );
};
