import { API_SPORT_TYPES_URL, API_URL, LOAD_SPORT_TYPES, SPORT_TYPE } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { sportTypesStore } from 'stores';
import { SportType } from 'stores';

class SportTypes extends LoadableService<SportTypesType, FiltersType, SportType> {
  constructor(store: SportType) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_SPORT_TYPES_URL}`,
      resource: SPORT_TYPE,
    });
  }

  @request({ action: LOAD_SPORT_TYPES })
  async loadResourcesRequest(params: FiltersType | {}): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_SPORT_TYPES, minRequestTime: 800 })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    if (!this.store.values.length) {
      return super.loadResources(params, pageNum);
    }
  }
}

const sportTypesService = new SportTypes(sportTypesStore);
export { SportTypes, sportTypesService };
