import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 8px 0;
`;

function Controls({ children, className }: Props) {
  return <ControlsWrapper className={classNames('bottom-controls', className)}>{children}</ControlsWrapper>;
}

export { Controls };
