import { Observer } from 'mobx-react';
import React from 'react';

import { SocialsSharing } from 'src/components/socials';
import { DEFAULT_RACE_IMAGE } from 'src/constants/mixed';
import { racesStore } from 'src/stores/';

export const SocialIcons = () => {
  return (
    <Observer>
      {() => {
        const racesModel = racesStore.modelSelectedValue;
        const shareTitles = {
          fb: racesModel?.value.name || '',
          tw: racesModel?.value.name || '',
          linked: racesModel?.value.name || '',
        };
        const raceUrl = window.location.href;

        return (
          <SocialsSharing
            type={'horizontal'}
            titles={shareTitles}
            raceURL={raceUrl}
            picture={racesModel?.value?.image_default || DEFAULT_RACE_IMAGE}
          />
        );
      }}
    </Observer>
  );
};

// Note: this (MAYBE) will be needed in future
//
// const SocialIconsWrapper = styled.div`
//   display: flex;
// `;
// export const SocialIcons = (): ReactElement | null => {
//   return (
//     <SocialIconsWrapper className='socials'>
//       <SocialIcon name='RaceFacebook' link={SOCIAL_LINKS.facebook} ATN='facebook' />
//       <SocialIcon name='RaceInstagram' link={SOCIAL_LINKS.instagram} ATN='instagram' />
//       <SocialIcon name='RaceLinkedin' link={SOCIAL_LINKS.linkedin} ATN='linkedin' />
//     </SocialIconsWrapper>
//   );
// };
