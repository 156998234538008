import { isViewportFilledEnough } from '../../utils';

import { pagination } from '../../derivations';
import { loadMore } from './loadMore';

async function loadUntilViewport() {
  await loadMore();
  await new Promise((res, rej) => setTimeout(res, 50));

  if (isViewportFilledEnough()) {
    return;
  }

  if (!pagination.hasMore.get()) {
    return;
  }

  loadUntilViewport();
}

export { loadUntilViewport };
