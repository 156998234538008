import { isEmpty } from 'lodash';
import { handleFocus } from 'modules/search/actions';
import React from 'react';

import { t } from 'utils';

import { SportMenu } from './SportMenu';

type Props = {
  compressed?: boolean;
  fieldName: string;
  label: string;
  placeholder: string;
  value: any;
  onChange: ({ name, value }: { name: string; value: any }) => void;
  isFocused: boolean;
};

export const SportSearch: React.FC<Props> = (props) => {
  const { compressed, fieldName, value = [], onChange, label, placeholder, isFocused } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isFocused && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const [open, setOpen] = React.useState(false);
  const inputValue = isEmpty(value) ? '' : `${t.staticAsString('input.selected')}: ${value.length}`;

  const setFocusedField = () => {
    handleFocus(fieldName);
    setOpen(true);
  };

  const handleChoose = (item: DropdownItemType) => () => {
    const neededValue = value.find((option) => option.key === item.key);
    const values = neededValue ? value.filter((option) => option.key !== item.key) : [...value, item];
    onChange({ name: fieldName, value: values });
  };

  return (
    <>
      <input
        {...testAnchors.useField(fieldName, TEST_ANCHORS.fieldStructure.root)}
        ref={inputRef}
        name={fieldName}
        type='text'
        placeholder={label}
        value={inputValue}
        onChange={() => {}}
        onFocus={setFocusedField}
        readOnly
      />
      <SportMenu onChoose={handleChoose} open={open} fieldName={fieldName} value={value} setOpen={setOpen} />
    </>
  );
};
