import { transaction } from 'mobx';

import { form } from 'stores';

import { FORM_FILTERS_ID } from '../constants';

import { validateData } from '../utils';

import { checkpointsStore, filtersStore } from '../stores';

import { currentDistance } from '../derivations';

function change(checkpointId: number | nil) {
  const distanceId = currentDistance.id.get();

  if (!distanceId || !checkpointId) {
    return;
  }

  if (!validateData.isValidCheckpoint(checkpointId)) {
    return;
  }

  const checkpoint = checkpointsStore.find(checkpointId);

  if (!checkpoint) {
    return;
  }

  transaction(() => {
    filtersStore.partialSetValue({ checkpoint_id: 1 });
    form.onChange(FORM_FILTERS_ID, 'checkpoint_id', checkpoint.toSelectItem());
  });
}

export const checkpointChanger = {
  change,
};
