import * as React from 'react';

import { Button } from 'src/styledComponents/Button';
import { Svg } from 'src/styledComponents/Icons';

import { Utility } from '../Typography';
import { BannerContentWrapper, EventOrganizerBannerWrapper, SubBox, StyledCloseBtn } from './styled';

type ContentBannerProps = {
  bodyText: string;
  buttonText: string;
  size: 'mobile' | 'tablet' | 'desktop';
  level: 0 | 1 | 3 | 5;
  onCloseBanner: () => void;
};

export const ContentBanner: React.FC<ContentBannerProps> = (props) => {
  const { size, level, buttonText, bodyText, onCloseBanner } = props;

  const navigateToAdminRegistration = (): void => {
    const url = 'https://organizer.raceid.com';

    window.open(url, '_blank');
  };

  return (
    <EventOrganizerBannerWrapper level={level} size={size}>
      <BannerContentWrapper>
        <Utility variant='u2' className='event-organizer-banner-text' weight='medium'>
          {bodyText}
        </Utility>
        <SubBox>
          <Button size='small' variant='primary' onClick={navigateToAdminRegistration}>
            {buttonText}
          </Button>
          <StyledCloseBtn onClick={onCloseBanner}>
            <Svg name='Cross' size={22} />
          </StyledCloseBtn>
        </SubBox>
      </BannerContentWrapper>
    </EventOrganizerBannerWrapper>
  );
};
