import { isNil, pickBy, includes } from 'lodash';
import Xregexp from 'xregexp';

import { FIELD_ID_PATTERNS } from '../../constants';

import { Data } from '../../types';

/**
 * @description
 * reject array of custom fields by array of provided fieldId`s
 * select all custom fields that are not included in the fieldIds array
 *
 */
function rejectCustomFields({ index, pattern, fieldIds }: { index: number; pattern: RegExp; fieldIds: string[] }) {
  return (customFields: Data.Form.CustomFields): Data.Form.CustomFields => {
    const disabledFields: { [k in number]: number[] } = {};

    fieldIds.forEach((fieldId) => {
      const match = Xregexp.exec(fieldId, pattern);

      if (!match) {
        return;
      }

      const index = parseInt(match.racerIndex);
      const id = parseInt(match.id);

      if (isNil(index) || isNil(id)) {
        return;
      }

      if (!disabledFields[index]) {
        disabledFields[index] = [];
      }

      disabledFields[index].push(id);
    });

    return pickBy(customFields, (_customFieldValue, customFieldKey) => {
      const match = Xregexp.exec(customFieldKey, FIELD_ID_PATTERNS.customField);
      const id = parseInt(match?.id);

      if (!id) {
        return true;
      }

      return !includes(disabledFields[index], id);
    });
  };
}

export { rejectCustomFields };
