import { isEmpty } from 'lodash';
import { getHostName } from 'modules/race/utils/getHostNameFromUrl';
import * as React from 'react';
import styled from 'styled-components';

import { SOCIAL_ICONS_COLORS } from 'src/constants';
import { SocialIcon } from 'src/styledComponents/SocialIcon';
import { BodyText } from 'src/styledComponents/Typography';
import { BodyTextStyles } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { htmlSanitizer, t } from 'utils';

import { windowSize } from 'stores';

type Props = {
  contacts: ContactsType;
};

const Wrapper = styled.div`
  background: ${(props) => props.theme.main.colors.white};
  padding: 0;
  text-align: left;
`;

const Text = styled.div<{ isMobile: boolean }>`
  padding: ${(props) => (props.isMobile ? '40px 40px 56px' : '56px 56px 72px')};
  text-align: left;

  ${BodyTextStyles.body2}

  a {
    text-decoration: none;
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
  .clay1,
  a {
    color: ${(props) => props.theme.main.colors.clay1};
  }
  .mb12 {
    margin: 0 0 12px;
  }
  .mb16 {
    margin: 0 0 16px;
  }
  .mb24 {
    margin: 0 0 24px;
  }
`;

const SocialIconsWrapper = styled.div`
  display: flex;
  margin: 16px 0 30px;
`;

export const ContactsDescription = (props: Props) => {
  const { contacts } = props;
  const isMobile = windowSize.isLessThan('small');
  const emails = contacts.emails && contacts.emails.map((email) => `<a href="mailto:${email}">${email}</a>`);
  const text = t.staticAsString('race.infoContactTextEmails');
  const isAnySocialIcon = Boolean(contacts.facebook || contacts.instagram || contacts.linkedin);

  return (
    <Wrapper>
      <Text isMobile={isMobile}>
        <Show if={!isEmpty(contacts)}>
          <BodyText variant='body2' className='clay1'>
            <p className='mb16'>{t.staticAsString('race.infoContactOrganizerText')}</p>
            <Show if={Boolean(contacts.name)}>
              <p className='mb12'>{contacts.name}</p>
            </Show>
            <Show if={contacts.emails && contacts.emails.length > 0}>
              <div className='mb12' dangerouslySetInnerHTML={{ __html: htmlSanitizer(emails?.join(', ')) }} />
            </Show>
            <Show if={Boolean(contacts.website)}>
              <div className='mb12'>
                <a target='_blank' href={contacts.website}>
                  {contacts.website ? getHostName(contacts.website) : ''}
                </a>
              </div>
            </Show>
          </BodyText>

          <Show if={isAnySocialIcon}>
            <SocialIconsWrapper>
              <Show if={Boolean(contacts.facebook)}>
                <SocialIcon color={SOCIAL_ICONS_COLORS.gray} name='Facebook' link={contacts.facebook || ''} ATN='facebook' />
              </Show>
              <Show if={Boolean(contacts.instagram)}>
                <SocialIcon color={SOCIAL_ICONS_COLORS.gray} name='Instagram' link={contacts.instagram || ''} ATN='instagram' />
              </Show>
              <Show if={Boolean(contacts.linkedin)}>
                <SocialIcon color={SOCIAL_ICONS_COLORS.gray} name='Linkedin' link={contacts.linkedin || ''} ATN='linkedin' />
              </Show>
            </SocialIconsWrapper>
          </Show>
        </Show>

        <Show if={Boolean(contacts.description)}>
          <p className='mb24'>{contacts.description}</p>
        </Show>

        <BodyText variant='body2' className='clay1'>
          <div dangerouslySetInnerHTML={{ __html: htmlSanitizer(text) }} />
        </BodyText>
      </Text>
    </Wrapper>
  );
};
