import { reaction } from 'mobx';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

import { racesService } from 'services';

import { localeStore } from 'stores';

import { parseRaceId } from '../utils/parseRaceId';

import { loadRace } from '../actions';

import { disposers } from '../stores';

const initialize = async () => {
  const raceId = parseRaceId();
  const response = await racesService.loadResource(raceId);
  if (response.status === 404) {
    history.replace(ROUTER.NOT_FOUND_PAGE);
  }
};

export const observeLanguageChange = () => {
  const disposer = reaction(
    () => localeStore.value,
    () => {
      initialize();
      loadRace();
    },
  );
  disposers.register(disposer);
};
