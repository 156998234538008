import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { InputWrapper } from 'components/form';

import { CheckpointStepper as CheckpointStepperComponent } from '../../components/CheckpointStepper';

import { checkpointsSelect, initDistanceLoader } from '../../utils';

const Wrapper = styled.div`
  padding-left: 20px;

  .navigation-steps {
    margin-top: 28px;
    margin-bottom: 32px;
  }

  .active-step-title {
    padding-bottom: 16px;
  }

  .list-wrapper .step-item {
    .step-item-count-wrap {
      width: 32px;
      height: 32px;

      span {
        font-size: 20px;
      }
    }
  }
`;

export const MobileCheckpointStepper = () => {
  return (
    <Observer>
      {() => {
        const checkpointsOptions = checkpointsSelect.options.get();

        const isCheckpoints = !!checkpointsOptions.length;

        if (!isCheckpoints) {
          return null;
        }

        if (initDistanceLoader.isLoading()) {
          return null;
        }

        return (
          <Wrapper className='navigation-menu-bottom'>
            <InputWrapper
              name='checkpoint_id'
              settings={{
                placeholder: 'Checkpoint',
                dropListItems: checkpointsOptions,
              }}
              Component={CheckpointStepperComponent}
            />
          </Wrapper>
        );
      }}
    </Observer>
  );
};
