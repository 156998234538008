import { APP_INITIALIZE } from 'src/constants';

import { countriesService, sportTypesService, userService } from 'services';

import { progressStore } from 'stores';

export const mount = async (): Promise<void> => {
  progressStore.log(APP_INITIALIZE, 'progress');
  await Promise.all([await sportTypesService.loadResources(), await countriesService.loadResources(), await userService.loadUserProfile()]);
  progressStore.log(APP_INITIALIZE, 'completed');
};
