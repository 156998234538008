import { Observer } from 'mobx-react';
import * as React from 'react';

import { resultsPresentation } from '../../derivations';
import type { LResult } from '../../types';

type Props = {
  result: LResult;
  checkpointId: number;
};

function SplitFinishTime({ result, checkpointId }: Props) {
  const generator = resultsPresentation.generateSplitFinishResult(result, checkpointId);

  return (
    <Observer>
      {() => {
        return <>{generator.get()}</>;
      }}
    </Observer>
  );
}

export { SplitFinishTime };
