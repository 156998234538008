import { LCoupon, LCouponUsageType } from '../../types';
import { calculateDiscountedPrice } from './calculateDiscountedPrice';

type Args = {
  distancePrice: number;
  customFieldTotalPrice: number;
  coupon: LCoupon | nil;
};

/**
 * @description
 * Calculate member price based on the coupon settings and provided distance and custom field prices
 */
function calculateMemberPriceWithDiscount({ distancePrice, customFieldTotalPrice, coupon }: Args) {
  if (!coupon) {
    return distancePrice + customFieldTotalPrice;
  }

  const amount = parseFloat(coupon.discount_amount) || NaN;
  const isPercentage = coupon.discount_percentage;

  if (coupon.usage_type === LCouponUsageType.DistanceOnly) {
    return calculateDiscountedPrice(distancePrice, { amount, isPercentage }) + customFieldTotalPrice;
  }

  if (coupon.usage_type === LCouponUsageType.CustomFieldsOnly) {
    return calculateDiscountedPrice(customFieldTotalPrice, { amount, isPercentage }) + distancePrice;
  }

  if (coupon.usage_type === LCouponUsageType.DistanceAndCustomFields) {
    return calculateDiscountedPrice(distancePrice + customFieldTotalPrice, { amount, isPercentage });
  }

  return 0;
}

export { calculateMemberPriceWithDiscount };
