import { some } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';
import { distanceToSelectItem } from 'modules/registration/utils/mappers/distanceToSelectItem';

import { Distance } from 'models';

import { confirmationStore, raceDistancesListStore } from '../../stores';

import { distanceSelector } from '../mappedData';

const distanceOptions: IComputedValue<NewSelectItemType[]> = computed(() => {
  const distances = raceDistancesListStore.value || [];

  const models = distances.map((distance) => {
    return new Distance(distance);
  });

  return models
    .filter((distance) => {
      const isAlreadyRegistered = distance.isAlreadyRegistered() && !confirmationStore.isInvited;
      const isSoldOut = distance.isSoldOut();
      const isPending = distance.isTherePendingPayment();
      const isRegistrationClosed = distance.isRegistrationClosed();

      /**
       * do not filter distance if it is currently selected
       */
      if (distance.value.id === distanceSelector.id.get()) {
        return true;
      }

      return !some([isAlreadyRegistered, isSoldOut, isPending, isRegistrationClosed]);
    })
    .map((distance) => {
      return distanceToSelectItem(distance);
    });
});

export { distanceOptions };
