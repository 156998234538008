import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NavLink, NavTab } from 'src/styledComponents/Link';

import { t } from 'utils';

import { isUrl } from '../../utils';

import { ItemProps, Props } from './NavItemTypes';

const MenuItem = styled.li<ItemProps>`
  display: flex;
  justify-content: center;

  a,
  span {
    cursor: pointer;
    position: relative;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${(props) => props.theme.main.colors.clay1};
    font-size: ${(props) => (props.size === 'large' ? '16px' : '14px')};
    line-height: ${(props) => (props.size === 'large' ? '20px' : '18px')};
    font-weight: 700;
    letter-spacing: 0.5px;
    transition: background 0.1s 0.2s ease;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 4px;
      width: 0;
      background-color: inherit;
      transition: width 0.3s ease;
    }

    &.active {
      color: ${(props) => props.theme.miles.colors.light.thin.primary};
    }

    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.miles.colors.light.thin.primary};

        &::before {
          width: 100%;
        }
      }
    }

    ${(props) =>
      props.variant === 'sub' &&
      css`
        color: ${(props) => props.theme.main.colors.clay1};

        &.active {
          color: ${(props) => props.theme.main.colors.clay1};
          background-color: ${(props) => props.theme.main.colors.white};

          &::before {
            background-color: ${(props) => props.theme.main.colors.clay3};
            width: 100%;
          }
        }
        @media (hover: hover) {
          &:hover {
            color: ${(props) => props.theme.main.colors.clay1};
            background-color: ${(props) => props.theme.main.colors.white};

            &::before {
              background-color: ${(props) => props.theme.main.colors.clay3};
              width: 100%;
            }
          }
        }
      `}

    ${(porps) =>
      porps.showInMobile &&
      css`
        @media (min-width: 1200px) {
          display: none;
        }
      `}
  }
`;

const NavItemComponent = (props: Props & OptionalTestAnchorProps) => {
  const { className, exact, link: linkKey, isActiveFunc, isActive, onClick, size, showInDesktop, variant, withHistoryReplace } = props;
  const title = t(props.titleLink as TranslationLockedKeys);
  const link = t.staticAsString(linkKey as TranslationLockedKeys);

  return (
    <MenuItem size={size} variant={variant} className={className || ''} showInMobile={!showInDesktop}>
      {isUrl(link) ? (
        <a target='_blank' href={link} {...testAnchors.useLink(props.ATN)}>
          {title}
        </a>
      ) : withHistoryReplace ? (
        <NavTab ATN={props.ATN} onClick={onClick} link={link} isActive={isActive}>
          {title}
        </NavTab>
      ) : (
        <NavLink ATN={props.ATN} onClick={onClick} to={link} exact={exact || false} isActive={isActiveFunc} activeClassName='active'>
          {title}
        </NavLink>
      )}
    </MenuItem>
  );
};

const NavItem = withRouter(NavItemComponent);

export { NavItem };
