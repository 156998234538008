import { app, fireToast, t } from 'utils';

import { translations } from 'actions/translations';

import { errorsStore, sessionStore } from 'stores';

type ResponseProcessorArgs = {
  action: string;
  response: any;
  root: string;
  writeError: boolean;
  error?: null | Error;
};

const ERROR_500_ID = 'ERROR_500_ID';
const ERROR_429_ID = 'ERROR_429_ID';

class ResponseProcessor {
  action: string;
  response: any;
  root: string;
  writeError: boolean;
  error: null | Error;

  constructor(params: ResponseProcessorArgs) {
    const { action, response, root, writeError, error } = params;
    this.action = action;
    this.response = response;
    this.root = root;
    this.writeError = writeError;
    this.error = error || null;
  }

  async process() {
    if (!this.response) {
      console.error('Provided invalid response object, response processing will not be performed.', this.error);
      return;
    }
    try {
      this.processByStatus();
      this.processNewToken();
      this.processTranslationsVerion();
    } catch (e) {
      console.error(e);
    }
  }

  processNewToken() {
    // const headers = (this.response.headers: any);
    // const token = headers && headers['authorization'];
    // if (token)
    //   sessionStore.debouncedSetToken(token);
  }

  processByStatus(): void {
    switch (this.response.status) {
      case 401:
        this.handleUnauthorized();
        return;
      case 403:
        this.handleValidationError();
        return;
      case 404:
        return;
      case 418:
        return this.handleInvalidVersion();
      case 422:
        this.handleValidationError();
        return;
      case 429:
        console.error('Too many requests', this.response);
        fireToast(t.staticAsString('toast.tooManyRequests'), 'error', '', 'top-right', 5000, true, ERROR_429_ID);
        return;
      case 500:
        console.error('Internal server error', this.response);
        fireToast(
          'Server is not responding to your request, please contact support@raceid.com',
          'error',
          '',
          'top-right',
          5000,
          true,
          ERROR_500_ID,
        );
        return;
      case 502:
        console.error('Bad gateway', this.response);
        this.handleShowStub();
        return;
      case 503:
        console.error('Service unavailable', this.response);
        this.handleShowStub();
        return;
      default:
        return;
    }
  }

  processTranslationsVerion() {
    translations.handleVersionChange(this.response as any);
  }

  handleUnauthorized() {
    sessionStore.logout();
  }

  handleInvalidVersion() {
    app.reload();
  }

  handleShowStub() {
    app.stub();
  }

  handleValidationError() {
    if (this.writeError && this.response && this.response.data) {
      errorsStore.addApiErrors(this.action, this.response.data[this.root]);
      errorsStore.add(this.action, this.response.data[this.root]);
    }
  }
}

export { ResponseProcessor };
