import * as React from 'react';
import styled from 'styled-components';

import { Headline } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

const TitleWrap = styled.div`
  display: flex;
  align-items: center;

  margin: 41px 0 32px;
  padding: 6px 0 6px 32px;
  border-left: 3px solid ${(props) => props.theme.main.colors.clay1};

  h1 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
`;

type Props = {
  title: TranslationLockedKeys;
};

export const BlockSubtitle: React.FC<Props> = (props: Props) => {
  const { title } = props;

  return (
    <TitleWrap>
      <Headline variant='h4'>{t.staticAsString(title)}</Headline>
    </TitleWrap>
  );
};
