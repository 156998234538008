import { calculatePercents } from 'utils';

function calcProgress(logged: number | null | void, goal: number | null | void): string {
  if (!logged || !goal) {
    return '0%';
  }

  if (logged >= goal) {
    return '100%';
  }

  return `${calculatePercents(logged, goal)}%`;
}

export { calcProgress };
