import { Observer } from 'mobx-react';
import * as React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import styled, { css } from 'styled-components';

import { borderStore } from 'src/modules/racersTable/stores';
import { scrollBarStyles } from 'src/styles';

type Props = {
  sticky?: boolean;
  withBorder?: boolean;
  children?: React.ReactNode;
};

const isSticky = (props) => {
  if (!props.sticky) return '';

  return css`
    position: -webkit-sticky;
    position: sticky;
    top: 88px;
    z-index: 10;
  `;
};

const getBorderStyles = (props) => {
  if (!props.showBorder) return '';

  return css`
    &:before {
      content: '';
      position: absolute;
      top: 74px;
      left: 447px;
      z-index: 1;

      height: calc(100% - 179px);
      width: 1px;
      background-color: ${props.theme.main.colors.clay5};
    }
  `;
};

const Wrapper = styled.div<{ sticky?: boolean; showBorder?: boolean }>`
  overflow-x: auto;
  overflow-y: hidden;

  ${isSticky}
  ${getBorderStyles}
  ${scrollBarStyles}
`;

const Body = styled.div`
  width: fit-content;
  min-width: 100%;
`;

export const RightSideWrapper: React.FC<Props> = (props) => {
  const { withBorder = false } = props;

  return (
    <Observer>
      {() => {
        const showBorder = Boolean(withBorder && borderStore.value);
        return (
          <ScrollSyncPane>
            <Wrapper showBorder={showBorder} sticky={props.sticky}>
              <Body>{props.children}</Body>
            </Wrapper>
          </ScrollSyncPane>
        );
      }}
    </Observer>
  );
};
