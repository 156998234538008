import { HeaderVariants } from 'modules/header/types';

import { theme } from 'styles';

const colors = theme.miles.colors;
export const getBackgroundColor = (variant?: HeaderVariants) => {
  switch (variant) {
    case HeaderVariants.milesLanding:
      return colors.brand.cobalt.cobalt900;
    case HeaderVariants.milesApp:
      return colors.dark.background.tertiary;
    default:
      return colors.dark.alpha['900'];
  }
};
