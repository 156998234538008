import * as React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import shortid from 'shortid';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { Item } from './Item';

type Props = {
  value: SelectItemType;
  dropListItems: Array<SelectItemType>;
  isDesktop: boolean;
} & BaseInputProps;

const StyledScrollContainer = styled(ScrollContainer)`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 40px;

  .active-step-title {

    span:first-child {
      padding-right: 8px;
    }
  }

  .list-wrapper {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    .step-item {
      .step-item-count-wrap {
        border: 1px solid ${(props) => props.theme.main.colors.clay1};
        border-radius: 1px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;

        &:hover {
          cursor: pointer;
        }
      }

      &.is-active .step-item-count-wrap {
        background: ${(props) => props.theme.main.colors.clay1};

        .step-item-count {
          color: ${(props) => props.theme.main.colors.white};
        }
      }
    }
  }
`;

class CheckpointStepper extends React.Component<Props> {
  onChange = (value: SelectItemType, e: React.SyntheticEvent) => {
    const { name } = this.props;

    this.props.onChange({ name, value }, e);
  };

  items = () => {
    const { dropListItems, value } = this.props;
    const stepsLength = dropListItems.length - 1;

    return dropListItems.map<React.ReactNode>((item, index) => {
      const isActive = value?.id === item.id;
      const isLast = stepsLength === index;

      return <Item key={shortid()} value={item} onChange={this.onChange} index={index} isActive={isActive} isLast={isLast} />;
    });
  };

  render() {
    const { dropListItems, value, isDesktop } = this.props;
    const activeStep = dropListItems.find((item) => value?.id === item.id);

    return (
      <>
        <div className='active-step-title'>
          <Utility variant='u2' weight='medium'>
            {t.staticAsString('results.checkpoint')}:
          </Utility>
          <Utility variant='u2' weight='bold'>
            {activeStep?.title}
          </Utility>
        </div>
        <StyledScrollContainer className='navigation-steps' vertical={false} horizontal={isDesktop} component='ul'>
          <div className='list-wrapper'>{this.items()}</div>
        </StyledScrollContainer>
      </>
    );
  }
}

export { CheckpointStepper };
