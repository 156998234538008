import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  weight: keyof typeof Weight;
  size: keyof typeof Sizes;
  fontStyle?: 'italic' | 'normal';
  className?: string;
  children?: React.ReactNode | JSX.Element;
  style?: React.CSSProperties;
};

enum Weight {
  light = 'light',
  regular = 'regular',
  bold = 'bold',
  heavy = 'heavy',
}

enum Sizes {
  xxl = 'xxl',
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
}

const getFontWeight = (weight: keyof typeof Weight) => {
  switch (weight) {
    case Weight.light:
      return 300;
    case Weight.regular:
      return 400;
    case Weight.bold:
      return 700;
    case Weight.heavy:
      return 900;
    default:
      return 400;
  }
};

const baseStyles = css<Omit<Props, 'size'>>`
  margin: 0;
  font-family: Aeonik, sans-serif;
  font-style: ${(props) => (props.fontStyle === 'italic' ? 'italic' : 'normal')};
  font-weight: ${(props) => getFontWeight(props.weight)};
`;

const H1 = styled.h1`
  ${baseStyles};
  font-size: 72px;
  line-height: 76px;
`;

const H2 = styled.h2`
  ${baseStyles};
  font-size: 60px;
  line-height: 64px;
`;

const H3 = styled.h3`
  ${baseStyles};
  font-size: 48px;
  line-height: 52px;
`;

const H4 = styled.h4`
  ${baseStyles};
  font-size: 36px;
  line-height: 40px;
`;

const H5 = styled.h5`
  ${baseStyles};
  font-size: 30px;
  line-height: 34px;
`;

const H6 = styled.h6`
  ${baseStyles};
  font-size: 24px;
  line-height: 28px;
`;

export const Display: React.FC<Props> = (props) => {
  const { children, size, className, ...rest } = props;

  switch (size) {
    case Sizes.xxl:
      return (
        <H1 className={className} {...rest}>
          {children}
        </H1>
      );

    case Sizes.xl:
      return (
        <H2 className={className} {...rest}>
          {children}
        </H2>
      );

    case Sizes.lg:
      return (
        <H3 className={className} {...rest}>
          {children}
        </H3>
      );

    case Sizes.md:
      return (
        <H4 className={className} {...rest}>
          {children}
        </H4>
      );

    case Sizes.sm:
      return (
        <H5 className={className} {...rest}>
          {children}
        </H5>
      );

    case Sizes.xs:
      return (
        <H6 className={className} {...rest}>
          {children}
        </H6>
      );

    default:
      return (
        <H1 className={className} {...rest}>
          {children}
        </H1>
      );
  }
};
