function winningPlaceClass(place: null | void | number, isDistanceFinished: boolean) {
  if (!place) {
    return '';
  }

  if (!isDistanceFinished) {
    return '';
  }

  if (place > 3 || place < 1) {
    return '';
  }

  return 'winning-place-' + place;
}

export { winningPlaceClass };
