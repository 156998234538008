export const CELL_SIZES = [
  {
    name: 'club',
    size: '1fr',
    minWidth: '222px',
  },
  {
    name: 'squad',
    size: '1fr',
    minWidth: '205px',
  },
  {
    name: 'class',
    size: '1fr',
    minWidth: '205px',
  },
  {
    name: 'wave',
    size: '1fr',
    minWidth: '205px',
  },
  {
    name: 'time',
    size: '80px',
  },
  {
    name: 'diff',
    size: '80px',
  },
  {
    name: 'goalDistance',
    size: '62px',
  },
  {
    name: 'tracker',
    size: '74px',
  },
  {
    name: 'checkpoint',
    size: '24px',
  },
];

export const SCROLL_ELEMENT_ID = 'racers-table_SCROLL_ELEMENT_ID';
