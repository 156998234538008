import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';

import { handleDecreaseZoom, handleIncreaseZoom } from '../actions';

const ControlsWrap = styled.div`
  display: grid;
  grid-template-columns: 32px;
  grid-template-rows: 32px;
  gap: 4px;

  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
`;

const IconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background-color: ${(props) => props.theme.main.colors.clay2};
  border-radius: 2px;
  cursor: pointer;
`;

export const ZoomControls = () => {
  return (
    <ControlsWrap>
      <IconButton onClick={handleIncreaseZoom} {...testAnchors.useButton('zoom-in')}>
        <Svg name='ZoomPlus' size={20} />
      </IconButton>
      <IconButton onClick={handleDecreaseZoom} {...testAnchors.useButton('zoom-out')}>
        <Svg name='ZoomMinus' size={20} />
      </IconButton>
    </ControlsWrap>
  );
};
