import { signInModalStore } from 'modules/registration/stores';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Divider } from 'src/styledComponents/Divider';
import { Svg } from 'src/styledComponents/Icons';
import { Headline } from 'src/styledComponents/Typography';

import { overlayStore } from 'stores';

import { AuthProps } from '.';

const Wrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      text-transform: uppercase;
      margin: 0;
    }

    .close-icon {
      cursor: pointer;
    }
  }

  .divider {
    margin: 15px 0;
  }

  .login-btn {
    margin-top: 22px;
    margin-bottom: 8px;
    padding: 17px 48px;
  }

  .social-btn {
    &:last-child {
      margin-bottom: 0;
    }
    padding: 15px 26px 15px 70px;
  }
`;

export const AuthModalTemplate: FC<AuthProps> = ({ title, children, className, testAnchorContainer, closeModal }) => {
  const onClose = () => {
    signInModalStore.off();
    overlayStore.hide();
  };

  return (
    <Wrapper className={className}>
      <TestAnchorContext.Provider value={{ container: testAnchorContainer }}>
        <div className='header'>
          <Headline variant='h4'>{title}</Headline>
          <Svg name='CloseLight' size={24} onClick={closeModal ? closeModal : onClose} className='close-icon' />
        </div>

        <Divider light className='divider' />

        {children}
      </TestAnchorContext.Provider>
    </Wrapper>
  );
};
