import { resultsPresentation } from 'modules/results/derivations';
import { LResult } from 'modules/results/types';
import * as React from 'react';
import styled from 'styled-components';

import { TeamParticipants } from 'src/modules/racersTable/components';
import { Utility } from 'src/styledComponents/Typography';
import { Truncate } from 'src/styles';

import { userModal } from '../../actions';

import { RowWrap } from './RowWrap';

const Wrapper = styled(RowWrap)`
  .grid-wrap {
    grid-template-columns: 80px 80px 1fr;
    gap: 8px;

    padding: 20px 10px 20px 40px;
    height: 132px;
    width: 100%;
  }
`;

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay2};
`;

const TeamName = styled(Utility)`
  ${Truncate}
`;

type Props = {
  value: LResult;
  distanceId: number;
  checkpointId: number | null;
};

export const LeftSideTeamRow: React.FC<Props> = (props) => {
  const { value, distanceId, checkpointId } = props;

  const place = resultsPresentation.generatePlace(value, distanceId!, checkpointId);

  if (!value.team) return null;

  return (
    <Wrapper place={place} distanceId={distanceId} checkpointId={checkpointId} value={value} height={132}>
      <Utility variant='u2' weight='bold'>
        {place}
      </Utility>
      <StyledUtility variant='u2' weight='medium'>
        {value.team.bib_number}
      </StyledUtility>
      <TeamName variant='u2' weight='bold' title={value.team.name}>
        {value.team.name}
      </TeamName>

      <div />
      <div />
      <TeamParticipants name={value.team.name} members={value.team.racers || []} onShowParticipant={userModal.onOpen} />
    </Wrapper>
  );
};
