import * as React from 'react';

export const More = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.2915 4.70833C9.2915 5.42663 9.57685 6.1155 10.0848 6.62341C10.5927 7.13133 11.2815 7.41667 11.9998 7.41667C12.7181 7.41667 13.407 7.13133 13.9149 6.62341C14.4228 6.1155 14.7082 5.42663 14.7082 4.70833C14.7082 3.99004 14.4228 3.30116 13.9149 2.79325C13.407 2.28534 12.7181 2 11.9998 2C11.2815 2 10.5927 2.28534 10.0848 2.79325C9.57685 3.30116 9.2915 3.99004 9.2915 4.70833ZM9.2915 12C9.2915 12.7183 9.57685 13.4072 10.0848 13.9151C10.5927 14.423 11.2815 14.7083 11.9998 14.7083C12.7181 14.7083 13.407 14.423 13.9149 13.9151C14.4228 13.4072 14.7082 12.7183 14.7082 12C14.7082 11.2817 14.4228 10.5928 13.9149 10.0849C13.407 9.57701 12.7181 9.29167 11.9998 9.29167C11.2815 9.29167 10.5927 9.57701 10.0848 10.0849C9.57685 10.5928 9.2915 11.2817 9.2915 12ZM10.0848 21.2067C9.57685 20.6988 9.2915 20.01 9.2915 19.2917C9.2915 18.5734 9.57685 17.8845 10.0848 17.3766C10.5927 16.8687 11.2815 16.5833 11.9998 16.5833C12.7181 16.5833 13.407 16.8687 13.9149 17.3766C14.4228 17.8845 14.7082 18.5734 14.7082 19.2917C14.7082 20.01 14.4228 20.6988 13.9149 21.2067C13.407 21.7147 12.7181 22 11.9998 22C11.2815 22 10.5927 21.7147 10.0848 21.2067Z'
      fill='#50555B'
    />
  </svg>
);
