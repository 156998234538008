import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const TargetPath = (
  <>
    <g filter='url(#filter0_d_4359_9844)'>
      <path
        d='M10.5 7C10.5 8.933 8.933 10.5 7 10.5C5.067 10.5 3.5 8.933 3.5 7C3.5 5.067 5.067 3.5 7 3.5C8.933 3.5 10.5 5.067 10.5 7Z'
        fill='white'
      />
      <circle cx='7' cy='7' r='2' fill='#070A0F' />
      <rect x='6.25' y='1.5' width='1.5' height='2.5' fill='white' />
      <rect x='6.25' y='10' width='1.5' height='2.5' fill='white' />
    </g>
    <defs>
      <filter id='filter0_d_4359_9844' x='-2' y='0' width='18' height='18' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='1.5' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4359_9844' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_4359_9844' result='shape' />
      </filter>
    </defs>
  </>
);

export const Target = (props: IconProps) => <SvgIcon path={TargetPath} {...props} viewBox='0 0 12 12' />;
