import { counterStore, paginationMetaStore } from '../stores';

import { currentDistance } from '../derivations';
import { getTeamDistanceRacersCount } from './getTeamDistanceRacersCount';

export const getTotalRacersCount = () => {
  const distance = currentDistance.model.get();
  const isTeam = Boolean(currentDistance.model.get()?.isTeam());

  if (!distance) {
    return counterStore.getCounter();
  }
  if (isTeam) {
    return getTeamDistanceRacersCount();
  } else {
    const distanceId = distance.value.id;
    const meta = paginationMetaStore.fetch(distanceId || 0);
    return meta?.total;
  }
};
