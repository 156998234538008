import { isEditRegistration } from 'modules/profile/selections/actionMenu/isEditRegistration';

import { name, isModalOpen, generateIsMenuOpen, isAnyMenuOpen } from './display';
import { distanceNotDeleted } from './distanceNotDelete';
import { isBibExport } from './isBibExport';
import { isDiplomaExport } from './isDiplomaExport';
import { generateIsMenuEnabled } from './isMenuEnable';
import { isReceipt } from './isReceipt';
import { isRegistrationTransfer } from './isRegistrationTransfer';
import { isTransferRegistration } from './isTransferRegistration';

export const actionMenu = {
  name,
  isModalOpen,
  generateIsMenuOpen,
  isAnyMenuOpen,
  isBibExport,
  isDiplomaExport,
  isReceipt,
  generateIsMenuEnabled,
  distanceNotDeleted,
  isTransferRegistration,
  isRegistrationTransfer,
  isEditRegistration,
};
