import { Button, Display, Text } from 'modules/miles/shared/components';
import adjustedPrice from 'modules/miles/shared/utils/price-currency';
import React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Icon } from 'src/styledComponents/Icons';

import { sessionStore } from 'stores';

import { t } from '../../../../utils';

type IconElementProps = {
  iconName: string;
  iconSize: number;
  children: React.ReactNode;
};

const Wrapper = styled.div`
  padding: 48px 16px 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.miles.colors.brand.grad.c900C70045};
`;

const IconsBlock = styled.div`
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 32px;
  p {
    margin-left: 8px;
  }
  .icons-left {
    display: flex;
    flex-direction: column;
  }
  .icons-right {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    flex-direction: column;
    align-items: start;
    margin: 24px 0 16px;
  }
`;

const StyledIconElement = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px 4px 0;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.theme.miles.colors.brand.alphas.light800};
  margin: 8px;
`;

const BottomBlock = styled.div`
  max-width: 560px;
  width: 100%;
  .divider-block {
    display: flex;
    margin: 16px 0;
    p {
      color: ${(props) => props.theme.miles.colors.light.alpha[800]};
    }
  }
`;

const IconElement: React.FC<IconElementProps> = (props) => {
  const { iconName, iconSize, children } = props;
  return (
    <StyledIconElement>
      <Icon name={iconName} size={iconSize} />
      <Text size='md' weight='regular' style={{ fontWeight: 200 }}>
        {children}
      </Text>
    </StyledIconElement>
  );
};

export const FreeTrial = () => {
  if (sessionStore.userModel?.hasMilesCoupon) return null;
  return (
    <Wrapper id='Pricing'>
      <Display style={{ marginBottom: '4px', textAlign: 'center' }} size='md' weight='bold'>
        {t('miles.landing.main-cta.title')}
      </Display>
      <Text size='md' weight='regular'>
        {t('miles.landing.main-cta.subtitle')}
      </Text>
      <IconsBlock>
        <div className='icons-left'>
          <IconElement iconName='Running' iconSize={16}>
            {t('miles.landing.main-cta.optimal-plan')}
          </IconElement>
          <IconElement iconName='Checkin-Daily' iconSize={16}>
            {t('miles.landing.main-cta.plan-changes')}
          </IconElement>
        </div>
        <div className='icons-right'>
          <IconElement iconName='Graph-Increase-Analytics' iconSize={16}>
            {t('miles.landing.main-cta.heart-rate-pace')}
          </IconElement>
          <IconElement iconName='Time-Clock' iconSize={16}>
            {t('miles.landing.main-cta.predicted-time')}
          </IconElement>
        </div>
      </IconsBlock>

      <BottomBlock>
        <Button message='main' size='l' mode='dark' fullWidth>
          {t('miles.landing.main-cta.subtitle', { amount: adjustedPrice() })}
        </Button>
        <div className='divider-block'>
          <Divider />
          <Text size='md' weight='bold'>
            {t('login.or')}
          </Text>
          <Divider />
        </div>
        <Button message='subtle' size='l' mode='dark' fullWidth>
          {t('miles.buttons.cta.start-trial', { number: 2 })}
        </Button>
      </BottomBlock>
    </Wrapper>
  );
};
