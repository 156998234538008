import { pickBy, isNil, isEmpty } from 'lodash';
import { Data } from 'modules/registration/types/data';

import { AdditionalRegistrationFields, RegistrationFields } from '../../constants';

/**
 * @description
 * Filter UserType or just object which is an object of {[registrationFields]: any}
 * filter null and undefined values
 */
function filterBERacerEmptyData(data: Data.Backend.Racer | Data.Backend.RacerToReceive): Data.Backend.Racer {
  return pickBy(data, (val, name) => {
    switch (name) {
      case RegistrationFields.gender:
      case RegistrationFields.country_id:
      case RegistrationFields.nationality_id:
      case RegistrationFields.squad_id:
      case AdditionalRegistrationFields.wave_id:
      case AdditionalRegistrationFields.discipline_id:
      case AdditionalRegistrationFields.class_id:
        return !isNil(val);
      default:
        return !isEmpty(val);
    }
  });
}

export { filterBERacerEmptyData };
