import { Observer } from 'mobx-react';
import * as React from 'react';

import { InputWithWrapper } from 'src/styledComponents/InputTools';

import { InputWrapper } from 'components/form';

import { t } from 'utils';

import { isGoalAltitude } from '../../derrivations';

function DistanceLogged() {
  return (
    <Observer>
      {() => {
        const title = isGoalAltitude.get() ? 'profile.resultForm.distance_logged_altitude' : 'profile.resultForm.distance_logged';

        return (
          <div className='field-wrapper'>
            <InputWrapper
              name='distance_logged'
              settings={{
                placeholder: '0',
                label: t.staticAsString(title),
                requiredMark: true,
                onlyDigits: true,
              }}
              Component={InputWithWrapper}
            />
          </div>
        );
      }}
    </Observer>
  );
}

export { DistanceLogged };
