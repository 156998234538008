import { reaction } from 'mobx';

import { footerVisibility } from 'utils';

import { progressStore } from 'stores';

import { PRELOADER } from '../constants';

import { disposers } from '../stores';

import { pagination } from '../derivations';

/* NOTE
 * Hide footer until all data is showed
 * Show footer only when all of data is displayed
 * and infininte scroll logic is disabled
 */
function observeHasMore() {
  const dispose = reaction(
    () => pagination.hasMore.get(),

    (isHasMore) => {
      if (isHasMore) {
        return footerVisibility.hide();
      }

      progressStore.log(PRELOADER, 'completed');
      footerVisibility.show();
    },

    { fireImmediately: true },
  );

  disposers.register(dispose);
}

export { observeHasMore };
