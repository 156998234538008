import classNames from 'classnames';
import { observer } from 'mobx-react';
import { AddResult, EditCell, PlaceCell, SegmentsLinkCell } from 'modules/profile/components';
import { calcProgress, getLength } from 'modules/profile/selections';
import moment from 'moment';
import * as React from 'react';

import { RESULT_DATE_FORMATE } from 'src/constants';
import { Link } from 'src/styledComponents/Link';

import { Table } from 'components/table';

import { t } from 'utils';

import { Distance, Goal } from 'models';

import { cannotLogError } from '../../utils';

import { getDistanceUrl, onAddResult, onNavigateToSegments, openEditResult } from '../../actions';

import { virtualStore } from '../../stores';

import { VirtualDistanceMapped } from '../../types';

type Props = {};

@observer
export class TableView extends React.Component<Props> {
  getRacerResult = (distance: VirtualDistanceMapped = {} as any): AnyObject => {
    return (distance.racers || [])[0]?.result || {};
  };

  getFormattedDate = (date?: string): string => {
    if (!date) {
      return '';
    }

    return moment(date, RESULT_DATE_FORMATE).format('MM/DD/YYYY');
  };

  renderRows = (): Array<React.ReactNode> | React.ReactNode => {
    return virtualStore.list.map((distance: VirtualDistanceMapped) => {
      const distanceModel = new Distance(distance as any);
      const goalModel = new Goal(distance.goal || ({} as any));

      const length = getLength(distance);
      const place = distance.resultData.place || '- -';

      const disableDistanceTitle = !distance.show_results || !distance.race.show_results;
      const isLogResultsDisabled = distanceModel.isLoggingClosed();

      return (
        <Table.Row key={distance.id}>
          <Table.Cell className='place' value={<PlaceCell value={place} hex={distance?.race?.sport?.hex_color} />} />
          <Table.Cell
            className='name'
            value={
              <Link
                className={classNames('distance-title truncate', { disabled: disableDistanceTitle })}
                to={getDistanceUrl(distance.id, distance.race.id, distance.type)}
              >
                {t(() => distanceModel.value.name)}
              </Link>
            }
          />
          <Table.Cell className='length' value={this.getFormattedDate(distance.race_date)} />
          <Table.Cell className='length' value={length} />
          <Table.Cell className='time' value={distance.resultData.time_result} />
          <Table.Cell
            className='source'
            value={
              <React.Fragment>
                <AddResult
                  disabled={isLogResultsDisabled}
                  disabledReason={cannotLogError(distanceModel)}
                  editMode={distance.editMode}
                  isAltitude={goalModel.isGoalType('altitude')}
                  isLength={goalModel.isGoalType('distance')}
                  percents={calcProgress(distance.resultData.distance_logged, distance.goal?.goal_max_length)}
                  link={distance.resultData.activity_link}
                  onClick={() => {}}
                  // onClick={onAddResult(distance)}
                />
                <EditCell
                  display={distance.editMode && distanceModel.isMode('virtual')}
                  hide={distanceModel.isLoggingClosed()}
                  onClick={openEditResult(distance.racers[0].id)}
                />
                <SegmentsLinkCell
                  display={distance.editMode && distanceModel.isMode('cumulative')}
                  hide={false}
                  onClick={onNavigateToSegments(distance)}
                />
              </React.Fragment>
            }
          />
        </Table.Row>
      );
    });
  };

  render() {
    return (
      <Table.Wrapper className='results-table'>
        <Table.Head>
          <Table.Cell className='place' value='profile.registeredTable.head.place' />
          <Table.Cell className='name' value='profile.registeredTable.head.name' />
          <Table.Cell className='length' value='profile.registeredTable.head.date' />
          <Table.Cell className='length' value='profile.registeredTable.head.altitudeOrLength' />
          <Table.Cell className='time' value='profile.registeredTable.head.time' />
          <Table.Cell className='source' value='profile.registeredTable.head.source' />
        </Table.Head>
        <Table.Body>{this.renderRows()}</Table.Body>
      </Table.Wrapper>
    );
  }
}
