import { debounce } from 'lodash';
import { observable, action, makeObservable } from 'mobx';

import { RESOLUTIONS } from 'src/constants';

type Value = {
  width: number;
  height: number;
};

class WindowSize {
  widthOfDeviceScreen = window.screen.width;

  @observable
  value: Value = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  // Not observable
  plainValue: Value = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  debouncedCalcNewValue = debounce(this.calcNewValue, 300);

  constructor() {
    makeObservable(this);
  }

  isLessThan(value: resolutions) {
    // checks if device screen width is less then visual screen width to assign a right one for Instagram's internal browser correct CSS deploy
    const currentWindowWidth = this.widthOfDeviceScreen < this.value.width ? this.widthOfDeviceScreen : this.value.width;
    return this._isLess(value, currentWindowWidth);
  }

  isGreaterThan(value: resolutions) {
    // checks if device screen width is less then visual screen width to assign a right one for Instagram's internal browser correct CSS deploy
    const currentWindowWidth = this.widthOfDeviceScreen < this.value.width ? this.widthOfDeviceScreen : this.value.width;
    return this._isGreater(value, currentWindowWidth);
  }

  notObservableIsLessThan(value: resolutions) {
    const currentWindowWidth = this.plainValue.width;
    return this._isLess(value, currentWindowWidth);
  }

  notObservableIsGreaterThan(value: resolutions) {
    const currentWindowWidth = this.plainValue.width;
    return this._isGreater(value, currentWindowWidth);
  }

  // -------

  _isGreater(value: resolutions, currentWindowWidth: number) {
    const min = RESOLUTIONS[value];
    return currentWindowWidth >= min;
  }

  _isLess(value: resolutions, currentWindowWidth: number) {
    const max = RESOLUTIONS[value];
    return currentWindowWidth <= max;
  }

  // -------

  @action
  applyNewValue(width: number, height: number) {
    this.value = { ...this.value, width, height };
    this.plainValue = { ...this.plainValue, width, height }; // Not observable
  }

  @action
  calcNewValue() {
    this.applyNewValue(window.innerWidth, window.innerHeight);
  }

  listenWindowResize() {
    window.addEventListener('resize', (event) => {
      this.debouncedCalcNewValue();
    });
  }
}

const windowSize = new WindowSize();
windowSize.listenWindowResize();

export { WindowSize, windowSize };
