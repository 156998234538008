import * as React from 'react';

import { formatDate } from '../utils';

type Props = {
  date: string;
  hex?: string;
};

export class DateCell extends React.PureComponent<Props> {
  render() {
    const { hex, date } = this.props;

    const borderLeftColor = hex && `#${hex}`;
    const [day, monthYear] = formatDate(date);

    return (
      <div
        className='date-cell'
        style={{
          borderLeftColor: borderLeftColor ? borderLeftColor : 'inherit',
        }}
      >
        <div className='day'>{day}</div>
        <div className='month'>{monthYear}</div>
      </div>
    );
  }
}
