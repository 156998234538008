import { Observer } from 'mobx-react';
import { generateIsMenuOpen } from 'modules/profile/selections/actionMenu/display';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { DEFAULT_RACE_IMAGE, ROUTER } from 'src/constants';
import { Svg } from 'src/styledComponents/Icons/Svg';
import { Utility } from 'src/styledComponents/Typography';

import { Hide, Show } from 'components/condition';

import { encodeQuery, history } from 'utils';

import { windowSize } from 'stores';

import { Skeleton } from '../Skeleton';
import { Body, Content, ImageBox, InlineFlex, SportTag, StyledCard } from './Components';
import { Popup } from './Popup';
import {
  Wrapper,
  DateUtility,
  Header,
  More,
  RaceDescription,
  ResultsRow,
  StyledDivider,
  SportType,
  ResultProgress,
  TitleUtility,
  RaceResultsLink,
  TruncatedUtility,
  MoreButtonDisabler,
  DistanceProgress,
} from './styled';

type Item = {
  title: string;
  icon: ReactNode;
  onClick: AnyFunction;
};

export type RaceProfileCardProps = {
  bodyTitle: string;
  className?: string;
  distanceId: number;
  distanceLength: string;
  distanceType?: 'virtual' | 'cumulative';
  distanceName: string;
  distanceProgress?: string;
  extraCotent?: ReactNode;
  image: string | nil;
  location?: string;
  onClickMore: AnyFunction;
  percents?: string;
  place?: number | string;
  published?: boolean;
  raceResultsLink?: string;
  settings: Item[];
  sportIcon: string;
  sportType: string;
  teamName: string;
  time?: string;
  total?: number;
  withResults?: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
};

export const RaceProfileCard: FC<RaceProfileCardProps> = ({
  bodyTitle,
  className,
  distanceId,
  distanceLength,
  distanceType,
  distanceName,
  distanceProgress,
  extraCotent,
  image,
  location,
  onClickMore,
  percents,
  place,
  published,
  raceResultsLink,
  settings,
  sportIcon,
  sportType,
  teamName,
  time,
  total,
  withResults,
  startDate,
  endDate,
}): ReactNode | any => {
  const isMobile = !windowSize.isGreaterThan('medium');
  const isOpenActions = generateIsMenuOpen(distanceId);
  const raceResultsLinkString = String(raceResultsLink);

  const getDate = () => {
    if (startDate && endDate) {
      return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    }

    return startDate ? startDate : '';
  };

  const redirectToResultPage = () => {
    history.push(raceResultsLinkString);
  };

  return (
    <Observer>
      {() => {
        const isOpen = isOpenActions.get();
        return (
          <Wrapper className={className}>
            <StyledCard>
              <Header>
                <div>
                  <Svg name='DateCalendar' size={16} />
                  <DateUtility variant='u4' weight='bold'>
                    {getDate()}
                  </DateUtility>
                </div>
                <div>
                  <Show if={Boolean(percents)}>
                    <ResultProgress>
                      <Utility variant='u4' weight='bold'>
                        {percents}
                      </Utility>{' '}
                    </ResultProgress>
                  </Show>

                  <More name='More' size={24} isOpen={isOpen} onClick={onClickMore} />
                </div>
              </Header>

              <Body>
                <Show if={isOpen && !isMobile}>
                  <MoreButtonDisabler />
                  <Popup items={settings} handleClose={onClickMore} />
                </Show>

                <ImageBox bg={image || DEFAULT_RACE_IMAGE} />

                <SportTag>
                  <img src={sportIcon} alt='' />
                  <SportType>{sportType}</SportType>
                </SportTag>
              </Body>
            </StyledCard>

            <Content>
              <Show if={!!withResults}>
                <ResultsRow distanceType={distanceType} onClick={redirectToResultPage}>
                  <div className='place'>
                    <Svg name='Place' size={24} />
                    <Utility variant={isMobile ? 'u1' : 'u2'} weight='bold'>
                      {`${place}/${total}`}
                    </Utility>
                  </div>

                  <Hide if={distanceType === 'cumulative'}>
                    <div>
                      <Svg name='Distance' size={24} />
                      <Utility variant={isMobile ? 'u1' : 'u2'} weight='bold'>
                        {distanceLength}
                      </Utility>
                    </div>
                  </Hide>

                  <div className='time'>
                    <Svg name='Time' size={24} />
                    <Utility variant={isMobile ? 'u1' : 'u2'} weight='bold'>
                      {time}
                    </Utility>
                  </div>
                </ResultsRow>

                <Hide if={distanceType === 'cumulative'}>
                  <StyledDivider />
                </Hide>

                <Show if={distanceType === 'cumulative'}>
                  <DistanceProgress>
                    <Utility variant={isMobile ? 'u2' : 'u3'} weight='bold'>
                      {distanceProgress}
                    </Utility>
                  </DistanceProgress>
                </Show>
              </Show>

              <TitleUtility variant={isMobile ? 'u1' : 'u2'} weight='bold' title={bodyTitle}>
                <Show if={Boolean(raceResultsLink)}>
                  <RaceResultsLink to={raceResultsLinkString} condition={Boolean(published)} ATN={bodyTitle}>
                    {bodyTitle}
                  </RaceResultsLink>
                </Show>
                <Hide if={Boolean(raceResultsLink)}>{bodyTitle}</Hide>
              </TitleUtility>

              <RaceDescription>
                <InlineFlex>
                  <Svg name='Distance' />
                  <TruncatedUtility variant={isMobile ? 'u1' : 'u2'} weight='medium' title={distanceName}>
                    {`${distanceName}`}
                  </TruncatedUtility>
                </InlineFlex>

                <Show if={Boolean(location)}>
                  <InlineFlex>
                    <Svg name='Location' />
                    <TruncatedUtility variant={isMobile ? 'u2' : 'u3'} weight='medium' title={location}>
                      {location}
                    </TruncatedUtility>
                  </InlineFlex>
                </Show>
              </RaceDescription>
            </Content>

            <Show if={!!extraCotent}>{extraCotent}</Show>
          </Wrapper>
        );
      }}
    </Observer>
  );
};

const StyledUtility = styled(Utility)`
  width: 100%;
`;

type RaceProfileCardSkeletonType = {
  withResults?: boolean;
};
export const RaceProfileCardSkeleton = ({ withResults = false }: RaceProfileCardSkeletonType) => {
  const isMobile = windowSize.isLessThan('medium');
  const height = isMobile ? ((window.innerWidth - 44) / 16) * 9 + 48 : 213;
  return (
    <Wrapper>
      <Skeleton variant={'rectangular'} height={height} />
      <Content>
        <Show if={withResults}>
          <ResultsRow style={{ paddingTop: '42px' }}>
            <div>
              <Utility variant='u2' weight='bold'>
                <Skeleton />
              </Utility>
            </div>

            <div>
              <Utility variant='u2' weight='bold'>
                <Skeleton />
              </Utility>
            </div>

            <div>
              <Utility variant='u2' weight='bold'>
                <Skeleton />
              </Utility>
            </div>
          </ResultsRow>
        </Show>

        <Utility variant='u2' weight='bold'>
          <Skeleton height={24} />
        </Utility>

        <InlineFlex>
          <StyledUtility variant='u2' weight='medium'>
            <Skeleton />
          </StyledUtility>
        </InlineFlex>

        <InlineFlex>
          <StyledUtility variant='u2' weight='medium'>
            <Skeleton width={'50%'} />
          </StyledUtility>
        </InlineFlex>
      </Content>
    </Wrapper>
  );
};
