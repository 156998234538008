import { observer, inject } from 'mobx-react';
import React, { lazy } from 'react';
import { Switch, withRouter, Route, matchPath, Redirect } from 'react-router-dom';

import { ROUTER_WITH_LOCALES, PRIVATE_ROUTES, ROUTER } from 'src/constants';
import { Session as SessionStore } from 'src/stores';
import { sessionStore, localeStore } from 'src/stores';
import { RoutesWrapper } from 'src/styledComponents/app';
import { history, privateRouteUtil, iFrameDetect, ExtendedRouterHistory } from 'src/utils';
import NestedRouteBehavior from 'src/utils/nestedRouteBehavior';

import { Spinner } from 'components/spinner';

import { generateAuthRoutes, generateAuthRoutesRedirects } from './Auth';
import { generateMilesRoutes } from './Miles';
import { generatePrivateRoutes } from './Private';

const AuthContainer = lazy(() =>
  import(
    /* webpackChunkName: "AuthContainer" */
    'modules/auth'
  ).then((m) => ({ default: m.AuthContainer })),
);
const RaceContainer = lazy(() =>
  import(
    /* webpackChunkName: "RaceContainer" */
    'modules/races'
  ).then((m) => ({ default: m.Races })),
);
const RaceDetailsContainer = lazy(() =>
  import(
    /* webpackChunkName: "RaceDetailsContainer" */
    'modules/race'
  ).then((m) => ({ default: m.RaceDetailsContainer })),
);
const FAQContainer = lazy(() =>
  import(
    /* webpackChunkName: "FAQContainer" */
    'modules/faq'
  ).then((m) => ({ default: m.FAQContainer })),
);
const PrivacyPolicyContainer = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyContainer" */
    'modules/privacy'
  ).then((m) => ({ default: m.PrivacyPolicyContainer })),
);
const HomeContainer = lazy(() =>
  import(
    /* webpackChunkName: "HomeContainer" */
    'modules/home'
  ).then((m) => ({ default: m.Home })),
);
const ArticleContainer = lazy(() =>
  import(
    /* webpackChunkName: "ArticleContainer" */
    'modules/articles'
  ).then((m) => ({ default: m.ArticleContainer })),
);
const ArticleDetailsContainer = lazy(() =>
  import(
    /* webpackChunkName: "ArticleDetailsContainer" */
    'modules/articles'
  ).then((m) => ({ default: m.ArticleDetailsContainer })),
);
const VirtualRacesBase = lazy(() =>
  import(
    /* webpackChunkName: "VirtualRacesBase" */
    'modules/virtualRaces'
  ).then((m) => ({ default: m.VirtualRacesBase })),
);
const RegistrationContainer = lazy(() =>
  import(
    /* webpackChunkName: "RegistrationContainer" */
    'modules/registration'
  ).then((m) => ({ default: m.RegistrationLasciateOgniSperanzaVoiChentrate })),
);
const AfterPaymentContainer = lazy(() =>
  import(
    /* webpackChunkName: "AfterPaymentContainer" */
    'modules/payment'
  ).then((m) => ({ default: m.AfterPaymentContainer })),
);
const Organizer = lazy(() =>
  import(
    /* webpackChunkName: "Organizer" */
    'modules/organizer/index'
  ).then((m) => ({ default: m.Organizer })),
);
const ContactContainer = lazy(() =>
  import(
    /* webpackChunkName: "ContactContainer" */
    'modules/contact'
  ).then((m) => ({ default: m.ContactContainer })),
);
const AboutContainer = lazy(() =>
  import(
    /* webpackChunkName: "AboutContainer" */
    'modules/about'
  ).then((m) => ({ default: m.AboutContainer })),
);
const TermsContainer = lazy(() =>
  import(
    /* webpackChunkName: "TermsContainer" */
    'modules/terms'
  ).then((m) => ({ default: m.TermsContainer })),
);
const NotFoundPage = lazy(() =>
  import(
    /* webpackChunkName: "NotFoundPage" */
    'modules/errorPage'
  ).then((m) => ({ default: m.NotFoundPage })),
);
const ErrorPage = lazy(() =>
  import(
    /* webpackChunkName: "ErrorPage" */
    'modules/errorPage'
  ).then((m) => ({ default: m.ErrorPage })),
);
const IframeMode = lazy(() =>
  import(
    /* webpackChunkName: "IframeMode" */
    'modules/iframeMode'
  ).then((m) => ({ default: m.IframeMode })),
);
const ConfirmProfile = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmProfile" */
    'modules/confirmProfile'
  ).then((m) => ({ default: m.ConfirmProfile })),
);
const TourProfileManagment = lazy(() =>
  import(
    /* webpackChunkName: "TourProfileManagment" */
    'modules/tourProfileManagment'
  ).then((m) => ({ default: m.TourProfileManagment })),
);
const unavailableServiceContainer = lazy(() =>
  import(
    /* webpackChunkName: "unavailableServiceContainer" */
    'modules/unavailableService'
  ).then((m) => ({ default: m.unavailableServiceContainer })),
);
const RaceSalesPages = lazy(() =>
  import(
    /* webpackChunkName: "RaceSalesPages" */
    'modules/raceSalesPages'
  ).then((m) => ({ default: m.RaceSalesPages })),
);
const PublicPages = lazy(() =>
  import(
    /* webpackChunkName: "unavailableServiceContainer" */
    'modules/publicPages/base'
  ).then((m) => ({ default: m.PublicPages })),
);
const TransferRegistrationConfirm = lazy(() =>
  import(
    /* webpackChunkName: "TransferRegistrationConfirm" */
    'modules/transferRegistrationConfirm'
  ).then((m) => ({ default: m.TransferRegistrationConfirm })),
);

const CampaignsLanding = lazy(() =>
  import(
    /* webpackChunkName: "CampaignsLanding" */
    'modules/campaigns'
  ).then((m) => ({ default: m.CampaignsLanding })),
);

const GiftcardForm = lazy(() =>
  import(
    /* webpackChunkName: "GiftcardForm" */
    'modules/giftcards-form'
  ).then((m) => ({ default: m.GiftcardForm })),
);

type Props = {
  sessionStore: SessionStore;
  location: AnyObject;
  history?: ExtendedRouterHistory;
};

type State = {
  isAuth: boolean;
};

@withRouter
@inject('sessionStore')
@observer
export class Routes extends React.Component<Props, State> {
  static defaultProps = {
    intl: null as any,
    location: null as any,
    sessionStore: sessionStore,
    history: null as any,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isAuth: props.sessionStore.isAuth,
    };
  }

  componentDidMount() {
    if (!this.props.history) {
      return;
    }

    new NestedRouteBehavior(this.props.history);
  }

  static getDerivedStateFromProps(props: Props) {
    const { sessionStore } = props;

    privateRouteUtil.checkIfPrivate();

    return {
      isAuth: sessionStore.isAuth,
    };
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const { location } = this.props;

    if (!this.state.isAuth && prevState.isAuth) {
      const isPrivate = !!PRIVATE_ROUTES.find((route) => matchPath(location?.pathname as string, route));

      if (isPrivate) {
        history.push(ROUTER.HOME);
      }
    }
  }

  getPrivateRoutes = () => {
    if (this.props.sessionStore.isAuth) {
      return generatePrivateRoutes();
    }

    return [];
  };

  getAuthRoutes = () => {
    if (this.props.sessionStore.isAuth) {
      return generateAuthRoutesRedirects();
    }

    return generateAuthRoutes();
  };

  genMilesRoutes = () => {
    return generateMilesRoutes();
  };

  getRoutes = () => {
    const privateRoutes = this.getPrivateRoutes() || [];

    if (iFrameDetect().isWithinIFrame) {
      return [
        <Route path={`${ROUTER_WITH_LOCALES.IFRAME_ROOT}*`} component={IframeMode} key='route-iframe-root' />,
        <Route path={ROUTER_WITH_LOCALES.AFTER_PAYMENT} component={AfterPaymentContainer} key='route-after-payment' />,
        <Route path={ROUTER_WITH_LOCALES.AFTER_PAYMENT_ALT} component={AfterPaymentContainer} key='route-after-payment-alt' />,
      ];
    }

    return [
      <Route exact path={ROUTER_WITH_LOCALES.HOME} component={HomeContainer} key='route-home' />,
      <Route exact path={ROUTER_WITH_LOCALES.CAMPAIGNS_LANDING} component={CampaignsLanding} key='route-campaigns' />,
      <Route exact path={ROUTER_WITH_LOCALES.GIFT_CARDS} component={GiftcardForm} key='route-gift-cards' />,
      <Redirect exact from={ROUTER_WITH_LOCALES.RACE} to={ROUTER_WITH_LOCALES.RACE_ABOUT_US} key='route-about-with-locale' />,

      ...this.getAuthRoutes(),

      <Route path={ROUTER_WITH_LOCALES.RESET} component={AuthContainer} key='reset' />,

      <Route path={ROUTER_WITH_LOCALES.PUBLIC} component={PublicPages} key='route-public-pages' />,
      <Route path={ROUTER_WITH_LOCALES.ABOUT} component={AboutContainer} key='route-about' />,
      <Route path={ROUTER_WITH_LOCALES.TERMS} component={TermsContainer} key='route-terms' />,
      <Route path={ROUTER_WITH_LOCALES.CONTACT} component={ContactContainer} key='route-contact' />,
      <Route path={ROUTER_WITH_LOCALES.FAQ} component={FAQContainer} key='route-faq' />,
      <Route path={ROUTER_WITH_LOCALES.PRIVACY_POLICY} component={PrivacyPolicyContainer} key='route-private-policy' />,
      <Route path={ROUTER_WITH_LOCALES.ORGANIZER} component={Organizer} key='route-organazier' />,
      <Redirect exact from={ROUTER_WITH_LOCALES.CONTACT_US} to={ROUTER.ABOUT} key='route-contact-us' />,

      <Route path={ROUTER_WITH_LOCALES.RACE_REGISTRATION} component={RegistrationContainer} key='route-race-rgistration' />,

      <Route path={ROUTER_WITH_LOCALES.RACE} component={RaceDetailsContainer} key='route-race' />,
      <Route path={ROUTER_WITH_LOCALES.RACES} component={RaceContainer} key='route-races' />,

      <Route path={ROUTER_WITH_LOCALES.ARTICLE} component={ArticleDetailsContainer} key='route-article' />,
      <Route path={ROUTER_WITH_LOCALES.ARTICLES} component={ArticleContainer} key='route-articles' />,

      // <Route path={ROUTER_WITH_LOCALES.VIRTUAL_RACES} component={VirtualRacesBase} key='route-virtual-races' />,
      <Route path={ROUTER_WITH_LOCALES.AFTER_PAYMENT} component={AfterPaymentContainer} key='route-after-payment' />,
      <Route path={ROUTER_WITH_LOCALES.AFTER_PAYMENT_ALT} component={AfterPaymentContainer} key='route-after-payment-alt' />,

      <Route path={ROUTER_WITH_LOCALES.ERROR_PAGE} component={ErrorPage} key='route-error-page' />,
      <Route path={ROUTER_WITH_LOCALES.NOT_FOUND_PAGE} component={NotFoundPage} key='route-not-found-page' />,
      <Route path={ROUTER_WITH_LOCALES.SERVICE_UNAVAILABLE} component={unavailableServiceContainer} key='route-service-unv' />,

      <Route path={ROUTER_WITH_LOCALES.CONFIRM_PROFILE} component={ConfirmProfile} key='route-confirm-profile' />,

      <Route path={ROUTER_WITH_LOCALES.PROFILE_MANAGEMENT_XTRI} component={TourProfileManagment} key='route-profile-m-xtri' />,
      <Route path={ROUTER_WITH_LOCALES.PROFILE_MANAGEMENT_NORSEMAN} component={TourProfileManagment} key='route-profile-m-norseman' />,
      <Route
        path={ROUTER_WITH_LOCALES.PROFILE_MANAGEMENT_TOUGH_VIKING}
        component={TourProfileManagment}
        key='route-profile-m-tough-viking'
      />,

      <Route
        path={ROUTER_WITH_LOCALES.TRANSFER_REGISTRATION_CONFIRM}
        component={TransferRegistrationConfirm}
        key='route-transfer-registration-confirm'
      />,

      ...this.genMilesRoutes(),

      ...privateRoutes,

      /*// TODO, remove. I hope*/
      <Route path={ROUTER_WITH_LOCALES.RACE_SALES_PAGES_ROOT} component={RaceSalesPages} key='route-race-sales-page-root' />,

      <Route component={NotFoundPage} key='route-not-found' />,
    ];
  };

  redirectHandler = (props: AnyObject) => {
    const path = `${props.location.pathname}${props.location.search}`;
    return <Redirect to={`/en${path}`} />;
  };

  render(): React.ReactNode {
    const pattern = localeStore.getLocalesPattern;
    let routes = this.getRoutes();

    return (
      <RoutesWrapper>
        <React.Suspense
          fallback={
            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
              <Spinner type='cubesSpinner' />
            </div>
          }
        >
          <Switch>
            <Route path={pattern}>
              <Switch>{routes}</Switch>
            </Route>
            <Route render={this.redirectHandler} />
          </Switch>
        </React.Suspense>
      </RoutesWrapper>
    );
  }
}
