import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  weight: keyof typeof WeightText;
  size: keyof typeof SizesText;
  fontStyle?: 'italic' | 'normal';
  className?: string;
  children?: React.ReactNode | JSX.Element;
  style?: React.CSSProperties;
  dangerouslySetInnerHTML?: any;
};

export enum WeightText {
  light = 'light',
  regular = 'regular',
  medium = 'medium',
  bold = 'bold',
  heavy = 'heavy',
}

export enum SizesText {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
}

const sizeXl = css`
  font-size: 20px;
  line-height: 22px;
`;

const sizeLg = css`
  font-size: 18px;
  line-height: 20px;
`;

const sizeMd = css`
  font-size: 16px;
  line-height: 18px;
`;

const sizeSm = css`
  font-size: 14px;
  line-height: 16px;
`;

const sizeXs = css`
  font-size: 12px;
  line-height: 14px;
`;

const getSizeProperties = (size: keyof typeof SizesText) => {
  switch (size) {
    case SizesText.xl:
      return sizeXl;
    case SizesText.lg:
      return sizeLg;
    case SizesText.md:
      return sizeMd;
    case SizesText.sm:
      return sizeSm;
    case SizesText.xs:
      return sizeXs;
    default:
      return sizeMd;
  }
};

const getFontWeight = (weight: keyof typeof WeightText) => {
  switch (weight) {
    case WeightText.light:
      return 300;
    case WeightText.regular:
      return 400;
    case WeightText.medium:
      return 500;
    case WeightText.bold:
      return 700;
    case WeightText.heavy:
      return 900;
    default:
      return 500;
  }
};

const TextParagraph = styled.p<Props>`
  font-family: Aeonik, sans-serif;
  margin: 0;
  font-style: ${(props) => (props.fontStyle === 'italic' ? 'italic' : 'normal')};
  font-weight: ${(props) => getFontWeight(props.weight)};
  ${(props) => getSizeProperties(props.size)}
`;

export const Text: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return <TextParagraph {...rest}>{children}</TextParagraph>;
};
