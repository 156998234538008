import { AnyStore, DisposersStore } from 'src/stores';

import { PlaceType } from '../types';

export const sportsOptionsStore = new AnyStore<DropdownItemType[]>([]);
export const locationStore = new AnyStore<PlaceType[]>([]);
export const racesStore = new AnyStore<AnyObject[]>([]);

export const focusedStore = new AnyStore<string>('');
export const locationAutocompleteOpenStore = new AnyStore<boolean>(false);
export const racesAutocompleteOpenStore = new AnyStore<boolean>(false);
export const mobileFiltersDropdownStore = new AnyStore<string | null>(null);
export const mobileModalStore = new AnyStore<boolean>(false);

export const disposersStore = new DisposersStore();
export const metaStore = new AnyStore<{ total_pages: number; total: number } | null>(null);

export const recentRacesStore = new AnyStore<string[]>([]);
export const recentLocationStore = new AnyStore<PlaceType[]>([]);
export const recomendedRacesStore = new AnyStore<AnyObject[]>([]);
