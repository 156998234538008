import * as React from 'react';

import { Spinner } from 'components/spinner';

function Loading() {
  return (
    <div className='user-info-loader'>
      <Spinner type={Spinner.types.default} />
    </div>
  );
}

export { Loading };
