import { Observer } from 'mobx-react';
import { isAnyDistanceShown } from 'modules/startlist/utils';
import * as React from 'react';
import styled from 'styled-components';

import { NoResults as NoResComponent } from 'src/modules/profile/components/NoResults';

const StyledNoResults = styled(NoResComponent)`
  display: flex;
  justify-content: center;

  margin: 56px 0;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

export const NoResults: React.FC<Props> = (props) => {
  return (
    <Observer>
      {() => {
        const isShown = isAnyDistanceShown();
        if (isShown) return <>{props.children}</>;
        return <StyledNoResults message='race.noStartList' />;
      }}
    </Observer>
  );
};
