import axios from 'axios';
import { UserType } from 'typings/old-types/UserType';

import { USER, API_URL, API_USER_PROFILE_URL, API_USER_AVATAR_URL } from 'src/constants';

import { request, action } from 'utils';

import { ACCOUNT_INFO_FORM_ID, ENTRY_QUERY_PARAMS, MORE_INFO_FORM_ID } from '../constants';

class Profile {
  @request({ action: USER })
  async loadUserProfileRequest(): Promise<any> {
    return axios.get(`${API_URL}${API_USER_PROFILE_URL}`, {
      params: {
        with: ENTRY_QUERY_PARAMS,
      },
    });
  }

  @action({ action: USER })
  async loadUserProfile(): Promise<any> {
    const [status, response] = await this.loadUserProfileRequest();
    return [status, response.data];
  }

  @request({ action: MORE_INFO_FORM_ID })
  async submitProfileRequest(data: UserType): Promise<Object> {
    return axios.patch(`${API_URL}${API_USER_PROFILE_URL}`, data, {
      params: {
        with: ENTRY_QUERY_PARAMS,
      },
    });
  }

  @action({ action: MORE_INFO_FORM_ID })
  async submitProfile(data: UserType): Promise<[boolean, AnyObject]> {
    const [status, response]: any = await this.submitProfileRequest(data);
    return [status, response.data];
  }

  @request({ action: ACCOUNT_INFO_FORM_ID })
  async deleteProfileRequest(): Promise<any> {
    return axios.delete(`${API_URL}${API_USER_PROFILE_URL}`);
  }

  @action({ action: ACCOUNT_INFO_FORM_ID })
  async deleteProfile(): Promise<any> {
    const [status, response]: any = await this.deleteProfileRequest();
    return [status, response.data];
  }

  @request({ action: ACCOUNT_INFO_FORM_ID })
  async submitUpdateAccountInfoRequest(data: UserType): Promise<Object> {
    return axios.patch(`${API_URL}${API_USER_PROFILE_URL}`, data, {
      params: {
        with: ENTRY_QUERY_PARAMS,
      },
    });
  }

  async updateAccountInfo(data: UserType): Promise<[boolean, AnyObject]> {
    const [status, response]: any = await this.submitUpdateAccountInfoRequest(data);

    return [status, response.data];
  }

  @request({ action: ACCOUNT_INFO_FORM_ID })
  async updateUploadAvatarAPI(avatar: FormData): Promise<any> {
    return axios.post(`${API_URL}${API_USER_AVATAR_URL}`, avatar);
  }

  @action({ action: ACCOUNT_INFO_FORM_ID })
  async uploadAvatar(data: string): Promise<[boolean, AnyObject]> {
    const resp = await fetch(data);
    const blobAvatar = await resp.blob();
    const formData = new FormData();
    formData.append('avatar', blobAvatar);

    const [status, response]: any = await this.updateUploadAvatarAPI(formData);

    return [status, response.data];
  }
}

const userProfile = new Profile();

export { userProfile };
