import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { StyledAccordion, StyledElevation } from 'src/modules/publicPages/styled';
import { createArrayOfNumbers } from 'src/modules/publicPages/utils';
import { BodyText, Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

const Wrapper = styled.div`
  margin: 41px 0 32px;

  .title {
    margin: 0;
    padding: 0;

    div:first-child {
      background: none;
      padding: 0;
    }

    .heading-text {
      padding: 6px 0 6px 32px;
      border-left: 3px solid ${(props) => props.theme.main.colors.clay1};
      text-transform: uppercase;
      color: ${(props) => props.theme.main.colors.clay1};
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }

    .accordion-body {
      margin: 32px 0 0 0 !important;
      padding: 0;

      @media (min-width: ${RESOLUTIONS.medium}px) {
        margin: 32px 0 0 0 !important;
        padding: 0 !important;
      }
    }
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .title {
    color: ${(props) => props.theme.main.colors.clay1};
    margin: 0 12px 16px 0;
  }
  .list-text {
    color: ${(props) => props.theme.main.colors.clay2};
    margin: 0 0 16px 0;

    @media (min-width: ${RESOLUTIONS.medium}px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export const BlockSubtitleAccordion = () => {
  return (
    <Wrapper>
      <StyledElevation level={0}>
        <StyledAccordion
          headingVariant='u1'
          headingText={t.staticAsString('transferRegistration.form.transferRules')}
          className='title'
          children={createArrayOfNumbers(3).map((value) => (
            <ListWrapper key={'blockSubtitlesKey' + value}>
              <Utility variant='u3' weight='bold' className='title'>
                {value}.
              </Utility>
              <BodyText variant='body1' className='list-text'>
                {t.staticAsString(`transferRegistration.form.transferRules.description${value}` as any)}
              </BodyText>
            </ListWrapper>
          ))}
          isInner={false}
        />
      </StyledElevation>
    </Wrapper>
  );
};
