import { observable, action, makeObservable } from 'mobx';

import { blockBodyScroll, unblockBodyScroll } from 'utils';
import { generateMobileScrollBlocker } from 'utils/blockScroll';

const [blockScroll, unblockScroll] = generateMobileScrollBlocker();

class Header {
  @observable showMobileMenu: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  openMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
    blockBodyScroll();
    blockScroll();
  }

  @action
  closeMobileMenu() {
    this.showMobileMenu = false;

    unblockBodyScroll();
    unblockScroll();
  }
}

export { Header };
export default new Header();
