import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { ClassesStore } from '../../stores';

function classesConditions(classesStore: ClassesStore) {
  function generateIsClasses(distanceId: number): IComputedValue<boolean> {
    return computed(() => {
      return !!classesStore.findClassesByDistance(+distanceId).length;
    });
  }

  const isAnyDistanceHasClasses: IComputedValue<boolean> = computed(() => {
    return !!classesStore.values.length;
  });

  return { isAnyDistanceHasClasses, generateIsClasses };
}

export { classesConditions };
