import { LOCALES } from './general';

export const IFRAME_PREFIX = '/iframe';

const salesPageIDs = '6486|french-marketing-race|6485|spanish-marketing-race';

export const ROUTER = {
  HOME: '/',
  TABLE_TEST: '/table-test',
  SIGNIN: '/signIn',
  SIGNUP: '/signUp',
  FORGOT: '/forgotPassword',
  FORGOT_CONFIRM: '/forgotPassword/confirm',
  RESET: '/reset-password',
  ABOUT: '/about',
  SHOP: 'https://shop.raceid.com/',
  PRESS: 'https://www.mynewsdesk.com/se/raceid',
  CAREER: 'https://career.raceid.com/',
  CONTACT_US: '/contactus', // Redirect to /about
  ABOUT_DETAILS: '/about/details',
  RACES: '/races',
  RACES_ABOUT: '/races/:id/about',
  RACES_INFORMATION: '/races/:id/information',
  RACES_MAP: '/races/:id/map',
  RACES_START_LIST: '/races/:id/startlist',
  RACES_RESULT: '/races/:id/result',
  RACE: '/races/:id',
  TERMS: '/termsAndConditions',
  TERMS_NEW: '/terms',
  PUBLIC: '/public',
  PUBLIC_TERMS: '/public/terms',
  PUBLIC_COOKIES: '/public/cookies',
  PUBLIC_PRIVACY: '/public/privacy',
  PUBLIC_CONTACT: '/public/contact',
  PUBLIC_FAQ: '/public/faq',
  PUBLIC_FAQ_MEMBER: '/public/faq/member',
  PUBLIC_FAQ_ORGANIZER: '/public/faq/organizer',
  CONTACT: '/contact',
  CAMPAIGNS_LANDING: '/campaigns/:token',
  GIFT_CARDS: '/gift-cards',

  PROFILE: '/myProfile',
  PROFILE_RESULTS_RACES: '/myProfile/races-results',
  PROFILE_RESULTS_VIRTUAL: '/myProfile/virtual-results',
  SETTINGS: '/myProfile/settings',
  ACCOUNTINFO: '/account-info',
  CHANGEPASSWORD: '/change-password',
  MOREINFO: '/more-info',
  SEGMENTS: '/myProfile/segments',
  SEGMENT_FORM: '/myProfile/distances/:distanceId/racers/:racerId',

  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy-policy',
  ARTICLE: '/articles/:id',
  ARTICLES: '/articles',
  VIRTUAL_RACES: '/virtual-races',
  ORGANIZER: '/organizer-guide',
  ORGANIZERS: 'links.organizersHeader',
  FEATURES_PRICING: 'https://organizer.raceid.com',
  RACE_ABOUT_US: '/races/:id/about',
  RACE_MAP: '/races/:id/map',
  RACE_INFO: '/races/:id/information',
  RACE_RESULT: '/races/:id/result',
  RACE_RESULT_WITH_DISTANCE: '/races/:id/result?currentDistanceId=:distanceId',
  RACE_START_LIST: '/races/:id/startlist',
  RACE_REGISTRATION: '/races/:id/registration',
  RACE_REGISTRATION_WITH_DISTANCE: '/races/:id/registration?distance=:distanceId',
  MANAGE_TEAM: '/manage-team/:raceId',
  AFTER_PAYMENT: '/race/:raceId/distance/:distanceId/after-payment',
  AFTER_PAYMENT_ALT: '/after_payment',
  ERROR_PAGE: '/something-went-wrong',
  NOT_FOUND_PAGE: '/not-found',
  SERVICE_UNAVAILABLE: '/service-unavailable',
  CONFIRM_PROFILE: '/confirm-profile/:type/:token',

  PROFILE_MANAGEMENT_XTRI: '/xtri',
  PROFILE_MANAGEMENT_NORSEMAN: '/norseman',
  PROFILE_MANAGEMENT_OTILLO: '/otillo',
  PROFILE_MANAGEMENT_TOUGH_VIKING: '/tough-viking',
  APP_STORE_APP: 'https://apps.apple.com/ua/app/raceid/id1532706481',
  MILES: '/miles',
  MILES_SIGN_IN: '/miles/sign-in',
  MILES_SETTINGS: '/miles/settings',
  MILES_HOME: '/miles/home',
  MILES_DAILY_DATA: '/miles/daily-data',
  MILES_TRAINING_PLAN: '/miles/training-plan',
  MILES_TRAINING_PLAN_WEEK: '/miles/training-plan/week/:weekStartDate',
  MILES_TRAINING_PLAN_FUTURE_WEEKS: '/miles/training-plan/future-weeks',
  MILES_METRICS_INSIGHTS: '/miles/training-plan/insights',
  MILES_TRAINING_PLAN_JOURNAL: '/miles/training-plan/insights/journal',
  MILES_METRICS_HEART_RATE_ZONES: '/miles/training-plan/insights/heart-rate-zones',
  MILES_METRICS_DATA_QUALITY: '/miles/training-plan/insights/data-quality',
  MILES_METRICS_SESSION_HISTORY: '/miles/training-plan/session-history',
  MILES_METRICS_SESSION_HISTORY_WEEK: '/miles/training-plan/session-history/:weekStartDate',
  MILES_RENEW_SUBSCRIPTION: '/miles/renew-subscription',

  // TODO, remove. Will be removed, I hope.
  RACE_SALES_PAGES_ROOT: `/:id(${salesPageIDs})`,
  RACE_SALES_PAGES_ABOUT: `/:id(${salesPageIDs})/about`,
  RACE_SALES_PAGES_MAP: `/:id(${salesPageIDs})/map`,
  RACE_SALES_PAGES_STARTLIST: `/:id(${salesPageIDs})/startlist`,
  RACE_SALES_PAGES_RESULTS: `/:id(${salesPageIDs})/result`,
  RACE_SALES_PAGES_INFORMATION: `/:id(${salesPageIDs})/information`,

  IFRAME_ROOT: IFRAME_PREFIX,
  IFRAME_RACES: `${IFRAME_PREFIX}/races`,
  IFRAME_RACE_RESULT: `${IFRAME_PREFIX}/races/:id/result`,
  IFRAME_ABOUT: `${IFRAME_PREFIX}/about`,
  IFRAME_ABOUT_DETAILS: `${IFRAME_PREFIX}/about/details`,
  IFRAME_TERMS: `${IFRAME_PREFIX}/termsAndConditions`,
  IFRAME_CONTACT_US: `${IFRAME_PREFIX}/contactus`, // redirect to about
  IFRAME_CONTACT: `${IFRAME_PREFIX}/contact`,
  IFRAME_PRIVACY_POLICY: `${IFRAME_PREFIX}/privacy-policy`,
  IFRAME_FAQ: `${IFRAME_PREFIX}/faq`,
  IFRAME_FORGOT: `${IFRAME_PREFIX}/forgotPassword`,
  IFRAME_PUBLIC: `${IFRAME_PREFIX}/public`,
  IFRAME_PUBLIC_TERMS: `${IFRAME_PREFIX}/public/terms`,
  IFRAME_PUBLIC_COOKIES: `${IFRAME_PREFIX}/public/cookies`,
  IFRAME_PUBLIC_PRIVACY: `${IFRAME_PREFIX}/public/privacy`,
  IFRAME_PUBLIC_CONTACT: `${IFRAME_PREFIX}/public/contact`,
  IFRAME_PUBLIC_FAQ: `${IFRAME_PREFIX}/public/faq`,
  IFRAME_PUBLIC_FAQ_MEMBER: `${IFRAME_PREFIX}/public/faq/member`,
  IFRAME_PUBLIC_FAQ_ORGANIZER: `${IFRAME_PREFIX}/public/faq/organizer`,
  TRANSFER_REGISTRATION_CONFIRM: '/transfer-registration-confirm',
};

type RouterType = typeof ROUTER;
type RouterKey = keyof RouterType;

export const ROUTER_WITH_LOCALES: { [k in keyof typeof ROUTER]: string } = (() => {
  const locales = LOCALES.join('|');

  return Object.keys(ROUTER).reduce((router, currentKey) => {
    return {
      ...router,
      [currentKey]: `/(${locales})${ROUTER[currentKey as RouterKey]}`,
    };
  }, {} as any);
})();

export const PRIVATE_ROUTES = [ROUTER_WITH_LOCALES.MANAGE_TEAM, ROUTER_WITH_LOCALES.PROFILE, ROUTER_WITH_LOCALES.SETTINGS];

export const LINK_TO_XTRI_JOURNEY = 'https://xtriworldtour.com/journey/';
export const LINK_RACEID_SHOP = 'https://shop.raceid.com/';
