import { Observer } from 'mobx-react';
import * as React from 'react';
import PhoneInputComponent from 'react-phone-input-2';

import { LOAD_LANGUAGES, PREFERED_COUNTRIES } from 'src/constants';

import { Hide } from 'components/condition';

import { countriesStore, progressStore } from 'stores';

import { InputWrapComponent } from '../InputTools';
import { PhoneFieldWrapper } from './PhoneFieldWrapper';

interface CustomInputProps extends BaseInputProps {
  inputName?: string;
  country?: string;
}

export const PhoneField: React.FC<CustomInputProps> = (props) => {
  const { autoComplete, requiredMark, errorMessage, label, name, value, disabled, inputName, country } = props;
  const phoneTestSelector = testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root);

  const handleChange = (phone: string, countryData: Object, e, formattedValue) => {
    const { onChange, name } = props;

    onChange({
      name,
      value: {
        phone: formattedValue,
        countryData,
      },
    });
  };

  const handleBlur = (e) => {
    const { onBlur, name } = props;

    onBlur?.({
      name,
      e,
    });
  };

  return (
    <InputWrapComponent
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={value?.phone || ''}
    >
      <PhoneFieldWrapper disabled={disabled} hasError={Boolean(errorMessage)} isEmpty={!value?.phone && !Boolean(country)}>
        <Observer>
          {() => {
            const isLoadingLocale = progressStore.isLoading(LOAD_LANGUAGES);
            const allCountriesForPhoneField = countriesStore.translatedValues();
            return (
              <Hide if={isLoadingLocale}>
                <PhoneInputComponent
                  containerClass={disabled ? 'disabled' : ''}
                  preferredCountries={PREFERED_COUNTRIES}
                  value={value?.phone || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={disabled}
                  enableSearch
                  enableLongNumbers
                  disableSearchIcon
                  localization={allCountriesForPhoneField}
                  isValid={() => true}
                  inputProps={{ autoComplete, name: inputName, ...phoneTestSelector }}
                  country={country?.toLowerCase() || ''}
                />
              </Hide>
            );
          }}
        </Observer>
      </PhoneFieldWrapper>
    </InputWrapComponent>
  );
};
