import { each, merge } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

import { ClassModel } from 'models';

class Classes {
  @observable values: ClassType[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get modelValues() {
    const { values } = this;

    return values.map<ClassModel>((el) => new ClassModel(el));
  }

  @action
  async setValues(values: ClassType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  findClassesByDistance(distanceId: number): ClassModel[] {
    const { modelValues } = this;
    return modelValues.filter((model) => model.value.distance_id === distanceId);
  }

  find(id: number | string | null): ClassModel | null {
    return this.expandedClasses[id || ''];
  }

  // For show all distances
  @computed
  get distinctClasses(): Array<ClassModel> {
    const { modelValues } = this;
    const similarValuesTable: { [K in string]: ClassModel[] } = {};

    modelValues.forEach((model) => {
      const hash = model.hashForCompare;
      similarValuesTable[hash] = [...(similarValuesTable[hash] || []), model];
    });

    const distinctClasses: AnyObject[] = [];

    each(similarValuesTable, (models: ClassModel[], hash: string) => {
      if (!models.length) {
        return;
      }

      const ids = models.map((model) => model.value.id);
      const serializedIds = ClassModel.serializeSimilarClassIds(ids);
      const distinctModel = new ClassModel({ ...models[0].value }, serializedIds);

      distinctClasses.push(distinctModel);
    });

    return distinctClasses as ClassModel[];
  }

  @computed
  get expandedClasses(): { [K in number]: ClassModel } {
    const { modelValues } = this;
    return modelValues.reduce((acc, model) => {
      const id = model.value.id;
      return merge(acc, { [id]: model });
    }, {});
  }
}

export { Classes, Classes as ClassesStore };
