import { get } from 'lodash';
import { Observer } from 'mobx-react';
import { DistanceType } from 'modules/home/types';
import { actionMenuActions } from 'modules/profile/actions';
import React, { FC } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';
import { ShowDesktop } from 'src/styledComponents/Conditions';
import { Divider } from 'src/styledComponents/Divider';

import { SportType } from 'models';

import { tabStore } from '../../stores';

import { menuItems } from '../ActionMenuItems';
import { MyRacesCard } from './MyRacesCard';

type Props = {
  getRedirectLink: Function;
};

const StyledDivider = styled(Divider)`
  height: 1px;
  margin: 24px 0;
`;

export const RegisteredTab: FC<Props> = ({ getRedirectLink }) => {
  return (
    <Observer>
      {() => {
        const amountOfdistances = tabStore.values.length;

        return tabStore.values.map((distance: DistanceType, index) => {
          const sportModel = new SportType(distance.sport as any);
          const sportIcon = distance.sport && sportModel.value.icon;
          const sportTitle = distance.sport && sportModel.title;
          const showBibs = Boolean(distance.show_bibs && distance.show_startlist);
          const isTransferRegistrationInProgress = (distance as any).registrationTransfer;

          return (
            <React.Fragment key={distance.id}>
              <MyRacesCard
                distanceId={distance.id}
                bodyTitle={distance.raceName}
                distanceName={distance.name}
                startDate={distance.startDate}
                endDate={distance.endDate}
                distanceLength={distance.distanceLength}
                image={distance.image}
                location={distance.location}
                onClickMore={() => actionMenuActions.toggleSwitchOn(distance as any)}
                settings={menuItems()}
                sportIcon={sportIcon ?? ''}
                sportType={sportTitle ?? ''}
                teamName={distance.teamName}
                raceResultsLink={generatePath(ROUTER.RACE_INFO, { id: get(distance, 'race_id') || get(distance, 'race_parent_id') })}
                published={distance.published}
                bib={showBibs ? distance.bib : ''}
                racerClass={distance.racerClass}
                wave={distance.wave}
                isTransferRegistrationInProgress={isTransferRegistrationInProgress}
              />

              <ShowDesktop>{index !== amountOfdistances - 1 && amountOfdistances > 1 && <StyledDivider light />}</ShowDesktop>
            </React.Fragment>
          );
        });
      }}
    </Observer>
  );
};
