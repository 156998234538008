import { DailyDataFields } from '../../dailyData/types';
import { HeartRateZones, JournalItemType } from '../types';
import { DataQualityBE, DataQualityTextType, DataQualityTypeFE, InsightAction } from '../types/dataQuality';

export const mapJournalBEtoFE = (data: JournalItemType[]) => {
  const mappedObj = data.map(({ readiness, ratings }) => {
    if (!ratings) {
      const updatedRatings = Object.keys(DailyDataFields).reduce((acc, currVal) => {
        return { ...acc, [currVal]: 0 };
      }, {});
      return {
        readiness,
        ...updatedRatings,
      };
    }
    return {
      readiness,
      ...ratings,
    };
  });

  let mappedData: AnyObject = {};

  mappedObj.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (!mappedData[key]) {
        mappedData[key] = [];
      }
      mappedData[key].push({ uv: value });
    });
  });

  return mappedData;
};

export const mapperHeartRates = ({ fractions, values }: HeartRateZones) => {
  const zones = fractions.map((fraction, index) => {
    const isoTime = new Date(values[index] * 1000).toISOString();
    const time = values[index] < 3600 ? isoTime.slice(14, 19) : isoTime.slice(11, 19);
    return { zone: index + 1, percent: Math.round(fraction * 100), time };
  });
  return zones.reverse();
};

export const mapDataQualityToFE = (data: DataQualityBE): DataQualityTypeFE[] => {
  const { insightActions } = data;
  return Object.values(insightActions).map((insightAction: InsightAction) => {
    const getText = (key: 'title' | 'insight' | 'action' | 'actionDeep') => {
      return insightAction[key].texts.reduce((accText, currObj: DataQualityTextType) => {
        return accText + currObj.text;
      }, '');
    };
    return {
      title: getText('title'),
      message: getText('insight'),
      description: `${getText('action')} ${getText('actionDeep')}`,
      points: insightAction.tag,
    };
  });
};
