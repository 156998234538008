import axios from 'axios';

import { API_URL, API_USER_PROFILE_URL, API_WISHLIST, API_USER_CHECK_EMAIL_URL, API_USER_REGISTERED_RACES } from 'src/constants';
import { USER } from 'src/constants';

import { request, action, toastService } from 'utils';

import { sessionStore, errorsStore, wishlistsStore } from 'stores';

type CheckingEmailType = {
  action: string;
  email: string;
  errorMessage?: string;
};

class User {
  @request({ action: USER, authOnly: true })
  async loadUserProfileRequest(): Promise<any> {
    return axios.get(`${API_URL}${API_USER_PROFILE_URL}`, {
      params: {
        with: 'sports;wishlist;raceUnions;xtriTickets;subscriptions',
      },
    });
  }

  @request({ action: USER })
  async loadUserWishlistsRequest(): Promise<any> {
    return axios.get(`${API_URL}${API_WISHLIST}`, {
      params: { filter: 'id' },
    });
  }

  @request({ action: USER })
  async loadUserRegisteredRacesRequest(): Promise<any> {
    return axios.get(`${API_URL}${API_USER_REGISTERED_RACES}`);
  }

  @action({ action: USER })
  async loadUserProfile(): Promise<any> {
    const [responseStatus, response] = await this.loadUserProfileRequest();

    if (responseStatus) {
      const user = response.data.data;
      sessionStore.userProfile(user);
    }
    response && toastService.error(response.data.message);
  }

  @action({ action: USER })
  async loadUserWishlist(): Promise<any> {
    const [status, response] = await this.loadUserWishlistsRequest();

    if (!status) {
      toastService.error(response.data.message);
    }

    return response;
  }

  @action({ action: USER })
  async loadUserRegisteredRaces(): Promise<any> {
    const [status, response] = await this.loadUserRegisteredRacesRequest();

    if (status) {
      wishlistsStore.addRegisteredValues(response.data.data);
    }
  }
}

const userService = new User();

export { User, userService };
