import { encodeQuery, history } from 'utils';

function parseUrlParams(): {
  distanceId: number | null;
  racerId: number | null;
} {
  const { search } = history.location;
  const params = encodeQuery.parseQuery(search);

  return {
    distanceId: params.distance_id,
    racerId: params.racer_id,
  };
}

export { parseUrlParams };
