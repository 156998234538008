import styled, { css } from 'styled-components';

import { gradientAnimation } from 'styles';

import { BaseSkeletonProps } from './Skeleton';

const defaultProps = {
  width: '100%',
  height: 'auto',
};

const RectangleSkeleton = styled.div<BaseSkeletonProps>`
  width: ${(props) => props.width || defaultProps.width};
  height: ${(props) => props.height || defaultProps.height};
  background: ${(props) => props.theme.main.colors.clay5};

  ${(props) =>
    props.hasChildren &&
    css`
      & > * {visibility: hidden;},
    `}

  ${(props) =>
    props.hasChildren &&
    !props.height &&
    css`
      height: auto;
    `}

    ${(props) =>
    props.hasChildren &&
    !props.width &&
    css`
      max-width: fit-content;
    `}
  ${({ animation }) => animation == 'gradient' && gradientAnimation}
`;

export default RectangleSkeleton;
