import { distanceChanger } from '../../mutations';
import { updateHistoryBasedOnFilters } from '../filters';

/*
 * Init filtrers and distance based on defaults and history params
 */
function onDistanceChange(id: null | number) {
  // distanceChanger.change(id);
  // updateHistoryBasedOnFilters();
}

export { onDistanceChange };
