import * as React from 'react';
import styled from 'styled-components';

import { RadioButton } from './Radio';

const Wrapper = styled.div``;

type RadioProps = {
  radioValue: any;
  labelPosition?: 'left' | 'right';
  radioListItems: NewSelectItemType[];
} & BaseInputProps;

export const RadioList: React.FC<RadioProps> = (props) => {
  const { label, name, value, onChange, labelPosition = 'left', radioListItems } = props;
  const stringedValue = value?.value?.toString();

  return (
    <Wrapper title={label}>
      {radioListItems.map((item) => (
        <RadioButton
          key={name + item.key}
          id={name + item.value}
          name={name}
          label={item.label as string}
          value={{ ...value, value: stringedValue }}
          radioValue={item}
          onChange={onChange}
          labelPosition={labelPosition}
        />
      ))}
    </Wrapper>
  );
};
