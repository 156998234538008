import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const PlusPath = (
  <>
    <g clipPath='url(#clip0_1374_79720)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.66675 9.47611V15.4761H9.33341V9.47611H15.3334V6.80945H9.33341V0.809448H6.66675V6.80945H0.666748V9.47611H6.66675Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1374_79720'>
        <rect width='16' height='16' fill='currentColor' transform='translate(0 0.142822)' />
      </clipPath>
    </defs>
  </>
);

export const Plus = (props: IconProps) => <SvgIcon path={PlusPath} viewBox={'0 0 16 17'} {...props} />;
