import { SELECT_DROP_MAX_HEIGHT } from 'src/constants';

function isSelectDropUp(rect: ClientRect | null, listHeight: number): boolean {
  if (!rect) {
    return false;
  }

  let selectHeight = SELECT_DROP_MAX_HEIGHT;

  if (listHeight < SELECT_DROP_MAX_HEIGHT) {
    selectHeight = listHeight;
  }

  const height: number = window.innerHeight;
  const offsetToBottom = height - rect.bottom;
  const offsetToTop = rect.top;

  if (selectHeight < offsetToBottom) {
    return false;
  }

  return offsetToTop > offsetToBottom;
}

export { isSelectDropUp };
