import { Observer } from 'mobx-react';
import * as React from 'react';

import { Form } from 'src/components/form';
import { MobileFilters } from 'src/modules/racersTable/components/Filters';
import { filtersOpenedStore } from 'src/modules/results/stores';

import { FORM_FILTERS_ID } from '../../constants';

import { classesSelect, wavesSelect, distanceSelect, gendersSelect, getTotalRacersCount } from '../../utils';

import { isAllFiltersAreShowed, isGenderFilterAvailable } from '../../derivations';

export const MobileFiltersWrapper = () => {
  return (
    <Observer>
      {() => {
        const open = Boolean(filtersOpenedStore.value);
        const bodyElement = document.querySelector('body');
        bodyElement!.style.overflow = open ? 'hidden' : 'initial';

        const filterProps = {
          distanceOptions: distanceSelect.options.get(),
          classOptions: classesSelect.options.get(),
          waveOptions: wavesSelect.options.get(),
          gendersOptions: gendersSelect.options(),
          isGenderAvailable: isGenderFilterAvailable.get(),
          results: getTotalRacersCount(),
          show: isAllFiltersAreShowed.get(),
        };

        return (
          <Form id={FORM_FILTERS_ID} onSubmit={() => {}} disableAutofill enableManualClean>
            <MobileFilters {...filterProps} isOpen={open} />
          </Form>
        );
      }}
    </Observer>
  );
};
