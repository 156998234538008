import * as React from 'react';

export const Bib = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.18927 12.5351C9.18927 13.3233 9.00629 13.9312 8.64033 14.3587C8.27712 14.7862 7.76395 15 7.10083 15C6.43219 15 5.9149 14.7849 5.54894 14.3547C5.18298 13.9245 5 13.318 5 12.5351V11.4689C5 10.6807 5.1816 10.0728 5.54481 9.64529C5.91077 9.21777 6.42669 9.00401 7.09257 9.00401C7.75845 9.00401 8.27437 9.2191 8.64033 9.6493C9.00629 10.0795 9.18927 10.6874 9.18927 11.4729V12.5351ZM7.79835 11.2966C7.79835 10.8771 7.74194 10.5645 7.62913 10.3587C7.51631 10.1503 7.33746 10.0461 7.09257 10.0461C6.85318 10.0461 6.67846 10.1423 6.5684 10.3347C6.46108 10.5271 6.4033 10.8183 6.39505 11.2084V12.6994C6.39505 13.1349 6.45145 13.4542 6.56427 13.6573C6.67708 13.8577 6.85594 13.9579 7.10083 13.9579C7.33746 13.9579 7.51219 13.8604 7.625 13.6653C7.73781 13.4676 7.7956 13.159 7.79835 12.7395V11.2966Z'
      fill='#272E34'
    />
    <path
      d='M14.105 14.9198H9.91156V14.0381L11.8432 12.0661C12.3192 11.5397 12.5572 11.1216 12.5572 10.8116C12.5572 10.5605 12.5008 10.3694 12.388 10.2385C12.2752 10.1075 12.1114 10.0421 11.8968 10.0421C11.6849 10.0421 11.513 10.1303 11.3809 10.3066C11.2488 10.4803 11.1828 10.6981 11.1828 10.9599H9.78774C9.78774 10.6019 9.87991 10.2719 10.0643 9.96994C10.2486 9.66533 10.5045 9.42752 10.832 9.25651C11.1594 9.0855 11.5254 9 11.9298 9C12.5792 9 13.0786 9.14562 13.4281 9.43687C13.7803 9.72812 13.9564 10.1463 13.9564 10.6914C13.9564 10.9212 13.9123 11.1456 13.8243 11.3647C13.7362 11.5812 13.5987 11.8096 13.4116 12.0501C13.2272 12.2879 12.9287 12.6072 12.5159 13.008L11.74 13.8778H14.105V14.9198Z'
      fill='#272E34'
    />
    <path
      d='M16.0778 11.4369H16.7341C17.2541 11.4369 17.5142 11.1897 17.5142 10.6954C17.5142 10.503 17.4522 10.3467 17.3284 10.2265C17.2046 10.1035 17.0299 10.0421 16.8042 10.0421C16.6199 10.0421 16.4589 10.0942 16.3213 10.1984C16.1865 10.3026 16.1191 10.4322 16.1191 10.5872H14.7282C14.7282 10.2799 14.8162 10.006 14.9923 9.76553C15.1684 9.52505 15.4119 9.33801 15.7229 9.20441C16.0366 9.06814 16.3805 9 16.7547 9C17.4233 9 17.9489 9.1483 18.3314 9.44489C18.7138 9.74148 18.9051 10.149 18.9051 10.6673C18.9051 10.9185 18.8253 11.1563 18.6657 11.3808C18.5088 11.6025 18.2791 11.7829 17.9764 11.9218C18.2956 12.0341 18.546 12.2051 18.7276 12.4349C18.9092 12.662 19 12.9439 19 13.2806C19 13.8016 18.7936 14.2184 18.3809 14.5311C17.9682 14.8437 17.4261 15 16.7547 15C16.3612 15 15.9953 14.9279 15.6568 14.7836C15.3211 14.6366 15.0666 14.4349 14.8933 14.1784C14.7199 13.9192 14.6333 13.6253 14.6333 13.2966H16.0324C16.0324 13.4756 16.1067 13.6306 16.2553 13.7615C16.4039 13.8925 16.5869 13.9579 16.8042 13.9579C17.0491 13.9579 17.2445 13.8925 17.3903 13.7615C17.5362 13.6279 17.6091 13.4583 17.6091 13.2525C17.6091 12.9586 17.5334 12.7502 17.3821 12.6273C17.2307 12.5043 17.0216 12.4429 16.7547 12.4429H16.0778V11.4369Z'
      fill='#272E34'
    />
    <rect x='2' y='4' width='20' height='16' rx='1' stroke='#272E34' strokeWidth='2' mask='url(#path-1-inside-1_588:684)' />
  </svg>
);
