import { generatePath } from 'react-router-dom';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

import { parseUrlParams } from './parseUrlParams';

function changeDistance(id: number, useReplace = false) {
  const params = parseUrlParams();
  const url = generatePath(ROUTER.RACE_REGISTRATION, { id: params.raceId });

  if (useReplace) {
    history.replace(`${url}?distance=${id}`);
  } else {
    history.push(`${url}?distance=${id}`);
  }
}

export const navigateUtil = {
  changeDistance,
};
