import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Headline } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { isModeNew, isModeEdit, isModeFirstNew, isGoalAltitude, isGoalDistance } from '../derrivations';

const StyledHeadline = styled(Headline)`
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 8px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    text-align: left;
  }
`;

const Title = () => {
  return (
    <Observer>
      {() => {
        let title: TranslationLockedKeys = '' as TranslationLockedKeys;

        if (isModeNew.get()) {
          title = 'segmentsForm.newTitle';
        }

        if (isModeFirstNew.get()) {
          if (isGoalAltitude.get()) {
            title = 'segmentsForm.firstNewTitleAltitude';
          }
          if (isGoalDistance.get()) {
            title = 'segmentsForm.firstNewTitleDistance';
          }
        }

        if (isModeEdit.get()) {
          title = 'segmentsForm.editTitle';
        }

        return <StyledHeadline variant={'h4'}>{t(title)!}</StyledHeadline>;
      }}
    </Observer>
  );
};

export { Title };
