import { CHANGE_PASS_FORM_ID } from 'modules/profileSettings/constants';
import { userPasswordFieldsType } from 'modules/profileSettings/validation';
import { UserPasswordType } from 'typings/old-types/UserType';

import { UPDATE_PASSWORD } from 'src/constants';
import { profileService } from 'src/services';
import { form } from 'src/stores';
import { validation } from 'src/utils';

export const onSubmitChangePasswordForm = async (): Promise<void> => {
  const formData: UserPasswordType = form.fetchWithoutObservable(CHANGE_PASS_FORM_ID);

  if (validation(CHANGE_PASS_FORM_ID, formData, userPasswordFieldsType)) {
    const requestFields = {
      action: UPDATE_PASSWORD,
      ...formData,
    };

    profileService.updateUserPassword(requestFields, CHANGE_PASS_FORM_ID);
  }
};
