import { isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { resultsPresentation } from 'modules/results/derivations';
import { calcProgress, formatDistance, formatAltitude } from 'modules/results/utils';
import * as React from 'react';
import styled from 'styled-components';

import { Cell } from 'src/modules/racersTable/components';
import { CELL_SIZES } from 'src/modules/racersTable/constants';
import { RightSideColumnsType } from 'src/modules/racersTable/types';
import { getCellSizes } from 'src/modules/racersTable/utils';
import { TrackingSource } from 'src/modules/segments/components/TrackingSource';

import { classesStore, wavesStore, squadsStore, distancesStore, goalsStore } from '../../stores';

import { LResult } from '../../types';
import { CheckpointArrow } from '../Checkpoint';
import { PresentResult } from '../PresentResult';
import { RowWrap } from './RowWrap';

type Props = {
  columnsActive: RightSideColumnsType;
  value: LResult;
  checkpointId: number | nil;
  distanceId: number | nil;
};

type CellType = {
  condition?: boolean;
  value?: string | React.ReactNode;
  minWidth?: string;
  weight?: 'medium' | 'bold';
  truncate?: boolean;
};

const Wrapper = styled(RowWrap)<{ sizes: string }>`
  .grid-wrap {
    justify-content: flex-end;
    align-items: center;
    grid-template-columns: ${(props) => props.sizes};
    gap: 32px;

    padding-right: 40px;
    padding-left: 32px;
  }
`;

export const RightSideSingleRow: React.FC<Props> = (props) => {
  const { columnsActive, value, checkpointId, distanceId } = props;
  const sizes = getCellSizes(columnsActive);
  const distanceModel = distancesStore.find(+distanceId!);
  const place = resultsPresentation.generatePlace(value, distanceId!, checkpointId);

  if (!value.racer || !distanceModel) return null;

  const classModel = classesStore.find(+value.racer.class_id!);
  const waveModel = wavesStore.find(+value.racer.wave_id!);
  const squad = squadsStore.values.find((item) => item.id === (value.racer as any).squad_id!);

  const CheckpointButton = !isEmpty(distanceModel.checkpoints) ? <CheckpointArrow recordId={value.racer_id} /> : null;
  const goalModel = goalsStore.findByDistance(+distanceId!);
  const showPercents = distanceModel.isMode('cumulative');
  const progressText = calcProgress(value.distance_logged, goalModel?.value?.goal_max_length);

  const Source = !distanceModel.isMode('classic') ? (
    <TrackingSource link={value.activity_link} segmentId={value.racer_id} showPercents={showPercents} progressText={progressText} />
  ) : null;

  const distanceLogged = value.distance_logged as any;
  const distanceLength = goalModel?.isGoalType('distance') ? formatDistance(distanceLogged) : formatAltitude(distanceLogged);

  return (
    <Wrapper sizes={sizes} checkpointId={checkpointId} distanceId={Number(distanceId)} value={value} height={64} isRightSide place={place}>
      <Cell name='club' columnsActive={columnsActive} minWidth={CELL_SIZES[0].minWidth} value={value.racer.union} />
      <Cell name='squad' columnsActive={columnsActive} minWidth={CELL_SIZES[1].minWidth} value={squad?.name} />
      <Cell name='class' columnsActive={columnsActive} minWidth={CELL_SIZES[2].minWidth} value={classModel?.value.title} />
      <Cell name='wave' columnsActive={columnsActive} minWidth={CELL_SIZES[3].minWidth} value={waveModel?.value.name} />
      <Cell
        name='time'
        columnsActive={columnsActive}
        minWidth={CELL_SIZES[4].minWidth}
        weight='bold'
        value={<PresentResult.FinishResult result={value} checkpointId={checkpointId} distance={distanceModel} />}
      />
      <Cell
        name='diff'
        columnsActive={columnsActive}
        minWidth={CELL_SIZES[5].minWidth}
        value={<PresentResult.LeaderTime result={value} checkpointId={checkpointId} distanceId={Number(distanceId)} />}
      />
      <Cell name='goalDistance' value={distanceLength} columnsActive={columnsActive} />
      <Cell name='tracker' columnsActive={columnsActive} minWidth={CELL_SIZES[6].minWidth} value={Source} />
      <Cell name='checkpoint' columnsActive={columnsActive} minWidth={CELL_SIZES[7].minWidth} value={CheckpointButton} />
    </Wrapper>
  );
};
