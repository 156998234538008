import * as React from 'react';

export const Search = (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1552 13.3522C13.2263 12.047 14.6578 8.4994 13.3526 5.42833C12.0475 2.35726 8.49983 0.925719 5.42876 2.23089C2.3577 3.53606 0.926154 7.08371 2.23133 10.1548C3.5365 13.2258 7.08414 14.6574 10.1552 13.3522Z'
      stroke='#50555B'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12.064 12.0634L16.75 16.75' stroke='#50555B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
