import axios from 'axios';
import { UserPasswordType, UserType } from 'typings/old-types/UserType';

import { API_UPDATE_PASSWORD_URL, API_URL, API_USER_AVATAR_URL, API_USER_PROFILE_URL, ROUTER, USER } from 'src/constants';

import { action, fireToast, history, request, t } from 'utils';

import { errorsStore, sessionStore } from 'stores';

class Profile {
  @request({ action: USER })
  async updateUserProfileAPI(profile: UserType): Promise<any> {
    return axios.patch(`${API_URL}${API_USER_PROFILE_URL}`, profile);
  }

  @request({ action: USER })
  async updateUploadAvatarAPI(avatar: UserType): Promise<any> {
    return axios.post(`${API_URL}${API_USER_AVATAR_URL}`, avatar);
  }

  @request({ action: USER })
  async updateUserPasswordAPI(options: UserPasswordType): Promise<any> {
    return axios.patch(`${API_URL}${API_UPDATE_PASSWORD_URL}`, options);
  }

  @action({ action: USER })
  async uploadUserAvatar(avatar: any, changeAvatarUrl: Function) {
    const [responseStatus, response] = await this.updateUploadAvatarAPI(avatar);

    if (responseStatus) {
      changeAvatarUrl(response.data.avatar);
      let user = sessionStore.getUserData();
      user.avatar = response.data.avatar;
      sessionStore.userProfile(user);
    } else {
      fireToast(response.data.message, 'error', '', 'top-right');
    }

    return responseStatus;
  }

  @action({ action: USER })
  async updateUserPassword(options: UserPasswordType, formId: string) {
    const [responseStatus, response] = await this.updateUserPasswordAPI(options);

    if (responseStatus) {
      if (response?.data?.data.token) {
        sessionStore.login(response?.data?.data.token);
        history.push(ROUTER.PROFILE);
        fireToast(t.staticAsString('toast.passwordUpdated'), 'success', '', 'top-right');
      }
      return;
    }
    if (response.data.errors.current_password) {
      errorsStore.add(formId, { current_password: [t.staticAsString('settings.passwordIncorrect')] });
    }

    return responseStatus;
  }
}

const profileService = new Profile();

export { Profile, profileService };
