import { insightsService, journalService, heartRateZonesService, dataQualityService, sessionHistoryService } from '../services';

import { heartRateZonesStore, insightsStore, journalStore, sessionHistoryStore } from '../stores';

export const mountInsights = () => {
  insightsService.getInsights();
};

export const unmountInsights = () => {
  insightsStore.clear();
};

export const mountJournal = () => {
  journalService.getJournal();
};

export const unmountJournal = () => {
  journalStore.clear();
};

export const mountDataQuality = () => {
  dataQualityService.getDataQuality();
};

export const mountHeartRateZones = () => {
  heartRateZonesService.getHeartRateZones();
};

export const unmountHeartRateZones = () => {
  heartRateZonesStore.clear();
};

export const mountSessionHistory = () => {
  sessionHistoryService.getSessionHistory();
};

export const unmountSessionHistory = () => {
  sessionHistoryStore.clear();
};
