import * as React from 'react';

export const RaceFacebook = (
  <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.9285 13.9856H17.4051V12.8543C17.3968 12.7716 17.406 12.6882 17.432 12.6094C17.458 12.5305 17.5004 12.458 17.5562 12.3966C17.6121 12.3351 17.6802 12.2861 17.7562 12.2527C17.8322 12.2193 17.9144 12.2023 17.9975 12.2027H19.7745V9.83337H17.2097C14.8818 9.83337 14.4435 11.6104 14.4435 12.7121V13.9856H12.6665V16.3549H14.4435V23.1667H17.4051V16.3549H19.6856L19.9285 13.9856Z'
      fill='#656B6F'
    />
    <rect x='0.75' y='1.25' width='30.5' height='30.5' rx='15.25' stroke='#656B6F' strokeWidth='1.5' />
  </svg>
);
