import { transaction } from 'mobx';

import { dataStore, paginationMetaStore, requestsVersionStore, loaderDisposers } from '../../stores';

/* NOTE
 * reset pagination, data, preloders
 * reenable app start position
 */
function refreshApp(): string {
  loaderDisposers.disposeAll();

  transaction(() => {
    requestsVersionStore.generate();
    dataStore.clean();
    paginationMetaStore.clean();
  });

  return requestsVersionStore.fetch();
}

export { refreshApp };
