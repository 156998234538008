import { computed } from 'mobx';
import { distanceProductStore, serviceFeeStore, smartLinkStore } from 'modules/registration/stores';

import { priceUtils } from '../../utils';

import { formDataSelectors } from '../formDataSelectors';
import { mainConditions } from '../mainConditions';
import { distanceSelector } from '../mappedData';
import { _groupSignUpMembersTotal } from './_groupSignUpMembersTotal';
import { _leaderCustomFieldsTotal } from './_leaderCustomFieldsTotal';
import { _leaderTotal, _leaderTotalWithoutDiscount } from './_leaderTotal';
import { _teamSignUpMembersTotal, _teamSignUpMembersTotalWithoutDiscount } from './_teamSignUpMembersTotal';
import { distancePrice as distancePriceSelector } from './distancePrice';

/**
 * @description
 * total price for the registration
 * including coupon
 */
const totalPrice = computed((): number => {
  const calculateWithRefund = (resultPrice) => {
    const refundProtectPercent = distanceSelector.refundProtect.get();
    const refundProtectFormValue = formDataSelectors.fetchLeaderRefundProtect();

    const { serviceFee } = serviceFeeStore;
    if (serviceFee) {
      resultPrice += serviceFee / 100;
    }

    if (refundProtectFormValue) {
      resultPrice += (resultPrice * refundProtectPercent) / 100;
    }

    return priceUtils.round(resultPrice);
  };

  if (mainConditions.isPageRegistrationTeamWithCustomFields.get() || mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
    let resultPrice =
      mainConditions.isSmartLinkRegistration() && mainConditions.isCouponEnableBySmartLink.get() && smartLinkStore.value
        ? priceUtils.round(
            priceUtils.calculatePriceWithSmartLinkDiscount({
              distancePrice: distancePriceSelector.get(),
              customFieldTotalPrice: _leaderTotalWithoutDiscount.get() + _teamSignUpMembersTotalWithoutDiscount.get(),
              smartLink: smartLinkStore.value,
            }),
          )
        : priceUtils.round(
            priceUtils.calculateMemberPriceWithDiscount({
              distancePrice: distancePriceSelector.get(),
              coupon: formDataSelectors.fetchLeaderCoupon(),
              customFieldTotalPrice: _leaderTotalWithoutDiscount.get() + _teamSignUpMembersTotalWithoutDiscount.get(),
            }),
          );

    return calculateWithRefund(resultPrice);
  }

  if (mainConditions.isPageRegistrationSingleWithCustomFields.get() || mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
    let resultPrice = priceUtils.round(_groupSignUpMembersTotal.get());
    return calculateWithRefund(resultPrice);
  }

  if (mainConditions.isPageConfirmationWithCustomFields.get() || mainConditions.isPageConfirmationWithoutCustomFields.get()) {
    let resultPrice;

    if (mainConditions.isPushPayment.get()) {
      resultPrice = priceUtils.round(_leaderTotal.get());
    } else {
      resultPrice = priceUtils.round(_leaderCustomFieldsTotal.get());
    }

    return calculateWithRefund(resultPrice);
  }

  if (mainConditions.isTransferredRegistration.get()) {
    const resultPrice = priceUtils.round(_leaderTotal.get());

    return calculateWithRefund(resultPrice);
  }

  return 0;
});

export { totalPrice };
