import { LOCALES } from 'src/constants';

import { toLocaleUrl } from './toLocaleUrl';

type HistoryChangeOptions = {
  omitScrollToTop: boolean;
};

function historyRouteActionWrapper(historyOriginalAction: Function) {
  return function (path: any, stateAndOptions: HistoryChangeOptions) {
    const locale: string = localStorage.getItem('locale') || LOCALES[0];
    const newPath = toLocaleUrl(path, locale);

    // Scroll to top
    if (!stateAndOptions?.omitScrollToTop) {
      window.scrollTo(0, 0);
    }

    // Perform push or replace
    return historyOriginalAction(newPath || path, stateAndOptions);
  };
}

export { historyRouteActionWrapper };
