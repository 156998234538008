import MaskedInput from 'racese-react-text-mask';
import * as React from 'react';

type Props = {
  inputRef?: Function;
  className?: string;
  onChange: AnyFunction;
};

export type Mask = Array<string | RegExp> | ((rawValue: any) => Array<string | RegExp>);

export type Config = {
  currentCaretPosition: number;
  guide: boolean;
  keepCharPositions: boolean;
  pipe: Pipe;
  placeholder: string;
  placeholderChar: string;
  previousConformedValue: string;
  rawValue: string;
};

export type PipeValue =
  | false
  | string
  | {
      value: string;
      indexesOfPipedChars: Array<number>;
    };
export type Pipe = (conformedValue: string, config: Config) => PipeValue;

type Args = {
  mask: Mask;
  guide?: boolean;
  placeholderChar?: string;
  placeholder: string;
  keepCharPositions?: boolean;
  alwaysShowMask?: boolean;
  pipe?: Pipe;
};

function generateMaskedInput(args: Args): React.ComponentType<Props> {
  return ({ inputRef, ...other }: Props) => {
    return <MaskedInput {...other} {...args} />;
  };
}

export { generateMaskedInput };
