import { closeSignIn } from 'modules/tourProfileManagment/utils/signIn';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

type Props = {
  label: string;
  helpText?: string;
  register: UseFormRegisterReturn;
  type?: string;
  icon?: React.ReactNode;
  isError?: boolean;
  placeholder?: string;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  styles?: React.CSSProperties;
  reserveHelpTextSpace?: boolean;
  required?: boolean;
  min?: number | string;
  max?: number | string;
  disabled?: boolean;
  showEmptyHelpText?: boolean;
};

const MilesInput = styled.input`
  color: rgba(255, 255, 255, 0.85);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  background: ${(props) => props.theme.miles.colors.dark.alpha.dark300};
  &:disabled {
    background: ${(props) => props.theme.miles.colors.dark.background[900]} !important;
  }
  border: 1px solid rgba(3, 7, 14, 0.45);
  border-radius: 4px;
  font-family: 'Aeonik';
  width: 100%;
  max-width: 100%;
  font-size: 1rem;

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(24, 111, 250, 0.65);
    border-radius: 4px;
    outline: none;
  }
  width: -webkit-fill-available;
  position: relative;
  ${(props) =>
    props.type === 'date' &&
    `
    ::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 10px; 
      filter: invert(1) brightness(2);
    }
  `}
  ${(props) =>
    props.type === 'number' &&
    `
    ::-webkit-calendar-picker-indicator {
      filter: invert(1) brightness(2);
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  `}
`;

const Label = styled.label<Partial<Props>>`
  height: 16px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 6px;
  color: ${({ isError }) => (isError ? '#FF5050' : 'rgba(255, 255, 255, 0.85);')};
`;

export const HelperText = styled.p<Partial<Props>>`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 6px;
  color: ${({ isError }) => (isError ? '#FF5050' : 'rgba(255, 255, 255, 0.85);')};
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
`;

const Suffix = styled.div`
  margin-left: 16px;
`;

const Prefix = styled.div`
  margin-right: 16px;
`;

const Input = ({
  disabled,
  max,
  min,
  required = false,
  styles,
  isError,
  label,
  register,
  type,
  suffix,
  prefix,
  placeholder,
  helpText,
  showEmptyHelpText = true,
}: Props) => {
  return (
    <Wrapper>
      {label && (
        <Label isError={isError} htmlFor={register.name}>
          {label} {required && '*'}
        </Label>
      )}
      <InputBox>
        {prefix && <Prefix>{prefix}</Prefix>}
        <MilesInput
          disabled={disabled}
          max={max}
          min={min}
          style={styles}
          placeholder={placeholder}
          type={type}
          id={register.name}
          {...register}
        />
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputBox>
      {showEmptyHelpText && <HelperText isError={isError}>{(isError || helpText) && helpText}</HelperText>}
    </Wrapper>
  );
};

type StandaloneInputProps = Partial<Props> & {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const StandaloneInputStyles = styled(MilesInput)`
  width: 100%;
  max-width: 100%;
  margin: 0;
`;

const StandaloneInput = ({
  max,
  min,
  required = false,
  styles,
  isError,
  label,
  name,
  value,
  onChange,
  type,
  suffix,
  prefix,
  placeholder,
  helpText,
}: StandaloneInputProps) => {
  return (
    <Wrapper style={{ maxWidth: '100% !important' }}>
      {label && (
        <Label isError={isError} htmlFor={name}>
          {label} {required && '*'}
        </Label>
      )}
      <InputBox>
        {prefix && <Prefix>{prefix}</Prefix>}
        <StandaloneInputStyles value={value} max={max} min={min} style={styles} placeholder={placeholder} type={type} onChange={onChange} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputBox>
      <HelperText isError={isError}>{(isError || helpText) && helpText}</HelperText>
    </Wrapper>
  );
};

const StyledInputMask = styled(InputMask)`
  color: rgba(255, 255, 255, 0.85);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  background: ${(props) => props.theme.miles.colors.dark.alpha.dark300};
  &:disabled {
    background: ${(props) => props.theme.miles.colors.dark.background[900]} !important;
  }
  border: 1px solid rgba(3, 7, 14, 0.45);
  border-radius: 4px;
  font-family: 'Aeonik';
  width: 100%;
  max-width: 100%;
  font-size: 1rem;

  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(24, 111, 250, 0.65);
    border-radius: 4px;
    outline: none;
  }
  width: -webkit-fill-available;
  position: relative;
`;

const MilesInputMask = (props) => {
  const { label, prefix, suffix, helpText, required, isError, name } = props;
  return (
    <Wrapper style={{ maxWidth: '100% !important' }}>
      {label && (
        <Label isError={isError} htmlFor={name}>
          {label} {required && '*'}
        </Label>
      )}
      <InputBox>
        {prefix && <Prefix>{prefix}</Prefix>}
        <StyledInputMask {...props} {...props.register} mask='99:99:99' />
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputBox>
      <HelperText isError={isError}>{(isError || helpText) && helpText}</HelperText>
    </Wrapper>
  );
};

export { Input, MilesInputMask, StandaloneInput };
