import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment';

import { WeeklyLoad } from '../types';

export const getWeekChangerTextInsights = (weeklyLoadData: WeeklyLoad) => {
  if (isEmpty(weeklyLoadData)) return 'N/A';
  const startOfWeek = weeklyLoadData[0].date ? moment(weeklyLoadData[6].date).startOf('isoWeek') : moment(weeklyLoadData[0].date);
  const endOfWeek = moment(weeklyLoadData[6].date);
  return getWeekChangerText(startOfWeek, endOfWeek);
};

export const getWeekChangerText = (startOfWeek: Moment, endOfWeek: Moment) => {
  if (startOfWeek.format('MMMM YYYY') === endOfWeek.format('MMMM YYYY')) {
    return `${startOfWeek.date()} - ${endOfWeek.format('D MMMM YYYY')}`;
  }
  return `${startOfWeek.format('D MMMM')} - ${endOfWeek.format('D MMMM YYYY')}`;
};
