import { TEAM, ROUTER } from 'src/constants';

import { history } from 'utils';

import { countriesService } from 'services';

import { manageTeamStore } from 'stores';

import { manageTeamService, loadDistancesService } from '../services';

import { distancesStore } from '../stores';

import { setFirstActiveDistance } from './setActiveDistance';
import { subscribeForActiveDistance, unsubscribeForActiveDistance } from './subscribeHistory';
import { subscribeSyncOverlayAndPopup, unsubscribeSyncOverlayAndPopup } from './subscribeOverlay';

async function init(raceId: number) {
  countriesService.loadResources();
  await manageTeamService.loadRaceInfo(raceId);

  const raceModel = manageTeamStore.modelSelectedValue;

  if (!raceModel) {
    return history.replace(ROUTER.NOT_FOUND_PAGE);
  }

  const distanceIds = raceModel
    .modelDistances()
    .filter((el) => el.value.type === TEAM)
    .map((el) => el.value.id);

  loadDistancesService.load(raceId, distanceIds);

  subscribeSyncOverlayAndPopup();
  subscribeForActiveDistance();

  setFirstActiveDistance();
}

function clean() {
  manageTeamStore.removeSelectedValue();
  distancesStore.clear();

  unsubscribeForActiveDistance();
  unsubscribeSyncOverlayAndPopup();
}

export { init, clean };
