import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';
import moment from 'moment';

import { actionMenuStore } from '../../stores';

const isDiplomaExport: IComputedValue<boolean> = computed(() => {
  const distance = actionMenuStore.distanceModel!;
  const result = actionMenuStore.result;

  if (!distance || !result) {
    return false;
  }

  if (!result.start_time || !result.finish_time) {
    return false;
  }

  if (distance && distance.isMode('classic')) {
    return distance.isStatus('finished');
  }

  if (!distance.isMode('classic')) {
    const endsAt = moment(distance.value.ends_at);
    return endsAt.isBefore(moment());
  }

  return false;
});

export { isDiplomaExport };
