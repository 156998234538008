import * as React from 'react';

export const DateCalendar = (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.501465 4C0.501465 3.17157 1.17304 2.5 2.00146 2.5H14.0015C14.8299 2.5 15.5015 3.17157 15.5015 4V14C15.5015 14.8284 14.8299 15.5 14.0015 15.5H2.00147C1.17304 15.5 0.501465 14.8284 0.501465 14V4Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.501465 6.5H15.5015' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.50146 4V0.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.5015 4V0.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
