import isEmpty from 'lodash/isEmpty';
import { Observer } from 'mobx-react';
import { DistanceType } from 'modules/home/types';
import { actionMenuActions } from 'modules/profile/actions';
import React, { FC } from 'react';

import { RaceProfileCard } from 'src/styledComponents/Card';

import { SportType } from 'models';

import { resultStore } from '../../stores';

import { menuItems } from '../ActionMenuItems';
import { NoResults } from '../NoResults';

export const ClassicRaces: FC = () => {
  const _isDataEmpty = (): boolean => {
    return isEmpty(resultStore.classic);
  };

  return (
    <Observer>
      {() => {
        if (_isDataEmpty()) {
          return <NoResults message='profile.onlyVirtualRaces' />;
        }

        return (
          <>
            {resultStore.classic.map((distance: DistanceType) => {
              const sportModel = new SportType(distance.race?.sport as any);
              const sportIcon = distance.race?.sport && sportModel.value.icon;
              const sportTitle = distance.race?.sport && sportModel.title;

              return (
                <RaceProfileCard
                  key={distance.id}
                  distanceId={distance.id}
                  bodyTitle={distance.raceName}
                  distanceName={distance.name}
                  startDate={distance.startDate}
                  endDate={distance.endDate}
                  distanceLength={distance.distanceLength}
                  image={distance.image}
                  location={distance.location}
                  onClickMore={() => actionMenuActions.toggleSwitchOn(distance as any)}
                  place={distance.place}
                  settings={menuItems()}
                  sportIcon={sportIcon ?? ''}
                  sportType={sportTitle ?? ''}
                  teamName={distance.teamName}
                  time={distance.time}
                  total={distance.total}
                  withResults
                  raceResultsLink={distance.raceResultsLink}
                  published={distance.published}
                />
              );
            })}
          </>
        );
      }}
    </Observer>
  );
};
