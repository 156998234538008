import { observable, action, computed, makeObservable } from 'mobx';

import { DEFAULT_LOCALE, AVAILABLE_DYNAMIC_LOCALES, LOCALES_STRING } from 'src/constants';

class Locale {
  @observable value: availableLocales = DEFAULT_LOCALE;

  constructor() {
    makeObservable(this);
  }

  get getLocalesPattern(): string {
    return `/(${LOCALES_STRING})`;
  }

  @action
  setLocale(value: availableLocales): void {
    this.value = value;
  }

  @computed
  get currentAvailableDynamicLocale(): string {
    const currentLocale = this.value;

    if (!AVAILABLE_DYNAMIC_LOCALES.includes(currentLocale)) {
      return DEFAULT_LOCALE;
    }

    return currentLocale;
  }

  touchLocale() {
    return this.value;
  }
}

export { Locale };
export default new Locale();
