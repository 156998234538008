import { getRaceId } from '../utils';

import { counterService } from '../services';

import { totalCountStore, params } from '../stores';

import { currentDistance } from '../derivations';

async function loadTotalCounter() {
  const raceId = getRaceId();

  if (!raceId) {
    return;
  }

  // Do not load total couner if it is not show all
  // Show all feature exclusively
  if (currentDistance.id.get()) {
    return;
  }

  const count = await counterService.load(raceId, params.counterParams());

  totalCountStore.setValue(count);
}

export { loadTotalCounter };
