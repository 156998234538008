import { transaction } from 'mobx';

import { countriesService } from 'services';

import { progressStore } from 'stores';

import { LOAD_RESULTS } from '../constants';

import { generateWaves, generateClasses, generateCheckpoints, generateRegistrationFields, generateGoals, generateSquads } from '../utils';

import { distancesService } from '../services';

import { distancesStore, classesStore, wavesStore, checkpointsStore, registrationFieldsStore, goalsStore, squadsStore } from '../stores';

async function loadBaseData(raceId: number) {
  progressStore.log(LOAD_RESULTS, 'progress');
  countriesService.loadResources();

  const distances = await distancesService.load(raceId);

  const waves = generateWaves(distances);
  const classes = generateClasses(distances);
  const squads = generateSquads(distances);
  const checkpoints = generateCheckpoints(distances);
  const registrationFields = generateRegistrationFields(distances);
  const goals = generateGoals(distances);

  transaction(() => {
    distancesStore.setValues(distances);

    wavesStore.setValues(waves);
    classesStore.setValues(classes);
    checkpointsStore.setValues(checkpoints);
    registrationFieldsStore.setValues(registrationFields);
    squadsStore.setValues(squads);
    goalsStore.setValues(goals);
  });

  progressStore.log(LOAD_RESULTS, 'completed');
}

export { loadBaseData };
