import { observable, action, makeObservable } from 'mobx';

class Squads {
  @observable values: SquadType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  async setValues(values: SquadType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  findSquadByDistance(distanceId: number): SquadType[] {
    return this.values.filter((item) => item.pivot.distance_id === distanceId);
  }

  find(id: number): SquadType | null {
    return this.values.find((item) => item.id === id) || null;
  }
}

export { Squads, Squads as SquadsStore };
