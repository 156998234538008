import { loaderDisposers } from '../../stores';

import { observeScroll } from './observeScroll';
import { observeViewportChange } from './observeViewportChange';

function observe() {
  loaderDisposers.register(observeViewportChange()!);
  loaderDisposers.register(observeScroll());
}

const infiniteScrollObserver = {
  observe,
};

export { infiniteScrollObserver };
