import { calculatePercents, displayLengthForTable, t } from 'utils';

export const calcProgress = (logged: number | null | void, goal: number | null | void): string => {
  if (!goal) {
    return '';
  }

  if (!logged) {
    return '0%';
  }

  if (logged >= goal) {
    return '100%';
  }

  return `${calculatePercents(logged, goal)}%`;
};

export const calcProgressLength = (logged: number | null | void, goal: number | null | void): string => {
  const units = t.staticAsString('profile.registeredTable.head.km');
  if (!goal) {
    return `${logged ? displayLengthForTable(logged) : 0} ${units}`;
  }

  if (!logged) {
    return `0/${displayLengthForTable(goal)} ${units}`;
  }

  return `${displayLengthForTable(logged)}/${displayLengthForTable(goal)} ${units}`;
};
