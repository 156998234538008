import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const FireStreakPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M11.9999 0.585704L5.29282 7.2928C1.58859 10.997 1.58859 17.0028 5.29282 20.707C8.99705 24.4112 15.0028 24.4112 18.707 20.707C21.7022 17.7119 22.2755 13.212 20.427 9.64074C19.532 10.4835 18.3263 11 16.9999 11C14.2385 11 11.9999 8.76142 11.9999 6V0.585704Z'
    fill='currentColor'
  />
);

export const FireStreak = (props: IconProps) => <SvgIcon path={FireStreakPath} {...props} />;
