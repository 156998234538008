import * as React from 'react';

import { ShowDesktop } from 'src/styledComponents/Conditions';

import { DesktopFilters } from './DesktopFilters';

type Props = {
  filterSettings: {
    distanceOptions: NewSelectItemType[];
    classOptions: NewSelectItemType[];
    waveOptions: NewSelectItemType[];
    isGenderAvailable: boolean;
    genderOptions: NewSelectItemType[];
    show: boolean;
  };
};

export const Filters: React.FC<Props> = (props) => {
  return (
    <ShowDesktop>
      <DesktopFilters {...props.filterSettings} />
    </ShowDesktop>
  );
};
