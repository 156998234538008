import { compact } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { t } from 'utils';

import { Distance } from 'models';

import { DistancesStore } from '../../stores';

export const VIRTUAL_DISTANCE = 2;

const showAll = () => ({
  key: 0,
  value: 0,
  label: t.staticAsString('results.showAllDistances'),
});

function generateDistanceSelect(store: DistancesStore, disableShowAllIf: (el: Distance[]) => boolean) {
  const distanceSelectOptions: IComputedValue<NewSelectItemType[]> = computed(() => {
    const filteredDistances = store.filteredModelValues;
    const maxDistanceNameLength = 35;
    const distanceOptions = filteredDistances.map((el) => el.itemForSelect(maxDistanceNameLength));
    const isShowAll = !disableShowAllIf(filteredDistances);

    return compact([...distanceOptions, isShowAll && showAll()]);
  });

  function findDistanceSelectOption(id: number): NewSelectItemType {
    const options = distanceSelectOptions.get();
    return options.find((option) => option.value === +id) || showAll();
  }

  const distanceSelect = {
    options: distanceSelectOptions,
    findOption: findDistanceSelectOption,
  };

  return distanceSelect;
}

export { generateDistanceSelect };
