import * as React from 'react';

import { resize, footerVisibility } from 'utils';

type Options = {
  mobileOnly?: boolean;
};

export const withoutFooter =
  ({ mobileOnly = false }: Options = { mobileOnly: false }) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<any> {
      componentDidMount() {
        let allowHiding = true;

        if (mobileOnly) {
          allowHiding = resize.isMobile();
        }

        if (allowHiding) {
          footerVisibility.hide();
        }
      }

      componentWillUnmount() {
        footerVisibility.show();
      }

      render() {
        return <WrappedComponent {...(this.props as any)} />;
      }
    } as any;
