import axios from 'axios';

import { HEART_RATE_ZONES } from 'src/constants';

import { action, request } from 'utils';

import { GET_HEART_RATE_ZONES } from '../constants';
import { CACHE_KEYS } from '../constants/localStorageKeys';

import { heartRateZonesStore } from '../stores';

export class HeartRateZones {
  @request({ action: GET_HEART_RATE_ZONES })
  private getHeartRateZonesRequest(): Promise<any> {
    return axios.get(HEART_RATE_ZONES);
  }

  @action({ action: GET_HEART_RATE_ZONES })
  async getHeartRateZones() {
    const [isOk, data] = await this.getHeartRateZonesRequest();
    if (localStorage.getItem(CACHE_KEYS.heartRateZones)) {
      const cached = JSON.parse(localStorage.getItem(CACHE_KEYS.heartRateZones)!);
      heartRateZonesStore.setValue(cached);
    }
    if (isOk) {
      heartRateZonesStore.setValue(data.data);
      localStorage.setItem(CACHE_KEYS.heartRateZones, JSON.stringify(data.data));
    }
  }
}

export const heartRateZonesService = new HeartRateZones();
