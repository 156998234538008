import { virtualService, classicService } from '../services';

import { virtualStore, classicStore } from '../stores';

import {
  viewPortCheckerMount,
  viewPortCheckerUnmount,
  viewPortCheckerClassicMount,
  viewPortCheckerClassicUnmount,
  classicOptions,
  virtualOptions,
  viewPortCheck,
} from './viewPort';

export const mountVirtual = async (): Promise<void> => {
  viewPortCheckerMount();
  await virtualService.getVirtualResults();
  viewPortCheck(virtualOptions)();
};

export const unmountVirtual = () => {
  viewPortCheckerUnmount();
  virtualStore.clear();
};

export const mountClassic = (): void => {
  viewPortCheckerClassicMount();
  classicService.getClassicResults();
  viewPortCheck(classicOptions)();
};

export const unmountClassic = () => {
  viewPortCheckerClassicUnmount();
  classicStore.clear();
};
