export const PROFILE_FIELDS = {
  email: '',
  firstname: '',
  lastname: '',
  address: '',
  post_code: '',
  city: '',
  birthday: '',
  telephone: {
    phone: '',
    countryData: {},
  },
  country_id: null,
  gender: '',
  emergency_phone: '',
  emergency_name: '',
  diseases: '',
  blood_type: '',
  union: '',
  shirt_size: '',
  shoe_size: '',
  external_swimrun_id: '',
};

export const BIRTHDAY_MIN_FROM_DATE = '1905-01-01';
export const BIRTHDAY_YEAR_ITEMS_LIST = 200;

export const RACE_UNTIONS: {
  [K in RaceUnionNames]: RaceUnionNames;
} = {
  xtri: 'xtri',
  norseman: 'norseman',
};
