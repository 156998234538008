import isEmpty from 'lodash/isEmpty';
import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Distance } from 'src/models/distance';
import { DistanceHeader } from 'src/modules/racersTable/components';

import { distancesStore } from '../../stores';

import {
  data,
  generateCheckpointId,
  isAnyDistanceHasClasses,
  isAnyDistanceHasSquads,
  isAnyDistanceHasUnion,
  isAnyDistanceHasWaves,
  isAnyDistanceHasCheckpoints,
} from '../../derivations';
import { RightSideSingleRow } from '../Table/RightSideSingleRow';
import { RightSideTeamRow } from '../Table/RightSideTeamRow';

type Props = {
  distance: Distance;
};

const Divider = styled.div`
  height: 30px;
  width: 100%;
`;

const Rows = {
  single: RightSideSingleRow,
  team: RightSideTeamRow,
};

const DistanceTable: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const Component = Rows[distance.value.type];

  const dataGenerator = data.generateDataEntries(distanceId);
  const checkpointGenerator = generateCheckpointId(distanceId);

  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isAnyDistanceHasWaves.get(),
          class: isAnyDistanceHasClasses.get(),
          club: isAnyDistanceHasUnion.get(),
          squad: isAnyDistanceHasSquads.get(),
          time: true,
          diff: true,
          tracker: !distance.isMode('classic'),
          checkpoint: isAnyDistanceHasCheckpoints.get(),
        };

        const data: any[] = dataGenerator.get() || [];
        const checkpointId = checkpointGenerator.get();

        if (isEmpty(data)) return null;

        return (
          <>
            <DistanceHeader name='' />
            {data.map((item) => (
              <Component
                key={`table-right-row-${item.id}`}
                columnsActive={columnsActive}
                value={item}
                distanceId={distanceId}
                checkpointId={checkpointId}
              />
            ))}
            <Divider />
          </>
        );
      }}
    </Observer>
  );
};

export const RightSide = () => {
  return (
    <Observer>
      {() => {
        return (
          <>
            {distancesStore.filteredModelValues.map((distance) => (
              <DistanceTable key={`right-all-${distance.value.id}`} distance={distance} />
            ))}
          </>
        );
      }}
    </Observer>
  );
};
