import { action, computed, observable, makeObservable } from 'mobx';
import moment from 'moment';

import { BACKEND_DATE_FORMAT } from 'src/constants';

import { SearchSerializer } from 'utils';

import { Race as RaceModel } from 'models/race';

import { racesStore } from 'stores';
import { Loadable } from 'stores/loadable';

class PastRace extends Loadable<RacesType, FiltersType> {
  isAllRacesLoaded: boolean;

  declare filters: FiltersType;

  handlers: AnyObject = {
    'location.city': 'like',
    name: 'like',
    'distances.race_length': 'between',
    race_end_date: '<',
    sport_id: 'in',
  };

  filterProcessorsHandlers: AnyObject = {
    race_end_date: '_raceEndDateProcessorHandler',
  };

  @observable
  isPastRacesShown: boolean = false;

  constructor() {
    super();

    makeObservable(this);

    this.filters = {
      orderBy: 'race_date',
      sortedBy: 'desc',
      searchJoin: 'AND',
      limit: 9,
      with: 'distances;location;route',
    };
  }

  @action
  setIsPastRacesShown(value: boolean): void {
    if (this.isPastRacesShown !== value) {
      this.isPastRacesShown = value;
    }
  }

  @computed
  get modelValues(): Array<RaceModel> {
    return this.values.map((race) => {
      const value = new RaceModel(race);
      value.markRaceAsPast();
      return value;
    });
  }

  @computed
  get racesStoreFilters() {
    return racesStore.filters;
  }

  @computed
  get racesPaginationMeta() {
    return racesStore.paginationMeta;
  }

  // If all of the CURRENT races already loaded
  // or in case of init loading
  @computed
  get isLoadNecessary(): boolean {
    const { current_page, total_pages } = this.racesPaginationMeta;
    const isAllCurrentRacesAlreadyLoaded = (current_page || 0) >= (total_pages || 0);

    return isAllCurrentRacesAlreadyLoaded;
  }

  @computed
  get hasMoreToLoad(): boolean {
    if (!this.isAllRacesLoaded) {
      return false;
    }

    const { paginationMeta, page } = this;
    return (paginationMeta.total_pages || 0) > page;
  }

  // Past races can be disabled(hidden block and excluded total) depends on filters
  @computed
  get isPastRacesDisabled(): boolean {
    return this._isRacesFiltersBlocksPastRaces();
  }

  @computed
  get nextPage() {
    return this.paginationMeta.current_page! + 1;
  }

  @computed
  get total(): number {
    if (this.isPastRacesDisabled) {
      return 0;
    }

    return this.paginationMeta.total || 0;
  }

  @computed
  get sortedListValues(): any {
    const res: AnyObject = {};

    this.values.forEach((item, index) => {
      const time = moment(item.race_date).format('MMMM YYYY');
      const race = new RaceModel(item);

      if (index === 0) {
        race.setAsFirstInPastRaces();
      } // Mark first race in list, to render past races label

      if (res[time]) {
        res[time].races.push(race);
      } else {
        res[time] = {
          races: [race],
        };
      }
    });

    return res;
  }

  racesAndPastRacesSortedValues() {
    if (!this.isAllRacesLoaded || this.isPastRacesDisabled) {
      return racesStore.sortedListValues;
    }

    return this.sortedRacesAndPastRacesValues;
  }

  @computed
  get sortedRacesAndPastRacesValues() {
    const racesValues = racesStore.sortedListValues;
    const pastRacesValues = this.sortedListValues;

    const racesKeys = Object.keys(racesStore);
    const pastRacesKeys = Object.keys(pastRacesValues);

    const matches = racesKeys.filter((el) => pastRacesKeys.includes(el));
    const totalValues = { ...racesValues, ...pastRacesValues };

    matches.forEach((matchDate) => {
      totalValues[matchDate] = [...racesValues[matchDate], ...pastRacesValues[matchDate]];
    });

    return totalValues;
  }

  generateFiltersForLoadMore(params: FiltersType) {
    const oldRacesFilters = racesStore.filters;
    const generatedFilters = {
      ...oldRacesFilters,
      ...params,
      ...this.filters,
      search: oldRacesFilters.search,
    };

    return this._generateFilters(generatedFilters);
  }

  generateFiltersForSearch(params: FiltersType) {
    const oldRacesFilters = racesStore.filters;
    const generatedFilters = {
      ...params,
      ...oldRacesFilters,
      ...this.filters,
    };

    return this._generateFilters(generatedFilters);
  }

  _generateFilters(params: FiltersType) {
    const searchModel = new SearchSerializer({
      search: params.search as any,
      handlers: this.handlers,
      nestedJoins: '',
      searchJoin: this.filters.searchJoin as any,
    });

    const mutableFilters = { ...searchModel.search };

    Object.keys(mutableFilters).forEach((filter) => {
      this._processFilters(filter, mutableFilters);
    });

    searchModel.search = mutableFilters;
    searchModel.serialize();

    return {
      ...params,
      ...searchModel.params(),
    };
  }

  // Mutates filters object
  _processFilters(name: string, filters: FiltersType): void {
    const filterProcessorHandlers = this.filterProcessorsHandlers[name];

    const filterProcessorFunction = eval(`this.${this.filterProcessorsHandlers[name]}`);

    if (!filterProcessorHandlers || typeof filterProcessorFunction !== 'function') {
      return;
    }

    return filterProcessorFunction.bind(this)(name, filters);
  }

  _raceEndDateProcessorHandler(name: string, filters: FiltersType): void {
    const todayDatePattern = moment();

    if (!!filters.race_date) {
      return;
    }

    const isEndDateToday = moment(filters.race_end_date as any).isSame(todayDatePattern, 'day');

    if (!filters.race_end_date || !isEndDateToday) {
      return;
    }

    delete filters.race_end_date;
    filters.race_end_date = todayDatePattern.format(BACKEND_DATE_FORMAT);
  }

  _isRacesFiltersBlocksPastRaces = () => {
    const searchModel = racesStore.searchDataModel;
    const filters = searchModel.search;
    const todayDatePattern = moment();

    const isEndDateToday = moment(filters.race_end_date as any).isSame(todayDatePattern, 'day');
    const isIdParam = !!filters.id;

    if (!filters.race_date && isEndDateToday && !isIdParam) {
      return false;
    }

    return true;
  };

  // Overwrite
  addValues(values: Array<RacesType>, page: number, filters: FiltersType) {
    super.addValues(values, page, {
      ...filters,
      ...this.filters,
    });
  }

  // Overwrite
  appendValues(values: Array<RacesType>, page: number, filters: FiltersType) {
    super.appendValues(values, page, {
      ...filters,
      ...this.filters,
    });
  }
}

export default new PastRace();
export { PastRace };
