import { HeaderVariants } from 'modules/header/types';

import { theme } from 'styles';

const shadows = theme.miles.shadows;
export const getShadow = (variant?: HeaderVariants) => {
  switch (variant) {
    case HeaderVariants.milesLanding:
    case HeaderVariants.milesApp:
      return shadows.dark.elevetion1;
    default:
      return '0 2px 6px 0 rgba(0, 0, 0, 0.08)';
  }
};
