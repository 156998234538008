import moment from 'moment';

import { t } from 'utils';

import { Distance } from 'models';

function cannotEditError(distance: Distance) {
  if (!distance) {
    return;
  }

  const from = moment(distance.value.editInformationSettings?.from || distance.value.registration_starts_at).format('DD MMM YYYY');
  const to = moment(distance.value.editInformationSettings?.to || distance.value.registration_ends_at).format('DD MMM YYYY');

  return t.staticAsString('profile.myRaces.cannotEditRegistration', { from, to });
}

export { cannotEditError };
