export const SOCIALS_LINKS_EN = {
  fb: 'https://www.facebook.com/raceid/',
  insta: 'https://www.instagram.com/raceid/',
  linked: 'https://www.linkedin.com/company/raceid',
  yt: 'https://www.youtube.com/channel/UCeDD8MGW5xjJkJf58opqDCQ',
};

export const SOCIALS_LINKS_SV = {
  fb: 'https://www.facebook.com/Raceidsweden',
  insta: 'https://www.instagram.com/raceidsweden/',
  linked: 'https://www.linkedin.com/company/raceid',
  yt: 'https://www.youtube.com/channel/UCeDD8MGW5xjJkJf58opqDCQ',
};
