import { AxiosResponse } from 'axios';
import { matchPath } from 'react-router-dom';

import { TRANSLATIONS_VERSION, API_TRANSLATIONS } from 'src/constants';

import { messages as messagesUtil } from 'utils/messages';
import { MESSAGES_VERSION_KEY } from 'utils/messages/constants';

import { messages as messagesActions } from 'actions/messages';

import { isInitCompleted } from './init';

async function handleVersionChange(response: AxiosResponse<any>) {
  if (response.config?.url === undefined) {
    return;
  }
  const url = response.config.url;
  const rawVersion = response.headers && response.headers[TRANSLATIONS_VERSION];
  const newVersion = parseInt(rawVersion, 10);

  if (!isInitCompleted()) {
    return;
  }

  if (!newVersion) {
    return;
  }

  if (messagesUtil.version.compare(newVersion)) {
    return;
  }

  if (matchPath(API_TRANSLATIONS, url?.split('/').slice(0, -1).join('/'))) {
    return;
  }

  localStorage.setItem(MESSAGES_VERSION_KEY, newVersion.toString());

  messagesActions.reload();
}

export { handleVersionChange };
