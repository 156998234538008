import { exportJobService } from 'services';

import { loadProgress } from 'stores';

const checkInterval = 1000; // 1sec

export const monitorJobId = async (id: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    const timerId = setInterval(async () => {
      const currentJob = loadProgress.loadingJobs[id];
      if (currentJob.isCancelled) {
        clearInterval(timerId);
        reject([false, 'cancelled']);
      }

      const [status, response] = await exportJobService.checkJobId(id);

      // User can cancel request meanwhile awaiting response
      // Lets verify if status wasn't changed to cancelled
      if (currentJob.isCancelled) {
        clearInterval(timerId);
        resolve([false, 'cancelled']);

        return;
      }

      if (!status) {
        loadProgress.connectionInterrupt(id);
        clearInterval(timerId);
        resolve([false, response]);

        return;
      }

      // statuses: queued, executing, finished
      const loadStatus = response?.data?.status;

      if (loadStatus === 'executing') {
        // Progress delta will incrementally increase progress from 0 to 100
        // checkInterval is ms value (1000) and estimateSec is integer like 4
        // Result is progress representation for 1 second
        const progressDelta = checkInterval / 10 / currentJob.estimateSec;

        loadProgress.updateLoadingProgress(id, progressDelta);
      }

      if (loadStatus === 'failed') {
        loadProgress.errorLoading(id);
        clearInterval(timerId);
        reject([false, response]);
      }

      if (loadStatus === 'finished') {
        loadProgress.finishLoadingProgress(id);
        clearInterval(timerId);
        resolve([true, response.data.data.url]);
      }
    }, checkInterval);
  });
};
