import trim from 'lodash/trim';
import { observer } from 'mobx-react';
import { appleLoginAction, facebookLoginAction, googleLoginAction } from 'modules/auth/actions';
import AuthPageTemplate, { ButtonWrapper, FormField, SignUpButton, StyledDivider } from 'modules/auth/components/AuthPageTemplate';
import SocialButtons from 'modules/auth/components/SocialButtons';
import TermsAndConditions from 'modules/auth/components/TermsAndConditions';
import { ModalContainer } from 'modules/auth/container/ModalContainer';
import * as React from 'react';
import styled from 'styled-components';

import { HEADER_SIGNUP, LOGIN, RESOLUTIONS, ROUTER } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Hide, Show } from 'components/condition';

import { Errors, history, t, privateRouteUtil, validation } from 'utils';

import { AuthService } from 'services';

import { errorsStore, progressStore, windowSize } from 'stores';

import { getRedirectLocation } from '../utils';

import { Divider } from '../../../styledComponents/Divider';
import { Link } from '../../../styledComponents/Link';
import { TextField } from '../../../styledComponents/TextField';
import { BodyText } from '../../../styledComponents/Typography';
import { AuthModalTemplate } from './AuthModalTemplate';

const ForgotPasswordBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 9px;
  a {
    color: ${(props) => props.theme.main.colors.clay0};
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
`;

const SignUpBlock = styled(BodyText)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.main.colors.clay2};
  font-size: 20px;
  a {
    color: ${(props) => props.theme.main.colors.clay0};
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  @media (max-width: ${RESOLUTIONS.small}px) {
    margin-bottom: 70px;
    font-size: 14px;
    a {
      font-size: 20px;
    }
  }
`;

const emailType = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('validation.emailRequired' as TranslationLockedKeys),
    },
    email: {
      message: () => t.staticAsString('login.wrongEmail' as TranslationLockedKeys),
    },
  },
};

@withErrorClean(LOGIN)
@observer
class SignIn extends React.Component<LoginPropsType, LoginType> {
  action: string;
  service: AuthService;
  errorsHandler: Errors;
  redirectLink: string;

  passwordRef = React.createRef<HTMLInputElement>();

  static defaultProps = {
    redirectToSignUp: null as any,
    errorsStore: null as any,

    options: {
      displayOnlyCredsFields: false,
      autoFocusPassword: false,
      disabledEmailField: false,
      innerModalWithSocials: false,
    },
  };

  state = { email: '', password: '' };

  constructor(props: LoginPropsType) {
    super(props);
    this.action = LOGIN;
    this.errorsHandler = new Errors(LOGIN);
    this.service = new AuthService();
    this.redirectLink = getRedirectLocation();

    if (props.autoFillCredentials && props.autoFillCredentials.email) {
      this.state = { email: props.autoFillCredentials.email, password: '' };
    }
  }

  onChange = (arg0, e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [e.currentTarget.name]: trim(e.currentTarget.value),
    });
  };

  onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    progressStore.log(LOGIN, 'progress');
    errorsStore.clear(this.action);

    if (validation(this.action, this.state, emailType)) {
      const isLogged = await this.service.login({
        email: trim(this.state.email),
        password: this.state.password,
      });

      if (isLogged && this.props.closeModal) {
        this.props.closeModal();
      }

      if (isLogged && !this.props.closeModal) {
        this.redirectLink ? history.push(this.redirectLink) : privateRouteUtil.back();
      }

      this.props.onAfterSubmit && this.props.onAfterSubmit();
    }
    progressStore.log(LOGIN, 'completed');
  };

  handleForgotClick = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }

    history.push(ROUTER.FORGOT);
  };

  handleRedirect = () => {
    const { redirectToSignUp } = this.props;

    if (redirectToSignUp) {
      return redirectToSignUp(HEADER_SIGNUP);
    } else {
      history.push(ROUTER.SIGNUP);
    }
  };

  hasError = (name: string): string => {
    const err = errorsStore.erroribus[this.action];

    if (err && name === 'password') {
      return t.staticAsString('login.wrongPassword');
    }
    return this.errorsHandler.hasError(name);
  };

  render(): React.ReactNode {
    const { email, password } = this.state;
    const { options } = this.props;

    const isMobile = windowSize.isLessThan('medium');
    errorsStore.erroribus[this.action];

    const getComponent = () => {
      if (options?.displayOnlyCredsFields && !isMobile) return AuthModalTemplate;
      if (options?.innerModalWithSocials && !isMobile) return AuthModalTemplate;
      return AuthPageTemplate;
    };

    const Component = getComponent();
    const isMiles = new URLSearchParams(window.location.search).get('from') === 'miles';

    return (
      <Component
        title={isMiles ? t.staticAsString('login.title-miles') : t.staticAsString('login.title')}
        testAnchorContainer={'SignIn'}
        closeModal={this.props.closeModal}
      >
        <form onSubmit={this.onSubmit}>
          <FormField>
            <TextField
              name='email'
              value={email}
              label={t.staticAsString('login.email')}
              requiredMark
              errorMessage={this.hasError('email')}
              placeholder={t.staticAsString('login.email.placeholder')}
              onChange={this.onChange}
              disabled={options?.disabledEmailField}
            />
          </FormField>
          <FormField>
            <TextField
              name='password'
              type='password'
              value={password}
              label={t.staticAsString('login.password')}
              requiredMark
              errorMessage={this.hasError('password')}
              placeholder={t.staticAsString('login.password')}
              onChange={this.onChange}
              autoFocus={!!options?.autoFocusPassword}
              className='password-input'
            />
            <ForgotPasswordBlock>
              <Link className='link' to={ROUTER.FORGOT} ATN='forgot'>
                {t('login.forgotYourPassword')}
              </Link>
            </ForgotPasswordBlock>
          </FormField>

          <ButtonWrapper className='login-btn' size='large' variant='primary' type='submit' fullWidth ATN='submit'>
            {t('login.continue')}
          </ButtonWrapper>
        </form>

        <TermsAndConditions />
        <Show if={!options?.displayOnlyCredsFields || Boolean(options?.innerModalWithSocials)}>
          <SocialButtons
            onFacebookClick={(responseData) => facebookLoginAction(responseData, this.props.closeModal, this.redirectLink)}
            onGoogleClick={(responseData) => googleLoginAction(responseData, this.props.closeModal, this.redirectLink)}
            onAppleClick={(responseData) => appleLoginAction(responseData, this.props.closeModal, this.redirectLink)}
          />
        </Show>
        <StyledDivider className='divider'>
          <Divider light />
          <span>{t('login.or')}</span>
          <Divider light />
        </StyledDivider>
        <Hide if={!!options?.displayOnlyCredsFields || Boolean(options?.innerModalWithSocials)}>
          <SignUpBlock variant='body2'>
            <span>{t('login.dontHaveAccShort')}</span>
            &nbsp;
            <Link to={ROUTER.SIGNUP} ATN='signup'>
              <SignUpButton className='login-btn' size='small' variant='primary'>
                {t('navigation.signUpLink')}
              </SignUpButton>
            </Link>
          </SignUpBlock>
        </Hide>
        <ModalContainer />
      </Component>
    );
  }
}

export { SignIn };
