import { isEmpty, isFunction } from 'lodash';
import moment from 'moment';

import { RESULT_DATE_FORMATE } from 'src/constants';

class SearchSerializer implements ISearchProcessor {
  // @ts-ignore
  search: FiltersType;
  searchJoin: string;
  handlers: {
    [K in string]: string;
  };
  // @ts-ignore
  serializedSearch: string;
  nestedJoins: string | nil; // TODO, remove
  // @ts-ignore
  serializedSearchHandlers: string;

  constructor({
    search,
    handlers,
    nestedJoins,
    searchJoin,
  }: {
    search: FiltersType | string;
    handlers: {
      [K in string]: string;
    };
    nestedJoins?: string;
    searchJoin?: string;
  }) {
    this.handlers = handlers;
    this.nestedJoins = nestedJoins;
    this.searchJoin = searchJoin || 'AND';

    if (typeof search === 'string') {
      this.serializedSearch = search;
      this.deserialize();
    } else {
      this.search = typeof search === 'object' ? search : {};
      this.serialize();
    }
  }

  serialize() {
    this.serializedSearch = this.serializeSearch();
    this.serializedSearchHandlers = this.serializeSearchHandlers();
  }

  deserialize() {
    this.search = this.deserializeSearch();
  }

  appendFilters(search: FiltersType) {
    this.search = { ...this.search, ...this._checkEmptyParams(search) } as any;
    this.serialize();
  }

  addFilters(search: FiltersType) {
    this.search = { ...this._checkEmptyParams(search) } as any;
    this.serialize();
  }

  clearFilters(): void {
    this.search = {};
    this.serialize();
  }

  serializeSearch(): string {
    const params = this.search;
    if (params) {
      return Object.keys(params).reduce((acc, param) => {
        let value = params[param];
        if (this._isParamEmpty(param, value) || !this.handlers[param]) {
          if (!this.handlers[param]) {
            console.warn(`Handler for param [${param}] is missing`);
          }
          return acc;
        }

        let filter = '';
        const serializedValue = this._serializeValue(value);
        filter = `${param}:${serializedValue ? serializedValue : ''}`;
        if (!acc) {
          return filter;
        }
        return `${acc};${filter}`;
      }, '');
    }
    return '';
  }

  serializeSearchHandlers(): string {
    const params = this.search;
    const search = Object.keys(params);
    return search.reduce((acc, param) => {
      if (!params[param] || !this.handlers[param]) {
        return acc;
      }
      const filterHandler = `${param}:${this.handlers[param]}`;
      if (!acc) {
        return filterHandler;
      }
      return `${acc};${filterHandler}`;
    }, '');
  }

  deserializeSearch(params?: string): FiltersType {
    let values = params || this.serializedSearch;

    if (params) {
      values = values.replace(/^\W/g, '');
    }

    return values.split(';').reduce<any>((acc, val) => {
      // @ts-ignore
      if (val || val === 0) {
        const [key, value] = val.split(':');
        acc[key] = this._deserializeValue(value);
      }
      return acc;
    }, {});
  }

  params():
    | {
        search: string;
        searchFields: string;
        searchJoin: string;
      }
    | {
        searchJoin: string;
      } {
    const { serializedSearch, serializedSearchHandlers, searchJoin } = this;
    if (!serializedSearch) {
      return { searchJoin: searchJoin };
    }

    return {
      search: serializedSearch,
      searchFields: serializedSearchHandlers,
      searchJoin: searchJoin,
    };
  }

  checkEmptyParams(params: FiltersType) {
    return this._checkEmptyParams(params);
  }

  clone(): SearchSerializer {
    return new SearchSerializer({
      search: this.search,
      handlers: this.handlers,
      nestedJoins: this.nestedJoins as any,
      searchJoin: this.searchJoin,
    });
  }

  isSearchEmpty(comparator: ((search: FiltersType) => boolean) | null): boolean {
    if (comparator && isFunction(comparator)) {
      return comparator(this.search);
    }

    return !isEmpty(this.search);
  }

  _isParamEmpty(_paramName: string, value: FilterType): boolean {
    if (!value) {
      return true;
    }
    if (!(value instanceof Date) && value instanceof Array) {
      const isInvalid = value.every((filter) => !filter);
      if (isInvalid) {
        return true;
      }
    }
    return false;
  }

  _serializeValue(value: FilterType): string | null {
    if (value instanceof Date) {
      return this._serializeDate(value);
    } else if (value instanceof Array) {
      return this._serializeArray(value);
    } else if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return `${value}`;
    } else {
      return value;
    }
  }

  _serializeDate(value: Date): string {
    return moment(value).format(RESULT_DATE_FORMATE);
  }

  _serializeArray(value: Array<FilterType>): string | null {
    return `${value.map((filter) => this._serializeValue(filter)).join(',')},`;
  }

  _deserializeValue(value: string) {
    if (/.*,.*/.test(value)) {
      return this._deserializeArray(value);
    } else if (/^\d{4,4}-\d{1,2}-\d{1,2}$/.test(value)) {
      return this._deserializeDate(value);
    } else if (parseInt(value, 10) == (value as any)) {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }

  _deserializeDate(value: string): Date {
    const [year, month, day] = value.split('-');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  }

  _deserializeArray(value: string): Array<FilterType> | null {
    const valuesArray: FilterType = [];

    value.split(',').map((filter) => {
      if (filter) {
        valuesArray.push(this._deserializeValue(filter));
      }
    });

    return valuesArray;
  }

  _checkEmptyParams(search: FiltersType): Object {
    const keys = Object.keys(search);
    return keys.reduce((formated, item) => {
      // @ts-ignore
      if (search[item] && search[item].toString()) {
        return {
          ...formated,
          [item]: search[item],
        };
      }
      return formated;
    }, {});
  }
}

export { SearchSerializer };
