import { Observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { progressStore } from 'src/stores';

import FormControls from '../../components/FormControls';
import { MoreInfoForm } from '../../components/MoreInfo';

import { MORE_INFO_FORM_ID } from '../../constants';

import { mount, unmount, updateUserProfile, updateInfoField } from '../../actions';

import { userProfileStore } from '../../stores';

import { selectedSportsIds } from '../../derivations/selectedSports';
import { sportTypesList } from '../../derivations/sportsList';
import { useDebounceLiveUpdate } from '../../hooks';

type Props = {};

const FormWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  form > div:not(:last-child) {
    margin-bottom: 32px;
  }
  form span[disabled] {
    color: inherit;
  }
`;

export const MoreInfo: FC<Props> = () => {
  const debounceLiveUpdate = useDebounceLiveUpdate(updateInfoField);

  useEffect(() => {
    mount();
    return () => {
      unmount();
    };
  }, []);

  const handleLiveUpdate = (value: AnyObject) => debounceLiveUpdate(value);

  const handleOnBlur = ({ target }) => {
    const changes = { name: [target?.name], value: target?.value };

    handleLiveUpdate(changes);
  };

  return (
    <Observer>
      {() => {
        const isLoadingProcess = progressStore.isLoading(MORE_INFO_FORM_ID);
        if (!userProfileStore.profile) return null;

        return (
          <TestAnchorContext.Provider value={{ container: 'Profile.More-settings' }}>
            <FormWrapper>
              <MoreInfoForm
                handleLiveUpdate={handleLiveUpdate}
                handleOnBlur={handleOnBlur}
                checkboxList={sportTypesList.get()}
                checked={selectedSportsIds.get()}
                isLoadingProcess={isLoadingProcess}
              />
              <FormControls onSubmit={updateUserProfile} />
            </FormWrapper>
          </TestAnchorContext.Provider>
        );
      }}
    </Observer>
  );
};
