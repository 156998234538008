function normalize(value: string | null): string {
  if (!value) {
    return '';
  }

  // COMPATIBILITY decomposition
  // replace all unicode special chars
  // Example
  // ①  -> 1,
  // i⁹ -> i9
  let normalizedValue = value.normalize('NFKD');

  // CANONICAL decomposition
  // split chars with graphemes to multiple chars
  // Example
  // 'ü'.length -> 1,
  // 'ü'.normalize('NFD').length -> 2
  normalizedValue = normalizedValue.normalize('NFD');

  // Remove all redunant unicode chars
  normalizedValue = normalizedValue.replace(/[\u0300-\u036f]/g, '');

  // Replace chars that won't be normalized
  return normalizeSpecialChars(normalizedValue);
}

function normalizeSpecialChars(value: string) {
  let normalizedValue = value;
  normalizedValue = normalizedValue.replace(new RegExp('æ', 'gi'), 'ae');
  normalizedValue = normalizedValue.replace(new RegExp('œ', 'gi'), 'oe');
  normalizedValue = normalizedValue.replace(new RegExp('ø', 'gi'), 'o');

  return normalizedValue;
}

export { normalize };
