import { action, computed, observable, makeObservable } from 'mobx';
import { mainConditions } from 'modules/registration/selectors/mainConditions';

import { RegistrationStepEnum } from '../constants';

import { currentPaymentStore, isPaymentFrameOpened } from '.';
import { stepperSelector } from '../selectors/stepper';

class StepperStore {
  @observable
  currentStep: RegistrationStepEnum | null;

  @observable
  completedSteps: RegistrationStepEnum[];

  constructor() {
    makeObservable(this);
  }

  @action
  init(initStep: RegistrationStepEnum = RegistrationStepEnum.registration) {
    this.currentStep = initStep;
    this.completedSteps = [];
  }

  /**
   * @description
   * change step only if it is already completed
   */
  @action
  changeStepIfPossible(step: RegistrationStepEnum) {
    if (isPaymentFrameOpened.value || currentPaymentStore.isRedirectPaymentCompleted) {
      return;
    }

    const prevStepToTargetStep = this.getPrevStep(step);
    if (this.isCompleted(step) || mainConditions.isStripeRedirect()) {
      this.currentStep = step;
    }

    if (prevStepToTargetStep && this.isCompleted(prevStepToTargetStep)) {
      this.currentStep = step;
    }
  }

  @action
  nextStep() {
    if (!this.currentStep) {
      return;
    }

    const nextStep = this.getNextStep(this.currentStep);

    if (nextStep) {
      this.currentStep = nextStep;
    }
  }

  /**
   * @description
   * mark step with check
   */
  @action
  completeStep() {
    if (!this.currentStep) {
      return;
    }

    this.completedSteps.push(this.currentStep);
  }

  /**
   * @description
   * unmark step with check
   */
  @action
  uncompleteStep(step: RegistrationStepEnum) {
    this.completedSteps = this.completedSteps.filter((completedStep) => step !== completedStep);
  }

  @action
  clear() {
    this.currentStep = null;
    this.completedSteps = [];
  }

  findIndex(step: RegistrationStepEnum): number {
    return stepperSelector.steps.get().findIndex((value) => step === value);
  }

  /**
   * @description
   * is step marked with check
   */
  isCompleted(step: RegistrationStepEnum) {
    return Boolean(this.completedSteps.find((completedStep) => step === completedStep));
  }

  /**
   * @description
   * is step currently active
   */
  isActive(step: RegistrationStepEnum) {
    return this.currentStep === step;
  }

  /**
   * @description
   * get next step relatively to passed step
   */
  getNextStep(step: RegistrationStepEnum): RegistrationStepEnum | null {
    const steps = stepperSelector.steps.get();
    const index = steps.findIndex((el) => el === step);

    return steps[index + 1] || null;
  }

  /**
   * @description
   * get prev step relatively to passed step
   */
  getPrevStep(step: RegistrationStepEnum): RegistrationStepEnum | null {
    const steps = stepperSelector.steps.get();
    const index = steps.findIndex((el) => el === step);

    return steps[index - 1] || null;
  }

  @computed
  get isLastStep(): boolean {
    if (!this.currentStep) {
      return false;
    }

    return this.findIndex(this.currentStep) + 1 === stepperSelector.steps.get().length;
  }
}

export { StepperStore };
