import { transaction } from 'mobx';

import { countriesService } from 'services';

import { progressStore } from 'stores';

import { LOAD_STARTLIST } from '../constants';

import { generateWaves, generateClasses, generateRegistrationFields, generateSquads } from '../utils';

import { distancesService } from '../services';

import { distancesStore, classesStore, wavesStore, registrationFieldsStore, squadsStore } from '../stores';

async function loadBaseData(raceId: number) {
  progressStore.log(LOAD_STARTLIST, 'progress');
  countriesService.loadResources();

  const distances = await distancesService.load(raceId);

  const waves = generateWaves(distances);
  const classes = generateClasses(distances);
  const squads = generateSquads(distances);
  const fields = generateRegistrationFields(distances);

  transaction(() => {
    distancesStore.setValues(distances);
    wavesStore.setValues(waves);
    classesStore.setValues(classes);
    squadsStore.setValues(squads);
    registrationFieldsStore.setValues(fields);
  });

  progressStore.log(LOAD_STARTLIST, 'completed');
}

export { loadBaseData };
