import { manageTeamStore, overlayStore, windowSize } from 'stores';

function editMemberPopup(distanceId: number, racerId: number) {
  const distanceModel = manageTeamStore.getNeededDistance(distanceId) || {};

  const team = ((distanceModel.value || {}).teams || [])[0] || {};
  const member = (team.racers || []).find((member) => member.id === racerId) || {};

  manageTeamStore.openAddModal();
  manageTeamStore.changeSelectedMember(member);

  if (windowSize.notObservableIsLessThan('medium')) {
    return;
  }

  overlayStore.show();
}

export { editMemberPopup };
