class RegistrationForms {
  personalForms: Array<AnyObject> = [];

  changeForms(forms: Array<Object>) {
    this.personalForms = forms;
  }

  clean() {
    this.personalForms = [];
  }
}

export { RegistrationForms };
export default new RegistrationForms();
