import * as React from 'react';

export const ArrowRight = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.33301 2.66699L10.5592 7.74907C10.5932 7.78204 10.6201 7.82121 10.6385 7.86432C10.6569 7.90744 10.6663 7.95365 10.6663 8.00033C10.6663 8.047 10.6569 8.09321 10.6385 8.13633C10.6201 8.17944 10.5932 8.21861 10.5592 8.25158L5.33301 13.3337'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
