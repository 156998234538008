import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const StarPath = (
  <path
    d='M4.5 0.75L6.02963 3.35796L9 3.99671L6.975 6.24727L7.28115 9.25L4.5 8.03296L1.71885 9.25L2.025 6.24727L0 3.99671L2.97037 3.35796L4.5 0.75Z'
    fill='currentColor'
  />
);

export const Star = (props: IconProps) => <SvgIcon path={StarPath} viewBox={'0 0 9 10'} {...props} />;
