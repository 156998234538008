import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_SEGMENT } from 'src/constants';

import { request, action } from 'utils';

class SegmentService {
  @request({ action: shortid() })
  request(racerId: number, id: number): any {
    const url = generatePath(API_URL + API_SEGMENT, { racerId, id });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async load(racerId: number, id: number): Promise<null | Segment> {
    const [, response] = await this.request(racerId, id);

    return response.data.data || null;
  }
}

export { SegmentService };
