import { Observer } from 'mobx-react';
import * as React from 'react';

import { resultsPresentation } from '../../derivations';
import type { LResult } from '../../types';

type Props = {
  result: LResult;
  checkpointId: number | nil;
  distanceId: number;
};

function LeaderTime({ result, checkpointId, distanceId }: Props) {
  const generator = resultsPresentation.generateLeaderDiff(result, checkpointId, distanceId);

  return (
    <Observer>
      {() => {
        return <>{generator.get()}</>;
      }}
    </Observer>
  );
}

export { LeaderTime };
