import { Observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Swipe from 'react-easy-swipe';

import { Loading } from 'src/components/loading';
import { Modal } from 'src/components/modal';
import { closeDeleteFormModal, onDelete } from 'src/modules/transferRegistration/actions';
import {
  Wrapper,
  DraggableStroke,
  Header,
  StyledDivider,
  Content,
  Controls,
  ScrollBarWrapper,
} from 'src/modules/transferRegistration/components/styled';
import { PRELOADER, LOADER } from 'src/modules/transferRegistration/constants';
import { windowSize } from 'src/stores';
import { Button } from 'src/styledComponents/Button';
import { ShowMobile } from 'src/styledComponents/Conditions';
import { blockBodyScroll, t, unblockBodyScroll } from 'src/utils';

import { BlockRecipientInfo } from './Fields';
import { Title } from './Title';

type Props = {
  open: boolean;
};

const DeleteModal = ({ open }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSwipeEnd, setIsSwipeEnd] = useState(false);

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const handleTransitionEnd = () => {};

  useEffect(() => {
    blockBodyScroll();
    return () => {
      unblockBodyScroll();
    };
  }, []);

  return (
    <TestAnchorContext.Provider value={{ container: 'Transfer-Registration-Popup-Form' }}>
      <Observer>
        {() => {
          const isMobile = windowSize.isLessThan('large');

          return (
            <Modal open={open}>
              <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={handleTransitionEnd} isDesktop={false} maxHeight={800}>
                <ScrollBarWrapper isDesktop={false}>
                  <ShowMobile>
                    <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
                      <DraggableStroke />
                    </Swipe>
                  </ShowMobile>
                  <Loading action={PRELOADER} opacity={1} />
                  <Loading action={LOADER} opacity={1} />

                  <Header>
                    <Title isMobile={isMobile} title={t.staticAsString('transferRegistration.form.titleDelete')} />
                  </Header>

                  <StyledDivider light />

                  <Content>
                    <BlockRecipientInfo text='transferRegistration.form.deleteText' isNeedPadding={true} />
                  </Content>

                  <Controls>
                    <Button
                      variant='secondary'
                      size='medium'
                      ATN='cancel'
                      onClick={closeDeleteFormModal}
                      className={isMobile ? 'margin-right' : ''}
                    >
                      {t.staticAsString('settings.cancel')}
                    </Button>

                    <Button variant='primary' size='medium' fullWidth={isMobile} ATN='submit' onClick={onDelete}>
                      {t.staticAsString('transferRegistration.form.delete')}
                    </Button>
                  </Controls>
                </ScrollBarWrapper>
              </Wrapper>
            </Modal>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};

export { DeleteModal };
