import { Observer } from 'mobx-react';
import { ResultsForm } from 'modules/profile/containers';
import { resultStore } from 'modules/profile/stores';
import * as React from 'react';
import { FC } from 'react';

import { Modal } from 'components/modal';

import { reloadDistance } from '../actions';

type Props = {};

export const FormProvider: FC<Props> = () => (
  <Observer>
    {() => {
      return (
        <Modal open={resultStore.isOpenEditResult}>
          <ResultsForm loadHandler={reloadDistance} />
        </Modal>
      );
    }}
  </Observer>
);
