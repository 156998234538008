import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { distanceSelector } from '../mappedData';

const classOptions: IComputedValue<any> = computed(() => {
  const distance = distanceSelector.model.get();

  if (!distance) {
    return [];
  }

  return distance.classes.map((c) => c.toSelectItem());
});

export { classOptions };
