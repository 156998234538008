import * as React from 'react';

export const DownloadReceipt = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.66699 7.33333H12.0003' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.66699 11.3333H10.0003' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6.66699 15.3333H10.0003' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 7.33333H17.3333' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.6822 16.0569C13.8746 16.3099 14.1252 16.5127 14.4127 16.6481C14.7002 16.7835 15.0162 16.8475 15.3338 16.8347C16.3462 16.8347 17.1667 16.2187 17.1667 15.4596C17.1667 14.7004 16.3462 14.0844 15.3338 14.0844C14.3213 14.0844 13.5 13.4684 13.5 12.7084C13.5 11.9484 14.3213 11.3333 15.3338 11.3333C15.6513 11.3206 15.9672 11.3847 16.2548 11.5201C16.5423 11.6555 16.7929 11.8582 16.9853 12.1111'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M15.334 16.8347V17.7502' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.334 10.4178V11.3333' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M16 22C16.2039 21.6505 16.4959 21.3605 16.8468 21.159C17.1977 20.9575 17.5953 20.8514 18 20.8514C18.4047 20.8514 18.8023 20.9575 19.1532 21.159C19.5041 21.3605 19.7961 21.6505 20 22V2C19.7955 2.34912 19.5035 2.6388 19.1527 2.84038C18.8019 3.04196 18.4046 3.14846 18 3.14933C17.5954 3.14855 17.198 3.04209 16.8472 2.8405C16.4964 2.6389 16.2044 2.34918 16 2C15.7955 2.34912 15.5035 2.6388 15.1527 2.84038C14.8019 3.04196 14.4046 3.14846 14 3.14933C13.5954 3.14855 13.198 3.04209 12.8472 2.8405C12.4964 2.6389 12.2044 2.34918 12 2C11.7955 2.34912 11.5035 2.6388 11.1527 2.84038C10.8019 3.04196 10.4046 3.14846 10 3.14933C9.59541 3.14855 9.19803 3.04209 8.84724 2.8405C8.49644 2.6389 8.20439 2.34918 8 2C7.79555 2.34912 7.50348 2.6388 7.1527 2.84038C6.80191 3.04196 6.40458 3.14846 6 3.14933C5.59541 3.14855 5.19803 3.04209 4.84724 2.8405C4.49644 2.6389 4.20439 2.34918 4 2V22C4.20394 21.6505 4.49591 21.3605 4.84682 21.159C5.19774 20.9575 5.59534 20.8514 6 20.8514C6.40466 20.8514 6.80226 20.9575 7.15318 21.159C7.50409 21.3605 7.79606 21.6505 8 22C8.20394 21.6505 8.49591 21.3605 8.84682 21.159C9.19774 20.9575 9.59534 20.8514 10 20.8514C10.4047 20.8514 10.8023 20.9575 11.1532 21.159C11.5041 21.3605 11.7961 21.6505 12 22'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 22C12.2039 21.6505 12.4959 21.3605 12.8468 21.159C13.1977 20.9575 13.5953 20.8514 14 20.8514C14.4047 20.8514 14.8023 20.9575 15.1532 21.159C15.5041 21.3605 15.7961 21.6505 16 22'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
