import * as React from 'react';
import shortId from 'shortid';
import styled from 'styled-components';

import { Title } from 'src/modules/footer/components';
import { Link as LinkType } from 'src/modules/footer/types';
import { Link } from 'src/styledComponents/Link';

import { t } from 'utils';

type Props = {
  title: string;
  links: Array<LinkType>;
};

const LinkStyles = `
  font-size: 14px;
  font-weight: 500;
  padding: 16px 0 0 0;
  display: block;
  text-decoration: none;
  width: fit-content;
  text-transform: uppercase;
`;

const ExternalLink = styled.a`
  ${LinkStyles}
  color: ${(props) => props.theme.main.colors.clay6};
  font-family: ${(props) => props.theme.main.font};

  .active,
  :hover {
    color: ${(props) => props.theme.main.colors.auroraLemon};
  }
`;

const NavLink = styled(Link)`
  ${LinkStyles}

  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.main.colors.clay6};
  font-family: ${(props) => props.theme.main.font};

  .active,
  :hover {
    color: ${(props) => props.theme.main.colors.auroraLemon};
  }
`;

export const LinksBlock = (props: Props) => {
  const { title, links } = props;
  const external = (link: LinkType & RequiredTestAnchorProps, key: string) => (
    <ExternalLink key={key} href={link.link} target='_blank' {...testAnchors.useLink(link.ATN)}>
      {t.staticAsString(link.title as any)}
    </ExternalLink>
  );
  const internal = (link: LinkType & RequiredTestAnchorProps, key: string) => (
    <NavLink key={key} to={link.link} ATN={link.ATN}>
      {link.icon && link.icon}
      {t.staticAsString(link.title as any)}
    </NavLink>
  );

  return (
    <>
      <Title>{t.staticAsString(title as any)}</Title>
      {links.map((link: LinkType & RequiredTestAnchorProps, index) => {
        const key = 'uniqLinkKeyLinksBlock' + index;
        return link.isExternal ? external(link, key) : internal(link, key);
      })}
    </>
  );
};
