import { preloader, initDistanceLoader } from '../../utils';

import { infiniteScrollObserver, observeFilters } from '../../reactions';
import { loadTotalCounter } from '../loadTotalCounter';
import { loader } from '../loader';
import { refreshApp } from './refreshApp';

async function initData() {
  preloader.enable();
  const appVersion = refreshApp();

  await Promise.all([loader.loadUntilViewport(appVersion), loadTotalCounter()]);

  observeFilters();

  infiniteScrollObserver.observe();
  preloader.disable();
  initDistanceLoader.disable();
}

export { initData };
