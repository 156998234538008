export const ENVIRONMENTS = {
  development: 'development',
  staging: 'staging',
  production: 'production',
};
export const APPLE_CLIENT_ID = 'com.raceid.client';

export const GOOGLE_CLIENT_ID_KEYS = {
  development: '156649242304-cdm6fk1rv45m01c8pnb90k8581sq09kb.apps.googleusercontent.com',
  staging: '487308708333-7a73routh6bmi7clslpp0skppd82p5f2.apps.googleusercontent.com',
  production: '327852842123-rqul21g3vort5u7kfcmv8l1ihfff6iml.apps.googleusercontent.com',
};

export const GTM_ID = 'GTM-PTFDK7Z';

export const AMPLITUDE_KEYS: { [key in Environment]: string } = {
  staging: 'be5e6023609a6e89636f737aa5c5e7e0',
  development: '1eebfa17c165706d78e8c127465146d0',
  production: '5a860a561dec7f4cada8d01b9075c59c',
};

/**
 * Temp GTM container added for the OTILLO organizer  RP-6333
 */
export const GTM_ID_OTILLO = 'GTM-KKX2NT7';

export const IOS_APP_ID = 'se.RaceID.ios://';

/**
 * Intercom id https://www.intercom.com/
 */
export const INTERCOM_APP_ID = 'gdcf2ieg';

/**
 * Stripe publishable API key
 */

export const STRIPE_PUBLIC_KEYS: { [key in Environment]: string } = {
  staging: 'pk_test_51KxVHNLJ5IZBFF4CLs207CviAkqp0yU78i1aC8r8Db6zXGhiuHp1TlTUybymLRGU2mGnFV9olmnVSYk3ggmOwLXo0012PzlTMi',
  development: 'pk_test_51KxTKbCXwdtqnR11HmtK0ArFtaRVXtmggmTsqgm0KpWrvFBi3K5KOJYcR1WITp8TLWl0t4K9CcrVz3FdWmNWQwjZ00jBbsaYkp',
  production: 'pk_live_djO5HIgPHjYpTiMmpRUgrHum00lZoLAQ84',
};

export const STRIPE_MILES_PUBLIC_KEYS: { [key in Environment]: string } = {
  staging: 'pk_test_51Nnd9lK5ZFtmIMtkzKKiHSq5BP2fRIkAh3XHiIl16tLJ8IXiyEU1lNnvUQMUlakRkag6jV0xCWMMeDN0HjO1wPEZ00S8L5OydF',
  development: 'pk_test_51Nnd9lK5ZFtmIMtkzKKiHSq5BP2fRIkAh3XHiIl16tLJ8IXiyEU1lNnvUQMUlakRkag6jV0xCWMMeDN0HjO1wPEZ00S8L5OydF',
  production: 'pk_live_51NhS1dEvVyHtiYdMWiMFEfsON7UjJxYt6w2ljn9fHwZM7mL0VzeKrzuY0CDhgysJAooy5mhM5WaWEHTIstrlCTHp00E0cKqlmo',
};

export const ADMIN_HOSTS: { [key in Environment]: string } = {
  production: 'https://admin.raceid.com',
  staging: 'https://admin.staging.raceid.com',
  development: 'https://admin.dev.raceid.com',
};
