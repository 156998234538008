import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { dataStore } from '../../stores';

import type { LResult } from '../../types';

/*
 * Collect loaded pages per distance
 * for example, loaded data with pages - [1,2,3,null,5]
 * page 4 is not loaded yet.
 * Collect only loaded pages, and interrupt at first not loaded pages
 * display data only for: [1,2,3]
 */
function generateDataEntries(distanceId: number): IComputedValue<LResult[]> {
  return computed(() => {
    const pages = dataStore.pages(distanceId);

    if (!pages) {
      return [];
    }

    let values: any[] = [];

    pages.some((page) => {
      const pageValues = dataStore.fetch(distanceId, page);

      if (!pageValues) {
        return true;
      }

      values.push.apply(values, pageValues);
      return false;
    });

    return values;
  });
}

export { generateDataEntries };
