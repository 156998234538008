import { Observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';
import { Spinner } from 'components/spinner';

import { t, calculatePercents, displayLengthByGoal, getUnitsByGoal } from 'utils';

import { VIEWPORT_OBSERVABLE } from '../constants';

import { onEditSegment } from '../actions';

import { segmentsStore } from '../stores';

import { TrackingSource } from './TrackingSource';

type Props = {};

const Wrapper = styled.div`
  padding: 20px 8px 24px;
  border-top: 1px solid ${(props) => props.theme.main.colors.clay4};

  .info-box {
    display: flex;
    justify-content: space-between;

    .info-item {
      display: flex;
      flex-direction: column;
    }

    .date {
      min-width: 80px;
      max-width: 80px;
    }

    .length {
      min-width: 55px;
      max-width: 55px;
    }

    .time,
    .percents {
      min-width: 65px;
      max-width: 65px;
    }

    .percents {
      align-items: end;
    }
  }

  .control-panel {
    display: flex;
    padding-top: 16px;
    align-items: center;

    div:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export const MobileView: React.FC<Props> = () => {
  const renderRows = (): React.ReactNode => {
    const goalModel = segmentsStore.goalModel;
    if (!goalModel) return null;

    return segmentsStore.list.map((segment: any) => {
      const date = moment(segment.distance_logged_at).format('DD/MM/YY');
      const distanceLength = displayLengthByGoal(segment.distance_logged, segmentsStore.distance?.goal, true);
      const percents = calculatePercents(segment.distance_logged, goalModel.value.goal_max_length);
      const percentsToDisplay = segmentsStore.distance?.goal?.goal_max_length ? `${percents}%` : '';

      return (
        <Wrapper key={`segment-list:${segment.id}`} className='segment-item'>
          <div className='info-box'>
            <div className='info-item date'>
              <Utility variant='u3' weight='medium'>
                {t('profile.registeredTable.head.date')}
              </Utility>

              <Utility variant='u2' weight='bold'>
                {date}
              </Utility>
            </div>
            <div className='info-item length'>
              <Utility variant='u3' weight='medium'>
                {t(getUnitsByGoal(segmentsStore.distance?.goal) as TranslationLockedKeys)}
              </Utility>

              <Utility variant='u2' weight='bold'>
                {distanceLength}
              </Utility>
            </div>
            <div className='info-item time'>
              <Utility variant='u3' weight='medium'>
                {t('profile.registeredTable.head.time')}
              </Utility>

              <Utility variant='u2' weight='bold'>
                {segment.time_result}
              </Utility>
            </div>
            <Show if={!!percentsToDisplay}>
              <div className='info-item percents'>
                <Utility variant='u3' weight='medium'>
                  {t('profile.registeredTable.head.completed')}
                </Utility>

                <Utility variant='u2' weight='bold'>
                  {percentsToDisplay}
                </Utility>
              </div>
            </Show>
          </div>

          <div className='control-panel'>
            <TrackingSource link={segment.activity_link} segmentId={segment.id} onEdit={onEditSegment(segment.id)} />
          </div>
        </Wrapper>
      );
    });
  };

  return (
    <Observer>
      {() => {
        const goalModel = segmentsStore.goalModel;
        if (!goalModel) return null;

        return (
          <>
            {renderRows()}
            <Show if={segmentsStore.hasMore}>
              <div id={VIEWPORT_OBSERVABLE}>
                <Spinner type='cubesSpinner' />
              </div>
            </Show>
          </>
        );
      }}
    </Observer>
  );
};
