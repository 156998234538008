import { Observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'src/components/condition';
import { InputWrapper } from 'src/components/form';
import { ShowDesktop } from 'src/styledComponents/Conditions';
import { DropDownFilter } from 'src/styledComponents/DropDownFilter';

import { t } from 'utils';

type Props = {
  distanceOptions: AnyObject;
  classOptions: AnyObject;
  gendersOptions: AnyObject;
  isGenderAvailable: boolean;
  show: boolean;
};

export const FiltersFormDesktop = (props: Props) => (
  <Observer>
    {() => {
      const { distanceOptions, classOptions, isGenderAvailable, gendersOptions, show } = props;

      return (
        <Show if={show}>
          <ShowDesktop>
            <div className='select-block-desktop'>
              <InputWrapper
                name='distance_id'
                settings={{
                  placeholder: t.staticAsString('racersTable.filters.distance'),
                  dropListItems: distanceOptions,
                  forceDropDirection: 'down',
                  size: 'medium',
                  isFitContent: true,
                  className: 'distances',
                }}
                Component={DropDownFilter}
              />

              <Show if={classOptions.length > 1}>
                <InputWrapper
                  name='class_id'
                  settings={{
                    placeholder: t.staticAsString('racersTable.filters.class'),
                    dropListItems: classOptions,
                    forceDropDirection: 'down',
                    size: 'medium',
                    isFitContent: true,
                    className: 'classes',
                    dropDirection: 'right',
                  }}
                  Component={DropDownFilter}
                />
              </Show>

              <Show if={isGenderAvailable}>
                <InputWrapper
                  name='gender'
                  settings={{
                    placeholder: t.staticAsString('racersTable.filters.gender'),
                    dropListItems: gendersOptions,
                    forceDropDirection: 'down',
                    size: 'medium',
                    isFitContent: true,
                    className: 'gender',
                    dropDirection: 'right',
                  }}
                  Component={DropDownFilter}
                />
              </Show>
            </div>
          </ShowDesktop>
        </Show>
      );
    }}
  </Observer>
);
