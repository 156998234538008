import { AnyStore } from 'stores';

import { HeartRateZonesDescription, Insights, JournalItemType } from '../types';
import { DataQualityBE } from '../types/dataQuality';
import { SessionHistory } from './sessionHistory';
import { TrainingPlanStore, TrainingPlanType } from './trainingPlan';

const trainingPlanStore = new TrainingPlanStore();
const sessionHistoryStore = new SessionHistory();
const activeDayWorkout = new AnyStore<TrainingPlanType>();
const slideWindowStore = new AnyStore<{ open: boolean; content: any }>({ open: false, content: '' });
const insightsStore = new AnyStore<Insights>();
const journalStore = new AnyStore<JournalItemType[]>();
const journalParamsStore = new AnyStore<{ start: number; end: number }>();
const dataQualityStore = new AnyStore<DataQualityBE>();
const heartRateZonesStore = new AnyStore<HeartRateZonesDescription>();

export {
  trainingPlanStore,
  activeDayWorkout,
  slideWindowStore,
  sessionHistoryStore,
  insightsStore,
  journalStore,
  journalParamsStore,
  dataQualityStore,
  heartRateZonesStore,
};
