import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Table } from 'components/table';

import { t } from 'utils';

import { getNumberOfChances, getStatus, getTicketTitle } from '../../utils';

import { getFilteredTickets } from '../../selections';

type Props = {
  title: string;
  unionId: number | string;
  isVisible: boolean;
};

const Wrapper = styled.div`
  background: ${(props) => props.theme.main.colors.white};
  max-width: 800px;
  margin-top: 48px;
  padding: 24px;
  .tickets-table {
    overflow-x: auto;
  }

  .ticket-title {
    margin-right: 40px;
    min-width: 235px;
    max-width: 235px;
  }
  .ticket-number {
    margin-right: 20px;
    min-width: 145px;
    max-width: 145px;
  }

  .table-head {
    padding: 0 40px;
  }
  .table-body {
    padding-top: 16px;
  }

  .table-row {
    padding: 20px 40px;
  }

  .table-head,
  .table-row {
    .table-cell {
      flex: 2 1 0;
    }
    .table-cell:nth-child(2) {
      flex: 1 1 0;
    }
  }

  .table-row {
    .ticket-number {
      color: ${(props) => props.theme.main.colors.clay3};
    }
    .valid {
      color: ${(props) => props.theme.main.colors.clay2};
    }
    .used {
      color: ${(props) => props.theme.main.colors.clay4};
    }

    &.union-footer {
      padding: 11px 40px;
      background: ${(props) => props.theme.main.colors.clay6};

      span {
        color: ${(props) => props.theme.main.colors.clay1};
      }

      .title {
        margin-right: 20px;
        min-width: 420px;
        max-width: 420px;
      }
      .value {
      }
    }
  }
`;

export const XtriUnion: FC<Props> = (props) => {
  const { title, unionId, isVisible } = props;
  const tickets = getFilteredTickets(unionId);

  if (isEmpty(tickets) || !isVisible) {
    return null;
  }

  const renderRows = (tickets: Array<AnyObject>): Array<React.ReactNode> =>
    tickets.map((item) => (
      <Table.Row key={`ticket:${item.id}`}>
        <Table.Cell className='ticket-title' variant='u2' weight='bold' value={getTicketTitle(item, title)} />
        <Table.Cell className='ticket-number' value={item.ticket_number} />
        <Table.Cell weight='bold' className={item.validity} value={getStatus(item.validity)} />
      </Table.Row>
    ));

  const renderFooter = () => (
    <Table.Row key='union-footer' className='union-footer'>
      <Table.Cell className='title' variant='u4' weight='medium' value={t('xtriTable.numberOfChance')} />
      <Table.Cell className='value' variant='u4' weight='bold' value={getNumberOfChances(tickets as any)} />
    </Table.Row>
  );

  return (
    <Observer>
      {() => {
        return (
          <Wrapper>
            <Table.Wrapper className='tickets-table'>
              <Table.Head>
                <Table.Cell className='ticket-title' variant='u4' weight='bold' value='xtriTab.table.source' />
                <Table.Cell className='ticket-number' variant='u4' weight='bold' value='xtriTab.table.drawNumber' />
                <Table.Cell variant='u4' weight='bold' value='xtriTab.table.statusShort' />
              </Table.Head>
              <Table.Body>
                {renderRows(tickets)}
                {renderFooter()}
              </Table.Body>
            </Table.Wrapper>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
