import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';
import styled from 'styled-components';

const CrossClosePath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M8.00016 9.58268L13.0842 14.6666L14.6668 13.084L9.58286 7.99998L14.6668 2.91598L13.0842 1.33331L8.00016 6.41733L2.91616 1.33331L1.3335 2.91598L6.41751 7.99998L1.3335 13.084L2.91616 14.6666L8.00016 9.58268Z'
    fill='currentColor'
    fillOpacity='0.65'
  />
);

export const CrossClose = (props: IconProps) => <SvgIcon path={CrossClosePath} viewBox='0 0 16 16' {...props} />;

const IconWrapper = styled.div`
  width: 32px;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.miles.colors.dark.alpha[300]};
`;

export const CloseInCircle = (props: IconProps) => {
  const { onClick, ...rest } = props;
  return (
    <IconWrapper onClick={onClick}>
      <CrossClose {...props} size={16} />
    </IconWrapper>
  );
};
