import { isEqual } from 'lodash';
import { reaction } from 'mobx';

import { initializer } from '../actions';

import { loaderDisposers, filtersStore } from '../stores';

/*
 * NOTE
 * Observe current filters state, in case it changes
 * reset loading data(results, pagination, count)
 */
function observeFilters() {
  const dispose = reaction(
    () => filtersStore.allExceptDistance,
    () => {
      initializer.initData();
    },
    { equals: isEqual },
  );

  loaderDisposers.register(dispose);
}

export { observeFilters };
