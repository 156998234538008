import styled from 'styled-components';

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: ${(props) => props.theme.miles.colors.brand.cobalt.cobalt800};
  box-shadow: ${(props) => props.theme.miles.shadows.light.elevetion1};
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

export const User = styled.div`
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 12px;
  }
`;

export const UserName = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & span {
    text-decoration: underline;
    text-decoration-color: #fff;
  }
  .user {
    letter-spacing: -0.1px;
  }
`;

export const LanguageBox = styled(FlexBox)`
  align-self: flex-start;
  span {
    font-size: 16px;
    line-height: 28px;
    padding-left: 4px;
  }
`;

export const ButtonBox = styled(FlexBox)`
  a {
    text-decoration: none;
  }

  a:first-child {
    margin-right: 16px;
  }
`;
