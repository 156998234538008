import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_V2_URL, API_USER_RESULTS } from 'src/constants';

import { action, request } from 'utils';

import { sessionStore } from 'stores';

import { resultStore } from '../stores';

export class UserResults {
  @request({ action: shortid() })
  requestUserResults(id: number): Promise<any> {
    const url = generatePath(API_V2_URL + API_USER_RESULTS, { id });
    return axios.get(url);
  }
  @action({ action: shortid() })
  async loadUserResults(): Promise<any> {
    const userId = +sessionStore.getUserData().id;
    const [status, response] = await this.requestUserResults(userId);
    if (status) {
      resultStore.setAllResults(response.data.data);
    }
    return null;
  }
}
