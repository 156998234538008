import { HEADER_HEIGHT } from 'modules/header/constants';
import { Button, Display, Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import RunningGirlImg from 'src/assets/images/miles/runningGirlDesktop.png';
import RunningGirlImg2x from 'src/assets/images/miles/runningGirlDesktop@2x.png';
import RunningGirlMobileImg2x from 'src/assets/images/miles/runningGirlMobile2x.png';
import RunningGirlMobileImg from 'src/assets/images/miles/runningGirlMobile.png';
import { RESOLUTIONS } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { ResponsiveImage } from 'src/styledComponents/ResponsiveImage';

import { history, t } from 'utils';

import { sessionStore } from 'stores';

const Wrapper = styled.div`
  padding: ${HEADER_HEIGHT + 55}px 0 115px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.miles.colors.brand.grad.c900C70045};
  .inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    .inner-wrapper {
      width: 1140px;
    }
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: ${HEADER_HEIGHT}px 16px 62px;
  }
`;
const RightPicture = styled(ResponsiveImage)`
  width: 60%;
  max-width: 840px;
  min-width: 660px;
  margin-right: -10%;
`;

const CenterPicture = styled(ResponsiveImage)`
  width: 100%;
  max-width: 420px;
`;

const Section = styled.div`
  max-width: 650px;
  margin-right: -180px;
  z-index: 1;
  .heading {
    line-height: 38px;
    letter-spacing: 0.48px;
    margin-top: 24px;
  }
  .text {
    margin-bottom: 20px;
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      text-align: center;
      font-size: 36px;
      margin-bottom: 24px;
    }
    .text {
      text-align: center;
      font-size: 18px;
      margin: 40px 0 16px;
    }
  }
`;

export const TopSection = () => {
  return (
    <Wrapper id='Home'>
      <div className='inner-wrapper'>
        <Section>
          <Display weight='bold' size='lg' className='heading'>
            {t('miles.landing.hero.title')}
          </Display>
          <ShowMobile>
            <CenterPicture image1x={RunningGirlMobileImg} image2x={RunningGirlMobileImg2x} />
          </ShowMobile>
          <Text weight='regular' size='xl' className='text'>
            <br />
            {t('miles.landing.hero.subtitle')}
            <br />
            <br />
            <b>{t('miles.landing.hero.bold-cta')}</b>
          </Text>
          <Button onClick={() => history.push('/miles/sign-in')} message='main' size='l' mode='dark'>
            {t.staticAsString('miles.landing.hero.cta-button')}
          </Button>
          <br />
          {t('miles.landing.hero.price')}
        </Section>
        <ShowDesktop>
          <RightPicture image1x={RunningGirlImg} image2x={RunningGirlImg2x} />
        </ShowDesktop>
      </div>
    </Wrapper>
  );
};
