import { observable, action, makeObservable } from 'mobx';

class RacesTopMenu {
  @observable showMobileFilter: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  openMobileFilter() {
    this.showMobileFilter = true;
  }

  @action
  closeMobileFilter() {
    this.showMobileFilter = false;
  }
}

export { RacesTopMenu };
export default new RacesTopMenu();
