import { mapKeys, keys, invert } from 'lodash';

import { EMPTY_FILTERS } from '../constants';

import { filterHistoryFilters, filterInternalFilters } from '../utils';

import { InternalFilters, HistoryFilters } from '../types';

const internalHistoryKeys: AnyObject = {
  checkpoint_id: 'currentCheckpointId',
  distance_id: 'currentDistanceId',
};

const historyInternalKeys = invert(internalHistoryKeys);

class InternaHistoryMapper implements MapperMiddleware<InternalFilters, HistoryFilters> {
  mapTo(value: InternalFilters): HistoryFilters {
    const withHistoryKeys = mapKeys(value, (value, key) => internalHistoryKeys[key] || key);
    const withDefaultValues = keys(withHistoryKeys).reduce(
      (acc, key) => ({
        ...acc,
        [key]: (withHistoryKeys[key] || (EMPTY_FILTERS as any)[key])?.toString(),
      }),
      {},
    );

    return filterHistoryFilters(withDefaultValues);
  }

  mapFrom(value: HistoryFilters): InternalFilters {
    const withInternalKeys = mapKeys(value, (value, key) => historyInternalKeys[key] || key);

    const class_id: string = withInternalKeys.class_id!;
    const gender = +withInternalKeys.gender!;
    const name = withInternalKeys.name;
    const checkpoint_id = parseInt(withInternalKeys.checkpoint_id!, 10);
    const distance_id = parseInt(withInternalKeys.distance_id!, 10);

    const filters = { class_id, gender, name, checkpoint_id, distance_id };

    return filterInternalFilters(filters as any);
  }
}

const internalHistoryMapper = new InternaHistoryMapper();
export { internalHistoryMapper };
