import { matchPath } from 'react-router-dom';

import { PRIVATE_ROUTES, ROUTER } from 'src/constants';

import { sessionStore } from 'stores';

import { history } from './history';

/*
 * Used to remember location when user page when he is not logged in (but page is require session)
 * And redirect to that page after login
 */
class PrivateRoutesHelper {
  prevPrivateRoute: string | null = null;

  checkIfPrivate() {
    const { location } = history;

    const isPrivate = !!PRIVATE_ROUTES.find((route) => matchPath(location.pathname, route));

    if (!sessionStore.isAuth && isPrivate) {
      this.prevPrivateRoute = location.pathname;
      return history.push(ROUTER.SIGNIN);
    }

    this.prevPrivateRoute = null;
  }

  back() {
    if (!this.prevPrivateRoute) {
      return history.safeGoBack({ fallbackLoggedRoute: ROUTER.PROFILE });
    }

    const route = this.prevPrivateRoute;
    this.prevPrivateRoute = null;
    history.push(route);
  }
}

const privateRouteUtil = new PrivateRoutesHelper();
export { privateRouteUtil };
