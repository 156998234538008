import { isEmpty } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

import { SearchSerializer } from 'utils';

import { Race as RaceModel } from 'models/race';

import { Loadable } from 'stores/loadable';

// Races store implementation for the map
class RaceWithRoute extends Loadable<RacesType, FiltersType> {
  declare filters: FiltersType;

  defaultFilters: FiltersType = {
    date_to: '',
    date_from: '',
    sports: [],
    term: '',
    length_from: null,
    length_to: 1000000,
  };

  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.defaultFilters;
  }

  handlers = {
    'location.city': 'like',
    name: 'like',
    'distances.race_length': 'between',
    race_date: '>=',
    race_end_date: '<=',
    sport_id: 'in',
    id: '=',
  };

  nestedJoins = '';
  searchJoin = 'AND';

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null;
      this.filters.searchFields = null;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters as any;
    return new SearchSerializer({
      search: search,
      handlers: this.handlers,
      nestedJoins: this.nestedJoins,
      searchJoin: this.searchJoin,
    });
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }

  @computed
  get modelValues(): Array<RaceModel> {
    return this.values.map((value) => {
      const modelValue = new RaceModel(value);
      modelValue.markRaceAsPastIfItIsPast();

      return modelValue;
    });
  }

  @computed
  get total(): number {
    return this.values.length;
  }

  @computed
  get coordinates(): Array<ParsedRoute> {
    return this.modelValues.filter((value) => !!value.value.route).map((value) => value.parsedRoute()!);
  }

  @computed
  get isFiltersEmpty() {
    const { searchDataModel } = this;
    const { q } = this.filters;

    return searchDataModel.isSearchEmpty((search) => {
      return isEmpty(search) && !q;
    });
  }
}

export { RaceWithRoute };
export default new RaceWithRoute();
