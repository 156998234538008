import { Observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { DatePickerInput } from 'src/styledComponents/DatePicker';

import { InputWrapper } from 'components/form';

import { t } from 'utils';

import { distanceStore } from '../../stores';

function DateLogged() {
  return (
    <Observer>
      {() => {
        return (
          <div className='field-wrapper'>
            <InputWrapper
              name='distance_logged_at'
              settings={{
                placeholder: 'DD / MM / YYYY',
                label: t.staticAsString('profile.resultForm.distance_logged_at'),
                maxDate: moment(),
                minDate: moment(distanceStore.value?.race_date),
                popperPlacement: 'top-start',
                requiredMark: true,
                clearable: true,
                withIcon: true,
                isRange: false,
                showCalendarAsModal: true,
              }}
              Component={DatePickerInput}
            />
          </div>
        );
      }}
    </Observer>
  );
}

export { DateLogged };
