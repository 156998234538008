import { Observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';
import { Skeleton } from 'src/styledComponents/Skeleton';
import { BodyText } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { t } from 'utils';

import { actionMenuActions } from '../../actions';

import { actionMenu } from '../../selections';

const BibIcon = styled(Svg)`
  svg path {
    stroke: none !important;
    fill: ${(props) => props.theme.main.colors.clay1};
  }
`;

const StyledSkeleton = styled(Skeleton)`
  margin-right: 0 !important;
`;

export const Wrapper = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 16px 0;
      color: ${theme.main.colors.clay1};

      div {
        margin-right: 16px;

        svg path {
          stroke: ${theme.main.colors.clay1};
        }
      }

      &:hover {
        cursor: pointer;
        background: ${theme.main.colors.clay6};
      }
    }
  `,
);

function ActionMenuItems() {
  return (
    <Observer>
      {() => {
        return (
          <Wrapper className='action-list'>
            <Show if={actionMenu.distanceNotDeleted.get()}>
              <li onClick={actionMenuActions.navigateToRace}>
                <Svg name='GoToRace' size={24} />
                <BodyText variant='body1'>{t('profile.actions.goToRace')}</BodyText>
              </li>
            </Show>
            <Show if={actionMenu.isDiplomaExport.get() && actionMenu.distanceNotDeleted.get()}>
              <li onClick={actionMenuActions.onExportDiploma}>
                <Svg name='ExportFinisherDiploma' size={24} />
                <BodyText variant='body1'>{t('profile.actions.exportDiploma')}</BodyText>
              </li>
            </Show>

            <Show if={actionMenu.isBibExport.get() && actionMenu.distanceNotDeleted.get()}>
              <li onClick={actionMenuActions.onExportBib}>
                <BibIcon name='Bib' size={24} />
                <BodyText variant='body1'>{t('profile.actions.exportBib')}</BodyText>
              </li>
            </Show>

            <Show if={false}>
              <li>
                <Svg name='GoToRace' size={24} />
                <BodyText variant='body1'>{t('profile.actions.registrationDetails')}</BodyText>
              </li>
            </Show>

            <Show if={actionMenu.isTransferRegistration.get()}>
              <li onClick={actionMenuActions.openTransferRegistrationModal}>
                <Svg name='TransferRegistration' size={24} />
                <BodyText variant='body1'>{t.staticAsString('profile.actions.transferRegistration')}</BodyText>
              </li>
            </Show>

            <Show if={actionMenu.isRegistrationTransfer.get()}>
              <li onClick={actionMenuActions.openDeleteTransferRegistrationModal}>
                <Svg name='TransferRegistration' size={24} />
                <BodyText variant='body1'>{t.staticAsString('profile.actions.deleteTransferRegistration')}</BodyText>
              </li>
            </Show>

            <Show if={actionMenu.isReceipt.get()}>
              <li onClick={actionMenuActions.onExportReceipt}>
                <Svg name='DownloadReceipt' size={24} />
                <BodyText variant='body1'> {t('profile.actions.exportReceipt')}</BodyText>
              </li>
            </Show>

            <Show if={actionMenu.isEditRegistration.get()}>
              <li onClick={actionMenuActions.editRegistrationInfo}>
                <Svg name='Edit' size={24} />
                <BodyText variant='body1'>{t('profile.actions.editInfo')}</BodyText>
              </li>
            </Show>
          </Wrapper>
        );
      }}
    </Observer>
  );
}

export { ActionMenuItems };

export const menuItems = () => {
  const setting: any[] = [];

  if (actionMenu.distanceNotDeleted.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.goToRace'),
      icon: <Svg name='GoToRace' size={24} />,
      onClick: () => actionMenuActions.navigateToRace(),
    });
  }

  if (actionMenu.isDiplomaExport.get() && actionMenu.distanceNotDeleted.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.exportDiploma'),
      icon: <Svg name='ExportFinisherDiploma' size={24} />,
      onClick: () => actionMenuActions.onExportDiploma(),
    });
  }

  if (actionMenu.isBibExport.get() && actionMenu.distanceNotDeleted.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.exportBib'),
      icon: <BibIcon name='Bib' size={24} />,
      onClick: () => actionMenuActions.onExportBib(),
    });
  }

  if (false) {
    setting.push({
      title: t.staticAsString('profile.actions.registrationDetails'),
      icon: <Svg name='GoToRace' size={24} />,
      onClick: () => {},
    });
  }

  if (actionMenu.isTransferRegistration.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.transferRegistration'),
      icon: <Svg name='TransferRegistration' size={24} />,
      onClick: () => actionMenuActions.openTransferRegistrationModal(),
    });
  }

  if (actionMenu.isRegistrationTransfer.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.deleteTransferRegistration'),
      icon: <Svg name='TransferRegistration' size={24} />,
      onClick: () => actionMenuActions.openDeleteTransferRegistrationModal(),
    });
  }

  if (actionMenu.isReceipt.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.exportReceipt'),
      icon: <Svg name='DownloadReceipt' size={24} />,
      onClick: () => actionMenuActions.onExportReceipt(),
    });
  }

  if (actionMenu.isEditRegistration.get()) {
    setting.push({
      title: t.staticAsString('profile.actions.editInfo'),
      icon: <Svg name='Edit' size={24} />,
      onClick: () => actionMenuActions.editRegistrationInfo(),
    });
  }

  return setting;
};
