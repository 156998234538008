import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_URL, API_AUTHORIZE_RACE } from 'src/constants';

import { request, action, t } from 'utils';

import { authorizedRace, errorsStore } from 'stores';

import { AUTH_ACTION } from '../constants';

class Authorize {
  @request({ action: AUTH_ACTION })
  authorizeRequest(id: number, password: string): any {
    const url = generatePath(API_URL + API_AUTHORIZE_RACE, { id });
    const params = { secret: password };
    return axios.post<{ secret: string; registration_token: string }>(url, params);
  }

  @action({ action: AUTH_ACTION })
  async authorize(id: number, password: string): Promise<any> {
    const [status, response] = await this.authorizeRequest(id, password);

    if (status) {
      const token = response.data.registration_token;
      authorizedRace.addOrUpdateValue(id, { token });
      return true;
    }

    const message = t.staticAsString('raceAuth.form.errors.password');

    errorsStore.add(AUTH_ACTION, { password: [message] });
    return false;
  }
}

export { Authorize, Authorize as AuthorizeService };
