import { observable, action, makeObservable } from 'mobx';

type Value = { [K in number]: PaginationMeta | null };

class PaginationMetaStore {
  @observable value: Value = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setValue(id: number, value: PaginationMeta) {
    this.value[id] = value;
  }

  @action
  clean() {
    this.value = {};
  }

  isPresented(id: number) {
    return !!this.value[id];
  }

  fetch(id: number) {
    return this.value[id] || null;
  }
}

export { PaginationMetaStore };
