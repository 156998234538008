import { Observer } from 'mobx-react';
import { handleChangeResultsPage } from 'modules/profile/actions';
import { resultStore } from 'modules/profile/stores';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS, ROUTER } from 'src/constants';
import { RaceProfileCardSkeleton } from 'src/styledComponents/Card';
import { ControlledPagination, DescriptionVariants } from 'src/styledComponents/Pagination';
import { Skeleton } from 'src/styledComponents/Skeleton';
import { ItemType, TagsFilter } from 'src/styledComponents/TagsFilter';

import { Hide, Show } from 'components/condition';

import { encodeQuery, history } from 'utils';

import { NoResults } from '../NoResults';
import { ClassicRaces } from './ClassicRaces';
import { VirtualRaces } from './VirtualRaces';

const Wrapper = styled.div`
  .filter {
    :empty {
      display: none;
    }
    padding-top: 32px;
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: 0 20px;
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(294px, 3fr));
  padding-top: 48px;

  justify-content: flex-start;
  gap: 64px 24px;

  & > div {
    width: 100%;
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    & > div {
      width: 294px;
    }
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

const StyledNoResults = styled(NoResults)`
  @media (max-width: ${RESOLUTIONS.medium}px) {
    .no-results-message {
      width: 232px;
    }
  }
`;

const StyledSkeleton = styled(Skeleton)`
  margin-top: 32px;
`;

export const StyledControlledPagination = styled(ControlledPagination)`
  margin-top: 48px;
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-top: 32px;
  }
`;

type Props = {
  tabList: ItemType[];
  countVR: number;
  countCumulative: number;
  countClassic: number;
  isLoading: boolean;
};

export const MyResultsTabContainer: FC<Props> = ({ tabList, countVR, countCumulative, countClassic, isLoading }) => {
  const [tab, setTab] = useState<'classic' | 'virtual' | 'cumulative'>();

  useEffect(() => {
    if (tabList.length > 0 && !tab) {
      const { distanceType } = encodeQuery.parseQuery(history.location.search);
      const isTabExist = tabList.find((tab) => tab.value === distanceType);
      const activeTab = isTabExist ? distanceType : tabList[0].value;
      history.push(`${ROUTER.PROFILE}?tab=my_results&distanceType=${activeTab}`);
      setTab(activeTab);
    }
  }, [tabList]);

  const handleTabChange = (e) => {
    history.push(`${ROUTER.PROFILE}?tab=my_results&distanceType=${e.value}`);
    setTab(e.value);
  };

  const noRaces = !countVR && !countCumulative && !countClassic;
  const isRacesLoaded = resultStore.classicMeta && resultStore.cumulativeMeta && resultStore.virtualMeta;

  const ATNMyResults = { ...testAnchors.useField('myResults', TEST_ANCHORS.fieldStructure.container) };

  return (
    <Wrapper {...ATNMyResults}>
      <Show if={isLoading || !isRacesLoaded}>
        <StyledSkeleton variant='rectangular' height={40} width={294} />
        <CardWrapper>
          {[...Array(3).keys()].map((value) => (
            <RaceProfileCardSkeleton key={value} withResults />
          ))}
        </CardWrapper>
      </Show>
      <Hide if={isLoading || !isRacesLoaded}>
        <TagsFilter className='filter' name='example' value={tab} onChange={handleTabChange} tagsList={tabList} />
        <Show if={noRaces && !isLoading}>
          <StyledNoResults message='profile.notResultsMessage' />
        </Show>

        <CardWrapper>
          <Show if={countClassic !== 0 && tab === 'classic'}>
            <ClassicRaces />
          </Show>
          <Show if={countVR !== 0 && tab === 'virtual'}>
            <VirtualRaces distanceType='virtual' />
          </Show>
          <Show if={countCumulative !== 0 && tab === 'cumulative'}>
            <VirtualRaces distanceType='cumulative' />
          </Show>
        </CardWrapper>
        <Show if={Boolean(tab)}>
          <Observer>
            {() => {
              return (
                <StyledControlledPagination
                  currentPage={resultStore[`${tab}Meta`]?.current_page}
                  onPageChanged={(page) => handleChangeResultsPage(page, tab!)}
                  pageLimit={resultStore[`${tab}Meta`]?.per_page}
                  totalRecords={resultStore[`${tab}Meta`]?.total}
                  descriptionVariant={DescriptionVariants.base}
                />
              );
            }}
          </Observer>
        </Show>
      </Hide>
    </Wrapper>
  );
};
