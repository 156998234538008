import React from 'react';

import CircularSkeleton from './CircularSkeleton';
import RectangleSkeleton from './RectangleSkeleton';
import TextSkeleton from './TextSkeleton';

export type SkeletonProps = {
  variant?: keyof typeof Variants;
  height?: number | string;
  width?: number | string;
  className?: string;
  animation?: 'gradient' | false;
  children?: React.ReactNode;
};

export type BaseSkeletonProps = SkeletonProps & {
  hasChildren?: boolean;
};

enum Variants {
  text = 'text',
  rectangular = 'rectangular',
  circular = 'circular',
}

const transformCssValues = (value: string | number | undefined) => {
  return typeof value === 'number' ? `${value}px` : value;
};

const components = {
  [Variants.text]: TextSkeleton,
  [Variants.rectangular]: RectangleSkeleton,
  [Variants.circular]: CircularSkeleton,
};

export const Skeleton: React.FC<SkeletonProps> = (props) => {
  const { children, variant = Variants.text, className, width, height, animation = 'gradient' } = props;
  const hasChildren = Boolean(children);

  const baseProps: BaseSkeletonProps = {
    className,
    width: transformCssValues(width),
    height: transformCssValues(height),
    hasChildren,
    animation,
  };

  const Component = components[variant];

  return <Component {...baseProps}>{children}</Component>;
};
