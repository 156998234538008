import axios from 'axios';
import { isArray, isEmpty } from 'lodash';

import { LOAD_RACES, API_SEARCH_ROUTE, ALL_RACES_FILTER_LOAD_VERSION, API_URL } from 'src/constants';

import { request, action, versionControl } from 'utils';

import { racesWithRouteStore, RaceWithRoute } from 'stores';

class RacesWithRoute {
  store: RaceWithRoute;

  constructor(store: RaceWithRoute) {
    this.store = store;
  }

  @request({ action: LOAD_RACES })
  async loadResourcesRequest(filters: FiltersType | {}): Promise<any> {
    const url = `${API_URL}${API_SEARCH_ROUTE}`;

    const params = {
      from: 0,
      size: 5000,
    };

    return axios.post(url, filters, { params });
  }

  @action({ action: LOAD_RACES, minRequestTime: 300 })
  async loadResourcesSearch(params: FiltersType = {}): Promise<any> {
    let { filters } = this.store || {};
    const searchModel = this.store.retrieveSearchDataModel();

    // To reject parallel requests, and accept only the last
    // Because debounce IS NOT ENOUGH
    const requestVersion = versionControl.incrementVersion(ALL_RACES_FILTER_LOAD_VERSION);

    searchModel.clearFilters();
    searchModel.addFilters(params);

    const newFilters = {
      ...filters,
      ...params,
    };

    const bodyFilters = Object.keys(newFilters).reduce((result, value) => {
      if (!newFilters[value]) {
        return result;
      }

      if (isArray(newFilters[value]) && isEmpty(newFilters[value])) {
        return result;
      }

      return {
        ...result,
        [value]: newFilters[value],
      };
    }, {});

    const [status, response] = await this.loadResourcesRequest(bodyFilters);

    if (status && requestVersion === versionControl.currentVersion(ALL_RACES_FILTER_LOAD_VERSION)) {
      this.store.addValues(response.data.data, 0, bodyFilters);
    }
  }
}

const racesWithRouteService = new RacesWithRoute(racesWithRouteStore);

export { RacesWithRoute, racesWithRouteService };
