import React from 'react';

import { Checkbox } from '../Checkbox';
import { InputWrapComponent } from '../InputTools';

export const MultiCheckbox = (props) => {
  const { requiredMark, errorMessage, label, name, value, checkboxList, disabled } = props;

  const isChecked = (checkbox_id: number | any): boolean | any => {
    if (!(value || []).length) {
      return false;
    }

    return value.findIndex((item: AnyObject | any) => parseInt(item, 10) === checkbox_id) >= 0;
  };

  const handleChange = (checkbox: AnyObject) => {
    const { onChange, name } = props;

    let currentSelection = [...props.value];

    let result;
    if (checkbox.value) {
      currentSelection.push(Number(checkbox.id));
    } else {
      currentSelection = currentSelection.filter((val) => val !== Number(checkbox.id));
    }

    result = {
      name,
      value: currentSelection.length ? currentSelection : undefined,
    };
    onChange(result);
  };

  return (
    <InputWrapComponent errorMessage={errorMessage} label={label} requiredMark={requiredMark} name={name} value={value}>
      {checkboxList.map((checkbox) => (
        <Checkbox
          id={`${name}-${checkbox.id}`}
          key={`${checkbox.id}`}
          name={checkbox.title}
          label={checkbox.title}
          value={isChecked(checkbox.id)}
          onChange={(data) => handleChange({ ...data, id: checkbox.id })}
          checkValue={true}
          disabled={disabled}
          labelPosition='right'
        />
      ))}
    </InputWrapComponent>
  );
};
