import { Observer } from 'mobx-react';
import { chooseRace, navigateRace } from 'modules/search/actions';
import { amountInDropdown } from 'modules/search/constants';
import { racesStore, recentRacesStore, recomendedRacesStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import * as React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import { HEADER_FORM, RESOLUTIONS } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { SportIcon } from 'src/styledComponents/SportType';
import { BodyText, Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

import { form } from 'stores';

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
  padding: 0 0 16px 20px;
  display: block;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }
`;

const NoResult = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
  padding-left: 20px;
  display: block;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }
`;

const RaceOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 20px;

  &:hover {
    background-color: ${(props) => props.theme.main.colors.clay6};
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }

  .sport-type {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;

const StyledOptionLabel = styled(BodyText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Races: React.FC<{ isRecentValues: boolean; isRecomendedValues: boolean }> = ({ isRecentValues, isRecomendedValues }) => {
  const getName = () => {
    if (isRecomendedValues && !isRecentValues) {
      return t('search.recomendedRaces');
    }
    if (isRecentValues) {
      return t('search.recentSearch');
    }
    return t('search.raceName');
  };

  const getList = () => {
    if (isRecomendedValues && !isRecentValues) {
      return <RecomendedRaces />;
    }
    if (isRecentValues) {
      return <RecentRaces />;
    }
    return <NormalRaces />;
  };

  return (
    <Observer>
      {() => {
        const raceName = form.fetch(HEADER_FORM, FieldNames.raceName);

        if (raceName && !racesStore.value?.length) {
          return (
            <NoResult variant='u3' weight='medium'>
              {t.staticAsString('header.noResults')}
            </NoResult>
          );
        }
        return !recentRacesStore.value?.length && !racesStore.value?.length && !recomendedRacesStore.value?.length ? null : (
          <>
            <ShowDesktop>
              <StyledUtility variant='u3' weight='medium'>
                {getName()}
              </StyledUtility>
            </ShowDesktop>
            <ShowMobile>
              <StyledUtility variant='u2' weight='bold'>
                {getName()}
              </StyledUtility>
            </ShowMobile>
            {getList()}
          </>
        );
      }}
    </Observer>
  );
};

const RecomendedRaces = () => {
  return (
    <>
      {recomendedRacesStore.value!.slice(0, amountInDropdown.raceName).map((race) => (
        <RaceOption key={`race-${race.key}`} onClick={navigateRace(race.key)}>
          <ShowDesktop>
            <SportIcon link={race.sportIcon} />
            <StyledOptionLabel variant='body1'>{race.label}</StyledOptionLabel>
          </ShowDesktop>
          <ShowMobile>
            <SportIcon link={race.sportIcon} />
            <StyledOptionLabel variant='body2'>{race.label}</StyledOptionLabel>
          </ShowMobile>
        </RaceOption>
      ))}
    </>
  );
};

const RecentRaces = () => {
  return (
    <>
      {recentRacesStore.value?.map((race) => (
        <RaceOption key={shortid()} onClick={chooseRace(race)}>
          <ShowDesktop>
            <SportIcon />
            <StyledOptionLabel variant='body1'>{race}</StyledOptionLabel>
          </ShowDesktop>
          <ShowMobile>
            <SportIcon />
            <StyledOptionLabel variant='body2'>{race}</StyledOptionLabel>
          </ShowMobile>
        </RaceOption>
      ))}
    </>
  );
};

const NormalRaces = () => {
  return (
    <>
      {racesStore.value!.slice(0, amountInDropdown.raceName).map((race) => (
        <RaceOption key={`race-${race.key}`} onClick={navigateRace(race.key)}>
          <ShowDesktop>
            <SportIcon link={race.sportIcon} />
            <StyledOptionLabel variant='body1'>{race.label}</StyledOptionLabel>
          </ShowDesktop>
          <ShowMobile>
            <SportIcon link={race.sportIcon} />
            <StyledOptionLabel variant='body2'>{race.label}</StyledOptionLabel>
          </ShowMobile>
        </RaceOption>
      ))}
    </>
  );
};
