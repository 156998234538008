import { cloneDeep, omit, trim } from 'lodash';

import { FormSegment } from '../types';

export const mapToValidate = (data: FormSegment): FormSegment => {
  let copy = cloneDeep(data);

  if (!trim(copy.activity_link)) {
    copy = omit(copy, ['activity_link']) as any;
  }

  return copy;
};
