class Maps {
  generateUrlWithPin(lat: number | string, lng: number | string): string {
    const floatLat = parseFloat(lat as string);
    const floatLng = parseFloat(lng as string);
    return `https://www.google.com/maps/search/?api=1&query=${floatLat},${floatLng}`;
  }
}

const mapsUtil = new Maps();
export { mapsUtil };
