import * as React from 'react';
import styled from 'styled-components';

const PinWrap = styled.div`
  position: relative;
  cursor: pointer;
`;

const SportIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.main.colors.clay1};

  &:before {
    position: absolute;
    bottom: -2px;
    left: 12px;
    content: '';
    background-color: ${(props) => props.theme.main.colors.clay1};

    width: 6px;
    height: 6px;

    transform: rotate(45deg);
  }

  img {
    filter: invert(1);
    width: 14px;
    height: 14px;
  }
`;

type Props = {
  icon: string;
  lat: number;
  lng: number;
};

export const Pin: React.FC<Props> = (props) => {
  return (
    <PinWrap>
      <SportIcon>
        <img src={props.icon} alt='sport' />
      </SportIcon>
    </PinWrap>
  );
};
