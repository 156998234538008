import styled, { css } from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

type ItemProps = {
  active: boolean;
};

export const Language = styled(Utility)`
  padding-right: 4px;
`;

export const LanguageItem = styled.li<ItemProps>(
  ({ theme, active }: { theme; active: boolean }) => css`
    display: flex;
    align-items: center;
    padding: 16px 32px;
    margin: 20px;

    svg path {
      stroke: ${theme.main.colors.clay4};
      stroke-width: 1.5px;
    }

    span:last-child {
      color: ${theme.main.colors.clay3};
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
      background: ${theme.main.colors.clay6};
    }

    ${active &&
    css`
      background: ${theme.main.colors.clay1};
      span {
        color: ${theme.main.colors.white};
      }
      span:last-child {
        color: ${theme.main.colors.clay5};
      }
      svg path {
        stroke: ${theme.main.colors.clay1};
      }
      &:hover {
        cursor: default;
        background: ${theme.main.colors.clay1};
      }
    `}
  `,
);
