import { RacerName } from 'modules/racersTable/components';
import * as React from 'react';

import { userModal } from '../../actions';

type Props = {
  value: RacersType;
};

function RacerNameComponent({ value }: Props) {
  return <RacerName value={value} onOpen={userModal.onOpen} />;
}

export { RacerNameComponent as RacerName };
