import * as React from 'react';

import TextAreaField from 'src/styledComponents/TextAreaField/TextAreaField';
import { TimeField, TimeFieldVariants } from 'src/styledComponents/TimeField';

import { InputWrapper } from 'components/form';

import { t } from 'utils';

import { onBlurLink } from '../../actions';

import { DateLogged } from './DateLogged';
import { DistanceLogged } from './DistanceLogged';

const FieldsComponent = () => (
  <>
    <div className='field-wrapper'>
      <InputWrapper
        name='activity_link'
        settings={{
          placeholder: t.staticAsString('profile.resultForm.placeholder.activity_link'),
          label: t.staticAsString('profile.resultForm.activity_link'),
          onBlur: onBlurLink('activity_link'),
        }}
        Component={TextAreaField}
      />
    </div>

    <DistanceLogged />
    <DateLogged />

    <div className='field-wrapper'>
      <InputWrapper
        name='time_result'
        settings={{
          label: t.staticAsString('profile.resultForm.title.logResults'),
          requiredMark: true,
          variant: TimeFieldVariants.separated,
          withMilliseconds: true,
        }}
        Component={TimeField}
      />
    </div>
  </>
);

export const Fields = React.memo<{}>(FieldsComponent);
