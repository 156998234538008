import { observer } from 'mobx-react';
import * as React from 'react';

import { snackStore } from 'stores';

import { Snack } from './Snack/index';
import { SnackContainer } from './SnackContainer';

@observer
class SnackContainerWithStore extends React.Component<{}> {
  values = () => {
    const values = snackStore.values;
    const isAllSnacksHidden = snackStore.isAllSnacksHidden;

    if (isAllSnacksHidden) {
      return null;
    }

    return values.map<React.ReactNode>((snack) => {
      return <Snack key={snack.id} {...snack} />;
    });
  };

  render() {
    return <SnackContainer>{this.values()}</SnackContainer>;
  }
}

export { SnackContainerWithStore, Snack, SnackContainer };
