import { Observer } from 'mobx-react';
import { FiltersLabel } from 'modules/racersTable/components';
import * as React from 'react';

import { ContextValue, FormContext } from 'src/components/form';

import { handleOpenMobileFilters } from '../../../actions';

import { filtersOpenedStore } from '../../../stores';

function Label() {
  const { form } = React.useContext<ContextValue>(FormContext);

  return (
    <Observer>
      {() => {
        const isOpened = filtersOpenedStore.value;
        return <FiltersLabel isOpen={isOpened} onClick={handleOpenMobileFilters(form!.id)} />;
      }}
    </Observer>
  );
}

export { Label };
