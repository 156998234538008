import { merge } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

import { Distance as DistanceModel } from 'models/distance';

class Distances {
  @observable values: DistanceType[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get modelValues() {
    const { values } = this;

    return values.map<DistanceModel>((el) => new DistanceModel(el));
  }

  find(id: number): DistanceModel | null {
    return this.expandedDistances[id];
  }

  @action
  async setValues(values: DistanceType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  @computed
  get expandedDistances(): { [K in number]: DistanceModel } {
    const { modelValues } = this;

    return modelValues.reduce((acc, model) => {
      const id = model.value.id;
      return merge(acc, { [id]: model });
    }, {});
  }

  // should be overwritten
  @computed
  get filteredModelValues() {
    return this.modelValues;
  }

  // should be overwritten
  @computed
  get filteredValues() {
    return this.values;
  }
}

export { Distances, Distances as DistancesStore };
