import styled, { css } from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/topographic-pattern.svg';

export const SidebarWrapper = styled.div<{ isActive: boolean }>`
  * {
    font-family: Aeonik, sans-serif !important;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  background-image: url('${PatternImage}');
  background-color: ${(props) => props.theme.main.colors.white};

  height: 100%;
  width: 100vw;

  transform: translate3d(100%, 0, 0);
  transition: transform 0.5s ease;

  ${(props) =>
    props.isActive &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`;
