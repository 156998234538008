import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ContentWrapper = styled.div`
  width: 550px;

  .field-wrapper {
    & > span {
      display: block;
      padding-bottom: 8px;
    }
    margin-bottom: 32px;
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    width: 100%;
  }
`;

function Content({ children, className }: Props) {
  return <ContentWrapper className={classNames('content', className)}>{children}</ContentWrapper>;
}

export { Content };
