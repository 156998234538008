import styled, { css } from 'styled-components';

export const Wrapper = styled('div')(
  ({ theme, isDark }: { theme; isDark: boolean }) => css`
    position: relative;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${isDark ? theme.main.colors.clay1 : theme.main.colors.white};

    & svg path {
      stroke: ${isDark ? theme.main.colors.clay1 : theme.main.colors.white};
    }

    &:hover {
      color: ${theme.miles.colors.light.thin.primary};
      & svg path {
        stroke: ${theme.miles.colors.light.thin.primary};
      }
    }
  `,
);

export const SelectedLanguage = styled.div`
  font-family: ${(props) => props.theme.main.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: center;
  margin-left: 10px;
  text-transform: uppercase;
`;
