import classNames from 'classnames';
import * as React from 'react';

import { Button } from 'src/styledComponents/Button';

import { t } from 'utils';

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  display: boolean;
  hide: boolean;
};

export class EditCell extends React.Component<Props> {
  render() {
    const { onClick, display, hide } = this.props;

    if (!display) {
      return null;
    }

    return (
      <div className={classNames('edit-icon', { 'hide-icon': hide })} onClick={onClick}>
        <Button variant='primary' size='medium' ATN='editResults'>
          {t.staticAsString('profile.resultForm.editTitle')}
        </Button>
      </div>
    );
  }
}
