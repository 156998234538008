import { mapFormToQuery } from 'modules/search/utils';
import * as React from 'react';

import { ROUTER } from 'src/constants';

import { encodeQuery, history } from 'utils';

export const handleBack = () => {
  history.safeGoBack();
};

export const handleClickNav = (_unused: any, e: React.MouseEvent<HTMLDivElement>) => {
  const scrollOffset = window.pageYOffset;
  setTimeout(() => window.scrollTo({ top: scrollOffset, behavior: 'smooth' }));
};

export const onOpenCategory = (id: number) => () => {
  const query = encodeQuery.stringify(mapFormToQuery({ sports: [{ key: id }] }));
  const url = `${ROUTER.RACES}?${query}`;
  window.open(url, '_blank', 'noreferrer');
};

export const onOpenLocation = (location: AnyObject) => () => {
  const query = encodeQuery.stringify({ location: location.value.city });
  const url = `${ROUTER.RACES}?${query}`;
  window.open(url, '_blank', 'noreferrer');
};
