import { Observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { ROUTER } from 'src/constants';
import { SmoothElevation } from 'src/styledComponents/Elevation';
import { Svg } from 'src/styledComponents/Icons';
import { Link } from 'src/styledComponents/Link';
import { BodyText } from 'src/styledComponents/Typography';
import { hideCookieBanner, t, intercom } from 'src/utils';

import { cookieBannerStore } from 'stores/cookieBannerStore';

import { ShowDesktop, ShowMobile, ShowTablet } from '../../styledComponents/Conditions';

const CookieBannerElement = styled(SmoothElevation)<{ size: 'mobile' | 'tablet' | 'desktop' }>`
  background: ${(props) => props.theme.main.colors.clay6};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  color: ${(props) => props.theme.main.colors.clay2};
  a {
    white-space: nowrap;
    color: ${(props) => props.theme.main.colors.clay2};
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid ${(props) => props.theme.main.colors.clay2};
  }

  .cookie-close-button {
    position: absolute;
    cursor: pointer;
  }

  ${(props) =>
    props.size === 'desktop' &&
    css`
      padding: 0px 104px 0px 80px;
      height: 72px;
      .cookie-close-button {
        right: 80px;
      }
    `}

  ${(props) =>
    props.size === 'mobile' &&
    css`
      padding: 0 84px 0 20px;
      height: 92px;
      .cookie-close-button {
        right: 20px;
      }
    `}

  ${(props) =>
    props.size === 'tablet' &&
    css`
      padding: 0 96px 0 24px;
      height: 112px;
      .cookie-close-button {
        right: 24px;
      }
    `}
`;

const CookieBannerComponent = () => {
  intercom.useCookiesHideIntercom();
  return (
    <>
      <ShowMobile>
        <CookieBannerElement level={5} size='mobile'>
          <BodyText variant='body2'>
            <span>{t.staticAsString('cookies.banner')}</span>
            <Link to={ROUTER.PUBLIC_COOKIES} ATN='cookie-banner'>
              {t.staticAsString('cookies.banner.link')}
            </Link>
          </BodyText>
          <Svg onClick={hideCookieBanner} className='cookie-close-button' name='CloseLight' size={24} />
        </CookieBannerElement>
      </ShowMobile>

      <ShowTablet>
        <CookieBannerElement level={5} size='tablet'>
          <BodyText variant='body1'>
            <span>{t.staticAsString('cookies.banner')}</span>
            <Link to={ROUTER.PUBLIC_COOKIES} ATN='cookie-banner'>
              {t.staticAsString('cookies.banner.link')}
            </Link>
          </BodyText>
          <Svg onClick={hideCookieBanner} className='cookie-close-button' name='CloseLight' size={24} />
        </CookieBannerElement>
      </ShowTablet>

      <ShowDesktop withTablet>
        <CookieBannerElement level={5} size='desktop'>
          <BodyText variant='body1'>
            <span>{t.staticAsString('cookies.banner')}</span>
            <Link to={ROUTER.PUBLIC_COOKIES} ATN='cookie-banner'>
              {t.staticAsString('cookies.banner.link')}
            </Link>
          </BodyText>
          <Svg onClick={hideCookieBanner} className='cookie-close-button' name='CloseLight' size={24} />
        </CookieBannerElement>
      </ShowDesktop>
    </>
  );
};

export const CookieBanner = () => {
  return <Observer>{() => (!cookieBannerStore.value ? null : <CookieBannerComponent />)}</Observer>;
};
