import * as React from 'react';
import styled from 'styled-components';

import { ButtonToTop } from 'src/components/scrollToTop';

export const ButtonScrollComponent = styled(ButtonToTop)`
  top: 100vh;
  z-index: 100;
`;

// That is here just because typescript is throwing errors
export const ButtonScroll = ButtonScrollComponent;
