import { useGoogleLogin } from '@react-oauth/google';
import * as React from 'react';

import { t, fireToast } from 'utils';

export type Props = {
  type: 'login' | 'signUp';
  btnProps?: AnyObject;
  handleClick: (token: string) => void;
  children?: React.ReactNode;
};

export const GoogleButton: React.FC<Props> = (props) => {
  const { type, btnProps, children, handleClick } = props;

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleClick(tokenResponse.access_token);
    },
    onError(error) {
      return fireToast(error.error_description || t.staticAsString('toast.cantGetGoogleUserData'), 'error');
    },
  });

  const btnText = type === 'login' ? 'login.googleSignin' : 'login.googleSignup';
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // @ts-ignore
      return React.cloneElement(child, { onClick: () => login() });
    }
    return child;
  });

  return (
    <>
      {childrenWithProps}
      {!children && (
        <button {...btnProps} className='btn' onClick={() => login()}>
          {t(btnText)}
        </button>
      )}
    </>
  );
};
