import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { actionMenuStore } from '../../stores';

const distanceNotDeleted: IComputedValue<boolean> = computed(() => {
  const distance = actionMenuStore.distanceModel;

  if (!distance) {
    return false;
  }

  return !distance?.value?.deleted_at;
});

export { distanceNotDeleted };
