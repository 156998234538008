import { theme } from 'styles';

export const HEART_RATE_ZONES_STATIC = [
  {
    title: 'Zone 1',
    description: '',
  },
  {
    title: 'Zone 2',
    description: '',
  },
  {
    title: 'Zone 3',
    description: '',
  },
  {
    title: 'Zone 4',
    description: '',
  },
  {
    title: 'Zone 5',
    description: '',
  },
  {
    title: 'Zone 6',
    description: '',
  },
];

export const ZONE_COLORS = [
  theme.miles.colors.dark.alpha['500'],
  theme.miles.colors.dark.icon.cobalt,
  theme.miles.colors.dark.thin.positive,
  theme.miles.colors.brand.aurora['500'],
  theme.miles.colors.dark.thin.warning,
  theme.miles.colors.dark.thin.negative,
];
