import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';
import { Utility, BodyText } from 'src/styledComponents/Typography';
import { t } from 'src/utils/t';

type Props = {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string | JSX.Element;
  title?: string;
};

type BodyProps = {
  type: 'success' | 'error' | 'warning' | 'info';
} & TestAnchortProperties;

const getTitleColor = (props) => {
  switch (props.type) {
    case 'success':
      return props.theme.main.colors.success;

    case 'error':
      return props.theme.main.colors.error;

    case 'warning':
      return props.theme.main.colors.warning;

    case 'info':
      return props.theme.main.colors.auroraLemon;
  }
};

const ToastBody = styled.div<BodyProps>`
  display: flex;
  align-items: center;

  .message-block {
    margin-left: 24px;

    p {
      color: ${(props) => props.theme.main.colors.clay6};
      margin: 0;
    }

    span {
      color: ${getTitleColor};
    }
  }
`;

const IconNames = {
  success: 'CheckGreen',
  error: 'ErrorRed',
  warning: 'WarningYellow',
  info: 'InfoLemon',
};

export const ToastItem: React.FC<Props> = (props) => {
  const { type, message, title } = props;
  const titleMessage = title ? title : t(`toast.title.${type}` as TranslationLockedKeys);

  return (
    <ToastBody type={type} className={`Root.toast.toast${type}`} {...testAnchors.field(type, TEST_ANCHORS.fieldStructure.root)}>
      <Icon name={IconNames[type]} size={24} />
      <div className='message-block'>
        <Utility variant='u2' weight='bold'>
          {titleMessage}
        </Utility>
        <BodyText variant='body2'>{message}</BodyText>
      </div>
    </ToastBody>
  );
};
