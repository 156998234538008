import { sortBy, isEmpty } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';
import { mainConditions } from 'modules/registration/selectors/mainConditions';

import { Distance } from 'src/models';

import { distanceStore, transferStore } from '../../stores';

const model: IComputedValue<Distance | null> = computed(() => {
  if (!distanceStore.value) {
    return null;
  }

  return new Distance(distanceStore.value);
});

const id: IComputedValue<number | null> = computed(() => {
  const distanceModel = model.get();

  if (!distanceModel) {
    return null;
  }

  return distanceModel.value.id;
});

/**
 * @description
 * custom fueld options mapped with prices
 * { [custom field option id]: custom field option price }
 */
const customFieldOptionsPrices: IComputedValue<{ [K in number]: number | null }> = computed(() => {
  const distance = model.get();

  if (!distance?.value.custom_fields) {
    return {};
  }

  return distance.value.custom_fields.reduce<{ [k in number]: number | null }>((optionsPrices, customField) => {
    if (!customField) {
      return optionsPrices;
    }

    customField.values?.forEach((customFieldOption) => {
      optionsPrices[customFieldOption.id] = parseFloat(customFieldOption.price) || null;
    });

    return optionsPrices;
  }, {});
});

const refundProtect: IComputedValue<number> = computed(() => {
  const distance = model.get();

  if (!distance?.value.refund_protect_percent) {
    return 17;
  }

  return distance?.value.refund_protect_percent;
});

export const distanceSelector = {
  model,
  id,
  /**
   * @description
   * custom fueld options mapped with prices
   * { [custom field option id]: custom field option price }
   */
  customFieldOptionsPrices,
  refundProtect,
  isProduct: computed(() => {
    const distance = model.get();

    if (!distance) {
      return false;
    }

    return distance.value.products ? distance.value.products.length > 0 : false;
  }),

  isTeam: computed(() => {
    const distance = model.get();

    if (!distance) {
      return false;
    }

    return distance.isTeam();
  }),
  isSingle: computed(() => {
    const distance = model.get();

    if (!distance) {
      return false;
    }

    return distance.isSingle();
  }),
  isNoProfile: computed(() => {
    const distance = model.get();

    if (!distance) {
      return false;
    }

    return distance.isNoProfile();
  }),

  isAlreadyRegistered: computed(() => {
    const distance = model.get();

    if (!distance) {
      return false;
    }

    return distance.isAlreadyRegistered();
  }),

  /**
   * @description
   * boolean value is there at least one custom field in the distance
   */
  isCustomFieldsEnabled: computed((): boolean => {
    const distance = model.get();

    return Boolean(distance?.value.custom_fields?.length);
  }),

  isRefundProtectEnabled: computed((): boolean => {
    const distance = model.get();

    // Condition that turn off Refund protect for transfer or edit registration
    if (transferStore.isTransferred || mainConditions.isEditRegistration()) return false;

    if (!distance) return false;

    if (!distance.value.price && !distanceSelector.isCustomFieldsEnabled.get()) return false;

    return !!distance?.value?.refund_protect_enabled;
  }),

  registrationFields: computed((): RegistrationFieldsType[] => {
    const distanceModel = model.get();

    if (!distanceModel) {
      return [];
    }

    return distanceModel.value.registration_fields || [];
  }),

  expandedRegistrationFields: computed((): { [K in registrationFields]: RegistrationFieldsType } => {
    return (
      distanceSelector.registrationFields.get().reduce((expandedRegistrationFields, field) => {
        expandedRegistrationFields[field.name] = field;

        return expandedRegistrationFields;
      }, {}) || ({} as any)
    );
  }),

  /**
   * @description
   * race_qty - racers_count
   * max number of racers available for this distance
   */
  maxRegistrationsPerDistance: computed((): number => {
    const distance = model.get();

    if (!distance) {
      return 0;
    }
    if (distance.value.race_qty === null) {
      return Infinity;
    }
    return distance.value.race_qty - distance.value.racers_count;
  }),

  /**
   * @description
   * max_members_quantity
   */
  maxMembersInTeam: computed((): number => {
    const distance = model.get();
    return distance?.value.max_members_quantity || NaN;
  }),

  /**
   * @description
   * min_members_quantity
   */
  minMembersInTeam: computed((): number => {
    const distance = model.get();
    return distance?.value.min_members_quantity || NaN;
  }),

  /**
   * @description
   * Array of sorted custom fields
   */
  customFields: computed(() => {
    const distance = model.get();

    if (!distance) {
      return [];
    }

    return sortBy(
      distance.value.custom_fields?.map((customField) => {
        const options = customField.values;

        if (isEmpty(options)) {
          return customField;
        }

        return { ...customField, values: sortBy(options, 'index') };
      }) || [],
      'index',
    );
  }),

  /**
   * @description
   * custom fields hash map
   * key - id
   * value - custom field
   */
  expandedCustomFields: computed((): { [k in number]: CustomFields | nil } => {
    const cfields = distanceSelector.customFields.get();

    return cfields.reduce((expandedCustomFields, customField) => {
      if (customField.id) {
        expandedCustomFields[customField.id] = customField;
      }

      return expandedCustomFields;
    }, {});
  }),
};
