import * as React from 'react';

export const Edit = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.1515 3.84827C19.879 3.57692 19.5554 3.36225 19.1995 3.21666C18.8435 3.07108 18.4623 2.99746 18.0777 3.00007C17.6931 3.00267 17.3129 3.08144 16.959 3.23183C16.605 3.38222 16.2844 3.60125 16.0156 3.87627L4.41596 15.4761L3 21L8.52383 19.5832L20.1235 7.9834C20.3986 7.71471 20.6177 7.39416 20.7681 7.04028C20.9185 6.68639 20.9973 6.3062 20.9999 5.92167C21.0025 5.53715 20.9289 5.15592 20.7833 4.80003C20.6376 4.44415 20.4229 4.12066 20.1515 3.84827V3.84827Z'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M15.6846 4.20825L19.7916 8.31539' stroke='#656B6F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.41699 15.4753L8.52807 19.5793' stroke='#656B6F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
