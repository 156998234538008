import classNames from 'classnames';
import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

import { t } from 'utils';

import { TEAM } from '../../constants';

type Props = {
  getLink: Function;
  withTeam: boolean;
  type: string;
  disabled?: boolean;
  passed?: boolean;
};

export class DistanceType extends React.PureComponent<Props> {
  render() {
    const { type, getLink, withTeam, disabled, passed } = this.props;

    const withLink = (type === TEAM && withTeam && !disabled) || (type === TEAM && withTeam && !disabled && passed);
    const text = type === TEAM ? t('profile.registeredTable.type.team') : t('profile.registeredTable.type.single');

    return (
      <Link
        className={classNames({
          'manage-team-link': withLink,
          'disabled-link': !withLink,
        })}
        to={getLink()}
      >
        {text}
      </Link>
    );
  }
}
