import styled from 'styled-components';

import { SizesText } from '../Typography';
import { ButtonProps } from './types';
import { SizesButton } from './types';

export const getButtonSizes = (size: keyof typeof SizesButton) => {
  switch (size) {
    case SizesButton.xxl:
      return {
        height: 60,
        padding: 16,
        text: SizesText.lg,
        icon: 16,
      };
    case SizesButton.xl:
      return {
        height: 48,
        padding: 16,
        text: SizesText.lg,
        icon: 16,
      };
    case SizesButton.l:
      return {
        height: 44,
        padding: 16,
        text: SizesText.lg,
        icon: 16,
      };
    case SizesButton.md:
      return {
        height: 40,
        padding: 14,
        text: SizesText.md,
        icon: 16,
      };
    case SizesButton.sm:
      return {
        height: 36,
        padding: 14,
        text: SizesText.md,
        icon: 16,
      };
    case SizesButton.xs:
      return {
        height: 28,
        padding: 12,
        text: SizesText.md,
        icon: 16,
      };
    case SizesButton.xxs:
      return {
        height: 20,
        padding: 8,
        text: SizesText.md,
        icon: 12,
      };
    case SizesButton.xxxs:
      return {
        height: 16,
        padding: 8,
        text: SizesText.md,
        icon: 12,
      };
    default:
      return {
        height: 44,
        padding: 16,
        text: SizesText.lg,
        icon: 16,
      };
  }
};

export const BaseButton = styled.button<ButtonProps>`
  display: flex;
  font-family: 'Aeonik';
  border-radius: 4px;
  border: none;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  cursor: pointer;
  letter-spacing: 0.5px;
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  padding: 0 ${(props) => getButtonSizes(props.size).padding}px;
  height: ${(props) => getButtonSizes(props.size).height}px;
  width: ${(props) => (props.fullWidth ? '100%' : '')};
  .left-icon {
    margin-right: 8px;
  }
  .right-icon {
    margin-left: 8px;
  }
`;
