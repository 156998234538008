import { action, observable, makeObservable } from 'mobx';

type ProgressType = 'progress' | 'failed' | 'completed';

class Progress {
  @observable progress: { [K in string]: { status: ProgressType } } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  log(
    action: string,
    status: ProgressType,
    timeout?: {
      requestStartTime: Date;
      minRequestTime: number;
    } | null,
  ) {
    const commit = () => {
      this.commitLog(action, status);
    };

    if (!timeout) {
      commit();
      return;
    }

    const { requestStartTime, minRequestTime } = timeout;
    const endTime = new Date();
    const requestTime = (+requestStartTime - +endTime) / 1000;

    if (requestTime > minRequestTime) {
      commit();
      return;
    }

    setTimeout(() => {
      commit();
    }, minRequestTime - requestTime);
  }

  @action
  commitLog(action: string, status: ProgressType) {
    this.progress[action] = { status: status };
  }

  isLoading(key: string): boolean {
    const progress = this.progress[key];
    return !!(progress && progress.status === 'progress');
  }
}

export { Progress };
export default new Progress();
