import styled from 'styled-components';

import { Button } from 'src/styledComponents/Button';
import { HardElevation } from 'src/styledComponents/Elevation';

import { Form } from 'components/form';

import { windowSize } from 'stores';

const transitionCases = (props) => {
  return props.open ? 'transform 0.5s ease-out' : 'transform 0.5s ease-out, opacity 50ms 0.5s';
};

export const FormSearchWrapper = styled(Form)<{ open: boolean; height: number }>`
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: white;

  will-change: transform;
  transition: ${transitionCases};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transform: ${(props) => (props.open ? 'translateY(0%)' : 'translateY(100%)')};
`;

export const InputFieldsWrapper = styled(HardElevation)`
  margin: 24px 20px 18px;
  border: 1px solid ${(props) => props.theme.main.colors.clay5};
  border-radius: 20px;
`;

export const ChipsWrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;

  span:not(:last-child) {
    margin-right: 9px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  position: absolute;
  bottom: 0;
`;
