import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  align-items: center;

  &::before,
  &::after {
    flex: 1;
    content: '';
    height: 1px;
    background-color: ${(props) => props.theme.miles.colors.dark.alpha['500']};
    margin: 2px 8px;
  }
`;
