import { get, keys, toNumber, last, sortBy, map, isUndefined } from 'lodash';
import { observable, action, makeObservable } from 'mobx';

type Value = { [K in number]: { [K in number]: RacersTeamType[] | RacersType[] | null } };

class DataStore {
  @observable value: Value = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setValues(distanceId: number, page: number, values: RacersType[] | RacersTeamType[]) {
    this.set(distanceId, page, values);
  }

  @action
  clean() {
    this.value = {};
  }

  fetch(distanceId: number, page: number) {
    return get(this.value, [distanceId, page]) || [];
  }

  lastPage(distanceId: number) {
    if (!this.value[distanceId]) {
      return null;
    }

    const pages = this.pages(distanceId);

    return last(pages);
  }

  pages(distanceId: number) {
    if (!this.value[distanceId]) {
      return [];
    }

    return sortBy<number>(map(keys(this.value[distanceId]), toNumber));
  }

  exists(distanceId: number, page: number) {
    return !isUndefined(get(this.value, [distanceId, page]));
  }

  @action
  set(distanceId: number, page: number, value: any) {
    if (!this.value[distanceId]) {
      this.value[distanceId] = {};
    }

    this.value[distanceId][page] = value;
  }
}

export { DataStore };
