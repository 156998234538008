import { debounce } from 'lodash';
import { reaction } from 'mobx';

import { setSportOptions, toggleLocationAutocomplete, toggleRacesAutocomplete } from '../actions';

import { searchService } from '../services';

import { disposersStore, recentLocationStore } from '../stores';

import { searchLocationValues, searchRaceValues, sportValues } from '../selectors';

const debouncedLocation = debounce(() => searchService.getLocation(), 400);
const debouncedRace = debounce(() => searchService.getRaces(), 400);

export const observeLocationAutocomplete = () => {
  const disposer = reaction(searchLocationValues, () => {
    if (Boolean(searchLocationValues())) {
      debouncedLocation();
      toggleLocationAutocomplete(true);
    }
    if (!(Boolean(searchLocationValues()) && Boolean(recentLocationStore.value))) {
      toggleLocationAutocomplete(false);
    }
  });

  disposersStore.register(disposer);
};

export const observeRacesAutocomplete = () => {
  const disposer = reaction(searchRaceValues, () => {
    debouncedRace();
    toggleRacesAutocomplete(true);
  });

  disposersStore.register(disposer);
};

export const observerSports = () => {
  const disposer = reaction(sportValues, (sportValues) => {
    setSportOptions(sportValues);
  });

  disposersStore.register(disposer);
};
