import * as React from 'react';

import { headerStore, onAuthStore } from '../../stores';

import { HeaderSettingsType } from '../../types';

export const useHeaderSettings = (settings: HeaderSettingsType) => {
  React.useEffect(() => {
    headerStore.setValue({
      ...headerStore.value!,
      variant: settings.variant,
      mobileSearch: settings.mobileSearch,
      hide: settings?.hide,
      fullWidth: settings.fullWidth,
      onAfterSearch: settings.onAfterSearch,
    });

    onAuthStore.setValue(settings.onAuth || null);

    return () => {
      headerStore.clear();
      onAuthStore.clear();
    };
  }, []);
};
