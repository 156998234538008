import * as React from 'react';

import { InputWrapComponent, InputComponent } from 'src/styledComponents/InputTools';

import PasswordField from './PasswordField';

export type TextFieldType = BaseInputProps & {
  onlyDigits?: boolean;
  shouldTrim?: boolean;
  additionalComponent?: AnyFunction;
  isWarning?: boolean;
  autoFocus?: boolean;
};

export const TextField = (props: TextFieldType): React.ReactNode | any => {
  const { requiredMark, errorMessage, label, name, value, disabled, type, isWarning, className } = props;

  return (
    <InputWrapComponent
      errorMessage={errorMessage}
      isWarning={isWarning}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={value}
      className={className}
    >
      {type === 'password' ? <PasswordField {...props} /> : <InputComponent {...props} />}
    </InputWrapComponent>
  );
};
