import { isNil } from 'lodash';
import { computed, toJS } from 'mobx';

import { resultsUtil } from '../../utils';

import type { LResult } from '../../types';

/**
 * @description
 * split.result_diff
 * Formula: Split.finish_time - Result.start_time
 * https://imgur.com/NohbQMO
 */
function generateSplitFinishResult(result: LResult, checkpointId: number) {
  return computed(() => {
    const split = resultsUtil.findSplit(result, checkpointId);
    const diff = split?.result_diff;

    if (isNil(diff)) {
      return resultsUtil.formatFallbackSplit();
    }

    return resultsUtil.formatDiff(diff);
  });
}

export { generateSplitFinishResult };
