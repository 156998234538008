import { distanceSelector } from 'modules/registration/selectors/mappedData';

import { AdditionalRegistrationFields, fieldIdStatic, TeamFields, OtherFields, RegistrationFields } from '../constants';

const staticKeys = {
  groupRegistrations: fieldIdStatic.groupRegistrations,
  teamMembers: fieldIdStatic.teamMembers,
  currentDistance: fieldIdStatic.currentDistance,
  terms: fieldIdStatic.terms,
  customFieldsKey: fieldIdStatic.customFieldsKey,
  customFieldValueIdPrefix: fieldIdStatic.customFieldValueIdPrefix,
};
function groupRegistrationWithIndex(index: number) {
  return `${staticKeys.groupRegistrations}.${index}`;
}

function groupRegistrationMemberEmail(index: number) {
  return `${groupRegistrationWithIndex(index)}.${RegistrationFields.email}`;
}

function groupRegistrationCustomField(index: number, fieldId: number) {
  return customFieldsFieldId(groupRegistrationWithIndex(index), fieldId);
}

function registrationLeaderEmail() {
  return groupRegistrationMemberEmail(0);
}

function groupRegistrationMemersExceptLeader() {
  return `${staticKeys.groupRegistrations}\.[1-9]\d*`;
}

function groupRegistrationsCoupon(index: number) {
  return `${groupRegistrationWithIndex(index)}.${AdditionalRegistrationFields.coupon_code}`;
}

function registrationGiftcard() {
  return AdditionalRegistrationFields.giftcard;
}

function groupRegistrationsRefundProtect(index: number) {
  return `${groupRegistrationWithIndex(index)}.${AdditionalRegistrationFields.refund_protect}`;
}

function registrationLeaderRefundProtect() {
  return groupRegistrationsRefundProtect(0);
}

function registrationLeaderPaymentMethod() {
  return `${groupRegistrationWithIndex(0)}.${OtherFields.payment_method}`;
}

function groupRegistrationsOpen(index: number) {
  return `${groupRegistrationWithIndex(index)}.${OtherFields.open}`;
}

function registrationLeaderPassword() {
  return `${groupRegistrationWithIndex(0)}.${OtherFields.password}`;
}

function registrationLeaderPasswordConfirmation() {
  return `${groupRegistrationWithIndex(0)}.${OtherFields.password_confirmation}`;
}

function registrationLeader() {
  return `${groupRegistrationWithIndex(0)}`;
}

function teamMemberWithIndex(index: number) {
  return `${staticKeys.teamMembers}.${index}`;
}

function teamMembersCustomField(index: number, fieldId: number) {
  return customFieldsFieldId(teamMemberWithIndex(index), fieldId);
}

function teamMemberEmail(index: number) {
  return `${teamMemberWithIndex(index)}.${RegistrationFields.email}`;
}

function teamMemberOpen(index: number) {
  return `${teamMemberWithIndex(index)}.${OtherFields.open}`;
}

function teamRegistrationTeamName(): string {
  return TeamFields.team_name;
}

function teamRegistrationClassId(): string {
  return AdditionalRegistrationFields.class_id;
}

function teamRegistrationWaveId() {
  return AdditionalRegistrationFields.wave_id;
}

function customFieldsFieldId(prefix: string | nil, fieldId: number) {
  if (!prefix) {
    return `${fieldIdStatic.customFieldValueIdPrefix}${fieldId}`;
  }

  return `${prefix}.${staticKeys.customFieldsKey}.${fieldIdStatic.customFieldValueIdPrefix}${fieldId}`;
}

function distanceCustomFieldsId(formIndex: number, onlyPriceFields = false) {
  const registrationCustomFields = distanceSelector.customFields.get() || [];
  const keyPrefix = fieldIdUtils.groupRegistrationWithIndex(formIndex);
  return (
    onlyPriceFields
      ? registrationCustomFields.filter((field) => field.values?.some((value) => Number(value.price)))
      : registrationCustomFields
  ).map((field) => fieldIdUtils.customFieldsFieldId(keyPrefix, field.id));
}

function distanceFreeCustomFieldsId(formIndex: number) {
  const registrationCustomFields = distanceSelector.customFields.get() || [];
  const keyPrefix = fieldIdUtils.groupRegistrationWithIndex(formIndex);
  return registrationCustomFields
    .filter((field) => !field.values?.some((value) => Number(value.price)))
    .map((field) => fieldIdUtils.customFieldsFieldId(keyPrefix, field.id));
}

function teamMembersMatcher() {
  return `${staticKeys.teamMembers}`;
}

function registrationLeaderMatcher() {
  return `${groupRegistrationWithIndex(0)}(?!\.${staticKeys.customFieldsKey}|\.${AdditionalRegistrationFields.coupon_code}|\.${
    AdditionalRegistrationFields.refund_protect
  })`;
}

function registrationLeaderCustomFieldsMatcher() {
  return `${groupRegistrationWithIndex(0)}\.${staticKeys.customFieldsKey}`;
}

function registrationLeaderCouponMatcher() {
  return `${groupRegistrationWithIndex(0)}\.${AdditionalRegistrationFields.coupon_code}`;
}

function registrationTermsMatcher() {
  return `${OtherFields.terms}`;
}

function teamMatcher() {
  return `${TeamFields.team_name}|${AdditionalRegistrationFields.class_id}|${AdditionalRegistrationFields.wave_id}`;
}

/**
 * @description
 * Keys that used for the form
 * to fetch and set data into the form
 */
const fieldIdUtils = {
  staticKeys,
  customFieldsFieldId,
  distanceCustomFieldsId,
  distanceFreeCustomFieldsId,

  registrationGiftcard,

  teamMemberWithIndex,
  teamMemberEmail,
  teamMemberOpen,
  teamRegistrationClassId,
  teamRegistrationTeamName,
  teamRegistrationWaveId,
  teamMembersCustomField,

  registrationLeaderEmail,
  registrationLeaderPassword,
  registrationLeaderPasswordConfirmation,
  registrationLeader,
  registrationLeaderRefundProtect,
  registrationLeaderPaymentMethod,

  groupRegistrationWithIndex,
  groupRegistrationMemberEmail,
  groupRegistrationMemersExceptLeader,
  groupRegistrationsCoupon,
  groupRegistrationsRefundProtect,
  groupRegistrationsOpen,
  groupRegistrationCustomField,

  /**
   * @description
   * Specific prefix with regexp syntax
   * to group errors
   */
  matchers: {
    teamMembersMatcher,
    registrationLeaderMatcher,
    registrationLeaderCustomFieldsMatcher,
    registrationLeaderCouponMatcher,
    registrationTermsMatcher,
    teamMatcher,
    groupRegistrationsRefundProtect,
  },
};

export { fieldIdUtils };
