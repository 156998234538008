import { cloneDeep, isString, isNil } from 'lodash';

import { sessionStore } from 'stores';

export const mapDataForPatch = (data: AnyObject = {}): AnyObject => {
  const copy: AnyObject = cloneDeep(data);
  const originalData: AnyObject = sessionStore.session.user;

  return Object.keys(copy).reduce((result: AnyObject, key: string) => {
    if (compareData(key, originalData[key], copy[key])) {
      return result;
    }

    return {
      ...result,
      [key]: copy[key],
    };
  }, {});
};

const compareData = (key: string, originalData: any, currentData: any): boolean => {
  switch (key) {
    case 'emergency_phone':
    case 'telephone':
      if (!originalData && !currentData) {
        return true;
      }

      if (isString(currentData)) {
        return originalData === currentData;
      }

      return originalData === currentData?.phone;

    case 'sports':
      if (originalData?.length !== currentData?.length) {
        return false;
      }

      return originalData.every((sport: AnyObject | any) => !!currentData.find((item: AnyObject) => item?.id === sport?.id));

    case 'external_swimrun_id':
      if (isNil(originalData) && isNil(currentData)) {
        return true;
      }

      return originalData === currentData;

    default:
      if (!originalData && !currentData) return true;
      return originalData === currentData;
  }
};
