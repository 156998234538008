import { SOCIALS_LINKS_EN, SOCIALS_LINKS_SV } from 'src/constants';

import { localeStore } from 'stores';

export const getSocials = (): { [k in 'fb' | 'insta' | 'linked' | 'yt']: string } => {
  if (localeStore.value === 'sv') {
    return SOCIALS_LINKS_SV;
  }

  return SOCIALS_LINKS_EN;
};
