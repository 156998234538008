import { history, encodeQuery } from 'utils';

import { manageTeamStore } from 'stores';

import { toogleCollapse } from '../actions';

import { distancesStore } from '../stores';

function setActiveDistance(id: number) {
  manageTeamStore.setTeamOwner(+id);
  toogleCollapse();

  const query = encodeQuery.parseQuery(history.location.search) || {};
  history.push({
    search: encodeQuery.stringify({
      ...query,
      distance_id: id,
    }),
  });
}

function setFirstActiveDistance() {
  const distances = manageTeamStore?.selected?.distances || [];
  const firstDistance = distances[0];
  const currentDistanceId = distancesStore.selectedDistanceId;

  if (!firstDistance || !!currentDistanceId) {
    return;
  }

  setActiveDistance(firstDistance.id);
}

export { setActiveDistance, setFirstActiveDistance };
