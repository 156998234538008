import { isEmpty } from 'lodash';
import { headerStore } from 'modules/header/stores';
import { generatePath } from 'react-router-dom';

import { HEADER_FORM, ROUTER } from 'src/constants';

import { history } from 'utils';

import { form } from 'stores';

import { Variants } from '../constants';

import {
  locationAutocompleteOpenStore,
  locationStore,
  mobileFiltersDropdownStore,
  mobileModalStore,
  racesAutocompleteOpenStore,
} from '../stores';

import { FieldNames } from '../types';
import { handleFocus, onMobileSearch } from './search';

export const chooseLocationMobile = (location: string) => () => {
  form.onChange(HEADER_FORM, FieldNames.location, location);
  locationAutocompleteOpenStore.setValue(false);
};

export const chooseLocationDesktop = (location: string) => () => {
  form.onChange(HEADER_FORM, FieldNames.location, location);
  locationAutocompleteOpenStore.setValue(false);
  window.requestAnimationFrame(() => {
    handleFocus(FieldNames.sports);
  });
};

export const handleClearLocationSearch = () => {
  form.onChange(HEADER_FORM, FieldNames.location, '');
};

export const chooseRace = (race: string) => () => {
  form.onChange(HEADER_FORM, FieldNames.raceName, race);
  racesAutocompleteOpenStore.setValue(false);
  handleFocus(FieldNames.location);
};

export const navigateRace = (id: number) => () => {
  form.onChange(HEADER_FORM, FieldNames.raceName, '');
  const path = generatePath(ROUTER.RACE_ABOUT_US, {
    id,
  });
  mobileModalStore.setValue(false);
  racesAutocompleteOpenStore.setValue(false);
  history.push(path);
  window.requestAnimationFrame(() => {
    handleFocus('');
  });
};

export const handleClearRaceSearch = () => {
  form.onChange(HEADER_FORM, FieldNames.raceName, '');
};

// start mobileSearch

export const handleMobileSearch = () => {
  mobileModalStore.setValue(false);
  onMobileSearch();
};

export const handleClearSearch = () => {
  form.clean(HEADER_FORM);
};

export const handleClickMobileSearch = () => {
  handleClearSearch();
  mobileModalStore.setValue(true);
};

export const handleToggleSearch = (trigger: boolean) => () => {
  mobileModalStore.setValue(trigger);
};

export const onOpenMobileFilter = (value: string) => () => {
  mobileFiltersDropdownStore.setValue(value);
};

export const onCloseMobileFilter = () => {
  mobileFiltersDropdownStore.setValue(null);
};

// end mobileSearch

export const toggleLocationAutocomplete = (trigger: boolean) => {
  locationAutocompleteOpenStore.setValue(trigger);
};

export const toggleRacesAutocomplete = (trigger: boolean) => {
  racesAutocompleteOpenStore.setValue(trigger);
};

export const onBlurLocationInput = () => {
  !isEmpty(locationStore.value) && form.onChange(HEADER_FORM, FieldNames.location, locationStore.value![0].city);
  locationAutocompleteOpenStore.setValue(false);
};
