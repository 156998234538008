import { mapZoomStore, mapStore } from '../stores';

export const handleChangeMap = (changes: { zoom: number; bounds: AnyObject }) => {
  mapZoomStore.setValue(changes.zoom);
};

export const handleMountMap = (map: AnyObject) => {
  mapStore.setValue(map);
};

export const handleIncreaseZoom = () => {
  mapZoomStore.setValue(mapZoomStore.value! + 1);
};

export const handleDecreaseZoom = () => {
  mapZoomStore.setValue(mapZoomStore.value! - 1);
};
