import { Observer } from 'mobx-react';
import React, { FC, useRef, useState, useEffect } from 'react';
import Cropper from 'react-cropper';
import ReactCropper from 'react-cropper';
import Swipe from 'react-easy-swipe';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import { Loading } from 'src/components/loading';
import { Modal } from 'src/components/modal';
import { RESOLUTIONS, USER } from 'src/constants';
import { Overlay as OverlayStore, windowSize } from 'src/stores';
import { Button } from 'src/styledComponents/Button';
import { Divider } from 'src/styledComponents/Divider';
import { Headline } from 'src/styledComponents/Typography';
import { t, unblockBodyScroll, blockBodyScroll } from 'src/utils';

type Props = {
  open: boolean;
  src: string;
  closeModal: () => void;
  changePreviewUrl: (file: string) => void;
  overlayStore?: OverlayStore;
};

const Wrapper = styled.div<{ isSwipeEnd: boolean }>`
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  padding: 0 20px 40px;
  border-radius: 24px 24px 0 0;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 628px;
    padding: 32px 40px;
    border-radius: 24px;
  }

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }

  .loading-overlay {
    .spinner {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    justify-content: space-between;
  }
`;

const StyledHeadline = styled(Headline)`
  margin: 0;
  text-transform: uppercase;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
`;

const Content = styled.div`
  margin-top: 40px;
  min-height: 290px;
  text-align: center;
  .cropper-view-box {
    border-radius: 50%;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  height: 56px;
  button {
    margin-left: 32px;
    span {
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: ${RESOLUTIONS.medium}px) {
      margin-left: 0;
    }
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-top: 48px;
  }
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

export const AvatarModalComponent: FC<Props> = (props) => {
  const [isSwipeEnd, setIsSwipeEnd] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const cropperRef = useRef<ReactCropper>(null);

  useEffect(() => {
    if (props.open) {
      blockBodyScroll();
    }

    return () => {
      unblockBodyScroll();
    };
  }, [props.open]);

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const handleTransitionEnd = () => {
    handleClickOutside();
  };

  const handleClickOutside = (): void => {
    const { closeModal, overlayStore } = props;
    closeModal();
    overlayStore && overlayStore.hide();
  };

  const handleComponentType = (): React.ReactNode => {
    return (
      <Cropper ref={cropperRef} aspectRatio={1} src={props.src || ''} style={{ height: 400, width: '100%', zIndex: 1 }} viewMode={1} />
    );
  };

  const onSubmit = async () => {
    const cropperImage: any = cropperRef?.current;
    const file = cropperImage?.cropper
      //@ts-ignore
      .getCroppedCanvas()
      .toDataURL('image/jpeg', 0.7);

    props.changePreviewUrl(file);

    handleClickOutside();
  };

  return (
    <Observer>
      {() => {
        const isMobile = windowSize.isLessThan('medium');

        return (
          <Modal open={props.open}>
            <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={handleTransitionEnd}>
              {isMobile && (
                <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
                  <DraggableStroke />
                </Swipe>
              )}

              <Loading action={USER} opacity={1} />
              <Header>
                <StyledHeadline variant='h4'>{t('settings.selectingMiniature')}</StyledHeadline>
              </Header>
              <StyledDivider light />
              <Content>{handleComponentType()}</Content>
              <Controls>
                {!isMobile && (
                  <Button variant='secondary' onClick={handleClickOutside} size='medium' ATN='image-upload-cancel'>
                    {t('settings.cancel')}
                  </Button>
                )}
                <Button variant='primary' size='medium' onClick={onSubmit} fullWidth={isMobile} ATN='image-upload-submit'>
                  {t('settings.save')}
                </Button>
              </Controls>
            </Wrapper>
          </Modal>
        );
      }}
    </Observer>
  );
};
