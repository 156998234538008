import { generateIsViewportFilled } from 'modules/racersTable/utils';

import { STARTLIST_CONTAINER_ID } from '../constants';

const isViewportFilled = generateIsViewportFilled(STARTLIST_CONTAINER_ID, {
  viewportQuantator: 1.5,
  loaderHeight: 201,
});

const isViewportFilledEnough = generateIsViewportFilled(STARTLIST_CONTAINER_ID, {
  viewportQuantator: 3,
  loaderHeight: 201,
});

export { isViewportFilled, isViewportFilledEnough };
