import * as React from 'react';

import { FirstCircleBox, SecondCircleBox, StyledIcon, ThirdCircleBox, Wrapper } from './styled';

export const PulseLoader = () => {
  return (
    <Wrapper>
      <StyledIcon name='PulseLoader' size={86} />
      <ThirdCircleBox />
      <SecondCircleBox />
      <FirstCircleBox />
    </Wrapper>
  );
};

export const PulseLoaderNoWrapper = () => {
  return (
    <>
      <StyledIcon name='PulseLoader' size={44} />
      <ThirdCircleBox />
      <SecondCircleBox />
      <FirstCircleBox />
    </>
  );
};
