import { action, observable, makeObservable } from 'mobx';

class CounterStore {
  @observable counter: number = 0;

  constructor() {
    makeObservable(this);
  }

  getCounter() {
    return this.counter;
  }

  @action
  setCounter(counter) {
    this.counter = counter;
  }
}

export { CounterStore };
