import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { InputWrapComponent } from '../inputWrap';
import { separatedTimeStore } from './store';

const placeholders = {
  hours: 'hh',
  minutes: 'mm',
  seconds: 'ss',
};

@observer
export class SeparatedTime extends React.Component<BaseInputProps> {
  constructor(props: BaseInputProps) {
    super(props);
    separatedTimeStore.parseString(props.value);
  }

  getInputProps = (fieldName: 'hours' | 'minutes' | 'seconds') => {
    const { name, disabled, readOnly, id } = this.props;

    return {
      className: 'input-component',
      type: 'number',
      placeholder: placeholders[fieldName],
      name: `${name}:${fieldName}`,
      value: separatedTimeStore.time[fieldName],
      onChange: this.handleChange(fieldName),
      id,
      disabled,
      readOnly,
    };
  };

  handleChange =
    (field: 'hours' | 'minutes' | 'seconds') =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { onChange, name } = this.props;

      let stateValue: string | number = +e.currentTarget.value;

      if (stateValue > 59 && field !== 'hours') stateValue = 59;
      if (stateValue < 0) stateValue = 0;
      if (isEmpty(e.currentTarget.value)) {
        stateValue = '';
      }

      separatedTimeStore.setTime({
        [field]: stateValue,
      });

      const { minutes = 0, seconds = 0 } = separatedTimeStore.time;
      const hours = separatedTimeStore.time.hours || 0;
      const time = moment()
        .startOf('day')
        .set({ minute: +minutes, second: +seconds })
        .format('mm:ss');

      onChange({ name, value: `${hours.toString().length >= 2 ? hours : '0' + hours}:${time}` }, e);
    };

  render() {
    const { requiredMark, errorMessage, label, name, value, disabled } = this.props;

    return (
      <InputWrapComponent
        errorMessage={errorMessage}
        disabled={disabled}
        label={label}
        requiredMark={requiredMark}
        name={name}
        value={value}
      >
        <div className='separated-time'>
          <input {...this.getInputProps('hours')} {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.fieldPart, 'hours')} />
          <div className='divider'>:</div>
          <input {...this.getInputProps('minutes')} {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.fieldPart, 'minutes')} />
          <div className='divider'>:</div>
          <input {...this.getInputProps('seconds')} {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.fieldPart, 'seconds')} />
        </div>
      </InputWrapComponent>
    );
  }
}
