import { RACE_AUTH_OVERLAY } from 'src/constants';

import { overlayStore, progressStore } from 'stores';

function onOpen() {
  overlayStore.show();
  progressStore.log(RACE_AUTH_OVERLAY, 'progress');
}

export { onOpen };
