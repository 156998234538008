import { includes } from 'lodash';
import { DistanceType } from 'modules/home/types';

import { DISTANCE_MODES } from 'src/constants';

import { segmentIgnition } from 'actions';

import { openResultForm } from './list';
import { reloadDistance } from './reloadDistance';

function onAddResult(value: DistanceType) {
  return () => {
    const distanceId = value.id;
    const racerId = value.racers[0].id;

    if (value.distance_mode !== DISTANCE_MODES.cumulative) {
      return openResultForm(racerId)();
    }

    segmentIgnition.switchFirstNew(distanceId, racerId, async (resposeState, type) => {
      if (!includes(['success', 'conflict'], resposeState)) {
        return;
      }

      reloadDistance(distanceId);
    });
  };
}

export { onAddResult };
