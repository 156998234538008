import { form, progressStore } from 'stores';

import { FORM_ID, LOADER } from '../constants';

import { createService } from '../services';

import { callbackStore, racerIdStore, modeStore } from '../stores';

import { formToBeSegmentMapper, mapToValidate, deleteEmptyActivityLink } from '../mapper';
import { FormSegment } from '../types';
import { ignition } from './ignition';
import { validate } from './validate';

async function createSegment(): Promise<boolean> {
  const formData = form.fetch<FormSegment>(FORM_ID);
  const racerId = racerIdStore.value;
  const isValid = validate('create', mapToValidate(formData));
  const callback = callbackStore.value;
  const data = deleteEmptyActivityLink(formToBeSegmentMapper.mapTo(formData));
  const start = new Date();

  if (!isValid || !racerId || !data) {
    return false;
  }

  progressStore.log(LOADER, 'progress');

  const [status, state] = await createService.ignite(racerId, data);

  await progressStore.log(LOADER, 'completed', {
    requestStartTime: start,
    minRequestTime: 200,
  });

  callback && (await callback(state, modeStore.value));

  (state === 'success' || state === 'conflict') && ignition.switchOff();

  return status;
}

export { createSegment };
