import React from 'react';
import { use100vh } from 'react-div-100vh';
import styled, { css } from 'styled-components';

import { NavLink } from 'src/styledComponents/Link';
import { Utility } from 'src/styledComponents/Typography';

export const StyledLogoLink = styled.div`
  max-height: 32px;
`;

const ScrollableStyledWrapper = styled('div')(
  ({ height100Vh }: { height100Vh: number | null }) => css`
    overflow-y: scroll;
    max-height: calc(${height100Vh}px - 187px);
    padding-top: 32px;
    padding-bottom: 20px;
  `,
);

export const ScrollableWrapper = (props: any) => {
  const height = use100vh() || 0;
  return <ScrollableStyledWrapper height100Vh={height} {...props} />;
};

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export const StyledUtility = styled(Utility)`
  text-transform: none;
`;

export const MainWrapper = styled.div`
  padding: 32px 0px;
`;
