import * as React from 'react';

export const ArrowDownSmall = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 6L8.18844 9.91964C8.16371 9.94512 8.13434 9.96533 8.102 9.97911C8.06967 9.9929 8.035 10 8 10C7.965 10 7.93033 9.9929 7.898 9.97911C7.86566 9.96533 7.83629 9.94512 7.81156 9.91964L4 6'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
