import { observable, action, makeObservable } from 'mobx';

import type { LResult } from '../types';

/**
 * @description
 * First result for each distance - response.data[0]
 */
type Value = { [K in number]: LResult };

class TimeLeadersStore {
  @observable
  value: Value = {};

  constructor() {
    makeObservable(this);
  }

  @action
  register(distanceId: number, value: LResult) {
    this.value[distanceId] = value;
  }

  clean() {
    this.value = {};
  }

  fetch(id: number) {
    return this.value[id];
  }
}

export { TimeLeadersStore };
