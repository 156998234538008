import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { getDefaultCheckpoint } from '../utils/getDefaultCheckpoint';

import { checkpointsStore, filtersStore } from '../stores';

const generateCheckpointId = (distanceId: number): IComputedValue<null | number> => {
  return computed((): null | number => {
    const selectedDistanceId = filtersStore.value.distance_id;
    const selectedCheckpointId = filtersStore.value.checkpoint_id;

    const checkpoint = checkpointsStore.find(+selectedCheckpointId!);

    if (selectedDistanceId === distanceId) {
      return checkpoint?.value.id || null;
    }

    return getDefaultCheckpoint(distanceId);
  });
};

export { generateCheckpointId };
