import { DEFAULT_LOCALE } from 'src/constants';

import { messages } from 'utils';

import { messagesStore } from 'stores';

import { locale } from '../locales';

/*
 * Simply fetch messages from local store and set it to be used in translations
 */
async function set(value: availableLocales): Promise<boolean> {
  const translationMessages = messages.fetch(value, DEFAULT_LOCALE);

  if (!!translationMessages) {
    await messagesStore.setValue(translationMessages);
    await locale.update(value);

    return true;
  }

  return false;
}

export { set };
