import { windowSize } from 'stores';

import { SEARCH_ID } from '../../constants';

import { isFindInterceptionHandled, filtersOpenedStore } from '../../stores';

function handle() {
  isFindInterceptionHandled.off();
  navigateToSearch();
}

function navigateToSearch() {
  const searchInput = document.getElementById(SEARCH_ID);

  if (!searchInput || isFindInterceptionHandled.value) {
    return;
  }

  if (windowSize.isLessThan('large')) {
    filtersOpenedStore.on();
  }

  searchInput.focus();
  isFindInterceptionHandled.on();
}

export const findInterceptor = {
  handle,
  navigateToSearch,
};
