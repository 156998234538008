import { Card as PastSessionCard } from 'modules/miles/shared/components';
import { Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';

import { history } from 'utils';

const StyledCard = styled(PastSessionCard)<{ onClick?: AnyFunction }>`
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: ${({ theme }) => theme.miles.colors.dark.alpha['900']};
  .box {
    display: flex;
    padding: 4px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
  }
  .divider {
    width: 1px;
    height: 60px;
    background: ${({ theme }) => theme.miles.colors.dark.alpha['500']};
  }
`;

export type SessionsType = {
  totalSessions: number;
  totalTrainedHours: number;
};

export const Sessions: React.FC = ({ ...rest }) => {
  return (
    <StyledCard
      onClick={() => {
        history.push(ROUTER.MILES_METRICS_SESSION_HISTORY);
      }}
    >
      <Text size='lg' weight='bold' style={{ textAlign: 'center', width: '100%' }}>
        See Past Sessions
      </Text>
    </StyledCard>
  );
};
