import { Observer } from 'mobx-react';
import * as React from 'react';

import { MobileHeader, MobileDistanceHeader } from 'src/modules/racersTable/components';

import { MobileRacerList } from '../../components/Mobile';
import { UsersCount } from '../../components/UsersCount';

import { currentDistance } from '../../derivations';

export const MobileList = () => {
  return (
    <Observer>
      {() => {
        const distance = currentDistance.model.get();
        if (!distance) return null;

        return (
          <>
            <MobileHeader isPlace isTeam={distance.value.type === 'team'} participantsCount={<UsersCount />} />
            <MobileDistanceHeader name={distance.title()} />
            <MobileRacerList distanceId={distance.value.id} type={distance.value.type} />
          </>
        );
      }}
    </Observer>
  );
};
