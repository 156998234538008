const MOBILE_ELEMENT = 'mobile-blocked-scroll';

function blockBodyScroll() {
  const body = document.body;

  if (!body) {
    return;
  }

  body.style.overflow = 'hidden';
}

function unblockBodyScroll() {
  const body = document.body;

  if (!body) {
    return;
  }

  body.style.overflow = 'auto';
}

const generateMobileScrollBlocker = () => {
  let _clientY = 0;
  let element: any = null;

  function listenBlockMobileBodyScroll() {
    // Listen scroll for the element
    element = document.getElementById(MOBILE_ELEMENT); // Init element
    if (!element) {
      return;
    }

    element.addEventListener('touchstart', _touchstartListener, false);
    element.addEventListener('touchmove', _touchmoveListener, false);
  }

  function unlistenBlockMobileBodyScroll() {
    // Unlisten scroll for the element
    const element = document.getElementById(MOBILE_ELEMENT);
    if (!element) {
      return;
    }

    element.removeEventListener('touchstart', _touchstartListener);
    element.removeEventListener('touchmove', _touchmoveListener);
  }

  function _touchstartListener(event: any): any {
    if (event.targetTouches.length === 1) {
      _clientY = event.targetTouches[0].clientY;
    }
  }

  function _touchmoveListener(event: any): any {
    if (event.targetTouches.length === 1) {
      _disableRubberBand(event);
    }
  }

  function _disableRubberBand(event: any) {
    if (!element) {
      return;
    }
    const clientY = event.targetTouches[0].clientY - _clientY;
    if (element.scrollTop === 0 && clientY > 0) {
      event.preventDefault();
    }
    if (_isOverlayTotallyScrolled() && clientY < 0) {
      event.preventDefault();
    }
  }

  function _isOverlayTotallyScrolled() {
    if (!element) {
      return;
    }
    return element.scrollHeight - element.scrollTop <= element.clientHeight;
  }

  return [listenBlockMobileBodyScroll, unlistenBlockMobileBodyScroll];
};

export { blockBodyScroll, unblockBodyScroll, generateMobileScrollBlocker };
