import moment from 'moment';
import * as React from 'react';

import { SNACK_TYPE } from 'src/constants';

import { CountdownTimer } from 'components/countdownTimer';

import { paymentsService } from 'services';

import { snackStore, paymentsStore } from 'stores';
import { sessionStore } from 'stores';

import { Races as RacesService } from './races';

const MAIN_LOOP_DELAY = 8000;

// Azm jesm DEPRECATED
// NOTE, DO NOT USE IT
// TODO< remove after unused
class PaymentsHandler {
  raceId: number;
  distanceId: number | nil;
  service: RacesService;
  isLoopActive: boolean;
  pendingPayments: {
    [K in string]: string;
  };

  constructor(raceId: number, service: RacesService, distanceId?: number | nil) {
    this.raceId = raceId;
    this.distanceId = distanceId;
    this.service = service;
    this.pendingPayments = {};
    this.isLoopActive = true;
    this.mainLoop({ preloader: true, initial: true });
  }

  async mainLoop(
    options: {
      preloader?: boolean;
      initial: boolean;
    } = { preloader: false, initial: false },
  ) {
    if (!this.isLoopActive) {
      return;
    }

    if (options.initial) {
      await paymentsService.getPaymentConfig();
    }

    await this._generatePendingPayments();
    if (options.preloader) {
      await this.loadDataWithPreloader();
    } else {
      await this.loadData();
    }

    await this._clearPendingPayment();

    if (!sessionStore.isAuth) {
      return;
    }

    setTimeout(this.mainLoop.bind(this), MAIN_LOOP_DELAY);
  }

  setDistance(id: number) {
    this.distanceId = id;

    this._generatePendingPayments();
  }

  pendingDistances() {
    const race = this.service.store.modelSelectedValue;

    if (!race) {
      return [];
    }

    const distances = race.modelDistances();

    return distances.filter((value: AnyObject) => !value.value.already_registered && value.isTherePendingPayment());
  }

  successfulDistances() {
    const race = this.service.store.modelSelectedValue;

    if (!race) {
      return [];
    }

    const distances = race.modelDistances();

    return distances.filter((value: AnyObject) => value.value.already_registered);
  }

  async loadDataWithPreloader() {
    const { raceId } = this;
    await this.service.loadResource(`${raceId}`, {}, false);
  }

  async loadData() {
    const { raceId } = this;
    await this.service._loadResource(`${raceId}`, false);
  }

  clear() {
    this.isLoopActive = false;
  }

  clearSnacks() {
    this.pendingPayments = {};
  }

  _generatePendingPayments() {
    const { distanceId } = this;

    const pendingDistances = this.pendingDistances();

    if (distanceId) {
      const neededDistance = pendingDistances.find((item: AnyObject) => item.value.id === distanceId);

      if (neededDistance) {
        neededDistance.isTherePendingPayment() && this._addPendingPaymentSnack(neededDistance.value);
      }

      return;
    }

    pendingDistances.forEach((item: AnyObject) => {
      item.isTherePendingPayment() && this._addPendingPaymentSnack(item.value);
    });
  }

  _clearPendingPayment() {
    const pendingDistances = this.pendingDistances();
    const successfulDistances = this.successfulDistances();
    const pendingDistancesIds = pendingDistances.map((el: AnyObject) => el.value.id.toString());
    const successfulDistancesIds = successfulDistances.map((el: AnyObject) => el.value.id.toString());

    for (let [distanceId, snackId] of Object.entries(this.pendingPayments)) {
      if (pendingDistancesIds.includes(distanceId)) {
        continue;
      }

      if (successfulDistancesIds.includes(distanceId)) {
        this._changeToSuccessPaymentSnack(distanceId, snackId);
        continue;
      }

      this._removePendingPaymentSnack(distanceId, snackId);
    }
  }

  _addPendingPaymentSnack(distance: DistanceType) {
    const distanceId = distance.id.toString();

    if (!this.pendingPayments[distanceId]) {
      const currentPaymentIndex = (distance.registrations?.length || 0) - 1;
      const currentPayment = (distance.registrations || [])[currentPaymentIndex];

      const expDate = this._generatePaymentExpirationDate(currentPayment.created_at);

      const snackId = snackStore.warn({
        i18key: 'registration.payment.pending',
        payload: {
          body: <CountdownTimer className='d-inline-block' expireDate={expDate} onExpireCallback={this.loadData.bind(this)} />,
        },
      });
      this.pendingPayments[distanceId] = snackId;
    }
  }

  _changeToSuccessPaymentSnack(distanceId: string, snackId: string) {
    const title = {
      i18key: 'registration.payment.success',
      i18params: null,
    };

    if (this.pendingPayments[distanceId]) {
      delete this.pendingPayments[distanceId];
      snackStore.updateValue(snackId, {
        title,
        type: SNACK_TYPE.success,
        body: null,
      });
      snackStore.removeWithTimeout(snackId);
    }
  }

  _removePendingPaymentSnack(distanceId: string, snackId: string) {
    if (this.pendingPayments[distanceId]) {
      delete this.pendingPayments[distanceId];
      snackStore.removeValue(snackId);
    }
  }

  _generatePaymentExpirationDate(createdAt: string): moment.Moment {
    const mCreatedAt = moment.utc(createdAt);
    const expTtlMinutes = paymentsStore.registrationConfigs.registration_ttl;
    const expTtlSeconds = 60 - mCreatedAt.seconds();

    const expDate = mCreatedAt.clone();
    expDate.add(expTtlMinutes, 'minutes');
    expDate.add(expTtlSeconds, 'seconds');

    return expDate;
  }
}

export { PaymentsHandler };
