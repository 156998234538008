import { last } from 'lodash';

import { checkpointsStore } from '../stores';

import { validateData } from './validateData';

/*
 * Return loaded default checkpoint if it is valid
 * return LAST checkpoint if no loadedDefaultCheckpoint
 */
function getDefaultCheckpoint(distanceId: number, loadedDefaultCheckpoint?: number | nil): number | null {
  if (validateData.isValidCheckpoint(loadedDefaultCheckpoint)) {
    return loadedDefaultCheckpoint || null;
  }

  return last(checkpointsStore.findCheckpointsByDistance(distanceId))?.value?.id || null;
}

export { getDefaultCheckpoint };
