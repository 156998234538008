import { observer } from 'mobx-react';
import * as React from 'react';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { Divider } from 'src/styledComponents/Divider';
import TextAreaField from 'src/styledComponents/TextAreaField/TextAreaField';
import { TimeField, TimeFieldVariants } from 'src/styledComponents/TimeField';
import { Utility } from 'src/styledComponents/Typography';

import { withBlockedScroll } from 'hocs';

import { Form, InputWrapper } from 'components/form';
import { Loading } from 'components/loading';

import { t } from 'utils';

import { windowSize } from 'stores';

import { MY_RESULTS_FORM } from '../constants';

import { closeResultForm, submitResult, editResult, onBlurLink, unmountForm, mountForm } from '../actions';

import { resultStore } from '../stores';

type Props = {
  loadHandler?: (distanceId: number) => Promise<void>;
};

const ModalContent = styled.div<{ isSwipeEnd: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;

  border-radius: 24px 24px 0 0;
  padding: 0 20px 40px 20px;

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }

  .loading-overlay {
    .spinner {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  textarea::-webkit-input-placeholder {
    text-transform: lowercase;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 32px 40px 40px;
    border-radius: 24px;
    width: 628px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  align-items: center;

  > :not(:last-of-type) {
    margin-bottom: 8px;
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    align-items: flex-start;
  }
`;

const TitleUtility = styled(Utility)`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.01em;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
`;

const DistanceNameUtility = styled(Utility)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

const StyledForm = styled.div`
  margin-top: 30px;
  > :not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  height: 56px;
  button {
    margin-left: 0;
    span {
      font-size: 20px;
      line-height: 24px;
    }

    @media (min-width: ${RESOLUTIONS.medium}px) {
      margin-left: 32px;
    }
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-top: 40px;
  }
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

@withTestAnchorContext('Profile.ResultsForm')
@withBlockedScroll({ desktopOnly: false })
@observer
export class ResultsForm extends React.Component<Props> {
  state = {
    isSwipeEnd: false,
  };

  ref = React.createRef<HTMLDivElement>();

  UNSAFE_componentWillMount() {
    mountForm();
  }

  componentWillUnmount(): void {
    unmountForm();
  }

  handleMove = ({ y: value }: { y: number }) => {
    if (!this.ref.current || value < 0) return;

    this.ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  handleSwipeDown = () => {
    if (!this.ref.current) return;

    this.ref.current.style.setProperty('transform', `translate3d(0, ${this.ref.current.clientHeight}px, 0)`);
    this.setState({ isSwipeEnd: true });
  };

  handleSwipeStart = () => {
    this.setState({ isSwipeEnd: false });
  };

  render() {
    const { loadHandler } = this.props;
    const isEdit = resultStore.isEdit;
    const title = isEdit ? 'profile.resultForm.editTitle' : 'profile.resultForm.title';
    const load = loadHandler;
    const submit = isEdit ? editResult(load) : submitResult(load);
    const distance = resultStore.selectedDistance;
    const isMobile = !windowSize.isGreaterThan('medium');
    return (
      <Form id={MY_RESULTS_FORM} disableAutofill>
        <ModalContent ref={this.ref} isSwipeEnd={this.state.isSwipeEnd} onTransitionEnd={closeResultForm}>
          {isMobile && (
            <Swipe
              className='swipe-zone'
              onSwipeMove={this.handleMove}
              onSwipeDown={this.handleSwipeDown}
              onSwipeStart={this.handleSwipeStart}
            >
              <DraggableStroke />
            </Swipe>
          )}

          <Loading action={MY_RESULTS_FORM} opacity={1} />

          <Header>
            <TitleUtility variant='u1' weight='bold'>
              {t.staticAsString('profile.resultForm.logDistance')}
            </TitleUtility>
            <DistanceNameUtility variant='u1' weight='medium'>
              {distance.name}
            </DistanceNameUtility>
          </Header>
          <StyledDivider light />
          <StyledForm>
            <InputWrapper
              name='activity_link'
              settings={{
                label: t.staticAsString('profile.resultForm.activity_link'),
                placeholder: t.staticAsString('profile.resultForm.placeholder.activity_link'),
                onBlur: onBlurLink(MY_RESULTS_FORM, 'activity_link'),
              }}
              Component={TextAreaField}
            />
            <InputWrapper
              name='time_result'
              settings={{
                label: t.staticAsString('profile.resultForm.title.logResults'),
                placeholder: t.staticAsString('profile.resultForm.placeholder.time_result'),
                requiredMark: true,
                withMilliseconds: true,
                variant: TimeFieldVariants.separated,
              }}
              Component={TimeField}
            />
          </StyledForm>
          <Controls>
            {!isMobile && (
              <Button variant='secondary' onClick={closeResultForm} size='medium' ATN='cancel'>
                {t('settings.cancel')}
              </Button>
            )}
            <Button variant='primary' size='medium' onClick={submit} fullWidth={isMobile} ATN='submit'>
              {t('settings.save')}
            </Button>
          </Controls>
        </ModalContent>
      </Form>
    );
  }
}
