import { TestAnchorContext, testAnchors, TEST_ANCHORS, withTestAnchorContext } from 'src/global/TestAnchors';

// @ts-ignore
global.TestAnchorContext = TestAnchorContext;

// @ts-ignore
global.testAnchors = testAnchors;

// @ts-ignore
global.TEST_ANCHORS = TEST_ANCHORS;

// @ts-ignore
global.withTestAnchorContext = withTestAnchorContext;
