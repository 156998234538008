import { css } from 'styled-components';

const requireAll = (r: any) => {
  return r.keys().reduce((initial: AnyObject, acc: string | any) => {
    const label = (acc.match(/^.*\/(.*?)\..*/) || [])[1];
    return { ...{ [label]: r }, ...initial };
  }, {});
};

const constants = requireAll(require.context('flag-icon-css/flags/4x3', true, /^.*svg$/));

export const getFlagsCSS = () => {
  let styles = '';

  for (const property in constants) {
    const src = constants[property];
    const imagePath = src(`./${property}.svg`);
    styles += `
        .${property} {
            background: url('${imagePath}') no-repeat !important;
            height: 16px !important;
            width: 21px !important;
        }
        `;
  }

  return css`
    ${styles}
  `;
};
