import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { LOAD_COUNTER, API_URL, API_RACERS_COUNTER } from 'src/constants';

import { request, action } from 'utils';

class CounterService {
  @request({ action: LOAD_COUNTER })
  async loadRequest(raceId: number, params: FiltersType | {}): Promise<any> {
    const url = generatePath(API_URL + API_RACERS_COUNTER, { id: raceId });
    return axios.get(url, { params });
  }

  @action({ action: LOAD_COUNTER, minRequestTime: 800 })
  async load(raceId: number, params: {}): Promise<number> {
    const [isOk, response] = await this.loadRequest(raceId, params);
    const data = response?.data?.count;
    if (!isOk || !data) {
      return 0;
    }
    return data;
  }
}

export { CounterService };
