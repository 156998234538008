import { isNull } from 'lodash';

import { duration } from 'utils';

import { PipeValue, Pipe } from '../mask';
import { maxHours, maxMinutes, maxSeconds } from './constants';

const defaultMaxValue = { HH: maxHours, MM: maxMinutes, SS: maxSeconds };
const formatOrder = ['HH', 'MM', 'SS'];
type maxValueKeys = keyof typeof defaultMaxValue;

function createAutoCorrectTimeDurationPipe(dateFormat: string = 'HH:MM:SS', maxHours?: number): Pipe {
  const maxValue = { HH: maxHours || defaultMaxValue.HH, MM: maxMinutes, SS: maxSeconds };
  const dateFormatArray = dateFormat.split(/[^HMS]+/).sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b));
  // NOTE, dateFormatArray = ['HH', 'MM','SS'];

  return function (conformedValue: string): PipeValue {
    const indexesOfPipedChars: Array<any> = [];
    const conformedValueArr = conformedValue.split('');

    // Autocorrect if value > max value e.g
    // data: '--', input: '9-' result - '09', inputed char: 9
    // data: '-7', input: '87' result - '08', inputed char: 8
    dateFormatArray.forEach((format) => {
      const position = dateFormat.indexOf(format);
      const maxFirstDigit = parseInt(maxValue[format as maxValueKeys].toString().substr(0, 1), 10);

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position];
        conformedValueArr[position] = '0';
        indexesOfPipedChars.push(position);
      }
    });

    const isInvalid = dateFormatArray.some((format) => {
      const position = dateFormat.indexOf(format);
      const maxDigit = maxValue[format as maxValueKeys];
      if (parseInt(conformedValue.substr(position, format.length), 10) > maxDigit) {
        return true;
      }
    });
    if (isInvalid) {
      return false;
    }

    return {
      value: conformedValueArr.join(''),
      indexesOfPipedChars,
    };
  };
}

function parseTimeDurationToDiff(mask: string): null | void | number {
  if (mask === '') {
    return null;
  }

  const diff = duration.diff(mask);

  if (isNull(diff)) {
    return undefined;
  }

  return diff;
}

export { createAutoCorrectTimeDurationPipe, parseTimeDurationToDiff };
