import { Observer } from 'mobx-react';
import { handleOpenSelector } from 'modules/languageSelector/actions';
import { handleClickMobileSearch, handleToggleSearch, handleClearSearch } from 'modules/search/actions';
import { MobileSearch } from 'modules/search/containers';
import * as React from 'react';

import { handleOpenSidebar } from 'src/modules/Sidebar/actions';
import { localeStore, windowSize } from 'src/stores';

import { Show } from 'components/condition';

import { Header as HeaderComponent, MobileHeader, TabletHeader } from '../components';

import { NAVIGATION_OPTIONS } from '../constants';

import { headerStore } from '../stores';

import { authProps, isMiles } from '../selectors';

export const Header = () => {
  const language = {
    value: localeStore.value,
    onClick: () => handleOpenSelector(),
  };

  return (
    <TestAnchorContext.Provider value={{ container: 'Header' }}>
      <Observer>
        {() => {
          const breakpoint = isMiles.get() ? 600 : 900;
          return (
            <>
              <Show if={windowSize.value.width >= breakpoint}>
                <HeaderComponent
                  navList={NAVIGATION_OPTIONS}
                  language={language}
                  auth={authProps.get()}
                  hide={headerStore.value!.hide}
                  fullWidth={headerStore.value!.fullWidth}
                />
              </Show>

              <Show if={windowSize.value.width < breakpoint}>
                <MobileSearch />
                <MobileHeader
                  onClickSearch={handleClickMobileSearch}
                  onClickBurger={handleOpenSidebar}
                  hide={headerStore.value!.hide}
                  mobileSearch={headerStore.value!.mobileSearch}
                  onFocusSearch={handleToggleSearch(true)}
                  handleClear={handleClearSearch}
                />
              </Show>
            </>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};
