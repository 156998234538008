// Various BE time and date formats
// to parse and format the date;

export const BE_DATE_FORMAT = 'YYYY-MM-DD';
export const BE_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const BE_DATETIME_MS_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';

export const BE_FORMAT: {
  [K in BEDateFormat]: string;
} = {
  date: BE_DATE_FORMAT,
  datetime: BE_DATETIME_FORMAT,
  datetimems: BE_DATETIME_MS_FORMAT,
};

// FE Formats

export const FE_DATE_FORMAT = 'DD MMM YYYY';
export const FE_DATE_DAY_MONTH_FORMAT = 'DD MMM';
export const FE_TIME_FORMAT = 'HH:mm A';
export const FE_TIME_ZONE_FORMAT = 'Z';
