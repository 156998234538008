import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const ClosePath = (
  <>
    <path d='M4.5 19.5L19.5 4.5' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.5 4.5L19.5 19.5' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </>
);

export const Close = (props: IconProps) => <SvgIcon path={ClosePath} {...props} />;
