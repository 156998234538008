import { Observer } from 'mobx-react';
import { FieldNames } from 'modules/search/types';
import React from 'react';

import { HEADER_FORM } from 'src/constants';

import { Form, InputWrapper } from 'components/form';

import { t, useOnClickOutside } from 'utils';

import { handleFocus, onSearch } from '../../actions';

import { focusedStore } from '../../stores';

import { SearchItemDesktop } from '../searchFields';
import { SearchWrapper } from './styled';

export const SearchDesktopView: React.FC<{ isTablet?: boolean }> = (props) => {
  const { isTablet } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    handleFocus('');
  });

  return (
    <Observer>
      {() => {
        const compressed = !focusedStore.value;
        const level = compressed ? 1 : 5;

        return (
          <Form id={HEADER_FORM} onSubmit={onSearch}>
            <SearchWrapper ref={ref} compressed={compressed} hasActive={Boolean(focusedStore.value)} level={level} isTablet={isTablet}>
              <InputWrapper
                name={FieldNames.raceName}
                settings={{
                  label: t.staticAsString('search.raceName'),
                  placeholder: t.staticAsString('header.searchBtn'),
                  fieldName: FieldNames.raceName,
                  compressed: compressed,
                }}
                Component={SearchItemDesktop}
              />
              <InputWrapper
                name={FieldNames.location}
                settings={{
                  label: t.staticAsString('search.location'),
                  placeholder: t.staticAsString('header.searchBtn'),
                  fieldName: FieldNames.location,
                  compressed: compressed,
                }}
                Component={SearchItemDesktop}
              />
              <InputWrapper
                name={FieldNames.sports}
                settings={{
                  label: t.staticAsString('search.sportType'),
                  placeholder: t.staticAsString('header.selectSport'),
                  fieldName: FieldNames.sports,
                  compressed: compressed,
                }}
                Component={SearchItemDesktop}
              />
            </SearchWrapper>
          </Form>
        );
      }}
    </Observer>
  );
};
