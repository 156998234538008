import { CELL_SIZES } from '../constants';

import { RightSideColumnsType } from '../types';

export const isLastColumn = (name: string, columnsActive: RightSideColumnsType) => {
  const last = CELL_SIZES.filter((item) => item.name !== 'checkpoint').reduce((prev, current) => {
    if (columnsActive[current.name]) return current.name;

    return prev;
  }, '');

  return last === name;
};
