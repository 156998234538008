import React, { FC, ReactNode } from 'react';
import Truncate from 'react-truncate';
import styled from 'styled-components';

import { DEFAULT_RACE_IMAGE } from 'src/constants';
import { BodyTextStyles, HeadlineStyles, Utility } from 'src/styledComponents/Typography';

import { htmlSanitizer } from 'utils';

import { Skeleton } from '../Skeleton';
import { Body, ImageBox, CardMark, Content, StyledCard, StyledLink } from './Components';

type Props = {
  className?: string;
  content: string;
  date: string;
  image: string | nil;
  link: string;
  onClick?: AnyFunction;
  title: string;
  restorationRef?: any;
  onSelect?: AnyFunction;
  id: number;
};

const Wrapper = styled.div`
  width: 410px;

  ${Content} span {
    color: ${(props) => props.theme.main.colors.clay0};
  }
`;

const HeadlineWrap = styled.div`
  ${HeadlineStyles.h4}
`;

const BodyTextWrap = styled.div`
  ${BodyTextStyles.editorialBody2}
`;

export const ArticleCard: FC<Props> = ({
  className,
  content,
  date,
  image,
  link,
  onClick,
  onSelect,
  title,
  restorationRef,
  id,
}): ReactNode | any => {
  React.useEffect(() => {
    if (!restorationRef) {
      return;
    }
    restorationRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
  });

  return (
    <Wrapper className={className}>
      <StyledCard>
        <Body>
          <ImageBox bg={image || DEFAULT_RACE_IMAGE} />

          <CardMark>
            <Utility variant='u2' weight='bold'>
              {date}
            </Utility>
          </CardMark>

          <StyledLink
            to={link}
            onClick={() => {
              onClick && onClick();
              onSelect && onSelect(id);
            }}
          />
        </Body>
      </StyledCard>

      <Content>
        <HeadlineWrap>
          <Truncate lines={3} ellipsis={<span>...</span>}>
            {title}
          </Truncate>
        </HeadlineWrap>

        <BodyTextWrap>
          <Truncate lines={7} ellipsis={<span>...</span>}>
            <div
              className='article-content'
              dangerouslySetInnerHTML={{
                __html: htmlSanitizer(content),
              }}
            />
          </Truncate>
        </BodyTextWrap>

        <StyledLink
          to={link}
          onClick={() => {
            onClick && onClick();
            onSelect && onSelect(id);
          }}
        />
      </Content>
    </Wrapper>
  );
};

export const ArticleCardSkeleton: FC = (): ReactNode | any => (
  <Wrapper>
    <StyledCard>
      <Body>
        <Skeleton variant={'rectangular'} height={232} />
      </Body>
    </StyledCard>

    <Content>
      <HeadlineWrap>
        <Skeleton width={'50%'} />
      </HeadlineWrap>

      <BodyTextWrap>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </BodyTextWrap>
    </Content>
  </Wrapper>
);
