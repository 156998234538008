export const getNumberOfChances = (
  tickets: Array<{
    validity: string;
  }>,
): number => {
  return tickets.reduce((result, current) => {
    if (current.validity === 'used') {
      return result;
    }

    return result + 1;
  }, 0);
};
