import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { Show } from 'components/condition';
import { withBlockedScroll } from 'components/hocs';

import { Close } from './Close';
import { Context } from './Context';
import type { ContextType } from './Context';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const BodyWrapper = styled.div`
  background: ${(props) => props.theme.main.colors.white};
  padding: 32px 40px;
  border-radius: 24px;

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: 4px 20px 40px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    position: fixed;
  }
`;

function Component({ children, className }: Props) {
  const context = React.useContext<ContextType>(Context);

  return (
    <BodyWrapper className={classNames('body', className)}>
      <Show if={Boolean(context.withCloseButton)}>
        <Close onClick={context.onClose!} />
      </Show>

      {children}
    </BodyWrapper>
  );
}

const Body = withBlockedScroll({})(Component);

export { Body };
