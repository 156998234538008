import { Observer } from 'mobx-react';
import { isMiles, isMilesLanding } from 'modules/header/selectors';
import { Footer as FooterMiles } from 'modules/miles/shared/components';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { RESOLUTIONS } from 'src/constants';
import { DefaultFooterComponent, SlimFooterComponent, TabletDefaultFooterComponent } from 'src/modules/footer/components';
import { MENU_LINKS } from 'src/modules/footer/constants';
import { footerStore } from 'src/modules/footer/stores';
import { ShowDesktop, ShowTablet } from 'src/styledComponents/Conditions';
import { Divider } from 'src/styledComponents/Divider';

const FooterWrapper = styled.footer<{ hide?: boolean }>`
  background: ${(props) => props.theme.main.colors.clay1};
  color: ${(props) => props.theme.main.colors.clay5};
  flex: 0 0 auto;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  padding: 64px 0 24px;
  flex-wrap: wrap;

  .section-content {
    padding: 20px;
    width: 100%;

    @media (min-width: ${RESOLUTIONS.large}px) {
      width: 1180px;
      padding: 0;
    }
    @media (min-width: ${RESOLUTIONS.xlarge}px) {
      width: 1280px;
      padding: 0;
    }
  }
`;

const TabletFooterWrapper = styled.footer<{ hide?: boolean }>`
  background: ${(props) => props.theme.main.colors.clay1};
  color: ${(props) => props.theme.main.colors.clay5};
  flex: 0 0 auto;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 64px 24px 24px;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
`;

export const Footer = () => {
  return (
    <TestAnchorContext.Provider value={{ container: 'Footer' }}>
      <Observer>
        {() => {
          if (isMilesLanding.get()) {
            return <FooterMiles />;
          }
          if (isMiles.get()) {
            return null;
          }

          const slim: boolean = footerStore.value?.slim as boolean;
          const hide: boolean = footerStore.value?.hide as boolean;

          return (
            <>
              <ShowDesktop withTablet>
                <FooterWrapper hide={hide}>
                  <div className='section-content'>
                    <Show if={!slim}>
                      <DefaultFooterComponent menuLinks={MENU_LINKS} />
                      <StyledDivider light />
                    </Show>
                    <SlimFooterComponent />
                  </div>
                </FooterWrapper>
              </ShowDesktop>

              <ShowTablet>
                <TabletFooterWrapper hide={hide}>
                  <Show if={!slim}>
                    <TabletDefaultFooterComponent menuLinks={MENU_LINKS} />
                    <StyledDivider light />
                  </Show>
                  <SlimFooterComponent />
                </TabletFooterWrapper>
              </ShowTablet>
            </>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};
