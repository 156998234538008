import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { actionMenuStore } from '../../stores';

export const isTransferRegistration: IComputedValue<boolean> = computed(() => {
  const distance = actionMenuStore.distanceModel as any;

  return distance && distance.value.isTransferRegistrationAvailable;
});
