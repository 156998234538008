import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import { Truncate } from 'styles';

import { Svg } from '../../Icons';
import { Utility } from '../../Typography';
import { Content } from '../Components';

export const Wrapper = styled.div`
  width: 354px;
  position: relative;

  ${Content} span {
    color: ${(props) => props.theme.main.colors.clay3};
    font-weight: 500;
  }

  &:hover {
    .content-container h1 {
      text-decoration: underline;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.main.colors.clay1};
  padding: 12px 16px;

  div {
    display: flex;
    align-items: center;
  }
`;

export const DateUtility = styled(Utility)`
  margin-left: 8px;
  color: ${(props) => props.theme.main.colors.white};
`;

export const Favourite = styled(Svg)<{ active: boolean }>`
  position: relative;
  z-index: 1;
  cursor: pointer;

  &:hover {
    svg path:first-child {
      fill: ${(props) => props.theme.main.colors.auroraLemon};
      stroke: ${(props) => props.theme.main.colors.auroraLemon};
    }
  }

  ${(props) =>
    props.active &&
    css`
      svg path:first-child {
        fill: ${props.theme.main.colors.auroraLemon};
        stroke: ${props.theme.main.colors.auroraLemon};
      }
    `}
`;

export const SportType = styled.span`
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  color: ${(props) => props.theme.main.colors.white};
`;

export const Distances = styled(Utility)`
  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 14px;
    line-height: 18px;
  }
  ${Truncate}
`;

export const TeaserTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
