import { computed } from 'mobx';

import { distancesStore } from '../../stores';

import { currentDistance } from '../currentDistance';

const isGenderFilterAvailable = computed(() => {
  if (currentDistance.id.get()) {
    return currentDistance.model.get()?.isSingle() || false;
  }

  return distancesStore.filteredModelValues.some((distance) => distance.isSingle());
});

export { isGenderFilterAvailable };
