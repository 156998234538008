import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { Skeleton } from 'src/styledComponents/Skeleton';

import { progressStore } from 'stores/index';

type Props = {
  actions: string[];
  withFakeTime?: boolean;
  children?: React.ReactNode;
};

const Wrapper = styled.div``;

const MobileItem = styled.div`
  padding: 20px;

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

const ItemHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 35px 1fr;
  gap: 8px;
`;

const TimeInfo = styled.div`
  display: grid;
  grid-template-columns: 35px 60px 60px;
  gap: 8px;
  height: 18px;
`;

const AdditionWrap = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 43px;

  margin-top: 16px;
`;

const InfoItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 93px;
  margin-right: 16px;
`;

export const MobileSkeleton: React.FC<Props> = (props) => {
  const { actions, children, withFakeTime = false } = props;

  return (
    <Observer>
      {() => {
        const isLoading = actions.some((item) => progressStore.isLoading(item));

        if (!isLoading) return <>{children}</>;

        const Loader = !isLoading ? null : (
          <Wrapper>
            {[...Array(8).keys()].map((item, index) => (
              <MobileItem key={`mobile-loader-${item}-${index}`}>
                <ItemHead>
                  <Skeleton height={16} />
                  <Skeleton height={24} />
                </ItemHead>
                <Show if={withFakeTime}>
                  <TimeInfo>
                    <Skeleton height={18} />
                    <Skeleton height={18} />
                    <Skeleton height={18} />
                  </TimeInfo>
                </Show>
                <AdditionWrap>
                  <InfoItemWrap>
                    <Skeleton height={16} />
                    <Skeleton height={16} />
                  </InfoItemWrap>
                  <InfoItemWrap>
                    <Skeleton height={16} />
                    <Skeleton height={16} />
                  </InfoItemWrap>
                </AdditionWrap>
              </MobileItem>
            ))}
          </Wrapper>
        );

        return (
          <>
            {Loader}
            {children}
          </>
        );
      }}
    </Observer>
  );
};
