import { computed } from 'mobx';

import { HEADER_FORM } from 'src/constants';
import { form, sportTypesStore } from 'src/stores';

import { mapSportOptions } from '../utils';

import { FieldNames } from '../types';

export const searchRaceValues = () => {
  return form.fetch(HEADER_FORM, FieldNames.raceName);
};

export const searchLocationValues = () => {
  return form.fetch(HEADER_FORM, FieldNames.location);
};

export const sportValues = () => {
  return mapSportOptions(sportTypesStore.values);
};

export const allFiltersValues = computed(() => {
  return form.fetchWithoutObservable<AnyObject>(HEADER_FORM) || {};
});

export const recentLocationSearches = () => {
  const jsonSearches = localStorage.getItem('recentLocationSearches');
  if (jsonSearches) {
    return JSON.parse(jsonSearches);
  }
  return [];
};

export const recentRaceNameSearches = () => {
  const jsonSearches = localStorage.getItem('recentRaceNameSearches');
  if (jsonSearches) {
    return JSON.parse(jsonSearches);
  }
  return [];
};

export const getOriginalLocationName = () => {
  const jsonName = sessionStorage.getItem('originalLocationName');
  return jsonName && JSON.parse(jsonName);
};
