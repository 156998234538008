import * as React from 'react';

import { AuthForm } from '../components/AuthForm';

import { onOpen, onClose, onSubmit } from '../actions';

type Props = {};

class AuthFormWrapper extends React.Component<Props> {
  componentDidMount() {
    onOpen();
  }

  componentWillUnmount() {
    onClose();
  }

  render() {
    return <AuthForm onSubmit={onSubmit} />;
  }
}

export { AuthFormWrapper };
