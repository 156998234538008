import moment, { Moment } from 'moment';
import * as React from 'react';
import { DayPickerRangeController } from 'react-dates';
import styled, { css } from 'styled-components';

import { Icon, Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils/t';

import { Hide, Show } from 'components/condition';

import { CalendarWrapper } from './DatePicker';

const Wrapper = styled(CalendarWrapper)<{ open: boolean; translateY: number; isTransitionEnd: boolean }>`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 0;

  transition: all 0.3s ease !important;
  transform: ${(props) => (props.open ? 'initial' : 'translateY(100%)')};

  .CalendarDay {
    box-sizing: content-box;
    padding: 0;
    width: 44px !important;
    height: 44px !important;

    border: 2px solid ${(props) => props.theme.main.colors.white};
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
  }

  .DayPicker {
    .CalendarMonthGrid {
      width: 375px !important;
      padding-top: 32px;
    }

    .CalendarMonth_caption {
      padding-bottom: 76px;
    }

    .DayPicker_weekHeader {
      top: 112px;
      height: 44px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0 !important;
      width: 375px;
      left: calc(50% - 187px);
      text-transform: uppercase;
    }

    .CalendarMonth {
      padding-bottom: 24px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .DayPickerNavigation_button {
      img {
        position: absolute;
        top: 0;
        background-color: white;
      }

      .navigate-left {
        left: 38px;
      }

      .navigate-right {
        right: 38px;
      }
    }

    .DayPicker_transitionContainer {
      height: 800px !important;
    }

    .CalendarMonthGrid__animating {
      transform: translateY(${(props) => props.translateY}px) !important;
    }
    .CalendarMonthGrid > div:first-child {
      width: 100%;
      ${(props) =>
        !props.isTransitionEnd &&
        css`
          top: -${props.translateY - 32}px !important;
        `};
    }
    .custom-year-container {
      opacity: ${(props) => (props.isTransitionEnd ? '1' : '0')};
      transition: opacity 750ms ease;
    }
  }
`;

const MenuHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 58px;
  width: 100%;
  padding: 0 19px 0 21px;

  border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
`;

const HeaderPhantom = styled.div`
  height: 58px;
  width: 100%;
  background: white;
`;

const Clear = styled(Utility)`
  position: relative;
  font-weight: 500;

  &:after {
    position: absolute;
    bottom: 1px;
    left: 0;
    z-index: 5;

    content: '';
    height: 1px;
    width: 100%;
    background-color: ${(props) => props.theme.main.colors.clay1};
  }
`;

const YearContainer = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;

  img:hover {
    cursor: pointer;
  }
`;

const ScrollWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 48px;
  padding-bottom: 24px;

  height: calc(100% - 58px - 90px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
  border: none;
  background-color: ${(props) => props.theme.main.colors.clay3};
`;

const ApplyButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 90px;
  border: none;
  background-color: ${(props) => props.theme.main.colors.clay3};
`;

const StyledConfirmText = styled(Utility)`
  color: white;
`;

const MiddleLabel = styled(Utility)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type Props = BaseInputProps & {
  isSingleDate?: boolean;
  open: boolean;
  confirmBtnText: string;
  onClose: AnyFunction;

  minDate?: Moment | null;
  maxDate?: Moment | null;
  noSubmit?: boolean;
};

export const MobileCalendar: React.FC<Props> = ({
  open,
  value = {},
  name,
  onClose,
  label,
  onChange,
  confirmBtnText,
  minDate,
  maxDate,
  noSubmit,
}) => {
  const { startDate = null, endDate = null } = value;
  const [focus, changeFocus] = React.useState('startDate');
  const [date, changeDate] = React.useState<{ startDate: null | Moment; endDate: null | Moment }>({
    startDate: value.startDate || null,
    endDate: value.endDate || null,
  });
  const [translateY, setTranslateY] = React.useState<number>(0);
  const [isTransitionEnd, setIsTransitionEnd] = React.useState<boolean>(true);

  const handleChange = (value: { startDate: Moment | null; endDate: Moment | null }) => {
    if (startDate && endDate) {
      changeDate({
        startDate: value.startDate || value.endDate,
        endDate: null,
      });
      return;
    }

    if (value.startDate && value.endDate) {
      changeFocus('startDate');
    }

    changeDate(value);
  };

  const handleClear = () => {
    changeDate({
      startDate: null,
      endDate: null,
    });
    onChange({ name, value: {} });
    onClose();
  };

  const isDayBlocked = (day: Moment): boolean => {
    if (!maxDate && !!minDate) return day.isSameOrBefore(minDate);
    if (!minDate && !!maxDate) return day.isSameOrAfter(maxDate);

    if (!!minDate && !!maxDate) return day.isSameOrBefore(minDate) || day.isSameOrAfter(maxDate);

    return false;
  };

  const handleSearch = (e) => {
    if (!date.startDate && !date.endDate) {
      handleClear();
      return;
    }
    onChange({ name, value: date });
    onClose();
  };

  const prevMonthClick = (date: moment.Moment) => {
    setIsTransitionEnd(false);
    const someElementsItems = document.querySelectorAll(".CalendarMonth[data-visible='false']");
    setTranslateY(someElementsItems[0].clientHeight);
  };
  const nextMonthClick = (date: moment.Moment) => {
    setIsTransitionEnd(false);
    const someElementsItems = document.querySelectorAll(".CalendarMonth[data-visible='true']");
    setTranslateY(-someElementsItems[0].clientHeight);
  };

  return (
    <Wrapper open={open} translateY={translateY} isTransitionEnd={isTransitionEnd}>
      <Show if={open}>
        <MenuHeader>
          <Svg name='Cross' size={16} onClick={handleClear} {...testAnchors.useButton('close')} />
          <MiddleLabel variant='u2' weight='bold'>
            {label}
          </MiddleLabel>
          <Clear variant='u2' weight='medium' onClick={handleClear} {...testAnchors.useButton('clear')}>
            {t('settings.clear')}
          </Clear>
        </MenuHeader>
      </Show>
      <Show if={!open}>
        <HeaderPhantom />
      </Show>
      <ScrollWrap>
        <DayPickerRangeController
          startDate={date.startDate}
          endDate={date.endDate}
          onDatesChange={handleChange}
          focusedInput={focus}
          onFocusChange={changeFocus}
          isDayBlocked={isDayBlocked}
          initialVisibleMonth={null}
          numberOfMonths={2}
          minimumNights={0}
          keepOpenOnDateSelect={true}
          noBorder
          orientation='vertical'
          verticalHeight={800}
          hideKeyboardShortcutsPanel
          enableOutsideDays
          renderMonthElement={MonthElement}
          transitionDuration={750}
          navPrev={
            <Icon onClick={prevMonthClick} className='navigate-left' name='NavigateLeft' size={24} {...testAnchors.useButton('left')} />
          }
          navNext={
            <Icon onClick={nextMonthClick} className='navigate-right' name='NavigateRight' size={24} {...testAnchors.useButton('right')} />
          }
          onPrevMonthClick={() => setIsTransitionEnd(true)}
          onNextMonthClick={() => setIsTransitionEnd(true)}
        />
      </ScrollWrap>
      <Show if={Boolean(noSubmit)}>
        <ApplyButton onClick={handleSearch} {...testAnchors.useButton('submit')}>
          <StyledConfirmText variant='u1' weight='bold'>
            {confirmBtnText}
          </StyledConfirmText>
        </ApplyButton>
      </Show>
      <Hide if={Boolean(noSubmit)}>
        <ConfirmButton onClick={handleSearch} {...testAnchors.useButton('submit')}>
          <StyledConfirmText variant='u1' weight='bold'>
            {confirmBtnText}
          </StyledConfirmText>
        </ConfirmButton>
      </Hide>
    </Wrapper>
  );
};

type MonthElementProps = {
  month: Moment;
  onYearSelect: AnyFunction;
};

const MonthElement: React.FC<MonthElementProps> = (props) => {
  const { month } = props;

  return (
    <div className='month-header' style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <Utility variant='u1' weight='medium'>
          {month.format('MMMM')}
        </Utility>
      </div>
      <YearContainer className='custom-year-container'>
        <Utility variant='u1' weight='medium'>
          {month.year()}
        </Utility>
      </YearContainer>
    </div>
  );
};
