import { Observer } from 'mobx-react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

import { Hide, Show } from 'components/condition';

import { t } from 'utils';

import { windowSize } from 'stores';

import { displayEditControls, getTotalInfo } from '../selections';

type Props = {};

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  padding: 24px 40px;
  border-top: 3px solid ${(props) => props.theme.main.colors.clay4};

  .cell:not(:first-child) {
    margin-left: 48px;
    display: flex;
  }

  .date {
    flex: 1;
  }

  .length {
    min-width: 45px;
    max-width: 45px;
    justify-content: flex-end;
  }

  .time {
    min-width: 65px;
    max-width: 65px;
    justify-content: flex-end;
  }

  .percents {
    min-width: 165px;
    max-width: 165px;
    justify-content: start;
  }

  .hide-controls-box {
    min-width: 85px;
    max-width: 85px;
  }

  .value {
    min-width: 39px;
    max-width: 39px;
    display: flex;
    justify-content: flex-end;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 52px 8px 24px;
      border-top: none;
      justify-content: space-between;

      .cell:not(:first-child) {
        margin: 0;
      }

      .date {
        min-width: 80px;
        max-width: 80px;
      }

      .percents {
        min-width: 65px;
        max-width: 65px;
        justify-content: flex-end;
      }

      .length {
        min-width: 55px;
        max-width: 55px;
      }

      .time,
      .length {
        justify-content: start;
      }
    `}
`;

export const Total: React.FC<Props> = () => {
  return (
    <Observer>
      {() => {
        const { time, length, lengthShort, percents } = getTotalInfo.get();
        const percentsToDisplay = percents ? `${percents}%` : null;
        const isMobile = !windowSize.isGreaterThan('medium');
        const hide = !displayEditControls.get();

        return (
          <Wrapper className='total' isMobile={isMobile}>
            <Show if={isMobile}>
              <Utility variant='u2' weight='bold' className='cell date'>
                {t('profile.segments.total')}
              </Utility>
              <Utility variant='u2' weight='bold' className='cell length'>
                {length}
              </Utility>
              <Utility variant='u2' weight='bold' className='cell time'>
                {time}
              </Utility>
              <Hide if={windowSize.isLessThan('medium') && !percentsToDisplay}>
                <Utility variant='u2' weight='bold' className='cell percents'>
                  {percentsToDisplay}
                </Utility>
              </Hide>
            </Show>

            <Show if={!isMobile}>
              <Utility variant='u3' weight='bold' className='cell date'>
                {t('profile.segments.total')}
              </Utility>
              <Utility variant='u3' weight='bold' className='cell time'>
                {time}
              </Utility>
              <Utility variant='u3' weight='bold' className='cell length'>
                {lengthShort}
              </Utility>
              <Hide if={windowSize.isLessThan('medium') && !percentsToDisplay}>
                <Utility variant='u3' weight='bold' className={`cell ${hide ? 'hide-controls-box' : 'percents'} `}>
                  <span className='value'>{percentsToDisplay}</span>
                </Utility>
              </Hide>
            </Show>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
