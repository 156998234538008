import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const TrainingLoadPath = (
  <>
    <path d='M6.5 8L3.5 21H21.5L18.5 8H6.5Z' fill='currentColor' />
    <circle cx='12.5' cy='5' r='2' stroke='currentColor' strokeWidth='2' />
  </>
);

export const TrainingLoad = (props: IconProps) => <SvgIcon path={TrainingLoadPath} {...props} />;
