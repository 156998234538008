import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { actionMenuStore } from '../../stores';

const isBibExport: IComputedValue<boolean> = computed(() => {
  const distance = actionMenuStore.distanceModel;

  if (!distance) {
    return false;
  }

  return !distance.isMode('classic');
});

export { isBibExport };
