import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { distancesStore } from '../stores';

import { generateIsAnyDistanceFieldSelected, generateIsFieldSelected } from './conditions';

const isAnyDistanceHasUnion = generateIsAnyDistanceFieldSelected('union');

const generateIsUnion = (distanceId: number): IComputedValue<boolean> =>
  computed(() => {
    const distance = distancesStore.find(distanceId);

    if (!distance) {
      return false;
    }

    if (distance.isTeam()) {
      return false;
    }

    return generateIsFieldSelected(distanceId, 'union').get();
  });

export { isAnyDistanceHasUnion, generateIsUnion };
