import axios from 'axios';
import { MILES_CONNECTED_DEVICES, MILES_DEVICE_AUTH, MILES_DEVICE_DEAUTH } from 'constants/api';
import { observer } from 'mobx-react';
import { ConfirmationPopup } from 'modules/miles/app/form/ui/steps/integrate-services/ConfirmationPopup';
import { connectedServicesStore } from 'modules/miles/app/stores';
import { Service } from 'modules/miles/app/stores/connectedServices';
import { Display, Text, Card, FullScreenPulse } from 'modules/miles/shared/components';
import { Delete } from 'modules/miles/shared/components/Icon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import LogoFitbit from 'src/assets/images/miles/fitbit_logo.png';
import LogoGarmin from 'src/assets/images/miles/garmin_logo.png';
import LogoPolar from 'src/assets/images/miles/polar_logo.png';
import LogoStrava from 'src/assets/images/miles/strava_logo.png';
import LogoSuunto from 'src/assets/images/miles/suunto_logo.png';
import { PulseLoaderNoWrapper } from 'src/styledComponents/Loader/PulseLoader';

import { Hide, Show } from 'components/condition';

type Props = { isEdit?: boolean; triggerSubmit: (isTrial?: boolean) => void };

const Box = styled.div`
  max-width: 600px;
  margin: 0 auto 16px;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

type ServiceCardProps = {
  isConnected: boolean;
};

const StyledCard = styled(Card)<ServiceCardProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 27px 16px;
  background-color: ${(props) => (props.isConnected ? props.theme.miles.colors.dark.alpha.cobalt300 : 'rgb(32, 41, 54)')};

  .service-info {
    display: flex;
    flex-direction: row;
    .image {
      width 100%;
      max-width: 107px;
      height: auto;
      margin-right: 8px;
    }
    .description {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding: 0 8px;
    }
  }
  .delete-icon {
    &:hover {
      color: ${(props) => props.theme.miles.colors.dark.alpha['500']};
    }
  }
`;

export const DEVICE_SERVICES: Service[] = [
  {
    id: 'garmin',
    label: 'Garmin Connect',
    provider: 'GARMIN',
    integrator: 'SVEXA',
    icon: LogoGarmin,
    onConnect: () => {
      connectedServicesStore.setServiceConnected('garmin', 'SVEXA');
    },
    isConnected: false,
  },
  {
    id: 'suunto',
    label: 'Suunto',
    provider: 'SUUNTO',
    integrator: 'SVEXA',
    icon: LogoSuunto,
    onConnect: () => {
      connectedServicesStore.setServiceConnected('suunto', 'SVEXA');
    },
    isConnected: false,
  },
  {
    id: 'polar',
    label: 'Polar',
    provider: 'POLAR',
    integrator: 'SVEXA',
    icon: LogoPolar,
    onConnect: () => {
      connectedServicesStore.setServiceConnected('polar', 'SVEXA');
    },
    isConnected: false,
  },
  {
    id: 'fitbit',
    label: 'Fitbit',
    provider: 'FITBIT',
    integrator: 'SVEXA',
    icon: LogoFitbit,
    onConnect: () => {
      connectedServicesStore.setServiceConnected('fitbit', 'FITBIT');
    },
    isConnected: false,
  },
  {
    id: 'strava',
    label: 'Strava',
    provider: 'STRAVA',
    integrator: 'SVEXA',
    icon: LogoStrava,
    onConnect: () => {
      connectedServicesStore.setServiceConnected('strave', 'STRAVA');
    },
    isConnected: false,
  },
];

const IntegrateServices = observer(({ isEdit, triggerSubmit }: Props) => {
  const availableServices = connectedServicesStore.services;

  useEffect(() => {
    if (!isEdit) triggerSubmit(true);
  }, []);

  const [connectingId, setConnecting] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    connectedServicesStore.setServices(DEVICE_SERVICES);
    const loadConnectedServices = async () => {
      try {
        setIsLoading(true);
        await connectedServicesStore.loadServices();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadConnectedServices();
  }, []);

  const [showConfirmation, setShowConfirmation] = useState<Boolean>(false);
  const [deactivateService, setDeactivateService] = useState<Service>();

  const deactivateServiceAction = (service: Service) => {
    setDeactivateService(service);
    setShowConfirmation(true);
  };

  const handleDeactivateService = () => {
    deactivateService && connectedServicesStore.disconnectService(deactivateService.id);
    setShowConfirmation(false);
  };

  const handleConfirmDeatuh = async () => {
    if (!deactivateService) return;
    try {
      await axios.delete(MILES_DEVICE_DEAUTH, {
        params: {
          integrator: deactivateService?.integrator,
          provider: deactivateService?.provider,
        },
      });
      connectedServicesStore.disconnectService(deactivateService.id);
    } catch (error) {
      console.error(error);
    } finally {
      setShowConfirmation(false);
    }
  };

  const handleConnectService = async ({ id, isConnected, integrator, provider }: Service) => {
    if (isConnected || connectingId !== '') return;
    try {
      setConnecting(id);
      let redirectUrl = `${window.origin}/en/miles/sign-in?step=integrate-services`;
      if (isEdit) redirectUrl = `${window.origin}/en/miles/settings?step=integrate-services`;

      const response = await axios.get(MILES_DEVICE_AUTH, {
        params: {
          locale: 'en',
          integrator,
          provider,
          successRedirect: `${redirectUrl}&success=${provider}`,
          errorRedirect: `${redirectUrl}&error=${provider}`,
        },
      });
      if ('auth_url' in response.data) {
        window.open(response.data.auth_url, '_self');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setConnecting('');
    }
  };

  return (
    <div
      style={{
        maxWidth: 480,
        margin: '0 auto',
      }}
    >
      <>
        <Show if={isLoading}>
          <FullScreenPulse />
        </Show>
        <Hide if={Boolean(isEdit)}>
          <Text style={{ margin: '24px 0' }} size='md' weight='regular'>
            Training Plan Setup
          </Text>
        </Hide>
        <Display style={{ marginBottom: 0 }} size='sm' weight='bold'>
          Connect Devices
        </Display>
        <Text size='md' weight='regular' style={{ marginBottom: 32, lineHeight: 1.5 }}>
          Connect your wearable device to improve the accuracy of your training plan and track your progress. Only use Strava if you are not
          using any of the devices below. You can set this up later in Settings.
        </Text>
        <Text size='xl' weight='regular' style={{ marginBottom: 8 }}>
          Available devices
        </Text>

        {availableServices.map((service) => {
          return (
            <>
              {service.id === 'strava' && (
                <Text size='xl' weight='regular' style={{ marginBottom: 8, marginTop: 8 }}>
                  All other devices
                </Text>
              )}
              <Box key={service.id} onClick={() => handleConnectService(service)}>
                {connectingId === service.id && (
                  <div style={{ position: 'absolute', width: '100%', height: '100%', padding: 24, backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    <PulseLoaderNoWrapper />
                  </div>
                )}
                <StyledCard isConnected={service.isConnected}>
                  <div className='service-info'>
                    <img className='image' src={service.icon} alt='logo-img' />
                    <div className='description'>
                      <Text size='md' weight={service.isConnected ? 'regular' : 'regular'}>
                        {service.label}
                      </Text>
                      {service.isConnected && (
                        <Text style={{ marginTop: 4 }} size='md' weight='light'>
                          Already Integrated
                        </Text>
                      )}
                    </div>
                  </div>

                  {service.isConnected && (
                    <Delete
                      className='delete-icon'
                      onClick={(event) => {
                        event.stopPropagation();
                        deactivateServiceAction(service);
                      }}
                    />
                  )}
                </StyledCard>
              </Box>
            </>
          );
        })}
      </>
      <Show if={Boolean(showConfirmation)}>
        <ConfirmationPopup onClose={handleConfirmDeatuh} onConfirm={handleConfirmDeatuh} serviceLabel={deactivateService?.label} />
      </Show>
    </div>
  );
});

export default IntegrateServices;
