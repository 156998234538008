import { LOAD_REGISTRATION_STATUS } from 'src/constants';

import { progressStore } from 'stores';

import { INITIALIZE_DISTANCE, INITIALIZE_BASE_DATA, SUBMIT_IT_PROGRESS } from '../constants';

function generate(action: string) {
  function enable() {
    progressStore.log(action, 'progress');
  }

  function disable() {
    progressStore.log(action, 'completed');
  }

  function isLoading() {
    return progressStore.isLoading(action);
  }

  return {
    enable,
    disable,
    isLoading,
  };
}

const initializeDistance = {
  ...generate(INITIALIZE_DISTANCE),
};

const initializeBaseData = {
  ...generate(INITIALIZE_BASE_DATA),
};

const submitInProgress = {
  ...generate(SUBMIT_IT_PROGRESS),
};

const loadRegistrationStatus = {
  ...generate(LOAD_REGISTRATION_STATUS),
};

const preloaders = {
  initializeDistance,
  initializeBaseData,
  submitInProgress,
  loadRegistrationStatus,
};

export { preloaders };
