import styled from 'styled-components';

import { Svg } from '../Icons';

export const Wrapper = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 22;
  top: 0;
  left: 0;
`;

export const FirstCircleBox = styled.div`
  display: block;
  position: absolute;
  background-color: #3e4e5a;
  z-index: 23;
  height: 44px;
  width: 44px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 43px;
  animation-duration: 3s;
  -webkit-animation-duration: 3s;
  animation-name: large-circle-animation;
  -webkit-animation-name: large-circle-animation;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  -webkit-animation-direction: alternate;

  @keyframes large-circle-animation {
    from {
      height: 44px;
      width: 44px;
      background-color: #272e34;
    }

    to {
      height: 86px;
      width: 86px;
    }
  }
`;

export const SecondCircleBox = styled.div`
  display: block;
  position: absolute;
  z-index: 24;
  height: 44px;
  width: 44px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 36px;
  background-color: #35414b;
  animation-duration: 3s;
  -webkit-animation-duration: 3s;
  animation-name: medium-circle-animation;
  -webkit-animation-name: medium-circle-animation;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  -webkit-animation-direction: alternate;

  @keyframes medium-circle-animation {
    from {
      height: 30px;
      width: 30px;
      background-color: #272e34;
    }

    to {
      height: 72px;
      width: 72px;
    }
  }
`;

export const ThirdCircleBox = styled.div`
  display: block;
  position: absolute;
  z-index: 25;
  height: 44px;
  width: 44px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 29px;
  background-color: #2d363e;
  animation-duration: 3s;
  -webkit-animation-duration: 3s;
  animation-name: small-circle-animation;
  -webkit-animation-name: small-circle-animation;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-direction: alternate;
  -webkit-animation-direction: alternate;

  @keyframes small-circle-animation {
    from {
      height: 16px;
      width: 16px;
      background-color: #272e34;
    }

    to {
      height: 58px;
      width: 58px;
    }
  }
`;

export const StyledIcon = styled(Svg)`
  display: block;
  z-index: 26;
  position: absolute;
  height: 88px;
  width: 88px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
`;
