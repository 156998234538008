import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const NotifyBellPath = (
  <>
    <path fillRule='evenodd' clipRule='evenodd' d='M12 22C10.3431 22 9 20.6569 9 19H15C15 20.6569 13.6569 22 12 22Z' fill='currentColor' />
    <path d='M5 9C5 4.64178 8.19579 2 12 2C15.8042 2 19 4.5 19 9V13.5L21.5 18H2.5L5 13.5V9Z' fill='currentColor' />
  </>
);

export const NotifyBell = (props: IconProps) => <SvgIcon path={NotifyBellPath} {...props} />;
