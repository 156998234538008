import { computed } from 'mobx';

import { Race } from 'models';

import { raceStore } from '../../stores';

const model = computed(() => {
  const race = raceStore.value;

  if (!race) {
    return null;
  }
  return new Race(race);
});

const id = computed((): number | null => {
  return model.get()?.value.id || null;
});

const currency = computed((): string | null => {
  return model.get()?.currency() || null;
});

const currencyId = computed((): number | nil => {
  return raceStore.value?.currency?.id;
});

const isSecret = computed(() => {
  return model.get()?.value.is_secret;
});

const raceSelector = {
  model,
  id,
  currency,
  currencyId,
  isSecret,
};

export { raceSelector };
