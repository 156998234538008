import { isFunction } from 'lodash';
import * as React from 'react';

export const withScrollListener = (value: Function) => {
  const callFunction = isFunction(value) ? value : () => {};

  return <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<any> {
      componentDidMount() {
        document.addEventListener('scroll', callFunction, true);
      }

      componentWillUnmount() {
        document.removeEventListener('scroll', callFunction, true);
      }

      render() {
        return <WrappedComponent {...(this.props as any)} />;
      }
    } as any;
};
