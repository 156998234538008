import { filtersStore } from '../stores';

import { internalBe } from '../mapper/internalBe';

function count() {
  const filters = internalBe.single(filtersStore.value);

  return filters;
}

function single() {
  const filters = internalBe.single(filtersStore.value);

  return {
    ...filters,
    limit: 100,
  };
}

function team() {
  const filters = internalBe.teams(filtersStore.value);

  return {
    ...filters,
    limit: 1000,
  };
}

export const params = { count, single, team };
