import { manageTeamStore, overlayStore, windowSize } from 'stores';

function addMemberPopup() {
  manageTeamStore.openAddModal();

  if (windowSize.notObservableIsLessThan('medium')) {
    return;
  }

  overlayStore.show();
}

export { addMemberPopup };
