import { messagesStore } from 'stores';

import { formatUnicorn } from './formatUnicorn';

/*
 * NOTE
 * fetch key from translations json
 * process message with params and formatUnicorn for replacing keys with values
 */
function translate(id: TranslationLockedKeys, params?: AnyObject): string {
  const translations = messagesStore.value;
  const message = translations && translations[id];

  if (!params) {
    return message || id || '';
  }

  return formatUnicorn(message || '', params) || id || '';
}

/*
 * Possible input value:
 * 'translation.key translation.key' - any number of translation keys separated by space
 */
function translateMultiple(id: string): string {
  if (!id) {
    return '';
  }

  const keys = id.split(/\s/);

  return keys.map((key) => translate(key as TranslationLockedKeys) || key).join(' ');
}

export { translate, translateMultiple };
