import classNames from 'classnames';
import isString from 'lodash/isString';
import * as React from 'react';

import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

type Props = {
  value: string | React.ReactNode;
  className?: string;
  variant?: 'u1' | 'u2' | 'u3' | 'u4';
  weight?: 'bold' | 'medium';
};

function Cell(props: Props) {
  const { className, value, variant = 'u3', weight = 'medium' } = props;

  let valueToDisplay = value;

  if (value && isString(value)) {
    valueToDisplay = t(value as TranslationLockedKeys);
  }

  return (
    <Utility className={classNames('table-cell', className)} variant={variant} weight={weight}>
      {valueToDisplay}
    </Utility>
  );
}

export { Cell };
