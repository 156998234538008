import React from 'react';
import styled from 'styled-components';

export interface IconProps {
  color?: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => void;
}

export interface SvgIconProps extends IconProps {
  path: React.ReactNode;
  viewBox?: string;
  width?: number;
  height?: number;
}

const Icon = styled.svg<IconProps>`
  fill: ${({ color }) => color};
  width: ${({ size, width }) => width || size}px;
  height: ${({ size, height }) => height || size}px;
`;

export const SvgIcon = ({ path, width, height, viewBox = '0 0 24 24', color = 'currentColor', size = 24, ...rest }: SvgIconProps) => {
  return (
    <Icon color={color} size={size} width={width} height={height} viewBox={viewBox} {...rest}>
      {path}
    </Icon>
  );
};
