import axios from 'axios';
import moment from 'moment';

import { LOCALES, LOCALES_STRING } from 'src/constants';

import { history } from 'utils/history';

import { localeStore } from 'stores';

function update(locale: availableLocales) {
  if (!LOCALES.includes(locale)) {
    return;
  }

  localeStore.setLocale(locale);

  storeLocale(locale);
  change3ToolsLocale(locale);
  changeHistory(locale);
}

function storeLocale(locale: string) {
  localStorage.setItem('locale', locale);
}

function change3ToolsLocale(locale: string) {
  axios.defaults.headers.common['locale'] = locale;
  moment.locale(locale);
}

function changeHistory(_locale: string) {
  const { pathname, search } = history.location;

  const path = `${pathname}${search}`.replace(new RegExp(`^\\/(${LOCALES_STRING})`), '');

  history.replace(path, { omitScrollToTop: true });
}

export { update };
