import React from 'react';
import styled from 'styled-components';

import { InputComponent } from '../InputTools';
import { TextFieldType } from './TextField';

const StyledButton = styled.div`
  padding: 0 16px;
  font-weight: 500;
`;

const PasswordField = (props: TextFieldType) => {
  const [type, setType] = React.useState<InputType>('password');
  const [isShow, setShow] = React.useState(true);
  const getShowPasswordButton = () => {
    return (
      <StyledButton
        {...testAnchors.useField(props.name, TEST_ANCHORS.fieldStructure.button, 'toggle-password')}
        onClick={() => {
          setType((state) => (state === 'password' ? 'text' : 'password'));
          setShow(() => !isShow);
        }}
      >
        {isShow ? 'Show' : 'Hide'}
      </StyledButton>
    );
  };

  return <InputComponent {...props} unit={getShowPasswordButton()} type={type} />;
};

export default PasswordField;
