import * as React from 'react';

export const HeaderAccountLogOut = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M2 11.9995H16.0019' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12.668 15.3926L16.0018 11.9998L12.668 8.60693'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.27197 16.1579C5.18947 18.0762 6.71472 19.6243 8.60203 20.5529C10.4893 21.4815 12.6289 21.7365 14.6759 21.2769C16.723 20.8173 18.5585 19.6699 19.8868 18.0193C21.215 16.3688 21.9587 14.3113 21.998 12.1782C22.0373 10.0451 21.37 7.96065 20.1035 6.26057C18.837 4.56049 17.0451 3.34375 15.0164 2.80634C12.9877 2.26893 10.8403 2.44214 8.92008 3.29805C6.99988 4.15397 5.41865 5.64278 4.43111 7.52466'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
