import { Observer } from 'mobx-react';
import { handleMobileSearch, onCloseMobileFilter, onOpenMobileFilter } from 'modules/search/actions';
import { DATE_FILTER, SPORT_FILTER, DISTANCE_FILTER, DistanceOptionsMapped } from 'modules/search/constants';
import { mobileFiltersDropdownStore, mobileModalStore, sportsOptionsStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { HEADER_FORM } from 'src/constants';
import { MobileCalendar } from 'src/styledComponents/DatePicker';
import { MobileMultiselectMenu } from 'src/styledComponents/MultiselectFilter';

import { InputWrapper } from 'components/form';

import { t } from 'utils';

import { windowSize } from 'stores';

import { PseudoSelect } from '../PseudoSelect';
import { SearchItemMobile } from '../searchFields';
import { LocationMenuMobile } from '../searchFields/location';
import { RacesMenuMobile } from '../searchFields/race';
import { SubmitButton } from './SubmitButton';
import { ChipsWrapper, FormSearchWrapper, InputFieldsWrapper } from './styled';

export const SearchMobileView = () => {
  return (
    <Observer>
      {() => {
        const containerHeight = windowSize.value.height;
        return (
          <FormSearchWrapper id={HEADER_FORM} open={Boolean(mobileModalStore.value)} onSubmit={handleMobileSearch} height={containerHeight}>
            <InputFieldsWrapper level={1}>
              <InputWrapper
                name={FieldNames.raceName}
                settings={{
                  placeholder: t.staticAsString('search.byRaceName'),
                }}
                Component={SearchItemMobile}
              />
              <InputWrapper
                name={FieldNames.location}
                settings={{
                  placeholder: t.staticAsString('search.byLocation'),
                }}
                Component={SearchItemMobile}
              />
            </InputFieldsWrapper>

            <InputWrapper
              name={FieldNames.date}
              settings={{
                open: mobileFiltersDropdownStore.value === DATE_FILTER,
                label: t.staticAsString('search.date'),
                confirmBtnText: t.staticAsString('registration.applyCoupon'),
                onClose: onCloseMobileFilter,
                noSubmit: true,
              }}
              Component={MobileCalendar}
            />
            <InputWrapper
              name={FieldNames.sports}
              settings={{
                open: mobileFiltersDropdownStore.value === SPORT_FILTER,
                label: t.staticAsString('search.sportType'),
                options: sportsOptionsStore.value,
                confirmBtnText: t.staticAsString('registration.applyCoupon'),
                onClose: onCloseMobileFilter,
                noSubmit: true,
              }}
              Component={MobileMultiselectMenu}
            />
            <InputWrapper
              name={FieldNames.distance}
              settings={{
                open: mobileFiltersDropdownStore.value === DISTANCE_FILTER,
                label: t.staticAsString('search.distance'),
                options: DistanceOptionsMapped,
                confirmBtnText: t.staticAsString('registration.applyCoupon'),
                onClose: onCloseMobileFilter,
                noSubmit: true,
              }}
              Component={MobileMultiselectMenu}
            />
            <FilterChips />
            <LocationMenuMobile />
            <RacesMenuMobile />
            <SubmitButton />
          </FormSearchWrapper>
        );
      }}
    </Observer>
  );
};

const FilterChips = () => {
  return (
    <ChipsWrapper>
      <PseudoSelect text={t.staticAsString('search.date')} name='date' className='filter' onClickHandle={onOpenMobileFilter(DATE_FILTER)} />
      <PseudoSelect
        name='sports'
        text={t.staticAsString('search.sportType')}
        className='filter'
        onClickHandle={onOpenMobileFilter(SPORT_FILTER)}
      />
      <PseudoSelect
        name='distance'
        text={t.staticAsString('search.distance')}
        className='filter'
        onClickHandle={onOpenMobileFilter(DISTANCE_FILTER)}
      />
    </ChipsWrapper>
  );
};
