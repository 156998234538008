import { sortBy } from 'lodash';

import { sessionStore } from 'stores';

import { distancesStore } from '../stores';

export const getFilteredTickets = (unionId: number | string) => {
  const data = (sessionStore.session?.user?.xtriTickets || [])
    .filter((item) => item.race_union_id === unionId)
    .map((item) => ({
      ...item,
      distance: item.distance_id
        ? (distancesStore.value || []).filter((distance) => Boolean(distance)).find((distance) => distance.id === item.distance_id)
        : null,
    }));

  return sortBy(data, (item) => -1 * (item as any).participating_year);
};
