import { LoadRacerService } from 'src/modules/racersTable/services';
import { SquadsStore } from 'src/modules/racersTable/stores';

import { CounterService } from './counter';
import { Distances } from './distances';
import { LastFinishedCheckpointService } from './lastFinishedCheckpoint';
import { ResultsService } from './results';

const loadRacerService = new LoadRacerService();
const distancesService = new Distances();
const resultsService = new ResultsService();
const lastFinishedCheckpointService = new LastFinishedCheckpointService();
const counterService = new CounterService();
const squadsStore = new SquadsStore();

export { distancesService, loadRacerService, resultsService, lastFinishedCheckpointService, counterService, squadsStore };
