import { history, encodeQuery } from 'utils';

import { filtersStore } from '../../stores';

import { internalHistoryMapper } from '../../mapper';

async function updateHistoryBasedOnFilters() {
  const filters = internalHistoryMapper.mapTo(filtersStore.value);
  const url = encodeQuery.generatePath(history.location.pathname, {}, filters);

  const scrollOffset = window.pageYOffset;
  history.replace(url);
  window.scrollTo(0, scrollOffset);
}

export { updateHistoryBasedOnFilters };
