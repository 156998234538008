import { computed } from 'mobx';

import { progressStore } from 'stores';

import { LOAD_STARTLIST } from '../constants';

import { distancesStore, isAppInitializedStore } from '../stores';

const isAllFiltersAreShowed = computed<boolean>(() => {
  if (progressStore.isLoading(LOAD_STARTLIST) && !isAppInitializedStore.value) {
    return false;
  }

  return Boolean(distancesStore.filteredValues.length);
});

export { isAllFiltersAreShowed };
