import { set } from 'lodash';
import { action, observable, computed, makeObservable } from 'mobx';

import { Goal } from 'models';
import { Goal as GoalType } from 'models';

class Segments {
  @observable
  list: Array<AnyObject> = [];
  @observable
  distance: AnyObject | null | any = null;
  @observable
  hasMore: boolean = true;
  @observable
  total: AnyObject = {};

  @observable
  page: number = 0;

  limit: number = 10;

  racerId: number | null = null;
  distanceId: number | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  get goalModel(): GoalType | null {
    return this.distance?.goal ? new Goal(this.distance?.goal as any) : null;
  }

  @action
  setTotalInfo(value: AnyObject): void {
    this.total = value;
  }

  @action
  changeIdOptions(raceId: number, distanceId: number): void {
    this.racerId = raceId;
    this.distanceId = distanceId;
  }

  @action
  changePage(value: number): void {
    this.page = value;
  }

  @action
  setList(list: Array<Object>): void {
    this.list = list;
  }

  @action
  setDistance(distance: AnyObject): void {
    this.distance = distance;
  }

  @action
  setHasMore(value: boolean): void {
    this.hasMore = value;
  }

  @action
  updateSegmentById(id: number, value: AnyObject) {
    const index = this.list.findIndex((el) => el.id === id);

    if (index < 0) {
      return;
    }

    set(this.list, [index], value);
  }

  @action
  clear(): void {
    this.list = [];
    this.distance = null;
    this.hasMore = true;
    this.racerId = null;
    this.distanceId = null;
    this.page = 0;
  }
}

export const segmentsStore: AnyFunction | any = new Segments();
