import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { checkpointsStore } from '../../stores';

function generateIsCheckpoints(distanceId: number): IComputedValue<boolean> {
  return computed(() => {
    return Boolean(checkpointsStore.findCheckpointsByDistance(+distanceId).length);
  });
}

const isAnyDistanceHasCheckpoints: IComputedValue<boolean> = computed(() => {
  return !!checkpointsStore.values.length;
});

export { generateIsCheckpoints, isAnyDistanceHasCheckpoints };
