import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import Check from 'img/check_yellow.svg';

import { InputWrapComponent } from 'components/core/inputWrap';

type Props = {
  onSubmit: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickOutside: Function;
  autoFocus?: boolean;
} & BaseInputType<React.ChangeEvent<HTMLInputElement>>;

@onClickOutside
class TeamNameInput extends React.PureComponent<Props> {
  handleClickOutside = () => {
    this.props.onClickOutside();
  };

  render() {
    const {
      requiredMark,
      errorMessage,
      label,
      type,
      placeholder,
      name,
      value,
      disabled,
      readOnly,
      onChange,
      onBlur,
      onKeyPress,
      autoFocus,
      onSubmit,
    } = this.props;

    return (
      <InputWrapComponent
        errorMessage={errorMessage}
        disabled={disabled}
        label={label}
        requiredMark={requiredMark}
        name={name}
        value={value}
      >
        <input
          type={type}
          placeholder={placeholder}
          id={name}
          name={name}
          autoComplete='off'
          value={value || ''}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          className='input-component'
          autoFocus={autoFocus}
        />
        <div id='submit-btn' className='submit-icon' onClick={onSubmit}>
          <img src={Check} />
        </div>
      </InputWrapComponent>
    );
  }
}

export { TeamNameInput };
