import { progressStore } from 'stores';

import { ACTION_IN_PROGRESS } from '../constants';

import { controlsStore } from '../stores';

import { closeConfirmation } from './lifecycle';

export const confirm = async (): Promise<void> => {
  progressStore.log(ACTION_IN_PROGRESS, 'progress');

  controlsStore.value?.confirmCallback && (await controlsStore.value.confirmCallback());
  closeConfirmation();

  progressStore.log(ACTION_IN_PROGRESS, 'completed');
};

export const cancel = async (): Promise<void> => {
  progressStore.log(ACTION_IN_PROGRESS, 'progress');

  controlsStore.value?.cancelCallback && (await controlsStore.value.cancelCallback());
  closeConfirmation();

  progressStore.log(ACTION_IN_PROGRESS, 'completed');
};
