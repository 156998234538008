import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

const AppCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: #202936;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  color: white;
  text-align: center;
  margin: 0px 8px;
`;

export const Card = ({ children, ...rest }: Props) => {
  return <AppCard {...rest}>{children}</AppCard>;
};
