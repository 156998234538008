import { computed } from 'mobx';

import { formDataSelectors } from '../formDataSelectors';
import { mainConditions } from '../mainConditions';
import { distanceSelector } from '../mappedData';

const disciplines = computed((): Array<NewSelectItemType> => {
  const model = distanceSelector.model.get();
  const selectedDisciplinesMap = formDataSelectors.fetchDisciplineOccupation();

  if (!model) {
    return [];
  }

  return (model.value.disciplines || []).map((item: DisciplineType) => ({
    key: item.id,
    value: item.id,
    label: item.title,
    disabled: mainConditions.isTeam.get() && Boolean(selectedDisciplinesMap[item.id]),
  }));
});

/**
 * @description
 * Unlike isEnabled, checks is there options that are not disalbed
 */
const isDisciplineOptionAvailable = computed((): boolean => {
  return disciplines.get().some((option) => !option.disabled);
});

export { disciplines, isDisciplineOptionAvailable };
