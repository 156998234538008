import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';

import { scrollManagerStore } from 'stores';

import races from '../stores/races';

class NestedRouteBehavior {
  config = {
    races: {
      store: races,
    },
  };

  currRoute = '';
  prevRoute = '';
  history: RouteComponentProps.history;

  constructor(history: History) {
    this.history = history;
    this.prevRoute = this.history.location.pathname;
    this.currRoute = this.history.location.pathname;
    this.bindListeners();
  }

  bindListeners() {
    this.history.listen(({ pathname }) => {
      this.prevRoute = this.currRoute;
      this.currRoute = pathname;

      if (this.shouldDropScroll) {
        scrollManagerStore.clearScrollPosition();
        return;
      }

      if (this.shouldSaveScroll) {
        scrollManagerStore.saveScrollPosition();
        return;
      }

      if (this.shouldRestoreScroll) {
        try {
          scrollManagerStore.restoreScroll();
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  get shouldRestoreScroll() {
    const inConfig = this.rootCurrRoute in this.config;
    if (!inConfig) return false;
    return this.rootCurrRoute === this.rootPrevRoute;
  }

  get shouldSaveScroll() {
    const inConfig = this.rootPrevRoute in this.config;
    if (!inConfig) return false;
    return this.currRoute.length > this.prevRoute.length && this.rootCurrRoute === this.rootPrevRoute;
  }

  get shouldDropScroll() {
    const inConfig = this.rootCurrRoute in this.config;
    if (!inConfig) return false;
    return this.rootCurrRoute !== this.rootPrevRoute;
  }

  get rootCurrRoute() {
    return this.currRoute.split('/')[2];
  }

  get rootPrevRoute() {
    return this.prevRoute.split('/')[2];
  }
}

export default NestedRouteBehavior;
