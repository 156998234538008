import { UserType } from 'typings/old-types/UserType';

import { validation } from 'utils';

import { sessionStore, form } from 'stores';

import { MORE_INFO_FORM_ID } from '../../constants';

import { mapper } from '../../utils';

import { userProfile } from '../../services';

import { moreInfoValidation } from '../../validation';

const updateInfoField = async (value: AnyObject) => {
  const fieldKey = value?.name.join('');
  const data = mapper.mapOneField(fieldKey);
  const validateData = mapper.mapToValidation(data);

  if (!validation(MORE_INFO_FORM_ID, validateData, { [fieldKey]: moreInfoValidation[fieldKey] })) {
    return;
  }

  const [status, response] = await userProfile.submitProfile(data as UserType);

  if (status) {
    const user = response.data;
    sessionStore.userProfile(user);
    form.merge(MORE_INFO_FORM_ID, {
      ...mapper.mapFrom(response.data),
    });
  }
};

export { updateInfoField };
