import { keys } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { Data } from '../types';

class DisabledRegistrationFields {
  /**
   * k in string is a field_id
   */
  @observable
  value: { [k in string]: boolean } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  init(disabledRegistrationFields: string[]) {
    this.value = disabledRegistrationFields.reduce(
      (acc, fieldId) => ({
        ...acc,
        [fieldId]: true,
      }),
      {},
    );
  }

  @action
  add(disabledRegistrationFields: string[]) {
    this.value = {
      ...this.value,
      ...disabledRegistrationFields.reduce(
        (acc, fieldId) => ({
          ...acc,
          [fieldId]: true,
        }),
        {},
      ),
    };
  }

  @action
  clean(): void {
    this.value = {};
  }

  @computed
  get disabledFieldIds() {
    return keys(this.value);
  }

  /**
   * @description
   * check is registration field is disabled checking by field id
   *
   */
  isDisabledByFieldId(fieldId: string): boolean {
    return this.value[fieldId];
  }
}

export { DisabledRegistrationFields };
