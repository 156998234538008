import { segmentIgnition } from 'actions';

import { parseUrlParams } from '../utils';

import { segmentsService } from '../services';

import { completeLastPageOrLoadMore } from './loadSegments';
import { reloadDistance } from './reloadDistance';

function onAddSegment() {
  const { distanceId, racerId } = parseUrlParams();

  if (!distanceId || !racerId) {
    return;
  }

  segmentIgnition.switchNew(distanceId, racerId, async (responseState, type) => {
    if (responseState === 'success') {
      await onSuccess(racerId);
      return;
    }

    if (responseState === 'conflict') {
      await onConflict();
    }
  });
}

async function onSuccess(racerId: number) {
  await completeLastPageOrLoadMore();
  await segmentsService.getTotal(racerId);
}

/*
 * Date does not allow to add new segment
 */
async function onConflict() {
  await reloadDistance();
}

export { onAddSegment };
