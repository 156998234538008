import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_DISTANCE_BY_ID } from 'src/constants';

import { request, action } from 'utils';

class DistanceService {
  @request({ action: shortid() })
  request(id: number): Promise<any> {
    const url = generatePath(API_URL + API_DISTANCE_BY_ID, { id });
    return axios.get(url, { params: this.params() });
  }

  @action({ action: shortid() })
  async load(id: number): Promise<null | DistanceType> {
    const [status, response] = await this.request(id);

    if (!status) {
      return null;
    }

    return response.data.data || null;
  }

  params = () => {
    return {
      with: 'goal',
    };
  };
}

export { DistanceService };
