import React, { FC } from 'react';
import styled from 'styled-components';

import { LINK_TO_XTRI_JOURNEY } from 'src/constants';
import { RESOLUTIONS } from 'src/constants';
import { BodyText, Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { handleOpenRaces } from '../../actions';

export const Wrapper = styled.div`
  max-width: 720px;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.main.colors.clay2};
  }

  p {
    margin-bottom: 16px;
    color: ${(props) => props.theme.main.colors.clay2};
  }

  span {
    color: ${(props) => props.theme.main.colors.clay0};

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    max-width: 335px;
    padding: 0 20px;

    p {
      margin-bottom: 8px;
    }

    p,
    span {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export const XtriMessage: FC = () => (
  <Wrapper>
    <BodyText variant='body2'>
      {t('profile.tourBallot.p1')}
      <a href={LINK_TO_XTRI_JOURNEY}>{t('profile.tourBallot.p1XTRIJourney')}</a>
    </BodyText>
    <BodyText variant='body2'>
      {t('profile.tourBallot.p2v2')}
      <Utility variant='u3' weight='bold' onClick={handleOpenRaces}>
        {t('profile.tourBallot.p2Calendar')}
      </Utility>
    </BodyText>
  </Wrapper>
);
