import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { LOAD_DISTANCES, API_URL, API_DISTANCES } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { distancesStore, Distances as DistancesStore } from 'stores';

// Deprecated, DO NOT USE IT
class Distances extends LoadableService<DistanceType, FiltersType, DistancesStore> {
  constructor(store: DistancesStore) {
    super({
      store,
      resourcesUrl: `${API_URL}${API_DISTANCES}`,
      resource: LOAD_DISTANCES,
    });
  }

  @request({ action: LOAD_DISTANCES })
  async loadDistance(raceId: string, params: FiltersType | {}): Promise<any> {
    const URL = generatePath(this.resourcesUrl, { id: raceId });
    return axios.get(URL, {
      params,
    });
  }

  @action({ action: LOAD_DISTANCES, minRequestTime: 800 })
  async loadDistances(raceId: string): Promise<any> {
    let { page, filters, sortBy, orderBy } = this.store || {};
    const queryParams = {
      ...filters,
      sortBy,
      orderBy,
      page,
    };

    const [status, response] = await this.loadDistance(raceId, queryParams);

    if (status) {
      let values = [];
      if (this.resourcesRoot === 'none') {
        values = response.data;
      } else {
        values = response.data[this.resourcesRoot];
      }
      this.addValues(values, page, queryParams);

      const paginationMeta = response.data[this.metaRoot] && response.data[this.metaRoot][this.paginationMetaRoot];
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }
  }
}

const distancesService = new Distances(distancesStore);

export { Distances, distancesService };
