import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { BACKEND_DATE_FORMAT, BIRTHDAY_MIN_FROM_DATE, RESOLUTIONS } from 'src/constants';

import { t } from 'utils';

import { InputWrapComponent, StyledInput } from '../InputTools';
import { Utility } from '../Typography';

const isObjectHasAllEmptyValues = (obj) =>
  Object.values(obj).every((value) => {
    return value === null || value === undefined || value === '';
  });

const Wrapper = styled.div<{ hasError: boolean; hideDateLabels?: boolean }>`
  display: flex;
  align-items: center;

  .input-item {
    display: flex;
    flex-direction: column;
    .input-item-label {
      margin-bottom: 8px;
      display: ${(props) => (props.hideDateLabels ? 'none' : 'block')};
    }
  }

  .input-item:not(:last-child) {
    margin-right: 16px;
  }

  .input-component {
    width: 100%;
    flex: unset;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
  }
`;

const placeholders = {
  day: 'DD',
  month: 'MM',
  year: 'YYYY',
};

type FieldName = 'day' | 'month' | 'year';

type Date = {
  day: string;
  month: string;
  year: string;
};

const minDate = moment(BIRTHDAY_MIN_FROM_DATE).format(BACKEND_DATE_FORMAT);
const maxDate = moment().format(BACKEND_DATE_FORMAT);
const CURRENT_YEAR = moment().year();
const MIN_YEAR = moment(BIRTHDAY_MIN_FROM_DATE).year();

export const SeparatedDateField: React.FC<BaseInputProps> = (props) => {
  const { errorMessage, label, name, value, disabled, requiredMark, hideDateLabels } = props;

  const [filled, setFilled] = useState<FieldName | ''>('');
  const [date, setDate] = useState<Date>({ day: '', month: '', year: '' });
  const [validationError, setValidationError] = useState<string>('');

  const refMonth = useRef<HTMLInputElement>(null);
  const refYear = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { onChange, name } = props;
    const { day, month, year } = date;
    const dateMomentObj = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
    const isValidDate = dateMomentObj.isValid();
    const isBetween = dateMomentObj.isBetween(minDate, maxDate);

    // Add a date to the form if it is valid and included to a valid interval. And also if the user entered one digit in the "day" or "month" field (1986-3-1)
    if (isValidDate && isBetween && day && month && year && year.length === 4) {
      onChange({ name, value: dateMomentObj.format(BACKEND_DATE_FORMAT) });
      setValidationError('');

      // Clear form field state if user enter invalid date, avoiding clearing on first render on first render
    } else if (!isObjectHasAllEmptyValues(date)) {
      onChange({ name, value: null });
    }

    // Display an error if the user filled in all the fields but the date was incorrect or was not included in the valid interval
    if ((!isValidDate || !isBetween) && day.length === 2 && month.length === 2 && year.length === 4) {
      setValidationError(t.staticAsStringMultiple('settings.birthday validation.notValid'));
    }
  }, [date]);

  useEffect(() => {
    if (value !== undefined) {
      const dateMoment = moment(value);
      const isValidDate = dateMoment.isValid();
      const isBetween = dateMoment.isBetween(minDate, maxDate);

      if (isBetween && isValidDate) {
        const valueAsArray = value.split('-');
        // Add initial state from the form
        if (isObjectHasAllEmptyValues(date)) {
          setDate({ day: valueAsArray[2], month: valueAsArray[1], year: valueAsArray[0] });
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (filled === 'day') {
      refMonth.current?.focus();
    }
    if (filled === 'month') {
      refYear.current?.focus();
    }
  }, [filled]);

  const getInputProps = (fieldName: FieldName) => {
    const { name, disabled, readOnly, id } = props;
    return {
      className: 'input-component',
      type: 'text',
      placeholder: placeholders[fieldName],
      name: `${name}:${fieldName}`,
      value: date[fieldName],
      onChange: handleChange(fieldName),
      onBlur: handleBlur(fieldName),
      id: `${id}:${fieldName}`,
      disabled,
      readOnly,
      withUnit: false,
    };
  };

  const handleBlur =
    (field: FieldName) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const fieldValue = e.currentTarget.value;

      if (['day', 'month'].includes(field) && fieldValue.length === 1) {
        setDate((state) => ({ ...state, [field]: fieldValue === '0' ? '01' : `0${fieldValue}` }));
      }
      if (field === 'year' && fieldValue && Number(fieldValue) < MIN_YEAR) {
        setDate((state) => ({ ...state, [field]: '' }));
      }
    };

  const handleChange =
    (field: FieldName) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      let stateValue: string = e.currentTarget.value;

      if (!stateValue.match(/^\d+$/) && stateValue !== '') return;
      if (['day', 'month'].includes(field) && stateValue.length > 2) return;

      if (stateValue) {
        if (Number(stateValue) > 31 && field === 'day') stateValue = '31';
        if (Number(stateValue) > 12 && field === 'month') stateValue = '12';
        if (['day', 'month'].includes(field) && stateValue === '00') stateValue = '01';
        if (field === 'year' && stateValue === '0000') stateValue = MIN_YEAR.toString();
        if (Number(stateValue) > CURRENT_YEAR && field === 'year') stateValue = CURRENT_YEAR.toString();
      }

      if (stateValue.length === 2) {
        if (field === 'day') setFilled('day');
        if (field === 'month') setFilled('month');
      }

      setDate((state) => ({ ...state, [field]: stateValue }));
    };

  return (
    <InputWrapComponent
      errorMessage={validationError || errorMessage}
      disabled={disabled}
      label={label}
      name={name}
      value={value}
      requiredMark={requiredMark}
    >
      <Wrapper className='separated-time' hasError={Boolean(errorMessage)} hideDateLabels={hideDateLabels}>
        <div className='input-item'>
          <Utility variant='u4' weight='bold' className='input-item-label'>
            {t.staticAsString('settings.birthday.day')}
          </Utility>
          <StyledInput
            {...getInputProps('day')}
            {...testAnchors.field(name, TEST_ANCHORS.fieldStructure.fieldPart, 'day')}
            hasError={!!(errorMessage || validationError)}
          />
        </div>
        <div className='input-item'>
          <Utility variant='u4' weight='bold' className='input-item-label'>
            {t.staticAsString('settings.birthday.month')}
          </Utility>
          <StyledInput
            {...getInputProps('month')}
            {...testAnchors.field(name, TEST_ANCHORS.fieldStructure.fieldPart, 'month')}
            hasError={!!(errorMessage || validationError)}
            ref={refMonth}
          />
        </div>
        <div className='input-item'>
          <Utility variant='u4' weight='bold' className='input-item-label'>
            {t.staticAsString('settings.birthday.year')}
          </Utility>
          <StyledInput
            {...getInputProps('year')}
            {...testAnchors.field(name, TEST_ANCHORS.fieldStructure.fieldPart, 'year')}
            hasError={!!(errorMessage || validationError)}
            type='text'
            ref={refYear}
          />
        </div>
      </Wrapper>
    </InputWrapComponent>
  );
};
