import { ResultStatus } from 'modules/results/types';

import { EMPTY_DATA } from '../../constants';

function formatFallbackResult(status: ResultStatus): string {
  if (status === ResultStatus.DNS) {
    return EMPTY_DATA.dns;
  }

  if (status === ResultStatus.DNF) {
    return EMPTY_DATA.dnf;
  }

  return EMPTY_DATA.empty;
}

function formatFallbackSplit(): string {
  return EMPTY_DATA.empty;
}

function formatFallbackLeaderDiff(): string {
  return EMPTY_DATA.empty;
}

export { formatFallbackResult, formatFallbackSplit, formatFallbackLeaderDiff };
