import { form, progressStore, overlayStore, errorsStore, windowSize } from 'stores';

import { FORM_ID, PRELOADER } from '../constants';

import { openModalStore, modeStore, callbackStore, racerIdStore, segmentIdStore } from '../stores';

import { ModalTypes } from '../types';
import { redirectToRoute } from './route';
import { setupMainData } from './setupMainData';
import { setupSegmentForm } from './setupSegmentForm';

async function switchOn(mode: ModalTypes, segmentId: null | number, distanceId: number, racerId: number, succCallback: Function) {
  const start = new Date();

  progressStore.log(PRELOADER, 'progress');

  openModalStore.on();
  overlayStore.show();

  modeStore.setValue(mode);
  racerIdStore.setValue(racerId);
  callbackStore.setValue(succCallback as any);

  if (segmentId) {
    segmentIdStore.setValue(segmentId);

    await Promise.all([setupMainData(distanceId), setupSegmentForm()]);
  } else {
    await setupMainData(distanceId);
  }

  progressStore.log(PRELOADER, 'completed', { requestStartTime: start, minRequestTime: 200 });
}

function switchOff() {
  openModalStore.off();
  overlayStore.hide();
  modeStore.clear();
  callbackStore.clear();
  racerIdStore.clear();
  segmentIdStore.clear();
  form.clean(FORM_ID);
  errorsStore.clear(FORM_ID);
}

export const ignition = {
  switchOn,
  switchOff,
};
