import { computed } from 'mobx';

import { priceUtils } from '../../utils';

import { mainConditions } from '../mainConditions';
import { distanceSelector } from '../mappedData';
import { transferPrice } from './transferPrice';

/**
 * @description
 * current distance price
 */
export const distancePrice = computed((): number => {
  return priceUtils.fetchPrice(
    distanceSelector.model.get(),
    mainConditions.isPushPayment.get(),
    mainConditions.isConfirmation.get(),
    transferPrice.get(),
  ) as any;
});
