import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { getDefaultRaces, handleFocus } from 'modules/search/actions';
import { recentRacesStore, recomendedRacesStore } from 'modules/search/stores';
import React from 'react';

import { RacesMenu } from './RacesMenu';

type Props = {
  compressed?: boolean;
  fieldName: string;
  label: string;
  placeholder: string;
  value: any;
  onChange: ({ name, value }: { name: string; value: any }) => void;
  isFocused: boolean;
};

export const RaceSearch: React.FC<Props> = (props) => {
  const { compressed, fieldName, value, onChange, label, placeholder, isFocused } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isFocused && inputRef?.current) {
      inputRef.current.focus();
    }
    if (!isFocused) {
      inputRef?.current?.blur();
    }
  }, [isFocused]);

  const setFocusField = () => {
    handleFocus(fieldName);
    getDefaultRaces();
  };

  return (
    <Observer>
      {() => {
        const isRecentValues = !value && !isEmpty(recentRacesStore.value);
        const isRecomendedValues = !value && !isEmpty(recomendedRacesStore.value);
        return (
          <>
            <input
              {...testAnchors.useField(fieldName, TEST_ANCHORS.fieldStructure.root)}
              ref={inputRef}
              name={fieldName}
              type='text'
              placeholder={label}
              value={value || ''}
              onChange={(e) => {
                onChange({ name: e.currentTarget.name, value: e.currentTarget.value });
              }}
              onFocus={setFocusField}
            />
            <RacesMenu isRecentValues={isRecentValues} isRecomendedValues={isRecomendedValues} />
          </>
        );
      }}
    </Observer>
  );
};
