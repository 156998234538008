import { isEmpty } from 'lodash';
import moment from 'moment';

import { STATUS_FINISHED, STATUS_STARTED } from 'src/constants';

import { duration } from 'utils/duration';

// TODO, move to modules/results
class ResultUtil {
  showTime(result: any = {}, currentDistance: AnyObject = {}): string {
    let copyResult = { ...result };

    const isStarted = !!copyResult.start_time;
    const distance = copyResult?.distance?.value || currentDistance?.value || copyResult?.distance;

    const distanceIsFinished = distance && distance.status === STATUS_FINISHED;
    const distanceIsStarted = distance && distance.status === STATUS_STARTED;

    if (isEmpty(copyResult) && distanceIsStarted) {
      return 'DNS';
    }

    if (!isStarted && (distanceIsFinished || distanceIsStarted)) {
      return 'DNS';
    }

    if (isStarted && !copyResult.finish_time && distanceIsFinished) {
      return 'DNF';
    }

    if (copyResult.hard_dnf) {
      return 'DNF';
    }

    return '--:--';
  }

  showCheckpointTime = (currentDistance: AnyObject, result: AnyObject, split: AnyObject, lastSplit: AnyObject) => {
    const isStarted = !!result.start_time;
    const distance = result.distance || currentDistance?.value;
    const distanceIsFinished = distance && distance.status === STATUS_FINISHED;
    const distanceIsStarted = distance && distance.status === STATUS_STARTED;

    const isLast = split?.id === lastSplit?.id;
    const withLastFinishTime = isLast ? false : lastSplit.finish_time;

    if (!isStarted && (distanceIsFinished || distanceIsStarted)) {
      return 'DNS';
    }

    if (isStarted && split && !split.finish_time && distanceIsFinished && !withLastFinishTime) {
      return 'DNF';
    }

    if (result.start_time && split && split.finish_time) {
      return resultUtil.diffTime(split.finish_time, result.start_time);
    }

    if (!split?.finish_time && result?.hard_dnf) {
      return 'DNF';
    }

    return '--:--';
  };

  // OPTIMIZE THIS
  diffWinnerTime(moreTime: string | number, lessTime: string | number) {
    const moreTimeValue = this._checkMilliseconds(moment.utc(moment(moreTime)));
    const lessTimeValue = this._checkMilliseconds(moment.utc(moment(lessTime)));

    const lessTimeSeconds: number = lessTimeValue.valueOf();
    const moreTimeSeconds: number = moreTimeValue.valueOf();
    const diffTimeValue = moment
      .utc(
        // @ts-ignore
        (parseInt(moreTimeSeconds / 1000) - parseInt(lessTimeSeconds / 1000)) * 1000,
      )
      .valueOf();

    if (diffTimeValue.valueOf() <= 0) {
      return '00:00:00';
    }

    return duration.time(diffTimeValue.valueOf());
  }

  _checkMilliseconds(date: moment.Moment): moment.Moment {
    const dateMatch = date.format('HH:mm:ss.SSS').match(/\d\d:\d\d:\d\d\.(\d\d\d)/);

    const millis = parseInt((dateMatch || [])[1] || '000', 10);
    if (millis > 500) {
      date.add(1, 'second');
    }

    return date;
  }

  diffTime(finishTime: string, startTime: string) {
    const from = moment.utc(startTime);
    const to = moment.utc(finishTime);

    const diff = duration.calcDiff(from, to);

    return duration.time(duration.roundDiff(diff));
  }

  static filterEmptyData(results: ResultType[]): ResultType[] {
    // @ts-ignore
    return results.filter<ResultType>((result) => !!result.team || !!result.racer);
  }
}

const resultUtil = new ResultUtil();
export { resultUtil };
