import moment from 'moment';

import { PAYMENT_STATUS, DISTANCE_MODES } from 'src/constants';

import { raceUtil } from 'utils/race';

import { CheckpointModel } from './checkpoint';
import { ClassModel } from './classes';
import { ParrentModel } from './parrentModel';

class Distance extends ParrentModel<DistanceType> {
  value: DistanceType;
  checkpoints: Array<CheckpointModel>;
  classes: Array<ClassModel>;

  constructor(value: DistanceType) {
    super(value);
    this.value = value;

    this.checkpoints = [];
    this.classes = [];

    if (!!value.checkpoints) {
      this.checkpoints = value.checkpoints.map((split) => new CheckpointModel(split));
    }

    if (!!value.classes) {
      this.classes = value.classes.map((item) => new ClassModel(item));
    }

    if (!!value.custom_fields) {
      this.value.custom_fields = value.custom_fields?.filter((item) => item.is_enabled);
    }
  }

  isTherePendingPayment() {
    const { registrations } = this.value;
    const isRegistrations = !!registrations && !!registrations.length;

    if (!isRegistrations) {
      return false;
    }

    return (registrations || []).some((payment) => payment.state === PAYMENT_STATUS.pending);
  }

  title(maxNameLength: number = -1): string {
    const { value } = this;
    const { race_length, name } = value;
    const label = `${raceUtil.humanizeDistance(race_length)} - ${name}`;
    return maxNameLength !== -1 && label.length > maxNameLength ? label.substr(0, maxNameLength) + '...' : label;
  }

  itemForSelect(maxDistanceNameLength: number = -1) {
    const { value } = this;

    if (!value) {
      return null;
    }

    const classes = this.classes.map((el) => ({
      ...el.value,
      key: el.value.id,
      value: el.value.id,
      label: el.value.title,
    }));

    return {
      ...value,
      key: value.id,
      value: value.id,
      label: this.title(maxDistanceNameLength),
      waves: value.waves,
      classes,
    };
  }

  roundedRaceLength(): number {
    const { race_length } = this.value;
    return Math.round((race_length / 1000) * 100) / 100;
  }

  isAlreadyRegistered(): boolean {
    const { already_registered } = this.value;
    return !!already_registered;
  }

  findRegistrationField(name: registrationFields): RegistrationFieldsType | undefined {
    const fields = this.value.registration_fields || [];
    return fields.find((field) => field.name === name);
  }

  /*
   * allowed time period to register
   */
  isRegistrationOpen(): boolean {
    const now = moment();
    const distaceStartDate = moment.utc(this.value.race_date || NaN).endOf('day');
    const isClassic = this.isMode('classic');

    if (this.isRegistrationInFuture() || this.isRegistrationInPast()) {
      return false;
    }

    if (isClassic && now.isAfter(distaceStartDate)) {
      return false;
    }

    return true;
  }

  isRegistrationClosed(): boolean {
    return !this.isRegistrationOpen();
  }

  /*
   * allowed time period to log result in profile
   */
  isLoggingOpen(): boolean {
    const now = moment();
    const from = moment.utc(this.value.race_date).startOf('day');
    const to = moment.utc(this.value.ends_at || NaN).endOf('day');

    if (!from.isValid() || !to.isValid()) {
      return true;
    }

    if (from.isAfter(now)) {
      return false;
    }

    if (to.isValid() && to.isBefore(now)) {
      return false;
    }

    return true;
  }

  isLoggingClosed(): boolean {
    return !this.isLoggingOpen();
  }

  isEditRegistrationAvailable(): boolean {
    const { editInformationSettings } = this.value;
    if (this.isTeam() || !editInformationSettings) return false;

    const now = moment();
    const from = moment.utc(this.value.editInformationSettings?.from || this.value.registration_starts_at || NaN).startOf('day');
    const to = moment.utc(this.value.editInformationSettings?.to || this.value.registration_ends_at || NaN).endOf('day');

    if (!from.isValid() || !to.isValid()) {
      return true;
    }

    if (from.isAfter(now)) {
      return false;
    }

    return !(to.isValid() && to.isBefore(now));
  }

  isSoldOut(): boolean {
    return raceUtil.isDistanceSoldOut(this.value);
  }

  isStatus(value: distanceStatusType): boolean {
    return value === this.value.status;
  }

  isSingle(): boolean {
    return this.value.type === 'single';
  }

  isTeam(): boolean {
    return this.value.type === 'team';
  }

  isMode(value: 'classic' | 'virtual' | 'cumulative') {
    const mode = this.value.distance_mode;

    switch (value) {
      case 'classic':
        return DISTANCE_MODES.classic === mode;
      case 'virtual':
        return DISTANCE_MODES.virtual === mode;
      case 'cumulative':
        return DISTANCE_MODES.cumulative === mode;
      default:
        return false;
    }
  }

  isRegistrationInFuture(): boolean {
    const now = moment();
    const from = moment.utc(this.value.registration_starts_at || NaN).startOf('day');

    return from.isValid() && from.isAfter(now);
  }

  isRegistrationInPast(): boolean {
    const now = moment();
    const to = moment.utc(this.value.registration_ends_at || NaN).endOf('day');

    return to.isValid() && to.isBefore(now);
  }

  lastPrice(): Price | null {
    return this.value.lastPrice || null;
  }

  firstPrice(): Price | null {
    return this.value.firstPrice || null;
  }

  isNoProfile(): boolean {
    return Boolean(this.value.allow_no_profile_registration);
  }
}

export { Distance };
