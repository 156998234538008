import { HEADER_ANIMATION_DURATION } from 'modules/header/constants';
import styled, { css } from 'styled-components';

import { SmoothElevation } from 'src/styledComponents/Elevation';
import { Svg } from 'src/styledComponents/Icons';

type Props = {
  hasActive?: boolean;
  isTablet?: boolean;
  active?: boolean;
  compressed?: boolean;
  isSportField?: boolean;
  isFocused?: boolean;
};

const getWidth = (props: Props) => {
  switch (true) {
    case props.compressed && props.isSportField:
      return 153;
    case props.compressed:
      return 108;
    case props.isSportField:
      return 158;
    default:
      return 109;
  }
};

export const StyledIcon = styled(Svg)`
  cursor: pointer;
  .icon-background {
    &:hover {
      fill: #eafd9b;
    }
  }
`;

const focusedStyles = css`
  :first-child,
  :last-child,
  :nth-child(2) {
    &::after,
    + label::after {
      height: 0;
    }
  }
`;

export const StyledWrapper = styled.label<Props>`
  position: relative;
  ${(props) => props.isFocused && focusedStyles}
  :not(:first-child) {
    &::after {
      content: '';
      display: ${(props) => (props.isFocused ? 'none' : 'block')};
      position: absolute;
      height: ${(props) => (props.compressed ? 24 : 26)}px;
      width: 1px;
      top: 10px;
      left: 0;
      background: ${(props) => props.theme.main.colors.clay5};
    }
  }
  &:hover {
    &::after,
    + label::after {
      height: 0;
    }
  }
`;

export const StyledSearchItem = styled(SmoothElevation)<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: ${getWidth}px;
  padding: 20px 24px;
  border-radius: 64px;
  cursor: pointer;
  transition: ${HEADER_ANIMATION_DURATION} ease;
  height: ${(props) => (!props.hasActive ? 44 : 48)}px;

  :first-child {
    width: ${(props) => props.isTablet && props.hasActive && 225}px;
    padding: 0 12px;
  }

  &:hover {
    ${(props) =>
      !props.active &&
      css`
        background: ${props.theme.main.colors.clay5};
      `}
  }

  img {
    cursor: pointer;
  }

  label {
    border-radius: 64px;
  }

  .label {
    text-transform: uppercase;
    color: ${(props) => props.theme.main.colors.clay1};
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.42px;
  }

  input {
    border: none;
    outline: none;
    padding: 0;
    width: 88%;
    color: ${(props) => props.theme.main.colors.clay1};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.42px;
    background: transparent;
  }

  .close-search {
    position: absolute;
    right: 2px;
    top: calc(50% - 10px);

    display: ${(props) => (props.active ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;

    height: 20px;
    width: 20px;
    background: ${(props) => props.theme.main.colors.clay6};
    border-radius: 32px;

    &.last {
      right: 52px;
    }

    svg {
      height: 8px;
      width: 8px;

      polygon:first-child {
        fill: ${(props) => props.theme.main.colors.clay1};
        fill-opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &:last-child {
    flex-grow: 8;
    border: none;
  }

  ${(props) =>
    props.compressed &&
    css`
      padding: 18px 24px;

      & > div {
        margin-right: 8px;
      }

      input {
        width: 100%;
        color: ${(props) => props.theme.main.colors.clay1};
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

        &::placeholder {
          color: ${(props) => props.theme.main.colors.clay1};
        }
      }

      a[type='button'] {
        padding: 0;
      }

      &:last-child {
        a[type='button'] {
          margin: 0 8px 0 8px;
        }

        & > svg {
          height: 16px;
          min-width: 16px;
          padding: 12px;
        }
      }
    `}
  ${(props) =>
    !props.compressed &&
    !props.isFocused &&
    css`
      input {
        width: 100%;
        color: ${(props) => props.theme.main.colors.clay1};
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

        &::placeholder {
          color: ${(props) => props.theme.main.colors.clay1};
        }
      }
    `}
  

  ${(props) =>
    props.active &&
    css`
      border-radius: 64px;
      background: ${(props) => props.theme.main.colors.white};
    `}
`;

export const SearchButton = styled.button<{ compressed?: boolean }>`
  position: absolute;
  top: ${(props) => (props.compressed ? 2 : 4)}px;
  left: 113px;
  z-index: 10;
  margin: 0;

  display: flex;
  border: none;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  ${(props) =>
    !props.compressed &&
    css`
      transform: scale(110%);
    `}
`;
