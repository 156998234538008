import { isNil } from 'lodash';
import moment from 'moment';

function parseTime(val: string | nil): moment.Moment | null {
  if (isNil(val)) {
    return null;
  }

  const mVal = moment(val);

  if (!mVal.isValid()) {
    return null;
  }

  return mVal;
}

export { parseTime };
