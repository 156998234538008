import { keys, includes } from 'lodash';

import { ENVIRONMENTS, ADMIN_HOSTS } from 'src/constants';

import { app } from 'utils';

const envs = keys(ENVIRONMENTS);
const ENV_HTML_ELEMENT = 'app-environment';
const FORCED_END = 'forced-env';

class AppEnvironmentControl {
  currentEnv(): Environment | null {
    const value = document.getElementById(ENV_HTML_ELEMENT);

    if (!value) {
      return null;
    }

    const env: Environment | null = value.dataset.environment as any;
    const forcedEnv = this.forcedEnv();

    return forcedEnv || env;
  }

  setCurrentEnvForApp() {
    const value = this.currentEnv();
    if (!value) {
      return;
    }

    app.assign('environment', value);
  }

  isEnv(value: Environment): boolean {
    return this.currentEnv() === value;
  }

  forcedEnv(): Environment | null {
    const forcedEnv: Environment | null = localStorage.getItem(FORCED_END) as any;

    if (!this.isValidEnv(forcedEnv)) {
      return null;
    }

    return forcedEnv;
  }

  get isDev(): boolean {
    return this.isEnv('development');
  }

  isValidEnv(value: any): boolean {
    return includes(envs, value);
  }
  currentEnvWithDefaultDev(): Environment {
    return this.currentEnv() || 'development';
  }
  generateUrlByEnv(): string {
    const data = this.currentEnv();

    return data ? ADMIN_HOSTS[data] : ADMIN_HOSTS.development;
  }
}

const appEnvControl = new AppEnvironmentControl();

export { AppEnvironmentControl, appEnvControl };
