import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const ArrowLeftPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M16.1893 23.3107L18.3107 21.1893L8.87133 11.75L18.3107 2.31067L16.1893 0.18935L4.62869 11.75L16.1893 23.3107Z'
    fill='white'
  />
);

export const ArrowLeft = (props: IconProps) => <SvgIcon path={ArrowLeftPath} {...props} />;
