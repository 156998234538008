import { load } from './load';
import { loadCounter } from './loadCounter';
import { loadMore } from './loadMore';
import { loadUntilViewport } from './loadUntilViewport';

const loader = {
  load,
  loadMore,
  loadUntilViewport,
  loadCounter,
};

export { loader };
