import React from 'react';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';
import { ShowMobile } from 'src/styledComponents/Conditions';
import { Link } from 'src/styledComponents/Link';
import { BodyText } from 'src/styledComponents/Typography';

import { t } from 'utils';

const Wrapper = styled(BodyText)`
  color: ${(props) => props.theme.main.colors.clay3};
  font-size: 16px;
  line-height: 22px;
  a {
    color: ${(props) => props.theme.main.colors.clay3};
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid ${(props) => props.theme.main.colors.clay3};
  }
`;

const TermsAndConditions = () => {
  return (
    <Wrapper variant='body1'>
      <span>{t('login.selectingContinue')}</span>
      &nbsp;
      <ShowMobile>
        <br />
      </ShowMobile>
      <Link className='link' to={ROUTER.PUBLIC_TERMS} target='_blank' rel='noreferrer noopener' ATN='terms'>
        {t('login.termsAndConditions')}
      </Link>
      <span> and</span>
      &nbsp;
      <Link className='link' to={ROUTER.PUBLIC_PRIVACY} target='_blank' rel='noreferrer noopener' ATN='privacy'>
        {t('login.privacyPolicy')}
      </Link>
    </Wrapper>
  );
};

export default TermsAndConditions;
