import * as React from 'react';

import { sidebarSettingsStore } from '../../stores';

import { SidebarSettingsType } from '../../types';

export const useSidebarSettings = (settings: SidebarSettingsType) => {
  const { loginHandler } = settings;

  React.useEffect(() => {
    sidebarSettingsStore.setValue({
      ...(sidebarSettingsStore.value || {}),
      loginHandler,
    });

    return () => {
      sidebarSettingsStore.clear();
    };
  }, []);
};
