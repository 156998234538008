import classNames from 'classnames';
import * as React from 'react';
import Scrollbar from 'react-scrollbars-custom';

import { scrollManagerStore } from 'stores';

type Props = {
  theme: 'light' | 'dark';
  children: React.ReactElement;
  loadMore?: Function;
  hasMore?: boolean;
  isYtrackVisible?: boolean;
  isXtrackVisible?: boolean;
  loader?: React.ReactNode;
  scrollBarClasses?: string;
  scrollDownCallBack?: Function;
};

type ScrollBarProps = {
  theme: 'light' | 'dark';
  children?: React.ReactElement;
  loader?: React.ReactNode;
};

export class CustomScrollBar extends React.PureComponent<Props> {
  static defaultProps = {
    theme: 'dark',
  };

  scrollRef: Scrollbar | undefined;

  componentDidMount() {
    scrollManagerStore.scrollRef = this.scrollRef!;
  }

  changeScrollStyle(props: any) {
    const { theme } = this.props;
    const isLightTheme = theme === 'light';

    return <div {...props} className={classNames('scroll', { light: isLightTheme })} />;
  }

  handleUpdate = (container): void => {
    const { hasMore, loadMore, scrollDownCallBack } = this.props;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const bottomGutter = 150;
    // isBottom will be greater than 1 if we are about to reach the bottom
    const isBottom = (scrollTop + bottomGutter) / (scrollHeight - clientHeight) > 1;
    if (isBottom && hasMore && loadMore) {
      loadMore();
    }

    scrollDownCallBack && scrollDownCallBack(scrollTop);
  };

  destructScrollbarsProps = (): ScrollBarProps => {
    const scrollBarsProps = { ...this.props };
    // ScrollBar has warnings for unknown props
    delete scrollBarsProps.hasMore;
    delete scrollBarsProps.loadMore;
    delete scrollBarsProps.loader;

    return scrollBarsProps;
  };

  render() {
    const { isYtrackVisible = true, isXtrackVisible = true, children, hasMore, loader } = this.props;
    return (
      <Scrollbar
        trackYProps={{ style: { opacity: isYtrackVisible ? 1 : 0 } }}
        trackXProps={{ style: { opacity: isXtrackVisible ? 1 : 0 } }}
        thumbYProps={{ style: { opacity: isYtrackVisible ? 1 : 0 } }}
        thumbXProps={{ style: { opacity: isXtrackVisible ? 1 : 0 } }}
        ref={(ref) => (this.scrollRef = ref)}
        onScroll={this.handleUpdate}
      >
        {children}
        {hasMore && loader}
      </Scrollbar>
    );
  }
}
