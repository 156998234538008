import React, { FC, MouseEventHandler, MouseEvent, useCallback, useState, useEffect } from 'react';

type Props = {
  src: string;
  srcSet: string;
  hoverSrc: string;
  hoverSrcSet: string;
  disabled?: boolean;
  className?: string;
  style?: any;
  onClick?: MouseEventHandler;
  alt?: string;
};

export const HoverImage: FC<Props> = ({ src, srcSet, hoverSrc, hoverSrcSet, style, disabled, onClick, className, alt }) => {
  const [imageSrc, setImageSrc] = useState<string>(src);
  const [imageSrcSet, setImageSrcSet] = useState<string>(srcSet);

  useEffect(() => {
    setImageSrc(src);
    setImageSrcSet(srcSet);
  }, [src, srcSet]);

  const mouseOver = useCallback(() => {
    setImageSrc(hoverSrc);
    setImageSrcSet(hoverSrcSet);
  }, [hoverSrc, hoverSrcSet]);

  const mouseOut = useCallback(() => {
    setImageSrc(src);
    setImageSrcSet(srcSet);
  }, [src, srcSet]);

  const handleClick = (e: MouseEvent) => {
    if (!onClick) return;
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <img
      src={imageSrc}
      srcSet={imageSrcSet}
      style={style}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      onClick={handleClick}
      className={className}
      alt={alt}
    />
  );
};
