import { ROUTER } from 'src/constants';

import { encodeQuery, history } from 'utils';

import { tabStore } from '../../stores';

export const handleOpenMagazine = () => {
  tabStore.toggleMagazine();
};

export const handleOpenRegistration = () => {
  const path = encodeQuery.generatePath(ROUTER.RACE_ABOUT_US, {
    // @ts-ignore
    id: tabStore.ballotRace?.id,
  });
  history.push(path);
};

export const handleOpenRaces = (): void => {
  const query = encodeQuery.stringify({
    raceName: 'XTRI World Tour',
  });
  history.push(`${ROUTER.RACES}?${query}`);
};
