import moment from 'moment';

class DateCorrector {
  isDateValid(value?: Date | null | string | null | moment.Moment | null): boolean {
    if (!value) {
      return false;
    }

    return moment(value).isValid();
  }

  newDate(value?: string | moment.Moment | Date | null): Date | undefined {
    if (this.isDateValid(value)) {
      return moment(value as any).toDate();
    }
  }

  // Possible change month and day
  correctYearChange(value: Date): Date {
    if (this.isDateValid(value)) {
      return value;
    }

    return new Date(`${value.getFullYear()}`);
  }

  // Possible change day
  correctMonthChange(value: Date): Date {
    if (this.isDateValid(value)) {
      return value;
    }

    return new Date(value.getFullYear(), value.getMonth());
  }
}

const dateCorrector = new DateCorrector();
export { DateCorrector, dateCorrector };
