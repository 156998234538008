import { Button } from 'modules/miles/shared/components';
import styled, { css } from 'styled-components';

export const CardWrapper = styled.div``;
export const animation = css`
  transition: 0.3s ease-in-out;
`;

export const SlidePaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 auto;
  max-width: 600px;
  .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
  }
`;

export const IconButton = styled(Button)`
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  ${animation}
  p {
    display: flex;
  }
`;
