import { Observer } from 'mobx-react';
import { amountInDropdown, GET_SEARCH_RACES } from 'modules/search/constants';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { progressStore } from 'src/stores';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { Utility } from 'src/styledComponents/Typography';
import { gradientAnimation } from 'src/styles';
import { t } from 'src/utils/t';

const StyledUtility = styled(Utility)`
  display: block;

  color: ${(props) => props.theme.main.colors.clay3};
  padding: 0 0 16px 20px;
  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;
  }
`;

const OptionMock = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 20px;

  .mock-img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.main.colors.clay5};
    ${gradientAnimation}
  }

  .mock-text {
    height: 20px;
    flex: 1;
    border-radius: 4px;
    background-color: ${(props) => props.theme.main.colors.clay5};
    ${gradientAnimation}
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding-left: 28px;

    .mock-text {
      margin: 6px 0;
    }
  }
`;

type Props = {
  children: React.ReactNode;
};

export const RacesOptionsLoader: React.FC<{ isRecentValues: boolean; isRecomendedValues: boolean; children: React.ReactNode }> = (
  props,
) => {
  const { children, isRecentValues, isRecomendedValues } = props;

  const getName = () => {
    if (isRecomendedValues && !isRecentValues) {
      return t('search.recomendedRaces');
    }
    if (isRecentValues) {
      return t('search.recentSearch');
    }
    return t('search.raceName');
  };

  return (
    <Observer>
      {() => {
        return (
          <>
            {!progressStore.isLoading(GET_SEARCH_RACES) ? (
              children
            ) : (
              <>
                <ShowDesktop>
                  <StyledUtility variant='u3' weight='medium'>
                    {getName()}
                  </StyledUtility>
                </ShowDesktop>
                <ShowMobile>
                  <StyledUtility variant='u2' weight='bold'>
                    {getName()}
                  </StyledUtility>
                </ShowMobile>
                <MockOptions />
              </>
            )}
          </>
        );
      }}
    </Observer>
  );
};

export const MockOptions = () => {
  return (
    <>
      {Array.from({ length: amountInDropdown.raceName }, () => null).map((el, idx) => {
        return (
          <OptionMock key={idx}>
            <div className='mock-img' />
            <div className='mock-text' />
          </OptionMock>
        );
      })}
    </>
  );
};
