import { Observer } from 'mobx-react';
import * as React from 'react';

import { MobileHeader, MobileDistanceHeader } from 'src/modules/racersTable/components';

import { MobileRacerList } from '../../components/Mobile';
import { UsersCount } from '../../components/UsersCount';

import { currentDistance, generateIsBibs } from '../../derivations';

export const MobileList = () => {
  return (
    <Observer>
      {() => {
        const distance = currentDistance.model.get();
        if (!distance) return null;
        const distanceId = distance.value.id;
        const hasBibs = generateIsBibs(distanceId).get();

        return (
          <>
            <MobileHeader
              isTeam={distance.value.type === 'team'}
              participantsCount={<UsersCount distanceId={distance.value.id} />}
              hasBibs={hasBibs}
            />
            <MobileDistanceHeader name={distance.title()} />
            <MobileRacerList distanceId={distance.value.id} type={distance.value.type} />
          </>
        );
      }}
    </Observer>
  );
};
