import { isNumber, flatten } from 'lodash';
import { CustomFieldTypes } from 'modules/registration/constants';
import { Data } from 'modules/registration/types';

import { mapperUtils } from '../mappers';

/**
 * @description
 * Only option-type fields can have price - radio,dropdown,checkbox...
 * Filter so, only those option-typed custom fields are presented
 * and map it to format that easy to access - number[] (cutomFieldValueId[])
 */
function calculatePricedCustomFieldsValues(customFields: Data.Form.CustomFields): number[] {
  const customFieldsWithPrice = mapperUtils.filterCFByType(customFields, [
    CustomFieldTypes.checkbox,
    CustomFieldTypes.radio,
    CustomFieldTypes.drop_down,
    CustomFieldTypes.extra,
  ]);

  return flatten(mapperUtils.mapPriceFormCFToBECF(customFieldsWithPrice).map((customField) => customField.values as number | number[])).map(
    (item) => Number(item),
  );
}

/**
 * @description
 * sum custom field value ids prices for one group sign up member
 */
const calculateCustomFieldsPrice = (
  membersCustomFields: Data.Form.CustomFields,
  customFieldValuesPrices: { [K in number]: number | nil },
): number => {
  const pricedCustomFieldValues = calculatePricedCustomFieldsValues(membersCustomFields);

  return pricedCustomFieldValues.reduce((total, customFieldValueId) => {
    const price = customFieldValuesPrices[customFieldValueId];
    if (isNumber(price)) {
      total += price;
    }

    return total;
  }, 0);
};

export { calculateCustomFieldsPrice, calculatePricedCustomFieldsValues };
