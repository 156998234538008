import * as React from 'react';
import shortid from 'shortid';

import { ButtonScroll } from 'src/modules/racersTable/components';
import { DesktopSkeleton, MobileSkeleton } from 'src/modules/racersTable/components/Skeleton';
import { ShowDesktop, ShowTablet, ShowMobile } from 'src/styledComponents/Conditions';
import { PageContent } from 'src/styledComponents/PageContent';
import { UtilityContext } from 'src/styledComponents/Typography';

import { NoResults } from '../components/NoResults';

import { LOAD_RESULTS, PRELOADER, RESULTS_CONTAINER_ID } from '../constants';

import { mount, unmount } from '../actions';

import { Table, AllTable, FiltersWrapper } from './Desktop';
import { MobileAllLists, MobileFiltersWrapper, MobileList } from './Mobile';
import { UserModal } from './UserModal';

export const Base = () => {
  const id = shortid();

  React.useEffect(() => {
    mount(id);
    return () => {
      unmount(id);
    };
  }, []);

  return (
    <>
      <UserModal />
      <ShowDesktop withTablet={true}>
        <ButtonScroll offsetTop={500} />
        <PageContent id={RESULTS_CONTAINER_ID}>
          <FiltersWrapper />
          <DesktopSkeleton withFakePlace actions={[LOAD_RESULTS, PRELOADER]}>
            <NoResults>
              <Table />
              <AllTable />
            </NoResults>
          </DesktopSkeleton>
        </PageContent>
      </ShowDesktop>
      <ShowTablet>
        <ButtonScroll offsetTop={650} />
        <UtilityContext.Provider value={{ withoutMediaConditions: true }}>
          <MobileFiltersWrapper />
          <div id={RESULTS_CONTAINER_ID}>
            <MobileSkeleton withFakeTime actions={[LOAD_RESULTS, PRELOADER]}>
              <NoResults>
                <MobileList />
                <MobileAllLists />
              </NoResults>
            </MobileSkeleton>
          </div>
        </UtilityContext.Provider>
      </ShowTablet>
      <ShowMobile>
        <ButtonScroll offsetTop={630} />
        <MobileFiltersWrapper />
        <div id={RESULTS_CONTAINER_ID}>
          <MobileSkeleton withFakeTime actions={[LOAD_RESULTS, PRELOADER]}>
            <NoResults>
              <MobileList />
              <MobileAllLists />
            </NoResults>
          </MobileSkeleton>
        </div>
      </ShowMobile>
    </>
  );
};
