import {
  generateDistanceSelect,
  generateClassesSelect,
  generateWavesSelect,
  generateCheckpointsSelect,
  gendersSelect,
} from 'modules/racersTable/utils';

import { Distance } from 'models';

import { distancesStore, classesStore, wavesStore, checkpointsStore } from '../stores';

import { currentDistance } from '../derivations/currentDistance';

function distancesDisableShowAllIf(distances: Distance[]) {
  if (distances.length <= 1) {
    return true;
  }

  return distances.some((distance) => distance.isMode('cumulative') || distance.isMode('virtual'));
}

const distanceSelect = generateDistanceSelect(distancesStore, distancesDisableShowAllIf);
const classesSelect = generateClassesSelect(currentDistance.id, classesStore);
const wavesSelect = generateWavesSelect(currentDistance.id, wavesStore);
const checkpointsSelect = generateCheckpointsSelect(currentDistance.id, checkpointsStore);

export { distanceSelect, classesSelect, checkpointsSelect, gendersSelect, wavesSelect };
