import { Observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { Divider } from 'src/styledComponents/Divider';
import { Utility } from 'src/styledComponents/Typography';

import { Form } from 'components/form';
import { Loading } from 'components/loading';
import { Modal } from 'components/modal';

import { blockBodyScroll, t, unblockBodyScroll } from 'utils';

import { windowSize } from 'stores';

import { FORM_ID, PRELOADER, LOADER } from '../constants';

import { onSubmit, ignition } from '../actions';

import { distanceStore } from '../stores';

import { Fields } from './Fields';
import { Title } from './Title';

type Props = {
  open: boolean;
};

const Wrapper = styled.div<{ isSwipeEnd: boolean }>`
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  padding: 0 20px 40px;
  border-radius: 24px 24px 0 0;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 628px;
    padding: 32px 40px 40px;
    border-radius: 24px;
  }

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }

  .loading-overlay {
    .spinner {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

const DistanceNameUtility = styled(Utility)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  align-items: center;

  > :not(:last-of-type) {
    margin-bottom: 8px;
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    align-items: flex-start;
  }
`;

const StyledDivider = styled(Divider)`
  height: 1px;
`;

const Content = styled.div`
  margin: 30px 0 40px;
`;

const StyledForm = styled(Form)`
  > :not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  height: 56px;
  button {
    margin-left: 0;
    span {
      font-size: 20px;
      line-height: 24px;
    }

    @media (min-width: ${RESOLUTIONS.medium}px) {
      margin-left: 32px;
    }
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-top: 40px;
  }
`;

const FormModal = ({ open }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSwipeEnd, setIsSwipeEnd] = useState(false);

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const handleTransitionEnd = () => {
    if (isSwipeEnd) ignition.switchOff();
  };

  useEffect(() => {
    blockBodyScroll();
    return () => {
      unblockBodyScroll();
    };
  }, []);

  return (
    <TestAnchorContext.Provider value={{ container: 'Profile-SegmentsForm' }}>
      <Observer>
        {() => {
          const isMobile = !windowSize.isGreaterThan('medium');

          return (
            <Modal open={open}>
              <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={handleTransitionEnd}>
                {isMobile && (
                  <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
                    <DraggableStroke />
                  </Swipe>
                )}
                <Loading action={PRELOADER} opacity={1} />
                <Loading action={LOADER} opacity={1} />

                <Header>
                  <Title />

                  <DistanceNameUtility variant='u1' weight='medium'>
                    {distanceStore.value?.name}
                  </DistanceNameUtility>
                </Header>

                <StyledDivider light />

                <Content>
                  <StyledForm id={FORM_ID} onSubmit={onSubmit} disableAutofill>
                    <Fields />
                  </StyledForm>
                </Content>

                <Controls>
                  {!isMobile && (
                    <Button variant='secondary' size='medium' onClick={ignition.switchOff} ATN='cancel'>
                      {t('settings.cancel')}
                    </Button>
                  )}

                  <Button variant='primary' size='medium' onClick={onSubmit} fullWidth={isMobile} ATN='submit'>
                    {t('settings.save')}
                  </Button>
                </Controls>
              </Wrapper>
            </Modal>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};

export { FormModal };
