import { isEmpty, keys } from 'lodash';

import { errorsStore } from 'stores';

import { REGISTRATION_FORM } from '../constants';

/**
 * @description
 * Check is there errors for group of keys that match patterns
 */
function matchErrors(matchers: RegExp[]): boolean {
  if (isEmpty(matchers)) {
    return false;
  }

  const flatErrors = errorsStore.getWithFlatKeys(REGISTRATION_FORM);
  const errorsKeys = keys(flatErrors);

  const matcherThatHaveErrors = matchers.some((matcher) => {
    return errorsKeys.some((key) => {
      if (matcher.test(key)) {
        return !isEmpty(errorsStore.get(REGISTRATION_FORM, key));
      }
    });
  });

  return Boolean(matcherThatHaveErrors);
}

export { matchErrors };
