import { overlayStore } from 'stores';

import { modalStore, controlsStore, contentStore } from '../stores';

import { ControlsStoreType, ContentStoreType } from '../types';

export const openConfirmation = (controls: ControlsStoreType = {}, content?: ContentStoreType): void => {
  controlsStore.setValue(controls);
  contentStore.setValue(content || { title: '', body: '' });
  overlayStore.show();
  modalStore.on();
};

export const closeConfirmation = (): void => {
  modalStore.off();
  overlayStore.hide();
  controlsStore.clear();
  contentStore.clear();
};
