import * as React from 'react';

export const Visa = (
  <svg width='38' height='24' viewBox='0 0 38 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M37.7815 0H0V24H37.7815V0Z' fill='white' />
    <path d='M37.1865 20.1084H0.664062V23.3828H37.1865V20.1084Z' fill='#F7B600' />
    <path d='M37.1865 0.627441H0.664062V3.90184H37.1865V0.627441Z' fill='#1A1F71' />
    <path
      d='M18.5477 7.50671L16.6226 16.5069H14.2941L16.2194 7.50671H18.5477ZM28.3436 13.3182L29.5694 9.93836L30.2745 13.3182H28.3436ZM30.9428 16.5069H33.0957L31.2146 7.50671H29.2287C28.7809 7.50671 28.4038 7.76628 28.2367 8.16648L24.7428 16.5069H27.1883L27.6737 15.1627H30.6605L30.9428 16.5069ZM24.8642 13.5685C24.8743 11.1934 21.5807 11.0617 21.6026 10.0005C21.6097 9.67792 21.9172 9.33439 22.59 9.24658C22.9233 9.20355 23.8434 9.16892 24.8864 9.64941L25.2943 7.74004C24.7339 7.53749 24.0131 7.34229 23.1163 7.34229C20.8144 7.34229 19.195 8.56494 19.1821 10.3172C19.1674 11.613 20.339 12.3354 21.2197 12.7669C22.1277 13.208 22.432 13.491 22.4276 13.8851C22.4213 14.4889 21.7033 14.7562 21.0351 14.7664C19.8637 14.7845 19.1849 14.4498 18.6437 14.1975L18.2211 16.1706C18.766 16.42 19.7702 16.6371 20.8097 16.6483C23.2567 16.6483 24.857 15.4398 24.8642 13.5685ZM15.2206 7.50671L11.4479 16.5069H8.98687L7.13015 9.32407C7.01768 8.88241 6.91956 8.72009 6.57725 8.53345C6.01735 8.22928 5.09293 7.94487 4.28027 7.76785L4.3352 7.50671H8.29736C8.80198 7.50671 9.25606 7.84254 9.37151 8.42378L10.3523 13.6322L12.7745 7.50671H15.2206Z'
      fill='#1A1F71'
    />
  </svg>
);
