import { truncate } from 'lodash';
import template from 'lodash/template';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { DEFAULT_RACE_IMAGE, LOAD_RACE, RACE_AUTH_OVERLAY, ROUTER } from 'src/constants';
import { RaceTabTitle } from 'src/modules/race/components';
import { RaceRegisterTable } from 'src/modules/race/components/register/RaceRegisterTable';
import { PageContent } from 'src/styledComponents/PageContent';

import { withErrorClean, withProgressSpinner, withRaceAuth } from 'hocs';

import SEO from 'components/SEO';

import { history, htmlSanitizer, stripHtml, t } from 'utils';

import { PaymentsHandler, racesService } from 'services';

import { progressStore, Race as RaceStore, sessionStore, snackStore, windowSize, Wishlist as WishlistsStore } from 'stores';

import { DistanceInfoModal } from '../components/register';

import { loadRace } from '../actions';

import { raceStore } from '../stores';

import { RegisterRaceSkeleton } from './Skeletons';

const StyledDistanceList = styled.div<{ isMobile: boolean }>`
  padding-right: 7px;
  .title-wrapper {
    padding: 65px 0 46px 0;

    .title {
      position: relative;
      padding-left: 32px;
      ::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 40px;
        left: 0px;
        top: -6px;
        background: #272e34;
      }
    }
  }
`;

type Props = {
  racesStore: RaceStore;
  wishlistsStore: WishlistsStore;
} & RouterProps &
  withRaceAuthComponent;

type State = {
  isAuth: boolean;
  activeTab: string;
};

const SUCCESS_PAYMENT_REDIRECT_PARAMS_PATTERN = /success=true/;

@withErrorClean(LOAD_RACE)
@withRaceAuth
@withRouter
@inject('racesStore', 'sportTypesStore', 'wishlistsStore')
@withProgressSpinner(RACE_AUTH_OVERLAY, {
  hideClassName: 'secret-overlay',
  spinner: 'none',
})
@withRaceAuth
@observer // Authorize race by password
export class RegisterRaceContainer extends React.Component<Props, State> {
  paymentHandler: PaymentsHandler | any;

  static defaultProps = {
    racesStore: null as any,
    wishlistsStore: null as any,
    location: null as any,
  };

  state = {
    isAuth: sessionStore.isAuth,
    activeTab: 'desc',
  };

  componentDidMount() {
    this.initialize();
  }

  static getDerivedStateFromProps() {
    return {
      isAuth: sessionStore.isAuth,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isAuth } = this.state;

    if (prevState.isAuth !== isAuth) {
      this.initialize();
    }

    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    this._cleanPaymentHandler();
  }

  initialize = () => {
    const { id } = this.props.match.params;
    this._cleanPaymentHandler();
    this.paymentHandler = new PaymentsHandler(id, racesService);
    this._handleSuccessPayment();
    this._authorizeRace();
    loadRace();
  };

  socialsPosition = (): 'horizontal' | 'vertical' => (windowSize.isLessThan('large') ? 'horizontal' : 'vertical');

  getFirstAllowDistanceId(): number {
    const racesModel = this.props.racesStore.modelSelectedValue;
    const value = racesModel && racesModel.value;

    if (!racesModel || !value) {
      return 0;
    }

    const distances = racesModel.modelDistances();

    const allowedDistance = distances.find((distance) => {
      const registrationOpen = distance.isRegistrationOpen();
      const soldOut = distance.isSoldOut();

      return !distance.value.already_registered && !soldOut && registrationOpen;
    });

    if (!allowedDistance) {
      return 0;
    }

    return allowedDistance.value.id;
  }

  registerToRace = (distanceId?: number): any => {
    const racesModel = this.props.racesStore.modelSelectedValue;
    const value = racesModel && racesModel.value;

    if (!value) {
      return;
    }

    if (value.race_url) {
      return window.open(value.race_url);
    }

    if (!value.allow_registration) {
      return;
    }

    if (!distanceId) {
      history.push(generatePath(ROUTER.RACE_REGISTRATION, { id: value.id }));
      return;
    }

    history.push(generatePath(ROUTER.RACE_REGISTRATION_WITH_DISTANCE, { id: value.id, distanceId }));
  };

  renderDefaultInfo = (): React.ReactNode => {
    const { racesStore } = this.props;
    const racesModel: AnyObject = racesStore.modelSelectedValue || {};
    const value = racesModel && racesModel.value;

    const dateList = racesModel.compareDate('DD MMMM YYYY');

    let info = t.staticAsString('race.defaultInfo');

    return (
      <p className='race-more-info-desc'>
        {template(info)({
          name: racesModel.value.name,
          date: `${dateList[0]}${dateList[1] ? ` - ${dateList[1]}` : ''}`,
          location: racesModel.cityOrCountry() || null || '',
          organizer: value.organizer && value.organizer.full_name,
        })}
      </p>
    );
  };

  descriptionContent = () => {
    const { racesStore } = this.props;
    const { activeTab } = this.state;
    const racesModel = racesStore.modelSelectedValue;
    if (!racesModel) {
      return null;
    }
    const description = racesModel.value.description;
    const organizerEmail = this._contactEmails();

    switch (activeTab) {
      case 'desc':
        return (
          <div className='race-more-info'>
            {description ? (
              <p
                className='race-more-info-desc'
                dangerouslySetInnerHTML={{
                  __html: htmlSanitizer(description),
                }}
              />
            ) : (
              this.renderDefaultInfo()
            )}
          </div>
        );
      case 'contact':
        return (
          <div className='race-more-info'>
            <p
              className='race-more-info-desc'
              dangerouslySetInnerHTML={{
                __html: htmlSanitizer(
                  t.staticAsString(organizerEmail ? 'race.contactInfoWithEmail' : 'race.contactInfo', {
                    organizerEmail: organizerEmail,
                  }),
                ),
              }}
            />
          </div>
        );
      default:
        return this.renderDefaultInfo();
    }
  };

  onChange = (tabToSelect: string) => () => {
    this.setState({
      ...this.state,
      activeTab: tabToSelect,
    });
  };

  _handleSuccessPayment = () => {
    const { search, pathname } = this.props.location;

    if (!SUCCESS_PAYMENT_REDIRECT_PARAMS_PATTERN.test(search)) {
      return;
    }

    const snackId = snackStore.success({
      i18key: 'registration.payment.success_no_profile',
    });
    snackStore.removeWithTimeout(snackId);

    history.push(pathname);
  };

  _cleanPaymentHandler = () => {
    if (this.paymentHandler) {
      this.paymentHandler.clear();
    }
  };

  _contactEmails = () => {
    const { racesStore } = this.props;
    const value = racesStore.modelSelectedValue;

    const contact_emails = value && value.contactEmails();
    return contact_emails || '';
  };

  _authorizeRace = () => {
    const { racesStore, authorizeRace } = this.props;
    const { isAuth } = this.state;

    const { selected, modelSelectedValue } = racesStore;

    if (!selected || !modelSelectedValue) {
      return;
    }

    const isRegiseredToAnyDistance = modelSelectedValue.distancesModels.some((distance) => distance.isAlreadyRegistered());

    if (isAuth && isRegiseredToAnyDistance) {
      return;
    }

    authorizeRace(selected);
  };

  render(): React.ReactNode {
    const { racesStore } = this.props;
    const racesModel: AnyObject = racesStore.modelSelectedValue || {};
    const value = racesModel && racesModel.value;

    const isMobile = windowSize.isLessThan('small');

    if (progressStore.isLoading(LOAD_RACE)) {
      return <RegisterRaceSkeleton />;
    }

    if (!value) {
      return null;
    }

    return (
      <PageContent>
        <SEO
          title={racesStore.selected?.name}
          description={truncate(stripHtml(racesStore.selected?.description ?? ''), { length: 250 })}
          ogImage={racesStore.selected?.image || DEFAULT_RACE_IMAGE}
        />
        <StyledDistanceList isMobile={isMobile}>
          <RaceTabTitle text={t.staticAsString('race.chooseDistance')} />
          <DistanceInfoModal />
          {value.distances.length && (
            // @ts-ignore
            <RaceRegisterTable
              allowRegistration={value.allow_registration}
              distances={raceStore.value?.distances || value}
              onHandleClick={this.registerToRace}
              currency={value.currency.iso_code}
            />
          )}
        </StyledDistanceList>
      </PageContent>
    );
  }
}
