import classNames from 'classnames';
import { toast, ToastPosition } from 'react-toastify';

import { ToastItem } from 'src/styledComponents/Toast';

const AUTOCLOSETIME = 10000;
class ToastService {
  success(message: string) {
    const type: any = 'success';

    toast.success(message, {
      type,
      autoClose: AUTOCLOSETIME,
      className: classNames(`toast-body ${type}`, 'Root.toast.toastSuccess'),
    });
  }

  error(message: string, toastId?: number | string) {
    const type = 'error';
    const options: any = {
      type,
      autoClose: AUTOCLOSETIME,
      className: classNames(`toast-body ${type}`, 'Root.toast.toastError'),
    };

    if (toastId) {
      options.toastId = toastId;
    }

    if (toastId && toast.isActive(toastId as number)) {
      return;
    }

    toast.error(message, options);
  }

  info(message: string) {
    const type: any = 'info';

    toast.info(message, {
      type,
      autoClose: AUTOCLOSETIME,
      className: classNames(`toast-body ${type}`, 'Root.toast.toastInfo'),
    });
  }

  warn(message: string) {
    const type: any = 'warning';

    toast.warn(message, {
      type,
      autoClose: AUTOCLOSETIME,
      className: classNames(`toast-body ${type}`, 'Root.toast.toastWarn'),
    });
  }
}

// Deprecated
export const toastService = new ToastService();

export const fireToast = (
  message: string | JSX.Element,
  type: 'success' | 'error' | 'warning' | 'info',
  title?: string,
  position: ToastPosition = 'top-right',
  autoCloseTime: number = AUTOCLOSETIME,
  closeOnClick: boolean = true,
  toastId?: string,
) => {
  return toast(ToastItem({ message, type, title }), {
    type,
    position,
    autoClose: autoCloseTime,
    closeOnClick,
    ...(toastId && { toastId }),
  });
};
