export const MAIN_THEME_COLORS = {
  /*
   * Brand colors
   * */

  auroraLemon: '#DFFC68', // Primary Aurora Lemon
  auroraLemon66: 'rgba(223, 252, 104, 0.66)',
  auroraLemon33: 'rgba(223, 252, 104, 0.33)',
  black: '#000000',
  clay0: '#131719',
  clay1: '#272E34', // Primary Clay
  clay2: '#50555B',
  clay3: '#656B6F',
  clay4: '#C3C6C8',
  clay5: '#DCDEDF',
  clay6: '#F6F7F7',
  clay7: '#EEEFF0',

  white: '#FFFFFF',
  white20: 'rgba(255, 255, 255, 0.2)',

  /*
   * Feedback colors
   * */

  error: '#EC5E4B',
  error20: 'rgba(236,94,75,0.2)',

  warning: '#F2BE00',
  warning20: '#FCF2CC',

  success: '#00DB62',
  success20: 'rgba(0, 219, 98, 0.2)',

  /*
   * Table background colors
   * */

  gold: 'linear-gradient(286.72deg, rgba(244, 211, 94, 0) 31.17%, rgba(204, 164, 59, 0.6) 186.45%)',
  silver: 'linear-gradient(113.29deg, rgba(173, 181, 189, 0.6) -35.22%, rgba(222, 226, 230, 0) 67.95%)',
  bronze: 'linear-gradient(266.42deg, rgba(195, 142, 112, 0) 15.12%, rgba(119, 73, 54, 0.6) 293.19%)',

  /*
   * Snack background colors
   * */

  errorBG: 'linear-gradient(85.31deg, rgba(236, 94, 75, 0.2) -68.62%, rgba(39, 46, 52, 0.2) 156.27%), #272E34;',
  warningBG: 'linear-gradient(85.31deg, rgba(242, 190, 0, 0.2) -68.62%, rgba(39, 46, 52, 0.2) 156.27%), #272E34',
  successBG: 'linear-gradient(85.31deg, rgba(0, 219, 98, 0.2) -68.62%, rgba(39, 46, 52, 0.2) 156.27%), #272E34;',
  infoBG: 'linear-gradient(85.31deg, rgba(223, 252, 104, 0.2) -68.62%, rgba(39, 46, 52, 0.2) 156.27%), #272E34;',
};

// --------- Miles colors ------------

export const MILES_DARK = {
  alpha: {
    900: '#FFFFFF',
    800: 'rgba(255,255,255,0.85)',
    700: 'rgba(255,255,255,0.65)',
    500: 'rgba(255,255,255,0.24)',
    300: 'rgba(255,255,255,0.08)',
    100: 'rgba(255,255,255,0.03)',
    swap900: '#070A0F',
    swap800: 'rgba(3,7,14,0.85)',
    swap700: 'rgba(3,11,24,0.65)',
    swap500: 'rgba(21,27,39,0.25)',
    swap300: 'rgba(25,40,67,0.08)',
    swap100: 'rgba(0,0,0,0.04)',
    cobalt700: 'rgba(69,140,255,0.65)',
    cobalt500: 'rgba(69,140,255,0.45)',
    cobalt300: 'rgba(69,140,255,0.25)',
    dark500: 'rgba(3,7,14,0.85)',
    dark300: 'rgba(3,7,14,0.45)',
    dark100: 'rgba(3,7,14,0.25)',
  },
  background: {
    default500: '#161E2B',
    600: '#202936',
    700: '#2A3340',
    800: '#36404E',
    900: '#424C5B',
    top500: '#161E2B',
    primary: '#D8FF3A',
    secondary: '#DCDEE0',
    tertiary: '#1F2835',
    positive: '#0BDF76',
    warning: '#FF9E42',
    negative: '#AF002D',
    swapPrimary: '#012668',
    cobalt: '#0146B5',
  },
  icon: {
    primary: '#D8FF3A',
    secondary: '#FFFFFF',
    tertiary: 'rgba(255,255,255,0.85)',
    positive: '#00AC58',
    warning: '#DB7E29',
    negative: '#DA3D3D',
    swapPrimary: '#012668',
    cobalt: '#3985FF',
  },
  thin: {
    primary: '#D8FF3A',
    secondary: '#FFFFFF',
    tertiary: 'rgba(255,255,255,0.85)',
    positive: '#00C966',
    warning: '#E58B37',
    negative: 'rgba(255,80,80,1)',
    swapPrimary: '#002A7C',
    cobalt: '#7BAEFF',
  },
  grad: {
    gradOuterBehind:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 19.27%, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.272) 100%)',
    gradInner: 'linear-gradient(180deg, rgba(0, 0, 0, 0.24) 0%, rgba(255, 255, 255, 0.08) 100%)',
  },
};

const MILES_LIGHT = {
  alpha: {
    900: '#070A0F',
    800: 'rgba(3, 7, 14, 0.85))',
    700: 'rgba(3, 11, 24, 0.65)',
    500: 'rgba(21, 27, 39, 0.24)',
    300: 'rgba(3, 11, 24, 0.65)',
    100: 'rgba(0, 0, 0, 0.03)',
    swap900: '#FFFFFF',
    swap800: 'rgba(255, 255, 255, 0.85)',
    swap700: 'rgba(255, 255, 255, 0.65)',
    swap500: 'rgba(255, 255, 255, 0.30)',
    swap300: 'rgba(255, 255, 255, 0.17)',
    swap100: 'rgba(23, 110, 250, 0.08)',
    cobalt700: 'rgba(23, 110, 250, 0.65)',
    cobalt500: 'rgba(23, 110, 250, 0.50)',
    cobalt300: 'rgba(23, 110, 250, 0.25)',
    dark500: 'rgba(21, 27, 39, 0.3)',
    dark300: 'rgba(25, 40, 67, 0.1)',
    dark100: 'rgba(25, 40, 67, 0.04)',
  },
  background: {
    default500: '#F3F6F9',
    600: '#F6F9FB',
    700: '#F9FBFC',
    800: '#FCFDFE',
    900: '#FFFFFF',
    top500: '#FFFFFF',
    primary: '#051B45',
    secondary: '#051B45',
    tertiary: '#051B45',
    positive: '#0BDF76',
    warning: '#FF9E42',
    negative: '#AF002D',
    swapPrimary: '#D8FF3A',
    cobalt: '#0146B5',
  },
  icon: {
    primary: '#176EFA',
    secondary: '#0146B5',
    tertiary: '#051B45',
    positive: '#00A153',
    warning: '#E56E00',
    negative: '#D83636',
    swapPrimary: '#D8FF3A',
    cobalt: '#0146B5',
  },
  thin: {
    primary: '#0146B5',
    secondary: '#0146B5',
    tertiary: '#012668',
    positive: '#008242',
    warning: '#BD5B00',
    negative: '#EB1111',
    swapPrimary: '#D8FF3A',
    cobalt: '#0146B5',
  },
  grad: {
    gradOuterBehind:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 19.27%, rgba(0, 0, 0, 0) 56.25%, rgba(0, 0, 0, 0.136) 100%)',
    gradInner: 'linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(255, 255, 255, 0.4) 100%)',
  },
};

export const MILES_BRAND = {
  aurora: {
    500: '#D8FF3A',
    100: '#F0FFAB',
    300: '#E6FF7A',
    600: '#AED13B',
    700: '#4F7218',
    800: '#214306',
  },
  cobalt: {
    cobalt500: '#186FFA',
    cobalt50: '#E9F6FF',
    cobalt100: '#BFE6FF',
    cobalt200: '#9DD2FF',
    cobalt300: '#75B5FE',
    cobalt400: '#4490FC',
    cobalt900: '#051B45',
    cobalt550: '#0F5CDA',
    cobalt600: '#0146B5',
    cobalt700: '#013895',
    cobalt800: '#012668',
    cobalt950: '#001234',
  },
  greys: {
    25: '#186FFA',
    50: '#E9F6FF',
    100: '#BFE6FF',
    200: '#9DD2FF',
    300: '#75B5FE',
    400: '#9AA4B2',
    500: '#0F5CDA',
    600: '#0548B8',
    700: '#013895',
    800: '#012668',
    900: '#121926',
    950: '#001234',
  },
  alphas: {
    light900: '#FFFFFF',
    light800: 'rgba(255,255,255,0.85)',
    light700: 'rgba(255,255,255,0.65)',
    light500: 'rgba(255,255,255,0.24)',
    light300: 'rgba(255,255,255,0.08)',
    light100: 'rgba(255,255,255,0.04)',
    dark900: '#070A0F',
    dark800: 'rgba(3,7,14,0.85)',
    dark700: 'rgba(3,11,24,0.65)',
    dark500: 'rgba(255,255,255,0.24)',
    dark300: 'rgba(255,255,255,0.08)',
    dark100: 'rgba(255,255,255,0.03)',
  },
  grad: {
    c600Aur0: 'linear-gradient(270deg, #D8FF3A 1.59%, #73E391 49.38%, #0146B5 95.23%)',
    c500Aur45: 'linear-gradient(225deg, #D8FF3A 0%, #73E391 51.04%, #005DEA 100%)',
    c900C70045: 'linear-gradient(65.49deg, #001A49 0%, #0141AA 102.54%)',
  },
  secondary: {
    magenta100: '#F9B3D7',
    magenta300: '#F073B8',
    magenta500: '#E7339A',
    magenta700: '#AE2071',
    magenta800: '#731149',
    magenta900: '#360120',
    purple100: '#C6ABF3',
    purple300: '#9761E3',
    purple500: '#6B1AD4',
    purple700: '#520F9F',
    purple800: '#520F9F',
    purple900: '#250136',
    red100: '#F9B7B4',
    red300: '#F07775',
    red500: '#E32E2F',
    red700: '#B02121',
    red800: '#701011',
    red900: '#370202',
    orange100: '#FBD6B2',
    orange300: '#F4AD6C',
    orange500: '#EA852B',
    orange700: '#AC5C1C',
    orange800: '#72350F',
    orange900: '#3A1002',
    green100: '#ACF3D0',
    green300: '#5BDBA2',
    green500: '#0EC576',
    green700: '#07955C',
    green800: '#07955C',
    green900: '#01372C',
  },
};

export const MILES_COLORS = {
  dark: MILES_DARK,
  light: MILES_LIGHT,
  brand: MILES_BRAND,
};
