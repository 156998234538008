import { transaction } from 'mobx';

import { progressStore } from 'stores';

import { PRELOADER } from '../constants';

import { dataStore, paginationMetaStore, requestsVersionStore, distancesStore } from '../stores';

/* NOTE
 * reset pagination, data, preloders
 * reenable app start position
 */
function refreshApp() {
  transaction(() => {
    requestsVersionStore.generate();
    dataStore.clean();
    paginationMetaStore.clean();

    /**
     * if there is a distance to load racers/teams by
     * if not, preloader will be eternal
     */
    if (distancesStore.filteredValues.length > 0) {
      progressStore.log(PRELOADER, 'progress');
    }
  });
}

export { refreshApp };
