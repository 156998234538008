import styled from 'styled-components';

export const SidebarUserWrapper = styled.div`
  padding: 0 20px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #dcdedf;
`;
