import { generateMaskedInput } from '../mask';
import { mask, placeholderChar, format, placeholder } from './constants';
import { createAutoCorrectTimeDurationPipe } from './tools';

const timeDurationMask = (maxHours?: number) =>
  generateMaskedInput({
    mask,
    pipe: createAutoCorrectTimeDurationPipe(format, maxHours),
    placeholderChar,
    guide: true,
    keepCharPositions: true,
    alwaysShowMask: true,
    placeholder,
  });

export * from './tools';
export { timeDurationMask };
