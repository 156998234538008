import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { PresentResult } from 'modules/results/components/PresentResult';
import { checkpointsStore } from 'modules/results/stores';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { TagLine, Utility } from 'src/styledComponents/Typography';

import { t } from 'utils/t';

import { LResult } from '../../types';

type Props = {
  value: LResult;
  checkpointId: null | void | number;
  distanceId: number;
  active?: boolean;
};

const Wrapper = styled.div<{ ref }>`
  background-color: white;
  width: 100%;
  transition: All 0.3s;
  padding: 0 16px;
  opacity: 0;
  .right-side {
    text-align: right;
  }

  &.open {
    opacity: 1;
    padding: 24px 16px;
  }

  @media (min-width: ${RESOLUTIONS.large}px) {
    width: 990px;
    padding: 0 56px;
    margin: 0 95px;

    &.open {
      padding: 32px 56px;
    }
  }
  @media (min-width: ${RESOLUTIONS.xlarge}px) {
    width: 1090px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  gap: 24px;
  padding: 0 0;
  border-bottom: 0 solid ${(props) => props.theme.main.colors.clay4};
  transition: All ease-out 0.3s;
  max-height: 0;
  overflow: hidden;

  &.active {
    padding: 16px 0;
    max-height: 100px;
    border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
  }
  &.active.table-header {
    padding-bottom: 8px;
    padding-top: 0;
    border: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const CheckpointsTable = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { distanceId, value, active: isActive } = props;

  const checkpoints = checkpointsStore.findCheckpointsByDistance(distanceId);
  return (
    <Wrapper className={isActive ? 'open' : ''} ref={ref}>
      <Row className={`${isActive ? 'active ' : ''} table-header`}>
        <TagLine>{t('results.splitName')}</TagLine>
        <TagLine className='right-side'>{t('results.splitTime')}</TagLine>
        <TagLine className='right-side'>{t('results.raceTime')}</TagLine>
      </Row>
      {checkpoints.map((checkpoint) => {
        return (
          <Row key={`checkpoint-${checkpoint.value.id}`} className={isActive ? 'active' : ''}>
            <Utility variant='u4' weight='medium'>
              {t(() => checkpoint.value.name)}
            </Utility>
            <Utility variant='u4' weight='medium' className='right-side'>
              <PresentResult.PreviousSplitDiff result={value} checkpointId={checkpoint.value.id} checkpointIndex={checkpoint.value.index} />
            </Utility>
            <Utility variant='u4' weight='medium' className='right-side'>
              <PresentResult.SplitFinishTime result={value} checkpointId={checkpoint.value.id} />
            </Utility>
          </Row>
        );
      })}
    </Wrapper>
  );
});
