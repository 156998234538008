import React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/styledComponents/Icons';

import { theme } from 'styles';

import { Show } from 'components/condition';

import { Text } from '../Typography';
import { BaseButton, getButtonSizes } from './Base';
import { ButtonProps, Message } from './types';

const colors = theme.miles.colors;

const getBackground = (message: keyof typeof Message) => {
  switch (message) {
    case Message.main:
      return {
        default: colors.light.background.primary,
        hovered: colors.brand.cobalt.cobalt700,
      };
    case Message.mainSwap:
      return {
        default: colors.light.background.swapPrimary,
        hovered: colors.brand.aurora[100],
      };
    case Message.subtle:
      return {
        default: colors.brand.alphas.dark300,
        hovered: colors.brand.alphas.dark300,
      };
    case Message.negative:
      return {
        default: colors.light.background.negative,
        hovered: colors.brand.secondary.red500,
      };
    default:
      return null;
  }
};

const getTextColor = (message: keyof typeof Message) => {
  switch (message) {
    case Message.main:
      return colors.light.thin.swapPrimary;
    case Message.mainSwap:
      return colors.light.thin.primary;
    case Message.subtle:
      return colors.light.thin.secondary;
    case Message.negative:
      return colors.light.alpha.swap900;
    default:
      return null;
  }
};

const StyledLightButton = styled(BaseButton)<ButtonProps>`
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
  background: ${(props) => getBackground(props.message)?.default};
  &:hover {
    background: ${(props) => getBackground(props.message)?.hovered};
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    box-shadow: ${(props) =>
      props.message === 'mainSwap'
        ? '0px 2px 3px rgba(0, 0, 0, 0.4)'
        : '0px 1px 1px rgba(0, 0, 0, 0.26), 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05)'};
  }
  color: ${(props) => getTextColor(props.message)};
`;

export const LightButton: React.FC<ButtonProps> = (props) => {
  const { message, size, children, iconLeft, iconRight, iconName, ...rest } = props;
  return (
    <StyledLightButton message={message} size={size} {...rest}>
      <Show if={Boolean(iconLeft)}>
        <Icon className='left-icon' name={iconName || ''} size={getButtonSizes(size).icon} />
      </Show>
      <Text weight='bold' size={getButtonSizes(size).text}>
        {children}
      </Text>
      <Show if={Boolean(iconRight)}>
        <Icon className='right-icon' name={iconName || ''} size={getButtonSizes(size).icon} />
      </Show>
    </StyledLightButton>
  );
};
