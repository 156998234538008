import { progressStore } from 'stores';

import { GET_VIRTUAL_RESULTS, GET_CLASSIC_RESULTS, VIEWPORT_OBSERVABLE } from '../constants';

import { virtualService, classicService } from '../services';

export const virtualOptions = {
  loaderId: GET_VIRTUAL_RESULTS,
  loadFunction: virtualService.getVirtualResults.bind(virtualService),
};

export const classicOptions = {
  loaderId: GET_CLASSIC_RESULTS,
  loadFunction: classicService.getClassicResults.bind(classicService),
};

export const viewPortCheck = (option: AnyObject) => (): void => {
  if (progressStore.isLoading(option.loaderId)) return;

  const observableElement = document.querySelector(`#${VIEWPORT_OBSERVABLE}`);
  if (!observableElement) return;

  const bounding = observableElement.getBoundingClientRect();

  if (bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement?.clientHeight)) {
    option.loadFunction();
  }
};

export const viewPortCheckerMount = (): void => {
  document.addEventListener('scroll', viewPortCheck(virtualOptions));
};

export const viewPortCheckerUnmount = () => {
  document.removeEventListener('scroll', viewPortCheck(virtualOptions));
};

export const viewPortCheckerClassicMount = (): void => {
  document.addEventListener('scroll', viewPortCheck(classicOptions));
};

export const viewPortCheckerClassicUnmount = () => {
  document.removeEventListener('scroll', viewPortCheck(classicOptions));
};
