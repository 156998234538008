import { sessionStore } from 'stores';
import { SWEDEN_COUNTRY_ID } from 'stores/countries';

const SWEDEN_PRICE = 199;
const OTHER_PRICE = 19.99;

export default function adjustedPrice(): string {
  const isSweden = sessionStore.getUserData().country_id === SWEDEN_COUNTRY_ID;
  if (isSweden) {
    return `${SWEDEN_PRICE} SEK`;
  }
  return `€${OTHER_PRICE}`;
}
