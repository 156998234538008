import React, { FC } from 'react';
import styled from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/topographic-pattern.svg';
import { Show } from 'src/components/condition';
import { LOGIN, RESOLUTIONS, ROUTER } from 'src/constants';
import { Logo } from 'src/modules/header/components';
import { windowSize } from 'src/stores';
import { Button } from 'src/styledComponents/Button';
import { Svg } from 'src/styledComponents/Icons';
import { PageContent } from 'src/styledComponents/PageContent';
import { BodyText, Headline } from 'src/styledComponents/Typography';
import { encodeQuery, history } from 'src/utils';

import { MAIN_THEME_COLORS } from 'styles/theme/colors';

import { withProgressSpinner } from 'hocs';

import { getRedirectLocation } from '../utils';

export const Wrapper = styled(PageContent)`
  .section-content {
    width: 100vw;
    height: 100vh;
    background-color: ${MAIN_THEME_COLORS.white};
    background-image: url('${PatternImage}');
    overflow-x: auto;
    z-index: 101;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px 32px 80px;

  svg:hover {
    circle {
      fill: ${(props) => props.theme.main.colors.clay3};
    }
    path {
      stroke: ${(props) => props.theme.main.colors.white};
    }
  }

  @media (max-width: ${RESOLUTIONS.small}px) {
    padding: 0;
  }
`;

const Title = styled.div`
  margin: 6px 0 72px;
  text-align: center;
  text-transform: uppercase;
  max-width: 800px;
  > * {
    margin: 0;
  }

  @media (max-width: ${RESOLUTIONS.small}px) {
    margin: 0 0 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 20px 94px;

  .loading-overlay {
    z-index: 1;
  }

  @media (max-width: ${RESOLUTIONS.small}px) {
    padding: 36px 0 28px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 406px;
`;

const FormWrapper = withProgressSpinner(LOGIN, { withOverlay: true, hideClassName: 'hidden' })(FormContainer);

export const FormField = styled.div`
  margin-bottom: 24px;
`;

export const TermsAndConditions = styled(BodyText)`
  color: ${(props) => props.theme.main.colors.clay3};
  font-size: 16px;
  a {
    color: ${(props) => props.theme.main.colors.clay3};
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid ${(props) => props.theme.main.colors.clay3};
  }
`;

export const Controls = styled.div`
  margin-top: 32px;
`;

export const ButtonWrapper = styled(Button)`
  margin-top: 32px;
  margin-bottom: 16px;
  span {
    font-size: 20px;
  }
  @media (max-width: ${RESOLUTIONS.small}px) {
    padding: 26px 48px;
    span {
      font-size: 20px;
    }
  }
`;

export const SignUpButton = styled(Button)`
  span {
    font-size: 20px;
  }
  @media (max-width: ${RESOLUTIONS.small}px) {
    padding: 26px 48px;
    span {
      font-size: 20px;
    }
  }
`;

export const StyledDivider = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;
  span {
    margin: 0 16px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  div {
    height: 1px;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export type AuthProps = {
  children: React.ReactNode;
  title?: string;
  className?: string;
  testAnchorContainer: TestAnchorContainers;
  closeModal?: AnyFunction;
};

const AuthPageTemplate: FC<AuthProps> = ({ title, children, className, testAnchorContainer, closeModal }) => {
  const isMobile = windowSize.isLessThan('small');

  const onClose = () => {
    const query = encodeQuery.parseQuery(history.location.search);

    if (query.redirect_location) {
      return history.push(getRedirectLocation());
    }

    history.push(ROUTER.HOME);
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Logo size={isMobile ? 32 : 56} />
        <CloseButton onClick={closeModal ? closeModal : onClose}>
          <Svg name='CloseLight' size={isMobile ? 24 : 40} />
        </CloseButton>
      </Header>
      <TestAnchorContext.Provider value={{ container: testAnchorContainer }}>
        <Content className='auth-template-content'>
          <Show if={Boolean(title)}>
            <Title>
              <Headline variant='h2'>{title}</Headline>
            </Title>
          </Show>
          <FormWrapper>{children}</FormWrapper>
        </Content>
      </TestAnchorContext.Provider>
    </Wrapper>
  );
};

export default AuthPageTemplate;
