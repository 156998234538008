import { Observer } from 'mobx-react';
import * as React from 'react';
import { FC } from 'react';

import { APP_INITIALIZE } from 'src/constants';

import { Show } from 'components/condition';
import { Loading } from 'components/loading';

import { progressStore } from 'stores';

import { AccountInformationForm } from './AccountInformationForm';

type Props = {};

export const AccountInformationComponent: FC<Props> = () => {
  return (
    <Observer>
      {() => {
        const isLoading = progressStore.isLoading(APP_INITIALIZE);

        return (
          <>
            <Show if={isLoading}>
              <Loading show opacity={1} />
            </Show>

            <Show if={!isLoading}>
              <AccountInformationForm />
            </Show>
          </>
        );
      }}
    </Observer>
  );
};
