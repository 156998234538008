import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Headline } from 'src/styledComponents/Typography';

const StyledHeadline = styled(Headline)`
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 8px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    text-align: left;
  }
`;
type Props = {
  isMobile: boolean;
  title: string;
};
export const Title = (props: Props) => <StyledHeadline variant={props.isMobile ? 'h4' : 'h3'}>{props.title}</StyledHeadline>;
