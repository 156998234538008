import { observable, action, makeObservable } from 'mobx';

import { resize } from 'utils';

import { overlayStore } from 'stores';

import { registeredTo, xtri, saved, my_results } from '../constants';

import { MetaType, TabStoreType } from '../types';

class Tab implements TabStoreType {
  @observable activeTab = null;

  @observable showMagazine = false;

  @observable tabList = [my_results, registeredTo, saved];

  @observable values: any = [];
  @observable valuesMeta: MetaType | null = null;

  valuesOrder = [];

  @observable deletedValues = {};

  @observable ballotRace = null;

  constructor() {
    makeObservable(this);
  }

  @action
  changeActiveTab(active: string) {
    this.values = [];
    this.valuesMeta = { current_page: 1, per_page: 20, total: 0 };
    this.deletedValues = {};
    this.activeTab = active as any;
  }

  @action
  changeTabPosition(isXtri: boolean) {
    if (isXtri) {
      this.tabList = [my_results, registeredTo, saved, xtri];
    } else {
      this.tabList = [my_results, registeredTo, saved];
    }
  }

  @action
  changeOrder(order: Array<Object>) {
    this.valuesOrder = order as any;
  }

  @action
  changeValues(values: Array<Object>, meta?: MetaType) {
    this.values = values as any;
    this.valuesMeta = meta ? meta : { current_page: 1, per_page: 20, total: 0 };
    this.deletedValues = {};
  }

  @action
  removeValue(id: number): void {
    this.deletedValues = {
      ...this.deletedValues,
      [id]: true,
    };
  }

  @action
  unremoveValue(id: number): void {
    this.deletedValues = {
      ...this.deletedValues,
      [id]: false,
    };
  }

  @action
  toggleMagazine(): void {
    this.showMagazine = !this.showMagazine;

    if (!resize.isMobile()) {
      overlayStore.toggle();
    }
  }

  @action
  setBallotRace(ballotRace: Object): void {
    this.ballotRace = ballotRace as any;
  }
}

const tabStore = new Tab();
export { tabStore };
