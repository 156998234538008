import { ROUTER } from 'src/constants';

import { encodeQuery, history } from 'utils';

import { profileService } from '../services';

export const getRedirectManageTeam = (distance_id: number, raceId: number) => (): string => {
  return encodeQuery.generatePath(ROUTER.MANAGE_TEAM, { raceId }, { distance_id });
};

export const handleRedirectToRace = (id: number) => () => {
  const path = encodeQuery.generatePath(ROUTER.RACE_ABOUT_US, { id });

  history.push(path);
};

export const handleChangeRegisteredPage = async (page: number) => {
  await profileService.loadRegistered(page);
  window.scrollTo({
    top: 0,
  });
};
