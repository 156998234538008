import * as React from 'react';

import { timeDurationMask } from '../core/mask';
import { InputWrapComponent } from '../inputWrap';

type Props = BaseInputProps;

/*
 * Input:
 * - string
 *
 * Output:
 * - string
 *
 * Edit range - 00:00:00 - 99:59:59
 */
function TimeDuration(props: Props) {
  const { errorMessage, disabled, label, name, value, requiredMark, onChange } = props;

  const Component = timeDurationMask();

  return (
    <InputWrapComponent errorMessage={errorMessage} disabled={disabled} label={label} requiredMark={requiredMark} name={name} value={value}>
      <Component
        {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
        {...props}
        className='input-component'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.currentTarget;
          onChange({ name, value }, e);
        }}
      />
    </InputWrapComponent>
  );
}

export { TimeDuration };
