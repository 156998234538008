import { MESSAGES_VERSION_KEY } from './constants';

let version = 0;

function init() {
  version = Number(localStorage.getItem(MESSAGES_VERSION_KEY));
}

function store(newVersion: number) {
  version = newVersion;
  localStorage.setItem(MESSAGES_VERSION_KEY, `${version || ''}`);
}

function compare(newVersion: number) {
  return version === newVersion;
}

const util = {
  init,
  store,
  compare,
};

export { util as version };
