import { RACE_AUTH_OVERLAY } from 'src/constants';

import { overlayStore, progressStore } from 'stores';

function onClose() {
  overlayStore.hide();
  progressStore.log(RACE_AUTH_OVERLAY, 'completed');
}

export { onClose };
