import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const CloseLightPath = (
  <>
    <path d='M4 20L20 4' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4 4L20 20' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </>
);

export const CloseLight = (props: IconProps) => <SvgIcon path={CloseLightPath} {...props} />;
