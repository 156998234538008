import { DefaultTheme } from 'styled-components';

import { MAIN_THEME_COLORS, MILES_COLORS } from './colors';
import { SHADOWS } from './shadows';

export const theme: DefaultTheme = {
  main: {
    colors: MAIN_THEME_COLORS,
    font: 'Aeonik',
  },
  miles: {
    colors: MILES_COLORS,
    font: 'Aeonik',
    shadows: SHADOWS,
  },
};

export * from './globalStyle';
