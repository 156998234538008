import React, { FC, ReactElement } from 'react';

import { LOCALES_STRING } from 'src/constants/general/settings';
import { Text } from 'src/modules/miles/shared/components/Typography';
import { NavLink } from 'src/styledComponents/Link';

import { history, isUrl, t } from 'utils';

import session from 'stores/session';

import { MenuItem } from '../components';

type Props = {
  item: AnyObject;
  isSub?: boolean;
  onClick?: AnyFunction;
};

export const Item: FC<Props> = ({ item, isSub = false, onClick }): ReactElement<any, any> | null => {
  if (item.link.includes('miles')) {
    if (!session.isMilesUser) return null;
  }
  const { location } = history;
  const link = t.staticAsString(item.link as TranslationLockedKeys);

  const handleActive = (item: AnyObject) => {
    const link = location.pathname.replace(new RegExp(`^\\/(${LOCALES_STRING})`), '');

    if (item.exact) {
      return link === item.link;
    }

    const regexp = new RegExp(`^\\${item.link}`);

    return item.link.length < 2 ? link.length === item.link.length && regexp.test(link) : regexp.test(link);
  };

  const chooseMenuItem = () =>
    isSub ? (
      <MenuItem isActive={handleActive(item)} key={item.title}>
        <Text weight='regular' size='xl'>
          {t.staticAsString(`navigation.${item.title}` as TranslationLockedKeys)}
        </Text>
      </MenuItem>
    ) : (
      <MenuItem isActive={handleActive(item)} key={item.title}>
        <Text weight='bold' size='xl'>
          {t.staticAsString(`navigation.${item.title}` as TranslationLockedKeys)}
        </Text>
      </MenuItem>
    );

  return isUrl(link) ? (
    <a target='_blank' href={link}>
      {chooseMenuItem()}
    </a>
  ) : (
    <NavLink onClick={onClick} to={item.link} exact={item.exact || false} isActive={() => handleActive(item)}>
      {chooseMenuItem()}
    </NavLink>
  );
};
