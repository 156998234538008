import axios from 'axios';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { CACHE_KEYS } from 'modules/miles/app/home/constants/localStorageKeys';
import { matchPath } from 'react-router-dom';
import { UserType } from 'typings/old-types/UserType';

import { AUTHORIZATION, ROUTER_WITH_LOCALES } from 'src/constants';

import { Race, User as UserModel } from 'models';

import { authorizedRace } from './authorizedRace';
import wishlistStore from './wishlists';

const TOKEN_KEY = 'token';
const USER = 'user';

export class Session {
  @observable session: SessionStoreType = this.getStoreSession();

  constructor() {
    makeObservable(this);
    this.updateToken();
  }

  @computed
  get isAuth(): boolean {
    return !isEmpty(this.session.user);
  }

  @action
  login(token: string) {
    this.session.token = token;
    this.storeSession(TOKEN_KEY, token);
    this.updateToken();
  }

  @action
  logout() {
    const isLogged = this.isAuth;

    this.session.user = {} as any;
    this.session.token = '';

    this.updateToken();
    this.removeStoreSession(TOKEN_KEY);
    this.removeStoreSession(USER);
    wishlistStore.loadWishListFromStorage();

    localStorage.removeItem(CACHE_KEYS.heartRateZones);
    localStorage.removeItem(CACHE_KEYS.readiness);
    localStorage.removeItem(CACHE_KEYS.signInFormData);
    localStorage.removeItem(CACHE_KEYS.signInFormLastStep);
    localStorage.removeItem(CACHE_KEYS.trainingPlan);

    // To filter invalid login attempts
    if (isLogged) {
      authorizedRace.cleanAll();
    }

    if (isLogged && window.location.pathname.includes('miles')) {
      window.location.assign('/');
    }

    if (isLogged && matchPath(window.location.pathname, { exact: true, path: ROUTER_WITH_LOCALES.RACES_ABOUT })) {
      window.location.reload();
    }
  }

  @action
  userProfile(userProfileInfo: UserType) {
    this.session.user = userProfileInfo;
    const userStringify = JSON.stringify(userProfileInfo);
    this.storeSession(USER, userStringify);
    wishlistStore.loadWishListFromStorage();
  }

  decode(token: string) {
    return (jwtDecode(token) as any).user;
  }

  updateToken() {
    const token = this.session.token;
    if (token) {
      axios.defaults.headers.common[AUTHORIZATION] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common[AUTHORIZATION];
    }
  }

  storeSession(key: string, item: any) {
    localStorage.setItem(key, item);
  }

  removeStoreSession(key: string) {
    localStorage.removeItem(key);
  }

  getUserData(): UserType {
    return toJS(this.session.user);
  }

  // If true redirect to renew subscription page
  getMilesSubscriptionStatus(): boolean {
    if (!this.session.user.subscriptions) return false;
    const { is_trial_active, is_trial_used, status } = this.session.user.subscriptions;
    return !is_trial_active && is_trial_used && status === 0;
  }

  get isMilesUser(): boolean {
    return this.session.user.has_miles_user;
  }

  getStoreSession(): SessionStoreType {
    const token = localStorage.getItem(TOKEN_KEY) || '';
    let user = {};

    if (token) {
      const serializedUser = localStorage.getItem(USER);
      user = serializedUser && JSON.parse(serializedUser);
    }

    if (token && !isEmpty(user)) {
      return {
        user: user as any,
        token: token,
      };
    }

    return {
      user: {} as any,
      token: '',
    };
  }

  @computed
  get modelAppliedRaces(): Array<Race> {
    const { user } = this.session;

    if (!user) {
      return [];
    }

    return user!.applied_races!.map((item) => new Race(item));
  }

  @computed
  get appliedRaces(): Array<RacesType> {
    const { user } = this.session;

    if (!user) {
      return [];
    }

    return user.applied_races!;
  }

  @computed
  get userModel(): UserModel | null {
    if (!this.session.user) {
      return null;
    }

    return new UserModel(this.session.user);
  }

  touchUser() {
    return this.session.user;
  }
}

export default new Session();
