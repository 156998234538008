import { computed } from 'mobx';

import { formDataSelectors } from '../formDataSelectors';
import { totalPrice } from './totalPrice';

export const fullDiscount = computed((): boolean => {
  const price = totalPrice.get();
  const coupon = formDataSelectors.fetchLeaderCoupon();
  if (coupon) {
    return price === 0;
  }
  return false;
});
