import { Observer } from 'mobx-react';
import { CardMedia, Display, SizesText, Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/Background-topo-dark.svg';
import PictureDiscoverMiles1 from 'src/assets/images/miles/DiscoverMiles1.png';
import PictureDiscoverMiles1x2 from 'src/assets/images/miles/DiscoverMiles1@2x.png';
import PictureDiscoverMiles2 from 'src/assets/images/miles/DiscoverMiles2.png';
import PictureDiscoverMiles2x2 from 'src/assets/images/miles/DiscoverMiles2@2x.png';
import PictureDiscoverMiles3 from 'src/assets/images/miles/DiscoverMiles3.png';
import PictureDiscoverMiles3x2 from 'src/assets/images/miles/DiscoverMiles3@2x.png';
import PictureDiscoverMiles4 from 'src/assets/images/miles/DiscoverMiles4.png';
import PictureDiscoverMiles4x2 from 'src/assets/images/miles/DiscoverMiles4@2x.png';
import { RESOLUTIONS } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { ResponsiveImage } from 'src/styledComponents/ResponsiveImage';

import { theme } from 'styles';

import { windowSize } from 'stores';

import { t } from '../../../../utils';

const colors = theme.miles.colors;

export const DiscoverMilesWrapper = styled.div`
  padding: 64px 16px 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.dark.background.default500};
  background-image: url('${PatternImage}');
  .scroll {
    display: flex;
    justify-content: center;
  }
`;

const CardsDesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 73px;
  .card {
    width: 31%;
  }
  img {
    width: 100%;
  }
  @media (max-width: 900px) {
    .card-header {
      font-size: 20px;
    }
    .bottom {
      height: 110px;
      p {
        font-size: 13px;
      }
    }
  }
`;

const CardsMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Heading = styled.div`
  display: flex;
  gap: 12px;
  text-align: center;
  margin-bottom: 48px;
`;

const BottomBlock = styled.div`
  max-width: 1132px;
  background: ${colors.dark.background[600]};
  box-shadow: ${(props) => props.theme.miles.shadows.dark.elevetion2};
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  .text-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0 20px 0 40px;
    .bottom-text {
      margin: 16px 0 0;
    }
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-top: 40px;
    max-width: 358px;
    .text-block {
      margin: 0;
      align-items: center;
      .bottom-header {
        margin: 16px 0;
      }
      .bottom-text {
        margin: 12px;
        text-align: center;
      }
    }
  }
`;

const cards = (
  <>
    <CardMedia
      imagePath1x={PictureDiscoverMiles1}
      imagePath2x={PictureDiscoverMiles1x2}
      className='card'
      textTop='Peak Performance with AI-Powered Precision'
      textBottom='Fine-tuned for <b>top performance running</b>, we bring you a cutting-edge digital training program to achieve your full potential.'
    />
    <CardMedia
      imagePath1x={PictureDiscoverMiles2}
      imagePath2x={PictureDiscoverMiles2x2}
      className='card'
      textTop='Customized Race Plans'
      textBottom='Our race plans are tailored to your <b>individual fitness level and goals.</b> It learns from you daily, ensuring that you have the most optimized and <b>injury-free</b> training program for your body.'
    />
    <CardMedia
      imagePath1x={PictureDiscoverMiles3}
      imagePath2x={PictureDiscoverMiles3x2}
      className='card'
      textTop='Maximize Your Race Performance'
      textBottom='Ever wondered what your fastest race time could be? Achieve your full potential and <b>reach your desired race goals.</b>'
    />
  </>
);

export const DiscoverMiles = () => {
  return (
    <Observer>
      {() => {
        const isMobile = windowSize.isLessThan('medium');
        return (
          <DiscoverMilesWrapper id='DiscoverMiles'>
            <Heading>
              <Display size={SizesText.lg} weight='regular'>
                {t('miles.landing.discover.title')}
              </Display>
              <Display size={SizesText.lg} weight='bold' fontStyle='italic'>
                Miles
              </Display>
            </Heading>
            <ShowDesktop>
              <CardsDesktopWrapper>{cards}</CardsDesktopWrapper>
            </ShowDesktop>
            <ShowMobile>
              <CardsMobileWrapper>{cards}</CardsMobileWrapper>
            </ShowMobile>
            <BottomBlock>
              <div className='text-block' id='HowItWorks'>
                <Display size={isMobile ? 'xs' : 'md'} weight='bold' className='bottom-header'>
                  {t('miles.landing.how-work.title')}
                </Display>
                <ShowMobile>
                  <ResponsiveImage image1x={PictureDiscoverMiles4} image2x={PictureDiscoverMiles4x2} alt='Discover Miles' />
                </ShowMobile>
                <Text size={isMobile ? 'md' : 'xl'} weight='regular' className='bottom-text'>
                  {t('miles.landing.how-work.subtitle')}
                </Text>
              </div>
              <ShowDesktop>
                <ResponsiveImage image1x={PictureDiscoverMiles4} image2x={PictureDiscoverMiles4x2} alt='Discover Miles' />
              </ShowDesktop>
            </BottomBlock>
          </DiscoverMilesWrapper>
        );
      }}
    </Observer>
  );
};
