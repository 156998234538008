import { computed } from 'mobx';

import { initDistanceLoader } from '../../utils';

import { distancesStore, isAppInitializedStore } from '../../stores';

const isAllFiltersAreShowed = computed<boolean>(() => {
  if (initDistanceLoader.isLoading() && !isAppInitializedStore.value) {
    return false;
  }

  return Boolean(distancesStore.filteredValues.length);
});

export { isAllFiltersAreShowed };
