import { distancesStore } from '../stores';

import { currentDistance } from '../derivations/currentDistance';
import { data } from '../derivations/data';

export const getResultsCount = () => {
  const distance = currentDistance.model.get();
  const distanceId = distance?.value?.id;
  let results;

  if (distanceId) {
    const resultsGenerator = distanceId ? data.generateDataEntries(distanceId) : null;
    results = (resultsGenerator && resultsGenerator.get()) || [];
  } else {
    results = [];
    distancesStore.values.map((distance) => {
      const resultsGenerator = data.generateDataEntries(distance.id);
      results = [...results, ...((resultsGenerator && resultsGenerator.get()) || [])];
    });
  }

  return results?.length;
};
