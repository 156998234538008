import { LOAD_LANGUAGES } from 'src/constants';

import { messages } from 'actions/messages';

import { progressStore } from 'stores';

async function changeLanguage(value: availableLocales) {
  progressStore.log(LOAD_LANGUAGES, 'progress');
  await messages.load(value);
  progressStore.log(LOAD_LANGUAGES, 'completed');
}

export { changeLanguage };
