import { action, observable, makeObservable } from 'mobx';

import { VirtualDistanceMapped } from '../types';

const defaultValues = {
  params: {
    limit: 20,
    page: 1,
    with: 'race.sport;goal',
  },
};

export class List {
  @observable list: Array<VirtualDistanceMapped> = [];

  @observable params: AnyObject = defaultValues.params;
  isPast: boolean = false;
  @observable hasMore: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setVirtualResults(data: Array<VirtualDistanceMapped>): void {
    this.list = data;
  }

  @action
  setParams(params: Object, isPast: boolean = this.isPast): void {
    this.params = {
      ...this.params,
      ...params,
    };

    this.isPast = isPast;
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  clear(): void {
    this.list = [];
    this.params = defaultValues.params;
    this.isPast = false;
    this.hasMore = true;
  }
}

const virtualStore = new List();
export { virtualStore };
