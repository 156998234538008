import { remove, includes } from 'lodash';

/*
 * Manage module lifecycle order
 * and making sure
 * that one mount will be called after another one
 */
class Life {
  alive: string[] = [];
  dead: string[] = [];

  startLife(id: string) {
    this.alive.push(id);
  }

  endLife(id: string) {
    remove(this.alive, (el) => el === id);
  }

  kill(id: string) {
    this.dead.push(id);
  }

  isBeingKilled(id: string): boolean {
    return includes(this.dead, id);
  }

  /*
   * Promise until alive id will be first in line
   */
  waitInLine(id: string): Promise<void> {
    return new Promise<void>((res) => {
      const intervalId = setInterval(() => {
        if (this.alive[0] !== id) {
          return;
        }

        res(clearInterval(intervalId));
      }, 50);
    });
  }
}

export { Life, Life as LifeStore };
