import { CHANGE_PASS_FORM_ID } from 'modules/profileSettings/constants';

import { errorsStore, form } from 'src/stores';

export const mountChangePasswordForm = async () => {
  form.registerForm(CHANGE_PASS_FORM_ID);
  form.merge(CHANGE_PASS_FORM_ID, {
    current_password: '',
    password: '',
    password_confirmation: '',
  });
};

export const unmountChangePasswordForm = () => {
  errorsStore.clear(CHANGE_PASS_FORM_ID);
};
