import * as React from 'react';

import { InfoTab } from './InfoTab';

type Props = {
  activeTab: boolean | any;
  label: string;
  onClick: Function;
  children: React.ReactNode | any;
  view: boolean;
};

export class TabsRaceInfo extends React.Component<Props | any> {
  render() {
    const {
      props: { children, view },
    } = this;

    let className = 'race-more-info hide-desktop';

    if (view) {
      className = 'race-more-info hide-mobile';
    }

    return (
      <div className={className}>
        <ul className='race-more-info-title'>
          {(children as any[]).map((child: AnyObject) => {
            const { label, onClick, isActive } = child.props;
            return <InfoTab activeTab={isActive} key={label} label={label} onClick={onClick} />;
          })}
        </ul>
        <div className='race-more-info'>
          {(children as any[]).map((child: AnyObject) => {
            const { activeTab } = child.props;
            if (child.props.label !== activeTab) {
              return;
            }
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}
