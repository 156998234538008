import { isEmpty } from 'lodash';

import { HEADER_FORM } from 'src/constants/actions';
import { ROUTER } from 'src/constants/routes';
import { headerStore } from 'src/modules/header/stores';
import { mapFormToQuery } from 'src/modules/search/utils';
import { form } from 'src/stores/form';
import { encodeQuery } from 'src/utils/encodeQuery';
import { history } from 'src/utils/history';

import { saveOriginalName } from '../utils';

import { setRecentLocations, setRecentRaces } from '../actions';

import { focusedStore } from '../stores';

import { FieldNames } from '../types';
import { toggleLocationAutocomplete } from './controls';

export const handleFocus = (value: string) => {
  focusedStore.setValue(value);
};

export const onSearch = async () => {
  const filters = form.fetchWithoutObservable<AnyObject>(HEADER_FORM) || {};
  const raceName = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.raceName);
  const location = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.location);
  const sports = form.fetchWithoutObservable(HEADER_FORM, FieldNames.sports);

  if (!raceName && !location && isEmpty(sports)) {
    focusedStore.setValue(FieldNames.raceName);
    return;
  }

  focusedStore.setValue('');
  toggleLocationAutocomplete(false);

  const query = encodeQuery.stringify(mapFormToQuery(filters));

  history.push(`${ROUTER.RACES}?${query}`);

  if (location) {
    setRecentLocations(location);
    saveOriginalName(location);
  }

  if (raceName) {
    setRecentRaces(raceName);
  }

  if (headerStore.value!.onAfterSearch) {
    headerStore.value!.onAfterSearch();
  }
};

export const onMobileSearch = () => {
  const allFilters = form.fetchWithoutObservable<AnyObject>(HEADER_FORM) || {};

  const location = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.location);
  const raceName = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.raceName);
  const date = form.fetchWithoutObservable(HEADER_FORM, FieldNames.date);
  const distance = form.fetchWithoutObservable(HEADER_FORM, FieldNames.distance);
  const sports = form.fetchWithoutObservable(HEADER_FORM, FieldNames.sports);

  if (!location && !raceName && isEmpty(date) && isEmpty(distance) && isEmpty(sports)) {
    const query = encodeQuery.stringify({ showAll: true });
    history.push(`${ROUTER.RACES}?${query}`);
    if (headerStore.value!.onAfterSearch) {
      headerStore.value!.onAfterSearch();
    }
    return;
  }

  const query = encodeQuery.stringify(mapFormToQuery(allFilters));
  history.push(`${ROUTER.RACES}?${query}`);

  if (location) {
    setRecentLocations(location);
    saveOriginalName(location);
  }

  if (raceName) {
    setRecentRaces(raceName);
  }

  if (headerStore.value!.onAfterSearch) {
    headerStore.value!.onAfterSearch();
  }
};
