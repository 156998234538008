import { transaction } from 'mobx';

import { form } from 'stores';

import { FORM_FILTERS_ID } from '../constants';

import { filtersStore } from '../stores';

import { currentDistance } from '../derivations';
import { frontendInternalMapper } from '../mapper';
import { InternalFilters } from '../types';

function change(value: InternalFilters) {
  const internalFiltersToApply = value;
  const frontendFiltersToApply = frontendInternalMapper.mapFrom(value);

  transaction(() => {
    filtersStore.setValue(internalFiltersToApply);
    form.merge(FORM_FILTERS_ID, frontendFiltersToApply);
  });
}

function reset() {
  const distanceId = currentDistance.id.get();

  if (distanceId) {
    return change({ distance_id: distanceId });
  }

  return change({});
}

function onChange(value: InternalFilters) {
  transaction(() => {
    change(value);
  });
}

function onNameChange(name: undefined | string) {
  transaction(() => {
    filtersStore.partialSetValue({ name });
  });
}

const filterChanger = {
  change,
  reset,
  onChange,
  onNameChange,
};

export { filterChanger };
