import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { TagLine } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

const Wrapper = styled.div<{ gridColumns: string }>`
  position: sticky;
  top: 88px;
  z-index: 10;

  display: grid;
  grid-template-columns: ${(props) => props.gridColumns};
  gap: 8px;
  padding: 8px 40px 16px;
  width: 100%;
  background-color: ${(props) => props.theme.main.colors.clay6};
`;

type Props = {
  withPlace?: boolean;
  withBib?: boolean;
  participantsCount: React.ReactNode;
};

export const LeftTableHeader: React.FC<Props> = (props) => {
  const { withPlace, participantsCount = 0, withBib = true } = props;

  const gridColumns = `${withPlace ? '60px' : ''} ${withBib ? '60px' : ''} 1fr`;

  return (
    <Wrapper gridColumns={gridColumns}>
      <Show if={Boolean(withPlace)}>
        <TagLine>{t('results.place')}</TagLine>
      </Show>
      <Show if={withBib}>
        <TagLine>{t('results.bib')}</TagLine>
      </Show>
      <TagLine>
        {t('results.participants')} {participantsCount}
      </TagLine>
    </Wrapper>
  );
};
