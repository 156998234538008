import React from 'react';

import { CombinedTimeField } from './CombinedTimeField';
import { SeparatedTimeField } from './SeparatedTimeField';

export enum TimeFieldVariants {
  combined = 'combined',
  separated = 'separated',
}

type TimeFieldProps = BaseInputProps & {
  variant: TimeFieldVariants;
  withMilliseconds: boolean;
  maxHours: number;
};

const components = {
  [TimeFieldVariants.combined]: CombinedTimeField,
  [TimeFieldVariants.separated]: SeparatedTimeField,
};

export const TimeField: React.FC<TimeFieldProps> = (props) => {
  const {
    variant = TimeFieldVariants.combined,
    className,
    requiredMark,
    errorMessage,
    label,
    name,
    value,
    disabled,
    onChange,
    withMilliseconds = false,
    maxHours,
  } = props;
  const Component = components[variant];

  return (
    <Component
      className={className}
      errorMessage={errorMessage}
      disabled={disabled}
      label={label}
      requiredMark={requiredMark}
      name={name}
      value={value}
      onChange={onChange}
      withMilliseconds={withMilliseconds}
      maxHours={maxHours}
    />
  );
};

export default TimeField;
