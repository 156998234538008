import { ComponentStory, ComponentMeta } from '@storybook/react';
import React from 'react';

import { Utility } from '../../../styledComponents/Typography';

const mockedData = 'Click this button';

export default {
  title: 'Core/Typography/Utilities',
  component: Utility,
  argTypes: {},
} as ComponentMeta<typeof Utility>;

const Template: ComponentStory<typeof Utility> = (args) => <Utility {...args} />;

export const Utility1 = Template.bind({});
Utility1.args = {
  variant: 'u1',
  children: mockedData,
};

export const Utility2 = Template.bind({});
Utility2.args = {
  variant: 'u2',
  children: mockedData,
};

export const Utility3 = Template.bind({});
Utility3.args = {
  variant: 'u3',
  children: mockedData,
};

export const Utility4 = Template.bind({});
Utility4.args = {
  variant: 'u3',
  children: mockedData,
};
