import { isEmpty } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { t } from 'utils';

import { WavesStore } from '../../stores';

const showAll = (isAvailable: boolean) =>
  !isAvailable
    ? []
    : [
        {
          value: 0,
          key: 0,
          label: t.staticAsString('results.filters.wavesPlaceholder'),
        },
      ];

function generateWavesSelect(idGenerator: IComputedValue<null | number>, store: WavesStore) {
  const waveSelectOptions: IComputedValue<NewSelectItemType[]> = computed(() => {
    let waves: any = [];
    const distanceId = idGenerator.get();
    const maxClassesNameLength = 35;

    if (!distanceId) {
      waves = store.distinctWaves;
    } else {
      waves = store.findWavesByDistance(distanceId);
    }

    return [
      ...waves.map((model) => model.toSelectItem(maxClassesNameLength)),
      ...showAll(!isEmpty(waves)),
    ] as unknown as NewSelectItemType[];
  });

  function findWaveSelectOption(ids: string | null, isDistinctClasses: boolean): NewSelectItemType {
    let item;

    if (isDistinctClasses) {
      item = store.distinctWaves.find((value) => value.id === ids);
    } else {
      item = store.find(ids);
    }

    if (!item) {
      return showAll(true)[0] as unknown as NewSelectItemType;
    }

    return item.toSelectItem() as unknown as NewSelectItemType;
  }

  const wavesSelect = {
    options: waveSelectOptions,
    findOption: findWaveSelectOption,
  };

  return wavesSelect;
}

export { generateWavesSelect };
