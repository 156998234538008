import classNames from 'classnames';
import { isString } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import onClickOutside from 'react-onclickoutside';
import styled, { css } from 'styled-components';

import { Show } from 'src/components/condition';
import { Svg } from 'src/styledComponents/Icons/Svg';
import { isSelectDropUp } from 'src/utils/isSelectDropUp';

import { boxShadow } from 'styles/effects/boxShadow';

import { InputWrapComponent } from '../InputTools';
import { SelectListItem } from './SelectListItem';
import { CustomScrollBar } from './customScrollBar';

const SELECT_DROP_MAX_HEIGHT = 400;

export enum Sizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type SelectListState = {
  listHeight: number;
  isOpened: boolean;
  isDropUp: boolean;
};

const StyledWrapper = styled.div<WrapperProps>`
  .dropdown {
    border-radius: 129px;
    position: relative;
    width: 100%;
  }

  .dropdown-wrap {
    border-radius: 129px;
    background: transparent;
    height: 100%;

    .icon-box {
      position: absolute;
      top: 50%;
      right: 20px;
      display: flex;

      svg {
        cursor: pointer;
        transform: translate3D(0, -50%, 0) ${(props) => (props.isOpened ? 'rotate(180deg)' : '')};

        path {
          stroke: ${(props) => props.theme.main.colors.clay3};
          stroke-width: 3;
        }
      }
    }

    .close-icon svg path {
      stroke-width: 2;
    }

    &:hover {
      .icon-box svg path {
        stroke: ${(props) => props.theme.main.colors.white};
      }

      .dropdown-chosen {
        color: ${(props) => props.theme.main.colors.white};
        background: ${(props) => props.theme.main.colors.clay1};
        border: 2px solid ${(props) => props.theme.main.colors.clay1};
        cursor: pointer;

        &::placeholder {
          color: ${(props) => props.theme.main.colors.white};
        }
      }

      ${(props) =>
        props.disabled &&
        css`
          .icon-box svg path {
            stroke: ${props.theme.main.colors.clay4};
          }

          .dropdown-chosen {
            border: 2px solid ${props.theme.main.colors.clay4};
          }

          .dropdown,
          .dropdown-chosen {
            color: ${props.theme.main.colors.clay4};
            background: transparent;
            cursor: not-allowed;

            &::placeholder {
              color: ${props.theme.main.colors.clay4};
            }
          }
        `}
    }
  }

  .dropdown-chosen {
    outline: none;
    width: 100%;

    background: transparent;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.main.colors.clay3};
    border-radius: 129px;
    font-weight: 700;
    text-transform: uppercase;
    border: 2px solid ${(props) => props.theme.main.colors.clay3};
    font-size: 12px;
    padding: 6px 40px 6px 16px;
    line-height: 16px;
    ${(props) => props.size && stylesBySize[props.size]}
    padding-right: 40px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:disabled {
      color: ${(props) => props.theme.main.colors.clay4};
      border: 2px solid ${(props) => props.theme.main.colors.clay4};
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${(props) => props.theme.main.colors.clay3};
    }
  }

  .fit-content-button {
    width: calc(${(props) => props.fitContentButtonWidth}ch + 70px);
  }

  .dropdown-click-zone {
    color: ${(props) => props.theme.main.colors.clay3};
    text-transform: uppercase;
    font-weight: 700;
    background: transparent;
    border-radius: 129px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .dropdown-expand {
    position: absolute;
    top: calc(100% + 16px);
    ${(props) => (props.dropDirection === 'left' ? 'left: 0;' : '')}
    ${(props) => (props.dropDirection === 'right' ? 'right: 0;' : '')}
    
    min-width: 300px;
    width: 100%;
    height: 0;
    background: ${(props) => props.theme.main.colors.white};
    color: ${(props) => props.theme.main.colors.clay1};
    overflow: auto;
    z-index: 1000;
    will-change: height;

    ${boxShadow};
    border-radius: 24px;

    ${(props) =>
      props.isDropUp &&
      css`
        top: unset;
        bottom: calc(100% + 48px);
      `}

    &.fit-content-menu {
      width: calc(${(props) => props.fitContentMenuWidth}ch + 70px);
      max-width: ${(props) => (props.fitNeedMaxWidth ? `calc(${props.fitContentButtonWidth}ch + 40px)` : '')};
      white-space: nowrap;
    }
  }

  .dropdown-list {
    list-style: none;
    margin: 0;
    padding: 24px 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  &.active {
    .dropdown-wrap {
      .icon-box svg path {
        stroke: ${(props) => props.theme.main.colors.white};
      }
    }

    .dropdown-chosen {
      color: ${(props) => props.theme.main.colors.white};
      background: ${(props) => props.theme.main.colors.clay3};
      border: 2px solid ${(props) => props.theme.main.colors.clay3};

      &::placeholder {
        color: ${(props) => props.theme.main.colors.white};
      }
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.main.colors.clay4};
      background: transparent;
      cursor: not-allowed;

      .dropdown-chosen::placeholder {
        color: ${props.theme.main.colors.clay4};
      }

      .dropdown-wrap {
        .icon-box svg path {
          stroke: ${props.theme.main.colors.clay4};
        }
      }
    `}
`;

type Props = {
  id?: string;
  type: string;
  readOnly: boolean;
  disabled: boolean;
  label: string;
  requiredMark: boolean;
  errorMessage: string;
  value: AnyObject;
  name: string;
  placeholder: string;
  forceDropDirection?: 'up' | 'down';
  onChange: AnyFunction;
  clearable?: boolean;
  size?: Sizes;
  className?: string;
  onMenuOpen?: AnyFunction;
  isFitContent?: boolean;
  dropDirection?: 'left' | 'right';
} & SelectType;

type WrapperProps = {
  size?: Sizes;
  isOpened: boolean;
  isDropUp: boolean;
  disabled: boolean;
  fitContentButtonWidth?: number;
  fitContentMenuWidth?: number;
  fitNeedMaxWidth?: boolean;
  dropDirection?: 'left' | 'right';
};

export const stylesBySize = {
  small: css`
    padding: 6px 16px;
    font-size: 12px;
    line-height: 16px;
  `,

  medium: css`
    padding: 9px 16px;
    font-size: 14px;
    line-height: 18px;
  `,

  large: css`
    padding: 14px 20px;
    font-size: 16px;
    line-height: 20px;
  `,
};

class Filter extends React.Component<Props, SelectListState> {
  outerHeight: number | any;

  dropdownRef: RefType<HTMLDivElement> | any = React.createRef<HTMLDivElement>();
  listRef: React.RefObject<HTMLUListElement> = React.createRef<HTMLUListElement>();

  static contextType = TestAnchorContext;
  declare context: React.ContextType<typeof TestAnchorContext>;

  static defaultProps = {
    type: 'text',
    disabled: false,
    readOnly: true,
    value: '',
  };

  state = {
    listHeight: 0,
    isOpened: false,
    isDropUp: false,
  };

  handleCloseDropList() {
    this.setState({
      listHeight: 0,
      isOpened: false,
    });
  }

  handleOpenDropList() {
    const { isOpened } = this.state;
    const { onMenuOpen } = this.props;
    let listHeight = 0;

    this.outerHeight = this.getOuterHeight();

    let isDropUp = this.state.isDropUp;

    if (!isOpened) {
      listHeight = this.outerHeight;

      const dropRect = this.dropdownRef.current && this.dropdownRef.current.getBoundingClientRect();
      isDropUp = isSelectDropUp(dropRect, listHeight);
    }

    if (this.props.forceDropDirection === 'up') {
      isDropUp = true;
    }

    if (this.props.forceDropDirection === 'down') {
      isDropUp = false;
    }

    onMenuOpen && onMenuOpen();
    this.setState({
      listHeight,
      isOpened: !isOpened,
      isDropUp,
    });
  }

  handleClickOutside() {
    this.handleCloseDropList();
  }

  onSelect = (value: NewSelectItemType) => {
    const { onChange, name } = this.props;
    this.handleCloseDropList();

    onChange({ name, value });
  };

  handleDropDownListItem() {
    const { dropListItems, value, name } = this.props;
    const res: any = [];
    res.push(
      dropListItems.map((item: AnyObject, key: number) => {
        return (
          <SelectListItem
            item={item as NewSelectItemType}
            activeItem={value?.value}
            key={`${key}-${item.key}`}
            title={item.label}
            onSelect={this.onSelect}
            name={name}
          />
        );
      }),
    );

    return res;
  }

  getCurrentItem = () => {
    const { value, dropListItems } = this.props;

    const currentItem = (dropListItems || []).find((item) => item.value === value?.value);

    if (!currentItem) {
      return '';
    }

    if (isString(currentItem.label)) return currentItem.label;
    return renderToStaticMarkup(currentItem.label);
  };

  getOuterHeight() {
    if (!this.listRef.current) {
      return 0;
    }

    return this.listRef.current.getBoundingClientRect().height;
  }

  render() {
    const {
      placeholder,
      dropListItems,
      name,
      type,
      readOnly,
      disabled,
      label,
      requiredMark,
      errorMessage,
      clearable,
      size,
      className = '',
      isFitContent = false,
      dropDirection = 'left',
    } = this.props;

    const { listHeight, isDropUp, isOpened } = this.state;
    const hasItems = dropListItems && !!dropListItems.length;
    const isValuePresented = Boolean(this.getCurrentItem());
    const activeClass = isValuePresented || isOpened ? 'active' : '';

    const fitContentButtonWidth = () => (isFitContent ? this.getCurrentItem().length : 0);

    const fitContentMenuWidth = () => {
      if (isFitContent && dropListItems && dropListItems.length > 0) {
        const lengths = dropListItems.map((item) => item.label.length);
        return Math.max(...lengths);
      }

      return 0;
    };

    const fitNeedMaxWidth = fitContentButtonWidth() === fitContentMenuWidth();

    return (
      <Observer>
        {() => {
          return (
            <StyledWrapper
              size={size}
              isOpened={isOpened}
              isDropUp={isDropUp}
              disabled={disabled}
              className={`${activeClass} ${className}`}
              fitContentButtonWidth={fitContentButtonWidth()}
              fitContentMenuWidth={fitContentMenuWidth()}
              fitNeedMaxWidth={fitNeedMaxWidth}
              dropDirection={dropDirection}
            >
              <InputWrapComponent disabled={disabled} name={name} label={label} requiredMark={requiredMark} errorMessage={errorMessage}>
                <div className='dropdown' ref={this.dropdownRef}>
                  <div className='dropdown-wrap'>
                    <div
                      className={classNames('dropdown-chosen', { 'fit-content-button': isFitContent })}
                      onClick={this.handleOpenDropList.bind(this)}
                    >
                      {this.getCurrentItem()}
                      <input
                        {...testAnchors.field(this.context.container, name, TEST_ANCHORS.fieldStructure.root)}
                        id={name}
                        type={type}
                        name={name}
                        value={this.getCurrentItem()}
                        disabled={disabled}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        onChange={() => {}}
                        onClick={this.handleOpenDropList.bind(this)}
                        style={{ display: 'none' }}
                      />
                    </div>

                    <Show if={(hasItems && !isValuePresented) || !clearable}>
                      <Svg
                        className='icon-box'
                        name='ArrowDown'
                        size={size === 'large' ? 12 : 10}
                        onClick={!isOpened && !disabled ? this.handleOpenDropList.bind(this) : this.handleCloseDropList.bind(this)}
                      />
                    </Show>

                    <Show if={Boolean(isValuePresented && clearable)}>
                      <Svg
                        className='icon-box close-icon'
                        name='CrossGray'
                        size={size === 'large' ? 12 : 8}
                        onClick={() => (disabled ? {} : this.props.onChange({ name, value: null }))}
                      />
                    </Show>
                  </div>

                  {hasItems && (
                    <div
                      className={classNames('dropdown-expand', { 'fit-content-menu': isFitContent })}
                      style={{
                        height: `${listHeight}px`,
                        maxHeight: SELECT_DROP_MAX_HEIGHT,
                      }}
                    >
                      <CustomScrollBar>
                        <ul className='dropdown-list' id={this.props.id} ref={this.listRef}>
                          {this.handleDropDownListItem()}
                        </ul>
                      </CustomScrollBar>
                    </div>
                  )}
                </div>
              </InputWrapComponent>
            </StyledWrapper>
          );
        }}
      </Observer>
    );
  }
}

// @ts-ignore
export const DropDownFilter = onClickOutside(Filter);
