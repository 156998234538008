function processContent(html: string): string {
  let updatedContent = html;
  html.replace(/target=['"]?_blank['"]/g, '').replace(/<a/g, '<a target="_blank"');

  updatedContent = updatedContent.replace(/target=['"]?_blank['"]/g, '');
  updatedContent = updatedContent.replace(/<a/g, '<a target="_blank"');

  return updatedContent;
}

export { processContent };
