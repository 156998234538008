export type Props = {
  className?: string;
  navList: Field[];
  additionalList?: Array<React.ReactNode>;
  closeSideBar?: Function;
  size?: Size;
  variant?: Variant;
  withHistoryReplace?: boolean;
};

export type Field = {
  link: string;
  showInDesktop: boolean;
  titleLink: string;
  exact?: boolean;
  className?: string;
  id?: string;
};

export enum Size {
  large = 'large',
  small = 'small',
}

export enum Variant {
  main = 'main',
  sub = 'sub',
}
