import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_URL, TRANSFER_REGISTRATION_END_POINT } from 'src/constants';
import { closeConfirmModal, closeDeleteFormModal, closeFormModal, loadRegistred } from 'src/modules/transferRegistration/actions';
import { DELETE_TRANSFER_REGISTRATION_ACTION, SAVE_TRANSFER_REGISTRATION_ACTION } from 'src/modules/transferRegistration/constants';

import { action, fireToast, request, t } from 'utils';

class TransferRegistrationService {
  @request({ action: SAVE_TRANSFER_REGISTRATION_ACTION })
  private async saveTransferRegistrationRequest(racerId: number, email: string): Promise<any> {
    const url = generatePath(API_URL + TRANSFER_REGISTRATION_END_POINT, { racerId });
    return axios.post(url, { email });
  }

  @request({ action: DELETE_TRANSFER_REGISTRATION_ACTION })
  private async deleteTransferRegistrationRequest(racerId: number): Promise<any> {
    const url = generatePath(API_URL + TRANSFER_REGISTRATION_END_POINT, { racerId });
    return axios.delete(url);
  }

  @action({ action: SAVE_TRANSFER_REGISTRATION_ACTION })
  async saveTransferRegistration(racerId: number, email: string): Promise<void> {
    const [status, response] = await this.saveTransferRegistrationRequest(racerId, email);

    if (status) {
      closeFormModal();
      closeConfirmModal();
      loadRegistred();
    } else {
      fireToast(t.staticAsString('transferRegistration.confirm.error', { email }), 'error');
    }
  }

  @action({ action: DELETE_TRANSFER_REGISTRATION_ACTION })
  async deleteTransferRegistration(racerId: number): Promise<void> {
    const [status, response] = await this.deleteTransferRegistrationRequest(racerId);

    if (status) {
      closeDeleteFormModal();
      fireToast(t.staticAsString('transferRegistration.form.delete.ok'), 'success');
      window.location.reload();
    } else {
      fireToast(t.staticAsString('transferRegistration.form.delete.error'), 'error');
    }
  }
}

const transferRegistrationService = new TransferRegistrationService();

export { transferRegistrationService };
