import { Observer } from 'mobx-react';
import { UserModal as UserModalComponent } from 'modules/racersTable/components';
import * as React from 'react';

import { userModal } from '../../actions';

import { userModalStore } from '../../stores';
import { classesStore, wavesStore } from '../../stores';

function UserModal() {
  const findClass = (id: number) => classesStore.find(id)?.value;
  const findWave = (id: number) => wavesStore.find(id)?.value;

  return (
    <Observer>
      {() => {
        const user = userModalStore.user;
        const isOpen = userModalStore.isOpen;
        const position = userModalStore.position;

        if (!isOpen) {
          return null;
        }

        return (
          <UserModalComponent
            currentUser={user}
            showUserModal={isOpen}
            withUnion={!!user?.union}
            modalPosition={position!}
            closeModal={userModal.close}
            getClass={findClass}
            getWave={findWave!}
          />
        );
      }}
    </Observer>
  );
}

export { UserModal };
