import { omit } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { form } from 'stores';

import { FORM_FILTERS_ID } from '../../constants';

import { frontendInternalMapper } from '../../mapper';
import { InternalFilters, ReactionInternalFilters } from '../../types';

const allFilters: IComputedValue<InternalFilters> = computed(() => {
  const frontendFilters = form.fetch(FORM_FILTERS_ID);
  return frontendInternalMapper.mapTo(frontendFilters as any);
});

const nameFilter: IComputedValue<undefined | string> = computed(() => {
  return allFilters.get().name;
});

const mainFilters: IComputedValue<any> = computed(() => {
  const all = allFilters.get();
  return omit(all, ['name', 'distance_id']);
});

const distanceFilter: IComputedValue<null | undefined | number> = computed(() => {
  return allFilters.get().distance_id;
});

const currentFormFilters = {
  all: allFilters,
  name: nameFilter,
  main: mainFilters,
  distance: distanceFilter,
};

export { currentFormFilters };
