// TODO: Figure out proper @types version for this to work
// @ts-ignore
// Module '"react-router-dom"' has no exported member 'Location'
import { Location } from 'react-router-dom';

import { history, encodeQuery } from 'utils';

import { distancesStore } from '../stores';

let unsubscribe: any = null;

function updateActiveDistance(location: Location) {
  const query = encodeQuery.parseQuery(location.search) || {};
  const distanceId = +query.distance_id;

  if (distancesStore.selectedDistanceId === distanceId) {
    return;
  }

  distancesStore.setSelectedDistance(distanceId);
}

function subscribeForActiveDistance() {
  updateActiveDistance(history.location);
  unsubscribe = history.listen((location, action) => {
    updateActiveDistance(location);
  });
}

function unsubscribeForActiveDistance() {
  unsubscribe && unsubscribe();
}

export { subscribeForActiveDistance, unsubscribeForActiveDistance };
