import * as React from 'react';
import shortid from 'shortid';

import { ButtonScroll } from 'src/modules/racersTable/components';
import { DesktopSkeleton, MobileSkeleton } from 'src/modules/racersTable/components/Skeleton';
import { ShowMobile, ShowTablet, ShowDesktop } from 'src/styledComponents/Conditions';
import { PageContent } from 'src/styledComponents/PageContent';
import { UtilityContext } from 'src/styledComponents/Typography';

import { NoResults } from '../components/NoResults';

import { LOAD_STARTLIST, PRELOADER, STARTLIST_CONTAINER_ID } from '../constants';

import { mount, unmount } from '../actions';

import { Table, AllTable } from './Desktop';
import { FiltersWrapper } from './Filters';
import { MobileList, MobileFiltersWrapper, MobileAllLists } from './Mobile';
import { UserModal } from './UserModal';

export const Base = () => {
  const id = shortid();

  React.useEffect(() => {
    mount(id);

    return () => {
      unmount(id);
    };
  }, []);

  return (
    <>
      <UserModal />
      <ShowDesktop withTablet>
        <>
          <ButtonScroll offsetTop={490} />
          <PageContent id={STARTLIST_CONTAINER_ID}>
            <FiltersWrapper />
            <DesktopSkeleton actions={[LOAD_STARTLIST, PRELOADER]}>
              <NoResults>
                <Table />
                <AllTable />
              </NoResults>
            </DesktopSkeleton>
          </PageContent>
        </>
      </ShowDesktop>
      <ShowTablet>
        <>
          <ButtonScroll offsetTop={650} />
          <UtilityContext.Provider value={{ withoutMediaConditions: true }}>
            <MobileFiltersWrapper />
            <div id={STARTLIST_CONTAINER_ID}>
              <MobileSkeleton actions={[LOAD_STARTLIST, PRELOADER]}>
                <NoResults>
                  <MobileList />
                  <MobileAllLists />
                </NoResults>
              </MobileSkeleton>
            </div>
          </UtilityContext.Provider>
        </>
      </ShowTablet>
      <ShowMobile>
        <>
          <ButtonScroll offsetTop={650} />
          <MobileFiltersWrapper />
          <div id={STARTLIST_CONTAINER_ID}>
            <MobileSkeleton actions={[LOAD_STARTLIST, PRELOADER]}>
              <NoResults>
                <MobileList />
                <MobileAllLists />
              </NoResults>
            </MobileSkeleton>
          </div>
        </>
      </ShowMobile>
    </>
  );
};
