import { observer } from 'mobx-react';
import { RaceDetailsContainer } from 'modules/race';
import * as React from 'react';
import { matchPath, generatePath } from 'react-router-dom';

import { ROUTER_WITH_LOCALES, ROUTER } from 'src/constants';

import { withProgressSpinner } from 'components/hocs';

import { history } from 'utils';

import { progressStore, localeStore } from 'stores';

import { fetchRace } from '../services';

const RACE_SALES_ACTION = 'RACE_SALES_ACTION';

@withProgressSpinner(RACE_SALES_ACTION)
@observer
class RaceSalesPages extends React.Component<{}> {
  componentDidMount() {
    this.init();
  }

  UNSAFE_componentWillMount() {
    progressStore.log(RACE_SALES_ACTION, 'progress');
  }

  async init() {
    await this.applyLocaleAndRedirect();
    progressStore.log(RACE_SALES_ACTION, 'completed');
  }

  async applyLocaleAndRedirect() {
    const match = matchPath(history.location.pathname, { path: ROUTER_WITH_LOCALES.RACE_SALES_PAGES_ROOT, exact: false });

    if (!match) {
      return;
    }

    const { id } = match.params;
    const raceId = await fetchRace.getRaceId(id);

    if (!raceId) {
      return;
    }

    const url = generatePath(ROUTER.RACE_SALES_PAGES_ABOUT, { id: raceId });

    history.replace(url);
  }

  render() {
    if (progressStore.isLoading(RACE_SALES_ACTION)) {
      return null;
    }

    return <RaceDetailsContainer salesMode />;
  }
}

export { RaceSalesPages };
