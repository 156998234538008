import { isEmpty, isObject } from 'lodash';
import { Observer } from 'mobx-react';
import { Moment } from 'moment';
import React from 'react';

import { HEADER_FORM } from 'src/constants';
import { Svg } from 'src/styledComponents/Icons';

import { form } from 'stores';

import { FilterOpener } from './styled';

interface Props {
  text: string;
  name: string;
  className?: string;
  onClickHandle: AnyFunction;
}

const formatDate = (date: Moment | undefined) => {
  if (date) return date.format('D MMM');
  return '';
};

const cases = (name: string, data: any, text: string) => {
  if (!data || (isObject(data) && isEmpty(data))) return text;

  switch (name) {
    case 'date':
      return data.endDate ? `${formatDate(data.startDate)} - ${formatDate(data.endDate)}` : `${formatDate(data.startDate)}`;

    case 'sports':
      return `${text}: ${data.length}`;

    case 'distance':
      return `${text}: ${data.length}`;

    default:
      return text;
  }
};

export const PseudoSelect = ({ name, text, onClickHandle }: Props) => {
  const valuesData: any = form.fetch(HEADER_FORM, name);
  const isActive = valuesData?.length || valuesData?.startDate || valuesData?.label;

  const result = cases(name, valuesData, text);
  const ATN = { ...testAnchors.useButton(name) };
  return (
    <Observer>
      {() => (
        <FilterOpener isActive={isActive} onClick={() => onClickHandle()} {...ATN}>
          {result}
          <Svg className='icon-box' name='ArrowDownSmall' size={16} />
        </FilterOpener>
      )}
    </Observer>
  );
};
