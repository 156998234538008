import { observer } from 'mobx-react';
import * as React from 'react';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { Headline, Utility } from 'src/styledComponents/Typography';

import { Loading } from 'components/loading';
import { Modal } from 'components/modal';

import { t } from 'utils';

import { windowSize } from 'stores';

import { ACTION_IN_PROGRESS } from '../constants';

import { cancel, confirm } from '../actions';

import { modalStore, contentStore } from '../stores';

type Props = {};

const Wrapper = styled.div<{ isSwipeEnd: boolean }>`
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  padding: 0 20px 40px;
  border-radius: 24px 24px 0 0;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 628px;
    padding: 32px 40px 40px;
    border-radius: 24px;
  }

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }
`;

const StyledHeadline = styled(Headline)`
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 8px;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    text-align: left;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  height: 56px;
  button {
    margin-left: 32px;
    span {
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: ${RESOLUTIONS.medium}px) {
      margin-left: 0;
    }
  }
  @media (max-width: ${RESOLUTIONS.medium}px) {
    margin-top: 48px;
  }
`;

const ContentUtility = styled(Utility)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

@observer
export class ConfirmationModal extends React.Component<Props> {
  state = {
    isSwipeEnd: false,
  };

  ref = React.createRef<HTMLDivElement>();

  handleMove = ({ y: value }: { y: number }) => {
    if (!this.ref.current || value < 0) return;

    this.ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  handleSwipeDown = () => {
    if (!this.ref.current) return;

    this.ref.current.style.setProperty('transform', `translate3d(0, ${this.ref.current.clientHeight}px, 0)`);
    this.setState({ isSwipeEnd: true });
  };

  handleSwipeStart = () => {
    this.setState({ isSwipeEnd: false });
  };

  handleTransitionEnd = () => {
    cancel();
  };

  render() {
    const isMobile = windowSize.isLessThan('medium');
    return (
      <Modal className='confirmation' open={modalStore.value}>
        <Wrapper ref={this.ref} isSwipeEnd={this.state.isSwipeEnd} onTransitionEnd={this.handleTransitionEnd}>
          {isMobile && (
            <Swipe
              className='swipe-zone'
              onSwipeMove={this.handleMove}
              onSwipeDown={this.handleSwipeDown}
              onSwipeStart={this.handleSwipeStart}
            >
              <DraggableStroke />
            </Swipe>
          )}
          <Loading action={ACTION_IN_PROGRESS} />
          <StyledHeadline variant='h4'>{contentStore.value?.title}</StyledHeadline>
          <ContentUtility variant='u1' weight='medium'>
            {contentStore.value?.body}
          </ContentUtility>
          <Controls>
            {!isMobile && (
              <Button variant='secondary' onClick={cancel} size='medium' ATN='image-upload-cancel'>
                {t('settings.cancel')}
              </Button>
            )}
            <Button variant='primary' size='medium' onClick={confirm} fullWidth={isMobile} ATN='image-upload-submit'>
              {t('settings.confirm')}
            </Button>
          </Controls>
        </Wrapper>
      </Modal>
    );
  }
}
