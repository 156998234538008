import * as React from 'react';

export const AlertClose = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 23.249C18.2132 23.249 23.25 18.2122 23.25 11.999C23.25 5.78579 18.2132 0.748993 12 0.748993C5.7868 0.748993 0.75 5.78579 0.75 11.999C0.75 18.2122 5.7868 23.249 12 23.249Z'
      fill='#50555B'
      stroke='#50555B'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.5 16.499L16.5 7.49899' stroke='#C3C6C8' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.5 16.499L7.5 7.49899' stroke='#C3C6C8' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
