import * as React from 'react';

export const RaceLinkedin = (
  <svg width='32' height='33' viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.2418 23.087H9.3631V14.8359H12.2418V23.087ZM18.2184 17.525C17.8822 17.525 17.5598 17.6586 17.3221 17.8963C17.0844 18.134 16.9508 18.4564 16.9508 18.7926V23.087H13.924V14.8359H16.9508V15.7777C17.6603 15.1771 18.5566 14.842 19.486 14.8299C21.3637 14.8299 22.6668 16.2219 22.6668 18.8637V23.087H19.486V18.7926C19.4868 18.6257 19.4546 18.4602 19.3912 18.3057C19.3279 18.1512 19.2346 18.0108 19.1168 17.8925C18.9991 17.7741 18.8591 17.6802 18.7049 17.6162C18.5507 17.5521 18.3854 17.5191 18.2184 17.5191V17.525ZM12.2951 11.9808C12.2951 12.2737 12.2083 12.56 12.0456 12.8035C11.8828 13.047 11.6516 13.2368 11.381 13.3489C11.1104 13.461 10.8127 13.4903 10.5254 13.4332C10.2382 13.3761 9.9743 13.235 9.7672 13.0279C9.56011 12.8208 9.41907 12.557 9.36194 12.2697C9.3048 11.9825 9.33412 11.6847 9.4462 11.4141C9.55828 11.1436 9.74808 10.9123 9.9916 10.7496C10.2351 10.5868 10.5214 10.5 10.8143 10.5C11.205 10.5031 11.5786 10.6605 11.8538 10.9379C12.1289 11.2153 12.2833 11.5901 12.2833 11.9808H12.2951Z'
      fill='#656B6F'
    />
    <rect x='0.75' y='1.25' width='30.5' height='30.5' rx='15.25' stroke='#656B6F' strokeWidth='1.5' />
  </svg>
);
