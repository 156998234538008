import { getModalStoreType } from 'src/modules/profile/utils';
import { openDeleteFormModal } from 'src/modules/transferRegistration/actions';
import { modalStore } from 'src/modules/transferRegistration/stores';

import { close } from './display';
import {actionMenuStore} from "modules/profile/stores";

export const openDeleteTransferRegistrationModal = () => {
  const racerId = Number(actionMenuStore.distance?.racer_id);

  modalStore.setValue(getModalStoreType(racerId));
  openDeleteFormModal();
  close();
};
