import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { scrollBarStyles } from 'src/styles';

import { Body } from 'components/modal';

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 24px;
  @media (max-width: ${RESOLUTIONS.medium}px) {
    border-radius: 0;
  }
`;

export const StyledBody = styled(Body)`
  border-radius: 0;
  padding: 0;
  width: 808px;
  max-height: calc(100vh - 136px);
  overflow-y: auto;
  ${scrollBarStyles}
  ::-webkit-scrollbar-thumb {
    border-top: 24px solid ${(props) => props.theme.main.colors.clay5};
    border-bottom: 24px solid ${(props) => props.theme.main.colors.clay5};
    border-radius: 4px;
  }

  @media (max-width: 830px) {
    width: 750px;
  }

  .header-section {
    padding: 24px 40px 16px;
    border-bottom: 1px solid ${(props) => props.theme.main.colors.clay4};
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading-text {
      text-transform: uppercase;
      margin: 0;
    }
  }

  .main-section {
    padding: 48px 40px 0;
  }

  .distance-length {
    display: inline-block;
    border: 1px solid ${(props) => props.theme.main.colors.clay5};
    padding: 10px 7px;
  }

  .distance-name {
    margin: 16px 0 0;
  }

  .distance-description {
    color: ${(props) => props.theme.main.colors.clay3};
    margin: 16px 0 32px;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
    iframe {
      width: 100%;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    border-radius: 0;
    position: static;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;

    ::-webkit-scrollbar-thumb {
      border-top: none;
      border-bottom: none;
    }

    .header-section {
      padding: 32px 20px;
      border-bottom: none;
    }

    .main-section {
      padding: 0 20px;
    }

    .distance-description {
      margin-bottom: 24px;
    }
  }
`;

export const DateTimeSection = styled.div`
  display: flex;
  padding: 40px;
  background-color: ${(props) => props.theme.main.colors.clay6};

  .column {
    margin-right: 56px;
    display: flex;
    flex-direction: column;
    .column-value {
      margin-top: 8px;
      color: ${(props) => props.theme.main.colors.clay3};
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: 32px 20px;
    display: block;
    .column {
      margin-bottom: 48px;
    }
    .column:last-child {
      margin-bottom: 0;
    }
  } ;
`;

export const PriceSection = styled.div`
  padding: 32px 40px 44px;

  .prices {
    display: flex;
    flex-wrap: wrap;
  }
  .price {
    margin: 0;
    text-transform: uppercase;
  }
  .price-section-heading {
    display: block;
    margin-bottom: 8px;
  }
  .price-column {
    margin: 0 35px 20px 0;
    display: flex;
    flex-direction: column;
    .price {
      white-space: nowrap;
    }
    .price-date-range {
      min-width: 137px;
      margin-top: 8px;
      color: ${(props) => props.theme.main.colors.clay3};
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    padding: 24px 20px 36px;
    .price-column {
      margin: 0 20px 20px 0;
    }
  }
`;

export const EmptyBlock = styled.div`
  height: 24px;
`;
