import { segmentIgnition } from 'actions';

import { parseUrlParams } from '../utils';

import { segmentsService } from '../services';

import { actualizeSegment } from './actualizeSegment';
import { reloadDistance } from './reloadDistance';

function onEditSegment(segmentId: number) {
  return () => {
    const { distanceId, racerId } = parseUrlParams();

    if (!distanceId || !racerId) {
      return;
    }

    segmentIgnition.switchEdit(segmentId, distanceId, racerId, async (responseState, type) => {
      if (responseState === 'success') {
        await onSuccess(racerId, segmentId);
        return;
      }

      if (responseState === 'conflict') {
        await onConflict();
      }
    });
  };
}

async function onSuccess(racerId: number, segmentId: number) {
  await actualizeSegment(segmentId);
  await segmentsService.getTotal(racerId);
}

/*
 * Date does not allow to add new segment
 */
async function onConflict() {
  await reloadDistance();
}

export { onEditSegment };
