import * as React from 'react';

export const Navigate = (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.0015 9.99609V21.9961' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M11.0015 14.9961L16.0015 9.99609L21.0015 14.9961'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
