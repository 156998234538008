import { isEmpty } from 'lodash';
import { ACCOUNT_INFO_FORM_ID } from 'modules/profileSettings/constants';

import { userService } from 'src/services';
import { errorsStore, form, sessionStore } from 'src/stores';

import { accountInfoMapper as mapper } from '../../utils';

export const mountAccountInfo = async () => {
  if (isEmpty(sessionStore.getUserData())) {
    await userService.loadUserProfile();
  }

  const user: any = mapper.mapFrom({
    ...sessionStore.getUserData(),
  });

  form.registerForm(ACCOUNT_INFO_FORM_ID);
  form.merge(ACCOUNT_INFO_FORM_ID, {
    ...user,
    initialUserData: user,
  });
};

export const unmountAccountInfo = () => {
  errorsStore.clear(ACCOUNT_INFO_FORM_ID);
};
