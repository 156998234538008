import { getDefaultRaces, handleFocus } from 'modules/search/actions';
import React from 'react';

interface InputProps extends BaseInputProps {
  isFocused: boolean;
}

export const RaceSearchMobile: React.FC<InputProps> = (props) => {
  const { value, onChange, name, placeholder, isFocused } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isFocused && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const setFocusField = (e) => {
    handleFocus(e.currentTarget.name);
    getDefaultRaces();
  };

  return (
    <input
      {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.root)}
      ref={inputRef}
      name={name}
      value={value || ''}
      type='text'
      enterKeyHint='search'
      placeholder={placeholder}
      onFocus={setFocusField}
      onChange={(e) => {
        onChange({ name: e.currentTarget.name, value: e.currentTarget.value });
      }}
    />
  );
};
