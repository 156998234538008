import { scrollToTop } from 'src/components/scrollToTop/actions';
import { form, windowSize } from 'src/stores';

import { EMPTY_FILTERS } from '../../constants';

import { filtersOpenedStore } from '../../stores';

let defaultFormValues = {};

export const handleOpenMobileFilters = (formId: string) => () => {
  if (!windowSize.isLessThan('large')) {
    return;
  }

  scrollToTop()();
  defaultFormValues = form.fetchWithoutObservable(formId);
  filtersOpenedStore.toogle();
};

export const handleCloseMobileFilters = (formId: string) => () => {
  form.merge(formId, defaultFormValues);
  defaultFormValues = {};
  filtersOpenedStore.toogle();
};

export const handleApplyMobileFilters = () => {
  defaultFormValues = {};
  filtersOpenedStore.toogle();
};

export const handleClearFilters = (formId: string) => () => {
  form.merge(formId, EMPTY_FILTERS);
};
