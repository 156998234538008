import { ROUTER } from 'src/constants';

import { history, encodeQuery } from 'utils';

export const redirectToRoute = (distanceId: number, racerId: number, segmentId?: number) => {
  const url = encodeQuery.generatePath(
    ROUTER.SEGMENT_FORM,
    {
      distanceId,
      racerId,
    },
    {
      segmentId,
    },
  );
  history.push(url);
};
