import { observable, action, makeObservable } from 'mobx';
import shortid from 'shortid';

/*
 * Requests versioning
 * In case if the same request should be sent again,
 * and it resolves sooner then request that already sent.
 * BLOCK execution of previous request
 * e.g.
 */
class VersionStore {
  @observable value: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  generate() {
    this.value = shortid();
  }

  fetch() {
    return this.value || '';
  }

  @action
  clean() {
    this.value = null;
  }

  compare(val: string): boolean {
    return this.value === val;
  }
}

export { VersionStore };
