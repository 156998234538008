import { PROFILE_FIELDS } from './profile';

export const PERSONAL_STEP_FIELDS: AnyObject = {
  email: 'email',
  firstname: 'text',
  lastname: 'text',
  gender: 'gender',
  birthday: 'date',
  telephone: 'phone',
  address: 'text',
  post_code: 'text',
  city: 'text',
  country_id: 'dropdown',
  discipline: 'dropdown',
  class: 'class',
  union: 'text',
  squad_id: 'text',

  company: 'text',
  coupon_code: 'coupon',
  emergency_name: 'text',
  emergency_phone: 'phone',
  diseases: 'text',
  blood_type: 'text',
  shoe_size: 'text',
  shirt_size: 'text',
  external_swimrun_id: 'text',
};

export const STORE_FORMAT_TYPES: AnyObject = {
  currentStepIndex: 'number',
  steps: {
    index: 'number',
    isError: 'boolean',
    isDisabled: 'boolean',
    isCompleted: 'boolean',
  },
  payment_method_id: 'number',
  class_id: 'number',
  countOfMembers: 'number',
};

//dont change the order of PERSONAL_FILEDS it will be broken server validation
export const PERSONAL_FIELDS = {
  ...PROFILE_FIELDS,
  company: '',
  emergency_name: '',
  emergency_phone: {
    phone: '',
    countryData: {},
  },
  diseases: '',
  blood_type: '',
  union: '',
  coupon_code: '',
  shoe_size: '',
  shirt_size: '',
  discipline_id: null,
};

export const PASWORD_FIELDS = {
  password: '',
  password_confirmation: '',
  termsAgreement: false,
};

export const TEAM_MEMBER_FIELDS = {
  email: '',
  firstname: '',
  lastname: '',
  gender: null,
  external_swimrun_id: null,
  country_id: null,
  discipline_id: null,
  isOpen: true,
};

export const PAYMENT_TYPES = {
  authorization: 'redirect-authorization',
  sale: 'redirect-sale',
  swish: 'redirect-app-swish',
};

export const PAYMENT_STATUS = {
  ready: 'Ready',
  pending: 'Pending',
  declined: 'Aborted',
};
