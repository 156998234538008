import { set, first } from 'lodash';

import { virtualListMapper } from '../utils';

import { singleVirtualService } from '../services';

import { virtualStore } from '../stores';

/*
 * NOTE.
 * In case of updated data or 409 error
 * there are necessity to update current loaded distance
 */
async function reloadDistance(id: number) {
  const index = virtualStore.list.findIndex((el) => el.id === id);

  if (index < 0) {
    return;
  }

  let updatedDistance = await singleVirtualService.load(id);

  if (!updatedDistance) {
    return;
  }

  updatedDistance = first(virtualListMapper([updatedDistance as any]))!;

  const list = [...virtualStore.list];

  set(list, [index], updatedDistance);

  virtualStore.setVirtualResults(list);
}

export { reloadDistance };
