import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const DeletePath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M9.17071 5C9.58254 3.83481 10.6938 3 12 3C13.3062 3 14.4175 3.83481 14.8293 5H9.17071ZM7.10002 5C7.56329 2.71776 9.58104 1 12 1C14.419 1 16.4367 2.71776 16.9 5H23V7H20.3824L18.5 23H5.5L3.61765 7H1V5H7.10002Z'
    fill='currentColor'
  />
);

export const Delete = (props: IconProps) => <SvgIcon path={DeletePath} {...props} />;
