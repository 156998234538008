import axios from 'axios';

import { MILES_DATA_QUALITY } from 'src/constants';

import { action, request } from 'utils';

import { GET_DATA_QUALITY } from '../constants';

import { dataQualityStore } from '../stores';

export class DataQuality {
  @request({ action: GET_DATA_QUALITY })
  private getDataQualityRequest(): Promise<any> {
    return axios.get(MILES_DATA_QUALITY);
  }

  @action({ action: GET_DATA_QUALITY })
  async getDataQuality() {
    const [isOk, data] = await this.getDataQualityRequest();
    if (isOk) {
      dataQualityStore.setValue(data.data);
    }
  }
}

export const dataQualityService = new DataQuality();
