import axios from 'axios';

import { API_URL, API_VIRTUAL_RESULTS_FUTURE, API_VIRTUAL_RESULTS_PAST, ROUTER } from 'src/constants';

import { action, request, history } from 'utils';

import { GET_VIRTUAL_RESULTS } from '../constants';

import { virtualListMapper } from '../utils';

import { virtualStore } from '../stores';

import { VirtualDistance } from '../types';

export class VirtualService {
  @request({ action: GET_VIRTUAL_RESULTS })
  async getVirtualFutureRequest(params: Object): Promise<any> {
    return axios.get(`${API_URL}${API_VIRTUAL_RESULTS_FUTURE}`, { params });
  }

  @request({ action: GET_VIRTUAL_RESULTS })
  async getVirtualPastRequest(params: Object): Promise<any> {
    return axios.get(`${API_URL}${API_VIRTUAL_RESULTS_PAST}`, { params });
  }

  @action({ action: GET_VIRTUAL_RESULTS })
  async getVirtualResults(): Promise<void> {
    const future = await this.getVirtualFuture();

    if (future.length === virtualStore.params.limit) {
      const data = virtualListMapper([...virtualStore.list, ...future], 'virtual');
      virtualStore.setVirtualResults(data);
      return;
    }

    const past = await this.getVirtualPast();

    const data = virtualListMapper([...virtualStore.list, ...future, ...past], 'virtual');
    virtualStore.setVirtualResults(data);

    if (virtualStore.list.length < 3) {
      history.push(`${ROUTER.PROFILE}?tab=my_results`);
    }
  }

  async getVirtualFuture(): Promise<Array<VirtualDistance>> {
    if (virtualStore.isPast) return [];
    const [status, response] = await this.getVirtualFutureRequest(virtualStore.params);

    if (!status) return [];

    if (virtualStore.params.page === response.data.meta.pagination.total_pages) {
      virtualStore.setParams({ page: 1 }, true);
    } else {
      virtualStore.setParams({ page: virtualStore.params.page + 1 });
    }

    return response.data.data;
  }

  async getVirtualPast(): Promise<Array<VirtualDistance>> {
    const [status, response] = await this.getVirtualPastRequest(virtualStore.params);
    if (!status) return [];

    virtualStore.setHasMore(response.data.meta.pagination.total_pages > virtualStore.params.page);
    virtualStore.setParams({ page: virtualStore.params.page + 1 });

    return response.data.data;
  }
}

const virtualService = new VirtualService();
export { virtualService };
