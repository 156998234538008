import { VIEWPORT_CONTAINER_ID } from '../constants';

function isViewportFilled(): boolean {
  const viewportEl = document.getElementById(VIEWPORT_CONTAINER_ID);

  if (!viewportEl) {
    return false;
  }

  const viewport = window.innerHeight * 2;
  const loadedViewportPosition = viewportEl.getBoundingClientRect().bottom;

  return loadedViewportPosition > viewport;
}

export { isViewportFilled };
