import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { Headline, Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { t } from 'utils';

import { Distance } from 'models';

import { windowSize } from 'stores';

import { segmentsStore } from '../stores';

type Props = {};

const StyledHeadline = styled(Headline)`
  margin: 0;
`;

const MobileHeader = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay0};
`;

export const DistanceName: React.FC<Props> = (props) => {
  return (
    <Observer>
      {() => {
        if (!segmentsStore.distance) return null;
        const model = new Distance(segmentsStore.distance);
        const isMobile = windowSize.isLessThan('medium');

        return (
          <>
            <Show if={isMobile}>
              <MobileHeader variant='u1' weight='bold' className='title'>
                {t(() => model.value.name)}
              </MobileHeader>
            </Show>

            <Show if={!isMobile}>
              <StyledHeadline variant='h3' className='title'>
                {t(() => model.value.name)}
              </StyledHeadline>
            </Show>
          </>
        );
      }}
    </Observer>
  );
};
