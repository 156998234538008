import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Divider } from 'src/styledComponents/Divider';
import { Utility } from 'src/styledComponents/Typography';

import { blockBodyScroll, t, unblockBodyScroll } from 'utils';

import { ActionMenuItems } from '../ActionMenuItems';

const Wrapper = styled.div<{ isSwipeEnd: boolean }>`
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100vw;
  padding: 0 20px;
  border-radius: 24px 24px 0 0;

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }

  .loading-overlay {
    .spinner {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  text-align: center;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    justify-content: space-between;
  }
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  margin-bottom: 24px;
`;

const ActionModalContent = ({ onClose, title }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isSwipeEnd, setIsSwipeEnd] = useState(false);

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  useEffect(() => {
    blockBodyScroll();
    return () => {
      unblockBodyScroll();
    };
  }, []);

  return (
    <TestAnchorContext.Provider value={{ container: 'Profile.Action' }}>
      <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={onClose}>
        <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
          <DraggableStroke />
        </Swipe>
        <Header>
          <Utility variant='u1' weight='medium'>
            {title}
          </Utility>
        </Header>
        <StyledDivider light />
        <ActionMenuItems />
      </Wrapper>
    </TestAnchorContext.Provider>
  );
};

export { ActionModalContent };
