import { values, keys } from 'lodash';

function mapToFeFormat(value: ApiTranslations): Translations {
  const translations: ApiTranslationsFile[] = values(value);

  return translations.reduce<Translations>((acc: any, translationFile: ApiTranslationsFile) => {
    const messageKeys = keys(translationFile.translations);

    acc[translationFile.name] = messageKeys.reduce((acc: any, key) => {
      acc[key] = translationFile.translations[key].message;
      return acc;
    }, {});

    return acc;
  }, {} as Translations);
}

export { mapToFeFormat };
