import * as React from 'react';

export const Swish = (
  <svg width='32' height='24' viewBox='0 0 32 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='24' rx='4' fill='#F5F5F7' />
    <path
      d='M12.5469 19.2187C14.3042 20.0575 16.3074 20.2273 18.181 19.6962C20.0547 19.1651 21.6702 17.9696 22.7248 16.3337C23.7795 14.6978 24.2009 12.7338 23.91 10.8097C23.6192 8.88566 22.6361 7.13358 21.1449 5.88184L18.8924 8.5527C20.1627 9.61761 20.9576 11.143 21.1022 12.7935C21.2469 14.4439 20.7294 16.0841 19.6637 17.3534C17.8933 19.4638 14.9834 20.1644 12.5469 19.2187Z'
      fill='url(#paint0_linear_635_24569)'
    />
    <path
      d='M12.5468 19.2187C14.148 19.9817 15.9574 20.1918 17.6911 19.8162C19.4248 19.4406 20.9845 18.5004 22.1256 17.1433C22.4147 16.8 22.6755 16.434 22.9056 16.0487C23.098 14.8526 22.9772 13.6271 22.5548 12.4915C22.1324 11.3559 21.4229 10.3489 20.4954 9.56857C20.0108 9.15913 19.4709 8.8201 18.8915 8.56152C20.1617 9.62643 20.9566 11.1519 21.1012 12.8023C21.2459 14.4527 20.7284 16.093 19.6627 17.3623C17.8923 19.4639 14.9825 20.1645 12.5459 19.2187'
      fill='white'
      fillOpacity='0.7'
    />
    <path
      d='M19.4529 4.78112C17.6956 3.94228 15.6924 3.77249 13.8187 4.30358C11.9451 4.83466 10.3296 6.03017 9.27494 7.66607C8.2203 9.30197 7.79892 11.266 8.08976 13.19C8.3806 15.1141 9.36371 16.8662 10.8548 18.1179L13.1073 15.4383C11.8371 14.3734 11.0422 12.848 10.8976 11.1975C10.7529 9.54711 11.2704 7.90686 12.3361 6.63759C14.1065 4.53592 17.0251 3.83537 19.4529 4.78112Z'
      fill='url(#paint1_linear_635_24569)'
    />
    <path
      d='M19.4526 4.78049C17.8513 4.01733 16.0417 3.80707 14.3078 4.1827C12.574 4.55833 11.014 5.49859 9.87292 6.85589C9.58384 7.19919 9.32298 7.56525 9.09287 7.9505C8.90047 9.14663 9.02135 10.3722 9.44373 11.5078C9.86612 12.6434 10.5756 13.6503 11.5031 14.4306C11.9877 14.8401 12.5276 15.1791 13.107 15.4377C11.8368 14.3728 11.0419 12.8473 10.8973 11.1969C10.7526 9.54648 11.2701 7.90624 12.3358 6.63696C14.1062 4.5353 17.0248 3.83474 19.4526 4.78049Z'
      fill='url(#paint2_linear_635_24569)'
    />
    <defs>
      <linearGradient id='paint0_linear_635_24569' x1='18.5429' y1='15.8321' x2='15.2551' y2='9.10149' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#EF2131' />
        <stop offset='1' stopColor='#FECF2C' />
      </linearGradient>
      <linearGradient id='paint1_linear_635_24569' x1='13.4301' y1='8.19326' x2='16.7107' y2='14.7824' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#78F6D8' />
        <stop offset='0.27' stopColor='#77D1F6' />
        <stop offset='0.55' stopColor='#70A4F3' />
        <stop offset='1' stopColor='#661EEC' />
      </linearGradient>
      <linearGradient id='paint2_linear_635_24569' x1='10.6517' y1='13.8342' x2='16.549' y2='3.59244' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#536EED' />
        <stop offset='0.25' stopColor='#54C3EC' />
        <stop offset='0.56' stopColor='#64D769' />
        <stop offset='1' stopColor='#FECF2C' />
      </linearGradient>
    </defs>
  </svg>
);
