import * as React from 'react';
import { Link as RouterLink, LinkProps, NavLinkProps, NavLink as RouterNavLink } from 'react-router-dom';

import { history } from 'utils';

type NavTabProps = {
  onClick?: Function;
  link: string;
  children: string | React.ReactNode;
  isActive: boolean;
};

function Link(props: LinkProps & OptionalTestAnchorProps) {
  const { ATN, ...linkProps } = props;
  const testAttributes = testAnchors.useLink(ATN);

  return <RouterLink {...linkProps} {...testAttributes} />;
}

function NavLink(props: NavLinkProps & OptionalTestAnchorProps) {
  const { ATN, ...linkProps } = props;
  const testAttributes = testAnchors.useLink(ATN);

  return <RouterNavLink {...linkProps} {...testAttributes} />;
}

function NavTab(props: NavTabProps & OptionalTestAnchorProps) {
  const { onClick, link, children, isActive, ATN } = props;
  const testAttributes = testAnchors.useLink(ATN);

  const activateTab = () => {
    onClick && onClick();
    history.replace(link);
  };

  return (
    <span {...testAttributes} onClick={activateTab} className={isActive ? 'active' : ''}>
      {children}
    </span>
  );
}

export { Link, NavLink, NavTab };
