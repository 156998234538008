import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const CrossExitPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12 14.3741L19.626 22L22 19.626L14.3741 12L22 4.374L19.626 2L12 9.62602L4.374 2L2 4.374L9.62602 12L2 19.626L4.374 22L12 14.3741Z'
    fill='white'
  />
);

export const CrossExit = (props: IconProps) => <SvgIcon path={CrossExitPath} {...props} />;
