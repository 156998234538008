import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const EnergyPath = (
  <path
    id='Subtract'
    fillRule='evenodd'
    clipRule='evenodd'
    d='M22.5009 14H20.5009V17C20.5009 18.0893 19.6509 19 18.5009 19H16.5L16.4989 22H6.5V19C6.5 17.7753 5.91871 16.7569 5.26788 15.6166C4.44018 14.1664 3.5 12.5192 3.5 10C3.5 5 7.52352 2.00012 12.5 2C16.6879 2.00026 19.9582 5.06635 20.5009 9L22.5009 14ZM11.5 17.7002L17.5 9.7002H12.5L13.5 4.7002L7.5 12.7002H12.5L11.5 17.7002Z'
    fill='currentColor'
  />
);

export const Energy = (props: IconProps) => <SvgIcon path={EnergyPath} {...props} />;
