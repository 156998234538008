import { ROUTER } from 'src/constants';

import { historyStore } from 'utils';

export const previousValidPath = (): string => {
  for (let location of historyStore.slice().reverse()) {
    const prevLocationIndex = historyStore.length - 2;
    const prevLocationRoot = historyStore[prevLocationIndex];
    let prevLocationPath = prevLocationRoot?.pathname + prevLocationRoot?.search;

    if (!location) {
      return ROUTER.HOME;
    }

    if (location) {
      return prevLocationPath;
    }
  }

  return ROUTER.HOME;
};
