import { RegistrationFields } from '../../constants';

import { Data } from '../../types/data';

/**
 * @description
 * Filter UserType or just object which is an object of {[registrationFields]: any}
 * to the object which contains only the selected fields
 */
function selectBERacerByRegistrationFields(registrationFields: RegistrationFieldsType[]) {
  return (data: Partial<{ [k in RegistrationFields]: any }>): Partial<{ [k in RegistrationFields]: any }> => {
    return registrationFields.reduce((filteredData, field) => {
      const name = field.name;

      if (field.selected) {
        filteredData[name] = data[name];
      }

      return filteredData;
    }, {});
  };
}

/**
 * @description
 * Filter team registrant, group registrant (racer from the form)
 * to the object which contains only the selected fields
 */
function selectFormRacerByRegistrationFields(registrationFields: RegistrationFieldsType[]) {
  return (data: Data.Form.Racer): Data.Form.Racer => {
    return registrationFields.reduce((filteredData, field) => {
      const name = field.name;

      if (field.selected || field.required) {
        filteredData[name] = data[name];
      }

      return filteredData;
    }, {});
  };
}

export { selectBERacerByRegistrationFields, selectFormRacerByRegistrationFields };
