import { computed } from 'mobx';
import { fieldIdUtils } from 'modules/registration/utils';

import { RegistrationStepEnum } from '../../constants';

import { validators } from '../../validations/validators';
import { mainConditions } from '../mainConditions';

/**
 * @description
 * Current stepper available steps that being displayed
 */
const steps = computed((): RegistrationStepEnum[] => {
  return [RegistrationStepEnum.registration, RegistrationStepEnum.confirmation];
});
/**
 * @description
 * For each step, list of regexp by which error beind binded from the errorsStore to the step
 */
const stepErrorMatchers = computed((): { [K in RegistrationStepEnum]: RegExp[] } => {
  const registrationLeaderMatcher = RegExp(fieldIdUtils.matchers.registrationLeaderMatcher());
  const registrationLeaderCustomFieldsMatcher = RegExp(fieldIdUtils.matchers.registrationLeaderCustomFieldsMatcher());
  const registrationLeaderCouponMatcher = RegExp(fieldIdUtils.matchers.registrationLeaderCouponMatcher());
  const groupSignUpMembersMatcher = RegExp(fieldIdUtils.groupRegistrationMemersExceptLeader());
  const teamMembersMatcher = RegExp(fieldIdUtils.matchers.teamMembersMatcher());
  const teamFieldsMatcher = RegExp(fieldIdUtils.matchers.teamMatcher());
  const termsMatcher = RegExp(fieldIdUtils.matchers.registrationTermsMatcher());
  const refundProtectMatcher = RegExp(fieldIdUtils.matchers.groupRegistrationsRefundProtect(0));

  if (mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        termsMatcher,
        registrationLeaderMatcher,
        groupSignUpMembersMatcher,
        registrationLeaderCouponMatcher,
      ],
      [RegistrationStepEnum.confirmation]: [refundProtectMatcher],
    };
  }

  if (mainConditions.isPageRegistrationSingleWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        termsMatcher,
        registrationLeaderMatcher,
        registrationLeaderCustomFieldsMatcher,
        groupSignUpMembersMatcher,
        registrationLeaderCouponMatcher,
      ],

      [RegistrationStepEnum.confirmation]: [refundProtectMatcher],
    };
  }

  if (mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        termsMatcher,
        registrationLeaderMatcher,
        registrationLeaderCouponMatcher,
        teamFieldsMatcher,
        teamMembersMatcher,
      ],

      [RegistrationStepEnum.confirmation]: [refundProtectMatcher],
    };
  }

  if (mainConditions.isPageRegistrationTeamWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        termsMatcher,
        registrationLeaderMatcher,
        teamFieldsMatcher,
        teamMembersMatcher,
        registrationLeaderCustomFieldsMatcher,
        registrationLeaderCouponMatcher,
      ],

      [RegistrationStepEnum.confirmation]: [refundProtectMatcher],
    };
  }

  if (mainConditions.isPageConfirmationWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [termsMatcher, registrationLeaderMatcher, registrationLeaderCouponMatcher],
      [RegistrationStepEnum.confirmation]: [refundProtectMatcher],
    };
  }

  if (mainConditions.isPageConfirmationWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        termsMatcher,
        registrationLeaderMatcher,
        registrationLeaderCustomFieldsMatcher,
        registrationLeaderCouponMatcher,
      ],

      [RegistrationStepEnum.confirmation]: [refundProtectMatcher],
    };
  }

  return {
    [RegistrationStepEnum.registration]: [],

    [RegistrationStepEnum.confirmation]: [],
  };
});

const stepValidators = computed((): { [K in RegistrationStepEnum]: AnyFunction[] } => {
  if (mainConditions.isPageRegistrationSingleWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
        validators.validateGroupRegistrations,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageRegistrationSingleWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
        validators.validateLeaderCustomFields,
        validators.validateGroupRegistrations,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageRegistrationTeamWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
        validators.validateTeam,
        validators.validateTeamRegistrationMembers,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageRegistrationTeamWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
        validators.validateTeam,
        validators.validateTeamRegistrationMembers,
        validators.validateLeaderCustomFields,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageConfirmationWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageConfirmationWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
        validators.validateLeaderCustomFields,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageTransferConfirmationWithoutCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  if (mainConditions.isPageTransferConfirmationWithCustomFields.get()) {
    return {
      [RegistrationStepEnum.registration]: [
        validators.validateRegistrationLeader,
        validators.validatePasswordFields,
        validators.validateTerms,
        validators.validateLeaderCustomFields,
      ],

      [RegistrationStepEnum.confirmation]: [validators.validateRefundProtect],
    };
  }

  return {
    [RegistrationStepEnum.registration]: [],

    [RegistrationStepEnum.confirmation]: [],
  };
});

export const stepperSelector = {
  steps,
  stepErrorMatchers,
  stepValidators,
};
