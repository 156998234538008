import { isEmpty } from 'lodash';

import { HEADER_FORM } from 'src/constants';

import { form } from 'stores';

import { amountInDropdown } from '../constants';

import { searchService } from '../services';

import { locationStore, recentLocationStore, recentRacesStore } from '../stores';

import { recentRaceNameSearches, recentLocationSearches } from '../selectors';
import { FieldNames } from '../types';
import { toggleLocationAutocomplete, toggleRacesAutocomplete } from './controls';

export const setRecentRaces = (raceName: string) => {
  const raceNameSearches = recentRaceNameSearches();
  raceNameSearches.unshift(raceName);
  localStorage.setItem('recentRaceNameSearches', JSON.stringify(raceNameSearches.slice(0, amountInDropdown.raceName)));
};

export const setRecentLocations = (location: string) => {
  const locationSearches = recentLocationSearches();
  const locationValue = locationStore.value?.find((el) => el.city === location);
  if (!locationValue) {
    return;
  }
  locationSearches.unshift(locationValue);
  localStorage.setItem('recentLocationSearches', JSON.stringify(locationSearches.slice(0, amountInDropdown.locations)));
};

export const getDefaultRaces = () => {
  const raceName = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.raceName);
  if (raceName) {
    return;
  }
  const raceNameSearches = recentRaceNameSearches();

  if (isEmpty(raceNameSearches)) {
    searchService.getRecomendedRaces();
    toggleRacesAutocomplete(true);
    return;
  }
  recentRacesStore.setValue(raceNameSearches);
  toggleRacesAutocomplete(true);
};

export const getDefaultLocations = () => {
  const location = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.location);
  const locationSearches = recentLocationSearches();
  if (location) {
    return;
  }
  recentLocationStore.setValue(locationSearches);
  !isEmpty(locationSearches) && toggleLocationAutocomplete(true);
};
