import { useHeaderSettings } from 'modules/header';
import { HEADER_HEIGHT } from 'modules/header/constants';
import { HeaderVariants } from 'modules/header/types';
import { SettingsTabComponent } from 'modules/profileSettings/components';
import * as React from 'react';
import { Route, Redirect, Switch, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { RESOLUTIONS, ROUTER } from 'src/constants';
import { Headline } from 'src/styledComponents/Typography';

import SEO from 'components/SEO';

import { t, intercom } from 'utils';

import { AccountInformationComponent, ChangePasswordComponent } from '../components';

import { MoreInfo } from '../containers/MoreInfo';

type TabsType = { title: string; link: string; ATN?: string };

type Props = RouteComponentProps & {};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.main.colors.white};
`;

const Container = styled.div`
  max-width: 740px;
  width: 100%;
  margin: ${HEADER_HEIGHT}px 0 64px;
  padding: 0 20px;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-top: ${HEADER_HEIGHT + 72}px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin: 24px 0;
  }
`;

const StyledHeadline = styled(Headline)`
  font-size: 40px;
  text-transform: uppercase;
  @media (min-width: ${RESOLUTIONS.medium}px) {
    font-size: 48px;
  }
`;

export const Base = ({ match: { path, url } }: Props) => {
  useHeaderSettings({ variant: HeaderVariants.normal });

  // intercom.useShowIntercom();

  const tabs: TabsType[] = [
    {
      title: t.staticAsString('settings.accountInfo'),
      link: `${url}${ROUTER.ACCOUNTINFO}`,
      ATN: 'account-info',
    },
    {
      title: t.staticAsString('settings.moreInfo'),
      link: `${url}${ROUTER.MOREINFO}`,
      ATN: 'more-info',
    },
    {
      title: t.staticAsString('settings.changePassword'),
      link: `${url}${ROUTER.CHANGEPASSWORD}`,
      ATN: 'change-password',
    },
  ];

  return (
    <Wrapper>
      <SEO title={t.staticAsString('settings.settingsTitle')} />

      <Container>
        <Header>
          <StyledHeadline variant='h1'>{t.staticAsString('settings.settingsTitle')}</StyledHeadline>
          <SettingsTabComponent tabs={tabs} />
        </Header>
        <Switch>
          <Route path={`${path}${ROUTER.ACCOUNTINFO}`} component={AccountInformationComponent} />
          <Route path={`${path}${ROUTER.MOREINFO}`} component={MoreInfo} />
          <Route path={`${path}${ROUTER.CHANGEPASSWORD}`} component={ChangePasswordComponent} />
          <Redirect to={`${path}${ROUTER.ACCOUNTINFO}`} />
        </Switch>
      </Container>
    </Wrapper>
  );
};
