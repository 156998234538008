import styled, { css } from 'styled-components';

import { RESOLUTIONS } from 'src/constants';

import Arrow from '../Icons/Files/Arrow.svg';
import CrossIcon from '../Icons/Files/CrossGray.svg';
import FormTick from '../Icons/Files/FormTick.svg';
import GlobeImage from '../Icons/Files/GlobeRedisign.svg';
import { getBackgroundColor, getBorderColor } from '../InputTools';
import { getFlagsCSS } from './utils';

type Props = {
  disabled?: boolean;
  hasError?: boolean;
  isEmpty?: boolean;
};

export const PhoneFieldWrapper = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  .react-tel-input {
    display: flex;
    flex-direction: row-reverse;
    height: 56px;

    background: ${getBackgroundColor};
    border-radius: 2px;
    border: 2px solid ${getBorderColor('transparent')};

    &.disabled {
      input {
        color: #c3c6c8;
      }
      .flag-dropdown {
        opacity: 0.3;
      }
    }

    &:not(.disabled):hover,
    &:focus {
      outline: none;
      border-color: ${getBorderColor()};
    }

    .special-label {
      display: none;
    }

    .no-entries-message {
      display: none;
    }

    input,
    .search-box {
      width: 100%;
      display: flex;
      align-items: center;
      height: 52px;
      flex: 1;

      padding: 0 16px;

      background: transparent;
      border-radius: 0;
      border: none;

      font-family: Aeonik, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;

      letter-spacing: -0.01em;
      color: ${(props) => props.theme.main.colors.clay1};

      ${(props) => (props.disabled ? 'pointer-events: none;' : '')}

      &::placeholder {
        color: ${(props) => props.theme.main.colors.clay4};
      }
    }

    .flag-dropdown {
      position: unset;
      width: 76px;
      border: none;
      border-radius: 0;
      background: transparent;
      padding: 16px;
      border-right: 1px solid ${(props) => (props.hasError ? props.theme.main.colors.clay5 : props.theme.main.colors.clay5)};

      ${(props) => (props.disabled ? 'pointer-events: none;' : '')}
      &:hover {
        .selected-flag {
          background: transparent;
        }
      }

      .selected-flag {
        padding: 0;
        display: flex;
        align-items: center;
        width: unset;
        background: transparent;

        .flag {
          position: unset;
          margin-top: 0;

          .arrow {
            border: none;
            left: 30px;
            margin-top: -4px;
            background-image: url('${Arrow}');
            background-size: contain;
            background-repeat: no-repeat;
            height: 12px;
            width: 12px;
          }
        }

        .flag {
          background-image: url('${GlobeImage}');
          background-size: contain;
          background-repeat: no-repeat;
          height: 24px;
          width: 24px;
        }
      }

      .country-list {
        overflow-x: hidden;
        top: 64px;
        left: -2px;
        margin: 0;

        width: calc(100% + 4px);
        max-height: 464px;
        background: white;
        border-radius: 2px;
        overflow-y: auto;
        padding: 0 0 8px;

        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);

        li.search {
          padding: 8px 0 0 0;
          z-index: 1;
          .search-box {
            height: 56px;
            margin: 0;
            background: ${(props) => props.theme.main.colors.clay6};
            border: 2px solid transparent;
          }
          input[type='search']::-webkit-input-placeholder {
            text-transform: capitalize;
          }

          input[type='search']::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 14px;
            width: 14px;
            background: url('${CrossIcon}') no-repeat 50% 50%;
            background-size: contain;
            opacity: 0;
            pointer-events: none;
          }
          input[type='search']:focus::-webkit-search-cancel-button {
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
          }
        }

        li.country {
          padding: 0 16px;
          height: 56px;
          width: 100%;
          display: flex;
          align-items: center;

          font-family: Aeonik, sans-serif;
          font-style: normal;
          color: ${(props) => props.theme.main.colors.clay1};
          font-weight: 500;
          letter-spacing: 0.03em;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 18px;

          :hover {
            background: ${(props) => props.theme.main.colors.clay6};
          }

          @media (min-width: ${RESOLUTIONS.medium}px) {
            font-size: 20px;
            line-height: 24px;
          }
          .flag {
            margin-top: 0;
          }

          .country-name {
            margin: 0 16px;
          }

          &.highlight {
            background-color: #ffffff;
            :after {
              content: url('${FormTick}');
              position: absolute;
              right: 16px;
            }
          }
        }
      }
    }

    .flag {
      background-size: contain;
      height: 16px;
      width: 21px;
      border-radius: 2px;
    }
    ${getFlagsCSS()};

    ${(props) =>
      props.isEmpty &&
      css`
        .flag-dropdown .selected-flag .flag {
          background-image: url('${GlobeImage}') !important;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          height: 24px !important;
          width: 24px !important;
        }
      `}
  }
`;
