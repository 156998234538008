/*
 * Actions to call segment new/edit form
 */
import { ignition } from 'modules/segmentForm';
import { Callback } from 'modules/segmentForm/types';

type CommonParams = [number, number, Callback];

/*
  ?segmentId: null | number,
  distanceId: number,
  racerId: number,
  succCallback: Function,
*/

function switchFirstNew(...args: CommonParams) {
  ignition.switchOn('first-new', null, ...args);
}

function switchNew(...args: CommonParams) {
  ignition.switchOn('new', null, ...args);
}

function switchEdit(segmentId: number, ...args: CommonParams) {
  ignition.switchOn('edit', segmentId, ...args);
}

const segmentIgnition = {
  switchFirstNew: switchFirstNew,
  switchNew: switchNew,
  switchEdit: switchEdit,
  close: ignition.switchOff,
};

export { segmentIgnition };
