// TODO, fix flow errors
import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  API_URL,
  API_MANAGE_TEAM_INFO,
  API_PATCH_TEAM_NAME,
  API_ADD_TEAM_MEMBER,
  API_EDIT_TEAM_MEMBER,
  API_RESEND_INVITATION,
  AWAITING,
} from 'src/constants';

import { request, action, encodeQuery, history, toastService } from 'utils';

import { manageTeamStore, Race } from 'stores';

import { MANAGE_TEAM_RACE, PATCH_TEAM_NAME, ADD_TEAM_MEMBER, EDIT_TEAM_MEMBER, RESEND_INVITATION } from '../constants';

import { apiErrors } from '../validation';

class ManageTeam {
  // TODO Verify what type of Race is this. The only store with changeNeededProp is
  store: any;
  constructor(store: any) {
    this.store = store;
  }

  @request({ action: MANAGE_TEAM_RACE })
  async loadRaceInfoRequest(id: number): Promise<any> {
    return axios.get(`${API_URL}${API_MANAGE_TEAM_INFO}/${id}`);
  }

  @request({ action: PATCH_TEAM_NAME })
  async patchTeamNameRequest(params: Object, teamId: number): Promise<any> {
    const URL = generatePath(`${API_URL}${API_PATCH_TEAM_NAME}`, {
      teamId,
    });

    return axios.patch(URL, params);
  }

  @request({ action: ADD_TEAM_MEMBER })
  async addTeamMemberRequest(params: Object, teamId: number): Promise<any> {
    const URL = generatePath(`${API_URL}${API_ADD_TEAM_MEMBER}`, {
      teamId,
    });

    return axios.post(URL, params);
  }

  @request({ action: EDIT_TEAM_MEMBER })
  async editTeamMemberRequest(params: Object, teamId: number, racerId: number): Promise<any> {
    const URL = generatePath(`${API_URL}${API_EDIT_TEAM_MEMBER}`, {
      teamId,
      racerId,
    });

    return axios.patch(URL, params);
  }

  @request({ action: RESEND_INVITATION })
  async resendInvitationRequest(teamId: number, racerId: number): Promise<any> {
    const URL = generatePath(`${API_URL}${API_RESEND_INVITATION}`, {
      teamId,
      racerId,
    });

    return axios.post(URL);
  }

  @action({ action: MANAGE_TEAM_RACE, minRequestTime: 400 })
  async loadRaceInfo(id: number) {
    const [status, response] = await this.loadRaceInfoRequest(id);

    if (status) {
      this.store.addSelectedValue(response.data.data || {});
      if (response && response.data && response.data.data) {
        const query = encodeQuery.parseQuery(history.location.search) || {};
        const distance = ((manageTeamStore.selected || {}).distances || [])[0] || {};

        let distanceId = +query.distance_id;

        if (!distanceId) {
          distanceId = distance.id;
        }

        manageTeamStore.setTeamOwner(distanceId);
      }
    }
  }

  @action({ action: PATCH_TEAM_NAME })
  async patchTeamName(params: Object, distanceId: number, teamId: number) {
    const [status, response] = await this.patchTeamNameRequest(params, teamId);

    if (status) {
      if (response && response.data && response.data.data) {
        const distanceIndex = (this.store.selected || {}).distances.findIndex((distance: any) => distance.id === distanceId);

        this.store.changeNeededProp(this.store.selected, `distances[${distanceIndex}].teams[0].name`, response.data.data.name);
      }
      return status;
    }
  }

  @action({ action: ADD_TEAM_MEMBER })
  async addTeamMember(params: Object, teamId: number, raceId: number) {
    const [status, response] = await this.addTeamMemberRequest(params, teamId);

    if (status && raceId) {
      await this.loadRaceInfo(raceId);
    }

    if (!status && response && response.data) {
      let errorExist = false;

      apiErrors.forEach((error) => {
        if (response.data.errors[error]) {
          toastService.error(response.data.errors[error][0]);
          errorExist = true;
        }
      });

      if (!errorExist) {
        toastService.error(response.data.message);
      }
    }

    return [status, response];
  }

  @action({ action: EDIT_TEAM_MEMBER })
  async editTeamMember(params: Object, teamId: number, raceId: number, racerId: number) {
    const [status, response] = await this.editTeamMemberRequest(params, teamId, racerId);

    if (status && raceId) {
      await this.loadRaceInfo(raceId);
    }

    if (!status && response && response.data) {
      let errorExist = false;

      apiErrors.forEach((error) => {
        if ((response.data.errors || [])[error]) {
          toastService.error(response.data.errors[error][0]);
          errorExist = true;
        }
      });

      if (!errorExist) {
        toastService.error(response.data.message);
      }
    }

    return [status, response];
  }

  @action({ action: RESEND_INVITATION })
  async resendInvitation(teamId: number, racerId: number): Promise<any> {
    const [status, response] = await this.resendInvitationRequest(teamId, racerId);

    if (status) {
      const member = this.store.getNeededMember(racerId);

      this.store.changeNeededProp(member, 'team_status', AWAITING);
    }

    return [status, response];
  }
}

export { ManageTeam };
