import { actionMenuStore } from '../../stores';

import { exportDiploma } from '../exportData';
import { close } from './display';

const onExportDiploma = async () => {
  const distance = actionMenuStore.distance;
  if (!distance) return;
  const id = distance.id;
  close();
  exportDiploma('pdf', id, distance.racer_id);
};

export { onExportDiploma };
