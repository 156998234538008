import { toastService } from 'utils';

function handleApiError(response: any) {
  if (response.status === 409) {
    const message = response.data.message;

    if (!message) {
      return;
    }

    toastService.error(message);
  }
}

export { handleApiError };
