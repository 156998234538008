import { get } from 'lodash';

import { errorsStore } from 'stores';

import { REGISTRATION_FORM } from '../constants';

import { distanceSelector } from '../selectors/mappedData';

export const checkRegistrationError = (tab: 'personal' | 'additional' | 'extras', prefixKey: string): boolean => {
  const errors = errorsStore.erroribus[REGISTRATION_FORM];
  const customFields = distanceSelector.customFields.get();

  if (!errors) {
    return false;
  }

  const lodashName = prefixKey.split('.');
  const currentErrors = get(errors, lodashName);

  if (!currentErrors) {
    return false;
  }

  if (tab === 'personal') {
    return Boolean(
      currentErrors.email ||
        currentErrors.firstname ||
        currentErrors.lastname ||
        currentErrors.gender ||
        currentErrors.password ||
        currentErrors.password_confirmation,
    );
  }

  if (tab === 'additional') {
    const customFieldsWithError = Object.keys(currentErrors.fields || {});
    const customFieldswithoutExtra = customFields.map((field) => {
      if (field.type !== 'extra') {
        return `custom_field_${field.id}`;
      }
    });
    const error = customFieldsWithError.find((item) => customFieldswithoutExtra.includes(item));

    return Boolean(
      currentErrors.address ||
        currentErrors.birthday ||
        currentErrors.city ||
        currentErrors.company ||
        currentErrors.country_id ||
        currentErrors.emergency_name ||
        currentErrors.emergency_phone ||
        currentErrors.external_swimrun_id ||
        currentErrors.nationality_id ||
        currentErrors.post_code ||
        currentErrors.shirt_size ||
        currentErrors.shoe_size ||
        currentErrors.telephone ||
        currentErrors.union ||
        currentErrors.class_id ||
        currentErrors.wave_id ||
        currentErrors.discipline_id ||
        error,
    );
  }

  if (tab === 'extras') {
    const customFieldsWithError = Object.keys(currentErrors.fields || {});
    const extraCustomFields = customFields.map((field) => {
      if (field.type === 'extra') {
        return `custom_field_${field.id}`;
      }
    });
    const error = customFieldsWithError.find((item) => extraCustomFields.includes(item));

    return Boolean(error);
  }

  return false;
};
