import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { Logo } from 'modules/header/components';
import { handleOpenSelector } from 'modules/languageSelector/actions';
import { Avatar, Button } from 'modules/miles/shared/components';
import React, { FC, ReactElement } from 'react';

import { Show } from 'src/components/condition';
import { menuItems, subMenuItems1, subMenuItems2 } from 'src/modules/Sidebar/constants';
import { Text } from 'src/modules/miles/shared/components/Typography';
import { Icon } from 'src/styledComponents/Icons';
import { Svg } from 'src/styledComponents/Icons/Svg';
import { NavLink } from 'src/styledComponents/Link';
import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { localeStore, sessionStore } from 'stores';

import {
  SidebarUserWrapper,
  Menu,
  SidebarWrapper,
  User,
  LanguageBox,
  ButtonBox,
  Top,
  SidebarDivider,
  UserName,
  MenuItem,
} from '../components';

import { handleCloseSidebar, handleLogout } from '../actions';

import { sidebarStore, sidebarSettingsStore } from '../stores';

import { Item } from './Item';
import { MainWrapper, ScrollableWrapper, StyledLogoLink, StyledNavLink, StyledUtility } from './styled';

export const Sidebar: FC = (): ReactElement<any, any> | null => {
  const language = localeStore.value;

  const fullname = (user: AnyObject): React.ReactNode => {
    return [user.firstname, user.lastname].join(' ').trim();
  };

  return (
    <Observer>
      {() => {
        const user = sessionStore.getUserData();
        const isUserExists = !isEmpty(user);
        const bodyElement = document.querySelector('body');
        const isActive = !!sidebarStore.value;
        const { loginHandler } = sidebarSettingsStore.value || {};

        if (!isActive) {
          bodyElement!.style.overflow = 'auto';
        } else {
          bodyElement!.style.overflow = 'hidden';
        }

        return (
          <SidebarWrapper isActive={isActive}>
            <Top>
              <StyledLogoLink onClick={() => handleCloseSidebar()}>
                <Logo type='withoutBackground' size={20} />
              </StyledLogoLink>
              <Svg name='CrossClose' onClick={() => handleCloseSidebar()} size={24} />
            </Top>
            <MainWrapper>
              <SidebarUserWrapper>
                {isUserExists ? (
                  <StyledNavLink to='/myProfile' onClick={() => handleCloseSidebar()}>
                    <User>
                      <Avatar size={56} />
                      <UserName>
                        <StyledUtility weight='medium' variant='u1' className='user'>
                          {fullname(user)}
                        </StyledUtility>
                        <StyledUtility weight='bold' variant='u1' className='user'>
                          My profile
                        </StyledUtility>
                      </UserName>
                    </User>
                  </StyledNavLink>
                ) : (
                  <ButtonBox>
                    <NavLink to='/signUp' onClick={() => handleCloseSidebar()}>
                      <Button mode='light' message='main' size='l'>
                        {t.staticAsString('navigation.signUpLink')}
                      </Button>
                    </NavLink>
                    <Show if={!loginHandler}>
                      <NavLink to='/signIn' onClick={() => handleCloseSidebar()}>
                        <Button mode='light' message='subtle' size='l'>
                          {t.staticAsString('shared.logIn')}
                        </Button>
                      </NavLink>
                    </Show>
                    <Show if={Boolean(loginHandler)}>
                      <Button
                        mode='light'
                        message='subtle'
                        size='l'
                        onClick={() => {
                          loginHandler && loginHandler();
                          handleCloseSidebar();
                        }}
                      >
                        {t.staticAsString('shared.logIn')}
                      </Button>
                    </Show>
                  </ButtonBox>
                )}

                <LanguageBox onClick={() => handleOpenSelector()}>
                  <Icon name='Globe' size={24} />
                  <Utility variant='u3' weight='medium'>
                    {language}
                  </Utility>
                </LanguageBox>
              </SidebarUserWrapper>
              <ScrollableWrapper>
                <Menu>
                  {menuItems.map((item) => (
                    <Item key={item.title} item={item} onClick={() => handleCloseSidebar()} />
                  ))}
                </Menu>

                <SidebarDivider />

                <Menu>
                  {subMenuItems1.map((item) => (
                    <Item key={item.title} item={item} isSub onClick={() => handleCloseSidebar()} />
                  ))}
                </Menu>

                <SidebarDivider />
                <Menu>
                  {subMenuItems2.map((item) => (
                    <Item key={item.title} item={item} isSub onClick={() => handleCloseSidebar()} />
                  ))}
                </Menu>

                <Show if={isUserExists}>
                  <SidebarDivider />
                  <Menu>
                    <MenuItem onClick={() => handleLogout()} isActive={false}>
                      <Text weight='regular' size='xl' style={{ cursor: 'pointer' }}>
                        Log out
                      </Text>
                    </MenuItem>
                  </Menu>
                </Show>
              </ScrollableWrapper>
            </MainWrapper>
          </SidebarWrapper>
        );
      }}
    </Observer>
  );
};
