import { generateConditions } from 'modules/racersTable/derivations';

import { wavesStore, classesStore, squadsStore, registrationFieldsStore } from '../stores';

const {
  generateIsClasses,
  generateIsWaves,
  generateIsSquads,
  isAnyDistanceHasWaves,
  isAnyDistanceHasSquads,
  isAnyDistanceHasClasses,
  generateIsFieldSelected,
  generateIsAnyDistanceFieldSelected,
} = generateConditions(wavesStore, classesStore, squadsStore, registrationFieldsStore);

export {
  generateIsWaves,
  generateIsClasses,
  generateIsSquads,
  isAnyDistanceHasWaves,
  isAnyDistanceHasSquads,
  isAnyDistanceHasClasses,
  generateIsFieldSelected,
  generateIsAnyDistanceFieldSelected,
};
export * from './isShowBibs';
export * from './isAllFiltersAreShowed';
