import { observable, action, computed, makeObservable } from 'mobx';
import { UserType } from 'typings/old-types/UserType';

import { UserProfileStoreType } from '../types';

class UserProfile implements UserProfileStoreType {
  @observable profile: UserType | any = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setProfile(profile: UserType) {
    this.profile = profile;
  }

  @computed
  get selectedSports(): Array<number | string> {
    return (this.profile?.sports || []).map((sport: any) => sport.id);
  }

  @action
  clearData() {
    this.profile = null;
  }
}

export { UserProfile };
