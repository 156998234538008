/*
    Truncate price to 2 decimals numbers.
    Examples:
        999.9999 -> 999.99
        2.07261 -> 2.07
        0.0000052 -> 0
*/

const round = (price: number): number => {
  if (price < 0.01) return 0;

  const splitPrice = price.toString().split('.');
  const decimals = splitPrice[1]?.slice(0, 2) || 0;
  const result = splitPrice[0] + '.' + decimals;

  return +result;
};

export { round };
