import classNames from 'classnames';
import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

import { redirectRaceUrl } from '../../actions';

type Props = {
  title: string;
  distanceId: number;
  raceId: number;
  type: string;
  disabled?: boolean;
};

export class DistanceTitle extends React.Component<Props> {
  render() {
    const { title, distanceId, raceId, type, disabled } = this.props;
    const url = redirectRaceUrl({ distanceId, raceId, type });

    return (
      <Link className={classNames('distance-title truncate', { disabled })} to={url}>
        {title}
      </Link>
    );
  }
}
