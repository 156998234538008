import axios from 'axios';

import { API_URL, API_UNIONS } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_UNIONS } from '../constants';

import { xtriStore } from '../stores';

class XtriService {
  @request({ action: LOAD_UNIONS })
  async loadUnionsRequest(): Promise<any> {
    return axios.get(API_URL + API_UNIONS);
  }

  @action({ action: LOAD_UNIONS })
  async loadUnions(): Promise<void> {
    const [status, response] = await this.loadUnionsRequest();

    if (status) {
      xtriStore.setValue(response.data);
    }
  }
}

export const xtriService = new XtriService();
