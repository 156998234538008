import axios from 'axios';

import { MILES_READINESS_EXPORT } from 'src/constants';

import { action, request } from 'utils';

import { GET_READINESS } from '../constants';
import { CACHE_KEYS } from '../constants/localStorageKeys';

import { dailyDataStore } from '../stores/dailyData';

export class DailyDataService {
  @request({ action: GET_READINESS })
  private getReadinessRequest(): Promise<any> {
    const today = new Date();
    const start = new Date(today).getTime();
    const end = new Date(today);
    end.setHours(24, 0, 0, 0);
    return axios.get(MILES_READINESS_EXPORT, {
      params: {
        start: Math.floor(start / 1000),
        end: Math.floor(end.getTime() / 1000),
        ratings: true,
      },
    });
  }

  @action({ action: GET_READINESS })
  async getReadiness() {
    const cache = localStorage.getItem(CACHE_KEYS.readiness);
    if (cache) {
      dailyDataStore.maybeSetReadinessValue(JSON.parse(cache));
    }
    const [isOk, data] = await this.getReadinessRequest();
    if (isOk) {
      localStorage.setItem(CACHE_KEYS.readiness, JSON.stringify(data.data[0]));
      dailyDataStore.maybeSetReadinessValue(data.data[0]);
    } else if (data.status === 403) {
      window.location.href = '/miles';
    }
  }
}

export const dailyDataService = new DailyDataService();
