import { reaction } from 'mobx';

import { footerVisibility, iFrameDetect } from 'utils';

import { disposers } from '../stores';

import { pagination } from '../derivations';

/* NOTE
 * Hide footer until all data is showed
 * Show footer only when all of data is displayed
 * and infininte scroll logic is disabled
 */
function observeHasMore() {
  const dispose = reaction(
    () => pagination.hasMore.get(),

    (isHasMore) => {
      if (isHasMore) {
        return footerVisibility.hide();
      }

      // @withoutFooter() is being called, but here we will toggle filter on
      // Should be hidden on iframe
      !iFrameDetect().isWithinIFrame && footerVisibility.show();
    },

    { fireImmediately: true },
  );

  disposers.register(dispose);
}

export { observeHasMore };
