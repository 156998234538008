import { form, authorizedRace } from 'stores';

import { AUTH_ACTION } from '../constants';

import { authorizeService } from '../services';

async function onSubmit() {
  const password = form.fetch<string>(AUTH_ACTION, 'password');
  const id = authorizedRace.active;

  if (!id) {
    return;
  }

  return await authorizeService.authorize(id, password);
}

export { onSubmit };
