import * as React from 'react';
import styled, { css } from 'styled-components';

import { Cell } from 'src/modules/racersTable/components';
import { CELL_SIZES } from 'src/modules/racersTable/constants';
import { RightSideColumnsType } from 'src/modules/racersTable/types';
import { getCellSizes } from 'src/modules/racersTable/utils';

import { classesStore, wavesStore, squadsStore } from '../../stores';

type Props = {
  columnsActive: RightSideColumnsType;
  value: RacersType;
  isTeam?: boolean;
};

const getTeamStyles = (props) => {
  if (!props.isTeam) return '';

  return css`
    padding: 20px 40px 20px 32px;
    height: 132px;
    align-items: flex-start;
  `;
};

const Wrapper = styled.div<{ sizes: string; isTeam?: boolean }>`
  display: grid;
  justify-content: flex-end;
  align-items: center;
  grid-template-columns: ${(props) => props.sizes};
  gap: 32px;

  padding-right: 40px;
  padding-left: 32px;
  height: 64px;
  width: 100%;

  ${getTeamStyles}

  &:nth-child(even) {
    background-color: ${(props) => props.theme.main.colors.clay7};
  }
`;

export const RightSideRow: React.FC<Props> = (props) => {
  const { columnsActive, value, isTeam } = props;
  const sizes = getCellSizes(columnsActive);

  const classModel = classesStore.find(+value.class_id!);
  const waveModel = wavesStore.find(+value.wave_id!);
  const squad = squadsStore.values.find((item) => item.id === value.squad_id!);

  return (
    <Wrapper sizes={sizes} isTeam={isTeam}>
      <Cell name='club' columnsActive={columnsActive} minWidth={CELL_SIZES[0].minWidth} value={value.union} />
      <Cell name='squad' columnsActive={columnsActive} minWidth={CELL_SIZES[1].minWidth} value={squad?.name || (value as any).name} />
      <Cell name='class' columnsActive={columnsActive} minWidth={CELL_SIZES[2].minWidth} value={classModel?.value.title} />
      <Cell name='wave' columnsActive={columnsActive} minWidth={CELL_SIZES[3].minWidth} value={waveModel?.value.name} />
    </Wrapper>
  );
};
