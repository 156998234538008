import moment from 'moment';
import { useState, useEffect } from 'react';

import { mountJournal, unmountJournal } from '../actions';

import { journalParamsStore } from '../stores';

export const useJournalData = () => {
  const today = moment();
  const [searchableDay, setSearchableDay] = useState(today);

  const thirtyDaysAgo = searchableDay.clone().subtract(30, 'days').startOf('day');

  const start = thirtyDaysAgo.unix();
  const end = today.endOf('day').unix();

  journalParamsStore.setValue({ start, end });

  useEffect(() => {
    mountJournal();
    return unmountJournal;
  }, [start, end]);

  return;
};
