import styled from 'styled-components';

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 11px;
  margin: 0;
  gap: 4px;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.main.colors.clay1};
  }
`;
