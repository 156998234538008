import * as React from 'react';
import styled from 'styled-components';

import { TagLine } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

import { Show } from 'components/condition';

type Props = {
  isPlace?: boolean;
  isTeam?: boolean;
  participantsCount: React.ReactNode;
  hasBibs?: boolean;
};

const Wrapper = styled.div<{ gridColumns: string }>`
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  z-index: 5;

  display: grid;
  grid-template-columns: ${(props) => props.gridColumns};
  gap: 8px;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
  background-color: ${(props) => props.theme.main.colors.clay6};
`;

export const MobileHeader: React.FC<Props> = (props) => {
  const { isPlace, isTeam, participantsCount, hasBibs } = props;
  const firstColumnName = isPlace ? 'results.place' : 'results.bib';
  const secondColumnName = !isTeam ? 'results.participants' : 'results.teams';
  const gridColumns = `${isPlace ? '35px' : ''} ${hasBibs ? '35px' : ''} 1fr`;

  return (
    <Wrapper gridColumns={gridColumns}>
      <Show if={Boolean(isPlace) || Boolean(hasBibs)}>
        <TagLine>{t(firstColumnName)}</TagLine>
      </Show>
      <TagLine>
        {t(secondColumnName)} {participantsCount}
      </TagLine>
    </Wrapper>
  );
};
