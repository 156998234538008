import { inject, observer } from 'mobx-react';
import AuthPageTemplate, { ButtonWrapper, Controls, FormField } from 'modules/auth/components/AuthPageTemplate';
import * as React from 'react';
import styled from 'styled-components';

import { FORGOT_PASSWORD, ROUTER } from 'src/constants';
import { TextField } from 'src/styledComponents/TextField';

import { withErrorClean } from 'hocs';

import { toastService, validation, Errors, history, previousValidPath, t } from 'utils';

import { AuthService } from 'services';

const EmailTip = styled.div`
  margin-top: 16px;
  color: ${(props) => props.theme.main.colors.clay3};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

const forgotPassword = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('validation.emailRequired'),
    },
    email: {
      message: () => t.staticAsString('validation.emailFormat'),
    },
  },
};

@withErrorClean(FORGOT_PASSWORD)
@inject('errorsStore')
@observer
export class ForgotPassword extends React.Component<LoginPropsType, ForgotPasswordType> {
  action: string;
  service: AuthService;
  errorsHandler: Errors;

  static defaultProps = {
    errorsStore: null as any,
  };

  state: ForgotPasswordType = {
    email: '',
    redirect_location: '',
  };

  constructor(props: LoginPropsType) {
    super(props);
    this.action = FORGOT_PASSWORD;
    this.errorsHandler = new Errors(FORGOT_PASSWORD);
    this.service = new AuthService();
  }

  componentDidMount() {
    this.setState({
      redirect_location: previousValidPath(),
    });
  }

  onSubmit = async (): Promise<void> => {
    if (validation(this.action, this.state, forgotPassword)) {
      const onSuccess = await this.service.forgotPassword(this.state);

      if (onSuccess) {
        history.push(ROUTER.FORGOT_CONFIRM);
      }
    }
  };

  onChange = (arg0, e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  hasError = (name: string): string => {
    return this.errorsHandler.hasError(name);
  };

  render(): React.ReactElement {
    const { email } = this.state;

    return (
      <AuthPageTemplate title={t.staticAsString('passwordRecovery.title')} testAnchorContainer='ForgotPassword'>
        <FormField>
          <TextField
            name='email'
            value={email}
            label={t.staticAsString('login.email')}
            requiredMark
            errorMessage={this.hasError('email')}
            placeholder={t.staticAsString('auth.emailExample')}
            onChange={this.onChange}
          />
          <EmailTip>{t('passwordRecovery.emailTip')}</EmailTip>
        </FormField>
        <Controls>
          <ButtonWrapper size='large' variant='primary' onClick={this.onSubmit} fullWidth ATN='submit'>
            {t('passwordRecovery.sendButton')}
          </ButtonWrapper>
        </Controls>
      </AuthPageTemplate>
    );
  }
}
