import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { Svg } from 'src/styledComponents/Icons';
import { BodyText } from 'src/styledComponents/Typography';

const StyleOption = styled.div<{ isMiles?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;

  padding: 0 24px;
  height: 56px;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.isMiles ? props.theme.miles.colors.dark.alpha['300'] : props.theme.main.colors.clay6)};
  }
`;

const StyledSvg = styled(Svg)`
  margin-right: 16px;
`;
type Props = {
  label: string;
  active: boolean;
  handleSelect: AnyFunction;
  svgName?: string;
  isMiles?: boolean;
  ATN: string;
};

export const Option: React.FC<Props> = (props) => {
  const { label, handleSelect, svgName, isMiles } = props;

  return (
    <StyleOption onClick={handleSelect} isMiles={isMiles} {...testAnchors.useField('auth', TEST_ANCHORS.fieldStructure.button, props.ATN)}>
      <Show if={Boolean(svgName)}>
        <StyledSvg name={svgName || ''} size={24} />
      </Show>
      <BodyText variant='body2'>{label}</BodyText>
    </StyleOption>
  );
};
