import { cloneDeep, isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { sportsOptionsStore } from 'modules/search/stores';
import * as React from 'react';
import styled from 'styled-components';

import { SmoothElevation } from 'src/styledComponents/Elevation';
import { scrollBarStyles } from 'src/styles';

import { useOnClickOutside } from 'utils';

import { Option } from './Option';

const MenuWrap = styled(SmoothElevation)<{ open: boolean }>`
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  z-index: 10;
  overflow: hidden;
  background-color: ${(props) => props.theme.main.colors.white};
  width: 378px;
  border-radius: 40px;

  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const Scroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  max-height: 293px;
  padding: 24px 0;

  ${scrollBarStyles}

  ::-webkit-scrollbar-thumb {
    height: 110px;
    border-top: 34px solid ${(props) => props.theme.main.colors.clay5};
    border-bottom: 34px solid ${(props) => props.theme.main.colors.clay5};
  }
`;

const Wrapper = styled.div``;

type Props = {
  open: boolean;
  value: DropdownItemType[];
  onChoose: (value: DropdownItemType) => AnyFunction;
  fieldName: string;
  setOpen: AnyFunction;
};

export const SportMenu: React.FC<Props> = (props) => {
  const { onChoose, open, value = [], fieldName, setOpen } = props;

  const ref = React.useRef(null);

  useOnClickOutside(
    ref,
    () => {
      if (!open) return;
      setOpen(false);
    },
    open,
  );

  const testAnchorContext = React.useContext(TestAnchorContext);
  return (
    <Observer>
      {() => {
        const options = sportsOptionsStore.value || [];
        if (isEmpty(options)) return null;

        return (
          <TestAnchorContext.Provider value={{ container: `${testAnchorContext.container}_${fieldName}` as TestAnchorContainers }}>
            <MenuWrap level={5} open={open} ref={ref}>
              <Scroll>
                <Wrapper>
                  {options.map((item) => (
                    <Option key={item.key} name={item.key} label={item.label} onChange={onChoose(item)} value={item} values={value} />
                  ))}
                </Wrapper>
              </Scroll>
            </MenuWrap>
          </TestAnchorContext.Provider>
        );
      }}
    </Observer>
  );
};
