import { cloneDeep, isEmpty } from 'lodash';
import { my_results } from 'modules/profile/constants';
import { resultStore } from 'modules/profile/stores';

import { ROUTER } from 'src/constants';

import { encodeQuery, getTimeResult } from 'utils';

import { form } from 'stores';

import { virtualStore } from '../stores';

import { MY_RESULTS_FORM } from '../../profile/constants';
import { VirtualDistanceMapped } from '../types';

export const getDistanceUrl = (distanceId: number, raceId: number, type: string): string => {
  return encodeQuery.generatePath(
    ROUTER.RACE_RESULT,
    {
      id: raceId,
    },
    {
      currentDistanceId: distanceId,
      currentDistanceType: type,
    },
  );
};

export const openResultForm = (racerId: number) => (): void => {
  const value = resultStore.virtual.find((item) => item.racers[0].id === racerId);

  resultStore.toggleEditResult(true);

  resultStore.setRacerId(racerId);
  resultStore.setSelected(value!);
};

export const openEditResult = (racerId: number) => (): void => {
  const value = resultStore.virtual.find((item) => item.racers[0].id === racerId);

  if (!value) {
    return;
  }

  resultStore.setRacerId(racerId);
  resultStore.setIsEdit(value.resultData);
  resultStore.setSelected(value);

  resultStore.toggleEditResult(true);
  form.merge(MY_RESULTS_FORM, value.resultData);
};

export const updateListInfo = (racerId: number, result: AnyObject) => {
  const copy: Array<AnyObject> = cloneDeep(virtualStore.list);
  const index = copy.findIndex((item) => item.racers[0].id === racerId);

  copy[index] = {
    ...copy[index],
    editMode: !isEmpty(copy[index].resultData),
    resultData: {
      ...copy[index].resultData,
      ...result,
      time_result: getTimeResult(result.start_time, result.finish_time),
    },
  };

  virtualStore.setVirtualResults(copy as VirtualDistanceMapped[]);
};

export const generateLinkToProfile = () => `${ROUTER.PROFILE}?tab=${my_results.id}`;
