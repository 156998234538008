import { isNaN } from 'lodash';
import moment from 'moment';
import Xregexp from 'xregexp';

import { TIME_PATTERN } from 'src/constants/patterns';

import { formatDigits, formatMillisecondsDigits } from './formatDigits';
import { formatHours } from './formatHours';

/*
 * NOTE
 * Tools for generating time mask 00:00:00 - 99:59:59
 * And conversion between diff(millis) and time mask
 */

/* NOTE
 * Calc millis number from two moment dates
 * to - from
 */
function calcDiff(from: moment.Moment, to: moment.Moment): number {
  const fromTime = from.valueOf();
  const toTime = to.valueOf();

  return toTime - fromTime;
}

/* NOTE
 * Generate (00:00:00 - 99:59:59) from milliseconds number(diff)
 * Reverse to diff
 */
function time(diff: number, showMillis: boolean = false, withPlus: boolean = false): string {
  const duration = moment.duration(diff);

  const hours = formatDigits(formatHours(Math.abs(duration.asHours())));
  const minutes = formatDigits(Math.abs(duration.minutes()).toString());
  const seconds = formatDigits(Math.abs(duration.seconds()).toString());

  const formattedMillis = formatMillisecondsDigits(Math.abs(duration.milliseconds()).toString());
  const millis = showMillis ? `.${formattedMillis}` : '';
  const minusChar = diff < 0 ? '- ' : '';
  // const minusChar = '';
  const plusChar = withPlus && !minusChar ? '+' : '';

  return `${plusChar}${hours}:${minutes}:${seconds}${millis}`;
}

/* NOTE
 * Parse timeDuration (00:00:00 - 99:59:59)
 * and generate milliseconds number(diff)
 * Reverse to time
 */
function diff(time: string): number | null {
  const match: {
    hours: string | null;
    minutes: string | null;
    seconds: string | null;
  } = Xregexp.exec(time, TIME_PATTERN) as any;

  if (!match) {
    return null;
  }

  const { hours, seconds, minutes } = match;

  const hour = Number(hours);
  const minute = Number(minutes);
  const second = Number(seconds);

  if (isNaN(hour) || isNaN(minute) || isNaN(second)) {
    return null;
  }

  return ((hour * 60 + minute) * 60 + second) * 1000;
}

/*
 * Rounding
 */
function roundDiff(value: number): number {
  return Math.round(value / 1000) * 1000;
}

export const duration = {
  calcDiff,
  diff,
  time,
  roundDiff,
};
