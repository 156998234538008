import { Observer } from 'mobx-react';
import { AvatarModalComponent, useLoadPreviewAvatar, changeProfilePicture } from 'modules/Shared/AvatarAccountUpload';
import { useHeaderSettings } from 'modules/header';
import { HEADER_HEIGHT } from 'modules/header/constants';
import { HeaderVariants } from 'modules/header/types';
import { ResultsFormModal } from 'modules/profile/containers/ResultsFormModal';
import React, { FC, useEffect, useState } from 'react';
import Swipe from 'react-easy-swipe';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/topographic-pattern.svg';
import { RESOLUTIONS } from 'src/constants';
import { ROUTER, PROFILE_TABS } from 'src/constants';
import { PageContent } from 'src/styledComponents/PageContent';

import { encodeQuery, history, useWindowSize } from 'utils';

import { ProfileInfo, TabsComponent, ActionModal } from '../components';

import { profileService } from '../services';

import { tabStore } from '../stores';

import { TabsWrapper } from './';

const Wrapper = styled(PageContent)`
  padding-top: ${HEADER_HEIGHT}px;

  .section-content {
    padding: 0;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: ${HEADER_HEIGHT + 20}px 0 64px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    display: grid;
    grid-template-columns: 302px auto;

    & > div:last-child {
      margin-left: 48px;
      overflow-x: hidden;
    }
  }
`;

const Content = styled.div`
  position: relative;

  .profile-tabs {
    a {
      padding-bottom: 24px;
    }

    li {
      padding: 0 14px;
      &:first-child {
        padding: 0 14px 0 0;
      }
    }

    a:hover,
    a.active {
      background: none;
    }
  }

  > div {
    overflow-y: hidden;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    .spinner {
      background-image: url('${PatternImage}');
      background-color: ${(props) => props.theme.main.colors.clay6};
    }
  }
`;

const SwipeContainer = styled.div<{ isSwipeEnd: boolean }>`
  position: absolute;
  background: ${(props) => props.theme.main.colors.white};
  padding: 0;
  max-width: 100%;
  min-height: calc(100vh - 304px);
  border-radius: 8px 8px 0 0;
  left: 0;
  right: 0;
  top: -250px;

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 8px 0 24px;
  }

  .profile-tabs {
    padding: 0 0 0 20px;
  }
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

const BaseContainer: FC = () => {
  useHeaderSettings({ variant: HeaderVariants.normal });

  const [offsetSwipeContainer, setOffsetSwipeContainer] = useState(0);
  const [isSwipeEnd, setIsSwipeEnd] = useState(false);
  const isMobile = useWindowSize();
  const { imagePreviewUrl, isShowAvatarModal, closeModal, handleChangeFile } = useLoadPreviewAvatar();
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { tab } = encodeQuery.parseQuery(history.location.search);

    if (tab && PROFILE_TABS.includes(tab)) {
      tabStore.changeActiveTab(tab);
    } else {
      tabStore.changeActiveTab('my_results');
      history.push('/myProfile?tab=my_results');
    }

    profileService.initializeProfile();
  }, []);

  const changeActiveTab = (active: string) => {
    tabStore.changeActiveTab(active);
    profileService.loadActiveTab();
  };

  const handleClickSettings = () => {
    history.push(ROUTER.SETTINGS);
  };

  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value + offsetSwipeContainer}px, 0)`);

    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, 250px, 0)`);
    setOffsetSwipeContainer(250);
    setIsSwipeEnd(true);
  };

  const handleSwipeUp = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, 0, 0)`);
    setOffsetSwipeContainer(0);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const changePreviewUrl = async (image: string) => {
    await changeProfilePicture(image);
  };

  return (
    <TestAnchorContext.Provider value={{ container: 'Profile' }}>
      <Observer>
        {() => {
          return (
            <Wrapper className='profile'>
              <ActionModal />
              <ResultsFormModal />
              <AvatarModalComponent
                open={isShowAvatarModal}
                src={imagePreviewUrl}
                changePreviewUrl={changePreviewUrl}
                closeModal={closeModal}
              />
              <Container className='profile-container'>
                <ProfileInfo handleRedirect={handleClickSettings} onChangeImage={handleChangeFile} />

                <Content>
                  {isMobile ? (
                    <SwipeContainer ref={ref} isSwipeEnd={isSwipeEnd}>
                      <Swipe
                        className='swipe-zone'
                        onSwipeMove={handleMove}
                        onSwipeDown={handleSwipeDown}
                        onSwipeUp={handleSwipeUp}
                        onSwipeStart={handleSwipeStart}
                      >
                        <DraggableStroke />
                      </Swipe>
                      <TabsComponent handleClick={changeActiveTab} />
                      <TabsWrapper />
                    </SwipeContainer>
                  ) : (
                    <div>
                      <TabsComponent handleClick={changeActiveTab} />
                      <TabsWrapper />
                    </div>
                  )}
                </Content>
              </Container>
            </Wrapper>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};

export const Base = withRouter(BaseContainer);
