import classNames from 'classnames';
import * as React from 'react';

type Props = {
  activeTab: boolean;
  label: string;
  onClick: Function;
};

class InfoTab extends React.Component<Props> {
  onClickTab = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClickTab,
      props: { activeTab, label },
    } = this;

    return (
      <li className={classNames('race-more-info-item', { active: activeTab })} onClick={onClickTab}>
        {label}
      </li>
    );
  }
}

export { InfoTab };
