import { isEmpty, each } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Dropdown } from 'src/styledComponents/Dropdown';

import { withProgressSpinner } from 'hocs';

import { Show } from 'components/condition';
import { Input } from 'components/core/input';
import { Close } from 'components/icons';

import { validation, Errors, generateGenderOptions, t } from 'utils';

import { countriesStore, manageTeamStore, errorsStore, progressStore } from 'stores';

import { ADD_TEAM_MEMBER, MANAGE_TEAM_RACE, EDIT_TEAM_MEMBER } from '../constants';

import { manageTeamService } from '../services';

import { distancesStore } from '../stores';

import { generateValidation } from '../validation';

type Props = {
  match: AnyObject;
  userFields?: AnyObject;
  teamId?: number;
  raceId: number;
};

type State = {
  fields: {
    firstname: string;
    lastname: string;
    email: string;
    gender: genderValue | null;
    country_id: number | null;
    nationality_id: number | null;
    raceId: number;
  };
  sentInvitation: boolean;
};

// TODO, move all methods to actions
@withProgressSpinner(MANAGE_TEAM_RACE)
@observer
class MemberFormContainer extends React.Component<Props, State> {
  state = {
    fields: {
      firstname: '',
      lastname: '',
      email: '',
      gender: null,
      country_id: null,
      nationality_id: null,
      raceId: 0,
    },
    sentInvitation: false,
  };

  fieldsErrorHandler: Errors;

  constructor(props: Props) {
    super(props);

    this.fieldsErrorHandler = new Errors(ADD_TEAM_MEMBER);
  }

  UNSAFE_componentWillMount() {
    const { userFields } = this.props;

    this.syncParams(userFields);
  }
  // It is not allowed to use shouldComponentUpdate in observer based components
  // ToDo: Update the component's logic when it will be used

  //   shouldComponentUpdate(nextProps: Props, nextState: State) {
  //     const { userFields } = this.props;
  //     const emptyPrev = isEmpty(toJS(userFields));
  //     const emptyNext = isEmpty(toJS(nextProps.userFields));
  //
  //     if (emptyPrev && !emptyNext) {
  //       this.syncParams(nextProps.userFields);
  //     }
  //
  //     return true;
  //   }

  cleanForm = () => {
    manageTeamStore.changeSelectedMember({});
    errorsStore.clearError(ADD_TEAM_MEMBER);
    errorsStore.clearError(EDIT_TEAM_MEMBER);
  };

  syncParams = (userFields?: Object) => {
    if (!isEmpty(userFields)) {
      this.setState((prevState) => ({
        fields: {
          firstname: (userFields as any)!.firstname,
          lastname: (userFields as any)!.lastname,
          email: (userFields as any)!.email,
          gender: (userFields as any)!.gender,
          country_id: ((userFields as any)!.country || {}).id,
          nationality_id: ((userFields as any)!.nationality_id || {}).id,
          raceId: prevState.fields.raceId,
        },
      }));
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
    });
  };

  handleSelectChange = (name: string, value: any) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value.id,
      },
    });
  };

  hasError = (name: string): string => {
    return this.fieldsErrorHandler.hasError(name);
  };

  handleClose = () => {
    if (manageTeamStore.showAddModal) {
      manageTeamStore.closeAddModal();
    }

    this.cleanForm();
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const userFields = manageTeamStore.selectedMember;

    if (isEmpty(userFields)) {
      this.handleAdd();
    } else {
      this.handleEdit(userFields);
    }
  };

  handleAdd = async () => {
    const { fields } = this.state;
    const { teamId, raceId } = this.props;
    const [isCountrySelected, isCountryRequired] = this.isCountry();
    const isCountry = isCountrySelected && isCountryRequired;
    const [isNationalitySelected, isNationalityRequired] = this.isNationality();
    const isNationality = isNationalitySelected && isNationalityRequired;

    const valid = validation(ADD_TEAM_MEMBER, fields, generateValidation({ isCountry, isNationality }));

    if (!valid) {
      return;
    }

    const params = {
      ...fields,
      gender: +fields.gender!,
    };

    if (!isCountrySelected) {
      // !The operand of a 'delete' operator must be optional.
      // @ts-ignore
      delete params.country_id;
    }

    const [status] = await manageTeamService.addTeamMember(params, teamId!, raceId);

    if (status) this.handleClose();
  };

  handleEdit = async (initialValues: AnyObject) => {
    const { fields } = this.state;
    const { teamId, raceId } = this.props;
    const [isCountrySelected, isCountryRequired] = this.isCountry();
    const isCountry = isCountrySelected && isCountryRequired;
    const [isNationalitySelected, isNationalityRequired] = this.isNationality();
    const isNationality = isNationalitySelected && isNationalityRequired;

    const valid = validation(ADD_TEAM_MEMBER, fields, generateValidation({ isCountry, isNationality }));

    if (!valid) {
      return;
    }

    let params = {};

    each(fields, (value, key) => {
      if (initialValues[key] !== value) {
        params = {
          ...params,
          [key]: value,
        };
      }
    });

    if (!isCountrySelected) {
      // Property 'country_id' does not exist on type
      // @ts-ignore
      delete params.country_id;
    }

    const [status] = await manageTeamService.editTeamMember(params, teamId!, raceId, initialValues.id);

    if (status) this.handleClose();
  };

  isCountry(): [boolean, boolean] {
    const { selectedDistance } = distancesStore;
    const countryField = selectedDistance && selectedDistance.findRegistrationField('country_id');

    if (!countryField) {
      return [false, false];
    }

    return [!!countryField.selected, !!countryField.required];
  }

  isNationality(): [boolean, boolean] {
    const { selectedDistance } = distancesStore;
    const nationalityField = selectedDistance && selectedDistance.findRegistrationField('nationality_id');

    if (!nationalityField) {
      return [false, false];
    }

    return [!!nationalityField.selected, !!nationalityField.required];
  }

  getFormTitle = (): string => {
    const { userFields } = this.props;
    const isEdit = manageTeamStore.isActiveUserEdit && !manageTeamStore.readOnlyMode;

    if (isEdit && !userFields?.is_owner) {
      return 'editTeamMemberTitle';
    }

    if (isEdit && userFields?.is_owner) {
      return 'editTeamLeaderTitle';
    }

    if (!isEdit && userFields?.is_owner) {
      return 'viewTeamLeaderTitle';
    }

    if (!isEdit && !userFields?.is_owner) {
      return 'viewTeamMemberTitle';
    }

    return 'addTeamMemberTitle';
  };

  render() {
    const {
      fields: { firstname, lastname, email, gender, country_id },
    } = this.state;

    if (progressStore.isLoading(MANAGE_TEAM_RACE)) {
      return null;
    }

    const countries = countriesStore.valuesForDropdown();
    const isEdit = !isEmpty(manageTeamStore.selectedMember);
    const [isCountrySelected, isCountryRequired] = this.isCountry();
    const titleIntlId = this.getFormTitle();
    let disableField = !manageTeamStore.isTeamOwner;

    if (manageTeamStore.isActiveUserEdit) {
      disableField = false;
    }

    if (manageTeamStore.readOnlyMode) {
      disableField = true;
    }

    const inviteButtonText = isEdit ? 'save' : 'invite';
    const genderOptions = generateGenderOptions();
    const activeSaveBtn = !manageTeamStore.readOnlyMode && (manageTeamStore.isTeamOwner || manageTeamStore.isActiveUserEdit);

    return (
      <form onSubmit={this.handleSubmit}>
        <div className='manage-member'>
          <div className='btn-close' onClick={this.handleClose}>
            {Close}
          </div>
          <p className='section-title'>{t(`manageTeam.${titleIntlId}` as TranslationLockedKeys)}</p>
          <Input
            name='firstname'
            placeholder={t.staticAsString('settings.firstname')}
            label={t.staticAsString('settings.firstname')}
            onChange={this.handleChange}
            value={firstname}
            errorMessage={this.hasError('firstname')}
            disabled={disableField}
            // ! required is not supported by the underline Input component.
            // TODO Figure out what to do with required prop.
            // required
            requiredMark
          />
          <Input
            name='lastname'
            placeholder={t.staticAsString('settings.lastname')}
            label={t.staticAsString('settings.lastname')}
            onChange={this.handleChange}
            value={lastname}
            errorMessage={this.hasError('lastname')}
            disabled={disableField}
            // required
            requiredMark
          />
          <Input
            name='email'
            placeholder={t.staticAsString('settings.email')}
            label={t.staticAsString('settings.email')}
            onChange={this.handleChange}
            errorMessage={this.hasError('email')}
            value={email}
            disabled={disableField}
            // required
            requiredMark
          />

          <Dropdown
            id='gender_select'
            name='gender'
            label={t.staticAsString('settings.gender')}
            placeholder={t.staticAsString('settings.gender')}
            value={gender}
            onChange={(value) => this.handleSelectChange('gender', value)}
            errorMessage={this.hasError('gender')}
            disabled={disableField}
            options={() => genderOptions}
            // required
            requiredMark
          />
          <Show if={isCountrySelected}>
            <Dropdown
              id='country_id_select'
              name='country_id'
              label={t.staticAsString('settings.country')}
              placeholder={t.staticAsString('settings.country')}
              value={+country_id!}
              options={() => countries}
              onChange={(value) => this.handleSelectChange('country_id', value)}
              errorMessage={this.hasError('country_id')}
              required={isCountryRequired}
              requiredMark={isCountryRequired}
              disabled={disableField}
            />
          </Show>
          <Show if={activeSaveBtn}>
            <button className='btn btn-submit' type='submit'>
              {t(`manageTeam.${inviteButtonText}` as TranslationLockedKeys) as any}
            </button>
          </Show>
          <Show if={disableField}>
            <button className='btn btn-submit' type='button' onClick={this.handleClose}>
              {t('manageTeam.done')}
            </button>
          </Show>
        </div>
      </form>
    );
  }
}

export { MemberFormContainer };
