import download from 'downloadjs';

import { Race } from 'models';

import { exportJobService } from 'services';

import { loadProgress } from 'stores';

import { monitorJobId } from '../utils';

import { tabStore } from '../stores';

const exportReceipt = async (type: string, order_id: number): Promise<void> => {
  const params = {};
  const [isOk, response] = await exportJobService.exportRegistered(type, order_id, params);
  if (!isOk) return;
  const distance = tabStore.values.find((el) => el.order?.id === order_id);
  const name = distance.name;
  const { id } = response.data.data;
  const estimatedLoadingTime = 3;
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName: `${name}.${type}`,
    onRetry: () => exportReceipt(type, order_id),
  });
  const [monitorStatus, url] = await monitorJobId(id);
  if (monitorStatus) {
    download(`${url}`);
  }
};

const exportBib = async (type: string, distanceId: number, racerId: number): Promise<void> => {
  const params = {};
  const [isOk, response] = await exportJobService.exportBib(type, racerId, params);
  if (!isOk) return;
  const distance: DistanceType = tabStore.values.find((el: DistanceType) => el.id === distanceId);
  // TODO TS, something horrible happend in here. Please check
  // @ts-ignore
  const { id } = response.data.data;
  const estimatedLoadingTime = 3;
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName: `${distance.name}.${type}`,
    onRetry: () => exportReceipt(type, distanceId),
  });
  const [monitorStatus, url] = await monitorJobId(id);
  if (monitorStatus) {
    download(`${url}`);
  }
};

const exportDiploma = async (type: string, distanceId: number, racerId: number): Promise<void> => {
  const params = {};
  const [isOk, response] = await exportJobService.exportDiploma(type, racerId, params);
  if (!isOk) return;
  const distance: DistanceType = tabStore.values.find((el: DistanceType) => el.id === distanceId);
  // TODO TS, something horrible happend in here. Please check
  // @ts-ignore
  const { id } = response.data.data;
  const estimatedLoadingTime = 3;
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName: `${distance.name}.${type}`,
    onRetry: () => exportReceipt(type, distanceId),
  });
  const [monitorStatus, url] = await monitorJobId(id);
  if (monitorStatus) {
    download(`${url}`);
  }
};

export { exportReceipt, exportBib, exportDiploma };
