import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { NoResultsComponent } from 'components/core/noResults';
import { PageTitleComponent } from 'components/core/pageTitle';

import { htmlSanitizer, t } from 'utils';

import { articlesStore } from 'stores';

type Props = {
  handleRedirect: (id: number) => void;
};

@observer
export class ArticleInfo extends React.Component<Props> {
  render() {
    const { handleRedirect } = this.props;
    const article = articlesStore.modelValues[0];
    const value = article?.value || {};

    return (
      <div className='col-12 col-lg-6 article-block'>
        <PageTitleComponent title='navigation.newsFeed' />
        <div className='feed'>
          <p className='feed-desc'>{t('profile.userArticleInfo')}</p>
          {isEmpty(value) ? (
            <NoResultsComponent message={t('profile.noArticlesYet')} withoutFlag />
          ) : (
            <div className='feed-card' onClick={() => handleRedirect(value.id)}>
              <div
                className='feed-card-img'
                style={{
                  backgroundImage: `url(${(value && value.image) || ''})`,
                }}
              />
              <div className='feed-card-desc'>
                <p className='feed-card-desc-title'>{article?.title}</p>
                <div className='feed-card-desc-text'>
                  <div
                    className='article-content'
                    dangerouslySetInnerHTML={{
                      __html: htmlSanitizer(article.description || ''),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
