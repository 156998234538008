import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Svg } from 'src/styledComponents/Icons';

type Props = {
  isOpen: boolean;
  onClick: AnyFunction;
};

export const FilterIcon = styled(Svg)`
  min-width: 32px;

  @media (min-width: ${RESOLUTIONS.large}px) {
    display: none;
  }
`;

const FiltersLabel = ({ isOpen, onClick }: Props) => <FilterIcon className='filters-label' onClick={onClick} name='Controls' size={32} />;

export { FiltersLabel };
