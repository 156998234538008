import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const GraphIncreaseAnalyticsPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M22.4966 2.49608L21.0325 7.96019L20.1665 8.46019L18.6692 5.86675L2.30163 15.3166L1.30163 13.5845L17.6692 4.1347L16.1665 1.53198L17.0325 1.03198L22.4966 2.49608ZM22 11H16V23.25H22V11ZM14.5 15H8.5V23.25H14.5V15ZM7 19H1V23.25H7V19Z'
    fill='currentColor'
  />
);

export const GraphIncreaseAnalytics = (props: IconProps) => <SvgIcon path={GraphIncreaseAnalyticsPath} {...props} />;
