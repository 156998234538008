import { CountryModel } from 'models';

import { countriesStore } from 'stores';

function getCountryByLocation(location?: LocationType): CountryModel | undefined {
  if (!location) {
    return undefined;
  }

  return countriesStore.modelValues.find((country) => country.value.alpha_2_code === location.country_code);
}

export { getCountryByLocation };
