import { manageTeamStore } from 'stores';

import { LoadDistances } from './loadDistances';
import { ManageTeam } from './manageTeam';

const manageTeamService = new ManageTeam(manageTeamStore);
const loadDistancesService = new LoadDistances();

export { manageTeamService, loadDistancesService };
export * from './manageTeam';
export * from './loadDistances';
