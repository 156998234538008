import styled, { css } from 'styled-components';

import { gradientAnimation } from 'styles';

import { BaseSkeletonProps } from './Skeleton';

const defaultProps = {
  width: '100%',
  height: 'auto',
  borderRadius: '4px',
};

const TextSkeleton = styled.div<BaseSkeletonProps>`
  width: ${(props) => props.width || defaultProps.width};
  height: ${(props) => props.height || defaultProps.height};
  background: ${(props) => props.theme.main.colors.clay5};

  border-radius: ${defaultProps.borderRadius};
  margin-top: 0;
  margin-bottom: 0;
  transform-origin: 0 55%;
  transform: scale(1, 0.6);

  &:empty:before {
    content: '\\00a0';
  }

  ${(props) =>
    props.hasChildren &&
    css`
      & > * {visibility: hidden;},
    `}

  ${(props) =>
    props.hasChildren &&
    !props.height &&
    css`
      height: auto;
    `}

    ${(props) =>
    props.hasChildren &&
    !props.width &&
    css`
      max-width: fit-content;
    `}
  ${({ animation }) => animation == 'gradient' && gradientAnimation}
`;

export default TextSkeleton;
