import { each, merge } from 'lodash';
import { observable, computed, action, makeObservable } from 'mobx';

import { Wave as WaveModel } from 'models';

class Waves {
  @observable values: WaveType[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get modelValues() {
    const { values } = this;

    return values.map<WaveModel>((el) => new WaveModel(el));
  }

  @action
  async setValues(values: WaveType[]) {
    this.values = values;
  }

  @action
  async cleanValues() {
    this.values = [];
  }

  findWavesByDistance(distanceId: number): WaveModel[] {
    const { modelValues } = this;
    return modelValues.filter((model) => model.value.distance_id === distanceId);
  }

  find(id: number | string | null): WaveModel | null {
    return this.expandedWaves[id || ''];
  }

  // For show all distances
  @computed
  get distinctWaves(): Array<WaveModel> {
    const { modelValues } = this;
    const similarValuesTable: { [K in string]: WaveModel[] } = {};

    modelValues.forEach((model) => {
      const hash = model.hashForCompare;
      similarValuesTable[hash] = [...(similarValuesTable[hash] || []), model];
    });

    const distinctWaves: AnyObject[] = [];

    each(similarValuesTable, (models: WaveModel[], hash: string) => {
      if (!models.length) {
        return;
      }

      const ids = models.map((model) => model.value.id);
      const serializedIds = WaveModel.serializeSimilarClassIds(ids);
      const distinctModel = new WaveModel({ ...models[0].value }, serializedIds);

      distinctWaves.push(distinctModel);
    });

    return distinctWaves as WaveModel[];
  }

  @computed
  get expandedWaves(): { [K in number]: WaveModel } {
    const { modelValues } = this;
    return modelValues.reduce((acc, model) => {
      const id = model.value.id;
      return merge(acc, { [id]: model });
    }, {});
  }
}

export { Waves, Waves as WavesStore };
