import { Observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { Show } from 'components/condition';

import { sessionStore } from 'stores';

import { DefaultAvatar } from '../Icon/icons/DefaultAvatar';

const StyledAvatar = styled.div<{ url: string; size: number }>`
  box-sizing: border-box;
  border-radius: 50%;
  background-image: url('${(props) => props.url}');
  background-size: contain;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  :active,
  :hover {
    cursor: pointer;
  }
`;

const WrapperAvatar = styled.div<{ size: number }>`
  outline: 4px solid rgba(255, 255, 255, 0.08);
  display: flex;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

type Props = {
  size: number;
  onClick?: AnyFunction;
};

export const Avatar = (props: Props) => {
  const { size, onClick } = props;
  return (
    <Observer>
      {() => {
        const avatarUrl = sessionStore.session.user.avatar || '';
        return (
          <>
            <Show if={Boolean(avatarUrl)}>
              <StyledAvatar onClick={onClick} url={avatarUrl} size={size} className='avatar' />
            </Show>
            <Show if={!Boolean(avatarUrl)}>
              <WrapperAvatar size={size}>
                <DefaultAvatar onClick={onClick} size={size} className='avatar' />
              </WrapperAvatar>
            </Show>
          </>
        );
      }}
    </Observer>
  );
};
