import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Container = styled.div`
  width: 100%;

  .table-head {
    display: flex;
  }

  .table-body {
    .table-row {
      display: flex;
      width: 100%;

      .table-cell {
        .source-button {
          width: fit-content;
        }
      }
    }
  }
`;

export const Wrapper = (props: Props) => {
  const { children, className } = props;

  return <Container className={classNames('table-wrapper', className)}>{children}</Container>;
};
