import axios from 'axios';
import { flatten, compact, reverse } from 'lodash';
import { generatePath } from 'react-router-dom';

import { LOAD_PAYMENTS, API_URL, API_V2_URL, API_DISTANCE_V2, API_DISTANCES, API_PAYMENT } from 'src/constants';

import { action, request, SearchSerializer } from 'utils';

import { PaymentTrackerStore } from 'stores';

class PaymentTracker {
  store: PaymentTrackerStore;

  constructor(store: PaymentTrackerStore) {
    this.store = store;
  }

  // Load distance - ONE distance
  @request({ action: LOAD_PAYMENTS })
  loadDistanceRequest(raceId: number, distanceId: number): any {
    const url = generatePath(API_V2_URL + API_DISTANCE_V2, { id: distanceId });
    return axios.get(url);
  }

  // Load distances - MULTIPLE distance
  @request({ action: LOAD_PAYMENTS })
  loadDistancesRequest(raceId: number, params: Object): any {
    const url = generatePath(API_URL + API_DISTANCES, { id: raceId });
    return axios.get(url, { params });
  }

  async request(raceId: number, ids: any): Promise<any> {
    let response, status;
    const params = new SearchSerializer({ search: { id: ids }, handlers: { id: 'in' } }).params();

    if (ids.length > 1) {
      [status, response] = await this.loadDistancesRequest(raceId, params);
    }

    if (ids.length === 1) {
      [status, response] = await this.loadDistanceRequest(raceId, ids[0]);
    }

    if (!status) {
      return [];
    }

    return compact(flatten([response?.data?.data]));
  }

  // NOTE,
  // Load payments by distance or ditances
  @action({ action: LOAD_PAYMENTS })
  async loadDistancesPayments(raceId: number, ...ids: number[]) {
    const distances: DistanceType[] = (await this.request(raceId, ids)) || [];
    const rawPayments = distances.map((distance) => distance.registrations);

    const payments = reverse(compact(flatten(rawPayments)));

    await this.store.setPayments(...payments);
  }

  @request({ action: LOAD_PAYMENTS })
  loadPaymentByTokenRequest(token: string): any {
    const url = generatePath(API_URL + API_PAYMENT, { token });
    return axios.get(url);
  }

  @action({ action: LOAD_PAYMENTS })
  async loadPaymentByToken(token: string) {
    const [status, response] = await this.loadPaymentByTokenRequest(token);
    const payment = response.data.payment;

    if (!status || !payment) {
      return;
    }

    await this.store.setPayments(payment);
  }
}

export { PaymentTracker, PaymentTracker as PaymentTrackerService };
