import axios from 'axios';

import { API_URL, API_TRANSLATIONS, TRANSLATIONS_VERSION } from 'src/constants';

import { MESSAGES_VERSION_KEY } from 'utils/messages/constants';
import { action, request } from 'utils/mobx';
import { translationsUrl } from 'utils/translations.url';

const loadAction = 'LOAD_TRANSLATIONS';

function generateTranslationsService() {
  class Translations {
    @request({ action: loadAction })
    fetchRequest(locale: availableLocales): any {
      return axios.get(translationsUrl() + locale + '.json', {
        headers: {
          'Cache-Control': 'no-cache',
          'translation-version': localStorage.getItem(MESSAGES_VERSION_KEY) || Math.random(),
        },
      });
    }

    @action({ action: loadAction })
    async fetch(locale: availableLocales): Promise<[number, null | ApiTranslations]> {
      const [status, response] = await this.fetchRequest(locale);

      const version = Number(localStorage.getItem(MESSAGES_VERSION_KEY)) ?? 0;

      if (!status) {
        return [version, null];
      }

      return [version, response.data];
    }
  }

  return new Translations();
}

export { generateTranslationsService };
