import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_URL, API_DISTANCES } from 'src/constants';

import { request, action, SearchSerializer } from 'utils';

import { LOAD_DISTANCES } from '../constants';

import { distancesStore, DistancesStore } from '../stores';

class LoadDistances {
  store: DistancesStore = distancesStore;
  handlers: AnyObject = { id: 'in' };

  @request({ action: LOAD_DISTANCES })
  loadRequest(raceId: number, params: FiltersType): Promise<any> {
    const url = generatePath(API_URL + API_DISTANCES, { id: raceId });

    return axios.get(url, { params });
  }

  @action({ action: LOAD_DISTANCES })
  async load(raceId: number, distanceIds: Array<number>) {
    const filters = this.filters(distanceIds);
    const [status, response] = await this.loadRequest(raceId, filters);

    if (status) {
      this.store.addValues(response.data.data, response.data.paginationMeta, {});
    }
  }

  filters(ids: any): any {
    const filters = new SearchSerializer({
      search: { id: ids },
      handlers: this.handlers,
    }).params();

    return {
      ...DistancesStore.defaultFilters,
      ...filters,
    };
  }
}

export { LoadDistances, LoadDistances as LoadDistancesService };
