import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { Observer } from 'mobx-react';
import { openModalStore } from 'modules/auth/stores';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Swipe from 'react-easy-swipe';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Button } from 'src/styledComponents/Button';
import { BodyText, Utility } from 'src/styledComponents/Typography';

import { CloseIcon } from 'components/icons';
import { Modal } from 'components/modal';

import { appEnvControl, blockBodyScroll, t, unblockBodyScroll, useOnClickOutside } from 'utils';

import { windowSize } from 'stores';

type Props = {
  open: boolean;
};

const Wrapper = styled.div<{ isSwipeEnd: boolean }>`
  background-color: ${(props) => props.theme.main.colors.white};
  width: 100%;
  padding: 0 20px 24px;
  border-radius: 24px 24px 0 0;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    width: 800px;
    padding: 32px 40px 40px;
    border-radius: 24px;
  }

  ${({ isSwipeEnd }) => isSwipeEnd && 'transition: 0.5s;'}

  .swipe-zone {
    padding: 20px 0 16px;
  }

  .loading-overlay {
    .spinner {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const DraggableStroke = styled.div`
  background: ${(props) => props.theme.main.colors.clay5};
  border-radius: 10px;
  width: 53px;
  height: 4px;
  margin: 0 auto;
`;

const TitleUtility = styled(Utility)`
  font-size: 24px;
  line-height: 28px;
  text-transform: none;
`;

const StyledUtility = styled(Utility)`
  margin: 12px auto;
  font-size: 16px;
  line-height: 20px;
  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin: 14px 22px 0 0;
    font-size: 24px;
    line-height: 28px;
  }
`;

const Content = styled.div`
  margin: 5px 0 28px;
  display: flex;
  justify-content: center;
  position: relative;

  .close-search {
    position: absolute;
    right: 0;
    top: -4px;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 32px;
    width: 32px;
    background: ${(props) => props.theme.main.colors.clay5};
    border-radius: 32px;

    svg {
      height: 11px;
      width: 11px;

      polygon:first-child {
        fill: ${(props) => props.theme.main.colors.clay3};
        fill-opacity: 1;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const StyledBodyText = styled(BodyText)`
  margin-top: 6px;
  text-align: center;
  @media (min-width: ${RESOLUTIONS.medium}px) {
    margin-top: 12px;
    text-align: left;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 28px auto 0;
  width: 100%;

  button {
    span {
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
    }
  }
  @media (min-width: ${RESOLUTIONS.medium}px) {
    flex-direction: row;
    width: 506px;
    margin: 28px auto 13px;
  }
`;

const DialogHelperModal = ({ open }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSwipeEnd, setIsSwipeEnd] = useState(false);
  const onClose = () => {
    openModalStore.off();
  };
  const handleMove = ({ y: value }: { y: number }) => {
    if (!ref.current || value < 0) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${value}px, 0)`);
    return true;
  };

  const handleSwipeDown = () => {
    if (!ref.current) return;

    ref.current.style.setProperty('transform', `translate3d(0, ${ref.current.clientHeight}px, 0)`);
    setIsSwipeEnd(true);
  };

  const handleSwipeStart = () => {
    setIsSwipeEnd(false);
  };

  const handleTransitionEnd = () => {
    if (isSwipeEnd) onClose();
  };

  const handleLoginAsParticipant = () => {
    amplitude.track('OrganizerHelperDialog.PressParticipantButton');
    onClose();
  };
  const handleLoginAsOrganizer = () => {
    amplitude.track('OrganizerHelperDialog.PressOrganizerButton');
    window.location.href = appEnvControl.generateUrlByEnv();
  };

  useOnClickOutside(ref, () => {
    onClose();
  });

  useEffect(() => {
    blockBodyScroll();
    return () => {
      unblockBodyScroll();
    };
  }, []);

  return (
    <Observer>
      {() => {
        const isMobile = !windowSize.isGreaterThan('medium');

        return (
          <Modal open={open}>
            <Wrapper ref={ref} isSwipeEnd={isSwipeEnd} onTransitionEnd={handleTransitionEnd}>
              {isMobile && (
                <Swipe className='swipe-zone' onSwipeMove={handleMove} onSwipeDown={handleSwipeDown} onSwipeStart={handleSwipeStart}>
                  <DraggableStroke />
                </Swipe>
              )}

              <Content>
                <TitleUtility variant={'u1'} weight={'bold'}>
                  {t.staticAsString('login.modal.title')}
                </TitleUtility>

                {!isMobile && (
                  <div onClick={onClose} className={classNames('close-search')}>
                    {CloseIcon}
                  </div>
                )}
              </Content>

              <Controls>
                <div>
                  <Button variant='primary' size='medium' onClick={handleLoginAsOrganizer} fullWidth={isMobile}>
                    {t.staticAsString('login.modal.organizer')}
                  </Button>
                  <StyledBodyText variant={'body2'}>{t.staticAsString('login.modal.organizerHelperText')}</StyledBodyText>
                </div>
                <StyledUtility variant={'u1'} weight={'bold'}>
                  {t.staticAsString('login.modal.or')}
                </StyledUtility>
                <div>
                  <Button variant='primary' size='medium' onClick={handleLoginAsParticipant} fullWidth={isMobile}>
                    {t.staticAsString('login.modal.participant')}
                  </Button>
                  <StyledBodyText variant={'body2'}>{t.staticAsString('login.modal.participantHelperText')}</StyledBodyText>
                </div>
              </Controls>
            </Wrapper>
          </Modal>
        );
      }}
    </Observer>
  );
};

export { DialogHelperModal };
