import { Observer } from 'mobx-react';
import * as React from 'react';

import { intercom } from 'utils';

import { sessionStore, localeStore } from 'stores';

// ! Scheduled for removal after 7.1.1 release, replaced by the HubSpot integration
// ! Look at the frontend/web/src/stores/hubstop.ts

function Controller() {
  intercom.boot();
  intercom.updateIntercomUser();
  intercom.updateIntercomLocale();

  return null;
}

function IntercomController() {
  return (
    <Observer>
      {() => {
        sessionStore.touchUser();
        localeStore.touchLocale();

        return <Controller />;
      }}
    </Observer>
  );
}

export { IntercomController };
