import { omit } from 'lodash';
import { action, observable, toJS, makeObservable } from 'mobx';

import { t } from 'utils';

import { LCoupon } from '../types';

type Value = {
  [k in string]: LCoupon | nil;
};

type InvalidValue = { [k in string]: string[] | null };

type ChangedFieldsArr = string[];

class CouponsStore {
  /**
   * @description
   * Loaded coupon code data. { '#Khhsaj': {...Coupon object} }
   */
  @observable
  value: Value = {};

  /**
   * @description
   * List of invalid coupon codes with provided error - { '#Khhsaj': 'There is no such coupon' }
   */
  @observable
  invalidValues: InvalidValue = {};

  /**
   * @description
   * array of changed fields ordered in the change way
   * ['coupon.1', 'coupon.2',  'coupon.4',  'coupon.3']
   */
  @observable
  changedCouponFields: ChangedFieldsArr = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setValue(value: Value) {
    this.value = value;
  }

  @action
  init(fieldId: string, value: LCoupon) {
    if (this.howManyCouponsLeft(value) < 1) {
      this.initInvalid(fieldId, [t.staticAsString('registration.notEnoughCouponQty')]);
      return;
    }
    this.value[fieldId] = value;
    this.invalidValues = omit(this.invalidValues, fieldId);
  }

  @action
  initInvalid(fieldId: string, error: string[]) {
    this.invalidValues[fieldId] = error;
    this.value = omit(this.value, fieldId);
    this.changedCouponFields.push(fieldId);
  }

  @action
  logFieldChange(fieldId: string) {
    this.changedCouponFields.push(fieldId);
  }

  @action
  clearInvalidValueOnCouponFieldChange(fieldId: string) {
    this.invalidValues = omit(this.invalidValues, fieldId);
  }

  @action
  clear() {
    this.value = {};
    this.invalidValues = {};
  }

  @action
  removeCode(fieldId: string) {
    this.value = omit(this.value, fieldId);
  }

  howManyCouponsLeft(code: LCoupon) {
    return Object.keys(this.value).reduce((acc, fieldId) => {
      if (this.value[fieldId]?.code === code.code) {
        acc -= 1;
      }
      return acc;
    }, code.qty);
  }

  findByFieldId(fielId: string) {
    if (!fielId) return null;
    return this.value[fielId];
  }

  getFieldIdByCode(code: string | nil): string | nil {
    if (!code) {
      return null;
    }
    const keys = Object.keys(this.value);
    for (let i = 0; i < keys.length; i++) {
      if (this.value[keys[i]]?.code === code) return keys[i];
    }
  }

  findByCode(code: string | nil) {
    if (!code) {
      return null;
    }
    return Object.keys(this.value).find((k) => this.value[k]?.code === code);
  }

  find(code: string | nil) {
    if (!code) {
      return null;
    }
    return this.value[code];
  }

  isCouponValid(fieldId: string): boolean {
    return Boolean(this.value[fieldId]);
  }

  isCouponApplied(fieldId: string): boolean {
    return Boolean(this.value[fieldId]);
  }
}

export { CouponsStore };
