import { Observer } from 'mobx-react';
import * as React from 'react';

import { ContextValue, FormContext } from 'src/components/form';
import { StyledDivider } from 'src/modules/results/components/Filters';
import { Button } from 'src/styledComponents/Button';
import { ShowMobile } from 'src/styledComponents/Conditions';
import { Svg } from 'src/styledComponents/Icons';
import { RadioList } from 'src/styledComponents/Radio/RadioList';
import { Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';
import { InputWrapper } from 'components/form';

import { t } from 'utils';

import { handleApplyMobileFilters, handleCloseMobileFilters, handleClearFilters } from '../../../actions';

type Props = {
  distanceOptions: AnyObject;
  classOptions: AnyObject;
  waveOptions: AnyObject;
  gendersOptions: AnyObject;
  isGenderAvailable: boolean;
  results: number;
  show: boolean;
};

export const FiltersFormMobile = (props: Props) => {
  const { form } = React.useContext<ContextValue>(FormContext);

  return (
    <Observer>
      {() => {
        const { distanceOptions, classOptions, waveOptions, isGenderAvailable, gendersOptions, results, show } = props;

        return (
          <Show if={show}>
            <div className='select-block-mobile'>
              <div className='select-block-mobile-header'>
                <Svg name='CrossGray' size={16} onClick={handleCloseMobileFilters(form!.id)} />

                <Utility variant='u4' weight='bold'>
                  {t.staticAsString('race.filters')}
                </Utility>

                <Utility className='clear-filters' variant='u2' weight='medium' onClick={handleClearFilters(form!.id)}>
                  {t.staticAsString('settings.clear')}
                </Utility>
              </div>

              <div className='select-block-mobile-body'>
                <div className='select-block-mobile-item'>
                  <Utility variant='u2' weight='bold'>
                    {t.staticAsString('racersTable.filters.distance')}
                  </Utility>
                  <InputWrapper
                    name='distance_id'
                    settings={{
                      radioListItems: distanceOptions,
                    }}
                    Component={RadioList}
                  />
                </div>

                <Show if={classOptions.length > 1}>
                  <div className='select-block-mobile-item'>
                    <StyledDivider />

                    <Utility variant='u2' weight='bold'>
                      {t.staticAsString('racersTable.filters.class')}
                    </Utility>
                    <InputWrapper
                      name='class_id'
                      settings={{
                        radioListItems: classOptions,
                      }}
                      Component={RadioList}
                    />
                  </div>
                </Show>

                <Show if={waveOptions.length > 1}>
                  <div className='select-block-mobile-item'>
                    <StyledDivider />

                    <Utility variant='u2' weight='bold'>
                      {t.staticAsString('racersTable.filters.wave')}
                    </Utility>
                    <InputWrapper
                      name='wave_id'
                      settings={{
                        radioListItems: waveOptions,
                      }}
                      Component={RadioList}
                    />
                  </div>
                </Show>

                <Show if={isGenderAvailable}>
                  <div className='select-block-mobile-item'>
                    <StyledDivider />

                    <Utility variant='u2' weight='bold'>
                      {t.staticAsString('racersTable.filters.gender')}
                    </Utility>
                    <InputWrapper
                      name='gender'
                      settings={{
                        radioListItems: gendersOptions,
                      }}
                      Component={RadioList}
                    />
                  </div>
                </Show>
              </div>

              <div className='select-block-mobile-footer'>
                <Button
                  className='mobile-button'
                  size='large'
                  variant='primary'
                  fullWidth
                  onClick={handleApplyMobileFilters}
                  disabled={!results}
                >
                  {t('racersTable.filters.showResults', { results: results || '' })}
                </Button>
              </div>
            </div>
          </Show>
        );
      }}
    </Observer>
  );
};
