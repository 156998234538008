import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const ArrowDownPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M0.439453 8.56065L2.56077 6.43933L12.0001 15.8787L21.4395 6.43933L23.5608 8.56065L12.0001 20.1213L0.439453 8.56065Z'
    fill='currentColor'
  />
);

export const ArrowDown = (props: IconProps) => <SvgIcon path={ArrowDownPath} {...props} />;
