import { SmarLinkCouponType, SmartLink, SmartLinkCouponUsageType } from 'modules/registration/types';

import { calculateSmartLinkDiscount } from './calculateSmartLinkDiscount';

export function calculatePriceWithSmartLinkDiscount(params: {
  distancePrice: number;
  customFieldTotalPrice: number;
  smartLink: SmartLink;
}) {
  const { distancePrice, customFieldTotalPrice, smartLink } = params;
  const { discount_type, discount_sum, discount_sum_type } = smartLink;
  const isPercentage = discount_type === SmarLinkCouponType.DiscountPercentageType;

  switch (discount_sum_type) {
    case SmartLinkCouponUsageType.DistanceOnly:
      return calculateSmartLinkDiscount(distancePrice, discount_sum, isPercentage) + customFieldTotalPrice;

    case SmartLinkCouponUsageType.CustomFieldsOnly:
      return calculateSmartLinkDiscount(customFieldTotalPrice, discount_sum, isPercentage) + distancePrice;

    case SmartLinkCouponUsageType.DistanceAndCustomFields:
      return calculateSmartLinkDiscount(distancePrice + customFieldTotalPrice, discount_sum, isPercentage);

    default:
      return 0;
  }
}
