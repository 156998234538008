import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { Headline } from 'src/styledComponents/Typography';

type Props = {
  children: React.ReactNode;
  className?: string;
  withoutDivider?: boolean;
};

const TitleWrapper = styled(Headline)`
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 8px;

  @media (max-width: ${RESOLUTIONS.medium}px) {
    text-align: center;
  }
`;

function Title({ children, className, withoutDivider }: Props) {
  return (
    <TitleWrapper variant='h4' className={classNames('title', className, { 'without-divider': withoutDivider })}>
      {children}
    </TitleWrapper>
  );
}

export { Title };
