import * as React from 'react';

export const Globe = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12V12Z'
      stroke='#FFFFFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.59026 21.7076C8.23737 19.7236 7.33337 16.1183 7.33337 12C7.33337 7.88181 8.23737 4.27648 9.59026 2.29248'
      stroke='#FFFFFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M2 12H22' stroke='#FFFFFF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.53992 17.3333H20.4608' stroke='#FFFFFF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.53992 6.66669H20.4608' stroke='#FFFFFF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M14.4097 2.29248C15.7626 4.27648 16.6666 7.88181 16.6666 12C16.6666 16.1183 15.7626 19.7236 14.4097 21.7076'
      stroke='#FFFFFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
