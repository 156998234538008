import { observable, action, computed, makeObservable } from 'mobx';

// Class 'Loadable<T, F>' incorrectly implements interface 'ILoadable<T, F>'.
// Property 'retrieveSearchDataModel' is missing in type 'Loadable<T, F>' but required in type 'ILoadable<T, F>'
// @ts-ignore
class Loadable<T extends Resource, F extends FiltersType> implements ILoadable<T, F> {
  @observable values: Array<T> = [];

  @observable page: number = 1;

  @observable paginationMeta: PaginationMeta = {};

  @observable selected: T | null = null;

  // TODO Replace filters params with resourcesParams
  @observable filters: F = {} as any;

  @observable resourceParams: F = {} as any;

  constructor() {
    makeObservable(this);
  }

  @action
  addValues(values: Array<T>, page: number, filters: F) {
    this.values = values;
    this.page = page;
    this.filters = filters;
  }

  @action
  appendValues(values: Array<T>, page: number, filters: F) {
    this.values = [...this.values, ...values];
    this.page = page;
    this.filters = filters;
  }

  @action
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @action
  addValue(value: T, direction?: 'start' | 'end') {
    switch (direction) {
      case 'start':
        this.values = [value, ...this.values];
        return;
      case 'end':
        this.values = [...this.values, value];
        return;
      default:
        this.values = [...this.values, value];
    }
  }

  @action
  updateValue(value: T) {
    const index = this.values.findIndex((e) => e.id === value.id);
    if (index !== -1) {
      this.values.splice(index, 1, value);
    }
  }

  @action
  clearData() {
    this.values = [];
  }

  @action
  removeValue(id: number) {
    this.values = this.values.filter((value) => value.id !== id);
  }

  @action
  addSelectedValue(value: T) {
    this.selected = value;
  }

  @action
  removeSelectedValue() {
    this.selected = null;
  }

  findById(id: number): T | undefined {
    return this.values.find((element) => element.id === id);
  }

  @computed
  get isValuesEmpty(): boolean {
    return !this.values.length;
  }
}

export { Loadable };
