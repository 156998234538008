import { SmarLinkCouponType, SmartLink } from 'modules/registration/types';

function calculateSmartLinkDiscount(price: number, discountSum: number, isPercentage: boolean) {
  if (!isPercentage && discountSum) {
    if (discountSum > price) {
      return 0;
    }

    return price - discountSum;
  }

  if (isPercentage && discountSum) {
    if (discountSum >= 100) {
      return 0;
    }

    return price - (price * discountSum) / 100;
  }

  return 0;
}

export { calculateSmartLinkDiscount };
