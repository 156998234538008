import { cannotEditError } from 'modules/myResults/utils';
import { generatePath } from 'react-router-dom';

import { ROUTER } from 'src/constants';

import { fireToast, history, t } from 'utils';

import { actionMenuStore } from '../../stores';

import { close } from './display';

function editRegistrationInfo() {
  const distance = actionMenuStore.distance;
  const distanceModel = actionMenuStore.distanceModel;

  if (!distance || !distanceModel) {
    return;
  }

  close();

  if (distanceModel.isEditRegistrationAvailable()) {
    history.push(
      `${generatePath(ROUTER.RACE_REGISTRATION_WITH_DISTANCE, { id: distance.race_id, distanceId: distance.id })}&edit_registration=${
        distance.racer_id
      }`,
    );
  } else {
    fireToast(
      cannotEditError(distanceModel) as string,
      'info',
      t.staticAsString('profile.toast.title.editRegistrationInformation'),
      'top-right',
      0,
    );
    return;
  }
}

export { editRegistrationInfo };
