import { Observer } from 'mobx-react';
import React, { FC } from 'react';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { HorizontalScroll } from 'src/styledComponents/HorizontalScroll';
import { Navigation, Variant } from 'src/styledComponents/Navigation';

import { tabStore } from '../stores';

type Props = {
  handleClick: Function;
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  .navigation {
    @media (min-width: ${RESOLUTIONS.medium}px) {
      margin-bottom: 0;
    }
  }

  .scroll-block {
    margin-bottom: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  li {
    flex: 0 0 auto;
  }
`;

export const TabsComponent: FC<Props> = ({ handleClick }) => {
  return (
    <Observer>
      {() => {
        const tabs = tabStore.tabList.map((tab) => ({
          link: `/myProfile?tab=${tab.id}`,
          showInDesktop: true,
          titleLink: tab.title,
          id: tab.id,
          ATN: tab.title,
        }));
        return (
          <Wrapper className='profile-tabs'>
            <HorizontalScroll>
              <Navigation className='navigation' closeSideBar={handleClick} variant={Variant.sub} navList={tabs} />
            </HorizontalScroll>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
