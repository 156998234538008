import { isEmpty, mapValues } from 'lodash';
import moment from 'moment';
import validate from 'validate.js';

import { t } from 'utils/t';

import { errorsStore } from 'stores';

validate.extend(validate.validators.datetime, {
  parse: function (value: any, options: any) {
    return +moment.utc(value);
  },
  format: function (value: any, options: any) {
    let format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  },
});

validate.validators.array = (arrayItems: any, itemConstraints: any) => {
  const arrayItemErrors = (arrayItems || []).reduce((errors: any, item: any, index: any) => {
    let error = validate(item, itemConstraints);

    if (error) {
      errors[index] = error;
      return errors;
    }

    return errors;
  }, {});

  return isEmpty(arrayItemErrors) ? null : arrayItemErrors;
};

const _translateValues = (values: RawError): RawError => {
  return mapValues(values, (errors: string[]) => (errors || []).map((error) => t.staticAsStringMultiple(error))) as any as RawError;
};

export const validation = (
  action: string,
  object: {
    [K in any]: any;
  },
  constrains: {
    [K in any]: any;
  },
  singleError?: boolean,
) => {
  let error = _translateValues(validate(object, constrains, { fullMessages: false }));

  if (!singleError) {
    if (!isEmpty(error)) {
      //Todo: remove because deprecated
      errorsStore.addFrontErrors(action, error);
      // Todo End
      errorsStore.add(action, error);
      return;
    }

    errorsStore.clearError(action);
  } else {
    if (!isEmpty(error)) {
      const key = Object.keys(object)[0];
      errorsStore.addSingleError(action, key, error[key]);
      return false;
    }
  }

  return true;
};
