import { loadRacerService } from '../services';

import { userModalStore } from '../stores';

function onOpen(racerId: number) {
  return (e: React.SyntheticEvent) => {
    open(racerId, e);
  };
}

function open(userId: number, e: React.SyntheticEvent) {
  e.stopPropagation();

  const currentModal = e.currentTarget;
  const modalPosition = currentModal.getBoundingClientRect();

  userModalStore.setValue(userId, modalPosition);
  loadRacer(userId);
}

function close() {
  if (!userModalStore.isOpen) {
    return;
  }

  userModalStore.setValue(null, null);
}

async function loadRacer(userId: number) {
  const racer = await loadRacerService.load(userId);

  if (!racer) {
    userModalStore.setValue(null, null);
  }

  userModalStore.setUser(racer!);
}

export const userModal = {
  onOpen,
  open,
  close,
  loadRacer,
};
