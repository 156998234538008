import type { LSplit } from './split';

/**
 * @description
 * LResult - L stands for local
 * means that type is used only in local module
 */

export enum ResultStatus {
  OK = 0,
  DNS = 1,
  DNF = 2,
}

export type LResult = {
  racer_id: number;
  start_time: string;
  activity_link: null | void | string;
  distance_logged: null | void | number;
  distance_logged_at: null | void | string;
  team: null | void | RacersTeamType;
  racer: null | void | RacersType;
  splits: LSplit[];
  status: ResultStatus;
  start_time_ms: number;
  finish_time_ms: number;
  finish_time: string;
  team_id: number;
  time_result_ms: number;

  place: number | nil;
  gender_place: number | nil;
  class_place: number | nil;
  gender_class_place: number | nil;
  wave_place: number | nil;

  /**
   * @description
   * finish_time - start_time
   */
  time_result: string;
};
