import { resultsService } from '../../services';

import { distancesStore, requestsVersionStore, dataStore } from '../../stores';
import { params } from '../../stores/params';

import { dataChanger } from '../../mutations';

/**
 * @access protected
 * @description
 * plain paginatable loading of the results
 */
async function loadFinished(distanceId: number, page: number) {
  const version = requestsVersionStore.fetch();
  const distance = distancesStore.find(distanceId);

  if (!distance) {
    return;
  }

  if (dataStore.exists(distanceId, page)) {
    return;
  }

  const response = await resultsService.load(version, distanceId, page, params.resultsParams(distanceId));

  if (!response) {
    return;
  }
  if (!requestsVersionStore.compare(version)) {
    return;
  }

  const { meta, data } = response;

  dataChanger.add(distanceId, page, data, meta);

  return data.length;
}

export { loadFinished };
