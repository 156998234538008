import { getModalStoreType } from 'src/modules/profile/utils';
import { openFormModal } from 'src/modules/transferRegistration/actions';
import { modalStore } from 'src/modules/transferRegistration/stores';

import { close } from './display';
import {actionMenuStore} from "modules/profile/stores";

export const openTransferRegistrationModal = () => {
  const racerId = Number(actionMenuStore.distance?.racer_id);

  modalStore.setValue(getModalStoreType(racerId));
  openFormModal();
  close();
};
