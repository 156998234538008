import { HistoryFilters } from '../types';

export const FORM_FILTERS_ID = 'STARTLIST_FORM_FILTERS_ID';

export const EMPTY_FILTERS: HistoryFilters = {
  name: '',
  gender: '0',
  class_id: '0',
  currentDistanceId: '0',
  wave_id: '0',
} as const;

export const FILTER_NAMES = ['name', 'gender', 'class_id', 'currentDistanceId'];

export const FILTERS_FOR_DATA_FILTERING = ['name', 'gender', 'class_id'];

export const STARTLIST_CONTAINER_ID = 'startlist-table-viewport';
export const SEARCH_ID = 'STARTLIST_SEARCH_ID';

export const PER_PAGE = 30;
export const LOAD_STARTLIST = 'LOAD_STARTLIST';
export const PRELOADER = 'STARTLIST_PRELOADER';

export * from './tableFormula';
