import { Dictionary, groupBy } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { RaceType } from 'modules/home/types';
import { TrainingPlanType } from 'modules/miles/app/home/stores/trainingPlan';
import moment from 'moment/moment';

import { mapToTrainingPlanType } from '../utils';

import { Plan } from './mock';

export type SessionHistoryType = TrainingPlanType & {
  points?: number;
};

class SessionHistory {
  @observable
  sessionHistory: SessionHistoryType[] = [];

  @observable
  endDate: string;

  @observable
  race: RaceType;
  constructor() {
    makeObservable(this);
  }

  @computed
  get isTrainingPlanAvailable(): boolean {
    return Boolean(this.sessionHistory.length);
  }

  @computed
  get sessionHistoryByWeeks(): Dictionary<SessionHistoryType[]> {
    return groupBy(this.sessionHistory, (day) => moment(day.date).startOf('isoWeek').format('YYYY-MM-DD'));
  }

  @computed
  get compactSessionHistoryByWeeks(): { trainingPlan: Dictionary<SessionHistoryType[]>; startWeekDate: string }[] {
    const weeks = Object.keys(this.sessionHistoryByWeeks);
    const trainingPlanByWeeks = this.sessionHistoryByWeeks;

    return weeks.map((week) => {
      return { startWeekDate: week, trainingPlan: groupBy(trainingPlanByWeeks[week], (item) => `${item.name}_${item.difficultyLevel}`) };
    });
  }

  @action
  setSessionHistory(value: Plan[]) {
    this.sessionHistory = value.map(mapToTrainingPlanType);
  }

  @action
  clear() {
    this.sessionHistory = [];
  }
}

export { SessionHistory };
