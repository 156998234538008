import { transaction } from 'mobx';

import { PER_PAGE } from '../../constants';

import { resultsService } from '../../services';

import { distancesStore, requestsVersionStore, dataStore } from '../../stores';
import { params } from '../../stores/params';

import { dataChanger } from '../../mutations';

/**
 * @access protected
 * @description
 * Loading of the results with not plain approach
 * Load all the previous pages during some page load
 *
 * 5 - page. Will load 5,4,3,2,1 pages
 *
 * That is done to handle situations for not finished distances.
 * That means that previous pages could change during the time
 * fifth page is in the load
 *
 * only one approach is to load bunch of data
 */
async function loadNonFinished(distanceId: number, requestedPage: number) {
  const version = requestsVersionStore.fetch();
  const distance = distancesStore.find(distanceId);

  if (!distance) {
    return;
  }

  if (dataStore.exists(distanceId, requestedPage)) {
    return;
  }

  const page = 1;
  const limit = requestedPage * PER_PAGE;
  const response = await resultsService.load(version, distanceId, page, { ...params.resultsParams(distanceId), limit });

  if (!response) {
    return;
  }
  if (!requestsVersionStore.compare(version)) {
    return;
  }

  const { data, meta } = response;

  let paginationMeta: PaginationMeta | null = null;

  if (requestedPage === 1) {
    paginationMeta = meta;
  }

  transaction(() => {
    let startDataPointer = 0;
    let endDataPointer: number;

    for (let loadedPage = 1; loadedPage <= requestedPage; loadedPage++) {
      endDataPointer = loadedPage * PER_PAGE;
      const loadedData = data.slice(startDataPointer, endDataPointer);

      dataChanger.add(distanceId, loadedPage, loadedData, paginationMeta);

      startDataPointer = endDataPointer;
    }
  });

  return data.length;
}

export { loadNonFinished };
