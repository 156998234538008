import { classesStore, squadsStore, wavesStore } from '../stores';

import { LResult } from '../types';

export const mobileItemHeight = (value: LResult): number => {
  const entity = value.racer || value.team;

  if (!entity) return 0;

  const classModel = classesStore.find(+entity.class_id!);
  const waveModel = wavesStore.find(+entity.wave_id!);
  const squad = squadsStore.values.find((item) => item.id === (value.racer as any)?.squad_id!);

  if (classModel?.value.title || (value.racer as any)?.union || waveModel?.value.name || squad?.name) return 140;

  return 90;
};
