import { observable, action, makeObservable } from 'mobx';

/*
 * Store current messages, to be used for translations
 * Independent of current locale and localeStore
 */
class Messages {
  @observable value: MessagesType = {} as MessagesType;

  constructor() {
    makeObservable(this);
  }

  @action
  async setValue(value: MessagesType) {
    this.value = value;
  }
}

const messagesStore = new Messages();

export { Messages, messagesStore };
