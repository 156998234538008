import { raceSelector } from '../../selectors/mappedData';

const getPrice = (item: AnyObject): string => {
  const currency = raceSelector.currency.get() || '';

  const isPricePresent = Boolean(item.price) && Boolean(parseFloat(item.price));

  return isPricePresent ? ` (+ ${item.price} ${currency})` : '';
};

/**
 * @description
 * Map custom field values select options, and provide title in format - 'option title (price race.currency)'
 * example - 'T-SHIRT (10.0 SEK)
 */
export function mapCustomFieldOptionsToSelectOptions(data: CustomValuesFields[] | nil): Array<NewSelectItemType> {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    key: item.id,
    value: item.id,
    label: `${item.value} ${getPrice(item)}`,
  }));
}
