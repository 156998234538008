import * as React from 'react';

export const CircleDown = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z'
      fill='#656B6F'
    />
    <path d='M16 11.02L12.01 15L8 11' stroke='#F6F7F7' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
