// @flow
import { AuthContainer } from 'modules/auth';
import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ROUTER_WITH_LOCALES, ROUTER } from 'src/constants';

const generateAuthRoutes = () => {
  return [
    <Route path={ROUTER_WITH_LOCALES.SIGNUP} component={AuthContainer} key='signup' />,
    <Route path={ROUTER_WITH_LOCALES.SIGNIN} component={AuthContainer} key='signin' />,
    <Route path={ROUTER_WITH_LOCALES.FORGOT} component={AuthContainer} key='forgot' />,
  ];
};

const generateAuthRoutesRedirects = () => {
  return [
    <Redirect from={ROUTER_WITH_LOCALES.SIGNUP} to={ROUTER.HOME} key='signup' />,
    <Redirect from={ROUTER_WITH_LOCALES.SIGNIN} to={ROUTER.HOME} key='signin' />,
    <Redirect from={ROUTER_WITH_LOCALES.FORGOT} to={ROUTER.HOME} key='forgot' />,
  ];
};

export { generateAuthRoutes, generateAuthRoutesRedirects };
