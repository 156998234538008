import { isEmpty, isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import styled, { css } from 'styled-components';

import { boxShadow, linkStyles } from 'styles/effects';

import { Show } from 'components/condition';

import { t } from 'utils/t';

import { Button } from '../Button';
import { stylesBySize } from '../DropDownFilter';
import { Icon, Svg } from '../Icons';
import { InputComponent, InputWrapComponent } from '../InputTools';
import { Utility } from '../Typography';
import { Calendar } from './Calendar';

export enum Sizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type DateRangeType = {
  startDate?: Moment;
  endDate?: Moment;
};

type Props = {
  isSingleMonthRange?: boolean;
  isRange: boolean;
  label?: string;
  name: string;
  onChange: (arg: { name: string; value: DateRangeType | Moment }) => void;
  minDate?: Moment;
  maxDate?: Moment;
  value: DateRangeType | Moment;
  clearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  size?: Sizes;
  className?: string;
  variant?: keyof typeof Variants;
  onMenuOpen?: AnyFunction;
  onMenuClose?: AnyFunction;
};

type State = {
  isOpened: boolean;
  value: DateRangeType | Moment;
};

type InputWrapProps = {
  size?: Sizes;
  isOpened: boolean;
  hasValue: boolean;
};

const hasValueStyles = css`
  input {
    background-color: ${(props) => props.theme.main.colors.clay3};
    border-color: ${(props) => props.theme.main.colors.clay3};
    color: white;

    &::placeholder {
      color: ${(props) => props.theme.main.colors.white};
    }
  }

  svg path {
    stroke: white;
  }
`;

enum Variants {
  formField = 'formField',
  rounded = 'rounded',
}

const StyledInputWrap = styled(InputWrapComponent)<InputWrapProps>`
  width: ${(props) => (props.size === 'large' ? '180px' : '150px')};

  .field-wrap {
    display: flex;
    align-items: center;
    .icon-box {
      position: absolute;
      right: 18px;
      display: flex;

      svg {
        cursor: pointer;
        transform: ${(props) => (props.isOpened ? 'rotate(180deg)' : '')};
        path {
          stroke: ${(props) => props.theme.main.colors.clay3};
        }
      }
    }

    .close-icon {
      top: 50%;

      svg path {
        stroke-width: 2;
      }
    }

    @media (hover: hover) {
      &:hover {
        span.input {
          color: ${(props) => props.theme.main.colors.white};
          background: ${(props) => props.theme.main.colors.clay3};
          border: 2px solid ${(props) => props.theme.main.colors.clay3};
          cursor: pointer;

          &::placeholder {
            color: ${(props) => props.theme.main.colors.white};
          }
        }

        svg path {
          stroke: ${(props) => props.theme.main.colors.white};
        }

        ${(props) =>
          props.disabled &&
          css`
            .field-wrap svg path {
              stroke: ${props.theme.main.colors.clay4};
            }

            span.input {
              border: 2px solid ${props.theme.main.colors.clay4};
              color: ${props.theme.main.colors.clay4};
              background: transparent;
              cursor: not-allowed;

              &::placeholder {
                color: ${props.theme.main.colors.clay4};
              }
            }
          `}
      }
    }
  }

  span.input {
    background: transparent;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: ${(props) => props.theme.main.colors.clay3};
    border: 2px solid ${(props) => props.theme.main.colors.clay3};
    border-radius: 129px;

    width: 100%;

    font-weight: 700;
    text-transform: uppercase;
    outline: none;

    ${(props) => stylesBySize[props.size ?? 'medium']};
    padding-right: ${(props) => (props.size === 'large' ? '48px' : '40px')};

    &:disabled {
      color: ${(props) => props.theme.main.colors.clay4};
      border: 2px solid ${(props) => props.theme.main.colors.clay4};
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${(props) => props.theme.main.colors.clay3};
    }
  }

  ${(props) =>
    props.isOpened &&
    css`
      .field-wrap .icon-box svg path {
        stroke: ${props.theme.main.colors.white};
      }

      span.input {
        color: ${props.theme.main.colors.white};
        background: ${props.theme.main.colors.clay1};
        border: 2px solid ${props.theme.main.colors.clay1};

        &::placeholder {
          color: ${props.theme.main.colors.white};
        }
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.main.colors.clay4};
      background: transparent;
      cursor: not-allowed;

      .field-wrap .icon-box svg {
        cursor: not-allowed;

        path {
          stroke: ${props.theme.main.colors.clay4};
        }
      }
    `}
  
  ${(props) => (props.hasValue ? hasValueStyles : '')}
`;

export const CalendarWrapper = styled.div`
  position: absolute;
  top: 88px;
  z-index: 9999999;
  border-radius: 24px;
  background-color: white;
  overflow: hidden;

  ${boxShadow};
  .CalendarMonth_caption {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.main.colors.clay2};

    strong {
      font-weight: 500;
    }
  }

  .DayPicker_weekHeader_li {
    color: ${(props) => props.theme.main.colors.clay4};
    font-weight: 700;
    font-size: 20px;
    width: 46px !important;
  }

  .CalendarDay__selected {
    background: ${(props) => props.theme.main.colors.clay1};
    color: ${(props) => props.theme.main.colors.white};
  }

  .CalendarDay__hovered_span {
    background: ${(props) => props.theme.main.colors.clay6};
    color: ${(props) => props.theme.main.colors.clay1};
  }

  .CalendarDay__selected_span {
    background: ${(props) => props.theme.main.colors.clay6};
    color: ${(props) => props.theme.main.colors.clay1};
  }

  .CalendarDay__outside {
    background: none;
    color: ${(props) => props.theme.main.colors.clay4};
  }

  .CalendarDay__blocked_calendar {
    background: none;
    color: ${(props) => props.theme.main.colors.clay4};
  }

  .CalendarMonthGrid > .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1:not(:nth-child(2)) .change-year {
    display: none;
  }
`;

const MenuFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 80px;
  padding: 0 44px 32px;
  
  border-top: 2px; solid ${(props) => props.theme.main.colors.clay4};
  background-color: white;
`;

const StyledUtility = styled(Utility)`
  ${linkStyles}
`;

const components = {
  [Variants.rounded]: StyledInputWrap,
  [Variants.formField]: InputWrapComponent,
};

@onClickOutside
export class DatePicker extends Component<Props, State> {
  static contextType = TestAnchorContext;
  declare context: React.ContextType<typeof TestAnchorContext>;

  state = {
    isOpened: false,
    value: {},
  };

  componentDidMount(): void {
    const { value } = this.props;

    this.setState({
      value,
    });
  }

  componentDidUpdate(prevProps): void {
    if (!isEqual(this.props.value, prevProps.value)) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onChange = (value: { startDate?: moment.Moment; endDate?: moment.Moment }): void => {
    this.setState({
      value,
    });
  };

  handleSave = () => {
    const { name, onChange } = this.props;
    const { value } = this.state;

    onChange({ name, value });
    this.handleCloseDropList();
  };

  handleCloseDropList() {
    const { onMenuClose } = this.props;

    onMenuClose && onMenuClose();

    this.setState({
      isOpened: false,
    });
  }

  handleOpenDropList() {
    const { isOpened } = this.state;
    const { onMenuClose, onMenuOpen } = this.props;

    if (isOpened) {
      onMenuClose && onMenuClose();
    } else {
      onMenuOpen && onMenuOpen();
    }

    this.setState({
      ...this.state,
      isOpened: !isOpened,
    });
  }

  handleClickOutside() {
    if (!this.state.isOpened) return;

    this.handleCloseDropList();
  }

  formatDate(date: Moment | undefined) {
    if (date) return date.format('D MMM');

    return '';
  }

  handleClear = () => {
    const { name, onChange } = this.props;

    onChange({ name, value: {} });
    this.setState({
      value: {},
    });
    this.handleCloseDropList();
  };

  render() {
    const {
      isSingleMonthRange = false,
      isRange,
      minDate,
      maxDate,
      label,
      name,
      clearable,
      disabled,
      placeholder,
      size,
      className,
      variant = Variants.rounded,
    } = this.props;
    const { isOpened, value } = this.state;

    const Component = components[variant];

    const isValuePresented = !isEmpty(value);
    const singleDate = !isValuePresented ? undefined : (value as Moment);
    const { startDate, endDate } = (value as DateRangeType) || {};

    const getValue = () => {
      if (!isValuePresented) return '';

      return isRange ? `${this.formatDate(startDate)} / ${this.formatDate(endDate)}` : `${this.formatDate(singleDate)}`;
    };

    return (
      <Component
        size={size}
        isOpened={isOpened}
        disabled={disabled}
        name={name}
        label={label}
        requiredMark={false}
        errorMessage={undefined}
        className={className}
        hasValue={!isEmpty(value)}
      >
        <div className='field-wrap'>
          {variant == Variants.rounded && (
            <>
              <input
                {...testAnchors.button(this.context.container, name)}
                id={name}
                name={name}
                type='text'
                value={getValue()}
                disabled={disabled}
                placeholder={placeholder}
                onChange={() => {}}
                readOnly
                style={{ display: 'none' }}
              />
              <span className='input' onClick={this.handleOpenDropList.bind(this)}>
                {getValue() || placeholder}
              </span>

              <Show if={!(!isOpened && isValuePresented && clearable)}>
                <Svg
                  className='icon-box'
                  name='ArrowDownSmall'
                  size={size === 'large' ? 24 : 16}
                  onClick={!isOpened && !disabled ? this.handleOpenDropList.bind(this) : this.handleCloseDropList.bind(this)}
                />
              </Show>

              <Show if={Boolean(!isOpened && isValuePresented && clearable)}>
                <Svg
                  className='icon-box close-icon'
                  name='CrossGray'
                  size={size === 'large' ? 24 : 16}
                  onClick={() => (disabled ? {} : this.onChange({}))}
                />
              </Show>
            </>
          )}

          {variant == Variants.formField && (
            <InputComponent
              id={name}
              name={name}
              type='text'
              value={getValue()}
              disabled={disabled}
              placeholder={placeholder}
              onChange={() => {}}
              onClick={this.handleOpenDropList.bind(this)}
              unit={<Icon name='ArrowDown' size={16} />}
              readOnly
            />
          )}
        </div>

        <Show if={isOpened}>
          <CalendarWrapper>
            <Calendar
              isSingleMonthRange={isSingleMonthRange}
              startDate={startDate}
              endDate={endDate}
              date={singleDate}
              isSingleDate={!isRange}
              minDate={minDate}
              maxDate={maxDate}
              onChange={this.onChange as AnyFunction}
            />
            <MenuFooter>
              <StyledUtility variant='u4' weight='medium' onClick={this.handleClear}>
                {t('settings.clear')}
              </StyledUtility>
              <Button size='small' variant='secondary' onClick={this.handleSave}>
                {t('settings.save')}
              </Button>
            </MenuFooter>
          </CalendarWrapper>
        </Show>
      </Component>
    );
  }
}
