import { history } from 'utils';

import { modeStore } from '../../stores';

import { ModalTypes } from '../../types';
import { createSegment } from '../create';
import { updateSegment } from '../update';

const submits: { [K in ModalTypes]: Function } = {
  new: createSegment,
  'first-new': createSegment,
  edit: updateSegment,
};

export const onSubmitViaRoute = async () => {
  const submit = submits[modeStore.value || 'new'];
  const status = submit && (await submit());

  if (status) {
    history.safeGoBack();
  }
};

export const onCancelViaRoute = () => {
  history.safeGoBack();
};
