import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const RacePrepStartPath = (
  <>
    <path
      d='M18.1135 6.89051C16.4818 6.05695 16.6351 2.95224 18.6282 3.00056C19.4496 3.04888 20.179 3.80995 20.1243 4.67976C20.1243 6.40911 19.1429 7.47038 18.1135 6.89051Z'
      fill='currentColor'
    />
    <path
      d='M3.95797 16.2298C5.54927 15.9007 8.5 15.5 8.5 15.5C7 13 6.4057 13.1228 6 11.5C5.43836 9.25342 8.49996 7.00003 13.5 5.99999C14 5.89999 15.5 5.88119 16 6.5C16.898 7.61141 16.6852 8.12962 16.5 8.5C16 9.50001 15.5 9.99998 12.5 9.99998L9 11.5C10.1881 13.2583 11 17.5 11 17.5H3C3.00004 16.8733 3.34424 16.3567 3.95797 16.2298Z'
      fill='currentColor'
    />
    <path
      d='M12.8338 16.0383C13.1299 14.7358 13.5138 13.0298 13.5003 13L10 12L12.5 11L14.2207 11.2458C15.1472 11.3782 15.7276 12.3173 15.4316 13.2051L13.9999 17.5C13.2323 17.5 12.6636 16.7868 12.8338 16.0383Z'
      fill='currentColor'
    />
    <path d='M19.5 17.5L16.5 7.5L14.5 8C15.6667 10.6667 17.6 15.3 18 16.5C18.4 17.7 19 17.6667 19.5 17.5Z' fill='currentColor' />
    <rect x='1' y='19' width='22' height='2' fill='currentColor' />
  </>
);

export const RacePrepStart = (props: IconProps) => <SvgIcon path={RacePrepStartPath} {...props} />;
