import { throttle } from 'lodash';

import { isViewportFilled } from '../../utils';

import { loader } from '../../actions';

// Infinite scroll
function observeScroll(): AnyFunction {
  const trottledObserver = throttle(observer, 100);

  document.addEventListener('scroll', trottledObserver);

  return () => document.removeEventListener('scroll', trottledObserver);
}

function observer() {
  if (isViewportFilled()) {
    return;
  }

  loader.loadMore();
}

export { observeScroll };
