import { ROUTER } from 'src/constants';

import { history } from 'utils';

import { progressStore } from 'stores';

import { INITIALIZE_SEGMENTS } from '../constants';

import { parseUrlParams } from '../utils';

import { segmentsService } from '../services';

import { segmentsStore } from '../stores';

import { observeViewportChange, unmountInfiniteScroll, unmountViewportChange } from '../observers';
import { loadMoreSegments } from './loadSegments';

export const mountSegments = async () => {
  unmountSegments();
  progressStore.log(INITIALIZE_SEGMENTS, 'progress');

  const { distanceId, racerId } = parseUrlParams();

  if (!racerId || !distanceId) {
    history.push(ROUTER.PROFILE);
    return;
  }

  segmentsStore.changeIdOptions(racerId, distanceId);

  await Promise.all([loadMoreSegments(), segmentsService.getDistance(distanceId), segmentsService.getTotal(+racerId)]);

  observeViewportChange();

  progressStore.log(INITIALIZE_SEGMENTS, 'completed');
};

export const unmountSegments = () => {
  segmentsStore.clear();
  unmountInfiniteScroll();
  unmountViewportChange();
};
