import { Observer } from 'mobx-react';
import { focusedStore } from 'modules/search/stores';
import { FieldNames } from 'modules/search/types';
import React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';

import { Show } from 'components/condition';

import { LocationSearchMobile } from './location';
import { RaceSearchMobile } from './race';

const SearchItemWrapper = styled.label`
  display: flex;
  width: 100%;
  align-items: center;

  input {
    border: none;
    outline: none;
    width: 100%;
  }
  &:first-child {
    padding: 16px 24px;
    border-bottom: 1px solid ${(props) => props.theme.main.colors.clay5};
  }
  &:last-child {
    padding: 16px 24px;
  }
  svg path {
    stroke: ${(props) => props.theme.main.colors.clay3};
  }
`;

export const SearchItemMobile: React.FC<BaseInputProps> = (props) => {
  const { value, onChange, name } = props;

  const onClose = () => {
    onChange({ name: name, value: '' });
  };

  return (
    <Observer>
      {() => {
        const getField = () => {
          switch (name) {
            case FieldNames.location:
              return <LocationSearchMobile isFocused={Boolean(focusedStore.value === name)} {...props} />;
            case FieldNames.raceName:
              return <RaceSearchMobile isFocused={Boolean(focusedStore.value === name)} {...props} />;
          }
        };

        return (
          <SearchItemWrapper>
            <Show if={!Boolean(value)}>
              <Svg name='Search' size={16} />
            </Show>
            {getField()}
            <Show if={Boolean(value)}>
              <Svg name='Cross' size={16} onClick={onClose} {...testAnchors.useButton('close')} />
            </Show>
          </SearchItemWrapper>
        );
      }}
    </Observer>
  );
};
