import styled from 'styled-components';

const ContentSeparator = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.85);
    margin: 0 10px;
  }
`;

export { ContentSeparator };
