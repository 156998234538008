import * as React from 'react';

export const Location = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.33325 8.96283C8.33325 9.84687 8.68443 10.6947 9.30954 11.3198C9.93465 11.9449 10.7825 12.2961 11.6665 12.2961C12.5506 12.2961 13.3984 11.9449 14.0235 11.3198C14.6486 10.6947 14.9998 9.84687 14.9998 8.96283C14.9998 8.07879 14.6486 7.23096 14.0235 6.60585C13.3984 5.98075 12.5506 5.62956 11.6665 5.62956C10.7825 5.62956 9.93465 5.98075 9.30954 6.60585C8.68443 7.23096 8.33325 8.07879 8.33325 8.96283Z'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.6665 2.2963C13.4346 2.2963 15.1303 2.99866 16.3805 4.24888C17.6307 5.4991 18.3331 7.19476 18.3331 8.96284C18.3331 11.9912 13.8229 19.5849 12.2221 21.9981C12.1613 22.0898 12.0787 22.165 11.9818 22.217C11.8849 22.2691 11.7766 22.2963 11.6665 22.2963C11.5565 22.2963 11.4482 22.2691 11.3513 22.217C11.2544 22.165 11.1718 22.0898 11.111 21.9981C9.51014 19.5849 5 11.9912 5 8.96284C5 7.19476 5.70237 5.4991 6.95258 4.24888C8.2028 2.99866 9.89846 2.2963 11.6665 2.2963V2.2963Z'
      stroke='#656B6F'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const AltLocation = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.33325 8.96315C8.33325 9.84719 8.68443 10.695 9.30954 11.3201C9.93465 11.9452 10.7825 12.2964 11.6665 12.2964C12.5506 12.2964 13.3984 11.9452 14.0235 11.3201C14.6486 10.695 14.9998 9.84719 14.9998 8.96315C14.9998 8.07912 14.6486 7.23128 14.0235 6.60618C13.3984 5.98107 12.5506 5.62988 11.6665 5.62988C10.7825 5.62988 9.93465 5.98107 9.30954 6.60618C8.68443 7.23128 8.33325 8.07912 8.33325 8.96315Z'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.6665 2.29639C13.4346 2.29639 15.1303 2.99875 16.3805 4.24897C17.6307 5.49919 18.3331 7.19485 18.3331 8.96293C18.3331 11.9913 13.8229 19.585 12.2221 21.9982C12.1613 22.0899 12.0787 22.1651 11.9818 22.2171C11.8849 22.2692 11.7766 22.2964 11.6665 22.2964C11.5565 22.2964 11.4482 22.2692 11.3513 22.2171C11.2544 22.1651 11.1718 22.0899 11.111 21.9982C9.51014 19.585 5 11.9913 5 8.96293C5 7.19485 5.70237 5.49919 6.95258 4.24897C8.2028 2.99875 9.89846 2.29639 11.6665 2.29639V2.29639Z'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
