import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const InjuryPath = (
  <>
    <path d='M11.5 19H8.5C6.29086 19 4.5 17.2091 4.5 15V14C4.5 11.7909 6.29086 10 8.5 10L11.5 19Z' fill='CurrentColor' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.7702 1.20591C13.3711 1.07235 12.944 1 12.5 1C10.6361 1 9.07002 2.27477 8.62598 4H9.8585L13.7702 1.20591ZM10.4994 6L10.4994 6.00003L10.4994 6H16.3739C15.9299 7.72523 14.3638 9 12.5 9C10.6361 9 9.07002 7.72523 8.62598 6H10.4994ZM13.2994 4L15.5397 2.39984C15.9301 2.85585 16.2197 3.40075 16.3739 4H13.2994Z'
      fill='CurrentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.5 10C18.7091 10 20.5 11.7909 20.5 14V24H7.5V21H13.5H14.5H16C17.3807 21 18.5 19.8807 18.5 18.5C18.5 17.1193 17.3807 16 16 16H13L11.5 11C12.5 11.1667 14.5 11.5 16.5 10ZM13.6 18L13.9 19H16C16.2761 19 16.5 18.7761 16.5 18.5C16.5 18.2239 16.2761 18 16 18H13.6Z'
      fill='CurrentColor'
    />
  </>
);

export const Injury = (props: IconProps) => <SvgIcon path={InjuryPath} {...props} />;
