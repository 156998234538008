import { Distance } from 'models';

/**
 * @description
 * In case if distance registration is closed for PUSH PAYMENTS
 * there are necesety to display PRICE anyway
 *
 * in case if registration has not started yet - display first price
 * in case if registration already ended - display last price
 */
function generateDistanceExtremePrice(distance: Distance) {
  if (!distance) {
    return;
  }

  if (distance.isRegistrationOpen()) {
    return generateDistancePrice(distance);
  }

  const firstPrice = distance.firstPrice();
  const lastPrice = distance.lastPrice();

  if (!firstPrice || !lastPrice) {
    return;
  }

  if (distance.isRegistrationInFuture()) {
    return firstPrice.value;
  }

  if (distance.isRegistrationInPast()) {
    return lastPrice.value;
  }
}

/**
 * @description
 * Fetch current relevant distance price
 */
function generateDistancePrice(distance: Distance): number | void {
  if (!distance) {
    return;
  }

  return distance.value.price;
}

export { generateDistancePrice, generateDistanceExtremePrice };
