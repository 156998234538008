import { isNumber } from 'lodash';

import type { Distance } from 'models';

import { generateDistancePrice, generateDistanceExtremePrice } from './generateDistancePrice';

/**
 * @description
 * Fetch current distance price
 */
function fetchPrice(distance: Distance | nil, isPushPayment?: boolean, isConfirmation?: boolean, transferPrice?: number | null) {
  if (!distance) {
    return;
  }

  if (isPushPayment) {
    return generateDistanceExtremePrice(distance);
  }

  if (isConfirmation) {
    return 0;
  }

  if (isNumber(transferPrice)) {
    return transferPrice;
  }

  return generateDistancePrice(distance);
}

export { fetchPrice };
