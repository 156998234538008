import { set } from 'lodash';
import { observable, computed, action, get, makeObservable } from 'mobx';

import { Distance as DistanceModel } from 'models/distance';
import { Race as RaceModel } from 'models/race';

import { Loadable } from 'stores/loadable';
import overlayStore from 'stores/overlay';
import sessionStore from 'stores/session';

class ManageTeam extends Loadable<RacesType, FiltersType> {
  constructor(params: any = {}) {
    super();
    makeObservable(this);
    this.filters = params.filters || this.filters;
  }

  // RP-3731 Note this trigger is needed for read-only mode
  // Read only manage team mode
  @observable readOnlyMode: boolean = true;
  @observable showAddModal: boolean = false;

  @observable selectedMember: Object = {};

  isTeamOwner: boolean = false;
  isActiveUserEdit: boolean = false;

  @computed
  get modelSelectedValue(): RaceModel | null {
    const { selected } = this;

    if (!selected) {
      return null;
    }

    return new RaceModel(selected);
  }

  @action
  setTeamOwner(distanceID: number): void {
    const distanceModel = this.getNeededDistance(distanceID);
    const team = (distanceModel?.value?.teams || [])[0];
    const members = team?.racers || [];

    this.isTeamOwner = !!members.find((member: any) => member.is_owner && member.user_id === sessionStore.session.user.id);
  }

  @action
  openAddModal() {
    this.showAddModal = true;
  }

  @action
  closeAddModal() {
    this.showAddModal = false;
    overlayStore.hide();
  }

  @action
  changeSelectedMember(member: AnyObject) {
    this.selectedMember = member;
    this.isActiveUserEdit = member.user_id === sessionStore.session.user.id;
  }

  @action
  isActiveUserEditing(member: AnyObject): boolean {
    return member.user_id === sessionStore.session.user.id;
  }

  @action
  changeNeededProp(source: Object | Array<unknown>, path: string, value: any) {
    set(source, path, value);
  }

  getNeededDistance(distanceId: number | null): AnyObject {
    if (!distanceId) {
      return {};
    }

    const distance = ((this.selected || {}).distances || []).find((distance) => distance.id === distanceId) || {};

    return new DistanceModel(distance as any);
  }

  getNeededMember(racerId: number): Object | null {
    let member: AnyObject | null = null;

    ((this.selected || {}).distances || []).forEach((distance) => {
      ((((distance || {}).teams || [])[0] || {}).racers || []).forEach((racer) => {
        if (racer.id === racerId) {
          member = racer;
        }
      });
    });

    return member;
  }
}

export { ManageTeam };
export default new ManageTeam();
