import { Observer } from 'mobx-react';
import * as React from 'react';

import { Distance } from 'src/models/distance';
import { DistanceHeader } from 'src/modules/racersTable/components';

import { data, generateIsClasses, generateIsSquads, generateIsUnion, generateIsWaves } from '../../derivations';
import { RightSideRow } from './RightSideSingleRow';

type Props = {
  distance: Distance;
};

export const RightSide: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const isWaves = generateIsWaves(distanceId);
  const isClasses = generateIsClasses(distanceId);
  const isSquads = generateIsSquads(distanceId);
  const isUnion = generateIsUnion(distanceId);

  const dataGenerator = data.generateDataEntries(distanceId);

  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isWaves.get(),
          class: isClasses.get(),
          club: isUnion.get(),
          squad: isSquads.get(),
        };

        const data: any[] = dataGenerator.get() || [];

        return (
          <>
            <DistanceHeader isPlaceholder />
            {data.map((item) => (
              <RightSideRow
                key={`table-right-row-${item.id}`}
                columnsActive={columnsActive}
                value={item}
                isTeam={distance.value.type === 'team'}
              />
            ))}
          </>
        );
      }}
    </Observer>
  );
};
