import { isFunction } from 'lodash';
import { observe } from 'mobx';

import { overlayStore, manageTeamStore, windowSize } from 'stores';

let unsubWindowSubscription: any;

function subscribeSyncOverlayAndPopup() {
  unsubscribeSyncOverlayAndPopup();
  unsubWindowSubscription = observe(windowSize, 'value', sync);
}

function unsubscribeSyncOverlayAndPopup() {
  isFunction(unsubWindowSubscription) && unsubWindowSubscription();
}

function sync() {
  if (windowSize.isLessThan('large')) {
    mobileMode();
  }

  if (windowSize.isGreaterThan('large')) {
    desktopMode();
  }
}

function mobileMode() {
  const isOverlay = overlayStore.isShow;

  if (isOverlay) {
    overlayStore.hide();
  }
}

function desktopMode() {
  const isOverlay = overlayStore.isShow;
  const isPopup = manageTeamStore.showAddModal;

  if (!isOverlay && isPopup) {
    overlayStore.show();
  }
}

export { subscribeSyncOverlayAndPopup, unsubscribeSyncOverlayAndPopup };
