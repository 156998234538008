import { Observer } from 'mobx-react';
import * as React from 'react';

import { Distance } from 'src/models/distance';
import { DistanceHeader } from 'src/modules/racersTable/components';

import {
  data,
  generateCheckpointId,
  generateIsCheckpoints,
  generateIsClasses,
  generateIsSquads,
  generateIsUnion,
  generateIsWaves,
} from '../../derivations';
import { RightSideSingleRow } from './RightSideSingleRow';
import { RightSideTeamRow } from './RightSideTeamRow';

type Props = {
  distance: Distance;
};

const Rows = {
  single: RightSideSingleRow,
  team: RightSideTeamRow,
};

export const RightSide: React.FC<Props> = (props) => {
  const { distance } = props;

  const distanceId = distance.value.id;
  const isWaves = generateIsWaves(distanceId);
  const isClasses = generateIsClasses(distanceId);
  const isSquads = generateIsSquads(distanceId);
  const isUnion = generateIsUnion(distanceId);
  const checkpointGenerator = generateCheckpointId(distanceId);
  const isCheckpoints = generateIsCheckpoints(distanceId);

  const dataGenerator = data.generateDataEntries(distanceId);

  return (
    <Observer>
      {() => {
        const columnsActive = {
          wave: isWaves.get(),
          class: isClasses.get(),
          club: isUnion.get(),
          squad: isSquads.get(),
          time: true,
          diff: !distance.isMode('cumulative'),
          goalDistance: distance.isMode('cumulative'),
          tracker: !distance.isMode('classic'),
          checkpoint: isCheckpoints.get(),
        };

        const data: any[] = dataGenerator.get() || [];
        const checkpointId = checkpointGenerator.get();

        const Component = Rows[distance.value.type];
        return (
          <>
            <DistanceHeader isPlaceholder />
            {data.map((item) => (
              <Component
                key={`table-right-row-${item.id}`}
                columnsActive={columnsActive}
                value={item}
                distanceId={distanceId}
                checkpointId={checkpointId}
              />
            ))}
          </>
        );
      }}
    </Observer>
  );
};
