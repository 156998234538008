import { FinishResult } from './FinishResult';
import { LeaderTime } from './LeaderTime';
import { PlaceColumn } from './Place';
import { PreviousSplitDiff } from './PreviousSplitDiff';
import { SplitFinishTime } from './SplitFinishResult';

export const PresentResult = {
  FinishResult,
  LeaderTime,
  SplitFinishTime,
  PreviousSplitDiff,
  PlaceColumn,
};
