import { Observer } from 'mobx-react';
import * as React from 'react';

import { LeftTableHeader } from 'src/modules/racersTable/components';

import { isAnyDistanceHasBibs } from '../../derivations';
import { UsersCount } from '../UsersCount';

export const LeftHeader = () => {
  return (
    <Observer>
      {() => {
        const withBib = isAnyDistanceHasBibs.get();

        return <LeftTableHeader withBib={withBib} participantsCount={<UsersCount />} />;
      }}
    </Observer>
  );
};
