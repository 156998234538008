import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { distancesStore } from '../stores';

export const isAnyDistanceHasBibs: IComputedValue<boolean> = computed(() =>
  distancesStore.filteredValues.some((distance) => Boolean(distance.show_bibs)),
);

export const generateIsBibs = (distanceId: number): IComputedValue<boolean> =>
  computed(() => {
    const distance = distancesStore.find(distanceId);
    if (!distance) {
      return false;
    }
    return Boolean(distance.value.show_bibs);
  });
