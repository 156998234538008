import { Observer } from 'mobx-react';
import { useHeaderSettings } from 'modules/header';
import { HeaderVariants } from 'modules/header/types';
import React, { FC, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { RESOLUTIONS, ROUTER } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { Svg } from 'src/styledComponents/Icons';
import { Skeleton } from 'src/styledComponents/Skeleton';
import { Utility } from 'src/styledComponents/Typography';

import { history, t } from 'utils';

import { progressStore } from 'stores';

import { TableView, DistanceName, Total, ButtonLog, MobileView } from '../components';

import { INITIALIZE_SEGMENTS, VIEWPORT_CONTAINER_ID } from '../constants';

import { mountSegments, unmountSegments } from '../actions';

const HideBodyImage = createGlobalStyle`
  body {
    background-image: none;
  }
`;

const Wrapper = styled.div`
  padding: 104px 20px 0;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 152px 20px 0;
  }

  @media (min-width: ${RESOLUTIONS.large}px) {
    padding: 152px 80px 0;
  }

  .navigation {
    display: flex;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
  }
  .go-back-icon:hover {
    cursor: pointer;

    svg > path {
      stroke: ${(props) => props.theme.main.colors.clay2};
    }
    &:hover {
      svg > path {
        stroke: ${(props) => props.theme.main.colors.white};
        &.circle {
          fill: ${(props) => props.theme.main.colors.clay3};
          stroke: ${(props) => props.theme.main.colors.clay3};
        }
      }
    }
  }
  .go-back-text {
    color: ${(props) => props.theme.main.colors.clay3};
    font-size: 16px;
    line-height: unset;
    padding-left: 8px;
    padding-right: 32px;
  }
`;

const MainContainer = styled.div`
  padding: 24px 0 72px;
  display: flex;
  justify-content: center;

  .container {
    max-width: 960px;
    width: 100%;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    padding: 32px 0 56px;
  }
`;

const SkeletonWrapper = styled.div`
  .skeleton-add-segment {
    margin: 24px 0 32px;
    margin-left: auto;
  }

  .skeleton-header-row {
    display: flex;
    padding: 0 40px 24px;
  }

  .skeleton-table-row {
    display: flex;
    padding: 30px 40px;
    border-top: 1px solid ${(props) => props.theme.main.colors.clay4};
  }

  .skeleton-total-row {
    display: flex;
    padding: 25px 40px;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    .skeleton-header-row {
      .header-item-1 {
        margin-right: auto;
      }
      .header-item-3 {
        margin-left: 80px;
      }
      .header-item-4 {
        margin-left: 117px;
      }
    }

    .skeleton-table-row {
      .row-item-2 {
        margin-left: 24px;
        margin-right: auto;
      }
      .row-item-4 {
        margin-left: 56px;
      }
      .row-item-5 {
        margin-left: 48px;
      }
    }

    .skeleton-total-row {
      border-top: 3px solid ${(props) => props.theme.main.colors.clay4};

      .total-row-item-1 {
        margin-right: auto;
      }
      .total-row-item-3 {
        margin-left: 56px;
        margin-right: 213px;
      }
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    .skeleton-total-row {
      justify-content: space-between;
      padding: 52px 8px 24px;
    }

    .skeleton-mobile-table-row {
      border-top: 1px solid ${(props) => props.theme.main.colors.clay4};
      padding: 20px 8px 24px;

      > div {
        display: flex;
        justify-content: space-between;

        .row-item-2 {
          margin-right: 8px;
        }
        .row-item-3 {
          margin-right: 48px;
        }
      }

      .row-item-5 {
        margin-top: 16px;
      }
    }
  }
`;

type Props = {
  children: React.ReactNode;
};

const SkeletonContainer: FC<Props> = ({ children }) => {
  const TotalRow = () => (
    <div className='skeleton-total-row'>
      <Skeleton width={'52px'} height={18} className='total-row-item-1' />
      <Skeleton width={'48px'} height={18} className='total-row-item-2' />
      <Skeleton width={'56px'} height={18} className='total-row-item-3' />
    </div>
  );

  const HeaderRow = () => (
    <div className='skeleton-header-row'>
      <Skeleton width={'24px'} height={14} className='header-item-1' />
      <Skeleton width={'24px'} height={14} className='header-item-2' />
      <Skeleton width={'32px'} height={14} className='header-item-3' />
      <Skeleton width={'96px'} height={14} className='header-item-4' />
    </div>
  );

  const Row = () => (
    <div className='skeleton-table-row'>
      <Skeleton width={'8px'} height={20} className='row-item-1' />
      <Skeleton width={'56px'} height={20} className='row-item-2' />
      <Skeleton width={'48px'} height={20} className='row-item-3' />
      <Skeleton width={'56px'} height={20} className='row-item-4' />
      <Skeleton width={'165px'} height={20} variant='rectangular' className='row-item-5' />
    </div>
  );

  const MobileRow = () => (
    <div className='skeleton-mobile-table-row'>
      <div>
        <Skeleton width={'24px'} height={16} />
        <Skeleton width={'16px'} height={16} />
        <Skeleton width={'32px'} height={16} />
        <Skeleton width={'56px'} height={16} />
      </div>
      <div>
        <Skeleton width={'48px'} height={18} className='row-item-1' />
        <Skeleton width={'32px'} height={18} className='row-item-2' />
        <Skeleton width={'48px'} height={18} className='row-item-3' />
        <Skeleton width={'16px'} height={18} className='row-item-4' />
      </div>
      <Skeleton width={'128px'} height={32} variant='rectangular' className='row-item-5' />
    </div>
  );

  return (
    <Observer>
      {() => {
        const isLoading = progressStore.isLoading(INITIALIZE_SEGMENTS);

        if (isLoading) {
          return (
            <SkeletonWrapper>
              <ShowDesktop>
                <Skeleton width={'250px'} height={36} variant='rectangular' />
                <Skeleton width={'20%'} height={40} variant='rectangular' className='skeleton-add-segment' />
                <HeaderRow />
                {[...Array(5).keys()].map(() => (
                  <Row />
                ))}
                <TotalRow />
              </ShowDesktop>

              <ShowMobile>
                <Skeleton width={'210px'} height={26} variant='rectangular' />
                <TotalRow />
                {[...Array(5).keys()].map(() => (
                  <MobileRow />
                ))}
              </ShowMobile>
            </SkeletonWrapper>
          );
        }

        return <>{children}</>;
      }}
    </Observer>
  );
};

export const SegmentsContainer: FC<Props> = (props) => {
  useHeaderSettings({ variant: HeaderVariants.normal });

  useEffect(() => {
    mountSegments();

    return () => unmountSegments();
  }, []);

  return (
    <TestAnchorContext.Provider value={{ container: 'Profile-SegmentsList' }}>
      <Wrapper>
        <div className='navigation'>
          <Svg
            name='NavigateLeft'
            onClick={() => history.push(`${ROUTER.PROFILE}?tab=my_results&distanceType=cumulative`)}
            className='go-back-icon'
            {...testAnchors.button('Profile-SegmentsList', 'goBack')}
          />
          <Utility variant='u3' weight='bold' className='go-back-text'>
            {t.staticAsString('registration.back')}
          </Utility>
        </div>
        <MainContainer className='segments my-results'>
          <HideBodyImage />
          <div className='container' id={VIEWPORT_CONTAINER_ID}>
            <SkeletonContainer>
              <DistanceName />

              <ShowDesktop>
                <ButtonLog />
                <TableView />
                <Total />
              </ShowDesktop>

              <ShowMobile>
                <Total />
                <MobileView />
                <ButtonLog />
              </ShowMobile>
            </SkeletonContainer>
          </div>
        </MainContainer>
      </Wrapper>
    </TestAnchorContext.Provider>
  );
};
