import * as React from 'react';

export const ExportFinisherDiploma = (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1885_14450)'>
      <path d='M9.375 14.375H0.625V0.625H19.375V4.375' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.625 8.125L6.875 14.375' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.125 0.625L16.875 4.375' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13.5312 13.7358L12.8645 18.635C12.8408 18.7661 12.8595 18.9014 12.9179 19.0212C12.9764 19.1409 13.0715 19.2389 13.1895 19.3008C13.3075 19.3627 13.4422 19.3854 13.5739 19.3654C13.7057 19.3455 13.8276 19.2841 13.922 19.19L15.6295 17.4817L17.337 19.19C17.4314 19.2841 17.5534 19.3455 17.6851 19.3654C17.8169 19.3854 17.9516 19.3627 18.0696 19.3008C18.1876 19.2389 18.2827 19.1409 18.3412 19.0212C18.3996 18.9014 18.4183 18.7661 18.3945 18.635L17.7279 13.7358'
        stroke='#272E34'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.625 14.375C17.6961 14.375 19.375 12.6961 19.375 10.625C19.375 8.55393 17.6961 6.875 15.625 6.875C13.5539 6.875 11.875 8.55393 11.875 10.625C11.875 12.6961 13.5539 14.375 15.625 14.375Z'
        stroke='#272E34'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.625 6.875L6.875 0.625' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15.625 10.3125C15.5632 10.3125 15.5028 10.3308 15.4514 10.3652C15.4 10.3995 15.3599 10.4483 15.3363 10.5054C15.3126 10.5625 15.3064 10.6253 15.3185 10.686C15.3306 10.7466 15.3603 10.8023 15.404 10.846C15.4477 10.8897 15.5034 10.9194 15.564 10.9315C15.6247 10.9436 15.6875 10.9374 15.7446 10.9137C15.8017 10.8901 15.8505 10.85 15.8848 10.7986C15.9192 10.7472 15.9375 10.6868 15.9375 10.625C15.9375 10.5421 15.9046 10.4626 15.846 10.404C15.7874 10.3454 15.7079 10.3125 15.625 10.3125Z'
        stroke='#272E34'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1885_14450'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
