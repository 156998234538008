import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const RunningPath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M21.503 1.09138C19.5098 1.04306 19.3565 4.14777 20.9883 4.98133C22.0177 5.5612 22.999 4.49993 22.999 2.77058C23.0538 1.90077 22.3243 1.1397 21.503 1.09138ZM13.1472 2.77058C13.0815 2.77058 8.37242 6.66053 8.33956 6.73301C8.33956 6.74509 8.76666 7.38536 9.588 7.07127C9.68656 7.03503 13.2786 5.22293 13.3662 5.19877C13.9947 4.94664 15.1905 5.3415 15.874 5.41624L7.74819 14.0418C7.74819 14.0418 2.34923 14.6338 1.90023 14.9479C1.49736 15.2293 1.24404 15.666 1.08984 16.1197H8.5805L11 14.5C11.3614 14.2342 12.3418 12.9787 11.3343 11.75L12.731 12.9787C12.731 12.9787 17.3524 15.9264 17.3743 15.9626C17.3962 16.0109 16.8925 21.3989 16.9472 21.6889C17.0744 22.292 17.4856 22.6922 18.0424 22.909L19.707 14.7304C19.707 14.7304 15.6002 11.2633 15.6879 11.2149C15.8521 11.1183 19.5756 7.72362 19.5646 6.47932C19.6851 6.02025 19.7398 4.63099 18.8418 3.51957C18.5023 3.06051 13.191 2.74641 13.1472 2.74641V2.77058ZM19.1813 8.52094C19.0488 8.61842 18.2487 9.4437 18.4038 9.57197C18.6009 9.75318 20.4516 10.9975 20.6816 11.0941C21.1804 11.2968 21.7226 11.1157 22.16 10.8525C21.4184 10.0605 20.6623 9.05411 19.7398 8.47263C19.5646 8.37599 19.3456 8.38805 19.1923 8.50886L19.1813 8.52094Z'
    fill='currentColor'
  />
);

export const Running = (props: IconProps) => <SvgIcon path={RunningPath} {...props} />;
