import * as React from 'react';

import { FiltersFormMobile } from 'src/modules/results/components/Filters';
import { filtersOpenedStore } from 'src/modules/results/stores';

import { Search } from './Search';
import { FiltersStyledWrap } from './StyledMobile';

type Props = {
  distanceOptions: AnyObject;
  classOptions: AnyObject;
  waveOptions: AnyObject;
  gendersOptions: AnyObject;
  isGenderAvailable: boolean;
  results: number;
  show: boolean;
  isOpen: boolean;
};

export const MobileFilters: React.FC<Props> = (props) => {
  const { isOpen, ...filterProps } = props;

  const open = Boolean(filtersOpenedStore.value);

  const bodyElement = document.querySelector('body');
  const mainContent = document.querySelector('.main-content') as HTMLElement;

  bodyElement!.style.overflow = open ? 'hidden' : 'initial';
  mainContent!.style.overflow = open ? 'hidden' : 'initial';

  return (
    <>
      <Search show={props.show} />
      <FiltersStyledWrap isOpen={isOpen}>
        <FiltersFormMobile {...filterProps} />
      </FiltersStyledWrap>
    </>
  );
};
