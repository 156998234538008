import { EMPTY_FILTERS } from '../constants';

import { filterInternalFilters, distanceSelect, checkpointsSelect, classesSelect, wavesSelect, gendersSelect } from '../utils';

import { FrontendFilters, InternalFilters } from '../types';

class FrontendInternalMapper implements MapperMiddleware<FrontendFilters, InternalFilters> {
  mapTo(filters: FrontendFilters): InternalFilters {
    const class_id: string = filters.class_id?.value as any;
    const wave_id: string = filters.wave_id?.value as any;

    const gender = +(filters.gender?.value || EMPTY_FILTERS['gender']!);
    const name = filters.name;
    const checkpoint_id = parseInt(filters.checkpoint_id?.id as any, 10);
    const distance_id = parseInt(filters.distance_id?.value as any, 10);

    return filterInternalFilters({ class_id, gender, name, checkpoint_id, distance_id, wave_id });
  }

  mapFrom(value: InternalFilters): FrontendFilters {
    const distanceSelectItem = distanceSelect.findOption(value.distance_id!);
    const isDistinctClasses = distanceSelectItem.value === 0;

    return {
      checkpoint_id: checkpointsSelect.findOption(value.checkpoint_id!) || null,
      distance_id: distanceSelectItem,
      class_id: classesSelect.findOption(value.class_id!, isDistinctClasses),
      wave_id: wavesSelect.findOption(value.wave_id!, isDistinctClasses),
      name: value.name || '',
      gender: gendersSelect.findOption(value.gender!),
    };
  }
}

const frontendInternalMapper = new FrontendInternalMapper();
export { frontendInternalMapper };
