import * as React from 'react';
import styled from 'styled-components';

import { Utility } from 'src/styledComponents/Typography';

import { t } from 'utils';

import { windowSize } from 'stores';

const Wrapper = styled.div`
  background: ${(props) => props.theme.main.colors.clay1};
  padding: 16px;
  text-align: left;

  .title-text {
    color: ${(props) => props.theme.main.colors.white};
    padding: 16px 0;
    text-align: left;
  }
`;

export const ContactsTitle = () => {
  const isMobile = windowSize.isLessThan('small');
  const variant = isMobile ? 'u2' : 'u3';

  return (
    <Wrapper>
      <Utility weight='medium' variant={variant} className='title-text'>
        {t.staticAsString('race.contact')}
      </Utility>
    </Wrapper>
  );
};
