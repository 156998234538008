import { observable, action, makeObservable } from 'mobx';
import Scrollbar from 'react-scrollbars-custom';

class ScrollManager {
  @observable
  scrollRef: Scrollbar;

  @observable
  restoreToPositionY: number = 0;

  @observable
  scrollTop: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  saveScrollPosition() {
    if (this.scrollRef) {
      this.restoreToPositionY = this.scrollRef.getScrollState().scrollTop;
    }
  }

  @action
  clearScrollPosition() {
    this.restoreToPositionY = 0;
  }

  @action
  restoreScroll() {
    if (this.scrollRef) {
      this.scrollRef.scrollTop = this.restoreToPositionY;
    }
  }

  get shouldRestore() {
    return this.restoreToPositionY > 0;
  }
}

export { ScrollManager };
export default new ScrollManager();
