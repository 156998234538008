import * as React from 'react';
import styled from 'styled-components';

import { RaceImage } from 'src/styledComponents/ImageWithProportions';
import { Link } from 'src/styledComponents/Link';

import { LinkWithCondition } from './LinkWithCondition';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Body = styled.div`
  position: relative;
`;

// export const ImageBox = styled.div<{ bg: string; isPast?: boolean }>`
//   height: 170px;
//   background-color: ${(props) => props.theme.main.colors.clay3};
// background-image: url(${(props) => props.bg}), url(${DEFAULT_RACE_IMAGE});
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   ${(props) => (props.isPast ? 'filter: grayscale(1);' : '')}
// `;

export const ImageBoxContainer = styled.div<{ isPast?: boolean }>`
  background-color: ${(props) => props.theme.main.colors.clay3};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${(props) => (props.isPast ? 'filter: grayscale(1);' : '')}
`;

type Props = {
  bg: string;
  isPast?: boolean;
};

export const ImageBox = ({ bg, isPast }: Props) => {
  return (
    <ImageBoxContainer className='image-box' isPast={isPast}>
      <RaceImage src={bg} />
    </ImageBoxContainer>
  );
};

export const TransferRegistrationInProgressBox = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.isMobile ? '100%' : '300px')};
  height: ${(props) => (props.isMobile ? '100%' : '170px')};

  align-items: center;
  display: flex;
  justify-content: center;

  background: linear-gradient(0deg, rgba(39, 46, 52, 0.64), rgba(39, 46, 52, 0.64));

  .transfer-text {
    width: 180px;
    display: block;

    text-align: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.main.colors.white};
  }
`;

export const StyledLink = styled(Link)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const StyledLinkWithCondition = styled(LinkWithCondition)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const InlineFlex = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  div {
    min-height: 24px;
    min-width: 24px;
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  h1 {
    margin: 0;
    padding-bottom: 16px;
  }

  ${InlineFlex}:not(:last-child) {
    padding-bottom: 16px;
  }
`;

export const CardMark = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  background: ${(props) => props.theme.main.colors.clay1};

  span {
    color: ${(props) => props.theme.main.colors.white};
  }
`;

export const SportTag = styled(CardMark)`
  padding: 8px 20px 6px 8px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  opacity: 0.99;

  img {
    height: 20px;
    width: 20px;
    filter: invert(1);
  }
`;
