import { PlaceType } from 'modules/search/types';

import { AnyStore, Disposers, DisposersStore } from 'src/stores';

import { HeaderStore, HeaderVariants } from '../types';

export const headerStore = new AnyStore<HeaderStore>({
  variant: HeaderVariants.normal,
  hide: true,
});

export const autocompleteOptionsStore = new AnyStore<DropdownItemType[]>([]);
export const autocompleteSelectedStore = new AnyStore<boolean>(false);
export const sportsOptionsStore = new AnyStore<DropdownItemType[]>([]);
export const locationStore = new AnyStore<PlaceType[]>([]);
export const racesStore = new AnyStore<AnyObject[]>([]);
export const focusedStore = new AnyStore<string>('');
export const autocompleteOpenStore = new AnyStore<boolean>(false);
export const onAuthStore = new AnyStore<AnyFunction | null>();
export const disposers = new Disposers();

export const disposersStore = new DisposersStore();
