import * as React from 'react';
import styled, { css } from 'styled-components';

import { Show } from 'src/components/condition';
import { Navigation } from 'src/styledComponents/Navigation';

import { HEADER_ANIMATION_DURATION } from '../../constants';

type Field = {
  link: string;
  showInDesktop: boolean;
  titleLink: string;
  exact?: boolean;
  className?: string;
};

type Props = {
  navList: Field[];
  hidden?: boolean;
  isTablet?: boolean;
  compressed?: boolean;
};

const hide = (props) => {
  if (!props.compressed) return '';

  return css`
    height: 0;
    opacity: 0;
    margin: 0;
  `;
};

const Wrapper = styled.div<{ isTablet?: boolean; compressed?: boolean }>`
  position: ${(props) => props.isTablet && 'absolute'};
  top: 110px;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: height ${HEADER_ANIMATION_DURATION}, opacity ${HEADER_ANIMATION_DURATION}, margin ${HEADER_ANIMATION_DURATION};

  ${hide}

  ul {
    width: auto;
  }
  a,
  span {
    text-transform: capitalize;
  }
`;

export const NavigationMenu: React.FC<Props> = (props) => {
  const { navList, hidden, isTablet, compressed } = props;

  return (
    <Wrapper isTablet={isTablet} compressed={compressed}>
      <Show if={!hidden}>
        <Navigation navList={navList} />
      </Show>
    </Wrapper>
  );
};
