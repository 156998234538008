export const REGISTRATION_FIELDS_KEYS: registrationFields[] | any = [
  'email',
  'firstname',
  'lastname',
  'gender',
  'telephone',
  'company',
  'coupon_code',
  'emergency_name',
  'emergency_phone',
  'diseases',
  'blood_type',
  'address',
  'city',
  'country_id',
  'post_code',
  'birthday',
  'union',
  'shoe_size',
  'shirt_size',
];

export const REGISTRATION_FIELDS: {
  [K in registrationFields]: registrationFields;
} = REGISTRATION_FIELDS_KEYS.reduce((acc: AnyObject, field: AnyObject | any) => ({ ...acc, [field]: field }), {});
