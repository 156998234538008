import { ROUTER } from 'src/constants';

export const menuItems = [
  { title: 'raceidHome', link: ROUTER.HOME },
  { title: 'miles', link: ROUTER.MILES_TRAINING_PLAN },
  { title: 'raceidOrganizer', link: ROUTER.ORGANIZERS },
];

export const subMenuItems1 = [
  { title: 'giftCards', link: ROUTER.GIFT_CARDS },
  { title: 'aboutMiles', link: ROUTER.MILES, exact: true },
  { title: 'articlesLink', link: ROUTER.ARTICLES },
  { title: 'organizersQuickGuideLink', link: ROUTER.ORGANIZER },
  { title: 'faqLink', link: ROUTER.PUBLIC_FAQ },

  // { title: 'aboutRaceIdLink', link: ROUTER.ABOUT },
  // { title: 'press', link: ROUTER.PRESS },
];
export const subMenuItems2 = [
  { title: 'careers', link: ROUTER.CAREER },
  { title: 'termsAndPrivacyLink', link: ROUTER.PUBLIC_TERMS },
];
