import { LoadRacerService } from 'modules/racersTable/services';

import { CounterService } from './counter';
import { Distances } from './distances';
import { RacersService } from './racers';
import { TeamsService } from './teams';

const loadRacerService = new LoadRacerService();
const distancesService = new Distances();
const teamsService = new TeamsService();
const racersService = new RacersService();
const counterService = new CounterService();

export { distancesService, loadRacerService, teamsService, racersService, counterService };
export * from './distances';
export * from './teams';
