import axios from 'axios';
import shortid from 'shortid';

import { API_V2_URL, API_DISTANCE_RACERS } from 'src/constants';

import { action, request, encodeQuery } from 'utils';

import { loadingToolbelt } from '../utils';

const toolbelt = loadingToolbelt('startlist-racers');

type Response = {
  meta: PaginationMeta;
  data: RacersType[];
};

class RacersService {
  @request({ action: shortid() })
  request(id: number, params: Object): Promise<any> {
    const url = encodeQuery.generatePath(API_V2_URL + API_DISTANCE_RACERS, {
      id,
    });
    return axios.get(url, { params });
  }

  @action({ action: shortid() })
  async load(version: string, id: number, page: number, params: Object): Promise<Response | null> {
    const { isLoading, logStart, logEnd, key } = toolbelt(version, id, page);

    const requestParams = { ...params, page };

    if (isLoading()) {
      return null;
    }

    logStart();

    const [status, response] = await this.request(id, requestParams);

    logEnd();

    if (response.status === 500) {
      throw new Error(`SA. Interrupt eternal loading - ${key}`);
    }

    if (!status) {
      return null;
    }

    return {
      meta: response.data.meta.pagination,
      data: response.data.data,
    };
  }
}

export { RacersService };
