import * as React from 'react';

import { scrollStore } from 'stores';

type Props = {
  children: React.ReactNode;
  id?: string | number;
  errorMessage?: string;
  disabled?: boolean;
};

export class ScrollWrapper extends React.Component<Props> {
  inputRef: AnyObject;

  constructor(props: Props) {
    super(props);

    this.inputRef = React.createRef();
  }
  componentDidUpdate() {
    scrollStore.scrollToInvalidField();
  }

  render() {
    const { children, id, errorMessage, disabled } = this.props;

    if (!disabled) {
      scrollStore.registerInput({
        id,
        ref: this.inputRef,
        errorMessage,
      });
    }

    return (
      <div className='scroll-wrapper' ref={this.inputRef as any}>
        {children}
      </div>
    );
  }
}
