import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import { API_URL, API_REFRESH_TOKEN } from 'src/constants';

import sessionStore from 'stores/session';

let requestPromise: Promise<any> | null = null;

class Session {
  store: AnyObject;

  constructor(store: AnyObject) {
    this.store = store;
  }

  async refreshTokenRequest(): Promise<any> {
    return axios.patch(`${API_URL}${API_REFRESH_TOKEN}`);
  }

  async refreshToken(): Promise<any> {
    requestPromise = new Promise<void>(async (res, rej) => {
      try {
        const response = await this.refreshTokenRequest();
        this.store.login(response.data.data.token);
      } catch (error) {
        this.store.logout();
      } finally {
        requestPromise = null;
        res();
      }
    });
    return requestPromise;
  }

  async xrefreshTokenIfNeeded() {
    const { token } = this.store.session;
    if (!token) {
      return null;
    }

    const now = moment.utc().toDate().getTime();
    const decoded = jwtDecode(token);
    const expires = decoded.exp * 1000;

    if (requestPromise) {
      return requestPromise;
    }

    if (expires < now) {
      await this.refreshToken();
    }
  }
}

const sessionService = new Session(sessionStore);
export { Session, sessionService };
