import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';

import { ClassicResults } from './ClassicResults';
import { VirtualResults } from './VirtualResults';

type Props = {};

export class MyResultsRouter extends React.Component<Props> {
  render() {
    return (
      <Switch>
        <Route exact path={ROUTER_WITH_LOCALES.PROFILE_RESULTS_RACES} component={ClassicResults} />
        <Route exact path={ROUTER_WITH_LOCALES.PROFILE_RESULTS_VIRTUAL} component={VirtualResults} />
      </Switch>
    );
  }
}
