import { actionMenuStore } from 'src/modules/profile/stores';
import { ModalStoreType } from 'src/modules/transferRegistration/types';

import { sessionStore } from 'stores';

export const getModalStoreType = (racerId:number): ModalStoreType => {
  const user = sessionStore.getUserData();

  return {
    name: user.firstname + ' ' + user.lastname,
    email: user.email as string,
    distanceName: String(actionMenuStore.distance?.name),
    distanceId: Number(actionMenuStore.distance?.id),
    recipientEmail: '',
    racerId: racerId,
  };
};
