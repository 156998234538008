/*
 * Tautologiamm. Quia sic oportet
 */
import { pickBy } from 'lodash';

import { EMPTY_FILTERS } from '../constants';

import { InternalFilters, HistoryFilters } from '../types';

/*
 * Clean empty values
 * class_id can contain multiple ids for filtering in show all by multiple classes with the same name
 *
 * filter all negative values, and class_id if it cannot be parsed as number or zero
 * class_id example: 'number' or 'number|number'   ('|' - serialization separator)
 */
function filterInternalFilters(value: InternalFilters): InternalFilters {
  const parsedClassId = parseInt(value.class_id!, 10);
  const filtersForFiltering = { ...value, class_id: parsedClassId };

  // @ts-ignore
  const defaultFilteredData = pickBy<any, any>(filtersForFiltering, (filter) => !!filter);

  // Apply class_id as string if class_id could be parsed to number
  const classIdFilter = defaultFilteredData.class_id ? { class_id: value.class_id } : {};

  return { ...defaultFilteredData, ...classIdFilter };
}

/*
 * Clean history empty values
 */
function filterHistoryFilters(value: HistoryFilters): HistoryFilters {
  // @ts-ignore
  return pickBy<any, any>(value, (filter, key) => EMPTY_FILTERS[key] !== filter);
}

export { filterInternalFilters, filterHistoryFilters };
