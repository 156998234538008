import classNames from 'classnames';
import { observer } from 'mobx-react';
import { EditCell, AddResult, SegmentsLinkCell } from 'modules/profile/components';
import { getLength, calcProgress } from 'modules/profile/selections';
import * as React from 'react';

import { Link } from 'src/styledComponents/Link';

import { Show } from 'components/condition';

import { t } from 'utils';

import { Distance, Goal } from 'models';

import { cannotLogError } from '../../utils';

import { getDistanceUrl, openEditResult, onAddResult, onNavigateToSegments } from '../../actions';

import { virtualStore } from '../../stores';

import type { VirtualDistanceMapped } from '../../types';

type Props = {};

@observer
export class MobileList extends React.Component<Props> {
  getRacerResult = (distance: VirtualDistanceMapped): AnyObject => {
    return (distance?.racers || [])[0]?.result || {};
  };

  render(): Array<React.ReactNode> {
    return virtualStore.list.map((distance: VirtualDistanceMapped) => {
      const distanceModel = new Distance(distance as any);
      const result = this.getRacerResult(distance);
      const goalModel = new Goal((distance.goal || {}) as any);

      const length = getLength(distance);
      const disableDistanceTitle = !distance.show_results || !distance.race?.show_results;

      const place = result.place || '- -';

      const borderLeftColor = distance.race.sport?.hex_code && `#${distance.race?.sport?.hex_code}`;
      const isLogResultsDisabled = distanceModel.isLoggingClosed();
      const percents = calcProgress(distance.resultData.distance_logged, distance.goal?.goal_max_length);

      const lengthTitle = goalModel.isGoalType('altitude')
        ? 'profile.registeredTable.head.altitude'
        : 'profile.registeredTable.head.distance';

      return (
        <div
          className='distance-card'
          style={{
            borderLeftColor: borderLeftColor ? borderLeftColor : 'inherit',
          }}
          key={`mobile:${distance.id}`}
        >
          <div className='name'>
            <Link
              className={classNames('distance-title', { disabled: disableDistanceTitle })}
              to={getDistanceUrl(distance.id, distance.race.id, distance.type)}
            >
              {t(() => distanceModel.value.name)}
            </Link>
          </div>
          <div className='additional-info'>
            <div className='info info-place'>
              <div className='info-title'>{t('profile.registeredTable.head.place')}</div>
              <div className='info-value'>{place}</div>
            </div>
            <div className='info info-length'>
              <div className='info-title'>{t(lengthTitle)}</div>
              <div className='info-value'>{length}</div>
            </div>
            <div className='info info-time'>
              <div className='info-title'>{t('profile.registeredTable.head.time')}</div>
              <div className='info-value'>{distance.resultData.time_result}</div>
            </div>

            <div className='info info-percents'>
              <Show if={distanceModel.isMode('cumulative') && !!percents}>
                <div className='info-title'>{t('profile.registeredTable.head.completed')}</div>
                <div className='info-value'>{percents}</div>
              </Show>
            </div>
          </div>
          <AddResult
            disabled={isLogResultsDisabled}
            disabledReason={cannotLogError(distanceModel)}
            expandButton={
              <React.Fragment>
                <SegmentsLinkCell
                  display={distance.editMode && distanceModel.isMode('cumulative')}
                  hide={false}
                  onClick={onNavigateToSegments(distance)}
                />
                <EditCell
                  display={distance.editMode && distanceModel.isMode('virtual')}
                  hide={distanceModel.isLoggingClosed()}
                  onClick={openEditResult(distance.racers[0].id)}
                />
              </React.Fragment>
            }
            editMode={distance.editMode}
            isAltitude={goalModel.isGoalType('altitude')}
            isLength={goalModel.isGoalType('distance')}
            link={distance.resultData.activity_link}
            onClick={() => {}}
            // onClick={onAddResult(distance)}
          />
        </div>
      );
    });
  }
}
