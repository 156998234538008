import { omit } from 'lodash';
import * as React from 'react';

import { resize } from 'utils';

type Options = {
  desktopOnly?: boolean;
};

// TODO, use /utils/blockScroll instead of code in componentDidMount and componentWillUnmount
export const withBlockedScroll =
  ({ desktopOnly = false }: Options) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class extends React.Component<any> {
      componentDidMount() {
        const body = document.body;

        if (!body) {
          return;
        }

        if (desktopOnly && resize.isMobile()) {
          return;
        }

        body.style.overflow = 'hidden';
      }

      componentWillUnmount() {
        const body = document.body;

        if (!body) {
          return;
        }

        if (desktopOnly && resize.isMobile()) {
          return;
        }

        // @ts-ignore
        body.style = omit(body.style, ['overflow']);
      }

      render() {
        return <WrappedComponent {...(this.props as any)} />;
      }
    } as any;
