import { observable, action, makeAutoObservable } from 'mobx';

class ServiceFeeStore {
  @observable serviceFee: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  clear() {
    this.serviceFee = 0;
  }

  @action
  setServiceFee = (serviceFee: number) => {
    this.serviceFee = serviceFee;
  };
}

export { ServiceFeeStore };
