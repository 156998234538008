import { generateGetRaceId } from 'modules/racersTable/utils';

import { ROUTER_WITH_LOCALES } from 'src/constants';

import { iFrameDetect } from 'utils';

const getRaceId = generateGetRaceId(
  iFrameDetect().isWithinIFrame ? ROUTER_WITH_LOCALES.IFRAME_RACE_RESULT : ROUTER_WITH_LOCALES.RACE_RESULT,
);

export { getRaceId };
