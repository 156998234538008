import styled from 'styled-components';

type Props = {
  light?: boolean;
};

export const Divider = styled.div<Props>`
  height: 2px;
  width: 100%;
  background-color: ${(props) => (props.light ? props.theme.main.colors.clay4 : props.theme.main.colors.clay2)};
  margin: 10px 0;
`;
