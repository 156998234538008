import { Observer } from 'mobx-react';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import PatternImage from 'src/assets/images/illistrations/topographic-pattern.svg';
import { RESOLUTIONS } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { PageContent } from 'src/styledComponents/PageContent';
import { Skeleton } from 'src/styledComponents/Skeleton';

import { windowSize } from 'stores';

import { TabletLessThan920, TabletMoreThan920 } from './teaser';

const EmptySpace = styled.div<{ width?: string; height?: string }>`
  min-height: ${(props) => props.height ?? '100%'};
  min-width: ${(props) => props.width ?? '100%'};
`;

const RaceInfo = styled(PageContent)<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${(props) => props.theme.main.colors.white};
  background-image: url(${PatternImage});
  width: 100%;

  .flex-row {
    display: flex;
    align-items: center;
  }

  .section-content {
    margin: 0 auto;
    padding: 0;
    padding: 32px 0 24px;
  }

  .race-info-container {
    display: flex;
    width: 100%;

    .race-image {
      aspect-ratio: 16/9;
      height: ${(props) => (props.isDesktop ? '292px' : 'auto')};
      width: ${(props) => (props.isDesktop ? '519px' : '100%')};
      flex: ${(props) => (props.isDesktop ? '0 1 auto' : '7 1 0%')};
    }

    .race-info-body {
      padding-left: ${(props) => (props.isDesktop ? '48px' : '30px')};
      flex: 7 1 0%;
      align-items: start;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: ${RESOLUTIONS.medium}px) {
    background-image: none;

    > .section-content {
      padding: 16px 20px !important;
    }

    .controls {
      display: flex;
      width: 100%;

      .favourite-icon {
        margin-left: auto;
        margin-right: 8px;
      }
    }

    .race-image {
      flex: 7 1 0%;
      width: 100vw;
      max-width: unset;
      aspect-ratio: 16/9;
      margin-left: -20px;
      height: auto;
    }
  }
`;

export const RaceInfoSkeleton: FC = () => (
  <Observer>
    {() => {
      const isDesktop = windowSize.isGreaterThan('large');
      return (
        <RaceInfo isDesktop={isDesktop}>
          <ShowDesktop>
            <Skeleton width='210px' height={24} variant='rectangular' />
            <EmptySpace height='48px' />
            <TabletLessThan920>
              <Skeleton width='100%' height={48} variant='rectangular' />
              <EmptySpace height='32px' />
              <Skeleton width='120px' height={18} variant='rectangular' />
              <EmptySpace height='12px' />
              <div className='flex-row'>
                {[...Array(3).keys()].map((_item, index) => (
                  <React.Fragment key={`race-info-circular-${index}`}>
                    <Skeleton width={32} height={32} variant='circular' />
                    <EmptySpace width='8px' height='32px' />
                  </React.Fragment>
                ))}
              </div>
              <EmptySpace height='16px' />
            </TabletLessThan920>
            <TabletMoreThan920>
              <Skeleton width='100%' height={48} variant='rectangular' />
              <EmptySpace height='32px' />
            </TabletMoreThan920>
            <div className='race-info-container'>
              <Skeleton variant='rectangular' className='race-image' />

              <div className='race-info-body'>
                <Skeleton width={isDesktop ? '400px' : '100%'} height={52} variant='text' />
                <EmptySpace height='16px' />
                <Skeleton width={isDesktop ? '400px' : '100%'} height={24} variant='text' />
                <Skeleton width='100%' height={22} variant='text' />
                <Skeleton width='100%' height={22} variant='text' />
                <EmptySpace height='56px' />
                <TabletMoreThan920>
                  <div className='flex-row'>
                    {[...Array(3).keys()].map((_item, index) => (
                      <React.Fragment key={`race-info-circular-${index}`}>
                        <Skeleton width={32} height={32} variant='circular' />
                        <EmptySpace width='8px' height='32px' />
                      </React.Fragment>
                    ))}
                  </div>
                </TabletMoreThan920>
              </div>
            </div>
          </ShowDesktop>

          <ShowMobile>
            <div className='controls'>
              <Skeleton width={24} height={24} variant='rectangular' />
              <EmptySpace width='8px' />
              <Skeleton width={40} height={24} variant='rectangular' />
              <Skeleton width={24} height={24} variant='circular' className='favourite-icon' />
            </div>
            <EmptySpace height='48px' />

            <Skeleton width={200} height={36} variant='text' />
            <EmptySpace height='24px' />
            <Skeleton width='120px' height={18} variant='rectangular' />
            <EmptySpace height='12px' />
            <div className='flex-row'>
              {[...Array(3).keys()].map((_item, index) => (
                <React.Fragment key={`race-info-${index}`}>
                  <Skeleton width='32px' height={32} variant='circular' />
                  <EmptySpace height='32px' width='8px' />
                </React.Fragment>
              ))}
            </div>
            <EmptySpace height='24px' />

            <Skeleton variant='rectangular' className='race-image' />
            <EmptySpace height='16px' />

            {[...Array(2).keys()].map((_item, index) => (
              <React.Fragment key={`race-info-next-${index}`}>
                <EmptySpace height='8px' />
                <div className='flex-row'>
                  <Skeleton width={24} height={24} variant='rectangular' />
                  <EmptySpace width='8px' />
                  <Skeleton width={130} height={16} variant='text' />
                </div>
              </React.Fragment>
            ))}
          </ShowMobile>
        </RaceInfo>
      );
    }}
  </Observer>
);
