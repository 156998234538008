import { cloneDeep } from 'lodash';

export const mapForSquads = (data: any): Array<NewSelectItemType> => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    return {
      key: item.id,
      value: item.id,
      label: item.name,
    };
  });
};
