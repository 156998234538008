import { truncate } from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { DEFAULT_RACE_IMAGE, LOAD_RACE, RESOLUTIONS } from 'src/constants';
import { RaceTabTitle } from 'src/modules/race/components';
import { ContactsDescription, ContactsTitle } from 'src/modules/race/components/information';
import { PageContent } from 'src/styledComponents/PageContent';
import { BodyTextStyles } from 'src/styledComponents/Typography';

import SEO from 'components/SEO';

import { htmlSanitizer, stripHtml, t } from 'utils';

import { progressStore, racesStore, windowSize } from 'stores';

import { InformationSkeleton } from './Skeletons';

type Props = {};

const Wrapper = styled.div<{ isMobile: boolean }>`
  padding-bottom: ${(props) => (props.isMobile ? '73' : '120')}px;
  .text {
    color: ${(props) => props.theme.main.colors.clay2};
    font-family: 'Aeonik', sans-serif;
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const Column = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1280px;
  align-items: flex-start;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
`;

const LeftRow = styled.div<{ isMobile: boolean }>`
  flex: 1;
  max-width: ${(props) => (props.isMobile ? '' : '630px')};

  h1 {
    font-size: 2em;
  }
`;

const RightRow = styled.div<{ isMobile: boolean }>`
  flex: 1;
  width: ${(props) => (props.isMobile ? 'auto' : '542px')};
  padding: ${(props) => (props.isMobile ? '0' : '65px 7px 0 108px')};
  @media (min-width: ${RESOLUTIONS.medium}px) and (max-width: 1010px) {
    padding-left: 60px;
  }
  margin-top: ${(props) => (props.isMobile ? '14px' : '0')};
`;

const TextWrapper = styled.div`
  ${BodyTextStyles.body2};
  iframe {
    width: 100%;
  }
`;

export const InformationContainer = (props: Props) => {
  const racesModel: AnyObject = racesStore.modelSelectedValue || {};
  const value = racesModel && racesModel.value;
  const isMobile = windowSize.isLessThan('medium');

  return (
    <Observer>
      {() => {
        if (progressStore.isLoading(LOAD_RACE)) {
          return <InformationSkeleton />;
        }

        if (!value) {
          return null;
        }

        return (
          <Wrapper isMobile={isMobile}>
            <SEO
              title={racesStore.selected?.name}
              description={truncate(stripHtml(racesStore.selected?.description ?? ''), { length: 250 })}
              ogImage={racesStore.selected?.image || DEFAULT_RACE_IMAGE}
            />
            <PageContent>
              <Column isMobile={isMobile}>
                <LeftRow isMobile={isMobile}>
                  <RaceTabTitle text={t.staticAsString('race.aboutTheRace')} />
                  <TextWrapper className='text'>
                    <div dangerouslySetInnerHTML={{ __html: htmlSanitizer(value.description) }} />
                  </TextWrapper>
                </LeftRow>
                <RightRow isMobile={isMobile}>
                  <ContactsTitle />
                  <ContactsDescription contacts={value.contacts || {}} />
                </RightRow>
              </Column>
            </PageContent>
          </Wrapper>
        );
      }}
    </Observer>
  );
};
