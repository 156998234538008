import * as React from 'react';

export const Delete = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M2.83337 6.16675H21.1667' stroke='#656B6F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13.875 2.83337H10.125C9.79348 2.83337 9.47554 2.96507 9.24112 3.19949C9.0067 3.43391 8.875 3.75185 8.875 4.08337V6.16671H15.125V4.08337C15.125 3.75185 14.9933 3.43391 14.7589 3.19949C14.5245 2.96507 14.2065 2.83337 13.875 2.83337Z'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.125 16.7917V10.5417' stroke='#656B6F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13.875 16.7917V10.5417' stroke='#656B6F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M17.7167 20.0167C17.6935 20.3299 17.5525 20.6227 17.3221 20.8361C17.0917 21.0494 16.789 21.1676 16.475 21.1667H7.525C7.21097 21.1676 6.90828 21.0494 6.67789 20.8361C6.4475 20.6227 6.30652 20.3299 6.28333 20.0167L5.125 6.16675H18.875L17.7167 20.0167Z'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
