import { compact } from 'lodash';
import { computed } from 'mobx';

import { AdditionalRegistrationFields, RegistrationFields } from '../constants';

import { fieldIdUtils } from '../utils';

import { disabledCustomFieldsStore } from '../stores';

import { formDataSelectors } from './formDataSelectors';
import { mainConditions } from './mainConditions';
import { distanceSelector } from './mappedData';
import { selectOptions } from './selectOptions';

/**
 * @description
 * Is field should be displayed conditions
 * used for the validation rules and UI
 */
const fieldConditions = {
  isRacerRegistrationFieldEnabled: (fieldName: RegistrationFields): boolean => {
    const field = distanceSelector.expandedRegistrationFields.get()[fieldName];

    if (!field) {
      return false;
    }

    return Boolean(field.selected);
  },

  isRacerRegistrationFieldRequired: (fieldName: RegistrationFields): boolean => {
    const field = distanceSelector.expandedRegistrationFields.get()[fieldName];

    if (!field) {
      return false;
    }

    return Boolean(field.required);
  },

  isCouponCodeFieldEnabled: computed((): boolean => {
    const field = distanceSelector.expandedRegistrationFields.get()[AdditionalRegistrationFields.coupon_code];
    return field?.selected;
  }),

  isRacerClassFieldEnabled: computed((): boolean => {
    return mainConditions.isSingle.get() && mainConditions.isClassesEnabled.get();
  }),

  isRacerDisciplineFieldEnabled: computed((): boolean => {
    const isTeamMemberConfirmation = mainConditions.isTeam.get() && mainConditions.isConfirmation.get();

    return mainConditions.isDisciplinesEnabled.get() && !isTeamMemberConfirmation;
  }),

  isRacerWaveFieldEnabled: () => {
    return mainConditions.isSingle.get() && mainConditions.isWavesEnabled.get();
  },

  isAllWavesSoldOut: (fieldId: string) => {
    const isValueNotNil = formDataSelectors.isFormValueNotNil(fieldId);
    return !(isValueNotNil || selectOptions.isWaveOptionAvailable.get());
  },

  isTeamClassFieldEnabled: computed((): boolean => {
    return mainConditions.isClassesEnabled.get();
  }),

  isTeamWaveFieldEnabled: computed((): boolean => {
    const isValueNotNil = formDataSelectors.isFormValueNotNil(fieldIdUtils.teamRegistrationWaveId());

    return mainConditions.isWavesEnabled.get() && (selectOptions.isWaveOptionAvailable.get() || isValueNotNil);
  }),

  /**
   * @description
   * List of enabled addtional racer fields specifically for each group registration or team registration member
   */
  racerAdditionalFields: (): AdditionalRegistrationFields[] => {
    return compact([
      fieldConditions.isRacerWaveFieldEnabled() && AdditionalRegistrationFields.wave_id,
      fieldConditions.isRacerClassFieldEnabled.get() && AdditionalRegistrationFields.class_id,
      fieldConditions.isRacerDisciplineFieldEnabled.get() && AdditionalRegistrationFields.discipline_id,
    ]);
  },

  /**
   * @description
   * List of enabled addtional team fields only for team
   */
  teamAdditionalFields: (): AdditionalRegistrationFields[] => {
    return compact([
      fieldConditions.isTeamWaveFieldEnabled.get() && AdditionalRegistrationFields.wave_id,
      fieldConditions.isTeamClassFieldEnabled.get() && AdditionalRegistrationFields.class_id,
    ]);
  },

  isCustomFieldHidden: (field: CustomFields, fieldId: string) =>
    computed((): boolean => {
      if (!mainConditions.isTransferredRegistration.get() && !mainConditions.isEditRegistration()) return false;
      if (disabledCustomFieldsStore.isDisabledByFieldId(fieldId)) return false;

      return (field.values || []).some((value) => Number(value.price));
    }),
};

export { fieldConditions };
