import { windowSize } from 'stores';

import { filtersOpenedStore } from '../../stores';

function toogleFiltersOpened() {
  if (!windowSize.isLessThan('large')) {
    return;
  }

  filtersOpenedStore.toogle();
}

export { toogleFiltersOpened };
