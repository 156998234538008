import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { Distance } from 'models';

import { FiltersStore, DistancesStore } from '../stores';

export type currentDistanceType = {
  id: IComputedValue<number | null>;
  isEmpty: IComputedValue<boolean>;
  isSingle: IComputedValue<boolean>;
  isTeam: IComputedValue<boolean>;
  model: IComputedValue<Distance | null>;
};

function generateCurrentDistance(filtersStore: FiltersStore<AnyObject>, distancesStore: DistancesStore): currentDistanceType {
  const id: IComputedValue<number | null> = computed((): number | null => {
    return filtersStore.value.distance_id || null;
  });

  const model: IComputedValue<Distance | null> = computed((): Distance | null => {
    const distanceId = id.get();

    if (!distanceId) {
      return null;
    }

    return distancesStore.find(distanceId) || null;
  });

  const isEmpty: IComputedValue<boolean> = computed((): boolean => {
    return !!model.get();
  });

  function type(type: 'single' | 'team'): IComputedValue<boolean> {
    return computed(() => model.get()?.value?.type === type);
  }

  const currentDistance = {
    id,
    model,
    isEmpty,
    isSingle: type('single'),
    isTeam: type('team'),
  };

  return currentDistance;
}

export { generateCurrentDistance };
