import { first } from 'lodash';
import { transaction } from 'mobx';

import { dataStore, paginationMetaStore, timeLeadersStore } from '../stores';

import type { LResult } from '../types';

function add(id: number, page: number, data: LResult[], meta: PaginationMeta | null) {
  transaction(() => {
    dataStore.setValues(id, page, data);

    if (meta) {
      paginationMetaStore.setValue(id, meta);
    }

    if (page === 1) {
      const timeLeader = first(data);
      timeLeader && timeLeadersStore.register(id, timeLeader);
    }
  });
}

export const dataChanger = {
  add,
};
