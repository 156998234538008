import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { ROUTER_WITH_LOCALES } from 'src/constants';

const MilesLanding = lazy(() =>
  import(
    /* webpackChunkName: "MilesLanding" */
    'modules/miles'
  ).then((m) => ({ default: m.MilesLanding })),
);

const MilesSignIn = lazy(() =>
  import(
    /* webpackChunkName: "MilesSignIn" */
    'modules/miles/app/form'
  ).then((m) => ({ default: m.MilesSignIn })),
);

const MilesHome = lazy(() =>
  import(
    /* webpackChunkName: "MilesHome" */
    'modules/miles/app/home'
  ).then((m) => ({ default: m.Base })),
);

const MilesSettings = lazy(() =>
  import(
    /* webpackChunkName: "MilesSettings" */
    'modules/miles/app/milesSettings'
  ).then((m) => ({ default: m.MilesSettings })),
);

const MilesDailyData = lazy(() =>
  import(
    /* webpackChunkName: "MilesDailyData" */
    'modules/miles/app/dailyData'
  ).then((m) => ({ default: m.MilesDailyData })),
);

const MilesRenew = lazy(() =>
  import(
    /* webpackChunkName: "MilesRenew" */
    'modules/miles/app/form'
  ).then((m) => ({ default: m.RenewFullScreen })),
);

export const generateMilesRoutes = () => {
  return [
    <Route path={ROUTER_WITH_LOCALES.MILES_TRAINING_PLAN} component={MilesHome} key='route-miles-landing' />,
    <Route path={ROUTER_WITH_LOCALES.MILES_SIGN_IN} component={MilesSignIn} key='route-miles-sign-in' />,
    <Route path={ROUTER_WITH_LOCALES.MILES_SETTINGS} component={MilesSettings} key='route-miles-settings' />,
    <Route path={ROUTER_WITH_LOCALES.MILES_DAILY_DATA} component={MilesDailyData} key='route-miles-daily-data' />,
    <Route path={ROUTER_WITH_LOCALES.MILES} component={MilesLanding} key='route-miles-landing' exact />,
    <Route path={ROUTER_WITH_LOCALES.MILES_RENEW_SUBSCRIPTION} component={MilesRenew} key='route-miles-renew' exact />,
  ];
};
