import axios from 'axios';
import { racesService } from 'modules/home/services';

import { API_URL, HEADER_FORM, API_PLACES, API_SEARCH } from 'src/constants';
import { form } from 'src/stores';
import { request, action } from 'src/utils';

import { GET_RECOMENDED_RACES, GET_SEARCH_LOCATIONS, GET_SEARCH_RACES } from '../constants';

import { mapLocations, mapSearchRacesResults } from '../utils';

import { locationStore, racesStore, recomendedRacesStore } from '../stores';

import { FieldNames } from '../types';

class SearchResults {
  @request({ action: GET_SEARCH_LOCATIONS })
  async getPlacesRequest(input: string): Promise<any[]> {
    const params = {
      input,
      types: '(regions)',
    };

    return axios.get(`${API_URL}${API_PLACES}`, { params });
  }

  @action({ action: GET_SEARCH_LOCATIONS })
  async getLocation() {
    const location = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.location) || '';

    const [status, response] = await this.getPlacesRequest(location);

    if (status) {
      const locations = mapLocations(response.data.predictions);
      locationStore.setValue(locations);
      return;
    }
  }

  @request({ action: GET_SEARCH_RACES })
  getRacesRequest(raceName: string): Promise<any> {
    return axios.post(`${API_URL}${API_SEARCH}`, { ['term']: raceName });
  }

  @action({ action: GET_SEARCH_RACES })
  async getRaces() {
    const raceName = form.fetchWithoutObservable<string>(HEADER_FORM, FieldNames.raceName);
    if (!raceName) {
      return;
    }
    if (raceName && raceName.length < 2) {
      return;
    }
    const [status, racesResponse] = await this.getRacesRequest(raceName);

    if (status) {
      const races = mapSearchRacesResults(racesResponse.data.data);
      racesStore.setValue(races);
      return;
    }
  }

  @action({ action: GET_RECOMENDED_RACES })
  async getRecomendedRaces() {
    const [status, response] = await racesService.getRacesRequest(null);
    if (status) {
      const races = mapSearchRacesResults(response.data.data);
      recomendedRacesStore.setValue(races);
    }
    return;
  }
}

export const searchService = new SearchResults();
