import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { Button, Display, SizesText, Text } from 'modules/miles/shared/components';
import React from 'react';
import styled from 'styled-components';

import { ROUTER } from 'src/constants';
import { Icon } from 'src/styledComponents/Icons';

import { theme } from 'styles';

import { history, t } from 'utils';

import { sessionStore } from 'stores';

import { useImageWidth, useRaceDetails } from '../hooks';

const Wrapper = styled.div`
  padding: 32px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: ${(props) => props.theme.miles.colors.dark.background[600]};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.52);
  button {
    margin: 20px 0 8px;
  }
`;

const PictureBlock = styled.div<{ imageUrl?: string; pictureWidth: number }>`
  background: ${({ theme }) => theme.miles.colors.brand.grad.c900C70045};
  height: 184px;
  max-width: 440px;
  width: 100%;
  margin: 16px 0;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${theme.miles.colors.light.alpha[300]};
  box-shadow: ${theme.miles.shadows.light.elevetion2Strong};

  .image {
    border-radius: 4px;
    background-image: url(${(props) => props.imageUrl});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
    position: absolute;
    width: ${(props) => props.pictureWidth}px;
    height: 184px;
  }
`;
const IconsBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 11px;
`;

const IconElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
`;

export const Embark = observer(() => {
  const detailsData = useRaceDetails();
  const userName = sessionStore.getUserData().firstname;

  if (isEmpty(detailsData) || !userName) {
    return (
      <Wrapper>
        <Display style={{ textAlign: 'center', marginBottom: '16px', letterSpacing: '1px' }} size={SizesText.md} weight='bold'>
          {t('miles.landing.no-race-cta.title')}
        </Display>
        <Text size='md' weight='regular' style={{ maxWidth: '278px' }}>
          {t('miles.landing.no-race-cta.subtext')}
        </Text>
        <Button onClick={() => history.push(ROUTER.HOME)} message='main' size='l' mode='dark'>
          {t('miles.landing.no-race-cta.button')}
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Display style={{ textAlign: 'center', marginBottom: '16px', letterSpacing: '1px' }} size={SizesText.md} weight='bold'>
        Embark on Your Best Race with Miles
      </Display>
      {(detailsData.timeLeft !== undefined || null) && (
        <Text size='lg' fontStyle='italic' weight='regular'>
          {userName} , you have {detailsData.timeLeft} until the big day!
        </Text>
      )}
      <Picture detailsData={detailsData} />
      <Text size='md' weight='regular' style={{ maxWidth: '278px' }}>
        Achieve your full potential.
        <br /> Reach your running goals.
        <br /> Maximize your race performance.
      </Text>
      <Button onClick={() => history.push(ROUTER.MILES_SIGN_IN)} message='main' size='l' mode='dark'>
        Try Miles For Free
      </Button>
      <Text size='md' weight='regular'>
        No Credit Card Required!
      </Text>
    </Wrapper>
  );
});

const Picture = ({ detailsData }) => {
  const { pictureWidth, pictureRef } = useImageWidth();
  return (
    <PictureBlock imageUrl={detailsData.image} ref={pictureRef} pictureWidth={pictureWidth || 440}>
      <div className='image' />
      <Text size='xl' weight='bold' style={{ marginBottom: '3px', zIndex: 1 }}>
        {detailsData.raceName}
      </Text>
      <Text size='md' weight='regular' style={{ zIndex: 1 }}>
        {detailsData.distanceName}
      </Text>
      <IconsBlock style={{ zIndex: 1 }}>
        <IconElement>
          <Icon name='Calendar' size={15} />
          <Text style={{ marginTop: '7px' }} size='md' weight='regular'>
            {detailsData.date}
          </Text>
        </IconElement>
        <IconElement>
          <Icon name='Flag-Distance-Far' size={20} />
          <Text style={{ marginTop: '4px' }} size='md' weight='regular'>
            {detailsData.length}
          </Text>
        </IconElement>
      </IconsBlock>
    </PictureBlock>
  );
};
