import { generateGenderValues } from 'utils';

export const userFieldsType = {
  firstname: {
    presence: {
      allowEmpty: false,
      message: 'settings.firstName validation.required',
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: 'settings.lastName validation.required',
    },
  },
  address: {
    presence: {
      allowEmpty: false,
      message: 'settings.address validation.required',
    },
  },
  post_code: {
    presence: {
      allowEmpty: false,
      message: 'settings.post_code validation.required',
    },
    format: {
      pattern: /^[A-Za-z0-9\s-]+$/,
      message: '^validation.invalidFormat',
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'settings.city validation.required',
    },
  },
  telephone: {
    phoneNumber: {
      allowEmpty: false,
      message: 'settings.phone_number validation.required',
    },
  },
  country_id: {
    presence: {
      allowEmpty: false,
      message: 'settings.country validation.required',
    },
  },
  nationality_id: {
    presence: {
      allowEmpty: false,
      message: 'settings.nationality_id validation.required',
    },
  },
  email: {
    email: {
      message: 'login.email validation.notValid',
    },
  },
  birthday: {
    presence: {
      allowEmpty: false,
      message: 'settings.birthday validation.required',
    },
    datetime: {
      dateOnly: true,
      message: 'settings.birthday validation.notValid',
    },
  },
  gender: {
    presence: {
      message: 'settings.gender validation.required',
    },
    inclusion: {
      within: generateGenderValues(),
      message: 'settings.gender validation.required',
    },
  },
};

export type UserFieldsKeys = keyof typeof userFieldsType;

export const userPasswordFieldsType = {
  current_password: {
    presence: {
      allowEmpty: false,
      message: 'settings.current_password validation.required',
    },
    length: {
      minimum: 6,
      message: 'settings.passwordToShort',
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'settings.password validation.required',
    },
    length: {
      minimum: 6,
      message: 'settings.passwordToShort',
    },
  },
  password_confirmation: {
    equality: {
      attribute: 'password',
      message: 'settings.password_confirm_not_eql_pass',
    },
    presence: {
      allowEmpty: false,
      message: 'settings.password_confirmation validation.required',
    },
  },
};

export const moreInfoValidation = {
  diseases: {
    length: {
      maximum: 255,
      message: '^settings.too_long_diseases',
    },
  },
  emergency_phone: {
    phoneNumber: {
      allowEmpty: true,
    },
  },
  external_swimrun_id: {
    length: { maximum: 250 },
    format: {
      pattern: /^([\w\d-_])*$/,
      message: `^settings.external_swimrun_id validation.pattern1_lndu`,
    },
  },
};
