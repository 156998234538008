import React from 'react';
import styled from 'styled-components';

import { RESOLUTIONS, ROUTER } from 'src/constants';
import { Button } from 'src/styledComponents/Button';

import { fireToast, history, t } from 'utils';

import { sessionStore } from 'stores';

import { userProfile } from '../services';

const Controls = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 64px;

  > *:not(:first-child) {
    margin-bottom: 24px;
  }

  @media (min-width: ${RESOLUTIONS.medium}px) {
    flex-direction: row;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 32px;
      margin-bottom: 0;
    }
    > *:not(:first-child) {
      margin-bottom: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 16px 48px;
  span {
    font-size: 20px;
  }
`;

const StyledDeleteButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.main.colors.error};
  border-color: ${(props) => props.theme.main.colors.error};
  color: ${(props) => props.theme.main.colors.white};
  &:hover {
    background-color: ${(props) => props.theme.main.colors.error};
    border-color: ${(props) => props.theme.main.colors.error};
    color: ${(props) => props.theme.main.colors.white};
  }
`;

const FormControls = ({ onSubmit, showDeleteButton = false }) => {
  const navigateToProfile = (): void => history.push(ROUTER.PROFILE);

  const handleConfirmDelete = async (): Promise<void> => {
    const isConfirmed = window.confirm(t.staticAsString('settings.deleteAccountConfirmation' as any));
    if (isConfirmed) {
      userProfile.deleteProfile();
      sessionStore.logout();
      fireToast('Your account has been deleted, contact support if you will change your mind', 'success');
    }
  };

  return (
    <Controls>
      {showDeleteButton && (
        <StyledDeleteButton variant='primary' size='medium' onClick={handleConfirmDelete} ATN='delete'>
          {t('settings.deleteAccount' as any)}
        </StyledDeleteButton>
      )}
      <StyledButton variant='secondary' size='medium' onClick={navigateToProfile} ATN='cancel'>
        {t('settings.back')}
      </StyledButton>
      <StyledButton variant='primary' size='medium' onClick={onSubmit} ATN='submit'>
        {t('settings.save')}
      </StyledButton>
    </Controls>
  );
};

export default FormControls;
