import { ClassesStore, WavesStore, RegistrationFieldsStore, SquadsStore } from '../../stores';

import { classesConditions } from './classesConditions';
import { registrationFieldsConditions } from './registrationFieldsConditions';
import { squadsConditions } from './squadConditions';
import { wavesConditions } from './wavesConditions';

function generateConditions(
  wavesStore: WavesStore,
  classesStore: ClassesStore,
  squadsStore: SquadsStore,
  registrationFieldsStore: RegistrationFieldsStore,
) {
  return {
    ...wavesConditions(wavesStore),
    ...classesConditions(classesStore),
    ...squadsConditions(squadsStore),
    ...registrationFieldsConditions(registrationFieldsStore),
  };
}

export { generateConditions };
