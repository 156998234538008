import { Text } from 'modules/miles/shared/components';
import { Divider } from 'modules/miles/shared/components/Divider';
import { ArrowDown } from 'modules/miles/shared/components/Icon';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Show } from 'src/components/condition';

const AccordionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.miles.colors.dark.background['700']};
  color: ${(props) => props.theme.miles.colors.dark.alpha['900']};
  padding: 16px 24px;

  border: 1px solid ${(props) => props.theme.miles.colors.dark.alpha['300']};
  box-shadow: 0px 3px 4px ${(props) => props.theme.miles.colors.dark.alpha.swap100};
  border-radius: 8px;
  -webkit-tap-highlight-color: transparent;

  .accordion-body {
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .divider {
    margin: 20px 0;
  }

  p {
    margin: 0;
  }
`;

const AccordionHeader = styled.div<{ withError?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;

  cursor: pointer;

  .expand-icon {
    display: flex;
    width: 16px;
    .icon {
      color: ${(props) => props.theme.miles.colors.dark.alpha['700']};
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  ${(props) =>
    props.withError &&
    css`
      // Error state css properties
    `}
`;

type Props = {
  children: React.ReactNode;
  headingText: string | React.ReactNode;
  defaultExpanded?: boolean;
  withError?: boolean;

  className?: string;
  style?: React.CSSProperties;
};

export const Accordion: React.FC<Props> = ({ children, headingText, className, defaultExpanded = false, withError, ...rest }) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);
  const [setHeight, setHeightState] = useState('0px');
  const content = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setHeightState(isOpen ? '0px' : `${content.current?.scrollHeight}px`);
  };

  return (
    <AccordionWrapper className={className} {...rest}>
      <AccordionHeader onClick={toggleAccordion} withError={withError}>
        <div className='heading-section'>
          <Text size='md' weight='regular'>
            {headingText}
          </Text>
        </div>
        <div className='expand-icon'>
          {isOpen ? (
            <ArrowDown className='icon' size={16} style={{ transform: 'rotate(180deg)' }} />
          ) : (
            <ArrowDown className='icon' size={16} />
          )}
        </div>
      </AccordionHeader>

      <div ref={content} style={{ maxHeight: `${setHeight}` }} className='accordion-body'>
        <Divider className='divider' />
        {children}
      </div>
    </AccordionWrapper>
  );
};
