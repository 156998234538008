import { makeAutoObservable } from 'mobx';

class DataStore {
  readinessScore: number | undefined = 0;
  streak = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setReadinessValue(newValue) {
    this.readinessScore = newValue;
  }

  setStreakValue(newValue) {
    this.streak = newValue;
  }

  maybeSetReadinessValue(value) {
    if ('ratings' in value) {
      this.readinessScore = value.readiness;
    }
  }

  readinessAsPercentage() {
    if (this.readinessScore) {
      return `${(this.readinessScore * 100).toFixed(0)}%`;
    }
  }
}

export const dailyDataStore = new DataStore();
