import { isViewportFilledEnough } from '../../utils';

import { requestsVersionStore } from '../../stores';

import { pagination } from '../../derivations';
import { loadMore } from './loadMore';

async function loadUntilViewport(version: string) {
  if (!requestsVersionStore.compare(version)) {
    return;
  }

  await loadMore();
  await new Promise((res, _rej) => setTimeout(res, 50));

  if (isViewportFilledEnough()) {
    return;
  }

  if (!pagination.hasMore.get()) {
    return;
  }

  await loadUntilViewport(version);
}

export { loadUntilViewport };
