import { isEmpty } from 'lodash';
import { computed } from 'mobx';

import { form, sessionStore } from 'stores';

import { LOCAL_STORAGE_KEYS } from '../../../constants/localStorage-keys';
import { AdditionalRegistrationFields, REGISTRATION_FORM } from '../constants';

import { parseUrlParams, preloaders } from '../utils';

import { confirmationStore, wavesStore, transferStore, smartLinkStore } from '../stores';

import { distanceSelector } from './mappedData/distance';
import { raceSelector } from './mappedData/race';
import { priceSelectors } from './prices';

/**
 * @description
 * Core conditions
 */
const mainConditions = {
  isProductDistance: distanceSelector.isProduct,

  /**
   * @description
   * Is distance is of type team
   */
  isTeam: distanceSelector.isTeam,
  /**
   * @description
   * Is distance is of type single
   */
  isSingle: distanceSelector.isSingle,
  /**
   * @description
   * Is distance option for allow_no_profile_registration enabled
   */
  isNoProfile: distanceSelector.isNoProfile,

  /**
   * @description
   * Is distance is_secret is enabled
   */
  isSecretRace: computed(() => raceSelector.isSecret.get()),

  /**
   * @description
   * Is there custom fields per distance
   */
  isCustomFields: distanceSelector.isCustomFieldsEnabled,

  /**
   * @description
   * Is user logged in
   */
  isLogged: computed(() => sessionStore.isAuth),

  /**
   * @description
   * Is there price available for the distance
   */
  isPaid: computed(() => Boolean(priceSelectors.distancePrice.get())),

  /**
   * @description
   * Is registration was already completed for this distance
   */
  isAlreadyRegistered: distanceSelector.isAlreadyRegistered,

  /**
   * @description
   * Is invite_token was provided
   * which enables confirmation flow
   */
  isConfirmation: computed(() => confirmationStore.isInvited),

  /**
   * @description
   *
   **/
  isTransferredRegistration: computed(() => transferStore.isTransferred),

  /**
   * @description
   * Is invite_token wasn't provided
   * which enables normal registration flow
   */
  isRegistration: computed(() => !confirmationStore.isInvited && !transferStore.isTransferred),

  /**
   * @description
   * Is disance presented, and registartion/confirmation can be opened
   * which enables normal registration flow
   */
  isDistanceLoaded: computed((): boolean => Boolean(distanceSelector.id.get() && !preloaders.loadRegistrationStatus.isLoading())),

  /**
   * @description
   * Is distance have an enabled refund protect plan
   */
  isRefundProtectEnabled: computed((): boolean => distanceSelector.isRefundProtectEnabled.get()),

  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   */
  isPageRegistrationSingleWithCustomFields: computed(
    (): boolean =>
      mainConditions.isRegistration.get() &&
      mainConditions.isCustomFields.get() &&
      mainConditions.isSingle.get() &&
      mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   */
  isPageRegistrationSingleWithoutCustomFields: computed(
    (): boolean =>
      mainConditions.isRegistration.get() &&
      !mainConditions.isCustomFields.get() &&
      mainConditions.isSingle.get() &&
      mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   */
  isPageRegistrationTeamWithCustomFields: computed(
    (): boolean =>
      mainConditions.isRegistration.get() &&
      mainConditions.isCustomFields.get() &&
      mainConditions.isTeam.get() &&
      mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   */
  isPageRegistrationTeamWithoutCustomFields: computed(
    (): boolean =>
      mainConditions.isRegistration.get() &&
      !mainConditions.isCustomFields.get() &&
      mainConditions.isTeam.get() &&
      mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   * Condition for transfer registration
   */
  isPageTransferConfirmationWithCustomFields: computed(
    (): boolean =>
      mainConditions.isTransferredRegistration.get() && mainConditions.isCustomFields.get() && mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   * Condition for transfer registration
   */
  isPageTransferConfirmationWithoutCustomFields: computed(
    (): boolean =>
      mainConditions.isTransferredRegistration.get() && !mainConditions.isCustomFields.get() && mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   */
  isPageConfirmationWithCustomFields: computed(
    (): boolean => mainConditions.isConfirmation.get() && mainConditions.isCustomFields.get() && mainConditions.isDistanceLoaded.get(),
  ),
  /**
   * @description
   * set of conditions to render a page, more in mainConditions
   */
  isPageConfirmationWithoutCustomFields: computed(
    (): boolean => mainConditions.isConfirmation.get() && !mainConditions.isCustomFields.get() && mainConditions.isDistanceLoaded.get(),
  ),

  /**
   * @description
   * Means that user registered and cannot proceed with the registration
   * When user is invited, he already registered, but still can proceed with the confirmation
   */
  isAlreadyRegisteredAndNotInvited: computed(
    (): boolean =>
      distanceSelector.isAlreadyRegistered.get() &&
      mainConditions.isRegistration.get() &&
      !mainConditions.isStripeRedirect() &&
      !mainConditions.isEditRegistration(),
  ),

  /**
   * @description
   * Means that user cannot register anymore because of the date limitation
   * When user is invited, he still can confirm
   */
  isRegistrationOutdated: computed((): boolean => {
    const distance = distanceSelector.model.get();
    return Boolean(distance?.isRegistrationClosed()) && mainConditions.isRegistration.get();
  }),

  /**
   * @description
   * Means that user cannot register in push payment registration due to the date limitation
   */
  isRegistrationOutdatedForPushPayment: computed((): boolean => {
    const distance = distanceSelector.model.get();
    return Boolean(distance?.isRegistrationClosed()) && mainConditions.isPushPayment.get();
  }),

  /**
   * @description
   * Means that user cannot register anymore because it's disabled on the race - allowed_registration
   * When user is invited, he still can confirm
   */
  isRegistrationClosed: computed((): boolean => {
    const race = raceSelector.model.get();
    if (!race) {
      return false;
    }

    return Boolean(!race.isRegistrationAllowed() && mainConditions.isRegistration.get());
  }),

  /**
   * @description
   * Means that user cannot register anymore because it's sold out
   * When user is invited, he still can confirm
   */
  isDistanceSoldOut: computed((): boolean => {
    const distance = distanceSelector.model.get();
    return Boolean(distance?.isSoldOut() && mainConditions.isRegistration.get());
  }),

  /**
   * @description
   * Is invite_token was provided but invalid(already confirmed or missing)
   * which blocks confirmation flow
   */
  isInvalidInviteToken: computed(() => confirmationStore.isInvalidInviteToken),

  /**
   * @description
   * Is classes enabled for the distance
   */
  isClassesEnabled: computed(() => Boolean(distanceSelector.model.get()?.classes.length)),

  /**
   * @description
   * Is relays (disciplines) enabled for the distance
   */
  isDisciplinesEnabled: computed(() => Boolean(distanceSelector.model.get()?.value.disciplines?.length)),

  /**
   * @description
   * Is waves enabled for the distance
   */
  isWavesEnabled: computed(() => Boolean(wavesStore.value?.length)),

  /**
   * @description
   * is group registration available for this distance
   */
  isGroupRegistrationsEnabled: computed(
    (): boolean => mainConditions.isRegistration.get() && mainConditions.isSingle.get() && !mainConditions.isSecretRace.get(),
  ),

  /**
   * @description
   * Enables only the distance selector
   * without the actual registration
   * a case when link looks like this: /:locale/races/:id/registration
   */
  isDistanceSelectorMode: function (): boolean {
    const { distanceId } = parseUrlParams();
    return !distanceId;
  },

  /**
   * @description
   * is confirmation token provided and racer marked as payment_required
   */
  isPushPayment: computed((): boolean => {
    const invitedMember = confirmationStore.tokenData;

    if (!invitedMember) {
      return false;
    }

    return invitedMember.payment_required;
  }),

  isConfirmationRacerAddedManually: computed((): boolean => {
    const invitedMember = confirmationStore.tokenData;

    if (!invitedMember) {
      return false;
    }

    return Boolean(invitedMember.added_at);
  }),

  isCouponCodeEnabled: computed((): boolean => {
    if (mainConditions.isTransferredRegistration.get()) return false;
    const field = distanceSelector.expandedRegistrationFields.get()[AdditionalRegistrationFields.coupon_code];
    return field?.selected;
  }),

  isGiftcardEnabled: computed((): boolean => {
    return Boolean(distanceSelector.model.get()?.value.giftcard_enabled);
  }),

  /**
   * @description
   * is registrationComplete id provided
   */
  isStripeRedirect: function (): boolean {
    const { registrationComplete } = parseUrlParams();
    return Boolean(registrationComplete);
  },

  /**
   * @description
   * is edit registration available
   */
  isEditRegistration: (): boolean => {
    const distance = distanceSelector.model.get();
    if (mainConditions.isTeam.get() || !distance?.value?.editInformationSettings) return false;
    const { editRacerId } = parseUrlParams();
    return Boolean(editRacerId);
  },

  isSmartLinkRegistration: (): boolean => {
    const { smartLinkToken } = parseUrlParams();
    return Boolean(smartLinkToken);
  },

  isSmartLinkValid: (): boolean => {
    return !isEmpty(smartLinkStore.value);
  },

  isCampaignLinkExists: (): boolean => {
    return Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.campaignLinkToken));
  },

  isCouponEnableBySmartLink: computed((): boolean => {
    return Boolean(smartLinkStore.value?.discount);
  }),
};

export { mainConditions };
