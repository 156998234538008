import { omit } from 'lodash';
import { action, observable, toJS, makeObservable } from 'mobx';

import { t } from 'utils';

import { LGiftcard } from '../types';

type Value = {
  [k in string]: LGiftcard | nil;
};

type InvalidValue = { [k in string]: string[] | null };

type ChangedFieldsArr = string[];

class GiftcardsStore {
  /**
   * @description
   * Loaded giftcard code data. { '#Khhsaj': {...Giftcard object} }
   */
  @observable
  value: Value = {};

  /**
   * @description
   * List of invalid giftcard codes with provided error - { '#Khhsaj': 'There is no such giftcard' }
   */
  @observable
  invalidValues: InvalidValue = {};

  /**
   * @description
   * array of changed fields ordered in the change way
   * ['giftcard.1', 'giftcard.2',  'giftcard.4',  'giftcard.3']
   */
  @observable
  changedGiftcardFields: ChangedFieldsArr = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setValue(value: Value) {
    this.value = value;
  }

  @action
  init(fieldId: string, value: LGiftcard) {
    this.value[fieldId] = value;
    this.invalidValues = omit(this.invalidValues, fieldId);
  }

  @action
  initInvalid(fieldId: string, error: string[]) {
    this.invalidValues[fieldId] = error;
    this.value = omit(this.value, fieldId);
    this.changedGiftcardFields.push(fieldId);
  }

  @action
  logFieldChange(fieldId: string) {
    this.changedGiftcardFields.push(fieldId);
  }

  @action
  clearInvalidValueOnGiftcardFieldChange(fieldId: string) {
    this.invalidValues = omit(this.invalidValues, fieldId);
  }

  @action
  clear() {
    this.value = {};
    this.invalidValues = {};
  }

  @action
  removeCode(fieldId: string) {
    this.value = omit(this.value, fieldId);
  }

  findByFieldId(fielId: string) {
    if (!fielId) return null;
    return this.value[fielId];
  }

  getFieldIdByCode(code: string | nil): string | nil {
    if (!code) {
      return null;
    }
    const keys = Object.keys(this.value);
    for (let i = 0; i < keys.length; i++) {
      if (this.value[keys[i]]?.code === code) return keys[i];
    }
  }

  findByCode(code: string | nil) {
    if (!code) {
      return null;
    }
    return Object.keys(this.value).find((k) => this.value[k]?.code === code);
  }

  find(code: string | nil) {
    if (!code) {
      return null;
    }
    return this.value[code];
  }

  isGiftcardValid(fieldId: string): boolean {
    return Boolean(this.value[fieldId]);
  }

  isGiftcardApplied(fieldId: string): boolean {
    return Boolean(this.value[fieldId]);
  }
}

export { GiftcardsStore };
