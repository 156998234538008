import { isEqual } from 'lodash';
import { isString } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';

import { Icon } from '../Icons';
import { BodyText } from '../Typography';

type CheckboxProps = {
  selected?: boolean;
  isError?: boolean;
  labelPosition: 'left' | 'right';
};

const CheckboxMark = styled.div<CheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 1px ${(props) => (!props.isError ? props.theme.main.colors.clay4 : props.theme.main.colors.error)} solid;
  border-radius: 1px;
  margin-${(props) => props.labelPosition}: 12px;

  .icon {
    opacity: ${(props) => (props.selected ? 1 : 0)};
  }
`;

const getDisabledStyles = (props) =>
  props.disabled
    ? `
  ${CheckboxMark} {
    background: ${props.theme.main.colors.clay5};
    border-color: ${props.theme.main.colors.clay5};
  }
`
    : '';

const getParagraphColor = (props) =>
  props.disabled ? props.theme.main.colors.clay3 : props.isError ? props.theme.main.colors.error : 'inherit';

const Check = styled.div<{ disabled?: boolean; isError?: boolean }>`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    ${(props) => (props.disabled ? 'pointer-events: none;' : '')}

    &:hover {
      ${CheckboxMark} {
        border-color: ${(props) => props.theme.main.colors.clay1};
      }
    }

    p {
      color: ${getParagraphColor};
    }

    ${getDisabledStyles}
  }
`;

export type RadioProps = {
  checkValue?: any;
  isError?: boolean;
  labelPosition: 'left' | 'right';
  className?: string;
  labelBodyVariant?: 'body1' | 'body2' | 'editorial1' | 'editorial2';
} & BaseInputProps;

export const Checkbox: React.FC<RadioProps> = (props) => {
  const {
    label,
    name,
    value,
    checkValue,
    disabled,
    readOnly,
    onChange,
    isError,
    labelPosition,
    className,
    id,
    errorMessage,
    labelBodyVariant = 'body2',
  } = props;

  const LabelElement = isString(label) ? <BodyText variant={labelBodyVariant}>{label}</BodyText> : label;

  return (
    <Check
      title={typeof label === 'string' ? label : ''}
      disabled={disabled}
      className={className}
      isError={isError || Boolean(errorMessage)}
    >
      <input
        type='checkbox'
        checked={(value as boolean) || false}
        id={id}
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const isChecked = e.currentTarget.checked;
          onChange({ name, value: isChecked }, e);
        }}
      />
      <label htmlFor={id} {...testAnchors.useField(name, TEST_ANCHORS.fieldStructure.value, value)}>
        <Show if={labelPosition === 'left'}>{LabelElement}</Show>
        <CheckboxMark
          selected={checkValue ? isEqual(value, checkValue) : Boolean(value)}
          isError={isError || Boolean(errorMessage)}
          labelPosition={labelPosition}
        >
          <Icon name='CheckboxMark' size={16} className='icon' />
        </CheckboxMark>
        <Show if={labelPosition === 'right'}>{LabelElement}</Show>
      </label>
    </Check>
  );
};
