/**
 * @description
 * Calculate new price based on discount settings
 */
function calculateDiscountedPrice(price: number, discount: { amount: number | nil; isPercentage: boolean }) {
  if (!discount.isPercentage && discount.amount) {
    if (discount.amount > price) {
      return 0;
    }

    return price - discount.amount;
  }

  if (discount.isPercentage && discount.amount) {
    if (discount.amount >= 100) {
      return 0;
    }

    return price - (price * discount.amount) / 100;
  }

  return 0;
}

export { calculateDiscountedPrice };
