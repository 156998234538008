import { RegistrationFormShape } from 'modules/miles/app/form/types';
import React, { useState } from 'react';
import { UseFormSetValue, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import { MILES_COLORS } from 'styles/theme/colors';

const Text = styled.div`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  transition: all 0.2s ease-in-out;
  user-select: none;
`;

const Left = styled(Text)<{ isOn: boolean }>`
  width: 50%;
  position: absolute;
  z-index: 10;
  color ${(props) => (props.isOn ? '#ffffff' : '#000000')};
  font-weight: 400;
`;

const Right = styled(Text)<{ isOn: boolean }>`
  position: absolute;
  z-index: 10;
  right: 0;
  width: 50%;
  color ${(props) => (!props.isOn ? '#ffffff' : '#000000')};
`;

const Pill = styled.div<{ isOn: boolean }>`
  align-items: center;
  padding: 0px 12px;
  width: 58px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2), 0px 3px 8px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 56px;
  position: absolute;
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out;
  left: ${(props) => (props.isOn ? 'calc(100% - 61px)' : '3px')};
  right: ${(props) => (props.isOn ? '3px' : 'auto')};
`;

type ToggleProps = {
  isOn: boolean;
};
const Background = styled.div<ToggleProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  gap: 6px;
  width: 126px;
  height: 44px;
  background: ${(props) => (props.isOn ? MILES_COLORS.dark.icon.cobalt : 'rgba(255, 255, 255, 0.24)')};
  border-radius: 22px;
  cursor: pointer;
`;

type Props = {
  leftText?: string;
  rightText?: string;
  fieldName: keyof RegistrationFormShape;
  setValue: UseFormSetValue<RegistrationFormShape>;
  currentValue: boolean;
};

export default function Toggle({ fieldName, setValue, leftText = 'No', rightText = 'Yes', currentValue }: Props) {
  const [isOn, setIsOn] = useState(currentValue);
  const handleClick = () => {
    setIsOn(!isOn);
    setValue(fieldName, !isOn);
  };
  return (
    <Background isOn={isOn} onClick={handleClick}>
      <Left isOn={isOn}>{leftText}</Left>
      <Right isOn={isOn}>{rightText}</Right>
      <Pill isOn={isOn}></Pill>
    </Background>
  );
}
