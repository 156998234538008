import * as React from 'react';

export const Controls = (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M21 15.02V7.02002' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M21 25.02V21.02' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24 18.02C24 19.6769 22.6569 21.02 21 21.02C19.3431 21.02 18 19.6769 18 18.02C18 16.3632 19.3431 15.02 21 15.02C22.6569 15.02 24 16.3632 24 18.02Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M11 13.02V25.02' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 10.02C14 8.36317 12.6569 7.02002 11 7.02002C9.34315 7.02002 8 8.36317 8 10.02C8 11.6769 9.34315 13.02 11 13.02C11.7956 13.02 12.5587 12.7039 13.1213 12.1413C13.6839 11.5787 14 10.8157 14 10.02V10.02Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
