import { flatten, sortBy, last } from 'lodash';

import { LCheckpoint } from '../../types';

function generateCheckpoints(values: DistanceType[]): LCheckpoint[] {
  const allCheckpoints = values.reduce((acc, distance) => {
    const checkpoints = sortBy(distance.checkpoints, (checkpoint) => checkpoint.index).map<LCheckpoint>((el) => ({ ...el, isLast: false }));

    const lastCheckpoint: LCheckpoint | nil = last(checkpoints);

    if (lastCheckpoint) {
      lastCheckpoint.isLast = true;
    }

    return [...acc, ...checkpoints];
  }, []);

  return flatten(allCheckpoints);
}

export { generateCheckpoints };
