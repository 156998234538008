import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/condition';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';
import { t } from 'src/utils';

import { ParticipantsModal } from '../UserModal';
import { Participant } from './Participant';

type Props = {
  members: RacersType[];
  onShowParticipant: (racerId: number) => AnyFunction;
  name: string;
};

const StyledUtility = styled(Utility)`
  color: ${(props) => props.theme.main.colors.clay3};
`;

const ShowMoreWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  .arrow {
    margin-left: 4px;
  }
`;

const ParticipantsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
`;

export const TeamParticipants: React.FC<Props> = (props) => {
  const { members, onShowParticipant, name } = props;
  const [show, changeShow] = React.useState(false);

  const onShowParticipants = (value) => () => changeShow(value);

  return (
    <Show if={Boolean(members.length)}>
      <ParticipantsWrap>
        <Participant value={members[0]} onShow={onShowParticipant} />
        <Show if={members.length > 2}>
          <ShowMoreWrap onClick={onShowParticipants(true)}>
            <ParticipantsModal
              name={name}
              members={members}
              onShowUser={onShowParticipant}
              show={show}
              onClose={onShowParticipants(false)}
            />
            <StyledUtility variant='u4' weight='bold'>
              {members.length} {t('racersTable.participants')}
            </StyledUtility>
            <Svg className='arrow' name='ArrowRight' size={16} />
          </ShowMoreWrap>
        </Show>
        <Show if={members.length === 2}>
          <Participant value={members[1]} onShow={onShowParticipant} />
        </Show>
      </ParticipantsWrap>
    </Show>
  );
};
