import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const DietFoodDrinkPath = (
  <>
    <path
      id='Vector'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 1V5.76015C6.5 6.23757 6.32922 6.69925 6.01851 7.06173L3.98149 9.43827C3.67078 9.80075 3.5 10.2624 3.5 10.7398V22C3.5 22.5523 3.94772 23 4.5 23H11.5C12.0523 23 12.5 22.5523 12.5 22V10.7398C12.5 10.2624 12.3292 9.80075 12.0185 9.43827L9.98149 7.06173C9.67078 6.69925 9.5 6.23757 9.5 5.76015V1H6.5ZM10.5 13H5.5V17H10.5V13Z'
      fill='currentColor'
    />
    <rect id='Rectangle 1192' x='17.5' y='4' width='2' height='5' fill='currentColor' />
    <circle id='Ellipse 114' cx='16.5' cy='5' r='2' fill='currentColor' />
    <circle id='Ellipse 114_2' cx='18.5' cy='3' r='2' fill='currentColor' />
    <circle id='Ellipse 114_3' cx='20.5' cy='5' r='2' fill='currentColor' />
    <path
      id='Subtract'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.5 9L19.6414 22.0101C19.5603 22.5781 19.0738 23 18.5 23C17.9262 23 17.4397 22.5781 17.3586 22.0101L16.6421 16.995C17.6804 16.9221 18.5 16.0568 18.5 15H16.3571L16.0714 13H16.5C17.6046 13 18.5 12.1046 18.5 11H15.7857L15.5 9H21.5Z'
      fill='currentColor'
    />
  </>
);

export const DietFoodDrink = (props: IconProps) => <SvgIcon path={DietFoodDrinkPath} {...props} />;
