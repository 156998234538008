import { isEmpty } from 'lodash';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { stylesBySize } from 'src/styledComponents/DropDownFilter';
import { Svg } from 'src/styledComponents/Icons';
import { Utility } from 'src/styledComponents/Typography';

import { Menu } from './Menu';

const StyledUtility = styled(Utility)<{ size: keyof typeof Sizes }>`
  color: ${(props) => props.theme.main.colors.clay3};
  ${(props) => stylesBySize[props.size]};
  padding: unset;
`;

const StyledArrow = styled(Svg)`
  margin-left: 8px;
  display: flex;
  svg path {
    stroke: ${(props) => props.theme.main.colors.clay3};
  }
`;

const activeStyles = css`
  background-color: ${(props) => props.theme.main.colors.clay1};
  border-color: ${(props) => props.theme.main.colors.clay1};

  ${StyledUtility} {
    color: white;
  }

  ${StyledArrow} {
    transform: rotate(180deg);

    svg path {
      stroke: white;
    }
  }
`;

const hasValueStyles = css`
  background-color: ${(props) => props.theme.main.colors.clay3};
  border-color: ${(props) => props.theme.main.colors.clay3};

  ${StyledUtility} {
    color: white;
  }

  ${StyledArrow} {
    svg path {
      stroke: white;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Filter = styled.div<{ active: boolean; hasValue: boolean; size: keyof typeof Sizes }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  border: 2px solid ${(props) => props.theme.main.colors.clay3};
  border-radius: 129px;
  cursor: pointer;

  ${(props) => (props.hasValue ? hasValueStyles : '')}
  ${(props) => (props.active ? activeStyles : '')}
  ${(props) => stylesBySize[props.size]}
  
  &:hover {
    background: ${(props) => props.theme.main.colors.clay3};
    border: 2px solid ${(props) => props.theme.main.colors.clay3};
    ${StyledUtility} {
      color: ${(props) => props.theme.main.colors.white};
    }
    ${StyledArrow} {
      svg path {
        stroke: ${(props) => props.theme.main.colors.white};
      }
    }
  }
`;

enum Sizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type Props = BaseInputProps & {
  options: DropdownItemType[];
  onMenuOpen?: AnyFunction;
  onMenuClose?: AnyFunction;
  size: keyof typeof Sizes;
};

export const MultiselectFilter: React.FC<Props> = (props) => {
  const { options, label, className, value = [], onChange, name, onMenuOpen, onMenuClose, size = 'medium' } = props;
  const [open, setOpenState] = React.useState(false);

  const setOpen = (trigger: boolean) => {
    if (trigger) onMenuOpen && onMenuOpen();
    else onMenuClose && onMenuClose();

    setOpenState(trigger);
  };

  const handleChoose = (item: DropdownItemType) => () => {
    const neededValue = value.find((option) => option.key === item.key);

    const values = neededValue ? value.filter((option) => option.key !== item.key) : [...value, item];

    onChange({ name, value: values });
  };

  const handleClear = () => {
    onChange({ name, value: [] });
  };

  return (
    <Wrapper className={className}>
      <Filter size={size} active={open} hasValue={!isEmpty(value)} onClick={() => setOpen(true)}>
        <StyledUtility size={size} variant='u4' weight='bold'>
          {label}
        </StyledUtility>

        <StyledArrow name='ArrowDownSmall' size={size === 'large' ? 20 : 16} />
      </Filter>

      <Menu options={options} open={open} value={value} setOpen={setOpen} onChoose={handleChoose} handleClear={handleClear} />
    </Wrapper>
  );
};
