import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/styledComponents/Icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 21px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .svg {
    display: flex;
  }

  path {
    stroke: ${(props) => props.theme.main.colors.clay3};
  }
`;

type Props = {
  className?: string;
};

export const DefaultFlag: React.FC<Props> = (props) => {
  return (
    <Wrapper className={props.className}>
      <Svg className='svg' name='Globe' size={7} />
    </Wrapper>
  );
};
