import { isEmpty } from 'lodash';
import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { t } from 'utils';

import { ClassesStore } from '../../stores';

const showAll = (isAvailable: boolean) =>
  !isAvailable
    ? []
    : [
        {
          value: 0,
          key: 0,
          label: t.staticAsString('registration.classPlaceholder'),
        },
      ];

function generateClassesSelect(idGenerator: IComputedValue<null | number>, store: ClassesStore) {
  const classSelectOptions: IComputedValue<NewSelectItemType[]> = computed(() => {
    let classes: any = [];
    const distanceId = idGenerator.get();
    const maxClassesNameLength = 35;

    if (!distanceId) {
      classes = store.distinctClasses;
    } else {
      classes = store.findClassesByDistance(distanceId);
    }

    return [
      ...classes.map((model) => model.toSelectItem(maxClassesNameLength)),
      ...showAll(!isEmpty(classes)),
    ] as unknown as NewSelectItemType[];
  });

  function findClassSelectOption(ids: string | null, isDistinctClasses: boolean): NewSelectItemType {
    let item;

    if (isDistinctClasses) {
      item = store.distinctClasses.find((value) => value.id === ids);
    } else {
      item = store.find(ids);
    }

    if (!item) {
      return showAll(true)[0] as unknown as NewSelectItemType;
    }

    return item.toSelectItem() as unknown as NewSelectItemType;
  }

  const classesSelect = {
    options: classSelectOptions,
    findOption: findClassSelectOption,
  };

  return classesSelect;
}

export { generateClassesSelect };
