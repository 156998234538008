import download from 'downloadjs';

import { actionMenuStore } from '../../stores';

import { close } from './display';

const onExportReceipt = async () => {
  const order = actionMenuStore.distance?.order;
  if (!order) return;
  close();

  //If need to download thr receipt in basic flow use - exportReceipt('pdf', id) function of the following:
  if (order.receipt?.url) {
    download(`${order.receipt.url}`);
  }
};

export { onExportReceipt };
