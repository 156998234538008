import { findInterceptor } from '../actions';

import { disposers } from '../stores';

function observeFindShortcuts() {
  window.addEventListener('keydown', observe);
  disposers.register(() => window.removeEventListener('keydown', observe));
}

function observe(e: KeyboardEvent) {
  if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
    e.preventDefault();
    findInterceptor.handle();
  }
}

export { observeFindShortcuts };
