import { ConfirmationModal } from 'modules/confirmation';
import { SegmentForm } from 'modules/segmentForm';
import * as React from 'react';

import {
  TransferRegistrationForm,
  TransferRegistrationConfirmForm,
  TransferRegistrationDeleteForm,
} from 'src/modules/transferRegistration';

function Modals() {
  return (
    <>
      <SegmentForm />
      <ConfirmationModal />
      <TransferRegistrationForm />
      <TransferRegistrationConfirmForm />
      <TransferRegistrationDeleteForm />
    </>
  );
}

export { Modals };
