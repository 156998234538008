import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  API_DELETE_CANCELED_PAYMENT,
  API_REGISTRATION_CONFIG,
  API_URL,
  PAYMENTS,
  PATCH_PAYMENT,
  PAYMENT_STATUS,
  REGISTRATION_CONFIG,
} from 'src/constants';

import { request, action } from 'utils';

import { paymentsStore } from 'stores';
import { Payments as PaymentsStore } from 'stores';

class PaymentsService {
  // extends LoadableService< PaymentsType, FiltersType, PaymentsStore > { NOTE DISABLE PAYMENT METHOD
  patchPaymentUrl: string;
  store: PaymentsStore;

  constructor(store: PaymentsStore) {
    // super({ NOTE DISABLE PAYMENT METHOD
    //   store,
    //   resourcesUrl: `${API_URL}${API_PAYMENTS_URL}`,
    //   resource: PAYMENTS
    // });
    this.store = store;
    this.patchPaymentUrl = `${API_URL}${API_DELETE_CANCELED_PAYMENT}`;
  }

  // NOTE DISABLE PAYMENT METHOD
  // @request({ action: PAYMENTS })
  // async loadResourcesRequest(): any {
  //   return super.loadResourcesRequest({});
  // }

  // @action({ action: PAYMENTS, minRequestTime: 800 })
  // async loadResource(): any {
  //   if (!this.store.values.length) {
  //     const [status, response] = await this.loadResourcesRequest();

  //     if (status) {
  //       let values = response.data || [];
  //       this.store.addValues(values, 0, {});
  //     }
  //   }
  // }

  @request({ action: PATCH_PAYMENT })
  async patchPaymentRequest(token: string): Promise<any> {
    const URL = generatePath(this.patchPaymentUrl, { token });

    return axios.patch(URL);
  }

  @action({ action: PATCH_PAYMENT })
  async patchPayment(params: AnyObject): Promise<boolean> {
    const [status, response] = await this.patchPaymentRequest(params.token);

    if (status) {
      const payment = response.data && response.data.payment;
      const amount = parseFloat(payment.amount) || 0;

      return payment.state === PAYMENT_STATUS.ready && amount > 0;
    }

    return false;
  }

  @request({ action: REGISTRATION_CONFIG })
  async getPaymentConfigRequest(): Promise<any> {
    return axios.get(`${API_URL}${API_REGISTRATION_CONFIG}`);
  }

  @action({ action: REGISTRATION_CONFIG })
  async getPaymentConfig() {
    const [status, response] = await this.getPaymentConfigRequest();

    if (status) {
      this.store.changeConfig({
        ...response.data,
        registration_ttl: +response.data.registration_ttl,
      });
    }
  }
}

const paymentsService = new PaymentsService(paymentsStore);
export { PaymentsService, paymentsService };
