import { computed } from 'mobx';
import type { IComputedValue } from 'mobx';

import { windowSize } from 'stores';

import { actionMenuStore } from '../../stores';

const isOpen: IComputedValue<boolean> = computed(() => Boolean(actionMenuStore.distance && actionMenuStore.isOpen));

const isAnyMenuOpen = computed(() => windowSize.isGreaterThan('medium') && isOpen.get());

const generateIsMenuOpen = (distanceId: number): IComputedValue<boolean> =>
  computed(() => {
    if (distanceId !== actionMenuStore.distance?.id) {
      return false;
    }

    return isAnyMenuOpen.get();
  });

const isModalOpen: IComputedValue<boolean> = computed(() => windowSize.isLessThan('large') && isOpen.get());

const name: IComputedValue<string> = computed(() => {
  if (!actionMenuStore.distanceModel) {
    return '';
  }

  return actionMenuStore.distanceModel.value.name;
});

export { name, isModalOpen, generateIsMenuOpen, isAnyMenuOpen };
