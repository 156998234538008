import * as React from 'react';

export const Place = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 19V12.5385C3 12.2528 3.06321 11.9789 3.17574 11.777C3.28826 11.575 3.44087 11.4615 3.6 11.4615H8.4'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.9996 19V12.5385C20.9996 12.2528 20.9364 11.9789 20.8239 11.777C20.7114 11.575 20.5587 11.4615 20.3996 11.4615H15.5996'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.40039 19V6.07692C8.40039 5.79131 8.4636 5.51739 8.57613 5.31542C8.68865 5.11346 8.84126 5 9.00039 5H15.0004C15.1595 5 15.3121 5.11346 15.4247 5.31542C15.5372 5.51739 15.6004 5.79131 15.6004 6.07692V19'
      stroke='#272E34'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
