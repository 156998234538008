import { helper } from 'racese-react-phone-input-2';

export const phoneMapper = (phone: string): Object | null => {
  if (!phone) return null;

  const countryData = helper.takeCountryData(phone || '');

  return {
    phone: phone ? `${phone}` : '',
    countryData: {
      dialCode: countryData.dialCode,
      name: countryData.name,
      countryCode: countryData.iso2,
    },
  };
};
