import { loadMore } from './loadMore';
import { loadUntilViewport } from './loadUntilViewport';

const loader = {
  /**
   * @access public
   * @description
   * Function is throttled
   * In case of NOT SHOW ALL
   * function will plainly load next not loaded page for choosen distance
   *
   * In case of SHOW ALL
   * function will choose next next for which results are still not loaded
   * and load 1 page for this distance
   */
  loadMore: loadMore,
  /**
   * @access public
   * @async
   * @description
   * WARNING
   * recursive function
   *
   * Function will call itself and for each iteration will loadMore results.
   * Until results is enough to fill up the viewport
   */
  loadUntilViewport: loadUntilViewport,
};

export { loader };
