import * as React from 'react';

type Props = {
  errorMessage?: ErrorMessageType;
  name: string;
};

export const ErrorLabel = ({ errorMessage, name }: Props) => {
  return (
    <span className='input-error-component' {...testAnchors.useField(name || TEST_ANCHORS.noname, TEST_ANCHORS.fieldStructure.error)}>
      {errorMessage}
    </span>
  );
};
