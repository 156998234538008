import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { distancesStore } from '../../stores';

const isAnyDistanceVirtual: IComputedValue<boolean> = computed((): boolean => {
  return distancesStore.filteredModelValues.some((distance) => distance.isMode('virtual'));
});

const isAnyDistanceCumulative: IComputedValue<boolean> = computed((): boolean => {
  return distancesStore.filteredModelValues.some((distance) => distance.isMode('cumulative'));
});

export { isAnyDistanceVirtual, isAnyDistanceCumulative };
