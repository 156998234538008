import { t } from 'utils';

const maxLength = 1000000000;

const base = {
  activity_link: {
    length: { maximum: 450 },
    url: {
      message: '^profile.resultForm.errorLink',
    },
  },

  time_result: {
    length: { maximum: 250 },
    exclusion: {
      within: { '00:00:00': '' },
      message: '^profile.resultForm.withoutZero',
    },
    presence: {
      allowEmpty: false,
      message: '^confirmProfile.validation.required',
    },
    format: {
      pattern: /^\d{2}:\d{2}:\d{2}.?\d{0,3}$/,
      message: '^confirmProfile.validation.invalidFormat',
    },
    hours: {
      greaterThan: {
        value: 99,
        message: '^segmentsForm.error.hoursLowerThan',
      },
    },
  },

  distance_logged_at: {
    presence: {
      allowEmpty: false,
      message: '^confirmProfile.validation.required',
    },
  },

  distance_logged: {
    presence: {
      allowEmpty: false,
      message: '^confirmProfile.validation.required',
    },
    numericality: {
      onlyInteger: true,
      lessThanOrEqualTo: maxLength,
      message: `^${t.staticAsString('profile.resultForm.lowerThan')} ${maxLength}`,
    },
  },
};

const create = {
  _validation: {
    ...base,
  },
};

const update = {
  _validation: {
    ...base,
  },
};

export { create, update };
