import { IconProps, SvgIcon } from 'modules/miles/shared/components/Icon/SvgIcon';
import React from 'react';

const CheckCirclePath = (
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M12.5 24C19.1274 24 24.5 18.6274 24.5 12C24.5 5.37258 19.1274 0 12.5 0C5.87258 0 0.5 5.37258 0.5 12C0.5 18.6274 5.87258 24 12.5 24ZM10.3458 17.6143L19.8334 8.12664L18.0925 6.38571L10.3458 14.1324L6.90768 10.6943L5.16675 12.4352L10.3458 17.6143Z'
    fill='#00AC58'
  />
);

export const CheckCircle = (props: IconProps) => <SvgIcon path={CheckCirclePath} {...props} />;
