import { Observer } from 'mobx-react';
import * as React from 'react';

import { count } from '../../derivations';

function UsersCountComponent() {
  return (
    <Observer>
      {() => {
        return <span className='user-count'>({count.get() || '-'})</span>;
      }}
    </Observer>
  );
}

const UsersCount = UsersCountComponent;

export { UsersCount };
