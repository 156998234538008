import ResizeObserver from 'resize-observer-polyfill';

import { RESULTS_CONTAINER_ID } from '../../constants';

import { isViewportFilled, isViewportFilledEnough, preloader } from '../../utils';

import { loader } from '../../actions';

/*
 * NOTE, Initial data fill up, to enable infinite scroll
 * It will recursively call itself util fill up the viewport
 */
function observeViewportChange(): AnyFunction | null {
  const resizeObserver = new ResizeObserver(observer);
  const resultsViewportEl = document.getElementById(RESULTS_CONTAINER_ID);

  if (!resultsViewportEl) {
    return null;
  }

  resizeObserver.observe(resultsViewportEl);
  return () => resizeObserver.disconnect();
}

function observer(_entries: ResizeObserverEntry[]) {
  if (!isViewportFilledEnough()) {
    loader.loadMore();
  }

  if (isViewportFilled()) {
    preloader.disable();
    return;
  }
}

export { observeViewportChange };
