import { Observer } from 'mobx-react';
import * as React from 'react';

import { windowSize } from 'src/stores';

type Props = {
  children: React.ReactNode;
};

export const ShowMobile: React.FC<Props> = (props) => {
  const { children } = props;

  return <Observer>{() => (windowSize.isGreaterThan('medium') ? null : <>{children}</>)}</Observer>;
};

type DesktopProps = {
  children: React.ReactNode;
  withTablet?: boolean;
};

export const ShowDesktop: React.FC<DesktopProps> = (props) => {
  const { children, withTablet = false } = props;

  return withTablet ? (
    <Observer>{() => (windowSize.isGreaterThan('large') ? <>{children}</> : null)}</Observer>
  ) : (
    <Observer>{() => (windowSize.isGreaterThan('medium') ? <>{children}</> : null)}</Observer>
  );
};

export const ShowTablet: React.FC<Props> = (props) => {
  const { children } = props;

  return <Observer>{() => (windowSize.isGreaterThan('medium') && windowSize.isLessThan('large') ? <>{children}</> : null)}</Observer>;
};
