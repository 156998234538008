import { DISTANCE_OPTIONS } from 'modules/races/constants';

export const Variants = {
  home: 'home',
  normal: 'normal',
};

export const GET_SEARCH_LOCATIONS = 'GET_SEARCH_LOCATIONS';
export const GET_SEARCH_RACES = 'GET_SEARCH_RACES';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_RECOMENDED_RACES = 'GET_RECOMENDED_RACES';

export const DistanceOptionsMapped = DISTANCE_OPTIONS.map((item) => ({
  key: item.id,
  label: item.label,
  value: item.value,
}));

export const DISTANCE_FILTER = 'DISTANCE_FILTER';
export const SPORT_FILTER = 'SPORT_FILTER';
export const DATE_FILTER = 'DATE_FILTER';

export const amountInDropdown = {
  locations: 3,
  raceName: 5,
};
