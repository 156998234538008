import * as React from 'react';

export const GoToRace = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_587:674)'>
      <path d='M17.625 11.9983H2.625' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.5 8.87333L17.625 11.9983L14.5 15.1233' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.375 2.62333V21.3733' stroke='#272E34' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_587:674'>
        <rect width='20' height='20' fill='white' transform='translate(2 2)' />
      </clipPath>
    </defs>
  </svg>
);
