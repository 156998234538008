import React, { FC, ReactNode } from 'react';

import { DEFAULT_RACE_IMAGE } from 'src/constants';
import { Svg } from 'src/styledComponents/Icons/Svg';
import { Skeleton } from 'src/styledComponents/Skeleton';
import { Headline, Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';

import { Body, Content, ImageBox, InlineFlex, SportTag, StyledCard, StyledLinkWithCondition } from '../Components';
import { DateUtility, Header, Favourite, Wrapper, SportType, Distances, TeaserTitle } from './styled';

type Props = {
  active: boolean;
  bodyTitle: string;
  distances: number[];
  image: string | nil;
  locations?: string[];
  onChangeFavorite?: () => void;
  raceLink: string;
  sportIcon: string;
  sportType: string;
  className?: string;
  isPast?: boolean;
  published?: boolean;
  isFavoritable?: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  restorationRef?: any;
  onSelect?: AnyFunction;
  id?: number;
  truncateDistances?: boolean;
  ref?: any;
};

export const Card: FC<Props> = ({
  active,
  bodyTitle,
  distances,
  truncateDistances = false,
  image,
  locations,
  onChangeFavorite,
  raceLink,
  sportIcon,
  sportType,
  className,
  isPast,
  startDate,
  endDate,
  published = true,
  isFavoritable = true,
  restorationRef,
  onSelect,
  id,
  ref,
}): ReactNode | any => {
  const renderDistances = () => {
    let d = distances;
    if (truncateDistances) {
      // return [...distances.slice(0, 5).map((distance) => `${distance / 1000}KM`), '...'].join(' | ');
    }
    const mappedDistances = d.map((distance) => `${distance / 1000}KM`);
    return mappedDistances.join(' | ');
  };

  const getDate = () => {
    if (startDate && endDate) {
      return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
    }

    return startDate ? startDate : '';
  };

  React.useEffect(() => {
    if (!restorationRef) {
      return;
    }
    restorationRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
  });

  return (
    <Wrapper
      ref={ref}
      className={className}
      onClick={() => {
        onSelect && onSelect(id);
      }}
    >
      <StyledCard>
        <Header>
          <div>
            <Svg name='DateCalendar' className='calendar-icon' size={16} />
            <DateUtility variant='u4' weight='bold'>
              {getDate()}
            </DateUtility>
          </div>
          {isFavoritable && (
            <Favourite
              name='Favourite'
              className='favourite-icon'
              size={24}
              active={active}
              onClick={() => onChangeFavorite && onChangeFavorite()}
            />
          )}
        </Header>

        <Body>
          <ImageBox isPast={isPast} bg={image || DEFAULT_RACE_IMAGE} />

          <SportTag>
            <img src={sportIcon} alt='' />
            <SportType>{sportType}</SportType>
          </SportTag>

          <StyledLinkWithCondition to={raceLink} ATN={bodyTitle} condition={published} />
        </Body>
      </StyledCard>

      <Content className='content-container'>
        <Headline variant='h4' className='content-title'>
          <TeaserTitle title={bodyTitle}>{bodyTitle}</TeaserTitle>
        </Headline>

        {distances && (
          <div style={{ maxWidth: '350px', marginBottom: 12 }}>
            <InlineFlex className='distances'>
              <Svg name='Distance' className='icon-distances' />
              <Distances variant='u1' weight='medium' className='content-distances'>
                {renderDistances()}
              </Distances>
            </InlineFlex>
          </div>
        )}

        <Show if={Boolean(locations?.length)}>
          <InlineFlex className='location'>
            <Svg name='Location' className='icon-location' />
            <Utility variant='u4' weight='medium' className='content-location'>
              {locations?.join(', ')}
            </Utility>
          </InlineFlex>
        </Show>
      </Content>

      <StyledLinkWithCondition to={raceLink} ATN={bodyTitle} condition={published} />
    </Wrapper>
  );
};

export const SkeletonCard: FC<{ className?: string }> = (props) => {
  return (
    <Wrapper className={props.className}>
      <StyledCard>
        <Skeleton variant='rectangular' height={218} />
      </StyledCard>

      <Content>
        <Headline variant='h4'>
          <Skeleton variant='rectangular' />
        </Headline>

        <InlineFlex>
          <Skeleton variant='rectangular' />
        </InlineFlex>

        <InlineFlex>
          <Skeleton variant='rectangular' />
        </InlineFlex>

        <InlineFlex>
          <Skeleton variant='rectangular' />
        </InlineFlex>
      </Content>
    </Wrapper>
  );
};
